import {AccountType} from '../../components/Search/SearchNav/types';
import {useViewer} from '../../state/viewer/hooks';
import {useRampProPricingPlan} from '../../util/rampFeatureFlags';

export function useGetIsAcademicUser() {
  const viewer = useViewer();
  const userInfo = viewer?.userInfo;
  return userInfo?.wandbUsageType === AccountType.Academic;
}

export function useGetTrialPlanName() {
  const isAcademicUser = useGetIsAcademicUser();
  const proPricePlanOverTeams = useRampProPricingPlan();
  return isAcademicUser ? 'Academic' : proPricePlanOverTeams ? 'Pro' : 'Teams';
}
