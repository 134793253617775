import {Icon} from '@wandb/weave/components/Icon';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import {maybePluralize} from '@wandb/weave/core/util/string';
import React, {FC, Fragment} from 'react';

import {
  AccountStatusBanner,
  AccountStatusBannerBoldText,
  AccountStatusBannerFlexWrapper,
  AccountStatusBannerTextWrapper,
} from '../../AccountStatusBanner';
import {useCurrentBannerStates} from '..';
import {ContactSalesCTA, WeaveTrialBannerProps} from './utils';

export const WeaveTrialBannerGlobalList: FC = () => {
  const currentBannerStates = useCurrentBannerStates();

  return (
    <Tailwind>
      <div className="space-y-8">
        {currentBannerStates.map(state => {
          const weaveTrialBannerProps =
            state.weaveEnforcementBannerFlags.weaveTrialBannerProps;

          return (
            <Fragment key={state.account.name + state.account.accountType}>
              {weaveTrialBannerProps && (
                <WeaveTrialBanner {...weaveTrialBannerProps} />
              )}
            </Fragment>
          );
        })}
      </div>
    </Tailwind>
  );
};

export const WeaveTrialBanner: FC<WeaveTrialBannerProps> = ({
  organizationId,
  numDaysRemaining,
}) => {
  const statusMsg =
    'Once your trial expires, access to Weave will be restricted. ';
  return (
    <Tailwind>
      <AccountStatusBanner
        eventData={{
          location: `exceeded 90% for Weave alert banner`,
          organizationId,
          resource: 'Weave',
        }}>
        <AccountStatusBannerFlexWrapper>
          <div>
            <Icon name="info" />
          </div>
          <AccountStatusBannerTextWrapper>
            <span>
              <AccountStatusBannerBoldText>
                You have {maybePluralize(numDaysRemaining, 'day')} remaining of
                your Weave trial.
              </AccountStatusBannerBoldText>{' '}
              <span>{statusMsg}</span>
              <ContactSalesCTA />
            </span>
          </AccountStatusBannerTextWrapper>
        </AccountStatusBannerFlexWrapper>
      </AccountStatusBanner>
    </Tailwind>
  );
};
