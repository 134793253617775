import {Reducer, useCallback, useEffect, useMemo, useReducer} from 'react';

import {PartialZoom} from '../types';
import {useDeepEqualValue} from './../../../util/hooks';

export const DEFAULT_ZOOM = {
  xAxisMax: null,
  xAxisMin: null,
  yAxisMax: null,
  yAxisMin: null,
};

type PanelZoomReducerState = {
  userZoom: {
    xAxisMax: number | null;
    xAxisMin: number | null;
    yAxisMax: number | null;
    yAxisMin: number | null;
  };
  xAxisKey: string;
};

type PanelZoomReducerActions =
  | {
      type: 'panelZoom/changeXAxisKey';
      payload: string;
    }
  | {
      type: 'panelZoom/resetUserZoom';
    }
  | {
      type: 'panelZoom/userZoomChange';
      payload: PartialZoom;
    };

export function panelZoomReducer(
  state: PanelZoomReducerState,
  action: PanelZoomReducerActions
) {
  switch (action.type) {
    /**
     * When the x-axis value changes we reset any active user zooms
     */
    case 'panelZoom/changeXAxisKey': {
      return {
        ...state,
        xAxisKey: action.payload,
        userZoom: DEFAULT_ZOOM,
      };
    }

    /**
     * When a config value changes we reset any active user zooms
     */
    case 'panelZoom/resetUserZoom': {
      return {
        ...state,
        userZoom: DEFAULT_ZOOM,
      };
    }

    case 'panelZoom/userZoomChange': {
      return {
        ...state,
        userZoom: {
          xAxisMin: action.payload.xAxisMin ?? null,
          xAxisMax: action.payload.xAxisMax ?? null,
          yAxisMin: action.payload.yAxisMin ?? null,
          yAxisMax: action.payload.yAxisMax ?? null,
        },
      };
    }

    default: {
      return state;
    }
  }
}

export const usePanelZoomReducer = ({
  configZoom,
  xAxisKey,
}: {
  configZoom: PartialZoom;
  xAxisKey: string;
}) => {
  const [state, dispatch] = useReducer<
    Reducer<PanelZoomReducerState, PanelZoomReducerActions>
  >(panelZoomReducer, {
    xAxisKey,
    userZoom: DEFAULT_ZOOM,
  });

  const configZoomMemo = useDeepEqualValue(configZoom);
  const userZoom = useDeepEqualValue(state.userZoom);

  useEffect(() => {
    dispatch({
      type: 'panelZoom/resetUserZoom',
    });
  }, [configZoomMemo]);

  const handleUserZoom = useCallback(
    (newZoom: PartialZoom) => {
      dispatch({
        type: 'panelZoom/userZoomChange',
        payload: newZoom,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    dispatch({
      type: 'panelZoom/changeXAxisKey',
      payload: xAxisKey,
    });
  }, [xAxisKey]);

  const queryZoom = useDeepEqualValue({
    xAxisMin: state.userZoom.xAxisMin ?? configZoom.xAxisMin ?? null,
    xAxisMax: state.userZoom.xAxisMax ?? configZoom.xAxisMax ?? null,
    yAxisMin: state.userZoom.yAxisMin ?? configZoom.yAxisMin ?? null,
    yAxisMax: state.userZoom.yAxisMax ?? configZoom.yAxisMax ?? null,
  });
  return useMemo(
    () => ({
      handleUserZoom,
      queryZoom,
      userZoom,
    }),
    [handleUserZoom, queryZoom, userZoom]
  );
};
