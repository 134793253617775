import {useMemo} from 'react';

import {usePart} from '../../../../state/views/hooks';
import * as PanelTypes from '../../../../state/views/panel/types';
import {useDeepEqualValue} from '../../../../util/hooks';
import {OrganizedSettings} from '../../../PanelBank/types';
import {RunsLinePlotConfig} from '../../../PanelRunsLinePlot/types';
import {getCascadingSetting} from '../getCascadingSetting';
import {getCascadingExcludeOutlierSetting} from '../runLinePlots/utils/getCascadingExcludeOutliers';
import {useCascadingPointAggregationMethod} from '../runLinePlots/utils/getCascadingPointAggregation';
import {getCascadingShowLegend} from '../runLinePlots/utils/getCascadingShowLegend';
import {getCascadingSmoothingSettings} from '../runLinePlots/utils/getCascadingSmoothingSettings';
import {getCascadingXAxisSettings} from '../runLinePlots/utils/getCascadingXAxisSettings';
import {getCascadingYAxisSettings} from '../runLinePlots/utils/getCascadingYAxisSettings';
import {DerivedPointVisualizationOption, LinePlotSettings} from '../types';

/**
 * This hook determine if the parent cascading settings or panel setting
 * should be used. It only gets used in PanelCompRedux.
 *
 * Hierarchy: panelConfig >> (sectionSettings >> workspaceSettings)
 *
 * @param inheritedSettings either the workspace or section setting
 */
export const usePanelWithInheritedSettings = (
  panelRef: PanelTypes.Ref,
  inheritedSettings: OrganizedSettings | undefined
) => {
  const panel = usePart(panelRef);
  const isRunsLinePlot = panel.viewType === 'Run History Line Plot';

  const cascadingPointAggregationSettings = useCascadingPointAggregationMethod(
    inheritedSettings?.linePlot,
    isRunsLinePlot ? panel.config : undefined
  );

  const modifiedPanel = useMemo(() => {
    if (inheritedSettings == null) {
      return panel;
    }

    // We need to pipe through useRunsTableGroupingInPanels so we can show
    // individual runs in the run comparer
    if (panel.viewType === 'Run Comparer') {
      return {
        ...panel,
        config: {
          ...panel.config,
          // section setting doesn't exist, so inherit workspace value
          useRunsTableGroupingInPanels:
            inheritedSettings.linePlot?.useRunsTableGroupingInPanels,
        },
      };
    }

    if (isRunsLinePlot) {
      return {
        ...panel,
        config: getLinePlotPanelConfigWithInheritedSettings(
          cascadingPointAggregationSettings.pointVisualizationMethod,
          inheritedSettings.linePlot,
          panel.config
        ),
      };
    }

    return panel;
  }, [
    inheritedSettings,
    panel,
    isRunsLinePlot,
    cascadingPointAggregationSettings,
  ]);

  const deepEqualPanel = useDeepEqualValue(modifiedPanel);
  return deepEqualPanel;
};

/**
 * Modifies runs line plot panel config with inherited settings.
 * Panel settings should always take precedence over workspace and section settings.
 */
export const getLinePlotPanelConfigWithInheritedSettings = (
  inheritedPointVisualizationMethod: DerivedPointVisualizationOption,
  inheritedSettings: LinePlotSettings | undefined,
  panelConfig: RunsLinePlotConfig
) => {
  const newPanelConfig = {
    // Order matters here
    ...panelConfig, // settings that belong at panel level and cannot be updated at a higher level
    ...getInheritedPanelSettings(
      inheritedPointVisualizationMethod,
      inheritedSettings,
      panelConfig
    ),
  };

  // @ts-expect-error - ignoreOutliers is deprecated, make sure it doesn't show up downstream
  delete newPanelConfig.ignoreOutliers;
  // @ts-expect-error - suppressLegends is deprecated, make sure it doesn't show up downstream
  delete newPanelConfig.suppressLegends;

  return newPanelConfig;
};

const getInheritedPanelSettings = (
  inheritedPointVisualizationMethod: DerivedPointVisualizationOption,
  // This should be the resolved settings between workspace and section settings
  inheritedSettings: LinePlotSettings | undefined,
  panelSettings?: RunsLinePlotConfig
) => {
  return {
    // Workspace vs section settings are resolved at a higher level, so
    // pass undefined for child/section settings. For certain settings,
    // we apply different logic at panel settings like x-axis system metric panels.
    ...getCascadingXAxisSettings(inheritedSettings, undefined, panelSettings),
    ...getCascadingSmoothingSettings(
      inheritedSettings,
      undefined,
      panelSettings
    ),
    ...getCascadingYAxisSettings(inheritedSettings, undefined, panelSettings),

    // This setting don't exist at panel level, so just take the parent values
    colorRunNames: inheritedSettings?.colorRunNames,
    // This setting don't exist at panel level, so just take the parent values
    displayFullRunName: inheritedSettings?.displayFullRunName,
    excludeOutliers: getCascadingExcludeOutlierSetting(
      inheritedPointVisualizationMethod,
      inheritedSettings,
      undefined,
      panelSettings
    ),
    groupAgg: getCascadingSetting([
      inheritedSettings?.groupAgg,
      panelSettings?.groupAgg,
    ]),
    highlightedCompanionRunOnly: inheritedSettings?.highlightedCompanionRunOnly,
    limit: getCascadingSetting([
      inheritedSettings?.limit,
      panelSettings?.limit,
    ]),
    // This is calculated at a higher level, so just take the parent value
    pointVisualizationMethod: inheritedPointVisualizationMethod,
    showLegend: getCascadingSetting([
      getCascadingShowLegend({
        // @ts-expect-error - suppressLegends is deprecated
        suppressLegends: inheritedSettings?.suppressLegends,
        showLegend: inheritedSettings?.showLegend,
      }),
      panelSettings?.showLegend,
    ]),
    tooltipNumberOfRuns: inheritedSettings?.tooltipNumberOfRuns,
    useRunsTableGroupingInPanels: getCascadingSetting([
      inheritedSettings?.useRunsTableGroupingInPanels,
      panelSettings?.useRunsTableGroupingInPanels,
    ]),
  };
};
