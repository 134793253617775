import {envIsTest} from './../../../config';

/**
 * This is hacky, but we need an expedient way to be able to trigger a polling event from a test environment. The reason is that there was a bug where the registerRequest/unregisterRequest logic wasn't correctly unregistering requests, so polling would run multiple request loops and thrash the the UI. We are going to cover this with an E2E snapshot test that confirms runs aren't blinking in and out of the UI, but need a way to manually control the polling
 *
 * NOTE: NEVER ENABLE THIS IS OUTSIDE THE CI ENVIRONMENT UNLESS YOU ARE SURE YOU KNOW WHAT YOU ARE DOING.
 */
export const IS_POLL_TESTING_ENV = envIsTest;

declare global {
  interface Window {
    WANDB_BQM_POLL: () => void;
  }
}

if (IS_POLL_TESTING_ENV) {
  const event = new CustomEvent('WANDB_BQM_POLL');

  window.WANDB_BQM_POLL = () => {
    window.dispatchEvent(event);
  };
}
