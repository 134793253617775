import {isEqual} from 'lodash';

import {OrganizedSettings} from '../../PanelBank/types';
import {
  DEFAULT_SMOOTHING_SETTINGS,
  DEFAULT_X_AXIS_SETTINGS,
  xAxisLabels,
} from './runLinePlots/linePlotDefaults';
import {
  DeprecatedLinePlotSettings,
  ExcludeOutliersValues,
  LinePlotSettings,
} from './types';

export const SUPPORTED_WORKSPACE_SPEC_VERSION = 1;

const LINE_PLOT_SETTING_FIELDS = [
  'colorRunNames',
  'displayFullRunName',
  'excludeOutliers',
  'highlightedCompanionRunOnly',
  'maxRuns',
  'pointVisualizationMethod',
  'smoothingType',
  'smoothingWeight',
  'suppressLegends',
  'tooltipNumberOfRuns',
  'useRunsTableGroupingInPanels',
  'xAxis',
  'xAxisMax',
  'xAxisMin',
];
const ACTIVE_SETTING_FIELDS = [
  'localSmoothingSettingsActive',
  'localxAxisSettingsActive',
  'smoothingActive',
  'xAxisActive',
];
/**
 * This is temporary migration logic. Do not use this function further.
 */
export const getOrganizedSettings = (
  settings: Record<string, any>
): OrganizedSettings => {
  const newWorkspaceSettings: Record<string, any> = {};
  for (const [key, value] of Object.entries(settings)) {
    // Exclude `xAxisActive`, `smoothingActive`, etc
    // Also don't include the refs since they're internal values
    if (ACTIVE_SETTING_FIELDS.indexOf(key) > -1 || key === 'ref') {
      continue;
    }

    // Add line specific plot settings into a separate field
    if (LINE_PLOT_SETTING_FIELDS.indexOf(key) > -1) {
      if (!('linePlot' in newWorkspaceSettings)) {
        newWorkspaceSettings.linePlot = {};
      }
      newWorkspaceSettings.linePlot[key] = value;
      continue;
    }

    newWorkspaceSettings[key] = value;
  }
  return newWorkspaceSettings;
};

export const areExcludeOutliersSettingsActive = (
  settings: (LinePlotSettings & DeprecatedLinePlotSettings) | undefined
) => {
  if (settings?.excludeOutliers != null) {
    return true;
  }
  // account for deprecated ignoreOutliers setting
  if (settings?.ignoreOutliers != null) {
    return true;
  }
  // account for deprecated showMinMaxOnHover setting
  if (settings?.showMinMaxOnHover != null) {
    return true;
  }
  return false;
};

/**
 * @param shouldCheckDefault arg used in ReportSettings since we're still using the old poup there
 */
export const areXAxisSettingsActive = (
  settings: LinePlotSettings | undefined,
  shouldCheckDefault: boolean = false
) => {
  if (shouldCheckDefault) {
    return !isEqual(
      {
        xAxis: settings?.xAxis ?? DEFAULT_X_AXIS_SETTINGS.xAxis,
        xAxisMin: settings?.xAxisMin,
        xAxisMax: settings?.xAxisMax,
        xLogScale: settings?.xLogScale,
      },
      DEFAULT_X_AXIS_SETTINGS
    );
  }
  return areSettingsActive([
    settings?.xAxis,
    settings?.xAxisMin,
    settings?.xAxisMax,
    settings?.xLogScale,
  ]);
};

export const areYAxisSettingsActive = (
  settings: LinePlotSettings | undefined
) => {
  return areSettingsActive([
    settings?.yAxisMin,
    settings?.yAxisMax,
    settings?.yLogScale,
  ]);
};

/**
 * @param shouldCheckDefault arg used in ReportSettings since we're still using the old poup there
 */
export const areSmoothingSettingsActive = (
  settings: LinePlotSettings | undefined,
  shouldCheckDefault: boolean = false
) => {
  if (shouldCheckDefault) {
    return !isEqual(
      {
        smoothingType:
          settings?.smoothingType ?? DEFAULT_SMOOTHING_SETTINGS.smoothingType,
        smoothingWeight:
          settings?.smoothingWeight ??
          DEFAULT_SMOOTHING_SETTINGS.smoothingWeight,
      },
      DEFAULT_SMOOTHING_SETTINGS
    );
  }
  return areSettingsActive([
    settings?.smoothingType,
    settings?.smoothingWeight,
  ]);
};

export const areMaxRunsSettingsActive = (
  settings: LinePlotSettings | undefined
) => {
  // panels use a different property than workspace settings, so let's account for that
  const currMaxRuns =
    settings?.limit != null ? settings.limit : settings?.maxRuns;
  return currMaxRuns == null ? false : true;
};

export const areOutliersExcluded = (
  excludeOutliers: ExcludeOutliersValues | undefined
) => {
  return excludeOutliers === 'exclude-outliers';
};

export const arePointAggregationSettingsActive = (
  settings: LinePlotSettings | undefined
) => {
  return settings?.pointVisualizationMethod != null;
};

export const isShowLegendSettingActive = (
  settings: (LinePlotSettings & DeprecatedLinePlotSettings) | undefined
) => {
  if (settings?.showLegend != null) {
    return true;
  }
  // account for deprecated suppressLegends setting
  return settings?.suppressLegends == null ? false : true;
};

export const isColorRunNamesSettingActive = (
  settings: LinePlotSettings | undefined
) => {
  return settings?.colorRunNames != null;
};

export const isDisplayFullRunNameSettingActive = (
  settings: LinePlotSettings | undefined
) => {
  return settings?.displayFullRunName != null;
};

export const isHighlightedCompanionRunOnlySettingActive = (
  settings: LinePlotSettings | undefined
) => {
  return settings?.highlightedCompanionRunOnly != null;
};

export const isTooltipNumberOfRunsSettingActive = (
  settings: LinePlotSettings | undefined
) => {
  return settings?.tooltipNumberOfRuns != null;
};

export const isRunsTableGroupingSettingActive = (
  settings: LinePlotSettings | undefined
) => {
  return settings?.useRunsTableGroupingInPanels != null;
};

export const isGroupAggSettingActive = (
  settings: LinePlotSettings | undefined
) => {
  return settings?.groupAgg != null;
};

// We treat default settings as unmodified setting
export const isSettingActive = (
  value: string | undefined,
  defaultVal?: string
) => {
  if (value == null || value === defaultVal) {
    return false;
  }
  return true;
};

// TODO - rename "Active" terminology to "Defined" or "Modified"
export const areSettingsActive = (settings: any[]) => {
  return settings.some(setting => isSettingActive(setting));
};

export function xAxisLabel(key: string): string {
  const label = xAxisLabels[key];
  return label || key;
}
