import {
  Button,
  ButtonSizes,
  ButtonVariants,
} from '@wandb/weave/components/Button';
import React from 'react';

import {SettingLevel} from '../../../../services/analytics/workspaceSettingsEvents';

export type ResetSettingButtonProps = {
  className?: string;
  isEnabled: boolean;
  settingLevel: SettingLevel;
  onButtonClick: () => void;
};

export const ResetSettingButton = ({
  className,
  isEnabled,
  settingLevel,
  onButtonClick,
}: ResetSettingButtonProps) => {
  if (settingLevel === 'workspace') {
    return null;
  }

  if (!isEnabled) {
    return <div className="h-32 w-32" />;
  }

  return (
    <Button
      className={className}
      aria-label="Reset to parent"
      disabled={!isEnabled}
      icon="randomize-reset-reload"
      onClick={onButtonClick}
      size={ButtonSizes.Medium}
      tooltip="Reset to parent"
      variant={ButtonVariants.Ghost}
    />
  );
};
