import {
  AccountSelectorContextProviderEntityQuery,
  OrganizationSubscriptionBasicInfoByNameQuery,
  OrganizationSubscriptionType,
} from '../../../generated/graphql';
import {getAccountType} from '../../../pages/Billing/AccountSettings/util';
import {PlanName} from '../../../pages/Billing/util';
import {Viewer} from '../../../state/viewer/types';
import {UserInfo} from '../../../types/graphql';
import {getPrimarySub} from '../../../util/accounts/pricing';
import {
  AccountSelectorStorageKeys,
  safelyParseAccountJson,
} from './AccountSelectorContextProvider';
import {Account, AccountType} from './types';

export type AccountSelectorViewer = Pick<
  Viewer,
  'id' | 'username' | 'teams' | 'defaultEntity'
>;

function getDefaultAccount(
  viewer: AccountSelectorViewer,
  accountList: Account[]
): Account | null {
  const defaultEntityOrganization = viewer.teams.edges.find(
    team => team.node.id === viewer.defaultEntity?.id
  );
  const account = accountList.find(
    o => o.id === defaultEntityOrganization?.node.organizationId
  );
  const isPersonalEntity =
    defaultEntityOrganization?.node.name === viewer?.username;

  let personalPaidAccount = null;
  if (account == null) {
    personalPaidAccount = accountList.find(
      accountItem =>
        accountItem?.accountType === AccountType.Personal &&
        accountItem.personalOrgId != null
    );
  }
  return account
    ? {
        name: account?.name ?? '',
        id: account?.id ?? '',
        accountType: isPersonalEntity
          ? AccountType.Personal
          : account?.accountType,
        isEnterprise: account?.isEnterprise,
        memberCount: account?.memberCount,
        username: account?.username,
      }
    : personalPaidAccount
    ? personalPaidAccount
    : {
        name: viewer?.username ?? '',
        id: viewer?.id ?? '',
        accountType: AccountType.Personal,
        username: viewer?.username ?? '',
      };
}

export function getAccountFromStorage(
  storage: {getItem: (key: string) => string | null},
  key: AccountSelectorStorageKeys,
  accountList: Account[]
) {
  const storageItem = storage.getItem(key);
  const account =
    storageItem != null
      ? safelyParseAccountJson(storageItem, accountList)
      : null;
  if (account != null) {
    const matchingAccount = accountList.find(
      accountListItem => account.id === accountListItem.id
    );
    if (matchingAccount != null) {
      return matchingAccount;
    }
  }
  return account;
}

export type GetSelectedAccountParams = {
  selectedAccount: Account | null;
  lastSelectedAccount: Account | null;
  viewer: AccountSelectorViewer;
  accountList: Account[];
  userInfo: Pick<UserInfo, 'isPersonalEntityHidden'>;
  orgName: string | null | undefined;
  entityName: string | null | undefined;
  entityQuery: AccountSelectorContextProviderEntityQuery | undefined;
  orgQuery: OrganizationSubscriptionBasicInfoByNameQuery | undefined;
  isAdminMode: boolean;
  isProfilePath: boolean;
};

function getSelectedAccountFromURL(
  {
    viewer,
    accountList,
    userInfo,
    orgName,
    entityName,
    entityQuery,
    orgQuery,
    isAdminMode,
    selectedAccount,
    isProfilePath,
  }: GetSelectedAccountParams,
  defaultAccount: Account | null
): Account | null {
  const orgID = orgQuery?.organization?.id;
  const orgAccount = orgID ? accountList.find(o => o.id === orgID) : null;
  if (orgAccount != null) {
    return orgAccount;
  }

  const isViewersPersonalEntity =
    orgAccount == null &&
    viewer?.username != null &&
    (entityName === viewer?.username || orgName === viewer?.username);

  const shouldHidePersonalEntity = userInfo?.isPersonalEntityHidden ?? false;

  // This is the personal account, so switch it
  if (
    isViewersPersonalEntity &&
    (!isProfilePath || selectedAccount == null) // If we're on the profile page, we don't want to switch to the personal account unless it hasn't been initialized yet
  ) {
    // If this is the personal account (and not the profile page)
    const personalAccount = accountList.find(
      account => account?.accountType === AccountType.Personal
    );
    if (personalAccount && !shouldHidePersonalEntity) {
      return personalAccount;
    }
  }
  const orgNameFromCurrentUrl =
    orgName ?? entityQuery?.entity?.organization?.name;
  const orgExists =
    orgNameFromCurrentUrl != null && orgQuery?.organization != null;
  // If there's an org in the URL, and it's not the personal account org
  if (orgAccount == null && !isViewersPersonalEntity && orgExists) {
    // We are viewing an org that we're not a member of, so create a dummy account for the account selector
    const organization = orgQuery?.organization ?? undefined;
    const primarySubType = getPrimarySub(organization)?.subscriptionType;
    const primarySubPlan = getPrimarySub(organization)?.plan?.name;
    return {
      name: orgNameFromCurrentUrl ?? '',
      accountType: getAccountType(organization!),
      id: orgQuery?.organization?.id,
      username: viewer?.username ?? '',
      memberCount: orgQuery?.organization?.memberCount,
      isEnterprise:
        primarySubPlan === PlanName.Enterprise &&
        primarySubType !== OrganizationSubscriptionType.Academic &&
        primarySubType !== OrganizationSubscriptionType.AcademicTrial,
      isDummyAccount: true,
    };
  }

  // If there's a personal entity in the URL, but it's not ours, and we're in admin mode, then switch
  // to the personal entity as a dummy account. UNLESS it's on the profile path, in which case, we're just
  // viewing their profile (not as them).
  const isPersonalEntity =
    entityQuery != null && entityQuery.entity?.isTeam === false;
  if (
    orgAccount == null &&
    !isViewersPersonalEntity &&
    !orgExists &&
    isPersonalEntity &&
    isAdminMode
  ) {
    return {
      name: entityName ?? '',
      accountType: AccountType.Personal,
      id: entityQuery?.entity?.members?.[0]?.id ?? '', // There's only one member in a personal entity, so just use their id
      username: viewer?.username ?? '',
      isDummyAccount: true,
    };
  }

  const orgOrEntityInUrl = orgName != null || entityName != null;
  // If there's no entity or org in the URL, but selected account was previously selected to be a dummy account,
  // we should switch back to the default account (the last selected account may also be a dummy account somehow).
  // This could happen if in admin mode viewing an org's page, then clicking the home button for example.
  if (!orgOrEntityInUrl && selectedAccount?.isDummyAccount === true) {
    return defaultAccount;
  }
  return null;
}

function validateSelectedAccount(
  accountList: Account[],
  selectedAccount: Account | null,
  isAdminMode: boolean
) {
  // If user has a selected account that does not belong to the user
  // and user is not impersonating, then invalidate the selected account and last selected account by setting it to null.
  // This could happen if the user is kicked from their org but the account is still selected in session storage for example
  const isAccountListMissingSelectedAccount =
    accountList.every(a => a.name !== selectedAccount?.name) &&
    accountList.length > 0;

  const isValidDummyAccount =
    selectedAccount?.isDummyAccount === true && isAdminMode; // Dummy accounts don't appear in the account list, but shouldn't be invalidated if they're currently in admin mode

  return selectedAccount &&
    isValidDummyAccount &&
    isAccountListMissingSelectedAccount
    ? null
    : selectedAccount;
}

export function getSelectedAccount(
  params: GetSelectedAccountParams
): Account | null {
  const {
    viewer,
    accountList,
    orgName,
    entityQuery,
    isAdminMode,
    selectedAccount: selectedAccountFromSessionStorage,
    lastSelectedAccount: lastSelectedAccountFromLocalStorage,
  } = params;
  const defaultAccount = getDefaultAccount(viewer, accountList);

  const selectedAccount = validateSelectedAccount(
    accountList,
    selectedAccountFromSessionStorage,
    isAdminMode
  );
  const lastSelectedAccount = validateSelectedAccount(
    accountList,
    lastSelectedAccountFromLocalStorage,
    isAdminMode
  );

  // The organization associated with the orgName in the URL gets precedence over the
  // entity's org (in the event that they don't match).
  const orgNameFromCurrentUrl =
    orgName ?? entityQuery?.entity?.organization?.name;
  const selectedAccountFromURL =
    selectedAccount?.name !== orgNameFromCurrentUrl || selectedAccount == null
      ? getSelectedAccountFromURL(params, defaultAccount)
      : null;
  return (
    selectedAccountFromURL ??
    selectedAccount ??
    lastSelectedAccount ??
    defaultAccount
  );
}

export function getCanSeeOrgInURL({
  orgQuery,
  entityQuery,
  orgName,
  entityName,
  viewer,
}: GetSelectedAccountParams) {
  // If there's an org in the URL, but no organization is returned, this means it's an org
  // that doesn't exist or we don't have permissions to see
  const orgOrEntityInUrl = orgName != null || entityName != null;
  const cannotSeeOrgFromUrl =
    orgOrEntityInUrl &&
    orgQuery?.organization == null &&
    entityQuery?.entity?.organization == null;
  const isViewersPersonalEntity =
    viewer.username != null &&
    (entityName === viewer.username || orgName === viewer.username);
  return isViewersPersonalEntity || !cannotSeeOrgFromUrl;
}
