import React, {createContext, useContext, useMemo} from 'react';

import {
  UseHistoryKeysQueryResult,
  VizMap,
} from '../../state/graphql/historyKeysQuery';
import {
  useKeyInfoQuery,
  useMultiRunsetKeyInfoQuery,
} from '../../state/runs/hooks';
import * as RunSetTypes from '../../state/views/runSet/types';
import {RunHistoryKeyInfo} from '../../types/run';
import {useRampFlagMultiRunsetHistoryKeys} from '../../util/rampFeatureFlags';
import * as RunHelpers from '../../util/runhelpers';

export type KeyInfoQueryResult = {
  loading: boolean;
  error: true | null;
  historyKeyInfo: RunHistoryKeyInfo & {sortedKeys: string[]};
  viz: VizMap;
};

type KeyInfoQueryContext = {
  keyInfoQuery: KeyInfoQueryResult;
  uniqueKeyTypes?: Set<string>;
};

function createKeyInfoQueryContext() {
  return createContext<KeyInfoQueryContext | undefined>(undefined);
}

function createKeyInfoQueryContextProvider(
  useKeyInfoQueryResult: (runSetRefs: RunSetTypes.Ref[]) => KeyInfoQueryResult,
  Context: React.Context<KeyInfoQueryContext | undefined>
) {
  return function KeyInfoQueryContextProvider({
    children,
    runSetRefs,
  }: {
    children: React.ReactNode;
    runSetRefs: RunSetTypes.Ref[];
  }) {
    const keyInfoQueryResult = useKeyInfoQueryResult(runSetRefs);
    const uniqueKeyTypes = useMemo(
      () => makeUniqueKeyTypes(keyInfoQueryResult),
      [keyInfoQueryResult]
    );

    const value = useMemo(
      () => ({
        keyInfoQuery: keyInfoQueryResult,
        uniqueKeyTypes,
      }),
      [keyInfoQueryResult, uniqueKeyTypes]
    );

    return <Context.Provider value={value}>{children}</Context.Provider>;
  };
}

const KeyInfoQueryContext = createKeyInfoQueryContext();

const KeyInfoQueryContextProviderComponent = createKeyInfoQueryContextProvider(
  (runSetRefs: RunSetTypes.Ref[]) =>
    useKeyInfoQueryResult(runSetRefs, useKeyInfoQuery),
  KeyInfoQueryContext
);

const MultiRunSetKeyInfoQueryContextProviderComponent =
  createKeyInfoQueryContextProvider(
    (runSetRefs: RunSetTypes.Ref[]) =>
      useKeyInfoQueryResult(runSetRefs, useMultiRunsetKeyInfoQuery),
    KeyInfoQueryContext
  );

export const KeyInfoQueryContextProvider = ({
  children,
  runSetRefs,
  entityName,
  allowMultiRunsetHistoryKeys: allowMultiRunsetHistoryKeys = false,
}: {
  children: React.ReactNode;
  runSetRefs: RunSetTypes.Ref[];
  entityName: string;
  allowMultiRunsetHistoryKeys?: boolean;
}) => {
  const multiRunsetHistoryKeysEnabled =
    useRampFlagMultiRunsetHistoryKeys(entityName);
  if (multiRunsetHistoryKeysEnabled && allowMultiRunsetHistoryKeys) {
    return (
      <MultiRunSetKeyInfoQueryContextProviderComponent runSetRefs={runSetRefs}>
        {children}
      </MultiRunSetKeyInfoQueryContextProviderComponent>
    );
  }
  return (
    <KeyInfoQueryContextProviderComponent runSetRefs={runSetRefs}>
      {children}
    </KeyInfoQueryContextProviderComponent>
  );
};

export const useKeyInfoQueryContext = () => {
  const context = useContext(KeyInfoQueryContext);

  if (!context) {
    throw new Error(
      'useKeyInfoQueryContext must be used within a KeyInfoQueryContextProvider'
    );
  }

  return context;
};

export function useMetricsIncludeHistogram(metrics: string[]) {
  const {keyInfoQuery} = useKeyInfoQueryContext();

  return metrics.some(metric => {
    const typeCounts =
      keyInfoQuery.historyKeyInfo.keys[metric]?.typeCounts?.flatMap(
        t => t.type
      ) ?? [];
    return typeCounts.some(type => type === 'histogram');
  });
}

function makeUniqueKeyTypes(
  keyInfoQuery: KeyInfoQueryResult
): Set<string> | undefined {
  if (keyInfoQuery.loading || keyInfoQuery.error != null) {
    return undefined;
  }

  const {historyKeyInfo} = keyInfoQuery;
  if (historyKeyInfo == null) {
    return undefined;
  }

  const keyTypes = RunHelpers.keyTypes(historyKeyInfo.keys);
  if (keyTypes == null) {
    return undefined;
  }
  return new Set<string>(Object.values(keyTypes) as string[]);
}

export const useKeyInfoQueryResult = (
  runSetRefs: RunSetTypes.Ref[],
  keyInfoQueryFunc: (runSetRefs: RunSetTypes.Ref[]) => UseHistoryKeysQueryResult
): KeyInfoQueryResult => {
  const keyInfoQuery = keyInfoQueryFunc(runSetRefs);
  const isKeyInfoLoading = keyInfoQuery.loading;
  const keyInfoQueryError = keyInfoQuery.error;

  const historyKeyInfo = useMemo(() => {
    if (isKeyInfoLoading || keyInfoQueryError != null) {
      return {sets: [], keys: {}, sortedKeys: []};
    }
    return keyInfoQuery.historyKeyInfo;
  }, [isKeyInfoLoading, keyInfoQuery, keyInfoQueryError]);
  const viz = useMemo(() => {
    if (isKeyInfoLoading || keyInfoQueryError != null) {
      return {};
    }
    return keyInfoQuery.viz;
  }, [isKeyInfoLoading, keyInfoQuery, keyInfoQueryError]);

  return useMemo(() => {
    return {
      loading: isKeyInfoLoading,
      error: keyInfoQueryError,
      historyKeyInfo,
      viz,
    };
  }, [historyKeyInfo, viz, isKeyInfoLoading, keyInfoQueryError]);
};
