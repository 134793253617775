import React, {FC, useEffect} from 'react';

import {useCacheIfEnabled} from './util/cache';
import {markCacheDisabled, markCacheEnabled} from './util/cache/util';

export const LongTermCacheProvider: FC = ({children}) => {
  const cache = useCacheIfEnabled();

  useEffect(() => {
    if (cache) {
      markCacheEnabled();
    } else {
      markCacheDisabled();
    }
  }, [cache]);

  return <>{children}</>;
};
