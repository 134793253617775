type NameAndRegex = {
  prettyName: string;
  regex: RegExp;
};

const appleRegex: NameAndRegex[] = [
  {
    prettyName: 'Apple eCPU Utilization (%)',
    regex: /system\/cpu\.ecpu_percent/,
  },
  {
    prettyName: 'Apple eCPU Frequency (MHz)',
    regex: /system\/cpu\.ecpu_freq/,
  },
  {
    prettyName: 'Apple pCPU Utilization (%)',
    regex: /system\/cpu\.pcpu_percent/,
  },
  {
    prettyName: 'Apple pCPU Frequency (MHz)',
    regex: /system\/cpu\.pcpu_freq/,
  },
  {
    prettyName: 'Average CPU Temperature (\u2103)',
    regex: /system\/cpu\.avg_temp/,
  },
  {
    prettyName: 'Swap Memory Used (Bytes)',
    regex: /system\/swap\.used(\/l:.+)?$/,
  },
  {
    prettyName: 'Swap Memory Used (%)',
    regex: /system\/swap\.used_percent(\/l:.+)?$/,
  },
  {
    prettyName: 'RAM Used (Bytes)',
    regex: /system\/memory\.used(\/l:.+)?$/,
  },
  {
    prettyName: 'RAM Used (%)',
    regex: /system\/memory\.used_percent(\/l:.+)?$/,
  },
  {
    prettyName: 'Apple Neural Engine Power (W)',
    regex: /system\/ane\.power/,
  },
  {
    prettyName: 'CPU Power (W)',
    regex: /system\/cpu\.powerWatts/,
  },
  {
    prettyName: 'System Power (W)',
    regex: /system\/system\.powerWatts/,
  },
];

const trainiumRegex: NameAndRegex[] = [
  {
    prettyName: 'Trainium Neuron Core $1 Utilization (%)',
    regex: /system\/trn\.((\d)+)\.neuroncore_utilization/,
  },
  {
    prettyName: 'Trainium Host Memory Usage, total (bytes)',
    regex: /system\/trn\.host_total_memory_usage(\/l:.+)?$/,
  },
  {
    prettyName: 'Trainium Neuron Device Total Memory Usage (bytes)',
    regex: /system\/trn\.neuron_device_total_memory_usage/,
  },
  {
    prettyName: 'Trainium Host Memory Usage, application memory (bytes)',
    regex: /system\/trn\.host_total_memory_usage\.application_memory/,
  },
  {
    prettyName: 'Trainium Host Memory Usage, constants (bytes)',
    regex: /system\/trn\.host_total_memory_usage\.constants/,
  },
  {
    prettyName: 'Trainium Host Memory Usage, DMA buffers (bytes)',
    regex: /system\/trn\.host_total_memory_usage\.dma_buffers/,
  },
  {
    prettyName: 'Trainium Host Memory Usage, tensors (bytes)',
    regex: /system\/trn\.host_total_memory_usage\.tensors/,
  },
  {
    prettyName: 'Trainium Neuron Core $1 Memory Usage, constants (bytes)',
    regex: /system\/trn\.((\d)+)\.neuroncore_memory_usage\.constants/,
  },
  {
    prettyName: 'Trainium Neuron Core $1 Memory Usage, model code (bytes)',
    regex: /system\/trn\.((\d)+)\.neuroncore_memory_usage\.model_code/,
  },
  {
    prettyName:
      'Trainium Neuron Core $1 Memory Usage, model shared scratchpad (bytes)',
    regex:
      /system\/trn\.((\d)+)\.neuroncore_memory_usage\.model_shared_scratchpad/,
  },
  {
    prettyName: 'Trainium Neuron Core $1 Memory Usage, runtime memory (bytes)',
    regex: /system\/trn\.((\d)+)\.neuroncore_memory_usage\.runtime_memory/,
  },
  {
    prettyName: 'Trainium Neuron Core $1 Memory Usage, tensors (bytes)',
    regex: /system\/trn\.((\d)+)\.neuroncore_memory_usage\.tensors/,
  },
];

const IPURegex: NameAndRegex[] = [
  {
    prettyName: 'IPU $1 Avg Board Temp (\u2103)',
    regex: /system\.ipu\.((\d)+)\.average board temp.*/,
  },
  {
    prettyName: 'IPU $1 Avg Die Temp (\u2103)',
    regex: /system\.ipu\.((\d)+)\.average die temp.*/,
  },
  {
    prettyName: 'IPU $1 Clock (MHz)',
    regex: /system\/ipu\.((\d)+)\.clock/,
  },
  {
    prettyName: 'IPU $1 Power (W)',
    regex: /system\/ipu\.((\d)+)\.power/,
  },
  {
    prettyName: 'IPU $1 Utilization (%)',
    regex: /system\/ipu\.((\d)+)\.ipu utilisation \(%\)(\/l:.+)?$/,
  },
  {
    prettyName: 'IPU $1 Utilization (session) (%)',
    regex: /system\/ipu\.((\d)+)\.ipu utilisation \(session\)/,
  },
];

const GPURegex: NameAndRegex[] = [
  {
    prettyName: 'GPU $1 Utilization (%)',
    regex: /system\/gpu\.((\d)+)\.gpu/,
  },
  {
    prettyName: 'GPU $1 Temperature (\u2103)',
    regex: /system\/gpu\.(\d+)\.temp/,
  },
  {
    prettyName: 'GPU $1 Frequency (MHz)',
    regex: /system\/gpu\.(\d+)\.freq/,
  },
  {
    prettyName: 'GPU $1 Time Spent Accessing Memory (%)',
    regex: /system\/gpu\.((\d)+)\.memory(\/l:.+)?$/,
  },
  {
    prettyName: 'GPU $1 Memory Allocated (%)',
    regex: /system\/gpu\.((\d)+)\.memory_?[aA]llocated/,
  },
  {
    prettyName: 'GPU $1 Power Usage (%)',
    regex: /system\/gpu\.((\d)+)\.powerPercent/,
  },
  {
    prettyName: 'GPU $1 Power Usage (W)',
    regex: /system\/gpu\.((\d)+)\.powerWatts/,
  },
  {
    prettyName: 'Process GPU $1 Utilization (%)',
    regex: /system\/gpu\.process\.((\d)+)\.gpu/,
  },
  {
    prettyName: 'Process GPU $1 Temp (℃)',
    regex: /system\/gpu\.process\.((\d)+)\.temp/,
  },
  {
    prettyName: 'Process GPU $1 Time Spent Accessing Memory (%)',
    regex: /system\/gpu\.process\.((\d)+)\.memory(\/l:.+)?$/,
  },
  {
    prettyName: 'Process GPU $1 Memory Allocated (%)',
    regex: /system\/gpu\.process\.((\d)+)\.memory_?[aA]llocated/,
  },
  {
    prettyName: 'Process GPU $1 Power Usage (%)',
    regex: /system\/gpu\.process\.((\d)+)\.powerPercent/,
  },
  {
    prettyName: 'Process GPU $1 Power Usage (W)',
    regex: /system\/gpu\.process\.((\d)+)\.powerWatts/,
  },
];

const TPURegex: NameAndRegex[] = [
  {
    prettyName: 'TPU $1 Duty Cycle (%)',
    regex: /system\/tpu\.(\d+)\.dutyCycle/,
  },
  {
    prettyName: 'TPU $1 Memory Usage (%)',
    regex: /system\/tpu\.(\d+)\.memory_?[uU]sage(\/l:.+)?$/,
  },
  {
    prettyName: 'TPU $1 Memory Usage (bytes)',
    regex: /system\/tpu\.(\d+)\.memory_?[uU]sage_?[bB]ytes/,
  },
];

const diskRegex: NameAndRegex[] = [
  {
    prettyName: 'Disk Utilization (%)',
    regex: /system\/disk(\/l:.+)?$/,
  },
  {
    prettyName: '$1 Utilization (%)',
    regex: /system\/disk\.((.)+)\.usagePercent/,
  },
  {
    prettyName: '$1 Utilization (GB)',
    regex: /system\/disk\.((.)+)\.usageGB/,
  },
  {
    prettyName: 'MB written to disk',
    regex: /system\/disk\.out/,
  },
  {
    prettyName: 'MB read from disk',
    regex: /system\/disk\.in/,
  },
];

const mainRegex: NameAndRegex[] = [
  {
    prettyName: 'Process CPU Utilization (%)',
    regex: /system\/cpu(\/l:.+)?$/,
  },
  {
    prettyName: 'Process Memory Utilization (%)',
    regex: /system\/proc\.memory\.percent(\/l:.+)?/,
  },
  {
    prettyName: 'Process Memory Available (MB)',
    regex: /system\/proc\.memory\.availableMB(\/l:.+)?/,
  },
  {
    prettyName: 'Process Memory In Use (MB)',
    regex: /system\/proc\.memory\.rssMB(\/l:.+)?/,
  },
  {
    prettyName: 'Process CPU Threads In Use',
    regex: /system\/proc\.cpu\.threads(\/l:.+)?/,
  },
  {
    prettyName: 'System CPU $1 Utilization (%)',
    regex: /system\/cpu\.(\d+)\.cpu_percent(?:\/l:(.+))?/,
  },
  {
    prettyName: 'System Memory Utilization (%)',
    regex: /system\/memory/,
  },
  {
    prettyName: 'Network Traffic Sent (bytes)',
    regex: /system\/network\.sent/,
  },
  {
    prettyName: 'Network Traffic Received (bytes)',
    regex: /system\/network\.recv/,
  },
];

type NameAndRegexGroup = {
  regex?: RegExp;
  detailedRegexes: NameAndRegex[];
};

const prettyMetricReplacements: NameAndRegexGroup[] = [
  {
    regex: /trn/,
    detailedRegexes: trainiumRegex,
  },
  {
    regex: /ipu/,
    detailedRegexes: IPURegex,
  },
  {
    regex: /gpu/,
    detailedRegexes: GPURegex,
  },
  {
    regex: /tpu/,
    detailedRegexes: TPURegex,
  },
  {
    regex: /disk/,
    detailedRegexes: diskRegex,
  },
  {
    detailedRegexes: appleRegex,
  },
  {
    // default to running these
    detailedRegexes: mainRegex,
  },
];

const sysRegex = /system/;

export function prettifyMetricName(originalMetricName: string): string {
  // if not system metric, return early
  if (!sysRegex.test(originalMetricName)) {
    return originalMetricName;
  }

  for (const {regex, detailedRegexes} of prettyMetricReplacements) {
    if (!regex || regex.test(originalMetricName)) {
      for (const {prettyName, regex: subRegex} of detailedRegexes) {
        const match = originalMetricName.match(subRegex);
        if (match == null) {
          continue;
        }

        let result = prettyName;

        // The label will only be present if /l: exists in the string
        const labelMatch = originalMetricName.match(/\/l:(.+)$/);
        const prefix = labelMatch ? `[${labelMatch[1]}] ` : '';

        if (match.length <= 1) {
          return prefix + result;
        }

        // Handle disk metrics that start with $1
        if (prettyName.startsWith('$1 Utilization')) {
          result = result.replace('$1', match[1]).replace(/\\/g, '/');
          return prefix + result;
        }

        // Handle all other metrics
        result = result.replace('$1', match[1]);
        return prefix + result;
      }
    }
  }

  return originalMetricName;
}
