import fnv from 'fnv-plus';

export function getEntityNameFromPath(): string {
  return window.location.pathname.split('/')[1];
}

let cacheEnabled = false;

export const markCacheEnabled = () => {
  cacheEnabled = true;
};

export const markCacheDisabled = () => {
  cacheEnabled = false;
};

export const isCacheEnabled = () => {
  return cacheEnabled;
};

export function getCacheStorageKey(namespace: string, key: any): string | null {
  if (key == null) {
    return null;
  }
  const stringified = JSON.stringify(key);

  // fnv.fast1a64 returns a hex string, which is always a valid file name.
  // See https://github.com/tjwebb/fnv-plus#fnvfast1a64string
  const hash = fnv.fast1a64(`${namespace}--${stringified}`);

  return `${hash}.json`;
}
