import {useElements} from '@stripe/react-stripe-js';
import {WBCheckbox} from '@wandb/ui';
import {TargetBlank} from '@wandb/weave/common/util/links';
import {Button} from '@wandb/weave/components/Button/Button';
import React, {useCallback, useContext, useEffect, useState} from 'react';
import {toast} from 'react-toastify';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Modal} from 'semantic-ui-react';

import {Analytics} from '../../../services/analytics';
import {
  CheckoutPlan,
  confirmPayment,
  getPrimarySub,
  getStorageSub,
  isPrimaryStripePlanInfoWithUnitPrice,
  isTeamsPrimaryPlan,
  Org,
  PrimaryStripePlanInfoWithUnitPrice,
  StripeElementsComp,
  trySubscriptionUpdateWithAnalyticsErrorHandling,
  useGetSubscriptionPlanByName,
} from '../../../util/accounts/pricing';
import {useRampProPricingPlan} from '../../../util/rampFeatureFlags';
import {privacyPolicy, termsOfService} from '../../../util/urls';
import {PlanName} from '../util';
import {
  Coupon,
  OrganizationSubscriptionType,
  useCreateHourOverageSubscriptionMutation,
  useCreateStorageSubscriptionMutation,
  useUpdateCustomerDefaultPaymentMethodMutation,
  useUpgradeCustomerSubscriptionMutation,
  useUpgradeCustomerToProPlanMutation,
  WeaveIngestionLimitInMb,
} from './../../../generated/graphql';
import {captureError} from './../../../integrations';
import {
  doNotRetryContext,
  extractErrorMessageFromApolloError,
  propagateErrorsContext,
} from './../../../util/errors';
import {BillingAddressContextProvider} from './BillingAddressContext';
import {
  NewCheckoutModalContext,
  NewCheckoutModalUpdaterContext,
} from './NewCheckoutModalContext';
import {NewStripeForm, SubmitParams} from './NewStripeForm';
import * as S from './TeamsPlanCheckoutModal.styles';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {
  PlanSeatPanel,
  ProPlanDescription,
  StruckPlanSeatControl,
  TeamsPlanDescription,
} from './TeamsPlanTab';
import {handleUpgradeSubError} from './util';

type TeamsCheckoutModalContextState = {
  onCloseWhenNotSubmitting: () => void;
  org: Org;
  monthlyPlan: PrimaryStripePlanInfoWithUnitPrice;
  yearlyPlan: PrimaryStripePlanInfoWithUnitPrice;

  submitting: boolean;
  setSubmitting: (submitting: boolean) => void;

  selectedPlan: PrimaryStripePlanInfoWithUnitPrice;
  setSelectedPlan: (plan: PrimaryStripePlanInfoWithUnitPrice) => void;

  isAlreadyTeamsMonthly: boolean;

  seats: number;
  minSeats: number;
  setSeats: (seats: number) => void;
  coupon?: Coupon | null;
};
export const TeamsCheckoutModalContext =
  React.createContext<TeamsCheckoutModalContextState | null>(null);

export const TeamsPlanCheckoutModal = (props: {
  org: Org;
  onTransactionCompleted: () => void;
  onClose: () => void;
  coupon?: Coupon | null;
}) => {
  const {org, onClose, coupon} = props;

  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [paymentParams, setPaymentParams] = useState<SubmitParams | null>(null);
  const [submitting, setSubmitting] = useState(false);

  const {loading, plans} = useGetSubscriptionPlanByName();

  const monthlyTeamPlan = plans[PlanName.MonthlyTeams2023];
  const yearlyTeamPlan = plans[PlanName.YearlyTeams2023];
  const proYearlyPlan = plans[PlanName.Pro];

  const proPlanInsteadOfTeamsPlan = useRampProPricingPlan();

  // Do annual teams or pro-pricing
  const initialSelectedPlan = proPlanInsteadOfTeamsPlan
    ? proYearlyPlan
    : yearlyTeamPlan;
  const [selectedPlan, setSelectedPlan] = useState(initialSelectedPlan);

  let currentSubSeats = org.usedSeats;
  const currentPrimarySub = getPrimarySub(org);
  if (
    currentPrimarySub != null &&
    currentPrimarySub.subscriptionType === OrganizationSubscriptionType.Stripe
  ) {
    currentSubSeats = currentPrimarySub.seats ?? currentSubSeats;
  }
  const minSeats = Math.max(currentSubSeats, 1);
  const [seats, setSeats] = useState(currentSubSeats);

  const upgradeSubscriptionToPro = useUpgradeSubscriptionToProPlan();
  const upgradeSubscriptionToTeams = useUpgradeSubscription();

  const upgradeSubscription = proPlanInsteadOfTeamsPlan
    ? upgradeSubscriptionToPro
    : upgradeSubscriptionToTeams;

  const onCloseWhenNotSubmitting = useCallback(() => {
    if (!submitting) {
      onClose();
    }
  }, [submitting, onClose]);

  const onSecondaryCloseWhenNotSubmitting = useCallback(() => {
    if (!submitting) {
      setIsConfirmationModalOpen(false);
    }
  }, [submitting, setIsConfirmationModalOpen]);

  useEffect(() => {
    Analytics.track(
      `${
        proPlanInsteadOfTeamsPlan ? 'Teams Plan' : 'Pro plan'
      } Checkout Modal Opened`,
      {
        location: `${
          proPlanInsteadOfTeamsPlan ? 'new teams plan' : 'new pro plan'
        } checkout modal comp`,
        organizationName: org.name,
        nextPlans: `${selectedPlan?.displayName}`,
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (loading) {
    return null;
  }

  if (
    monthlyTeamPlan == null ||
    !isPrimaryStripePlanInfoWithUnitPrice(monthlyTeamPlan) ||
    yearlyTeamPlan == null ||
    !isPrimaryStripePlanInfoWithUnitPrice(yearlyTeamPlan) ||
    selectedPlan == null ||
    !isPrimaryStripePlanInfoWithUnitPrice(selectedPlan)
  ) {
    captureError(
      'Plans Data Not Passed for Checkout Modal',
      'NewStandardPlanCheckoutModal'
    );
    toast(
      'Something went wrong while ugprading to a higher tier plan. Please reach out to support@wandb.com.'
    );
    return null;
  }

  const isAlreadyTeamsMonthly =
    getPrimarySub(org)?.plan?.name === PlanName.MonthlyTeams2023;

  const performUpgrade = async (
    finalParams: SubmitParams,
    finalOrg: Org,
    finalSelectedPlan: PrimaryStripePlanInfoWithUnitPrice,
    finalSeats: number
  ) => {
    const success = await upgradeSubscription(
      finalParams,
      finalSelectedPlan,
      finalSeats,
      finalOrg
    );
    if (!success) {
      return false;
    }
    props.onTransactionCompleted();
    return true;
  };

  const primarySubmission = async (params: SubmitParams) => {
    if (selectedPlan === monthlyTeamPlan) {
      setPaymentParams(params);
      setIsConfirmationModalOpen(true);
      return false;
    }
    return await performUpgrade(params, org, selectedPlan, seats);
  };

  const secondarySubmission = async (
    plan: PrimaryStripePlanInfoWithUnitPrice
  ) => {
    if (paymentParams == null) {
      return false;
    }

    const result = await performUpgrade(paymentParams, org, plan, seats);
    if (!result) {
      setIsConfirmationModalOpen(false);
      setSubmitting(false);
      setPaymentParams(null);
    }
    return result;
  };

  const context: TeamsCheckoutModalContextState = {
    onCloseWhenNotSubmitting,
    org,
    monthlyPlan: monthlyTeamPlan,
    yearlyPlan: yearlyTeamPlan,

    submitting,
    setSubmitting,

    selectedPlan,
    setSelectedPlan,

    isAlreadyTeamsMonthly,

    seats,
    minSeats,
    setSeats,
    coupon,
  };

  return (
    <>
      <StripeElementsComp>
        <TeamsCheckoutModalContext.Provider value={context}>
          <Modal
            open={true}
            basic
            className="checkout-modal"
            closeOnDimmerClick={true}
            onClose={onCloseWhenNotSubmitting}>
            <TeamsPlanModalForm submitForm={primarySubmission} />
          </Modal>
          {isConfirmationModalOpen && (
            <Modal
              open={isConfirmationModalOpen}
              basic
              closeOnDimmerClick={true}
              onClose={onSecondaryCloseWhenNotSubmitting}
              size="tiny">
              <TeamsPlanSecondaryForm
                submitForm={secondarySubmission}
                organization={org}
              />
            </Modal>
          )}
        </TeamsCheckoutModalContext.Provider>
      </StripeElementsComp>
    </>
  );
};

type TeamsPlanSecondaryFormProps = {
  submitForm: (plan: PrimaryStripePlanInfoWithUnitPrice) => void;
  organization: Org;
};

const TeamsPlanSecondaryForm = ({
  submitForm,
  organization,
}: TeamsPlanSecondaryFormProps) => {
  const contextValue = useContext(TeamsCheckoutModalContext);
  if (contextValue == null) {
    return null;
  }
  const {monthlyPlan, yearlyPlan, submitting, setSubmitting} = contextValue;
  const showCTA = !submitting;
  return (
    <S.SecondaryContainer>
      <S.SecondaryFormMainContainer>
        <S.Title>Get 2 months free with annual plan</S.Title>
        <S.Subtitle>Compare your savings.</S.Subtitle>
        <StruckPlanSeatControl
          struckPlan={monthlyPlan}
          proposedPlan={yearlyPlan}
        />
        <S.CTAContainer>
          <S.UpgradeOrCancelContainer>
            {showCTA && (
              <Button
                onClick={() => {
                  Analytics.track(
                    'Confirm Upgrade Switch To Annual Subscription Button Clicked',
                    {
                      orgId: organization.id,
                      name: organization.name,
                    }
                  );
                  setSubmitting(true);
                  submitForm(yearlyPlan);
                }}>
                Switch to annual
              </Button>
            )}
            {showCTA && (
              <Button
                variant="ghost"
                onClick={() => {
                  Analytics.track('Continue with monthly plan Button Clicked', {
                    orgId: organization.id,
                    name: organization.name,
                  });
                  setSubmitting(true);
                  submitForm(monthlyPlan);
                }}>
                Continue with monthly plan
              </Button>
            )}
            <S.ProcessingNotice>
              {submitting && 'Processing...'}
            </S.ProcessingNotice>
          </S.UpgradeOrCancelContainer>
        </S.CTAContainer>
      </S.SecondaryFormMainContainer>
    </S.SecondaryContainer>
  );
};

const TeamsPlanModalForm = ({
  submitForm,
}: {
  submitForm: (params: SubmitParams) => Promise<boolean>;
}) => {
  const contextValue = useContext(TeamsCheckoutModalContext);
  const proPricingPlanFlag = useRampProPricingPlan();

  if (contextValue == null) {
    return null;
  }
  const {selectedPlan, isAlreadyTeamsMonthly} = contextValue;

  // If coming from monthly teams, don't show upgrade to Pro. Continue to show upgrade to Yearly teams.
  const proPlanInsteadOfTeamsPlan =
    proPricingPlanFlag && !isAlreadyTeamsMonthly;

  const generalUpgradeTitle = proPlanInsteadOfTeamsPlan
    ? 'Upgrade to Pro plan'
    : 'Upgrade to Teams plan';
  const intervalUpgradeTitle = 'Get 2 months free with an annual plan';

  return (
    <S.Container>
      <S.MainContainer>
        <S.Title>
          {isAlreadyTeamsMonthly ? intervalUpgradeTitle : generalUpgradeTitle}
        </S.Title>
        <S.WhatsIncluded>What's included</S.WhatsIncluded>
        <S.WhatsIncludedTextContainer>
          {proPlanInsteadOfTeamsPlan ? (
            <ProPlanDescription primaryPlan={selectedPlan} />
          ) : (
            <TeamsPlanDescription plan={selectedPlan} />
          )}
        </S.WhatsIncludedTextContainer>
        <PlanSeatPanel plan={selectedPlan} proPlanInsteadOfTeamsPlan />
      </S.MainContainer>
      <S.SideContainer>
        <BillingAddressContextProvider>
          <TeamsUpgradeSubscriptionForm submitForm={submitForm} />
        </BillingAddressContextProvider>
      </S.SideContainer>
    </S.Container>
  );
};

const TeamsUpgradeSubscriptionForm = ({
  submitForm,
}: {
  submitForm: (params: SubmitParams) => Promise<boolean>;
}) => {
  const [isTermsChecked, setIsTermsChecked] = useState(false);
  const contextValue = useContext(TeamsCheckoutModalContext);
  if (contextValue == null) {
    return null;
  }
  const {org, selectedPlan, seats, submitting, setSubmitting} = contextValue;
  const stripeContextValue = {submitting};
  const stripeUpdaterContextValue = {setSubmitting};
  return (
    <NewCheckoutModalContext.Provider value={stripeContextValue}>
      <NewCheckoutModalUpdaterContext.Provider
        value={stripeUpdaterContextValue}>
        <NewStripeForm
          orgName={org.name}
          orgID={org.id}
          renderButtons={({
            submitWrapper,
            submitButtonDisabled,
            submitting: isSubmitting,
          }) => {
            return (
              <S.CTAContainer>
                <S.TermsAndPrivacyContainer>
                  <WBCheckbox
                    checked={isTermsChecked}
                    onChange={() => {
                      setIsTermsChecked(!isTermsChecked);
                    }}
                  />
                  <span>
                    I agree to the{' '}
                    <TargetBlank href={termsOfService()}>
                      <u>Terms of Service</u>
                    </TargetBlank>{' '}
                    and{' '}
                    <TargetBlank href={privacyPolicy()}>
                      <u>Privacy Policy</u>
                    </TargetBlank>
                    .
                  </span>
                </S.TermsAndPrivacyContainer>
                <S.UpgradeOrCancelContainer>
                  <Button
                    className="w-full"
                    variant="primary"
                    data-for="upgradeBtn"
                    tooltip={
                      submitButtonDisabled || !isTermsChecked
                        ? 'Must fill out necessary fields & agree to the terms of service'
                        : ''
                    }
                    onClick={() => {
                      Analytics.track(
                        'Confirm Upgrade Subscription Button Clicked',
                        {
                          org: org.id,
                          plan: selectedPlan.id,
                          units: seats,
                        }
                      );
                      submitWrapper(submitForm);
                    }}
                    disabled={submitButtonDisabled || !isTermsChecked}>
                    {isSubmitting ? 'Processing...' : 'Purchase'}
                  </Button>
                </S.UpgradeOrCancelContainer>
              </S.CTAContainer>
            );
          }}
        />
      </NewCheckoutModalUpdaterContext.Provider>
    </NewCheckoutModalContext.Provider>
  );
};

const useUpgradeSubscription = () => {
  const elements = useElements();

  const [upgradeCustomerSubscriptionMutation] =
    useUpgradeCustomerSubscriptionMutation({
      context: {...propagateErrorsContext(), ...doNotRetryContext()},
    });

  const [createStorageSubscriptionMutation] =
    useCreateStorageSubscriptionMutation({
      context: {...propagateErrorsContext(), ...doNotRetryContext()},
    });

  const [createHourOverageSubscriptionMutation] =
    useCreateHourOverageSubscriptionMutation({
      context: {...propagateErrorsContext(), ...doNotRetryContext()},
    });

  const [updateDefaultPaymentMethodMutation] =
    useUpdateCustomerDefaultPaymentMethodMutation({
      context: {...propagateErrorsContext(), ...doNotRetryContext()},
    });

  return async (
    {
      stripe,
      paymentMethodID,
      setPaymentMethodAsDefault,
      setErrMsg,
    }: SubmitParams,
    plan: CheckoutPlan,
    seats: number,
    org: Org
  ): Promise<boolean> => {
    if (seats < org.usedSeats) {
      setErrMsg(
        `You have ${org.usedSeats} people on your team, so you need at least ${org.usedSeats} seats.`
      );
      return false;
    }
    if (elements == null) {
      return false;
    }

    const subResult = await upgrade();
    if (subResult == null) {
      return false;
    }

    const {organizationID, subscription} = subResult;

    // if the purchase was successful and the set payment method flag is true,
    // set the payment method as default
    if (setPaymentMethodAsDefault && paymentMethodID != null) {
      const {errors} = await updateDefaultPaymentMethodMutation({
        variables: {
          organizationId: organizationID,
          paymentMethod: paymentMethodID,
        },
      });

      // since the subscription itself was successful, just surface the error
      if (errors != null) {
        const errMsg = extractErrorMessageFromApolloError(errors);
        if (errMsg != null) {
          setErrMsg(errMsg);
        }
      }
    }

    const paymentIntent = subscription.latest_invoice.payment_intent;
    const clientSecret = paymentIntent?.client_secret;
    const paymentIntentStatus = paymentIntent?.status;
    const needsPayment =
      ['requires_action', 'requires_payment_method'].indexOf(
        paymentIntentStatus
      ) !== -1;

    if (needsPayment) {
      const paymentConfirmed = await confirmPayment(
        clientSecret,
        stripe,
        elements,
        'primary',
        setErrMsg
      );
      if (!paymentConfirmed) {
        return false;
      }
    }

    const existingStorageSub = getStorageSub(org);
    if (existingStorageSub == null) {
      // if organization doesn't subscribe to storage subscription
      // subscribe to the storage subscription
      const storageSubscription = await subscribeStorage(organizationID);
      if (storageSubscription == null) {
        return false;
      }

      const paymentIntentStorage =
        storageSubscription.latest_invoice.payment_intent;
      const clientSecretStorage = paymentIntentStorage?.client_secret;
      const paymentIntentStatusStorage = paymentIntentStorage?.status;
      const needsPaymentStorage =
        ['requires_action', 'requires_payment_method'].indexOf(
          paymentIntentStatusStorage
        ) !== -1;

      if (needsPaymentStorage) {
        const paymentConfirmedStorage = await confirmPayment(
          clientSecretStorage,
          stripe,
          elements,
          'storage',
          setErrMsg
        );
        if (!paymentConfirmedStorage) {
          return false;
        }
      }
    }

    if (isTeamsPrimaryPlan(plan)) {
      const hourOverageSubscription = await subscribeHourOverage(
        organizationID
      );
      if (hourOverageSubscription == null) {
        return false;
      }
      const paymentIntentHourOverage =
        hourOverageSubscription.latest_invoice.payment_intent;
      const clientSecretHourOverage = paymentIntentHourOverage?.client_secret;
      const paymentIntentStatusHourOverage = paymentIntentHourOverage?.status;
      const needsPaymentHourOverage =
        ['requires_action', 'requires_payment_method'].indexOf(
          paymentIntentStatusHourOverage
        ) !== -1;

      if (needsPaymentHourOverage) {
        const paymentConfirmedHourOverage = await confirmPayment(
          clientSecretHourOverage,
          stripe,
          elements,
          'hourOverage',
          setErrMsg
        );
        if (!paymentConfirmedHourOverage) {
          return false;
        }
      }
    }

    Analytics.track('Upgrade Subscription Completed', {
      org: org.id,
      plan: plan.id,
      units: seats,
      location: 'new upgrade subscription form',
    });
    return true;

    // HELPER FUNCTIONS
    async function upgrade(): Promise<{
      organizationID: string;
      subscription: {
        latest_invoice: any;
      };
    } | null> {
      if (paymentMethodID == null) {
        throw new Error(`missing paymentMethodID`);
      }

      try {
        const upgradeSubResult = await upgradeCustomerSubscriptionMutation({
          variables: {
            organizationID: org.id,
            planID: plan.id,
            paymentMethod: paymentMethodID,
            quantity: seats,
          },
        });

        const customerInfo = upgradeSubResult.data?.upgradeCustomerSubscription;
        const sub = customerInfo?.subscription;
        const orgID = customerInfo?.organizationID;

        if (upgradeSubResult.errors != null || sub == null || orgID == null) {
          const errMsg = handleUpgradeSubError(
            {
              err: "Unknown error upgrading subscription. Exception didn't throw but success was not reported.",
              op: 'handleUpgrade-unknown',
              analyticsEvent:
                'Upgrade Subscription Error from teams checkout modal',
              extra: {errorType: 'unknown'},
              userFacingErrorMsg:
                'Error confirming payment. Please wait a few moments or contact support@wandb.com for help.',
            },
            org,
            plan,
            seats
          );
          if (errMsg != null) {
            setErrMsg(errMsg);
          }
          return null;
        }

        return {organizationID: orgID, subscription: sub};
      } catch (err) {
        const errMsg = extractErrorMessageFromApolloError(err);
        const errString = handleUpgradeSubError(
          {
            err,
            op: 'upgradeSubResult',
            extra: {
              reason: errMsg,
              errorType: 'processing payment',
            },
            analyticsEvent: 'Upgrade Subscription Error',
            userFacingErrorMsg: `Error processing payment: ${errMsg}`,
          },
          org,
          plan,
          seats
        );
        if (errString != null) {
          setErrMsg(errString);
        }
        return null;
      }
    }

    async function subscribeHourOverage(orgID: string): Promise<{
      latest_invoice: any;
    } | null> {
      return trySubscriptionUpdateWithAnalyticsErrorHandling({
        mutation: createHourOverageSubscriptionMutation,
        input: {
          variables: {
            organizationId: org.id,
          },
        },
        transformDataToOutput: data => {
          const sub = data.createHourOverageSubscription?.subscription;
          if (sub == null) {
            return null;
          }
          return sub;
        },
        subscriptionTypeName: 'hourOverage',
      });
    }

    // subscribeStorage is only called if the user is upgrading to the starter tier 1 for the first time
    // otherwise, they should be already on the storage plan and don't need to subscribe to storage plan
    async function subscribeStorage(orgID: string): Promise<{
      latest_invoice: any;
    } | null> {
      return trySubscriptionUpdateWithAnalyticsErrorHandling({
        mutation: createStorageSubscriptionMutation,
        input: {
          variables: {
            paymentMethod: paymentMethodID,
            organizationId: org.id,
          },
        },
        transformDataToOutput: data => {
          const storageSub = data?.createStorageSubscription?.subscription;
          if (storageSub == null) {
            return null;
          }
          return storageSub;
        },
        subscriptionTypeName: 'storage',
      });
    }
  };
};

const useUpgradeSubscriptionToProPlan = () => {
  const elements = useElements();

  const [upgradeCustomerToProPlanMutation] =
    useUpgradeCustomerToProPlanMutation({
      context: {...propagateErrorsContext(), ...doNotRetryContext()},
    });

  const [updateDefaultPaymentMethodMutation] =
    useUpdateCustomerDefaultPaymentMethodMutation({
      context: {...propagateErrorsContext(), ...doNotRetryContext()},
    });

  return async (
    {
      stripe,
      paymentMethodID,
      setPaymentMethodAsDefault,
      setErrMsg,
    }: SubmitParams,
    plan: CheckoutPlan,
    seats: number,
    org: Org
  ): Promise<boolean> => {
    if (seats < org.usedSeats) {
      setErrMsg(
        `You have ${org.usedSeats} people on your team, so you need at least ${org.usedSeats} seats.`
      );
      return false;
    }
    if (elements == null) {
      return false;
    }

    const subResult = await upgrade();
    if (subResult == null) {
      return false;
    }

    const {organizationID, subscription} = subResult;

    // if the purchase was successful and the set payment method flag is true,
    // set the payment method as default
    if (setPaymentMethodAsDefault && paymentMethodID != null) {
      const {errors} = await updateDefaultPaymentMethodMutation({
        variables: {
          organizationId: organizationID,
          paymentMethod: paymentMethodID,
        },
      });

      // since the subscription itself was successful, just surface the error
      if (errors != null) {
        const errMsg = extractErrorMessageFromApolloError(errors);
        if (errMsg != null) {
          setErrMsg(errMsg);
        }
      }
    }

    const paymentIntent = subscription.latest_invoice.payment_intent;
    const clientSecret = paymentIntent?.client_secret;
    const paymentIntentStatus = paymentIntent?.status;
    const needsPayment =
      ['requires_action', 'requires_payment_method'].indexOf(
        paymentIntentStatus
      ) !== -1;

    if (needsPayment) {
      const paymentConfirmed = await confirmPayment(
        clientSecret,
        stripe,
        elements,
        'primary',
        setErrMsg
      );
      if (!paymentConfirmed) {
        return false;
      }
    }

    Analytics.track('Upgrade Subscription to Pro Plan Completed', {
      org: org.id,
      plan: plan.id,
      units: seats,
      location: 'new upgrade subscription form',
    });
    return true;

    // HELPER FUNCTIONS
    async function upgrade(): Promise<{
      organizationID: string;
      subscription: {
        latest_invoice: any;
      };
    } | null> {
      if (paymentMethodID == null) {
        throw new Error(`missing paymentMethodID`);
      }

      try {
        const upgradeSubResult = await upgradeCustomerToProPlanMutation({
          variables: {
            organizationID: org.id,
            planID: plan.id,
            paymentMethod: paymentMethodID,
            modelsSeats: seats,
            weaveIngestionTier: WeaveIngestionLimitInMb.Limit_600, // Default Weave tier limit
          },
        });

        const customerInfo = upgradeSubResult.data?.upgradeCustomerToProPlan;
        const sub = customerInfo?.subscriptionCommit;
        const orgID = customerInfo?.organizationID;

        if (upgradeSubResult.errors != null || sub == null || orgID == null) {
          const errMsg = handleUpgradeSubError(
            {
              err: "Unknown error upgrading subscription. Exception didn't throw but success was not reported.",
              op: 'handleUpgrade-unknown',
              analyticsEvent: 'Upgrade Subscription Error from checkout modal',
              extra: {errorType: 'unknown'},
              userFacingErrorMsg:
                'Error confirming payment. Please wait a few moments or contact support@wandb.com for help.',
            },
            org,
            plan,
            seats
          );
          if (errMsg != null) {
            setErrMsg(errMsg);
          }
          return null;
        }

        return {organizationID: orgID, subscription: sub};
      } catch (err) {
        const errMsg = extractErrorMessageFromApolloError(err);
        const errString = handleUpgradeSubError(
          {
            err,
            op: 'upgradeSubResult',
            extra: {
              reason: errMsg,
              errorType: 'processing payment',
            },
            analyticsEvent: 'Upgrade Subscription Error',
            userFacingErrorMsg: `Error processing payment: ${errMsg}`,
          },
          org,
          plan,
          seats
        );
        if (errString != null) {
          setErrMsg(errString);
        }
        return null;
      }
    }
  };
};
