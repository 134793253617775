import React from 'react';

import {toRunsDataQuery} from '../../containers/RunsDataLoader';
import {useSampleAndQueryToTable} from '../../util/panelHelpers';
import type {PanelSpec} from '../../util/panelTypes';
import type {Query} from '../../util/queryTypes';
import {PANEL_TYPE} from './constants';
import type {RunComparerConfig} from './types';

const runComparerTransformQuery = (query: Query, config: RunComparerConfig) => {
  const transformed = toRunsDataQuery(
    query,
    {
      selectionsAsFilters: true,
      disableGrouping: config.useRunsTableGroupingInPanels === false,
    },
    {fullConfig: true, fullSummary: true}
  );
  return transformed;
};

const useTableData = (pageQuery: Query, config: RunComparerConfig) => {
  const query = toRunsDataQuery(
    pageQuery,
    {
      selectionsAsFilters: true,
      disableGrouping: config.useRunsTableGroupingInPanels === false,
    },
    {fullConfig: true, fullSummary: true}
  );
  // TODO: config is not updating, useRunsTableGroupingInPanels is always false
  // this means ungrouped panel comparer panels don't export csv properly
  return useSampleAndQueryToTable(query, pageQuery, config);
};

export const Spec: PanelSpec<typeof PANEL_TYPE, RunComparerConfig> = {
  type: PANEL_TYPE,
  Component: React.lazy(() => import('./ComponentWrapper')),
  transformQuery: runComparerTransformQuery,
  noEditMode: true,

  exportable: {
    csv: true,
    api: true,
  },

  useTableData,
  icon: 'panel-run-comparer',
};
