export function marketingSite(): string {
  return `https://wandb.ai/site`;
}

export function weave(): string {
  return `https://wandb.ai/site/weave`;
}

export function experimentTracking(): string {
  return `https://wandb.ai/site/experiment-tracking`;
}

export function tables(): string {
  return `https://wandb.ai/site/tables`;
}

export function sweeps(): string {
  return `https://wandb.ai/site/sweeps`;
}

export function models(): string {
  return `https://wandb.ai/site/models`;
}

export function launch(): string {
  return `https://wandb.ai/site/launch`;
}

export function artifacts(): string {
  return `https://wandb.ai/site/artifacts`;
}

export function reports(): string {
  return `https://wandb.ai/site/reports`;
}

export function articles(): string {
  return `https://wandb.ai/site/articles`;
}

export function podcast(): string {
  return `https://wandb.ai/site/podcast`;
}

export function caseStudies(): string {
  return `https://wandb.ai/site/case-studies`;
}

export function tutorials(): string {
  return `https://wandb.ai/site/tutorials`;
}

export function benchmarks(): string {
  return `https://wandb.ai/site/benchmarks`;
}

export function company(): string {
  return `https://wandb.ai/site/company/about-us`;
}

export function authors(): string {
  return `https://wandb.ai/site/authors`;
}

export function termsOfService(): string {
  return `https://wandb.ai/site/terms`;
}

export function privacyPolicy(): string {
  return `https://wandb.ai/site/privacy`;
}

export function pricing(): string {
  return `https://wandb.ai/site/pricing`;
}

export function lineageTrackingPricing(): string {
  return `https://wandb.ai/site/pricing#lineage-tracking`;
}

export function enterprise(): string {
  return `https://wandb.ai/site/enterprise`;
}

export function enterpriseTrial(): string {
  return `https://wandb.ai/site/enterprise-trial`;
}

export function benchmarkList(): string {
  return `https://wandb.ai/site/benchmarks`;
}

export function contact(): string {
  return `https://wandb.ai/site/contact`;
}

export function contactSalesPricing(): string {
  return `https://wandb.ai/site/contact?interested-in=pricing`;
}

export function contactSalesWeaveTrialLimitReached(): string {
  return `https://wandb.ai/site/contact?interested-in=pricing&utm_source=product&utm_medium=in-product&utm_campaign=handraiser-weave-trial-limit-reached`;
}

export function contactSalesSeats(): string {
  return `https://wandb.ai/site/contact?interested-in=pricing&utm_campaign=additional_model_seats`;
}

export function contactSalesDemo(): string {
  return `https://wandb.ai/site/contact?interested-in=demo`;
}

export function contactSupportOther(): string {
  return `https://wandb.ai/site/contact?interested-in=other`;
}

export function community(): string {
  return 'https://wandb.ai/site/community';
}

export function courses(): string {
  return 'https://www.wandb.courses';
}

export function careers(): string {
  return `https://jobs.lever.co/wandb`;
}

export function facebook(): string {
  return `https://www.facebook.com/weightsandbiases`;
}

export function instagram(): string {
  return `https://www.instagram.com/weightsandbiases`;
}

// export function pinterest(): string {
//   return ``;
// }

export function twitter(): string {
  return `https://twitter.com/weights_biases`;
}

export function youtube(): string {
  return `https://www.youtube.com/channel/UCBp3w4DCEC64FZr4k9ROxig`;
}

export function allReports(entityName: string): string {
  return `/${entityName}/reports`;
}

export function allProjects(entityName: string): string {
  return `/${entityName}/projects`;
}

export function teamMembers(entityName: string): string {
  return `/${entityName}/members`;
}

export function deployer(): string {
  return `https://deploy.wandb.ai`;
}
