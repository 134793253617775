import {Icon} from '@wandb/weave/components/Icon';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import numeral from 'numeral';
import React, {FC, Fragment} from 'react';

import {
  AccountStatusBanner,
  AccountStatusBannerBoldText,
  AccountStatusBannerFlexWrapper,
  AccountStatusBannerTextWrapper,
} from '../../AccountStatusBanner';
import {useCurrentBannerStates} from '..';
import {WeaveExceededLimitBannerProps} from './utils';
export const WeaveExceededLimitBannerGlobalList: FC = () => {
  const currentBannerStates = useCurrentBannerStates();

  return (
    <Tailwind>
      <div className="space-y-8">
        {currentBannerStates.map(state => {
          const weaveExceededLimitBannerProps =
            state.weaveEnforcementBannerFlags.weaveExceededLimitBannerProps;

          return (
            <Fragment key={state.account.name + state.account.accountType}>
              {weaveExceededLimitBannerProps && (
                <WeaveExceededLimitBanner {...weaveExceededLimitBannerProps} />
              )}
            </Fragment>
          );
        })}
      </div>
    </Tailwind>
  );
};

export const WeaveExceededLimitBanner: FC<WeaveExceededLimitBannerProps> = ({
  organizationId,
  weaveLimitBytes,
  cta,
}) => {
  const statusMsg = 'Access to Weave will be restricted. ';
  return (
    <Tailwind>
      <AccountStatusBanner
        eventData={{
          location: `exceeded 90% for Weave alert banner`,
          organizationId,
          resource: 'Weave',
        }}>
        <AccountStatusBannerFlexWrapper>
          <div>
            <Icon name="info" />
          </div>
          <AccountStatusBannerTextWrapper>
            <span>
              <AccountStatusBannerBoldText>
                Your organization has exceeded its{' '}
                {numeral(weaveLimitBytes).format('0 b')} free ingestion of
                Weave.
              </AccountStatusBannerBoldText>{' '}
              <span>{statusMsg}</span>
              {cta}
            </span>
          </AccountStatusBannerTextWrapper>
        </AccountStatusBannerFlexWrapper>
      </AccountStatusBanner>
    </Tailwind>
  );
};
