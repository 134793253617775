import {ID} from '@wandb/weave/common/util/id';
import gql from 'graphql-tag';
import _ from 'lodash';
import {useEffect, useMemo, useRef, useState} from 'react';

import * as Generated from '../../generated/graphql';
import {RunHistoryKeyInfo, RunHistoryKeyInfoKeys} from '../../types/run';
import {useCachedData} from '../../util/cache';
import {usePersistentRumViewContext} from '../../util/datadog';
import * as Filter from '../../util/filters';
import {usePerformanceStatLogger} from '../../util/profiler/react';
import * as QueryTS from '../../util/queryts';
import {useApolloClient} from '../hooks';
import {HistoryKeyInfoQueryVars} from './historyKeysQueryTypes';
import {useQuery} from './query';

export const HISTORY_KEYS_QUERY = gql`
  query HistoryKeys(
    $projectName: String!
    $entityName: String!
    $filters: JSONString
    $limit: Int = 100
    $order: String
  ) {
    project(name: $projectName, entityName: $entityName) {
      id
      runs(filters: $filters, first: $limit, order: $order) {
        historyKeys(format: PLAINTEXT)
        edges {
          node {
            id
            wandbConfig(keys: ["viz", "visualize"])
          }
        }
      }
    }
  }
`;

export const HISTORY_KEYS_QUERY_BY_PROJECT_ID = gql`
  query HistoryKeysByProjectId(
    $internalId: ID!
    $filters: JSONString
    $limit: Int = 100
    $order: String
  ) {
    project(internalId: $internalId) {
      id
      runs(filters: $filters, first: $limit, order: $order) {
        historyKeys(format: BITMAP)
        edges {
          node {
            id
            wandbConfig(keys: ["viz", "visualize"])
          }
        }
      }
    }
  }
`;

interface RunHistoryKeyInfoServerResponse {
  keys: RunHistoryKeyInfoKeys;
  lastStep?: number;
}

interface Data {
  project: {
    runs: {
      historyKeys: RunHistoryKeyInfoServerResponse;
      edges: Array<{
        node: {
          wandbConfig?: string;
        };
      }>;
    };
  };
}

// For really big result sets, we set a much longer interval
const LONG_POLL_KEYS_THRESHOLD = 1000;

export interface VizMap {
  [key: string]: any;
}

function mergeViz(viz: Array<string | undefined>): VizMap {
  const res: VizMap = {};
  viz.forEach(vs => {
    if (vs == null) {
      return;
    }
    const parsed = JSON.parse(vs);
    if (parsed.viz == null && parsed.visualize == null) {
      return;
    }
    const v = parsed.viz;
    if (v != null) {
      Object.keys(v).forEach(k => {
        if (res[k] == null) {
          res[k] = v[k];
        }
      });
    }

    const visualize = parsed.visualize;
    if (visualize != null) {
      Object.keys(visualize).forEach(k => {
        if (res[k] == null) {
          res[k] = visualize[k];
        }
      });
    }
  });
  return res;
}

// WB-9640: A bug in the client is persisting inefficient user queries for custom charts
// Basically, summaryTable field is over-fetching because `tableColumns` arg is missing
// This function tries its best to find these malformed configs and adds the missing arg.
function patchVizMap(vizMap: VizMap): VizMap {
  const result = _.mapValues(vizMap, viz => {
    (function fixViz() {
      if (
        viz.panel_config?.panelDefId == null ||
        ![
          'wandb/bar/v0',
          'wandb/confusion_matrix/v1',
          'wandb/histogram/v0',
          'wandb/lineseries/v0',
          'wandb/line/v0',
          'wandb/area-under-curve/v0',
          'wandb/scatter/v0',
        ].includes(viz.panel_config?.panelDefId)
      ) {
        return;
      }

      // Collect used fields
      const fieldSettings = viz.panel_config?.fieldSettings;
      if (fieldSettings == null) {
        // Unexpected viz shape
        return;
      }
      const usedFields = Object.values(fieldSettings).filter(f => f != null);

      const queryFields = viz.panel_config?.userQuery?.queryFields[0]?.fields;
      if (queryFields == null) {
        // Unexpected viz shape
        return;
      }

      // Check above obviates null-chaining
      viz.panel_config.userQuery.queryFields[0].fields = queryFields.map(
        (field: any) => {
          (function fixQueryField() {
            if (field.name !== 'summaryTable') {
              // Only fix the summaryTable field's args
              return;
            }

            const args: Array<{name: string; value: any}> = field.args;
            if (args.find(arg => arg.name === 'tableColumns') != null) {
              // Already has a tableColumns arg
              return;
            }

            const tableKeyArg = args.find(arg => arg.name === 'tableKey');
            if (
              tableKeyArg == null ||
              !(tableKeyArg.value as string).endsWith('_table')
            ) {
              // This didn't have a tableKey arg, or it doesn't have the expected format!
              return;
            }

            field.args.push({name: 'tableColumns', value: usedFields});
          })();

          return field;
        }
      );
    })();

    return viz;
  });

  return result;
}

type HistoryKeyLoggingInfo = {
  keys?: Record<string, unknown>;
  lastStep?: number;
};
function useHistoryKeyLogging(historyKeyInfo?: HistoryKeyLoggingInfo) {
  const keyCount = useMemo(
    () => Object.keys(historyKeyInfo?.keys ?? {}).length ?? 0,
    [historyKeyInfo]
  );
  const stepCount = historyKeyInfo?.lastStep ?? 0;
  usePerformanceStatLogger('workspace.keys_count', keyCount, v => v >= 100);
  usePerformanceStatLogger(
    'workspace.steps_count',
    stepCount,
    v => v >= 1_000_000
  );
  usePersistentRumViewContext('workspace.keys_count', keyCount, keyCount > 0);
  usePersistentRumViewContext(
    'workspace.steps_count',
    stepCount,
    stepCount > 0
  );
}

// For tests only
export {patchVizMap as __patchVizMap};

export type UseHistoryKeysQueryResult =
  | {loading: true; error: null}
  | {loading: false; error: true}
  | ({
      loading: false;
      error: null;
    } & HistoryKeysResult);

type HistoryKeysResult = {
  historyKeyInfo: RunHistoryKeyInfo;
  viz: VizMap;
};

// This query is polled, respecting the user polling settings and the page poll
// interval (stored in redux). If the query is expensive (determined by a simple
// threshold on the result size), we raise the poll interval to a much larger
// value, to reduce network overhead for users.
export function useHistoryKeysQuery(
  queryVars: HistoryKeyInfoQueryVars,
  skip?: boolean
): UseHistoryKeysQueryResult {
  const {entityName, projectName, filters, sort} = queryVars;
  const [pollMultiplier, setPollMultiplier] = useState<number>(1);

  const filtersStr = JSON.stringify(Filter.toMongo(filters));
  const orderStr = QueryTS.sortToOrderString(sort);
  const variables = useMemo(
    () => ({
      entityName,
      projectName,
      filters: filtersStr,
      order: orderStr,
    }),
    [entityName, projectName, filtersStr, orderStr]
  );
  const query = useQuery<Data, Generated.HistoryKeysQueryVariables>(
    Generated.HistoryKeysDocument,
    {
      variables,
      enablePolling: true,
      pollMultiplier,
      skip,
    }
  );

  const initialLoading = query.initialLoading;
  const project = query.initialLoading ? null : query.data?.project;
  const historyKeyInfo = project?.runs.historyKeys;

  const resultFromServer: HistoryKeysResult | null = useMemo(() => {
    if (project == null || historyKeyInfo == null) {
      return null;
    }
    return {
      historyKeyInfo: {
        ...historyKeyInfo,
        sortedKeys: Object.keys(historyKeyInfo.keys).sort(),
      },
      viz: patchVizMap(
        mergeViz(project.runs.edges.map(e => e.node.wandbConfig))
      ),
    };
  }, [project, historyKeyInfo]);

  const result = useCachedData({
    namespace: `useHistoryKeysQuery`,
    key: variables,
    dataFromServer: resultFromServer,
    waitingForDataFromServer: initialLoading,
  });

  useEffect(() => {
    if (!initialLoading && historyKeyInfo != null) {
      const numKeys = Object.keys(historyKeyInfo.keys).length;
      setPollMultiplier(numKeys > LONG_POLL_KEYS_THRESHOLD ? 10 : 1);
    }
  }, [initialLoading, historyKeyInfo]);

  useHistoryKeyLogging(historyKeyInfo);

  return useMemo(() => {
    if (initialLoading && result == null) {
      return {loading: true, error: null};
    }
    if (!initialLoading && project == null) {
      return {
        loading: false,
        error: true,
      };
    }
    if (result == null) {
      throw new Error(`invalid state`);
    }
    return {
      loading: false,
      error: null,
      ...result,
    };
  }, [initialLoading, project, result]);
}

export function useMultiRunsetHistoryKeysQuery(
  queryVars: HistoryKeyInfoQueryVars[]
): UseHistoryKeysQueryResult {
  const idRef = useRef(ID());
  const id = idRef.current;
  const [pollMultiplier, setPollMultiplier] = useState<number>(1);

  const [pollCount, setPollCount] = useState(0);
  const [resultFromServer, setResultFromServer] =
    useState<HistoryKeysResult | null>(null);
  const [err, setErr] = useState<Error | null>(null);

  const client = useApolloClient();
  useEffect(() => {
    const fetchHistoryKeys = async () => {
      const projectAccessPromises = queryVars.map(async ps => {
        const variables = {
          entityName: ps.entityName,
          projectName: ps.projectName,
          filters: JSON.stringify(Filter.toMongo(ps.filters)),
          order: QueryTS.sortToOrderString(ps.sort),
        };
        try {
          const {data} = await client.query<
            Generated.HistoryKeysQueryResult['data']
          >({
            query: Generated.HistoryKeysDocument,
            variables,
          });
          return data;
        } catch (error) {
          console.error(error);
          setErr(error as Error);
          return null;
        }
      });

      const results = await Promise.all(projectAccessPromises);
      const validResults = results.filter(result => result != null);
      const allKeys = Object.assign(
        {} as RunHistoryKeyInfoKeys,
        ...validResults.map(r => r?.project?.runs?.historyKeys?.keys)
      );
      const allViz = Object.assign(
        {} as VizMap,
        ...validResults.map(r => {
          if (r?.project?.runs?.edges == null) {
            return {};
          }
          return patchVizMap(
            mergeViz(r.project.runs.edges.map((e: any) => e.node.wandbConfig))
          );
        })
      );
      const mergedResult: HistoryKeysResult = {
        historyKeyInfo: {
          keys: allKeys,
          sortedKeys: Object.keys(allKeys).sort(),
        },
        viz: allViz,
      };
      if (mergedResult == null || Object.keys(mergedResult).length === 0) {
        return;
      }
      setResultFromServer(mergedResult);
    };

    fetchHistoryKeys();
  }, [queryVars, client, pollCount]); // N.B pollCount is used to force a re-fetch

  // polling hack, force polling by setting pollCount
  useEffect(() => {
    const interval = setInterval(() => {
      setPollCount(pollCount + 1);
    }, 5000 * pollMultiplier);
    return () => clearInterval(interval);
  }, [pollMultiplier, pollCount]);

  const initialLoading = resultFromServer == null && err == null;

  const result = useCachedData({
    namespace: `useHistoryKeysQueryIncrementally`,
    key: queryVars,
    dataFromServer: resultFromServer,
    waitingForDataFromServer: initialLoading,
  });

  const historyKeyInfo = result?.historyKeyInfo;
  useEffect(() => {
    if (!initialLoading && historyKeyInfo != null) {
      const numKeys = Object.keys(historyKeyInfo.keys).length;
      setPollMultiplier(numKeys > LONG_POLL_KEYS_THRESHOLD ? 10 : 1);
    }
  }, [initialLoading, historyKeyInfo]);

  useHistoryKeyLogging(historyKeyInfo);

  return useMemo(() => {
    if (err != null) {
      return {loading: false, error: true};
    }
    if (initialLoading) {
      return {
        loading: true,
        error: null,
      };
    }
    if (result == null) {
      throw new Error(`invalid state`);
    }
    return {
      loading: false,
      error: null,
      ...result,
    };
  }, [initialLoading, err, result]);
}
