import {Icon} from '@wandb/weave/components/Icon';
import * as Tooltip from '@wandb/weave/components/RadixTooltip';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import React, {useState} from 'react';
import {twMerge} from 'tailwind-merge';

export const InvalidFilterTooltip = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Tailwind>
      <Tooltip.Provider>
        <Tooltip.Root open={isOpen} onOpenChange={setIsOpen}>
          <Tooltip.Trigger className="night-aware">
            <Icon
              name="warning"
              className={twMerge(
                'inline-flex items-center justify-center',
                'h-24 w-24 rounded p-4',
                'text-red-600 hover:bg-red-300/[.32]',
                'dark:text-red-500 dark:hover:bg-red-600/[.32]'
              )}
            />
          </Tooltip.Trigger>
          <Tooltip.Content side="top">This filter is invalid</Tooltip.Content>
        </Tooltip.Root>
      </Tooltip.Provider>
    </Tailwind>
  );
};
