import * as _ from 'lodash';

import {BucketedLine} from '../types';
import {BIN_POINT_TO_USE} from './../../../util/plotHelpers/chartTypes';
import {BucketedQueryState} from './bucketedQueryManager';

export function bracketHistoryData(
  run: BucketedLine,
  loFiRun: BucketedLine,
  xAxisKey?: string
) {
  run.history.forEach((h, index) => {
    const matchedLoFiHistory = loFiRun.history[index];
    const lastKey = xAxisKey + BIN_POINT_TO_USE;

    if (h.length < 2 || !matchedLoFiHistory || !matchedLoFiHistory.length) {
      return;
    }

    const [minX, maxX] = [h[0][lastKey], h[h.length - 1][lastKey]];

    // Find closest points in lower fidelity data that bracket the high fidelity range
    const leftBracketIndex = _.sortedIndexBy(
      matchedLoFiHistory,
      {[lastKey]: minX},
      lastKey
    );

    const leftBracket =
      leftBracketIndex > 0 ? matchedLoFiHistory[leftBracketIndex - 1] : null;

    const rightBracketIndex = _.sortedIndexBy(
      matchedLoFiHistory,
      {[lastKey]: maxX},
      lastKey
    );

    const newHistory = run.history[index];
    if (leftBracket) {
      newHistory.unshift(leftBracket);
    }

    const rightBracket =
      rightBracketIndex < matchedLoFiHistory.length - 1
        ? matchedLoFiHistory[rightBracketIndex + 1]
        : null;

    if (rightBracket) {
      newHistory.push(rightBracket);
    }

    run.history[index] = newHistory;
  });
}

/**
 * The intent here is to wrap the histories returned by the API with points outside the specified range if some can be found in the previously queried histories. Imagine a metric logged every 1000 steps, if you hit the API with a zoom range like 2250 - 222250 the first and last points on the returned data will be logged at 2300 and 222000 respectively. This will cause the chart to look like the lines do not extend past the zoomed range. Wrapping these with two points outside the range lets react vis plot the line to the edges of the chart.
 *
 * It's okay to swallow errors here as the charts are still usable without this functionality, so if there's something unexpected here we can just sniff it out in Sentry and fix it as needed.
 *
 * Perf notes: w/ 50 runs and 1000 points per run this takes < .5ms
 */
export function bracketHistoriesData(
  state: BucketedQueryState,
  xAxisKey?: string
) {
  // no-op if there is no lower fidelity data to bracket with
  if (!state.lowerFidelityData || !xAxisKey) {
    return state;
  }

  Object.entries(state.data?.runsById ?? {}).forEach(([runId, run]) => {
    const lowerFidelityMatch = state.lowerFidelityData?.runsById[runId];
    if (!lowerFidelityMatch) {
      return;
    }
    try {
      bracketHistoryData(run, lowerFidelityMatch, xAxisKey);
    } catch (e) {
      console.error('bracketHistoriesData', e);
      return;
    }
  });

  return state;
}
