import './ProfileMenu.css';

import * as globals from '@wandb/weave/common/css/globals.styles';
import {useWindowSize} from '@wandb/weave/common/hooks/useWindowSize';
import {useBooleanState, useSyncedState} from '@wandb/weave/common/util/hooks';
import {isNotNullUndefinedOrFalse} from '@wandb/weave/common/util/types';
import {
  IconAddNew,
  IconChartVerticalBars,
  IconCodeAlt,
  IconCreditCardPayment,
  IconDashboardBlackboard,
  IconDocument,
  IconImpersonateMaskAlt,
  IconJobCalculator,
  IconLogOut,
  IconMagicWandStick,
  IconRandomizeAlt,
  IconSettings,
  IconSettingsParameters,
  IconShareWith,
  IconUserAuthor,
  IconUsersTeam,
} from '@wandb/weave/components/Icon';
import {Tooltip} from '@wandb/weave/components/Tooltip';
import React, {
  memo,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import config, {
  envIsLocal,
  envIsPublicCloud,
  urlPrefixed,
} from '../../../config';
import {useAutoRefreshSettings} from '../../../containers/AutoRefreshSettings';
import {
  useProfileMenuOrgRoleInfoQuery,
  useServerInfoQuery,
  useViewerPhotoUrlQuery,
} from '../../../generated/graphql';
import {getViewerOrganizationMemberRole} from '../../../pages/Billing/AccountSettings/util';
import {useIsOrgDashboardEnabled} from '../../../pages/OrgDashboard/useIsOrgDashboardEnabled';
import {auth} from '../../../setup';
import {useViewer} from '../../../state/viewer/hooks';
import {
  setViewerUsingAdminPrivileges,
  useAdminModeActive,
  viewerUsingAdminPrivileges,
  viewingAs,
} from '../../../util/admin';
import history from '../../../util/history';
import {
  useRampFlagAccountSelector,
  useRampFlagOrgLevelServiceAccounts,
} from '../../../util/rampFeatureFlags';
import {
  accountSettingsBillingTab,
  accountSettingsMembersTab,
  accountSettingsServiceAccountsTab,
  accountSettingsSettingsTab,
  accountSettingsTeamsTab,
  adminHome,
  adminUsers,
  homePage,
  orgDashboard,
  profilePage,
  settings,
  subscriptions,
} from '../../../util/urls';
import {removeNullUndefinedOrFalse} from '../../../util/utility';
import {ImpersonateUserModal} from '../../ImpersonateUserModal';
import {LoginButton, SignupButton} from '../../LoginButton';
import {OrganizationMemberRole} from '../../MembersTable/util';
import {useShowJoinTeamsContent} from '../../ShowJoinTeamsContent';
import UsageSimulationModal from '../../UsageSimulationModal';
import {useAnonUserSession} from '../../useAnonUserSession';
import {ViewTeamsModal} from '../../ViewTeamsModal';
import {AccountSelectorContext} from './AccountSelectorContextProvider';
import {AccountSelectorMenuItem} from './AccountSelectorMenuItem';
import NavMenu, {NavMenuItem, NavMenuSection} from './NavMenu';
import * as S from './ProfileMenu.styles';
import {Account, AccountType} from './types';
import {useAccountSelector} from './useAccountSelector';

type ProfileMenuProps = {
  isSignup?: boolean;
  isFullyConnected?: boolean;
};

const ProfileMenuComp: React.FC<ProfileMenuProps> = React.memo(
  ({isSignup, isFullyConnected}) => {
    const viewer = useViewer();
    const isAdminMode = useAdminModeActive();
    const isImpersonation = !envIsLocal && viewingAs() !== '';

    const {
      state: menuOpen,
      setTrue: openMenu,
      setFalse: closeMenu,
    } = useBooleanState(false);
    const {
      state: limitConfigOpen,
      setTrue: openLimitConfig,
      setFalse: closeLimitConfig,
    } = useBooleanState(false);
    const {
      state: impersonateModalOpen,
      setTrue: openImpersonateModal,
      setFalse: closeImpersonateModal,
    } = useBooleanState(false);

    const {data: viewerPhotoURLData} = useViewerPhotoUrlQuery({
      fetchPolicy: `network-only`,
    });
    const viewerPhotoURL = viewerPhotoURLData?.viewer?.photoUrl;

    const res = useShowJoinTeamsContent('Profile Menu');
    const {showTeamsContent: showJoinTeamsContent, viewerEmail: userEmail} =
      res;

    const [isViewTeamsModalOpen, setIsViewTeamsModalOpen] = useState(false);
    const {selectedAccount} = useContext(AccountSelectorContext);
    const enableAccountSelector = useRampFlagAccountSelector();
    const {responsiveSize} = useWindowSize();
    const sections: NavMenuSection[] = useProfileMenuSections({
      openLimitConfig,
      openImpersonateModal,
      setIsViewTeamsModalOpen,
      isViewTeamsModalOpen,
      showJoinTeamsContent,
      isSignup,
      isFullyConnected,
    });
    const accountName =
      selectedAccount?.accountType === AccountType.Personal &&
      selectedAccount?.isDummyAccount !== true
        ? 'Personal'
        : selectedAccount?.name;

    const accountNameRef = useRef<HTMLDivElement>(null);

    const [showTooltip, setShowTooltip] = useState(false);

    useEffect(() => {
      const element = accountNameRef.current;
      if (element) {
        const isOverflowing = element.scrollWidth > element.clientWidth;
        setShowTooltip(isOverflowing);
      }
    }, [accountName]);

    useAnonUserSession(viewer);

    if (viewer == null) {
      return (
        <div className="viewer-dropdown">
          <div className="logged-out-menu">
            <LoginButton />
            {!config.ENVIRONMENT_IS_PRIVATE && <SignupButton />}
          </div>
        </div>
      );
    }

    if (enableAccountSelector && !isFullyConnected && selectedAccount == null) {
      return null;
    }

    return (
      <>
        <div className="viewer-dropdown" data-test="profile-menu">
          <NavMenu
            open={menuOpen}
            onOpen={openMenu}
            onClose={closeMenu}
            trigger={
              enableAccountSelector && !isFullyConnected ? (
                <S.ProfileButtonContainer
                  data-test="nav-profile-account-selector"
                  aria-label="Toggle profile & account menu">
                  <S.ProfileButton>
                    {responsiveSize !== 'small' && (
                      <S.TextContainer>
                        <S.UserName>{viewer?.name}</S.UserName>
                        {showTooltip && (
                          <Tooltip
                            content={accountName}
                            position="bottom center"
                            trigger={
                              <S.AccountName
                                admin={isAdminMode}
                                ref={accountNameRef}
                                impersonation={isImpersonation}>
                                {accountName}
                              </S.AccountName>
                            }
                          />
                        )}
                        {!showTooltip && (
                          <S.AccountName
                            admin={isAdminMode}
                            ref={accountNameRef}
                            impersonation={isImpersonation}>
                            {accountName}
                          </S.AccountName>
                        )}
                      </S.TextContainer>
                    )}
                    <S.ProfileImage
                      aria-label="Toggle profile & account menu"
                      alt={`${viewer.username}'s profile picture`}
                      className="nav-propic"
                      src={viewerPhotoURL ?? viewer.photoUrl}
                    />
                  </S.ProfileButton>
                </S.ProfileButtonContainer>
              ) : (
                <S.Container data-test="nav-profile">
                  <S.Avatar
                    className="nav-propic"
                    alt={`${viewer.username}'s profile picture`}
                    src={viewerPhotoURL ?? viewer.photoUrl}
                    avatar
                  />
                </S.Container>
              )
            }
            sections={sections}
          />
        </div>
        {impersonateModalOpen && (
          <ImpersonateUserModal
            onClose={closeImpersonateModal}
            onImpersonate={() => history.push(homePage())}
            onClick={e => {
              e.stopPropagation();
            }}
          />
        )}
        {limitConfigOpen && <UsageSimulationModal onClose={closeLimitConfig} />}
        {isViewTeamsModalOpen && (
          <ViewTeamsModal
            setIsModalOpen={setIsViewTeamsModalOpen}
            viewerEmail={userEmail}
            location={'user menu'}
          />
        )}
      </>
    );
  }
);

const ProfileMenu = memo(ProfileMenuComp);

export default ProfileMenu;

type UseProfileMenuSectionsParams = {
  openLimitConfig: () => void;
  openImpersonateModal: () => void;
  setIsViewTeamsModalOpen: (isViewTeamsModalOpen: boolean) => void;
  isViewTeamsModalOpen: boolean;
  showJoinTeamsContent: boolean;
  isSignup?: boolean;
  isFullyConnected?: boolean;
};

const isOperatorEnabled = () =>
  (window.CONFIG as any)?.OPERATOR_ENABLED ?? false;

function getAccountSettingsTabs(
  selectedAccount: Account | null,
  canSeeBillingTab: boolean,
  canSeeServiceAccountsTab: boolean
): NavMenuItem[] {
  if (!envIsPublicCloud) {
    return [];
  }
  if (selectedAccount?.accountType === AccountType.Personal) {
    return [
      {
        icon: <IconChartVerticalBars />,
        label: 'Usage',
        to: subscriptions(),
      },
    ];
  }
  return [
    {
      icon: <IconUserAuthor />,
      label: 'Users',
      to: accountSettingsMembersTab(selectedAccount?.name ?? ''),
    },
    {
      icon: <IconUsersTeam />,
      label: 'Teams',
      to: accountSettingsTeamsTab(selectedAccount?.name ?? ''),
    },
    canSeeServiceAccountsTab && {
      icon: <IconUsersTeam />,
      label: 'Service Accounts',
      to: accountSettingsServiceAccountsTab(selectedAccount?.name ?? ''),
    },
    canSeeBillingTab && {
      icon: <IconCreditCardPayment />,
      label: 'Billing',
      to: accountSettingsBillingTab(selectedAccount?.name ?? ''),
    },
    {
      icon: <IconSettings />,
      label: 'Settings',
      to: accountSettingsSettingsTab(selectedAccount?.name ?? ''),
    },
    {
      icon: <IconAddNew />,
      label: 'Invite users',
      onClick: () =>
        history.push(accountSettingsMembersTab(selectedAccount?.name ?? '')),
      variant: 'secondary',
    },
  ].filter(isNotNullUndefinedOrFalse);
}

function useProfileMenuSections({
  openLimitConfig,
  openImpersonateModal,
  setIsViewTeamsModalOpen,
  isViewTeamsModalOpen,
  showJoinTeamsContent,
  isSignup = false,
  isFullyConnected,
}: UseProfileMenuSectionsParams): NavMenuSection[] {
  const viewer = useViewer();
  const inAdminMode = useAdminModeActive();

  const {isOrgDashboardEnabled} = useIsOrgDashboardEnabled();

  const {autoRefreshEnabled, setAutoRefreshEnabled} = useAutoRefreshSettings();
  const {state: autoRefreshEnabledLocal, setState: setAutoRefreshEnabledLocal} =
    useSyncedState({
      stateToSyncWith: autoRefreshEnabled,
      setStateToSyncWith: setAutoRefreshEnabled,
    });
  const toggleAutoRefresh = useCallback(() => {
    setAutoRefreshEnabledLocal(!autoRefreshEnabledLocal);
  }, [autoRefreshEnabledLocal, setAutoRefreshEnabledLocal]);

  const {
    state: viewerUsingAdminPrivilegesLocal,
    setState: setViewerUsingAdminPrivilegesLocal,
  } = useSyncedState({
    stateToSyncWith: viewerUsingAdminPrivileges(),
    setStateToSyncWith: setViewerUsingAdminPrivileges,
  });
  const toggleViewerUsingAdminPrivileges = useCallback(() => {
    setViewerUsingAdminPrivilegesLocal(!viewerUsingAdminPrivilegesLocal);
    setTimeout(() => window.location.reload(), 500);
  }, [viewerUsingAdminPrivilegesLocal, setViewerUsingAdminPrivilegesLocal]);

  const openJoinTeamsModal = useCallback(() => {
    setIsViewTeamsModalOpen(!isViewTeamsModalOpen);
  }, [isViewTeamsModalOpen, setIsViewTeamsModalOpen]);

  const serverInfoQuery = useServerInfoQuery();
  const isGoogleIap = serverInfoQuery.data?.serverInfo?.isGoogleIap;

  const canLogout = !config.AUTH_STUB_JWT || config.AUTH_STUB_JWT === 'false';
  const enableAccountSelector = useRampFlagAccountSelector();
  const {selectedAccount} = useContext(AccountSelectorContext);
  const accountSelector = useAccountSelector();
  const {data: orgRoleData} = useProfileMenuOrgRoleInfoQuery({
    variables: {orgName: selectedAccount?.name ?? ''},
    skip:
      selectedAccount?.accountType === AccountType.Personal ||
      selectedAccount?.name == null,
  });
  const viewerMemberRole = getViewerOrganizationMemberRole(
    viewer,
    orgRoleData?.organization?.members
  );
  const canSeeBillingTab =
    inAdminMode ||
    viewerMemberRole === OrganizationMemberRole.ADMIN ||
    viewerMemberRole === OrganizationMemberRole.BILLING_ONLY;
  const {data: canSeeServiceAccountsTab} = useRampFlagOrgLevelServiceAccounts(
    selectedAccount?.name ?? ''
  );
  return useMemo(
    () =>
      removeNullUndefinedOrFalse([
        !isSignup && {
          header: viewer?.email,
          items: removeNullUndefinedOrFalse([
            {
              icon: <IconShareWith role="presentation" />,
              label: `Profile`,
              to: profilePage(viewer?.username ?? ``),
            },
            {
              icon: <IconSettingsParameters role="presentation" />,
              label: `User settings`,
              to: settings(),
            },

            isOrgDashboardEnabled && {
              icon: <IconDashboardBlackboard role="presentation" />,
              label: 'Organization dashboard',
              to: orgDashboard(),
            },
            {
              icon: (
                <Tooltip
                  content="Get updated in real-time without needing to reload the page."
                  className="tooltip"
                  basic
                  inverted
                  position="top left"
                  trigger={
                    <div className="tooltip-trigger">
                      <IconRandomizeAlt
                        className="refresh-icon"
                        role="presentation"
                      />
                      <span className="help-text underline-dashed">
                        Auto-refresh
                      </span>
                    </div>
                  }
                />
              ),
              label: '',
              onClick: toggleAutoRefresh,
              closeMenuOnClick: false,
              rightSide: (
                <S.Switch
                  checked={autoRefreshEnabledLocal}
                  data-test="auto-refresh-checkbox"
                />
              ),
              disableHover: true,
            },

            envIsPublicCloud &&
              !enableAccountSelector && {
                icon: <IconCreditCardPayment role="presentation" />,
                label: `Subscriptions`,
                to: subscriptions(),
              },

            showJoinTeamsContent && {
              icon: <IconUsersTeam role="presentation" />,
              label: `See new teams that you can join`,
              onClick: openJoinTeamsModal,
              variant: 'secondary',
            },
          ]),
        },

        enableAccountSelector &&
          !isFullyConnected && {
            header: `Account`,
            items: removeNullUndefinedOrFalse([
              envIsPublicCloud && {
                content: (
                  <AccountSelectorMenuItem
                    account={selectedAccount}
                    isSelectedAccount={true}
                  />
                ),
                variant: 'account-selector',
                disabled: true,
              },

              ...getAccountSettingsTabs(
                selectedAccount,
                canSeeBillingTab,
                canSeeServiceAccountsTab
              ),
            ]),
          },

        !isSignup &&
          viewer?.admin && {
            header: `W&B admin tools`,
            backgroundColor: globals.MOON_100,
            items: removeNullUndefinedOrFalse([
              envIsPublicCloud && {
                icon: <IconDocument role="presentation" />,
                label: `Admin pages`,
                to: adminHome(),
              },

              !envIsLocal && {
                icon: <IconMagicWandStick role="presentation" />,
                label: `Admin powers`,
                onClick: toggleViewerUsingAdminPrivileges,
                closeMenuOnClick: false,
                rightSide: (
                  <S.Switch checked={viewerUsingAdminPrivilegesLocal} />
                ),
              },

              inAdminMode &&
                !envIsLocal && {
                  icon: <IconJobCalculator role="presentation" />,
                  label: `Simulate usage`,
                  onClick: openLimitConfig,
                },

              inAdminMode &&
                (!envIsLocal || !!viewingAs()) && {
                  icon: <IconImpersonateMaskAlt role="presentation" />,
                  label: `Use as...`,
                  onClick: openImpersonateModal,
                },

              (!envIsLocal || !!viewingAs()) &&
                window.location.host !== 'localhost:9000' &&
                window.location.host !== 'app.wandb.test' && {
                  icon: <IconCodeAlt role="presentation" />,
                  label: `Open in dev environment`,
                  onClick: () => {
                    // we can't use the history API since we need to change the origin
                    const redirect = `http://localhost:9000${window.location.pathname}${window.location.search}`;
                    window.location.href = redirect; // eslint-disable-line
                  },
                },

              (!envIsLocal || !!viewingAs()) &&
                window.location.host !== 'wandb.ai' && {
                  icon: <IconCodeAlt role="presentation" />,
                  label: `Open in prod environment`,
                  onClick: () => {
                    // we can't use the history API since we need to change the origin
                    const redirect = `https://wandb.ai${window.location.pathname}${window.location.search}`;
                    window.location.href = redirect; // eslint-disable-line
                  },
                },

              envIsLocal &&
                !isOperatorEnabled() && {
                  icon: <IconSettingsParameters role="presentation" />,
                  label: `System settings`,
                  href: urlPrefixed(`/system-admin`, false),
                },

              envIsLocal &&
                isOperatorEnabled() && {
                  icon: <IconSettingsParameters role="presentation" />,
                  label: `System console`,
                  href: urlPrefixed(`/console/settings`, false),
                },

              envIsLocal &&
                !isOrgDashboardEnabled && {
                  icon: <IconShareWith role="presentation" />,
                  label: `Users`,
                  to: adminUsers(),
                },
            ]),
          },

        enableAccountSelector &&
          !isFullyConnected &&
          accountSelector.length > 0 && {
            header: `Other accounts`,
            items: removeNullUndefinedOrFalse(accountSelector),
          },

        canLogout && {
          items: removeNullUndefinedOrFalse([
            {
              icon: <IconLogOut role="presentation" />,
              label: `Log out`,
              onClick: () => auth.logout(),
              ...(isGoogleIap
                ? {
                    disabled: true,
                    tooltipText: `This instance is using Google IAP for authentication, so you'll need to log out of your Google account to log out of wandb.`,
                  }
                : {}),
            },
          ]),
        },
      ]),
    [
      isSignup,
      isFullyConnected,
      viewer?.username,
      viewer?.admin,
      isOrgDashboardEnabled,
      toggleAutoRefresh,
      autoRefreshEnabledLocal,
      showJoinTeamsContent,
      openJoinTeamsModal,
      toggleViewerUsingAdminPrivileges,
      viewerUsingAdminPrivilegesLocal,
      inAdminMode,
      openLimitConfig,
      openImpersonateModal,
      canLogout,
      isGoogleIap,
      accountSelector,
      enableAccountSelector,
      selectedAccount,
      canSeeBillingTab,
      canSeeServiceAccountsTab,
      viewer?.email,
    ]
  );
}
