import {Button, Radio} from '@wandb/weave/components';
import * as React from 'react';
import {useRef} from 'react';
import {twMerge} from 'tailwind-merge';
import {v4 as uuidv4} from 'uuid';

import {SettingOverrideCounts} from '../../../../state/views/workspaceSettings/settingOverrideUtils';
import {ClearSettingsRows} from '../components/ClearSettingsRow';
import {ResetSettingButton} from '../components/ResetSettingButton';
import {SettingLabel} from '../components/SettingLabel';
import {useClearSettingRows} from '../hooks/useClearSettingRows';
import {ClearSettingFnType} from '../hooks/useSettingOverrides';
import {
  DerivedPointVisualizationOption,
  POINT_VISUALIZATION_OPTIONS,
  PointVisualizationOptions,
  SettingLevel,
} from '../types';
import {arePointAggregationSettingsActive} from '../utils';

/**
 * Types of point visualization:
 * BucketingGorilla: this is new functionality using gorilla to bucket points without sampling. We ejected from Weave to do this for time, but long-term hope to move this to weave.

 * Sampling: this is the legacy functionality of doing sampling/bucketing clientside
 * SamplingByDefault: we need a way to differentiate downstream if a workspace has sampling
 * saved as a value or if we're just opting into it by default. If it's by default, we can 
 * selectively override it to enroll organizations or users into bucketing to drive enrollment
 */

type LinePlotPointAggregationProps = {
  className?: string;
  clearSetting?: ClearSettingFnType;
  displayValues: {
    pointVisualizationMethod: DerivedPointVisualizationOption;
  };
  numOverrides?: SettingOverrideCounts;
  onPointVisualizationMethodChange: (
    value: DerivedPointVisualizationOption
  ) => void;
  settingLevel: SettingLevel;
  values: {
    pointVisualizationMethod?: PointVisualizationOptions;
  };
};

export const LinePlotPointAggregation = ({
  className,
  clearSetting,
  displayValues,
  numOverrides,
  onPointVisualizationMethodChange,
  settingLevel,
  values,
}: LinePlotPointAggregationProps) => {
  // Generate stable and unique IDs
  const ref = useRef({
    samplingRadioId: uuidv4() + 'point-visualization-sampling',
    bucketingRadioId: uuidv4() + 'point-visualization-bucketing-gorilla',
  });

  const clearSettingRows = useClearSettingRows({
    numOverrides,
    settingKey: 'pointVisualizationMethod',
    settingLevel,
    clearSetting,
    emptySettings: {pointVisualizationMethod: undefined},
  });

  return (
    <fieldset className={twMerge('text-moon-800', className)}>
      <div className="flex items-center justify-between">
        <div className="center mb-4 flex flex-col">
          <div className="flex items-center gap-4">
            <SettingLabel className="w-fit" text="Point aggregation method" />
            <Button
              size="small"
              variant="ghost"
              icon="info"
              tooltipProps={{className: 'w-[20rem]'}}
              tooltip={
                <>
                  <div className="mb-12">
                    <strong>Random sampling</strong> displays a subset of points
                    to improve performance with large datasets.
                  </div>
                  <div>
                    <strong>Full fidelity</strong> shows all data points with
                    point aggregation across x-axis buckets. You can choose to
                    display min/max values of each bucket as a shaded area.
                  </div>
                </>
              }
            />
          </div>
          <p className="text-sm text-moon-500">
            Only applies to lines with more than 1,000 data points.
          </p>
        </div>
        <ResetSettingButton
          isEnabled={arePointAggregationSettingsActive(values)}
          onButtonClick={() =>
            clearSetting?.(settingLevel, 'pointVisualizationMethod', {
              pointVisualizationMethod: undefined,
            })
          }
          settingLevel={settingLevel}
        />
      </div>
      <Radio.Root
        aria-label="Point aggregation method"
        name="point-aggregation-method"
        onValueChange={onPointVisualizationMethodChange}
        value={displayValues.pointVisualizationMethod}>
        <div className="flex items-center justify-start">
          <Radio.Item
            id={ref.current.samplingRadioId}
            value={POINT_VISUALIZATION_OPTIONS.Sampling}>
            <Radio.Indicator />
          </Radio.Item>
          <label htmlFor={ref.current.samplingRadioId}>
            <span className="mx-8">Random sampling</span>
          </label>
        </div>
        <div className="flex items-center justify-start">
          <Radio.Item
            id={ref.current.bucketingRadioId}
            value={POINT_VISUALIZATION_OPTIONS.BucketingGorilla}>
            <Radio.Indicator />
          </Radio.Item>
          <label htmlFor={ref.current.bucketingRadioId}>
            <span className="mx-8">Full fidelity</span>
          </label>
        </div>
      </Radio.Root>
      <ClearSettingsRows rows={clearSettingRows} />
    </fieldset>
  );
};
