import React, {
  createContext,
  Dispatch,
  FC,
  ReactNode,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import {useIsInLoadedReportContext} from '../../pages/ReportPage/LoadedReportContext';
import {
  PanelControlsEvent,
  trackPanelControls as trackPanelControlsBase,
} from '../../services/analytics/panelControlsEvents';
import {usePart} from '../../state/views/hooks';
import * as PanelTypes from '../../state/views/panel/types';
import type {Ref as PanelBankSectionConfigRef} from '../../state/views/panelBankSectionConfig/types';
import {RunHistoryKeyInfo} from '../../types/run';
import {useDeepEqualValue} from '../../util/hooks';
import {LayedOutPanel} from '../../util/panelTypes';
import {PanelLoadingState} from '../PanelBank/PanelBankTiming';
import {OrganizedSettings} from '../PanelBank/types';
import {PanelPopupMode} from './types';

// TODO - as panels gets refactored some more, consider separating some of these out to a PanelBankContextProvider

interface PanelContextValue {
  isReadOnly?: boolean; // whether user can edit the panel
  isProjectReadOnly?: boolean; // whether user can edit project level resources (e.g. reports)
  panel: LayedOutPanel;
  panelBankSectionConfigRef: PanelBankSectionConfigRef | undefined;
  panelRef: PanelTypes.Ref;
  inheritedSettings?: OrganizedSettings;
  searchQuery?: string;
  loadingState?: PanelLoadingState;
  panelPopupMode?: PanelPopupMode;
  setPanelPopupMode: Dispatch<SetStateAction<PanelPopupMode | undefined>>;
  trackPanelControls: (event: Omit<PanelControlsEvent, 'page'>) => void;
}

export const PanelContext = createContext<PanelContextValue>({
  panelBankSectionConfigRef: {
    id: '',
    type: 'panel-bank-section-config',
    viewID: '',
  },
  panelRef: {
    id: '',
    type: 'panel',
    viewID: '',
  },
  panel: {} as LayedOutPanel,
  setPanelPopupMode: () => {},
  trackPanelControls: () => {},
});
PanelContext.displayName = 'PanelContext';

interface PanelContextProviderProps {
  children: ReactNode;
  historyKeyInfo?: RunHistoryKeyInfo; // Used in PanelRunsLinePlot
  isReadOnly?: boolean; // whether user can edit the panel
  isProjectReadOnly?: boolean; // whether user can edit project level resources (e.g. reports)
  /**
   * The panelbank section that contains this panel; used for actions like move/delete.
   * The section ref is optional because a panel may be rendered outside the context
   * of a section, in which case those actions would not be available.
   */
  panelBankSectionConfigRef?: PanelBankSectionConfigRef;
  panelRef: PanelTypes.Ref;
  inheritedSettings?: OrganizedSettings;
  loadingState?: PanelLoadingState;
}

/**
 * Panel components are shared between workspaces and reports, so this context provider
 * acts as a bridge between the two. Not all workspace related context providers wrap
 * around report panel grid, so using certain context hooks will result in an error.
 * PanelContextProvider exists to circumvent this,
 */
export const PanelContextProvider: FC<PanelContextProviderProps> = ({
  children,
  inheritedSettings,
  isReadOnly,
  isProjectReadOnly,
  loadingState,
  panelBankSectionConfigRef,
  panelRef,
}) => {
  const panel = usePart(panelRef);
  const deepEqualPanel = useDeepEqualValue(panel);
  const [panelPopupMode, setPanelPopupMode] = useState<PanelPopupMode>();

  const isInReport = useIsInLoadedReportContext();
  const trackPanelControls = useCallback(
    (event: Omit<PanelControlsEvent, 'page'>) => {
      trackPanelControlsBase({
        page: isInReport ? 'report' : 'workspace',
        ...event,
      });
    },
    [isInReport]
  );

  const state = useMemo<PanelContextValue>(
    () => ({
      isReadOnly,
      isProjectReadOnly,
      panelBankSectionConfigRef,
      panelRef,
      panel: deepEqualPanel,
      inheritedSettings,
      loadingState,
      panelPopupMode,
      setPanelPopupMode,
      trackPanelControls,
    }),
    [
      isReadOnly,
      isProjectReadOnly,
      panelBankSectionConfigRef,
      panelRef,
      deepEqualPanel,
      inheritedSettings,
      loadingState,
      panelPopupMode,
      setPanelPopupMode,
      trackPanelControls,
    ]
  );

  return (
    <PanelContext.Provider value={state}>{children} </PanelContext.Provider>
  );
};

export const usePanelContext = (): PanelContextValue => {
  const value = useContext(PanelContext);

  if (value == null) {
    throw new Error(
      'usePanelContext must be used within a PanelContextProvider'
    );
  }

  return value;
};
