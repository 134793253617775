import {AxisBottom} from '@visx/axis';
import {Group} from '@visx/group';
import {scaleBand, scaleLinear} from '@visx/scale';
import * as Shape from '@visx/shape';
import * as Stats from '@visx/stats';
import {Text} from '@visx/text';
import * as globals from '@wandb/weave/common/css/globals.styles';
import _ from 'lodash';
import React from 'react';

import {getAxisStyleForFontSize} from '../../../util/plotHelpers/plotFontSize';
import {getPlotMargin} from '../../../util/plotHelpers/plotHelpers';
import {BarChartVizProps} from './types';
import {numTicksForWidth} from './utils';

export const HorizontalBarChart = (
  props: BarChartVizProps & {
    parentWidth: number;
    parentHeight: number;
    min: number;
    max: number;
  }
) => {
  const {
    bars,
    parentWidth,
    parentHeight,
    mouseOver,
    mouseOut,
    boxPlot,
    violinPlot,
    yDomain,
  } = props;

  const xDomain = [props.min, props.max];

  const yKeys = bars.map(d => d.key);

  const margin = getPlotMargin({
    axisDomain: {yAxis: yDomain},
    axisType: {
      yAxis: 'linear',
    },
    axisValues: {yAxis: yKeys},

    fontSize: props.fontSize ?? 'small',
  });

  const axisFontStyles = getAxisStyleForFontSize(props.fontSize ?? 'small');

  margin.left = 32;
  margin.right = 32;

  const labelHeight = 24;
  const minimumBarHeight = 2;
  const minWidth = 0;
  const minHeight =
    (labelHeight + minimumBarHeight) * yDomain.length +
    margin.top +
    margin.bottom;

  const width = Math.max(parentWidth, minWidth);
  const height = Math.max(parentHeight, minHeight);

  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const spaceReservedPerBar = yMax / yDomain.length;
  const requiredPaddingForLabel = labelHeight / spaceReservedPerBar;
  const paddingBetweenBars = Math.max(0.25, requiredPaddingForLabel);
  const yScale = scaleBand({
    range: [0, yMax],
    round: true,
    domain: yDomain,
    padding: paddingBetweenBars,
  });

  const xScale = scaleLinear({
    range: [0, xMax],
    round: true,
    domain: xDomain,
  });

  return (
    <svg style={{display: 'block', width: '100%', height: '100%'}}>
      <Group top={margin.top} left={margin.left} key="chart">
        {bars.map((d, i) => {
          const barHeight = Math.max(0, yScale.bandwidth());
          let barX = Math.max(xScale(0), xScale(xDomain[0]), 0);

          const barY = yScale(i + 1) ?? 0;
          const constrainedHeight = Math.min(40, barHeight);
          const key = d.key + '--' + i.toString();

          if (violinPlot) {
            return (
              <Group key={key}>
                <Text
                  x={barX}
                  y={barY - 4}
                  fill={d.color ?? 'red'}
                  style={axisFontStyles}>
                  {d.key}
                </Text>
                <Stats.ViolinPlot
                  key={key}
                  data={d.bins ?? []}
                  count={data => data.count}
                  value={data => data.bin}
                  top={barY + barHeight / 2 - constrainedHeight / 2}
                  width={constrainedHeight} // not a bug - here width is "bar" width
                  valueScale={xScale}
                  fill={d.color ?? 'red'}
                  fillOpacity={0.5}
                  stroke={d.color ?? 'red'}
                  strokeWidth={1}
                  horizontal
                />
              </Group>
            );
          } else if (boxPlot) {
            return (
              <Group key={key}>
                <Text
                  x={barX}
                  y={barY - 4}
                  fill={d.color ?? 'red'}
                  style={axisFontStyles}>
                  {d.key}
                </Text>
                <Stats.BoxPlot
                  key={key}
                  min={d.quartiles != null ? d.quartiles[0] : d.value}
                  max={d.quartiles != null ? d.quartiles[4] : d.value}
                  median={d.quartiles != null ? d.quartiles[2] : d.value}
                  firstQuartile={d.quartiles != null ? d.quartiles[1] : d.value}
                  thirdQuartile={d.quartiles != null ? d.quartiles[3] : d.value}
                  top={barY + barHeight / 2 - constrainedHeight / 2}
                  fill={d.color ?? 'red'}
                  fillOpacity={0.5}
                  stroke={d.color ?? 'red'}
                  strokeWidth={1}
                  boxWidth={constrainedHeight}
                  valueScale={xScale}
                  horizontal
                  boxProps={{
                    onMouseOver: event => mouseOver && mouseOver(event, d),
                    onMouseLeave: event => mouseOut && mouseOut(),
                  }}
                />
              </Group>
            );
          } else {
            let barWidth = xScale(d.value) - barX;
            if (isNaN(barWidth)) {
              barWidth = 0;
            }
            if (barWidth < 0) {
              barWidth = Math.abs(barWidth);
              barX = barX - barWidth;
              if (barX < 0) {
                barWidth = Math.max(barWidth + barX, 0);
                barX = 0;
              }
            }
            return (
              <Group key={key}>
                <Text
                  x={barX}
                  y={barY - 4}
                  fill={d.color ?? 'red'}
                  style={axisFontStyles}>
                  {d.key}
                </Text>
                <Shape.Bar
                  key={key}
                  width={barWidth}
                  height={barHeight}
                  x={barX}
                  y={barY}
                  fill={d.color ?? 'red'}
                  onMouseOver={event => mouseOver && mouseOver(event, d)}
                  onMouseOut={event => mouseOut && mouseOut()}
                />
                {d.range != null &&
                  _.isFinite(d.range[0]) &&
                  _.isFinite(d.range[1]) && (
                    <Shape.Bar
                      key={key + 'error-bar'}
                      width={Math.max(
                        0,
                        xScale(d.range[1]) - xScale(d.range[0])
                      )}
                      height={1}
                      x={xScale(d.range[0])}
                      y={(barY ?? 0) + barHeight / 2}
                      fill={'black'}
                    />
                  )}
              </Group>
            );
          }
        })}
      </Group>
      <Group key="axis">
        <line
          x1={margin.left}
          y1={height - margin.bottom}
          x2={width - margin.right}
          y2={height - margin.bottom}
          stroke="#888"
          strokeWidth={0.5}
        />

        <AxisBottom
          top={height - margin.bottom}
          left={margin.left}
          scale={xScale}
          stroke={globals.gray500}
          strokeWidth={0.5}
          tickStroke="#b3b3b0"
          labelProps={{
            ...axisFontStyles,
            textAnchor: 'middle',
          }}
          tickLabelProps={(value: any, index: any) => ({
            ...axisFontStyles,
            textAnchor: 'middle',
            dx: '-0.25em',
            dy: '0.25em',
          })}
          numTicks={numTicksForWidth(width)}></AxisBottom>
      </Group>
    </svg>
  );
};
