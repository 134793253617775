import {LinePlotSettings} from '../../../components/WorkspaceDrawer/Settings/types';
import {PanelBankSectionConfigNormalized} from '../../../state/views/panelBankSectionConfig/types';
import {PanelWithConfig} from '../../../util/panelTypes';
import {Ref} from '../panel/types';

/**
 * Determines how many sections have a setting that overrides the
 * workspace setting. Note: section settings take precedence over workspace settings
 */
export const filteredOverrideSections = ({
  sections,
  linePlotSettings,
  areSettingsActive,
}: {
  sections: PanelBankSectionConfigNormalized[];
  linePlotSettings: LinePlotSettings | undefined;
  areSettingsActive: (settings: LinePlotSettings | undefined) => boolean;
}) => {
  // If settings hasn't been changed, then stop early
  if (linePlotSettings == null || !areSettingsActive(linePlotSettings)) {
    return [];
  }

  return sections.filter(section => {
    const linePlotSectionSettings = section?.sectionSettings?.linePlot;
    return areSettingsActive(linePlotSectionSettings);
  });
};

/**
 * Determines how many line plot panels have a setting that overrides the
 * workspace setting.
 * Note 1: panels settings take precedence over workspace settings.
 * Note 2: A majority of global settings only apply to runs line plots.
 */
export const filteredOverridePanels = ({
  panels,
  linePlotSettings,
  areSettingsActive,
}: {
  panels: (PanelWithConfig<'Run History Line Plot'> & {ref: Ref})[];
  linePlotSettings: LinePlotSettings | undefined;
  areSettingsActive: (settings: LinePlotSettings | undefined) => boolean;
}) => {
  // If workspace setting hasn't been changed, then stop early
  if (linePlotSettings == null || !areSettingsActive(linePlotSettings)) {
    return [];
  }

  return panels.filter(panel => {
    if (panel.viewType !== 'Run History Line Plot') {
      return false;
    }

    return areSettingsActive(panel.config);
  });
};
