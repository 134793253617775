import classNames from 'classnames';
import React from 'react';

function getColorClassName(fraction: number, isUnlimited: boolean) {
  if (isUnlimited || fraction < 0.9) {
    return 'bg-teal-500';
  }
  if (fraction >= 0.9 && fraction < 1.0) {
    return 'bg-gold-550';
  }
  return 'bg-red-500';
}

type Props = {
  fraction: number;
  isUnlimited: boolean;
  size?: 'small' | 'medium';
};

export const MeterBar = ({fraction, isUnlimited, size}: Props) => {
  const heightClassName = size === 'small' ? 'h-6' : 'h-[15px]';

  return (
    <div className={classNames('w-full rounded bg-moon-100', heightClassName)}>
      <div
        className={classNames(
          getColorClassName(fraction, isUnlimited),
          heightClassName,
          fraction >= 1 ? 'rounded' : 'rounded-l'
        )}
        style={{
          width: `${Math.max(0, Math.min(fraction * 100, 100))}%`,
        }}
      />
    </div>
  );
};
