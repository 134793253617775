import {EMPTY_PANEL_BANK_CONFIG} from '../../../util/panelbankConfigs';
import * as Normalize from '../normalize';
import * as PanelBankSectionConfigTypes from '../panelBankSectionConfig/types';
import {ActionType, ViewReducerState} from '../reducerSupport';
import * as WorkspaceSettingsTypes from '../workspaceSettings/types';
import * as Actions from './actions';
import * as Types from './types';

export const clearAllPanels = (
  state: ViewReducerState,
  panelBankConfigRef: Types.Ref,
  workspaceSettingsRef: WorkspaceSettingsTypes.Ref
): [ViewReducerState, ActionType] => {
  // track these values for undo
  const prevSectionRefs =
    state.parts[panelBankConfigRef.type][panelBankConfigRef.id].sectionRefs;
  const prevShouldAutoGeneratePanels =
    state.parts[workspaceSettingsRef.type][workspaceSettingsRef.id]
      .shouldAutoGeneratePanels;

  const {newParts, newSectionRefs} = EMPTY_PANEL_BANK_CONFIG.sections.reduce(
    (acc, section) => {
      const {parts, ref} = Normalize.addObjImmutable(
        acc.newParts,
        'panel-bank-section-config',
        panelBankConfigRef.viewID,
        section
      );
      acc.newParts = parts;
      acc.newSectionRefs.push(ref);
      return acc;
    },
    {
      newParts: state.parts,
      newSectionRefs: [] as PanelBankSectionConfigTypes.Ref[],
    }
  );

  const newState = {
    ...state,
    parts: {
      ...newParts,
      [panelBankConfigRef.type]: {
        ...newParts[panelBankConfigRef.type],
        [panelBankConfigRef.id]: {
          ...newParts[panelBankConfigRef.type][panelBankConfigRef.id],
          sectionRefs: newSectionRefs, // update pb config to use new sections
        },
      },
    },
  };

  const inverseAction = Actions.clearAllPanelsUndo(
    panelBankConfigRef,
    workspaceSettingsRef,
    prevSectionRefs
  );

  return [newState, inverseAction];
};

export const clearAllPanelsUndo = (
  state: ViewReducerState,
  panelBankConfigRef: Types.Ref,
  workspaceSettingsRef: WorkspaceSettingsTypes.Ref,
  sectionRefs: PanelBankSectionConfigTypes.Ref[]
): [ViewReducerState, ActionType] => {
  const newState = {
    ...state,
    parts: {
      ...state.parts,
      [panelBankConfigRef.type]: {
        ...state.parts[panelBankConfigRef.type],
        [panelBankConfigRef.id]: {
          ...state.parts[panelBankConfigRef.type][panelBankConfigRef.id],
          sectionRefs, // reverts sectionRefs
        },
      },
    },
  };

  const inverseAction = Actions.clearAllPanels(
    panelBankConfigRef,
    workspaceSettingsRef
  );

  return [newState, inverseAction];
};
