import ModifiedDropdown from '@wandb/weave/common/components/elements/ModifiedDropdown';
import {notEmpty} from '@wandb/weave/common/util/obj';
import React, {FC, useMemo} from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Dropdown, Input, Label} from 'semantic-ui-react';

import {NULL_STRING} from '../../util/constants';
import * as Filter from '../../util/filters';
import * as FilterTypes from '../../util/filterTypes';
import * as Run from '../../util/runs';
import * as RunTypes from '../../util/runTypes';
import {tryJSONParse} from '../../util/try_json_parse';
import {beautify, Option} from '../../util/uihelpers';
import {DatePickerComp} from '../DatePicker';

interface FilterValueSelectorDateProps {
  value: string | null;
  setFilter(
    filter: Partial<FilterTypes.IndividualFilter<FilterTypes.FilterKey>>
  ): void;
}

export const FilterValueSelectorDate: FC<FilterValueSelectorDateProps> =
  React.memo(({value, setFilter}) => {
    return (
      <DatePickerComp
        className="filter-list__value"
        value={value ? new Date(value) : new Date()}
        onChange={(date: Date | null) =>
          date != null &&
          setFilter({
            value: date?.toISOString(),
            disabled: false,
          })
        }
      />
    );
  });

// Sets any unit to seconds
const toSeconds = (value: string, unit: FilterTypes.TimeOption) => {
  return (
    parseFloat(value) * Filter.checkedGetTimeOptionData(unit).numSeconds
  ).toString();
};

// Sets seconds to units
const toUnit = (secondsValue: string, unit: FilterTypes.TimeOption): string => {
  return (
    parseFloat(secondsValue) / Filter.checkedGetTimeOptionData(unit).numSeconds
  ).toString();
};

// Convert n units(from) : n units(to)
// Keeping n the same but adjusting units
export const convert = (
  value: string,
  from: FilterTypes.TimeOption,
  to: FilterTypes.TimeOption
): string => {
  const initialValue = parseFloat(value);
  return (
    (initialValue / Filter.checkedGetTimeOptionData(from).numSeconds) *
    Filter.checkedGetTimeOptionData(to).numSeconds
  ).toString();
};

const getOptionsList = (minimumUnit: FilterTypes.TimeOption | null) => {
  return Filter.getSortedTimeOptions(minimumUnit).map(([unit, data]) => ({
    key: unit,
    text: data.displayText,
    value: unit,
  }));
};

interface FilterValueSelectorTimeProps {
  value: string | null;
  meta?: FilterTypes.FilterMeta;
  minimumUnit: FilterTypes.TimeOption | null;
  setFilter(
    filter: Partial<FilterTypes.IndividualFilter<FilterTypes.FilterKey>>
  ): void;
}

export const FilterValueSelectorTime: FC<FilterValueSelectorTimeProps> =
  React.memo(({value, meta, minimumUnit, setFilter}) => {
    const defaultUnit = minimumUnit || 'seconds';
    const unit: FilterTypes.TimeOption = meta ? meta.unit : defaultUnit;
    const val = value || '0';
    const displayV = toUnit(val, unit);

    return (
      <React.Fragment>
        <Input
          className="filter-list__value filter-list__time"
          fluid
          value={displayV}
          onChange={(e, res) => {
            // Take input without text and fails it.
            if (isNaN(parseFloat(res.value))) {
              return;
            }
            setFilter({value: toSeconds(res.value, unit)});
          }}
        />
        <Dropdown
          className="filter-dropdown filter-list__value"
          options={getOptionsList(minimumUnit)}
          value={unit}
          onChange={(e, res) => {
            const unitValue = res.value as FilterTypes.TimeOption;
            setFilter({
              value: convert(val, unit, unitValue),
              meta: {unit: unitValue},
              disabled: false,
            });
          }}
          inline
        />
      </React.Fragment>
    );
  });

interface FilterTagOpSelectorProps {
  keys: string[];
  filter: FilterTypes.IndividualFilter<FilterTypes.FilterKey>;
  setFilter(filter: FilterTypes.Filter<FilterTypes.FilterKey>): void;
}

export const FilterTagOpSelector: FC<FilterTagOpSelectorProps> = React.memo(
  ({keys, filter, setFilter}) => {
    const tagNames = keys
      .map(tagKey => {
        const key = Run.keyFromString(tagKey);
        return key && key.name;
      })
      .filter(notEmpty);
    let currentValue: string;
    if (filter.op === 'IN') {
      currentValue = 'IN';
    } else if (filter.op === 'NIN') {
      currentValue = 'NOT IN';
    } else {
      currentValue = (
        filter as FilterTypes.IndividualFilter<FilterTypes.FilterKey>
      ).value
        ? 'set'
        : 'notset';
    }
    return (
      <Dropdown
        className="filter-dropdown filter-list__operation"
        data-test="filter-operation"
        options={[
          {key: 'set', text: 'is', value: 'set'},
          {key: 'notset', text: 'is not', value: 'notset'},
          {key: 'IN', text: 'IN', value: 'IN'},
          {key: 'NIN', text: 'NOT IN', value: 'NOT IN'},
        ]}
        placeholder="value"
        search
        inline
        value={currentValue}
        onChange={(e, {value}) => {
          const tagName =
            filter.key.name === '*' ? tagNames[0] : filter.key.name;
          if (value === 'set') {
            setFilter({
              ...filter,
              key: {section: 'tags', name: tagName},
              op: '=',
              value: true,
            });
          } else if (value === 'notset') {
            setFilter({
              ...filter,
              key: {section: 'tags', name: tagName},
              op: '!=',
              value: false,
            });
          } else if (value === 'IN') {
            setFilter({
              ...filter,
              key: {section: 'tags', name: '*'},
              op: 'IN',
              value: [],
            });
          } else {
            setFilter({
              ...filter,
              key: {section: 'tags', name: '*'},
              op: 'NIN',
              value: [],
            });
          }
        }}
      />
    );
  }
);

interface FilterValueSelectorProps<K extends FilterTypes.FilterKey> {
  loading: boolean;
  suggestions: Array<{
    text: string;
    key: string | number;
    value: RunTypes.Value;
    count: number;
  }>;
  filter: FilterTypes.IndividualFilter<K>;
  setFilter(filter: FilterTypes.IndividualFilter<K>): void;
  isValid?: boolean;
}

export type FilterValueSelectorCreatorProps<K extends FilterTypes.FilterKey> =
  Omit<FilterValueSelectorProps<K>, 'suggestions' | 'loading'>;

export function filterValueOptionTransform(o: Option) {
  return {
    ...beautify(o),
    content: (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}>
        <span
          style={{
            display: 'inline-block',
            whiteSpace: 'normal',
          }}>
          {o.text}
        </span>
        {o.text !== '*' && <Label className="count-label">{o.count}</Label>}
      </div>
    ),
  };
}

export const FilterValueSelector = <K extends FilterTypes.FilterKey>(
  props: FilterValueSelectorProps<K>
) => {
  const currentValue = props.filter.value;
  const isBlank = !currentValue || currentValue === NULL_STRING;
  const isMulti = Filter.isMultiValue(props.filter);

  const {suggestions} = props;
  const options = useMemo(
    () =>
      suggestions.map(s => ({
        ...s,
        value: JSON.stringify(s.value),
        key: s.text,
      })),
    [suggestions]
  );

  return (
    <ModifiedDropdown
      className={`filter-dropdown filter-list__value ${
        isBlank ? 'filter-dropdown--blank' : ''
      }`}
      loading={props.loading}
      data-test="filter-value"
      style={{
        flexWrap: 'wrap',
        flexGrow: 1,
        maxWidth: 400,
      }}
      additionLabel=""
      allowAdditions={true} // We do not assume the server always returns all possible values
      options={options}
      optionTransform={filterValueOptionTransform}
      placeholder="value"
      search
      // fluid
      floating
      // This is a workaround for a bug.
      // Active and value get out of sync
      // It's probably in semantic, but could also be caused by setFilter
      key={(props.filter.value || 0).toString()}
      multiple={Filter.isMultiValue(props.filter)}
      value={
        isMulti
          ? (currentValue as any[]).map(v => JSON.stringify(v))
          : JSON.stringify(currentValue)
      }
      onChange={(e, {value}) => {
        if (value) {
          if (isMulti) {
            if (Array.isArray(value)) {
              props.setFilter({
                ...props.filter,
                value: value.map(tryJSONParse),
                disabled: false,
              } as FilterTypes.IndividualFilter<K>);
            }
          } else {
            props.setFilter({
              ...props.filter,
              value: tryJSONParse(value as string),
              disabled: false,
            } as FilterTypes.IndividualFilter<K>);
          }
        }
      }}
    />
  );
};
