import ModifiedDropdown from '@wandb/weave/common/components/elements/ModifiedDropdown';
import {isArray, isEqual, isString, last} from 'lodash';
import React, {useCallback, useEffect, useMemo, useState} from 'react';

import {trackSetting} from '../../services/analytics/workspaceSettingsEvents';
import {prettifyMetricName} from '../../util/plotHelpers/prettifyMetricName';
import {useTrackSettingPage} from '../WorkspaceDrawer/Settings/hooks/useTrackSettingEvent';
// eslint-disable-next-line import/no-cycle -- please fix if you can
import {MetricsPickerProps, MetricsRegexToggle} from './MetricsPicker';

export const MetricsPickerDropdown = (props: MetricsPickerProps) => {
  const {config, options, keyTypes, updateConfig} = props;
  const trackSettingPage = useTrackSettingPage();

  const [selectedValues, setSelectedValues] = useState(config.metrics);
  const {dropdownOptions} = useYAxisDropdownOptions(options, keyTypes);
  const newMetrics = React.useMemo(
    () => getUpdatedDropdownMetrics([options[0]], keyTypes),
    [options, keyTypes]
  );
  /**
   * Whenever we get metrics that don't match selected values, update the selected values
   * this keeps the component synced to whatever the spec is
   */
  useEffect(
    () => {
      if (!isEqual(selectedValues, config.metrics)) {
        setSelectedValues(config.metrics);
      }
    },
    // we only want to update this when a new value comes in as props
    // selected values will eagerly updated on change
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [config.metrics]
  );

  // Set a nice default metric if possible
  useEffect(() => {
    if (selectedValues == null && options.length > 0) {
      if (!isEqual(newMetrics, config.metrics)) {
        updateConfig({
          metrics: newMetrics,
        });
      }
    }
  }, [
    config.metrics,
    newMetrics,
    options.length,
    selectedValues,
    updateConfig,
  ]);

  const onDropdownChange = useCallback(
    (_, {value: newValue}) => {
      if (isArray(newValue) && newValue.every(isString)) {
        const updatedMetrics = getUpdatedDropdownMetrics(newValue, keyTypes);
        setSelectedValues(updatedMetrics);
        updateConfig({
          metrics: updatedMetrics,
        });

        trackSetting({
          action: 'change',
          page: trackSettingPage,
          settingLevel: 'panel',
          setting: 'yAxis',
          settingValue: updatedMetrics.toString(),
          additionalProperties: {
            metrics: updatedMetrics,
          },
        });
      }
    },
    [keyTypes, trackSettingPage, updateConfig]
  );

  return (
    <div className="metrics-picker">
      <ModifiedDropdown
        allowRegexSearch
        className="with-button regex-dropdown"
        data-test="y-axis"
        lazyLoad
        multiple
        onChange={onDropdownChange}
        options={dropdownOptions}
        placeholder="Select metrics to visualize"
        search
        selection
        value={selectedValues}
      />
      <MetricsRegexToggle {...props} />
    </div>
  );
};

const getUpdatedDropdownMetrics = (
  newMetrics: string[],
  keyTypes: MetricsPickerProps['keyTypes']
) => {
  let updateConfigMetrics = newMetrics;

  // Histograms cannot be displayed alongside other metrics, including other histograms.
  // If there is a histogram present, overwrite the entire list of metrics
  // with the one just added by the user.
  // This ensures that a histogram metric will never coexist with another metric.
  if (newMetrics.some(k => keyTypes[k] === 'histogram')) {
    updateConfigMetrics = [last(newMetrics)!];
  }
  return updateConfigMetrics;
};

const sysRegex = /^system/;
const bottomSortMetrics = new Set([
  '_runtime',
  '_step',
  '_timestamp',
  '_absolute_runtime',
]);

// because there can be a lot of metrics, beware tests in this fn that aren't O(1)
const score = (opt: string, keyTypes: MetricsPickerProps['keyTypes']) => {
  if (bottomSortMetrics.has(opt)) {
    // put the reserved metrics last
    return -3;
  } else if (sysRegex.test(opt)) {
    // put the system metrics next to last
    return -2;
  } else if (keyTypes != null && keyTypes[opt] !== 'number') {
    // put the histograms next to last
    return -1;
  }

  // put the nice regular metrics first
  return 0;
};

function getKeyTypeIcon(opt: string, keyTypes: MetricsPickerProps['keyTypes']) {
  if (keyTypes[opt] === 'number') {
    return 'hashtag';
  }
  return 'list';
}

function getKeyTypeText(opt: string, keyTypes: MetricsPickerProps['keyTypes']) {
  const prettyMetricName = prettifyMetricName(opt);
  if (keyTypes[opt] === 'number') {
    return prettyMetricName;
  }
  return prettyMetricName + ' (Histogram)';
}

function useYAxisDropdownOptions(
  options: MetricsPickerProps['options'],
  keyTypes: MetricsPickerProps['keyTypes']
) {
  const dropdownOptions = useMemo(
    () =>
      options
        .sort((a, b) => score(b, keyTypes) - score(a, keyTypes))
        .map(opt => ({
          icon: getKeyTypeIcon(opt, keyTypes),
          text: getKeyTypeText(opt, keyTypes),
          value: opt,
          key: opt,
        })),
    [keyTypes, options]
  );

  return {dropdownOptions};
}
