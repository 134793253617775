import {Tailwind} from '@wandb/weave/components/Tailwind';
import classNames from 'classnames';
import React, {useEffect, useRef, useState} from 'react';

import {DateFormat, format} from '../util/date';

type Props = {
  value?: Date;
  placeholder?: string;
  onChange: (value: Date | null) => void;
  maxDate?: Date;
  minDate?: Date;
  className?: string;
  timeZone?: string;
};

/**
 * This component was created as a replacement for `react-datetime` because moment is deprecated, and we want to remove it as a
 * dependency. This uses the standard <input type="date" /> component with some extra features to attempt to keep parity with
 * previous functionality.
 *
 * Known issues/feature gaps:
 *   - Clear does not trigger the onBlur or the onChange event if the value is already null. So the placeholder does not show.
 *   - Does not support typing in dates, only using the date picker (to simplify input validation for now)
 *   - Only supports local time (not selecting a date in UTC for example)
 *   - Backspace only works to clear it if the datepicker isn't open and only the input itself is focused. The date picker itself doesn't have a onKeyDown event we can capture.
 *   - The standard calendar icon can't be hidden in the input. Styling within the input and calendar isn't particularly easy.
 */
export const DatePickerComp = ({
  value,
  placeholder,
  onChange,
  className,
  maxDate,
  minDate = new Date(0),
  timeZone,
}: Props) => {
  // value must be in format yyyy-mm-dd https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/date
  const valueString =
    value !== undefined
      ? format(value, DateFormat.YEAR_MONTH_DAY, timeZone)
      : undefined;

  // Used to allow for placeholders until it's in focus
  const ref = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (ref.current) {
      // placeholders don't work on input type="date", so show a input type="text" (which supports placeholders) if the value isn't defined
      ref.current.type =
        placeholder === undefined || value !== undefined ? 'date' : 'text';
    }
  }, [value, placeholder]);

  const handleFocus = () => {
    if (ref?.current) {
      if (placeholder != null) {
        // even if the value is non-defined, we want to show the date picker, so change the type to date.
        ref.current.type = 'date';
      }
      try {
        // @ts-ignore it does exist
        ref.current.showPicker();
      } catch (e) {
        // swallow NotAllowedError errors from tabbing between tabs and refocusing (which isn't detected as a user event)
      }
    }
  };
  const [key, setKey] = useState(0);

  return (
    <Tailwind style={{width: '100%'}}>
      <input
        key={key} // needed so that onChange event from onKeyDown is recognized and updates the component
        ref={ref}
        tabIndex={0}
        className={classNames(className, 'w-full pl-4 caret-transparent')}
        aria-label="Date"
        placeholder={placeholder}
        value={valueString}
        onFocus={handleFocus}
        onClick={handleFocus}
        onBlur={() => {
          if (value === undefined && placeholder != null && ref.current) {
            ref.current.type = 'text';
          }
        }}
        onKeyDown={e => {
          // only allow selections from the calendar, or focusing by tabbing/pressing enter in and out.
          if (e.key === 'Backspace') {
            onChange(null);
            setKey(prev => prev + 1);
            e.preventDefault();
          } else if (e.key === 'Enter' && ref.current) {
            ref.current.type = 'date';
            // @ts-ignore it does exist
            ref.current.showPicker();
          } else if (ref.current?.type === 'text') {
            // prevent typing in the input
            e.preventDefault();
          }
        }}
        onChange={event => {
          const newValue = event.target.value;
          const [inputYear, inputMonth, inputDay] = newValue.split('-');

          const newYear = parseInt(inputYear);
          const newMonth = parseInt(inputMonth) - 1;
          const newDay = parseInt(inputDay);
          if ([newYear, newMonth, newDay].every(num => !Number.isNaN(num))) {
            const newDate = new Date(newYear, newMonth, newDay);
            onChange(newDate);
          } else {
            onChange(null);
          }
        }}
        min={
          minDate !== undefined
            ? format(minDate, DateFormat.YEAR_MONTH_DAY)
            : undefined
        }
        max={
          maxDate !== undefined
            ? format(maxDate, DateFormat.YEAR_MONTH_DAY)
            : undefined
        }
      />
    </Tailwind>
  );
};
