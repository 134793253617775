// THIS IS A GENERATED FILE, DO NOT EDIT IT! (see codegen.yml)
/* eslint-disable */
import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '../state/graphql/apolloWithAbort';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;


/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  Duration: number;
  Int64: any;
  JSON: any;
  JSONString: any;
};










export type AccessToken = {
  __typename: 'AccessToken';
  id: Scalars['ID'];
  token: Scalars['String'];
  view: View;
  type: AccessTokenType;
  emails: Array<Scalars['String']>;
  createdBy: User;
  createdAt: Scalars['DateTime'];
  lastAccessedAt?: Maybe<Scalars['DateTime']>;
  revokedAt?: Maybe<Scalars['DateTime']>;
  projects: Array<Project>;
};

export enum AccessTokenType {
  Public = 'PUBLIC',
  Restricted = 'RESTRICTED',
  IndividualSharing = 'INDIVIDUAL_SHARING'
}

export type AckJobSetItemInput = {
  jobSetId: Scalars['ID'];
  jobSetItemId: Scalars['ID'];
  agentId: Scalars['ID'];
  runName: Scalars['String'];
};

export type AckJobSetItemPayload = {
  __typename: 'AckJobSetItemPayload';
  success: Scalars['Boolean'];
};

export type AckRunQueueItemInput = {
  runQueueItemId: Scalars['ID'];
  runName: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AckRunQueueItemPayload = {
  __typename: 'AckRunQueueItemPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ActiveExperiment = Node & {
  __typename: 'ActiveExperiment';
  id: Scalars['ID'];
  name: Scalars['String'];
  activeVariants: Array<ActiveVariant>;
  experimentType: ExperimentType;
  startAt?: Maybe<Scalars['DateTime']>;
  endAt?: Maybe<Scalars['DateTime']>;
};

export type ActiveVariant = {
  __typename: 'ActiveVariant';
  bucket: Scalars['Int'];
  allocation: Scalars['Int'];
};

export type ActivityDuration = {
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
};

export type AddAliasesInput = {
  aliases: Array<ArtifactCollectionAliasInput>;
  artifactID: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddAliasesPayload = {
  __typename: 'AddAliasesPayload';
  artifact: Artifact;
  aliases: Array<ArtifactCollectionAlias>;
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddFilesInput = {
  names: Array<Maybe<Scalars['String']>>;
  modelName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  bucketName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddFilesPayload = {
  __typename: 'AddFilesPayload';
  files?: Maybe<FileConnection>;
  clientMutationId?: Maybe<Scalars['String']>;
};


export type AddFilesPayloadFilesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type AddProtectedAliasesInput = {
  entityName: Scalars['String'];
  aliases: Array<Scalars['String']>;
  adminType: WorkflowsAdminType;
  clientMutationID?: Maybe<Scalars['String']>;
};

export type AddProtectedAliasesPayload = {
  __typename: 'AddProtectedAliasesPayload';
  aliases: Array<Scalars['String']>;
  clientMutationID?: Maybe<Scalars['String']>;
};

export type AddRolePermissionsInput = {
  roleID: Scalars['ID'];
  permissions: Array<Scalars['String']>;
};

export type AddRolePermissionsPayload = {
  __typename: 'AddRolePermissionsPayload';
  success: Scalars['Boolean'];
};

export type AddUserToOrganizationInput = {
  userName: Scalars['String'];
  teams?: Maybe<Array<Scalars['String']>>;
  organizationId: Scalars['ID'];
  userOrgRole: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddUserToOrganizationPayload = {
  __typename: 'AddUserToOrganizationPayload';
  success: Scalars['Boolean'];
  emailSent: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AddWorkflowsAdminInput = {
  entityName: Scalars['String'];
  username?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['ID']>;
  adminType: WorkflowsAdminType;
  clientMutationID?: Maybe<Scalars['String']>;
};

export type AddWorkflowsAdminPayload = {
  __typename: 'AddWorkflowsAdminPayload';
  success: Scalars['Boolean'];
  clientMutationID?: Maybe<Scalars['String']>;
};

export type Agent = Node & {
  __typename: 'Agent';
  id: Scalars['ID'];
  name: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  host: Scalars['String'];
  persistent?: Maybe<Scalars['Boolean']>;
  sweep: Sweep;
  user?: Maybe<User>;
  metrics: Scalars['JSONString'];
  createdAt: Scalars['DateTime'];
  heartbeatAt?: Maybe<Scalars['DateTime']>;
  displayName?: Maybe<Scalars['String']>;
  totalRuns: Scalars['Int'];
  runs: RunConnection;
};


export type AgentRunsArgs = {
  order?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type AgentConnection = {
  __typename: 'AgentConnection';
  pageInfo: PageInfo;
  edges: Array<AgentEdge>;
};

export type AgentEdge = {
  __typename: 'AgentEdge';
  node: Agent;
  cursor: Scalars['String'];
};

export type AgentHeartbeatInput = {
  id: Scalars['ID'];
  metrics?: Maybe<Scalars['JSONString']>;
  runState?: Maybe<Scalars['JSONString']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AgentHeartbeatPayload = {
  __typename: 'AgentHeartbeatPayload';
  agent?: Maybe<Agent>;
  commands?: Maybe<Scalars['JSONString']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum Aggregation {
  Max = 'MAX',
  Min = 'MIN',
  Average = 'AVERAGE',
  Sum = 'SUM',
  Count = 'COUNT'
}

export type AggregationKey = {
  key: Scalars['String'];
  aggregation: Aggregation;
};

export type Alert = {
  __typename: 'Alert';
  id: Scalars['ID'];
  condition: AlertCondition;
  subscriptions: Array<AlertSubscription>;
  events: AlertEventConnection;
};


export type AlertEventsArgs = {
  from: Scalars['DateTime'];
  to?: Maybe<Scalars['DateTime']>;
  order?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type AlertCondition = FinishedRunCondition | StoppedRunCondition | ScriptableRunCondition;

export type AlertEvent = {
  __typename: 'AlertEvent';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
};

export type AlertEventConnection = {
  __typename: 'AlertEventConnection';
  pageInfo: PageInfo;
  edges: Array<AlertEventEdge>;
};

export type AlertEventEdge = {
  __typename: 'AlertEventEdge';
  node?: Maybe<AlertEvent>;
  cursor: Scalars['String'];
};

export enum AlertSeverity {
  Info = 'INFO',
  Warn = 'WARN',
  Error = 'ERROR'
}

export type AlertSubscription = {
  id: Scalars['ID'];
};

export type ApiKey = {
  __typename: 'ApiKey';
  ips: Array<Scalars['String']>;
  rateLimit?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type ApiKeyConnection = {
  __typename: 'ApiKeyConnection';
  pageInfo: PageInfo;
  edges: Array<ApiKeyEdge>;
};

export type ApiKeyEdge = {
  __typename: 'ApiKeyEdge';
  node?: Maybe<ApiKey>;
  cursor: Scalars['String'];
};

export type Artifact = {
  __typename: 'Artifact';
  id: Scalars['ID'];
  digest: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  commitHash?: Maybe<Scalars['String']>;
  versionIndex?: Maybe<Scalars['Int']>;
  aliases: Array<ArtifactAlias>;
  /** @deprecated Field no longer supported */
  labels?: Maybe<Scalars['JSONString']>;
  metadata?: Maybe<Scalars['JSONString']>;
  state: ArtifactState;
  size: Scalars['Int64'];
  storageBytes: Scalars['Int64'];
  fileCount: Scalars['Int64'];
  artifactType: ArtifactType;
  artifactCollections: ArtifactCollectionConnection;
  artifactMemberships: ArtifactCollectionMembershipConnection;
  artifactSequence: ArtifactSequence;
  /** @deprecated Field no longer supported */
  artifactActions?: Maybe<ArtifactActionConnection>;
  dependsOn: ArtifactConnection;
  dependencyOf: ArtifactConnection;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<Initiator>;
  usedCount: Scalars['Int'];
  usedBy: RunConnection;
  currentManifest?: Maybe<ArtifactManifest>;
  files: FileConnection;
  filesByManifestEntries: FileConnection;
  historyStep?: Maybe<Scalars['Int64']>;
  artifactLineageDag?: Maybe<ArtifactLineageDag>;
  ttlDurationSeconds: Scalars['Int64'];
  ttlIsInherited: Scalars['Boolean'];
  isGenerated: Scalars['Boolean'];
  isLinkedToGlobalRegistry: Scalars['Boolean'];
  tags: Array<Tag>;
};


export type ArtifactCommitHashArgs = {
  artifactCollectionName?: Maybe<Scalars['String']>;
};


export type ArtifactVersionIndexArgs = {
  artifactCollectionName?: Maybe<Scalars['String']>;
};


export type ArtifactAliasesArgs = {
  artifactCollectionName?: Maybe<Scalars['String']>;
};


export type ArtifactArtifactActionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ArtifactDependsOnArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ArtifactDependencyOfArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ArtifactUsedByArgs = {
  filters?: Maybe<Scalars['JSONString']>;
  order?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ArtifactFilesArgs = {
  names?: Maybe<Array<Scalars['String']>>;
  contentType?: Maybe<Array<Scalars['String']>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ArtifactFilesByManifestEntriesArgs = {
  entries?: Maybe<Array<ArtifactManifestEntryInput>>;
  manifestVersion: Scalars['String'];
  storageLayout: Scalars['String'];
  storageRegion?: Maybe<Scalars['String']>;
};


export type ArtifactArtifactLineageDagArgs = {
  limit: Scalars['Int'];
  filterGeneratedArtifacts: Scalars['Boolean'];
  clusterSize?: Maybe<Scalars['Int']>;
  directLineage?: Maybe<Scalars['Boolean']>;
  traversalDirection?: Maybe<TraversalDirection>;
  baseGraphNodeIDs?: Maybe<Array<Scalars['String']>>;
};

export type ArtifactAction = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  artifact: Artifact;
  initiator: Initiator;
};

export type ArtifactActionConnection = {
  __typename: 'ArtifactActionConnection';
  pageInfo: PageInfo;
  edges: Array<ArtifactActionEdge>;
};

export type ArtifactActionEdge = {
  __typename: 'ArtifactActionEdge';
  node: ArtifactAction;
  cursor: Scalars['String'];
};

export type ArtifactAlias = {
  __typename: 'ArtifactAlias';
  id: Scalars['ID'];
  artifact?: Maybe<Artifact>;
  artifactCollection?: Maybe<ArtifactCollection>;
  artifactCollectionName: Scalars['String'];
  alias: Scalars['String'];
};

export type ArtifactAliasAction = {
  __typename: 'ArtifactAliasAction';
  alias: Scalars['String'];
  actionType: Scalars['String'];
  artifactCollection: ArtifactCollection;
  sourceArtifact?: Maybe<Artifact>;
  targetArtifact?: Maybe<Artifact>;
  createdAt: Scalars['DateTime'];
  user?: Maybe<User>;
};

export type ArtifactAliasActionConnection = {
  __typename: 'ArtifactAliasActionConnection';
  pageInfo: PageInfo;
  edges: Array<ArtifactAliasActionEdge>;
};

export type ArtifactAliasActionEdge = {
  __typename: 'ArtifactAliasActionEdge';
  node: ArtifactAliasAction;
  cursor: Scalars['String'];
};

export type ArtifactAliasConnection = {
  __typename: 'ArtifactAliasConnection';
  pageInfo: PageInfo;
  edges: Array<ArtifactAliasEdge>;
};

export type ArtifactAliasEdge = {
  __typename: 'ArtifactAliasEdge';
  node?: Maybe<ArtifactAlias>;
  cursor: Scalars['String'];
};

export type ArtifactAliasInput = {
  artifactCollectionName: Scalars['String'];
  alias: Scalars['String'];
};

export type ArtifactCollection = {
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  project?: Maybe<Project>;
  state: ArtifactCollectionState;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultArtifactType: ArtifactType;
  artifactCollectionActions: ArtifactCollectionActionConnection;
  aliasActionHistory: ArtifactAliasActionConnection;
  /** @deprecated Field no longer supported */
  artifactActions?: Maybe<ArtifactActionConnection>;
  artifacts?: Maybe<VersionedArtifactConnection>;
  aliases: ArtifactAliasConnection;
  artifactMemberships: ArtifactCollectionMembershipConnection;
  artifactMembership?: Maybe<ArtifactCollectionMembership>;
  triggers: TriggerConnection;
  tags: TagConnection;
  hasArtifactVersionLinkedToGlobalRegistry: Scalars['Boolean'];
};


export type ArtifactCollectionArtifactCollectionActionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
};


export type ArtifactCollectionAliasActionHistoryArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
};


export type ArtifactCollectionArtifactActionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ArtifactCollectionArtifactsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
};


export type ArtifactCollectionAliasesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ArtifactCollectionArtifactMembershipsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ArtifactCollectionArtifactMembershipArgs = {
  aliasName: Scalars['String'];
};


export type ArtifactCollectionTriggersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ArtifactCollectionTagsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  tagCategoryName?: Maybe<Scalars['String']>;
};

export type ArtifactCollectionAction = {
  __typename: 'ArtifactCollectionAction';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  artifact?: Maybe<Artifact>;
  artifactCollection?: Maybe<ArtifactCollection>;
  user?: Maybe<User>;
  actionType: Scalars['String'];
};

export type ArtifactCollectionActionConnection = {
  __typename: 'ArtifactCollectionActionConnection';
  pageInfo: PageInfo;
  edges: Array<ArtifactCollectionActionEdge>;
};

export type ArtifactCollectionActionEdge = {
  __typename: 'ArtifactCollectionActionEdge';
  node: ArtifactCollectionAction;
  cursor: Scalars['String'];
};

export type ArtifactCollectionAlias = {
  __typename: 'ArtifactCollectionAlias';
  alias: Scalars['String'];
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  artifactCollectionName: Scalars['String'];
};

export type ArtifactCollectionAliasInput = {
  alias: Scalars['String'];
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  artifactCollectionName: Scalars['String'];
};

export type ArtifactCollectionConnection = {
  __typename: 'ArtifactCollectionConnection';
  totalCount: Scalars['Int'];
  pageInfo: PageInfo;
  edges: Array<ArtifactCollectionEdge>;
};

export type ArtifactCollectionEdge = {
  __typename: 'ArtifactCollectionEdge';
  node?: Maybe<ArtifactCollection>;
  cursor: Scalars['String'];
};

export type ArtifactCollectionMembership = {
  __typename: 'ArtifactCollectionMembership';
  id: Scalars['ID'];
  artifactCollection?: Maybe<ArtifactCollection>;
  artifact?: Maybe<Artifact>;
  createdAt: Scalars['DateTime'];
  commitHash?: Maybe<Scalars['String']>;
  versionIndex?: Maybe<Scalars['Int']>;
  aliases: Array<ArtifactAlias>;
};

export type ArtifactCollectionMembershipConnection = {
  __typename: 'ArtifactCollectionMembershipConnection';
  pageInfo: PageInfo;
  edges: Array<ArtifactCollectionMembershipEdge>;
};

export type ArtifactCollectionMembershipEdge = {
  __typename: 'ArtifactCollectionMembershipEdge';
  node?: Maybe<ArtifactCollectionMembership>;
  cursor: Scalars['String'];
};

export enum ArtifactCollectionState {
  Ready = 'READY',
  Deleted = 'DELETED'
}

export enum ArtifactCollectionType {
  Sequence = 'SEQUENCE',
  Portfolio = 'PORTFOLIO'
}

export type ArtifactConnection = ArtifactConnectionType & {
  __typename: 'ArtifactConnection';
  totalCount: Scalars['Int'];
  pageInfo: PageInfo;
  edges: Array<ArtifactEdge>;
};

export type ArtifactConnectionType = {
  totalCount: Scalars['Int'];
  pageInfo: PageInfo;
  edges: Array<ArtifactEdgeType>;
};

export enum ArtifactDigestAlgorithm {
  ManifestMd5 = 'MANIFEST_MD5'
}

export type ArtifactEdge = ArtifactEdgeType & {
  __typename: 'ArtifactEdge';
  node?: Maybe<Artifact>;
  cursor: Scalars['String'];
};

export type ArtifactEdgeType = {
  node?: Maybe<Artifact>;
  cursor: Scalars['String'];
};

export type ArtifactLineageDag = {
  __typename: 'ArtifactLineageDag';
  artifacts: Array<Maybe<ArtifactLineageDagArtifact>>;
  runs: Array<Maybe<ArtifactLineageDagRun>>;
  edges: Array<Maybe<ArtifactLineageDagEdge>>;
  clusters: Array<Maybe<ArtifactLineageDagCluster>>;
  hitLimit: Scalars['Boolean'];
};

export type ArtifactLineageDagArtifact = {
  __typename: 'ArtifactLineageDagArtifact';
  id: Scalars['ID'];
  artifactNodeID: Scalars['String'];
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  artifactTypeName: Scalars['String'];
  artifactSequenceName: Scalars['String'];
  artifactCommitHash: Scalars['String'];
  versionIndex: Scalars['Int'];
};

export type ArtifactLineageDagCluster = {
  __typename: 'ArtifactLineageDagCluster';
  nodeType: DagNodeType;
  id: Scalars['String'];
  artifactNodes: Array<Maybe<ArtifactLineageDagArtifact>>;
  runNodes: Array<Maybe<ArtifactLineageDagRun>>;
  clusterEdges: Array<Maybe<ArtifactLineageDagEdge>>;
};

export type ArtifactLineageDagEdge = {
  __typename: 'ArtifactLineageDagEdge';
  edgeID: Scalars['String'];
  artifactNodeID: Scalars['String'];
  runNodeID: Scalars['String'];
  clusterNodeID?: Maybe<Scalars['String']>;
  direction: DagDirection;
};

export type ArtifactLineageDagRun = {
  __typename: 'ArtifactLineageDagRun';
  runNodeID: Scalars['String'];
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  runName: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  jobType?: Maybe<Scalars['String']>;
};

export type ArtifactManifest = {
  __typename: 'ArtifactManifest';
  id: Scalars['ID'];
  type: ArtifactManifestType;
  createdAt: Scalars['DateTime'];
  digest: Scalars['String'];
  artifact: Artifact;
  file: File;
};

export type ArtifactManifestConnection = {
  __typename: 'ArtifactManifestConnection';
  pageInfo: PageInfo;
  edges: Array<ArtifactManifestEdge>;
};

export type ArtifactManifestEdge = {
  __typename: 'ArtifactManifestEdge';
  node?: Maybe<ArtifactManifest>;
  cursor: Scalars['String'];
};

export type ArtifactManifestEntryInput = {
  name: Scalars['String'];
  digest: Scalars['String'];
  birthArtifactID?: Maybe<Scalars['ID']>;
  ref?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int64']>;
};

export enum ArtifactManifestType {
  Full = 'FULL',
  Patch = 'PATCH',
  Incremental = 'INCREMENTAL'
}

export type ArtifactPortfolio = ArtifactCollection & {
  __typename: 'ArtifactPortfolio';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  project?: Maybe<Project>;
  state: ArtifactCollectionState;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultArtifactType: ArtifactType;
  artifactCollectionActions: ArtifactCollectionActionConnection;
  aliasActionHistory: ArtifactAliasActionConnection;
  /** @deprecated Field no longer supported */
  artifactActions: ArtifactActionConnection;
  artifacts: VersionedArtifactConnection;
  aliases: ArtifactAliasConnection;
  artifactMemberships: ArtifactCollectionMembershipConnection;
  artifactMembership?: Maybe<ArtifactCollectionMembership>;
  triggers: TriggerConnection;
  tags: TagConnection;
  hasArtifactVersionLinkedToGlobalRegistry: Scalars['Boolean'];
};


export type ArtifactPortfolioArtifactCollectionActionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
};


export type ArtifactPortfolioAliasActionHistoryArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
};


export type ArtifactPortfolioArtifactActionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ArtifactPortfolioArtifactsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
};


export type ArtifactPortfolioAliasesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ArtifactPortfolioArtifactMembershipsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ArtifactPortfolioArtifactMembershipArgs = {
  aliasName: Scalars['String'];
};


export type ArtifactPortfolioTriggersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ArtifactPortfolioTagsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  tagCategoryName?: Maybe<Scalars['String']>;
};

export type ArtifactSequence = ArtifactCollection & {
  __typename: 'ArtifactSequence';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  project?: Maybe<Project>;
  state: ArtifactCollectionState;
  updatedAt?: Maybe<Scalars['DateTime']>;
  defaultArtifactType: ArtifactType;
  artifactCollectionActions: ArtifactCollectionActionConnection;
  aliasActionHistory: ArtifactAliasActionConnection;
  /** @deprecated Field no longer supported */
  artifactActions: ArtifactActionConnection;
  latestArtifact?: Maybe<Artifact>;
  artifacts: VersionedArtifactConnection;
  aliases: ArtifactAliasConnection;
  artifactMemberships: ArtifactCollectionMembershipConnection;
  artifactMembership?: Maybe<ArtifactCollectionMembership>;
  triggers: TriggerConnection;
  tags: TagConnection;
  hasArtifactVersionLinkedToGlobalRegistry: Scalars['Boolean'];
};


export type ArtifactSequenceArtifactCollectionActionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
};


export type ArtifactSequenceAliasActionHistoryArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
};


export type ArtifactSequenceArtifactActionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ArtifactSequenceArtifactsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
};


export type ArtifactSequenceAliasesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ArtifactSequenceArtifactMembershipsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ArtifactSequenceArtifactMembershipArgs = {
  aliasName: Scalars['String'];
};


export type ArtifactSequenceTriggersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ArtifactSequenceTagsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  tagCategoryName?: Maybe<Scalars['String']>;
};

export type ArtifactSequenceConnection = {
  __typename: 'ArtifactSequenceConnection';
  totalCount: Scalars['Int'];
  pageInfo: PageInfo;
  edges: Array<ArtifactSequenceEdge>;
};

export type ArtifactSequenceEdge = {
  __typename: 'ArtifactSequenceEdge';
  node?: Maybe<ArtifactSequence>;
  cursor: Scalars['String'];
};

export type ArtifactSequenceStorageNode = StorageTreeNode & {
  __typename: 'ArtifactSequenceStorageNode';
  id: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int64'];
  artifactSequence: ArtifactSequence;
  artifactType: ArtifactType;
  artifactSequenceID: Scalars['ID'];
  artifacts: ArtifactStorageNodeConnection;
};


export type ArtifactSequenceStorageNodeArtifactsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ArtifactSequenceStorageNodeConnection = {
  __typename: 'ArtifactSequenceStorageNodeConnection';
  totalSize: Scalars['Int64'];
  pageInfo: PageInfo;
  edges: Array<ArtifactSequenceStorageNodeEdge>;
};

export type ArtifactSequenceStorageNodeEdge = {
  __typename: 'ArtifactSequenceStorageNodeEdge';
  node: ArtifactSequenceStorageNode;
  cursor: Scalars['String'];
};

export enum ArtifactState {
  Pending = 'PENDING',
  Committed = 'COMMITTED',
  Deleted = 'DELETED'
}

export enum ArtifactStorageLayout {
  V1 = 'V1',
  V2 = 'V2'
}

export type ArtifactStorageNode = StorageTreeNode & {
  __typename: 'ArtifactStorageNode';
  id: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int64'];
  artifact: Artifact;
  artifactID: Scalars['ID'];
};

export type ArtifactStorageNodeConnection = {
  __typename: 'ArtifactStorageNodeConnection';
  totalSize: Scalars['Int64'];
  pageInfo: PageInfo;
  edges: Array<ArtifactStorageNodeEdge>;
};

export type ArtifactStorageNodeEdge = {
  __typename: 'ArtifactStorageNodeEdge';
  node: ArtifactStorageNode;
  cursor: Scalars['String'];
};

export type ArtifactType = {
  __typename: 'ArtifactType';
  id: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  project: Project;
  artifactActions?: Maybe<ArtifactActionConnection>;
  artifact?: Maybe<Artifact>;
  artifactCollection?: Maybe<ArtifactCollection>;
  artifactCollections?: Maybe<ArtifactCollectionConnection>;
  artifactSequence?: Maybe<ArtifactSequence>;
  artifactSequences?: Maybe<ArtifactSequenceConnection>;
};


export type ArtifactTypeArtifactActionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ArtifactTypeArtifactArgs = {
  name: Scalars['String'];
};


export type ArtifactTypeArtifactCollectionArgs = {
  name: Scalars['String'];
};


export type ArtifactTypeArtifactCollectionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
  collectionTypes?: Maybe<Array<ArtifactCollectionType>>;
};


export type ArtifactTypeArtifactSequenceArgs = {
  name: Scalars['String'];
};


export type ArtifactTypeArtifactSequencesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
};

export type ArtifactTypeConnection = {
  __typename: 'ArtifactTypeConnection';
  pageInfo: PageInfo;
  edges: Array<ArtifactTypeEdge>;
};

export type ArtifactTypeEdge = {
  __typename: 'ArtifactTypeEdge';
  node?: Maybe<ArtifactType>;
  cursor: Scalars['String'];
};

export type ArtifactTypeInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
};

export type ArtifactTypeStorageNode = StorageTreeNode & {
  __typename: 'ArtifactTypeStorageNode';
  id: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int64'];
  artifactType: ArtifactType;
};

export type AttachCustomerPaymentMethodInput = {
  organizationId: Scalars['ID'];
  paymentMethod: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type AttachCustomerPaymentMethodPayload = {
  __typename: 'AttachCustomerPaymentMethodPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type BenchmarkRun = {
  __typename: 'BenchmarkRun';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  state: Scalars['String'];
  details?: Maybe<Scalars['JSON']>;
  results?: Maybe<Scalars['String']>;
  user: User;
  benchmark: Project;
  run: Run;
  originalProject?: Maybe<Project>;
  originalRun?: Maybe<Run>;
  isCodeHidden: Scalars['Boolean'];
  gitHubSubmissionPR?: Maybe<Scalars['String']>;
};

export type BenchmarkRunConnection = {
  __typename: 'BenchmarkRunConnection';
  pageInfo: PageInfo;
  edges: Array<BenchmarkRunEdge>;
};

export type BenchmarkRunEdge = {
  __typename: 'BenchmarkRunEdge';
  node?: Maybe<BenchmarkRun>;
  cursor: Scalars['String'];
};

export type BucketDiff = {
  __typename: 'BucketDiff';
  op: DiffOperation;
  run?: Maybe<Run>;
};

export type BucketDiffs = {
  __typename: 'BucketDiffs';
  order: Array<Scalars['ID']>;
  delta: Array<BucketDiff>;
};

export type BucketStoreConnectionError = Error & {
  __typename: 'BucketStoreConnectionError';
  message: Scalars['String'];
  severity: ErrorSeverity;
};

export type BucketType = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSONString']>;
  framework?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
  github?: Maybe<Scalars['String']>;
  commit?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  shouldStop?: Maybe<Scalars['Boolean']>;
  summaryMetrics?: Maybe<Scalars['JSONString']>;
  history: Array<Scalars['String']>;
  sweep?: Maybe<Sweep>;
  exampleTableColumns?: Maybe<Scalars['JSONString']>;
  exampleTableTypes?: Maybe<Scalars['JSONString']>;
  exampleTable?: Maybe<Scalars['JSONString']>;
  fileCount?: Maybe<Scalars['Int']>;
  logLines?: Maybe<LogLineConnection>;
  files?: Maybe<FileConnection>;
};


export type BucketTypeConfigArgs = {
  keys?: Maybe<Array<Scalars['String']>>;
};


export type BucketTypeSummaryMetricsArgs = {
  keys?: Maybe<Array<Scalars['String']>>;
  packVersion?: Maybe<Scalars['Int']>;
};


export type BucketTypeHistoryArgs = {
  samples?: Maybe<Scalars['Int']>;
  minStep?: Maybe<Scalars['Int64']>;
  maxStep?: Maybe<Scalars['Int64']>;
};


export type BucketTypeLogLinesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type BucketTypeFilesArgs = {
  pattern?: Maybe<Scalars['String']>;
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
  contentType?: Maybe<Array<Scalars['String']>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CancelCustomerSubscriptionInput = {
  organizationId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CancelCustomerSubscriptionPayload = {
  __typename: 'CancelCustomerSubscriptionPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum CardBrand {
  Amex = 'AMEX',
  Diners = 'DINERS',
  Discover = 'DISCOVER',
  Interac = 'INTERAC',
  Jcb = 'JCB',
  Mastercard = 'MASTERCARD',
  Unionpay = 'UNIONPAY',
  Unknown = 'UNKNOWN',
  Visa = 'VISA'
}

export type ClaimAnonymousEntityInput = {
  anonymousApiKey: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ClaimAnonymousEntityPayload = {
  __typename: 'ClaimAnonymousEntityPayload';
  task: Task;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ClientIdMapping = {
  __typename: 'ClientIDMapping';
  clientID: Scalars['ID'];
  serverID: Scalars['ID'];
  entry: ClientIdMappingEntry;
};

export type ClientIdMappingEntry = Artifact | ArtifactSequence;

export type CloneProjectsInput = {
  projectNames?: Maybe<Array<Scalars['String']>>;
  sourceEntityName: Scalars['String'];
  destinationEntityName: Scalars['String'];
  ownerUsername: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CloneProjectsPayload = {
  __typename: 'CloneProjectsPayload';
  tasks: Array<Task>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum CloudProvider {
  Aws = 'AWS',
  Gcp = 'GCP',
  Azure = 'AZURE',
  Minio = 'MINIO'
}

export type CloudRegion = {
  __typename: 'CloudRegion';
  id: Scalars['ID'];
  provider: CloudProvider;
  region: Scalars['String'];
};

export type Code = {
  __typename: 'Code';
  name: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  directUrl: Scalars['String'];
};

export type CommitArtifactInput = {
  artifactID: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CommitArtifactPayload = {
  __typename: 'CommitArtifactPayload';
  artifact: Artifact;
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum CompleteMultipartAction {
  Complete = 'Complete'
}

export type CompleteMultipartUploadArtifactInput = {
  completeMultipartAction: CompleteMultipartAction;
  completedParts: Array<UploadPartsInput>;
  artifactID: Scalars['ID'];
  storagePath: Scalars['String'];
  uploadID: Scalars['String'];
};

export type CompleteMultipartUploadArtifactPayload = {
  __typename: 'CompleteMultipartUploadArtifactPayload';
  digest?: Maybe<Scalars['String']>;
};

export type ContactUsForComputeHoursInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ContactUsForComputeHoursPayload = {
  __typename: 'ContactUsForComputeHoursPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ContactUsForUserLedTrialInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ContactUsForUserLedTrialPayload = {
  __typename: 'ContactUsForUserLedTrialPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Coupon = {
  __typename: 'Coupon';
  id: Scalars['String'];
  amountOff: Scalars['Int'];
  percentOff: Scalars['Float'];
  duration: Scalars['String'];
  currency: Scalars['String'];
  name: Scalars['String'];
  durationInMonths: Scalars['Int'];
};

export type CreateAwsExternalIdInput = {
  organizationID?: Maybe<Scalars['ID']>;
  clientMutationID?: Maybe<Scalars['String']>;
};

export type CreateAcademicOrReverseTrialInput = {
  newOrganizationName: Scalars['String'];
  newTeamName: Scalars['String'];
  isAcademic: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateAcademicOrReverseTrialPayload = {
  __typename: 'CreateAcademicOrReverseTrialPayload';
  subscription?: Maybe<Scalars['JSON']>;
  clientMutationId?: Maybe<Scalars['String']>;
  orgID: Scalars['ID'];
};

export type CreateAccessTokenInput = {
  projects: Array<ProjectSpecifier>;
  viewId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
  previewURL?: Maybe<Scalars['String']>;
};

export type CreateAccessTokenPayload = {
  __typename: 'CreateAccessTokenPayload';
  accessToken: AccessToken;
  toNewUser?: Maybe<Scalars['Boolean']>;
  recipientAlreadyOnTeam?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateAgentInput = {
  host: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  sweep: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateAgentPayload = {
  __typename: 'CreateAgentPayload';
  agent?: Maybe<Agent>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateAlertPayload = {
  __typename: 'CreateAlertPayload';
  alert: Alert;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateAnonymousEntityInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateArtifactCollectionPayload = {
  __typename: 'CreateArtifactCollectionPayload';
  artifactCollection: ArtifactCollection;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateArtifactCollectionTagAssignmentsInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  artifactCollectionName: Scalars['String'];
  tags: Array<TagInput>;
  clientMutationID?: Maybe<Scalars['String']>;
};

export type CreateArtifactFileSpecInput = {
  artifactID: Scalars['ID'];
  name: Scalars['String'];
  md5: Scalars['String'];
  mimetype?: Maybe<Scalars['String']>;
  artifactManifestID?: Maybe<Scalars['ID']>;
  uploadPartsInput?: Maybe<Array<UploadPartsInput>>;
};

export type CreateArtifactFilesInput = {
  artifactFiles: Array<CreateArtifactFileSpecInput>;
  storageLayout: ArtifactStorageLayout;
  cloudRegionID?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateArtifactFilesPayload = {
  __typename: 'CreateArtifactFilesPayload';
  files: FileConnection;
  clientMutationId?: Maybe<Scalars['String']>;
};


export type CreateArtifactFilesPayloadFilesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type CreateArtifactInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  artifactTypeName: Scalars['String'];
  artifactCollectionName?: Maybe<Scalars['String']>;
  artifactCollectionNames?: Maybe<Array<Scalars['String']>>;
  runName?: Maybe<Scalars['String']>;
  digest: Scalars['String'];
  digestAlgorithm: ArtifactDigestAlgorithm;
  description?: Maybe<Scalars['String']>;
  labels?: Maybe<Scalars['JSONString']>;
  aliases?: Maybe<Array<ArtifactAliasInput>>;
  tags?: Maybe<Array<TagInput>>;
  metadata?: Maybe<Scalars['JSONString']>;
  ttlDurationSeconds?: Maybe<Scalars['Int64']>;
  historyStep?: Maybe<Scalars['Int64']>;
  enableDigestDeduplication?: Maybe<Scalars['Boolean']>;
  distributedID?: Maybe<Scalars['String']>;
  clientID?: Maybe<Scalars['ID']>;
  sequenceClientID?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateArtifactManifestInput = {
  artifactID: Scalars['ID'];
  baseArtifactID?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  digest: Scalars['String'];
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  runName: Scalars['String'];
  type: ArtifactManifestType;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateArtifactManifestPayload = {
  __typename: 'CreateArtifactManifestPayload';
  artifactManifest: ArtifactManifest;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateArtifactPayload = {
  __typename: 'CreateArtifactPayload';
  artifact: Artifact;
  artifactActions: Array<ArtifactAction>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateArtifactPortfolioInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  artifactTypeID: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateArtifactSequenceInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  artifactTypeID: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateArtifactTagAssignmentsInput = {
  artifactID: Scalars['ID'];
  tags: Array<TagInput>;
  clientMutationID?: Maybe<Scalars['String']>;
};

export type CreateArtifactTypeInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateArtifactTypePayload = {
  __typename: 'CreateArtifactTypePayload';
  artifactType: ArtifactType;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateArtifactTypesInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  artifactTypes: Array<ArtifactTypeInput>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateArtifactTypesPayload = {
  __typename: 'CreateArtifactTypesPayload';
  artifactTypes: Array<ArtifactType>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateBenchmarkDiscussionCommentInput = {
  discussionThreadID: Scalars['ID'];
  parentCommentID?: Maybe<Scalars['ID']>;
  body: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateBenchmarkDiscussionThreadInput = {
  benchmarkEntityName: Scalars['String'];
  benchmarkProjectName: Scalars['String'];
  title: Scalars['String'];
  body?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateClientIdMappingInput = {
  clientID: Scalars['ID'];
  serverID: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateClientIdMappingPayload = {
  __typename: 'CreateClientIDMappingPayload';
  clientIDMapping: ClientIdMapping;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateClientInput = {
  entityName: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
  providerId: Scalars['ID'];
};

export type CreateClientPayload = {
  __typename: 'CreateClientPayload';
  success: Scalars['Boolean'];
  clientId?: Maybe<Scalars['String']>;
};

export type CreateCustomChartInput = {
  entity: Scalars['String'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  type: Scalars['String'];
  access: Scalars['String'];
  spec: Scalars['JSONString'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateCustomChartPayload = {
  __typename: 'CreateCustomChartPayload';
  chart: CustomChart;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateCustomerSubscriptionInput = {
  planId: Scalars['ID'];
  newOrganizationName: Scalars['String'];
  paymentMethod: Scalars['String'];
  quantity: Scalars['Int'];
  trial: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateCustomerSubscriptionPayload = {
  __typename: 'CreateCustomerSubscriptionPayload';
  subscription?: Maybe<Scalars['JSON']>;
  clientMutationId?: Maybe<Scalars['String']>;
  organizationId: Scalars['ID'];
};

export type CreateCustomerTrialInput = {
  userName: Scalars['String'];
  planId: Scalars['ID'];
  newOrganizationName: Scalars['String'];
  quantity: Scalars['Int'];
  trialDays: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateCustomerTrialPayload = {
  __typename: 'CreateCustomerTrialPayload';
  subscription?: Maybe<Scalars['JSON']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDefaultResourceConfigInput = {
  entityName: Scalars['String'];
  projectName?: Maybe<Scalars['String']>;
  resource: Scalars['String'];
  config: Scalars['JSONString'];
  templateVariables?: Maybe<Scalars['JSONString']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDefaultResourceConfigPayload = {
  __typename: 'CreateDefaultResourceConfigPayload';
  success: Scalars['Boolean'];
  defaultResourceConfigID: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
  schemaValidationErrors?: Maybe<Array<Scalars['String']>>;
};

export type CreateDiscussionCommentPayload = {
  __typename: 'CreateDiscussionCommentPayload';
  discussionComment: DiscussionComment;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateDiscussionThreadPayload = {
  __typename: 'CreateDiscussionThreadPayload';
  discussionThread: DiscussionThread;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateEmailSubscriptionInput = {
  alertID: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateEmailSubscriptionPayload = {
  __typename: 'CreateEmailSubscriptionPayload';
  subscription: EmailSubscription;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateEntityInput = {
  name: Scalars['String'];
  defaultFramework?: Maybe<Scalars['String']>;
  invited?: Maybe<Scalars['String']>;
  defaultAccess?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateEntityPayload = {
  __typename: 'CreateEntityPayload';
  entity?: Maybe<Entity>;
  apiKey?: Maybe<ApiKey>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateExperimentInput = {
  name: Scalars['String'];
  variants: Array<VariantInput>;
  experimentType: ExperimentType;
  metadata?: Maybe<Scalars['JSONString']>;
  sampleSize: Scalars['Int'];
  enrollmentPeriod: Scalars['Int'];
  observationalUnit: ObservationalUnit;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateExperimentPayload = {
  __typename: 'CreateExperimentPayload';
  id: Scalars['ID'];
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateFilterTriggerInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  triggeringEventType: EventTriggeringConditionType;
  scopeType: TriggerScopeType;
  scopeID: Scalars['ID'];
  eventFilter: Scalars['JSONString'];
  triggeredActionType: TriggeredActionType;
  triggeredActionConfig: TriggeredActionConfig;
  enabled: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateFilterTriggerPayload = {
  __typename: 'CreateFilterTriggerPayload';
  trigger?: Maybe<Trigger>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateFinishedRunAlertInput = {
  entityName: Scalars['String'];
  projectName?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateGenericWebhookIntegrationInput = {
  entityName: Scalars['String'];
  urlEndpoint: Scalars['String'];
  name: Scalars['String'];
  secretRef?: Maybe<Scalars['String']>;
  accessTokenRef?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateGitHubOAuthIntegrationInput = {
  code: Scalars['String'];
  entityName: Scalars['String'];
  redirectURI: Scalars['String'];
  state: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateGlueTaskInput = {
  name: Scalars['String'];
  taskType: Scalars['String'];
  arguments: Scalars['JSONString'];
  status: Scalars['String'];
  strategyType: Scalars['String'];
  strategyMetadata: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateGlueTaskPayload = {
  __typename: 'CreateGlueTaskPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateHourOverageSubscriptionInput = {
  organizationId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateHourOverageSubscriptionPayload = {
  __typename: 'CreateHourOverageSubscriptionPayload';
  subscription?: Maybe<Scalars['JSON']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateIntegrationPayload = {
  __typename: 'CreateIntegrationPayload';
  integration: Integration;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateInviteInput = {
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  entityName: Scalars['String'];
  accountType?: Maybe<Scalars['String']>;
  admin?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  addSeat?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
  modelSeat?: Maybe<Scalars['String']>;
};

export type CreateInvitePayload = {
  __typename: 'CreateInvitePayload';
  invite?: Maybe<Invite>;
  entity?: Maybe<Entity>;
  success: Scalars['Boolean'];
  emailSent: Scalars['Boolean'];
  remainingSeats?: Maybe<Scalars['Int']>;
  remainingViewOnlySeats?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateLaunchAgentInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  runQueues: Array<Scalars['ID']>;
  hostname: Scalars['String'];
  agentConfig?: Maybe<Scalars['JSONString']>;
  clientMutationId?: Maybe<Scalars['String']>;
  version?: Maybe<Scalars['String']>;
};

export type CreateLaunchAgentPayload = {
  __typename: 'CreateLaunchAgentPayload';
  success?: Maybe<Scalars['Boolean']>;
  launchAgentId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateLocalLicenseOrganizationInput = {
  newOrganizationName: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateLocalLicenseOrganizationPayload = {
  __typename: 'CreateLocalLicenseOrganizationPayload';
  organization: Organization;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateNewsletterSubscriptionInput = {
  email?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateNewsletterSubscriptionPayload = {
  __typename: 'CreateNewsletterSubscriptionPayload';
  newsletterSubscription: NewsletterSubscription;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateOAuthProviderInput = {
  issuerUrl: Scalars['String'];
  jwk?: Maybe<Scalars['JSONString']>;
  organizationId: Scalars['ID'];
};

export type CreateOAuthProviderPayload = {
  __typename: 'CreateOAuthProviderPayload';
  success: Scalars['Boolean'];
};

export type CreateOrgServiceAccountInput = {
  description: Scalars['String'];
  defaultEntityId: Scalars['ID'];
  organizationId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateOrgServiceAccountPayload = {
  __typename: 'CreateOrgServiceAccountPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePreviewableLinkInput = {
  entityName: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  resourceID: Scalars['ID'];
  resourceType: PreviewableLinkResourceType;
  redirectURL: Scalars['String'];
  author: Scalars['String'];
  useDefaultImage: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreatePreviewableLinkPayload = {
  __typename: 'CreatePreviewableLinkPayload';
  success: Scalars['Boolean'];
  link: Scalars['String'];
  previewImageUploadURL?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateProjectMembersInput = {
  userIds: Array<Scalars['ID']>;
  projectId: Scalars['ID'];
};

export type CreateProjectMembersPayload = {
  __typename: 'CreateProjectMembersPayload';
  success: Scalars['Boolean'];
};

export type CreateRoleInput = {
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  inheritedFrom: Scalars['String'];
  permissions: Array<Scalars['String']>;
  organizationID: Scalars['ID'];
};

export type CreateRolePayload = {
  __typename: 'CreateRolePayload';
  success: Scalars['Boolean'];
};

export type CreateRunFilesInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  runName: Scalars['String'];
  files: Array<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateRunFilesPayload = {
  __typename: 'CreateRunFilesPayload';
  runID: Scalars['ID'];
  uploadHeaders: Array<Scalars['String']>;
  files: Array<File>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateRunQueueInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  queueName: Scalars['String'];
  access: RunQueueAccessType;
  defaultResourceConfigID?: Maybe<Scalars['ID']>;
  prioritizationMode?: Maybe<RunQueuePrioritizationMode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateRunQueuePayload = {
  __typename: 'CreateRunQueuePayload';
  success?: Maybe<Scalars['Boolean']>;
  queueID?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateScriptableRunAlertInput = {
  entityName: Scalars['String'];
  projectName?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateServiceAccountInput = {
  description?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateServiceAccountPayload = {
  __typename: 'CreateServiceAccountPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateSlackChannelSubscriptionInput = {
  alertID: Scalars['ID'];
  integrationID: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateSlackChannelSubscriptionPayload = {
  __typename: 'CreateSlackChannelSubscriptionPayload';
  subscription: SlackChannelSubscription;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateSlackIntegrationInput = {
  code: Scalars['String'];
  entityName: Scalars['String'];
  redirectURI: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateStoppedRunAlertInput = {
  entityName: Scalars['String'];
  projectName?: Maybe<Scalars['String']>;
  minimumRunDuration: Scalars['Duration'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateStorageSubscriptionInput = {
  storagePlanId?: Maybe<Scalars['ID']>;
  trackingPlanId?: Maybe<Scalars['ID']>;
  organizationId?: Maybe<Scalars['ID']>;
  paymentMethod?: Maybe<Scalars['String']>;
  trial?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateStorageSubscriptionPayload = {
  __typename: 'CreateStorageSubscriptionPayload';
  subscription?: Maybe<Scalars['JSON']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateTagsPayload = {
  __typename: 'CreateTagsPayload';
  tags: Array<Tag>;
  clientMutationID?: Maybe<Scalars['String']>;
};

export type CreateTeamInput = {
  teamName: Scalars['String'];
  teamAdminUserName?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  defaultAccess?: Maybe<Scalars['String']>;
  storageBucketInfo?: Maybe<StorageBucketInfoInput>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateTeamPayload = {
  __typename: 'CreateTeamPayload';
  entity?: Maybe<Entity>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateUserEmailInput = {
  email: Scalars['String'];
  type: EmailType;
};

export type CreateUserEmailPayload = {
  __typename: 'CreateUserEmailPayload';
  success: Scalars['Boolean'];
  email?: Maybe<Email>;
};

export type CreateUserInput = {
  email: Scalars['String'];
  admin?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateUserLedTrialInput = {
  newOrganizationName: Scalars['String'];
  newTeamName: Scalars['String'];
  orgSize: Scalars['String'];
  isAcademic: Scalars['Boolean'];
  allowJoinMatchingEmailDomain?: Maybe<Scalars['Boolean']>;
  justification?: Maybe<Scalars['String']>;
  storageBucketInfo?: Maybe<StorageBucketInfoInput>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateUserLedTrialPayload = {
  __typename: 'CreateUserLedTrialPayload';
  subscription?: Maybe<Scalars['JSON']>;
  clientMutationId?: Maybe<Scalars['String']>;
  orgID: Scalars['ID'];
};

export type CreateUserPayload = {
  __typename: 'CreateUserPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateViewCommentsAlertSubscriptionInput = {
  viewID: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateViewDiscussionCommentInput = {
  viewID: Scalars['ID'];
  discussionThreadID?: Maybe<Scalars['ID']>;
  body: Scalars['String'];
  notifyAllSubscribers?: Maybe<Scalars['Boolean']>;
  inlineCommentDetails?: Maybe<Scalars['JSONString']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CreateViewDiscussionCommentPayload = {
  __typename: 'CreateViewDiscussionCommentPayload';
  discussionThread: DiscussionThread;
  discussionComment: DiscussionComment;
  alertSubscription: AlertSubscription;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type CustomChart = {
  __typename: 'CustomChart';
  id: Scalars['ID'];
  entity: Entity;
  name: Scalars['String'];
  displayName: Scalars['String'];
  type: Scalars['String'];
  user: User;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  access: Scalars['String'];
  spec: Scalars['JSONString'];
};

export type CustomChartConnection = {
  __typename: 'CustomChartConnection';
  pageInfo: PageInfo;
  edges: Array<CustomChartEdge>;
};

export type CustomChartEdge = {
  __typename: 'CustomChartEdge';
  node: CustomChart;
  cursor: Scalars['String'];
};

export enum DagDirection {
  TowardArtifact = 'TowardArtifact',
  AwayFromArtifact = 'AwayFromArtifact'
}

export enum DagNodeType {
  Artifact = 'Artifact',
  Run = 'Run'
}


export type DefaultResourceConfig = {
  __typename: 'DefaultResourceConfig';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  scope: DefaultResourceConfigScope;
  resource: Scalars['String'];
  config?: Maybe<Scalars['JSON']>;
  templateVariables: Array<TemplateVariable>;
};

export type DefaultResourceConfigConnection = {
  __typename: 'DefaultResourceConfigConnection';
  pageInfo: PageInfo;
  edges: Array<DefaultResourceConfigEdge>;
};

export type DefaultResourceConfigEdge = {
  __typename: 'DefaultResourceConfigEdge';
  node: DefaultResourceConfig;
  cursor: Scalars['String'];
};

export type DefaultResourceConfigScope = {
  __typename: 'DefaultResourceConfigScope';
  Type: DefaultResourceConfigScopeType;
  ID: Scalars['Int'];
};

export enum DefaultResourceConfigScopeType {
  Project = 'PROJECT',
  Entity = 'ENTITY'
}

export type DeleteAlertInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteAlertPayload = {
  __typename: 'DeleteAlertPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteAlertSubscriptionInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteAlertSubscriptionPayload = {
  __typename: 'DeleteAlertSubscriptionPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteAliasesInput = {
  aliases: Array<ArtifactCollectionAliasInput>;
  artifactID: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteAliasesPayload = {
  __typename: 'DeleteAliasesPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteApiKeyInput = {
  id?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteApiKeyPayload = {
  __typename: 'DeleteApiKeyPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteArtifactAction = ArtifactAction & {
  __typename: 'DeleteArtifactAction';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  artifact: Artifact;
  initiator: Initiator;
};

export type DeleteArtifactCollectionPayload = {
  __typename: 'DeleteArtifactCollectionPayload';
  artifactCollection: ArtifactCollection;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteArtifactCollectionTagAssignmentsInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  artifactCollectionName: Scalars['String'];
  tags: Array<TagInput>;
  clientMutationID?: Maybe<Scalars['String']>;
};

export type DeleteArtifactEntityTtlInput = {
  entityName: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteArtifactEntityTtlPayload = {
  __typename: 'DeleteArtifactEntityTTLPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteArtifactInput = {
  artifactID: Scalars['ID'];
  deleteAliases?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteArtifactPayload = {
  __typename: 'DeleteArtifactPayload';
  artifact: Artifact;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteArtifactPortfolioInput = {
  artifactPortfolioID: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteArtifactSequenceInput = {
  artifactSequenceID: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteArtifactTagAssignmentsInput = {
  artifactID: Scalars['ID'];
  tags: Array<TagInput>;
  clientMutationID?: Maybe<Scalars['String']>;
};

export type DeleteCustomChartInput = {
  entity: Scalars['String'];
  name: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteCustomChartPayload = {
  __typename: 'DeleteCustomChartPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteDefaultResourceConfigsInput = {
  defaultResourceConfigIDs: Array<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteDefaultResourceConfigsPayload = {
  __typename: 'DeleteDefaultResourceConfigsPayload';
  success: Scalars['Boolean'];
  numAffected: Scalars['Int64'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteDiscussionCommentInput = {
  id: Scalars['ID'];
  deleteThread?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteDiscussionCommentPayload = {
  __typename: 'DeleteDiscussionCommentPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteExperimentInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteExperimentPayload = {
  __typename: 'DeleteExperimentPayload';
  id: Scalars['ID'];
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteFilesByRunAndFolderInput = {
  runName: Scalars['String'];
  projectId: Scalars['Int'];
  folders: Array<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteFilesInput = {
  files: Array<Scalars['ID']>;
  projectId?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteFilesPayload = {
  __typename: 'DeleteFilesPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteFromRunQueueInput = {
  queueID: Scalars['ID'];
  runQueueItemId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteFromRunQueuePayload = {
  __typename: 'DeleteFromRunQueuePayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteGlueTaskInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteGlueTaskPayload = {
  __typename: 'DeleteGlueTaskPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteIntegrationInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteIntegrationPayload = {
  __typename: 'DeleteIntegrationPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteInviteInput = {
  id?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteInvitePayload = {
  __typename: 'DeleteInvitePayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteLaunchAgentsInput = {
  launchAgentIds: Array<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteLaunchAgentsPayload = {
  __typename: 'DeleteLaunchAgentsPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteModelInput = {
  id: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteModelPayload = {
  __typename: 'DeleteModelPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteOAuthProviderInput = {
  providerId: Scalars['ID'];
};

export type DeleteOAuthProviderPayload = {
  __typename: 'DeleteOAuthProviderPayload';
  success: Scalars['Boolean'];
};

export type DeleteOrganizationInput = {
  organizationId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteOrganizationPayload = {
  __typename: 'DeleteOrganizationPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteOrganizationSubscriptionInput = {
  subscriptionID: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteOrganizationSubscriptionPayload = {
  __typename: 'DeleteOrganizationSubscriptionPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteProjectMembersInput = {
  userIds: Array<Scalars['ID']>;
  projectId: Scalars['ID'];
};

export type DeleteProjectMembersPayload = {
  __typename: 'DeleteProjectMembersPayload';
  success: Scalars['Boolean'];
};

export type DeleteRoleInput = {
  roleID: Scalars['ID'];
};

export type DeleteRolePayload = {
  __typename: 'DeleteRolePayload';
  success: Scalars['Boolean'];
};

export type DeleteRolePermissionsInput = {
  roleID: Scalars['ID'];
  permissions: Array<Scalars['String']>;
};

export type DeleteRolePermissionsPayload = {
  __typename: 'DeleteRolePermissionsPayload';
  success: Scalars['Boolean'];
};

export type DeleteRunInput = {
  id: Scalars['ID'];
  deleteArtifacts?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteRunPayload = {
  __typename: 'DeleteRunPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteRunQueuesInput = {
  queueIDs: Array<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteRunQueuesPayload = {
  __typename: 'DeleteRunQueuesPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteRunsInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  filters: Scalars['JSONString'];
  deleteArtifacts?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteRunsPayload = {
  __typename: 'DeleteRunsPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Scalars['String']>;
};

export type DeleteSecretInput = {
  entityName: Scalars['String'];
  secretName: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteSecretPayload = {
  __typename: 'DeleteSecretPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteServiceAccountInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteServiceAccountPayload = {
  __typename: 'DeleteServiceAccountPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteSweepInput = {
  id: Scalars['ID'];
  deleteRuns: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteSweepsInput = {
  ids: Array<Scalars['ID']>;
  deleteRuns: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteSweepsPayload = {
  __typename: 'DeleteSweepsPayload';
  affectedAgents: Scalars['Int'];
  affectedRuns: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteTagAssignmentPayload = {
  __typename: 'DeleteTagAssignmentPayload';
  success: Scalars['Boolean'];
  clientMutationID?: Maybe<Scalars['String']>;
};

export type DeleteTeamInput = {
  teamName: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteTeamPayload = {
  __typename: 'DeleteTeamPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteTriggerInput = {
  triggerID: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteTriggerPayload = {
  __typename: 'DeleteTriggerPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteUserEmailInput = {
  id: Scalars['ID'];
};

export type DeleteUserEmailPayload = {
  __typename: 'DeleteUserEmailPayload';
  success: Scalars['Boolean'];
  email?: Maybe<Email>;
};

export type DeleteUserInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteUserPayload = {
  __typename: 'DeleteUserPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteViewInput = {
  id?: Maybe<Scalars['ID']>;
  deleteDrafts?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteViewPayload = {
  __typename: 'DeleteViewPayload';
  success?: Maybe<Scalars['Boolean']>;
  pendingDrafts?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteViewsInput = {
  ids: Array<Scalars['ID']>;
  deleteDrafts?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteViewsPayload = {
  __typename: 'DeleteViewsPayload';
  success?: Maybe<Scalars['Boolean']>;
  pendingDrafts?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DeleteWorkflowsAdminInput = {
  entityName: Scalars['String'];
  username?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['ID']>;
  adminType: WorkflowsAdminType;
  clientMutationID?: Maybe<Scalars['String']>;
};

export type DeleteWorkflowsAdminPayload = {
  __typename: 'DeleteWorkflowsAdminPayload';
  success: Scalars['Boolean'];
  clientMutationID?: Maybe<Scalars['String']>;
};

export type DetachCustomerPaymentMethodInput = {
  organizationId: Scalars['ID'];
  paymentMethod: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DetachCustomerPaymentMethodPayload = {
  __typename: 'DetachCustomerPaymentMethodPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum DiffOperation {
  Insert = 'INSERT',
  Delete = 'DELETE',
  Update = 'UPDATE'
}

export type DisableGlueTaskInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DisableGlueTaskPayload = {
  __typename: 'DisableGlueTaskPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DisableOldTrackingSubscriptionInput = {
  organizationId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type DisableOldTrackingSubscriptionPayload = {
  __typename: 'DisableOldTrackingSubscriptionPayload';
  subscriptionID?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Discount = {
  __typename: 'Discount';
  coupon: Coupon;
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};

export type DiscussionComment = {
  __typename: 'DiscussionComment';
  id: Scalars['ID'];
  poster: User;
  parentComment?: Maybe<DiscussionComment>;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isParent: Scalars['Boolean'];
  upvotes: Scalars['Int'];
  body: Scalars['String'];
  childComments: DiscussionCommentConnection;
};


export type DiscussionCommentChildCommentsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DiscussionCommentConnection = {
  __typename: 'DiscussionCommentConnection';
  totalCount: Scalars['Int'];
  pageInfo: PageInfo;
  edges: Array<DiscussionCommentEdge>;
};

export type DiscussionCommentEdge = {
  __typename: 'DiscussionCommentEdge';
  node: DiscussionComment;
  cursor: Scalars['String'];
};

export type DiscussionThread = {
  __typename: 'DiscussionThread';
  id: Scalars['ID'];
  poster: User;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  upvotes: Scalars['Int'];
  title: Scalars['String'];
  body: Scalars['String'];
  comments: DiscussionCommentConnection;
};


export type DiscussionThreadCommentsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type DiscussionThreadConnection = {
  __typename: 'DiscussionThreadConnection';
  totalCount: Scalars['Int'];
  pageInfo: PageInfo;
  edges: Array<DiscussionThreadEdge>;
};

export type DiscussionThreadEdge = {
  __typename: 'DiscussionThreadEdge';
  node: DiscussionThread;
  cursor: Scalars['String'];
};

export type DismissedBanner = {
  __typename: 'DismissedBanner';
  id: Scalars['ID'];
  dismissedAt: Scalars['DateTime'];
};

export enum DotBehavior {
  KeepAllDotValues = 'KEEP_ALL_DOT_VALUES',
  SkipFirstDotValue = 'SKIP_FIRST_DOT_VALUE'
}


export type Email = {
  __typename: 'Email';
  id: Scalars['ID'];
  emailAddress: Scalars['String'];
  type: EmailType;
  createdAt: Scalars['DateTime'];
  identities: Array<Identity>;
  verified: Scalars['Boolean'];
};

export type EmailSubscription = AlertSubscription & {
  __typename: 'EmailSubscription';
  id: Scalars['ID'];
};

export enum EmailType {
  Personal = 'PERSONAL',
  Corporate = 'CORPORATE',
  Academic = 'ACADEMIC'
}

export type EnableGlueTaskInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type EnableGlueTaskPayload = {
  __typename: 'EnableGlueTaskPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type EnterpriseSubscription = {
  __typename: 'EnterpriseSubscription';
  billingPeriodStart?: Maybe<Scalars['DateTime']>;
  billingPeriodEnd?: Maybe<Scalars['DateTime']>;
};

export type Entity = Node & {
  __typename: 'Entity';
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  featureFlags: Array<Maybe<FeatureFlag>>;
  views: ViewConnection;
  profileView?: Maybe<View>;
  photoUrl?: Maybe<Scalars['String']>;
  photoUploadUrl: Scalars['String'];
  uploadHeaders: Array<Scalars['String']>;
  subscriptionId?: Maybe<Scalars['Int']>;
  defaultAccess: Scalars['String'];
  privateOnly: Scalars['Boolean'];
  codeSavingEnabled: Scalars['Boolean'];
  isTeam: Scalars['Boolean'];
  entityType?: Maybe<Scalars['String']>;
  projects?: Maybe<ProjectConnection>;
  project?: Maybe<Project>;
  launchProject?: Maybe<Project>;
  latestRuns?: Maybe<RunConnection>;
  limits?: Maybe<Scalars['JSONString']>;
  available?: Maybe<Scalars['Boolean']>;
  invites?: Maybe<InviteConnection>;
  member?: Maybe<Member>;
  members: Array<Member>;
  memberCount: Scalars['Int'];
  projectCount: Scalars['Int'];
  invitedTeam?: Maybe<Scalars['String']>;
  readOnly?: Maybe<Scalars['Boolean']>;
  readOnlyAdmin: Scalars['Boolean'];
  user?: Maybe<User>;
  storageBytes: Scalars['Int64'];
  referenceBytes: Scalars['Int64'];
  storageTree?: Maybe<EntityStorageNode>;
  artifactCollectionStorageTree?: Maybe<ArtifactStorageNodeConnection>;
  computeHours: Scalars['Duration'];
  userStats?: Maybe<EntityUserConnection>;
  integrations?: Maybe<IntegrationConnection>;
  defaultAlerts: Array<Alert>;
  dailyRunCount: Array<Scalars['Int']>;
  claimingEntity?: Maybe<Entity>;
  claimedEntities?: Maybe<EntityConnection>;
  isPaid?: Maybe<Scalars['Boolean']>;
  rateLimits?: Maybe<RateLimits>;
  settings: EntitySettings;
  accessTokens: Array<AccessToken>;
  organizationId?: Maybe<Scalars['ID']>;
  organization?: Maybe<Organization>;
  repos?: Maybe<RepoConnection>;
  artifactCollections?: Maybe<ArtifactCollectionConnection>;
  defaultResourceConfigs?: Maybe<DefaultResourceConfigConnection>;
  defaultResourceConfig?: Maybe<DefaultResourceConfig>;
  tagCategories: Array<TagCategory>;
  protectedAliases: Array<Scalars['String']>;
  workflowsAdmins: Array<User>;
  secrets: Array<Secret>;
  artifactTTLDurationSeconds: Scalars['Int64'];
};


export type EntityFeatureFlagsArgs = {
  featureKey?: Maybe<Scalars['String']>;
  rampIDType: RampIdType;
};


export type EntityViewsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
};


export type EntityProjectsArgs = {
  pattern?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
};


export type EntityProjectArgs = {
  name?: Maybe<Scalars['String']>;
};


export type EntityInvitesArgs = {
  keysOnly?: Maybe<Scalars['Boolean']>;
  batchSize?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type EntityMemberArgs = {
  id: Scalars['ID'];
};


export type EntityStorageBytesArgs = {
  cached?: Maybe<Scalars['Boolean']>;
};


export type EntityReferenceBytesArgs = {
  cached?: Maybe<Scalars['Boolean']>;
};


export type EntityStorageTreeArgs = {
  enableReferenceTracking?: Maybe<Scalars['Boolean']>;
  cached?: Maybe<Scalars['Boolean']>;
};


export type EntityArtifactCollectionStorageTreeArgs = {
  artifactCollectionID: Scalars['ID'];
  enableReferenceTracking?: Maybe<Scalars['Boolean']>;
  cached?: Maybe<Scalars['Boolean']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type EntityComputeHoursArgs = {
  minDaysOld?: Maybe<Scalars['Int']>;
  cached?: Maybe<Scalars['Boolean']>;
  timeWindow?: Maybe<TimeWindow>;
};


export type EntityUserStatsArgs = {
  from: Scalars['DateTime'];
  to?: Maybe<Scalars['DateTime']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
};


export type EntityIntegrationsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type EntityDailyRunCountArgs = {
  limit: Scalars['Int'];
};


export type EntityClaimedEntitiesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type EntityReposArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type EntityArtifactCollectionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
  collectionTypes?: Maybe<Array<ArtifactCollectionType>>;
};


export type EntityDefaultResourceConfigsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type EntityDefaultResourceConfigArgs = {
  id: Scalars['ID'];
};


export type EntityProtectedAliasesArgs = {
  adminType: WorkflowsAdminType;
};


export type EntityWorkflowsAdminsArgs = {
  adminType: WorkflowsAdminType;
};

export type EntityConnection = {
  __typename: 'EntityConnection';
  edges: Array<EntityEdge>;
};

export type EntityEdge = {
  __typename: 'EntityEdge';
  node?: Maybe<Entity>;
  cursor: Scalars['String'];
};

export type EntitySettings = {
  __typename: 'EntitySettings';
  defaultCloudRegion: CloudRegion;
  openToMatchingOrgEmailDomainUsers?: Maybe<Scalars['Boolean']>;
  storageBucketInfo?: Maybe<StorageBucketInfo>;
  entityProfileEnabled: Scalars['Boolean'];
  hideActivityCalendar: Scalars['Boolean'];
  membersCanInvite?: Maybe<Scalars['Boolean']>;
  disableMagicLinkSharing?: Maybe<Scalars['Boolean']>;
  disableMembersEditArtifactTTL: Scalars['Boolean'];
  hidden: Scalars['Boolean'];
};

export type EntitySettingsInput = {
  defaultCloudRegionID?: Maybe<Scalars['String']>;
  openToMatchingOrgEmailDomainUsers?: Maybe<Scalars['Boolean']>;
  entityProfileEnabled?: Maybe<Scalars['Boolean']>;
  hideActivityCalendar?: Maybe<Scalars['Boolean']>;
  membersCanInvite?: Maybe<Scalars['Boolean']>;
  disableMagicLinkSharing?: Maybe<Scalars['Boolean']>;
  disableMembersEditArtifactTTL?: Maybe<Scalars['Boolean']>;
  hidden?: Maybe<Scalars['Boolean']>;
};

export type EntityStorageNode = StorageTreeNode & {
  __typename: 'EntityStorageNode';
  id: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int64'];
  entity: Entity;
  entityID: Scalars['ID'];
  projects: ProjectStorageNodeConnection;
  lastUpdatedAt?: Maybe<Scalars['DateTime']>;
};


export type EntityStorageNodeProjectsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  query?: Maybe<Scalars['String']>;
};

export type EntityUser = Node & {
  __typename: 'EntityUser';
  id: Scalars['ID'];
  user: User;
  runCount: Scalars['Int'];
};

export type EntityUserConnection = {
  __typename: 'EntityUserConnection';
  edges: Array<EntityUserEdge>;
};

export type EntityUserEdge = {
  __typename: 'EntityUserEdge';
  node?: Maybe<EntityUser>;
  cursor: Scalars['String'];
};

export type Error = {
  message: Scalars['String'];
};

export enum ErrorSeverity {
  Warn = 'WARN',
  Error = 'ERROR'
}

export type Event = {
  __typename: 'Event';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  kind?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  extra?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  projectName?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
};

export type EventEdge = {
  __typename: 'EventEdge';
  node?: Maybe<Event>;
  cursor: Scalars['String'];
};

export enum EventTriggeringConditionType {
  CreateArtifact = 'CREATE_ARTIFACT',
  UpdateArtifactAlias = 'UPDATE_ARTIFACT_ALIAS',
  AddArtifactAlias = 'ADD_ARTIFACT_ALIAS',
  LinkModel = 'LINK_MODEL',
  RunMetric = 'RUN_METRIC'
}

export type ExpansionPack = Node & {
  __typename: 'ExpansionPack';
  id: Scalars['ID'];
  secondsLimit: Scalars['Duration'];
  secondsRemaining: Scalars['Duration'];
  expiresAt?: Maybe<Scalars['DateTime']>;
};

export type ExpansionPackConnection = {
  __typename: 'ExpansionPackConnection';
  edges: Array<ExpansionPackEdge>;
};

export type ExpansionPackEdge = {
  __typename: 'ExpansionPackEdge';
  node: ExpansionPack;
  cursor: Scalars['String'];
};

export type Experiment = Node & {
  __typename: 'Experiment';
  id: Scalars['ID'];
  name: Scalars['String'];
  variants: Array<Variant>;
  experimentType: ExperimentType;
  startAt?: Maybe<Scalars['DateTime']>;
  endAt?: Maybe<Scalars['DateTime']>;
  updatedAt: Scalars['DateTime'];
  metadata?: Maybe<Scalars['JSON']>;
  observationalUnit: ObservationalUnit;
  sampleSize: Scalars['Int'];
  enrollmentPeriod: Scalars['Int'];
};

export enum ExperimentType {
  FeatureFlag = 'FEATURE_FLAG',
  AbTest = 'AB_TEST'
}

export type FailRunQueueItemInput = {
  runQueueItemId: Scalars['ID'];
  info?: Maybe<Scalars['JSONString']>;
  message?: Maybe<Scalars['String']>;
  stage?: Maybe<Scalars['String']>;
  filePaths?: Maybe<Array<Scalars['String']>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type FailRunQueueItemPayload = {
  __typename: 'FailRunQueueItemPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type FailedPaymentBanners = {
  __typename: 'FailedPaymentBanners';
  userID: Scalars['Int'];
  orgsWithFailedPayments: Array<Maybe<OrganizationDataForFailedPaymentBanner>>;
};

export type FeatureFlag = {
  __typename: 'FeatureFlag';
  rampKey: Scalars['String'];
  isEnabled: Scalars['Boolean'];
};

export type File = Node & {
  __typename: 'File';
  id: Scalars['ID'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  archived?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  storageBucket?: Maybe<Scalars['String']>;
  storagePath?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  special?: Maybe<Scalars['Boolean']>;
  mimetype?: Maybe<Scalars['String']>;
  /** @deprecated Use id instead */
  ndbId?: Maybe<Scalars['ID']>;
  sizeBytes: Scalars['Int64'];
  md5?: Maybe<Scalars['String']>;
  digest?: Maybe<Scalars['String']>;
  isReference: Scalars['Boolean'];
  exists: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
  uploadUrl?: Maybe<Scalars['String']>;
  uploadMultipartUrls?: Maybe<UploadMultipartUrls>;
  uploadHeaders: Array<Scalars['String']>;
  directUrl: Scalars['String'];
  artifact?: Maybe<Artifact>;
};


export type FileUrlArgs = {
  upload?: Maybe<Scalars['Boolean']>;
};

export type FileConnection = {
  __typename: 'FileConnection';
  uploadHeaders: Array<Scalars['String']>;
  pageInfo: PageInfo;
  edges: Array<FileEdge>;
};

export type FileEdge = {
  __typename: 'FileEdge';
  node?: Maybe<File>;
  cursor: Scalars['String'];
};

export type FileStorageNode = StorageTreeNode & {
  __typename: 'FileStorageNode';
  id: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int64'];
  file: File;
  fileID: Scalars['ID'];
};

export type FileStorageNodeConnection = {
  __typename: 'FileStorageNodeConnection';
  totalSize: Scalars['Int64'];
  pageInfo: PageInfo;
  edges: Array<FileStorageNodeEdge>;
};

export type FileStorageNodeEdge = {
  __typename: 'FileStorageNodeEdge';
  node: FileStorageNode;
  cursor: Scalars['String'];
};

export type FilterEventTriggeringCondition = {
  __typename: 'FilterEventTriggeringCondition';
  eventType: EventTriggeringConditionType;
  filter: Scalars['String'];
};

export type FinishedRunCondition = {
  __typename: 'FinishedRunCondition';
  success: Scalars['Boolean'];
};

export type FolderMetadata = {
  __typename: 'FolderMetadata';
  name: Scalars['String'];
  size: Scalars['Int64'];
  numberOfFiles: Scalars['Int64'];
};

export type Folders = {
  __typename: 'Folders';
  folderList: Array<Maybe<FolderMetadata>>;
};

export type ForceCleanArtifactStorageStatsInput = {
  artifactCollectionIDs: Array<Scalars['ID']>;
};

export type ForceCleanArtifactStorageStatsPayload = {
  __typename: 'ForceCleanArtifactStorageStatsPayload';
  cleanedArtifactCollectionIDs: Array<Scalars['ID']>;
  success: Scalars['Boolean'];
};

export type ForceCleanRunStorageStatsInput = {
  projectIDs: Array<Scalars['Int']>;
};

export type ForceCleanRunStorageStatsPayload = {
  __typename: 'ForceCleanRunStorageStatsPayload';
  cleanedProjectIDs: Array<Scalars['Int']>;
  success: Scalars['Boolean'];
};

export type GenerateApiKeyInput = {
  description?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type GenerateApiKeyPayload = {
  __typename: 'GenerateApiKeyPayload';
  apiKey?: Maybe<ApiKey>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type GenerateLocalLicenseInput = {
  contactEmail: Scalars['String'];
  customerName: Scalars['String'];
  seats: Scalars['Int'];
  teams: Scalars['Int'];
  storageGigs: Scalars['Int'];
  expDuration: Scalars['Int'];
  flags: Array<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type GenerateLocalLicensePayload = {
  __typename: 'GenerateLocalLicensePayload';
  localLicense: LocalLicense;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type GenericWebhookActionInput = {
  integrationID: Scalars['ID'];
  requestPayload?: Maybe<Scalars['JSONString']>;
};

export type GenericWebhookIntegration = Integration & {
  __typename: 'GenericWebhookIntegration';
  id: Scalars['ID'];
  urlEndpoint: Scalars['String'];
  name: Scalars['String'];
  secretRef?: Maybe<Scalars['String']>;
  accessTokenRef?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
};

export type GenericWebhookTriggeredAction = {
  __typename: 'GenericWebhookTriggeredAction';
  integration: Integration;
  requestPayload?: Maybe<Scalars['JSONString']>;
};

export type GitHubOAuthIntegration = Integration & {
  __typename: 'GitHubOAuthIntegration';
  id: Scalars['ID'];
};

export type GitInfo = {
  __typename: 'GitInfo';
  remote?: Maybe<Scalars['String']>;
  commit?: Maybe<Scalars['String']>;
};

export type GlueManager = {
  __typename: 'GlueManager';
  taskTypes: Array<Scalars['String']>;
  taskConfig?: Maybe<GlueTaskConfig>;
  registeredTasks: GlueTaskConnection;
};


export type GlueManagerTaskConfigArgs = {
  taskType: Scalars['String'];
};


export type GlueManagerRegisteredTasksArgs = {
  pattern?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type GlueRun = {
  __typename: 'GlueRun';
  id: Scalars['ID'];
  task: GlueTask;
  runAt: Scalars['DateTime'];
  runtimeMS: Scalars['Int64'];
  arguments?: Maybe<Scalars['JSONString']>;
  checkpoint?: Maybe<Scalars['JSONString']>;
  errors?: Maybe<Scalars['JSONString']>;
  retries: Scalars['Int'];
  succeeded: Scalars['Boolean'];
};

export type GlueRunConnection = {
  __typename: 'GlueRunConnection';
  pageInfo: PageInfo;
  edges: Array<GlueRunEdge>;
};

export type GlueRunEdge = {
  __typename: 'GlueRunEdge';
  node: GlueRun;
  cursor: Scalars['String'];
};

export type GlueTask = {
  __typename: 'GlueTask';
  id: Scalars['ID'];
  name: Scalars['String'];
  taskType: Scalars['String'];
  arguments?: Maybe<Scalars['JSONString']>;
  checkpoint?: Maybe<Scalars['JSONString']>;
  lastRunAt?: Maybe<Scalars['DateTime']>;
  status: Scalars['String'];
  strategyType: Scalars['String'];
  strategyMetadata: Scalars['String'];
  glueRuns: GlueRunConnection;
};


export type GlueTaskGlueRunsArgs = {
  pattern?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type GlueTaskConfig = {
  __typename: 'GlueTaskConfig';
  defaultArguments: Scalars['JSONString'];
  argumentsSchema: Scalars['JSONString'];
  checkpointSchema: Scalars['JSONString'];
};

export type GlueTaskConnection = {
  __typename: 'GlueTaskConnection';
  pageInfo: PageInfo;
  edges: Array<GlueTaskEdge>;
};

export type GlueTaskEdge = {
  __typename: 'GlueTaskEdge';
  node: GlueTask;
  cursor: Scalars['String'];
};

export type GroupAggregation = {
  default: Aggregation;
  custom: Array<AggregationKey>;
};

export type GroupedRun = Node & {
  __typename: 'GroupedRun';
  id: Scalars['ID'];
  name: Scalars['String'];
  projectId: Scalars['ID'];
  bucketedHistory: Array<Scalars['JSON']>;
};


export type GroupedRunBucketedHistoryArgs = {
  specs: Array<Scalars['JSONString']>;
  packVersion?: Maybe<Scalars['Int']>;
};

export type GroupedRunConnection = {
  __typename: 'GroupedRunConnection';
  edges: Array<GroupedRunEdge>;
};

export type GroupedRunEdge = {
  __typename: 'GroupedRunEdge';
  node: GroupedRun;
  cursor: Scalars['String'];
};

export enum HistoryKeysFormat {
  Plaintext = 'PLAINTEXT',
  Bitmap = 'BITMAP'
}

export type HubSettingsInput = {
  repo?: Maybe<Scalars['String']>;
  disk?: Maybe<Scalars['String']>;
  redisEnabled?: Maybe<Scalars['Boolean']>;
  dockerEnabled?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Scalars['String']>;
};

export type Identity = {
  __typename: 'Identity';
  id: Scalars['ID'];
  emailIdentityVerified: Scalars['Boolean'];
  provider?: Maybe<Scalars['String']>;
};

export type ImageUrl = {
  __typename: 'ImageUrl';
  url?: Maybe<Scalars['String']>;
  publicUrl?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type Initiator = Run | User;

export type InputArtifactConnection = ArtifactConnectionType & {
  __typename: 'InputArtifactConnection';
  totalCount: Scalars['Int'];
  pageInfo: PageInfo;
  edges: Array<InputArtifactEdge>;
};

export type InputArtifactEdge = ArtifactEdgeType & {
  __typename: 'InputArtifactEdge';
  node?: Maybe<Artifact>;
  cursor: Scalars['String'];
  usedAs: Array<Scalars['String']>;
};

export type InsertGalleryDiscussionInput = {
  spec: Scalars['String'];
  reportID: Scalars['ID'];
  sendEmail?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type InsertGalleryDiscussionPayload = {
  __typename: 'InsertGalleryDiscussionPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type InsertSecretInput = {
  entityName: Scalars['String'];
  secretName: Scalars['String'];
  secretValue: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type InsertSecretPayload = {
  __typename: 'InsertSecretPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};


export type Integration = {
  id: Scalars['ID'];
};

export type IntegrationConnection = {
  __typename: 'IntegrationConnection';
  pageInfo: PageInfo;
  edges: Array<IntegrationEdge>;
};

export type IntegrationEdge = {
  __typename: 'IntegrationEdge';
  node?: Maybe<Integration>;
  cursor: Scalars['String'];
};

export type Invite = {
  __typename: 'Invite';
  updatedAt?: Maybe<Scalars['DateTime']>;
  toUser?: Maybe<User>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  fromUser?: Maybe<User>;
  accepted?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  entity?: Maybe<Entity>;
  role: Scalars['String'];
  organization?: Maybe<Organization>;
  id: Scalars['ID'];
  name: Scalars['String'];
};

export type InviteConnection = {
  __typename: 'InviteConnection';
  pageInfo: PageInfo;
  edges: Array<InviteEdge>;
};

export type InviteEdge = {
  __typename: 'InviteEdge';
  node?: Maybe<Invite>;
  cursor: Scalars['String'];
};



export type JobSetDiff = {
  __typename: 'JobSetDiff';
  complete: Scalars['Boolean'];
  version: Scalars['Int'];
  removeJobs: Array<Scalars['ID']>;
  upsertJobs: Array<RunQueueItem>;
  metadata?: Maybe<Scalars['JSON']>;
};

export type JobSetSelectorInput = {
  jobSetName: Scalars['String'];
  entityName: Scalars['String'];
  projectName?: Maybe<Scalars['String']>;
};

export type LaunchAgent = {
  __typename: 'LaunchAgent';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  runQueues: Array<Scalars['ID']>;
  hostname: Scalars['String'];
  agentStatus: Scalars['String'];
  stopPolling: Scalars['Boolean'];
  agentConfig?: Maybe<Scalars['JSONString']>;
  version?: Maybe<Scalars['String']>;
  heartbeatAt?: Maybe<Scalars['DateTime']>;
};

export type LeaseJobSetItemInput = {
  jobSetId: Scalars['ID'];
  jobSetItemId: Scalars['ID'];
  agentId: Scalars['ID'];
};

export type LeaseJobSetItemPayload = {
  __typename: 'LeaseJobSetItemPayload';
  success: Scalars['Boolean'];
};

export type LicenseClaims = {
  __typename: 'LicenseClaims';
  seats: Scalars['Int'];
  teams: Scalars['Int'];
  storageGigs: Scalars['Int'];
  flags: Array<Scalars['String']>;
  expiresAt: Scalars['Int64'];
};

export type LicenseExpirationUi = {
  __typename: 'LicenseExpirationUI';
  bannerTotalDays: Scalars['Int'];
  bannerDaysRemaining: Scalars['Int'];
  renewUrl?: Maybe<Scalars['String']>;
};

export type LinkArtifactInput = {
  artifactID?: Maybe<Scalars['ID']>;
  artifactPortfolioID?: Maybe<Scalars['ID']>;
  artifactPortfolioName?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  aliases?: Maybe<Array<ArtifactAliasInput>>;
  clientID?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type LinkArtifactPayload = {
  __typename: 'LinkArtifactPayload';
  artifactCollectionID: Scalars['ID'];
  artifactID: Scalars['ID'];
  aliases: Array<ArtifactCollectionAlias>;
  createdAt: Scalars['DateTime'];
  versionIndex?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type LinkTeamToOrganizationInput = {
  entityName: Scalars['String'];
  organizationID: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type LinkTeamToOrganizationPayload = {
  __typename: 'LinkTeamToOrganizationPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type LocalLicense = Node & {
  __typename: 'LocalLicense';
  id: Scalars['ID'];
  uid: Scalars['String'];
  license: Scalars['String'];
  customerName: Scalars['String'];
  contactEmail: Scalars['String'];
  generatedBy: User;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  expiresAt: Scalars['DateTime'];
  claims: LicenseClaims;
  notes?: Maybe<Scalars['String']>;
};

export type LocalVersionInfo = {
  __typename: 'LocalVersionInfo';
  outOfDate: Scalars['Boolean'];
  latestVersionString: Scalars['String'];
  versionOnThisInstanceString: Scalars['String'];
};

export type LogLine = {
  __typename: 'LogLine';
  line?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type LogLineConnection = {
  __typename: 'LogLineConnection';
  pageInfo: PageInfo;
  edges: Array<LogLineEdge>;
};

export type LogLineEdge = {
  __typename: 'LogLineEdge';
  node: LogLine;
  cursor: Scalars['String'];
};

export type MaybeGroupedRunConnection = RunConnection | GroupedRunConnection;

export type Member = {
  __typename: 'Member';
  OAuthClients: Array<OAuthClient>;
  accountType?: Maybe<Scalars['String']>;
  /** @deprecated Use `role` instead. */
  admin?: Maybe<Scalars['Boolean']>;
  apiKey?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  memberRole?: Maybe<Role>;
  name: Scalars['String'];
  pending?: Maybe<Scalars['Boolean']>;
  photoUrl?: Maybe<Scalars['String']>;
  role?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type MigrateOldHourOverageSubscriptionInput = {
  organizationId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MigrateOldHourOverageSubscriptionPayload = {
  __typename: 'MigrateOldHourOverageSubscriptionPayload';
  oldSubscriptionID?: Maybe<Scalars['String']>;
  newSubscriptionID?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MigrateOldStorageSubscriptionInput = {
  organizationId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MigrateOldStorageSubscriptionPayload = {
  __typename: 'MigrateOldStorageSubscriptionPayload';
  oldSubscriptionID?: Maybe<Scalars['String']>;
  newSubscriptionID?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ModifyRunsInput = {
  ids?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSONString']>;
  entityName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  addTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  removeTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ModifyRunsPayload = {
  __typename: 'ModifyRunsPayload';
  runs: Array<Maybe<Run>>;
  runsSQL: Array<Maybe<Run>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MoveArtifactAliasAction = ArtifactAction & {
  __typename: 'MoveArtifactAliasAction';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  artifact: Artifact;
  destinationArtifact?: Maybe<Artifact>;
  initiator: Initiator;
};

export type MoveArtifactCollectionPayload = {
  __typename: 'MoveArtifactCollectionPayload';
  artifactCollection?: Maybe<ArtifactCollection>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MoveArtifactSequenceInput = {
  artifactSequenceID: Scalars['ID'];
  destinationArtifactTypeName: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MoveProjectsInput = {
  projectNames?: Maybe<Array<Scalars['String']>>;
  sourceEntityName: Scalars['String'];
  destinationEntityName: Scalars['String'];
  ownerUsername: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MoveProjectsPayload = {
  __typename: 'MoveProjectsPayload';
  tasks: Array<Task>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MoveRunsInput = {
  filters: Scalars['JSONString'];
  sourceEntityName: Scalars['String'];
  sourceProjectName: Scalars['String'];
  destinationEntityName: Scalars['String'];
  destinationProjectName: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MoveRunsPayload = {
  __typename: 'MoveRunsPayload';
  task: Task;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MoveTagCategoryInput = {
  tagCategoryName: Scalars['String'];
  oldEntityName: Scalars['String'];
  newEntityName: Scalars['String'];
  clientMutationID?: Maybe<Scalars['String']>;
};

export type MoveTagInput = {
  tagName: Scalars['String'];
  entityName: Scalars['String'];
  oldTagCategoryName: Scalars['String'];
  newTagCategoryName: Scalars['String'];
  clientMutationID?: Maybe<Scalars['String']>;
};

export type MoveViewInput = {
  id: Scalars['ID'];
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type MoveViewPayload = {
  __typename: 'MoveViewPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename: 'Mutation';
  ackJobSetItem?: Maybe<AckJobSetItemPayload>;
  ackRunQueueItem?: Maybe<AckRunQueueItemPayload>;
  addAliases?: Maybe<AddAliasesPayload>;
  addFiles?: Maybe<AddFilesPayload>;
  addProtectedAliases?: Maybe<AddProtectedAliasesPayload>;
  addRolePermissions?: Maybe<AddRolePermissionsPayload>;
  addUserToOrganization?: Maybe<AddUserToOrganizationPayload>;
  addWorkflowsAdmin?: Maybe<AddWorkflowsAdminPayload>;
  agentHeartbeat?: Maybe<AgentHeartbeatPayload>;
  attachCustomerPaymentMethod?: Maybe<AttachCustomerPaymentMethodPayload>;
  cancelCustomerSubscription?: Maybe<CancelCustomerSubscriptionPayload>;
  claimAnonymousEntity?: Maybe<ClaimAnonymousEntityPayload>;
  cloneProjects?: Maybe<CloneProjectsPayload>;
  commitArtifact?: Maybe<CommitArtifactPayload>;
  completeMultipartUploadArtifact?: Maybe<CompleteMultipartUploadArtifactPayload>;
  contactUsForComputeHours?: Maybe<ContactUsForComputeHoursPayload>;
  contactUsForUserLedTrial?: Maybe<ContactUsForUserLedTrialPayload>;
  createAWSExternalID: Scalars['String'];
  createAcademicOrReverseTrial?: Maybe<CreateAcademicOrReverseTrialPayload>;
  createAccessToken?: Maybe<CreateAccessTokenPayload>;
  createAgent?: Maybe<CreateAgentPayload>;
  createAnonymousEntity?: Maybe<CreateEntityPayload>;
  createArtifact?: Maybe<CreateArtifactPayload>;
  createArtifactCollectionTagAssignments?: Maybe<CreateTagsPayload>;
  createArtifactFiles?: Maybe<CreateArtifactFilesPayload>;
  createArtifactManifest?: Maybe<CreateArtifactManifestPayload>;
  createArtifactPortfolio?: Maybe<CreateArtifactCollectionPayload>;
  createArtifactSequence?: Maybe<CreateArtifactCollectionPayload>;
  createArtifactTagAssignments?: Maybe<CreateTagsPayload>;
  /** @deprecated Use createArtifactTypes instead */
  createArtifactType?: Maybe<CreateArtifactTypePayload>;
  createArtifactTypes?: Maybe<CreateArtifactTypesPayload>;
  createBenchmarkDiscussionComment?: Maybe<CreateDiscussionCommentPayload>;
  createBenchmarkDiscussionThread?: Maybe<CreateDiscussionThreadPayload>;
  createClient?: Maybe<CreateClientPayload>;
  createClientIDMapping?: Maybe<CreateClientIdMappingPayload>;
  createCustomChart?: Maybe<CreateCustomChartPayload>;
  createCustomerSubscription?: Maybe<CreateCustomerSubscriptionPayload>;
  createCustomerTrial?: Maybe<CreateCustomerTrialPayload>;
  createDefaultResourceConfig?: Maybe<CreateDefaultResourceConfigPayload>;
  createEmailSubscription?: Maybe<CreateEmailSubscriptionPayload>;
  createEntity?: Maybe<CreateEntityPayload>;
  createExperiment?: Maybe<CreateExperimentPayload>;
  createFilterTrigger?: Maybe<CreateFilterTriggerPayload>;
  createFinishedRunAlert?: Maybe<CreateAlertPayload>;
  createGenericWebhookIntegration?: Maybe<CreateIntegrationPayload>;
  createGitHubOAuthIntegration?: Maybe<CreateIntegrationPayload>;
  createGlueTask?: Maybe<CreateGlueTaskPayload>;
  createHourOverageSubscription?: Maybe<CreateHourOverageSubscriptionPayload>;
  createInvite?: Maybe<CreateInvitePayload>;
  createLaunchAgent?: Maybe<CreateLaunchAgentPayload>;
  createLocalLicenseOrganization?: Maybe<CreateLocalLicenseOrganizationPayload>;
  createNewsletterSubscription?: Maybe<CreateNewsletterSubscriptionPayload>;
  createOAuthProvider?: Maybe<CreateOAuthProviderPayload>;
  createOrgServiceAccount?: Maybe<CreateOrgServiceAccountPayload>;
  createPreviewableLink?: Maybe<CreatePreviewableLinkPayload>;
  createProjectMembers?: Maybe<CreateProjectMembersPayload>;
  createRole?: Maybe<CreateRolePayload>;
  createRunFiles?: Maybe<CreateRunFilesPayload>;
  createRunQueue?: Maybe<CreateRunQueuePayload>;
  createScriptableRunAlert?: Maybe<CreateAlertPayload>;
  createServiceAccount?: Maybe<CreateServiceAccountPayload>;
  createSlackChannelSubscription?: Maybe<CreateSlackChannelSubscriptionPayload>;
  createSlackIntegration?: Maybe<CreateIntegrationPayload>;
  createStoppedRunAlert?: Maybe<CreateAlertPayload>;
  createStorageSubscription?: Maybe<CreateStorageSubscriptionPayload>;
  createTeam?: Maybe<CreateTeamPayload>;
  createUser?: Maybe<CreateUserPayload>;
  createUserLedTrial?: Maybe<CreateUserLedTrialPayload>;
  createViewCommentsAlertSubscription?: Maybe<CreateEmailSubscriptionPayload>;
  createViewDiscussionComment?: Maybe<CreateViewDiscussionCommentPayload>;
  deleteAlert?: Maybe<DeleteAlertPayload>;
  deleteAlertSubscription?: Maybe<DeleteAlertSubscriptionPayload>;
  deleteAliases?: Maybe<DeleteAliasesPayload>;
  deleteApiKey?: Maybe<DeleteApiKeyPayload>;
  deleteArtifact?: Maybe<DeleteArtifactPayload>;
  deleteArtifactCollectionTagAssignments?: Maybe<DeleteTagAssignmentPayload>;
  deleteArtifactEntityTTL?: Maybe<DeleteArtifactEntityTtlPayload>;
  deleteArtifactPortfolio?: Maybe<DeleteArtifactCollectionPayload>;
  deleteArtifactSequence?: Maybe<DeleteArtifactCollectionPayload>;
  deleteArtifactTagAssignments?: Maybe<DeleteTagAssignmentPayload>;
  deleteCustomChart?: Maybe<DeleteCustomChartPayload>;
  deleteDefaultResourceConfigs?: Maybe<DeleteDefaultResourceConfigsPayload>;
  deleteDiscussionComment?: Maybe<DeleteDiscussionCommentPayload>;
  deleteExperiment?: Maybe<DeleteExperimentPayload>;
  deleteFiles?: Maybe<DeleteFilesPayload>;
  deleteFilesByRunAndFolder?: Maybe<DeleteFilesPayload>;
  deleteFromRunQueue?: Maybe<DeleteFromRunQueuePayload>;
  deleteGlueTask?: Maybe<DeleteGlueTaskPayload>;
  deleteIntegration?: Maybe<DeleteIntegrationPayload>;
  deleteInvite?: Maybe<DeleteInvitePayload>;
  deleteLaunchAgents?: Maybe<DeleteLaunchAgentsPayload>;
  deleteModel?: Maybe<DeleteModelPayload>;
  deleteOAuthProvider?: Maybe<DeleteOAuthProviderPayload>;
  deleteOrganization?: Maybe<DeleteOrganizationPayload>;
  deleteOrganizationSubscription?: Maybe<DeleteOrganizationSubscriptionPayload>;
  deleteProjectMembers?: Maybe<DeleteProjectMembersPayload>;
  deleteRole?: Maybe<DeleteRolePayload>;
  deleteRolePermissions?: Maybe<DeleteRolePermissionsPayload>;
  deleteRun?: Maybe<DeleteRunPayload>;
  deleteRunQueues?: Maybe<DeleteRunQueuesPayload>;
  deleteRuns?: Maybe<DeleteRunsPayload>;
  deleteSecret?: Maybe<DeleteSecretPayload>;
  deleteServiceAccount?: Maybe<DeleteServiceAccountPayload>;
  deleteSweep?: Maybe<DeleteSweepsPayload>;
  deleteSweeps?: Maybe<DeleteSweepsPayload>;
  deleteTeam?: Maybe<DeleteTeamPayload>;
  deleteTrigger?: Maybe<DeleteTriggerPayload>;
  deleteUser?: Maybe<DeleteUserPayload>;
  deleteUserEmail?: Maybe<DeleteUserEmailPayload>;
  deleteView?: Maybe<DeleteViewPayload>;
  deleteViews?: Maybe<DeleteViewsPayload>;
  deleteWorkflowsAdmin?: Maybe<DeleteWorkflowsAdminPayload>;
  detachCustomerPaymentMethod?: Maybe<DetachCustomerPaymentMethodPayload>;
  disableGlueTask?: Maybe<DisableGlueTaskPayload>;
  disableOldTrackingSubscription?: Maybe<DisableOldTrackingSubscriptionPayload>;
  enableGlueTask?: Maybe<EnableGlueTaskPayload>;
  failRunQueueItem?: Maybe<FailRunQueueItemPayload>;
  forceCleanArtifactStorageStats?: Maybe<ForceCleanArtifactStorageStatsPayload>;
  forceCleanRunStorageStats?: Maybe<ForceCleanRunStorageStatsPayload>;
  generateApiKey?: Maybe<GenerateApiKeyPayload>;
  generateLocalLicense?: Maybe<GenerateLocalLicensePayload>;
  insertGalleryDiscussion?: Maybe<InsertGalleryDiscussionPayload>;
  insertSecret?: Maybe<InsertSecretPayload>;
  leaseJobSetItem?: Maybe<LeaseJobSetItemPayload>;
  linkArtifact?: Maybe<LinkArtifactPayload>;
  linkTeamToOrganization?: Maybe<LinkTeamToOrganizationPayload>;
  migrateOldHourOverageSubscription?: Maybe<MigrateOldHourOverageSubscriptionPayload>;
  migrateOldStorageSubscription?: Maybe<MigrateOldStorageSubscriptionPayload>;
  modifyRuns?: Maybe<ModifyRunsPayload>;
  moveArtifactSequence?: Maybe<MoveArtifactCollectionPayload>;
  moveProjects?: Maybe<MoveProjectsPayload>;
  moveRuns?: Maybe<MoveRunsPayload>;
  moveTag?: Maybe<UpdateTagPayload>;
  moveTagCategory?: Maybe<UpdateTagCategoryPayload>;
  moveView?: Maybe<MoveViewPayload>;
  notifyScriptableRunAlert?: Maybe<NotifyScriptableRunAlertPayload>;
  popFromRunQueue?: Maybe<PopFromRunQueuePayload>;
  publishBenchmarkRun?: Maybe<PublishBenchmarkRunPayload>;
  purgeUser?: Maybe<PurgeUserPayload>;
  pushToRunQueue?: Maybe<PushToRunQueuePayload>;
  pushToRunQueueByName?: Maybe<PushToRunQueueByNamePayload>;
  removeProtectedAliases?: Maybe<RemoveProtectedAliasesPayload>;
  removeUserFromOrganization?: Maybe<RemoveUserFromOrganizationPayload>;
  removeUserFromOrganizationById?: Maybe<RemoveUserFromOrganizationByIdPayload>;
  renameProject?: Maybe<UpsertModelPayload>;
  resendEmailVerification?: Maybe<ResendEmailVerificationPayload>;
  resetPassword?: Maybe<ResetPasswordPayload>;
  retryStripeOpenInvoicesByOrganization?: Maybe<RetryStripeOpenInvoicesByOrganizationPayload>;
  revokeAccessToken?: Maybe<RevokeAccessTokenPayload>;
  revokeAccessTokenEmail?: Maybe<RevokeAccessTokenEmailPayload>;
  revokeClient?: Maybe<RevokeClientPayload>;
  rewindRun?: Maybe<RewindRunPayload>;
  sendRequestOrgEmailDomainVerification?: Maybe<SendRequestOrgEmailDomainVerificationPayload>;
  setDismissedBanner?: Maybe<SetDismissedBannerPayload>;
  setTagColor?: Maybe<SetTagColorPayload>;
  starProject?: Maybe<StarProjectPayload>;
  starView?: Maybe<StarViewPayload>;
  startExperiment?: Maybe<UpdateExperimentPayload>;
  stopExperiment?: Maybe<UpdateExperimentPayload>;
  stopRun?: Maybe<StopRunPayload>;
  submitBenchmarkRun?: Maybe<SubmitBenchmarkRunPayload>;
  submitFCNews?: Maybe<SubmitFcNewsPayload>;
  testBucketStoreConnection: Array<BucketStoreConnectionError>;
  testGenericWebhookIntegration?: Maybe<TestGenericWebhookIntegrationPayload>;
  triggerParquetExport?: Maybe<TriggerParquetExportPayload>;
  undeleteRuns?: Maybe<UndeleteRunsPayload>;
  undeleteUser?: Maybe<UndeleteUserPayload>;
  unlinkArtifact?: Maybe<UnlinkArtifactPayload>;
  unstarProject?: Maybe<UnstarProjectPayload>;
  unstarView?: Maybe<UnstarViewPayload>;
  updateAccessToken?: Maybe<UpdateAccessTokenPayload>;
  updateAccessTokenProjects?: Maybe<UpdateAccessTokenProjectsPayload>;
  updateArtifact?: Maybe<UpdateArtifactPayload>;
  updateArtifactEntityTTL?: Maybe<UpdateArtifactEntityTtlPayload>;
  updateArtifactManifest?: Maybe<UpdateArtifactManifestPayload>;
  updateArtifactPortfolio?: Maybe<UpdateArtifactCollectionPayload>;
  updateArtifactSequence?: Maybe<UpdateArtifactCollectionPayload>;
  updateArtifactType?: Maybe<UpdateArtifactTypePayload>;
  updateBenchmarkRun?: Maybe<UpdateBenchmarkRunPayload>;
  updateCustomChart?: Maybe<UpdateCustomChartPayload>;
  updateCustomerDefaultPaymentMethod?: Maybe<UpdateCustomerDefaultPaymentMethodPayload>;
  updateCustomerPaymentMethod?: Maybe<UpdateCustomerPaymentMethodPayload>;
  updateDefaultResourceConfig?: Maybe<UpdateDefaultResourceConfigPayload>;
  updateDiscussionComment?: Maybe<UpdateDiscussionCommentPayload>;
  updateDiscussionThread?: Maybe<UpdateDiscussionThreadPayload>;
  updateEntity?: Maybe<UpdateEntityPayload>;
  updateExperiment?: Maybe<UpdateExperimentPayload>;
  updateFilterTrigger?: Maybe<UpdateFilterTriggerPayload>;
  updateGenericWebhookIntegration?: Maybe<CreateIntegrationPayload>;
  updateGlueTask?: Maybe<UpdateGlueTaskPayload>;
  updateLaunchAgent?: Maybe<UpdateLaunchAgentPayload>;
  updateLocalLicense?: Maybe<UpdateLocalLicensePayload>;
  updateMember?: Maybe<UpdateMemberPayload>;
  updateModelsSeat?: Maybe<UpdateModelsSeatPayload>;
  updateOrganization?: Maybe<UpdateOrganizationPayload>;
  updateOrganizationBillingUser?: Maybe<UpdateOrganizationBillingUserPayload>;
  updateOrganizationPrivacySettings?: Maybe<UpdateOrganizationPrivacySettingsPayload>;
  updateOrganizationSubscription?: Maybe<UpdateOrganizationSubscriptionPayload>;
  updateOrganizationSubscriptionSeats?: Maybe<UpdateOrganizationSubscriptionSeatsPayload>;
  updateOrganizationUser?: Maybe<UpdateOrganizationUserPayload>;
  updateOrganizationUserV2?: Maybe<UpdateOrganizationUserV2Payload>;
  updateProjectMember?: Maybe<UpdateProjectMemberPayload>;
  updateRampThreshold?: Maybe<UpdateRampThresholdPayload>;
  updateRole?: Maybe<UpdateRolePayload>;
  updateRunQueue?: Maybe<UpdateRunQueuePayload>;
  updateRunQueueItemWarning?: Maybe<UpdateRunQueueItemWarningPayload>;
  updateStoppedRunAlert?: Maybe<UpdateAlertPayload>;
  updateSubscription?: Maybe<UpdateSubscriptionPayload>;
  updateTag?: Maybe<UpdateTagPayload>;
  updateTagCategory?: Maybe<UpdateTagCategoryPayload>;
  updateUser?: Maybe<UpdateUserPayload>;
  updateUserEmail?: Maybe<UpdateUserEmailPayload>;
  updateUserRole?: Maybe<UpdateUserRolePayload>;
  updateUserRoleV2?: Maybe<UpdateUserRoleV2Payload>;
  upgradeCustomerSubscription?: Maybe<UpgradeCustomerSubscriptionPayload>;
  upgradeCustomerToProPlan?: Maybe<UpgradeCustomerToProPlanPayload>;
  upgradeTrialSubscription?: Maybe<UpgradeTrialSubscriptionPayload>;
  /** @deprecated Use createPreviewableLink instead */
  uploadPanel?: Maybe<UploadPanelPayload>;
  upsertBucket?: Maybe<UpsertBucketPayload>;
  upsertModel?: Maybe<UpsertModelPayload>;
  upsertRunGroup?: Maybe<UpsertRunGroupPayload>;
  upsertRunQueue?: Maybe<UpsertRunQueuePayload>;
  upsertSharedView?: Maybe<UpsertSharedViewPayload>;
  upsertSweep?: Maybe<UpsertSweepPayload>;
  upsertView?: Maybe<UpsertViewPayload>;
  useArtifact?: Maybe<UseArtifactPayload>;
  viewView?: Maybe<ViewViewPayload>;
};


export type MutationAckJobSetItemArgs = {
  input: AckJobSetItemInput;
};


export type MutationAckRunQueueItemArgs = {
  input: AckRunQueueItemInput;
};


export type MutationAddAliasesArgs = {
  input: AddAliasesInput;
};


export type MutationAddFilesArgs = {
  input: AddFilesInput;
};


export type MutationAddProtectedAliasesArgs = {
  input: AddProtectedAliasesInput;
};


export type MutationAddRolePermissionsArgs = {
  input: AddRolePermissionsInput;
};


export type MutationAddUserToOrganizationArgs = {
  input: AddUserToOrganizationInput;
};


export type MutationAddWorkflowsAdminArgs = {
  input: AddWorkflowsAdminInput;
};


export type MutationAgentHeartbeatArgs = {
  input: AgentHeartbeatInput;
};


export type MutationAttachCustomerPaymentMethodArgs = {
  input: AttachCustomerPaymentMethodInput;
};


export type MutationCancelCustomerSubscriptionArgs = {
  input: CancelCustomerSubscriptionInput;
};


export type MutationClaimAnonymousEntityArgs = {
  input: ClaimAnonymousEntityInput;
};


export type MutationCloneProjectsArgs = {
  input: CloneProjectsInput;
};


export type MutationCommitArtifactArgs = {
  input: CommitArtifactInput;
};


export type MutationCompleteMultipartUploadArtifactArgs = {
  input: CompleteMultipartUploadArtifactInput;
};


export type MutationContactUsForComputeHoursArgs = {
  input: ContactUsForComputeHoursInput;
};


export type MutationContactUsForUserLedTrialArgs = {
  input: ContactUsForUserLedTrialInput;
};


export type MutationCreateAwsExternalIdArgs = {
  input: CreateAwsExternalIdInput;
};


export type MutationCreateAcademicOrReverseTrialArgs = {
  input: CreateAcademicOrReverseTrialInput;
};


export type MutationCreateAccessTokenArgs = {
  input: CreateAccessTokenInput;
};


export type MutationCreateAgentArgs = {
  input: CreateAgentInput;
};


export type MutationCreateAnonymousEntityArgs = {
  input: CreateAnonymousEntityInput;
};


export type MutationCreateArtifactArgs = {
  input: CreateArtifactInput;
};


export type MutationCreateArtifactCollectionTagAssignmentsArgs = {
  input: CreateArtifactCollectionTagAssignmentsInput;
};


export type MutationCreateArtifactFilesArgs = {
  input: CreateArtifactFilesInput;
};


export type MutationCreateArtifactManifestArgs = {
  input: CreateArtifactManifestInput;
};


export type MutationCreateArtifactPortfolioArgs = {
  input: CreateArtifactPortfolioInput;
};


export type MutationCreateArtifactSequenceArgs = {
  input: CreateArtifactSequenceInput;
};


export type MutationCreateArtifactTagAssignmentsArgs = {
  input: CreateArtifactTagAssignmentsInput;
};


export type MutationCreateArtifactTypeArgs = {
  input: CreateArtifactTypeInput;
};


export type MutationCreateArtifactTypesArgs = {
  input: CreateArtifactTypesInput;
};


export type MutationCreateBenchmarkDiscussionCommentArgs = {
  input: CreateBenchmarkDiscussionCommentInput;
};


export type MutationCreateBenchmarkDiscussionThreadArgs = {
  input: CreateBenchmarkDiscussionThreadInput;
};


export type MutationCreateClientArgs = {
  input: CreateClientInput;
};


export type MutationCreateClientIdMappingArgs = {
  input: CreateClientIdMappingInput;
};


export type MutationCreateCustomChartArgs = {
  input: CreateCustomChartInput;
};


export type MutationCreateCustomerSubscriptionArgs = {
  input: CreateCustomerSubscriptionInput;
};


export type MutationCreateCustomerTrialArgs = {
  input: CreateCustomerTrialInput;
};


export type MutationCreateDefaultResourceConfigArgs = {
  input: CreateDefaultResourceConfigInput;
};


export type MutationCreateEmailSubscriptionArgs = {
  input: CreateEmailSubscriptionInput;
};


export type MutationCreateEntityArgs = {
  input: CreateEntityInput;
};


export type MutationCreateExperimentArgs = {
  input: CreateExperimentInput;
};


export type MutationCreateFilterTriggerArgs = {
  input: CreateFilterTriggerInput;
};


export type MutationCreateFinishedRunAlertArgs = {
  input: CreateFinishedRunAlertInput;
};


export type MutationCreateGenericWebhookIntegrationArgs = {
  input: CreateGenericWebhookIntegrationInput;
};


export type MutationCreateGitHubOAuthIntegrationArgs = {
  input: CreateGitHubOAuthIntegrationInput;
};


export type MutationCreateGlueTaskArgs = {
  input: CreateGlueTaskInput;
};


export type MutationCreateHourOverageSubscriptionArgs = {
  input: CreateHourOverageSubscriptionInput;
};


export type MutationCreateInviteArgs = {
  input: CreateInviteInput;
};


export type MutationCreateLaunchAgentArgs = {
  input: CreateLaunchAgentInput;
};


export type MutationCreateLocalLicenseOrganizationArgs = {
  input: CreateLocalLicenseOrganizationInput;
};


export type MutationCreateNewsletterSubscriptionArgs = {
  input: CreateNewsletterSubscriptionInput;
};


export type MutationCreateOAuthProviderArgs = {
  input: CreateOAuthProviderInput;
};


export type MutationCreateOrgServiceAccountArgs = {
  input: CreateOrgServiceAccountInput;
};


export type MutationCreatePreviewableLinkArgs = {
  input: CreatePreviewableLinkInput;
};


export type MutationCreateProjectMembersArgs = {
  input: CreateProjectMembersInput;
};


export type MutationCreateRoleArgs = {
  input: CreateRoleInput;
};


export type MutationCreateRunFilesArgs = {
  input: CreateRunFilesInput;
};


export type MutationCreateRunQueueArgs = {
  input: CreateRunQueueInput;
};


export type MutationCreateScriptableRunAlertArgs = {
  input: CreateScriptableRunAlertInput;
};


export type MutationCreateServiceAccountArgs = {
  input: CreateServiceAccountInput;
};


export type MutationCreateSlackChannelSubscriptionArgs = {
  input: CreateSlackChannelSubscriptionInput;
};


export type MutationCreateSlackIntegrationArgs = {
  input: CreateSlackIntegrationInput;
};


export type MutationCreateStoppedRunAlertArgs = {
  input: CreateStoppedRunAlertInput;
};


export type MutationCreateStorageSubscriptionArgs = {
  input: CreateStorageSubscriptionInput;
};


export type MutationCreateTeamArgs = {
  input: CreateTeamInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateUserLedTrialArgs = {
  input: CreateUserLedTrialInput;
};


export type MutationCreateViewCommentsAlertSubscriptionArgs = {
  input: CreateViewCommentsAlertSubscriptionInput;
};


export type MutationCreateViewDiscussionCommentArgs = {
  input: CreateViewDiscussionCommentInput;
};


export type MutationDeleteAlertArgs = {
  input: DeleteAlertInput;
};


export type MutationDeleteAlertSubscriptionArgs = {
  input: DeleteAlertSubscriptionInput;
};


export type MutationDeleteAliasesArgs = {
  input: DeleteAliasesInput;
};


export type MutationDeleteApiKeyArgs = {
  input: DeleteApiKeyInput;
};


export type MutationDeleteArtifactArgs = {
  input: DeleteArtifactInput;
};


export type MutationDeleteArtifactCollectionTagAssignmentsArgs = {
  input: DeleteArtifactCollectionTagAssignmentsInput;
};


export type MutationDeleteArtifactEntityTtlArgs = {
  input: DeleteArtifactEntityTtlInput;
};


export type MutationDeleteArtifactPortfolioArgs = {
  input: DeleteArtifactPortfolioInput;
};


export type MutationDeleteArtifactSequenceArgs = {
  input: DeleteArtifactSequenceInput;
};


export type MutationDeleteArtifactTagAssignmentsArgs = {
  input: DeleteArtifactTagAssignmentsInput;
};


export type MutationDeleteCustomChartArgs = {
  input: DeleteCustomChartInput;
};


export type MutationDeleteDefaultResourceConfigsArgs = {
  input: DeleteDefaultResourceConfigsInput;
};


export type MutationDeleteDiscussionCommentArgs = {
  input: DeleteDiscussionCommentInput;
};


export type MutationDeleteExperimentArgs = {
  input: DeleteExperimentInput;
};


export type MutationDeleteFilesArgs = {
  input: DeleteFilesInput;
};


export type MutationDeleteFilesByRunAndFolderArgs = {
  input: DeleteFilesByRunAndFolderInput;
};


export type MutationDeleteFromRunQueueArgs = {
  input: DeleteFromRunQueueInput;
};


export type MutationDeleteGlueTaskArgs = {
  input: DeleteGlueTaskInput;
};


export type MutationDeleteIntegrationArgs = {
  input: DeleteIntegrationInput;
};


export type MutationDeleteInviteArgs = {
  input: DeleteInviteInput;
};


export type MutationDeleteLaunchAgentsArgs = {
  input: DeleteLaunchAgentsInput;
};


export type MutationDeleteModelArgs = {
  input: DeleteModelInput;
};


export type MutationDeleteOAuthProviderArgs = {
  input: DeleteOAuthProviderInput;
};


export type MutationDeleteOrganizationArgs = {
  input: DeleteOrganizationInput;
};


export type MutationDeleteOrganizationSubscriptionArgs = {
  input: DeleteOrganizationSubscriptionInput;
};


export type MutationDeleteProjectMembersArgs = {
  input: DeleteProjectMembersInput;
};


export type MutationDeleteRoleArgs = {
  input: DeleteRoleInput;
};


export type MutationDeleteRolePermissionsArgs = {
  input: DeleteRolePermissionsInput;
};


export type MutationDeleteRunArgs = {
  input: DeleteRunInput;
};


export type MutationDeleteRunQueuesArgs = {
  input: DeleteRunQueuesInput;
};


export type MutationDeleteRunsArgs = {
  input: DeleteRunsInput;
};


export type MutationDeleteSecretArgs = {
  input: DeleteSecretInput;
};


export type MutationDeleteServiceAccountArgs = {
  input: DeleteServiceAccountInput;
};


export type MutationDeleteSweepArgs = {
  input: DeleteSweepInput;
};


export type MutationDeleteSweepsArgs = {
  input: DeleteSweepsInput;
};


export type MutationDeleteTeamArgs = {
  input: DeleteTeamInput;
};


export type MutationDeleteTriggerArgs = {
  input: DeleteTriggerInput;
};


export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};


export type MutationDeleteUserEmailArgs = {
  input: DeleteUserEmailInput;
};


export type MutationDeleteViewArgs = {
  input: DeleteViewInput;
};


export type MutationDeleteViewsArgs = {
  input: DeleteViewsInput;
};


export type MutationDeleteWorkflowsAdminArgs = {
  input: DeleteWorkflowsAdminInput;
};


export type MutationDetachCustomerPaymentMethodArgs = {
  input: DetachCustomerPaymentMethodInput;
};


export type MutationDisableGlueTaskArgs = {
  input: DisableGlueTaskInput;
};


export type MutationDisableOldTrackingSubscriptionArgs = {
  input: DisableOldTrackingSubscriptionInput;
};


export type MutationEnableGlueTaskArgs = {
  input: EnableGlueTaskInput;
};


export type MutationFailRunQueueItemArgs = {
  input: FailRunQueueItemInput;
};


export type MutationForceCleanArtifactStorageStatsArgs = {
  input: ForceCleanArtifactStorageStatsInput;
};


export type MutationForceCleanRunStorageStatsArgs = {
  input: ForceCleanRunStorageStatsInput;
};


export type MutationGenerateApiKeyArgs = {
  input: GenerateApiKeyInput;
};


export type MutationGenerateLocalLicenseArgs = {
  input: GenerateLocalLicenseInput;
};


export type MutationInsertGalleryDiscussionArgs = {
  input: InsertGalleryDiscussionInput;
};


export type MutationInsertSecretArgs = {
  input: InsertSecretInput;
};


export type MutationLeaseJobSetItemArgs = {
  input: LeaseJobSetItemInput;
};


export type MutationLinkArtifactArgs = {
  input: LinkArtifactInput;
};


export type MutationLinkTeamToOrganizationArgs = {
  input: LinkTeamToOrganizationInput;
};


export type MutationMigrateOldHourOverageSubscriptionArgs = {
  input: MigrateOldHourOverageSubscriptionInput;
};


export type MutationMigrateOldStorageSubscriptionArgs = {
  input: MigrateOldStorageSubscriptionInput;
};


export type MutationModifyRunsArgs = {
  input: ModifyRunsInput;
};


export type MutationMoveArtifactSequenceArgs = {
  input: MoveArtifactSequenceInput;
};


export type MutationMoveProjectsArgs = {
  input: MoveProjectsInput;
};


export type MutationMoveRunsArgs = {
  input: MoveRunsInput;
};


export type MutationMoveTagArgs = {
  input: MoveTagInput;
};


export type MutationMoveTagCategoryArgs = {
  input: MoveTagCategoryInput;
};


export type MutationMoveViewArgs = {
  input: MoveViewInput;
};


export type MutationNotifyScriptableRunAlertArgs = {
  input: NotifyScriptableRunAlertInput;
};


export type MutationPopFromRunQueueArgs = {
  input: PopFromRunQueueInput;
};


export type MutationPublishBenchmarkRunArgs = {
  input: PublishBenchmarkRunInput;
};


export type MutationPurgeUserArgs = {
  input: PurgeUserInput;
};


export type MutationPushToRunQueueArgs = {
  input: PushToRunQueueInput;
};


export type MutationPushToRunQueueByNameArgs = {
  input: PushToRunQueueByNameInput;
};


export type MutationRemoveProtectedAliasesArgs = {
  input: RemoveProtectedAliasesInput;
};


export type MutationRemoveUserFromOrganizationArgs = {
  input: RemoveUserFromOrganizationInput;
};


export type MutationRemoveUserFromOrganizationByIdArgs = {
  input: RemoveUserFromOrganizationByIdInput;
};


export type MutationRenameProjectArgs = {
  input: RenameProjectInput;
};


export type MutationResendEmailVerificationArgs = {
  input: ResendEmailVerificationInput;
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationRetryStripeOpenInvoicesByOrganizationArgs = {
  input: RetryStripeOpenInvoicesByOrganizationInput;
};


export type MutationRevokeAccessTokenArgs = {
  input: RevokeAccessTokenInput;
};


export type MutationRevokeAccessTokenEmailArgs = {
  input: RevokeAccessTokenEmailInput;
};


export type MutationRevokeClientArgs = {
  input: RevokeClientInput;
};


export type MutationRewindRunArgs = {
  input: RewindRunInput;
};


export type MutationSendRequestOrgEmailDomainVerificationArgs = {
  input: SendRequestOrgEmailDomainVerificationInput;
};


export type MutationSetDismissedBannerArgs = {
  input: SetDismissedBannerInput;
};


export type MutationSetTagColorArgs = {
  input: SetTagColorInput;
};


export type MutationStarProjectArgs = {
  input: StarProjectInput;
};


export type MutationStarViewArgs = {
  input: StarViewInput;
};


export type MutationStartExperimentArgs = {
  input: StartExperimentInput;
};


export type MutationStopExperimentArgs = {
  input: StopExperimentInput;
};


export type MutationStopRunArgs = {
  input: StopRunInput;
};


export type MutationSubmitBenchmarkRunArgs = {
  input: SubmitBenchmarkRunInput;
};


export type MutationSubmitFcNewsArgs = {
  input: SubmitFcNewsInput;
};


export type MutationTestBucketStoreConnectionArgs = {
  input: StorageBucketInfoInput;
};


export type MutationTestGenericWebhookIntegrationArgs = {
  input: TestGenericWebhookIntegrationInput;
};


export type MutationTriggerParquetExportArgs = {
  input: TriggerParquetExportInput;
};


export type MutationUndeleteRunsArgs = {
  input: UndeleteRunsInput;
};


export type MutationUndeleteUserArgs = {
  input: UndeleteUserInput;
};


export type MutationUnlinkArtifactArgs = {
  input: UnlinkArtifactInput;
};


export type MutationUnstarProjectArgs = {
  input: UnstarProjectInput;
};


export type MutationUnstarViewArgs = {
  input: UnstarViewInput;
};


export type MutationUpdateAccessTokenArgs = {
  input: UpdateAccessTokenInput;
};


export type MutationUpdateAccessTokenProjectsArgs = {
  input: UpdateAccessTokenProjectsInput;
};


export type MutationUpdateArtifactArgs = {
  input: UpdateArtifactInput;
};


export type MutationUpdateArtifactEntityTtlArgs = {
  input: UpdateArtifactEntityTtlInput;
};


export type MutationUpdateArtifactManifestArgs = {
  input: UpdateArtifactManifestInput;
};


export type MutationUpdateArtifactPortfolioArgs = {
  input: UpdateArtifactPortfolioInput;
};


export type MutationUpdateArtifactSequenceArgs = {
  input: UpdateArtifactSequenceInput;
};


export type MutationUpdateArtifactTypeArgs = {
  input: UpdateArtifactTypeInput;
};


export type MutationUpdateBenchmarkRunArgs = {
  input: UpdateBenchmarkRunInput;
};


export type MutationUpdateCustomChartArgs = {
  input: UpdateCustomChartInput;
};


export type MutationUpdateCustomerDefaultPaymentMethodArgs = {
  input: UpdateCustomerDefaultPaymentMethodInput;
};


export type MutationUpdateCustomerPaymentMethodArgs = {
  input: UpdateCustomerPaymentMethodInput;
};


export type MutationUpdateDefaultResourceConfigArgs = {
  input: UpdateDefaultResourceConfigInput;
};


export type MutationUpdateDiscussionCommentArgs = {
  input: UpdateDiscussionCommentInput;
};


export type MutationUpdateDiscussionThreadArgs = {
  input: UpdateDiscussionThreadInput;
};


export type MutationUpdateEntityArgs = {
  input: UpdateEntityInput;
};


export type MutationUpdateExperimentArgs = {
  input: UpdateExperimentInput;
};


export type MutationUpdateFilterTriggerArgs = {
  input: UpdateFilterTriggerInput;
};


export type MutationUpdateGenericWebhookIntegrationArgs = {
  input: UpdateGenericWebhookIntegrationInput;
};


export type MutationUpdateGlueTaskArgs = {
  input: UpdateGlueTaskInput;
};


export type MutationUpdateLaunchAgentArgs = {
  input: UpdateLaunchAgentInput;
};


export type MutationUpdateLocalLicenseArgs = {
  input: UpdateLocalLicenseInput;
};


export type MutationUpdateMemberArgs = {
  input: UpdateMemberInput;
};


export type MutationUpdateModelsSeatArgs = {
  input: UpdateModelsSeatInput;
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};


export type MutationUpdateOrganizationBillingUserArgs = {
  input: UpdateOrganizationBillingUserInput;
};


export type MutationUpdateOrganizationPrivacySettingsArgs = {
  input: UpdateOrganizationPrivacySettingsInput;
};


export type MutationUpdateOrganizationSubscriptionArgs = {
  input: UpdateOrganizationSubscriptionInput;
};


export type MutationUpdateOrganizationSubscriptionSeatsArgs = {
  input: UpdateOrganizationSubscriptionSeatsInput;
};


export type MutationUpdateOrganizationUserArgs = {
  input: UpdateOrganizationUserInput;
};


export type MutationUpdateOrganizationUserV2Args = {
  input: UpdateOrganizationUserV2Input;
};


export type MutationUpdateProjectMemberArgs = {
  input: UpdateProjectMemberInput;
};


export type MutationUpdateRampThresholdArgs = {
  input: UpdateRampThresholdInput;
};


export type MutationUpdateRoleArgs = {
  input: UpdateRoleInput;
};


export type MutationUpdateRunQueueArgs = {
  input: UpdateRunQueueInput;
};


export type MutationUpdateRunQueueItemWarningArgs = {
  input: UpdateRunQueueItemWarningInput;
};


export type MutationUpdateStoppedRunAlertArgs = {
  input: UpdateStoppedRunAlertInput;
};


export type MutationUpdateSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};


export type MutationUpdateTagArgs = {
  input: UpdateTagInput;
};


export type MutationUpdateTagCategoryArgs = {
  input: UpdateTagCategoryInput;
};


export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};


export type MutationUpdateUserEmailArgs = {
  input: UpdateUserEmailInput;
};


export type MutationUpdateUserRoleArgs = {
  input: UpdateUserRoleInput;
};


export type MutationUpdateUserRoleV2Args = {
  input: UpdateUserRoleV2Input;
};


export type MutationUpgradeCustomerSubscriptionArgs = {
  input: UpgradeCustomerSubscriptionInput;
};


export type MutationUpgradeCustomerToProPlanArgs = {
  input: UpgradeCustomerToProPlanInput;
};


export type MutationUpgradeTrialSubscriptionArgs = {
  input: UpgradeTrialSubscriptionInput;
};


export type MutationUploadPanelArgs = {
  input: UploadPanelInput;
};


export type MutationUpsertBucketArgs = {
  input: UpsertBucketInput;
};


export type MutationUpsertModelArgs = {
  input: UpsertModelInput;
};


export type MutationUpsertRunGroupArgs = {
  input: UpsertRunGroupInput;
};


export type MutationUpsertRunQueueArgs = {
  input: UpsertRunQueueInput;
};


export type MutationUpsertSharedViewArgs = {
  input: UpsertSharedViewInput;
};


export type MutationUpsertSweepArgs = {
  input: UpsertSweepInput;
};


export type MutationUpsertViewArgs = {
  input: UpsertViewInput;
};


export type MutationUseArtifactArgs = {
  input: UseArtifactInput;
};


export type MutationViewViewArgs = {
  input: ViewViewInput;
};

export type NewsletterSubscription = {
  __typename: 'NewsletterSubscription';
  id: Scalars['ID'];
  user?: Maybe<User>;
  email?: Maybe<Scalars['String']>;
  createdAt: Scalars['DateTime'];
};

export type NewsletterSubscriptionConnection = {
  __typename: 'NewsletterSubscriptionConnection';
  edges: Array<NewsletterSubscriptionEdge>;
};

export type NewsletterSubscriptionEdge = {
  __typename: 'NewsletterSubscriptionEdge';
  node?: Maybe<NewsletterSubscription>;
  cursor: Scalars['String'];
};

export type NoOpTriggeredAction = {
  __typename: 'NoOpTriggeredAction';
  noOp?: Maybe<Scalars['Boolean']>;
};

export type NoOpTriggeredActionInput = {
  noOp?: Maybe<Scalars['Boolean']>;
};

export type Node = {
  id: Scalars['ID'];
};

export type NotificationActionInput = {
  integrationID: Scalars['ID'];
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  severity?: Maybe<AlertSeverity>;
};

export type NotificationTriggeredAction = {
  __typename: 'NotificationTriggeredAction';
  integration: Integration;
  title?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  severity?: Maybe<AlertSeverity>;
};

export type NotifyScriptableRunAlertInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  runName: Scalars['String'];
  title: Scalars['String'];
  text: Scalars['String'];
  severity?: Maybe<AlertSeverity>;
  waitDuration?: Maybe<Scalars['Duration']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type NotifyScriptableRunAlertPayload = {
  __typename: 'NotifyScriptableRunAlertPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type OAuthClient = {
  __typename: 'OAuthClient';
  id: Scalars['String'];
  subject: Scalars['String'];
};

export type OAuthProvider = {
  __typename: 'OAuthProvider';
  id: Scalars['ID'];
  issuerUrl: Scalars['String'];
};

export enum ObservationalUnit {
  User = 'USER',
  AnonymousUser = 'ANONYMOUS_USER',
  Organization = 'ORGANIZATION',
  Team = 'TEAM'
}

export type OrgActivityTimeline = ActivityDuration & {
  __typename: 'OrgActivityTimeline';
  numActiveUsers: Scalars['Int'];
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
};

export enum OrgType {
  Organization = 'ORGANIZATION',
  Personal = 'PERSONAL'
}

export type Organization = Node & {
  __typename: 'Organization';
  OAuthProviders: Array<OAuthProvider>;
  activity: Array<OrgActivityTimeline>;
  artifactCollections?: Maybe<ArtifactCollectionConnection>;
  artifactSequences?: Maybe<ArtifactSequenceConnection>;
  available: Scalars['Boolean'];
  billingUser?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  featureFlags: Array<Maybe<FeatureFlag>>;
  flags?: Maybe<Scalars['JSONString']>;
  id: Scalars['ID'];
  isLaunchActive: Scalars['Boolean'];
  latestRuns?: Maybe<RunConnection>;
  member?: Maybe<OrganizationMember>;
  memberCount: Scalars['Int'];
  members: Array<OrganizationMember>;
  name: Scalars['String'];
  orgEntity?: Maybe<Entity>;
  orgType: OrgType;
  pendingInvites: Array<Invite>;
  projects?: Maybe<ProjectConnection>;
  roles: Array<Role>;
  seatAvailability: OrganizationSeatAvailability;
  serviceAccounts: Array<ServiceAccount>;
  stripeBillingInfo?: Maybe<StripeBillingInfo>;
  stripeInvoices: Array<StripeInvoice>;
  stripePaymentMethods: Array<StripePaymentMethod>;
  subscriptions: Array<OrganizationSubscription>;
  teams: Array<Entity>;
  usage: Array<UsageAggregation>;
  usedSeats: Scalars['Int'];
  usedViewOnlySeats: Scalars['Int'];
  views: ViewConnection;
  weaveEnterpriseSubscription?: Maybe<EnterpriseSubscription>;
};


export type OrganizationActivityArgs = {
  start?: Maybe<Scalars['DateTime']>;
  end?: Maybe<Scalars['DateTime']>;
};


export type OrganizationArtifactCollectionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
  collectionTypes?: Maybe<Array<ArtifactCollectionType>>;
};


export type OrganizationArtifactSequencesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
};


export type OrganizationFeatureFlagsArgs = {
  featureKey?: Maybe<Scalars['String']>;
  rampIDType: RampIdType;
};


export type OrganizationLatestRunsArgs = {
  userID: Scalars['ID'];
};


export type OrganizationMemberArgs = {
  username?: Maybe<Scalars['String']>;
};


export type OrganizationProjectsArgs = {
  userID?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  pattern?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
};


export type OrganizationUsageArgs = {
  usageType: UsageType;
  intervalMarkers: Array<Scalars['DateTime']>;
};


export type OrganizationViewsArgs = {
  userID?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
};

export type OrganizationConnection = {
  __typename: 'OrganizationConnection';
  pageInfo: PageInfo;
  edges: Array<OrganizationEdge>;
};

export type OrganizationDataForFailedPaymentBanner = {
  __typename: 'OrganizationDataForFailedPaymentBanner';
  id: Scalars['ID'];
  orgId: Scalars['Int'];
  isBillingUser: Scalars['Boolean'];
  orgName: Scalars['String'];
  billingEmail: Scalars['String'];
  daysUntilCancellation: Scalars['Int'];
  isCollectionMethodACH: Scalars['Boolean'];
};

export type OrganizationEdge = {
  __typename: 'OrganizationEdge';
  node: Organization;
  cursor: Scalars['String'];
};

export type OrganizationMember = {
  __typename: 'OrganizationMember';
  user: User;
  admin: Scalars['Boolean'];
  username: Scalars['String'];
  name: Scalars['String'];
  id: Scalars['String'];
  email: Scalars['String'];
  photoUrl: Scalars['String'];
  teams?: Maybe<EntityConnection>;
  role: Scalars['String'];
  orgID: Scalars['ID'];
  modelsSeat: Scalars['String'];
};


export type OrganizationMemberTeamsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type OrganizationSeatAvailability = {
  __typename: 'OrganizationSeatAvailability';
  seats: Scalars['Int'];
  viewOnlySeats: Scalars['Int'];
  expiresAt?: Maybe<Scalars['DateTime']>;
};

export type OrganizationSubscription = {
  __typename: 'OrganizationSubscription';
  id: Scalars['ID'];
  plan: Plan;
  nextPlans: Array<Plan>;
  privileges: Scalars['JSON'];
  seats: Scalars['Int'];
  subscriptionType: OrganizationSubscriptionType;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  status: OrganizationSubscriptionStatus;
  expiresAt?: Maybe<Scalars['DateTime']>;
  availableSeatsToPurchase: Scalars['Int'];
  expansionPacks: ExpansionPackConnection;
  isAutomaticUpgrade: Scalars['Boolean'];
  thresholdCrossedAt?: Maybe<Scalars['DateTime']>;
  createdAt: Scalars['DateTime'];
  upgradedAt?: Maybe<Scalars['DateTime']>;
  billingPeriodStart?: Maybe<Scalars['DateTime']>;
  billingPeriodEnd?: Maybe<Scalars['DateTime']>;
  discount?: Maybe<Discount>;
};


export type OrganizationSubscriptionExpansionPacksArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum OrganizationSubscriptionStatus {
  Enabled = 'ENABLED',
  Disabled = 'DISABLED'
}

export enum OrganizationSubscriptionType {
  Stripe = 'STRIPE',
  Enterprise = 'ENTERPRISE',
  ManualTrial = 'MANUAL_TRIAL',
  UserLedTrial = 'USER_LED_TRIAL',
  AcademicTrial = 'ACADEMIC_TRIAL',
  Academic = 'ACADEMIC',
  Local = 'LOCAL',
  FreePlan = 'FREE_PLAN'
}

export type PageInfo = {
  __typename: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
};

export type ParquetHistory = {
  __typename: 'ParquetHistory';
  liveData: Array<Scalars['JSON']>;
  parquetUrls: Array<Scalars['String']>;
};

export type PaymentMetadata = {
  __typename: 'PaymentMetadata';
  orgName?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  shouldUpgradeToTeams?: Maybe<Scalars['Boolean']>;
};

export type PaymentMethod = {
  __typename: 'PaymentMethod';
  id: Scalars['ID'];
  type: Scalars['String'];
  cardType?: Maybe<Scalars['String']>;
  endingIn?: Maybe<Scalars['String']>;
};

export type Plan = {
  __typename: 'Plan';
  id: Scalars['ID'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  stripePlanId?: Maybe<Scalars['String']>;
  maxSeats: Scalars['Int'];
  defaultPrivileges: Scalars['JSON'];
  planType: PlanType;
  planVisibility: PlanVisibility;
  unitPrice?: Maybe<Scalars['Int']>;
  billingInterval?: Maybe<Scalars['String']>;
  stripePrice?: Maybe<StripePrice>;
};

export enum PlanType {
  Primary = 'PRIMARY',
  Storage = 'STORAGE',
  Reference = 'REFERENCE',
  HourOverage = 'HOUR_OVERAGE',
  WeaveIngestionCommit = 'WEAVE_INGESTION_COMMIT',
  ModelsSeats = 'MODELS_SEATS',
  WeaveIngestionUsage = 'WEAVE_INGESTION_USAGE',
  HourUsage = 'HOUR_USAGE'
}

export enum PlanVisibility {
  Public = 'PUBLIC',
  Private = 'PRIVATE',
  Deprecated = 'DEPRECATED'
}

export type PopFromRunQueueInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  queueName: Scalars['String'];
  launchAgentId?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PopFromRunQueuePayload = {
  __typename: 'PopFromRunQueuePayload';
  runQueueItemId?: Maybe<Scalars['ID']>;
  runSpec?: Maybe<Scalars['JSON']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PreviewFriendlyUrl = {
  __typename: 'PreviewFriendlyURL';
  id: Scalars['ID'];
  URL: Scalars['String'];
  viewID: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type PreviewableLink = {
  __typename: 'PreviewableLink';
  id: Scalars['ID'];
  URL: Scalars['String'];
  resourceID: Scalars['ID'];
  resourceType: PreviewableLinkResourceType;
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export enum PreviewableLinkResourceType {
  View = 'View',
  User = 'User',
  Entity = 'Entity'
}

export type Project = Node & {
  __typename: 'Project';
  access?: Maybe<Scalars['String']>;
  allowAllArtifactTypesInRegistry: Scalars['Boolean'];
  artifact?: Maybe<Artifact>;
  artifacts: Array<Artifact>;
  artifactCollection?: Maybe<ArtifactCollection>;
  artifactType?: Maybe<ArtifactType>;
  bucket?: Maybe<Run>;
  bucketCount?: Maybe<Scalars['Int']>;
  computeHours: Scalars['Duration'];
  createdAt: Scalars['DateTime'];
  description?: Maybe<Scalars['String']>;
  dockerImage?: Maybe<Scalars['String']>;
  entity: Entity;
  entityId?: Maybe<Scalars['Int']>;
  entityName: Scalars['String'];
  featured?: Maybe<Scalars['Int']>;
  gitHubSubmissionRepo?: Maybe<Scalars['String']>;
  groupPath?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  internalId: Scalars['ID'];
  isBenchmark: Scalars['Boolean'];
  isPublished: Scalars['Boolean'];
  lastActive?: Maybe<Scalars['DateTime']>;
  launchAgents: Array<LaunchAgent>;
  linkedBenchmark?: Maybe<Project>;
  media?: Maybe<Scalars['String']>;
  member?: Maybe<ProjectMember>;
  members: Array<ProjectMember>;
  name: Scalars['String'];
  ndbId?: Maybe<Scalars['ID']>;
  pathCounts?: Maybe<Scalars['JSONString']>;
  public?: Maybe<Scalars['Boolean']>;
  rateLimits?: Maybe<RateLimits>;
  readOnly?: Maybe<Scalars['Boolean']>;
  repo?: Maybe<Scalars['String']>;
  requestSubscribe?: Maybe<Scalars['Boolean']>;
  run?: Maybe<Run>;
  runActiveCount: Scalars['Int'];
  runCount: Scalars['Int'];
  runGroup?: Maybe<RunGroup>;
  runKeySuggestions: Array<RunKeySuggestion>;
  runOutputArtifactsCount: Scalars['Int'];
  runQueue?: Maybe<RunQueue>;
  runQueues: Array<RunQueue>;
  starred: Scalars['Boolean'];
  storageBytes: Scalars['Int64'];
  subGroupPath?: Maybe<Scalars['String']>;
  summaryMetrics?: Maybe<Scalars['JSONString']>;
  sweep?: Maybe<Sweep>;
  tagCounts: Array<RunTagCount>;
  /** @deprecated Use runTags instead */
  tags: Array<RunTag>;
  totalArtifactCollections: Scalars['Int'];
  fromV2?: Maybe<Scalars['Boolean']>;
  totalRuns?: Maybe<Scalars['Int']>;
  /** @deprecated Field no longer supported */
  totalRunsWithGroup: Scalars['Int'];
  /** @deprecated Field no longer supported */
  totalRunsWithJobType: Scalars['Int'];
  /** @deprecated Use computeHours instead */
  totalRunTime?: Maybe<Scalars['Float']>;
  totalSweeps: Scalars['Int'];
  totalUsers?: Maybe<Scalars['Int']>;
  triggers: Array<Trigger>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
  valueCounts?: Maybe<Scalars['JSONString']>;
  views?: Maybe<Scalars['JSONString']>;
  runs?: Maybe<RunConnection>;
  groupedRuns?: Maybe<MaybeGroupedRunConnection>;
  buckets?: Maybe<RunConnection>;
  allViews?: Maybe<ViewConnection>;
  runFieldValues?: Maybe<RunFieldValueConnection>;
  sweeps?: Maybe<SweepConnection>;
  runTags?: Maybe<RunTagConnection>;
  benchmarkRuns?: Maybe<BenchmarkRunConnection>;
  benchmarkSubmissions?: Maybe<BenchmarkRunConnection>;
  benchmarkDiscussionThreads: DiscussionThreadConnection;
  fields: ProjectFieldConnection;
  artifactTypes: ArtifactTypeConnection;
  artifactCollections?: Maybe<ArtifactCollectionConnection>;
  parameterImportance: Scalars['JSON'];
  defaultResourceConfigs?: Maybe<DefaultResourceConfigConnection>;
  weavePermissions: ProjectPermissions;
};


export type ProjectArtifactArgs = {
  name: Scalars['String'];
  enableTracking?: Maybe<Scalars['Boolean']>;
};


export type ProjectArtifactsArgs = {
  filters?: Maybe<Scalars['JSONString']>;
};


export type ProjectArtifactCollectionArgs = {
  name: Scalars['String'];
};


export type ProjectArtifactTypeArgs = {
  name: Scalars['String'];
};


export type ProjectBucketArgs = {
  desc?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  missingOk?: Maybe<Scalars['Boolean']>;
};


export type ProjectMemberArgs = {
  id: Scalars['ID'];
};


export type ProjectPathCountsArgs = {
  filters?: Maybe<Scalars['JSONString']>;
};


export type ProjectRunArgs = {
  desc?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};


export type ProjectRunActiveCountArgs = {
  filters?: Maybe<Scalars['JSONString']>;
};


export type ProjectRunCountArgs = {
  filters?: Maybe<Scalars['JSONString']>;
};


export type ProjectRunGroupArgs = {
  name: Scalars['String'];
};


export type ProjectRunKeySuggestionsArgs = {
  keyPath: Scalars['String'];
  filters?: Maybe<Scalars['JSONString']>;
};


export type ProjectRunOutputArtifactsCountArgs = {
  filters?: Maybe<Scalars['JSONString']>;
};


export type ProjectRunQueueArgs = {
  name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};


export type ProjectStarredArgs = {
  organizationId?: Maybe<Scalars['ID']>;
};


export type ProjectSweepArgs = {
  sweepName: Scalars['String'];
};


export type ProjectTagCountsArgs = {
  filters?: Maybe<Scalars['JSONString']>;
};


export type ProjectTotalArtifactCollectionsArgs = {
  collectionTypes?: Maybe<Array<ArtifactCollectionType>>;
};


export type ProjectTriggersArgs = {
  projectOnly?: Maybe<Scalars['Boolean']>;
};


export type ProjectValueCountsArgs = {
  keyPath?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
};


export type ProjectRunsArgs = {
  jobKey?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSONString']>;
  order?: Maybe<Scalars['String']>;
  groupKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupAggregation?: Maybe<GroupAggregation>;
  groupLevel?: Maybe<Scalars['Int']>;
  historyFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProjectGroupedRunsArgs = {
  jobKey?: Maybe<Scalars['String']>;
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
  filters?: Maybe<Scalars['JSONString']>;
  order?: Maybe<Scalars['String']>;
  groupKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupAggregation?: Maybe<GroupAggregation>;
  groupLevel?: Maybe<Scalars['Int']>;
  historyFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProjectBucketsArgs = {
  jobKey?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProjectAllViewsArgs = {
  userId?: Maybe<Scalars['Int']>;
  viewType?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  viewName?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type ProjectRunFieldValuesArgs = {
  keyPath: Scalars['String'];
  filters?: Maybe<Scalars['JSONString']>;
  valuePattern?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProjectSweepsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
};


export type ProjectRunTagsArgs = {
  pattern?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProjectBenchmarkRunsArgs = {
  state: Scalars['String'];
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProjectBenchmarkSubmissionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProjectBenchmarkDiscussionThreadsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProjectFieldsArgs = {
  types?: Maybe<Array<Scalars['String']>>;
  columns?: Maybe<Array<Scalars['String']>>;
  pattern?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProjectArtifactTypesArgs = {
  includeAll?: Maybe<Scalars['Boolean']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProjectArtifactCollectionsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  collectionTypes?: Maybe<Array<ArtifactCollectionType>>;
};


export type ProjectParameterImportanceArgs = {
  filters?: Maybe<Scalars['JSONString']>;
  limit?: Maybe<Scalars['Int']>;
  target: Scalars['String'];
  parameters?: Maybe<Array<Scalars['String']>>;
  runParameters?: Maybe<Array<Scalars['String']>>;
};


export type ProjectDefaultResourceConfigsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ProjectConnection = {
  __typename: 'ProjectConnection';
  pageInfo: PageInfo;
  edges: Array<ProjectEdge>;
};

export type ProjectEdge = {
  __typename: 'ProjectEdge';
  node?: Maybe<Project>;
  cursor: Scalars['String'];
};

export type ProjectField = {
  __typename: 'ProjectField';
  path: Scalars['String'];
  type: Scalars['String'];
};

export type ProjectFieldConnection = {
  __typename: 'ProjectFieldConnection';
  pageInfo: PageInfo;
  edges: Array<ProjectFieldEdge>;
};

export type ProjectFieldEdge = {
  __typename: 'ProjectFieldEdge';
  node: ProjectField;
  cursor: Scalars['String'];
};

export type ProjectIdentifierInput = {
  Name?: Maybe<Scalars['String']>;
  EntityName?: Maybe<Scalars['String']>;
};

export type ProjectMember = {
  __typename: 'ProjectMember';
  role: Role;
  username?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  accountType: Scalars['String'];
  photoUrl?: Maybe<Scalars['String']>;
  projectRoleIsInherited: Scalars['Boolean'];
};

export type ProjectPermissions = {
  __typename: 'ProjectPermissions';
  canRead: Scalars['Boolean'];
  canWrite: Scalars['Boolean'];
};

export type ProjectSpecifier = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
};

export type ProjectStorageNode = StorageTreeNode & {
  __typename: 'ProjectStorageNode';
  id: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int64'];
  project: Project;
  projectID: Scalars['ID'];
  runs: RunStorageNodeConnection;
  artifactSequences: ArtifactSequenceStorageNodeConnection;
  artifactTypes: Array<ArtifactTypeStorageNode>;
};


export type ProjectStorageNodeRunsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProjectStorageNodeArtifactSequencesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ProjectStorageNodeArtifactTypesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ProjectStorageNodeConnection = {
  __typename: 'ProjectStorageNodeConnection';
  totalSize: Scalars['Int64'];
  pageInfo: PageInfo;
  edges: Array<ProjectStorageNodeEdge>;
};

export type ProjectStorageNodeEdge = {
  __typename: 'ProjectStorageNodeEdge';
  node: ProjectStorageNode;
  cursor: Scalars['String'];
};

export type PublicImageUploadInfo = {
  __typename: 'PublicImageUploadInfo';
  uploadUrl: Scalars['String'];
  imageUrl: Scalars['String'];
  uploadHeaders: Array<Scalars['String']>;
};

export type PublishBenchmarkRunInput = {
  id: Scalars['ID'];
  isCodeHidden?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
  benchmarkName?: Maybe<Scalars['String']>;
  benchmarkEntityName?: Maybe<Scalars['String']>;
};

export type PublishBenchmarkRunPayload = {
  __typename: 'PublishBenchmarkRunPayload';
  benchmarkRun: BenchmarkRun;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PurgeUserInput = {
  username: Scalars['String'];
  email: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PurgeUserPayload = {
  __typename: 'PurgeUserPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PushToRunQueueByNameInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  queueName: Scalars['String'];
  runSpec: Scalars['JSONString'];
  templateVariableValues?: Maybe<Scalars['JSONString']>;
  priority?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PushToRunQueueByNamePayload = {
  __typename: 'PushToRunQueueByNamePayload';
  runQueueItemId: Scalars['ID'];
  runSpec: Scalars['JSONString'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PushToRunQueueInput = {
  queueID: Scalars['ID'];
  runSpec: Scalars['JSONString'];
  templateVariableValues?: Maybe<Scalars['JSONString']>;
  priority?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type PushToRunQueuePayload = {
  __typename: 'PushToRunQueuePayload';
  runQueueItemId: Scalars['ID'];
  runSpec: Scalars['JSONString'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename: 'Query';
  models?: Maybe<ProjectConnection>;
  /** @deprecated use project */
  model?: Maybe<Project>;
  viewer?: Maybe<User>;
  user?: Maybe<User>;
  entity?: Maybe<Entity>;
  users?: Maybe<UserConnection>;
  numPendingInvites: Scalars['Int'];
  project?: Maybe<Project>;
  projects?: Maybe<ProjectConnection>;
  benchmarks?: Maybe<ProjectConnection>;
  publishedProjects?: Maybe<ProjectConnection>;
  entities?: Maybe<EntityConnection>;
  trendingRepos?: Maybe<RowConnection>;
  dataFrame?: Maybe<RowConnection>;
  dataFrameSchema: Array<Scalars['JSON']>;
  task?: Maybe<Task>;
  discussionThread?: Maybe<DiscussionThread>;
  discussionComment?: Maybe<DiscussionComment>;
  view?: Maybe<View>;
  viewParent?: Maybe<View>;
  views?: Maybe<ViewConnection>;
  publicViews?: Maybe<ViewConnection>;
  singletonView?: Maybe<View>;
  featuredReports?: Maybe<View>;
  galleryTagSections: Scalars['JSONString'];
  highQualityUserProfiles?: Maybe<View>;
  /** @deprecated Use previewableLink instead */
  previewFriendlyURL?: Maybe<PreviewFriendlyUrl>;
  previewableLink?: Maybe<PreviewableLink>;
  serverInfo?: Maybe<ServerInfo>;
  plans: Array<Maybe<Plan>>;
  reportSearch?: Maybe<ViewConnection>;
  organizations: OrganizationConnection;
  organization?: Maybe<Organization>;
  customChart?: Maybe<CustomChart>;
  customCharts?: Maybe<CustomChartConnection>;
  artifact?: Maybe<Artifact>;
  localLicenses: Array<Maybe<LocalLicense>>;
  publicImageUploadUrl?: Maybe<PublicImageUploadInfo>;
  invite?: Maybe<Invite>;
  newsletterSubscriptions?: Maybe<NewsletterSubscriptionConnection>;
  clientIDMapping?: Maybe<ClientIdMapping>;
  artifactCollection?: Maybe<ArtifactCollection>;
  artifactSequence?: Maybe<ArtifactSequence>;
  rampManager?: Maybe<RampManager>;
  launchAgent?: Maybe<LaunchAgent>;
  repoInsightsPlotData?: Maybe<RepoInsightsRowConnection>;
  repo?: Maybe<Repo>;
  activeExperiments: Array<ActiveExperiment>;
  experiments: Array<Experiment>;
  dismissedBanner?: Maybe<DismissedBanner>;
  failedPaymentBanners?: Maybe<FailedPaymentBanners>;
  tag?: Maybe<Tag>;
  glueManager?: Maybe<GlueManager>;
  glueRuns?: Maybe<GlueRunConnection>;
  jobSetDiff?: Maybe<JobSetDiff>;
  usage: Array<UsageAggregation>;
  coupon?: Maybe<Coupon>;
};


export type QueryModelsArgs = {
  entityName?: Maybe<Scalars['String']>;
  keysOnly?: Maybe<Scalars['Boolean']>;
  batchSize?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryModelArgs = {
  name?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
};


export type QueryViewerArgs = {
  entityName?: Maybe<Scalars['String']>;
};


export type QueryUserArgs = {
  id?: Maybe<Scalars['ID']>;
  userName?: Maybe<Scalars['String']>;
};


export type QueryEntityArgs = {
  name?: Maybe<Scalars['String']>;
  login?: Maybe<Scalars['Boolean']>;
};


export type QueryUsersArgs = {
  query?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  usernames?: Maybe<Array<Scalars['String']>>;
  ids?: Maybe<Array<Scalars['ID']>>;
};


export type QueryProjectArgs = {
  name?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  internalId?: Maybe<Scalars['ID']>;
};


export type QueryProjectsArgs = {
  entityName?: Maybe<Scalars['String']>;
  pattern?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  anonymous?: Maybe<Scalars['Boolean']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  projectIDs?: Maybe<Array<Scalars['ID']>>;
  internalIDs?: Maybe<Array<Scalars['ID']>>;
};


export type QueryBenchmarksArgs = {
  entityName: Scalars['String'];
  order?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryPublishedProjectsArgs = {
  entityName: Scalars['String'];
  order?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryEntitiesArgs = {
  order?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryTrendingReposArgs = {
  from?: Maybe<Scalars['DateTime']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDataFrameArgs = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  filters?: Maybe<Scalars['JSONString']>;
  order?: Maybe<Scalars['String']>;
  groupKeys?: Maybe<Array<Scalars['String']>>;
  dataFrameKeys: Array<Scalars['String']>;
  columns?: Maybe<Array<Scalars['String']>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryDataFrameSchemaArgs = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  dataFrameKeys: Array<Scalars['String']>;
};


export type QueryTaskArgs = {
  id: Scalars['ID'];
};


export type QueryDiscussionThreadArgs = {
  id: Scalars['ID'];
};


export type QueryDiscussionCommentArgs = {
  id: Scalars['ID'];
};


export type QueryViewArgs = {
  id: Scalars['ID'];
  type?: Maybe<Scalars['String']>;
};


export type QueryViewParentArgs = {
  id: Scalars['ID'];
};


export type QueryViewsArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryPublicViewsArgs = {
  type?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QuerySingletonViewArgs = {
  type: Scalars['String'];
};


export type QueryPreviewFriendlyUrlArgs = {
  viewID: Scalars['ID'];
};


export type QueryPreviewableLinkArgs = {
  resourceType: PreviewableLinkResourceType;
  resourceID: Scalars['ID'];
};


export type QueryReportSearchArgs = {
  query: Scalars['String'];
};


export type QueryOrganizationsArgs = {
  ids?: Maybe<Array<Scalars['ID']>>;
  emailDomain?: Maybe<Scalars['String']>;
  query?: Maybe<Scalars['String']>;
  queryOrg?: Maybe<Scalars['String']>;
  querySubId?: Maybe<Scalars['String']>;
  queryUser?: Maybe<Scalars['String']>;
  queryTeam?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryOrganizationArgs = {
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
};


export type QueryCustomChartArgs = {
  id: Scalars['ID'];
};


export type QueryCustomChartsArgs = {
  type: Scalars['String'];
  entity?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryArtifactArgs = {
  id: Scalars['ID'];
};


export type QueryLocalLicensesArgs = {
  expired?: Maybe<Scalars['Boolean']>;
};


export type QueryInviteArgs = {
  key: Scalars['String'];
};


export type QueryNewsletterSubscriptionsArgs = {
  order?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryClientIdMappingArgs = {
  clientID: Scalars['ID'];
};


export type QueryArtifactCollectionArgs = {
  id: Scalars['ID'];
};


export type QueryArtifactSequenceArgs = {
  id: Scalars['ID'];
};


export type QueryLaunchAgentArgs = {
  id: Scalars['ID'];
};


export type QueryRepoInsightsPlotDataArgs = {
  plotName: Scalars['String'];
  repoName: Scalars['String'];
  filters?: Maybe<Scalars['JSONString']>;
  order?: Maybe<Scalars['String']>;
  groupKeys?: Maybe<Array<Scalars['String']>>;
  columns?: Maybe<Array<Scalars['String']>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryRepoArgs = {
  id: Scalars['ID'];
};


export type QueryDismissedBannerArgs = {
  id: Scalars['ID'];
};


export type QueryFailedPaymentBannersArgs = {
  id: Scalars['ID'];
};


export type QueryTagArgs = {
  entityName?: Maybe<Scalars['String']>;
  tag: QueryTagInput;
};


export type QueryGlueRunsArgs = {
  taskID: Scalars['ID'];
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type QueryJobSetDiffArgs = {
  id?: Maybe<Scalars['ID']>;
  selector?: Maybe<JobSetSelectorInput>;
  fromVersion?: Maybe<Scalars['Int']>;
  agentID: Scalars['ID'];
};


export type QueryUsageArgs = {
  usageType: UsageType;
  intervalMarkers: Array<Scalars['DateTime']>;
};


export type QueryCouponArgs = {
  id: Scalars['String'];
};

export type QueryTagInput = {
  tagCategoryName?: Maybe<Scalars['String']>;
  tagName: Scalars['String'];
};

export type QueueJobActionInput = {
  queueID: Scalars['ID'];
  template: Scalars['JSONString'];
};

export type QueueJobTriggeredAction = {
  __typename: 'QueueJobTriggeredAction';
  queue?: Maybe<RunQueue>;
  template: Scalars['JSONString'];
};

export enum RampIdType {
  None = 'None',
  CustomId = 'CustomID',
  EntityId = 'EntityID',
  EntityName = 'EntityName',
  OrgName = 'OrgName',
  OrgId = 'OrgID',
  UserId = 'UserID',
  UserName = 'UserName',
  ProjectId = 'ProjectID',
  ApiKey = 'ApiKey'
}

export type RampManager = {
  __typename: 'RampManager';
  settings: RampSettingConnection;
  setting?: Maybe<RampSettingEdge>;
};


export type RampManagerSettingArgs = {
  name: Scalars['String'];
};

export type RampSettingConnection = {
  __typename: 'RampSettingConnection';
  edges: Array<RampSettingEdge>;
};

export type RampSettingEdge = {
  __typename: 'RampSettingEdge';
  name: Scalars['String'];
  description: Scalars['String'];
  group: Scalars['String'];
  stores: Array<RampThresholdStore>;
  rampIDType: RampIdType;
  rampType: RampType;
  idIsRamped: Scalars['Boolean'];
};


export type RampSettingEdgeIdIsRampedArgs = {
  id: Scalars['String'];
};

export type RampSettingThreshold = {
  __typename: 'RampSettingThreshold';
  checkId: Scalars['String'];
  displayCheckID: Scalars['String'];
  threshold: Scalars['Float'];
};

export type RampThresholdStore = {
  __typename: 'RampThresholdStore';
  name: Scalars['String'];
  thresholds: Array<RampSettingThreshold>;
};

export enum RampType {
  Backend = 'Backend',
  FrontendFeatureFlag = 'FrontendFeatureFlag',
  FrontendBetaFlag = 'FrontendBetaFlag'
}

export type RateLimits = {
  __typename: 'RateLimits';
  graphql: Scalars['Int'];
  sdkGraphql: Scalars['Int'];
  filestreamCount: Scalars['Int'];
  filestreamSize: Scalars['Int'];
  sdkGraphqlQuerySeconds: Scalars['Float'];
};

export type RateLimitsInput = {
  graphql?: Maybe<Scalars['Int']>;
  sdkGraphql?: Maybe<Scalars['Int']>;
  filestreamCount?: Maybe<Scalars['Int']>;
  filestreamSize?: Maybe<Scalars['Int']>;
  sdkGraphqlQuerySeconds?: Maybe<Scalars['Float']>;
};

export type RemoveProtectedAliasesInput = {
  entityName: Scalars['String'];
  aliases: Array<Scalars['String']>;
  adminType: WorkflowsAdminType;
  clientMutationID?: Maybe<Scalars['String']>;
};

export type RemoveProtectedAliasesPayload = {
  __typename: 'RemoveProtectedAliasesPayload';
  aliases: Array<Scalars['String']>;
};

export type RemoveUserFromOrganizationByIdInput = {
  userId: Scalars['ID'];
  organizationId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveUserFromOrganizationByIdPayload = {
  __typename: 'RemoveUserFromOrganizationByIdPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveUserFromOrganizationInput = {
  userName: Scalars['String'];
  organizationId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RemoveUserFromOrganizationPayload = {
  __typename: 'RemoveUserFromOrganizationPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RenameProjectInput = {
  entityName: Scalars['String'];
  oldProjectName: Scalars['String'];
  newProjectName: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Repo = Node & {
  __typename: 'Repo';
  id: Scalars['ID'];
  entity: Entity;
  displayName: Scalars['String'];
};

export type RepoConnection = {
  __typename: 'RepoConnection';
  edges: Array<RepoEdge>;
};

export type RepoEdge = {
  __typename: 'RepoEdge';
  node?: Maybe<Repo>;
  cursor: Scalars['String'];
};

export type RepoInsightsRowConnection = {
  __typename: 'RepoInsightsRowConnection';
  totalCount: Scalars['Int'];
  pageInfo: PageInfo;
  schema: Scalars['JSON'];
  edges: Array<RowEdge>;
  isNormalizedUserCount: Scalars['Boolean'];
};

export type ResendEmailVerificationInput = {
  email: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ResendEmailVerificationPayload = {
  __typename: 'ResendEmailVerificationPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ResetPasswordInput = {
  email: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ResetPasswordPayload = {
  __typename: 'ResetPasswordPayload';
  success: Scalars['Boolean'];
};

export type RetryStripeOpenInvoicesByOrganizationInput = {
  organizationId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RetryStripeOpenInvoicesByOrganizationPayload = {
  __typename: 'RetryStripeOpenInvoicesByOrganizationPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RevokeAccessTokenEmailInput = {
  token: Scalars['String'];
  email: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RevokeAccessTokenEmailPayload = {
  __typename: 'RevokeAccessTokenEmailPayload';
  accessToken: AccessToken;
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RevokeAccessTokenInput = {
  token: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RevokeAccessTokenPayload = {
  __typename: 'RevokeAccessTokenPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RevokeClientInput = {
  clientId: Scalars['String'];
  entityName: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RevokeClientPayload = {
  __typename: 'RevokeClientPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RewindRunInput = {
  runName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  metricName: Scalars['String'];
  metricValue: Scalars['Float'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type RewindRunPayload = {
  __typename: 'RewindRunPayload';
  rewoundRun?: Maybe<Run>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Role = {
  __typename: 'Role';
  ID: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  inheritedFrom?: Maybe<Role>;
  permissions: Array<RolePermission>;
};

export type RolePermission = {
  __typename: 'RolePermission';
  name: Scalars['String'];
  groupName: Scalars['String'];
  displayName: Scalars['String'];
};

export type RowConnection = {
  __typename: 'RowConnection';
  totalCount: Scalars['Int'];
  pageInfo: PageInfo;
  schema: Scalars['JSON'];
  edges: Array<RowEdge>;
};

export type RowEdge = {
  __typename: 'RowEdge';
  node?: Maybe<RowType>;
};

export type RowType = {
  __typename: 'RowType';
  row: Scalars['JSON'];
};

export type Run = Node & BucketType & {
  __typename: 'Run';
  agent?: Maybe<Agent>;
  aggregations?: Maybe<Scalars['JSON']>;
  benchmarkRun?: Maybe<BenchmarkRun>;
  bucketedHistory: Array<Scalars['JSON']>;
  commit?: Maybe<Scalars['String']>;
  computeSeconds: Scalars['Duration'];
  createdAt: Scalars['DateTime'];
  config?: Maybe<Scalars['JSONString']>;
  debug?: Maybe<Scalars['Boolean']>;
  defaultColorIndex?: Maybe<Scalars['Int']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  events: Array<Scalars['String']>;
  eventsLineCount?: Maybe<Scalars['Int']>;
  eventsTail?: Maybe<Scalars['JSONString']>;
  exampleTable?: Maybe<Scalars['JSONString']>;
  exampleTableColumns?: Maybe<Scalars['JSONString']>;
  exampleTableTypes?: Maybe<Scalars['JSONString']>;
  exitcode?: Maybe<Scalars['Float']>;
  failed?: Maybe<Scalars['Boolean']>;
  fileCount?: Maybe<Scalars['Int']>;
  framework?: Maybe<Scalars['String']>;
  github?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  groupCounts?: Maybe<Array<Scalars['Int']>>;
  heartbeatAt?: Maybe<Scalars['DateTime']>;
  historyKeys?: Maybe<Scalars['JSON']>;
  historyLineCount?: Maybe<Scalars['Int']>;
  historyTail?: Maybe<Scalars['JSONString']>;
  host?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  internalID?: Maybe<Scalars['ID']>;
  jobId?: Maybe<Scalars['Int']>;
  jobType?: Maybe<Scalars['String']>;
  logLineCount?: Maybe<Scalars['Int']>;
  metricStatistics: Scalars['JSON'];
  model?: Maybe<Project>;
  name: Scalars['String'];
  ndbId?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  parquetHistory: ParquetHistory;
  patch?: Maybe<Scalars['String']>;
  pendingUpdates: Scalars['Boolean'];
  project?: Maybe<Project>;
  projectId: Scalars['ID'];
  readOnly?: Maybe<Scalars['Boolean']>;
  requestSubscribe?: Maybe<Scalars['Boolean']>;
  running?: Maybe<Scalars['Boolean']>;
  sampledHistory: Array<Scalars['JSON']>;
  servicesAvailable?: Maybe<RunServicesAvailable>;
  shouldStop?: Maybe<Scalars['Boolean']>;
  state?: Maybe<Scalars['String']>;
  stepsForMetricValues: Array<Maybe<Scalars['Int']>>;
  stopped?: Maybe<Scalars['Boolean']>;
  storageBytes: Scalars['Int64'];
  summaryMetrics?: Maybe<Scalars['JSONString']>;
  sweep?: Maybe<Sweep>;
  sweepName?: Maybe<Scalars['String']>;
  systemMetrics?: Maybe<Scalars['JSONString']>;
  tagColors: Array<RunTag>;
  tags?: Maybe<Array<Scalars['String']>>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['Int']>;
  wandbConfig?: Maybe<Scalars['JSONString']>;
  files?: Maybe<FileConnection>;
  folders?: Maybe<Folders>;
  runInfo?: Maybe<RunInfo>;
  logLines?: Maybe<LogLineConnection>;
  outputArtifacts?: Maybe<ArtifactConnection>;
  inputArtifacts?: Maybe<InputArtifactConnection>;
  job?: Maybe<Artifact>;
  history: Array<Scalars['String']>;
  artifactLineageDag?: Maybe<ArtifactLineageDag>;
  branchPoint?: Maybe<RunBranchPoint>;
};


export type RunAggregationsArgs = {
  keys?: Maybe<Array<Scalars['String']>>;
};


export type RunBucketedHistoryArgs = {
  specs: Array<Scalars['JSONString']>;
  packVersion?: Maybe<Scalars['Int']>;
};


export type RunConfigArgs = {
  keys?: Maybe<Array<Scalars['String']>>;
  dotBehavior?: Maybe<DotBehavior>;
};


export type RunEventsArgs = {
  samples?: Maybe<Scalars['Int']>;
};


export type RunHistoryKeysArgs = {
  format?: Maybe<HistoryKeysFormat>;
};


export type RunMetricStatisticsArgs = {
  metricName?: Maybe<Scalars['String']>;
};


export type RunParquetHistoryArgs = {
  liveKeys: Array<Scalars['String']>;
};


export type RunSampledHistoryArgs = {
  specs: Array<Scalars['JSONString']>;
  packVersion?: Maybe<Scalars['Int']>;
};


export type RunStepsForMetricValuesArgs = {
  metric: Scalars['String'];
  values: Array<Scalars['Float']>;
};


export type RunSummaryMetricsArgs = {
  keys?: Maybe<Array<Scalars['String']>>;
  packVersion?: Maybe<Scalars['Int']>;
};


export type RunWandbConfigArgs = {
  keys?: Maybe<Array<Scalars['String']>>;
};


export type RunFilesArgs = {
  pattern?: Maybe<Scalars['String']>;
  names?: Maybe<Array<Maybe<Scalars['String']>>>;
  contentType?: Maybe<Array<Scalars['String']>>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  folderPath?: Maybe<Scalars['String']>;
};


export type RunFoldersArgs = {
  folderPath?: Maybe<Scalars['String']>;
};


export type RunLogLinesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type RunOutputArtifactsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
};


export type RunInputArtifactsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
};


export type RunHistoryArgs = {
  samples?: Maybe<Scalars['Int']>;
  minStep?: Maybe<Scalars['Int64']>;
  maxStep?: Maybe<Scalars['Int64']>;
  maxKeyLimit?: Maybe<Scalars['Int']>;
};


export type RunArtifactLineageDagArgs = {
  limit: Scalars['Int'];
  filterGeneratedArtifacts: Scalars['Boolean'];
  clusterSize?: Maybe<Scalars['Int']>;
  directLineage?: Maybe<Scalars['Boolean']>;
  traversalDirection?: Maybe<TraversalDirection>;
  baseGraphNodeIDs?: Maybe<Array<Scalars['String']>>;
};

export type RunBranchPoint = {
  __typename: 'RunBranchPoint';
  run: Run;
  step: Scalars['Int64'];
};

export type RunConnection = {
  __typename: 'RunConnection';
  delta: Array<RunDiff>;
  deltas?: Maybe<BucketDiffs>;
  edges: Array<RunEdge>;
  historyKeys?: Maybe<Scalars['JSON']>;
  metricStatistics: Scalars['JSON'];
  pageInfo: PageInfo;
  paths?: Maybe<Scalars['JSONString']>;
  totalCount: Scalars['Int'];
};


export type RunConnectionDeltaArgs = {
  currentRuns: Array<Scalars['String']>;
  lastUpdated: Scalars['DateTime'];
};


export type RunConnectionDeltasArgs = {
  currentRuns: Array<Scalars['String']>;
  lastUpdated: Scalars['DateTime'];
};


export type RunConnectionHistoryKeysArgs = {
  format?: Maybe<HistoryKeysFormat>;
};


export type RunConnectionMetricStatisticsArgs = {
  metricName?: Maybe<Scalars['String']>;
};

export type RunDiff = {
  __typename: 'RunDiff';
  index: Scalars['Int'];
  op: DiffOperation;
  run?: Maybe<Run>;
};

export type RunEdge = {
  __typename: 'RunEdge';
  node: Run;
  cursor: Scalars['String'];
};

export type RunFieldValue = {
  __typename: 'RunFieldValue';
  value?: Maybe<Scalars['JSONString']>;
};

export type RunFieldValueConnection = {
  __typename: 'RunFieldValueConnection';
  pageInfo: PageInfo;
  edges: Array<RunFieldValueEdge>;
};

export type RunFieldValueEdge = {
  __typename: 'RunFieldValueEdge';
  node: RunFieldValue;
  cursor: Scalars['String'];
};

export type RunGroup = {
  __typename: 'RunGroup';
  id: Scalars['ID'];
  name: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  tags: Array<RunTag>;
  project: Project;
};

export type RunInfo = {
  __typename: 'RunInfo';
  program?: Maybe<Scalars['String']>;
  args: Array<Scalars['String']>;
  os?: Maybe<Scalars['String']>;
  python?: Maybe<Scalars['String']>;
  colab?: Maybe<Scalars['String']>;
  executable?: Maybe<Scalars['String']>;
  codeSaved?: Maybe<Scalars['Boolean']>;
  cpuCount?: Maybe<Scalars['Int']>;
  cpuCountLogical?: Maybe<Scalars['Int']>;
  gpuCount?: Maybe<Scalars['Int']>;
  gpu?: Maybe<Scalars['String']>;
  git?: Maybe<GitInfo>;
  codePath?: Maybe<Scalars['String']>;
};

export type RunKeySuggestion = {
  __typename: 'RunKeySuggestion';
  value?: Maybe<Scalars['JSONString']>;
  displayValue?: Maybe<Scalars['JSONString']>;
  count: Scalars['Int'];
};

export type RunQueue = {
  __typename: 'RunQueue';
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  name: Scalars['String'];
  entityName: Scalars['String'];
  /** @deprecated Executor state tracked in LaunchAgent.AgentStatus */
  executorInfo: Scalars['String'];
  createdBy?: Maybe<Scalars['Int']>;
  access: RunQueueAccessType;
  runQueueItem?: Maybe<RunQueueItem>;
  runQueueItems: RunQueueItemConnection;
  defaultResourceConfigID?: Maybe<Scalars['ID']>;
  defaultResourceConfig?: Maybe<DefaultResourceConfig>;
  prioritizationMode: RunQueuePrioritizationMode;
  externalLinks?: Maybe<Scalars['JSON']>;
};


export type RunQueueRunQueueItemArgs = {
  id: Scalars['ID'];
};


export type RunQueueRunQueueItemsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export enum RunQueueAccessType {
  Project = 'PROJECT',
  User = 'USER'
}

export type RunQueueItem = {
  __typename: 'RunQueueItem';
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  runSpec: Scalars['JSON'];
  priority: Scalars['Int'];
  state: RunQueueItemState;
  associatedRunId?: Maybe<Scalars['String']>;
  launchAgentId?: Maybe<Scalars['ID']>;
  templateVariableValues?: Maybe<Scalars['JSON']>;
  error?: Maybe<RunQueueItemInfoDetails>;
  warnings: Array<Maybe<RunQueueItemInfoDetails>>;
};

export type RunQueueItemConnection = {
  __typename: 'RunQueueItemConnection';
  pageInfo: PageInfo;
  edges: Array<RunQueueItemEdge>;
};

export type RunQueueItemEdge = {
  __typename: 'RunQueueItemEdge';
  node: RunQueueItem;
  cursor: Scalars['String'];
};

export type RunQueueItemInfoDetails = Error & {
  __typename: 'RunQueueItemInfoDetails';
  message: Scalars['String'];
  stage: Scalars['String'];
  filePaths: Array<Scalars['String']>;
};

export enum RunQueueItemState {
  Pending = 'PENDING',
  Leased = 'LEASED',
  Claimed = 'CLAIMED',
  Failed = 'FAILED'
}

export enum RunQueuePrioritizationMode {
  Disabled = 'DISABLED',
  V0 = 'V0'
}

export type RunServicesAvailable = {
  __typename: 'RunServicesAvailable';
  tensorboard?: Maybe<Scalars['Boolean']>;
};

export type RunStorageNode = StorageTreeNode & {
  __typename: 'RunStorageNode';
  id: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int64'];
  run: Run;
  runID: Scalars['ID'];
  files: FileStorageNodeConnection;
};


export type RunStorageNodeFilesArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type RunStorageNodeConnection = {
  __typename: 'RunStorageNodeConnection';
  totalSize: Scalars['Int64'];
  pageInfo: PageInfo;
  edges: Array<RunStorageNodeEdge>;
};

export type RunStorageNodeEdge = {
  __typename: 'RunStorageNodeEdge';
  node: RunStorageNode;
  cursor: Scalars['String'];
};

export type RunTag = {
  __typename: 'RunTag';
  id: Scalars['ID'];
  name: Scalars['String'];
  colorIndex: Scalars['Int'];
};

export type RunTagConnection = {
  __typename: 'RunTagConnection';
  pageInfo: PageInfo;
  edges: Array<RunTagEdge>;
};

export type RunTagCount = {
  __typename: 'RunTagCount';
  name: Scalars['String'];
  count: Scalars['Int'];
};

export type RunTagEdge = {
  __typename: 'RunTagEdge';
  node: RunTag;
  cursor: Scalars['String'];
};

export type ScriptableRunCondition = {
  __typename: 'ScriptableRunCondition';
  success: Scalars['Boolean'];
};

export type Secret = {
  __typename: 'Secret';
  entityId: Scalars['Int'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
};

export type SendRequestOrgEmailDomainVerificationInput = {
  organizationID: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SendRequestOrgEmailDomainVerificationPayload = {
  __typename: 'SendRequestOrgEmailDomainVerificationPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ServerFeature = {
  __typename: 'ServerFeature';
  name: Scalars['String'];
  isEnabled: Scalars['Boolean'];
  description: Scalars['String'];
};

export type ServerInfo = {
  __typename: 'ServerInfo';
  pollingOK: Scalars['Boolean'];
  streamTableEnabled: Scalars['Boolean'];
  availableCloudRegions: Array<CloudRegion>;
  defaultCloudRegion: CloudRegion;
  slackClientID: Scalars['String'];
  cliVersionInfo: Scalars['JSON'];
  latestLocalVersionInfo?: Maybe<LocalVersionInfo>;
  messageOfTheDay?: Maybe<Scalars['String']>;
  isGoogleIap: Scalars['Boolean'];
  userLimitReached: Scalars['Boolean'];
  viewOnlyUserLimitReached: Scalars['Boolean'];
  registeredModelLimitReached: Scalars['Boolean'];
  actionHistoryLimit: Scalars['Int'];
  frontendHost: Scalars['String'];
  hasLocalLicense: Scalars['Boolean'];
  licenseFlags: Array<Scalars['String']>;
  licenseExpirationUI?: Maybe<LicenseExpirationUi>;
  exposesExplicitRunQueueAckPath: Scalars['Boolean'];
  availableBucketProviders: Array<CloudProvider>;
  secureStorageConnectorEnabled: Scalars['Boolean'];
  dataRetentionDuration: Scalars['Duration'];
  deploymentId?: Maybe<Scalars['String']>;
  weaveLimits?: Maybe<WeaveLimits>;
  contractStartDate?: Maybe<Scalars['DateTime']>;
  features: Array<Maybe<ServerFeature>>;
};


export type ServerInfoRegisteredModelLimitReachedArgs = {
  entityName: Scalars['String'];
};


export type ServerInfoActionHistoryLimitArgs = {
  entityName?: Maybe<Scalars['String']>;
  artifactCollectionID?: Maybe<Scalars['ID']>;
};

export type ServiceAccount = {
  __typename: 'ServiceAccount';
  id: Scalars['ID'];
  name: Scalars['String'];
  createdAt: Scalars['DateTime'];
  teams?: Maybe<EntityConnection>;
  apiKey?: Maybe<Scalars['String']>;
  defaultEntity: Entity;
  accountType: UserAccountType;
};


export type ServiceAccountTeamsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type SetDismissedBannerInput = {
  bannerID: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SetDismissedBannerPayload = {
  __typename: 'SetDismissedBannerPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SetTagColorInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  tagName: Scalars['String'];
  colorIndex?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SetTagColorPayload = {
  __typename: 'SetTagColorPayload';
  tags: Array<RunTag>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SlackChannelSubscription = AlertSubscription & {
  __typename: 'SlackChannelSubscription';
  id: Scalars['ID'];
  slackIntegration: SlackIntegration;
};

export type SlackIntegration = Integration & {
  __typename: 'SlackIntegration';
  id: Scalars['ID'];
  teamName: Scalars['String'];
  channelName: Scalars['String'];
};

export type StarProjectInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  organizationId?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type StarProjectPayload = {
  __typename: 'StarProjectPayload';
  project?: Maybe<Project>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type StarViewInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type StarViewPayload = {
  __typename: 'StarViewPayload';
  view?: Maybe<View>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type StargazerConnection = {
  __typename: 'StargazerConnection';
  pageInfo: PageInfo;
  edges: Array<StargazerEdge>;
};

export type StargazerEdge = {
  __typename: 'StargazerEdge';
  node?: Maybe<User>;
  cursor: Scalars['String'];
};

export type StartExperimentInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type StopExperimentInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type StopRunInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type StopRunPayload = {
  __typename: 'StopRunPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type StoppedRunCondition = {
  __typename: 'StoppedRunCondition';
  minimumRunDuration: Scalars['Duration'];
};

export type StorageBucketInfo = {
  __typename: 'StorageBucketInfo';
  ID: Scalars['ID'];
  provider: CloudProvider;
  name: Scalars['String'];
  kmsKeyID?: Maybe<Scalars['String']>;
  awsExternalID?: Maybe<Scalars['String']>;
  azureTenantID?: Maybe<Scalars['String']>;
  azureClientID?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type StorageBucketInfoInput = {
  name: Scalars['String'];
  provider: CloudProvider;
  kmsKeyID?: Maybe<Scalars['String']>;
  awsExternalID?: Maybe<Scalars['String']>;
  organizationID?: Maybe<Scalars['ID']>;
  azureTenantID?: Maybe<Scalars['String']>;
  azureClientID?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};

export type StorageTreeNode = {
  id: Scalars['ID'];
  name: Scalars['String'];
  size: Scalars['Int64'];
};

export enum StorageUnit {
  B = 'B',
  Kb = 'KB',
  Mb = 'MB',
  Gb = 'GB',
  Tb = 'TB',
  Pb = 'PB'
}

export type StripeBillingInfo = {
  __typename: 'StripeBillingInfo';
  stripeSubscriptionId: Scalars['String'];
  status: Scalars['String'];
  cancelAtPeriodEnd: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  currentPeriodStart: Scalars['DateTime'];
  currentPeriodEnd: Scalars['DateTime'];
  invoiceLink?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethod>;
  paymentMetadata?: Maybe<PaymentMetadata>;
};

export type StripeInvoice = {
  __typename: 'StripeInvoice';
  number: Scalars['String'];
  created: Scalars['DateTime'];
  total: Scalars['Int64'];
  status: StripeInvoiceStatus;
  currency: Scalars['String'];
  hostedInvoiceURL: Scalars['String'];
  invoicePDF: Scalars['String'];
  subscription?: Maybe<StripeSubscription>;
};

export enum StripeInvoiceStatus {
  Draft = 'DRAFT',
  Open = 'OPEN',
  Paid = 'PAID',
  Uncollectible = 'UNCOLLECTIBLE',
  Void = 'VOID'
}

export type StripePaymentMethod = {
  __typename: 'StripePaymentMethod';
  stripePaymentMethodID: Scalars['String'];
  type: StripePaymentMethodType;
  isDefault: Scalars['Boolean'];
  isFailed: Scalars['Boolean'];
  card: StripePaymentMethodCard;
};

export type StripePaymentMethodCard = {
  __typename: 'StripePaymentMethodCard';
  last4: Scalars['String'];
  brand: CardBrand;
};

export enum StripePaymentMethodType {
  Card = 'CARD'
}

export type StripePrice = {
  __typename: 'StripePrice';
  amount: Scalars['Int64'];
  currency: Scalars['String'];
  interval?: Maybe<Scalars['String']>;
};

export type StripeSubscription = {
  __typename: 'StripeSubscription';
  status: Scalars['String'];
  cancelAtPeriodEnd: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  currentPeriodEnd: Scalars['DateTime'];
  organizationSubscriptions: Array<OrganizationSubscription>;
};

export type SubmitBenchmarkRunInput = {
  id: Scalars['ID'];
  isCodeHidden?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
  benchmarkName?: Maybe<Scalars['String']>;
  benchmarkEntityName?: Maybe<Scalars['String']>;
};

export type SubmitBenchmarkRunPayload = {
  __typename: 'SubmitBenchmarkRunPayload';
  benchmarkRun: BenchmarkRun;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SubmitFcNewsInput = {
  name: Scalars['String'];
  email: Scalars['String'];
  url: Scalars['String'];
  details?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type SubmitFcNewsPayload = {
  __typename: 'SubmitFCNewsPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type Sweep = Node & {
  __typename: 'Sweep';
  id: Scalars['ID'];
  name: Scalars['String'];
  method: Scalars['String'];
  state: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  config: Scalars['String'];
  createdAt: Scalars['DateTime'];
  heartbeatAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  earlyStopJobRunning: Scalars['Boolean'];
  project?: Maybe<Project>;
  controller?: Maybe<Scalars['JSONString']>;
  scheduler?: Maybe<Scalars['JSONString']>;
  displayName?: Maybe<Scalars['String']>;
  runCount: Scalars['Int'];
  runCountExpected?: Maybe<Scalars['Int']>;
  runTime: Scalars['Int'];
  bestLoss?: Maybe<Scalars['Float']>;
  runs: RunConnection;
  priorRuns: RunConnection;
  agents: AgentConnection;
  agent?: Maybe<Agent>;
  controllerRunName: Scalars['String'];
};


export type SweepRunsArgs = {
  order?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type SweepAgentArgs = {
  agentName: Scalars['String'];
};

export type SweepConnection = {
  __typename: 'SweepConnection';
  pageInfo: PageInfo;
  edges: Array<SweepEdge>;
};

export type SweepEdge = {
  __typename: 'SweepEdge';
  node: Sweep;
  cursor: Scalars['String'];
};

export type Tag = {
  __typename: 'Tag';
  id: Scalars['ID'];
  name: Scalars['String'];
  attributes: Scalars['JSONString'];
  tagCategory?: Maybe<TagCategory>;
  tagCategoryName: Scalars['String'];
  objects?: Maybe<TaggedObjectConnection>;
};


export type TagObjectsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  objectType?: Maybe<TaggedObjectType>;
};

export type TagCategory = {
  __typename: 'TagCategory';
  id: Scalars['ID'];
  name: Scalars['String'];
  group: TagGroup;
  tags: TagConnection;
};


export type TagCategoryTagsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type TagConnection = {
  __typename: 'TagConnection';
  pageInfo: PageInfo;
  edges: Array<TagEdge>;
};

export type TagEdge = {
  __typename: 'TagEdge';
  node: Tag;
  cursor: Scalars['String'];
};

export type TagGroup = Entity;

export type TagInput = {
  tagCategoryName?: Maybe<Scalars['String']>;
  tagName: Scalars['String'];
  attributes?: Maybe<Scalars['JSONString']>;
};

export type TaggedObject = ArtifactSequence | ArtifactPortfolio | Artifact;

export type TaggedObjectConnection = {
  __typename: 'TaggedObjectConnection';
  pageInfo: PageInfo;
  edges: Array<TaggedObjectEdge>;
};

export type TaggedObjectEdge = {
  __typename: 'TaggedObjectEdge';
  node: TaggedObject;
  cursor: Scalars['String'];
};

export enum TaggedObjectType {
  ArtifactCollection = 'ARTIFACT_COLLECTION',
  Artifact = 'ARTIFACT'
}

export type Task = {
  __typename: 'Task';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: Scalars['String'];
  state: TaskState;
  progress: Scalars['Int'];
  name: Scalars['String'];
};

export enum TaskState {
  Pending = 'PENDING',
  Running = 'RUNNING',
  Finished = 'FINISHED',
  Failed = 'FAILED'
}

export type TemplateVariable = {
  __typename: 'TemplateVariable';
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  schema: Scalars['JSONString'];
};

export type TestGenericWebhookIntegrationInput = {
  entityName: Scalars['String'];
  urlEndpoint: Scalars['String'];
  accessTokenRef?: Maybe<Scalars['String']>;
  secretRef?: Maybe<Scalars['String']>;
  requestPayload?: Maybe<Scalars['JSONString']>;
  clientMutationID?: Maybe<Scalars['String']>;
};

export type TestGenericWebhookIntegrationPayload = {
  __typename: 'TestGenericWebhookIntegrationPayload';
  ok: Scalars['Boolean'];
  response: Scalars['JSONString'];
  clientMutationID?: Maybe<Scalars['String']>;
};

export type TimeWindow = {
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
};

export enum TraversalDirection {
  Upstream = 'UPSTREAM',
  Downstream = 'DOWNSTREAM'
}

export type Trigger = {
  __typename: 'Trigger';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  createdBy: User;
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  scope: TriggerScope;
  triggeringCondition: TriggeringCondition;
  triggeredAction: TriggeredAction;
  enabled: Scalars['Boolean'];
};

export type TriggerConnection = {
  __typename: 'TriggerConnection';
  totalCount: Scalars['Int'];
  pageInfo: PageInfo;
  edges: Array<TriggerEdge>;
};

export type TriggerEdge = {
  __typename: 'TriggerEdge';
  node: Trigger;
  cursor: Scalars['String'];
};

export type TriggerParquetExportInput = {
  runId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type TriggerParquetExportPayload = {
  __typename: 'TriggerParquetExportPayload';
  task: Task;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type TriggerScope = Project | ArtifactSequence | ArtifactPortfolio;

export enum TriggerScopeType {
  Project = 'PROJECT',
  ArtifactCollection = 'ARTIFACT_COLLECTION'
}

export type TriggeredAction = QueueJobTriggeredAction | NotificationTriggeredAction | GenericWebhookTriggeredAction | NoOpTriggeredAction;

export type TriggeredActionConfig = {
  queueJobActionInput?: Maybe<QueueJobActionInput>;
  notificationActionInput?: Maybe<NotificationActionInput>;
  genericWebhookActionInput?: Maybe<GenericWebhookActionInput>;
  noOpActionInput?: Maybe<NoOpTriggeredActionInput>;
};

export enum TriggeredActionType {
  QueueJob = 'QUEUE_JOB',
  Notification = 'NOTIFICATION',
  GenericWebhook = 'GENERIC_WEBHOOK',
  NoOp = 'NO_OP'
}

export type TriggeringCondition = FilterEventTriggeringCondition;

export type UndeleteRunsInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  filters: Scalars['JSONString'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UndeleteRunsPayload = {
  __typename: 'UndeleteRunsPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UndeleteUserInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UndeleteUserPayload = {
  __typename: 'UndeleteUserPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UnlinkArtifactInput = {
  artifactID: Scalars['ID'];
  artifactPortfolioID: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UnlinkArtifactPayload = {
  __typename: 'UnlinkArtifactPayload';
  artifactID: Scalars['ID'];
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UnstarProjectInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  organizationId?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UnstarProjectPayload = {
  __typename: 'UnstarProjectPayload';
  project?: Maybe<Project>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UnstarViewInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UnstarViewPayload = {
  __typename: 'UnstarViewPayload';
  view?: Maybe<View>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateAccessTokenInput = {
  token: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
  previewURL?: Maybe<Scalars['String']>;
};

export type UpdateAccessTokenPayload = {
  __typename: 'UpdateAccessTokenPayload';
  accessToken: AccessToken;
  success: Scalars['Boolean'];
  toNewUser?: Maybe<Scalars['Boolean']>;
  recipientAlreadyOnTeam?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateAccessTokenProjectsInput = {
  token: Scalars['String'];
  projects: Array<ProjectSpecifier>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateAccessTokenProjectsPayload = {
  __typename: 'UpdateAccessTokenProjectsPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateAlertPayload = {
  __typename: 'UpdateAlertPayload';
  alert: Alert;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateArtifactAction = ArtifactAction & {
  __typename: 'UpdateArtifactAction';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  artifact: Artifact;
  initiator: Initiator;
  /** @deprecated Field no longer supported */
  oldTags?: Maybe<Scalars['JSONString']>;
  /** @deprecated Field no longer supported */
  newTags?: Maybe<Scalars['JSONString']>;
  oldAliases?: Maybe<Array<ArtifactAlias>>;
  newAliases?: Maybe<Array<ArtifactAlias>>;
  oldDescription?: Maybe<Scalars['String']>;
  newDescription?: Maybe<Scalars['String']>;
  oldMetadata?: Maybe<Scalars['JSONString']>;
  newMetadata?: Maybe<Scalars['JSONString']>;
};

export type UpdateArtifactCollectionPayload = {
  __typename: 'UpdateArtifactCollectionPayload';
  artifactCollection: ArtifactCollection;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateArtifactEntityTtlInput = {
  entityName: Scalars['String'];
  ttlDurationSeconds: Scalars['Int64'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateArtifactEntityTtlPayload = {
  __typename: 'UpdateArtifactEntityTTLPayload';
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateArtifactInput = {
  artifactID: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  labels?: Maybe<Scalars['JSONString']>;
  aliases?: Maybe<Array<ArtifactAliasInput>>;
  tagsToAdd?: Maybe<Array<TagInput>>;
  tagsToDelete?: Maybe<Array<TagInput>>;
  metadata?: Maybe<Scalars['JSONString']>;
  ttlDurationSeconds?: Maybe<Scalars['Int64']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateArtifactManifestInput = {
  artifactManifestID: Scalars['ID'];
  baseArtifactID?: Maybe<Scalars['ID']>;
  digest?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateArtifactManifestPayload = {
  __typename: 'UpdateArtifactManifestPayload';
  artifactManifest: ArtifactManifest;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateArtifactPayload = {
  __typename: 'UpdateArtifactPayload';
  artifact: Artifact;
  artifactActions: Array<ArtifactAction>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateArtifactPortfolioInput = {
  artifactPortfolioID: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateArtifactSequenceInput = {
  artifactSequenceID: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateArtifactTypeInput = {
  artifactTypeID: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateArtifactTypePayload = {
  __typename: 'UpdateArtifactTypePayload';
  artifactType: ArtifactType;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateBenchmarkRunInput = {
  id: Scalars['ID'];
  state?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['JSONString']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateBenchmarkRunPayload = {
  __typename: 'UpdateBenchmarkRunPayload';
  benchmarkRun: BenchmarkRun;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCustomChartInput = {
  entity: Scalars['String'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  access?: Maybe<Scalars['String']>;
  spec?: Maybe<Scalars['JSONString']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCustomChartPayload = {
  __typename: 'UpdateCustomChartPayload';
  chart: CustomChart;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCustomerDefaultPaymentMethodInput = {
  organizationId: Scalars['ID'];
  paymentMethod: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCustomerDefaultPaymentMethodPayload = {
  __typename: 'UpdateCustomerDefaultPaymentMethodPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCustomerPaymentMethodInput = {
  organizationId: Scalars['ID'];
  paymentMethod: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateCustomerPaymentMethodPayload = {
  __typename: 'UpdateCustomerPaymentMethodPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDefaultResourceConfigInput = {
  defaultResourceConfigID: Scalars['ID'];
  entityName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  resource?: Maybe<Scalars['String']>;
  config: Scalars['JSONString'];
  templateVariables?: Maybe<Scalars['JSONString']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDefaultResourceConfigPayload = {
  __typename: 'UpdateDefaultResourceConfigPayload';
  success: Scalars['Boolean'];
  defaultResourceConfigID: Scalars['ID'];
  schemaValidationErrors?: Maybe<Array<Scalars['String']>>;
};

export type UpdateDiscussionCommentInput = {
  viewID?: Maybe<Scalars['ID']>;
  discussionCommentID: Scalars['ID'];
  body: Scalars['String'];
  inlineCommentDetails?: Maybe<Scalars['JSONString']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDiscussionCommentPayload = {
  __typename: 'UpdateDiscussionCommentPayload';
  discussionComment: DiscussionComment;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDiscussionThreadInput = {
  discussionThreadID: Scalars['ID'];
  body: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateDiscussionThreadPayload = {
  __typename: 'UpdateDiscussionThreadPayload';
  discussionThread: DiscussionThread;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateEntityInput = {
  entity: Scalars['String'];
  photoUrl?: Maybe<Scalars['String']>;
  defaultAccess?: Maybe<Scalars['String']>;
  privateOnly?: Maybe<Scalars['Boolean']>;
  codeSavingEnabled?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  rateLimits?: Maybe<RateLimitsInput>;
  settings?: Maybe<EntitySettingsInput>;
};

export type UpdateEntityPayload = {
  __typename: 'UpdateEntityPayload';
  entity?: Maybe<Entity>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateExperimentInput = {
  id: Scalars['ID'];
  name: Scalars['String'];
  variants: Array<VariantInput>;
  experimentType: ExperimentType;
  metadata?: Maybe<Scalars['JSONString']>;
  sampleSize: Scalars['Int'];
  enrollmentPeriod: Scalars['Int'];
  observationalUnit: ObservationalUnit;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateExperimentPayload = {
  __typename: 'UpdateExperimentPayload';
  id: Scalars['ID'];
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateFilterTriggerInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  triggeringEventType?: Maybe<EventTriggeringConditionType>;
  scopeType?: Maybe<TriggerScopeType>;
  scopeID?: Maybe<Scalars['ID']>;
  eventFilter?: Maybe<Scalars['JSONString']>;
  triggeredActionType?: Maybe<TriggeredActionType>;
  triggeredActionConfig?: Maybe<TriggeredActionConfig>;
  enabled?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateFilterTriggerPayload = {
  __typename: 'UpdateFilterTriggerPayload';
  trigger?: Maybe<Trigger>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateGenericWebhookIntegrationInput = {
  id: Scalars['ID'];
  urlEndpoint?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  secretRef?: Maybe<Scalars['String']>;
  accessTokenRef?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateGlueTaskInput = {
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  arguments?: Maybe<Scalars['JSONString']>;
  checkpoint?: Maybe<Scalars['JSONString']>;
  strategyType?: Maybe<Scalars['String']>;
  strategyMetadata?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateGlueTaskPayload = {
  __typename: 'UpdateGlueTaskPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateLaunchAgentInput = {
  launchAgentId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  runQueues?: Maybe<Array<Scalars['ID']>>;
  agentStatus?: Maybe<Scalars['String']>;
  stopPolling?: Maybe<Scalars['Boolean']>;
  heartbeatAt?: Maybe<Scalars['DateTime']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateLaunchAgentPayload = {
  __typename: 'UpdateLaunchAgentPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateLocalLicenseInput = {
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateLocalLicensePayload = {
  __typename: 'UpdateLocalLicensePayload';
  localLicense: LocalLicense;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateMemberInput = {
  entityName: Scalars['String'];
  user: Scalars['ID'];
  admin?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateMemberPayload = {
  __typename: 'UpdateMemberPayload';
  member: Member;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateModelsSeatInput = {
  organizationId: Scalars['ID'];
  userId: Scalars['ID'];
  modelsSeat: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateModelsSeatPayload = {
  __typename: 'UpdateModelsSeatPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationBillingUserInput = {
  organizationId: Scalars['ID'];
  newBillingUser: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationBillingUserPayload = {
  __typename: 'UpdateOrganizationBillingUserPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationInput = {
  organizationID: Scalars['ID'];
  flags?: Maybe<Scalars['JSONString']>;
  name?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationPayload = {
  __typename: 'UpdateOrganizationPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationPrivacySettingsInput = {
  organizationId: Scalars['ID'];
  hidden?: Maybe<Scalars['Boolean']>;
  privateOnly?: Maybe<Scalars['Boolean']>;
  onlyAdminsCanInvite?: Maybe<Scalars['Boolean']>;
  disableMagicLinkSharing?: Maybe<Scalars['Boolean']>;
  restrictMatchingOrgEmailDomainUsers?: Maybe<Scalars['Boolean']>;
  codeSavingDisabled?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationPrivacySettingsPayload = {
  __typename: 'UpdateOrganizationPrivacySettingsPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationSubscriptionInput = {
  organizationSubscriptionID: Scalars['ID'];
  createStripeSubscription?: Maybe<Scalars['Boolean']>;
  planID?: Maybe<Scalars['ID']>;
  seats?: Maybe<Scalars['Int']>;
  privileges?: Maybe<Scalars['JSONString']>;
  type?: Maybe<OrganizationSubscriptionType>;
  status?: Maybe<OrganizationSubscriptionStatus>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationSubscriptionPayload = {
  __typename: 'UpdateOrganizationSubscriptionPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationSubscriptionSeatsInput = {
  organizationID: Scalars['ID'];
  seats: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationSubscriptionSeatsPayload = {
  __typename: 'UpdateOrganizationSubscriptionSeatsPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationUserInput = {
  userName: Scalars['String'];
  organizationId: Scalars['ID'];
  userOrgRole: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationUserPayload = {
  __typename: 'UpdateOrganizationUserPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationUserV2Input = {
  userName: Scalars['String'];
  organizationId: Scalars['ID'];
  userOrgRole: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateOrganizationUserV2Payload = {
  __typename: 'UpdateOrganizationUserV2Payload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateProjectMemberInput = {
  userId: Scalars['ID'];
  projectId: Scalars['ID'];
  userProjectRole: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateProjectMemberPayload = {
  __typename: 'UpdateProjectMemberPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateRampThresholdInput = {
  store: Scalars['String'];
  setting: Scalars['String'];
  checkId: Scalars['String'];
  threshold: Scalars['Float'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateRampThresholdPayload = {
  __typename: 'UpdateRampThresholdPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateRoleInput = {
  roleID: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  inheritedFrom?: Maybe<Scalars['String']>;
};

export type UpdateRolePayload = {
  __typename: 'UpdateRolePayload';
  success: Scalars['Boolean'];
};

export type UpdateRunQueueInput = {
  queueID: Scalars['ID'];
  defaultResourceConfigID?: Maybe<Scalars['ID']>;
  externalLinks?: Maybe<Scalars['JSONString']>;
  prioritizationMode?: Maybe<RunQueuePrioritizationMode>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateRunQueueItemWarningInput = {
  runQueueItemId: Scalars['ID'];
  message: Scalars['String'];
  stage: Scalars['String'];
  filePaths?: Maybe<Array<Scalars['String']>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateRunQueueItemWarningPayload = {
  __typename: 'UpdateRunQueueItemWarningPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateRunQueuePayload = {
  __typename: 'UpdateRunQueuePayload';
  success?: Maybe<Scalars['Boolean']>;
  queueID?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateStoppedRunAlertInput = {
  id: Scalars['ID'];
  minimumRunDuration?: Maybe<Scalars['Duration']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateSubscriptionInput = {
  entityName: Scalars['String'];
  teamsEnabled?: Maybe<Scalars['Boolean']>;
  privateProjects?: Maybe<Scalars['Boolean']>;
  gpuEnabled?: Maybe<Scalars['DateTime']>;
  hubPreset?: Maybe<Scalars['String']>;
  hubSettings?: Maybe<HubSettingsInput>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateSubscriptionPayload = {
  __typename: 'UpdateSubscriptionPayload';
  entity?: Maybe<Entity>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateTagCategoryInput = {
  entityName: Scalars['String'];
  oldTagCategoryName: Scalars['String'];
  newTagCategoryName?: Maybe<Scalars['String']>;
  clientMutationID?: Maybe<Scalars['String']>;
};

export type UpdateTagCategoryPayload = {
  __typename: 'UpdateTagCategoryPayload';
  tagCategory: TagCategory;
  clientMutationID?: Maybe<Scalars['String']>;
};

export type UpdateTagInput = {
  tagCategoryName: Scalars['String'];
  entityName: Scalars['String'];
  oldTagName: Scalars['String'];
  newTagName?: Maybe<Scalars['String']>;
  attributes?: Maybe<Scalars['JSONString']>;
  clientMutationID?: Maybe<Scalars['String']>;
};

export type UpdateTagPayload = {
  __typename: 'UpdateTagPayload';
  tag: Tag;
  clientMutationID?: Maybe<Scalars['String']>;
};

export type UpdateUserEmailInput = {
  id: Scalars['ID'];
  type?: Maybe<EmailType>;
};

export type UpdateUserEmailPayload = {
  __typename: 'UpdateUserEmailPayload';
  success: Scalars['Boolean'];
  email?: Maybe<Email>;
};

export type UpdateUserInput = {
  id?: Maybe<Scalars['ID']>;
  primaryEmail?: Maybe<Scalars['String']>;
  defaultEntity?: Maybe<Scalars['String']>;
  defaultFramework?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  userInfo?: Maybe<Scalars['JSONString']>;
  hideTeamsFromPublic?: Maybe<Scalars['Boolean']>;
  settingsVisited?: Maybe<Scalars['Boolean']>;
  galleryVisited?: Maybe<Scalars['Boolean']>;
  onboardingHidden?: Maybe<Scalars['Boolean']>;
  private?: Maybe<Scalars['Boolean']>;
  admin?: Maybe<Scalars['Boolean']>;
  password?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUserPayload = {
  __typename: 'UpdateUserPayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUserRoleInput = {
  id: Scalars['ID'];
  accountType: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUserRolePayload = {
  __typename: 'UpdateUserRolePayload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUserRoleV2Input = {
  id: Scalars['ID'];
  accountType: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpdateUserRoleV2Payload = {
  __typename: 'UpdateUserRoleV2Payload';
  user?: Maybe<User>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpgradeCustomerSubscriptionInput = {
  planID: Scalars['ID'];
  organizationID: Scalars['ID'];
  paymentMethod: Scalars['String'];
  quantity: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpgradeCustomerSubscriptionPayload = {
  __typename: 'UpgradeCustomerSubscriptionPayload';
  subscription?: Maybe<Scalars['JSON']>;
  clientMutationId?: Maybe<Scalars['String']>;
  organizationID: Scalars['ID'];
};

export type UpgradeTrialSubscriptionPayload = {
  __typename: 'UpgradeTrialSubscriptionPayload';
  success: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UploadMultipartUrls = {
  __typename: 'UploadMultipartUrls';
  uploadID: Scalars['String'];
  uploadUrlParts: Array<UploadUrlPart>;
};

export type UploadPanelInput = {
  entityName: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  panelID: Scalars['String'];
  viewID: Scalars['ID'];
  redirectURL: Scalars['String'];
  author: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UploadPanelPayload = {
  __typename: 'UploadPanelPayload';
  success: Scalars['Boolean'];
  panelLink: Scalars['String'];
  panelImageUploadUrl: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UploadPartsInput = {
  partNumber: Scalars['Int64'];
  hexMD5: Scalars['String'];
};

export type UploadUrlPart = {
  __typename: 'UploadUrlPart';
  partNumber: Scalars['Int64'];
  uploadUrl: Scalars['String'];
};

export type UpsertBucketInput = {
  name?: Maybe<Scalars['String']>;
  groupName?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  sweep?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  framework?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['JSONString']>;
  summaryMetrics?: Maybe<Scalars['JSONString']>;
  commit?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  host?: Maybe<Scalars['String']>;
  debug?: Maybe<Scalars['Boolean']>;
  entityName?: Maybe<Scalars['String']>;
  modelName?: Maybe<Scalars['String']>;
  jobProgram?: Maybe<Scalars['String']>;
  jobRepo?: Maybe<Scalars['String']>;
  jobType?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  runQueueItemId?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpsertBucketPayload = {
  __typename: 'UpsertBucketPayload';
  bucket?: Maybe<Run>;
  inserted?: Maybe<Scalars['Boolean']>;
  updateAvailable?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpsertModelInput = {
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  framework?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  dockerImage?: Maybe<Scalars['String']>;
  repo?: Maybe<Scalars['String']>;
  access?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['JSONString']>;
  isBenchmark?: Maybe<Scalars['Boolean']>;
  linkedBenchmark?: Maybe<Scalars['ID']>;
  isPublished?: Maybe<Scalars['Boolean']>;
  owner?: Maybe<Scalars['ID']>;
  allowAllArtifactTypesInRegistry?: Maybe<Scalars['Boolean']>;
  rateLimits?: Maybe<RateLimitsInput>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpsertModelPayload = {
  __typename: 'UpsertModelPayload';
  model?: Maybe<Project>;
  project?: Maybe<Project>;
  entityName?: Maybe<Scalars['String']>;
  inserted?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpsertRunGroupInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  name: Scalars['String'];
  newName?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
  notes?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpsertRunGroupPayload = {
  __typename: 'UpsertRunGroupPayload';
  group?: Maybe<RunGroup>;
  inserted?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpsertRunQueueInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  queueName: Scalars['String'];
  resourceType: Scalars['String'];
  resourceConfig: Scalars['JSONString'];
  templateVariables?: Maybe<Scalars['JSONString']>;
  prioritizationMode?: Maybe<RunQueuePrioritizationMode>;
  externalLinks?: Maybe<Scalars['JSONString']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpsertRunQueuePayload = {
  __typename: 'UpsertRunQueuePayload';
  success?: Maybe<Scalars['Boolean']>;
  configSchemaValidationErrors?: Maybe<Array<Scalars['String']>>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpsertSharedViewInput = {
  entityName: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  spec: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpsertSharedViewPayload = {
  __typename: 'UpsertSharedViewPayload';
  view?: Maybe<View>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpsertSweepInput = {
  entityName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  config?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  controller?: Maybe<Scalars['JSONString']>;
  launchScheduler?: Maybe<Scalars['JSONString']>;
  scheduler?: Maybe<Scalars['JSONString']>;
  priorRunsFilters?: Maybe<Scalars['JSONString']>;
  displayName?: Maybe<Scalars['String']>;
  templateVariableValues?: Maybe<Scalars['JSONString']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpsertSweepPayload = {
  __typename: 'UpsertSweepPayload';
  sweep?: Maybe<Sweep>;
  inserted?: Maybe<Scalars['Boolean']>;
  insertedPriorRuns?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
  configValidationWarnings: Array<Scalars['String']>;
};

export type UpsertViewInput = {
  id?: Maybe<Scalars['ID']>;
  entityName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  spec?: Maybe<Scalars['String']>;
  specIsGzipped?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['ID']>;
  locked?: Maybe<Scalars['Boolean']>;
  previewUrl?: Maybe<Scalars['String']>;
  coverUrl?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
  showcasedAt?: Maybe<Scalars['DateTime']>;
  createdUsing?: Maybe<ViewSource>;
};

export type UpsertViewPayload = {
  __typename: 'UpsertViewPayload';
  view?: Maybe<View>;
  inserted?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UsageAggregation = {
  __typename: 'UsageAggregation';
  start: Scalars['DateTime'];
  end: Scalars['DateTime'];
  value: Scalars['Int64'];
  entityName: Scalars['String'];
  projectName: Scalars['String'];
};

export type UsageStat = {
  __typename: 'UsageStat';
  date?: Maybe<Scalars['DateTime']>;
  stat?: Maybe<Scalars['Float']>;
};

export type UsageStatConnection = {
  __typename: 'UsageStatConnection';
  pageInfo: PageInfo;
  edges: Array<UsageStatEdge>;
};

export type UsageStatEdge = {
  __typename: 'UsageStatEdge';
  node: UsageStat;
  cursor: Scalars['String'];
};

export enum UsageType {
  Storage = 'STORAGE',
  TrackedHours = 'TRACKED_HOURS',
  Weave = 'WEAVE',
  UserSeats = 'USER_SEATS'
}

export type UseArtifactAction = ArtifactAction & {
  __typename: 'UseArtifactAction';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  artifact: Artifact;
  initiator: Initiator;
};

export type UseArtifactInput = {
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  runName: Scalars['String'];
  artifactID: Scalars['ID'];
  usedAs?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UseArtifactPayload = {
  __typename: 'UseArtifactPayload';
  run: Run;
  artifact: Artifact;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type User = Node & {
  __typename: 'User';
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  analyticsId?: Maybe<Scalars['String']>;
  featureFlags: Array<Maybe<FeatureFlag>>;
  /** @deprecated Field no longer supported */
  ndbId?: Maybe<Scalars['ID']>;
  email?: Maybe<Scalars['String']>;
  emails: Array<Email>;
  /** @deprecated Field no longer supported */
  authId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  username?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  photoUploadUrl: Scalars['String'];
  uploadHeaders: Array<Scalars['String']>;
  admin?: Maybe<Scalars['Boolean']>;
  defaultEntityId?: Maybe<Scalars['Int']>;
  accountType?: Maybe<UserAccountType>;
  loggedInAt?: Maybe<Scalars['DateTime']>;
  defaultEntity?: Maybe<Entity>;
  userEntity?: Maybe<Entity>;
  userInfo?: Maybe<Scalars['JSON']>;
  hideTeamsFromPublic: Scalars['Boolean'];
  limits?: Maybe<Scalars['JSON']>;
  onboardingSteps?: Maybe<Scalars['JSON']>;
  private: Scalars['Boolean'];
  signupRequired: Scalars['Boolean'];
  newsletterSubscription?: Maybe<NewsletterSubscription>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  projects?: Maybe<ProjectConnection>;
  starredProjects?: Maybe<ProjectConnection>;
  views?: Maybe<ViewConnection>;
  starredViews?: Maybe<ViewConnection>;
  apiKeys?: Maybe<ApiKeyConnection>;
  apiKey?: Maybe<Scalars['String']>;
  entity?: Maybe<Scalars['String']>;
  defaultFramework?: Maybe<Scalars['String']>;
  flags?: Maybe<Scalars['JSONString']>;
  code?: Maybe<Scalars['String']>;
  teams?: Maybe<EntityConnection>;
  organizations: Array<Organization>;
  dailyRunCount: Array<Scalars['Int']>;
  totalRuns: Scalars['Int'];
  hasRun: Scalars['Boolean'];
  runs?: Maybe<RunConnection>;
  activity?: Maybe<UserActivityDetails>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  personalEntityDisabled: Scalars['Boolean'];
  instanceAdminTeamAccessDisabled: Scalars['Boolean'];
  isEmailDomainClaimedAndShouldRedirectToSSO: Scalars['Boolean'];
  domainClaimBlockingReason?: Maybe<Scalars['String']>;
};


export type UserFeatureFlagsArgs = {
  featureKey?: Maybe<Scalars['String']>;
  rampIDType: RampIdType;
};


export type UserProjectsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
  restrictTo?: Maybe<UserProjectRestriction>;
  linkedBenchmark?: Maybe<ProjectIdentifierInput>;
  pattern?: Maybe<Scalars['String']>;
};


export type UserStarredProjectsArgs = {
  organizationID?: Maybe<Scalars['ID']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
};


export type UserViewsArgs = {
  viewType?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserStarredViewsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserApiKeysArgs = {
  keysOnly?: Maybe<Scalars['Boolean']>;
  batchSize?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserTeamsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type UserOrganizationsArgs = {
  emailDomain?: Maybe<Scalars['String']>;
};


export type UserDailyRunCountArgs = {
  limit: Scalars['Int'];
};


export type UserRunsArgs = {
  order?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  pattern?: Maybe<Scalars['String']>;
};


export type UserDomainClaimBlockingReasonArgs = {
  orgId: Scalars['ID'];
};

export enum UserAccountType {
  User = 'USER',
  Service = 'SERVICE',
  OrgService = 'ORG_SERVICE',
  Anonymous = 'ANONYMOUS',
  Viewer = 'VIEWER'
}

export type UserActivityDetails = ActivityDuration & {
  __typename: 'UserActivityDetails';
  lastActiveAt?: Maybe<Scalars['DateTime']>;
  daysActive: Scalars['Int'];
  numReports: Scalars['Int'];
  numRuns: Scalars['Int'];
  startAt: Scalars['DateTime'];
  endAt: Scalars['DateTime'];
};

export type UserConnection = {
  __typename: 'UserConnection';
  pageInfo: PageInfo;
  edges: Array<UserEdge>;
};

export type UserEdge = {
  __typename: 'UserEdge';
  node?: Maybe<User>;
  cursor: Scalars['String'];
};

export enum UserProjectRestriction {
  Owned = 'OWNED',
  Contributed = 'CONTRIBUTED'
}

export type Variant = {
  __typename: 'Variant';
  bucket: Scalars['Int'];
  allocation: Scalars['Int'];
  description: Scalars['String'];
};

export type VariantInput = {
  bucket: Scalars['Int'];
  allocation: Scalars['Int'];
  description: Scalars['String'];
};

export type VersionedArtifactConnection = ArtifactConnectionType & {
  __typename: 'VersionedArtifactConnection';
  totalCount: Scalars['Int'];
  pageInfo: PageInfo;
  edges: Array<VersionedArtifactEdge>;
};

export type VersionedArtifactEdge = ArtifactEdgeType & {
  __typename: 'VersionedArtifactEdge';
  node: Artifact;
  version: Scalars['String'];
  cursor: Scalars['String'];
};

export type View = Node & {
  __typename: 'View';
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  updatedBy?: Maybe<User>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  projectId?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  imageUploadUrl: ImageUrl;
  uploadHeaders: Array<Scalars['String']>;
  previewUrl?: Maybe<Scalars['String']>;
  coverUrl?: Maybe<Scalars['String']>;
  specObject?: Maybe<Scalars['JSON']>;
  spec?: Maybe<Scalars['JSONString']>;
  user?: Maybe<User>;
  project?: Maybe<Project>;
  entity?: Maybe<Entity>;
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  starCount: Scalars['Int'];
  starred?: Maybe<Scalars['Boolean']>;
  stargazers: StargazerConnection;
  children?: Maybe<ViewConnection>;
  parentId?: Maybe<Scalars['ID']>;
  locked: Scalars['Boolean'];
  viewCount: Scalars['Int'];
  showcasedAt?: Maybe<Scalars['DateTime']>;
  createdUsing: ViewSource;
  discussionThreads: DiscussionThreadConnection;
  alertSubscription?: Maybe<AlertSubscription>;
  accessTokens: Array<AccessToken>;
};


export type ViewImageUploadUrlArgs = {
  name: Scalars['String'];
};


export type ViewStarCountArgs = {
  from?: Maybe<Scalars['DateTime']>;
  to?: Maybe<Scalars['DateTime']>;
};


export type ViewStargazersArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type ViewDiscussionThreadsArgs = {
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type ViewConnection = {
  __typename: 'ViewConnection';
  totalCount: Scalars['Int'];
  pageInfo: PageInfo;
  edges: Array<ViewEdge>;
};

export type ViewEdge = {
  __typename: 'ViewEdge';
  node?: Maybe<View>;
  cursor: Scalars['String'];
};

export enum ViewSource {
  WandbUi = 'WANDB_UI',
  WandbUiSharePanel = 'WANDB_UI_SHARE_PANEL',
  WandbSdk = 'WANDB_SDK',
  WeaveUi = 'WEAVE_UI'
}

export type ViewViewInput = {
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type ViewViewPayload = {
  __typename: 'ViewViewPayload';
  success?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export enum WeaveIngestionLimitInMb {
  Limit_600 = 'LIMIT_600',
  Limit_1000 = 'LIMIT_1000'
}

export type WeaveLimits = {
  __typename: 'WeaveLimits';
  weaveLimitBytes?: Maybe<Scalars['Int64']>;
  weaveOverageCostCents?: Maybe<Scalars['Int']>;
  weaveOverageUnit?: Maybe<StorageUnit>;
};

export enum WorkflowsAdminType {
  Model = 'MODEL'
}

export type UpgradeCustomerToProPlanInput = {
  planID: Scalars['ID'];
  organizationID: Scalars['ID'];
  paymentMethod: Scalars['String'];
  modelsSeats: Scalars['Int'];
  weaveIngestionTier: WeaveIngestionLimitInMb;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type UpgradeCustomerToProPlanPayload = {
  __typename: 'upgradeCustomerToProPlanPayload';
  subscriptionCommit?: Maybe<Scalars['JSON']>;
  subscriptionUsage?: Maybe<Scalars['JSON']>;
  clientMutationId?: Maybe<Scalars['String']>;
  organizationID: Scalars['ID'];
};

export type UpgradeTrialSubscriptionInput = {
  organizationID: Scalars['ID'];
  planID: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type GraphDataFragmentFragment = (
  { __typename: 'ArtifactLineageDag' }
  & Pick<ArtifactLineageDag, 'hitLimit'>
  & { artifacts: Array<Maybe<(
    { __typename: 'ArtifactLineageDagArtifact' }
    & Pick<ArtifactLineageDagArtifact, 'id' | 'artifactNodeID' | 'entityName' | 'projectName' | 'artifactTypeName' | 'artifactSequenceName' | 'artifactCommitHash' | 'versionIndex'>
  )>>, runs: Array<Maybe<(
    { __typename: 'ArtifactLineageDagRun' }
    & Pick<ArtifactLineageDagRun, 'runNodeID' | 'entityName' | 'projectName' | 'runName' | 'displayName' | 'jobType'>
  )>>, clusters: Array<Maybe<(
    { __typename: 'ArtifactLineageDagCluster' }
    & Pick<ArtifactLineageDagCluster, 'id' | 'nodeType'>
    & { runNodes: Array<Maybe<(
      { __typename: 'ArtifactLineageDagRun' }
      & Pick<ArtifactLineageDagRun, 'runNodeID' | 'entityName' | 'projectName' | 'runName' | 'displayName' | 'jobType'>
    )>>, artifactNodes: Array<Maybe<(
      { __typename: 'ArtifactLineageDagArtifact' }
      & Pick<ArtifactLineageDagArtifact, 'id' | 'artifactNodeID' | 'entityName' | 'projectName' | 'artifactTypeName' | 'artifactSequenceName' | 'artifactCommitHash' | 'versionIndex'>
    )>>, clusterEdges: Array<Maybe<(
      { __typename: 'ArtifactLineageDagEdge' }
      & Pick<ArtifactLineageDagEdge, 'edgeID' | 'artifactNodeID' | 'runNodeID' | 'clusterNodeID' | 'direction'>
    )>> }
  )>>, edges: Array<Maybe<(
    { __typename: 'ArtifactLineageDagEdge' }
    & Pick<ArtifactLineageDagEdge, 'edgeID' | 'artifactNodeID' | 'runNodeID' | 'clusterNodeID' | 'direction'>
  )>> }
);

export type ArtifactDagQueryVariables = Exact<{
  artifactVersionID: Scalars['ID'];
  limit: Scalars['Int'];
  filterGeneratedArtifacts: Scalars['Boolean'];
  clusterSize?: Maybe<Scalars['Int']>;
  directLineage?: Maybe<Scalars['Boolean']>;
  traversalDirection?: Maybe<TraversalDirection>;
  baseGraphNodeIDs?: Maybe<Array<Scalars['String']>>;
}>;


export type ArtifactDagQuery = (
  { __typename: 'Query' }
  & { artifact?: Maybe<(
    { __typename: 'Artifact' }
    & Pick<Artifact, 'id'>
    & { artifactLineageDag?: Maybe<(
      { __typename: 'ArtifactLineageDag' }
      & GraphDataFragmentFragment
    )> }
  )> }
);

export type ArtifactOverviewQueryVariables = Exact<{
  artifactID: Scalars['ID'];
}>;


export type ArtifactOverviewQuery = (
  { __typename: 'Query' }
  & { artifact?: Maybe<(
    { __typename: 'Artifact' }
    & Pick<Artifact, 'id' | 'digest' | 'description' | 'size' | 'fileCount' | 'createdAt' | 'usedCount' | 'ttlDurationSeconds'>
    & { artifactSequence: (
      { __typename: 'ArtifactSequence' }
      & Pick<ArtifactSequence, 'id' | 'name'>
      & { project?: Maybe<(
        { __typename: 'Project' }
        & Pick<Project, 'id' | 'name' | 'entityName'>
      )> }
    ), aliases: Array<(
      { __typename: 'ArtifactAlias' }
      & Pick<ArtifactAlias, 'id' | 'alias'>
    )>, createdBy?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id' | 'displayName'>
      & { runName: Run['name'] }
      & { runProjectQuery?: Maybe<(
        { __typename: 'Project' }
        & Pick<Project, 'id'>
        & { runProject: Project['name'], runEntity: Project['entityName'] }
      )> }
    ) | (
      { __typename: 'User' }
      & Pick<User, 'id' | 'username'>
    )>, artifactCollections: (
      { __typename: 'ArtifactCollectionConnection' }
      & { edges: Array<(
        { __typename: 'ArtifactCollectionEdge' }
        & { node?: Maybe<(
          { __typename: 'ArtifactPortfolio' }
          & Pick<ArtifactPortfolio, 'id'>
          & { linkPortfolioName: ArtifactPortfolio['name'] }
          & { linkProjectQuery?: Maybe<(
            { __typename: 'Project' }
            & Pick<Project, 'id'>
            & { linkProjectName: Project['name'], linkEntityName: Project['entityName'] }
          )>, artifactMemberships: (
            { __typename: 'ArtifactCollectionMembershipConnection' }
            & { edges: Array<(
              { __typename: 'ArtifactCollectionMembershipEdge' }
              & { node?: Maybe<(
                { __typename: 'ArtifactCollectionMembership' }
                & Pick<ArtifactCollectionMembership, 'id' | 'versionIndex'>
              )> }
            )> }
          ) }
        ) | (
          { __typename: 'ArtifactSequence' }
          & Pick<ArtifactSequence, 'id'>
        )> }
      )> }
    ) }
  )> }
);

export type RunArtifactDagQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  runName: Scalars['String'];
  limit: Scalars['Int'];
  filterGeneratedArtifacts: Scalars['Boolean'];
  clusterSize?: Maybe<Scalars['Int']>;
  directLineage?: Maybe<Scalars['Boolean']>;
  traversalDirection?: Maybe<TraversalDirection>;
  baseGraphNodeIDs?: Maybe<Array<Scalars['String']>>;
}>;


export type RunArtifactDagQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { run?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id'>
      & { artifactLineageDag?: Maybe<(
        { __typename: 'ArtifactLineageDag' }
        & GraphDataFragmentFragment
      )> }
    )> }
  )> }
);

export type RunOverviewLineageQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  runName: Scalars['String'];
}>;


export type RunOverviewLineageQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { run?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id' | 'createdAt' | 'group' | 'state' | 'notes' | 'config' | 'tags' | 'host' | 'computeSeconds'>
      & { project?: Maybe<(
        { __typename: 'Project' }
        & Pick<Project, 'id' | 'name'>
      )>, user?: Maybe<(
        { __typename: 'User' }
        & Pick<User, 'id' | 'username'>
      )>, runInfo?: Maybe<(
        { __typename: 'RunInfo' }
        & Pick<RunInfo, 'os' | 'python'>
      )> }
    )> }
  )> }
);

export type ArtifactCollectionWithRegistryVersionQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  artifactTypeName: Scalars['String'];
  artifactCollectionName: Scalars['String'];
}>;


export type ArtifactCollectionWithRegistryVersionQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { artifactType?: Maybe<(
      { __typename: 'ArtifactType' }
      & Pick<ArtifactType, 'id'>
      & { artifactCollection?: Maybe<(
        { __typename: 'ArtifactPortfolio' }
        & Pick<ArtifactPortfolio, 'id' | 'name' | 'hasArtifactVersionLinkedToGlobalRegistry'>
      ) | (
        { __typename: 'ArtifactSequence' }
        & Pick<ArtifactSequence, 'id' | 'name' | 'hasArtifactVersionLinkedToGlobalRegistry'>
      )> }
    )> }
  )> }
);

export type ArtifactProjectMetadataSearchQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  filters: Scalars['JSONString'];
}>;


export type ArtifactProjectMetadataSearchQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { artifacts: Array<(
      { __typename: 'Artifact' }
      & Pick<Artifact, 'id' | 'digest' | 'versionIndex' | 'createdAt'>
      & { aliases: Array<(
        { __typename: 'ArtifactAlias' }
        & Pick<ArtifactAlias, 'id' | 'alias'>
      )>, artifactSequence: (
        { __typename: 'ArtifactSequence' }
        & Pick<ArtifactSequence, 'id' | 'name'>
      ), artifactType: (
        { __typename: 'ArtifactType' }
        & Pick<ArtifactType, 'id' | 'name'>
      ) }
    )> }
  )> }
);

export type EotfOrganizationSubscriptionsBasicInfoQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type EotfOrganizationSubscriptionsBasicInfoQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
    & { subscriptions: Array<(
      { __typename: 'OrganizationSubscription' }
      & Pick<OrganizationSubscription, 'id' | 'expiresAt'>
      & { plan: (
        { __typename: 'Plan' }
        & Pick<Plan, 'id' | 'name' | 'planType'>
      ) }
    )> }
  )> }
);

export type ProjectFromNameQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
}>;


export type ProjectFromNameQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'name' | 'entityName'>
  )> }
);

export type EntityArtifactTypeQueryVariables = Exact<{
  entityName: Scalars['String'];
  artifactType: Scalars['String'];
}>;


export type EntityArtifactTypeQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id'>
    & { projects?: Maybe<(
      { __typename: 'ProjectConnection' }
      & { edges: Array<(
        { __typename: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename: 'Project' }
          & Pick<Project, 'id' | 'name'>
          & { artifactType?: Maybe<(
            { __typename: 'ArtifactType' }
            & Pick<ArtifactType, 'id' | 'name'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type FetchProjectJobsForTriggersPartialQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  limit: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;


export type FetchProjectJobsForTriggersPartialQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'name'>
    & { artifactType?: Maybe<(
      { __typename: 'ArtifactType' }
      & Pick<ArtifactType, 'id'>
      & { artifactCollections?: Maybe<(
        { __typename: 'ArtifactCollectionConnection' }
        & { pageInfo: (
          { __typename: 'PageInfo' }
          & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
        ), edges: Array<(
          { __typename: 'ArtifactCollectionEdge' }
          & { node?: Maybe<(
            { __typename: 'ArtifactPortfolio' }
            & Pick<ArtifactPortfolio, 'id' | 'name' | 'createdAt'>
            & { project?: Maybe<(
              { __typename: 'Project' }
              & Pick<Project, 'id' | 'name' | 'access'>
              & { entity: (
                { __typename: 'Entity' }
                & Pick<Entity, 'id' | 'name'>
              ) }
            )>, artifacts: (
              { __typename: 'VersionedArtifactConnection' }
              & { edges: Array<(
                { __typename: 'VersionedArtifactEdge' }
                & { node: (
                  { __typename: 'Artifact' }
                  & Pick<Artifact, 'id' | 'createdAt' | 'description' | 'labels' | 'versionIndex'>
                  & { aliases: Array<(
                    { __typename: 'ArtifactAlias' }
                    & Pick<ArtifactAlias, 'id' | 'alias'>
                  )>, createdBy?: Maybe<(
                    { __typename: 'Run' }
                    & Pick<Run, 'id' | 'name' | 'config'>
                  ) | (
                    { __typename: 'User' }
                    & Pick<User, 'id' | 'name' | 'username' | 'photoUrl'>
                  )>, usedBy: (
                    { __typename: 'RunConnection' }
                    & Pick<RunConnection, 'totalCount'>
                    & { edges: Array<(
                      { __typename: 'RunEdge' }
                      & { node: (
                        { __typename: 'Run' }
                        & Pick<Run, 'id' | 'name' | 'displayName' | 'state' | 'createdAt' | 'config' | 'defaultColorIndex'>
                        & { user?: Maybe<(
                          { __typename: 'User' }
                          & Pick<User, 'id' | 'name' | 'username' | 'photoUrl'>
                        )> }
                      ) }
                    )> }
                  ) }
                ) }
              )> }
            ) }
          ) | (
            { __typename: 'ArtifactSequence' }
            & Pick<ArtifactSequence, 'id' | 'name' | 'createdAt'>
            & { project?: Maybe<(
              { __typename: 'Project' }
              & Pick<Project, 'id' | 'name' | 'access'>
              & { entity: (
                { __typename: 'Entity' }
                & Pick<Entity, 'id' | 'name'>
              ) }
            )>, artifacts: (
              { __typename: 'VersionedArtifactConnection' }
              & { edges: Array<(
                { __typename: 'VersionedArtifactEdge' }
                & { node: (
                  { __typename: 'Artifact' }
                  & Pick<Artifact, 'id' | 'createdAt' | 'description' | 'labels' | 'versionIndex'>
                  & { aliases: Array<(
                    { __typename: 'ArtifactAlias' }
                    & Pick<ArtifactAlias, 'id' | 'alias'>
                  )>, createdBy?: Maybe<(
                    { __typename: 'Run' }
                    & Pick<Run, 'id' | 'name' | 'config'>
                  ) | (
                    { __typename: 'User' }
                    & Pick<User, 'id' | 'name' | 'username' | 'photoUrl'>
                  )>, usedBy: (
                    { __typename: 'RunConnection' }
                    & Pick<RunConnection, 'totalCount'>
                    & { edges: Array<(
                      { __typename: 'RunEdge' }
                      & { node: (
                        { __typename: 'Run' }
                        & Pick<Run, 'id' | 'name' | 'displayName' | 'state' | 'createdAt' | 'config' | 'defaultColorIndex'>
                        & { user?: Maybe<(
                          { __typename: 'User' }
                          & Pick<User, 'id' | 'name' | 'username' | 'photoUrl'>
                        )> }
                      ) }
                    )> }
                  ) }
                ) }
              )> }
            ) }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type OrganizationMembersTableQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type OrganizationMembersTableQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
    & { billingUser?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'username'>
    )>, members: Array<(
      { __typename: 'OrganizationMember' }
      & Pick<OrganizationMember, 'id' | 'name' | 'username' | 'email' | 'photoUrl' | 'role' | 'modelsSeat'>
      & { teams?: Maybe<(
        { __typename: 'EntityConnection' }
        & { edges: Array<(
          { __typename: 'EntityEdge' }
          & { node?: Maybe<(
            { __typename: 'Entity' }
            & Pick<Entity, 'id' | 'name'>
          )> }
        )> }
      )> }
    )>, seatAvailability: (
      { __typename: 'OrganizationSeatAvailability' }
      & Pick<OrganizationSeatAvailability, 'viewOnlySeats' | 'seats'>
    ), teams: Array<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'name'>
    )>, subscriptions: Array<(
      { __typename: 'OrganizationSubscription' }
      & Pick<OrganizationSubscription, 'id' | 'availableSeatsToPurchase' | 'privileges'>
      & { plan: (
        { __typename: 'Plan' }
        & Pick<Plan, 'id' | 'planType' | 'unitPrice'>
      ) }
    )>, pendingInvites: Array<(
      { __typename: 'Invite' }
      & Pick<Invite, 'id' | 'email' | 'createdAt' | 'role'>
      & { toUser?: Maybe<(
        { __typename: 'User' }
        & Pick<User, 'id' | 'username'>
      )>, entity?: Maybe<(
        { __typename: 'Entity' }
        & Pick<Entity, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type FetchEntityModelRegistryArtifactCollectionsPartialQueryVariables = Exact<{
  entityName: Scalars['String'];
  limit: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;


export type FetchEntityModelRegistryArtifactCollectionsPartialQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id' | 'name'>
    & { artifactCollections?: Maybe<(
      { __typename: 'ArtifactCollectionConnection' }
      & { pageInfo: (
        { __typename: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      ), edges: Array<(
        { __typename: 'ArtifactCollectionEdge' }
        & { node?: Maybe<(
          { __typename: 'ArtifactPortfolio' }
          & RegistryPageCollectionFragment_ArtifactPortfolio_Fragment
        ) | (
          { __typename: 'ArtifactSequence' }
          & RegistryPageCollectionFragment_ArtifactSequence_Fragment
        )> }
      )> }
    )> }
  )> }
);

export type ProjectListFragment = (
  { __typename: 'Project' }
  & Pick<Project, 'name' | 'access' | 'entityName' | 'createdAt' | 'lastActive'>
  & { totalRuns: Project['runCount'] }
);

export type ProjectsByTeamQueryVariables = Exact<{
  entityName: Scalars['String'];
  pattern?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;


export type ProjectsByTeamQuery = (
  { __typename: 'Query' }
  & { projects?: Maybe<(
    { __typename: 'ProjectConnection' }
    & { edges: Array<(
      { __typename: 'ProjectEdge' }
      & { node?: Maybe<(
        { __typename: 'Project' }
        & Pick<Project, 'id'>
        & ProjectListFragment
      )> }
    )>, pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  )> }
);

export type ProjectsByAccountQueryVariables = Exact<{
  orgID: Scalars['ID'];
  userID: Scalars['ID'];
  order?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  pattern?: Maybe<Scalars['String']>;
}>;


export type ProjectsByAccountQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { projects?: Maybe<(
      { __typename: 'ProjectConnection' }
      & { edges: Array<(
        { __typename: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename: 'Project' }
          & Pick<Project, 'id'>
          & ProjectListFragment
        )> }
      )>, pageInfo: (
        { __typename: 'PageInfo' }
        & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
      ) }
    )> }
  )> }
);

export type ProjectsByUserQueryVariables = Exact<{
  userName: Scalars['String'];
  pattern?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
}>;


export type ProjectsByUserQuery = (
  { __typename: 'Query' }
  & { user?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'username'>
    & { projects?: Maybe<(
      { __typename: 'ProjectConnection' }
      & { edges: Array<(
        { __typename: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename: 'Project' }
          & Pick<Project, 'id'>
          & ProjectListFragment
        )> }
      )>, pageInfo: (
        { __typename: 'PageInfo' }
        & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
      ) }
    )> }
  )> }
);

export type ProjectDetailsQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
}>;


export type ProjectDetailsQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'fromV2'>
  )> }
);

export type DeltaQueryFragmentFragment = (
  { __typename: 'Project' }
  & Pick<Project, 'entityName' | 'name'>
  & { runs?: Maybe<(
    { __typename: 'RunConnection' }
    & { deltas?: Maybe<(
      { __typename: 'BucketDiffs' }
      & Pick<BucketDiffs, 'order'>
      & { delta: Array<(
        { __typename: 'BucketDiff' }
        & Pick<BucketDiff, 'op'>
        & { run?: Maybe<(
          { __typename: 'Run' }
          & Pick<Run, 'bucketedHistory' | 'config' | 'defaultColorIndex' | 'displayName' | 'id' | 'name' | 'projectId' | 'summaryMetrics' | 'updatedAt'>
          & RunStateBasicFragmentFragment
        )> }
      )> }
    )> }
  )> }
);

export type BucketedRunsDeltaQueryQueryVariables = Exact<{
  bucketedHistorySpecs: Array<Scalars['JSONString']>;
  configKeys?: Maybe<Array<Scalars['String']>>;
  currentRuns: Array<Scalars['String']>;
  enableBasic?: Maybe<Scalars['Boolean']>;
  enableConfig?: Maybe<Scalars['Boolean']>;
  enableSummary?: Maybe<Scalars['Boolean']>;
  entityName: Scalars['String'];
  filters: Scalars['JSONString'];
  groupKeys: Array<Scalars['String']>;
  groupLevel: Scalars['Int'];
  lastUpdated: Scalars['DateTime'];
  limit: Scalars['Int'];
  order: Scalars['String'];
  projectName: Scalars['String'];
  summaryKeys?: Maybe<Array<Scalars['String']>>;
}>;


export type BucketedRunsDeltaQueryQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & DeltaQueryFragmentFragment
  )> }
);

export type BucketedRunsDeltaQueryInternalIdQueryVariables = Exact<{
  bucketedHistorySpecs: Array<Scalars['JSONString']>;
  configKeys?: Maybe<Array<Scalars['String']>>;
  currentRuns: Array<Scalars['String']>;
  enableBasic?: Maybe<Scalars['Boolean']>;
  enableConfig?: Maybe<Scalars['Boolean']>;
  enableSummary?: Maybe<Scalars['Boolean']>;
  filters: Scalars['JSONString'];
  groupKeys: Array<Scalars['String']>;
  groupLevel: Scalars['Int'];
  internalId: Scalars['ID'];
  lastUpdated: Scalars['DateTime'];
  limit: Scalars['Int'];
  order: Scalars['String'];
  summaryKeys?: Maybe<Array<Scalars['String']>>;
}>;


export type BucketedRunsDeltaQueryInternalIdQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & DeltaQueryFragmentFragment
  )> }
);

export type StarProjectMutationVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  organizationId?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type StarProjectMutation = (
  { __typename: 'Mutation' }
  & { starProject?: Maybe<(
    { __typename: 'StarProjectPayload' }
    & Pick<StarProjectPayload, 'clientMutationId'>
    & { project?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id' | 'starred'>
    )> }
  )> }
);

export type UnstarProjectMutationVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  organizationId?: Maybe<Scalars['ID']>;
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type UnstarProjectMutation = (
  { __typename: 'Mutation' }
  & { unstarProject?: Maybe<(
    { __typename: 'UnstarProjectPayload' }
    & Pick<UnstarProjectPayload, 'clientMutationId'>
    & { project?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id' | 'starred'>
    )> }
  )> }
);

export type TeamMemberRolesQueryVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type TeamMemberRolesQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id'>
    & { members: Array<(
      { __typename: 'Member' }
      & Pick<Member, 'id' | 'username'>
      & { memberRole?: Maybe<(
        { __typename: 'Role' }
        & Pick<Role, 'ID' | 'name'>
      )> }
    )> }
  )> }
);

export type UpdateUserProjectRoleMutationVariables = Exact<{
  userId: Scalars['ID'];
  projectId: Scalars['ID'];
  userProjectRole: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type UpdateUserProjectRoleMutation = (
  { __typename: 'Mutation' }
  & { updateProjectMember?: Maybe<(
    { __typename: 'UpdateProjectMemberPayload' }
    & Pick<UpdateProjectMemberPayload, 'success' | 'clientMutationId'>
  )> }
);

export type FilteredProjectPickerQueryQueryVariables = Exact<{
  entityName: Scalars['String'];
  userName: Scalars['String'];
  pattern?: Maybe<Scalars['String']>;
}>;


export type FilteredProjectPickerQueryQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id'>
    & { projects?: Maybe<(
      { __typename: 'ProjectConnection' }
      & { edges: Array<(
        { __typename: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename: 'Project' }
          & Pick<Project, 'id' | 'internalId' | 'name' | 'entityName' | 'createdAt'>
        )> }
      )> }
    )> }
  )>, viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { projects?: Maybe<(
      { __typename: 'ProjectConnection' }
      & { edges: Array<(
        { __typename: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename: 'Project' }
          & Pick<Project, 'id' | 'internalId' | 'name' | 'entityName' | 'createdAt'>
        )> }
      )> }
    )> }
  )>, user?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { teams?: Maybe<(
      { __typename: 'EntityConnection' }
      & { edges: Array<(
        { __typename: 'EntityEdge' }
        & { node?: Maybe<(
          { __typename: 'Entity' }
          & Pick<Entity, 'id' | 'name'>
          & { projects?: Maybe<(
            { __typename: 'ProjectConnection' }
            & { edges: Array<(
              { __typename: 'ProjectEdge' }
              & { node?: Maybe<(
                { __typename: 'Project' }
                & Pick<Project, 'id' | 'internalId' | 'name' | 'entityName' | 'createdAt'>
              )> }
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

type RegistryPageCollectionFragment_ArtifactPortfolio_Fragment = (
  { __typename: 'ArtifactPortfolio' }
  & Pick<ArtifactPortfolio, 'id' | 'name' | 'description' | 'createdAt' | 'updatedAt'>
  & { defaultArtifactType: (
    { __typename: 'ArtifactType' }
    & Pick<ArtifactType, 'id' | 'name'>
  ), project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'name' | 'entityName'>
  )>, artifactMemberships: (
    { __typename: 'ArtifactCollectionMembershipConnection' }
    & { edges: Array<(
      { __typename: 'ArtifactCollectionMembershipEdge' }
      & { node?: Maybe<(
        { __typename: 'ArtifactCollectionMembership' }
        & Pick<ArtifactCollectionMembership, 'id' | 'versionIndex' | 'createdAt'>
        & { aliases: Array<(
          { __typename: 'ArtifactAlias' }
          & Pick<ArtifactAlias, 'id' | 'alias'>
        )>, artifact?: Maybe<(
          { __typename: 'Artifact' }
          & Pick<Artifact, 'id'>
          & { tags: Array<(
            { __typename: 'Tag' }
            & Pick<Tag, 'id' | 'name' | 'tagCategoryName' | 'attributes'>
          )> }
        )> }
      )> }
    )> }
  ), tags: (
    { __typename: 'TagConnection' }
    & { edges: Array<(
      { __typename: 'TagEdge' }
      & { node: (
        { __typename: 'Tag' }
        & Pick<Tag, 'id' | 'name' | 'tagCategoryName' | 'attributes'>
      ) }
    )> }
  ) }
);

type RegistryPageCollectionFragment_ArtifactSequence_Fragment = (
  { __typename: 'ArtifactSequence' }
  & Pick<ArtifactSequence, 'id' | 'name' | 'description' | 'createdAt' | 'updatedAt'>
  & { defaultArtifactType: (
    { __typename: 'ArtifactType' }
    & Pick<ArtifactType, 'id' | 'name'>
  ), project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'name' | 'entityName'>
  )>, artifactMemberships: (
    { __typename: 'ArtifactCollectionMembershipConnection' }
    & { edges: Array<(
      { __typename: 'ArtifactCollectionMembershipEdge' }
      & { node?: Maybe<(
        { __typename: 'ArtifactCollectionMembership' }
        & Pick<ArtifactCollectionMembership, 'id' | 'versionIndex' | 'createdAt'>
        & { aliases: Array<(
          { __typename: 'ArtifactAlias' }
          & Pick<ArtifactAlias, 'id' | 'alias'>
        )>, artifact?: Maybe<(
          { __typename: 'Artifact' }
          & Pick<Artifact, 'id'>
          & { tags: Array<(
            { __typename: 'Tag' }
            & Pick<Tag, 'id' | 'name' | 'tagCategoryName' | 'attributes'>
          )> }
        )> }
      )> }
    )> }
  ), tags: (
    { __typename: 'TagConnection' }
    & { edges: Array<(
      { __typename: 'TagEdge' }
      & { node: (
        { __typename: 'Tag' }
        & Pick<Tag, 'id' | 'name' | 'tagCategoryName' | 'attributes'>
      ) }
    )> }
  ) }
);

export type RegistryPageCollectionFragmentFragment = RegistryPageCollectionFragment_ArtifactPortfolio_Fragment | RegistryPageCollectionFragment_ArtifactSequence_Fragment;

export type FetchRegistryArtifactCollectionsPartialQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  limit: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;


export type FetchRegistryArtifactCollectionsPartialQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'entityName' | 'name'>
    & { artifactCollections?: Maybe<(
      { __typename: 'ArtifactCollectionConnection' }
      & { pageInfo: (
        { __typename: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      ), edges: Array<(
        { __typename: 'ArtifactCollectionEdge' }
        & { node?: Maybe<(
          { __typename: 'ArtifactPortfolio' }
          & RegistryPageCollectionFragment_ArtifactPortfolio_Fragment
        ) | (
          { __typename: 'ArtifactSequence' }
          & RegistryPageCollectionFragment_ArtifactSequence_Fragment
        )> }
      )> }
    )> }
  )> }
);

export type OrganizationMemberFragmentFragment = (
  { __typename: 'OrganizationMember' }
  & Pick<OrganizationMember, 'orgID' | 'admin' | 'username' | 'name' | 'id' | 'email' | 'photoUrl' | 'role'>
  & { user: (
    { __typename: 'User' }
    & Pick<User, 'id' | 'accountType' | 'admin'>
  ) }
);

export type RegistryOrganizationQueryVariables = Exact<{
  orgName: Scalars['String'];
}>;


export type RegistryOrganizationQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { members: Array<(
      { __typename: 'OrganizationMember' }
      & OrganizationMemberFragmentFragment
    )>, serviceAccounts: Array<(
      { __typename: 'ServiceAccount' }
      & Pick<ServiceAccount, 'id' | 'name' | 'accountType'>
    )>, subscriptions: Array<(
      { __typename: 'OrganizationSubscription' }
      & Pick<OrganizationSubscription, 'id'>
      & { plan: (
        { __typename: 'Plan' }
        & Pick<Plan, 'id' | 'name' | 'planType'>
      ) }
    )> }
  )> }
);

export type OrganizationReadOnlyQueryVariables = Exact<{
  orgName: Scalars['String'];
}>;


export type OrganizationReadOnlyQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { orgEntity?: Maybe<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'readOnly'>
    )> }
  )> }
);

export type ProjectReadOnlyQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
}>;


export type ProjectReadOnlyQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id'>
    & { project?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id' | 'readOnly'>
    )> }
  )> }
);

export type RegistryProjectArtifactTypesQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
}>;


export type RegistryProjectArtifactTypesQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'name'>
    & { artifactTypes: (
      { __typename: 'ArtifactTypeConnection' }
      & { edges: Array<(
        { __typename: 'ArtifactTypeEdge' }
        & { node?: Maybe<(
          { __typename: 'ArtifactType' }
          & Pick<ArtifactType, 'id' | 'name'>
        )> }
      )> }
    ) }
  )> }
);

export type RegistryProjectMembersQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
}>;


export type RegistryProjectMembersQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'name'>
    & { user?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'name' | 'username' | 'photoUrl' | 'email' | 'accountType'>
    )>, members: Array<(
      { __typename: 'ProjectMember' }
      & Pick<ProjectMember, 'id' | 'username' | 'name' | 'photoUrl' | 'email' | 'accountType' | 'projectRoleIsInherited'>
      & { role: (
        { __typename: 'Role' }
        & Pick<Role, 'ID' | 'name'>
      ) }
    )> }
  )> }
);

export type ProjectFragmentFragment = (
  { __typename: 'Project' }
  & Pick<Project, 'name' | 'entityName' | 'access' | 'createdAt' | 'lastActive' | 'description' | 'totalArtifactCollections' | 'allowAllArtifactTypesInRegistry'>
  & { user?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'name' | 'photoUrl'>
  )>, artifactTypes: (
    { __typename: 'ArtifactTypeConnection' }
    & { edges: Array<(
      { __typename: 'ArtifactTypeEdge' }
      & { node?: Maybe<(
        { __typename: 'ArtifactType' }
        & Pick<ArtifactType, 'id' | 'name'>
      )> }
    )> }
  ) }
);

export type RegistryProjectsQueryVariables = Exact<{
  orgName: Scalars['String'];
}>;


export type RegistryProjectsQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { orgEntity?: Maybe<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'name'>
      & { projects?: Maybe<(
        { __typename: 'ProjectConnection' }
        & { edges: Array<(
          { __typename: 'ProjectEdge' }
          & { node?: Maybe<(
            { __typename: 'Project' }
            & Pick<Project, 'id'>
            & ProjectFragmentFragment
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type LegacyRegistryProjectsQueryVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type LegacyRegistryProjectsQuery = (
  { __typename: 'Query' }
  & { projects?: Maybe<(
    { __typename: 'ProjectConnection' }
    & { edges: Array<(
      { __typename: 'ProjectEdge' }
      & { node?: Maybe<(
        { __typename: 'Project' }
        & Pick<Project, 'id'>
        & ProjectFragmentFragment
      )> }
    )> }
  )> }
);

export type RegistryProjectsWithTypeQueryVariables = Exact<{
  orgName: Scalars['String'];
  artifactTypeName: Scalars['String'];
}>;


export type RegistryProjectsWithTypeQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { orgEntity?: Maybe<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'name'>
      & { projects?: Maybe<(
        { __typename: 'ProjectConnection' }
        & { edges: Array<(
          { __typename: 'ProjectEdge' }
          & { node?: Maybe<(
            { __typename: 'Project' }
            & Pick<Project, 'id' | 'name' | 'allowAllArtifactTypesInRegistry'>
            & { artifactType?: Maybe<(
              { __typename: 'ArtifactType' }
              & Pick<ArtifactType, 'id' | 'name'>
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type UpsertRegistryProjectMutationVariables = Exact<{
  description?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  access?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['ID']>;
  allowAllArtifactTypesInRegistry?: Maybe<Scalars['Boolean']>;
}>;


export type UpsertRegistryProjectMutation = (
  { __typename: 'Mutation' }
  & { upsertModel?: Maybe<(
    { __typename: 'UpsertModelPayload' }
    & Pick<UpsertModelPayload, 'inserted'>
    & { project?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id' | 'name' | 'entityName' | 'description' | 'access' | 'allowAllArtifactTypesInRegistry'>
      & { user?: Maybe<(
        { __typename: 'User' }
        & Pick<User, 'id'>
      )> }
    )> }
  )> }
);

export type UpdateUserRegistryRoleMutationVariables = Exact<{
  userId: Scalars['ID'];
  projectId: Scalars['ID'];
  userProjectRole: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type UpdateUserRegistryRoleMutation = (
  { __typename: 'Mutation' }
  & { updateProjectMember?: Maybe<(
    { __typename: 'UpdateProjectMemberPayload' }
    & Pick<UpdateProjectMemberPayload, 'success' | 'clientMutationId'>
  )> }
);

export type ImportPanelMenuProjectsQueryVariables = Exact<{
  userName: Scalars['String'];
  selectedEntityName?: Maybe<Scalars['String']>;
  projectSearchQuery?: Maybe<Scalars['String']>;
}>;


export type ImportPanelMenuProjectsQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id'>
    & ImportPanelMenuEntityFragmentFragment
  )>, user?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { teams?: Maybe<(
      { __typename: 'EntityConnection' }
      & { edges: Array<(
        { __typename: 'EntityEdge' }
        & { node?: Maybe<(
          { __typename: 'Entity' }
          & Pick<Entity, 'id'>
          & ImportPanelMenuEntityFragmentFragment
        )> }
      )> }
    )> }
  )> }
);

export type ImportPanelMenuEntityFragmentFragment = (
  { __typename: 'Entity' }
  & Pick<Entity, 'id' | 'name'>
  & { projects?: Maybe<(
    { __typename: 'ProjectConnection' }
    & { edges: Array<(
      { __typename: 'ProjectEdge' }
      & { node?: Maybe<(
        { __typename: 'Project' }
        & Pick<Project, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type ImportPanelMenuViewsQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
}>;


export type ImportPanelMenuViewsQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { allViews?: Maybe<(
      { __typename: 'ViewConnection' }
      & { edges: Array<(
        { __typename: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename: 'View' }
          & Pick<View, 'id'>
          & ImportPanelProjectViewsFragmentFragment
        )> }
      )> }
    )> }
  )> }
);

export type ImportPanelProjectViewsFragmentFragment = (
  { __typename: 'View' }
  & Pick<View, 'id' | 'name'>
  & { user?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'username'>
  )> }
);

export type ImportPanelMenuViewSpecQueryVariables = Exact<{
  viewId: Scalars['ID'];
}>;


export type ImportPanelMenuViewSpecQuery = (
  { __typename: 'Query' }
  & { view?: Maybe<(
    { __typename: 'View' }
    & Pick<View, 'id' | 'spec'>
  )> }
);

export type AllRunGroupsQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
}>;


export type AllRunGroupsQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { runs?: Maybe<(
      { __typename: 'RunConnection' }
      & { edges: Array<(
        { __typename: 'RunEdge' }
        & { node: (
          { __typename: 'Run' }
          & Pick<Run, 'id' | 'name' | 'group'>
        ) }
      )> }
    )> }
  )> }
);

export type MoveRunToGroupMutationVariables = Exact<{
  entityName: Scalars['String'];
  groupName: Scalars['String'];
  projectName: Scalars['String'];
  runId: Scalars['String'];
}>;


export type MoveRunToGroupMutation = (
  { __typename: 'Mutation' }
  & { upsertBucket?: Maybe<(
    { __typename: 'UpsertBucketPayload' }
    & { bucket?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id' | 'group'>
    )> }
  )> }
);

export type RunValueSuggestionsPaginatedQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  keyPath: Scalars['String'];
  filters?: Maybe<Scalars['JSONString']>;
  valuePattern?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type RunValueSuggestionsPaginatedQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { runFieldValues?: Maybe<(
      { __typename: 'RunFieldValueConnection' }
      & { pageInfo: (
        { __typename: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
      ), edges: Array<(
        { __typename: 'RunFieldValueEdge' }
        & { node: (
          { __typename: 'RunFieldValue' }
          & Pick<RunFieldValue, 'value'>
        ) }
      )> }
    )> }
  )> }
);

export type AccountSelectorContextProviderEntityQueryVariables = Exact<{
  teamName: Scalars['String'];
}>;


export type AccountSelectorContextProviderEntityQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id' | 'isTeam'>
    & { organization?: Maybe<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
    )>, members: Array<(
      { __typename: 'Member' }
      & Pick<Member, 'id'>
    )> }
  )> }
);

export type ProfileMenuOrgRoleInfoQueryVariables = Exact<{
  orgName: Scalars['String'];
}>;


export type ProfileMenuOrgRoleInfoQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { members: Array<(
      { __typename: 'OrganizationMember' }
      & Pick<OrganizationMember, 'id' | 'role' | 'username'>
    )> }
  )> }
);

export type UpdateSubscriptionMutationVariables = Exact<{
  entityName: Scalars['String'];
  teamsEnabled?: Maybe<Scalars['Boolean']>;
  privateProjects?: Maybe<Scalars['Boolean']>;
  gpuEnabled?: Maybe<Scalars['DateTime']>;
  hubPreset?: Maybe<Scalars['String']>;
  repo?: Maybe<Scalars['String']>;
  disk?: Maybe<Scalars['String']>;
  redisEnabled?: Maybe<Scalars['Boolean']>;
  dockerEnabled?: Maybe<Scalars['Boolean']>;
  image?: Maybe<Scalars['String']>;
}>;


export type UpdateSubscriptionMutation = (
  { __typename: 'Mutation' }
  & { updateSubscription?: Maybe<(
    { __typename: 'UpdateSubscriptionPayload' }
    & { entity?: Maybe<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'name' | 'available' | 'photoUrl' | 'limits'>
    )> }
  )> }
);

export type UpdateEntityPhotoMutationVariables = Exact<{
  entityName: Scalars['String'];
  photoUrl?: Maybe<Scalars['String']>;
}>;


export type UpdateEntityPhotoMutation = (
  { __typename: 'Mutation' }
  & { updateEntity?: Maybe<(
    { __typename: 'UpdateEntityPayload' }
    & { entity?: Maybe<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'name' | 'available' | 'photoUrl' | 'limits'>
    )> }
  )> }
);

export type TeamQueryQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
}>;


export type TeamQueryQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id' | 'name' | 'available' | 'photoUrl' | 'limits'>
    & { settings: (
      { __typename: 'EntitySettings' }
      & Pick<EntitySettings, 'openToMatchingOrgEmailDomainUsers'>
    ) }
  )> }
);

export type UserQueryQueryVariables = Exact<{
  userName: Scalars['String'];
}>;


export type UserQueryQuery = (
  { __typename: 'Query' }
  & { user?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type AllEntitiesQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
}>;


export type AllEntitiesQuery = (
  { __typename: 'Query' }
  & { entities?: Maybe<(
    { __typename: 'EntityConnection' }
    & { edges: Array<(
      { __typename: 'EntityEdge' }
      & { node?: Maybe<(
        { __typename: 'Entity' }
        & Pick<Entity, 'id' | 'createdAt' | 'name' | 'memberCount' | 'isTeam'>
        & { members: Array<(
          { __typename: 'Member' }
          & Pick<Member, 'admin' | 'pending' | 'username' | 'email' | 'name' | 'id'>
        )> }
      )> }
    )> }
  )> }
);

export type UpdateEntityAdminMutationVariables = Exact<{
  entityName: Scalars['String'];
  isPaid?: Maybe<Scalars['Boolean']>;
  rateLimits?: Maybe<RateLimitsInput>;
}>;


export type UpdateEntityAdminMutation = (
  { __typename: 'Mutation' }
  & { updateEntity?: Maybe<(
    { __typename: 'UpdateEntityPayload' }
    & { entity?: Maybe<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'isPaid'>
      & { rateLimits?: Maybe<(
        { __typename: 'RateLimits' }
        & Pick<RateLimits, 'graphql' | 'sdkGraphql' | 'filestreamCount' | 'filestreamSize' | 'sdkGraphqlQuerySeconds'>
      )> }
    )> }
  )> }
);

export type UpdateProjectAdminMutationVariables = Exact<{
  id: Scalars['String'];
  rateLimits?: Maybe<RateLimitsInput>;
}>;


export type UpdateProjectAdminMutation = (
  { __typename: 'Mutation' }
  & { upsertModel?: Maybe<(
    { __typename: 'UpsertModelPayload' }
    & { project?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id'>
      & { rateLimits?: Maybe<(
        { __typename: 'RateLimits' }
        & Pick<RateLimits, 'filestreamCount' | 'filestreamSize' | 'sdkGraphqlQuerySeconds'>
      )> }
    )> }
  )> }
);

export type ProjectRateLimitsQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
}>;


export type ProjectRateLimitsQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { rateLimits?: Maybe<(
      { __typename: 'RateLimits' }
      & Pick<RateLimits, 'filestreamCount' | 'filestreamSize' | 'sdkGraphqlQuerySeconds'>
    )> }
  )> }
);

export type TriggerParquetExportMutationVariables = Exact<{
  runId: Scalars['ID'];
}>;


export type TriggerParquetExportMutation = (
  { __typename: 'Mutation' }
  & { triggerParquetExport?: Maybe<(
    { __typename: 'TriggerParquetExportPayload' }
    & { task: (
      { __typename: 'Task' }
      & Pick<Task, 'id'>
    ) }
  )> }
);

export type CreateFinishedRunAlertMutationVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type CreateFinishedRunAlertMutation = (
  { __typename: 'Mutation' }
  & { createFinishedRunAlert?: Maybe<(
    { __typename: 'CreateAlertPayload' }
    & { alert: (
      { __typename: 'Alert' }
      & Pick<Alert, 'id'>
      & { condition: (
        { __typename: 'FinishedRunCondition' }
        & Pick<FinishedRunCondition, 'success'>
      ) | { __typename: 'StoppedRunCondition' } | { __typename: 'ScriptableRunCondition' }, subscriptions: Array<(
        { __typename: 'EmailSubscription' }
        & Pick<EmailSubscription, 'id'>
      ) | (
        { __typename: 'SlackChannelSubscription' }
        & Pick<SlackChannelSubscription, 'id'>
      )> }
    ) }
  )> }
);

export type CreateStoppedRunAlertMutationVariables = Exact<{
  entityName: Scalars['String'];
  minimumRunDuration: Scalars['Duration'];
}>;


export type CreateStoppedRunAlertMutation = (
  { __typename: 'Mutation' }
  & { createStoppedRunAlert?: Maybe<(
    { __typename: 'CreateAlertPayload' }
    & { alert: (
      { __typename: 'Alert' }
      & Pick<Alert, 'id'>
      & { condition: { __typename: 'FinishedRunCondition' } | (
        { __typename: 'StoppedRunCondition' }
        & Pick<StoppedRunCondition, 'minimumRunDuration'>
      ) | { __typename: 'ScriptableRunCondition' }, subscriptions: Array<(
        { __typename: 'EmailSubscription' }
        & Pick<EmailSubscription, 'id'>
      ) | (
        { __typename: 'SlackChannelSubscription' }
        & Pick<SlackChannelSubscription, 'id'>
      )> }
    ) }
  )> }
);

export type UpdateStoppedRunAlertMutationVariables = Exact<{
  id: Scalars['ID'];
  minimumRunDuration?: Maybe<Scalars['Duration']>;
}>;


export type UpdateStoppedRunAlertMutation = (
  { __typename: 'Mutation' }
  & { updateStoppedRunAlert?: Maybe<(
    { __typename: 'UpdateAlertPayload' }
    & { alert: (
      { __typename: 'Alert' }
      & Pick<Alert, 'id'>
      & { condition: { __typename: 'FinishedRunCondition' } | (
        { __typename: 'StoppedRunCondition' }
        & Pick<StoppedRunCondition, 'minimumRunDuration'>
      ) | { __typename: 'ScriptableRunCondition' }, subscriptions: Array<(
        { __typename: 'EmailSubscription' }
        & Pick<EmailSubscription, 'id'>
      ) | (
        { __typename: 'SlackChannelSubscription' }
        & Pick<SlackChannelSubscription, 'id'>
      )> }
    ) }
  )> }
);

export type CreateScriptableRunAlertMutationVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type CreateScriptableRunAlertMutation = (
  { __typename: 'Mutation' }
  & { createScriptableRunAlert?: Maybe<(
    { __typename: 'CreateAlertPayload' }
    & { alert: (
      { __typename: 'Alert' }
      & Pick<Alert, 'id'>
      & { condition: { __typename: 'FinishedRunCondition' } | { __typename: 'StoppedRunCondition' } | (
        { __typename: 'ScriptableRunCondition' }
        & Pick<ScriptableRunCondition, 'success'>
      ), subscriptions: Array<(
        { __typename: 'EmailSubscription' }
        & Pick<EmailSubscription, 'id'>
      ) | (
        { __typename: 'SlackChannelSubscription' }
        & Pick<SlackChannelSubscription, 'id'>
      )> }
    ) }
  )> }
);

export type DeleteAlertMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteAlertMutation = (
  { __typename: 'Mutation' }
  & { deleteAlert?: Maybe<(
    { __typename: 'DeleteAlertPayload' }
    & Pick<DeleteAlertPayload, 'success'>
  )> }
);

export type CreateSlackChannelSubscriptionMutationVariables = Exact<{
  alertID: Scalars['ID'];
  integrationID: Scalars['ID'];
}>;


export type CreateSlackChannelSubscriptionMutation = (
  { __typename: 'Mutation' }
  & { createSlackChannelSubscription?: Maybe<(
    { __typename: 'CreateSlackChannelSubscriptionPayload' }
    & { subscription: (
      { __typename: 'SlackChannelSubscription' }
      & Pick<SlackChannelSubscription, 'id'>
    ) }
  )> }
);

export type CreateEmailSubscriptionMutationVariables = Exact<{
  alertID: Scalars['ID'];
}>;


export type CreateEmailSubscriptionMutation = (
  { __typename: 'Mutation' }
  & { createEmailSubscription?: Maybe<(
    { __typename: 'CreateEmailSubscriptionPayload' }
    & { subscription: (
      { __typename: 'EmailSubscription' }
      & Pick<EmailSubscription, 'id'>
    ) }
  )> }
);

export type CreateViewCommentsAlertSubscriptionMutationVariables = Exact<{
  viewID: Scalars['ID'];
}>;


export type CreateViewCommentsAlertSubscriptionMutation = (
  { __typename: 'Mutation' }
  & { createViewCommentsAlertSubscription?: Maybe<(
    { __typename: 'CreateEmailSubscriptionPayload' }
    & { subscription: (
      { __typename: 'EmailSubscription' }
      & Pick<EmailSubscription, 'id'>
    ) }
  )> }
);

export type DeleteAlertSubscriptionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteAlertSubscriptionMutation = (
  { __typename: 'Mutation' }
  & { deleteAlertSubscription?: Maybe<(
    { __typename: 'DeleteAlertSubscriptionPayload' }
    & Pick<DeleteAlertSubscriptionPayload, 'success'>
  )> }
);

export type BenchmarkProjectQueryVariables = Exact<{
  name: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
}>;


export type BenchmarkProjectQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'access' | 'name' | 'description' | 'readOnly' | 'entityName' | 'views' | 'gitHubSubmissionRepo'>
    & { acceptedBenchmarkRuns?: Maybe<(
      { __typename: 'BenchmarkRunConnection' }
      & BenchmarkRunsFragmentFragment
    )>, rejectedBenchmarkRuns?: Maybe<(
      { __typename: 'BenchmarkRunConnection' }
      & BenchmarkRunsFragmentFragment
    )>, submittedBenchmarkRuns?: Maybe<(
      { __typename: 'BenchmarkRunConnection' }
      & BenchmarkRunsFragmentFragment
    )>, entity: (
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'name' | 'photoUrl' | 'defaultAccess' | 'readOnlyAdmin' | 'privateOnly' | 'isTeam'>
    ) }
  )> }
);

export type BenchmarkRunsFragmentFragment = (
  { __typename: 'BenchmarkRunConnection' }
  & { edges: Array<(
    { __typename: 'BenchmarkRunEdge' }
    & { node?: Maybe<(
      { __typename: 'BenchmarkRun' }
      & Pick<BenchmarkRun, 'id' | 'gitHubSubmissionPR' | 'createdAt' | 'details' | 'results' | 'state'>
      & { user: (
        { __typename: 'User' }
        & Pick<User, 'id' | 'username' | 'photoUrl' | 'admin'>
      ), originalRun?: Maybe<(
        { __typename: 'Run' }
        & Pick<Run, 'id' | 'name' | 'displayName'>
        & { project?: Maybe<(
          { __typename: 'Project' }
          & Pick<Project, 'id' | 'name' | 'entityName'>
        )> }
      )>, originalProject?: Maybe<(
        { __typename: 'Project' }
        & Pick<Project, 'id' | 'name' | 'entityName'>
      )>, run: (
        { __typename: 'Run' }
        & Pick<Run, 'github' | 'commit' | 'id' | 'name' | 'displayName' | 'notes' | 'summaryMetrics'>
        & { user?: Maybe<(
          { __typename: 'User' }
          & Pick<User, 'id' | 'username'>
        )>, diffPatchFile?: Maybe<(
          { __typename: 'FileConnection' }
          & { edges: Array<(
            { __typename: 'FileEdge' }
            & { node?: Maybe<(
              { __typename: 'File' }
              & Pick<File, 'id' | 'name' | 'md5' | 'sizeBytes'>
            )> }
          )> }
        )> }
      ), benchmark: (
        { __typename: 'Project' }
        & Pick<Project, 'id' | 'name' | 'entityName'>
      ) }
    )> }
  )> }
);

export type SubmitBenchmarkRunMutationVariables = Exact<{
  id: Scalars['ID'];
  isCodeHidden?: Maybe<Scalars['Boolean']>;
  benchmarkEntityName?: Maybe<Scalars['String']>;
  benchmarkName?: Maybe<Scalars['String']>;
}>;


export type SubmitBenchmarkRunMutation = (
  { __typename: 'Mutation' }
  & { submitBenchmarkRun?: Maybe<(
    { __typename: 'SubmitBenchmarkRunPayload' }
    & { benchmarkRun: (
      { __typename: 'BenchmarkRun' }
      & Pick<BenchmarkRun, 'id' | 'results'>
    ) }
  )> }
);

export type PublishBenchmarkRunMutationVariables = Exact<{
  id: Scalars['ID'];
  isCodeHidden?: Maybe<Scalars['Boolean']>;
  benchmarkEntityName?: Maybe<Scalars['String']>;
  benchmarkName?: Maybe<Scalars['String']>;
}>;


export type PublishBenchmarkRunMutation = (
  { __typename: 'Mutation' }
  & { publishBenchmarkRun?: Maybe<(
    { __typename: 'PublishBenchmarkRunPayload' }
    & { benchmarkRun: (
      { __typename: 'BenchmarkRun' }
      & Pick<BenchmarkRun, 'id' | 'gitHubSubmissionPR'>
    ) }
  )> }
);

export type UpdateBenchmarkRunMutationVariables = Exact<{
  id: Scalars['ID'];
  state?: Maybe<Scalars['String']>;
  details?: Maybe<Scalars['JSONString']>;
}>;


export type UpdateBenchmarkRunMutation = (
  { __typename: 'Mutation' }
  & { updateBenchmarkRun?: Maybe<(
    { __typename: 'UpdateBenchmarkRunPayload' }
    & { benchmarkRun: (
      { __typename: 'BenchmarkRun' }
      & Pick<BenchmarkRun, 'id' | 'state' | 'details'>
    ) }
  )> }
);

export type CreateReportsPromptQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type CreateReportsPromptQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { runs?: Maybe<(
      { __typename: 'RunConnection' }
      & { edges: Array<(
        { __typename: 'RunEdge' }
        & { node: (
          { __typename: 'Run' }
          & Pick<Run, 'id' | 'createdAt' | 'updatedAt'>
          & { project?: Maybe<(
            { __typename: 'Project' }
            & Pick<Project, 'id' | 'name' | 'entityName'>
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type CustomChartFragmentFragment = (
  { __typename: 'CustomChart' }
  & Pick<CustomChart, 'id' | 'name' | 'displayName' | 'spec' | 'access'>
  & { entity: (
    { __typename: 'Entity' }
    & Pick<Entity, 'id' | 'name' | 'readOnly'>
  ), user: (
    { __typename: 'User' }
    & Pick<User, 'id' | 'username'>
  ) }
);

export type CreateCustomChartMutationVariables = Exact<{
  entity: Scalars['String'];
  name: Scalars['String'];
  displayName: Scalars['String'];
  type: Scalars['String'];
  access: Scalars['String'];
  spec: Scalars['JSONString'];
}>;


export type CreateCustomChartMutation = (
  { __typename: 'Mutation' }
  & { createCustomChart?: Maybe<(
    { __typename: 'CreateCustomChartPayload' }
    & { chart: (
      { __typename: 'CustomChart' }
      & Pick<CustomChart, 'id'>
      & CustomChartFragmentFragment
    ) }
  )> }
);

export type UpdateCustomChartMutationVariables = Exact<{
  entity: Scalars['String'];
  name: Scalars['String'];
  displayName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  access?: Maybe<Scalars['String']>;
  spec?: Maybe<Scalars['JSONString']>;
}>;


export type UpdateCustomChartMutation = (
  { __typename: 'Mutation' }
  & { updateCustomChart?: Maybe<(
    { __typename: 'UpdateCustomChartPayload' }
    & { chart: (
      { __typename: 'CustomChart' }
      & Pick<CustomChart, 'id'>
      & CustomChartFragmentFragment
    ) }
  )> }
);

export type DeleteCustomChartMutationVariables = Exact<{
  entity: Scalars['String'];
  name: Scalars['String'];
}>;


export type DeleteCustomChartMutation = (
  { __typename: 'Mutation' }
  & { deleteCustomChart?: Maybe<(
    { __typename: 'DeleteCustomChartPayload' }
    & Pick<DeleteCustomChartPayload, 'success'>
  )> }
);

export type CustomChartsQueryVariables = Exact<{
  entity: Scalars['String'];
}>;


export type CustomChartsQuery = (
  { __typename: 'Query' }
  & { customCharts?: Maybe<(
    { __typename: 'CustomChartConnection' }
    & { edges: Array<(
      { __typename: 'CustomChartEdge' }
      & { node: (
        { __typename: 'CustomChart' }
        & Pick<CustomChart, 'id'>
        & CustomChartFragmentFragment
      ) }
    )> }
  )> }
);

export type CustomChartQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CustomChartQuery = (
  { __typename: 'Query' }
  & { customChart?: Maybe<(
    { __typename: 'CustomChart' }
    & Pick<CustomChart, 'id'>
    & CustomChartFragmentFragment
  )> }
);

export type CreateCustomerSubscriptionMutationVariables = Exact<{
  planId: Scalars['ID'];
  newOrganizationName: Scalars['String'];
  paymentMethod: Scalars['String'];
  quantity: Scalars['Int'];
  trial: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type CreateCustomerSubscriptionMutation = (
  { __typename: 'Mutation' }
  & { createCustomerSubscription?: Maybe<(
    { __typename: 'CreateCustomerSubscriptionPayload' }
    & Pick<CreateCustomerSubscriptionPayload, 'subscription' | 'organizationId' | 'clientMutationId'>
  )> }
);

export type CreateAcademicOrReverseTrialMutationVariables = Exact<{
  newOrganizationName: Scalars['String'];
  newTeamName: Scalars['String'];
  isAcademic: Scalars['Boolean'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type CreateAcademicOrReverseTrialMutation = (
  { __typename: 'Mutation' }
  & { createAcademicOrReverseTrial?: Maybe<(
    { __typename: 'CreateAcademicOrReverseTrialPayload' }
    & Pick<CreateAcademicOrReverseTrialPayload, 'clientMutationId' | 'orgID'>
  )> }
);

export type CreateCustomerTrialMutationVariables = Exact<{
  planId: Scalars['ID'];
  userName: Scalars['String'];
  newOrganizationName: Scalars['String'];
  quantity: Scalars['Int'];
  trialDays: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type CreateCustomerTrialMutation = (
  { __typename: 'Mutation' }
  & { createCustomerTrial?: Maybe<(
    { __typename: 'CreateCustomerTrialPayload' }
    & Pick<CreateCustomerTrialPayload, 'subscription' | 'clientMutationId'>
  )> }
);

export type CreateUserLedTrialMutationVariables = Exact<{
  newOrganizationName: Scalars['String'];
  newTeamName: Scalars['String'];
  orgSize: Scalars['String'];
  isAcademic: Scalars['Boolean'];
  justification?: Maybe<Scalars['String']>;
  allowJoinMatchingEmailDomain?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type CreateUserLedTrialMutation = (
  { __typename: 'Mutation' }
  & { createUserLedTrial?: Maybe<(
    { __typename: 'CreateUserLedTrialPayload' }
    & Pick<CreateUserLedTrialPayload, 'clientMutationId' | 'orgID'>
  )> }
);

export type CancelCustomerSubscriptionMutationVariables = Exact<{
  organizationId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type CancelCustomerSubscriptionMutation = (
  { __typename: 'Mutation' }
  & { cancelCustomerSubscription?: Maybe<(
    { __typename: 'CancelCustomerSubscriptionPayload' }
    & Pick<CancelCustomerSubscriptionPayload, 'success' | 'clientMutationId'>
  )> }
);

export type UpdateCustomerPaymentMethodMutationVariables = Exact<{
  organizationId: Scalars['ID'];
  paymentMethod: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type UpdateCustomerPaymentMethodMutation = (
  { __typename: 'Mutation' }
  & { updateCustomerPaymentMethod?: Maybe<(
    { __typename: 'UpdateCustomerPaymentMethodPayload' }
    & Pick<UpdateCustomerPaymentMethodPayload, 'success' | 'clientMutationId'>
  )> }
);

export type AttachCustomerPaymentMethodMutationVariables = Exact<{
  organizationId: Scalars['ID'];
  paymentMethod: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type AttachCustomerPaymentMethodMutation = (
  { __typename: 'Mutation' }
  & { attachCustomerPaymentMethod?: Maybe<(
    { __typename: 'AttachCustomerPaymentMethodPayload' }
    & Pick<AttachCustomerPaymentMethodPayload, 'success' | 'clientMutationId'>
  )> }
);

export type UpdateCustomerDefaultPaymentMethodMutationVariables = Exact<{
  organizationId: Scalars['ID'];
  paymentMethod: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type UpdateCustomerDefaultPaymentMethodMutation = (
  { __typename: 'Mutation' }
  & { updateCustomerDefaultPaymentMethod?: Maybe<(
    { __typename: 'UpdateCustomerDefaultPaymentMethodPayload' }
    & Pick<UpdateCustomerDefaultPaymentMethodPayload, 'success' | 'clientMutationId'>
  )> }
);

export type DetachCustomerPaymentMethodMutationVariables = Exact<{
  organizationId: Scalars['ID'];
  paymentMethod: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type DetachCustomerPaymentMethodMutation = (
  { __typename: 'Mutation' }
  & { detachCustomerPaymentMethod?: Maybe<(
    { __typename: 'DetachCustomerPaymentMethodPayload' }
    & Pick<DetachCustomerPaymentMethodPayload, 'success' | 'clientMutationId'>
  )> }
);

export type RetryStripeOpenInvoicesByOrganizationMutationVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type RetryStripeOpenInvoicesByOrganizationMutation = (
  { __typename: 'Mutation' }
  & { retryStripeOpenInvoicesByOrganization?: Maybe<(
    { __typename: 'RetryStripeOpenInvoicesByOrganizationPayload' }
    & Pick<RetryStripeOpenInvoicesByOrganizationPayload, 'success' | 'clientMutationId'>
  )> }
);

export type CustomerSubscriptionInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type CustomerSubscriptionInfoQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { organizations: Array<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'orgType' | 'usedSeats'>
      & { stripeBillingInfo?: Maybe<(
        { __typename: 'StripeBillingInfo' }
        & Pick<StripeBillingInfo, 'status' | 'cancelAtPeriodEnd' | 'currentPeriodEnd' | 'invoiceLink'>
        & { paymentMethod?: Maybe<(
          { __typename: 'PaymentMethod' }
          & Pick<PaymentMethod, 'id' | 'type' | 'cardType' | 'endingIn'>
        )> }
      )>, billingUser?: Maybe<(
        { __typename: 'User' }
        & Pick<User, 'id' | 'username'>
      )>, subscriptions: Array<(
        { __typename: 'OrganizationSubscription' }
        & Pick<OrganizationSubscription, 'id' | 'privileges' | 'seats' | 'expiresAt' | 'subscriptionType' | 'status' | 'billingPeriodStart' | 'billingPeriodEnd'>
        & { plan: (
          { __typename: 'Plan' }
          & Pick<Plan, 'id' | 'name' | 'displayName' | 'unitPrice' | 'billingInterval' | 'planType'>
        ) }
      )>, teams: Array<(
        { __typename: 'Entity' }
        & Pick<Entity, 'id' | 'name' | 'storageBytes' | 'referenceBytes' | 'computeHours'>
      )>, members: Array<(
        { __typename: 'OrganizationMember' }
        & Pick<OrganizationMember, 'orgID' | 'id' | 'username' | 'name' | 'photoUrl' | 'admin' | 'role'>
        & { teams?: Maybe<(
          { __typename: 'EntityConnection' }
          & { edges: Array<(
            { __typename: 'EntityEdge' }
            & { node?: Maybe<(
              { __typename: 'Entity' }
              & Pick<Entity, 'id' | 'name'>
            )> }
          )> }
        )> }
      )>, pendingInvites: Array<(
        { __typename: 'Invite' }
        & Pick<Invite, 'id' | 'email'>
      )> }
    )> }
  )> }
);

export type OrganizationSubscriptionInfoQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type OrganizationSubscriptionInfoQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'orgType' | 'flags' | 'usedSeats' | 'usedViewOnlySeats' | 'createdAt'>
    & { seatAvailability: (
      { __typename: 'OrganizationSeatAvailability' }
      & Pick<OrganizationSeatAvailability, 'seats' | 'viewOnlySeats'>
    ), stripeBillingInfo?: Maybe<(
      { __typename: 'StripeBillingInfo' }
      & Pick<StripeBillingInfo, 'status' | 'cancelAtPeriodEnd' | 'currentPeriodEnd' | 'invoiceLink'>
      & { paymentMethod?: Maybe<(
        { __typename: 'PaymentMethod' }
        & Pick<PaymentMethod, 'id' | 'type' | 'cardType' | 'endingIn'>
      )>, paymentMetadata?: Maybe<(
        { __typename: 'PaymentMetadata' }
        & Pick<PaymentMetadata, 'shouldUpgradeToTeams'>
      )> }
    )>, stripePaymentMethods: Array<(
      { __typename: 'StripePaymentMethod' }
      & Pick<StripePaymentMethod, 'stripePaymentMethodID' | 'type' | 'isDefault' | 'isFailed'>
      & { card: (
        { __typename: 'StripePaymentMethodCard' }
        & Pick<StripePaymentMethodCard, 'last4' | 'brand'>
      ) }
    )>, stripeInvoices: Array<(
      { __typename: 'StripeInvoice' }
      & Pick<StripeInvoice, 'number' | 'created' | 'total' | 'status' | 'currency' | 'hostedInvoiceURL' | 'invoicePDF'>
      & { subscription?: Maybe<(
        { __typename: 'StripeSubscription' }
        & Pick<StripeSubscription, 'status'>
        & { organizationSubscriptions: Array<(
          { __typename: 'OrganizationSubscription' }
          & Pick<OrganizationSubscription, 'id'>
          & { plan: (
            { __typename: 'Plan' }
            & Pick<Plan, 'id' | 'displayName'>
          ) }
        )> }
      )> }
    )>, billingUser?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'username' | 'name' | 'email' | 'photoUrl' | 'stripeCustomerId'>
    )>, teams: Array<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'name' | 'memberCount' | 'photoUrl'>
    )>, subscriptions: Array<(
      { __typename: 'OrganizationSubscription' }
      & Pick<OrganizationSubscription, 'id' | 'privileges' | 'seats' | 'expiresAt' | 'subscriptionType' | 'status' | 'availableSeatsToPurchase' | 'isAutomaticUpgrade' | 'thresholdCrossedAt' | 'upgradedAt' | 'billingPeriodStart' | 'billingPeriodEnd'>
      & { plan: (
        { __typename: 'Plan' }
        & Pick<Plan, 'id' | 'name' | 'unitPrice' | 'billingInterval' | 'planType' | 'maxSeats' | 'displayName' | 'defaultPrivileges'>
        & { stripePrice?: Maybe<(
          { __typename: 'StripePrice' }
          & Pick<StripePrice, 'amount'>
        )> }
      ), nextPlans: Array<(
        { __typename: 'Plan' }
        & Pick<Plan, 'id' | 'name' | 'displayName' | 'billingInterval' | 'defaultPrivileges'>
        & { stripePrice?: Maybe<(
          { __typename: 'StripePrice' }
          & Pick<StripePrice, 'amount'>
        )> }
      )> }
    )>, members: Array<(
      { __typename: 'OrganizationMember' }
      & Pick<OrganizationMember, 'orgID' | 'id' | 'username' | 'name' | 'photoUrl' | 'admin' | 'email' | 'role' | 'modelsSeat'>
      & { user: (
        { __typename: 'User' }
        & Pick<User, 'id' | 'loggedInAt'>
      ), teams?: Maybe<(
        { __typename: 'EntityConnection' }
        & { edges: Array<(
          { __typename: 'EntityEdge' }
          & { node?: Maybe<(
            { __typename: 'Entity' }
            & Pick<Entity, 'id' | 'name'>
          )> }
        )> }
      )> }
    )>, pendingInvites: Array<(
      { __typename: 'Invite' }
      & Pick<Invite, 'id' | 'email' | 'role' | 'createdAt'>
      & { entity?: Maybe<(
        { __typename: 'Entity' }
        & Pick<Entity, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type UpgradeCustomerSubscriptionMutationVariables = Exact<{
  planID: Scalars['ID'];
  organizationID: Scalars['ID'];
  paymentMethod: Scalars['String'];
  quantity: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type UpgradeCustomerSubscriptionMutation = (
  { __typename: 'Mutation' }
  & { upgradeCustomerSubscription?: Maybe<(
    { __typename: 'UpgradeCustomerSubscriptionPayload' }
    & Pick<UpgradeCustomerSubscriptionPayload, 'subscription' | 'organizationID' | 'clientMutationId'>
  )> }
);

export type UpgradeCustomerToProPlanMutationVariables = Exact<{
  planID: Scalars['ID'];
  organizationID: Scalars['ID'];
  paymentMethod: Scalars['String'];
  modelsSeats: Scalars['Int'];
  weaveIngestionTier: WeaveIngestionLimitInMb;
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type UpgradeCustomerToProPlanMutation = (
  { __typename: 'Mutation' }
  & { upgradeCustomerToProPlan?: Maybe<(
    { __typename: 'upgradeCustomerToProPlanPayload' }
    & Pick<UpgradeCustomerToProPlanPayload, 'subscriptionUsage' | 'subscriptionCommit' | 'organizationID' | 'clientMutationId'>
  )> }
);

export type CreateHourOverageSubscriptionMutationVariables = Exact<{
  organizationId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type CreateHourOverageSubscriptionMutation = (
  { __typename: 'Mutation' }
  & { createHourOverageSubscription?: Maybe<(
    { __typename: 'CreateHourOverageSubscriptionPayload' }
    & Pick<CreateHourOverageSubscriptionPayload, 'subscription' | 'clientMutationId'>
  )> }
);

export type UpgradeTrialSubscriptionMutationVariables = Exact<{
  organizationID: Scalars['ID'];
  planID: Scalars['ID'];
}>;


export type UpgradeTrialSubscriptionMutation = (
  { __typename: 'Mutation' }
  & { upgradeTrialSubscription?: Maybe<(
    { __typename: 'UpgradeTrialSubscriptionPayload' }
    & Pick<UpgradeTrialSubscriptionPayload, 'success' | 'clientMutationId'>
  )> }
);

export type BenchmarkDiscussionThreadsQueryVariables = Exact<{
  benchmarkEntityName: Scalars['String'];
  benchmarkProjectName: Scalars['String'];
}>;


export type BenchmarkDiscussionThreadsQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { benchmarkDiscussionThreads: (
      { __typename: 'DiscussionThreadConnection' }
      & Pick<DiscussionThreadConnection, 'totalCount'>
      & { pageInfo: (
        { __typename: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage'>
      ), edges: Array<(
        { __typename: 'DiscussionThreadEdge' }
        & { node: (
          { __typename: 'DiscussionThread' }
          & Pick<DiscussionThread, 'id' | 'createdAt' | 'updatedAt' | 'title' | 'body' | 'upvotes'>
          & { poster: (
            { __typename: 'User' }
            & Pick<User, 'id' | 'photoUrl' | 'name'>
          ), comments: (
            { __typename: 'DiscussionCommentConnection' }
            & Pick<DiscussionCommentConnection, 'totalCount'>
          ) }
        ) }
      )> }
    ) }
  )> }
);

export type CreateBenchmarkDiscussionThreadMutationVariables = Exact<{
  benchmarkEntityName: Scalars['String'];
  benchmarkProjectName: Scalars['String'];
  title: Scalars['String'];
  body: Scalars['String'];
}>;


export type CreateBenchmarkDiscussionThreadMutation = (
  { __typename: 'Mutation' }
  & { createBenchmarkDiscussionThread?: Maybe<(
    { __typename: 'CreateDiscussionThreadPayload' }
    & { discussionThread: (
      { __typename: 'DiscussionThread' }
      & Pick<DiscussionThread, 'id' | 'createdAt' | 'updatedAt' | 'title' | 'body' | 'upvotes'>
      & { poster: (
        { __typename: 'User' }
        & Pick<User, 'id' | 'photoUrl' | 'name'>
      ) }
    ) }
  )> }
);

export type UpdateDiscussionThreadMutationVariables = Exact<{
  discussionThreadID: Scalars['ID'];
  body: Scalars['String'];
}>;


export type UpdateDiscussionThreadMutation = (
  { __typename: 'Mutation' }
  & { updateDiscussionThread?: Maybe<(
    { __typename: 'UpdateDiscussionThreadPayload' }
    & { discussionThread: (
      { __typename: 'DiscussionThread' }
      & Pick<DiscussionThread, 'id' | 'createdAt' | 'updatedAt' | 'title' | 'body' | 'upvotes'>
      & { poster: (
        { __typename: 'User' }
        & Pick<User, 'id' | 'photoUrl' | 'name'>
      ) }
    ) }
  )> }
);

export type CreateBenchmarkDiscussionCommentMutationVariables = Exact<{
  discussionThreadID: Scalars['ID'];
  parentCommentID?: Maybe<Scalars['ID']>;
  body: Scalars['String'];
}>;


export type CreateBenchmarkDiscussionCommentMutation = (
  { __typename: 'Mutation' }
  & { createBenchmarkDiscussionComment?: Maybe<(
    { __typename: 'CreateDiscussionCommentPayload' }
    & { discussionComment: (
      { __typename: 'DiscussionComment' }
      & Pick<DiscussionComment, 'id' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'body' | 'isParent'>
      & { poster: (
        { __typename: 'User' }
        & Pick<User, 'id' | 'photoUrl' | 'name'>
      ) }
    ) }
  )> }
);

export type UpdateDiscussionCommentMutationVariables = Exact<{
  viewID?: Maybe<Scalars['ID']>;
  discussionCommentID: Scalars['ID'];
  body: Scalars['String'];
  inlineCommentDetails?: Maybe<Scalars['JSONString']>;
}>;


export type UpdateDiscussionCommentMutation = (
  { __typename: 'Mutation' }
  & { updateDiscussionComment?: Maybe<(
    { __typename: 'UpdateDiscussionCommentPayload' }
    & { discussionComment: (
      { __typename: 'DiscussionComment' }
      & Pick<DiscussionComment, 'id' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'body' | 'isParent'>
      & { poster: (
        { __typename: 'User' }
        & Pick<User, 'id' | 'photoUrl' | 'name' | 'username'>
      ) }
    ) }
  )> }
);

export type DeleteDiscussionCommentMutationVariables = Exact<{
  id: Scalars['ID'];
  deleteThread?: Maybe<Scalars['Boolean']>;
}>;


export type DeleteDiscussionCommentMutation = (
  { __typename: 'Mutation' }
  & { deleteDiscussionComment?: Maybe<(
    { __typename: 'DeleteDiscussionCommentPayload' }
    & Pick<DeleteDiscussionCommentPayload, 'success'>
  )> }
);

export type ViewDiscussionThreadsQueryVariables = Exact<{
  viewID: Scalars['ID'];
}>;


export type ViewDiscussionThreadsQuery = (
  { __typename: 'Query' }
  & { view?: Maybe<(
    { __typename: 'View' }
    & Pick<View, 'id'>
    & { discussionThreads: (
      { __typename: 'DiscussionThreadConnection' }
      & Pick<DiscussionThreadConnection, 'totalCount'>
      & { pageInfo: (
        { __typename: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage'>
      ), edges: Array<(
        { __typename: 'DiscussionThreadEdge' }
        & { node: (
          { __typename: 'DiscussionThread' }
          & Pick<DiscussionThread, 'id' | 'createdAt' | 'updatedAt' | 'title' | 'body' | 'upvotes'>
          & { poster: (
            { __typename: 'User' }
            & Pick<User, 'id' | 'photoUrl' | 'name' | 'username'>
          ), comments: (
            { __typename: 'DiscussionCommentConnection' }
            & Pick<DiscussionCommentConnection, 'totalCount'>
            & { pageInfo: (
              { __typename: 'PageInfo' }
              & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage'>
            ), edges: Array<(
              { __typename: 'DiscussionCommentEdge' }
              & { node: (
                { __typename: 'DiscussionComment' }
                & Pick<DiscussionComment, 'id' | 'createdAt' | 'updatedAt' | 'body'>
                & { poster: (
                  { __typename: 'User' }
                  & Pick<User, 'id' | 'photoUrl' | 'name' | 'username'>
                ) }
              ) }
            )> }
          ) }
        ) }
      )> }
    ) }
  )> }
);

export type CreateViewDiscussionCommentMutationVariables = Exact<{
  viewID: Scalars['ID'];
  discussionThreadID?: Maybe<Scalars['ID']>;
  body: Scalars['String'];
  notifyAllSubscribers?: Maybe<Scalars['Boolean']>;
  inlineCommentDetails?: Maybe<Scalars['JSONString']>;
}>;


export type CreateViewDiscussionCommentMutation = (
  { __typename: 'Mutation' }
  & { createViewDiscussionComment?: Maybe<(
    { __typename: 'CreateViewDiscussionCommentPayload' }
    & { discussionThread: (
      { __typename: 'DiscussionThread' }
      & Pick<DiscussionThread, 'id' | 'createdAt' | 'updatedAt' | 'title' | 'body' | 'upvotes'>
      & { poster: (
        { __typename: 'User' }
        & Pick<User, 'id' | 'photoUrl' | 'name' | 'username'>
      ) }
    ), discussionComment: (
      { __typename: 'DiscussionComment' }
      & Pick<DiscussionComment, 'id' | 'createdAt' | 'updatedAt' | 'body'>
      & { poster: (
        { __typename: 'User' }
        & Pick<User, 'id' | 'photoUrl' | 'name' | 'username'>
      ) }
    ), alertSubscription: (
      { __typename: 'EmailSubscription' }
      & Pick<EmailSubscription, 'id'>
    ) | (
      { __typename: 'SlackChannelSubscription' }
      & Pick<SlackChannelSubscription, 'id'>
    ) }
  )> }
);

export type DismissedBannerQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DismissedBannerQuery = (
  { __typename: 'Query' }
  & { dismissedBanner?: Maybe<(
    { __typename: 'DismissedBanner' }
    & Pick<DismissedBanner, 'id' | 'dismissedAt'>
  )> }
);

export type SetDismissedBannerMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SetDismissedBannerMutation = (
  { __typename: 'Mutation' }
  & { setDismissedBanner?: Maybe<(
    { __typename: 'SetDismissedBannerPayload' }
    & Pick<SetDismissedBannerPayload, 'success'>
  )> }
);

export type ResendEmailVerificationMutationVariables = Exact<{
  email: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type ResendEmailVerificationMutation = (
  { __typename: 'Mutation' }
  & { resendEmailVerification?: Maybe<(
    { __typename: 'ResendEmailVerificationPayload' }
    & Pick<ResendEmailVerificationPayload, 'success' | 'clientMutationId'>
  )> }
);

export type EntityPageQueryVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type EntityPageQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id' | 'isTeam' | 'entityType' | 'available' | 'name'>
    & { claimingEntity?: Maybe<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'name'>
    )>, members: Array<(
      { __typename: 'Member' }
      & Pick<Member, 'id' | 'name' | 'username' | 'email' | 'photoUrl' | 'pending' | 'accountType' | 'role'>
    )> }
  )> }
);

export type EntityPageUserStatsQueryVariables = Exact<{
  entityName: Scalars['String'];
  userStatsStartDate: Scalars['DateTime'];
}>;


export type EntityPageUserStatsQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id'>
    & { userStats?: Maybe<(
      { __typename: 'EntityUserConnection' }
      & { edges: Array<(
        { __typename: 'EntityUserEdge' }
        & { node?: Maybe<(
          { __typename: 'EntityUser' }
          & Pick<EntityUser, 'id' | 'runCount'>
          & { user: (
            { __typename: 'User' }
            & Pick<User, 'id' | 'name' | 'username' | 'photoUrl' | 'accountType'>
          ) }
        )> }
      )> }
    )> }
  )> }
);

export type EntityRunsQueryVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type EntityRunsQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id'>
    & { latestRuns?: Maybe<(
      { __typename: 'RunConnection' }
      & { edges: Array<(
        { __typename: 'RunEdge' }
        & { node: (
          { __typename: 'Run' }
          & Pick<Run, 'id'>
          & RunsTableFragmentFragment
        ) }
      )> }
    )> }
  )> }
);

export type EntitySettingsQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type EntitySettingsQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id'>
    & { settings: (
      { __typename: 'EntitySettings' }
      & Pick<EntitySettings, 'openToMatchingOrgEmailDomainUsers' | 'entityProfileEnabled' | 'hideActivityCalendar' | 'membersCanInvite' | 'disableMagicLinkSharing' | 'disableMembersEditArtifactTTL' | 'hidden'>
      & { defaultCloudRegion: (
        { __typename: 'CloudRegion' }
        & Pick<CloudRegion, 'id' | 'provider' | 'region'>
      ) }
    ) }
  )> }
);

export type EntityStorageQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
  enableReferenceTracking?: Maybe<Scalars['Boolean']>;
}>;


export type EntityStorageQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id' | 'storageBytes' | 'referenceBytes' | 'computeHours'>
    & { storage?: Maybe<(
      { __typename: 'EntityStorageNode' }
      & Pick<EntityStorageNode, 'id' | 'size'>
    )> }
  )> }
);

export type CachedEntityStorageInfoQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
}>;


export type CachedEntityStorageInfoQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id' | 'storageBytes'>
  )> }
);

export type ArtifactNodeFragment = (
  { __typename: 'ArtifactSequenceStorageNode' }
  & Pick<ArtifactSequenceStorageNode, 'id' | 'name' | 'size' | 'artifactSequenceID'>
  & { artifactType: (
    { __typename: 'ArtifactType' }
    & Pick<ArtifactType, 'id' | 'name'>
  ) }
);

export type RunNodeFragment = (
  { __typename: 'RunStorageNode' }
  & Pick<RunStorageNode, 'id' | 'name' | 'size' | 'runID'>
);

export type ArtifactCollectionStorageTreeQueryVariables = Exact<{
  entityName: Scalars['String'];
  enableReferenceTracking: Scalars['Boolean'];
  artifactCollectionID: Scalars['ID'];
}>;


export type ArtifactCollectionStorageTreeQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id'>
    & { artifactCollectionStorageTree?: Maybe<(
      { __typename: 'ArtifactStorageNodeConnection' }
      & { edges: Array<(
        { __typename: 'ArtifactStorageNodeEdge' }
        & { node: (
          { __typename: 'ArtifactStorageNode' }
          & Pick<ArtifactStorageNode, 'id' | 'name' | 'size' | 'artifactID'>
        ) }
      )> }
    )> }
  )> }
);

export type EntityStorageTreeQueryVariables = Exact<{
  entityName: Scalars['String'];
  enableReferenceTracking: Scalars['Boolean'];
  query?: Maybe<Scalars['String']>;
  cached?: Maybe<Scalars['Boolean']>;
}>;


export type EntityStorageTreeQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id'>
    & { storageTree?: Maybe<(
      { __typename: 'EntityStorageNode' }
      & Pick<EntityStorageNode, 'id' | 'name' | 'size' | 'lastUpdatedAt'>
      & { projects: (
        { __typename: 'ProjectStorageNodeConnection' }
        & Pick<ProjectStorageNodeConnection, 'totalSize'>
        & { edges: Array<(
          { __typename: 'ProjectStorageNodeEdge' }
          & { node: (
            { __typename: 'ProjectStorageNode' }
            & Pick<ProjectStorageNode, 'id' | 'name' | 'size' | 'projectID'>
            & { runs: (
              { __typename: 'RunStorageNodeConnection' }
              & Pick<RunStorageNodeConnection, 'totalSize'>
              & { edges: Array<(
                { __typename: 'RunStorageNodeEdge' }
                & { node: (
                  { __typename: 'RunStorageNode' }
                  & Pick<RunStorageNode, 'id'>
                  & RunNodeFragment
                ) }
              )> }
            ), artifactSequences: (
              { __typename: 'ArtifactSequenceStorageNodeConnection' }
              & Pick<ArtifactSequenceStorageNodeConnection, 'totalSize'>
              & { edges: Array<(
                { __typename: 'ArtifactSequenceStorageNodeEdge' }
                & { node: (
                  { __typename: 'ArtifactSequenceStorageNode' }
                  & Pick<ArtifactSequenceStorageNode, 'id'>
                  & ArtifactNodeFragment
                ) }
              )> }
            ), artifactTypes: Array<(
              { __typename: 'ArtifactTypeStorageNode' }
              & Pick<ArtifactTypeStorageNode, 'id' | 'name' | 'size'>
            )> }
          ) }
        )> }
      ) }
    )> }
  )> }
);

export type AllExperimentsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllExperimentsQuery = (
  { __typename: 'Query' }
  & { experiments: Array<(
    { __typename: 'Experiment' }
    & Pick<Experiment, 'id' | 'name' | 'experimentType' | 'startAt' | 'endAt' | 'updatedAt' | 'metadata' | 'sampleSize' | 'observationalUnit' | 'enrollmentPeriod'>
    & { variants: Array<(
      { __typename: 'Variant' }
      & Pick<Variant, 'bucket' | 'allocation' | 'description'>
    )> }
  )> }
);

export type ActiveExperimentsQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveExperimentsQuery = (
  { __typename: 'Query' }
  & { activeExperiments: Array<(
    { __typename: 'ActiveExperiment' }
    & Pick<ActiveExperiment, 'id' | 'name' | 'experimentType' | 'startAt' | 'endAt'>
    & { activeVariants: Array<(
      { __typename: 'ActiveVariant' }
      & Pick<ActiveVariant, 'bucket' | 'allocation'>
    )> }
  )> }
);

export type CreateExperimentMutationVariables = Exact<{
  name: Scalars['String'];
  variants: Array<VariantInput>;
  experimentType: ExperimentType;
  metadata?: Maybe<Scalars['JSONString']>;
  sampleSize: Scalars['Int'];
  observationalUnit: ObservationalUnit;
  enrollmentPeriod: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type CreateExperimentMutation = (
  { __typename: 'Mutation' }
  & { createExperiment?: Maybe<(
    { __typename: 'CreateExperimentPayload' }
    & Pick<CreateExperimentPayload, 'id' | 'success' | 'clientMutationId'>
  )> }
);

export type UpdateExperimentMutationVariables = Exact<{
  id: Scalars['ID'];
  name: Scalars['String'];
  variants: Array<VariantInput>;
  experimentType: ExperimentType;
  metadata?: Maybe<Scalars['JSONString']>;
  sampleSize: Scalars['Int'];
  observationalUnit: ObservationalUnit;
  enrollmentPeriod: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type UpdateExperimentMutation = (
  { __typename: 'Mutation' }
  & { updateExperiment?: Maybe<(
    { __typename: 'UpdateExperimentPayload' }
    & Pick<UpdateExperimentPayload, 'id' | 'success' | 'clientMutationId'>
  )> }
);

export type StartExperimentMutationVariables = Exact<{
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type StartExperimentMutation = (
  { __typename: 'Mutation' }
  & { startExperiment?: Maybe<(
    { __typename: 'UpdateExperimentPayload' }
    & Pick<UpdateExperimentPayload, 'id' | 'success' | 'clientMutationId'>
  )> }
);

export type StopExperimentMutationVariables = Exact<{
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type StopExperimentMutation = (
  { __typename: 'Mutation' }
  & { stopExperiment?: Maybe<(
    { __typename: 'UpdateExperimentPayload' }
    & Pick<UpdateExperimentPayload, 'id' | 'success' | 'clientMutationId'>
  )> }
);

export type DeleteExperimentMutationVariables = Exact<{
  id: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type DeleteExperimentMutation = (
  { __typename: 'Mutation' }
  & { deleteExperiment?: Maybe<(
    { __typename: 'DeleteExperimentPayload' }
    & Pick<DeleteExperimentPayload, 'id' | 'success' | 'clientMutationId'>
  )> }
);

export type UserEmailsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserEmailsQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { emails: Array<(
      { __typename: 'Email' }
      & Pick<Email, 'id' | 'emailAddress' | 'type' | 'createdAt' | 'verified'>
      & { identities: Array<(
        { __typename: 'Identity' }
        & Pick<Identity, 'id' | 'emailIdentityVerified' | 'provider'>
      )> }
    )> }
  )> }
);

export type GlueTasksQueryVariables = Exact<{ [key: string]: never; }>;


export type GlueTasksQuery = (
  { __typename: 'Query' }
  & { glueManager?: Maybe<(
    { __typename: 'GlueManager' }
    & Pick<GlueManager, 'taskTypes'>
    & { registeredTasks: (
      { __typename: 'GlueTaskConnection' }
      & { edges: Array<(
        { __typename: 'GlueTaskEdge' }
        & { node: (
          { __typename: 'GlueTask' }
          & Pick<GlueTask, 'id' | 'name' | 'taskType' | 'arguments' | 'checkpoint' | 'lastRunAt' | 'status' | 'strategyType' | 'strategyMetadata'>
        ) }
      )> }
    ) }
  )> }
);

export type GlueTaskConfigQueryVariables = Exact<{
  taskType: Scalars['String'];
}>;


export type GlueTaskConfigQuery = (
  { __typename: 'Query' }
  & { glueManager?: Maybe<(
    { __typename: 'GlueManager' }
    & { taskConfig?: Maybe<(
      { __typename: 'GlueTaskConfig' }
      & Pick<GlueTaskConfig, 'defaultArguments' | 'argumentsSchema' | 'checkpointSchema'>
    )> }
  )> }
);

export type GlueRunsQueryVariables = Exact<{
  taskID: Scalars['ID'];
}>;


export type GlueRunsQuery = (
  { __typename: 'Query' }
  & { glueRuns?: Maybe<(
    { __typename: 'GlueRunConnection' }
    & { edges: Array<(
      { __typename: 'GlueRunEdge' }
      & { node: (
        { __typename: 'GlueRun' }
        & Pick<GlueRun, 'id' | 'runAt' | 'runtimeMS' | 'arguments' | 'errors' | 'retries' | 'succeeded'>
      ) }
    )> }
  )> }
);

export type CreateGlueTaskMutationVariables = Exact<{
  name: Scalars['String'];
  taskType: Scalars['String'];
  arguments: Scalars['JSONString'];
  status: Scalars['String'];
  strategyType: Scalars['String'];
  strategyMetadata: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type CreateGlueTaskMutation = (
  { __typename: 'Mutation' }
  & { createGlueTask?: Maybe<(
    { __typename: 'CreateGlueTaskPayload' }
    & Pick<CreateGlueTaskPayload, 'success' | 'clientMutationId'>
  )> }
);

export type UpdateGlueTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  arguments?: Maybe<Scalars['JSONString']>;
  checkpoint?: Maybe<Scalars['JSONString']>;
  strategyType?: Maybe<Scalars['String']>;
  strategyMetadata?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
}>;


export type UpdateGlueTaskMutation = (
  { __typename: 'Mutation' }
  & { updateGlueTask?: Maybe<(
    { __typename: 'UpdateGlueTaskPayload' }
    & Pick<UpdateGlueTaskPayload, 'success' | 'clientMutationId'>
  )> }
);

export type EnableGlueTaskMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type EnableGlueTaskMutation = (
  { __typename: 'Mutation' }
  & { enableGlueTask?: Maybe<(
    { __typename: 'EnableGlueTaskPayload' }
    & Pick<EnableGlueTaskPayload, 'success' | 'clientMutationId'>
  )> }
);

export type DisableGlueTaskMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DisableGlueTaskMutation = (
  { __typename: 'Mutation' }
  & { disableGlueTask?: Maybe<(
    { __typename: 'DisableGlueTaskPayload' }
    & Pick<DisableGlueTaskPayload, 'success' | 'clientMutationId'>
  )> }
);

export type DeleteGlueTaskMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteGlueTaskMutation = (
  { __typename: 'Mutation' }
  & { deleteGlueTask?: Maybe<(
    { __typename: 'DeleteGlueTaskPayload' }
    & Pick<DeleteGlueTaskPayload, 'success' | 'clientMutationId'>
  )> }
);

export type HomePageViewerDetailsQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
}>;


export type HomePageViewerDetailsQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'username' | 'name' | 'photoUrl'>
    & { organizations: Array<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
    )>, defaultEntity?: Maybe<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'name'>
    )>, teams?: Maybe<(
      { __typename: 'EntityConnection' }
      & { edges: Array<(
        { __typename: 'EntityEdge' }
        & { node?: Maybe<(
          { __typename: 'Entity' }
          & Pick<Entity, 'id' | 'name' | 'photoUrl'>
        )> }
      )> }
    )> }
  )> }
);

export type HomePageViewerProjectsQueryVariables = Exact<{
  entityName: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  pattern?: Maybe<Scalars['String']>;
}>;


export type HomePageViewerProjectsQuery = (
  { __typename: 'Query' }
  & { projects?: Maybe<(
    { __typename: 'ProjectConnection' }
    & { edges: Array<(
      { __typename: 'ProjectEdge' }
      & { node?: Maybe<(
        { __typename: 'Project' }
        & Pick<Project, 'id' | 'name' | 'entityName' | 'access' | 'createdAt'>
      )> }
    )> }
  )> }
);

export type ViewerReportsQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
}>;


export type ViewerReportsQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { views?: Maybe<(
      { __typename: 'ViewConnection' }
      & { edges: Array<(
        { __typename: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename: 'View' }
          & Pick<View, 'id'>
          & ReportsTableFragmentFragment
        )> }
      )> }
    )> }
  )> }
);

export type ViewerRunsQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}>;


export type ViewerRunsQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { runs?: Maybe<(
      { __typename: 'RunConnection' }
      & { edges: Array<(
        { __typename: 'RunEdge' }
        & { node: (
          { __typename: 'Run' }
          & Pick<Run, 'id'>
          & RunsTableFragmentFragment
        ) }
      )> }
    )> }
  )> }
);

export type EntityProjectsQueryVariables = Exact<{
  entityName: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
}>;


export type EntityProjectsQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id'>
    & { projects?: Maybe<(
      { __typename: 'ProjectConnection' }
      & { edges: Array<(
        { __typename: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename: 'Project' }
          & Pick<Project, 'id'>
          & AccountProjectFragmentFragment
        )> }
      )> }
    )> }
  )> }
);

export type EntityReportsQueryVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type EntityReportsQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id'>
    & { views: (
      { __typename: 'ViewConnection' }
      & { edges: Array<(
        { __typename: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename: 'View' }
          & Pick<View, 'id'>
          & ReportsTableFragmentFragment
        )> }
      )> }
    ) }
  )> }
);

export type OrganizationReportsQueryVariables = Exact<{
  orgID: Scalars['ID'];
  userID?: Maybe<Scalars['ID']>;
}>;


export type OrganizationReportsQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { views: (
      { __typename: 'ViewConnection' }
      & { edges: Array<(
        { __typename: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename: 'View' }
          & Pick<View, 'id'>
          & ReportsTableFragmentFragment
        )> }
      )> }
    ) }
  )> }
);

export type AccountTeamsQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountTeamsQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'name' | 'username'>
    & { teams?: Maybe<(
      { __typename: 'EntityConnection' }
      & { edges: Array<(
        { __typename: 'EntityEdge' }
        & { node?: Maybe<(
          { __typename: 'Entity' }
          & Pick<Entity, 'id' | 'isTeam' | 'organizationId' | 'name' | 'defaultAccess' | 'privateOnly'>
          & { members: Array<(
            { __typename: 'Member' }
            & Pick<Member, 'id' | 'name' | 'username' | 'email' | 'pending' | 'photoUrl' | 'accountType' | 'role'>
            & { memberRole?: Maybe<(
              { __typename: 'Role' }
              & Pick<Role, 'name'>
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type CreateSlackIntegrationMutationVariables = Exact<{
  code: Scalars['String'];
  entityName: Scalars['String'];
  redirectURI: Scalars['String'];
}>;


export type CreateSlackIntegrationMutation = (
  { __typename: 'Mutation' }
  & { createSlackIntegration?: Maybe<(
    { __typename: 'CreateIntegrationPayload' }
    & { integration: (
      { __typename: 'GenericWebhookIntegration' }
      & Pick<GenericWebhookIntegration, 'id'>
    ) | (
      { __typename: 'GitHubOAuthIntegration' }
      & Pick<GitHubOAuthIntegration, 'id'>
    ) | (
      { __typename: 'SlackIntegration' }
      & Pick<SlackIntegration, 'id'>
    ) }
  )> }
);

export type CreateGitHubIntegrationMutationVariables = Exact<{
  code: Scalars['String'];
  entityName: Scalars['String'];
  redirectURI: Scalars['String'];
  state: Scalars['String'];
}>;


export type CreateGitHubIntegrationMutation = (
  { __typename: 'Mutation' }
  & { createGitHubOAuthIntegration?: Maybe<(
    { __typename: 'CreateIntegrationPayload' }
    & { integration: (
      { __typename: 'GenericWebhookIntegration' }
      & Pick<GenericWebhookIntegration, 'id'>
    ) | (
      { __typename: 'GitHubOAuthIntegration' }
      & Pick<GitHubOAuthIntegration, 'id'>
    ) | (
      { __typename: 'SlackIntegration' }
      & Pick<SlackIntegration, 'id'>
    ) }
  )> }
);

export type CreateGenericWebhookIntegrationMutationVariables = Exact<{
  entityName: Scalars['String'];
  webhookName: Scalars['String'];
  urlEndpoint: Scalars['String'];
  accessTokenRef?: Maybe<Scalars['String']>;
  secretRef?: Maybe<Scalars['String']>;
}>;


export type CreateGenericWebhookIntegrationMutation = (
  { __typename: 'Mutation' }
  & { createGenericWebhookIntegration?: Maybe<(
    { __typename: 'CreateIntegrationPayload' }
    & { integration: (
      { __typename: 'GenericWebhookIntegration' }
      & Pick<GenericWebhookIntegration, 'id' | 'urlEndpoint' | 'name'>
    ) | { __typename: 'GitHubOAuthIntegration' } | { __typename: 'SlackIntegration' } }
  )> }
);

export type UpdateGenericWebhookIntegrationMutationVariables = Exact<{
  integrationID: Scalars['ID'];
  webhookName?: Maybe<Scalars['String']>;
  urlEndpoint?: Maybe<Scalars['String']>;
  accessTokenRef?: Maybe<Scalars['String']>;
  secretRef?: Maybe<Scalars['String']>;
}>;


export type UpdateGenericWebhookIntegrationMutation = (
  { __typename: 'Mutation' }
  & { updateGenericWebhookIntegration?: Maybe<(
    { __typename: 'CreateIntegrationPayload' }
    & { integration: (
      { __typename: 'GenericWebhookIntegration' }
      & Pick<GenericWebhookIntegration, 'id' | 'urlEndpoint' | 'name'>
    ) | { __typename: 'GitHubOAuthIntegration' } | { __typename: 'SlackIntegration' } }
  )> }
);

export type TestGenericWebhookIntegrationMutationVariables = Exact<{
  entityName: Scalars['String'];
  urlEndpoint: Scalars['String'];
  accessTokenRef?: Maybe<Scalars['String']>;
  secretRef?: Maybe<Scalars['String']>;
  requestPayload?: Maybe<Scalars['JSONString']>;
}>;


export type TestGenericWebhookIntegrationMutation = (
  { __typename: 'Mutation' }
  & { testGenericWebhookIntegration?: Maybe<(
    { __typename: 'TestGenericWebhookIntegrationPayload' }
    & Pick<TestGenericWebhookIntegrationPayload, 'ok' | 'response'>
  )> }
);

export type DeleteIntegrationMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteIntegrationMutation = (
  { __typename: 'Mutation' }
  & { deleteIntegration?: Maybe<(
    { __typename: 'DeleteIntegrationPayload' }
    & Pick<DeleteIntegrationPayload, 'success'>
  )> }
);

export type FetchLaunchAgentsFromProjectQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
}>;


export type FetchLaunchAgentsFromProjectQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { launchAgents: Array<(
      { __typename: 'LaunchAgent' }
      & Pick<LaunchAgent, 'id' | 'name' | 'createdAt' | 'agentStatus' | 'stopPolling' | 'runQueues' | 'hostname' | 'agentConfig' | 'version'>
    )> }
  )> }
);

export type StopLaunchAgentMutationVariables = Exact<{
  agentId: Scalars['ID'];
  stopPolling?: Maybe<Scalars['Boolean']>;
}>;


export type StopLaunchAgentMutation = (
  { __typename: 'Mutation' }
  & { updateLaunchAgent?: Maybe<(
    { __typename: 'UpdateLaunchAgentPayload' }
    & Pick<UpdateLaunchAgentPayload, 'success'>
  )> }
);

export type DeleteLaunchAgentsMutationVariables = Exact<{
  agentIds: Array<Scalars['ID']>;
}>;


export type DeleteLaunchAgentsMutation = (
  { __typename: 'Mutation' }
  & { deleteLaunchAgents?: Maybe<(
    { __typename: 'DeleteLaunchAgentsPayload' }
    & Pick<DeleteLaunchAgentsPayload, 'success'>
  )> }
);

export type HasLocalLicenseQueryVariables = Exact<{ [key: string]: never; }>;


export type HasLocalLicenseQuery = (
  { __typename: 'Query' }
  & { serverInfo?: Maybe<(
    { __typename: 'ServerInfo' }
    & Pick<ServerInfo, 'hasLocalLicense'>
  )> }
);

export type LatestLocalVersionInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type LatestLocalVersionInfoQuery = (
  { __typename: 'Query' }
  & { serverInfo?: Maybe<(
    { __typename: 'ServerInfo' }
    & { latestLocalVersionInfo?: Maybe<(
      { __typename: 'LocalVersionInfo' }
      & Pick<LocalVersionInfo, 'outOfDate' | 'latestVersionString' | 'versionOnThisInstanceString'>
    )> }
  )> }
);

export type AvailableFilesQueryQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  runName: Scalars['String'];
  filenames: Array<Scalars['String']>;
  includeDirectUrl: Scalars['Boolean'];
}>;


export type AvailableFilesQueryQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { run?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id'>
      & { files?: Maybe<(
        { __typename: 'FileConnection' }
        & { edges: Array<(
          { __typename: 'FileEdge' }
          & { node?: Maybe<(
            { __typename: 'File' }
            & Pick<File, 'id' | 'name' | 'directUrl' | 'url' | 'md5' | 'sizeBytes'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type DirectUrlQueryQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  runName: Scalars['String'];
  filenames: Array<Scalars['String']>;
}>;


export type DirectUrlQueryQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { run?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id'>
      & { files?: Maybe<(
        { __typename: 'FileConnection' }
        & { edges: Array<(
          { __typename: 'FileEdge' }
          & { node?: Maybe<(
            { __typename: 'File' }
            & Pick<File, 'id' | 'name' | 'directUrl' | 'url'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type ArtifactCodeFileQueryQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  runName: Scalars['String'];
  filenames: Array<Scalars['String']>;
}>;


export type ArtifactCodeFileQueryQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { run?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id'>
      & { outputArtifacts?: Maybe<(
        { __typename: 'ArtifactConnection' }
        & { edges: Array<(
          { __typename: 'ArtifactEdge' }
          & { node?: Maybe<(
            { __typename: 'Artifact' }
            & Pick<Artifact, 'id'>
            & { files: (
              { __typename: 'FileConnection' }
              & { edges: Array<(
                { __typename: 'FileEdge' }
                & { node?: Maybe<(
                  { __typename: 'File' }
                  & Pick<File, 'id' | 'name' | 'url' | 'directUrl' | 'sizeBytes'>
                )> }
              )> }
            ) }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type AddWorkflowsAdminMutationVariables = Exact<{
  entityName: Scalars['String'];
  username?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['ID']>;
  adminType: WorkflowsAdminType;
}>;


export type AddWorkflowsAdminMutation = (
  { __typename: 'Mutation' }
  & { addWorkflowsAdmin?: Maybe<(
    { __typename: 'AddWorkflowsAdminPayload' }
    & Pick<AddWorkflowsAdminPayload, 'success'>
  )> }
);

export type DeleteWorkflowsAdminMutationVariables = Exact<{
  entityName: Scalars['String'];
  username?: Maybe<Scalars['String']>;
  userID?: Maybe<Scalars['ID']>;
  adminType: WorkflowsAdminType;
}>;


export type DeleteWorkflowsAdminMutation = (
  { __typename: 'Mutation' }
  & { deleteWorkflowsAdmin?: Maybe<(
    { __typename: 'DeleteWorkflowsAdminPayload' }
    & Pick<DeleteWorkflowsAdminPayload, 'success'>
  )> }
);

export type AddProtectedAliasesMutationVariables = Exact<{
  entityName: Scalars['String'];
  aliases: Array<Scalars['String']>;
  adminType: WorkflowsAdminType;
}>;


export type AddProtectedAliasesMutation = (
  { __typename: 'Mutation' }
  & { addProtectedAliases?: Maybe<(
    { __typename: 'AddProtectedAliasesPayload' }
    & Pick<AddProtectedAliasesPayload, 'aliases'>
  )> }
);

export type RemoveProtectedAliasesMutationVariables = Exact<{
  entityName: Scalars['String'];
  aliases: Array<Scalars['String']>;
  adminType: WorkflowsAdminType;
}>;


export type RemoveProtectedAliasesMutation = (
  { __typename: 'Mutation' }
  & { removeProtectedAliases?: Maybe<(
    { __typename: 'RemoveProtectedAliasesPayload' }
    & Pick<RemoveProtectedAliasesPayload, 'aliases'>
  )> }
);

export type EntityProtectedAliasesQueryVariables = Exact<{
  entityName: Scalars['String'];
  workflowsAdminType: WorkflowsAdminType;
}>;


export type EntityProtectedAliasesQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id' | 'protectedAliases'>
    & { workflowsAdmins: Array<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'username' | 'name' | 'email'>
    )> }
  )> }
);

export type ModelQueryVariables = Exact<{
  name: Scalars['String'];
  logCursor?: Maybe<Scalars['String']>;
  fileCursor?: Maybe<Scalars['String']>;
  fileLimit?: Maybe<Scalars['Int']>;
  fileNames?: Maybe<Array<Maybe<Scalars['String']>>>;
  entityName?: Maybe<Scalars['String']>;
  bucketName: Scalars['String'];
  upload?: Maybe<Scalars['Boolean']>;
  detailed: Scalars['Boolean'];
  includeFiles?: Maybe<Scalars['Boolean']>;
}>;


export type ModelQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'readOnly' | 'name' | 'entityName' | 'description' | 'createdAt' | 'bucketCount' | 'access' | 'summaryMetrics' | 'views'>
    & { run?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id' | 'name'>
      & SelectRunFragmentFragment
      & BasicRunFragmentFragment
      & DetailedRunFragmentFragment
      & RunFilesFragmentFragment
    )> }
  )>, viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'email' | 'photoUrl' | 'admin' | 'defaultFramework'>
  )> }
);

export type UpsertModelMutationVariables = Exact<{
  description?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  framework?: Maybe<Scalars['String']>;
  access?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['JSONString']>;
  owner?: Maybe<Scalars['ID']>;
}>;


export type UpsertModelMutation = (
  { __typename: 'Mutation' }
  & { upsertModel?: Maybe<(
    { __typename: 'UpsertModelPayload' }
    & Pick<UpsertModelPayload, 'inserted'>
    & { project?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id' | 'name' | 'entityName' | 'description' | 'access' | 'views'>
      & { user?: Maybe<(
        { __typename: 'User' }
        & Pick<User, 'id'>
      )> }
    )>, model?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id' | 'name' | 'entityName' | 'description' | 'access' | 'views'>
      & { user?: Maybe<(
        { __typename: 'User' }
        & Pick<User, 'id'>
      )> }
    )> }
  )> }
);

export type RenameProjectMutationVariables = Exact<{
  entityName: Scalars['String'];
  oldProjectName: Scalars['String'];
  newProjectName: Scalars['String'];
}>;


export type RenameProjectMutation = (
  { __typename: 'Mutation' }
  & { renameProject?: Maybe<(
    { __typename: 'UpsertModelPayload' }
    & Pick<UpsertModelPayload, 'inserted'>
    & { project?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id' | 'name' | 'entityName' | 'description' | 'access' | 'views'>
    )>, model?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id' | 'name' | 'entityName' | 'description' | 'access' | 'views'>
    )> }
  )> }
);

export type DeleteModelMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteModelMutation = (
  { __typename: 'Mutation' }
  & { deleteModel?: Maybe<(
    { __typename: 'DeleteModelPayload' }
    & Pick<DeleteModelPayload, 'success'>
  )> }
);

export type ModelsQueryVariables = Exact<{
  cursor?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  anonymous?: Maybe<Scalars['Boolean']>;
}>;


export type ModelsQuery = (
  { __typename: 'Query' }
  & { projects?: Maybe<(
    { __typename: 'ProjectConnection' }
    & { edges: Array<(
      { __typename: 'ProjectEdge' }
      & { node?: Maybe<(
        { __typename: 'Project' }
        & Pick<Project, 'id' | 'name' | 'entityName' | 'description' | 'totalRuns' | 'totalUsers' | 'lastActive'>
      )> }
    )>, pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  )>, viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'email' | 'photoUrl'>
  )> }
);

export type NumPendingInvitesQueryVariables = Exact<{ [key: string]: never; }>;


export type NumPendingInvitesQuery = (
  { __typename: 'Query' }
  & Pick<Query, 'numPendingInvites'>
);

export type CreateOAuthProviderMutationVariables = Exact<{
  organizationId: Scalars['ID'];
  issuerUrl: Scalars['String'];
}>;


export type CreateOAuthProviderMutation = (
  { __typename: 'Mutation' }
  & { createOAuthProvider?: Maybe<(
    { __typename: 'CreateOAuthProviderPayload' }
    & Pick<CreateOAuthProviderPayload, 'success'>
  )> }
);

export type OauthProvidersQueryVariables = Exact<{
  organizationName: Scalars['String'];
}>;


export type OauthProvidersQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { OAuthProviders: Array<(
      { __typename: 'OAuthProvider' }
      & Pick<OAuthProvider, 'id' | 'issuerUrl'>
    )> }
  )> }
);

export type DeleteOAuthProviderMutationVariables = Exact<{
  providerId: Scalars['ID'];
}>;


export type DeleteOAuthProviderMutation = (
  { __typename: 'Mutation' }
  & { deleteOAuthProvider?: Maybe<(
    { __typename: 'DeleteOAuthProviderPayload' }
    & Pick<DeleteOAuthProviderPayload, 'success'>
  )> }
);

export type OauthClientsQueryVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type OauthClientsQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id'>
    & { members: Array<(
      { __typename: 'Member' }
      & Pick<Member, 'id' | 'role'>
      & { OAuthClients: Array<(
        { __typename: 'OAuthClient' }
        & Pick<OAuthClient, 'id' | 'subject'>
      )> }
    )> }
  )> }
);

export type CreateOAuthClientMutationVariables = Exact<{
  providerId: Scalars['ID'];
  name: Scalars['String'];
  subject: Scalars['String'];
  entityName: Scalars['String'];
}>;


export type CreateOAuthClientMutation = (
  { __typename: 'Mutation' }
  & { createClient?: Maybe<(
    { __typename: 'CreateClientPayload' }
    & Pick<CreateClientPayload, 'success'>
  )> }
);

export type DeleteOAuthClientMutationVariables = Exact<{
  clientId: Scalars['String'];
  entityName: Scalars['String'];
}>;


export type DeleteOAuthClientMutation = (
  { __typename: 'Mutation' }
  & { revokeClient?: Maybe<(
    { __typename: 'RevokeClientPayload' }
    & Pick<RevokeClientPayload, 'success'>
  )> }
);

export type ActivityTimelineQueryVariables = Exact<{
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
}>;


export type ActivityTimelineQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { activity: Array<(
      { __typename: 'OrgActivityTimeline' }
      & Pick<OrgActivityTimeline, 'numActiveUsers' | 'startAt' | 'endAt'>
    )> }
  )> }
);

export type OrgFlagsQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type OrgFlagsQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'flags'>
  )> }
);

export type OrgDashboardEnablementQueryVariables = Exact<{ [key: string]: never; }>;


export type OrgDashboardEnablementQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
    & { activity: Array<(
      { __typename: 'OrgActivityTimeline' }
      & Pick<OrgActivityTimeline, 'numActiveUsers'>
    )> }
  )> }
);

export type OrgDashboardContextQueryVariables = Exact<{
  orgName?: Maybe<Scalars['String']>;
}>;


export type OrgDashboardContextQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
    & { members: Array<(
      { __typename: 'OrganizationMember' }
      & Pick<OrganizationMember, 'orgID' | 'id' | 'modelsSeat'>
      & { user: (
        { __typename: 'User' }
        & Pick<User, 'id'>
        & { teams?: Maybe<(
          { __typename: 'EntityConnection' }
          & { edges: Array<(
            { __typename: 'EntityEdge' }
            & { node?: Maybe<(
              { __typename: 'Entity' }
              & Pick<Entity, 'id' | 'name'>
            )> }
          )> }
        )>, activity?: Maybe<(
          { __typename: 'UserActivityDetails' }
          & Pick<UserActivityDetails, 'lastActiveAt' | 'startAt' | 'endAt' | 'daysActive' | 'numReports' | 'numRuns'>
        )> }
        & UserRoleFragmentFragment
      ) }
    )> }
  )> }
);

export type OrgActivityTimelineQueryVariables = Exact<{
  startDate?: Maybe<Scalars['DateTime']>;
  endDate?: Maybe<Scalars['DateTime']>;
}>;


export type OrgActivityTimelineQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { activity: Array<(
      { __typename: 'OrgActivityTimeline' }
      & Pick<OrgActivityTimeline, 'numActiveUsers' | 'startAt' | 'endAt'>
    )> }
  )> }
);

export type OrgSeatsQueryVariables = Exact<{ [key: string]: never; }>;


export type OrgSeatsQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { seatAvailability: (
      { __typename: 'OrganizationSeatAvailability' }
      & Pick<OrganizationSeatAvailability, 'seats' | 'viewOnlySeats' | 'expiresAt'>
    ) }
  )> }
);

export type UserActivityTableQueryVariables = Exact<{ [key: string]: never; }>;


export type UserActivityTableQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
    & { members: Array<(
      { __typename: 'OrganizationMember' }
      & Pick<OrganizationMember, 'orgID' | 'id' | 'role' | 'modelsSeat'>
      & { user: (
        { __typename: 'User' }
        & Pick<User, 'id' | 'name' | 'photoUrl' | 'createdAt' | 'accountType'>
        & { activity?: Maybe<(
          { __typename: 'UserActivityDetails' }
          & Pick<UserActivityDetails, 'lastActiveAt' | 'daysActive' | 'numReports' | 'numRuns'>
        )>, teams?: Maybe<(
          { __typename: 'EntityConnection' }
          & { edges: Array<(
            { __typename: 'EntityEdge' }
            & { node?: Maybe<(
              { __typename: 'Entity' }
              & Pick<Entity, 'id' | 'name'>
            )> }
          )> }
        )> }
        & UserRoleFragmentFragment
      ) }
    )> }
  )> }
);

export type AddUserToOrganizationMutationVariables = Exact<{
  userName: Scalars['String'];
  teams?: Maybe<Array<Scalars['String']>>;
  organizationId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
  userOrgRole: Scalars['String'];
}>;


export type AddUserToOrganizationMutation = (
  { __typename: 'Mutation' }
  & { addUserToOrganization?: Maybe<(
    { __typename: 'AddUserToOrganizationPayload' }
    & Pick<AddUserToOrganizationPayload, 'success' | 'clientMutationId'>
  )> }
);

export type RemoveUserFromOrganizationMutationVariables = Exact<{
  userName: Scalars['String'];
  organizationId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type RemoveUserFromOrganizationMutation = (
  { __typename: 'Mutation' }
  & { removeUserFromOrganization?: Maybe<(
    { __typename: 'RemoveUserFromOrganizationPayload' }
    & Pick<RemoveUserFromOrganizationPayload, 'success' | 'clientMutationId'>
  )> }
);

export type RemoveUserFromOrganizationByIdMutationVariables = Exact<{
  userId: Scalars['ID'];
  organizationId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type RemoveUserFromOrganizationByIdMutation = (
  { __typename: 'Mutation' }
  & { removeUserFromOrganizationById?: Maybe<(
    { __typename: 'RemoveUserFromOrganizationByIdPayload' }
    & Pick<RemoveUserFromOrganizationByIdPayload, 'success' | 'clientMutationId'>
  )> }
);

export type UpdateOrganizationMutationVariables = Exact<{
  organizationID: Scalars['ID'];
  flags?: Maybe<Scalars['JSONString']>;
  name?: Maybe<Scalars['String']>;
}>;


export type UpdateOrganizationMutation = (
  { __typename: 'Mutation' }
  & { updateOrganization?: Maybe<(
    { __typename: 'UpdateOrganizationPayload' }
    & Pick<UpdateOrganizationPayload, 'success' | 'clientMutationId'>
  )> }
);

export type DeleteOrganizationMutationVariables = Exact<{
  organizationId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type DeleteOrganizationMutation = (
  { __typename: 'Mutation' }
  & { deleteOrganization?: Maybe<(
    { __typename: 'DeleteOrganizationPayload' }
    & Pick<DeleteOrganizationPayload, 'success' | 'clientMutationId'>
  )> }
);

export type UpdateOrganizationUserMutationVariables = Exact<{
  userName: Scalars['String'];
  organizationId: Scalars['ID'];
  userOrgRole: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type UpdateOrganizationUserMutation = (
  { __typename: 'Mutation' }
  & { updateOrganizationUser?: Maybe<(
    { __typename: 'UpdateOrganizationUserPayload' }
    & Pick<UpdateOrganizationUserPayload, 'success' | 'clientMutationId'>
  )> }
);

export type UpdateOrganizationUserV2MutationVariables = Exact<{
  userName: Scalars['String'];
  organizationId: Scalars['ID'];
  userOrgRole: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type UpdateOrganizationUserV2Mutation = (
  { __typename: 'Mutation' }
  & { updateOrganizationUserV2?: Maybe<(
    { __typename: 'UpdateOrganizationUserV2Payload' }
    & Pick<UpdateOrganizationUserV2Payload, 'success' | 'clientMutationId'>
  )> }
);

export type UpdateModelsSeatMutationVariables = Exact<{
  userID: Scalars['ID'];
  organizationId: Scalars['ID'];
  modelsSeat: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type UpdateModelsSeatMutation = (
  { __typename: 'Mutation' }
  & { updateModelsSeat?: Maybe<(
    { __typename: 'UpdateModelsSeatPayload' }
    & Pick<UpdateModelsSeatPayload, 'success' | 'clientMutationId'>
  )> }
);

export type UpdateOrganizationBillingUserMutationVariables = Exact<{
  organizationId: Scalars['ID'];
  newBillingUser: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type UpdateOrganizationBillingUserMutation = (
  { __typename: 'Mutation' }
  & { updateOrganizationBillingUser?: Maybe<(
    { __typename: 'UpdateOrganizationBillingUserPayload' }
    & Pick<UpdateOrganizationBillingUserPayload, 'success' | 'clientMutationId'>
  )> }
);

export type UpdateOrganizationSubscriptionMutationVariables = Exact<{
  organizationSubscriptionID: Scalars['ID'];
  createStripeSubscription: Scalars['Boolean'];
  planID?: Maybe<Scalars['ID']>;
  seats?: Maybe<Scalars['Int']>;
  privileges?: Maybe<Scalars['JSONString']>;
  type?: Maybe<OrganizationSubscriptionType>;
  status?: Maybe<OrganizationSubscriptionStatus>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  expiresAt?: Maybe<Scalars['DateTime']>;
}>;


export type UpdateOrganizationSubscriptionMutation = (
  { __typename: 'Mutation' }
  & { updateOrganizationSubscription?: Maybe<(
    { __typename: 'UpdateOrganizationSubscriptionPayload' }
    & Pick<UpdateOrganizationSubscriptionPayload, 'success' | 'clientMutationId'>
  )> }
);

export type DeleteOrganizationSubscriptionMutationVariables = Exact<{
  subscriptionID: Scalars['ID'];
}>;


export type DeleteOrganizationSubscriptionMutation = (
  { __typename: 'Mutation' }
  & { deleteOrganizationSubscription?: Maybe<(
    { __typename: 'DeleteOrganizationSubscriptionPayload' }
    & Pick<DeleteOrganizationSubscriptionPayload, 'success' | 'clientMutationId'>
  )> }
);

export type LinkTeamToOrganizationMutationVariables = Exact<{
  entityName: Scalars['String'];
  organizationID: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type LinkTeamToOrganizationMutation = (
  { __typename: 'Mutation' }
  & { linkTeamToOrganization?: Maybe<(
    { __typename: 'LinkTeamToOrganizationPayload' }
    & Pick<LinkTeamToOrganizationPayload, 'success' | 'clientMutationId'>
  )> }
);

export type AllOrganizationsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
  queryOrg?: Maybe<Scalars['String']>;
  querySubId?: Maybe<Scalars['String']>;
  queryUser?: Maybe<Scalars['String']>;
  queryTeam?: Maybe<Scalars['String']>;
  emailDomain?: Maybe<Scalars['String']>;
}>;


export type AllOrganizationsQuery = (
  { __typename: 'Query' }
  & { organizations: (
    { __typename: 'OrganizationConnection' }
    & { pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    ), edges: Array<(
      { __typename: 'OrganizationEdge' }
      & { node: (
        { __typename: 'Organization' }
        & Pick<Organization, 'id' | 'name' | 'orgType' | 'flags' | 'usedSeats' | 'usedViewOnlySeats'>
        & { billingUser?: Maybe<(
          { __typename: 'User' }
          & Pick<User, 'id' | 'username' | 'email'>
        )>, teams: Array<(
          { __typename: 'Entity' }
          & Pick<Entity, 'id' | 'name'>
        )>, stripeBillingInfo?: Maybe<(
          { __typename: 'StripeBillingInfo' }
          & Pick<StripeBillingInfo, 'stripeSubscriptionId' | 'status' | 'currentPeriodEnd' | 'cancelAtPeriodEnd'>
          & { paymentMethod?: Maybe<(
            { __typename: 'PaymentMethod' }
            & Pick<PaymentMethod, 'id' | 'cardType' | 'endingIn'>
          )> }
        )>, members: Array<(
          { __typename: 'OrganizationMember' }
          & Pick<OrganizationMember, 'orgID' | 'id' | 'admin' | 'name' | 'username' | 'photoUrl' | 'role' | 'modelsSeat'>
          & { teams?: Maybe<(
            { __typename: 'EntityConnection' }
            & { edges: Array<(
              { __typename: 'EntityEdge' }
              & { node?: Maybe<(
                { __typename: 'Entity' }
                & Pick<Entity, 'id' | 'name'>
              )> }
            )> }
          )> }
        )>, subscriptions: Array<(
          { __typename: 'OrganizationSubscription' }
          & Pick<OrganizationSubscription, 'seats' | 'id' | 'subscriptionType' | 'stripeSubscriptionId' | 'status' | 'expiresAt' | 'privileges'>
          & { plan: (
            { __typename: 'Plan' }
            & Pick<Plan, 'id' | 'name' | 'planType' | 'displayName'>
          ) }
        )> }
      ) }
    )> }
  ) }
);

export type OrganizationSubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganizationSubscriptionsQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { organizations: Array<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'createdAt' | 'name' | 'flags' | 'usedSeats'>
      & { billingUser?: Maybe<(
        { __typename: 'User' }
        & Pick<User, 'id' | 'username' | 'email'>
      )>, subscriptions: Array<(
        { __typename: 'OrganizationSubscription' }
        & Pick<OrganizationSubscription, 'id' | 'subscriptionType' | 'status' | 'expiresAt' | 'privileges' | 'seats' | 'isAutomaticUpgrade' | 'createdAt' | 'thresholdCrossedAt' | 'upgradedAt' | 'billingPeriodStart' | 'billingPeriodEnd'>
        & { plan: (
          { __typename: 'Plan' }
          & Pick<Plan, 'id' | 'name' | 'planType' | 'billingInterval'>
        ), expansionPacks: (
          { __typename: 'ExpansionPackConnection' }
          & { edges: Array<(
            { __typename: 'ExpansionPackEdge' }
            & { node: (
              { __typename: 'ExpansionPack' }
              & Pick<ExpansionPack, 'id' | 'secondsLimit' | 'secondsRemaining' | 'expiresAt'>
            ) }
          )> }
        ) }
      )>, teams: Array<(
        { __typename: 'Entity' }
        & Pick<Entity, 'id' | 'name' | 'computeHours'>
        & { oldComputeHours: Entity['computeHours'] }
      )>, members: Array<(
        { __typename: 'OrganizationMember' }
        & Pick<OrganizationMember, 'orgID' | 'id' | 'username' | 'role'>
      )>, pendingInvites: Array<(
        { __typename: 'Invite' }
        & Pick<Invite, 'id'>
      )> }
    )> }
  )> }
);

export type OrganizationStorageInfoQueryVariables = Exact<{
  organizationID: Scalars['ID'];
}>;


export type OrganizationStorageInfoQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { teams: Array<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'storageBytes' | 'referenceBytes'>
    )> }
  )> }
);

export type OrganizationSubscriptionBasicInfoFragment = (
  { __typename: 'Organization' }
  & Pick<Organization, 'name' | 'orgType' | 'memberCount'>
  & { subscriptions: Array<(
    { __typename: 'OrganizationSubscription' }
    & Pick<OrganizationSubscription, 'id' | 'subscriptionType'>
    & { plan: (
      { __typename: 'Plan' }
      & Pick<Plan, 'id' | 'name' | 'planType'>
    ) }
  )> }
);

export type OrganizationSubscriptionsBasicInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type OrganizationSubscriptionsBasicInfoQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'username'>
    & { organizations: Array<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id'>
      & OrganizationSubscriptionBasicInfoFragment
    )> }
  )> }
);

export type OrganizationSubscriptionBasicInfoByNameQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type OrganizationSubscriptionBasicInfoByNameQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & OrganizationSubscriptionBasicInfoFragment
  )> }
);

export type OrganizationSubscriptionsBasicDetailsQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type OrganizationSubscriptionsBasicDetailsQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { subscriptions: Array<(
      { __typename: 'OrganizationSubscription' }
      & Pick<OrganizationSubscription, 'id' | 'subscriptionType' | 'billingPeriodStart' | 'billingPeriodEnd'>
      & { plan: (
        { __typename: 'Plan' }
        & Pick<Plan, 'id' | 'name' | 'planType'>
      ) }
    )> }
  )> }
);

export type OrganizationComputeSecondUsageInfoQueryVariables = Exact<{
  organizationId: Scalars['ID'];
  timeWindow: TimeWindow;
}>;


export type OrganizationComputeSecondUsageInfoQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { teams: Array<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'computeHours'>
    )> }
  )> }
);

export type UpdateOrganizationSubscriptionSeatsMutationVariables = Exact<{
  organizationID: Scalars['ID'];
  seats: Scalars['Int'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type UpdateOrganizationSubscriptionSeatsMutation = (
  { __typename: 'Mutation' }
  & { updateOrganizationSubscriptionSeats?: Maybe<(
    { __typename: 'UpdateOrganizationSubscriptionSeatsPayload' }
    & Pick<UpdateOrganizationSubscriptionSeatsPayload, 'success' | 'clientMutationId'>
  )> }
);

export type SendRequestOrgEmailDomainVerificationMutationVariables = Exact<{
  organizationID: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type SendRequestOrgEmailDomainVerificationMutation = (
  { __typename: 'Mutation' }
  & { sendRequestOrgEmailDomainVerification?: Maybe<(
    { __typename: 'SendRequestOrgEmailDomainVerificationPayload' }
    & Pick<SendRequestOrgEmailDomainVerificationPayload, 'success' | 'clientMutationId'>
  )> }
);

export type OrganizationRunsQueryVariables = Exact<{
  orgID: Scalars['ID'];
  userID: Scalars['ID'];
}>;


export type OrganizationRunsQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { latestRuns?: Maybe<(
      { __typename: 'RunConnection' }
      & { edges: Array<(
        { __typename: 'RunEdge' }
        & { node: (
          { __typename: 'Run' }
          & Pick<Run, 'id'>
          & RunsTableFragmentFragment
        ) }
      )> }
    )> }
  )> }
);

export type OrganizationServiceAccountsQueryVariables = Exact<{
  orgName: Scalars['String'];
}>;


export type OrganizationServiceAccountsQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { serviceAccounts: Array<(
      { __typename: 'ServiceAccount' }
      & Pick<ServiceAccount, 'id' | 'name' | 'createdAt' | 'apiKey' | 'accountType'>
      & { defaultEntity: (
        { __typename: 'Entity' }
        & Pick<Entity, 'name' | 'id'>
      ), teams?: Maybe<(
        { __typename: 'EntityConnection' }
        & { edges: Array<(
          { __typename: 'EntityEdge' }
          & { node?: Maybe<(
            { __typename: 'Entity' }
            & Pick<Entity, 'id' | 'name'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type AccountProjectFragmentFragment = (
  { __typename: 'Project' }
  & Pick<Project, 'id' | 'name' | 'entityName' | 'access' | 'totalRuns' | 'createdAt' | 'lastActive' | 'description'>
  & { user?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'username'>
  )> }
);

export type OrganizationProjectsQueryVariables = Exact<{
  orgID: Scalars['ID'];
  userID?: Maybe<Scalars['ID']>;
  first?: Maybe<Scalars['Int']>;
}>;


export type OrganizationProjectsQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { projects?: Maybe<(
      { __typename: 'ProjectConnection' }
      & { edges: Array<(
        { __typename: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename: 'Project' }
          & Pick<Project, 'id'>
          & AccountProjectFragmentFragment
        )> }
      )> }
    )> }
  )> }
);

export type ViewerOrganizationFeatureFlagsQueryVariables = Exact<{
  rampIDType: RampIdType;
}>;


export type ViewerOrganizationFeatureFlagsQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { organizations: Array<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
      & { featureFlags: Array<Maybe<(
        { __typename: 'FeatureFlag' }
        & Pick<FeatureFlag, 'rampKey' | 'isEnabled'>
      )>> }
    )> }
  )> }
);

export type OrganizationFeatureFlagsQueryVariables = Exact<{
  orgName?: Maybe<Scalars['String']>;
  rampIDType: RampIdType;
}>;


export type OrganizationFeatureFlagsQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'orgType'>
    & { featureFlags: Array<Maybe<(
      { __typename: 'FeatureFlag' }
      & Pick<FeatureFlag, 'rampKey' | 'isEnabled'>
    )>> }
  )> }
);

export type UpdateOrganizationPrivacySettingsMutationVariables = Exact<{
  organizationId: Scalars['ID'];
  hidden?: Maybe<Scalars['Boolean']>;
  privateOnly?: Maybe<Scalars['Boolean']>;
  onlyAdminsCanInvite?: Maybe<Scalars['Boolean']>;
  disableMagicLinkSharing?: Maybe<Scalars['Boolean']>;
  restrictMatchingOrgEmailDomainUsers?: Maybe<Scalars['Boolean']>;
  codeSavingDisabled?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type UpdateOrganizationPrivacySettingsMutation = (
  { __typename: 'Mutation' }
  & { updateOrganizationPrivacySettings?: Maybe<(
    { __typename: 'UpdateOrganizationPrivacySettingsPayload' }
    & Pick<UpdateOrganizationPrivacySettingsPayload, 'success' | 'clientMutationId'>
  )> }
);

export type OrganizationMemberModelsSeatQueryVariables = Exact<{
  orgName?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
}>;


export type OrganizationMemberModelsSeatQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { member?: Maybe<(
      { __typename: 'OrganizationMember' }
      & Pick<OrganizationMember, 'id' | 'role' | 'modelsSeat'>
    )> }
  )> }
);

export type FetchPreviewableLinkQueryVariables = Exact<{
  resourceID: Scalars['ID'];
  resourceType: PreviewableLinkResourceType;
}>;


export type FetchPreviewableLinkQuery = (
  { __typename: 'Query' }
  & { previewableLink?: Maybe<(
    { __typename: 'PreviewableLink' }
    & Pick<PreviewableLink, 'id' | 'URL' | 'createdAt' | 'updatedAt' | 'resourceID' | 'resourceType'>
  )> }
);

export type ProfilePageQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type ProfilePageQuery = (
  { __typename: 'Query' }
  & { user?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'name' | 'username' | 'email' | 'photoUrl' | 'userInfo' | 'hideTeamsFromPublic' | 'accountType'>
    & { defaultEntity?: Maybe<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'name'>
    )>, views?: Maybe<(
      { __typename: 'ViewConnection' }
      & { edges: Array<(
        { __typename: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename: 'View' }
          & Pick<View, 'id'>
          & ReportsTableFragmentFragment
        )> }
      )> }
    )>, starredViews?: Maybe<(
      { __typename: 'ViewConnection' }
      & { edges: Array<(
        { __typename: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename: 'View' }
          & Pick<View, 'id'>
          & ReportsTableFragmentFragment
        )> }
      )> }
    )>, teams?: Maybe<(
      { __typename: 'EntityConnection' }
      & { edges: Array<(
        { __typename: 'EntityEdge' }
        & { node?: Maybe<(
          { __typename: 'Entity' }
          & Pick<Entity, 'id' | 'name' | 'photoUrl' | 'isTeam'>
        )> }
      )> }
    )> }
  )> }
);

export type ProfilePageProjectsQueryVariables = Exact<{
  username: Scalars['String'];
}>;


export type ProfilePageProjectsQuery = (
  { __typename: 'Query' }
  & { user?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { projects?: Maybe<(
      { __typename: 'ProjectConnection' }
      & { edges: Array<(
        { __typename: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename: 'Project' }
          & Pick<Project, 'id' | 'name' | 'entityName' | 'access' | 'description' | 'totalRuns' | 'lastActive' | 'runCount'>
          & { user?: Maybe<(
            { __typename: 'User' }
            & Pick<User, 'id' | 'username'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type UserRunsActivityQueryVariables = Exact<{
  username: Scalars['String'];
  dailyRunCountLimit: Scalars['Int'];
}>;


export type UserRunsActivityQuery = (
  { __typename: 'Query' }
  & { user?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'dailyRunCount'>
  )> }
);

export type UserRunsQueryVariables = Exact<{
  username: Scalars['String'];
  pattern?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}>;


export type UserRunsQuery = (
  { __typename: 'Query' }
  & { user?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { runs?: Maybe<(
      { __typename: 'RunConnection' }
      & { edges: Array<(
        { __typename: 'RunEdge' }
        & { node: (
          { __typename: 'Run' }
          & Pick<Run, 'id'>
          & RunsTableFragmentFragment
        ) }
      )> }
    )> }
  )> }
);

export type ProjectNameFromInternalIdQueryVariables = Exact<{
  internalId: Scalars['ID'];
}>;


export type ProjectNameFromInternalIdQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'name'>
  )> }
);

export type ProjectInfosFromInternalIdsQueryVariables = Exact<{
  internalIds: Array<Scalars['ID']>;
}>;


export type ProjectInfosFromInternalIdsQuery = (
  { __typename: 'Query' }
  & { projects?: Maybe<(
    { __typename: 'ProjectConnection' }
    & { edges: Array<(
      { __typename: 'ProjectEdge' }
      & { node?: Maybe<(
        { __typename: 'Project' }
        & Pick<Project, 'id' | 'internalId' | 'name' | 'entityName'>
      )> }
    )> }
  )> }
);

export type AllProjectNamesQueryVariables = Exact<{
  entityName: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
}>;


export type AllProjectNamesQuery = (
  { __typename: 'Query' }
  & { projects?: Maybe<(
    { __typename: 'ProjectConnection' }
    & { edges: Array<(
      { __typename: 'ProjectEdge' }
      & { node?: Maybe<(
        { __typename: 'Project' }
        & Pick<Project, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type MoveProjectsMutationVariables = Exact<{
  projectNames?: Maybe<Array<Scalars['String']>>;
  sourceEntityName: Scalars['String'];
  destinationEntityName: Scalars['String'];
  ownerUsername: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type MoveProjectsMutation = (
  { __typename: 'Mutation' }
  & { moveProjects?: Maybe<(
    { __typename: 'MoveProjectsPayload' }
    & { tasks: Array<(
      { __typename: 'Task' }
      & Pick<Task, 'id' | 'type' | 'state' | 'progress' | 'name'>
    )> }
  )> }
);

export type CloneProjectsMutationVariables = Exact<{
  projectNames?: Maybe<Array<Scalars['String']>>;
  sourceEntityName: Scalars['String'];
  destinationEntityName: Scalars['String'];
  ownerUsername: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type CloneProjectsMutation = (
  { __typename: 'Mutation' }
  & { cloneProjects?: Maybe<(
    { __typename: 'CloneProjectsPayload' }
    & { tasks: Array<(
      { __typename: 'Task' }
      & Pick<Task, 'id' | 'type' | 'state' | 'progress' | 'name'>
    )> }
  )> }
);

export type ProjectMemberFieldsFragment = (
  { __typename: 'ProjectMember' }
  & Pick<ProjectMember, 'id' | 'name' | 'username' | 'email' | 'photoUrl' | 'accountType' | 'projectRoleIsInherited'>
  & { role: (
    { __typename: 'Role' }
    & Pick<Role, 'ID' | 'name'>
    & { inheritedFrom?: Maybe<(
      { __typename: 'Role' }
      & { permissions: Array<(
        { __typename: 'RolePermission' }
        & Pick<RolePermission, 'name'>
      )> }
    )>, permissions: Array<(
      { __typename: 'RolePermission' }
      & Pick<RolePermission, 'name'>
    )> }
  ) }
);

export type ProjectMembersQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
}>;


export type ProjectMembersQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { members: Array<(
      { __typename: 'ProjectMember' }
      & ProjectMemberFieldsFragment
    )> }
  )> }
);

export type ProjectMemberQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  memberId: Scalars['ID'];
}>;


export type ProjectMemberQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { member?: Maybe<(
      { __typename: 'ProjectMember' }
      & ProjectMemberFieldsFragment
    )> }
  )> }
);

export type CreateProjectMembersMutationVariables = Exact<{
  userIds: Array<Scalars['ID']>;
  projectId: Scalars['ID'];
}>;


export type CreateProjectMembersMutation = (
  { __typename: 'Mutation' }
  & { createProjectMembers?: Maybe<(
    { __typename: 'CreateProjectMembersPayload' }
    & Pick<CreateProjectMembersPayload, 'success'>
  )> }
);

export type DeleteProjectMembersMutationVariables = Exact<{
  userIds: Array<Scalars['ID']>;
  projectId: Scalars['ID'];
}>;


export type DeleteProjectMembersMutation = (
  { __typename: 'Mutation' }
  & { deleteProjectMembers?: Maybe<(
    { __typename: 'DeleteProjectMembersPayload' }
    & Pick<DeleteProjectMembersPayload, 'success'>
  )> }
);

export type ProjectMembersEntityMembersQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
}>;


export type ProjectMembersEntityMembersQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { members: Array<(
      { __typename: 'ProjectMember' }
      & Pick<ProjectMember, 'id'>
      & { role: (
        { __typename: 'Role' }
        & Pick<Role, 'ID' | 'name'>
      ) }
    )>, entity: (
      { __typename: 'Entity' }
      & Pick<Entity, 'id'>
      & { members: Array<(
        { __typename: 'Member' }
        & Pick<Member, 'id' | 'admin' | 'pending' | 'email' | 'username' | 'name' | 'photoUrl' | 'accountType'>
        & { memberRole?: Maybe<(
          { __typename: 'Role' }
          & Pick<Role, 'ID' | 'name'>
          & { permissions: Array<(
            { __typename: 'RolePermission' }
            & Pick<RolePermission, 'name' | 'groupName' | 'displayName'>
          )> }
        )> }
      )> }
    ) }
  )> }
);

export type RampSettingsQueryVariables = Exact<{ [key: string]: never; }>;


export type RampSettingsQuery = (
  { __typename: 'Query' }
  & { rampManager?: Maybe<(
    { __typename: 'RampManager' }
    & { settings: (
      { __typename: 'RampSettingConnection' }
      & { edges: Array<(
        { __typename: 'RampSettingEdge' }
        & Pick<RampSettingEdge, 'name' | 'group' | 'description' | 'rampType' | 'rampIDType'>
        & { stores: Array<(
          { __typename: 'RampThresholdStore' }
          & Pick<RampThresholdStore, 'name'>
          & { thresholds: Array<(
            { __typename: 'RampSettingThreshold' }
            & Pick<RampSettingThreshold, 'checkId' | 'displayCheckID' | 'threshold'>
          )> }
        )> }
      )> }
    ) }
  )> }
);

export type IdIsRampedForSettingQueryVariables = Exact<{
  setting: Scalars['String'];
  id: Scalars['String'];
}>;


export type IdIsRampedForSettingQuery = (
  { __typename: 'Query' }
  & { rampManager?: Maybe<(
    { __typename: 'RampManager' }
    & { setting?: Maybe<(
      { __typename: 'RampSettingEdge' }
      & Pick<RampSettingEdge, 'idIsRamped'>
    )> }
  )> }
);

export type UpdateRampThresholdMutationVariables = Exact<{
  store: Scalars['String'];
  setting: Scalars['String'];
  checkId: Scalars['String'];
  threshold: Scalars['Float'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type UpdateRampThresholdMutation = (
  { __typename: 'Mutation' }
  & { updateRampThreshold?: Maybe<(
    { __typename: 'UpdateRampThresholdPayload' }
    & Pick<UpdateRampThresholdPayload, 'success' | 'clientMutationId'>
  )> }
);

export type FetchRepoDisplayNameByIdQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FetchRepoDisplayNameByIdQuery = (
  { __typename: 'Query' }
  & { repo?: Maybe<(
    { __typename: 'Repo' }
    & Pick<Repo, 'id' | 'displayName'>
  )> }
);

export type ReportSearchQueryVariables = Exact<{
  query: Scalars['String'];
}>;


export type ReportSearchQuery = (
  { __typename: 'Query' }
  & { reportSearch?: Maybe<(
    { __typename: 'ViewConnection' }
    & { edges: Array<(
      { __typename: 'ViewEdge' }
      & { node?: Maybe<(
        { __typename: 'View' }
        & Pick<View, 'entityName' | 'displayName' | 'id' | 'projectName'>
      )> }
    )> }
  )> }
);

export type OrganizationRolesQueryVariables = Exact<{
  orgName: Scalars['String'];
}>;


export type OrganizationRolesQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { roles: Array<(
      { __typename: 'Role' }
      & Pick<Role, 'ID' | 'name' | 'description'>
      & { inheritedFrom?: Maybe<(
        { __typename: 'Role' }
        & Pick<Role, 'name'>
      )> }
    )> }
  )> }
);

export type OrganizationRolePermissionsQueryVariables = Exact<{
  orgName: Scalars['String'];
}>;


export type OrganizationRolePermissionsQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { roles: Array<(
      { __typename: 'Role' }
      & Pick<Role, 'ID' | 'name' | 'description'>
      & { inheritedFrom?: Maybe<(
        { __typename: 'Role' }
        & Pick<Role, 'ID' | 'name'>
      )>, permissions: Array<(
        { __typename: 'RolePermission' }
        & Pick<RolePermission, 'name' | 'displayName' | 'groupName'>
      )> }
    )> }
  )> }
);

export type CreateRoleMutationVariables = Exact<{
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  inheritedFrom: Scalars['String'];
  permissions: Array<Scalars['String']>;
  organizationID: Scalars['ID'];
}>;


export type CreateRoleMutation = (
  { __typename: 'Mutation' }
  & { createRole?: Maybe<(
    { __typename: 'CreateRolePayload' }
    & Pick<CreateRolePayload, 'success'>
  )> }
);

export type UpdateRoleMutationVariables = Exact<{
  roleID: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  inheritedFrom?: Maybe<Scalars['String']>;
}>;


export type UpdateRoleMutation = (
  { __typename: 'Mutation' }
  & { updateRole?: Maybe<(
    { __typename: 'UpdateRolePayload' }
    & Pick<UpdateRolePayload, 'success'>
  )> }
);

export type DeleteRoleMutationVariables = Exact<{
  roleID: Scalars['ID'];
}>;


export type DeleteRoleMutation = (
  { __typename: 'Mutation' }
  & { deleteRole?: Maybe<(
    { __typename: 'DeleteRolePayload' }
    & Pick<DeleteRolePayload, 'success'>
  )> }
);

export type AddRolePermissionsMutationVariables = Exact<{
  roleID: Scalars['ID'];
  permissions: Array<Scalars['String']>;
}>;


export type AddRolePermissionsMutation = (
  { __typename: 'Mutation' }
  & { addRolePermissions?: Maybe<(
    { __typename: 'AddRolePermissionsPayload' }
    & Pick<AddRolePermissionsPayload, 'success'>
  )> }
);

export type DeleteRolePermissionsMutationVariables = Exact<{
  roleID: Scalars['ID'];
  permissions: Array<Scalars['String']>;
}>;


export type DeleteRolePermissionsMutation = (
  { __typename: 'Mutation' }
  & { deleteRolePermissions?: Maybe<(
    { __typename: 'DeleteRolePermissionsPayload' }
    & Pick<DeleteRolePermissionsPayload, 'success'>
  )> }
);

export type EntityMemberRoleQueryVariables = Exact<{
  entityName: Scalars['String'];
  memberId: Scalars['ID'];
}>;


export type EntityMemberRoleQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id'>
    & { member?: Maybe<(
      { __typename: 'Member' }
      & Pick<Member, 'id' | 'role'>
      & { memberRole?: Maybe<(
        { __typename: 'Role' }
        & Pick<Role, 'ID' | 'name'>
        & { inheritedFrom?: Maybe<(
          { __typename: 'Role' }
          & { permissions: Array<(
            { __typename: 'RolePermission' }
            & Pick<RolePermission, 'name' | 'groupName' | 'displayName'>
          )> }
        )>, permissions: Array<(
          { __typename: 'RolePermission' }
          & Pick<RolePermission, 'name' | 'groupName' | 'displayName'>
        )> }
      )> }
    )> }
  )> }
);

export type RunLogLinesQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  runName: Scalars['String'];
}>;


export type RunLogLinesQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { run?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id'>
      & { logLines?: Maybe<(
        { __typename: 'LogLineConnection' }
        & { edges: Array<(
          { __typename: 'LogLineEdge' }
          & { node: (
            { __typename: 'LogLine' }
            & Pick<LogLine, 'id' | 'line' | 'level'>
          ) }
        )> }
      )> }
    )> }
  )> }
);

export type RunQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  runName: Scalars['String'];
  enableArtifactCounts?: Maybe<Scalars['Boolean']>;
}>;


export type RunQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'name' | 'readOnly' | 'createdAt' | 'access' | 'views' | 'entityName' | 'isBenchmark'>
    & { tags: Array<(
      { __typename: 'RunTag' }
      & Pick<RunTag, 'id' | 'name' | 'colorIndex'>
    )>, linkedBenchmark?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id' | 'entityName' | 'name' | 'gitHubSubmissionRepo' | 'views'>
    )>, run?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id' | 'name' | 'displayName' | 'notes' | 'config' | 'summaryMetrics' | 'historyKeys' | 'github' | 'fileCount' | 'createdAt' | 'heartbeatAt' | 'computeSeconds' | 'commit' | 'jobType' | 'group' | 'host' | 'state' | 'stopped' | 'logLineCount' | 'internalID'>
      & { tags: Array<(
        { __typename: 'RunTag' }
        & Pick<RunTag, 'id' | 'name' | 'colorIndex'>
      )>, sweep?: Maybe<(
        { __typename: 'Sweep' }
        & Pick<Sweep, 'id' | 'name' | 'displayName'>
      )>, runInfo?: Maybe<(
        { __typename: 'RunInfo' }
        & Pick<RunInfo, 'program' | 'args' | 'os' | 'python' | 'colab' | 'executable' | 'codeSaved' | 'cpuCount' | 'cpuCountLogical' | 'gpuCount' | 'gpu'>
        & { git?: Maybe<(
          { __typename: 'GitInfo' }
          & Pick<GitInfo, 'remote' | 'commit'>
        )> }
      )>, diffPatchFile?: Maybe<(
        { __typename: 'FileConnection' }
        & { edges: Array<(
          { __typename: 'FileEdge' }
          & { node?: Maybe<(
            { __typename: 'File' }
            & Pick<File, 'id' | 'name' | 'md5' | 'sizeBytes'>
          )> }
        )> }
      )>, metadataFile?: Maybe<(
        { __typename: 'FileConnection' }
        & { edges: Array<(
          { __typename: 'FileEdge' }
          & { node?: Maybe<(
            { __typename: 'File' }
            & Pick<File, 'id' | 'name' | 'md5' | 'sizeBytes' | 'directUrl'>
          )> }
        )> }
      )>, outputLogFile?: Maybe<(
        { __typename: 'FileConnection' }
        & { edges: Array<(
          { __typename: 'FileEdge' }
          & { node?: Maybe<(
            { __typename: 'File' }
            & Pick<File, 'id' | 'name' | 'md5' | 'sizeBytes' | 'directUrl' | 'url'>
          )> }
        )> }
      )>, benchmarkRun?: Maybe<(
        { __typename: 'BenchmarkRun' }
        & Pick<BenchmarkRun, 'id' | 'details' | 'results' | 'gitHubSubmissionPR' | 'isCodeHidden' | 'state'>
        & { benchmark: (
          { __typename: 'Project' }
          & Pick<Project, 'id' | 'name' | 'entityName' | 'readOnly' | 'views'>
        ), run: (
          { __typename: 'Run' }
          & Pick<Run, 'id' | 'name' | 'github' | 'summaryMetrics'>
          & { user?: Maybe<(
            { __typename: 'User' }
            & Pick<User, 'id' | 'username'>
          )> }
        ), originalProject?: Maybe<(
          { __typename: 'Project' }
          & Pick<Project, 'id' | 'name' | 'entityName'>
        )>, originalRun?: Maybe<(
          { __typename: 'Run' }
          & Pick<Run, 'id' | 'name'>
        )> }
      )>, user?: Maybe<(
        { __typename: 'User' }
        & Pick<User, 'id' | 'username' | 'name' | 'photoUrl' | 'accountType'>
      )>, servicesAvailable?: Maybe<(
        { __typename: 'RunServicesAvailable' }
        & Pick<RunServicesAvailable, 'tensorboard'>
      )>, branchPoint?: Maybe<(
        { __typename: 'RunBranchPoint' }
        & Pick<RunBranchPoint, 'step'>
        & { run: (
          { __typename: 'Run' }
          & Pick<Run, 'id' | 'name'>
          & { project?: Maybe<(
            { __typename: 'Project' }
            & Pick<Project, 'id' | 'name'>
            & { entity: (
              { __typename: 'Entity' }
              & Pick<Entity, 'id' | 'name'>
            ) }
          )> }
        ) }
      )> }
      & ArtifactCountsFragmentFragment
    )>, entity: (
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'name' | 'defaultAccess' | 'readOnlyAdmin' | 'privateOnly' | 'isTeam' | 'codeSavingEnabled'>
      & { user?: Maybe<(
        { __typename: 'User' }
        & Pick<User, 'id' | 'username' | 'accountType'>
      )>, claimingEntity?: Maybe<(
        { __typename: 'Entity' }
        & Pick<Entity, 'id' | 'name'>
      )> }
    ) }
  )> }
);

export type RunQueueItemFragmentFragment = (
  { __typename: 'RunQueueItem' }
  & Pick<RunQueueItem, 'id' | 'createdAt' | 'updatedAt' | 'runSpec' | 'state' | 'associatedRunId' | 'launchAgentId' | 'priority' | 'templateVariableValues'>
);

export type CreateRunQueueMutationVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  queueName: Scalars['String'];
  access: RunQueueAccessType;
  defaultResourceConfigID?: Maybe<Scalars['ID']>;
  prioritizationMode: RunQueuePrioritizationMode;
}>;


export type CreateRunQueueMutation = (
  { __typename: 'Mutation' }
  & { createRunQueue?: Maybe<(
    { __typename: 'CreateRunQueuePayload' }
    & Pick<CreateRunQueuePayload, 'success' | 'queueID'>
  )> }
);

export type UpdateRunQueueMutationVariables = Exact<{
  queueID: Scalars['ID'];
  defaultResourceConfigID?: Maybe<Scalars['ID']>;
  externalLinks?: Maybe<Scalars['JSONString']>;
}>;


export type UpdateRunQueueMutation = (
  { __typename: 'Mutation' }
  & { updateRunQueue?: Maybe<(
    { __typename: 'UpdateRunQueuePayload' }
    & Pick<UpdateRunQueuePayload, 'success'>
  )> }
);

export type EnableRunQueuePrioritizationMutationVariables = Exact<{
  queueID: Scalars['ID'];
}>;


export type EnableRunQueuePrioritizationMutation = (
  { __typename: 'Mutation' }
  & { updateRunQueue?: Maybe<(
    { __typename: 'UpdateRunQueuePayload' }
    & Pick<UpdateRunQueuePayload, 'success'>
  )> }
);

export type DeleteRunQueuesMutationVariables = Exact<{
  queueIDs: Array<Scalars['ID']>;
}>;


export type DeleteRunQueuesMutation = (
  { __typename: 'Mutation' }
  & { deleteRunQueues?: Maybe<(
    { __typename: 'DeleteRunQueuesPayload' }
    & Pick<DeleteRunQueuesPayload, 'success'>
  )> }
);

export type PushToRunQueueMutationVariables = Exact<{
  queueID: Scalars['ID'];
  runSpec: Scalars['JSONString'];
  priority?: Maybe<Scalars['Int']>;
  templateVariableValues?: Maybe<Scalars['JSONString']>;
}>;


export type PushToRunQueueMutation = (
  { __typename: 'Mutation' }
  & { pushToRunQueue?: Maybe<(
    { __typename: 'PushToRunQueuePayload' }
    & Pick<PushToRunQueuePayload, 'runQueueItemId'>
  )> }
);

export type DeleteFromRunQueueMutationVariables = Exact<{
  queueID: Scalars['ID'];
  runQueueItemId: Scalars['ID'];
}>;


export type DeleteFromRunQueueMutation = (
  { __typename: 'Mutation' }
  & { deleteFromRunQueue?: Maybe<(
    { __typename: 'DeleteFromRunQueuePayload' }
    & Pick<DeleteFromRunQueuePayload, 'success'>
  )> }
);

export type FetchRunQueuesFromProjectQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
}>;


export type FetchRunQueuesFromProjectQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { runQueues: Array<(
      { __typename: 'RunQueue' }
      & Pick<RunQueue, 'id' | 'createdAt' | 'createdBy' | 'access' | 'updatedAt' | 'name' | 'defaultResourceConfigID' | 'executorInfo'>
      & { runQueueItems: (
        { __typename: 'RunQueueItemConnection' }
        & { edges: Array<(
          { __typename: 'RunQueueItemEdge' }
          & { node: (
            { __typename: 'RunQueueItem' }
            & Pick<RunQueueItem, 'id'>
            & RunQueueItemFragmentFragment
          ) }
        )> }
      ) }
    )> }
  )> }
);

export type FetchRunQueueByNameQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  runQueueName: Scalars['String'];
}>;


export type FetchRunQueueByNameQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { runQueue?: Maybe<(
      { __typename: 'RunQueue' }
      & Pick<RunQueue, 'id' | 'createdAt' | 'createdBy' | 'access' | 'updatedAt' | 'name' | 'defaultResourceConfigID' | 'executorInfo'>
      & { runQueueItems: (
        { __typename: 'RunQueueItemConnection' }
        & { edges: Array<(
          { __typename: 'RunQueueItemEdge' }
          & { node: (
            { __typename: 'RunQueueItem' }
            & Pick<RunQueueItem, 'id'>
            & RunQueueItemFragmentFragment
          ) }
        )> }
      ) }
    )> }
  )> }
);

export type FetchRunQueueItemFromProjectQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  runQueueName: Scalars['String'];
  runQueueItemID: Scalars['ID'];
}>;


export type FetchRunQueueItemFromProjectQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { runQueue?: Maybe<(
      { __typename: 'RunQueue' }
      & Pick<RunQueue, 'id'>
      & { runQueueItem?: Maybe<(
        { __typename: 'RunQueueItem' }
        & Pick<RunQueueItem, 'id'>
        & RunQueueItemFragmentFragment
      )> }
    )> }
  )> }
);

export type TeamInfoQueryVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type TeamInfoQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id'>
    & { members: Array<(
      { __typename: 'Member' }
      & Pick<Member, 'id' | 'username' | 'name' | 'admin'>
    )> }
  )> }
);

export type RunDisplayNameQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  runName: Scalars['String'];
}>;


export type RunDisplayNameQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { run?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id' | 'displayName'>
    )> }
  )> }
);

export type RunsStateQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
}>;


export type RunsStateQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { runs?: Maybe<(
      { __typename: 'RunConnection' }
      & { edges: Array<(
        { __typename: 'RunEdge' }
        & { node: (
          { __typename: 'Run' }
          & Pick<Run, 'id' | 'name' | 'displayName' | 'state'>
        ) }
      )> }
    )> }
  )> }
);

export type RunSystemMetricsQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  runName: Scalars['String'];
}>;


export type RunSystemMetricsQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { run?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id' | 'events'>
    )> }
  )> }
);

export type RunTensorboardQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  runName: Scalars['String'];
}>;


export type RunTensorboardQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { run?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id'>
      & { servicesAvailable?: Maybe<(
        { __typename: 'RunServicesAvailable' }
        & Pick<RunServicesAvailable, 'tensorboard'>
      )> }
    )> }
  )> }
);

export type BasicRunFragmentFragment = (
  { __typename: 'Run' }
  & Pick<Run, 'id' | 'readOnly' | 'framework' | 'displayName' | 'notes' | 'github' | 'group' | 'jobType' | 'createdAt' | 'heartbeatAt' | 'commit' | 'host' | 'state' | 'shouldStop' | 'groupCounts' | 'stopped' | 'defaultColorIndex'>
  & { sweep?: Maybe<(
    { __typename: 'Sweep' }
    & Pick<Sweep, 'id' | 'name' | 'displayName'>
  )>, agent?: Maybe<(
    { __typename: 'Agent' }
    & Pick<Agent, 'id' | 'name'>
  )>, user?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'username' | 'photoUrl'>
  )>, tags: Array<(
    { __typename: 'RunTag' }
    & Pick<RunTag, 'id' | 'name' | 'colorIndex'>
  )>, benchmarkRun?: Maybe<(
    { __typename: 'BenchmarkRun' }
    & Pick<BenchmarkRun, 'id'>
  )>, runInfo?: Maybe<(
    { __typename: 'RunInfo' }
    & Pick<RunInfo, 'gpuCount' | 'gpu'>
  )> }
);

export type DetailedRunFragmentFragment = (
  { __typename: 'Run' }
  & Pick<Run, 'history' | 'events' | 'fileCount'>
  & { logLines?: Maybe<(
    { __typename: 'LogLineConnection' }
    & { edges: Array<(
      { __typename: 'LogLineEdge' }
      & { node: (
        { __typename: 'LogLine' }
        & Pick<LogLine, 'id' | 'line' | 'level'>
      ) }
    )> }
  )> }
);

export type RunFilesFragmentFragment = (
  { __typename: 'Run' }
  & { files?: Maybe<(
    { __typename: 'FileConnection' }
    & { edges: Array<(
      { __typename: 'FileEdge' }
      & { node?: Maybe<(
        { __typename: 'File' }
        & Pick<File, 'id' | 'name' | 'directUrl' | 'url' | 'sizeBytes' | 'updatedAt'>
      )> }
    )>, pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<PageInfo, 'endCursor' | 'hasNextPage'>
    ) }
  )> }
);

export type SelectRunFragmentFragment = (
  { __typename: 'Run' }
  & Pick<Run, 'config' | 'summaryMetrics' | 'systemMetrics'>
);

export type HistoryRunFragmentFragment = (
  { __typename: 'Run' }
  & Pick<Run, 'history'>
);

export type SampledHistoryRunFragmentFragment = (
  { __typename: 'Run' }
  & Pick<Run, 'sampledHistory'>
);

export type RunsModelQueryVariables = Exact<{
  cursor?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  jobKey?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
  limit?: Maybe<Scalars['Int']>;
  bucketIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  requestSubscribe: Scalars['Boolean'];
  selectEnable?: Maybe<Scalars['Boolean']>;
  basicEnable?: Maybe<Scalars['Boolean']>;
  history?: Maybe<Scalars['Boolean']>;
  historyKeyInfo?: Maybe<Scalars['Boolean']>;
  historySamples?: Maybe<Scalars['Int']>;
  sampledHistory?: Maybe<Scalars['Boolean']>;
  fields?: Maybe<Array<Maybe<Scalars['String']>>>;
  historyFields?: Maybe<Array<Maybe<Scalars['String']>>>;
  sampledHistorySpecs: Array<Scalars['JSONString']>;
  groupKeys?: Maybe<Array<Maybe<Scalars['String']>>>;
  groupLevel?: Maybe<Scalars['Int']>;
}>;


export type RunsModelQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'name' | 'createdAt' | 'entityName' | 'description' | 'summaryMetrics' | 'views' | 'requestSubscribe' | 'runCount'>
    & { runs?: Maybe<(
      { __typename: 'RunConnection' }
      & Pick<RunConnection, 'totalCount'>
      & { edges: Array<(
        { __typename: 'RunEdge' }
        & { node: (
          { __typename: 'Run' }
          & Pick<Run, 'id' | 'name' | 'displayName' | 'historyKeys'>
          & SelectRunFragmentFragment
          & BasicRunFragmentFragment
          & HistoryRunFragmentFragment
          & SampledHistoryRunFragmentFragment
        ) }
      )>, pageInfo: (
        { __typename: 'PageInfo' }
        & Pick<PageInfo, 'startCursor' | 'hasPreviousPage' | 'endCursor' | 'hasNextPage'>
      ) }
    )> }
  )>, viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'email' | 'photoUrl' | 'admin'>
  )>, serverInfo?: Maybe<(
    { __typename: 'ServerInfo' }
    & Pick<ServerInfo, 'pollingOK'>
  )> }
);

export type UpsertRunMutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
}>;


export type UpsertRunMutation = (
  { __typename: 'Mutation' }
  & { upsertBucket?: Maybe<(
    { __typename: 'UpsertBucketPayload' }
    & Pick<UpsertBucketPayload, 'inserted'>
    & { bucket?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id' | 'name' | 'displayName' | 'notes'>
      & { tags: Array<(
        { __typename: 'RunTag' }
        & Pick<RunTag, 'id' | 'name' | 'colorIndex'>
      )>, project?: Maybe<(
        { __typename: 'Project' }
        & Pick<Project, 'id'>
        & { tags: Array<(
          { __typename: 'RunTag' }
          & Pick<RunTag, 'id' | 'name' | 'colorIndex'>
        )> }
      )> }
    )> }
  )> }
);

export type UpsertRunGroupMutationVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  name: Scalars['String'];
  newName?: Maybe<Scalars['String']>;
  notes?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Scalars['String']>>;
}>;


export type UpsertRunGroupMutation = (
  { __typename: 'Mutation' }
  & { upsertRunGroup?: Maybe<(
    { __typename: 'UpsertRunGroupPayload' }
    & { group?: Maybe<(
      { __typename: 'RunGroup' }
      & Pick<RunGroup, 'id' | 'name' | 'notes'>
      & { tags: Array<(
        { __typename: 'RunTag' }
        & Pick<RunTag, 'id' | 'name' | 'colorIndex'>
      )>, project: (
        { __typename: 'Project' }
        & Pick<Project, 'id'>
        & { tags: Array<(
          { __typename: 'RunTag' }
          & Pick<RunTag, 'id' | 'name' | 'colorIndex'>
        )> }
      ) }
    )> }
  )> }
);

export type ModifyRunsMutationVariables = Exact<{
  filters?: Maybe<Scalars['JSONString']>;
  entityName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  addTags?: Maybe<Array<Maybe<Scalars['String']>>>;
  removeTags?: Maybe<Array<Maybe<Scalars['String']>>>;
}>;


export type ModifyRunsMutation = (
  { __typename: 'Mutation' }
  & { modifyRuns?: Maybe<(
    { __typename: 'ModifyRunsPayload' }
    & { runsSQL: Array<Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id' | 'name'>
      & { user?: Maybe<(
        { __typename: 'User' }
        & Pick<User, 'id' | 'username' | 'photoUrl'>
      )> }
      & SelectRunFragmentFragment
      & BasicRunFragmentFragment
    )>> }
  )> }
);

export type MoveRunsMutationVariables = Exact<{
  filters: Scalars['JSONString'];
  sourceEntityName: Scalars['String'];
  sourceProjectName: Scalars['String'];
  destinationEntityName: Scalars['String'];
  destinationProjectName: Scalars['String'];
}>;


export type MoveRunsMutation = (
  { __typename: 'Mutation' }
  & { moveRuns?: Maybe<(
    { __typename: 'MoveRunsPayload' }
    & { task: (
      { __typename: 'Task' }
      & Pick<Task, 'id'>
    ) }
  )> }
);

export type DeleteRunsMutationVariables = Exact<{
  filters: Scalars['JSONString'];
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  deleteArtifacts: Scalars['Boolean'];
}>;


export type DeleteRunsMutation = (
  { __typename: 'Mutation' }
  & { deleteRuns?: Maybe<(
    { __typename: 'DeleteRunsPayload' }
    & Pick<DeleteRunsPayload, 'clientMutationId' | 'errors'>
  )> }
);

export type UndeleteRunsMutationVariables = Exact<{
  filters: Scalars['JSONString'];
  entityName: Scalars['String'];
  projectName: Scalars['String'];
}>;


export type UndeleteRunsMutation = (
  { __typename: 'Mutation' }
  & { undeleteRuns?: Maybe<(
    { __typename: 'UndeleteRunsPayload' }
    & Pick<UndeleteRunsPayload, 'clientMutationId'>
  )> }
);

export type DeleteRunMutationVariables = Exact<{
  id: Scalars['ID'];
  deleteArtifacts?: Maybe<Scalars['Boolean']>;
}>;


export type DeleteRunMutation = (
  { __typename: 'Mutation' }
  & { deleteRun?: Maybe<(
    { __typename: 'DeleteRunPayload' }
    & Pick<DeleteRunPayload, 'clientMutationId'>
  )> }
);

export type StopRunMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type StopRunMutation = (
  { __typename: 'Mutation' }
  & { stopRun?: Maybe<(
    { __typename: 'StopRunPayload' }
    & Pick<StopRunPayload, 'clientMutationId'>
  )> }
);

export type RunStopMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RunStopMutation = (
  { __typename: 'Mutation' }
  & { stopRun?: Maybe<(
    { __typename: 'StopRunPayload' }
    & Pick<StopRunPayload, 'success'>
  )> }
);

export type DeleteFilesMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
  projectId: Scalars['Int'];
}>;


export type DeleteFilesMutation = (
  { __typename: 'Mutation' }
  & { deleteFiles?: Maybe<(
    { __typename: 'DeleteFilesPayload' }
    & Pick<DeleteFilesPayload, 'clientMutationId' | 'success'>
  )> }
);

export type DeleteFilesByRunAndFolderMutationVariables = Exact<{
  runName: Scalars['String'];
  projectId: Scalars['Int'];
  folders: Array<Scalars['String']>;
}>;


export type DeleteFilesByRunAndFolderMutation = (
  { __typename: 'Mutation' }
  & { deleteFilesByRunAndFolder?: Maybe<(
    { __typename: 'DeleteFilesPayload' }
    & Pick<DeleteFilesPayload, 'clientMutationId' | 'success'>
  )> }
);

export type InsertSecretMutationVariables = Exact<{
  entityName: Scalars['String'];
  secretName: Scalars['String'];
  secretValue: Scalars['String'];
}>;


export type InsertSecretMutation = (
  { __typename: 'Mutation' }
  & { insertSecret?: Maybe<(
    { __typename: 'InsertSecretPayload' }
    & Pick<InsertSecretPayload, 'success'>
  )> }
);

export type DeleteSecretMutationVariables = Exact<{
  entityName: Scalars['String'];
  secretName: Scalars['String'];
}>;


export type DeleteSecretMutation = (
  { __typename: 'Mutation' }
  & { deleteSecret?: Maybe<(
    { __typename: 'DeleteSecretPayload' }
    & Pick<DeleteSecretPayload, 'success'>
  )> }
);

export type SecretsQueryVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type SecretsQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id'>
    & { secrets: Array<(
      { __typename: 'Secret' }
      & Pick<Secret, 'entityId' | 'name' | 'createdAt'>
    )> }
  )> }
);

export type ServerInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type ServerInfoQuery = (
  { __typename: 'Query' }
  & { serverInfo?: Maybe<(
    { __typename: 'ServerInfo' }
    & Pick<ServerInfo, 'isGoogleIap' | 'slackClientID'>
    & { availableCloudRegions: Array<(
      { __typename: 'CloudRegion' }
      & Pick<CloudRegion, 'id' | 'provider' | 'region'>
    )> }
  )> }
);

export type LicenseExpirationUiQueryVariables = Exact<{ [key: string]: never; }>;


export type LicenseExpirationUiQuery = (
  { __typename: 'Query' }
  & { serverInfo?: Maybe<(
    { __typename: 'ServerInfo' }
    & { licenseExpirationUI?: Maybe<(
      { __typename: 'LicenseExpirationUI' }
      & Pick<LicenseExpirationUi, 'bannerTotalDays' | 'bannerDaysRemaining' | 'renewUrl'>
    )> }
  )> }
);

export type UserLimitReachedQueryVariables = Exact<{ [key: string]: never; }>;


export type UserLimitReachedQuery = (
  { __typename: 'Query' }
  & { serverInfo?: Maybe<(
    { __typename: 'ServerInfo' }
    & Pick<ServerInfo, 'userLimitReached' | 'viewOnlyUserLimitReached' | 'licenseFlags'>
  )> }
);

export type ViewOnlyUserLimitReachedQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewOnlyUserLimitReachedQuery = (
  { __typename: 'Query' }
  & { serverInfo?: Maybe<(
    { __typename: 'ServerInfo' }
    & Pick<ServerInfo, 'viewOnlyUserLimitReached'>
  )> }
);

export type RegisteredModelLimitReachedQueryVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type RegisteredModelLimitReachedQuery = (
  { __typename: 'Query' }
  & { serverInfo?: Maybe<(
    { __typename: 'ServerInfo' }
    & Pick<ServerInfo, 'registeredModelLimitReached'>
  )> }
);

export type ActionHistoryLimitQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
  artifactCollectionID?: Maybe<Scalars['ID']>;
}>;


export type ActionHistoryLimitQuery = (
  { __typename: 'Query' }
  & { serverInfo?: Maybe<(
    { __typename: 'ServerInfo' }
    & Pick<ServerInfo, 'actionHistoryLimit'>
  )> }
);

export type FrontendHostQueryVariables = Exact<{ [key: string]: never; }>;


export type FrontendHostQuery = (
  { __typename: 'Query' }
  & { serverInfo?: Maybe<(
    { __typename: 'ServerInfo' }
    & Pick<ServerInfo, 'frontendHost'>
  )> }
);

export type DeploymentIdQueryVariables = Exact<{ [key: string]: never; }>;


export type DeploymentIdQuery = (
  { __typename: 'Query' }
  & { serverInfo?: Maybe<(
    { __typename: 'ServerInfo' }
    & Pick<ServerInfo, 'deploymentId'>
  )> }
);

export type SecureStorageConnectorEnabledQueryVariables = Exact<{ [key: string]: never; }>;


export type SecureStorageConnectorEnabledQuery = (
  { __typename: 'Query' }
  & { serverInfo?: Maybe<(
    { __typename: 'ServerInfo' }
    & Pick<ServerInfo, 'secureStorageConnectorEnabled'>
  )> }
);

export type AvailableBucketProvidersQueryVariables = Exact<{ [key: string]: never; }>;


export type AvailableBucketProvidersQuery = (
  { __typename: 'Query' }
  & { serverInfo?: Maybe<(
    { __typename: 'ServerInfo' }
    & Pick<ServerInfo, 'availableBucketProviders'>
  )> }
);

export type DataRetentionDurationQueryVariables = Exact<{ [key: string]: never; }>;


export type DataRetentionDurationQuery = (
  { __typename: 'Query' }
  & { serverInfo?: Maybe<(
    { __typename: 'ServerInfo' }
    & Pick<ServerInfo, 'dataRetentionDuration'>
  )> }
);

export type FailedPaymentBannersQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FailedPaymentBannersQuery = (
  { __typename: 'Query' }
  & { failedPaymentBanners?: Maybe<(
    { __typename: 'FailedPaymentBanners' }
    & Pick<FailedPaymentBanners, 'userID'>
    & { orgsWithFailedPayments: Array<Maybe<(
      { __typename: 'OrganizationDataForFailedPaymentBanner' }
      & Pick<OrganizationDataForFailedPaymentBanner, 'id' | 'orgId' | 'orgName' | 'isBillingUser' | 'billingEmail' | 'daysUntilCancellation' | 'isCollectionMethodACH'>
    )>> }
  )> }
);

export type StarredProjectsQueryVariables = Exact<{
  organizationId?: Maybe<Scalars['ID']>;
}>;


export type StarredProjectsQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { starredProjects?: Maybe<(
      { __typename: 'ProjectConnection' }
      & { edges: Array<(
        { __typename: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename: 'Project' }
          & Pick<Project, 'id'>
          & AccountProjectFragmentFragment
        )> }
      )> }
    )> }
  )> }
);

export type CreateStorageSubscriptionMutationVariables = Exact<{
  organizationId?: Maybe<Scalars['ID']>;
  paymentMethod?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type CreateStorageSubscriptionMutation = (
  { __typename: 'Mutation' }
  & { createStorageSubscription?: Maybe<(
    { __typename: 'CreateStorageSubscriptionPayload' }
    & Pick<CreateStorageSubscriptionPayload, 'subscription' | 'clientMutationId'>
  )> }
);

export type SubscriptionPlansQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscriptionPlansQuery = (
  { __typename: 'Query' }
  & { plans: Array<Maybe<(
    { __typename: 'Plan' }
    & Pick<Plan, 'id' | 'name' | 'displayName' | 'stripePlanId' | 'maxSeats' | 'defaultPrivileges' | 'planType' | 'planVisibility' | 'unitPrice' | 'billingInterval'>
  )>> }
);

export type UpsertSweepMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  description?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  config?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  priorRunsFilters?: Maybe<Scalars['JSONString']>;
  launchScheduler?: Maybe<Scalars['JSONString']>;
}>;


export type UpsertSweepMutation = (
  { __typename: 'Mutation' }
  & { upsertSweep?: Maybe<(
    { __typename: 'UpsertSweepPayload' }
    & Pick<UpsertSweepPayload, 'inserted' | 'insertedPriorRuns'>
    & { sweep?: Maybe<(
      { __typename: 'Sweep' }
      & Pick<Sweep, 'id' | 'name' | 'displayName' | 'description' | 'state'>
    )> }
  )> }
);

export type DeleteSweepsMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
  deleteRuns: Scalars['Boolean'];
}>;


export type DeleteSweepsMutation = (
  { __typename: 'Mutation' }
  & { deleteSweeps?: Maybe<(
    { __typename: 'DeleteSweepsPayload' }
    & Pick<DeleteSweepsPayload, 'affectedAgents' | 'affectedRuns'>
  )> }
);

export type GetTaskQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetTaskQuery = (
  { __typename: 'Query' }
  & { task?: Maybe<(
    { __typename: 'Task' }
    & Pick<Task, 'id' | 'name' | 'state' | 'progress' | 'createdAt' | 'updatedAt' | 'type'>
  )> }
);

export type EntityIsTeamQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
}>;


export type EntityIsTeamQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id' | 'isTeam'>
  )> }
);

export type BasicTeamOrganizationQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
}>;


export type BasicTeamOrganizationQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id' | 'isTeam' | 'available'>
    & { organization?: Maybe<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
    )> }
  )> }
);

export type TeamOrganizationQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
}>;


export type TeamOrganizationQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id' | 'isTeam' | 'computeHours'>
    & { settings: (
      { __typename: 'EntitySettings' }
      & Pick<EntitySettings, 'openToMatchingOrgEmailDomainUsers' | 'membersCanInvite' | 'disableMagicLinkSharing'>
    ), organization?: Maybe<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'flags' | 'usedSeats' | 'orgType'>
      & { roles: Array<(
        { __typename: 'Role' }
        & Pick<Role, 'ID' | 'name' | 'description'>
        & { inheritedFrom?: Maybe<(
          { __typename: 'Role' }
          & Pick<Role, 'name'>
        )> }
      )>, members: Array<(
        { __typename: 'OrganizationMember' }
        & Pick<OrganizationMember, 'orgID' | 'id' | 'username' | 'role' | 'modelsSeat'>
      )>, seatAvailability: (
        { __typename: 'OrganizationSeatAvailability' }
        & Pick<OrganizationSeatAvailability, 'seats' | 'viewOnlySeats'>
      ), subscriptions: Array<(
        { __typename: 'OrganizationSubscription' }
        & Pick<OrganizationSubscription, 'id' | 'privileges' | 'subscriptionType' | 'billingPeriodStart' | 'billingPeriodEnd' | 'availableSeatsToPurchase' | 'seats'>
        & { plan: (
          { __typename: 'Plan' }
          & Pick<Plan, 'id' | 'name' | 'planType' | 'unitPrice' | 'billingInterval' | 'defaultPrivileges' | 'maxSeats'>
        ) }
      )> }
    )> }
  )> }
);

export type TeamOrganizationRolesQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
}>;


export type TeamOrganizationRolesQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id'>
    & { settings: (
      { __typename: 'EntitySettings' }
      & Pick<EntitySettings, 'membersCanInvite'>
    ), members: Array<(
      { __typename: 'Member' }
      & Pick<Member, 'id' | 'username' | 'role'>
    )>, organization?: Maybe<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id'>
      & { seatAvailability: (
        { __typename: 'OrganizationSeatAvailability' }
        & Pick<OrganizationSeatAvailability, 'viewOnlySeats'>
      ), roles: Array<(
        { __typename: 'Role' }
        & Pick<Role, 'ID' | 'name' | 'description'>
        & { inheritedFrom?: Maybe<(
          { __typename: 'Role' }
          & Pick<Role, 'name'>
        )> }
      )>, members: Array<(
        { __typename: 'OrganizationMember' }
        & Pick<OrganizationMember, 'id' | 'username' | 'role'>
      )> }
    )> }
  )> }
);

export type TeamPageProjectsTwoQueryVariables = Exact<{
  entityName: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
}>;


export type TeamPageProjectsTwoQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id' | 'organizationId'>
    & { projects?: Maybe<(
      { __typename: 'ProjectConnection' }
      & { edges: Array<(
        { __typename: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename: 'Project' }
          & Pick<Project, 'id' | 'name' | 'entityName' | 'description' | 'totalRuns' | 'lastActive'>
        )> }
      )> }
    )> }
  )> }
);

export type TeamPageProjectsQueryVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type TeamPageProjectsQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id' | 'organizationId'>
    & { projects?: Maybe<(
      { __typename: 'ProjectConnection' }
      & { edges: Array<(
        { __typename: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename: 'Project' }
          & Pick<Project, 'id' | 'name' | 'entityName' | 'access'>
          & { allViews?: Maybe<(
            { __typename: 'ViewConnection' }
            & { edges: Array<(
              { __typename: 'ViewEdge' }
              & { node?: Maybe<(
                { __typename: 'View' }
                & Pick<View, 'id'>
                & ReportsTableFragmentFragment
              )> }
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type FetchTeamProfilePageProjectsByIDsQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectIDs: Array<Scalars['ID']>;
}>;


export type FetchTeamProfilePageProjectsByIDsQuery = (
  { __typename: 'Query' }
  & { projects?: Maybe<(
    { __typename: 'ProjectConnection' }
    & { edges: Array<(
      { __typename: 'ProjectEdge' }
      & { node?: Maybe<(
        { __typename: 'Project' }
        & Pick<Project, 'id' | 'name' | 'entityName' | 'access' | 'lastActive' | 'runCount'>
        & { user?: Maybe<(
          { __typename: 'User' }
          & Pick<User, 'id' | 'username'>
        )> }
      )> }
    )> }
  )> }
);

export type TeamPageQueryVariables = Exact<{
  entityName: Scalars['String'];
  workflowsAdminType: WorkflowsAdminType;
}>;


export type TeamPageQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'available' | 'id' | 'isTeam' | 'memberCount' | 'name' | 'organizationId' | 'photoUrl' | 'projectCount' | 'readOnly' | 'protectedAliases'>
    & { claimingEntity?: Maybe<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'name'>
    )>, members: Array<(
      { __typename: 'Member' }
      & Pick<Member, 'id' | 'admin' | 'pending' | 'email' | 'username' | 'name' | 'photoUrl' | 'accountType' | 'apiKey' | 'role'>
      & { memberRole?: Maybe<(
        { __typename: 'Role' }
        & Pick<Role, 'ID' | 'name'>
      )> }
    )>, invites?: Maybe<(
      { __typename: 'InviteConnection' }
      & { edges: Array<(
        { __typename: 'InviteEdge' }
        & { node?: Maybe<(
          { __typename: 'Invite' }
          & Pick<Invite, 'id' | 'email'>
          & { fromUser?: Maybe<(
            { __typename: 'User' }
            & Pick<User, 'id' | 'username'>
          )> }
        )> }
      )> }
    )>, projects?: Maybe<(
      { __typename: 'ProjectConnection' }
      & { edges: Array<(
        { __typename: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename: 'Project' }
          & Pick<Project, 'id'>
        )> }
      )> }
    )>, workflowsAdmins: Array<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'username' | 'name' | 'email'>
    )> }
  )> }
);

export type AvailableTeamQueryVariables = Exact<{
  teamName: Scalars['String'];
}>;


export type AvailableTeamQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id' | 'available'>
  )> }
);

export type AvailableOrgQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type AvailableOrgQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'available'>
  )> }
);

export type OrganizationStorageBucketInfosQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type OrganizationStorageBucketInfosQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { teams: Array<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id'>
      & { settings: (
        { __typename: 'EntitySettings' }
        & { storageBucketInfo?: Maybe<(
          { __typename: 'StorageBucketInfo' }
          & Pick<StorageBucketInfo, 'ID' | 'provider' | 'name' | 'kmsKeyID' | 'awsExternalID' | 'path'>
        )> }
      ) }
    )> }
  )> }
);

export type StorageBucketInfosQueryVariables = Exact<{ [key: string]: never; }>;


export type StorageBucketInfosQuery = (
  { __typename: 'Query' }
  & { entities?: Maybe<(
    { __typename: 'EntityConnection' }
    & { edges: Array<(
      { __typename: 'EntityEdge' }
      & { node?: Maybe<(
        { __typename: 'Entity' }
        & Pick<Entity, 'id'>
        & { settings: (
          { __typename: 'EntitySettings' }
          & { storageBucketInfo?: Maybe<(
            { __typename: 'StorageBucketInfo' }
            & Pick<StorageBucketInfo, 'ID' | 'provider' | 'name' | 'kmsKeyID' | 'awsExternalID' | 'path'>
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type CreateTeamMutationVariables = Exact<{
  teamName: Scalars['String'];
  organizationId?: Maybe<Scalars['String']>;
  teamAdminUserName?: Maybe<Scalars['String']>;
  storageBucketInfo?: Maybe<StorageBucketInfoInput>;
}>;


export type CreateTeamMutation = (
  { __typename: 'Mutation' }
  & { createTeam?: Maybe<(
    { __typename: 'CreateTeamPayload' }
    & { entity?: Maybe<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'name' | 'available' | 'photoUrl' | 'limits'>
    )> }
  )> }
);

export type TestBucketStoreConnectionMutationVariables = Exact<{
  input: StorageBucketInfoInput;
}>;


export type TestBucketStoreConnectionMutation = (
  { __typename: 'Mutation' }
  & { testBucketStoreConnection: Array<(
    { __typename: 'BucketStoreConnectionError' }
    & Pick<BucketStoreConnectionError, 'message' | 'severity'>
  )> }
);

export type DeleteTeamMutationVariables = Exact<{
  teamName: Scalars['String'];
}>;


export type DeleteTeamMutation = (
  { __typename: 'Mutation' }
  & { deleteTeam?: Maybe<(
    { __typename: 'DeleteTeamPayload' }
    & Pick<DeleteTeamPayload, 'success'>
  )> }
);

export type EntityFeatureFlagsQueryVariables = Exact<{
  teamName?: Maybe<Scalars['String']>;
  rampIDType: RampIdType;
}>;


export type EntityFeatureFlagsQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id'>
    & { featureFlags: Array<Maybe<(
      { __typename: 'FeatureFlag' }
      & Pick<FeatureFlag, 'rampKey' | 'isEnabled'>
    )>> }
  )> }
);

export type OrganizationTeamsQueryVariables = Exact<{
  orgName: Scalars['String'];
}>;


export type OrganizationTeamsQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { teams: Array<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'name' | 'memberCount' | 'computeHours'>
    )>, subscriptions: Array<(
      { __typename: 'OrganizationSubscription' }
      & Pick<OrganizationSubscription, 'id' | 'privileges'>
    )>, members: Array<(
      { __typename: 'OrganizationMember' }
      & Pick<OrganizationMember, 'orgID' | 'id' | 'username' | 'role'>
      & { user: (
        { __typename: 'User' }
        & Pick<User, 'id' | 'name' | 'accountType'>
        & { teams?: Maybe<(
          { __typename: 'EntityConnection' }
          & { edges: Array<(
            { __typename: 'EntityEdge' }
            & { node?: Maybe<(
              { __typename: 'Entity' }
              & Pick<Entity, 'id' | 'name'>
            )> }
          )> }
        )> }
        & UserRoleFragmentFragment
      ) }
    )> }
  )> }
);

export type EntityOrganizationQueryVariables = Exact<{
  teamName: Scalars['String'];
}>;


export type EntityOrganizationQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id'>
    & { organization?: Maybe<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
    )> }
  )> }
);

export type UserContextQueryVariables = Exact<{ [key: string]: never; }>;


export type UserContextQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { views?: Maybe<(
      { __typename: 'ViewConnection' }
      & { edges: Array<(
        { __typename: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename: 'View' }
          & Pick<View, 'id' | 'spec'>
        )> }
      )> }
    )> }
  )> }
);

export type ViewerUserInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerUserInfoQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'userInfo'>
  )> }
);

export type UserOrganizationsQueryVariables = Exact<{
  emailDomain?: Maybe<Scalars['String']>;
}>;


export type UserOrganizationsQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { organizations: Array<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'orgType'>
      & { members: Array<(
        { __typename: 'OrganizationMember' }
        & Pick<OrganizationMember, 'id' | 'admin'>
      )>, teams: Array<(
        { __typename: 'Entity' }
        & Pick<Entity, 'id' | 'name'>
      )>, subscriptions: Array<(
        { __typename: 'OrganizationSubscription' }
        & Pick<OrganizationSubscription, 'id' | 'subscriptionType' | 'privileges'>
        & { plan: (
          { __typename: 'Plan' }
          & Pick<Plan, 'id' | 'name' | 'planType' | 'displayName'>
        ) }
      )> }
    )>, openMatchingOrganizations: Array<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'orgType' | 'flags'>
      & { members: Array<(
        { __typename: 'OrganizationMember' }
        & Pick<OrganizationMember, 'orgID' | 'id' | 'admin'>
      )>, subscriptions: Array<(
        { __typename: 'OrganizationSubscription' }
        & Pick<OrganizationSubscription, 'id' | 'status'>
      )>, teams: Array<(
        { __typename: 'Entity' }
        & Pick<Entity, 'id' | 'name' | 'memberCount'>
        & { settings: (
          { __typename: 'EntitySettings' }
          & Pick<EntitySettings, 'openToMatchingOrgEmailDomainUsers'>
        ) }
      )> }
    )> }
  )> }
);

export type EntityStorageBytesQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type EntityStorageBytesQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id' | 'storageBytes'>
  )> }
);

export type EntityQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type EntityQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id' | 'name' | 'available' | 'photoUrl' | 'readOnly' | 'readOnlyAdmin' | 'isTeam' | 'privateOnly' | 'codeSavingEnabled' | 'artifactTTLDurationSeconds' | 'defaultAccess' | 'isPaid' | 'computeHours'>
    & { settings: (
      { __typename: 'EntitySettings' }
      & Pick<EntitySettings, 'hidden' | 'openToMatchingOrgEmailDomainUsers' | 'membersCanInvite' | 'disableMagicLinkSharing' | 'disableMembersEditArtifactTTL'>
      & { defaultCloudRegion: (
        { __typename: 'CloudRegion' }
        & Pick<CloudRegion, 'id' | 'provider' | 'region'>
      ), storageBucketInfo?: Maybe<(
        { __typename: 'StorageBucketInfo' }
        & Pick<StorageBucketInfo, 'provider' | 'name' | 'kmsKeyID' | 'awsExternalID' | 'path'>
      )> }
    ), rateLimits?: Maybe<(
      { __typename: 'RateLimits' }
      & Pick<RateLimits, 'graphql' | 'sdkGraphql' | 'filestreamCount' | 'filestreamSize' | 'sdkGraphqlQuerySeconds'>
    )>, members: Array<(
      { __typename: 'Member' }
      & Pick<Member, 'id' | 'admin' | 'role' | 'pending' | 'email' | 'username' | 'name' | 'photoUrl' | 'accountType' | 'apiKey'>
    )>, defaultAlerts: Array<(
      { __typename: 'Alert' }
      & Pick<Alert, 'id'>
      & { condition: (
        { __typename: 'FinishedRunCondition' }
        & Pick<FinishedRunCondition, 'success'>
      ) | (
        { __typename: 'StoppedRunCondition' }
        & Pick<StoppedRunCondition, 'minimumRunDuration'>
      ) | { __typename: 'ScriptableRunCondition' }, subscriptions: Array<(
        { __typename: 'EmailSubscription' }
        & Pick<EmailSubscription, 'id'>
      ) | (
        { __typename: 'SlackChannelSubscription' }
        & Pick<SlackChannelSubscription, 'id'>
      )> }
    )>, integrations?: Maybe<(
      { __typename: 'IntegrationConnection' }
      & { edges: Array<(
        { __typename: 'IntegrationEdge' }
        & { node?: Maybe<(
          { __typename: 'GenericWebhookIntegration' }
          & Pick<GenericWebhookIntegration, 'id' | 'urlEndpoint' | 'name' | 'accessTokenRef' | 'secretRef' | 'createdAt'>
        ) | (
          { __typename: 'GitHubOAuthIntegration' }
          & Pick<GitHubOAuthIntegration, 'id'>
        ) | (
          { __typename: 'SlackIntegration' }
          & Pick<SlackIntegration, 'teamName' | 'channelName' | 'id'>
        )> }
      )> }
    )>, organization?: Maybe<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'orgType'>
      & { billingUser?: Maybe<(
        { __typename: 'User' }
        & Pick<User, 'id' | 'username'>
      )>, subscriptions: Array<(
        { __typename: 'OrganizationSubscription' }
        & Pick<OrganizationSubscription, 'id' | 'privileges' | 'seats' | 'expiresAt' | 'subscriptionType' | 'status'>
        & { plan: (
          { __typename: 'Plan' }
          & Pick<Plan, 'id' | 'name' | 'planType'>
        ) }
      )>, members: Array<(
        { __typename: 'OrganizationMember' }
        & Pick<OrganizationMember, 'orgID' | 'id' | 'username' | 'name' | 'photoUrl' | 'admin' | 'role'>
      )>, pendingInvites: Array<(
        { __typename: 'Invite' }
        & Pick<Invite, 'id' | 'email'>
      )> }
    )>, invites?: Maybe<(
      { __typename: 'InviteConnection' }
      & { edges: Array<(
        { __typename: 'InviteEdge' }
        & { node?: Maybe<(
          { __typename: 'Invite' }
          & Pick<Invite, 'id' | 'createdAt' | 'name'>
          & { toUser?: Maybe<(
            { __typename: 'User' }
            & Pick<User, 'id' | 'email' | 'username'>
          )> }
        )> }
      )> }
    )> }
  )>, user?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'username' | 'accountType'>
  )> }
);

export type EntityIntegrationsQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type EntityIntegrationsQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id' | 'name'>
    & { integrations?: Maybe<(
      { __typename: 'IntegrationConnection' }
      & { edges: Array<(
        { __typename: 'IntegrationEdge' }
        & { node?: Maybe<(
          { __typename: 'GenericWebhookIntegration' }
          & Pick<GenericWebhookIntegration, 'id' | 'urlEndpoint' | 'name' | 'accessTokenRef' | 'secretRef' | 'createdAt'>
        ) | (
          { __typename: 'GitHubOAuthIntegration' }
          & Pick<GitHubOAuthIntegration, 'id'>
        ) | (
          { __typename: 'SlackIntegration' }
          & Pick<SlackIntegration, 'teamName' | 'channelName' | 'id'>
        )> }
      )> }
    )> }
  )> }
);

export type UserRoleFragmentFragment = (
  { __typename: 'User' }
  & Pick<User, 'accountType' | 'admin' | 'deletedAt' | 'email' | 'username'>
);

export type SearchUsersQueryVariables = Exact<{
  query?: Maybe<Scalars['String']>;
}>;


export type SearchUsersQuery = (
  { __typename: 'Query' }
  & { users?: Maybe<(
    { __typename: 'UserConnection' }
    & { edges: Array<(
      { __typename: 'UserEdge' }
      & { node?: Maybe<(
        { __typename: 'User' }
        & Pick<User, 'id' | 'name' | 'loggedInAt' | 'userInfo'>
        & UserRoleFragmentFragment
      )> }
    )> }
  )> }
);

export type UserFragmentFragment = (
  { __typename: 'User' }
  & Pick<User, 'name' | 'email' | 'username' | 'deletedAt' | 'photoUrl'>
);

export type FindUsersQueryVariables = Exact<{
  query: Scalars['String'];
  limit: Scalars['Int'];
}>;


export type FindUsersQuery = (
  { __typename: 'Query' }
  & { users?: Maybe<(
    { __typename: 'UserConnection' }
    & { edges: Array<(
      { __typename: 'UserEdge' }
      & { node?: Maybe<(
        { __typename: 'User' }
        & Pick<User, 'id'>
        & UserFragmentFragment
      )> }
    )> }
  )> }
);

export type FindUsersByUsernameQueryVariables = Exact<{
  usernames: Array<Scalars['String']>;
  limit: Scalars['Int'];
}>;


export type FindUsersByUsernameQuery = (
  { __typename: 'Query' }
  & { users?: Maybe<(
    { __typename: 'UserConnection' }
    & { edges: Array<(
      { __typename: 'UserEdge' }
      & { node?: Maybe<(
        { __typename: 'User' }
        & Pick<User, 'id'>
        & UserFragmentFragment
      )> }
    )> }
  )> }
);

export type UpdateUserRoleMutationVariables = Exact<{
  id: Scalars['ID'];
  role: Scalars['String'];
}>;


export type UpdateUserRoleMutation = (
  { __typename: 'Mutation' }
  & { updateUserRole?: Maybe<(
    { __typename: 'UpdateUserRolePayload' }
    & { user?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'name' | 'username' | 'email' | 'admin' | 'deletedAt' | 'accountType'>
    )> }
  )> }
);

export type UpdateUserRoleV2MutationVariables = Exact<{
  id: Scalars['ID'];
  role: Scalars['String'];
}>;


export type UpdateUserRoleV2Mutation = (
  { __typename: 'Mutation' }
  & { updateUserRoleV2?: Maybe<(
    { __typename: 'UpdateUserRoleV2Payload' }
    & { user?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'name' | 'username' | 'email' | 'admin' | 'deletedAt' | 'accountType'>
    )> }
  )> }
);

export type SetUserAdminMutationVariables = Exact<{
  id: Scalars['ID'];
  admin: Scalars['Boolean'];
}>;


export type SetUserAdminMutation = (
  { __typename: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename: 'UpdateUserPayload' }
    & { user?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'name' | 'username' | 'email' | 'admin' | 'deletedAt'>
    )> }
  )> }
);

export type PurgeUserMutationVariables = Exact<{
  username: Scalars['String'];
  email: Scalars['String'];
}>;


export type PurgeUserMutation = (
  { __typename: 'Mutation' }
  & { purgeUser?: Maybe<(
    { __typename: 'PurgeUserPayload' }
    & { user?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'deletedAt'>
    )> }
  )> }
);

export type DeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserMutation = (
  { __typename: 'Mutation' }
  & { deleteUser?: Maybe<(
    { __typename: 'DeleteUserPayload' }
    & { user?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'name' | 'username' | 'email' | 'admin' | 'deletedAt'>
    )> }
  )> }
);

export type UndeleteUserMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UndeleteUserMutation = (
  { __typename: 'Mutation' }
  & { undeleteUser?: Maybe<(
    { __typename: 'UndeleteUserPayload' }
    & { user?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'name' | 'username' | 'email' | 'admin' | 'deletedAt'>
    )> }
  )> }
);

export type CreateInviteMutationVariables = Exact<{
  entityName: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  admin?: Maybe<Scalars['Boolean']>;
  role?: Maybe<Scalars['String']>;
  addSeat?: Maybe<Scalars['Boolean']>;
  modelSeat?: Maybe<Scalars['String']>;
}>;


export type CreateInviteMutation = (
  { __typename: 'Mutation' }
  & { createInvite?: Maybe<(
    { __typename: 'CreateInvitePayload' }
    & Pick<CreateInvitePayload, 'success' | 'remainingSeats' | 'remainingViewOnlySeats'>
    & { invite?: Maybe<(
      { __typename: 'Invite' }
      & Pick<Invite, 'id' | 'name' | 'email' | 'createdAt'>
      & { toUser?: Maybe<(
        { __typename: 'User' }
        & Pick<User, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type DeleteInviteMutationVariables = Exact<{
  id?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
}>;


export type DeleteInviteMutation = (
  { __typename: 'Mutation' }
  & { deleteInvite?: Maybe<(
    { __typename: 'DeleteInvitePayload' }
    & Pick<DeleteInvitePayload, 'success'>
  )> }
);

export type UpdateMemberMutationVariables = Exact<{
  entityName: Scalars['String'];
  user: Scalars['ID'];
  role: Scalars['String'];
}>;


export type UpdateMemberMutation = (
  { __typename: 'Mutation' }
  & { updateMember?: Maybe<(
    { __typename: 'UpdateMemberPayload' }
    & { member: (
      { __typename: 'Member' }
      & Pick<Member, 'id' | 'role'>
    ) }
  )> }
);

export type CreateServiceAccountMutationVariables = Exact<{
  entityName: Scalars['String'];
  description: Scalars['String'];
  organizationId?: Maybe<Scalars['ID']>;
}>;


export type CreateServiceAccountMutation = (
  { __typename: 'Mutation' }
  & { createServiceAccount?: Maybe<(
    { __typename: 'CreateServiceAccountPayload' }
    & { user?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'name'>
      & { apiKeys?: Maybe<(
        { __typename: 'ApiKeyConnection' }
        & { edges: Array<(
          { __typename: 'ApiKeyEdge' }
          & { node?: Maybe<(
            { __typename: 'ApiKey' }
            & Pick<ApiKey, 'id' | 'name'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type CreateOrgServiceAccountMutationVariables = Exact<{
  description: Scalars['String'];
  defaultEntityId: Scalars['ID'];
  organizationId: Scalars['ID'];
}>;


export type CreateOrgServiceAccountMutation = (
  { __typename: 'Mutation' }
  & { createOrgServiceAccount?: Maybe<(
    { __typename: 'CreateOrgServiceAccountPayload' }
    & { user?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'name'>
      & { apiKeys?: Maybe<(
        { __typename: 'ApiKeyConnection' }
        & { edges: Array<(
          { __typename: 'ApiKeyEdge' }
          & { node?: Maybe<(
            { __typename: 'ApiKey' }
            & Pick<ApiKey, 'id' | 'name'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type DeleteServiceAccountMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteServiceAccountMutation = (
  { __typename: 'Mutation' }
  & { deleteServiceAccount?: Maybe<(
    { __typename: 'DeleteServiceAccountPayload' }
    & { user?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id'>
    )> }
  )> }
);

export type CreateEntityMutationVariables = Exact<{
  name: Scalars['String'];
  invited?: Maybe<Scalars['String']>;
  framework?: Maybe<Scalars['String']>;
}>;


export type CreateEntityMutation = (
  { __typename: 'Mutation' }
  & { createEntity?: Maybe<(
    { __typename: 'CreateEntityPayload' }
    & { entity?: Maybe<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'name' | 'photoUrl' | 'invitedTeam'>
      & { projects?: Maybe<(
        { __typename: 'ProjectConnection' }
        & { edges: Array<(
          { __typename: 'ProjectEdge' }
          & { node?: Maybe<(
            { __typename: 'Project' }
            & Pick<Project, 'id' | 'name' | 'entityName'>
          )> }
        )> }
      )> }
    )>, apiKey?: Maybe<(
      { __typename: 'ApiKey' }
      & Pick<ApiKey, 'id' | 'name'>
    )> }
  )> }
);

export type CreateUserFromAdminMutationVariables = Exact<{
  email: Scalars['String'];
  admin?: Maybe<Scalars['Boolean']>;
  accountType?: Maybe<Scalars['String']>;
}>;


export type CreateUserFromAdminMutation = (
  { __typename: 'Mutation' }
  & { createUser?: Maybe<(
    { __typename: 'CreateUserPayload' }
    & { user?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'name' | 'username' | 'email' | 'admin'>
    )> }
  )> }
);

export type CreateEntityOnboardingFlowMutationVariables = Exact<{
  name: Scalars['String'];
}>;


export type CreateEntityOnboardingFlowMutation = (
  { __typename: 'Mutation' }
  & { createEntity?: Maybe<(
    { __typename: 'CreateEntityPayload' }
    & { entity?: Maybe<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id'>
    )> }
  )> }
);

export type CheckAvailabilityQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
}>;


export type CheckAvailabilityQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id' | 'available'>
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  defaultEntity?: Maybe<Scalars['String']>;
  defaultFramework?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  settingsVisited?: Maybe<Scalars['Boolean']>;
  galleryVisited?: Maybe<Scalars['Boolean']>;
  onboardingHidden?: Maybe<Scalars['Boolean']>;
  hideTeamsFromPublic?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateUserMutation = (
  { __typename: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename: 'UpdateUserPayload' }
    & { user?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'flags' | 'entity' | 'hideTeamsFromPublic'>
      & { teams?: Maybe<(
        { __typename: 'EntityConnection' }
        & { edges: Array<(
          { __typename: 'EntityEdge' }
          & { node?: Maybe<(
            { __typename: 'Entity' }
            & Pick<Entity, 'id' | 'name' | 'isTeam'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type UpdateUserInfoMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  photoUrl?: Maybe<Scalars['String']>;
  userInfo?: Maybe<Scalars['JSONString']>;
  defaultEntity?: Maybe<Scalars['String']>;
  primaryEmail?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
}>;


export type UpdateUserInfoMutation = (
  { __typename: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename: 'UpdateUserPayload' }
    & { user?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'name' | 'userInfo' | 'photoUrl' | 'entity'>
      & { teams?: Maybe<(
        { __typename: 'EntityConnection' }
        & { edges: Array<(
          { __typename: 'EntityEdge' }
          & { node?: Maybe<(
            { __typename: 'Entity' }
            & Pick<Entity, 'id' | 'name' | 'isTeam'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type UpdateUserEmailMutationVariables = Exact<{
  id: Scalars['ID'];
  type?: Maybe<EmailType>;
}>;


export type UpdateUserEmailMutation = (
  { __typename: 'Mutation' }
  & { updateUserEmail?: Maybe<(
    { __typename: 'UpdateUserEmailPayload' }
    & Pick<UpdateUserEmailPayload, 'success'>
    & { email?: Maybe<(
      { __typename: 'Email' }
      & Pick<Email, 'id' | 'emailAddress'>
    )> }
  )> }
);

export type DeleteUserEmailMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteUserEmailMutation = (
  { __typename: 'Mutation' }
  & { deleteUserEmail?: Maybe<(
    { __typename: 'DeleteUserEmailPayload' }
    & Pick<DeleteUserEmailPayload, 'success'>
  )> }
);

export type GenerateApiKeyMutationVariables = Exact<{
  description?: Maybe<Scalars['String']>;
}>;


export type GenerateApiKeyMutation = (
  { __typename: 'Mutation' }
  & { generateApiKey?: Maybe<(
    { __typename: 'GenerateApiKeyPayload' }
    & { apiKey?: Maybe<(
      { __typename: 'ApiKey' }
      & Pick<ApiKey, 'id' | 'name'>
    )> }
  )> }
);

export type DeleteApiKeyMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteApiKeyMutation = (
  { __typename: 'Mutation' }
  & { deleteApiKey?: Maybe<(
    { __typename: 'DeleteApiKeyPayload' }
    & Pick<DeleteApiKeyPayload, 'success'>
  )> }
);

export type UpdateEntityMutationVariables = Exact<{
  entityName: Scalars['String'];
  defaultAccess?: Maybe<Scalars['String']>;
  privateOnly?: Maybe<Scalars['Boolean']>;
  codeSavingEnabled?: Maybe<Scalars['Boolean']>;
  settings?: Maybe<EntitySettingsInput>;
}>;


export type UpdateEntityMutation = (
  { __typename: 'Mutation' }
  & { updateEntity?: Maybe<(
    { __typename: 'UpdateEntityPayload' }
    & { entity?: Maybe<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'defaultAccess' | 'privateOnly' | 'limits'>
      & { settings: (
        { __typename: 'EntitySettings' }
        & Pick<EntitySettings, 'hidden'>
        & { defaultCloudRegion: (
          { __typename: 'CloudRegion' }
          & Pick<CloudRegion, 'id' | 'provider' | 'region'>
        ) }
      ) }
    )> }
  )> }
);

export type ClaimAnonymousEntityMutationVariables = Exact<{
  anonymousApiKey: Scalars['String'];
}>;


export type ClaimAnonymousEntityMutation = (
  { __typename: 'Mutation' }
  & { claimAnonymousEntity?: Maybe<(
    { __typename: 'ClaimAnonymousEntityPayload' }
    & { task: (
      { __typename: 'Task' }
      & Pick<Task, 'id' | 'type' | 'state' | 'progress' | 'name'>
    ) }
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename: 'Mutation' }
  & { resetPassword?: Maybe<(
    { __typename: 'ResetPasswordPayload' }
    & Pick<ResetPasswordPayload, 'success'>
  )> }
);

export type ViewerQueryVariables = Exact<{
  includeStorage?: Maybe<Scalars['Boolean']>;
}>;


export type ViewerQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'analyticsId' | 'admin' | 'email' | 'entity' | 'defaultFramework' | 'photoUrl' | 'flags' | 'code' | 'username' | 'createdAt' | 'name' | 'accountType' | 'userInfo' | 'hideTeamsFromPublic' | 'limits' | 'signupRequired' | 'personalEntityDisabled' | 'instanceAdminTeamAccessDisabled'>
    & { teams?: Maybe<(
      { __typename: 'EntityConnection' }
      & { edges: Array<(
        { __typename: 'EntityEdge' }
        & { node?: Maybe<(
          { __typename: 'Entity' }
          & Pick<Entity, 'id' | 'name' | 'photoUrl' | 'defaultAccess' | 'readOnlyAdmin' | 'privateOnly' | 'isTeam' | 'organizationId'>
        )> }
      )> }
    )>, organizations: Array<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'orgType'>
      & { subscriptions: Array<(
        { __typename: 'OrganizationSubscription' }
        & Pick<OrganizationSubscription, 'id' | 'subscriptionType'>
      )> }
    )>, defaultEntity?: Maybe<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'name'>
    )>, userEntity?: Maybe<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'name' | 'defaultAccess' | 'codeSavingEnabled' | 'storageBytes' | 'isTeam'>
      & { settings: (
        { __typename: 'EntitySettings' }
        & { defaultCloudRegion: (
          { __typename: 'CloudRegion' }
          & Pick<CloudRegion, 'id' | 'provider' | 'region'>
        ) }
      ), defaultAlerts: Array<(
        { __typename: 'Alert' }
        & Pick<Alert, 'id'>
        & { condition: (
          { __typename: 'FinishedRunCondition' }
          & Pick<FinishedRunCondition, 'success'>
        ) | (
          { __typename: 'StoppedRunCondition' }
          & Pick<StoppedRunCondition, 'minimumRunDuration'>
        ) | { __typename: 'ScriptableRunCondition' }, subscriptions: Array<(
          { __typename: 'EmailSubscription' }
          & Pick<EmailSubscription, 'id'>
        ) | (
          { __typename: 'SlackChannelSubscription' }
          & Pick<SlackChannelSubscription, 'id'>
        )> }
      )>, integrations?: Maybe<(
        { __typename: 'IntegrationConnection' }
        & { edges: Array<(
          { __typename: 'IntegrationEdge' }
          & { node?: Maybe<(
            { __typename: 'GenericWebhookIntegration' }
            & Pick<GenericWebhookIntegration, 'id'>
          ) | (
            { __typename: 'GitHubOAuthIntegration' }
            & Pick<GitHubOAuthIntegration, 'id'>
          ) | (
            { __typename: 'SlackIntegration' }
            & Pick<SlackIntegration, 'teamName' | 'channelName' | 'id'>
          )> }
        )> }
      )>, claimedEntities?: Maybe<(
        { __typename: 'EntityConnection' }
        & { edges: Array<(
          { __typename: 'EntityEdge' }
          & { node?: Maybe<(
            { __typename: 'Entity' }
            & Pick<Entity, 'id' | 'name'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type ViewerPhotoUrlQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerPhotoUrlQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'photoUrl'>
  )> }
);

export type ViewerProjectsQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
}>;


export type ViewerProjectsQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'entity' | 'photoUrl' | 'username' | 'createdAt'>
    & { linkedProjects?: Maybe<(
      { __typename: 'ProjectConnection' }
      & { edges: Array<(
        { __typename: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename: 'Project' }
          & Pick<Project, 'id' | 'name' | 'entityName'>
          & { runs?: Maybe<(
            { __typename: 'RunConnection' }
            & { edges: Array<(
              { __typename: 'RunEdge' }
              & { node: (
                { __typename: 'Run' }
                & Pick<Run, 'id' | 'name' | 'notes' | 'summaryMetrics' | 'displayName' | 'createdAt'>
                & { project?: Maybe<(
                  { __typename: 'Project' }
                  & Pick<Project, 'id' | 'entityName' | 'name'>
                )>, benchmarkRun?: Maybe<(
                  { __typename: 'BenchmarkRun' }
                  & Pick<BenchmarkRun, 'id' | 'results'>
                  & { originalRun?: Maybe<(
                    { __typename: 'Run' }
                    & Pick<Run, 'id' | 'name'>
                  )> }
                )> }
              ) }
            )> }
          )> }
        )> }
      )> }
    )>, projects?: Maybe<(
      { __typename: 'ProjectConnection' }
      & { edges: Array<(
        { __typename: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename: 'Project' }
          & Pick<Project, 'id' | 'name' | 'entityName'>
        )> }
      )> }
    )> }
  )> }
);

export type ViewerFeatureFlagsQueryVariables = Exact<{
  rampIDType: RampIdType;
}>;


export type ViewerFeatureFlagsQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { featureFlags: Array<Maybe<(
      { __typename: 'FeatureFlag' }
      & Pick<FeatureFlag, 'rampKey' | 'isEnabled'>
    )>> }
  )> }
);

export type ViewFragmentFragment = (
  { __typename: 'View' }
  & Pick<View, 'id' | 'type' | 'name' | 'displayName' | 'description' | 'spec' | 'updatedAt'>
  & { user?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'username' | 'photoUrl' | 'admin'>
  )>, project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'name' | 'entityName'>
  )> }
);

export type ViewFragmentMetadataFragment = (
  { __typename: 'View' }
  & Pick<View, 'id' | 'type' | 'name' | 'displayName' | 'description' | 'updatedAt' | 'createdAt' | 'starred' | 'starCount' | 'parentId' | 'locked' | 'viewCount'>
  & { updatedBy?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'username' | 'name'>
  )>, user?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'username' | 'photoUrl' | 'admin'>
  )>, project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'name' | 'entityName'>
  )>, alertSubscription?: Maybe<(
    { __typename: 'EmailSubscription' }
    & Pick<EmailSubscription, 'id'>
  ) | (
    { __typename: 'SlackChannelSubscription' }
    & Pick<SlackChannelSubscription, 'id'>
  )> }
);

export type UpsertViewMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  entityName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  spec: Scalars['String'];
  specIsGzipped?: Maybe<Scalars['Boolean']>;
}>;


export type UpsertViewMutation = (
  { __typename: 'Mutation' }
  & { upsertView?: Maybe<(
    { __typename: 'UpsertViewPayload' }
    & Pick<UpsertViewPayload, 'inserted'>
    & { view?: Maybe<(
      { __typename: 'View' }
      & Pick<View, 'id'>
      & ViewFragmentFragment
    )> }
  )> }
);

export type DeleteViewMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type DeleteViewMutation = (
  { __typename: 'Mutation' }
  & { deleteView?: Maybe<(
    { __typename: 'DeleteViewPayload' }
    & Pick<DeleteViewPayload, 'success'>
  )> }
);

export type CreateSharedViewMutationVariables = Exact<{
  entityName: Scalars['String'];
  name: Scalars['String'];
  type: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  spec: Scalars['String'];
}>;


export type CreateSharedViewMutation = (
  { __typename: 'Mutation' }
  & { upsertSharedView?: Maybe<(
    { __typename: 'UpsertSharedViewPayload' }
    & { view?: Maybe<(
      { __typename: 'View' }
      & Pick<View, 'id'>
      & ViewFragmentFragment
    )> }
  )> }
);

export type ViewParentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ViewParentQuery = (
  { __typename: 'Query' }
  & { viewParent?: Maybe<(
    { __typename: 'View' }
    & Pick<View, 'id' | 'displayName'>
  )> }
);

export type ViewChildrenQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ViewChildrenQuery = (
  { __typename: 'Query' }
  & { view?: Maybe<(
    { __typename: 'View' }
    & Pick<View, 'id'>
    & { children?: Maybe<(
      { __typename: 'ViewConnection' }
      & { edges: Array<(
        { __typename: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename: 'View' }
          & Pick<View, 'id' | 'displayName'>
          & { user?: Maybe<(
            { __typename: 'User' }
            & Pick<User, 'id'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type AcademicApplicationOrgInfoQueryVariables = Exact<{
  organizationID: Scalars['ID'];
}>;


export type AcademicApplicationOrgInfoQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
    & { subscriptions: Array<(
      { __typename: 'OrganizationSubscription' }
      & Pick<OrganizationSubscription, 'id'>
      & { plan: (
        { __typename: 'Plan' }
        & Pick<Plan, 'id' | 'name' | 'planType'>
      ) }
    )> }
  )> }
);

export type CurrentBillingPeriodUsageSectionOrgInfoQueryVariables = Exact<{
  orgName: Scalars['String'];
}>;


export type CurrentBillingPeriodUsageSectionOrgInfoQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'createdAt'>
    & { teams: Array<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'storageBytes'>
    )>, subscriptions: Array<(
      { __typename: 'OrganizationSubscription' }
      & Pick<OrganizationSubscription, 'id' | 'billingPeriodStart' | 'billingPeriodEnd' | 'subscriptionType'>
      & { plan: (
        { __typename: 'Plan' }
        & Pick<Plan, 'id' | 'name' | 'planType'>
      ) }
    )> }
  )> }
);

export type EnterpriseBillingPeriodOrgInfoQueryVariables = Exact<{
  orgName: Scalars['String'];
}>;


export type EnterpriseBillingPeriodOrgInfoQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { weaveEnterpriseSubscription?: Maybe<(
      { __typename: 'EnterpriseSubscription' }
      & Pick<EnterpriseSubscription, 'billingPeriodStart' | 'billingPeriodEnd'>
    )> }
  )> }
);

export type CurrentPlanPageOrgInfoQueryVariables = Exact<{
  organizationID: Scalars['ID'];
}>;


export type CurrentPlanPageOrgInfoQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
    & { subscriptions: Array<(
      { __typename: 'OrganizationSubscription' }
      & Pick<OrganizationSubscription, 'id'>
      & { plan: (
        { __typename: 'Plan' }
        & Pick<Plan, 'id' | 'planType' | 'name'>
      ) }
    )>, stripeBillingInfo?: Maybe<(
      { __typename: 'StripeBillingInfo' }
      & Pick<StripeBillingInfo, 'currentPeriodEnd'>
    )> }
  )> }
);

export type PrimaryPlanCardV3OrgInfoQueryVariables = Exact<{
  organizationID: Scalars['ID'];
}>;


export type PrimaryPlanCardV3OrgInfoQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'usedSeats' | 'createdAt'>
    & { teams: Array<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'computeHours' | 'storageBytes'>
    )>, subscriptions: Array<(
      { __typename: 'OrganizationSubscription' }
      & Pick<OrganizationSubscription, 'id' | 'expiresAt' | 'seats' | 'billingPeriodStart' | 'billingPeriodEnd' | 'subscriptionType'>
      & { discount?: Maybe<(
        { __typename: 'Discount' }
        & Pick<Discount, 'start' | 'end'>
        & { coupon: (
          { __typename: 'Coupon' }
          & Pick<Coupon, 'id' | 'amountOff' | 'percentOff' | 'duration' | 'currency' | 'name'>
        ) }
      )>, plan: (
        { __typename: 'Plan' }
        & Pick<Plan, 'id' | 'displayName' | 'planType' | 'name' | 'billingInterval' | 'unitPrice'>
        & { stripePrice?: Maybe<(
          { __typename: 'StripePrice' }
          & Pick<StripePrice, 'amount'>
        )> }
      ) }
    )> }
  )> }
);

export type StoragePlanCardV2OrgInfoQueryVariables = Exact<{
  organizationID: Scalars['ID'];
}>;


export type StoragePlanCardV2OrgInfoQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'createdAt'>
    & { teams: Array<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'storageBytes' | 'referenceBytes'>
    )>, subscriptions: Array<(
      { __typename: 'OrganizationSubscription' }
      & Pick<OrganizationSubscription, 'id' | 'billingPeriodStart' | 'billingPeriodEnd' | 'subscriptionType'>
      & { plan: (
        { __typename: 'Plan' }
        & Pick<Plan, 'id' | 'name' | 'planType'>
      ) }
    )> }
  )> }
);

export type ServerCurrentBillingPeriodUsageSectionQueryVariables = Exact<{ [key: string]: never; }>;


export type ServerCurrentBillingPeriodUsageSectionQuery = (
  { __typename: 'Query' }
  & { serverInfo?: Maybe<(
    { __typename: 'ServerInfo' }
    & Pick<ServerInfo, 'contractStartDate'>
    & { weaveLimits?: Maybe<(
      { __typename: 'WeaveLimits' }
      & Pick<WeaveLimits, 'weaveLimitBytes' | 'weaveOverageCostCents' | 'weaveOverageUnit'>
    )> }
  )> }
);

export type TotalUsageSectionOrgInfoQueryVariables = Exact<{
  organizationID: Scalars['ID'];
}>;


export type TotalUsageSectionOrgInfoQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'usedSeats'>
    & { subscriptions: Array<(
      { __typename: 'OrganizationSubscription' }
      & Pick<OrganizationSubscription, 'id' | 'privileges' | 'seats' | 'subscriptionType' | 'billingPeriodEnd'>
      & { plan: (
        { __typename: 'Plan' }
        & Pick<Plan, 'id' | 'name' | 'planType'>
      ) }
    )>, teams: Array<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'computeHours' | 'storageBytes' | 'referenceBytes'>
    )> }
  )> }
);

export type StorageTableQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type StorageTableQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
    & { teams: Array<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'name' | 'storageBytes'>
      & { storageTree?: Maybe<(
        { __typename: 'EntityStorageNode' }
        & Pick<EntityStorageNode, 'id'>
      )>, projects?: Maybe<(
        { __typename: 'ProjectConnection' }
        & { edges: Array<(
          { __typename: 'ProjectEdge' }
          & { node?: Maybe<(
            { __typename: 'Project' }
            & Pick<Project, 'id' | 'storageBytes'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type DefaultEntityForDomainCaptureDropdownQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type DefaultEntityForDomainCaptureDropdownQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'flags'>
    & { teams: Array<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'name'>
      & { settings: (
        { __typename: 'EntitySettings' }
        & Pick<EntitySettings, 'openToMatchingOrgEmailDomainUsers'>
      ) }
    )> }
  )> }
);

export type OrganizationDefaultModelsSeatQueryVariables = Exact<{
  orgName: Scalars['String'];
}>;


export type OrganizationDefaultModelsSeatQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'flags'>
    & { subscriptions: Array<(
      { __typename: 'OrganizationSubscription' }
      & Pick<OrganizationSubscription, 'id' | 'privileges'>
      & { plan: (
        { __typename: 'Plan' }
        & Pick<Plan, 'id' | 'planType'>
      ) }
    )> }
  )> }
);

export type OrganizationDomainCaptureSettingQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type OrganizationDomainCaptureSettingQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'flags'>
    & { teams: Array<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id'>
      & { settings: (
        { __typename: 'EntitySettings' }
        & Pick<EntitySettings, 'openToMatchingOrgEmailDomainUsers'>
      ) }
    )> }
  )> }
);

export type ViewerDomainClaimBlockingReasonQueryVariables = Exact<{
  orgID: Scalars['ID'];
}>;


export type ViewerDomainClaimBlockingReasonQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'domainClaimBlockingReason'>
  )> }
);

export type OrganizationTeamsWithDomainCaptureEnabledQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type OrganizationTeamsWithDomainCaptureEnabledQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'flags'>
    & { teams: Array<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'name' | 'memberCount'>
      & { settings: (
        { __typename: 'EntitySettings' }
        & Pick<EntitySettings, 'openToMatchingOrgEmailDomainUsers'>
      ) }
    )> }
  )> }
);

export type EditOrgMembersModalQueryVariables = Exact<{
  organizationID: Scalars['ID'];
}>;


export type EditOrgMembersModalQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'flags'>
    & { teams: Array<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'name'>
      & { settings: (
        { __typename: 'EntitySettings' }
        & Pick<EntitySettings, 'openToMatchingOrgEmailDomainUsers'>
      ) }
    )>, seatAvailability: (
      { __typename: 'OrganizationSeatAvailability' }
      & Pick<OrganizationSeatAvailability, 'seats'>
    ), pendingInvites: Array<(
      { __typename: 'Invite' }
      & Pick<Invite, 'id' | 'email'>
    )>, billingUser?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'username'>
    )>, subscriptions: Array<(
      { __typename: 'OrganizationSubscription' }
      & Pick<OrganizationSubscription, 'id' | 'seats'>
      & { plan: (
        { __typename: 'Plan' }
        & Pick<Plan, 'id' | 'unitPrice' | 'planType' | 'billingInterval'>
      ) }
    )>, members: Array<(
      { __typename: 'OrganizationMember' }
      & Pick<OrganizationMember, 'id' | 'username' | 'role' | 'modelsSeat' | 'name'>
      & { user: (
        { __typename: 'User' }
        & Pick<User, 'id' | 'loggedInAt'>
      ), teams?: Maybe<(
        { __typename: 'EntityConnection' }
        & { edges: Array<(
          { __typename: 'EntityEdge' }
          & { node?: Maybe<(
            { __typename: 'Entity' }
            & Pick<Entity, 'id' | 'name'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type IsEmailDomainClaimedAndShouldRedirectToSsoQueryVariables = Exact<{ [key: string]: never; }>;


export type IsEmailDomainClaimedAndShouldRedirectToSsoQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'isEmailDomainClaimedAndShouldRedirectToSSO'>
  )> }
);

export type TeamPageShowcasedProjectsQueryVariables = Exact<{
  entityName: Scalars['String'];
  pattern?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
}>;


export type TeamPageShowcasedProjectsQuery = (
  { __typename: 'Query' }
  & { projects?: Maybe<(
    { __typename: 'ProjectConnection' }
    & { edges: Array<(
      { __typename: 'ProjectEdge' }
      & { node?: Maybe<(
        { __typename: 'Project' }
        & Pick<Project, 'id' | 'name' | 'entityName' | 'description' | 'lastActive' | 'runCount' | 'access'>
        & { user?: Maybe<(
          { __typename: 'User' }
          & Pick<User, 'id' | 'username'>
        )> }
      )> }
    )>, pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<PageInfo, 'startCursor' | 'endCursor' | 'hasNextPage' | 'hasPreviousPage'>
    ) }
  )> }
);

export type ProfilePageShowcasedProjectsQueryVariables = Exact<{
  username: Scalars['String'];
  pattern?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
}>;


export type ProfilePageShowcasedProjectsQuery = (
  { __typename: 'Query' }
  & { user?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { projects?: Maybe<(
      { __typename: 'ProjectConnection' }
      & { edges: Array<(
        { __typename: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename: 'Project' }
          & Pick<Project, 'id' | 'name' | 'entityName' | 'access' | 'description' | 'totalRuns' | 'lastActive' | 'runCount'>
          & { user?: Maybe<(
            { __typename: 'User' }
            & Pick<User, 'id' | 'username'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type HistoricUsageOrganizationInfoQueryVariables = Exact<{
  orgName: Scalars['String'];
}>;


export type HistoricUsageOrganizationInfoQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { members: Array<(
      { __typename: 'OrganizationMember' }
      & Pick<OrganizationMember, 'id' | 'username' | 'role'>
    )> }
  )> }
);

export type UsageTabInfoQueryVariables = Exact<{
  orgName: Scalars['String'];
  usageType: UsageType;
  intervalMarkers: Array<Scalars['DateTime']>;
}>;


export type UsageTabInfoQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'createdAt'>
    & { usage: Array<(
      { __typename: 'UsageAggregation' }
      & Pick<UsageAggregation, 'start' | 'end' | 'value' | 'projectName' | 'entityName'>
    )> }
  )> }
);

export type GlobalUsageTabInfoQueryVariables = Exact<{
  usageType: UsageType;
  intervalMarkers: Array<Scalars['DateTime']>;
}>;


export type GlobalUsageTabInfoQuery = (
  { __typename: 'Query' }
  & { usage: Array<(
    { __typename: 'UsageAggregation' }
    & Pick<UsageAggregation, 'start' | 'end' | 'value' | 'projectName' | 'entityName'>
  )> }
);

export type TrialCardAccountExpirationQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type TrialCardAccountExpirationQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { subscriptions: Array<(
      { __typename: 'OrganizationSubscription' }
      & Pick<OrganizationSubscription, 'id' | 'expiresAt' | 'status'>
      & { plan: (
        { __typename: 'Plan' }
        & Pick<Plan, 'id' | 'name' | 'planType'>
      ) }
    )> }
  )> }
);

export type PredefinedRoleOrgInfoQueryVariables = Exact<{
  organizationID: Scalars['ID'];
}>;


export type PredefinedRoleOrgInfoQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { subscriptions: Array<(
      { __typename: 'OrganizationSubscription' }
      & Pick<OrganizationSubscription, 'id' | 'subscriptionType'>
      & { plan: (
        { __typename: 'Plan' }
        & Pick<Plan, 'id' | 'name' | 'planType'>
      ) }
    )> }
  )> }
);

export type OrganizationFlagsQueryVariables = Exact<{
  orgName: Scalars['String'];
}>;


export type OrganizationFlagsQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'flags'>
  )> }
);

export type FetchProfilePageProjectsByIDsQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectIDs: Array<Scalars['ID']>;
}>;


export type FetchProfilePageProjectsByIDsQuery = (
  { __typename: 'Query' }
  & { projects?: Maybe<(
    { __typename: 'ProjectConnection' }
    & { edges: Array<(
      { __typename: 'ProjectEdge' }
      & { node?: Maybe<(
        { __typename: 'Project' }
        & Pick<Project, 'id' | 'name' | 'entityName' | 'access' | 'lastActive' | 'runCount'>
        & { user?: Maybe<(
          { __typename: 'User' }
          & Pick<User, 'id' | 'username'>
        )> }
      )> }
    )> }
  )> }
);

export type StartupProgramQueryVariables = Exact<{
  organizationID: Scalars['ID'];
}>;


export type StartupProgramQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'flags'>
    & { subscriptions: Array<(
      { __typename: 'OrganizationSubscription' }
      & Pick<OrganizationSubscription, 'id' | 'subscriptionType'>
      & { plan: (
        { __typename: 'Plan' }
        & Pick<Plan, 'id' | 'name' | 'planType'>
      ) }
    )> }
  )> }
);

export type StartupProgramCouponInfoQueryVariables = Exact<{
  couponID: Scalars['String'];
}>;


export type StartupProgramCouponInfoQuery = (
  { __typename: 'Query' }
  & { coupon?: Maybe<(
    { __typename: 'Coupon' }
    & Pick<Coupon, 'id'>
  )> }
);

export type TrialEndOrgInfoQueryVariables = Exact<{
  organizationID: Scalars['ID'];
}>;


export type TrialEndOrgInfoQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name' | 'usedSeats' | 'flags'>
    & { teams: Array<(
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'name'>
    )>, subscriptions: Array<(
      { __typename: 'OrganizationSubscription' }
      & Pick<OrganizationSubscription, 'id' | 'expiresAt'>
      & { plan: (
        { __typename: 'Plan' }
        & Pick<Plan, 'id' | 'name' | 'planType'>
      ) }
    )> }
  )> }
);

export type TrialEndCouponInfoQueryVariables = Exact<{
  couponID: Scalars['String'];
}>;


export type TrialEndCouponInfoQuery = (
  { __typename: 'Query' }
  & { coupon?: Maybe<(
    { __typename: 'Coupon' }
    & Pick<Coupon, 'id' | 'amountOff' | 'percentOff' | 'duration' | 'currency' | 'name' | 'durationInMonths'>
  )> }
);

export type CreateAccessTokenMutationVariables = Exact<{
  projects: Array<ProjectSpecifier>;
  viewId: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
  previewURL?: Maybe<Scalars['String']>;
}>;


export type CreateAccessTokenMutation = (
  { __typename: 'Mutation' }
  & { createAccessToken?: Maybe<(
    { __typename: 'CreateAccessTokenPayload' }
    & Pick<CreateAccessTokenPayload, 'toNewUser' | 'recipientAlreadyOnTeam' | 'clientMutationId'>
    & { accessToken: (
      { __typename: 'AccessToken' }
      & Pick<AccessToken, 'id' | 'token' | 'type' | 'emails' | 'createdAt' | 'lastAccessedAt' | 'revokedAt'>
      & { view: (
        { __typename: 'View' }
        & Pick<View, 'id'>
      ), createdBy: (
        { __typename: 'User' }
        & Pick<User, 'id' | 'username' | 'email' | 'name'>
      ), projects: Array<(
        { __typename: 'Project' }
        & Pick<Project, 'id' | 'name' | 'entityName'>
      )> }
    ) }
  )> }
);

export type RevokeAccessTokenMutationVariables = Exact<{
  token: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type RevokeAccessTokenMutation = (
  { __typename: 'Mutation' }
  & { revokeAccessToken?: Maybe<(
    { __typename: 'RevokeAccessTokenPayload' }
    & Pick<RevokeAccessTokenPayload, 'success' | 'clientMutationId'>
  )> }
);

export type UpdateAccessTokenProjectsMutationVariables = Exact<{
  token: Scalars['String'];
  projects: Array<ProjectSpecifier>;
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type UpdateAccessTokenProjectsMutation = (
  { __typename: 'Mutation' }
  & { updateAccessTokenProjects?: Maybe<(
    { __typename: 'UpdateAccessTokenProjectsPayload' }
    & Pick<UpdateAccessTokenProjectsPayload, 'success' | 'clientMutationId'>
  )> }
);

export type UpdateAccessTokenMutationVariables = Exact<{
  token: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
  previewURL?: Maybe<Scalars['String']>;
}>;


export type UpdateAccessTokenMutation = (
  { __typename: 'Mutation' }
  & { updateAccessToken?: Maybe<(
    { __typename: 'UpdateAccessTokenPayload' }
    & Pick<UpdateAccessTokenPayload, 'toNewUser' | 'recipientAlreadyOnTeam' | 'clientMutationId'>
    & { accessToken: (
      { __typename: 'AccessToken' }
      & Pick<AccessToken, 'id' | 'token' | 'type' | 'emails' | 'createdAt' | 'lastAccessedAt' | 'revokedAt'>
      & { view: (
        { __typename: 'View' }
        & Pick<View, 'id'>
      ), createdBy: (
        { __typename: 'User' }
        & Pick<User, 'id' | 'username' | 'email' | 'name'>
      ), projects: Array<(
        { __typename: 'Project' }
        & Pick<Project, 'id' | 'name' | 'entityName'>
      )> }
    ) }
  )> }
);

export type RevokeAccessTokenEmailMutationVariables = Exact<{
  token: Scalars['String'];
  email: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type RevokeAccessTokenEmailMutation = (
  { __typename: 'Mutation' }
  & { revokeAccessTokenEmail?: Maybe<(
    { __typename: 'RevokeAccessTokenEmailPayload' }
    & Pick<RevokeAccessTokenEmailPayload, 'clientMutationId'>
    & { accessToken: (
      { __typename: 'AccessToken' }
      & Pick<AccessToken, 'id' | 'token' | 'type' | 'emails'>
      & { view: (
        { __typename: 'View' }
        & Pick<View, 'id'>
      ) }
    ) }
  )> }
);

export type ArtifactCollectionAliasActionHistoryQueryVariables = Exact<{
  projectName?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  artifactTypeName: Scalars['String'];
  artifactCollectionName: Scalars['String'];
  actionCursor?: Maybe<Scalars['String']>;
  actionFirst?: Maybe<Scalars['Int']>;
  alias?: Maybe<Scalars['String']>;
}>;


export type ArtifactCollectionAliasActionHistoryQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { artifactType?: Maybe<(
      { __typename: 'ArtifactType' }
      & Pick<ArtifactType, 'id'>
      & { artifactCollection?: Maybe<(
        { __typename: 'ArtifactPortfolio' }
        & Pick<ArtifactPortfolio, 'id'>
        & { aliases: (
          { __typename: 'ArtifactAliasConnection' }
          & { edges: Array<(
            { __typename: 'ArtifactAliasEdge' }
            & { node?: Maybe<(
              { __typename: 'ArtifactAlias' }
              & Pick<ArtifactAlias, 'id' | 'alias'>
            )> }
          )> }
        ), aliasActionHistory: (
          { __typename: 'ArtifactAliasActionConnection' }
          & { pageInfo: (
            { __typename: 'PageInfo' }
            & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
          ), edges: Array<(
            { __typename: 'ArtifactAliasActionEdge' }
            & { node: (
              { __typename: 'ArtifactAliasAction' }
              & Pick<ArtifactAliasAction, 'createdAt' | 'alias' | 'actionType'>
              & { targetArtifact?: Maybe<(
                { __typename: 'Artifact' }
                & Pick<Artifact, 'id' | 'versionIndex'>
                & { artifactSequence: (
                  { __typename: 'ArtifactSequence' }
                  & Pick<ArtifactSequence, 'id' | 'name'>
                  & { project?: Maybe<(
                    { __typename: 'Project' }
                    & Pick<Project, 'id' | 'name'>
                  )> }
                ) }
              )>, sourceArtifact?: Maybe<(
                { __typename: 'Artifact' }
                & Pick<Artifact, 'id' | 'versionIndex'>
                & { artifactSequence: (
                  { __typename: 'ArtifactSequence' }
                  & Pick<ArtifactSequence, 'id' | 'name'>
                  & { project?: Maybe<(
                    { __typename: 'Project' }
                    & Pick<Project, 'id' | 'name'>
                  )> }
                ) }
              )>, user?: Maybe<(
                { __typename: 'User' }
                & Pick<User, 'id' | 'username' | 'photoUrl' | 'name' | 'accountType'>
              )> }
            ) }
          )> }
        ) }
      ) | (
        { __typename: 'ArtifactSequence' }
        & Pick<ArtifactSequence, 'id'>
        & { aliases: (
          { __typename: 'ArtifactAliasConnection' }
          & { edges: Array<(
            { __typename: 'ArtifactAliasEdge' }
            & { node?: Maybe<(
              { __typename: 'ArtifactAlias' }
              & Pick<ArtifactAlias, 'id' | 'alias'>
            )> }
          )> }
        ), aliasActionHistory: (
          { __typename: 'ArtifactAliasActionConnection' }
          & { pageInfo: (
            { __typename: 'PageInfo' }
            & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
          ), edges: Array<(
            { __typename: 'ArtifactAliasActionEdge' }
            & { node: (
              { __typename: 'ArtifactAliasAction' }
              & Pick<ArtifactAliasAction, 'createdAt' | 'alias' | 'actionType'>
              & { targetArtifact?: Maybe<(
                { __typename: 'Artifact' }
                & Pick<Artifact, 'id' | 'versionIndex'>
                & { artifactSequence: (
                  { __typename: 'ArtifactSequence' }
                  & Pick<ArtifactSequence, 'id' | 'name'>
                  & { project?: Maybe<(
                    { __typename: 'Project' }
                    & Pick<Project, 'id' | 'name'>
                  )> }
                ) }
              )>, sourceArtifact?: Maybe<(
                { __typename: 'Artifact' }
                & Pick<Artifact, 'id' | 'versionIndex'>
                & { artifactSequence: (
                  { __typename: 'ArtifactSequence' }
                  & Pick<ArtifactSequence, 'id' | 'name'>
                  & { project?: Maybe<(
                    { __typename: 'Project' }
                    & Pick<Project, 'id' | 'name'>
                  )> }
                ) }
              )>, user?: Maybe<(
                { __typename: 'User' }
                & Pick<User, 'id' | 'username' | 'photoUrl' | 'name' | 'accountType'>
              )> }
            ) }
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type ArtifactCollectionActionHistoryQueryVariables = Exact<{
  projectName?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  artifactTypeName: Scalars['String'];
  artifactCollectionName: Scalars['String'];
  actionCursor?: Maybe<Scalars['String']>;
  actionFirst?: Maybe<Scalars['Int']>;
}>;


export type ArtifactCollectionActionHistoryQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { artifactType?: Maybe<(
      { __typename: 'ArtifactType' }
      & Pick<ArtifactType, 'id'>
      & { artifactCollection?: Maybe<(
        { __typename: 'ArtifactPortfolio' }
        & Pick<ArtifactPortfolio, 'id'>
        & { aliases: (
          { __typename: 'ArtifactAliasConnection' }
          & { edges: Array<(
            { __typename: 'ArtifactAliasEdge' }
            & { node?: Maybe<(
              { __typename: 'ArtifactAlias' }
              & Pick<ArtifactAlias, 'id' | 'alias'>
            )> }
          )> }
        ), artifactCollectionActions: (
          { __typename: 'ArtifactCollectionActionConnection' }
          & { pageInfo: (
            { __typename: 'PageInfo' }
            & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
          ), edges: Array<(
            { __typename: 'ArtifactCollectionActionEdge' }
            & { node: (
              { __typename: 'ArtifactCollectionAction' }
              & Pick<ArtifactCollectionAction, 'id' | 'createdAt' | 'actionType'>
              & { user?: Maybe<(
                { __typename: 'User' }
                & Pick<User, 'id' | 'username' | 'name' | 'accountType'>
              )>, artifact?: Maybe<(
                { __typename: 'Artifact' }
                & Pick<Artifact, 'id' | 'versionIndex'>
                & { artifactSequence: (
                  { __typename: 'ArtifactSequence' }
                  & Pick<ArtifactSequence, 'id' | 'name'>
                  & { project?: Maybe<(
                    { __typename: 'Project' }
                    & Pick<Project, 'id' | 'name'>
                  )> }
                ) }
              )> }
            ) }
          )> }
        ) }
      ) | (
        { __typename: 'ArtifactSequence' }
        & Pick<ArtifactSequence, 'id'>
        & { aliases: (
          { __typename: 'ArtifactAliasConnection' }
          & { edges: Array<(
            { __typename: 'ArtifactAliasEdge' }
            & { node?: Maybe<(
              { __typename: 'ArtifactAlias' }
              & Pick<ArtifactAlias, 'id' | 'alias'>
            )> }
          )> }
        ), artifactCollectionActions: (
          { __typename: 'ArtifactCollectionActionConnection' }
          & { pageInfo: (
            { __typename: 'PageInfo' }
            & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
          ), edges: Array<(
            { __typename: 'ArtifactCollectionActionEdge' }
            & { node: (
              { __typename: 'ArtifactCollectionAction' }
              & Pick<ArtifactCollectionAction, 'id' | 'createdAt' | 'actionType'>
              & { user?: Maybe<(
                { __typename: 'User' }
                & Pick<User, 'id' | 'username' | 'name' | 'accountType'>
              )>, artifact?: Maybe<(
                { __typename: 'Artifact' }
                & Pick<Artifact, 'id' | 'versionIndex'>
                & { artifactSequence: (
                  { __typename: 'ArtifactSequence' }
                  & Pick<ArtifactSequence, 'id' | 'name'>
                  & { project?: Maybe<(
                    { __typename: 'Project' }
                    & Pick<Project, 'id' | 'name'>
                  )> }
                ) }
              )> }
            ) }
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type ArtifactCollectionQueryVariables = Exact<{
  projectName?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  artifactTypeName: Scalars['String'];
  artifactCollectionName: Scalars['String'];
  artifactCursor?: Maybe<Scalars['String']>;
  artifactFirst?: Maybe<Scalars['Int']>;
}>;


export type ArtifactCollectionQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { artifactType?: Maybe<(
      { __typename: 'ArtifactType' }
      & Pick<ArtifactType, 'id' | 'name' | 'description'>
      & { artifactCollection?: Maybe<(
        { __typename: 'ArtifactPortfolio' }
        & Pick<ArtifactPortfolio, 'id' | 'name' | 'description'>
        & { artifacts: (
          { __typename: 'VersionedArtifactConnection' }
          & Pick<VersionedArtifactConnection, 'totalCount'>
        ), artifactMemberships: (
          { __typename: 'ArtifactCollectionMembershipConnection' }
          & { pageInfo: (
            { __typename: 'PageInfo' }
            & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
          ), edges: Array<(
            { __typename: 'ArtifactCollectionMembershipEdge' }
            & { node?: Maybe<(
              { __typename: 'ArtifactCollectionMembership' }
              & Pick<ArtifactCollectionMembership, 'id' | 'createdAt' | 'commitHash' | 'versionIndex'>
              & { artifact?: Maybe<(
                { __typename: 'Artifact' }
                & Pick<Artifact, 'id' | 'digest' | 'state' | 'createdAt'>
              )>, aliases: Array<(
                { __typename: 'ArtifactAlias' }
                & Pick<ArtifactAlias, 'id' | 'alias'>
                & { artifactCollection?: Maybe<(
                  { __typename: 'ArtifactPortfolio' }
                  & Pick<ArtifactPortfolio, 'id' | 'name'>
                ) | (
                  { __typename: 'ArtifactSequence' }
                  & Pick<ArtifactSequence, 'id' | 'name'>
                )> }
              )> }
            )> }
          )> }
        ), aliases: (
          { __typename: 'ArtifactAliasConnection' }
          & { edges: Array<(
            { __typename: 'ArtifactAliasEdge' }
            & { node?: Maybe<(
              { __typename: 'ArtifactAlias' }
              & Pick<ArtifactAlias, 'id' | 'alias'>
              & { artifact?: Maybe<(
                { __typename: 'Artifact' }
                & Pick<Artifact, 'id' | 'versionIndex'>
              )> }
            )> }
          )> }
        ), tags: (
          { __typename: 'TagConnection' }
          & { edges: Array<(
            { __typename: 'TagEdge' }
            & { node: (
              { __typename: 'Tag' }
              & Pick<Tag, 'id' | 'name' | 'tagCategoryName' | 'attributes'>
            ) }
          )> }
        ) }
      ) | (
        { __typename: 'ArtifactSequence' }
        & Pick<ArtifactSequence, 'id' | 'name' | 'description'>
        & { artifacts: (
          { __typename: 'VersionedArtifactConnection' }
          & Pick<VersionedArtifactConnection, 'totalCount'>
        ), artifactMemberships: (
          { __typename: 'ArtifactCollectionMembershipConnection' }
          & { pageInfo: (
            { __typename: 'PageInfo' }
            & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
          ), edges: Array<(
            { __typename: 'ArtifactCollectionMembershipEdge' }
            & { node?: Maybe<(
              { __typename: 'ArtifactCollectionMembership' }
              & Pick<ArtifactCollectionMembership, 'id' | 'createdAt' | 'commitHash' | 'versionIndex'>
              & { artifact?: Maybe<(
                { __typename: 'Artifact' }
                & Pick<Artifact, 'id' | 'digest' | 'state' | 'createdAt'>
              )>, aliases: Array<(
                { __typename: 'ArtifactAlias' }
                & Pick<ArtifactAlias, 'id' | 'alias'>
                & { artifactCollection?: Maybe<(
                  { __typename: 'ArtifactPortfolio' }
                  & Pick<ArtifactPortfolio, 'id' | 'name'>
                ) | (
                  { __typename: 'ArtifactSequence' }
                  & Pick<ArtifactSequence, 'id' | 'name'>
                )> }
              )> }
            )> }
          )> }
        ), aliases: (
          { __typename: 'ArtifactAliasConnection' }
          & { edges: Array<(
            { __typename: 'ArtifactAliasEdge' }
            & { node?: Maybe<(
              { __typename: 'ArtifactAlias' }
              & Pick<ArtifactAlias, 'id' | 'alias'>
              & { artifact?: Maybe<(
                { __typename: 'Artifact' }
                & Pick<Artifact, 'id' | 'versionIndex'>
              )> }
            )> }
          )> }
        ), tags: (
          { __typename: 'TagConnection' }
          & { edges: Array<(
            { __typename: 'TagEdge' }
            & { node: (
              { __typename: 'Tag' }
              & Pick<Tag, 'id' | 'name' | 'tagCategoryName' | 'attributes'>
            ) }
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type EntityPortfoliosQueryVariables = Exact<{
  entityName: Scalars['String'];
  artifactTypeName: Scalars['String'];
}>;


export type EntityPortfoliosQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id' | 'name'>
    & { projects?: Maybe<(
      { __typename: 'ProjectConnection' }
      & { edges: Array<(
        { __typename: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename: 'Project' }
          & Pick<Project, 'id' | 'name'>
          & { artifactType?: Maybe<(
            { __typename: 'ArtifactType' }
            & Pick<ArtifactType, 'id' | 'name'>
            & { artifactCollections?: Maybe<(
              { __typename: 'ArtifactCollectionConnection' }
              & { edges: Array<(
                { __typename: 'ArtifactCollectionEdge' }
                & { node?: Maybe<(
                  { __typename: 'ArtifactPortfolio' }
                  & Pick<ArtifactPortfolio, 'id' | 'name'>
                ) | (
                  { __typename: 'ArtifactSequence' }
                  & Pick<ArtifactSequence, 'id' | 'name'>
                )> }
              )> }
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type ArtifactCollectionNameFromIdQueryVariables = Exact<{
  artifactCollectionID: Scalars['ID'];
}>;


export type ArtifactCollectionNameFromIdQuery = (
  { __typename: 'Query' }
  & { artifactCollection?: Maybe<(
    { __typename: 'ArtifactPortfolio' }
    & Pick<ArtifactPortfolio, 'id' | 'name'>
    & { project?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id' | 'name'>
    )> }
  ) | (
    { __typename: 'ArtifactSequence' }
    & Pick<ArtifactSequence, 'id' | 'name'>
    & { project?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id' | 'name'>
    )> }
  )> }
);

export type ArtifactFilesQueryVariables = Exact<{
  artifactID: Scalars['ID'];
  artifactFileNames: Array<Scalars['String']>;
}>;


export type ArtifactFilesQuery = (
  { __typename: 'Query' }
  & { artifact?: Maybe<(
    { __typename: 'Artifact' }
    & Pick<Artifact, 'id' | 'createdAt'>
    & { files: (
      { __typename: 'FileConnection' }
      & { edges: Array<(
        { __typename: 'FileEdge' }
        & { node?: Maybe<(
          { __typename: 'File' }
          & Pick<File, 'id' | 'name' | 'displayName' | 'digest' | 'url' | 'directUrl' | 'isReference' | 'sizeBytes' | 'updatedAt'>
        )> }
      )> }
    ) }
  )> }
);

export type ArtifactIdQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  artifactTypeName: Scalars['String'];
  artifactID: Scalars['String'];
}>;


export type ArtifactIdQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { artifactType?: Maybe<(
      { __typename: 'ArtifactType' }
      & Pick<ArtifactType, 'id'>
      & { artifact?: Maybe<(
        { __typename: 'Artifact' }
        & Pick<Artifact, 'id'>
      )> }
    )> }
  )> }
);

export type ArtifactPathInfoQueryVariables = Exact<{
  artifactId: Scalars['ID'];
}>;


export type ArtifactPathInfoQuery = (
  { __typename: 'Query' }
  & { artifact?: Maybe<(
    { __typename: 'Artifact' }
    & Pick<Artifact, 'id' | 'createdAt' | 'commitHash' | 'versionIndex'>
    & { artifactSequence: (
      { __typename: 'ArtifactSequence' }
      & Pick<ArtifactSequence, 'id' | 'name'>
    ), artifactType: (
      { __typename: 'ArtifactType' }
      & Pick<ArtifactType, 'id' | 'name'>
      & { project: (
        { __typename: 'Project' }
        & Pick<Project, 'id' | 'name' | 'entityName'>
      ) }
    ) }
  )> }
);

export type ArtifactManifestQueryVariables = Exact<{
  projectName?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  artifactTypeName: Scalars['String'];
  artifactID: Scalars['String'];
}>;


export type ArtifactManifestQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { artifactType?: Maybe<(
      { __typename: 'ArtifactType' }
      & Pick<ArtifactType, 'id'>
      & { artifact?: Maybe<(
        { __typename: 'Artifact' }
        & Pick<Artifact, 'id' | 'createdAt'>
        & { currentManifest?: Maybe<(
          { __typename: 'ArtifactManifest' }
          & Pick<ArtifactManifest, 'id'>
          & { file: (
            { __typename: 'File' }
            & Pick<File, 'id' | 'directUrl'>
          ) }
        )> }
      )> }
    )> }
  )>, serverInfo?: Maybe<(
    { __typename: 'ServerInfo' }
    & { defaultCloudRegion: (
      { __typename: 'CloudRegion' }
      & Pick<CloudRegion, 'id'>
    ) }
  )> }
);

export type ArtifactManifestFromIdQueryVariables = Exact<{
  artifactId: Scalars['ID'];
}>;


export type ArtifactManifestFromIdQuery = (
  { __typename: 'Query' }
  & { artifact?: Maybe<(
    { __typename: 'Artifact' }
    & Pick<Artifact, 'id' | 'createdAt'>
    & { artifactSequence: (
      { __typename: 'ArtifactSequence' }
      & Pick<ArtifactSequence, 'id' | 'name'>
      & { project?: Maybe<(
        { __typename: 'Project' }
        & Pick<Project, 'id' | 'name' | 'entityName'>
      )> }
    ), artifactType: (
      { __typename: 'ArtifactType' }
      & Pick<ArtifactType, 'id' | 'name'>
    ), currentManifest?: Maybe<(
      { __typename: 'ArtifactManifest' }
      & Pick<ArtifactManifest, 'id'>
      & { file: (
        { __typename: 'File' }
        & Pick<File, 'id' | 'directUrl'>
      ) }
    )> }
  )>, serverInfo?: Maybe<(
    { __typename: 'ServerInfo' }
    & { defaultCloudRegion: (
      { __typename: 'CloudRegion' }
      & Pick<CloudRegion, 'id'>
    ) }
  )> }
);

export type ResolveLatestSequenceAliasToArtifactIdQueryVariables = Exact<{
  sequenceId: Scalars['ID'];
}>;


export type ResolveLatestSequenceAliasToArtifactIdQuery = (
  { __typename: 'Query' }
  & { artifactSequence?: Maybe<(
    { __typename: 'ArtifactSequence' }
    & Pick<ArtifactSequence, 'id'>
    & { latestArtifact?: Maybe<(
      { __typename: 'Artifact' }
      & Pick<Artifact, 'id'>
    )> }
  )> }
);

export type ArtifactCommitHashQueryVariables = Exact<{
  id: Scalars['ID'];
  artifactCollectionName: Scalars['String'];
}>;


export type ArtifactCommitHashQuery = (
  { __typename: 'Query' }
  & { artifact?: Maybe<(
    { __typename: 'Artifact' }
    & Pick<Artifact, 'id' | 'commitHash'>
  )> }
);

export type CreateArtifactTypesMutationVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  artifactTypes: Array<ArtifactTypeInput>;
}>;


export type CreateArtifactTypesMutation = (
  { __typename: 'Mutation' }
  & { createArtifactTypes?: Maybe<(
    { __typename: 'CreateArtifactTypesPayload' }
    & { artifactTypes: Array<(
      { __typename: 'ArtifactType' }
      & Pick<ArtifactType, 'id' | 'name'>
    )> }
  )> }
);

export type UpdateArtifactMutationVariables = Exact<{
  artifactID: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
  aliases?: Maybe<Array<ArtifactAliasInput>>;
  labels?: Maybe<Scalars['JSONString']>;
  metadata?: Maybe<Scalars['JSONString']>;
  ttlDurationSeconds?: Maybe<Scalars['Int64']>;
}>;


export type UpdateArtifactMutation = (
  { __typename: 'Mutation' }
  & { updateArtifact?: Maybe<(
    { __typename: 'UpdateArtifactPayload' }
    & { artifact: (
      { __typename: 'Artifact' }
      & Pick<Artifact, 'id' | 'digest' | 'description' | 'labels' | 'ttlDurationSeconds'>
      & { aliases: Array<(
        { __typename: 'ArtifactAlias' }
        & Pick<ArtifactAlias, 'id' | 'artifactCollectionName' | 'alias'>
      )> }
    ) }
  )> }
);

export type DeleteArtifactMutationVariables = Exact<{
  artifactID: Scalars['ID'];
  deleteAliases?: Maybe<Scalars['Boolean']>;
}>;


export type DeleteArtifactMutation = (
  { __typename: 'Mutation' }
  & { deleteArtifact?: Maybe<(
    { __typename: 'DeleteArtifactPayload' }
    & { artifact: (
      { __typename: 'Artifact' }
      & Pick<Artifact, 'id' | 'state'>
    ) }
  )> }
);

export type DeleteArtifactSequenceMutationVariables = Exact<{
  artifactSequenceID: Scalars['ID'];
}>;


export type DeleteArtifactSequenceMutation = (
  { __typename: 'Mutation' }
  & { deleteArtifactSequence?: Maybe<(
    { __typename: 'DeleteArtifactCollectionPayload' }
    & { artifactCollection: (
      { __typename: 'ArtifactPortfolio' }
      & Pick<ArtifactPortfolio, 'id' | 'state'>
    ) | (
      { __typename: 'ArtifactSequence' }
      & Pick<ArtifactSequence, 'id' | 'state'>
    ) }
  )> }
);

export type UpdateArtifactTypeMutationVariables = Exact<{
  artifactTypeID: Scalars['ID'];
  description?: Maybe<Scalars['String']>;
}>;


export type UpdateArtifactTypeMutation = (
  { __typename: 'Mutation' }
  & { updateArtifactType?: Maybe<(
    { __typename: 'UpdateArtifactTypePayload' }
    & { artifactType: (
      { __typename: 'ArtifactType' }
      & Pick<ArtifactType, 'id' | 'description'>
    ) }
  )> }
);

export type UpdateArtifactPortfolioMutationVariables = Exact<{
  artifactPortfolioID: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}>;


export type UpdateArtifactPortfolioMutation = (
  { __typename: 'Mutation' }
  & { updateArtifactPortfolio?: Maybe<(
    { __typename: 'UpdateArtifactCollectionPayload' }
    & { artifactCollection: (
      { __typename: 'ArtifactPortfolio' }
      & Pick<ArtifactPortfolio, 'id' | 'name' | 'description'>
    ) | (
      { __typename: 'ArtifactSequence' }
      & Pick<ArtifactSequence, 'id' | 'name' | 'description'>
    ) }
  )> }
);

export type UpdateArtifactCollectionMutationVariables = Exact<{
  artifactSequenceID: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
}>;


export type UpdateArtifactCollectionMutation = (
  { __typename: 'Mutation' }
  & { updateArtifactSequence?: Maybe<(
    { __typename: 'UpdateArtifactCollectionPayload' }
    & { artifactCollection: (
      { __typename: 'ArtifactPortfolio' }
      & Pick<ArtifactPortfolio, 'id' | 'name' | 'description'>
    ) | (
      { __typename: 'ArtifactSequence' }
      & Pick<ArtifactSequence, 'id' | 'name' | 'description'>
    ) }
  )> }
);

export type MoveArtifactCollectionMutationVariables = Exact<{
  artifactSequenceID: Scalars['ID'];
  destinationArtifactTypeName: Scalars['String'];
}>;


export type MoveArtifactCollectionMutation = (
  { __typename: 'Mutation' }
  & { moveArtifactSequence?: Maybe<(
    { __typename: 'MoveArtifactCollectionPayload' }
    & { artifactCollection?: Maybe<(
      { __typename: 'ArtifactPortfolio' }
      & Pick<ArtifactPortfolio, 'id' | 'name' | 'description'>
    ) | (
      { __typename: 'ArtifactSequence' }
      & Pick<ArtifactSequence, 'id' | 'name' | 'description'>
    )> }
  )> }
);

export type DeleteArtifactCollectionMutationVariables = Exact<{
  artifactSequenceID: Scalars['ID'];
}>;


export type DeleteArtifactCollectionMutation = (
  { __typename: 'Mutation' }
  & { deleteArtifactSequence?: Maybe<(
    { __typename: 'DeleteArtifactCollectionPayload' }
    & { artifactCollection: (
      { __typename: 'ArtifactPortfolio' }
      & Pick<ArtifactPortfolio, 'id'>
    ) | (
      { __typename: 'ArtifactSequence' }
      & Pick<ArtifactSequence, 'id'>
    ) }
  )> }
);

export type DeleteArtifactPortfolioMutationVariables = Exact<{
  artifactPortfolioID: Scalars['ID'];
}>;


export type DeleteArtifactPortfolioMutation = (
  { __typename: 'Mutation' }
  & { deleteArtifactPortfolio?: Maybe<(
    { __typename: 'DeleteArtifactCollectionPayload' }
    & { artifactCollection: (
      { __typename: 'ArtifactPortfolio' }
      & Pick<ArtifactPortfolio, 'id'>
    ) | (
      { __typename: 'ArtifactSequence' }
      & Pick<ArtifactSequence, 'id'>
    ) }
  )> }
);

export type CreateArtifactFilesMutationVariables = Exact<{
  artifactFiles: Array<CreateArtifactFileSpecInput>;
  layout?: Maybe<ArtifactStorageLayout>;
}>;


export type CreateArtifactFilesMutation = (
  { __typename: 'Mutation' }
  & { createArtifactFiles?: Maybe<(
    { __typename: 'CreateArtifactFilesPayload' }
    & { files: (
      { __typename: 'FileConnection' }
      & { edges: Array<(
        { __typename: 'FileEdge' }
        & { node?: Maybe<(
          { __typename: 'File' }
          & Pick<File, 'id' | 'name' | 'displayName' | 'directUrl' | 'isReference' | 'sizeBytes' | 'updatedAt'>
          & { uploadUrl: File['url'] }
        )> }
      )> }
    ) }
  )> }
);

export type CreateArtifactPortfolioMutationVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  artifactTypeID: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type CreateArtifactPortfolioMutation = (
  { __typename: 'Mutation' }
  & { createArtifactPortfolio?: Maybe<(
    { __typename: 'CreateArtifactCollectionPayload' }
    & { artifactCollection: (
      { __typename: 'ArtifactPortfolio' }
      & Pick<ArtifactPortfolio, 'id' | 'name'>
    ) | (
      { __typename: 'ArtifactSequence' }
      & Pick<ArtifactSequence, 'id' | 'name'>
    ) }
  )> }
);

export type CreateArtifactSequenceMutationVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  artifactTypeID: Scalars['ID'];
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type CreateArtifactSequenceMutation = (
  { __typename: 'Mutation' }
  & { createArtifactSequence?: Maybe<(
    { __typename: 'CreateArtifactCollectionPayload' }
    & { artifactCollection: (
      { __typename: 'ArtifactPortfolio' }
      & Pick<ArtifactPortfolio, 'id' | 'name'>
    ) | (
      { __typename: 'ArtifactSequence' }
      & Pick<ArtifactSequence, 'id' | 'name'>
    ) }
  )> }
);

export type LinkArtifactMutationVariables = Exact<{
  artifactID?: Maybe<Scalars['ID']>;
  artifactPortfolioID?: Maybe<Scalars['ID']>;
  aliases?: Maybe<Array<ArtifactAliasInput>>;
}>;


export type LinkArtifactMutation = (
  { __typename: 'Mutation' }
  & { linkArtifact?: Maybe<(
    { __typename: 'LinkArtifactPayload' }
    & Pick<LinkArtifactPayload, 'artifactCollectionID' | 'artifactID' | 'createdAt' | 'versionIndex' | 'clientMutationId'>
  )> }
);

export type UnlinkArtifactMutationVariables = Exact<{
  artifactID: Scalars['ID'];
  artifactPortfolioID: Scalars['ID'];
}>;


export type UnlinkArtifactMutation = (
  { __typename: 'Mutation' }
  & { unlinkArtifact?: Maybe<(
    { __typename: 'UnlinkArtifactPayload' }
    & Pick<UnlinkArtifactPayload, 'artifactID' | 'success' | 'clientMutationId'>
  )> }
);

export type AddAliasesMutationVariables = Exact<{
  artifactID: Scalars['ID'];
  aliases: Array<ArtifactCollectionAliasInput>;
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type AddAliasesMutation = (
  { __typename: 'Mutation' }
  & { addAliases?: Maybe<(
    { __typename: 'AddAliasesPayload' }
    & Pick<AddAliasesPayload, 'success'>
  )> }
);

export type DeleteAliasesMutationVariables = Exact<{
  artifactID: Scalars['ID'];
  aliases: Array<ArtifactCollectionAliasInput>;
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type DeleteAliasesMutation = (
  { __typename: 'Mutation' }
  & { deleteAliases?: Maybe<(
    { __typename: 'DeleteAliasesPayload' }
    & Pick<DeleteAliasesPayload, 'success'>
  )> }
);

export type UpdateArtifactEntityTtlMutationVariables = Exact<{
  entityName: Scalars['String'];
  ttlDurationSeconds: Scalars['Int64'];
}>;


export type UpdateArtifactEntityTtlMutation = (
  { __typename: 'Mutation' }
  & { updateArtifactEntityTTL?: Maybe<(
    { __typename: 'UpdateArtifactEntityTTLPayload' }
    & Pick<UpdateArtifactEntityTtlPayload, 'clientMutationId'>
  )> }
);

export type DeleteArtifactEntityTtlMutationVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type DeleteArtifactEntityTtlMutation = (
  { __typename: 'Mutation' }
  & { deleteArtifactEntityTTL?: Maybe<(
    { __typename: 'DeleteArtifactEntityTTLPayload' }
    & Pick<DeleteArtifactEntityTtlPayload, 'clientMutationId'>
  )> }
);

export type ArtifactRedirectQueryVariables = Exact<{
  projectName?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  artifactTypeName: Scalars['String'];
  artifactCollectionName: Scalars['String'];
  membershipIdentifier: Scalars['String'];
}>;


export type ArtifactRedirectQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { artifactTypes: (
      { __typename: 'ArtifactTypeConnection' }
      & { edges: Array<(
        { __typename: 'ArtifactTypeEdge' }
        & { node?: Maybe<(
          { __typename: 'ArtifactType' }
          & Pick<ArtifactType, 'id' | 'name'>
          & { artifactCollections?: Maybe<(
            { __typename: 'ArtifactCollectionConnection' }
            & { edges: Array<(
              { __typename: 'ArtifactCollectionEdge' }
              & { node?: Maybe<(
                { __typename: 'ArtifactPortfolio' }
                & Pick<ArtifactPortfolio, 'id' | 'name'>
                & { artifactMemberships: (
                  { __typename: 'ArtifactCollectionMembershipConnection' }
                  & { edges: Array<(
                    { __typename: 'ArtifactCollectionMembershipEdge' }
                    & { node?: Maybe<(
                      { __typename: 'ArtifactCollectionMembership' }
                      & Pick<ArtifactCollectionMembership, 'id' | 'versionIndex'>
                    )> }
                  )> }
                ) }
              ) | (
                { __typename: 'ArtifactSequence' }
                & Pick<ArtifactSequence, 'id' | 'name'>
                & { artifactMemberships: (
                  { __typename: 'ArtifactCollectionMembershipConnection' }
                  & { edges: Array<(
                    { __typename: 'ArtifactCollectionMembershipEdge' }
                    & { node?: Maybe<(
                      { __typename: 'ArtifactCollectionMembership' }
                      & Pick<ArtifactCollectionMembership, 'id' | 'versionIndex'>
                    )> }
                  )> }
                ) }
              )> }
            )> }
          )> }
        )> }
      )> }
    ), artifactType?: Maybe<(
      { __typename: 'ArtifactType' }
      & Pick<ArtifactType, 'id' | 'name'>
      & { artifactCollections?: Maybe<(
        { __typename: 'ArtifactCollectionConnection' }
        & { edges: Array<(
          { __typename: 'ArtifactCollectionEdge' }
          & { node?: Maybe<(
            { __typename: 'ArtifactPortfolio' }
            & Pick<ArtifactPortfolio, 'id' | 'name'>
            & { artifactMemberships: (
              { __typename: 'ArtifactCollectionMembershipConnection' }
              & { edges: Array<(
                { __typename: 'ArtifactCollectionMembershipEdge' }
                & { node?: Maybe<(
                  { __typename: 'ArtifactCollectionMembership' }
                  & Pick<ArtifactCollectionMembership, 'id' | 'versionIndex'>
                )> }
              )> }
            ) }
          ) | (
            { __typename: 'ArtifactSequence' }
            & Pick<ArtifactSequence, 'id' | 'name'>
            & { artifactMemberships: (
              { __typename: 'ArtifactCollectionMembershipConnection' }
              & { edges: Array<(
                { __typename: 'ArtifactCollectionMembershipEdge' }
                & { node?: Maybe<(
                  { __typename: 'ArtifactCollectionMembership' }
                  & Pick<ArtifactCollectionMembership, 'id' | 'versionIndex'>
                )> }
              )> }
            ) }
          )> }
        )> }
      )>, artifactCollection?: Maybe<(
        { __typename: 'ArtifactPortfolio' }
        & Pick<ArtifactPortfolio, 'id' | 'name'>
        & { artifactMemberships: (
          { __typename: 'ArtifactCollectionMembershipConnection' }
          & { edges: Array<(
            { __typename: 'ArtifactCollectionMembershipEdge' }
            & { node?: Maybe<(
              { __typename: 'ArtifactCollectionMembership' }
              & Pick<ArtifactCollectionMembership, 'id' | 'versionIndex'>
            )> }
          )> }
        ), artifactMembership?: Maybe<(
          { __typename: 'ArtifactCollectionMembership' }
          & Pick<ArtifactCollectionMembership, 'id' | 'versionIndex'>
        )> }
      ) | (
        { __typename: 'ArtifactSequence' }
        & Pick<ArtifactSequence, 'id' | 'name'>
        & { artifactMemberships: (
          { __typename: 'ArtifactCollectionMembershipConnection' }
          & { edges: Array<(
            { __typename: 'ArtifactCollectionMembershipEdge' }
            & { node?: Maybe<(
              { __typename: 'ArtifactCollectionMembership' }
              & Pick<ArtifactCollectionMembership, 'id' | 'versionIndex'>
            )> }
          )> }
        ), artifactMembership?: Maybe<(
          { __typename: 'ArtifactCollectionMembership' }
          & Pick<ArtifactCollectionMembership, 'id' | 'versionIndex'>
        )> }
      )> }
    )> }
  )> }
);

export type ArtifactTypeQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  artifactTypeName: Scalars['String'];
}>;


export type ArtifactTypeQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { artifactType?: Maybe<(
      { __typename: 'ArtifactType' }
      & Pick<ArtifactType, 'id' | 'name' | 'description'>
      & { artifactSequences?: Maybe<(
        { __typename: 'ArtifactSequenceConnection' }
        & { edges: Array<(
          { __typename: 'ArtifactSequenceEdge' }
          & { node?: Maybe<(
            { __typename: 'ArtifactSequence' }
            & Pick<ArtifactSequence, 'id' | 'name' | 'description' | 'createdAt'>
            & { artifacts: (
              { __typename: 'VersionedArtifactConnection' }
              & Pick<VersionedArtifactConnection, 'totalCount'>
            ) }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type ArtifactTypesQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
}>;


export type ArtifactTypesQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { artifactTypes: (
      { __typename: 'ArtifactTypeConnection' }
      & { edges: Array<(
        { __typename: 'ArtifactTypeEdge' }
        & { node?: Maybe<(
          { __typename: 'ArtifactType' }
          & Pick<ArtifactType, 'id' | 'name'>
        )> }
      )> }
    ) }
  )> }
);

export type ViewerDefaultResourceConfigsQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerDefaultResourceConfigsQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { teams?: Maybe<(
      { __typename: 'EntityConnection' }
      & { edges: Array<(
        { __typename: 'EntityEdge' }
        & { node?: Maybe<(
          { __typename: 'Entity' }
          & Pick<Entity, 'id'>
          & { defaultResourceConfigs?: Maybe<(
            { __typename: 'DefaultResourceConfigConnection' }
            & { edges: Array<(
              { __typename: 'DefaultResourceConfigEdge' }
              & { node: (
                { __typename: 'DefaultResourceConfig' }
                & Pick<DefaultResourceConfig, 'id' | 'createdAt' | 'updatedAt' | 'resource' | 'config'>
                & { scope: (
                  { __typename: 'DefaultResourceConfigScope' }
                  & Pick<DefaultResourceConfigScope, 'Type' | 'ID'>
                ), templateVariables: Array<(
                  { __typename: 'TemplateVariable' }
                  & Pick<TemplateVariable, 'name' | 'description' | 'schema'>
                )> }
              ) }
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type EntityDefaultResourceConfigsQueryVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type EntityDefaultResourceConfigsQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id'>
    & { defaultResourceConfigs?: Maybe<(
      { __typename: 'DefaultResourceConfigConnection' }
      & { edges: Array<(
        { __typename: 'DefaultResourceConfigEdge' }
        & { node: (
          { __typename: 'DefaultResourceConfig' }
          & Pick<DefaultResourceConfig, 'id' | 'createdAt' | 'updatedAt' | 'resource' | 'config'>
          & { scope: (
            { __typename: 'DefaultResourceConfigScope' }
            & Pick<DefaultResourceConfigScope, 'Type' | 'ID'>
          ), templateVariables: Array<(
            { __typename: 'TemplateVariable' }
            & Pick<TemplateVariable, 'name' | 'description' | 'schema'>
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type ProjectDefaultResourceConfigsQueryVariables = Exact<{
  projectName: Scalars['String'];
}>;


export type ProjectDefaultResourceConfigsQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { defaultResourceConfigs?: Maybe<(
      { __typename: 'DefaultResourceConfigConnection' }
      & { edges: Array<(
        { __typename: 'DefaultResourceConfigEdge' }
        & { node: (
          { __typename: 'DefaultResourceConfig' }
          & Pick<DefaultResourceConfig, 'id' | 'createdAt' | 'updatedAt' | 'resource' | 'config'>
          & { scope: (
            { __typename: 'DefaultResourceConfigScope' }
            & Pick<DefaultResourceConfigScope, 'Type' | 'ID'>
          ), templateVariables: Array<(
            { __typename: 'TemplateVariable' }
            & Pick<TemplateVariable, 'name' | 'description' | 'schema'>
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type CreateDefaultResourceConfigMutationVariables = Exact<{
  entityName: Scalars['String'];
  projectName?: Maybe<Scalars['String']>;
  resource: Scalars['String'];
  config: Scalars['JSONString'];
  templateVariables: Scalars['JSONString'];
}>;


export type CreateDefaultResourceConfigMutation = (
  { __typename: 'Mutation' }
  & { createDefaultResourceConfig?: Maybe<(
    { __typename: 'CreateDefaultResourceConfigPayload' }
    & Pick<CreateDefaultResourceConfigPayload, 'defaultResourceConfigID' | 'schemaValidationErrors' | 'success'>
  )> }
);

export type DeleteDefaultResourceConfigMutationVariables = Exact<{
  defaultResourceConfigIDs: Array<Scalars['ID']>;
}>;


export type DeleteDefaultResourceConfigMutation = (
  { __typename: 'Mutation' }
  & { deleteDefaultResourceConfigs?: Maybe<(
    { __typename: 'DeleteDefaultResourceConfigsPayload' }
    & Pick<DeleteDefaultResourceConfigsPayload, 'numAffected' | 'success'>
  )> }
);

export type UpdateDefaultResourceConfigMutationVariables = Exact<{
  defaultResourceConfigID: Scalars['ID'];
  resource?: Maybe<Scalars['String']>;
  config: Scalars['JSONString'];
  entityName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  templateVariables: Scalars['JSONString'];
}>;


export type UpdateDefaultResourceConfigMutation = (
  { __typename: 'Mutation' }
  & { updateDefaultResourceConfig?: Maybe<(
    { __typename: 'UpdateDefaultResourceConfigPayload' }
    & Pick<UpdateDefaultResourceConfigPayload, 'schemaValidationErrors' | 'success'>
  )> }
);

export type EntityArtifactsQueryVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type EntityArtifactsQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id'>
    & { projects?: Maybe<(
      { __typename: 'ProjectConnection' }
      & { edges: Array<(
        { __typename: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename: 'Project' }
          & Pick<Project, 'id' | 'name'>
          & { artifactTypes: (
            { __typename: 'ArtifactTypeConnection' }
            & { edges: Array<(
              { __typename: 'ArtifactTypeEdge' }
              & { node?: Maybe<(
                { __typename: 'ArtifactType' }
                & Pick<ArtifactType, 'id' | 'name'>
                & { artifactSequences?: Maybe<(
                  { __typename: 'ArtifactSequenceConnection' }
                  & { edges: Array<(
                    { __typename: 'ArtifactSequenceEdge' }
                    & { node?: Maybe<(
                      { __typename: 'ArtifactSequence' }
                      & Pick<ArtifactSequence, 'id' | 'name' | 'createdAt'>
                      & { artifacts: (
                        { __typename: 'VersionedArtifactConnection' }
                        & { edges: Array<(
                          { __typename: 'VersionedArtifactEdge' }
                          & { node: (
                            { __typename: 'Artifact' }
                            & Pick<Artifact, 'id' | 'versionIndex'>
                            & { aliases: Array<(
                              { __typename: 'ArtifactAlias' }
                              & Pick<ArtifactAlias, 'id' | 'alias'>
                            )> }
                          ) }
                        )> }
                      ) }
                    )> }
                  )> }
                )> }
              )> }
            )> }
          ) }
        )> }
      )> }
    )> }
  )> }
);

export type PaidEntityQueryVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type PaidEntityQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id' | 'name' | 'isPaid'>
  )> }
);

export type EntityProfileQueryVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type EntityProfileQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id'>
    & { profileView?: Maybe<(
      { __typename: 'View' }
      & Pick<View, 'id' | 'spec' | 'updatedAt'>
      & { updatedBy?: Maybe<(
        { __typename: 'User' }
        & Pick<User, 'id' | 'username'>
      )> }
    )> }
  )> }
);

export type EntityProjectsReportsQueryVariables = Exact<{
  entityName: Scalars['String'];
  pattern?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
}>;


export type EntityProjectsReportsQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id'>
    & { projects?: Maybe<(
      { __typename: 'ProjectConnection' }
      & { edges: Array<(
        { __typename: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename: 'Project' }
          & Pick<Project, 'id' | 'name' | 'entityName'>
          & { reports?: Maybe<(
            { __typename: 'ViewConnection' }
            & { edges: Array<(
              { __typename: 'ViewEdge' }
              & { node?: Maybe<(
                { __typename: 'View' }
                & Pick<View, 'id'>
                & ViewFragmentMetadataFragment
              )> }
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type EntityTagsQueryVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type EntityTagsQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id'>
    & { tagCategories: Array<(
      { __typename: 'TagCategory' }
      & Pick<TagCategory, 'id' | 'name'>
      & { tags: (
        { __typename: 'TagConnection' }
        & { edges: Array<(
          { __typename: 'TagEdge' }
          & { node: (
            { __typename: 'Tag' }
            & Pick<Tag, 'id' | 'name' | 'tagCategoryName' | 'attributes'>
          ) }
        )> }
      ) }
    )> }
  )> }
);

export type GalleryQueryVariables = Exact<{ [key: string]: never; }>;


export type GalleryQuery = (
  { __typename: 'Query' }
  & { singletonView?: Maybe<(
    { __typename: 'View' }
    & Pick<View, 'id' | 'spec'>
  )> }
);

export type UpdateGalleryMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  spec: Scalars['String'];
}>;


export type UpdateGalleryMutation = (
  { __typename: 'Mutation' }
  & { upsertView?: Maybe<(
    { __typename: 'UpsertViewPayload' }
    & Pick<UpsertViewPayload, 'inserted'>
    & { view?: Maybe<(
      { __typename: 'View' }
      & Pick<View, 'id' | 'updatedAt' | 'createdAt' | 'spec'>
    )> }
  )> }
);

export type FeaturedReportsMetadataQueryVariables = Exact<{
  ids: Array<Scalars['ID']>;
  recentStarCountFrom?: Maybe<Scalars['DateTime']>;
}>;


export type FeaturedReportsMetadataQuery = (
  { __typename: 'Query' }
  & { views?: Maybe<(
    { __typename: 'ViewConnection' }
    & { edges: Array<(
      { __typename: 'ViewEdge' }
      & { node?: Maybe<(
        { __typename: 'View' }
        & Pick<View, 'id' | 'createdAt' | 'updatedAt' | 'entityName' | 'displayName' | 'description' | 'previewUrl' | 'viewCount' | 'starCount' | 'starred'>
        & { recentStarCount: View['starCount'] }
        & { project?: Maybe<(
          { __typename: 'Project' }
          & Pick<Project, 'id' | 'entityName' | 'name' | 'access'>
        )>, discussionThreads: (
          { __typename: 'DiscussionThreadConnection' }
          & { edges: Array<(
            { __typename: 'DiscussionThreadEdge' }
            & { node: (
              { __typename: 'DiscussionThread' }
              & Pick<DiscussionThread, 'id'>
              & { comments: (
                { __typename: 'DiscussionCommentConnection' }
                & Pick<DiscussionCommentConnection, 'totalCount'>
              ) }
            ) }
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type FeaturedReportsAuthorsQueryVariables = Exact<{
  usernames: Array<Scalars['String']>;
}>;


export type FeaturedReportsAuthorsQuery = (
  { __typename: 'Query' }
  & { users?: Maybe<(
    { __typename: 'UserConnection' }
    & { edges: Array<(
      { __typename: 'UserEdge' }
      & { node?: Maybe<(
        { __typename: 'User' }
        & Pick<User, 'id' | 'name' | 'username' | 'photoUrl' | 'userInfo'>
      )> }
    )> }
  )> }
);

export type InsertGalleryDiscussionMutationVariables = Exact<{
  spec: Scalars['String'];
  reportID: Scalars['ID'];
  sendEmail?: Maybe<Scalars['Boolean']>;
}>;


export type InsertGalleryDiscussionMutation = (
  { __typename: 'Mutation' }
  & { insertGalleryDiscussion?: Maybe<(
    { __typename: 'InsertGalleryDiscussionPayload' }
    & Pick<InsertGalleryDiscussionPayload, 'success'>
  )> }
);

export type SubmitFcNewsMutationVariables = Exact<{
  name: Scalars['String'];
  email: Scalars['String'];
  url: Scalars['String'];
  details?: Maybe<Scalars['String']>;
}>;


export type SubmitFcNewsMutation = (
  { __typename: 'Mutation' }
  & { submitFCNews?: Maybe<(
    { __typename: 'SubmitFCNewsPayload' }
    & Pick<SubmitFcNewsPayload, 'success'>
  )> }
);

export type GalleryTagSectionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GalleryTagSectionsQuery = (
  { __typename: 'Query' }
  & Pick<Query, 'galleryTagSections'>
);

export type ProjectAccessQueryVariables = Exact<{
  name?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
}>;


export type ProjectAccessQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'access'>
  )> }
);

export type NewsletterSubscriptionQueryVariables = Exact<{
  userName: Scalars['String'];
}>;


export type NewsletterSubscriptionQuery = (
  { __typename: 'Query' }
  & { user?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { newsletterSubscription?: Maybe<(
      { __typename: 'NewsletterSubscription' }
      & Pick<NewsletterSubscription, 'id'>
    )> }
  )> }
);

export type CreateNewsletterSubscriptionMutationVariables = Exact<{
  email?: Maybe<Scalars['String']>;
}>;


export type CreateNewsletterSubscriptionMutation = (
  { __typename: 'Mutation' }
  & { createNewsletterSubscription?: Maybe<(
    { __typename: 'CreateNewsletterSubscriptionPayload' }
    & { newsletterSubscription: (
      { __typename: 'NewsletterSubscription' }
      & Pick<NewsletterSubscription, 'id'>
    ) }
  )> }
);

export type AllNewsletterSubscriptionsQueryVariables = Exact<{
  first?: Maybe<Scalars['Int']>;
}>;


export type AllNewsletterSubscriptionsQuery = (
  { __typename: 'Query' }
  & { newsletterSubscriptions?: Maybe<(
    { __typename: 'NewsletterSubscriptionConnection' }
    & { edges: Array<(
      { __typename: 'NewsletterSubscriptionEdge' }
      & { node?: Maybe<(
        { __typename: 'NewsletterSubscription' }
        & Pick<NewsletterSubscription, 'id' | 'email' | 'createdAt'>
        & { user?: Maybe<(
          { __typename: 'User' }
          & Pick<User, 'id' | 'username' | 'email'>
        )> }
      )> }
    )> }
  )> }
);

export type GroupPageQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  groupName: Scalars['String'];
}>;


export type GroupPageQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { runGroup?: Maybe<(
      { __typename: 'RunGroup' }
      & Pick<RunGroup, 'id' | 'name' | 'notes'>
      & { tags: Array<(
        { __typename: 'RunTag' }
        & Pick<RunTag, 'id' | 'name' | 'colorIndex'>
      )> }
    )> }
  )> }
);

export type HistoryKeysQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  filters?: Maybe<Scalars['JSONString']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
}>;


export type HistoryKeysQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { runs?: Maybe<(
      { __typename: 'RunConnection' }
      & Pick<RunConnection, 'historyKeys'>
      & { edges: Array<(
        { __typename: 'RunEdge' }
        & { node: (
          { __typename: 'Run' }
          & Pick<Run, 'id' | 'wandbConfig'>
        ) }
      )> }
    )> }
  )> }
);

export type HistoryKeysByProjectIdQueryVariables = Exact<{
  internalId: Scalars['ID'];
  filters?: Maybe<Scalars['JSONString']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
}>;


export type HistoryKeysByProjectIdQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { runs?: Maybe<(
      { __typename: 'RunConnection' }
      & Pick<RunConnection, 'historyKeys'>
      & { edges: Array<(
        { __typename: 'RunEdge' }
        & { node: (
          { __typename: 'Run' }
          & Pick<Run, 'id' | 'wandbConfig'>
        ) }
      )> }
    )> }
  )> }
);

export type InviteQueryVariables = Exact<{
  key: Scalars['String'];
}>;


export type InviteQuery = (
  { __typename: 'Query' }
  & { invite?: Maybe<(
    { __typename: 'Invite' }
    & Pick<Invite, 'id'>
    & { toUser?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'username'>
    )> }
  )> }
);

export type DefaultResourceConfigFragmentFragment = (
  { __typename: 'DefaultResourceConfig' }
  & Pick<DefaultResourceConfig, 'id' | 'createdAt' | 'updatedAt' | 'resource' | 'config'>
  & { scope: (
    { __typename: 'DefaultResourceConfigScope' }
    & Pick<DefaultResourceConfigScope, 'Type' | 'ID'>
  ), templateVariables: Array<(
    { __typename: 'TemplateVariable' }
    & Pick<TemplateVariable, 'name' | 'description' | 'schema'>
  )> }
);

export type FetchEntityJobsQueryVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type FetchEntityJobsQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id' | 'name'>
    & { projects?: Maybe<(
      { __typename: 'ProjectConnection' }
      & { edges: Array<(
        { __typename: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename: 'Project' }
          & Pick<Project, 'id' | 'name'>
          & { artifactType?: Maybe<(
            { __typename: 'ArtifactType' }
            & Pick<ArtifactType, 'id'>
            & { artifactCollections?: Maybe<(
              { __typename: 'ArtifactCollectionConnection' }
              & { edges: Array<(
                { __typename: 'ArtifactCollectionEdge' }
                & { node?: Maybe<(
                  { __typename: 'ArtifactPortfolio' }
                  & Pick<ArtifactPortfolio, 'id' | 'name' | 'description'>
                  & { artifacts: (
                    { __typename: 'VersionedArtifactConnection' }
                    & { edges: Array<(
                      { __typename: 'VersionedArtifactEdge' }
                      & { node: (
                        { __typename: 'Artifact' }
                        & Pick<Artifact, 'id' | 'description' | 'labels' | 'versionIndex'>
                        & { aliases: Array<(
                          { __typename: 'ArtifactAlias' }
                          & Pick<ArtifactAlias, 'id' | 'alias'>
                        )> }
                      ) }
                    )> }
                  ) }
                ) | (
                  { __typename: 'ArtifactSequence' }
                  & Pick<ArtifactSequence, 'id' | 'name' | 'description'>
                  & { artifacts: (
                    { __typename: 'VersionedArtifactConnection' }
                    & { edges: Array<(
                      { __typename: 'VersionedArtifactEdge' }
                      & { node: (
                        { __typename: 'Artifact' }
                        & Pick<Artifact, 'id' | 'description' | 'labels' | 'versionIndex'>
                        & { aliases: Array<(
                          { __typename: 'ArtifactAlias' }
                          & Pick<ArtifactAlias, 'id' | 'alias'>
                        )> }
                      ) }
                    )> }
                  ) }
                )> }
              )> }
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type FetchProjectJobsPartialQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  limit: Scalars['Int'];
  after?: Maybe<Scalars['String']>;
}>;


export type FetchProjectJobsPartialQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'name'>
    & { artifactType?: Maybe<(
      { __typename: 'ArtifactType' }
      & Pick<ArtifactType, 'id'>
      & { artifactCollections?: Maybe<(
        { __typename: 'ArtifactCollectionConnection' }
        & { pageInfo: (
          { __typename: 'PageInfo' }
          & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
        ), edges: Array<(
          { __typename: 'ArtifactCollectionEdge' }
          & { node?: Maybe<(
            { __typename: 'ArtifactPortfolio' }
            & Pick<ArtifactPortfolio, 'id' | 'name' | 'description' | 'createdAt'>
            & { artifacts: (
              { __typename: 'VersionedArtifactConnection' }
              & { edges: Array<(
                { __typename: 'VersionedArtifactEdge' }
                & { node: (
                  { __typename: 'Artifact' }
                  & Pick<Artifact, 'id' | 'description' | 'labels' | 'versionIndex'>
                  & { aliases: Array<(
                    { __typename: 'ArtifactAlias' }
                    & Pick<ArtifactAlias, 'id' | 'alias'>
                  )>, usedBy: (
                    { __typename: 'RunConnection' }
                    & Pick<RunConnection, 'totalCount'>
                    & { edges: Array<(
                      { __typename: 'RunEdge' }
                      & { node: (
                        { __typename: 'Run' }
                        & Pick<Run, 'id' | 'name' | 'displayName' | 'state' | 'createdAt'>
                      ) }
                    )> }
                  ) }
                ) }
              )> }
            ) }
          ) | (
            { __typename: 'ArtifactSequence' }
            & Pick<ArtifactSequence, 'id' | 'name' | 'description' | 'createdAt'>
            & { artifacts: (
              { __typename: 'VersionedArtifactConnection' }
              & { edges: Array<(
                { __typename: 'VersionedArtifactEdge' }
                & { node: (
                  { __typename: 'Artifact' }
                  & Pick<Artifact, 'id' | 'description' | 'labels' | 'versionIndex'>
                  & { aliases: Array<(
                    { __typename: 'ArtifactAlias' }
                    & Pick<ArtifactAlias, 'id' | 'alias'>
                  )>, usedBy: (
                    { __typename: 'RunConnection' }
                    & Pick<RunConnection, 'totalCount'>
                    & { edges: Array<(
                      { __typename: 'RunEdge' }
                      & { node: (
                        { __typename: 'Run' }
                        & Pick<Run, 'id' | 'name' | 'displayName' | 'state' | 'createdAt'>
                      ) }
                    )> }
                  ) }
                ) }
              )> }
            ) }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type FetchJobVersionsQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  jobName: Scalars['String'];
}>;


export type FetchJobVersionsQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { artifactCollection?: Maybe<(
      { __typename: 'ArtifactPortfolio' }
      & Pick<ArtifactPortfolio, 'id' | 'description'>
      & { artifacts: (
        { __typename: 'VersionedArtifactConnection' }
        & { edges: Array<(
          { __typename: 'VersionedArtifactEdge' }
          & { node: (
            { __typename: 'Artifact' }
            & Pick<Artifact, 'id' | 'createdAt'>
            & { createdBy?: Maybe<(
              { __typename: 'Run' }
              & Pick<Run, 'id' | 'name' | 'displayName' | 'defaultColorIndex'>
            ) | (
              { __typename: 'User' }
              & Pick<User, 'id' | 'name' | 'username' | 'photoUrl'>
            )>, aliases: Array<(
              { __typename: 'ArtifactAlias' }
              & Pick<ArtifactAlias, 'id' | 'alias'>
            )> }
          ) }
        )> }
      ) }
    ) | (
      { __typename: 'ArtifactSequence' }
      & Pick<ArtifactSequence, 'id' | 'description'>
      & { artifacts: (
        { __typename: 'VersionedArtifactConnection' }
        & { edges: Array<(
          { __typename: 'VersionedArtifactEdge' }
          & { node: (
            { __typename: 'Artifact' }
            & Pick<Artifact, 'id' | 'createdAt'>
            & { createdBy?: Maybe<(
              { __typename: 'Run' }
              & Pick<Run, 'id' | 'name' | 'displayName' | 'defaultColorIndex'>
            ) | (
              { __typename: 'User' }
              & Pick<User, 'id' | 'name' | 'username' | 'photoUrl'>
            )>, aliases: Array<(
              { __typename: 'ArtifactAlias' }
              & Pick<ArtifactAlias, 'id' | 'alias'>
            )> }
          ) }
        )> }
      ) }
    )> }
  )> }
);

export type FetchJobQueryVariables = Exact<{
  jobId: Scalars['ID'];
}>;


export type FetchJobQuery = (
  { __typename: 'Query' }
  & { artifactCollection?: Maybe<(
    { __typename: 'ArtifactPortfolio' }
    & Pick<ArtifactPortfolio, 'id' | 'name'>
  ) | (
    { __typename: 'ArtifactSequence' }
    & Pick<ArtifactSequence, 'id' | 'name'>
  )> }
);

export type FetchJobDetailsQueryVariables = Exact<{
  jobId: Scalars['ID'];
}>;


export type FetchJobDetailsQuery = (
  { __typename: 'Query' }
  & { artifactCollection?: Maybe<(
    { __typename: 'ArtifactPortfolio' }
    & Pick<ArtifactPortfolio, 'id' | 'name' | 'description' | 'createdAt'>
    & { project?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id' | 'access' | 'name'>
      & { entity: (
        { __typename: 'Entity' }
        & Pick<Entity, 'id' | 'name'>
      ) }
    )>, artifacts: (
      { __typename: 'VersionedArtifactConnection' }
      & { edges: Array<(
        { __typename: 'VersionedArtifactEdge' }
        & { node: (
          { __typename: 'Artifact' }
          & Pick<Artifact, 'id' | 'description' | 'labels' | 'versionIndex' | 'createdAt'>
          & { aliases: Array<(
            { __typename: 'ArtifactAlias' }
            & Pick<ArtifactAlias, 'id' | 'alias'>
          )>, createdBy?: Maybe<(
            { __typename: 'Run' }
            & Pick<Run, 'id' | 'name' | 'displayName' | 'defaultColorIndex'>
          ) | (
            { __typename: 'User' }
            & Pick<User, 'id' | 'name' | 'username' | 'photoUrl'>
          )>, usedBy: (
            { __typename: 'RunConnection' }
            & Pick<RunConnection, 'totalCount'>
            & { edges: Array<(
              { __typename: 'RunEdge' }
              & { node: (
                { __typename: 'Run' }
                & Pick<Run, 'id' | 'name' | 'displayName' | 'state' | 'stopped' | 'createdAt' | 'config' | 'defaultColorIndex'>
                & { user?: Maybe<(
                  { __typename: 'User' }
                  & Pick<User, 'id' | 'name' | 'username' | 'photoUrl'>
                )>, project?: Maybe<(
                  { __typename: 'Project' }
                  & Pick<Project, 'id' | 'name'>
                  & { entity: (
                    { __typename: 'Entity' }
                    & Pick<Entity, 'id' | 'name'>
                  ) }
                )>, runInfo?: Maybe<(
                  { __typename: 'RunInfo' }
                  & Pick<RunInfo, 'args'>
                )> }
              ) }
            )> }
          ) }
        ) }
      )> }
    ) }
  ) | (
    { __typename: 'ArtifactSequence' }
    & Pick<ArtifactSequence, 'id' | 'name' | 'description' | 'createdAt'>
    & { project?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id' | 'access' | 'name'>
      & { entity: (
        { __typename: 'Entity' }
        & Pick<Entity, 'id' | 'name'>
      ) }
    )>, artifacts: (
      { __typename: 'VersionedArtifactConnection' }
      & { edges: Array<(
        { __typename: 'VersionedArtifactEdge' }
        & { node: (
          { __typename: 'Artifact' }
          & Pick<Artifact, 'id' | 'description' | 'labels' | 'versionIndex' | 'createdAt'>
          & { aliases: Array<(
            { __typename: 'ArtifactAlias' }
            & Pick<ArtifactAlias, 'id' | 'alias'>
          )>, createdBy?: Maybe<(
            { __typename: 'Run' }
            & Pick<Run, 'id' | 'name' | 'displayName' | 'defaultColorIndex'>
          ) | (
            { __typename: 'User' }
            & Pick<User, 'id' | 'name' | 'username' | 'photoUrl'>
          )>, usedBy: (
            { __typename: 'RunConnection' }
            & Pick<RunConnection, 'totalCount'>
            & { edges: Array<(
              { __typename: 'RunEdge' }
              & { node: (
                { __typename: 'Run' }
                & Pick<Run, 'id' | 'name' | 'displayName' | 'state' | 'stopped' | 'createdAt' | 'config' | 'defaultColorIndex'>
                & { user?: Maybe<(
                  { __typename: 'User' }
                  & Pick<User, 'id' | 'name' | 'username' | 'photoUrl'>
                )>, project?: Maybe<(
                  { __typename: 'Project' }
                  & Pick<Project, 'id' | 'name'>
                  & { entity: (
                    { __typename: 'Entity' }
                    & Pick<Entity, 'id' | 'name'>
                  ) }
                )>, runInfo?: Maybe<(
                  { __typename: 'RunInfo' }
                  & Pick<RunInfo, 'args'>
                )> }
              ) }
            )> }
          ) }
        ) }
      )> }
    ) }
  )> }
);

export type FetchProjectRunsQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
}>;


export type FetchProjectRunsQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { runs?: Maybe<(
      { __typename: 'RunConnection' }
      & { edges: Array<(
        { __typename: 'RunEdge' }
        & { node: (
          { __typename: 'Run' }
          & Pick<Run, 'id' | 'name' | 'displayName'>
          & { runInfo?: Maybe<(
            { __typename: 'RunInfo' }
            & { git?: Maybe<(
              { __typename: 'GitInfo' }
              & Pick<GitInfo, 'remote' | 'commit'>
            )> }
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type FetchProjectLaunchQueuesQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
}>;


export type FetchProjectLaunchQueuesQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'name'>
    & { entity: (
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'isTeam' | 'name'>
    ), runQueues: Array<(
      { __typename: 'RunQueue' }
      & Pick<RunQueue, 'id' | 'name' | 'access' | 'defaultResourceConfigID' | 'prioritizationMode'>
    )> }
  )> }
);

export type FetchEntityProjectsQueryVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type FetchEntityProjectsQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id'>
    & { projects?: Maybe<(
      { __typename: 'ProjectConnection' }
      & { edges: Array<(
        { __typename: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename: 'Project' }
          & Pick<Project, 'id' | 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type EntityPhotoQueryVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type EntityPhotoQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id' | 'photoUrl'>
  )> }
);

export type UserEntitiesQueryVariables = Exact<{ [key: string]: never; }>;


export type UserEntitiesQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { teams?: Maybe<(
      { __typename: 'EntityConnection' }
      & { edges: Array<(
        { __typename: 'EntityEdge' }
        & { node?: Maybe<(
          { __typename: 'Entity' }
          & Pick<Entity, 'id' | 'name' | 'photoUrl'>
          & { members: Array<(
            { __typename: 'Member' }
            & Pick<Member, 'id' | 'username' | 'role'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type ViewerDestinationProjectsQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerDestinationProjectsQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { teams?: Maybe<(
      { __typename: 'EntityConnection' }
      & { edges: Array<(
        { __typename: 'EntityEdge' }
        & { node?: Maybe<(
          { __typename: 'Entity' }
          & Pick<Entity, 'id' | 'name'>
          & { projects?: Maybe<(
            { __typename: 'ProjectConnection' }
            & { edges: Array<(
              { __typename: 'ProjectEdge' }
              & { node?: Maybe<(
                { __typename: 'Project' }
                & Pick<Project, 'id' | 'name'>
              )> }
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type QueueNamesQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
}>;


export type QueueNamesQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { runQueues: Array<(
      { __typename: 'RunQueue' }
      & Pick<RunQueue, 'id' | 'name'>
    )> }
  )> }
);

export type AllQueuesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllQueuesQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'entity'>
    & { teams?: Maybe<(
      { __typename: 'EntityConnection' }
      & { edges: Array<(
        { __typename: 'EntityEdge' }
        & { node?: Maybe<(
          { __typename: 'Entity' }
          & Pick<Entity, 'id' | 'name' | 'organizationId'>
          & { launchProject?: Maybe<(
            { __typename: 'Project' }
            & Pick<Project, 'id' | 'name'>
            & { launchAgents: Array<(
              { __typename: 'LaunchAgent' }
              & Pick<LaunchAgent, 'id' | 'name' | 'createdAt' | 'heartbeatAt' | 'agentStatus' | 'stopPolling' | 'runQueues' | 'hostname'>
            )>, runQueues: Array<(
              { __typename: 'RunQueue' }
              & Pick<RunQueue, 'id' | 'createdAt' | 'createdBy' | 'updatedAt' | 'name' | 'access' | 'prioritizationMode' | 'executorInfo' | 'externalLinks'>
              & { defaultResourceConfig?: Maybe<(
                { __typename: 'DefaultResourceConfig' }
                & Pick<DefaultResourceConfig, 'id'>
                & DefaultResourceConfigFragmentFragment
              )>, runQueueItems: (
                { __typename: 'RunQueueItemConnection' }
                & { edges: Array<(
                  { __typename: 'RunQueueItemEdge' }
                  & { node: (
                    { __typename: 'RunQueueItem' }
                    & Pick<RunQueueItem, 'id'>
                    & { error?: Maybe<(
                      { __typename: 'RunQueueItemInfoDetails' }
                      & Pick<RunQueueItemInfoDetails, 'message' | 'stage' | 'filePaths'>
                    )>, warnings: Array<Maybe<(
                      { __typename: 'RunQueueItemInfoDetails' }
                      & Pick<RunQueueItemInfoDetails, 'message' | 'stage' | 'filePaths'>
                    )>> }
                    & RunQueueItemFragmentFragment
                  ) }
                )> }
              ) }
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type EntityQueuesLiteQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
}>;


export type EntityQueuesLiteQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id' | 'name'>
    & { launchProject?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id' | 'name'>
      & { launchAgents: Array<(
        { __typename: 'LaunchAgent' }
        & Pick<LaunchAgent, 'id' | 'name' | 'createdAt' | 'heartbeatAt' | 'agentStatus' | 'stopPolling' | 'runQueues' | 'hostname'>
      )>, runQueues: Array<(
        { __typename: 'RunQueue' }
        & Pick<RunQueue, 'id' | 'createdAt' | 'createdBy' | 'updatedAt' | 'name' | 'access' | 'prioritizationMode' | 'executorInfo' | 'externalLinks'>
        & { defaultResourceConfig?: Maybe<(
          { __typename: 'DefaultResourceConfig' }
          & Pick<DefaultResourceConfig, 'id' | 'createdAt' | 'updatedAt' | 'resource' | 'config'>
          & { scope: (
            { __typename: 'DefaultResourceConfigScope' }
            & Pick<DefaultResourceConfigScope, 'Type' | 'ID'>
          ), templateVariables: Array<(
            { __typename: 'TemplateVariable' }
            & Pick<TemplateVariable, 'name' | 'description' | 'schema'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type AllQueuesLiteQueryVariables = Exact<{ [key: string]: never; }>;


export type AllQueuesLiteQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'entity'>
    & { teams?: Maybe<(
      { __typename: 'EntityConnection' }
      & { edges: Array<(
        { __typename: 'EntityEdge' }
        & { node?: Maybe<(
          { __typename: 'Entity' }
          & Pick<Entity, 'id' | 'name' | 'organizationId'>
          & { launchProject?: Maybe<(
            { __typename: 'Project' }
            & Pick<Project, 'id' | 'name'>
            & { launchAgents: Array<(
              { __typename: 'LaunchAgent' }
              & Pick<LaunchAgent, 'id' | 'name' | 'createdAt' | 'heartbeatAt' | 'agentStatus' | 'stopPolling' | 'runQueues' | 'hostname'>
            )>, runQueues: Array<(
              { __typename: 'RunQueue' }
              & Pick<RunQueue, 'id' | 'createdAt' | 'createdBy' | 'updatedAt' | 'name' | 'access' | 'prioritizationMode' | 'executorInfo' | 'externalLinks'>
              & { defaultResourceConfig?: Maybe<(
                { __typename: 'DefaultResourceConfig' }
                & Pick<DefaultResourceConfig, 'id' | 'createdAt' | 'updatedAt' | 'resource' | 'config'>
                & { scope: (
                  { __typename: 'DefaultResourceConfigScope' }
                  & Pick<DefaultResourceConfigScope, 'Type' | 'ID'>
                ), templateVariables: Array<(
                  { __typename: 'TemplateVariable' }
                  & Pick<TemplateVariable, 'name' | 'description' | 'schema'>
                )> }
              )> }
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type RunsInformationQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
}>;


export type RunsInformationQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { runs?: Maybe<(
      { __typename: 'RunConnection' }
      & { edges: Array<(
        { __typename: 'RunEdge' }
        & { node: (
          { __typename: 'Run' }
          & Pick<Run, 'id' | 'name' | 'displayName' | 'state' | 'createdAt' | 'heartbeatAt'>
        ) }
      )> }
    )> }
  )> }
);

export type FetchCodeArtifactFilesQueryVariables = Exact<{
  artifactID: Scalars['ID'];
}>;


export type FetchCodeArtifactFilesQuery = (
  { __typename: 'Query' }
  & { artifact?: Maybe<(
    { __typename: 'Artifact' }
    & Pick<Artifact, 'id'>
    & { files: (
      { __typename: 'FileConnection' }
      & { edges: Array<(
        { __typename: 'FileEdge' }
        & { node?: Maybe<(
          { __typename: 'File' }
          & Pick<File, 'id' | 'name' | 'displayName' | 'mimetype' | 'sizeBytes' | 'url'>
        )> }
      )> }
    ) }
  )> }
);

export type FetchAgentDetailsQueryVariables = Exact<{
  agentId: Scalars['ID'];
}>;


export type FetchAgentDetailsQuery = (
  { __typename: 'Query' }
  & { launchAgent?: Maybe<(
    { __typename: 'LaunchAgent' }
    & Pick<LaunchAgent, 'id' | 'name' | 'createdAt' | 'updatedAt' | 'heartbeatAt' | 'runQueues' | 'hostname' | 'agentStatus' | 'stopPolling' | 'agentConfig' | 'version'>
  )> }
);

export type EntityAgentsQueryVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type EntityAgentsQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id' | 'name'>
    & { launchProject?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id' | 'name'>
      & { launchAgents: Array<(
        { __typename: 'LaunchAgent' }
        & Pick<LaunchAgent, 'id' | 'name' | 'createdAt' | 'heartbeatAt' | 'agentStatus' | 'stopPolling' | 'runQueues' | 'hostname'>
      )> }
    )> }
  )> }
);

export type FetchRunOutputLogQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  runName: Scalars['String'];
}>;


export type FetchRunOutputLogQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { run?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id' | 'logLineCount'>
      & { outputLogFile?: Maybe<(
        { __typename: 'FileConnection' }
        & { edges: Array<(
          { __typename: 'FileEdge' }
          & { node?: Maybe<(
            { __typename: 'File' }
            & Pick<File, 'id' | 'name' | 'md5' | 'sizeBytes' | 'directUrl' | 'url'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type QueueByIdQueryVariables = Exact<{
  entityName: Scalars['String'];
  queueId: Scalars['ID'];
}>;


export type QueueByIdQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'name'>
    & { launchAgents: Array<(
      { __typename: 'LaunchAgent' }
      & Pick<LaunchAgent, 'id' | 'name' | 'createdAt' | 'heartbeatAt' | 'agentStatus' | 'stopPolling' | 'runQueues' | 'hostname'>
    )>, runQueue?: Maybe<(
      { __typename: 'RunQueue' }
      & Pick<RunQueue, 'id' | 'createdAt' | 'name' | 'prioritizationMode' | 'executorInfo' | 'externalLinks'>
      & { defaultResourceConfig?: Maybe<(
        { __typename: 'DefaultResourceConfig' }
        & Pick<DefaultResourceConfig, 'id' | 'createdAt' | 'resource' | 'config'>
        & { templateVariables: Array<(
          { __typename: 'TemplateVariable' }
          & Pick<TemplateVariable, 'name' | 'description' | 'schema'>
        )> }
      )>, runQueueItems: (
        { __typename: 'RunQueueItemConnection' }
        & { edges: Array<(
          { __typename: 'RunQueueItemEdge' }
          & { node: (
            { __typename: 'RunQueueItem' }
            & Pick<RunQueueItem, 'id'>
            & { error?: Maybe<(
              { __typename: 'RunQueueItemInfoDetails' }
              & Pick<RunQueueItemInfoDetails, 'message' | 'stage' | 'filePaths'>
            )>, warnings: Array<Maybe<(
              { __typename: 'RunQueueItemInfoDetails' }
              & Pick<RunQueueItemInfoDetails, 'message' | 'stage' | 'filePaths'>
            )>> }
            & RunQueueItemFragmentFragment
          ) }
        )> }
      ) }
    )> }
  )> }
);

export type RunJobQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  runName: Scalars['String'];
}>;


export type RunJobQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { run?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id'>
      & { job?: Maybe<(
        { __typename: 'Artifact' }
        & Pick<Artifact, 'id' | 'versionIndex'>
        & { artifactSequence: (
          { __typename: 'ArtifactSequence' }
          & Pick<ArtifactSequence, 'id' | 'name'>
        ) }
      )> }
    )> }
  )> }
);

export type RunFilesAndFolderWithDirectUrLsQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  runName: Scalars['String'];
  maxNumFiles: Scalars['Int'];
  pattern?: Maybe<Scalars['String']>;
}>;


export type RunFilesAndFolderWithDirectUrLsQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'name'>
    & { run?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id' | 'name' | 'projectId'>
      & { files?: Maybe<(
        { __typename: 'FileConnection' }
        & { edges: Array<(
          { __typename: 'FileEdge' }
          & { node?: Maybe<(
            { __typename: 'File' }
            & Pick<File, 'id' | 'name' | 'directUrl' | 'sizeBytes' | 'updatedAt'>
            & { artifact?: Maybe<(
              { __typename: 'Artifact' }
              & Pick<Artifact, 'id' | 'digest'>
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type AccountUsingLaunchQueryVariables = Exact<{
  orgID: Scalars['ID'];
}>;


export type AccountUsingLaunchQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'isLaunchActive'>
  )> }
);

export type MessageOfTheDayQueryVariables = Exact<{ [key: string]: never; }>;


export type MessageOfTheDayQuery = (
  { __typename: 'Query' }
  & { serverInfo?: Maybe<(
    { __typename: 'ServerInfo' }
    & Pick<ServerInfo, 'messageOfTheDay'>
  )> }
);

export type MoveViewMutationVariables = Exact<{
  id: Scalars['ID'];
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type MoveViewMutation = (
  { __typename: 'Mutation' }
  & { moveView?: Maybe<(
    { __typename: 'MoveViewPayload' }
    & Pick<MoveViewPayload, 'success' | 'clientMutationId'>
  )> }
);

export type MultiRunWorkspaceQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
}>;


export type MultiRunWorkspaceQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'readOnly' | 'isBenchmark'>
    & { linkedBenchmark?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id' | 'entityName' | 'name' | 'gitHubSubmissionRepo' | 'views'>
    )>, runQueues: Array<(
      { __typename: 'RunQueue' }
      & Pick<RunQueue, 'id' | 'name' | 'createdBy' | 'access'>
    )> }
  )> }
);

export type NwEntityPermissionQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
}>;


export type NwEntityPermissionQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id' | 'isTeam'>
    & { settings: (
      { __typename: 'EntitySettings' }
      & Pick<EntitySettings, 'hidden'>
    ) }
  )> }
);

export type NwProjectPermissionQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
}>;


export type NwProjectPermissionQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'access'>
    & { user?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'username'>
    )>, members: Array<(
      { __typename: 'ProjectMember' }
      & Pick<ProjectMember, 'id' | 'username'>
    )> }
  )> }
);

export type OrgAdminsQueryVariables = Exact<{
  orgName?: Maybe<Scalars['String']>;
}>;


export type OrgAdminsQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { billingUser?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'username'>
    )>, members: Array<(
      { __typename: 'OrganizationMember' }
      & Pick<OrganizationMember, 'id' | 'admin'>
      & { user: (
        { __typename: 'User' }
        & Pick<User, 'id' | 'username'>
      ) }
    )> }
  )> }
);

export type OrganizationPageQueryVariables = Exact<{
  name: Scalars['String'];
}>;


export type OrganizationPageQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id' | 'name'>
    & { members: Array<(
      { __typename: 'OrganizationMember' }
      & Pick<OrganizationMember, 'orgID' | 'id' | 'name' | 'username'>
    )>, subscriptions: Array<(
      { __typename: 'OrganizationSubscription' }
      & Pick<OrganizationSubscription, 'id' | 'privileges'>
    )> }
  )> }
);

export type ParameterImportanceQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  limit: Scalars['Int'];
  targetName: Scalars['String'];
  filters?: Maybe<Scalars['JSONString']>;
  parameters?: Maybe<Array<Scalars['String']>>;
  runParameters?: Maybe<Array<Scalars['String']>>;
}>;


export type ParameterImportanceQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'parameterImportance'>
  )> }
);

export type ProjectArtifactCollectionsQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
}>;


export type ProjectArtifactCollectionsQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { artifactTypes: (
      { __typename: 'ArtifactTypeConnection' }
      & { edges: Array<(
        { __typename: 'ArtifactTypeEdge' }
        & { node?: Maybe<(
          { __typename: 'ArtifactType' }
          & Pick<ArtifactType, 'id'>
          & { artifactCollections?: Maybe<(
            { __typename: 'ArtifactCollectionConnection' }
            & { edges: Array<(
              { __typename: 'ArtifactCollectionEdge' }
              & { node?: Maybe<(
                { __typename: 'ArtifactPortfolio' }
                & Pick<ArtifactPortfolio, 'id' | 'name'>
              ) | (
                { __typename: 'ArtifactSequence' }
                & Pick<ArtifactSequence, 'id' | 'name'>
              )> }
            )> }
          )> }
        )> }
      )> }
    ) }
  )> }
);

export type ProjectArtifactsQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
}>;


export type ProjectArtifactsQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { artifactTypes: (
      { __typename: 'ArtifactTypeConnection' }
      & { edges: Array<(
        { __typename: 'ArtifactTypeEdge' }
        & { node?: Maybe<(
          { __typename: 'ArtifactType' }
          & Pick<ArtifactType, 'id' | 'name'>
          & { artifactCollections?: Maybe<(
            { __typename: 'ArtifactCollectionConnection' }
            & Pick<ArtifactCollectionConnection, 'totalCount'>
            & { edges: Array<(
              { __typename: 'ArtifactCollectionEdge' }
              & { node?: Maybe<(
                { __typename: 'ArtifactPortfolio' }
                & Pick<ArtifactPortfolio, 'id' | 'name'>
              ) | (
                { __typename: 'ArtifactSequence' }
                & Pick<ArtifactSequence, 'id' | 'name'>
              )> }
            )> }
          )> }
        )> }
      )> }
    ) }
  )> }
);

export type ProjectFieldsQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  types: Array<Scalars['String']>;
  columns: Array<Scalars['String']>;
  pattern?: Maybe<Scalars['String']>;
  count: Scalars['Int'];
  cursor?: Maybe<Scalars['String']>;
}>;


export type ProjectFieldsQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { fields: (
      { __typename: 'ProjectFieldConnection' }
      & { pageInfo: (
        { __typename: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      ), edges: Array<(
        { __typename: 'ProjectFieldEdge' }
        & { node: (
          { __typename: 'ProjectField' }
          & Pick<ProjectField, 'path' | 'type'>
        ) }
      )> }
    ) }
  )> }
);

export type WeaveflowCheckFragmentFragment = (
  { __typename: 'Project' }
  & { weaveRun?: Maybe<(
    { __typename: 'Run' }
    & Pick<Run, 'id'>
  )> }
);

export type ProjectPageQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
}>;


export type ProjectPageQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'name' | 'entityName' | 'lastActive' | 'access' | 'description' | 'readOnly' | 'fromV2' | 'computeHours' | 'totalSweeps' | 'totalUsers' | 'isBenchmark' | 'views'>
    & { totalRuns: Project['runCount'] }
    & { reports?: Maybe<(
      { __typename: 'ViewConnection' }
      & Pick<ViewConnection, 'totalCount'>
    )>, user?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'name' | 'username' | 'photoUrl' | 'accountType'>
    )>, linkedBenchmark?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id' | 'entityName' | 'name' | 'gitHubSubmissionRepo' | 'views'>
    )>, entity: (
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'name' | 'defaultAccess' | 'readOnlyAdmin' | 'privateOnly' | 'isTeam'>
      & { claimingEntity?: Maybe<(
        { __typename: 'Entity' }
        & Pick<Entity, 'id' | 'name'>
      )> }
    ) }
    & WeaveflowCheckFragmentFragment
  )> }
);

export type ProjectTags2QueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  pattern?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
}>;


export type ProjectTags2Query = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { runTags?: Maybe<(
      { __typename: 'RunTagConnection' }
      & { pageInfo: (
        { __typename: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
      ), edges: Array<(
        { __typename: 'RunTagEdge' }
        & { node: (
          { __typename: 'RunTag' }
          & Pick<RunTag, 'name' | 'id'>
        ) }
      )> }
    )> }
  )> }
);

export type ProjectTagsQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
}>;


export type ProjectTagsQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { tagCounts: Array<(
      { __typename: 'RunTagCount' }
      & Pick<RunTagCount, 'name' | 'count'>
    )> }
  )> }
);

export type ReportPageQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
}>;


export type ReportPageQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'access' | 'readOnly'>
    & { entity: (
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'name' | 'defaultAccess' | 'readOnlyAdmin' | 'privateOnly' | 'isTeam'>
    ) }
  )> }
);

export type ReportTableQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  draftsOffset?: Maybe<Scalars['Int']>;
  draftsPageSize?: Maybe<Scalars['Int']>;
  publishedOffset?: Maybe<Scalars['Int']>;
  publishedPageSize?: Maybe<Scalars['Int']>;
}>;


export type ReportTableQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'access' | 'readOnly'>
    & { reportDrafts?: Maybe<(
      { __typename: 'ViewConnection' }
      & Pick<ViewConnection, 'totalCount'>
      & { pageInfo: (
        { __typename: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage'>
      ), edges: Array<(
        { __typename: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename: 'View' }
          & Pick<View, 'id'>
          & ViewFragmentMetadataFragment
        )> }
      )> }
    )>, reports?: Maybe<(
      { __typename: 'ViewConnection' }
      & Pick<ViewConnection, 'totalCount'>
      & { pageInfo: (
        { __typename: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage'>
      ), edges: Array<(
        { __typename: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename: 'View' }
          & Pick<View, 'id'>
          & ViewFragmentMetadataFragment
        )> }
      )> }
    )> }
  )> }
);

export type RunArtifactVersionsQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  runName: Scalars['String'];
  pageSize?: Maybe<Scalars['Int']>;
  includeOutput?: Maybe<Scalars['Boolean']>;
  includeInput?: Maybe<Scalars['Boolean']>;
}>;


export type RunArtifactVersionsQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { run?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id'>
      & { outputArtifacts?: Maybe<(
        { __typename: 'ArtifactConnection' }
        & Pick<ArtifactConnection, 'totalCount'>
        & { edges: Array<(
          { __typename: 'ArtifactEdge' }
          & { node?: Maybe<(
            { __typename: 'Artifact' }
            & Pick<Artifact, 'id' | 'digest' | 'commitHash' | 'versionIndex' | 'isLinkedToGlobalRegistry'>
            & { artifactType: (
              { __typename: 'ArtifactType' }
              & Pick<ArtifactType, 'id' | 'name'>
              & { project: (
                { __typename: 'Project' }
                & Pick<Project, 'id' | 'name'>
                & { entity: (
                  { __typename: 'Entity' }
                  & Pick<Entity, 'id' | 'name'>
                ) }
              ) }
            ), artifactSequence: (
              { __typename: 'ArtifactSequence' }
              & Pick<ArtifactSequence, 'id' | 'name'>
            ), aliases: Array<(
              { __typename: 'ArtifactAlias' }
              & Pick<ArtifactAlias, 'id' | 'artifactCollectionName' | 'alias'>
            )>, usedBy: (
              { __typename: 'RunConnection' }
              & Pick<RunConnection, 'totalCount'>
            ) }
          )> }
        )> }
      )>, inputArtifacts?: Maybe<(
        { __typename: 'InputArtifactConnection' }
        & Pick<InputArtifactConnection, 'totalCount'>
        & { edges: Array<(
          { __typename: 'InputArtifactEdge' }
          & Pick<InputArtifactEdge, 'usedAs'>
          & { node?: Maybe<(
            { __typename: 'Artifact' }
            & Pick<Artifact, 'id' | 'digest' | 'commitHash' | 'versionIndex'>
            & { artifactType: (
              { __typename: 'ArtifactType' }
              & Pick<ArtifactType, 'id' | 'name'>
              & { project: (
                { __typename: 'Project' }
                & Pick<Project, 'id' | 'name'>
                & { entity: (
                  { __typename: 'Entity' }
                  & Pick<Entity, 'id' | 'name'>
                ) }
              ) }
            ), artifactSequence: (
              { __typename: 'ArtifactSequence' }
              & Pick<ArtifactSequence, 'id' | 'name'>
              & { project?: Maybe<(
                { __typename: 'Project' }
                & Pick<Project, 'id' | 'name'>
                & { entity: (
                  { __typename: 'Entity' }
                  & Pick<Entity, 'id' | 'name'>
                ) }
              )> }
            ), aliases: Array<(
              { __typename: 'ArtifactAlias' }
              & Pick<ArtifactAlias, 'id' | 'artifactCollectionName' | 'alias'>
            )>, usedBy: (
              { __typename: 'RunConnection' }
              & Pick<RunConnection, 'totalCount'>
            ) }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type RunsOutputArtifactsQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  runNames: Array<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
  filenames: Array<Scalars['String']>;
}>;


export type RunsOutputArtifactsQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { runs?: Maybe<(
      { __typename: 'RunConnection' }
      & { edges: Array<(
        { __typename: 'RunEdge' }
        & { node: (
          { __typename: 'Run' }
          & Pick<Run, 'id' | 'name'>
          & { outputArtifacts?: Maybe<(
            { __typename: 'ArtifactConnection' }
            & { edges: Array<(
              { __typename: 'ArtifactEdge' }
              & { node?: Maybe<(
                { __typename: 'Artifact' }
                & Pick<Artifact, 'id'>
                & { artifactType: (
                  { __typename: 'ArtifactType' }
                  & Pick<ArtifactType, 'id' | 'name'>
                ), files: (
                  { __typename: 'FileConnection' }
                  & { edges: Array<(
                    { __typename: 'FileEdge' }
                    & { node?: Maybe<(
                      { __typename: 'File' }
                      & Pick<File, 'id' | 'name'>
                    )> }
                  )> }
                ) }
              )> }
            )> }
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type RunFilesQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  runName: Scalars['String'];
  maxNumFiles: Scalars['Int'];
}>;


export type RunFilesQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'name'>
    & { run?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id' | 'name'>
      & { files?: Maybe<(
        { __typename: 'FileConnection' }
        & { edges: Array<(
          { __typename: 'FileEdge' }
          & { node?: Maybe<(
            { __typename: 'File' }
            & Pick<File, 'id' | 'name' | 'url' | 'sizeBytes' | 'updatedAt'>
            & { artifact?: Maybe<(
              { __typename: 'Artifact' }
              & Pick<Artifact, 'id' | 'digest'>
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type RunFilesAndFoldersQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  runName: Scalars['String'];
  maxNumFiles: Scalars['Int'];
  folderPath?: Maybe<Scalars['String']>;
}>;


export type RunFilesAndFoldersQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'name'>
    & { run?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id' | 'name' | 'projectId'>
      & { files?: Maybe<(
        { __typename: 'FileConnection' }
        & { edges: Array<(
          { __typename: 'FileEdge' }
          & { node?: Maybe<(
            { __typename: 'File' }
            & Pick<File, 'id' | 'name' | 'url' | 'sizeBytes' | 'updatedAt'>
            & { artifact?: Maybe<(
              { __typename: 'Artifact' }
              & Pick<Artifact, 'id' | 'digest'>
            )> }
          )> }
        )> }
      )>, folders?: Maybe<(
        { __typename: 'Folders' }
        & { folderList: Array<Maybe<(
          { __typename: 'FolderMetadata' }
          & Pick<FolderMetadata, 'name' | 'size' | 'numberOfFiles'>
        )>> }
      )> }
    )> }
  )> }
);

export type RunHistoryQueryQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  runName: Scalars['String'];
  sampledHistorySpecs: Array<Scalars['JSONString']>;
}>;


export type RunHistoryQueryQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { run?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id' | 'sampledHistory'>
    )> }
  )> }
);

export type RunInfoAndConfigQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  runName: Scalars['String'];
}>;


export type RunInfoAndConfigQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { run?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id' | 'config'>
      & { runInfo?: Maybe<(
        { __typename: 'RunInfo' }
        & Pick<RunInfo, 'program' | 'args' | 'os' | 'python' | 'colab' | 'executable' | 'codeSaved' | 'cpuCount' | 'cpuCountLogical' | 'gpuCount' | 'gpu'>
        & { git?: Maybe<(
          { __typename: 'GitInfo' }
          & Pick<GitInfo, 'remote' | 'commit'>
        )> }
      )> }
    )> }
  )> }
);

export type RunInfoQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  runName: Scalars['String'];
}>;


export type RunInfoQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { run?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id'>
      & { runInfo?: Maybe<(
        { __typename: 'RunInfo' }
        & Pick<RunInfo, 'program' | 'args' | 'os' | 'python' | 'colab' | 'executable' | 'codeSaved' | 'cpuCount' | 'cpuCountLogical' | 'gpuCount' | 'gpu'>
        & { git?: Maybe<(
          { __typename: 'GitInfo' }
          & Pick<GitInfo, 'remote' | 'commit'>
        )> }
      )> }
    )> }
  )> }
);

export type RunKeysQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  types?: Maybe<Array<Scalars['String']>>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type RunKeysQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'name'>
    & { fields: (
      { __typename: 'ProjectFieldConnection' }
      & { edges: Array<(
        { __typename: 'ProjectFieldEdge' }
        & { node: (
          { __typename: 'ProjectField' }
          & Pick<ProjectField, 'path'>
        ) }
      )> }
    ) }
  )> }
);

export type RunSelectorWithIdQueryVariables = Exact<{
  internalId: Scalars['ID'];
}>;


export type RunSelectorWithIdQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'runCount'>
    & { fields: (
      { __typename: 'ProjectFieldConnection' }
      & { edges: Array<(
        { __typename: 'ProjectFieldEdge' }
        & { node: (
          { __typename: 'ProjectField' }
          & Pick<ProjectField, 'path'>
        ) }
      )> }
    ), entity: (
      { __typename: 'Entity' }
      & Pick<Entity, 'id'>
      & { settings: (
        { __typename: 'EntitySettings' }
        & { storageBucketInfo?: Maybe<(
          { __typename: 'StorageBucketInfo' }
          & Pick<StorageBucketInfo, 'ID'>
        )> }
      ) }
    ) }
  )> }
);

export type RunSelectorQueryVariables = Exact<{
  name: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
}>;


export type RunSelectorQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'runCount'>
    & { fields: (
      { __typename: 'ProjectFieldConnection' }
      & { edges: Array<(
        { __typename: 'ProjectFieldEdge' }
        & { node: (
          { __typename: 'ProjectField' }
          & Pick<ProjectField, 'path'>
        ) }
      )> }
    ), entity: (
      { __typename: 'Entity' }
      & Pick<Entity, 'id'>
      & { settings: (
        { __typename: 'EntitySettings' }
        & { storageBucketInfo?: Maybe<(
          { __typename: 'StorageBucketInfo' }
          & Pick<StorageBucketInfo, 'ID'>
        )> }
      ) }
    ) }
  )> }
);

export type RunFieldsFragmentFragment = (
  { __typename: 'Run' }
  & Pick<Run, 'id' | 'aggregations' | 'commit' | 'computeSeconds' | 'config' | 'createdAt' | 'defaultColorIndex' | 'displayName' | 'framework' | 'github' | 'group' | 'groupCounts' | 'heartbeatAt' | 'host' | 'jobType' | 'logLineCount' | 'name' | 'notes' | 'pendingUpdates' | 'projectId' | 'readOnly' | 'shouldStop' | 'state' | 'stopped' | 'summaryMetrics' | 'updatedAt' | 'wandbConfig'>
  & { agent?: Maybe<(
    { __typename: 'Agent' }
    & Pick<Agent, 'id' | 'name'>
  )>, benchmarkRun?: Maybe<(
    { __typename: 'BenchmarkRun' }
    & Pick<BenchmarkRun, 'id'>
  )>, runInfo?: Maybe<(
    { __typename: 'RunInfo' }
    & Pick<RunInfo, 'gpu' | 'gpuCount'>
  )>, sweep?: Maybe<(
    { __typename: 'Sweep' }
    & Pick<Sweep, 'id' | 'name' | 'displayName'>
  )>, tags: Array<(
    { __typename: 'RunTag' }
    & Pick<RunTag, 'id' | 'name' | 'colorIndex'>
  )>, user?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'username' | 'photoUrl'>
  )> }
  & ArtifactCountsFragmentFragment
);

export type RunsSelectorRunsProjectFragmentFragment = (
  { __typename: 'Project' }
  & Pick<Project, 'id'>
  & { runs?: Maybe<(
    { __typename: 'RunConnection' }
    & Pick<RunConnection, 'totalCount'>
    & { delta: Array<(
      { __typename: 'RunDiff' }
      & Pick<RunDiff, 'index' | 'op'>
      & { run?: Maybe<(
        { __typename: 'Run' }
        & RunFieldsFragmentFragment
      )> }
    )> }
  )> }
);

export type RunSelectorRunsWithIdQueryVariables = Exact<{
  aggregationKeys?: Maybe<Array<Scalars['String']>>;
  configKeys?: Maybe<Array<Scalars['String']>>;
  currentRuns: Array<Scalars['String']>;
  enableAggregations?: Maybe<Scalars['Boolean']>;
  enableArtifactCounts?: Maybe<Scalars['Boolean']>;
  enableConfig?: Maybe<Scalars['Boolean']>;
  enableSummary?: Maybe<Scalars['Boolean']>;
  enableTags?: Maybe<Scalars['Boolean']>;
  filters: Scalars['JSONString'];
  groupKeys: Array<Scalars['String']>;
  groupLevel: Scalars['Int'];
  lastUpdated: Scalars['DateTime'];
  limit: Scalars['Int'];
  order: Scalars['String'];
  summaryKeys?: Maybe<Array<Scalars['String']>>;
  wandbKeys?: Maybe<Array<Scalars['String']>>;
  internalId: Scalars['ID'];
}>;


export type RunSelectorRunsWithIdQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & RunsSelectorRunsProjectFragmentFragment
  )> }
);

export type RunSelectorRunsQueryVariables = Exact<{
  aggregationKeys?: Maybe<Array<Scalars['String']>>;
  configKeys?: Maybe<Array<Scalars['String']>>;
  currentRuns: Array<Scalars['String']>;
  enableAggregations?: Maybe<Scalars['Boolean']>;
  enableArtifactCounts?: Maybe<Scalars['Boolean']>;
  enableConfig?: Maybe<Scalars['Boolean']>;
  enableSummary?: Maybe<Scalars['Boolean']>;
  enableTags?: Maybe<Scalars['Boolean']>;
  filters: Scalars['JSONString'];
  groupKeys: Array<Scalars['String']>;
  groupLevel: Scalars['Int'];
  lastUpdated: Scalars['DateTime'];
  limit: Scalars['Int'];
  order: Scalars['String'];
  summaryKeys?: Maybe<Array<Scalars['String']>>;
  wandbKeys?: Maybe<Array<Scalars['String']>>;
  entityName: Scalars['String'];
  projectName: Scalars['String'];
}>;


export type RunSelectorRunsQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & RunsSelectorRunsProjectFragmentFragment
  )> }
);

export type RunSelectorCsvExportRunsQueryVariables = Exact<{
  aggregationKeys?: Maybe<Array<Scalars['String']>>;
  configKeys?: Maybe<Array<Scalars['String']>>;
  enableAggregations?: Maybe<Scalars['Boolean']>;
  enableArtifactCounts?: Maybe<Scalars['Boolean']>;
  enableConfig?: Maybe<Scalars['Boolean']>;
  enableSummary?: Maybe<Scalars['Boolean']>;
  enableTags?: Maybe<Scalars['Boolean']>;
  entityName: Scalars['String'];
  filters: Scalars['JSONString'];
  groupKeys: Array<Scalars['String']>;
  groupLevel: Scalars['Int'];
  limit: Scalars['Int'];
  order: Scalars['String'];
  projectName: Scalars['String'];
  summaryKeys?: Maybe<Array<Scalars['String']>>;
  wandbKeys?: Maybe<Array<Scalars['String']>>;
}>;


export type RunSelectorCsvExportRunsQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { runs?: Maybe<(
      { __typename: 'RunConnection' }
      & { edges: Array<(
        { __typename: 'RunEdge' }
        & { node: (
          { __typename: 'Run' }
          & Pick<Run, 'id' | 'aggregations' | 'commit' | 'config' | 'createdAt' | 'displayName' | 'framework' | 'github' | 'group' | 'groupCounts' | 'heartbeatAt' | 'host' | 'jobType' | 'logLineCount' | 'name' | 'notes' | 'pendingUpdates' | 'projectId' | 'readOnly' | 'shouldStop' | 'state' | 'stopped' | 'summaryMetrics' | 'tags' | 'updatedAt' | 'wandbConfig'>
          & { runInfo?: Maybe<(
            { __typename: 'RunInfo' }
            & Pick<RunInfo, 'gpu' | 'gpuCount'>
          )>, sweep?: Maybe<(
            { __typename: 'Sweep' }
            & Pick<Sweep, 'id' | 'name' | 'displayName'>
          )>, user?: Maybe<(
            { __typename: 'User' }
            & Pick<User, 'id' | 'username'>
          )> }
          & ArtifactCountsFragmentFragment
        ) }
      )> }
    )> }
  )> }
);

export type RunSelectorAutoColsRunsQueryVariables = Exact<{
  enableArtifactCounts?: Maybe<Scalars['Boolean']>;
  entityName: Scalars['String'];
  filters: Scalars['JSONString'];
  groupKeys: Array<Scalars['String']>;
  groupLevel: Scalars['Int'];
  limit: Scalars['Int'];
  order: Scalars['String'];
  projectName: Scalars['String'];
}>;


export type RunSelectorAutoColsRunsQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { runs?: Maybe<(
      { __typename: 'RunConnection' }
      & { edges: Array<(
        { __typename: 'RunEdge' }
        & { node: (
          { __typename: 'Run' }
          & Pick<Run, 'id' | 'aggregations' | 'commit' | 'config' | 'createdAt' | 'displayName' | 'framework' | 'github' | 'group' | 'groupCounts' | 'heartbeatAt' | 'host' | 'jobType' | 'logLineCount' | 'name' | 'notes' | 'pendingUpdates' | 'projectId' | 'readOnly' | 'shouldStop' | 'state' | 'stopped' | 'summaryMetrics' | 'updatedAt'>
          & { runInfo?: Maybe<(
            { __typename: 'RunInfo' }
            & Pick<RunInfo, 'gpu' | 'gpuCount'>
          )>, sweep?: Maybe<(
            { __typename: 'Sweep' }
            & Pick<Sweep, 'id' | 'name' | 'displayName'>
          )>, user?: Maybe<(
            { __typename: 'User' }
            & Pick<User, 'id' | 'username'>
          )> }
          & ArtifactCountsFragmentFragment
        ) }
      )> }
    )> }
  )> }
);

export type BulkEditorProjectTagsQueryVariables = Exact<{
  name: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  tempFilters?: Maybe<Scalars['JSONString']>;
}>;


export type BulkEditorProjectTagsQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { tags: Array<(
      { __typename: 'RunTag' }
      & Pick<RunTag, 'id' | 'name' | 'colorIndex'>
    )>, tagCounts: Array<(
      { __typename: 'RunTagCount' }
      & Pick<RunTagCount, 'name' | 'count'>
    )> }
  )> }
);

export type RunSetSummaryQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  selections: Scalars['JSONString'];
}>;


export type RunSetSummaryQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { selectedCount: Project['runCount'] }
  )> }
);

export type RunSetSummaryByIdQueryVariables = Exact<{
  internalId: Scalars['ID'];
  selections: Scalars['JSONString'];
}>;


export type RunSetSummaryByIdQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { selectedCount: Project['runCount'] }
  )> }
);

export type RunValueSuggestionsQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  keyPath: Scalars['String'];
  filters?: Maybe<Scalars['JSONString']>;
}>;


export type RunValueSuggestionsQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'name'>
    & { runKeySuggestions: Array<(
      { __typename: 'RunKeySuggestion' }
      & Pick<RunKeySuggestion, 'value' | 'displayValue' | 'count'>
    )> }
  )> }
);

export type ViewSpecQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ViewSpecQuery = (
  { __typename: 'Query' }
  & { view?: Maybe<(
    { __typename: 'View' }
    & Pick<View, 'id' | 'type' | 'spec'>
  )> }
);

export type SearchEntityProjectsQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
  entityProjectsLimit?: Maybe<Scalars['Int']>;
}>;


export type SearchEntityProjectsQuery = (
  { __typename: 'Query' }
  & { projects?: Maybe<(
    { __typename: 'ProjectConnection' }
    & { edges: Array<(
      { __typename: 'ProjectEdge' }
      & { node?: Maybe<(
        { __typename: 'Project' }
        & Pick<Project, 'id' | 'name' | 'entityName'>
        & { allViews?: Maybe<(
          { __typename: 'ViewConnection' }
          & { edges: Array<(
            { __typename: 'ViewEdge' }
            & { node?: Maybe<(
              { __typename: 'View' }
              & Pick<View, 'id' | 'displayName'>
              & { project?: Maybe<(
                { __typename: 'Project' }
                & Pick<Project, 'id' | 'name' | 'entityName'>
              )> }
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type SearchViewerQueryVariables = Exact<{ [key: string]: never; }>;


export type SearchViewerQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'admin' | 'photoUrl' | 'username'>
    & { organizations: Array<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'orgType'>
    )>, projects?: Maybe<(
      { __typename: 'ProjectConnection' }
      & { edges: Array<(
        { __typename: 'ProjectEdge' }
        & { node?: Maybe<(
          { __typename: 'Project' }
          & Pick<Project, 'id' | 'name' | 'entityName'>
        )> }
      )> }
    )>, views?: Maybe<(
      { __typename: 'ViewConnection' }
      & { edges: Array<(
        { __typename: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename: 'View' }
          & Pick<View, 'id' | 'displayName'>
          & { project?: Maybe<(
            { __typename: 'Project' }
            & Pick<Project, 'id' | 'name' | 'entityName'>
          )> }
        )> }
      )> }
    )>, teams?: Maybe<(
      { __typename: 'EntityConnection' }
      & { edges: Array<(
        { __typename: 'EntityEdge' }
        & { node?: Maybe<(
          { __typename: 'Entity' }
          & Pick<Entity, 'id' | 'name' | 'photoUrl'>
          & { projects?: Maybe<(
            { __typename: 'ProjectConnection' }
            & { edges: Array<(
              { __typename: 'ProjectEdge' }
              & { node?: Maybe<(
                { __typename: 'Project' }
                & Pick<Project, 'id' | 'name' | 'entityName'>
              )> }
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type SearchNavQueryQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  runName: Scalars['String'];
  sweepName: Scalars['String'];
}>;


export type SearchNavQueryQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'name' | 'access' | 'readOnly' | 'isBenchmark'>
    & { entity: (
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'name' | 'readOnlyAdmin' | 'defaultAccess' | 'privateOnly' | 'isTeam'>
    ), user?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id'>
    )>, run?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id' | 'displayName' | 'state'>
    )>, sweep?: Maybe<(
      { __typename: 'Sweep' }
      & Pick<Sweep, 'id' | 'name' | 'displayName' | 'config'>
    )> }
  )> }
);

export type SelectedCountQueryVariables = Exact<{
  name: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  filters?: Maybe<Scalars['JSONString']>;
  tempFilters?: Maybe<Scalars['JSONString']>;
}>;


export type SelectedCountQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { selectedCount: Project['runCount'], tempSelectedCount: Project['runCount'], tempSelectedActiveCount: Project['runActiveCount'], tempOutputArtifactsCount: Project['runOutputArtifactsCount'] }
  )> }
);

export type SelectedCountWithIdQueryVariables = Exact<{
  internalId: Scalars['ID'];
  filters?: Maybe<Scalars['JSONString']>;
  tempFilters?: Maybe<Scalars['JSONString']>;
}>;


export type SelectedCountWithIdQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { selectedCount: Project['runCount'], tempSelectedCount: Project['runCount'], tempSelectedActiveCount: Project['runActiveCount'], tempOutputArtifactsCount: Project['runOutputArtifactsCount'] }
  )> }
);

export type SingleFileQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  runName: Scalars['String'];
  fileName: Scalars['String'];
}>;


export type SingleFileQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { run?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id'>
      & { files?: Maybe<(
        { __typename: 'FileConnection' }
        & { edges: Array<(
          { __typename: 'FileEdge' }
          & { node?: Maybe<(
            { __typename: 'File' }
            & Pick<File, 'id' | 'name' | 'directUrl' | 'sizeBytes' | 'updatedAt'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type SweepPageQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  sweepName: Scalars['String'];
}>;


export type SweepPageQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'access' | 'readOnly'>
    & { sweep?: Maybe<(
      { __typename: 'Sweep' }
      & Pick<Sweep, 'id' | 'name' | 'displayName' | 'runCount' | 'runCountExpected' | 'runTime' | 'method' | 'state' | 'description' | 'config' | 'createdAt' | 'heartbeatAt' | 'updatedAt' | 'controllerRunName' | 'scheduler'>
      & { user?: Maybe<(
        { __typename: 'User' }
        & Pick<User, 'id' | 'username' | 'photoUrl'>
      )>, priorRuns: (
        { __typename: 'RunConnection' }
        & { edges: Array<(
          { __typename: 'RunEdge' }
          & { node: (
            { __typename: 'Run' }
            & Pick<Run, 'id' | 'name'>
          ) }
        )> }
      ), agents: (
        { __typename: 'AgentConnection' }
        & { edges: Array<(
          { __typename: 'AgentEdge' }
          & { node: (
            { __typename: 'Agent' }
            & Pick<Agent, 'id' | 'name' | 'state' | 'host' | 'persistent' | 'metrics' | 'createdAt' | 'heartbeatAt' | 'totalRuns'>
            & { user?: Maybe<(
              { __typename: 'User' }
              & Pick<User, 'id'>
            )> }
          ) }
        )> }
      ) }
    )>, entity: (
      { __typename: 'Entity' }
      & Pick<Entity, 'id' | 'name' | 'defaultAccess' | 'readOnlyAdmin' | 'privateOnly' | 'isTeam'>
    ) }
  )> }
);

export type SweepsQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
}>;


export type SweepsQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'readOnly'>
    & { sweeps?: Maybe<(
      { __typename: 'SweepConnection' }
      & { edges: Array<(
        { __typename: 'SweepEdge' }
        & { node: (
          { __typename: 'Sweep' }
          & Pick<Sweep, 'id' | 'name' | 'displayName' | 'state' | 'runCount' | 'runCountExpected' | 'runTime' | 'createdAt' | 'config' | 'scheduler'>
          & { user?: Maybe<(
            { __typename: 'User' }
            & Pick<User, 'id' | 'username' | 'photoUrl'>
          )> }
        ) }
      )> }
    )> }
  )> }
);

export type CreateArtifactCollectionTagAssignmentsMutationVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  artifactCollectionName: Scalars['String'];
  tags: Array<TagInput>;
}>;


export type CreateArtifactCollectionTagAssignmentsMutation = (
  { __typename: 'Mutation' }
  & { createArtifactCollectionTagAssignments?: Maybe<(
    { __typename: 'CreateTagsPayload' }
    & { tags: Array<(
      { __typename: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'tagCategoryName'>
    )> }
  )> }
);

export type DeleteArtifactCollectionTagAssignmentsMutationVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  artifactCollectionName: Scalars['String'];
  tags: Array<TagInput>;
}>;


export type DeleteArtifactCollectionTagAssignmentsMutation = (
  { __typename: 'Mutation' }
  & { deleteArtifactCollectionTagAssignments?: Maybe<(
    { __typename: 'DeleteTagAssignmentPayload' }
    & Pick<DeleteTagAssignmentPayload, 'success'>
  )> }
);

export type CreateArtifactTagAssignmentsMutationVariables = Exact<{
  artifactID: Scalars['ID'];
  tags: Array<TagInput>;
}>;


export type CreateArtifactTagAssignmentsMutation = (
  { __typename: 'Mutation' }
  & { createArtifactTagAssignments?: Maybe<(
    { __typename: 'CreateTagsPayload' }
    & { tags: Array<(
      { __typename: 'Tag' }
      & Pick<Tag, 'id' | 'name' | 'tagCategoryName'>
    )> }
  )> }
);

export type DeleteArtifactTagAssignmentsMutationVariables = Exact<{
  artifactID: Scalars['ID'];
  tags: Array<TagInput>;
}>;


export type DeleteArtifactTagAssignmentsMutation = (
  { __typename: 'Mutation' }
  & { deleteArtifactTagAssignments?: Maybe<(
    { __typename: 'DeleteTagAssignmentPayload' }
    & Pick<DeleteTagAssignmentPayload, 'success'>
  )> }
);

export type TeamMemberFieldsFragment = (
  { __typename: 'Member' }
  & Pick<Member, 'id' | 'username' | 'name' | 'photoUrl' | 'role'>
);

export type TeamMembersQueryVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type TeamMembersQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id'>
    & { members: Array<(
      { __typename: 'Member' }
      & TeamMemberFieldsFragment
    )> }
  )> }
);

export type TeamMemberQueryVariables = Exact<{
  entityName: Scalars['String'];
  memberId: Scalars['ID'];
}>;


export type TeamMemberQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id'>
    & { member?: Maybe<(
      { __typename: 'Member' }
      & TeamMemberFieldsFragment
    )> }
  )> }
);

export type CreateFilterTriggerMutationVariables = Exact<{
  name: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  triggeringEventType: EventTriggeringConditionType;
  scopeType: TriggerScopeType;
  scopeID: Scalars['ID'];
  eventFilter: Scalars['JSONString'];
  triggeredActionType: TriggeredActionType;
  triggeredActionConfig: TriggeredActionConfig;
  enabled: Scalars['Boolean'];
}>;


export type CreateFilterTriggerMutation = (
  { __typename: 'Mutation' }
  & { createFilterTrigger?: Maybe<(
    { __typename: 'CreateFilterTriggerPayload' }
    & { trigger?: Maybe<(
      { __typename: 'Trigger' }
      & Pick<Trigger, 'id'>
    )> }
  )> }
);

export type FetchProjectTriggersQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  projectOnly?: Maybe<Scalars['Boolean']>;
}>;


export type FetchProjectTriggersQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { triggers: Array<(
      { __typename: 'Trigger' }
      & Pick<Trigger, 'id' | 'name' | 'createdAt' | 'enabled' | 'description'>
      & { createdBy: (
        { __typename: 'User' }
        & Pick<User, 'id' | 'name'>
      ), scope: (
        { __typename: 'Project' }
        & Pick<Project, 'id' | 'name'>
      ) | (
        { __typename: 'ArtifactSequence' }
        & Pick<ArtifactSequence, 'id' | 'name'>
        & { project?: Maybe<(
          { __typename: 'Project' }
          & Pick<Project, 'id' | 'name'>
        )> }
      ) | (
        { __typename: 'ArtifactPortfolio' }
        & Pick<ArtifactPortfolio, 'id' | 'name'>
        & { project?: Maybe<(
          { __typename: 'Project' }
          & Pick<Project, 'id' | 'name'>
        )> }
      ), triggeringCondition: (
        { __typename: 'FilterEventTriggeringCondition' }
        & Pick<FilterEventTriggeringCondition, 'eventType' | 'filter'>
      ), triggeredAction: (
        { __typename: 'QueueJobTriggeredAction' }
        & Pick<QueueJobTriggeredAction, 'template'>
        & { queue?: Maybe<(
          { __typename: 'RunQueue' }
          & Pick<RunQueue, 'id' | 'name'>
        )> }
      ) | (
        { __typename: 'NotificationTriggeredAction' }
        & Pick<NotificationTriggeredAction, 'title' | 'message' | 'severity'>
        & { integration: (
          { __typename: 'GenericWebhookIntegration' }
          & Pick<GenericWebhookIntegration, 'id'>
        ) | (
          { __typename: 'GitHubOAuthIntegration' }
          & Pick<GitHubOAuthIntegration, 'id'>
        ) | (
          { __typename: 'SlackIntegration' }
          & Pick<SlackIntegration, 'id'>
        ) }
      ) | (
        { __typename: 'GenericWebhookTriggeredAction' }
        & Pick<GenericWebhookTriggeredAction, 'requestPayload'>
        & { integration: (
          { __typename: 'GenericWebhookIntegration' }
          & Pick<GenericWebhookIntegration, 'id' | 'name' | 'urlEndpoint' | 'accessTokenRef' | 'secretRef' | 'createdAt'>
        ) | { __typename: 'GitHubOAuthIntegration' } | { __typename: 'SlackIntegration' } }
      ) | { __typename: 'NoOpTriggeredAction' } }
    )> }
  )> }
);

export type FetchArtifactCollectionTriggersQueryVariables = Exact<{
  artifactCollectionID: Scalars['ID'];
}>;


export type FetchArtifactCollectionTriggersQuery = (
  { __typename: 'Query' }
  & { artifactCollection?: Maybe<(
    { __typename: 'ArtifactPortfolio' }
    & Pick<ArtifactPortfolio, 'id' | 'name'>
    & { triggers: (
      { __typename: 'TriggerConnection' }
      & { edges: Array<(
        { __typename: 'TriggerEdge' }
        & { node: (
          { __typename: 'Trigger' }
          & Pick<Trigger, 'id' | 'name' | 'createdAt' | 'enabled' | 'description'>
          & { createdBy: (
            { __typename: 'User' }
            & Pick<User, 'id' | 'name'>
          ), scope: (
            { __typename: 'Project' }
            & Pick<Project, 'id' | 'name'>
          ) | (
            { __typename: 'ArtifactSequence' }
            & Pick<ArtifactSequence, 'id' | 'name'>
            & { project?: Maybe<(
              { __typename: 'Project' }
              & Pick<Project, 'id' | 'name'>
            )> }
          ) | (
            { __typename: 'ArtifactPortfolio' }
            & Pick<ArtifactPortfolio, 'id' | 'name'>
            & { project?: Maybe<(
              { __typename: 'Project' }
              & Pick<Project, 'id' | 'name'>
            )> }
          ), triggeringCondition: (
            { __typename: 'FilterEventTriggeringCondition' }
            & Pick<FilterEventTriggeringCondition, 'eventType' | 'filter'>
          ), triggeredAction: (
            { __typename: 'QueueJobTriggeredAction' }
            & Pick<QueueJobTriggeredAction, 'template'>
            & { queue?: Maybe<(
              { __typename: 'RunQueue' }
              & Pick<RunQueue, 'id' | 'name'>
            )> }
          ) | (
            { __typename: 'NotificationTriggeredAction' }
            & Pick<NotificationTriggeredAction, 'title' | 'message' | 'severity'>
            & { integration: (
              { __typename: 'GenericWebhookIntegration' }
              & Pick<GenericWebhookIntegration, 'id'>
            ) | (
              { __typename: 'GitHubOAuthIntegration' }
              & Pick<GitHubOAuthIntegration, 'id'>
            ) | (
              { __typename: 'SlackIntegration' }
              & Pick<SlackIntegration, 'id'>
            ) }
          ) | (
            { __typename: 'GenericWebhookTriggeredAction' }
            & Pick<GenericWebhookTriggeredAction, 'requestPayload'>
            & { integration: (
              { __typename: 'GenericWebhookIntegration' }
              & Pick<GenericWebhookIntegration, 'id' | 'name' | 'urlEndpoint' | 'accessTokenRef' | 'secretRef' | 'createdAt'>
            ) | { __typename: 'GitHubOAuthIntegration' } | { __typename: 'SlackIntegration' } }
          ) | { __typename: 'NoOpTriggeredAction' } }
        ) }
      )> }
    ) }
  ) | (
    { __typename: 'ArtifactSequence' }
    & Pick<ArtifactSequence, 'id' | 'name'>
    & { triggers: (
      { __typename: 'TriggerConnection' }
      & { edges: Array<(
        { __typename: 'TriggerEdge' }
        & { node: (
          { __typename: 'Trigger' }
          & Pick<Trigger, 'id' | 'name' | 'createdAt' | 'enabled' | 'description'>
          & { createdBy: (
            { __typename: 'User' }
            & Pick<User, 'id' | 'name'>
          ), scope: (
            { __typename: 'Project' }
            & Pick<Project, 'id' | 'name'>
          ) | (
            { __typename: 'ArtifactSequence' }
            & Pick<ArtifactSequence, 'id' | 'name'>
            & { project?: Maybe<(
              { __typename: 'Project' }
              & Pick<Project, 'id' | 'name'>
            )> }
          ) | (
            { __typename: 'ArtifactPortfolio' }
            & Pick<ArtifactPortfolio, 'id' | 'name'>
            & { project?: Maybe<(
              { __typename: 'Project' }
              & Pick<Project, 'id' | 'name'>
            )> }
          ), triggeringCondition: (
            { __typename: 'FilterEventTriggeringCondition' }
            & Pick<FilterEventTriggeringCondition, 'eventType' | 'filter'>
          ), triggeredAction: (
            { __typename: 'QueueJobTriggeredAction' }
            & Pick<QueueJobTriggeredAction, 'template'>
            & { queue?: Maybe<(
              { __typename: 'RunQueue' }
              & Pick<RunQueue, 'id' | 'name'>
            )> }
          ) | (
            { __typename: 'NotificationTriggeredAction' }
            & Pick<NotificationTriggeredAction, 'title' | 'message' | 'severity'>
            & { integration: (
              { __typename: 'GenericWebhookIntegration' }
              & Pick<GenericWebhookIntegration, 'id'>
            ) | (
              { __typename: 'GitHubOAuthIntegration' }
              & Pick<GitHubOAuthIntegration, 'id'>
            ) | (
              { __typename: 'SlackIntegration' }
              & Pick<SlackIntegration, 'id'>
            ) }
          ) | (
            { __typename: 'GenericWebhookTriggeredAction' }
            & Pick<GenericWebhookTriggeredAction, 'requestPayload'>
            & { integration: (
              { __typename: 'GenericWebhookIntegration' }
              & Pick<GenericWebhookIntegration, 'id' | 'name' | 'urlEndpoint' | 'accessTokenRef' | 'secretRef' | 'createdAt'>
            ) | { __typename: 'GitHubOAuthIntegration' } | { __typename: 'SlackIntegration' } }
          ) | { __typename: 'NoOpTriggeredAction' } }
        ) }
      )> }
    ) }
  )> }
);

export type DeleteTriggerMutationVariables = Exact<{
  triggerID: Scalars['ID'];
}>;


export type DeleteTriggerMutation = (
  { __typename: 'Mutation' }
  & { deleteTrigger?: Maybe<(
    { __typename: 'DeleteTriggerPayload' }
    & Pick<DeleteTriggerPayload, 'success'>
  )> }
);

export type ContactUsForUserLedTrialMutationVariables = Exact<{
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type ContactUsForUserLedTrialMutation = (
  { __typename: 'Mutation' }
  & { contactUsForUserLedTrial?: Maybe<(
    { __typename: 'ContactUsForUserLedTrialPayload' }
    & Pick<ContactUsForUserLedTrialPayload, 'clientMutationId'>
  )> }
);

export type ContactUsForComputeHoursMutationVariables = Exact<{
  clientMutationId?: Maybe<Scalars['String']>;
}>;


export type ContactUsForComputeHoursMutation = (
  { __typename: 'Mutation' }
  & { contactUsForComputeHours?: Maybe<(
    { __typename: 'ContactUsForComputeHoursPayload' }
    & Pick<ContactUsForComputeHoursPayload, 'clientMutationId'>
  )> }
);

export type UserProjectsQueryVariables = Exact<{
  userName: Scalars['String'];
  includeReports: Scalars['Boolean'];
  first?: Maybe<Scalars['Int']>;
  pattern?: Maybe<Scalars['String']>;
}>;


export type UserProjectsQuery = (
  { __typename: 'Query' }
  & { user?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { teams?: Maybe<(
      { __typename: 'EntityConnection' }
      & { edges: Array<(
        { __typename: 'EntityEdge' }
        & { node?: Maybe<(
          { __typename: 'Entity' }
          & Pick<Entity, 'id' | 'name'>
          & { projects?: Maybe<(
            { __typename: 'ProjectConnection' }
            & { edges: Array<(
              { __typename: 'ProjectEdge' }
              & { node?: Maybe<(
                { __typename: 'Project' }
                & Pick<Project, 'id' | 'name' | 'entityName'>
                & { reports?: Maybe<(
                  { __typename: 'ViewConnection' }
                  & { edges: Array<(
                    { __typename: 'ViewEdge' }
                    & { node?: Maybe<(
                      { __typename: 'View' }
                      & Pick<View, 'id'>
                      & ViewFragmentMetadataFragment
                    )> }
                  )> }
                )> }
              )> }
            )> }
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type AllTeamProjectsQueryVariables = Exact<{
  entityName: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
}>;


export type AllTeamProjectsQuery = (
  { __typename: 'Query' }
  & { projects?: Maybe<(
    { __typename: 'ProjectConnection' }
    & { edges: Array<(
      { __typename: 'ProjectEdge' }
      & { node?: Maybe<(
        { __typename: 'Project' }
        & Pick<Project, 'id' | 'name' | 'entityName' | 'totalRuns' | 'lastActive' | 'computeHours'>
      )> }
    )>, pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
    ) }
  )> }
);

export type UserReportsQueryVariables = Exact<{ [key: string]: never; }>;


export type UserReportsQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'username'>
    & { views?: Maybe<(
      { __typename: 'ViewConnection' }
      & { edges: Array<(
        { __typename: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename: 'View' }
          & Pick<View, 'id' | 'createdAt' | 'updatedAt' | 'name' | 'displayName' | 'description' | 'previewUrl' | 'spec' | 'viewCount'>
          & { project?: Maybe<(
            { __typename: 'Project' }
            & Pick<Project, 'id' | 'name' | 'entityName' | 'access'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type Vega2PanelQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  fetchSingle: Scalars['Boolean'];
}>;


export type Vega2PanelQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { allViews?: Maybe<(
      { __typename: 'ViewConnection' }
      & { edges: Array<(
        { __typename: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename: 'View' }
          & Pick<View, 'id'>
          & ViewFragmentFragment
        )> }
      )> }
    )> }
  )>, view?: Maybe<(
    { __typename: 'View' }
    & Pick<View, 'id'>
    & ViewFragmentFragment
  )> }
);

export type VegaPanelQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  fetchSingle: Scalars['Boolean'];
}>;


export type VegaPanelQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { allViews?: Maybe<(
      { __typename: 'ViewConnection' }
      & { edges: Array<(
        { __typename: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename: 'View' }
          & Pick<View, 'id'>
          & ViewFragmentFragment
        )> }
      )> }
    )> }
  )>, view?: Maybe<(
    { __typename: 'View' }
    & Pick<View, 'id'>
    & ViewFragmentFragment
  )> }
);

export type ViewerApiKeyQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerApiKeyQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'apiKey'>
  )> }
);

export type ViewerApiKeysQueryVariables = Exact<{ [key: string]: never; }>;


export type ViewerApiKeysQuery = (
  { __typename: 'Query' }
  & { viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
    & { apiKeys?: Maybe<(
      { __typename: 'ApiKeyConnection' }
      & { edges: Array<(
        { __typename: 'ApiKeyEdge' }
        & { node?: Maybe<(
          { __typename: 'ApiKey' }
          & Pick<ApiKey, 'id' | 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type WandbConfigQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
  filters?: Maybe<Scalars['JSONString']>;
  limit?: Maybe<Scalars['Int']>;
  order?: Maybe<Scalars['String']>;
}>;


export type WandbConfigQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { runs?: Maybe<(
      { __typename: 'RunConnection' }
      & { edges: Array<(
        { __typename: 'RunEdge' }
        & { node: (
          { __typename: 'Run' }
          & Pick<Run, 'id' | 'wandbConfig'>
        ) }
      )> }
    )> }
  )> }
);

export type ServerPollingOkQueryVariables = Exact<{ [key: string]: never; }>;


export type ServerPollingOkQuery = (
  { __typename: 'Query' }
  & { serverInfo?: Maybe<(
    { __typename: 'ServerInfo' }
    & Pick<ServerInfo, 'pollingOK'>
  )> }
);

export type AccountPrivilegesQueryVariables = Exact<{
  organizationId: Scalars['ID'];
}>;


export type AccountPrivilegesQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { subscriptions: Array<(
      { __typename: 'OrganizationSubscription' }
      & Pick<OrganizationSubscription, 'id' | 'privileges'>
      & { plan: (
        { __typename: 'Plan' }
        & Pick<Plan, 'id' | 'planType'>
      ) }
    )> }
  )> }
);

export type TeamOrgQueryVariables = Exact<{
  entityName: Scalars['String'];
}>;


export type TeamOrgQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id' | 'name'>
    & { organization?: Maybe<(
      { __typename: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
    )> }
  )> }
);

export type OrganizationSubscriptionUpdateAdditionalInfoQueryVariables = Exact<{
  orgName: Scalars['String'];
}>;


export type OrganizationSubscriptionUpdateAdditionalInfoQuery = (
  { __typename: 'Query' }
  & { organization?: Maybe<(
    { __typename: 'Organization' }
    & Pick<Organization, 'id'>
    & { weaveEnterpriseSubscription?: Maybe<(
      { __typename: 'EnterpriseSubscription' }
      & Pick<EnterpriseSubscription, 'billingPeriodStart' | 'billingPeriodEnd'>
    )> }
  )> }
);

export type DiscussionThreadPageQueryVariables = Exact<{
  threadID: Scalars['ID'];
}>;


export type DiscussionThreadPageQuery = (
  { __typename: 'Query' }
  & { discussionThread?: Maybe<(
    { __typename: 'DiscussionThread' }
    & Pick<DiscussionThread, 'id' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'title' | 'body' | 'upvotes'>
    & { poster: (
      { __typename: 'User' }
      & Pick<User, 'id' | 'photoUrl' | 'name'>
    ), comments: (
      { __typename: 'DiscussionCommentConnection' }
      & Pick<DiscussionCommentConnection, 'totalCount'>
      & { pageInfo: (
        { __typename: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage'>
      ), edges: Array<(
        { __typename: 'DiscussionCommentEdge' }
        & { node: (
          { __typename: 'DiscussionComment' }
          & Pick<DiscussionComment, 'id' | 'createdAt' | 'updatedAt' | 'deletedAt' | 'body' | 'isParent' | 'upvotes'>
          & { poster: (
            { __typename: 'User' }
            & Pick<User, 'id' | 'photoUrl' | 'name'>
          ) }
        ) }
      )> }
    ) }
  )>, viewer?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type ReportsTableFragmentFragment = (
  { __typename: 'View' }
  & Pick<View, 'id' | 'displayName' | 'description' | 'starCount' | 'createdAt' | 'updatedAt' | 'starred' | 'showcasedAt' | 'previewUrl' | 'viewCount'>
  & { user?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'username' | 'photoUrl' | 'admin'>
  )>, project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'name' | 'entityName' | 'access'>
  )> }
);

export type RunsTableFragmentFragment = (
  { __typename: 'Run' }
  & Pick<Run, 'id' | 'name' | 'displayName' | 'state' | 'createdAt' | 'heartbeatAt' | 'updatedAt' | 'groupCounts'>
  & { user?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'username'>
  )>, project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'name' | 'entityName'>
  )> }
);

export type SubmitRunByIdQueryQueryVariables = Exact<{
  entityName: Scalars['String'];
  projectName: Scalars['String'];
  runName: Scalars['String'];
}>;


export type SubmitRunByIdQueryQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'name' | 'entityName'>
    & { run?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id' | 'name' | 'displayName' | 'summaryMetrics'>
      & { benchmarkRun?: Maybe<(
        { __typename: 'BenchmarkRun' }
        & Pick<BenchmarkRun, 'id' | 'results'>
      )> }
    )> }
  )> }
);

export type RunCsvQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName?: Maybe<Scalars['String']>;
  runName: Scalars['String'];
  fileName: Scalars['String'];
}>;


export type RunCsvQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'name'>
    & { run?: Maybe<(
      { __typename: 'Run' }
      & Pick<Run, 'id' | 'name'>
      & { benchmarkRun?: Maybe<(
        { __typename: 'BenchmarkRun' }
        & Pick<BenchmarkRun, 'id' | 'results'>
      )>, submissionFiles?: Maybe<(
        { __typename: 'FileConnection' }
        & { edges: Array<(
          { __typename: 'FileEdge' }
          & { node?: Maybe<(
            { __typename: 'File' }
            & Pick<File, 'id' | 'name' | 'md5'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type UpsertLinkedBenchmarkMutationVariables = Exact<{
  description?: Maybe<Scalars['String']>;
  entityName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  framework?: Maybe<Scalars['String']>;
  access?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['JSONString']>;
  linkedBenchmark?: Maybe<Scalars['ID']>;
}>;


export type UpsertLinkedBenchmarkMutation = (
  { __typename: 'Mutation' }
  & { upsertModel?: Maybe<(
    { __typename: 'UpsertModelPayload' }
    & Pick<UpsertModelPayload, 'inserted'>
    & { project?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id' | 'name' | 'entityName' | 'description' | 'access' | 'views' | 'isBenchmark'>
      & { linkedBenchmark?: Maybe<(
        { __typename: 'Project' }
        & Pick<Project, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type SimpleTestQueryQueryVariables = Exact<{ [key: string]: never; }>;


export type SimpleTestQueryQuery = (
  { __typename: 'Query' }
  & { serverInfo?: Maybe<(
    { __typename: 'ServerInfo' }
    & Pick<ServerInfo, 'pollingOK'>
  )> }
);

export type UpsertBenchmarkMutationVariables = Exact<{
  description?: Maybe<Scalars['String']>;
  entityName: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  access?: Maybe<Scalars['String']>;
  views?: Maybe<Scalars['JSONString']>;
}>;


export type UpsertBenchmarkMutation = (
  { __typename: 'Mutation' }
  & { upsertModel?: Maybe<(
    { __typename: 'UpsertModelPayload' }
    & Pick<UpsertModelPayload, 'inserted'>
    & { project?: Maybe<(
      { __typename: 'Project' }
      & Pick<Project, 'id' | 'name' | 'entityName' | 'description' | 'access' | 'views' | 'isBenchmark'>
    )> }
  )> }
);

export type TrendingReposQueryVariables = Exact<{
  from: Scalars['DateTime'];
}>;


export type TrendingReposQuery = (
  { __typename: 'Query' }
  & { trendingRepos?: Maybe<(
    { __typename: 'RowConnection' }
    & Pick<RowConnection, 'totalCount' | 'schema'>
    & { pageInfo: (
      { __typename: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), edges: Array<(
      { __typename: 'RowEdge' }
      & { node?: Maybe<(
        { __typename: 'RowType' }
        & Pick<RowType, 'row'>
      )> }
    )> }
  )> }
);

export type RunStateBasicFragmentFragment = (
  { __typename: 'Run' }
  & Pick<Run, 'commit' | 'computeSeconds' | 'createdAt' | 'defaultColorIndex' | 'displayName' | 'framework' | 'github' | 'group' | 'groupCounts' | 'heartbeatAt' | 'host' | 'jobType' | 'logLineCount' | 'notes' | 'pendingUpdates' | 'projectId' | 'readOnly' | 'shouldStop' | 'state' | 'stopped'>
  & { agent?: Maybe<(
    { __typename: 'Agent' }
    & Pick<Agent, 'id' | 'name'>
  )>, benchmarkRun?: Maybe<(
    { __typename: 'BenchmarkRun' }
    & Pick<BenchmarkRun, 'id'>
  )>, runInfo?: Maybe<(
    { __typename: 'RunInfo' }
    & Pick<RunInfo, 'gpu' | 'gpuCount' | 'codePath'>
  )>, sweep?: Maybe<(
    { __typename: 'Sweep' }
    & Pick<Sweep, 'id' | 'name' | 'displayName'>
  )>, user?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'username' | 'photoUrl'>
  )> }
);

export type ArtifactCountsFragmentFragment = (
  { __typename: 'Run' }
  & { inputArtifacts?: Maybe<(
    { __typename: 'InputArtifactConnection' }
    & Pick<InputArtifactConnection, 'totalCount'>
  )>, outputArtifacts?: Maybe<(
    { __typename: 'ArtifactConnection' }
    & Pick<ArtifactConnection, 'totalCount'>
  )> }
);

export type RunsStateQueryQueryVariables = Exact<{
  aggregationKeys?: Maybe<Array<Scalars['String']>>;
  configKeys?: Maybe<Array<Scalars['String']>>;
  enableAggregations?: Maybe<Scalars['Boolean']>;
  enableArtifactCounts?: Maybe<Scalars['Boolean']>;
  enableBasic?: Maybe<Scalars['Boolean']>;
  enableConfig?: Maybe<Scalars['Boolean']>;
  enableHistoryKeyInfo?: Maybe<Scalars['Boolean']>;
  enableSampledHistory?: Maybe<Scalars['Boolean']>;
  enableSummary?: Maybe<Scalars['Boolean']>;
  enableSystemMetrics?: Maybe<Scalars['Boolean']>;
  enableTags?: Maybe<Scalars['Boolean']>;
  enableWandb?: Maybe<Scalars['Boolean']>;
  entityName: Scalars['String'];
  filters: Scalars['JSONString'];
  groupKeys: Array<Scalars['String']>;
  groupLevel: Scalars['Int'];
  limit: Scalars['Int'];
  order: Scalars['String'];
  projectName: Scalars['String'];
  sampledHistorySpecs: Array<Scalars['JSONString']>;
  summaryKeys?: Maybe<Array<Scalars['String']>>;
  wandbKeys?: Maybe<Array<Scalars['String']>>;
}>;


export type RunsStateQueryQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { runs?: Maybe<(
      { __typename: 'RunConnection' }
      & Pick<RunConnection, 'totalCount'>
      & { edges: Array<(
        { __typename: 'RunEdge' }
        & { node: (
          { __typename: 'Run' }
          & Pick<Run, 'id' | 'name' | 'projectId' | 'displayName' | 'updatedAt' | 'aggregations' | 'config' | 'historyKeys' | 'sampledHistory' | 'summaryMetrics' | 'systemMetrics' | 'wandbConfig'>
          & { tags: Array<(
            { __typename: 'RunTag' }
            & Pick<RunTag, 'id' | 'name' | 'colorIndex'>
          )> }
          & ArtifactCountsFragmentFragment
          & RunStateBasicFragmentFragment
        ) }
      )> }
    )> }
  )> }
);

export type RunsStateQueryWithProjectIdQueryVariables = Exact<{
  aggregationKeys?: Maybe<Array<Scalars['String']>>;
  configKeys?: Maybe<Array<Scalars['String']>>;
  enableAggregations?: Maybe<Scalars['Boolean']>;
  enableArtifactCounts?: Maybe<Scalars['Boolean']>;
  enableBasic?: Maybe<Scalars['Boolean']>;
  enableConfig?: Maybe<Scalars['Boolean']>;
  enableHistoryKeyInfo?: Maybe<Scalars['Boolean']>;
  enableSampledHistory?: Maybe<Scalars['Boolean']>;
  enableSummary?: Maybe<Scalars['Boolean']>;
  enableSystemMetrics?: Maybe<Scalars['Boolean']>;
  enableTags?: Maybe<Scalars['Boolean']>;
  enableWandb?: Maybe<Scalars['Boolean']>;
  filters: Scalars['JSONString'];
  groupKeys: Array<Scalars['String']>;
  groupLevel: Scalars['Int'];
  limit: Scalars['Int'];
  order: Scalars['String'];
  sampledHistorySpecs: Array<Scalars['JSONString']>;
  summaryKeys?: Maybe<Array<Scalars['String']>>;
  wandbKeys?: Maybe<Array<Scalars['String']>>;
  internalId: Scalars['ID'];
}>;


export type RunsStateQueryWithProjectIdQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { runs?: Maybe<(
      { __typename: 'RunConnection' }
      & Pick<RunConnection, 'totalCount'>
      & { edges: Array<(
        { __typename: 'RunEdge' }
        & { node: (
          { __typename: 'Run' }
          & Pick<Run, 'id' | 'name' | 'projectId' | 'displayName' | 'updatedAt' | 'aggregations' | 'config' | 'historyKeys' | 'sampledHistory' | 'summaryMetrics' | 'systemMetrics' | 'wandbConfig'>
          & { tags: Array<(
            { __typename: 'RunTag' }
            & Pick<RunTag, 'id' | 'name' | 'colorIndex'>
          )> }
          & ArtifactCountsFragmentFragment
          & RunStateBasicFragmentFragment
        ) }
      )> }
    )> }
  )> }
);

export type RunsStateDeltaQueryQueryVariables = Exact<{
  aggregationKeys?: Maybe<Array<Scalars['String']>>;
  configKeys?: Maybe<Array<Scalars['String']>>;
  currentRuns: Array<Scalars['String']>;
  enableAggregations?: Maybe<Scalars['Boolean']>;
  enableArtifactCounts?: Maybe<Scalars['Boolean']>;
  enableBasic?: Maybe<Scalars['Boolean']>;
  enableConfig?: Maybe<Scalars['Boolean']>;
  enableHistoryKeyInfo?: Maybe<Scalars['Boolean']>;
  enableSampledHistory?: Maybe<Scalars['Boolean']>;
  enableSummary?: Maybe<Scalars['Boolean']>;
  enableSystemMetrics?: Maybe<Scalars['Boolean']>;
  enableTags?: Maybe<Scalars['Boolean']>;
  enableWandb?: Maybe<Scalars['Boolean']>;
  entityName: Scalars['String'];
  filters: Scalars['JSONString'];
  groupKeys: Array<Scalars['String']>;
  groupLevel: Scalars['Int'];
  lastUpdated: Scalars['DateTime'];
  limit: Scalars['Int'];
  order: Scalars['String'];
  projectName: Scalars['String'];
  sampledHistorySpecs: Array<Scalars['JSONString']>;
  summaryKeys?: Maybe<Array<Scalars['String']>>;
  wandbKeys?: Maybe<Array<Scalars['String']>>;
}>;


export type RunsStateDeltaQueryQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { runs?: Maybe<(
      { __typename: 'RunConnection' }
      & Pick<RunConnection, 'totalCount'>
      & { delta: Array<(
        { __typename: 'RunDiff' }
        & Pick<RunDiff, 'index' | 'op'>
        & { run?: Maybe<(
          { __typename: 'Run' }
          & Pick<Run, 'id' | 'name' | 'projectId' | 'displayName' | 'updatedAt' | 'aggregations' | 'config' | 'historyKeys' | 'sampledHistory' | 'summaryMetrics' | 'systemMetrics' | 'wandbConfig'>
          & { tags: Array<(
            { __typename: 'RunTag' }
            & Pick<RunTag, 'id' | 'name' | 'colorIndex'>
          )> }
          & ArtifactCountsFragmentFragment
          & RunStateBasicFragmentFragment
        )> }
      )> }
    )> }
  )> }
);

export type RunsStateDeltaQueryWithProjectIdQueryVariables = Exact<{
  aggregationKeys?: Maybe<Array<Scalars['String']>>;
  configKeys?: Maybe<Array<Scalars['String']>>;
  currentRuns: Array<Scalars['String']>;
  enableAggregations?: Maybe<Scalars['Boolean']>;
  enableArtifactCounts?: Maybe<Scalars['Boolean']>;
  enableBasic?: Maybe<Scalars['Boolean']>;
  enableConfig?: Maybe<Scalars['Boolean']>;
  enableHistoryKeyInfo?: Maybe<Scalars['Boolean']>;
  enableSampledHistory?: Maybe<Scalars['Boolean']>;
  enableSummary?: Maybe<Scalars['Boolean']>;
  enableSystemMetrics?: Maybe<Scalars['Boolean']>;
  enableTags?: Maybe<Scalars['Boolean']>;
  enableWandb?: Maybe<Scalars['Boolean']>;
  filters: Scalars['JSONString'];
  groupKeys: Array<Scalars['String']>;
  groupLevel: Scalars['Int'];
  lastUpdated: Scalars['DateTime'];
  limit: Scalars['Int'];
  order: Scalars['String'];
  sampledHistorySpecs: Array<Scalars['JSONString']>;
  summaryKeys?: Maybe<Array<Scalars['String']>>;
  wandbKeys?: Maybe<Array<Scalars['String']>>;
  internalId: Scalars['ID'];
}>;


export type RunsStateDeltaQueryWithProjectIdQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { runs?: Maybe<(
      { __typename: 'RunConnection' }
      & Pick<RunConnection, 'totalCount'>
      & { delta: Array<(
        { __typename: 'RunDiff' }
        & Pick<RunDiff, 'index' | 'op'>
        & { run?: Maybe<(
          { __typename: 'Run' }
          & Pick<Run, 'id' | 'name' | 'projectId' | 'displayName' | 'updatedAt' | 'aggregations' | 'config' | 'historyKeys' | 'sampledHistory' | 'summaryMetrics' | 'systemMetrics' | 'wandbConfig'>
          & { tags: Array<(
            { __typename: 'RunTag' }
            & Pick<RunTag, 'id' | 'name' | 'colorIndex'>
          )> }
          & ArtifactCountsFragmentFragment
          & RunStateBasicFragmentFragment
        )> }
      )> }
    )> }
  )> }
);

export type ViewFragmentMetadata2Fragment = (
  { __typename: 'View' }
  & Pick<View, 'id' | 'name' | 'displayName' | 'type' | 'description' | 'entityName' | 'previewUrl' | 'coverUrl' | 'updatedAt' | 'createdAt' | 'starCount' | 'starred' | 'parentId' | 'locked' | 'viewCount' | 'showcasedAt'>
  & { user?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'username' | 'photoUrl' | 'admin' | 'name'>
  )>, updatedBy?: Maybe<(
    { __typename: 'User' }
    & Pick<User, 'id' | 'name' | 'username'>
  )>, project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'name' | 'entityName' | 'readOnly'>
  )>, alertSubscription?: Maybe<(
    { __typename: 'EmailSubscription' }
    & Pick<EmailSubscription, 'id'>
  ) | (
    { __typename: 'SlackChannelSubscription' }
    & Pick<SlackChannelSubscription, 'id'>
  )>, accessTokens: Array<(
    { __typename: 'AccessToken' }
    & Pick<AccessToken, 'id' | 'token' | 'type' | 'emails' | 'createdAt' | 'lastAccessedAt' | 'revokedAt'>
    & { view: (
      { __typename: 'View' }
      & Pick<View, 'id'>
    ), createdBy: (
      { __typename: 'User' }
      & Pick<User, 'id' | 'username' | 'email' | 'name'>
    ), projects: Array<(
      { __typename: 'Project' }
      & Pick<Project, 'id' | 'name' | 'entityName'>
    )> }
  )> }
);

export type Views2MetadataQueryVariables = Exact<{
  entityName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  viewType?: Maybe<Scalars['String']>;
  userName?: Maybe<Scalars['String']>;
  viewName?: Maybe<Scalars['String']>;
}>;


export type Views2MetadataQuery = (
  { __typename: 'Query' }
  & { project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id'>
    & { allViews?: Maybe<(
      { __typename: 'ViewConnection' }
      & { edges: Array<(
        { __typename: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename: 'View' }
          & Pick<View, 'id'>
          & ViewFragmentMetadata2Fragment
        )> }
      )> }
    )> }
  )> }
);

export type Views2ViewQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Views2ViewQuery = (
  { __typename: 'Query' }
  & { view?: Maybe<(
    { __typename: 'View' }
    & Pick<View, 'id' | 'spec'>
    & ViewFragmentMetadata2Fragment
  )> }
);

export type Views2RawViewQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type Views2RawViewQuery = (
  { __typename: 'Query' }
  & { view?: Maybe<(
    { __typename: 'View' }
    & Pick<View, 'id' | 'specObject'>
    & ViewFragmentMetadata2Fragment
  )> }
);

export type ViewUpdatedAtQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ViewUpdatedAtQuery = (
  { __typename: 'Query' }
  & { view?: Maybe<(
    { __typename: 'View' }
    & Pick<View, 'id' | 'updatedAt'>
  )> }
);

export type NamedWorkspaceUpdateViewFieldsQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type NamedWorkspaceUpdateViewFieldsQuery = (
  { __typename: 'Query' }
  & { view?: Maybe<(
    { __typename: 'View' }
    & Pick<View, 'id' | 'createdAt' | 'updatedAt'>
    & { user?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'name' | 'username'>
    )>, updatedBy?: Maybe<(
      { __typename: 'User' }
      & Pick<User, 'id' | 'name' | 'username'>
    )> }
  )> }
);

export type UpsertView2MutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  entityName?: Maybe<Scalars['String']>;
  projectName?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  spec?: Maybe<Scalars['String']>;
  specIsGzipped?: Maybe<Scalars['Boolean']>;
  parentId?: Maybe<Scalars['ID']>;
  locked?: Maybe<Scalars['Boolean']>;
  previewUrl?: Maybe<Scalars['String']>;
  coverUrl?: Maybe<Scalars['String']>;
  showcasedAt?: Maybe<Scalars['DateTime']>;
  createdUsing?: Maybe<ViewSource>;
}>;


export type UpsertView2Mutation = (
  { __typename: 'Mutation' }
  & { upsertView?: Maybe<(
    { __typename: 'UpsertViewPayload' }
    & Pick<UpsertViewPayload, 'inserted'>
    & { view?: Maybe<(
      { __typename: 'View' }
      & Pick<View, 'id'>
      & ViewFragmentMetadata2Fragment
    )> }
  )> }
);

export type DeleteView2MutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  deleteDrafts?: Maybe<Scalars['Boolean']>;
}>;


export type DeleteView2Mutation = (
  { __typename: 'Mutation' }
  & { deleteView?: Maybe<(
    { __typename: 'DeleteViewPayload' }
    & Pick<DeleteViewPayload, 'success' | 'pendingDrafts'>
  )> }
);

export type DeleteViewsMutationVariables = Exact<{
  ids: Array<Scalars['ID']>;
  deleteDrafts?: Maybe<Scalars['Boolean']>;
}>;


export type DeleteViewsMutation = (
  { __typename: 'Mutation' }
  & { deleteViews?: Maybe<(
    { __typename: 'DeleteViewsPayload' }
    & Pick<DeleteViewsPayload, 'success' | 'pendingDrafts'>
  )> }
);

export type StarViewMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type StarViewMutation = (
  { __typename: 'Mutation' }
  & { starView?: Maybe<(
    { __typename: 'StarViewPayload' }
    & { view?: Maybe<(
      { __typename: 'View' }
      & Pick<View, 'id' | 'starCount' | 'starred'>
    )> }
  )> }
);

export type UnstarViewMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UnstarViewMutation = (
  { __typename: 'Mutation' }
  & { unstarView?: Maybe<(
    { __typename: 'UnstarViewPayload' }
    & { view?: Maybe<(
      { __typename: 'View' }
      & Pick<View, 'id' | 'starCount' | 'starred'>
    )> }
  )> }
);

export type StargazersQueryQueryVariables = Exact<{
  viewID: Scalars['ID'];
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
}>;


export type StargazersQueryQuery = (
  { __typename: 'Query' }
  & { view?: Maybe<(
    { __typename: 'View' }
    & Pick<View, 'id'>
    & { stargazers: (
      { __typename: 'StargazerConnection' }
      & { pageInfo: (
        { __typename: 'PageInfo' }
        & Pick<PageInfo, 'hasNextPage' | 'endCursor'>
      ), edges: Array<(
        { __typename: 'StargazerEdge' }
        & { node?: Maybe<(
          { __typename: 'User' }
          & Pick<User, 'id' | 'name' | 'username' | 'userInfo' | 'photoUrl'>
        )> }
      )> }
    ) }
  )> }
);

export type PublicViewsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  cursor?: Maybe<Scalars['String']>;
}>;


export type PublicViewsQuery = (
  { __typename: 'Query' }
  & { publicViews?: Maybe<(
    { __typename: 'ViewConnection' }
    & { edges: Array<(
      { __typename: 'ViewEdge' }
      & { node?: Maybe<(
        { __typename: 'View' }
        & Pick<View, 'id' | 'name' | 'description'>
        & { user?: Maybe<(
          { __typename: 'User' }
          & Pick<User, 'id' | 'username' | 'name'>
        )> }
      )> }
    )> }
  )> }
);

export type ViewDraftsQueryVariables = Exact<{
  viewID: Scalars['ID'];
}>;


export type ViewDraftsQuery = (
  { __typename: 'Query' }
  & { view?: Maybe<(
    { __typename: 'View' }
    & Pick<View, 'id'>
    & { children?: Maybe<(
      { __typename: 'ViewConnection' }
      & { edges: Array<(
        { __typename: 'ViewEdge' }
        & { node?: Maybe<(
          { __typename: 'View' }
          & Pick<View, 'id' | 'displayName' | 'entityName'>
          & { user?: Maybe<(
            { __typename: 'User' }
            & Pick<User, 'id'>
          )>, project?: Maybe<(
            { __typename: 'Project' }
            & Pick<Project, 'id' | 'name' | 'entityName'>
          )> }
        )> }
      )> }
    )> }
  )> }
);

export type ViewPermissionsQueryVariables = Exact<{
  projectName: Scalars['String'];
  entityName: Scalars['String'];
}>;


export type ViewPermissionsQuery = (
  { __typename: 'Query' }
  & { entity?: Maybe<(
    { __typename: 'Entity' }
    & Pick<Entity, 'id' | 'isTeam'>
    & { settings: (
      { __typename: 'EntitySettings' }
      & Pick<EntitySettings, 'disableMagicLinkSharing' | 'hidden'>
    ) }
  )>, project?: Maybe<(
    { __typename: 'Project' }
    & Pick<Project, 'id' | 'access'>
  )> }
);

export type ViewViewMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ViewViewMutation = (
  { __typename: 'Mutation' }
  & { viewView?: Maybe<(
    { __typename: 'ViewViewPayload' }
    & Pick<ViewViewPayload, 'success'>
  )> }
);

export const GraphDataFragmentFragmentDoc = gql`
    fragment GraphDataFragment on ArtifactLineageDag {
  artifacts {
    id
    artifactNodeID
    entityName
    projectName
    artifactTypeName
    artifactSequenceName
    artifactCommitHash
    versionIndex
  }
  runs {
    runNodeID
    entityName
    projectName
    runName
    displayName
    jobType
  }
  clusters {
    id
    nodeType
    runNodes {
      runNodeID
      entityName
      projectName
      runName
      displayName
      jobType
    }
    artifactNodes {
      id
      artifactNodeID
      entityName
      projectName
      artifactTypeName
      artifactSequenceName
      artifactCommitHash
      versionIndex
    }
    clusterEdges {
      edgeID
      artifactNodeID
      runNodeID
      clusterNodeID
      direction
    }
  }
  edges {
    edgeID
    artifactNodeID
    runNodeID
    clusterNodeID
    direction
  }
  hitLimit
}
    `;
export const ProjectListFragmentDoc = gql`
    fragment ProjectList on Project {
  name
  access
  entityName
  totalRuns: runCount
  createdAt
  lastActive
}
    `;
export const RunStateBasicFragmentFragmentDoc = gql`
    fragment RunStateBasicFragment on Run {
  agent {
    id
    name
  }
  benchmarkRun {
    id
  }
  commit
  computeSeconds
  createdAt
  defaultColorIndex
  displayName
  framework
  github
  group
  groupCounts
  heartbeatAt
  host
  jobType
  logLineCount
  notes
  pendingUpdates
  projectId
  readOnly
  runInfo {
    gpu
    gpuCount
    codePath
  }
  shouldStop
  state
  stopped
  sweep {
    id
    name
    displayName
  }
  user {
    id
    username
    photoUrl
  }
}
    `;
export const DeltaQueryFragmentFragmentDoc = gql`
    fragment DeltaQueryFragment on Project {
  entityName
  name
  runs(
    first: $limit
    order: $order
    filters: $filters
    groupKeys: $groupKeys
    groupLevel: $groupLevel
  ) {
    deltas(currentRuns: $currentRuns, lastUpdated: $lastUpdated) {
      order
      delta {
        op
        run {
          ...RunStateBasicFragment @include(if: $enableBasic)
          bucketedHistory(specs: $bucketedHistorySpecs, packVersion: 1)
          config(keys: $configKeys) @include(if: $enableConfig)
          defaultColorIndex
          displayName
          id
          name
          projectId
          summaryMetrics(keys: $summaryKeys) @include(if: $enableSummary)
          updatedAt
        }
      }
    }
  }
}
    ${RunStateBasicFragmentFragmentDoc}`;
export const RegistryPageCollectionFragmentFragmentDoc = gql`
    fragment RegistryPageCollectionFragment on ArtifactCollection {
  __typename
  ... on ArtifactPortfolio {
    id
    name
    description
    createdAt
    updatedAt
    defaultArtifactType {
      id
      name
    }
    project {
      id
      name
      entityName
    }
    artifactMemberships {
      edges {
        node {
          id
          versionIndex
          createdAt
          aliases {
            id
            alias
          }
          artifact {
            id
            tags {
              id
              name
              tagCategoryName
              attributes
            }
          }
        }
      }
    }
    tags {
      edges {
        node {
          id
          name
          tagCategoryName
          attributes
        }
      }
    }
  }
  __typename
  ... on ArtifactSequence {
    id
    name
    description
    createdAt
    updatedAt
    defaultArtifactType {
      id
      name
    }
    project {
      id
      name
      entityName
    }
    artifactMemberships {
      edges {
        node {
          id
          versionIndex
          createdAt
          aliases {
            id
            alias
          }
          artifact {
            id
            tags {
              id
              name
              tagCategoryName
              attributes
            }
          }
        }
      }
    }
    tags {
      edges {
        node {
          id
          name
          tagCategoryName
          attributes
        }
      }
    }
  }
}
    `;
export const OrganizationMemberFragmentFragmentDoc = gql`
    fragment OrganizationMemberFragment on OrganizationMember {
  orgID
  user {
    id
    accountType
    admin
  }
  admin
  username
  name
  id
  email
  photoUrl
  role
}
    `;
export const ProjectFragmentFragmentDoc = gql`
    fragment ProjectFragment on Project {
  name
  user {
    id
    name
    photoUrl
  }
  entityName
  access
  createdAt
  lastActive
  description
  totalArtifactCollections(collectionTypes: [PORTFOLIO])
  artifactTypes(includeAll: true) {
    edges {
      node {
        id
        name
      }
    }
  }
  allowAllArtifactTypesInRegistry
}
    `;
export const ImportPanelMenuEntityFragmentFragmentDoc = gql`
    fragment ImportPanelMenuEntityFragment on Entity {
  id
  name
  projects(order: "-created_at", first: 10000, pattern: $projectSearchQuery) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;
export const ImportPanelProjectViewsFragmentFragmentDoc = gql`
    fragment ImportPanelProjectViewsFragment on View {
  id
  name
  user {
    id
    username
  }
}
    `;
export const BenchmarkRunsFragmentFragmentDoc = gql`
    fragment benchmarkRunsFragment on BenchmarkRunConnection {
  edges {
    node {
      id
      gitHubSubmissionPR
      createdAt
      details
      results
      state
      user {
        id
        username
        photoUrl
        admin
      }
      originalRun {
        id
        name
        displayName
        project {
          id
          name
          entityName
        }
      }
      originalProject {
        id
        name
        entityName
      }
      run {
        github
        commit
        id
        name
        displayName
        notes
        user {
          id
          username
        }
        diffPatchFile: files(first: 1, names: ["diff.patch"]) {
          edges {
            node {
              id
              name
              md5
              sizeBytes
            }
          }
        }
        summaryMetrics
      }
      benchmark {
        id
        name
        entityName
      }
    }
  }
}
    `;
export const CustomChartFragmentFragmentDoc = gql`
    fragment CustomChartFragment on CustomChart {
  id
  name
  displayName
  entity {
    id
    name
    readOnly
  }
  user {
    id
    username
  }
  spec
  access
}
    `;
export const ArtifactNodeFragmentDoc = gql`
    fragment ArtifactNode on ArtifactSequenceStorageNode {
  id
  name
  size
  artifactType {
    id
    name
  }
  artifactSequenceID
}
    `;
export const RunNodeFragmentDoc = gql`
    fragment RunNode on RunStorageNode {
  id
  name
  size
  runID
}
    `;
export const OrganizationSubscriptionBasicInfoFragmentDoc = gql`
    fragment OrganizationSubscriptionBasicInfo on Organization {
  name
  orgType
  memberCount
  subscriptions {
    id
    subscriptionType
    plan {
      id
      name
      planType
    }
  }
}
    `;
export const AccountProjectFragmentFragmentDoc = gql`
    fragment AccountProjectFragment on Project {
  id
  name
  entityName
  access
  totalRuns
  createdAt
  lastActive
  description
  user {
    id
    username
  }
}
    `;
export const ProjectMemberFieldsFragmentDoc = gql`
    fragment ProjectMemberFields on ProjectMember {
  id
  name
  username
  email
  photoUrl
  accountType
  projectRoleIsInherited
  role {
    ID
    name
    inheritedFrom {
      permissions {
        name
      }
    }
    permissions {
      name
    }
  }
}
    `;
export const RunQueueItemFragmentFragmentDoc = gql`
    fragment RunQueueItemFragment on RunQueueItem {
  id
  createdAt
  updatedAt
  runSpec
  state
  associatedRunId
  launchAgentId
  priority
  templateVariableValues
}
    `;
export const BasicRunFragmentFragmentDoc = gql`
    fragment BasicRunFragment on Run {
  id
  readOnly
  framework
  displayName
  notes
  github
  group
  jobType
  createdAt
  heartbeatAt
  commit
  host
  state
  shouldStop
  groupCounts
  stopped
  defaultColorIndex
  sweep {
    id
    name
    displayName
  }
  agent {
    id
    name
  }
  user {
    id
    username
    photoUrl
  }
  tags: tagColors {
    id
    name
    colorIndex
  }
  benchmarkRun {
    id
  }
  runInfo {
    gpuCount
    gpu
  }
}
    `;
export const DetailedRunFragmentFragmentDoc = gql`
    fragment DetailedRunFragment on Run {
  history
  events
  fileCount
  logLines(after: $logCursor, last: 1000) {
    edges {
      node {
        id
        line
        level
      }
    }
  }
}
    `;
export const RunFilesFragmentFragmentDoc = gql`
    fragment RunFilesFragment on Run {
  files(after: $fileCursor, first: $fileLimit, names: $fileNames) {
    edges {
      node {
        id
        name
        directUrl
        url(upload: $upload)
        sizeBytes
        updatedAt
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
}
    `;
export const SelectRunFragmentFragmentDoc = gql`
    fragment SelectRunFragment on Run {
  config
  summaryMetrics(packVersion: 1)
  systemMetrics
}
    `;
export const HistoryRunFragmentFragmentDoc = gql`
    fragment HistoryRunFragment on Run {
  history(samples: $historySamples)
}
    `;
export const SampledHistoryRunFragmentFragmentDoc = gql`
    fragment SampledHistoryRunFragment on Run {
  sampledHistory(specs: $sampledHistorySpecs, packVersion: 1)
}
    `;
export const UserRoleFragmentFragmentDoc = gql`
    fragment UserRoleFragment on User {
  accountType
  admin
  deletedAt
  email
  username
}
    `;
export const UserFragmentFragmentDoc = gql`
    fragment UserFragment on User {
  name
  email
  username
  deletedAt
  photoUrl
}
    `;
export const ViewFragmentFragmentDoc = gql`
    fragment ViewFragment on View {
  id
  type
  name
  displayName
  description
  user {
    id
    username
    photoUrl
    admin
  }
  project {
    id
    name
    entityName
  }
  spec
  updatedAt
}
    `;
export const ViewFragmentMetadataFragmentDoc = gql`
    fragment ViewFragmentMetadata on View {
  id
  type
  name
  displayName
  description
  updatedBy {
    id
    username
    name
  }
  user {
    id
    username
    photoUrl
    admin
  }
  project {
    id
    name
    entityName
  }
  updatedAt
  createdAt
  starred
  starCount
  parentId
  locked
  viewCount
  alertSubscription {
    id
  }
}
    `;
export const DefaultResourceConfigFragmentFragmentDoc = gql`
    fragment DefaultResourceConfigFragment on DefaultResourceConfig {
  id
  createdAt
  updatedAt
  scope {
    __typename
    Type
    ID
  }
  resource
  config
  templateVariables {
    name
    description
    schema
  }
}
    `;
export const WeaveflowCheckFragmentFragmentDoc = gql`
    fragment WeaveflowCheckFragment on Project {
  weaveRun: run(name: "__weave_trace_server__") {
    id
  }
}
    `;
export const ArtifactCountsFragmentFragmentDoc = gql`
    fragment ArtifactCountsFragment on Run {
  inputArtifacts {
    totalCount
  }
  outputArtifacts {
    totalCount
  }
}
    `;
export const RunFieldsFragmentFragmentDoc = gql`
    fragment RunFieldsFragment on Run {
  id
  agent {
    id
    name
  }
  aggregations(keys: $aggregationKeys) @include(if: $enableAggregations)
  benchmarkRun {
    id
  }
  commit
  computeSeconds
  config(keys: $configKeys) @include(if: $enableConfig)
  createdAt
  defaultColorIndex
  displayName
  framework
  github
  group
  groupCounts
  heartbeatAt
  host
  jobType
  logLineCount
  name
  notes
  pendingUpdates
  projectId
  readOnly
  runInfo {
    gpu
    gpuCount
  }
  shouldStop
  state
  stopped
  summaryMetrics(keys: $summaryKeys) @include(if: $enableSummary)
  sweep {
    id
    name
    displayName
  }
  tags: tagColors @include(if: $enableTags) {
    id
    name
    colorIndex
  }
  updatedAt
  user {
    id
    username
    photoUrl
  }
  wandbConfig(keys: $wandbKeys)
  ...ArtifactCountsFragment @include(if: $enableArtifactCounts)
}
    ${ArtifactCountsFragmentFragmentDoc}`;
export const RunsSelectorRunsProjectFragmentFragmentDoc = gql`
    fragment RunsSelectorRunsProjectFragment on Project {
  id
  runs(
    first: $limit
    order: $order
    filters: $filters
    groupKeys: $groupKeys
    groupLevel: $groupLevel
  ) {
    totalCount
    delta(currentRuns: $currentRuns, lastUpdated: $lastUpdated) {
      index
      op
      run {
        ...RunFieldsFragment
      }
    }
  }
}
    ${RunFieldsFragmentFragmentDoc}`;
export const TeamMemberFieldsFragmentDoc = gql`
    fragment TeamMemberFields on Member {
  id
  username
  name
  photoUrl
  role
}
    `;
export const ReportsTableFragmentFragmentDoc = gql`
    fragment ReportsTableFragment on View {
  id
  displayName
  description
  user {
    id
    username
    photoUrl
    admin
  }
  project {
    id
    name
    entityName
    access
  }
  starCount
  createdAt
  updatedAt
  starred
  showcasedAt
  previewUrl
  viewCount
}
    `;
export const RunsTableFragmentFragmentDoc = gql`
    fragment RunsTableFragment on Run {
  id
  name
  displayName
  state
  createdAt
  heartbeatAt
  updatedAt
  user {
    id
    username
  }
  project {
    id
    name
    entityName
  }
  groupCounts
}
    `;
export const ViewFragmentMetadata2FragmentDoc = gql`
    fragment ViewFragmentMetadata2 on View {
  id
  name
  displayName
  type
  description
  user {
    id
    username
    photoUrl
    admin
    name
  }
  updatedBy {
    id
    name
    username
  }
  entityName
  project {
    id
    name
    entityName
    readOnly
  }
  previewUrl
  coverUrl
  updatedAt
  createdAt
  starCount
  starred
  parentId
  locked
  viewCount
  showcasedAt
  alertSubscription {
    id
  }
  accessTokens {
    id
    token
    view {
      id
    }
    type
    emails
    createdBy {
      id
      username
      email
      name
    }
    createdAt
    lastAccessedAt
    revokedAt
    projects {
      id
      name
      entityName
    }
  }
}
    `;
export const ArtifactDagDocument = gql`
    query ArtifactDAG($artifactVersionID: ID!, $limit: Int!, $filterGeneratedArtifacts: Boolean!, $clusterSize: Int, $directLineage: Boolean, $traversalDirection: TraversalDirection, $baseGraphNodeIDs: [String!]) {
  artifact(id: $artifactVersionID) {
    id
    artifactLineageDag(
      limit: $limit
      filterGeneratedArtifacts: $filterGeneratedArtifacts
      clusterSize: $clusterSize
      directLineage: $directLineage
      traversalDirection: $traversalDirection
      baseGraphNodeIDs: $baseGraphNodeIDs
    ) {
      ...GraphDataFragment
    }
  }
}
    ${GraphDataFragmentFragmentDoc}`;
export type ArtifactDagComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactDagQuery, ArtifactDagQueryVariables>, 'query'> & ({ variables: ArtifactDagQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactDagComponent = (props: ArtifactDagComponentProps) => (
      <ApolloReactComponents.Query<ArtifactDagQuery, ArtifactDagQueryVariables> query={ArtifactDagDocument} {...props} />
    );
    
export type ArtifactDagProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactDagQuery, ArtifactDagQueryVariables>
    } & TChildProps;
export function withArtifactDag<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactDagQuery,
  ArtifactDagQueryVariables,
  ArtifactDagProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactDagQuery, ArtifactDagQueryVariables, ArtifactDagProps<TChildProps, TDataName>>(ArtifactDagDocument, {
      alias: 'artifactDag',
      ...operationOptions
    });
};

/**
 * __useArtifactDagQuery__
 *
 * To run a query within a React component, call `useArtifactDagQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactDagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactDagQuery({
 *   variables: {
 *      artifactVersionID: // value for 'artifactVersionID'
 *      limit: // value for 'limit'
 *      filterGeneratedArtifacts: // value for 'filterGeneratedArtifacts'
 *      clusterSize: // value for 'clusterSize'
 *      directLineage: // value for 'directLineage'
 *      traversalDirection: // value for 'traversalDirection'
 *      baseGraphNodeIDs: // value for 'baseGraphNodeIDs'
 *   },
 * });
 */
export function useArtifactDagQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactDagQuery, ArtifactDagQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactDagQuery, ArtifactDagQueryVariables>(ArtifactDagDocument, baseOptions);
      }
export function useArtifactDagLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactDagQuery, ArtifactDagQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactDagQuery, ArtifactDagQueryVariables>(ArtifactDagDocument, baseOptions);
        }
export type ArtifactDagQueryHookResult = ReturnType<typeof useArtifactDagQuery>;
export type ArtifactDagLazyQueryHookResult = ReturnType<typeof useArtifactDagLazyQuery>;
export type ArtifactDagQueryResult = ApolloReactCommon.QueryResult<ArtifactDagQuery, ArtifactDagQueryVariables>;
export const ArtifactOverviewDocument = gql`
    query ArtifactOverview($artifactID: ID!) {
  artifact(id: $artifactID) {
    id
    artifactSequence {
      id
      name
      project {
        id
        name
        entityName
      }
    }
    digest
    description
    aliases {
      id
      alias
    }
    size
    fileCount
    createdAt
    createdBy {
      __typename
      ... on Run {
        id
        runName: name
        displayName
        runProjectQuery: project {
          id
          runProject: name
          runEntity: entityName
        }
      }
      ... on User {
        id
        username
      }
    }
    artifactCollections {
      edges {
        node {
          __typename
          id
          ... on ArtifactPortfolio {
            linkPortfolioName: name
            linkProjectQuery: project {
              id
              linkProjectName: name
              linkEntityName: entityName
            }
            artifactMemberships {
              edges {
                node {
                  id
                  versionIndex
                }
              }
            }
          }
        }
      }
    }
    usedCount
    ttlDurationSeconds
  }
}
    `;
export type ArtifactOverviewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactOverviewQuery, ArtifactOverviewQueryVariables>, 'query'> & ({ variables: ArtifactOverviewQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactOverviewComponent = (props: ArtifactOverviewComponentProps) => (
      <ApolloReactComponents.Query<ArtifactOverviewQuery, ArtifactOverviewQueryVariables> query={ArtifactOverviewDocument} {...props} />
    );
    
export type ArtifactOverviewProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactOverviewQuery, ArtifactOverviewQueryVariables>
    } & TChildProps;
export function withArtifactOverview<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactOverviewQuery,
  ArtifactOverviewQueryVariables,
  ArtifactOverviewProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactOverviewQuery, ArtifactOverviewQueryVariables, ArtifactOverviewProps<TChildProps, TDataName>>(ArtifactOverviewDocument, {
      alias: 'artifactOverview',
      ...operationOptions
    });
};

/**
 * __useArtifactOverviewQuery__
 *
 * To run a query within a React component, call `useArtifactOverviewQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactOverviewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactOverviewQuery({
 *   variables: {
 *      artifactID: // value for 'artifactID'
 *   },
 * });
 */
export function useArtifactOverviewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactOverviewQuery, ArtifactOverviewQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactOverviewQuery, ArtifactOverviewQueryVariables>(ArtifactOverviewDocument, baseOptions);
      }
export function useArtifactOverviewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactOverviewQuery, ArtifactOverviewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactOverviewQuery, ArtifactOverviewQueryVariables>(ArtifactOverviewDocument, baseOptions);
        }
export type ArtifactOverviewQueryHookResult = ReturnType<typeof useArtifactOverviewQuery>;
export type ArtifactOverviewLazyQueryHookResult = ReturnType<typeof useArtifactOverviewLazyQuery>;
export type ArtifactOverviewQueryResult = ApolloReactCommon.QueryResult<ArtifactOverviewQuery, ArtifactOverviewQueryVariables>;
export const RunArtifactDagDocument = gql`
    query RunArtifactDAG($projectName: String!, $entityName: String!, $runName: String!, $limit: Int!, $filterGeneratedArtifacts: Boolean!, $clusterSize: Int, $directLineage: Boolean, $traversalDirection: TraversalDirection, $baseGraphNodeIDs: [String!]) {
  project(name: $projectName, entityName: $entityName) {
    id
    run(name: $runName) {
      id
      artifactLineageDag(
        limit: $limit
        filterGeneratedArtifacts: $filterGeneratedArtifacts
        clusterSize: $clusterSize
        directLineage: $directLineage
        traversalDirection: $traversalDirection
        baseGraphNodeIDs: $baseGraphNodeIDs
      ) {
        ...GraphDataFragment
      }
    }
  }
}
    ${GraphDataFragmentFragmentDoc}`;
export type RunArtifactDagComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunArtifactDagQuery, RunArtifactDagQueryVariables>, 'query'> & ({ variables: RunArtifactDagQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunArtifactDagComponent = (props: RunArtifactDagComponentProps) => (
      <ApolloReactComponents.Query<RunArtifactDagQuery, RunArtifactDagQueryVariables> query={RunArtifactDagDocument} {...props} />
    );
    
export type RunArtifactDagProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunArtifactDagQuery, RunArtifactDagQueryVariables>
    } & TChildProps;
export function withRunArtifactDag<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunArtifactDagQuery,
  RunArtifactDagQueryVariables,
  RunArtifactDagProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunArtifactDagQuery, RunArtifactDagQueryVariables, RunArtifactDagProps<TChildProps, TDataName>>(RunArtifactDagDocument, {
      alias: 'runArtifactDag',
      ...operationOptions
    });
};

/**
 * __useRunArtifactDagQuery__
 *
 * To run a query within a React component, call `useRunArtifactDagQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunArtifactDagQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunArtifactDagQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runName: // value for 'runName'
 *      limit: // value for 'limit'
 *      filterGeneratedArtifacts: // value for 'filterGeneratedArtifacts'
 *      clusterSize: // value for 'clusterSize'
 *      directLineage: // value for 'directLineage'
 *      traversalDirection: // value for 'traversalDirection'
 *      baseGraphNodeIDs: // value for 'baseGraphNodeIDs'
 *   },
 * });
 */
export function useRunArtifactDagQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunArtifactDagQuery, RunArtifactDagQueryVariables>) {
        return ApolloReactHooks.useQuery<RunArtifactDagQuery, RunArtifactDagQueryVariables>(RunArtifactDagDocument, baseOptions);
      }
export function useRunArtifactDagLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunArtifactDagQuery, RunArtifactDagQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunArtifactDagQuery, RunArtifactDagQueryVariables>(RunArtifactDagDocument, baseOptions);
        }
export type RunArtifactDagQueryHookResult = ReturnType<typeof useRunArtifactDagQuery>;
export type RunArtifactDagLazyQueryHookResult = ReturnType<typeof useRunArtifactDagLazyQuery>;
export type RunArtifactDagQueryResult = ApolloReactCommon.QueryResult<RunArtifactDagQuery, RunArtifactDagQueryVariables>;
export const RunOverviewLineageDocument = gql`
    query RunOverviewLineage($projectName: String!, $entityName: String!, $runName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    run(name: $runName) {
      id
      project {
        id
        name
      }
      createdAt
      group
      state
      notes
      config
      tags
      host
      computeSeconds
      user {
        id
        username
      }
      runInfo {
        os
        python
      }
    }
  }
}
    `;
export type RunOverviewLineageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunOverviewLineageQuery, RunOverviewLineageQueryVariables>, 'query'> & ({ variables: RunOverviewLineageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunOverviewLineageComponent = (props: RunOverviewLineageComponentProps) => (
      <ApolloReactComponents.Query<RunOverviewLineageQuery, RunOverviewLineageQueryVariables> query={RunOverviewLineageDocument} {...props} />
    );
    
export type RunOverviewLineageProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunOverviewLineageQuery, RunOverviewLineageQueryVariables>
    } & TChildProps;
export function withRunOverviewLineage<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunOverviewLineageQuery,
  RunOverviewLineageQueryVariables,
  RunOverviewLineageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunOverviewLineageQuery, RunOverviewLineageQueryVariables, RunOverviewLineageProps<TChildProps, TDataName>>(RunOverviewLineageDocument, {
      alias: 'runOverviewLineage',
      ...operationOptions
    });
};

/**
 * __useRunOverviewLineageQuery__
 *
 * To run a query within a React component, call `useRunOverviewLineageQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunOverviewLineageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunOverviewLineageQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runName: // value for 'runName'
 *   },
 * });
 */
export function useRunOverviewLineageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunOverviewLineageQuery, RunOverviewLineageQueryVariables>) {
        return ApolloReactHooks.useQuery<RunOverviewLineageQuery, RunOverviewLineageQueryVariables>(RunOverviewLineageDocument, baseOptions);
      }
export function useRunOverviewLineageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunOverviewLineageQuery, RunOverviewLineageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunOverviewLineageQuery, RunOverviewLineageQueryVariables>(RunOverviewLineageDocument, baseOptions);
        }
export type RunOverviewLineageQueryHookResult = ReturnType<typeof useRunOverviewLineageQuery>;
export type RunOverviewLineageLazyQueryHookResult = ReturnType<typeof useRunOverviewLineageLazyQuery>;
export type RunOverviewLineageQueryResult = ApolloReactCommon.QueryResult<RunOverviewLineageQuery, RunOverviewLineageQueryVariables>;
export const ArtifactCollectionWithRegistryVersionDocument = gql`
    query ArtifactCollectionWithRegistryVersion($projectName: String!, $entityName: String!, $artifactTypeName: String!, $artifactCollectionName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    artifactType(name: $artifactTypeName) {
      id
      artifactCollection(name: $artifactCollectionName) {
        id
        name
        hasArtifactVersionLinkedToGlobalRegistry
      }
    }
  }
}
    `;
export type ArtifactCollectionWithRegistryVersionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactCollectionWithRegistryVersionQuery, ArtifactCollectionWithRegistryVersionQueryVariables>, 'query'> & ({ variables: ArtifactCollectionWithRegistryVersionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactCollectionWithRegistryVersionComponent = (props: ArtifactCollectionWithRegistryVersionComponentProps) => (
      <ApolloReactComponents.Query<ArtifactCollectionWithRegistryVersionQuery, ArtifactCollectionWithRegistryVersionQueryVariables> query={ArtifactCollectionWithRegistryVersionDocument} {...props} />
    );
    
export type ArtifactCollectionWithRegistryVersionProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactCollectionWithRegistryVersionQuery, ArtifactCollectionWithRegistryVersionQueryVariables>
    } & TChildProps;
export function withArtifactCollectionWithRegistryVersion<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactCollectionWithRegistryVersionQuery,
  ArtifactCollectionWithRegistryVersionQueryVariables,
  ArtifactCollectionWithRegistryVersionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactCollectionWithRegistryVersionQuery, ArtifactCollectionWithRegistryVersionQueryVariables, ArtifactCollectionWithRegistryVersionProps<TChildProps, TDataName>>(ArtifactCollectionWithRegistryVersionDocument, {
      alias: 'artifactCollectionWithRegistryVersion',
      ...operationOptions
    });
};

/**
 * __useArtifactCollectionWithRegistryVersionQuery__
 *
 * To run a query within a React component, call `useArtifactCollectionWithRegistryVersionQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactCollectionWithRegistryVersionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactCollectionWithRegistryVersionQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      artifactTypeName: // value for 'artifactTypeName'
 *      artifactCollectionName: // value for 'artifactCollectionName'
 *   },
 * });
 */
export function useArtifactCollectionWithRegistryVersionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactCollectionWithRegistryVersionQuery, ArtifactCollectionWithRegistryVersionQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactCollectionWithRegistryVersionQuery, ArtifactCollectionWithRegistryVersionQueryVariables>(ArtifactCollectionWithRegistryVersionDocument, baseOptions);
      }
export function useArtifactCollectionWithRegistryVersionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactCollectionWithRegistryVersionQuery, ArtifactCollectionWithRegistryVersionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactCollectionWithRegistryVersionQuery, ArtifactCollectionWithRegistryVersionQueryVariables>(ArtifactCollectionWithRegistryVersionDocument, baseOptions);
        }
export type ArtifactCollectionWithRegistryVersionQueryHookResult = ReturnType<typeof useArtifactCollectionWithRegistryVersionQuery>;
export type ArtifactCollectionWithRegistryVersionLazyQueryHookResult = ReturnType<typeof useArtifactCollectionWithRegistryVersionLazyQuery>;
export type ArtifactCollectionWithRegistryVersionQueryResult = ApolloReactCommon.QueryResult<ArtifactCollectionWithRegistryVersionQuery, ArtifactCollectionWithRegistryVersionQueryVariables>;
export const ArtifactProjectMetadataSearchDocument = gql`
    query ArtifactProjectMetadataSearch($projectName: String!, $entityName: String!, $filters: JSONString!) {
  project(name: $projectName, entityName: $entityName) {
    id
    artifacts(filters: $filters) {
      id
      digest
      aliases {
        id
        alias
      }
      versionIndex
      artifactSequence {
        id
        name
      }
      artifactType {
        id
        name
      }
      createdAt
    }
  }
}
    `;
export type ArtifactProjectMetadataSearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactProjectMetadataSearchQuery, ArtifactProjectMetadataSearchQueryVariables>, 'query'> & ({ variables: ArtifactProjectMetadataSearchQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactProjectMetadataSearchComponent = (props: ArtifactProjectMetadataSearchComponentProps) => (
      <ApolloReactComponents.Query<ArtifactProjectMetadataSearchQuery, ArtifactProjectMetadataSearchQueryVariables> query={ArtifactProjectMetadataSearchDocument} {...props} />
    );
    
export type ArtifactProjectMetadataSearchProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactProjectMetadataSearchQuery, ArtifactProjectMetadataSearchQueryVariables>
    } & TChildProps;
export function withArtifactProjectMetadataSearch<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactProjectMetadataSearchQuery,
  ArtifactProjectMetadataSearchQueryVariables,
  ArtifactProjectMetadataSearchProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactProjectMetadataSearchQuery, ArtifactProjectMetadataSearchQueryVariables, ArtifactProjectMetadataSearchProps<TChildProps, TDataName>>(ArtifactProjectMetadataSearchDocument, {
      alias: 'artifactProjectMetadataSearch',
      ...operationOptions
    });
};

/**
 * __useArtifactProjectMetadataSearchQuery__
 *
 * To run a query within a React component, call `useArtifactProjectMetadataSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactProjectMetadataSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactProjectMetadataSearchQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useArtifactProjectMetadataSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactProjectMetadataSearchQuery, ArtifactProjectMetadataSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactProjectMetadataSearchQuery, ArtifactProjectMetadataSearchQueryVariables>(ArtifactProjectMetadataSearchDocument, baseOptions);
      }
export function useArtifactProjectMetadataSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactProjectMetadataSearchQuery, ArtifactProjectMetadataSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactProjectMetadataSearchQuery, ArtifactProjectMetadataSearchQueryVariables>(ArtifactProjectMetadataSearchDocument, baseOptions);
        }
export type ArtifactProjectMetadataSearchQueryHookResult = ReturnType<typeof useArtifactProjectMetadataSearchQuery>;
export type ArtifactProjectMetadataSearchLazyQueryHookResult = ReturnType<typeof useArtifactProjectMetadataSearchLazyQuery>;
export type ArtifactProjectMetadataSearchQueryResult = ApolloReactCommon.QueryResult<ArtifactProjectMetadataSearchQuery, ArtifactProjectMetadataSearchQueryVariables>;
export const EotfOrganizationSubscriptionsBasicInfoDocument = gql`
    query EOTFOrganizationSubscriptionsBasicInfo($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    name
    subscriptions {
      id
      expiresAt
      plan {
        id
        name
        planType
      }
    }
  }
}
    `;
export type EotfOrganizationSubscriptionsBasicInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EotfOrganizationSubscriptionsBasicInfoQuery, EotfOrganizationSubscriptionsBasicInfoQueryVariables>, 'query'> & ({ variables: EotfOrganizationSubscriptionsBasicInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EotfOrganizationSubscriptionsBasicInfoComponent = (props: EotfOrganizationSubscriptionsBasicInfoComponentProps) => (
      <ApolloReactComponents.Query<EotfOrganizationSubscriptionsBasicInfoQuery, EotfOrganizationSubscriptionsBasicInfoQueryVariables> query={EotfOrganizationSubscriptionsBasicInfoDocument} {...props} />
    );
    
export type EotfOrganizationSubscriptionsBasicInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EotfOrganizationSubscriptionsBasicInfoQuery, EotfOrganizationSubscriptionsBasicInfoQueryVariables>
    } & TChildProps;
export function withEotfOrganizationSubscriptionsBasicInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EotfOrganizationSubscriptionsBasicInfoQuery,
  EotfOrganizationSubscriptionsBasicInfoQueryVariables,
  EotfOrganizationSubscriptionsBasicInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EotfOrganizationSubscriptionsBasicInfoQuery, EotfOrganizationSubscriptionsBasicInfoQueryVariables, EotfOrganizationSubscriptionsBasicInfoProps<TChildProps, TDataName>>(EotfOrganizationSubscriptionsBasicInfoDocument, {
      alias: 'eotfOrganizationSubscriptionsBasicInfo',
      ...operationOptions
    });
};

/**
 * __useEotfOrganizationSubscriptionsBasicInfoQuery__
 *
 * To run a query within a React component, call `useEotfOrganizationSubscriptionsBasicInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useEotfOrganizationSubscriptionsBasicInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEotfOrganizationSubscriptionsBasicInfoQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useEotfOrganizationSubscriptionsBasicInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EotfOrganizationSubscriptionsBasicInfoQuery, EotfOrganizationSubscriptionsBasicInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<EotfOrganizationSubscriptionsBasicInfoQuery, EotfOrganizationSubscriptionsBasicInfoQueryVariables>(EotfOrganizationSubscriptionsBasicInfoDocument, baseOptions);
      }
export function useEotfOrganizationSubscriptionsBasicInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EotfOrganizationSubscriptionsBasicInfoQuery, EotfOrganizationSubscriptionsBasicInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EotfOrganizationSubscriptionsBasicInfoQuery, EotfOrganizationSubscriptionsBasicInfoQueryVariables>(EotfOrganizationSubscriptionsBasicInfoDocument, baseOptions);
        }
export type EotfOrganizationSubscriptionsBasicInfoQueryHookResult = ReturnType<typeof useEotfOrganizationSubscriptionsBasicInfoQuery>;
export type EotfOrganizationSubscriptionsBasicInfoLazyQueryHookResult = ReturnType<typeof useEotfOrganizationSubscriptionsBasicInfoLazyQuery>;
export type EotfOrganizationSubscriptionsBasicInfoQueryResult = ApolloReactCommon.QueryResult<EotfOrganizationSubscriptionsBasicInfoQuery, EotfOrganizationSubscriptionsBasicInfoQueryVariables>;
export const ProjectFromNameDocument = gql`
    query ProjectFromName($projectName: String!, $entityName: String) {
  project(name: $projectName, entityName: $entityName) {
    id
    name
    entityName
  }
}
    `;
export type ProjectFromNameComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectFromNameQuery, ProjectFromNameQueryVariables>, 'query'> & ({ variables: ProjectFromNameQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectFromNameComponent = (props: ProjectFromNameComponentProps) => (
      <ApolloReactComponents.Query<ProjectFromNameQuery, ProjectFromNameQueryVariables> query={ProjectFromNameDocument} {...props} />
    );
    
export type ProjectFromNameProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProjectFromNameQuery, ProjectFromNameQueryVariables>
    } & TChildProps;
export function withProjectFromName<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProjectFromNameQuery,
  ProjectFromNameQueryVariables,
  ProjectFromNameProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProjectFromNameQuery, ProjectFromNameQueryVariables, ProjectFromNameProps<TChildProps, TDataName>>(ProjectFromNameDocument, {
      alias: 'projectFromName',
      ...operationOptions
    });
};

/**
 * __useProjectFromNameQuery__
 *
 * To run a query within a React component, call `useProjectFromNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectFromNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectFromNameQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useProjectFromNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectFromNameQuery, ProjectFromNameQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectFromNameQuery, ProjectFromNameQueryVariables>(ProjectFromNameDocument, baseOptions);
      }
export function useProjectFromNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectFromNameQuery, ProjectFromNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectFromNameQuery, ProjectFromNameQueryVariables>(ProjectFromNameDocument, baseOptions);
        }
export type ProjectFromNameQueryHookResult = ReturnType<typeof useProjectFromNameQuery>;
export type ProjectFromNameLazyQueryHookResult = ReturnType<typeof useProjectFromNameLazyQuery>;
export type ProjectFromNameQueryResult = ApolloReactCommon.QueryResult<ProjectFromNameQuery, ProjectFromNameQueryVariables>;
export const EntityArtifactTypeDocument = gql`
    query EntityArtifactType($entityName: String!, $artifactType: String!) {
  entity(name: $entityName) {
    id
    projects {
      edges {
        node {
          id
          name
          artifactType(name: $artifactType) {
            id
            name
          }
        }
      }
    }
  }
}
    `;
export type EntityArtifactTypeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityArtifactTypeQuery, EntityArtifactTypeQueryVariables>, 'query'> & ({ variables: EntityArtifactTypeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntityArtifactTypeComponent = (props: EntityArtifactTypeComponentProps) => (
      <ApolloReactComponents.Query<EntityArtifactTypeQuery, EntityArtifactTypeQueryVariables> query={EntityArtifactTypeDocument} {...props} />
    );
    
export type EntityArtifactTypeProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityArtifactTypeQuery, EntityArtifactTypeQueryVariables>
    } & TChildProps;
export function withEntityArtifactType<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityArtifactTypeQuery,
  EntityArtifactTypeQueryVariables,
  EntityArtifactTypeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityArtifactTypeQuery, EntityArtifactTypeQueryVariables, EntityArtifactTypeProps<TChildProps, TDataName>>(EntityArtifactTypeDocument, {
      alias: 'entityArtifactType',
      ...operationOptions
    });
};

/**
 * __useEntityArtifactTypeQuery__
 *
 * To run a query within a React component, call `useEntityArtifactTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityArtifactTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityArtifactTypeQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      artifactType: // value for 'artifactType'
 *   },
 * });
 */
export function useEntityArtifactTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityArtifactTypeQuery, EntityArtifactTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityArtifactTypeQuery, EntityArtifactTypeQueryVariables>(EntityArtifactTypeDocument, baseOptions);
      }
export function useEntityArtifactTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityArtifactTypeQuery, EntityArtifactTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityArtifactTypeQuery, EntityArtifactTypeQueryVariables>(EntityArtifactTypeDocument, baseOptions);
        }
export type EntityArtifactTypeQueryHookResult = ReturnType<typeof useEntityArtifactTypeQuery>;
export type EntityArtifactTypeLazyQueryHookResult = ReturnType<typeof useEntityArtifactTypeLazyQuery>;
export type EntityArtifactTypeQueryResult = ApolloReactCommon.QueryResult<EntityArtifactTypeQuery, EntityArtifactTypeQueryVariables>;
export const FetchProjectJobsForTriggersPartialDocument = gql`
    query FetchProjectJobsForTriggersPartial($entityName: String!, $projectName: String!, $limit: Int!, $after: String) {
  project(entityName: $entityName, name: $projectName) {
    id
    name
    artifactType(name: "job") {
      id
      artifactCollections(first: $limit, after: $after) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            name
            createdAt
            project {
              id
              name
              access
              entity {
                id
                name
              }
            }
            artifacts {
              edges {
                node {
                  id
                  createdAt
                  description
                  labels
                  versionIndex
                  aliases {
                    id
                    alias
                  }
                  createdBy {
                    __typename
                    ... on User {
                      id
                      name
                      username
                      photoUrl
                    }
                    ... on Run {
                      id
                      name
                      config
                    }
                  }
                  usedBy(order: "-createdAt") {
                    totalCount
                    edges {
                      node {
                        id
                        name
                        displayName
                        state
                        createdAt
                        user {
                          id
                          name
                          username
                          photoUrl
                        }
                        config
                        defaultColorIndex
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type FetchProjectJobsForTriggersPartialComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FetchProjectJobsForTriggersPartialQuery, FetchProjectJobsForTriggersPartialQueryVariables>, 'query'> & ({ variables: FetchProjectJobsForTriggersPartialQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FetchProjectJobsForTriggersPartialComponent = (props: FetchProjectJobsForTriggersPartialComponentProps) => (
      <ApolloReactComponents.Query<FetchProjectJobsForTriggersPartialQuery, FetchProjectJobsForTriggersPartialQueryVariables> query={FetchProjectJobsForTriggersPartialDocument} {...props} />
    );
    
export type FetchProjectJobsForTriggersPartialProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FetchProjectJobsForTriggersPartialQuery, FetchProjectJobsForTriggersPartialQueryVariables>
    } & TChildProps;
export function withFetchProjectJobsForTriggersPartial<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FetchProjectJobsForTriggersPartialQuery,
  FetchProjectJobsForTriggersPartialQueryVariables,
  FetchProjectJobsForTriggersPartialProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FetchProjectJobsForTriggersPartialQuery, FetchProjectJobsForTriggersPartialQueryVariables, FetchProjectJobsForTriggersPartialProps<TChildProps, TDataName>>(FetchProjectJobsForTriggersPartialDocument, {
      alias: 'fetchProjectJobsForTriggersPartial',
      ...operationOptions
    });
};

/**
 * __useFetchProjectJobsForTriggersPartialQuery__
 *
 * To run a query within a React component, call `useFetchProjectJobsForTriggersPartialQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProjectJobsForTriggersPartialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProjectJobsForTriggersPartialQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useFetchProjectJobsForTriggersPartialQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchProjectJobsForTriggersPartialQuery, FetchProjectJobsForTriggersPartialQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchProjectJobsForTriggersPartialQuery, FetchProjectJobsForTriggersPartialQueryVariables>(FetchProjectJobsForTriggersPartialDocument, baseOptions);
      }
export function useFetchProjectJobsForTriggersPartialLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchProjectJobsForTriggersPartialQuery, FetchProjectJobsForTriggersPartialQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchProjectJobsForTriggersPartialQuery, FetchProjectJobsForTriggersPartialQueryVariables>(FetchProjectJobsForTriggersPartialDocument, baseOptions);
        }
export type FetchProjectJobsForTriggersPartialQueryHookResult = ReturnType<typeof useFetchProjectJobsForTriggersPartialQuery>;
export type FetchProjectJobsForTriggersPartialLazyQueryHookResult = ReturnType<typeof useFetchProjectJobsForTriggersPartialLazyQuery>;
export type FetchProjectJobsForTriggersPartialQueryResult = ApolloReactCommon.QueryResult<FetchProjectJobsForTriggersPartialQuery, FetchProjectJobsForTriggersPartialQueryVariables>;
export const OrganizationMembersTableDocument = gql`
    query OrganizationMembersTable($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    name
    billingUser {
      id
      username
    }
    members {
      id
      name
      username
      email
      photoUrl
      teams {
        edges {
          node {
            id
            name
          }
        }
      }
      role
      modelsSeat
    }
    seatAvailability {
      viewOnlySeats
      seats
    }
    teams {
      id
      name
    }
    subscriptions {
      id
      availableSeatsToPurchase
      plan {
        id
        planType
        unitPrice
      }
      privileges
    }
    pendingInvites {
      id
      email
      createdAt
      toUser {
        id
        username
      }
      entity {
        id
        name
      }
      role
    }
  }
}
    `;
export type OrganizationMembersTableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationMembersTableQuery, OrganizationMembersTableQueryVariables>, 'query'> & ({ variables: OrganizationMembersTableQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationMembersTableComponent = (props: OrganizationMembersTableComponentProps) => (
      <ApolloReactComponents.Query<OrganizationMembersTableQuery, OrganizationMembersTableQueryVariables> query={OrganizationMembersTableDocument} {...props} />
    );
    
export type OrganizationMembersTableProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationMembersTableQuery, OrganizationMembersTableQueryVariables>
    } & TChildProps;
export function withOrganizationMembersTable<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationMembersTableQuery,
  OrganizationMembersTableQueryVariables,
  OrganizationMembersTableProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationMembersTableQuery, OrganizationMembersTableQueryVariables, OrganizationMembersTableProps<TChildProps, TDataName>>(OrganizationMembersTableDocument, {
      alias: 'organizationMembersTable',
      ...operationOptions
    });
};

/**
 * __useOrganizationMembersTableQuery__
 *
 * To run a query within a React component, call `useOrganizationMembersTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationMembersTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationMembersTableQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationMembersTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationMembersTableQuery, OrganizationMembersTableQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationMembersTableQuery, OrganizationMembersTableQueryVariables>(OrganizationMembersTableDocument, baseOptions);
      }
export function useOrganizationMembersTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationMembersTableQuery, OrganizationMembersTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationMembersTableQuery, OrganizationMembersTableQueryVariables>(OrganizationMembersTableDocument, baseOptions);
        }
export type OrganizationMembersTableQueryHookResult = ReturnType<typeof useOrganizationMembersTableQuery>;
export type OrganizationMembersTableLazyQueryHookResult = ReturnType<typeof useOrganizationMembersTableLazyQuery>;
export type OrganizationMembersTableQueryResult = ApolloReactCommon.QueryResult<OrganizationMembersTableQuery, OrganizationMembersTableQueryVariables>;
export const FetchEntityModelRegistryArtifactCollectionsPartialDocument = gql`
    query FetchEntityModelRegistryArtifactCollectionsPartial($entityName: String!, $limit: Int!, $after: String) {
  entity(name: $entityName) {
    id
    name
    artifactCollections(first: $limit, after: $after, collectionTypes: PORTFOLIO) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...RegistryPageCollectionFragment
        }
      }
    }
  }
}
    ${RegistryPageCollectionFragmentFragmentDoc}`;
export type FetchEntityModelRegistryArtifactCollectionsPartialComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FetchEntityModelRegistryArtifactCollectionsPartialQuery, FetchEntityModelRegistryArtifactCollectionsPartialQueryVariables>, 'query'> & ({ variables: FetchEntityModelRegistryArtifactCollectionsPartialQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FetchEntityModelRegistryArtifactCollectionsPartialComponent = (props: FetchEntityModelRegistryArtifactCollectionsPartialComponentProps) => (
      <ApolloReactComponents.Query<FetchEntityModelRegistryArtifactCollectionsPartialQuery, FetchEntityModelRegistryArtifactCollectionsPartialQueryVariables> query={FetchEntityModelRegistryArtifactCollectionsPartialDocument} {...props} />
    );
    
export type FetchEntityModelRegistryArtifactCollectionsPartialProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FetchEntityModelRegistryArtifactCollectionsPartialQuery, FetchEntityModelRegistryArtifactCollectionsPartialQueryVariables>
    } & TChildProps;
export function withFetchEntityModelRegistryArtifactCollectionsPartial<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FetchEntityModelRegistryArtifactCollectionsPartialQuery,
  FetchEntityModelRegistryArtifactCollectionsPartialQueryVariables,
  FetchEntityModelRegistryArtifactCollectionsPartialProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FetchEntityModelRegistryArtifactCollectionsPartialQuery, FetchEntityModelRegistryArtifactCollectionsPartialQueryVariables, FetchEntityModelRegistryArtifactCollectionsPartialProps<TChildProps, TDataName>>(FetchEntityModelRegistryArtifactCollectionsPartialDocument, {
      alias: 'fetchEntityModelRegistryArtifactCollectionsPartial',
      ...operationOptions
    });
};

/**
 * __useFetchEntityModelRegistryArtifactCollectionsPartialQuery__
 *
 * To run a query within a React component, call `useFetchEntityModelRegistryArtifactCollectionsPartialQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchEntityModelRegistryArtifactCollectionsPartialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchEntityModelRegistryArtifactCollectionsPartialQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useFetchEntityModelRegistryArtifactCollectionsPartialQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchEntityModelRegistryArtifactCollectionsPartialQuery, FetchEntityModelRegistryArtifactCollectionsPartialQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchEntityModelRegistryArtifactCollectionsPartialQuery, FetchEntityModelRegistryArtifactCollectionsPartialQueryVariables>(FetchEntityModelRegistryArtifactCollectionsPartialDocument, baseOptions);
      }
export function useFetchEntityModelRegistryArtifactCollectionsPartialLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchEntityModelRegistryArtifactCollectionsPartialQuery, FetchEntityModelRegistryArtifactCollectionsPartialQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchEntityModelRegistryArtifactCollectionsPartialQuery, FetchEntityModelRegistryArtifactCollectionsPartialQueryVariables>(FetchEntityModelRegistryArtifactCollectionsPartialDocument, baseOptions);
        }
export type FetchEntityModelRegistryArtifactCollectionsPartialQueryHookResult = ReturnType<typeof useFetchEntityModelRegistryArtifactCollectionsPartialQuery>;
export type FetchEntityModelRegistryArtifactCollectionsPartialLazyQueryHookResult = ReturnType<typeof useFetchEntityModelRegistryArtifactCollectionsPartialLazyQuery>;
export type FetchEntityModelRegistryArtifactCollectionsPartialQueryResult = ApolloReactCommon.QueryResult<FetchEntityModelRegistryArtifactCollectionsPartialQuery, FetchEntityModelRegistryArtifactCollectionsPartialQueryVariables>;
export const ProjectsByTeamDocument = gql`
    query ProjectsByTeam($entityName: String!, $pattern: String, $order: String, $first: Int = 500, $before: String, $after: String) {
  projects(
    first: $first
    entityName: $entityName
    order: $order
    before: $before
    after: $after
    pattern: $pattern
  ) {
    edges {
      node {
        id
        ...ProjectList
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    ${ProjectListFragmentDoc}`;
export type ProjectsByTeamComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectsByTeamQuery, ProjectsByTeamQueryVariables>, 'query'> & ({ variables: ProjectsByTeamQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectsByTeamComponent = (props: ProjectsByTeamComponentProps) => (
      <ApolloReactComponents.Query<ProjectsByTeamQuery, ProjectsByTeamQueryVariables> query={ProjectsByTeamDocument} {...props} />
    );
    
export type ProjectsByTeamProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProjectsByTeamQuery, ProjectsByTeamQueryVariables>
    } & TChildProps;
export function withProjectsByTeam<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProjectsByTeamQuery,
  ProjectsByTeamQueryVariables,
  ProjectsByTeamProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProjectsByTeamQuery, ProjectsByTeamQueryVariables, ProjectsByTeamProps<TChildProps, TDataName>>(ProjectsByTeamDocument, {
      alias: 'projectsByTeam',
      ...operationOptions
    });
};

/**
 * __useProjectsByTeamQuery__
 *
 * To run a query within a React component, call `useProjectsByTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsByTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsByTeamQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      pattern: // value for 'pattern'
 *      order: // value for 'order'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useProjectsByTeamQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectsByTeamQuery, ProjectsByTeamQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectsByTeamQuery, ProjectsByTeamQueryVariables>(ProjectsByTeamDocument, baseOptions);
      }
export function useProjectsByTeamLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectsByTeamQuery, ProjectsByTeamQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectsByTeamQuery, ProjectsByTeamQueryVariables>(ProjectsByTeamDocument, baseOptions);
        }
export type ProjectsByTeamQueryHookResult = ReturnType<typeof useProjectsByTeamQuery>;
export type ProjectsByTeamLazyQueryHookResult = ReturnType<typeof useProjectsByTeamLazyQuery>;
export type ProjectsByTeamQueryResult = ApolloReactCommon.QueryResult<ProjectsByTeamQuery, ProjectsByTeamQueryVariables>;
export const ProjectsByAccountDocument = gql`
    query ProjectsByAccount($orgID: ID!, $userID: ID!, $order: String, $first: Int = 500, $before: String, $after: String, $pattern: String) {
  organization(id: $orgID) {
    id
    projects(
      first: $first
      userID: $userID
      order: $order
      before: $before
      after: $after
      pattern: $pattern
    ) {
      edges {
        node {
          id
          ...ProjectList
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
}
    ${ProjectListFragmentDoc}`;
export type ProjectsByAccountComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectsByAccountQuery, ProjectsByAccountQueryVariables>, 'query'> & ({ variables: ProjectsByAccountQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectsByAccountComponent = (props: ProjectsByAccountComponentProps) => (
      <ApolloReactComponents.Query<ProjectsByAccountQuery, ProjectsByAccountQueryVariables> query={ProjectsByAccountDocument} {...props} />
    );
    
export type ProjectsByAccountProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProjectsByAccountQuery, ProjectsByAccountQueryVariables>
    } & TChildProps;
export function withProjectsByAccount<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProjectsByAccountQuery,
  ProjectsByAccountQueryVariables,
  ProjectsByAccountProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProjectsByAccountQuery, ProjectsByAccountQueryVariables, ProjectsByAccountProps<TChildProps, TDataName>>(ProjectsByAccountDocument, {
      alias: 'projectsByAccount',
      ...operationOptions
    });
};

/**
 * __useProjectsByAccountQuery__
 *
 * To run a query within a React component, call `useProjectsByAccountQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsByAccountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsByAccountQuery({
 *   variables: {
 *      orgID: // value for 'orgID'
 *      userID: // value for 'userID'
 *      order: // value for 'order'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      pattern: // value for 'pattern'
 *   },
 * });
 */
export function useProjectsByAccountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectsByAccountQuery, ProjectsByAccountQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectsByAccountQuery, ProjectsByAccountQueryVariables>(ProjectsByAccountDocument, baseOptions);
      }
export function useProjectsByAccountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectsByAccountQuery, ProjectsByAccountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectsByAccountQuery, ProjectsByAccountQueryVariables>(ProjectsByAccountDocument, baseOptions);
        }
export type ProjectsByAccountQueryHookResult = ReturnType<typeof useProjectsByAccountQuery>;
export type ProjectsByAccountLazyQueryHookResult = ReturnType<typeof useProjectsByAccountLazyQuery>;
export type ProjectsByAccountQueryResult = ApolloReactCommon.QueryResult<ProjectsByAccountQuery, ProjectsByAccountQueryVariables>;
export const ProjectsByUserDocument = gql`
    query ProjectsByUser($userName: String!, $pattern: String, $order: String, $first: Int = 500, $before: String, $after: String) {
  user(userName: $userName) {
    id
    username
    projects(
      first: $first
      order: $order
      before: $before
      after: $after
      pattern: $pattern
    ) {
      edges {
        node {
          id
          ...ProjectList
        }
      }
      pageInfo {
        startCursor
        endCursor
        hasNextPage
        hasPreviousPage
      }
    }
  }
}
    ${ProjectListFragmentDoc}`;
export type ProjectsByUserComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectsByUserQuery, ProjectsByUserQueryVariables>, 'query'> & ({ variables: ProjectsByUserQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectsByUserComponent = (props: ProjectsByUserComponentProps) => (
      <ApolloReactComponents.Query<ProjectsByUserQuery, ProjectsByUserQueryVariables> query={ProjectsByUserDocument} {...props} />
    );
    
export type ProjectsByUserProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProjectsByUserQuery, ProjectsByUserQueryVariables>
    } & TChildProps;
export function withProjectsByUser<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProjectsByUserQuery,
  ProjectsByUserQueryVariables,
  ProjectsByUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProjectsByUserQuery, ProjectsByUserQueryVariables, ProjectsByUserProps<TChildProps, TDataName>>(ProjectsByUserDocument, {
      alias: 'projectsByUser',
      ...operationOptions
    });
};

/**
 * __useProjectsByUserQuery__
 *
 * To run a query within a React component, call `useProjectsByUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectsByUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectsByUserQuery({
 *   variables: {
 *      userName: // value for 'userName'
 *      pattern: // value for 'pattern'
 *      order: // value for 'order'
 *      first: // value for 'first'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useProjectsByUserQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectsByUserQuery, ProjectsByUserQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectsByUserQuery, ProjectsByUserQueryVariables>(ProjectsByUserDocument, baseOptions);
      }
export function useProjectsByUserLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectsByUserQuery, ProjectsByUserQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectsByUserQuery, ProjectsByUserQueryVariables>(ProjectsByUserDocument, baseOptions);
        }
export type ProjectsByUserQueryHookResult = ReturnType<typeof useProjectsByUserQuery>;
export type ProjectsByUserLazyQueryHookResult = ReturnType<typeof useProjectsByUserLazyQuery>;
export type ProjectsByUserQueryResult = ApolloReactCommon.QueryResult<ProjectsByUserQuery, ProjectsByUserQueryVariables>;
export const ProjectDetailsDocument = gql`
    query ProjectDetails($entityName: String, $projectName: String) {
  project(entityName: $entityName, name: $projectName) {
    id
    fromV2
  }
}
    `;
export type ProjectDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectDetailsQuery, ProjectDetailsQueryVariables>, 'query'>;

    export const ProjectDetailsComponent = (props: ProjectDetailsComponentProps) => (
      <ApolloReactComponents.Query<ProjectDetailsQuery, ProjectDetailsQueryVariables> query={ProjectDetailsDocument} {...props} />
    );
    
export type ProjectDetailsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProjectDetailsQuery, ProjectDetailsQueryVariables>
    } & TChildProps;
export function withProjectDetails<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProjectDetailsQuery,
  ProjectDetailsQueryVariables,
  ProjectDetailsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProjectDetailsQuery, ProjectDetailsQueryVariables, ProjectDetailsProps<TChildProps, TDataName>>(ProjectDetailsDocument, {
      alias: 'projectDetails',
      ...operationOptions
    });
};

/**
 * __useProjectDetailsQuery__
 *
 * To run a query within a React component, call `useProjectDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectDetailsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *   },
 * });
 */
export function useProjectDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectDetailsQuery, ProjectDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectDetailsQuery, ProjectDetailsQueryVariables>(ProjectDetailsDocument, baseOptions);
      }
export function useProjectDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectDetailsQuery, ProjectDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectDetailsQuery, ProjectDetailsQueryVariables>(ProjectDetailsDocument, baseOptions);
        }
export type ProjectDetailsQueryHookResult = ReturnType<typeof useProjectDetailsQuery>;
export type ProjectDetailsLazyQueryHookResult = ReturnType<typeof useProjectDetailsLazyQuery>;
export type ProjectDetailsQueryResult = ApolloReactCommon.QueryResult<ProjectDetailsQuery, ProjectDetailsQueryVariables>;
export const BucketedRunsDeltaQueryDocument = gql`
    query BucketedRunsDeltaQuery($bucketedHistorySpecs: [JSONString!]!, $configKeys: [String!], $currentRuns: [String!]!, $enableBasic: Boolean = true, $enableConfig: Boolean = false, $enableSummary: Boolean = false, $entityName: String!, $filters: JSONString!, $groupKeys: [String!]!, $groupLevel: Int!, $lastUpdated: DateTime!, $limit: Int!, $order: String!, $projectName: String!, $summaryKeys: [String!]) {
  project(name: $projectName, entityName: $entityName) {
    id
    ...DeltaQueryFragment
  }
}
    ${DeltaQueryFragmentFragmentDoc}`;
export type BucketedRunsDeltaQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BucketedRunsDeltaQueryQuery, BucketedRunsDeltaQueryQueryVariables>, 'query'> & ({ variables: BucketedRunsDeltaQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BucketedRunsDeltaQueryComponent = (props: BucketedRunsDeltaQueryComponentProps) => (
      <ApolloReactComponents.Query<BucketedRunsDeltaQueryQuery, BucketedRunsDeltaQueryQueryVariables> query={BucketedRunsDeltaQueryDocument} {...props} />
    );
    
export type BucketedRunsDeltaQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<BucketedRunsDeltaQueryQuery, BucketedRunsDeltaQueryQueryVariables>
    } & TChildProps;
export function withBucketedRunsDeltaQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BucketedRunsDeltaQueryQuery,
  BucketedRunsDeltaQueryQueryVariables,
  BucketedRunsDeltaQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, BucketedRunsDeltaQueryQuery, BucketedRunsDeltaQueryQueryVariables, BucketedRunsDeltaQueryProps<TChildProps, TDataName>>(BucketedRunsDeltaQueryDocument, {
      alias: 'bucketedRunsDeltaQuery',
      ...operationOptions
    });
};

/**
 * __useBucketedRunsDeltaQueryQuery__
 *
 * To run a query within a React component, call `useBucketedRunsDeltaQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useBucketedRunsDeltaQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBucketedRunsDeltaQueryQuery({
 *   variables: {
 *      bucketedHistorySpecs: // value for 'bucketedHistorySpecs'
 *      configKeys: // value for 'configKeys'
 *      currentRuns: // value for 'currentRuns'
 *      enableBasic: // value for 'enableBasic'
 *      enableConfig: // value for 'enableConfig'
 *      enableSummary: // value for 'enableSummary'
 *      entityName: // value for 'entityName'
 *      filters: // value for 'filters'
 *      groupKeys: // value for 'groupKeys'
 *      groupLevel: // value for 'groupLevel'
 *      lastUpdated: // value for 'lastUpdated'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      projectName: // value for 'projectName'
 *      summaryKeys: // value for 'summaryKeys'
 *   },
 * });
 */
export function useBucketedRunsDeltaQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BucketedRunsDeltaQueryQuery, BucketedRunsDeltaQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<BucketedRunsDeltaQueryQuery, BucketedRunsDeltaQueryQueryVariables>(BucketedRunsDeltaQueryDocument, baseOptions);
      }
export function useBucketedRunsDeltaQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BucketedRunsDeltaQueryQuery, BucketedRunsDeltaQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BucketedRunsDeltaQueryQuery, BucketedRunsDeltaQueryQueryVariables>(BucketedRunsDeltaQueryDocument, baseOptions);
        }
export type BucketedRunsDeltaQueryQueryHookResult = ReturnType<typeof useBucketedRunsDeltaQueryQuery>;
export type BucketedRunsDeltaQueryLazyQueryHookResult = ReturnType<typeof useBucketedRunsDeltaQueryLazyQuery>;
export type BucketedRunsDeltaQueryQueryResult = ApolloReactCommon.QueryResult<BucketedRunsDeltaQueryQuery, BucketedRunsDeltaQueryQueryVariables>;
export const BucketedRunsDeltaQueryInternalIdDocument = gql`
    query BucketedRunsDeltaQueryInternalID($bucketedHistorySpecs: [JSONString!]!, $configKeys: [String!], $currentRuns: [String!]!, $enableBasic: Boolean = true, $enableConfig: Boolean = false, $enableSummary: Boolean = false, $filters: JSONString!, $groupKeys: [String!]!, $groupLevel: Int!, $internalId: ID!, $lastUpdated: DateTime!, $limit: Int!, $order: String!, $summaryKeys: [String!]) {
  project(internalId: $internalId) {
    id
    ...DeltaQueryFragment
  }
}
    ${DeltaQueryFragmentFragmentDoc}`;
export type BucketedRunsDeltaQueryInternalIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BucketedRunsDeltaQueryInternalIdQuery, BucketedRunsDeltaQueryInternalIdQueryVariables>, 'query'> & ({ variables: BucketedRunsDeltaQueryInternalIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BucketedRunsDeltaQueryInternalIdComponent = (props: BucketedRunsDeltaQueryInternalIdComponentProps) => (
      <ApolloReactComponents.Query<BucketedRunsDeltaQueryInternalIdQuery, BucketedRunsDeltaQueryInternalIdQueryVariables> query={BucketedRunsDeltaQueryInternalIdDocument} {...props} />
    );
    
export type BucketedRunsDeltaQueryInternalIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<BucketedRunsDeltaQueryInternalIdQuery, BucketedRunsDeltaQueryInternalIdQueryVariables>
    } & TChildProps;
export function withBucketedRunsDeltaQueryInternalId<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BucketedRunsDeltaQueryInternalIdQuery,
  BucketedRunsDeltaQueryInternalIdQueryVariables,
  BucketedRunsDeltaQueryInternalIdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, BucketedRunsDeltaQueryInternalIdQuery, BucketedRunsDeltaQueryInternalIdQueryVariables, BucketedRunsDeltaQueryInternalIdProps<TChildProps, TDataName>>(BucketedRunsDeltaQueryInternalIdDocument, {
      alias: 'bucketedRunsDeltaQueryInternalId',
      ...operationOptions
    });
};

/**
 * __useBucketedRunsDeltaQueryInternalIdQuery__
 *
 * To run a query within a React component, call `useBucketedRunsDeltaQueryInternalIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useBucketedRunsDeltaQueryInternalIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBucketedRunsDeltaQueryInternalIdQuery({
 *   variables: {
 *      bucketedHistorySpecs: // value for 'bucketedHistorySpecs'
 *      configKeys: // value for 'configKeys'
 *      currentRuns: // value for 'currentRuns'
 *      enableBasic: // value for 'enableBasic'
 *      enableConfig: // value for 'enableConfig'
 *      enableSummary: // value for 'enableSummary'
 *      filters: // value for 'filters'
 *      groupKeys: // value for 'groupKeys'
 *      groupLevel: // value for 'groupLevel'
 *      internalId: // value for 'internalId'
 *      lastUpdated: // value for 'lastUpdated'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      summaryKeys: // value for 'summaryKeys'
 *   },
 * });
 */
export function useBucketedRunsDeltaQueryInternalIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BucketedRunsDeltaQueryInternalIdQuery, BucketedRunsDeltaQueryInternalIdQueryVariables>) {
        return ApolloReactHooks.useQuery<BucketedRunsDeltaQueryInternalIdQuery, BucketedRunsDeltaQueryInternalIdQueryVariables>(BucketedRunsDeltaQueryInternalIdDocument, baseOptions);
      }
export function useBucketedRunsDeltaQueryInternalIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BucketedRunsDeltaQueryInternalIdQuery, BucketedRunsDeltaQueryInternalIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BucketedRunsDeltaQueryInternalIdQuery, BucketedRunsDeltaQueryInternalIdQueryVariables>(BucketedRunsDeltaQueryInternalIdDocument, baseOptions);
        }
export type BucketedRunsDeltaQueryInternalIdQueryHookResult = ReturnType<typeof useBucketedRunsDeltaQueryInternalIdQuery>;
export type BucketedRunsDeltaQueryInternalIdLazyQueryHookResult = ReturnType<typeof useBucketedRunsDeltaQueryInternalIdLazyQuery>;
export type BucketedRunsDeltaQueryInternalIdQueryResult = ApolloReactCommon.QueryResult<BucketedRunsDeltaQueryInternalIdQuery, BucketedRunsDeltaQueryInternalIdQueryVariables>;
export const StarProjectDocument = gql`
    mutation starProject($entityName: String!, $projectName: String!, $organizationId: ID, $clientMutationId: String) {
  starProject(
    input: {entityName: $entityName, projectName: $projectName, organizationId: $organizationId, clientMutationId: $clientMutationId}
  ) {
    project {
      id
      starred(organizationId: $organizationId)
    }
    clientMutationId
  }
}
    `;
export type StarProjectMutationFn = ApolloReactCommon.MutationFunction<StarProjectMutation, StarProjectMutationVariables>;
export type StarProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<StarProjectMutation, StarProjectMutationVariables>, 'mutation'>;

    export const StarProjectComponent = (props: StarProjectComponentProps) => (
      <ApolloReactComponents.Mutation<StarProjectMutation, StarProjectMutationVariables> mutation={StarProjectDocument} {...props} />
    );
    
export type StarProjectProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<StarProjectMutation, StarProjectMutationVariables>
    } & TChildProps;
export function withStarProject<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  StarProjectMutation,
  StarProjectMutationVariables,
  StarProjectProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, StarProjectMutation, StarProjectMutationVariables, StarProjectProps<TChildProps, TDataName>>(StarProjectDocument, {
      alias: 'starProject',
      ...operationOptions
    });
};

/**
 * __useStarProjectMutation__
 *
 * To run a mutation, you first call `useStarProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStarProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [starProjectMutation, { data, loading, error }] = useStarProjectMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      organizationId: // value for 'organizationId'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useStarProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StarProjectMutation, StarProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<StarProjectMutation, StarProjectMutationVariables>(StarProjectDocument, baseOptions);
      }
export type StarProjectMutationHookResult = ReturnType<typeof useStarProjectMutation>;
export type StarProjectMutationResult = ApolloReactCommon.MutationResult<StarProjectMutation>;
export type StarProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<StarProjectMutation, StarProjectMutationVariables>;
export const UnstarProjectDocument = gql`
    mutation unstarProject($entityName: String!, $projectName: String!, $organizationId: ID, $clientMutationId: String) {
  unstarProject(
    input: {entityName: $entityName, projectName: $projectName, organizationId: $organizationId, clientMutationId: $clientMutationId}
  ) {
    project {
      id
      starred(organizationId: $organizationId)
    }
    clientMutationId
  }
}
    `;
export type UnstarProjectMutationFn = ApolloReactCommon.MutationFunction<UnstarProjectMutation, UnstarProjectMutationVariables>;
export type UnstarProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnstarProjectMutation, UnstarProjectMutationVariables>, 'mutation'>;

    export const UnstarProjectComponent = (props: UnstarProjectComponentProps) => (
      <ApolloReactComponents.Mutation<UnstarProjectMutation, UnstarProjectMutationVariables> mutation={UnstarProjectDocument} {...props} />
    );
    
export type UnstarProjectProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UnstarProjectMutation, UnstarProjectMutationVariables>
    } & TChildProps;
export function withUnstarProject<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UnstarProjectMutation,
  UnstarProjectMutationVariables,
  UnstarProjectProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UnstarProjectMutation, UnstarProjectMutationVariables, UnstarProjectProps<TChildProps, TDataName>>(UnstarProjectDocument, {
      alias: 'unstarProject',
      ...operationOptions
    });
};

/**
 * __useUnstarProjectMutation__
 *
 * To run a mutation, you first call `useUnstarProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnstarProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unstarProjectMutation, { data, loading, error }] = useUnstarProjectMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      organizationId: // value for 'organizationId'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useUnstarProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnstarProjectMutation, UnstarProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<UnstarProjectMutation, UnstarProjectMutationVariables>(UnstarProjectDocument, baseOptions);
      }
export type UnstarProjectMutationHookResult = ReturnType<typeof useUnstarProjectMutation>;
export type UnstarProjectMutationResult = ApolloReactCommon.MutationResult<UnstarProjectMutation>;
export type UnstarProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<UnstarProjectMutation, UnstarProjectMutationVariables>;
export const TeamMemberRolesDocument = gql`
    query TeamMemberRoles($entityName: String!) {
  entity(name: $entityName) {
    id
    members {
      id
      username
      memberRole {
        ID
        name
      }
    }
  }
}
    `;
export type TeamMemberRolesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamMemberRolesQuery, TeamMemberRolesQueryVariables>, 'query'> & ({ variables: TeamMemberRolesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamMemberRolesComponent = (props: TeamMemberRolesComponentProps) => (
      <ApolloReactComponents.Query<TeamMemberRolesQuery, TeamMemberRolesQueryVariables> query={TeamMemberRolesDocument} {...props} />
    );
    
export type TeamMemberRolesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TeamMemberRolesQuery, TeamMemberRolesQueryVariables>
    } & TChildProps;
export function withTeamMemberRoles<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TeamMemberRolesQuery,
  TeamMemberRolesQueryVariables,
  TeamMemberRolesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TeamMemberRolesQuery, TeamMemberRolesQueryVariables, TeamMemberRolesProps<TChildProps, TDataName>>(TeamMemberRolesDocument, {
      alias: 'teamMemberRoles',
      ...operationOptions
    });
};

/**
 * __useTeamMemberRolesQuery__
 *
 * To run a query within a React component, call `useTeamMemberRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMemberRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMemberRolesQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useTeamMemberRolesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamMemberRolesQuery, TeamMemberRolesQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamMemberRolesQuery, TeamMemberRolesQueryVariables>(TeamMemberRolesDocument, baseOptions);
      }
export function useTeamMemberRolesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamMemberRolesQuery, TeamMemberRolesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamMemberRolesQuery, TeamMemberRolesQueryVariables>(TeamMemberRolesDocument, baseOptions);
        }
export type TeamMemberRolesQueryHookResult = ReturnType<typeof useTeamMemberRolesQuery>;
export type TeamMemberRolesLazyQueryHookResult = ReturnType<typeof useTeamMemberRolesLazyQuery>;
export type TeamMemberRolesQueryResult = ApolloReactCommon.QueryResult<TeamMemberRolesQuery, TeamMemberRolesQueryVariables>;
export const UpdateUserProjectRoleDocument = gql`
    mutation updateUserProjectRole($userId: ID!, $projectId: ID!, $userProjectRole: String!, $clientMutationId: String) {
  updateProjectMember(
    input: {userId: $userId, projectId: $projectId, userProjectRole: $userProjectRole, clientMutationId: $clientMutationId}
  ) {
    success
    clientMutationId
  }
}
    `;
export type UpdateUserProjectRoleMutationFn = ApolloReactCommon.MutationFunction<UpdateUserProjectRoleMutation, UpdateUserProjectRoleMutationVariables>;
export type UpdateUserProjectRoleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserProjectRoleMutation, UpdateUserProjectRoleMutationVariables>, 'mutation'>;

    export const UpdateUserProjectRoleComponent = (props: UpdateUserProjectRoleComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserProjectRoleMutation, UpdateUserProjectRoleMutationVariables> mutation={UpdateUserProjectRoleDocument} {...props} />
    );
    
export type UpdateUserProjectRoleProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateUserProjectRoleMutation, UpdateUserProjectRoleMutationVariables>
    } & TChildProps;
export function withUpdateUserProjectRole<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateUserProjectRoleMutation,
  UpdateUserProjectRoleMutationVariables,
  UpdateUserProjectRoleProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateUserProjectRoleMutation, UpdateUserProjectRoleMutationVariables, UpdateUserProjectRoleProps<TChildProps, TDataName>>(UpdateUserProjectRoleDocument, {
      alias: 'updateUserProjectRole',
      ...operationOptions
    });
};

/**
 * __useUpdateUserProjectRoleMutation__
 *
 * To run a mutation, you first call `useUpdateUserProjectRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProjectRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProjectRoleMutation, { data, loading, error }] = useUpdateUserProjectRoleMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      projectId: // value for 'projectId'
 *      userProjectRole: // value for 'userProjectRole'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useUpdateUserProjectRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserProjectRoleMutation, UpdateUserProjectRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserProjectRoleMutation, UpdateUserProjectRoleMutationVariables>(UpdateUserProjectRoleDocument, baseOptions);
      }
export type UpdateUserProjectRoleMutationHookResult = ReturnType<typeof useUpdateUserProjectRoleMutation>;
export type UpdateUserProjectRoleMutationResult = ApolloReactCommon.MutationResult<UpdateUserProjectRoleMutation>;
export type UpdateUserProjectRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserProjectRoleMutation, UpdateUserProjectRoleMutationVariables>;
export const FilteredProjectPickerQueryDocument = gql`
    query FilteredProjectPickerQuery($entityName: String!, $userName: String!, $pattern: String) {
  entity(name: $entityName) {
    id
    projects(order: "-created_at", first: 50, pattern: $pattern) {
      edges {
        node {
          id
          internalId
          name
          entityName
          createdAt
        }
      }
    }
  }
  viewer {
    id
    projects(first: 500) {
      edges {
        node {
          id
          internalId
          name
          entityName
          createdAt
        }
      }
    }
  }
  user(userName: $userName) {
    id
    teams {
      edges {
        node {
          id
          name
          projects(order: "-created_at", first: 50, pattern: $pattern) {
            edges {
              node {
                id
                internalId
                name
                entityName
                createdAt
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type FilteredProjectPickerQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FilteredProjectPickerQueryQuery, FilteredProjectPickerQueryQueryVariables>, 'query'> & ({ variables: FilteredProjectPickerQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FilteredProjectPickerQueryComponent = (props: FilteredProjectPickerQueryComponentProps) => (
      <ApolloReactComponents.Query<FilteredProjectPickerQueryQuery, FilteredProjectPickerQueryQueryVariables> query={FilteredProjectPickerQueryDocument} {...props} />
    );
    
export type FilteredProjectPickerQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FilteredProjectPickerQueryQuery, FilteredProjectPickerQueryQueryVariables>
    } & TChildProps;
export function withFilteredProjectPickerQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FilteredProjectPickerQueryQuery,
  FilteredProjectPickerQueryQueryVariables,
  FilteredProjectPickerQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FilteredProjectPickerQueryQuery, FilteredProjectPickerQueryQueryVariables, FilteredProjectPickerQueryProps<TChildProps, TDataName>>(FilteredProjectPickerQueryDocument, {
      alias: 'filteredProjectPickerQuery',
      ...operationOptions
    });
};

/**
 * __useFilteredProjectPickerQueryQuery__
 *
 * To run a query within a React component, call `useFilteredProjectPickerQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useFilteredProjectPickerQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFilteredProjectPickerQueryQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      userName: // value for 'userName'
 *      pattern: // value for 'pattern'
 *   },
 * });
 */
export function useFilteredProjectPickerQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FilteredProjectPickerQueryQuery, FilteredProjectPickerQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<FilteredProjectPickerQueryQuery, FilteredProjectPickerQueryQueryVariables>(FilteredProjectPickerQueryDocument, baseOptions);
      }
export function useFilteredProjectPickerQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FilteredProjectPickerQueryQuery, FilteredProjectPickerQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FilteredProjectPickerQueryQuery, FilteredProjectPickerQueryQueryVariables>(FilteredProjectPickerQueryDocument, baseOptions);
        }
export type FilteredProjectPickerQueryQueryHookResult = ReturnType<typeof useFilteredProjectPickerQueryQuery>;
export type FilteredProjectPickerQueryLazyQueryHookResult = ReturnType<typeof useFilteredProjectPickerQueryLazyQuery>;
export type FilteredProjectPickerQueryQueryResult = ApolloReactCommon.QueryResult<FilteredProjectPickerQueryQuery, FilteredProjectPickerQueryQueryVariables>;
export const FetchRegistryArtifactCollectionsPartialDocument = gql`
    query FetchRegistryArtifactCollectionsPartial($entityName: String!, $projectName: String!, $limit: Int!, $after: String) {
  project(entityName: $entityName, name: $projectName) {
    id
    entityName
    name
    artifactCollections(first: $limit, after: $after, collectionTypes: PORTFOLIO) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...RegistryPageCollectionFragment
        }
      }
    }
  }
}
    ${RegistryPageCollectionFragmentFragmentDoc}`;
export type FetchRegistryArtifactCollectionsPartialComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FetchRegistryArtifactCollectionsPartialQuery, FetchRegistryArtifactCollectionsPartialQueryVariables>, 'query'> & ({ variables: FetchRegistryArtifactCollectionsPartialQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FetchRegistryArtifactCollectionsPartialComponent = (props: FetchRegistryArtifactCollectionsPartialComponentProps) => (
      <ApolloReactComponents.Query<FetchRegistryArtifactCollectionsPartialQuery, FetchRegistryArtifactCollectionsPartialQueryVariables> query={FetchRegistryArtifactCollectionsPartialDocument} {...props} />
    );
    
export type FetchRegistryArtifactCollectionsPartialProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FetchRegistryArtifactCollectionsPartialQuery, FetchRegistryArtifactCollectionsPartialQueryVariables>
    } & TChildProps;
export function withFetchRegistryArtifactCollectionsPartial<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FetchRegistryArtifactCollectionsPartialQuery,
  FetchRegistryArtifactCollectionsPartialQueryVariables,
  FetchRegistryArtifactCollectionsPartialProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FetchRegistryArtifactCollectionsPartialQuery, FetchRegistryArtifactCollectionsPartialQueryVariables, FetchRegistryArtifactCollectionsPartialProps<TChildProps, TDataName>>(FetchRegistryArtifactCollectionsPartialDocument, {
      alias: 'fetchRegistryArtifactCollectionsPartial',
      ...operationOptions
    });
};

/**
 * __useFetchRegistryArtifactCollectionsPartialQuery__
 *
 * To run a query within a React component, call `useFetchRegistryArtifactCollectionsPartialQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRegistryArtifactCollectionsPartialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRegistryArtifactCollectionsPartialQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useFetchRegistryArtifactCollectionsPartialQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchRegistryArtifactCollectionsPartialQuery, FetchRegistryArtifactCollectionsPartialQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchRegistryArtifactCollectionsPartialQuery, FetchRegistryArtifactCollectionsPartialQueryVariables>(FetchRegistryArtifactCollectionsPartialDocument, baseOptions);
      }
export function useFetchRegistryArtifactCollectionsPartialLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchRegistryArtifactCollectionsPartialQuery, FetchRegistryArtifactCollectionsPartialQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchRegistryArtifactCollectionsPartialQuery, FetchRegistryArtifactCollectionsPartialQueryVariables>(FetchRegistryArtifactCollectionsPartialDocument, baseOptions);
        }
export type FetchRegistryArtifactCollectionsPartialQueryHookResult = ReturnType<typeof useFetchRegistryArtifactCollectionsPartialQuery>;
export type FetchRegistryArtifactCollectionsPartialLazyQueryHookResult = ReturnType<typeof useFetchRegistryArtifactCollectionsPartialLazyQuery>;
export type FetchRegistryArtifactCollectionsPartialQueryResult = ApolloReactCommon.QueryResult<FetchRegistryArtifactCollectionsPartialQuery, FetchRegistryArtifactCollectionsPartialQueryVariables>;
export const RegistryOrganizationDocument = gql`
    query RegistryOrganization($orgName: String!) {
  organization(name: $orgName) {
    id
    members {
      ...OrganizationMemberFragment
    }
    serviceAccounts {
      id
      name
      accountType
    }
    subscriptions {
      id
      plan {
        id
        name
        planType
      }
    }
  }
}
    ${OrganizationMemberFragmentFragmentDoc}`;
export type RegistryOrganizationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RegistryOrganizationQuery, RegistryOrganizationQueryVariables>, 'query'> & ({ variables: RegistryOrganizationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RegistryOrganizationComponent = (props: RegistryOrganizationComponentProps) => (
      <ApolloReactComponents.Query<RegistryOrganizationQuery, RegistryOrganizationQueryVariables> query={RegistryOrganizationDocument} {...props} />
    );
    
export type RegistryOrganizationProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RegistryOrganizationQuery, RegistryOrganizationQueryVariables>
    } & TChildProps;
export function withRegistryOrganization<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RegistryOrganizationQuery,
  RegistryOrganizationQueryVariables,
  RegistryOrganizationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RegistryOrganizationQuery, RegistryOrganizationQueryVariables, RegistryOrganizationProps<TChildProps, TDataName>>(RegistryOrganizationDocument, {
      alias: 'registryOrganization',
      ...operationOptions
    });
};

/**
 * __useRegistryOrganizationQuery__
 *
 * To run a query within a React component, call `useRegistryOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistryOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistryOrganizationQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useRegistryOrganizationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RegistryOrganizationQuery, RegistryOrganizationQueryVariables>) {
        return ApolloReactHooks.useQuery<RegistryOrganizationQuery, RegistryOrganizationQueryVariables>(RegistryOrganizationDocument, baseOptions);
      }
export function useRegistryOrganizationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RegistryOrganizationQuery, RegistryOrganizationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RegistryOrganizationQuery, RegistryOrganizationQueryVariables>(RegistryOrganizationDocument, baseOptions);
        }
export type RegistryOrganizationQueryHookResult = ReturnType<typeof useRegistryOrganizationQuery>;
export type RegistryOrganizationLazyQueryHookResult = ReturnType<typeof useRegistryOrganizationLazyQuery>;
export type RegistryOrganizationQueryResult = ApolloReactCommon.QueryResult<RegistryOrganizationQuery, RegistryOrganizationQueryVariables>;
export const OrganizationReadOnlyDocument = gql`
    query OrganizationReadOnly($orgName: String!) {
  organization(name: $orgName) {
    id
    orgEntity {
      id
      readOnly
    }
  }
}
    `;
export type OrganizationReadOnlyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationReadOnlyQuery, OrganizationReadOnlyQueryVariables>, 'query'> & ({ variables: OrganizationReadOnlyQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationReadOnlyComponent = (props: OrganizationReadOnlyComponentProps) => (
      <ApolloReactComponents.Query<OrganizationReadOnlyQuery, OrganizationReadOnlyQueryVariables> query={OrganizationReadOnlyDocument} {...props} />
    );
    
export type OrganizationReadOnlyProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationReadOnlyQuery, OrganizationReadOnlyQueryVariables>
    } & TChildProps;
export function withOrganizationReadOnly<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationReadOnlyQuery,
  OrganizationReadOnlyQueryVariables,
  OrganizationReadOnlyProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationReadOnlyQuery, OrganizationReadOnlyQueryVariables, OrganizationReadOnlyProps<TChildProps, TDataName>>(OrganizationReadOnlyDocument, {
      alias: 'organizationReadOnly',
      ...operationOptions
    });
};

/**
 * __useOrganizationReadOnlyQuery__
 *
 * To run a query within a React component, call `useOrganizationReadOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationReadOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationReadOnlyQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useOrganizationReadOnlyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationReadOnlyQuery, OrganizationReadOnlyQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationReadOnlyQuery, OrganizationReadOnlyQueryVariables>(OrganizationReadOnlyDocument, baseOptions);
      }
export function useOrganizationReadOnlyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationReadOnlyQuery, OrganizationReadOnlyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationReadOnlyQuery, OrganizationReadOnlyQueryVariables>(OrganizationReadOnlyDocument, baseOptions);
        }
export type OrganizationReadOnlyQueryHookResult = ReturnType<typeof useOrganizationReadOnlyQuery>;
export type OrganizationReadOnlyLazyQueryHookResult = ReturnType<typeof useOrganizationReadOnlyLazyQuery>;
export type OrganizationReadOnlyQueryResult = ApolloReactCommon.QueryResult<OrganizationReadOnlyQuery, OrganizationReadOnlyQueryVariables>;
export const ProjectReadOnlyDocument = gql`
    query ProjectReadOnly($entityName: String!, $projectName: String!) {
  entity(name: $entityName) {
    id
    project(name: $projectName) {
      id
      readOnly
    }
  }
}
    `;
export type ProjectReadOnlyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectReadOnlyQuery, ProjectReadOnlyQueryVariables>, 'query'> & ({ variables: ProjectReadOnlyQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectReadOnlyComponent = (props: ProjectReadOnlyComponentProps) => (
      <ApolloReactComponents.Query<ProjectReadOnlyQuery, ProjectReadOnlyQueryVariables> query={ProjectReadOnlyDocument} {...props} />
    );
    
export type ProjectReadOnlyProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProjectReadOnlyQuery, ProjectReadOnlyQueryVariables>
    } & TChildProps;
export function withProjectReadOnly<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProjectReadOnlyQuery,
  ProjectReadOnlyQueryVariables,
  ProjectReadOnlyProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProjectReadOnlyQuery, ProjectReadOnlyQueryVariables, ProjectReadOnlyProps<TChildProps, TDataName>>(ProjectReadOnlyDocument, {
      alias: 'projectReadOnly',
      ...operationOptions
    });
};

/**
 * __useProjectReadOnlyQuery__
 *
 * To run a query within a React component, call `useProjectReadOnlyQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectReadOnlyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectReadOnlyQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *   },
 * });
 */
export function useProjectReadOnlyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectReadOnlyQuery, ProjectReadOnlyQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectReadOnlyQuery, ProjectReadOnlyQueryVariables>(ProjectReadOnlyDocument, baseOptions);
      }
export function useProjectReadOnlyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectReadOnlyQuery, ProjectReadOnlyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectReadOnlyQuery, ProjectReadOnlyQueryVariables>(ProjectReadOnlyDocument, baseOptions);
        }
export type ProjectReadOnlyQueryHookResult = ReturnType<typeof useProjectReadOnlyQuery>;
export type ProjectReadOnlyLazyQueryHookResult = ReturnType<typeof useProjectReadOnlyLazyQuery>;
export type ProjectReadOnlyQueryResult = ApolloReactCommon.QueryResult<ProjectReadOnlyQuery, ProjectReadOnlyQueryVariables>;
export const RegistryProjectArtifactTypesDocument = gql`
    query RegistryProjectArtifactTypes($entityName: String!, $projectName: String!) {
  project(entityName: $entityName, name: $projectName) {
    id
    name
    artifactTypes(includeAll: true) {
      edges {
        node {
          id
          name
        }
      }
    }
  }
}
    `;
export type RegistryProjectArtifactTypesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RegistryProjectArtifactTypesQuery, RegistryProjectArtifactTypesQueryVariables>, 'query'> & ({ variables: RegistryProjectArtifactTypesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RegistryProjectArtifactTypesComponent = (props: RegistryProjectArtifactTypesComponentProps) => (
      <ApolloReactComponents.Query<RegistryProjectArtifactTypesQuery, RegistryProjectArtifactTypesQueryVariables> query={RegistryProjectArtifactTypesDocument} {...props} />
    );
    
export type RegistryProjectArtifactTypesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RegistryProjectArtifactTypesQuery, RegistryProjectArtifactTypesQueryVariables>
    } & TChildProps;
export function withRegistryProjectArtifactTypes<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RegistryProjectArtifactTypesQuery,
  RegistryProjectArtifactTypesQueryVariables,
  RegistryProjectArtifactTypesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RegistryProjectArtifactTypesQuery, RegistryProjectArtifactTypesQueryVariables, RegistryProjectArtifactTypesProps<TChildProps, TDataName>>(RegistryProjectArtifactTypesDocument, {
      alias: 'registryProjectArtifactTypes',
      ...operationOptions
    });
};

/**
 * __useRegistryProjectArtifactTypesQuery__
 *
 * To run a query within a React component, call `useRegistryProjectArtifactTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistryProjectArtifactTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistryProjectArtifactTypesQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *   },
 * });
 */
export function useRegistryProjectArtifactTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RegistryProjectArtifactTypesQuery, RegistryProjectArtifactTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<RegistryProjectArtifactTypesQuery, RegistryProjectArtifactTypesQueryVariables>(RegistryProjectArtifactTypesDocument, baseOptions);
      }
export function useRegistryProjectArtifactTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RegistryProjectArtifactTypesQuery, RegistryProjectArtifactTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RegistryProjectArtifactTypesQuery, RegistryProjectArtifactTypesQueryVariables>(RegistryProjectArtifactTypesDocument, baseOptions);
        }
export type RegistryProjectArtifactTypesQueryHookResult = ReturnType<typeof useRegistryProjectArtifactTypesQuery>;
export type RegistryProjectArtifactTypesLazyQueryHookResult = ReturnType<typeof useRegistryProjectArtifactTypesLazyQuery>;
export type RegistryProjectArtifactTypesQueryResult = ApolloReactCommon.QueryResult<RegistryProjectArtifactTypesQuery, RegistryProjectArtifactTypesQueryVariables>;
export const RegistryProjectMembersDocument = gql`
    query RegistryProjectMembers($entityName: String!, $projectName: String!) {
  project(entityName: $entityName, name: $projectName) {
    id
    name
    user {
      id
      name
      username
      photoUrl
      email
      accountType
    }
    members {
      id
      username
      name
      photoUrl
      email
      accountType
      role {
        ID
        name
      }
      projectRoleIsInherited
    }
  }
}
    `;
export type RegistryProjectMembersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RegistryProjectMembersQuery, RegistryProjectMembersQueryVariables>, 'query'> & ({ variables: RegistryProjectMembersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RegistryProjectMembersComponent = (props: RegistryProjectMembersComponentProps) => (
      <ApolloReactComponents.Query<RegistryProjectMembersQuery, RegistryProjectMembersQueryVariables> query={RegistryProjectMembersDocument} {...props} />
    );
    
export type RegistryProjectMembersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RegistryProjectMembersQuery, RegistryProjectMembersQueryVariables>
    } & TChildProps;
export function withRegistryProjectMembers<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RegistryProjectMembersQuery,
  RegistryProjectMembersQueryVariables,
  RegistryProjectMembersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RegistryProjectMembersQuery, RegistryProjectMembersQueryVariables, RegistryProjectMembersProps<TChildProps, TDataName>>(RegistryProjectMembersDocument, {
      alias: 'registryProjectMembers',
      ...operationOptions
    });
};

/**
 * __useRegistryProjectMembersQuery__
 *
 * To run a query within a React component, call `useRegistryProjectMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistryProjectMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistryProjectMembersQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *   },
 * });
 */
export function useRegistryProjectMembersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RegistryProjectMembersQuery, RegistryProjectMembersQueryVariables>) {
        return ApolloReactHooks.useQuery<RegistryProjectMembersQuery, RegistryProjectMembersQueryVariables>(RegistryProjectMembersDocument, baseOptions);
      }
export function useRegistryProjectMembersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RegistryProjectMembersQuery, RegistryProjectMembersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RegistryProjectMembersQuery, RegistryProjectMembersQueryVariables>(RegistryProjectMembersDocument, baseOptions);
        }
export type RegistryProjectMembersQueryHookResult = ReturnType<typeof useRegistryProjectMembersQuery>;
export type RegistryProjectMembersLazyQueryHookResult = ReturnType<typeof useRegistryProjectMembersLazyQuery>;
export type RegistryProjectMembersQueryResult = ApolloReactCommon.QueryResult<RegistryProjectMembersQuery, RegistryProjectMembersQueryVariables>;
export const RegistryProjectsDocument = gql`
    query RegistryProjects($orgName: String!) {
  organization(name: $orgName) {
    id
    orgEntity {
      id
      name
      projects(pattern: "wandb-registry-") {
        edges {
          node {
            id
            ...ProjectFragment
          }
        }
      }
    }
  }
}
    ${ProjectFragmentFragmentDoc}`;
export type RegistryProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RegistryProjectsQuery, RegistryProjectsQueryVariables>, 'query'> & ({ variables: RegistryProjectsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RegistryProjectsComponent = (props: RegistryProjectsComponentProps) => (
      <ApolloReactComponents.Query<RegistryProjectsQuery, RegistryProjectsQueryVariables> query={RegistryProjectsDocument} {...props} />
    );
    
export type RegistryProjectsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RegistryProjectsQuery, RegistryProjectsQueryVariables>
    } & TChildProps;
export function withRegistryProjects<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RegistryProjectsQuery,
  RegistryProjectsQueryVariables,
  RegistryProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RegistryProjectsQuery, RegistryProjectsQueryVariables, RegistryProjectsProps<TChildProps, TDataName>>(RegistryProjectsDocument, {
      alias: 'registryProjects',
      ...operationOptions
    });
};

/**
 * __useRegistryProjectsQuery__
 *
 * To run a query within a React component, call `useRegistryProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistryProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistryProjectsQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useRegistryProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RegistryProjectsQuery, RegistryProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<RegistryProjectsQuery, RegistryProjectsQueryVariables>(RegistryProjectsDocument, baseOptions);
      }
export function useRegistryProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RegistryProjectsQuery, RegistryProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RegistryProjectsQuery, RegistryProjectsQueryVariables>(RegistryProjectsDocument, baseOptions);
        }
export type RegistryProjectsQueryHookResult = ReturnType<typeof useRegistryProjectsQuery>;
export type RegistryProjectsLazyQueryHookResult = ReturnType<typeof useRegistryProjectsLazyQuery>;
export type RegistryProjectsQueryResult = ApolloReactCommon.QueryResult<RegistryProjectsQuery, RegistryProjectsQueryVariables>;
export const LegacyRegistryProjectsDocument = gql`
    query LegacyRegistryProjects($entityName: String!) {
  projects(pattern: "", entityName: $entityName) {
    edges {
      node {
        id
        ...ProjectFragment
      }
    }
  }
}
    ${ProjectFragmentFragmentDoc}`;
export type LegacyRegistryProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LegacyRegistryProjectsQuery, LegacyRegistryProjectsQueryVariables>, 'query'> & ({ variables: LegacyRegistryProjectsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const LegacyRegistryProjectsComponent = (props: LegacyRegistryProjectsComponentProps) => (
      <ApolloReactComponents.Query<LegacyRegistryProjectsQuery, LegacyRegistryProjectsQueryVariables> query={LegacyRegistryProjectsDocument} {...props} />
    );
    
export type LegacyRegistryProjectsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<LegacyRegistryProjectsQuery, LegacyRegistryProjectsQueryVariables>
    } & TChildProps;
export function withLegacyRegistryProjects<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LegacyRegistryProjectsQuery,
  LegacyRegistryProjectsQueryVariables,
  LegacyRegistryProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, LegacyRegistryProjectsQuery, LegacyRegistryProjectsQueryVariables, LegacyRegistryProjectsProps<TChildProps, TDataName>>(LegacyRegistryProjectsDocument, {
      alias: 'legacyRegistryProjects',
      ...operationOptions
    });
};

/**
 * __useLegacyRegistryProjectsQuery__
 *
 * To run a query within a React component, call `useLegacyRegistryProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useLegacyRegistryProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLegacyRegistryProjectsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useLegacyRegistryProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LegacyRegistryProjectsQuery, LegacyRegistryProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<LegacyRegistryProjectsQuery, LegacyRegistryProjectsQueryVariables>(LegacyRegistryProjectsDocument, baseOptions);
      }
export function useLegacyRegistryProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LegacyRegistryProjectsQuery, LegacyRegistryProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LegacyRegistryProjectsQuery, LegacyRegistryProjectsQueryVariables>(LegacyRegistryProjectsDocument, baseOptions);
        }
export type LegacyRegistryProjectsQueryHookResult = ReturnType<typeof useLegacyRegistryProjectsQuery>;
export type LegacyRegistryProjectsLazyQueryHookResult = ReturnType<typeof useLegacyRegistryProjectsLazyQuery>;
export type LegacyRegistryProjectsQueryResult = ApolloReactCommon.QueryResult<LegacyRegistryProjectsQuery, LegacyRegistryProjectsQueryVariables>;
export const RegistryProjectsWithTypeDocument = gql`
    query RegistryProjectsWithType($orgName: String!, $artifactTypeName: String!) {
  organization(name: $orgName) {
    id
    orgEntity {
      id
      name
      projects(pattern: "wandb-registry-") {
        edges {
          node {
            id
            name
            artifactType(name: $artifactTypeName) {
              id
              name
            }
            allowAllArtifactTypesInRegistry
          }
        }
      }
    }
  }
}
    `;
export type RegistryProjectsWithTypeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RegistryProjectsWithTypeQuery, RegistryProjectsWithTypeQueryVariables>, 'query'> & ({ variables: RegistryProjectsWithTypeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RegistryProjectsWithTypeComponent = (props: RegistryProjectsWithTypeComponentProps) => (
      <ApolloReactComponents.Query<RegistryProjectsWithTypeQuery, RegistryProjectsWithTypeQueryVariables> query={RegistryProjectsWithTypeDocument} {...props} />
    );
    
export type RegistryProjectsWithTypeProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RegistryProjectsWithTypeQuery, RegistryProjectsWithTypeQueryVariables>
    } & TChildProps;
export function withRegistryProjectsWithType<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RegistryProjectsWithTypeQuery,
  RegistryProjectsWithTypeQueryVariables,
  RegistryProjectsWithTypeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RegistryProjectsWithTypeQuery, RegistryProjectsWithTypeQueryVariables, RegistryProjectsWithTypeProps<TChildProps, TDataName>>(RegistryProjectsWithTypeDocument, {
      alias: 'registryProjectsWithType',
      ...operationOptions
    });
};

/**
 * __useRegistryProjectsWithTypeQuery__
 *
 * To run a query within a React component, call `useRegistryProjectsWithTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegistryProjectsWithTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegistryProjectsWithTypeQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *      artifactTypeName: // value for 'artifactTypeName'
 *   },
 * });
 */
export function useRegistryProjectsWithTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RegistryProjectsWithTypeQuery, RegistryProjectsWithTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<RegistryProjectsWithTypeQuery, RegistryProjectsWithTypeQueryVariables>(RegistryProjectsWithTypeDocument, baseOptions);
      }
export function useRegistryProjectsWithTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RegistryProjectsWithTypeQuery, RegistryProjectsWithTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RegistryProjectsWithTypeQuery, RegistryProjectsWithTypeQueryVariables>(RegistryProjectsWithTypeDocument, baseOptions);
        }
export type RegistryProjectsWithTypeQueryHookResult = ReturnType<typeof useRegistryProjectsWithTypeQuery>;
export type RegistryProjectsWithTypeLazyQueryHookResult = ReturnType<typeof useRegistryProjectsWithTypeLazyQuery>;
export type RegistryProjectsWithTypeQueryResult = ApolloReactCommon.QueryResult<RegistryProjectsWithTypeQuery, RegistryProjectsWithTypeQueryVariables>;
export const UpsertRegistryProjectDocument = gql`
    mutation upsertRegistryProject($description: String, $entityName: String, $id: String, $name: String, $access: String, $owner: ID, $allowAllArtifactTypesInRegistry: Boolean) {
  upsertModel(
    input: {description: $description, entityName: $entityName, id: $id, name: $name, access: $access, owner: $owner, allowAllArtifactTypesInRegistry: $allowAllArtifactTypesInRegistry}
  ) {
    project {
      id
      name
      entityName
      description
      access
      user {
        id
      }
      allowAllArtifactTypesInRegistry
    }
    inserted
  }
}
    `;
export type UpsertRegistryProjectMutationFn = ApolloReactCommon.MutationFunction<UpsertRegistryProjectMutation, UpsertRegistryProjectMutationVariables>;
export type UpsertRegistryProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertRegistryProjectMutation, UpsertRegistryProjectMutationVariables>, 'mutation'>;

    export const UpsertRegistryProjectComponent = (props: UpsertRegistryProjectComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertRegistryProjectMutation, UpsertRegistryProjectMutationVariables> mutation={UpsertRegistryProjectDocument} {...props} />
    );
    
export type UpsertRegistryProjectProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpsertRegistryProjectMutation, UpsertRegistryProjectMutationVariables>
    } & TChildProps;
export function withUpsertRegistryProject<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpsertRegistryProjectMutation,
  UpsertRegistryProjectMutationVariables,
  UpsertRegistryProjectProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpsertRegistryProjectMutation, UpsertRegistryProjectMutationVariables, UpsertRegistryProjectProps<TChildProps, TDataName>>(UpsertRegistryProjectDocument, {
      alias: 'upsertRegistryProject',
      ...operationOptions
    });
};

/**
 * __useUpsertRegistryProjectMutation__
 *
 * To run a mutation, you first call `useUpsertRegistryProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertRegistryProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertRegistryProjectMutation, { data, loading, error }] = useUpsertRegistryProjectMutation({
 *   variables: {
 *      description: // value for 'description'
 *      entityName: // value for 'entityName'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      access: // value for 'access'
 *      owner: // value for 'owner'
 *      allowAllArtifactTypesInRegistry: // value for 'allowAllArtifactTypesInRegistry'
 *   },
 * });
 */
export function useUpsertRegistryProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertRegistryProjectMutation, UpsertRegistryProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertRegistryProjectMutation, UpsertRegistryProjectMutationVariables>(UpsertRegistryProjectDocument, baseOptions);
      }
export type UpsertRegistryProjectMutationHookResult = ReturnType<typeof useUpsertRegistryProjectMutation>;
export type UpsertRegistryProjectMutationResult = ApolloReactCommon.MutationResult<UpsertRegistryProjectMutation>;
export type UpsertRegistryProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertRegistryProjectMutation, UpsertRegistryProjectMutationVariables>;
export const UpdateUserRegistryRoleDocument = gql`
    mutation updateUserRegistryRole($userId: ID!, $projectId: ID!, $userProjectRole: String!, $clientMutationId: String) {
  updateProjectMember(
    input: {userId: $userId, projectId: $projectId, userProjectRole: $userProjectRole, clientMutationId: $clientMutationId}
  ) {
    success
    clientMutationId
  }
}
    `;
export type UpdateUserRegistryRoleMutationFn = ApolloReactCommon.MutationFunction<UpdateUserRegistryRoleMutation, UpdateUserRegistryRoleMutationVariables>;
export type UpdateUserRegistryRoleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserRegistryRoleMutation, UpdateUserRegistryRoleMutationVariables>, 'mutation'>;

    export const UpdateUserRegistryRoleComponent = (props: UpdateUserRegistryRoleComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserRegistryRoleMutation, UpdateUserRegistryRoleMutationVariables> mutation={UpdateUserRegistryRoleDocument} {...props} />
    );
    
export type UpdateUserRegistryRoleProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateUserRegistryRoleMutation, UpdateUserRegistryRoleMutationVariables>
    } & TChildProps;
export function withUpdateUserRegistryRole<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateUserRegistryRoleMutation,
  UpdateUserRegistryRoleMutationVariables,
  UpdateUserRegistryRoleProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateUserRegistryRoleMutation, UpdateUserRegistryRoleMutationVariables, UpdateUserRegistryRoleProps<TChildProps, TDataName>>(UpdateUserRegistryRoleDocument, {
      alias: 'updateUserRegistryRole',
      ...operationOptions
    });
};

/**
 * __useUpdateUserRegistryRoleMutation__
 *
 * To run a mutation, you first call `useUpdateUserRegistryRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRegistryRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRegistryRoleMutation, { data, loading, error }] = useUpdateUserRegistryRoleMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      projectId: // value for 'projectId'
 *      userProjectRole: // value for 'userProjectRole'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useUpdateUserRegistryRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserRegistryRoleMutation, UpdateUserRegistryRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserRegistryRoleMutation, UpdateUserRegistryRoleMutationVariables>(UpdateUserRegistryRoleDocument, baseOptions);
      }
export type UpdateUserRegistryRoleMutationHookResult = ReturnType<typeof useUpdateUserRegistryRoleMutation>;
export type UpdateUserRegistryRoleMutationResult = ApolloReactCommon.MutationResult<UpdateUserRegistryRoleMutation>;
export type UpdateUserRegistryRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserRegistryRoleMutation, UpdateUserRegistryRoleMutationVariables>;
export const ImportPanelMenuProjectsDocument = gql`
    query ImportPanelMenuProjects($userName: String!, $selectedEntityName: String, $projectSearchQuery: String) {
  entity(name: $selectedEntityName) {
    id
    ...ImportPanelMenuEntityFragment
  }
  user(userName: $userName) {
    id
    teams {
      edges {
        node {
          id
          ...ImportPanelMenuEntityFragment
        }
      }
    }
  }
}
    ${ImportPanelMenuEntityFragmentFragmentDoc}`;
export type ImportPanelMenuProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ImportPanelMenuProjectsQuery, ImportPanelMenuProjectsQueryVariables>, 'query'> & ({ variables: ImportPanelMenuProjectsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ImportPanelMenuProjectsComponent = (props: ImportPanelMenuProjectsComponentProps) => (
      <ApolloReactComponents.Query<ImportPanelMenuProjectsQuery, ImportPanelMenuProjectsQueryVariables> query={ImportPanelMenuProjectsDocument} {...props} />
    );
    
export type ImportPanelMenuProjectsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ImportPanelMenuProjectsQuery, ImportPanelMenuProjectsQueryVariables>
    } & TChildProps;
export function withImportPanelMenuProjects<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ImportPanelMenuProjectsQuery,
  ImportPanelMenuProjectsQueryVariables,
  ImportPanelMenuProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ImportPanelMenuProjectsQuery, ImportPanelMenuProjectsQueryVariables, ImportPanelMenuProjectsProps<TChildProps, TDataName>>(ImportPanelMenuProjectsDocument, {
      alias: 'importPanelMenuProjects',
      ...operationOptions
    });
};

/**
 * __useImportPanelMenuProjectsQuery__
 *
 * To run a query within a React component, call `useImportPanelMenuProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportPanelMenuProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportPanelMenuProjectsQuery({
 *   variables: {
 *      userName: // value for 'userName'
 *      selectedEntityName: // value for 'selectedEntityName'
 *      projectSearchQuery: // value for 'projectSearchQuery'
 *   },
 * });
 */
export function useImportPanelMenuProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ImportPanelMenuProjectsQuery, ImportPanelMenuProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<ImportPanelMenuProjectsQuery, ImportPanelMenuProjectsQueryVariables>(ImportPanelMenuProjectsDocument, baseOptions);
      }
export function useImportPanelMenuProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ImportPanelMenuProjectsQuery, ImportPanelMenuProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ImportPanelMenuProjectsQuery, ImportPanelMenuProjectsQueryVariables>(ImportPanelMenuProjectsDocument, baseOptions);
        }
export type ImportPanelMenuProjectsQueryHookResult = ReturnType<typeof useImportPanelMenuProjectsQuery>;
export type ImportPanelMenuProjectsLazyQueryHookResult = ReturnType<typeof useImportPanelMenuProjectsLazyQuery>;
export type ImportPanelMenuProjectsQueryResult = ApolloReactCommon.QueryResult<ImportPanelMenuProjectsQuery, ImportPanelMenuProjectsQueryVariables>;
export const ImportPanelMenuViewsDocument = gql`
    query ImportPanelMenuViews($entityName: String, $projectName: String, $userName: String) {
  project(name: $projectName, entityName: $entityName) {
    id
    allViews(viewType: "project-view", userName: $userName) {
      edges {
        node {
          id
          ...ImportPanelProjectViewsFragment
        }
      }
    }
  }
}
    ${ImportPanelProjectViewsFragmentFragmentDoc}`;
export type ImportPanelMenuViewsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ImportPanelMenuViewsQuery, ImportPanelMenuViewsQueryVariables>, 'query'>;

    export const ImportPanelMenuViewsComponent = (props: ImportPanelMenuViewsComponentProps) => (
      <ApolloReactComponents.Query<ImportPanelMenuViewsQuery, ImportPanelMenuViewsQueryVariables> query={ImportPanelMenuViewsDocument} {...props} />
    );
    
export type ImportPanelMenuViewsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ImportPanelMenuViewsQuery, ImportPanelMenuViewsQueryVariables>
    } & TChildProps;
export function withImportPanelMenuViews<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ImportPanelMenuViewsQuery,
  ImportPanelMenuViewsQueryVariables,
  ImportPanelMenuViewsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ImportPanelMenuViewsQuery, ImportPanelMenuViewsQueryVariables, ImportPanelMenuViewsProps<TChildProps, TDataName>>(ImportPanelMenuViewsDocument, {
      alias: 'importPanelMenuViews',
      ...operationOptions
    });
};

/**
 * __useImportPanelMenuViewsQuery__
 *
 * To run a query within a React component, call `useImportPanelMenuViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportPanelMenuViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportPanelMenuViewsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      userName: // value for 'userName'
 *   },
 * });
 */
export function useImportPanelMenuViewsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ImportPanelMenuViewsQuery, ImportPanelMenuViewsQueryVariables>) {
        return ApolloReactHooks.useQuery<ImportPanelMenuViewsQuery, ImportPanelMenuViewsQueryVariables>(ImportPanelMenuViewsDocument, baseOptions);
      }
export function useImportPanelMenuViewsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ImportPanelMenuViewsQuery, ImportPanelMenuViewsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ImportPanelMenuViewsQuery, ImportPanelMenuViewsQueryVariables>(ImportPanelMenuViewsDocument, baseOptions);
        }
export type ImportPanelMenuViewsQueryHookResult = ReturnType<typeof useImportPanelMenuViewsQuery>;
export type ImportPanelMenuViewsLazyQueryHookResult = ReturnType<typeof useImportPanelMenuViewsLazyQuery>;
export type ImportPanelMenuViewsQueryResult = ApolloReactCommon.QueryResult<ImportPanelMenuViewsQuery, ImportPanelMenuViewsQueryVariables>;
export const ImportPanelMenuViewSpecDocument = gql`
    query ImportPanelMenuViewSpec($viewId: ID!) {
  view(id: $viewId) {
    id
    spec
  }
}
    `;
export type ImportPanelMenuViewSpecComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ImportPanelMenuViewSpecQuery, ImportPanelMenuViewSpecQueryVariables>, 'query'> & ({ variables: ImportPanelMenuViewSpecQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ImportPanelMenuViewSpecComponent = (props: ImportPanelMenuViewSpecComponentProps) => (
      <ApolloReactComponents.Query<ImportPanelMenuViewSpecQuery, ImportPanelMenuViewSpecQueryVariables> query={ImportPanelMenuViewSpecDocument} {...props} />
    );
    
export type ImportPanelMenuViewSpecProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ImportPanelMenuViewSpecQuery, ImportPanelMenuViewSpecQueryVariables>
    } & TChildProps;
export function withImportPanelMenuViewSpec<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ImportPanelMenuViewSpecQuery,
  ImportPanelMenuViewSpecQueryVariables,
  ImportPanelMenuViewSpecProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ImportPanelMenuViewSpecQuery, ImportPanelMenuViewSpecQueryVariables, ImportPanelMenuViewSpecProps<TChildProps, TDataName>>(ImportPanelMenuViewSpecDocument, {
      alias: 'importPanelMenuViewSpec',
      ...operationOptions
    });
};

/**
 * __useImportPanelMenuViewSpecQuery__
 *
 * To run a query within a React component, call `useImportPanelMenuViewSpecQuery` and pass it any options that fit your needs.
 * When your component renders, `useImportPanelMenuViewSpecQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useImportPanelMenuViewSpecQuery({
 *   variables: {
 *      viewId: // value for 'viewId'
 *   },
 * });
 */
export function useImportPanelMenuViewSpecQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ImportPanelMenuViewSpecQuery, ImportPanelMenuViewSpecQueryVariables>) {
        return ApolloReactHooks.useQuery<ImportPanelMenuViewSpecQuery, ImportPanelMenuViewSpecQueryVariables>(ImportPanelMenuViewSpecDocument, baseOptions);
      }
export function useImportPanelMenuViewSpecLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ImportPanelMenuViewSpecQuery, ImportPanelMenuViewSpecQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ImportPanelMenuViewSpecQuery, ImportPanelMenuViewSpecQueryVariables>(ImportPanelMenuViewSpecDocument, baseOptions);
        }
export type ImportPanelMenuViewSpecQueryHookResult = ReturnType<typeof useImportPanelMenuViewSpecQuery>;
export type ImportPanelMenuViewSpecLazyQueryHookResult = ReturnType<typeof useImportPanelMenuViewSpecLazyQuery>;
export type ImportPanelMenuViewSpecQueryResult = ApolloReactCommon.QueryResult<ImportPanelMenuViewSpecQuery, ImportPanelMenuViewSpecQueryVariables>;
export const AllRunGroupsDocument = gql`
    query AllRunGroups($projectName: String!, $entityName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    runs(groupKeys: ["group"]) {
      edges {
        node {
          id
          name
          group
        }
      }
    }
  }
}
    `;
export type AllRunGroupsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllRunGroupsQuery, AllRunGroupsQueryVariables>, 'query'> & ({ variables: AllRunGroupsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AllRunGroupsComponent = (props: AllRunGroupsComponentProps) => (
      <ApolloReactComponents.Query<AllRunGroupsQuery, AllRunGroupsQueryVariables> query={AllRunGroupsDocument} {...props} />
    );
    
export type AllRunGroupsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AllRunGroupsQuery, AllRunGroupsQueryVariables>
    } & TChildProps;
export function withAllRunGroups<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AllRunGroupsQuery,
  AllRunGroupsQueryVariables,
  AllRunGroupsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AllRunGroupsQuery, AllRunGroupsQueryVariables, AllRunGroupsProps<TChildProps, TDataName>>(AllRunGroupsDocument, {
      alias: 'allRunGroups',
      ...operationOptions
    });
};

/**
 * __useAllRunGroupsQuery__
 *
 * To run a query within a React component, call `useAllRunGroupsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllRunGroupsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllRunGroupsQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useAllRunGroupsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllRunGroupsQuery, AllRunGroupsQueryVariables>) {
        return ApolloReactHooks.useQuery<AllRunGroupsQuery, AllRunGroupsQueryVariables>(AllRunGroupsDocument, baseOptions);
      }
export function useAllRunGroupsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllRunGroupsQuery, AllRunGroupsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllRunGroupsQuery, AllRunGroupsQueryVariables>(AllRunGroupsDocument, baseOptions);
        }
export type AllRunGroupsQueryHookResult = ReturnType<typeof useAllRunGroupsQuery>;
export type AllRunGroupsLazyQueryHookResult = ReturnType<typeof useAllRunGroupsLazyQuery>;
export type AllRunGroupsQueryResult = ApolloReactCommon.QueryResult<AllRunGroupsQuery, AllRunGroupsQueryVariables>;
export const MoveRunToGroupDocument = gql`
    mutation MoveRunToGroup($entityName: String!, $groupName: String!, $projectName: String!, $runId: String!) {
  upsertBucket(
    input: {entityName: $entityName, groupName: $groupName, id: $runId, modelName: $projectName}
  ) {
    bucket {
      id
      group
    }
  }
}
    `;
export type MoveRunToGroupMutationFn = ApolloReactCommon.MutationFunction<MoveRunToGroupMutation, MoveRunToGroupMutationVariables>;
export type MoveRunToGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MoveRunToGroupMutation, MoveRunToGroupMutationVariables>, 'mutation'>;

    export const MoveRunToGroupComponent = (props: MoveRunToGroupComponentProps) => (
      <ApolloReactComponents.Mutation<MoveRunToGroupMutation, MoveRunToGroupMutationVariables> mutation={MoveRunToGroupDocument} {...props} />
    );
    
export type MoveRunToGroupProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<MoveRunToGroupMutation, MoveRunToGroupMutationVariables>
    } & TChildProps;
export function withMoveRunToGroup<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MoveRunToGroupMutation,
  MoveRunToGroupMutationVariables,
  MoveRunToGroupProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, MoveRunToGroupMutation, MoveRunToGroupMutationVariables, MoveRunToGroupProps<TChildProps, TDataName>>(MoveRunToGroupDocument, {
      alias: 'moveRunToGroup',
      ...operationOptions
    });
};

/**
 * __useMoveRunToGroupMutation__
 *
 * To run a mutation, you first call `useMoveRunToGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveRunToGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveRunToGroupMutation, { data, loading, error }] = useMoveRunToGroupMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      groupName: // value for 'groupName'
 *      projectName: // value for 'projectName'
 *      runId: // value for 'runId'
 *   },
 * });
 */
export function useMoveRunToGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MoveRunToGroupMutation, MoveRunToGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<MoveRunToGroupMutation, MoveRunToGroupMutationVariables>(MoveRunToGroupDocument, baseOptions);
      }
export type MoveRunToGroupMutationHookResult = ReturnType<typeof useMoveRunToGroupMutation>;
export type MoveRunToGroupMutationResult = ApolloReactCommon.MutationResult<MoveRunToGroupMutation>;
export type MoveRunToGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<MoveRunToGroupMutation, MoveRunToGroupMutationVariables>;
export const RunValueSuggestionsPaginatedDocument = gql`
    query RunValueSuggestionsPaginated($projectName: String!, $entityName: String, $keyPath: String!, $filters: JSONString, $valuePattern: String, $before: String, $after: String, $first: Int, $last: Int) {
  project(name: $projectName, entityName: $entityName) {
    id
    runFieldValues(
      keyPath: $keyPath
      filters: $filters
      valuePattern: $valuePattern
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          value
        }
      }
    }
  }
}
    `;
export type RunValueSuggestionsPaginatedComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunValueSuggestionsPaginatedQuery, RunValueSuggestionsPaginatedQueryVariables>, 'query'> & ({ variables: RunValueSuggestionsPaginatedQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunValueSuggestionsPaginatedComponent = (props: RunValueSuggestionsPaginatedComponentProps) => (
      <ApolloReactComponents.Query<RunValueSuggestionsPaginatedQuery, RunValueSuggestionsPaginatedQueryVariables> query={RunValueSuggestionsPaginatedDocument} {...props} />
    );
    
export type RunValueSuggestionsPaginatedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunValueSuggestionsPaginatedQuery, RunValueSuggestionsPaginatedQueryVariables>
    } & TChildProps;
export function withRunValueSuggestionsPaginated<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunValueSuggestionsPaginatedQuery,
  RunValueSuggestionsPaginatedQueryVariables,
  RunValueSuggestionsPaginatedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunValueSuggestionsPaginatedQuery, RunValueSuggestionsPaginatedQueryVariables, RunValueSuggestionsPaginatedProps<TChildProps, TDataName>>(RunValueSuggestionsPaginatedDocument, {
      alias: 'runValueSuggestionsPaginated',
      ...operationOptions
    });
};

/**
 * __useRunValueSuggestionsPaginatedQuery__
 *
 * To run a query within a React component, call `useRunValueSuggestionsPaginatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunValueSuggestionsPaginatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunValueSuggestionsPaginatedQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      keyPath: // value for 'keyPath'
 *      filters: // value for 'filters'
 *      valuePattern: // value for 'valuePattern'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useRunValueSuggestionsPaginatedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunValueSuggestionsPaginatedQuery, RunValueSuggestionsPaginatedQueryVariables>) {
        return ApolloReactHooks.useQuery<RunValueSuggestionsPaginatedQuery, RunValueSuggestionsPaginatedQueryVariables>(RunValueSuggestionsPaginatedDocument, baseOptions);
      }
export function useRunValueSuggestionsPaginatedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunValueSuggestionsPaginatedQuery, RunValueSuggestionsPaginatedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunValueSuggestionsPaginatedQuery, RunValueSuggestionsPaginatedQueryVariables>(RunValueSuggestionsPaginatedDocument, baseOptions);
        }
export type RunValueSuggestionsPaginatedQueryHookResult = ReturnType<typeof useRunValueSuggestionsPaginatedQuery>;
export type RunValueSuggestionsPaginatedLazyQueryHookResult = ReturnType<typeof useRunValueSuggestionsPaginatedLazyQuery>;
export type RunValueSuggestionsPaginatedQueryResult = ApolloReactCommon.QueryResult<RunValueSuggestionsPaginatedQuery, RunValueSuggestionsPaginatedQueryVariables>;
export const AccountSelectorContextProviderEntityDocument = gql`
    query AccountSelectorContextProviderEntity($teamName: String!) {
  entity(name: $teamName) {
    id
    isTeam
    organization {
      id
      name
    }
    members {
      id
    }
  }
}
    `;
export type AccountSelectorContextProviderEntityComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AccountSelectorContextProviderEntityQuery, AccountSelectorContextProviderEntityQueryVariables>, 'query'> & ({ variables: AccountSelectorContextProviderEntityQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AccountSelectorContextProviderEntityComponent = (props: AccountSelectorContextProviderEntityComponentProps) => (
      <ApolloReactComponents.Query<AccountSelectorContextProviderEntityQuery, AccountSelectorContextProviderEntityQueryVariables> query={AccountSelectorContextProviderEntityDocument} {...props} />
    );
    
export type AccountSelectorContextProviderEntityProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AccountSelectorContextProviderEntityQuery, AccountSelectorContextProviderEntityQueryVariables>
    } & TChildProps;
export function withAccountSelectorContextProviderEntity<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AccountSelectorContextProviderEntityQuery,
  AccountSelectorContextProviderEntityQueryVariables,
  AccountSelectorContextProviderEntityProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AccountSelectorContextProviderEntityQuery, AccountSelectorContextProviderEntityQueryVariables, AccountSelectorContextProviderEntityProps<TChildProps, TDataName>>(AccountSelectorContextProviderEntityDocument, {
      alias: 'accountSelectorContextProviderEntity',
      ...operationOptions
    });
};

/**
 * __useAccountSelectorContextProviderEntityQuery__
 *
 * To run a query within a React component, call `useAccountSelectorContextProviderEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountSelectorContextProviderEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountSelectorContextProviderEntityQuery({
 *   variables: {
 *      teamName: // value for 'teamName'
 *   },
 * });
 */
export function useAccountSelectorContextProviderEntityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountSelectorContextProviderEntityQuery, AccountSelectorContextProviderEntityQueryVariables>) {
        return ApolloReactHooks.useQuery<AccountSelectorContextProviderEntityQuery, AccountSelectorContextProviderEntityQueryVariables>(AccountSelectorContextProviderEntityDocument, baseOptions);
      }
export function useAccountSelectorContextProviderEntityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountSelectorContextProviderEntityQuery, AccountSelectorContextProviderEntityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AccountSelectorContextProviderEntityQuery, AccountSelectorContextProviderEntityQueryVariables>(AccountSelectorContextProviderEntityDocument, baseOptions);
        }
export type AccountSelectorContextProviderEntityQueryHookResult = ReturnType<typeof useAccountSelectorContextProviderEntityQuery>;
export type AccountSelectorContextProviderEntityLazyQueryHookResult = ReturnType<typeof useAccountSelectorContextProviderEntityLazyQuery>;
export type AccountSelectorContextProviderEntityQueryResult = ApolloReactCommon.QueryResult<AccountSelectorContextProviderEntityQuery, AccountSelectorContextProviderEntityQueryVariables>;
export const ProfileMenuOrgRoleInfoDocument = gql`
    query ProfileMenuOrgRoleInfo($orgName: String!) {
  organization(name: $orgName) {
    id
    members {
      id
      role
      username
    }
  }
}
    `;
export type ProfileMenuOrgRoleInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProfileMenuOrgRoleInfoQuery, ProfileMenuOrgRoleInfoQueryVariables>, 'query'> & ({ variables: ProfileMenuOrgRoleInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProfileMenuOrgRoleInfoComponent = (props: ProfileMenuOrgRoleInfoComponentProps) => (
      <ApolloReactComponents.Query<ProfileMenuOrgRoleInfoQuery, ProfileMenuOrgRoleInfoQueryVariables> query={ProfileMenuOrgRoleInfoDocument} {...props} />
    );
    
export type ProfileMenuOrgRoleInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProfileMenuOrgRoleInfoQuery, ProfileMenuOrgRoleInfoQueryVariables>
    } & TChildProps;
export function withProfileMenuOrgRoleInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProfileMenuOrgRoleInfoQuery,
  ProfileMenuOrgRoleInfoQueryVariables,
  ProfileMenuOrgRoleInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProfileMenuOrgRoleInfoQuery, ProfileMenuOrgRoleInfoQueryVariables, ProfileMenuOrgRoleInfoProps<TChildProps, TDataName>>(ProfileMenuOrgRoleInfoDocument, {
      alias: 'profileMenuOrgRoleInfo',
      ...operationOptions
    });
};

/**
 * __useProfileMenuOrgRoleInfoQuery__
 *
 * To run a query within a React component, call `useProfileMenuOrgRoleInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfileMenuOrgRoleInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfileMenuOrgRoleInfoQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useProfileMenuOrgRoleInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProfileMenuOrgRoleInfoQuery, ProfileMenuOrgRoleInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<ProfileMenuOrgRoleInfoQuery, ProfileMenuOrgRoleInfoQueryVariables>(ProfileMenuOrgRoleInfoDocument, baseOptions);
      }
export function useProfileMenuOrgRoleInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProfileMenuOrgRoleInfoQuery, ProfileMenuOrgRoleInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProfileMenuOrgRoleInfoQuery, ProfileMenuOrgRoleInfoQueryVariables>(ProfileMenuOrgRoleInfoDocument, baseOptions);
        }
export type ProfileMenuOrgRoleInfoQueryHookResult = ReturnType<typeof useProfileMenuOrgRoleInfoQuery>;
export type ProfileMenuOrgRoleInfoLazyQueryHookResult = ReturnType<typeof useProfileMenuOrgRoleInfoLazyQuery>;
export type ProfileMenuOrgRoleInfoQueryResult = ApolloReactCommon.QueryResult<ProfileMenuOrgRoleInfoQuery, ProfileMenuOrgRoleInfoQueryVariables>;
export const UpdateSubscriptionDocument = gql`
    mutation updateSubscription($entityName: String!, $teamsEnabled: Boolean, $privateProjects: Boolean, $gpuEnabled: DateTime, $hubPreset: String, $repo: String, $disk: String, $redisEnabled: Boolean, $dockerEnabled: Boolean, $image: String) {
  updateSubscription(
    input: {entityName: $entityName, teamsEnabled: $teamsEnabled, privateProjects: $privateProjects, gpuEnabled: $gpuEnabled, hubPreset: $hubPreset, hubSettings: {repo: $repo, disk: $disk, redisEnabled: $redisEnabled, dockerEnabled: $dockerEnabled, image: $image}}
  ) {
    entity {
      id
      name
      available
      photoUrl
      limits
    }
  }
}
    `;
export type UpdateSubscriptionMutationFn = ApolloReactCommon.MutationFunction<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>;
export type UpdateSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>, 'mutation'>;

    export const UpdateSubscriptionComponent = (props: UpdateSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables> mutation={UpdateSubscriptionDocument} {...props} />
    );
    
export type UpdateSubscriptionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>
    } & TChildProps;
export function withUpdateSubscription<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateSubscriptionMutation,
  UpdateSubscriptionMutationVariables,
  UpdateSubscriptionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables, UpdateSubscriptionProps<TChildProps, TDataName>>(UpdateSubscriptionDocument, {
      alias: 'updateSubscription',
      ...operationOptions
    });
};

/**
 * __useUpdateSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSubscriptionMutation, { data, loading, error }] = useUpdateSubscriptionMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      teamsEnabled: // value for 'teamsEnabled'
 *      privateProjects: // value for 'privateProjects'
 *      gpuEnabled: // value for 'gpuEnabled'
 *      hubPreset: // value for 'hubPreset'
 *      repo: // value for 'repo'
 *      disk: // value for 'disk'
 *      redisEnabled: // value for 'redisEnabled'
 *      dockerEnabled: // value for 'dockerEnabled'
 *      image: // value for 'image'
 *   },
 * });
 */
export function useUpdateSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>(UpdateSubscriptionDocument, baseOptions);
      }
export type UpdateSubscriptionMutationHookResult = ReturnType<typeof useUpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationResult = ApolloReactCommon.MutationResult<UpdateSubscriptionMutation>;
export type UpdateSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>;
export const UpdateEntityPhotoDocument = gql`
    mutation updateEntityPhoto($entityName: String!, $photoUrl: String) {
  updateEntity(input: {entity: $entityName, photoUrl: $photoUrl}) {
    entity {
      id
      name
      available
      photoUrl
      limits
    }
  }
}
    `;
export type UpdateEntityPhotoMutationFn = ApolloReactCommon.MutationFunction<UpdateEntityPhotoMutation, UpdateEntityPhotoMutationVariables>;
export type UpdateEntityPhotoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateEntityPhotoMutation, UpdateEntityPhotoMutationVariables>, 'mutation'>;

    export const UpdateEntityPhotoComponent = (props: UpdateEntityPhotoComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateEntityPhotoMutation, UpdateEntityPhotoMutationVariables> mutation={UpdateEntityPhotoDocument} {...props} />
    );
    
export type UpdateEntityPhotoProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateEntityPhotoMutation, UpdateEntityPhotoMutationVariables>
    } & TChildProps;
export function withUpdateEntityPhoto<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateEntityPhotoMutation,
  UpdateEntityPhotoMutationVariables,
  UpdateEntityPhotoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateEntityPhotoMutation, UpdateEntityPhotoMutationVariables, UpdateEntityPhotoProps<TChildProps, TDataName>>(UpdateEntityPhotoDocument, {
      alias: 'updateEntityPhoto',
      ...operationOptions
    });
};

/**
 * __useUpdateEntityPhotoMutation__
 *
 * To run a mutation, you first call `useUpdateEntityPhotoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntityPhotoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntityPhotoMutation, { data, loading, error }] = useUpdateEntityPhotoMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      photoUrl: // value for 'photoUrl'
 *   },
 * });
 */
export function useUpdateEntityPhotoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEntityPhotoMutation, UpdateEntityPhotoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEntityPhotoMutation, UpdateEntityPhotoMutationVariables>(UpdateEntityPhotoDocument, baseOptions);
      }
export type UpdateEntityPhotoMutationHookResult = ReturnType<typeof useUpdateEntityPhotoMutation>;
export type UpdateEntityPhotoMutationResult = ApolloReactCommon.MutationResult<UpdateEntityPhotoMutation>;
export type UpdateEntityPhotoMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEntityPhotoMutation, UpdateEntityPhotoMutationVariables>;
export const TeamQueryDocument = gql`
    query TeamQuery($name: String) {
  entity(name: $name) {
    id
    name
    available
    photoUrl
    limits
    settings {
      openToMatchingOrgEmailDomainUsers
    }
  }
}
    `;
export type TeamQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamQueryQuery, TeamQueryQueryVariables>, 'query'>;

    export const TeamQueryComponent = (props: TeamQueryComponentProps) => (
      <ApolloReactComponents.Query<TeamQueryQuery, TeamQueryQueryVariables> query={TeamQueryDocument} {...props} />
    );
    
export type TeamQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TeamQueryQuery, TeamQueryQueryVariables>
    } & TChildProps;
export function withTeamQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TeamQueryQuery,
  TeamQueryQueryVariables,
  TeamQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TeamQueryQuery, TeamQueryQueryVariables, TeamQueryProps<TChildProps, TDataName>>(TeamQueryDocument, {
      alias: 'teamQuery',
      ...operationOptions
    });
};

/**
 * __useTeamQueryQuery__
 *
 * To run a query within a React component, call `useTeamQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamQueryQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useTeamQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamQueryQuery, TeamQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamQueryQuery, TeamQueryQueryVariables>(TeamQueryDocument, baseOptions);
      }
export function useTeamQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamQueryQuery, TeamQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamQueryQuery, TeamQueryQueryVariables>(TeamQueryDocument, baseOptions);
        }
export type TeamQueryQueryHookResult = ReturnType<typeof useTeamQueryQuery>;
export type TeamQueryLazyQueryHookResult = ReturnType<typeof useTeamQueryLazyQuery>;
export type TeamQueryQueryResult = ApolloReactCommon.QueryResult<TeamQueryQuery, TeamQueryQueryVariables>;
export const UserQueryDocument = gql`
    query UserQuery($userName: String!) {
  user(userName: $userName) {
    id
  }
}
    `;
export type UserQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserQueryQuery, UserQueryQueryVariables>, 'query'> & ({ variables: UserQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserQueryComponent = (props: UserQueryComponentProps) => (
      <ApolloReactComponents.Query<UserQueryQuery, UserQueryQueryVariables> query={UserQueryDocument} {...props} />
    );
    
export type UserQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UserQueryQuery, UserQueryQueryVariables>
    } & TChildProps;
export function withUserQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserQueryQuery,
  UserQueryQueryVariables,
  UserQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, UserQueryQuery, UserQueryQueryVariables, UserQueryProps<TChildProps, TDataName>>(UserQueryDocument, {
      alias: 'userQuery',
      ...operationOptions
    });
};

/**
 * __useUserQueryQuery__
 *
 * To run a query within a React component, call `useUserQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQueryQuery({
 *   variables: {
 *      userName: // value for 'userName'
 *   },
 * });
 */
export function useUserQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserQueryQuery, UserQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<UserQueryQuery, UserQueryQueryVariables>(UserQueryDocument, baseOptions);
      }
export function useUserQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserQueryQuery, UserQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserQueryQuery, UserQueryQueryVariables>(UserQueryDocument, baseOptions);
        }
export type UserQueryQueryHookResult = ReturnType<typeof useUserQueryQuery>;
export type UserQueryLazyQueryHookResult = ReturnType<typeof useUserQueryLazyQuery>;
export type UserQueryQueryResult = ApolloReactCommon.QueryResult<UserQueryQuery, UserQueryQueryVariables>;
export const AllEntitiesDocument = gql`
    query allEntities($first: Int = 2000) {
  entities(first: $first) {
    edges {
      node {
        id
        createdAt
        name
        memberCount
        isTeam
        members {
          admin
          pending
          username
          email
          name
          id
        }
      }
    }
  }
}
    `;
export type AllEntitiesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllEntitiesQuery, AllEntitiesQueryVariables>, 'query'>;

    export const AllEntitiesComponent = (props: AllEntitiesComponentProps) => (
      <ApolloReactComponents.Query<AllEntitiesQuery, AllEntitiesQueryVariables> query={AllEntitiesDocument} {...props} />
    );
    
export type AllEntitiesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AllEntitiesQuery, AllEntitiesQueryVariables>
    } & TChildProps;
export function withAllEntities<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AllEntitiesQuery,
  AllEntitiesQueryVariables,
  AllEntitiesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AllEntitiesQuery, AllEntitiesQueryVariables, AllEntitiesProps<TChildProps, TDataName>>(AllEntitiesDocument, {
      alias: 'allEntities',
      ...operationOptions
    });
};

/**
 * __useAllEntitiesQuery__
 *
 * To run a query within a React component, call `useAllEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllEntitiesQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useAllEntitiesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllEntitiesQuery, AllEntitiesQueryVariables>) {
        return ApolloReactHooks.useQuery<AllEntitiesQuery, AllEntitiesQueryVariables>(AllEntitiesDocument, baseOptions);
      }
export function useAllEntitiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllEntitiesQuery, AllEntitiesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllEntitiesQuery, AllEntitiesQueryVariables>(AllEntitiesDocument, baseOptions);
        }
export type AllEntitiesQueryHookResult = ReturnType<typeof useAllEntitiesQuery>;
export type AllEntitiesLazyQueryHookResult = ReturnType<typeof useAllEntitiesLazyQuery>;
export type AllEntitiesQueryResult = ApolloReactCommon.QueryResult<AllEntitiesQuery, AllEntitiesQueryVariables>;
export const UpdateEntityAdminDocument = gql`
    mutation UpdateEntityAdmin($entityName: String!, $isPaid: Boolean, $rateLimits: RateLimitsInput) {
  updateEntity(
    input: {entity: $entityName, isPaid: $isPaid, rateLimits: $rateLimits}
  ) {
    entity {
      id
      isPaid
      rateLimits {
        graphql
        sdkGraphql
        filestreamCount
        filestreamSize
        sdkGraphqlQuerySeconds
      }
    }
  }
}
    `;
export type UpdateEntityAdminMutationFn = ApolloReactCommon.MutationFunction<UpdateEntityAdminMutation, UpdateEntityAdminMutationVariables>;
export type UpdateEntityAdminComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateEntityAdminMutation, UpdateEntityAdminMutationVariables>, 'mutation'>;

    export const UpdateEntityAdminComponent = (props: UpdateEntityAdminComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateEntityAdminMutation, UpdateEntityAdminMutationVariables> mutation={UpdateEntityAdminDocument} {...props} />
    );
    
export type UpdateEntityAdminProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateEntityAdminMutation, UpdateEntityAdminMutationVariables>
    } & TChildProps;
export function withUpdateEntityAdmin<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateEntityAdminMutation,
  UpdateEntityAdminMutationVariables,
  UpdateEntityAdminProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateEntityAdminMutation, UpdateEntityAdminMutationVariables, UpdateEntityAdminProps<TChildProps, TDataName>>(UpdateEntityAdminDocument, {
      alias: 'updateEntityAdmin',
      ...operationOptions
    });
};

/**
 * __useUpdateEntityAdminMutation__
 *
 * To run a mutation, you first call `useUpdateEntityAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntityAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntityAdminMutation, { data, loading, error }] = useUpdateEntityAdminMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      isPaid: // value for 'isPaid'
 *      rateLimits: // value for 'rateLimits'
 *   },
 * });
 */
export function useUpdateEntityAdminMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEntityAdminMutation, UpdateEntityAdminMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEntityAdminMutation, UpdateEntityAdminMutationVariables>(UpdateEntityAdminDocument, baseOptions);
      }
export type UpdateEntityAdminMutationHookResult = ReturnType<typeof useUpdateEntityAdminMutation>;
export type UpdateEntityAdminMutationResult = ApolloReactCommon.MutationResult<UpdateEntityAdminMutation>;
export type UpdateEntityAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEntityAdminMutation, UpdateEntityAdminMutationVariables>;
export const UpdateProjectAdminDocument = gql`
    mutation UpdateProjectAdmin($id: String!, $rateLimits: RateLimitsInput) {
  upsertModel(input: {id: $id, rateLimits: $rateLimits}) {
    project {
      id
      rateLimits {
        filestreamCount
        filestreamSize
        sdkGraphqlQuerySeconds
      }
    }
  }
}
    `;
export type UpdateProjectAdminMutationFn = ApolloReactCommon.MutationFunction<UpdateProjectAdminMutation, UpdateProjectAdminMutationVariables>;
export type UpdateProjectAdminComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateProjectAdminMutation, UpdateProjectAdminMutationVariables>, 'mutation'>;

    export const UpdateProjectAdminComponent = (props: UpdateProjectAdminComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateProjectAdminMutation, UpdateProjectAdminMutationVariables> mutation={UpdateProjectAdminDocument} {...props} />
    );
    
export type UpdateProjectAdminProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateProjectAdminMutation, UpdateProjectAdminMutationVariables>
    } & TChildProps;
export function withUpdateProjectAdmin<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateProjectAdminMutation,
  UpdateProjectAdminMutationVariables,
  UpdateProjectAdminProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateProjectAdminMutation, UpdateProjectAdminMutationVariables, UpdateProjectAdminProps<TChildProps, TDataName>>(UpdateProjectAdminDocument, {
      alias: 'updateProjectAdmin',
      ...operationOptions
    });
};

/**
 * __useUpdateProjectAdminMutation__
 *
 * To run a mutation, you first call `useUpdateProjectAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProjectAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProjectAdminMutation, { data, loading, error }] = useUpdateProjectAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *      rateLimits: // value for 'rateLimits'
 *   },
 * });
 */
export function useUpdateProjectAdminMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateProjectAdminMutation, UpdateProjectAdminMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateProjectAdminMutation, UpdateProjectAdminMutationVariables>(UpdateProjectAdminDocument, baseOptions);
      }
export type UpdateProjectAdminMutationHookResult = ReturnType<typeof useUpdateProjectAdminMutation>;
export type UpdateProjectAdminMutationResult = ApolloReactCommon.MutationResult<UpdateProjectAdminMutation>;
export type UpdateProjectAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateProjectAdminMutation, UpdateProjectAdminMutationVariables>;
export const ProjectRateLimitsDocument = gql`
    query ProjectRateLimits($projectName: String!, $entityName: String) {
  project(name: $projectName, entityName: $entityName) {
    id
    rateLimits {
      filestreamCount
      filestreamSize
      sdkGraphqlQuerySeconds
    }
  }
}
    `;
export type ProjectRateLimitsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectRateLimitsQuery, ProjectRateLimitsQueryVariables>, 'query'> & ({ variables: ProjectRateLimitsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectRateLimitsComponent = (props: ProjectRateLimitsComponentProps) => (
      <ApolloReactComponents.Query<ProjectRateLimitsQuery, ProjectRateLimitsQueryVariables> query={ProjectRateLimitsDocument} {...props} />
    );
    
export type ProjectRateLimitsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProjectRateLimitsQuery, ProjectRateLimitsQueryVariables>
    } & TChildProps;
export function withProjectRateLimits<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProjectRateLimitsQuery,
  ProjectRateLimitsQueryVariables,
  ProjectRateLimitsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProjectRateLimitsQuery, ProjectRateLimitsQueryVariables, ProjectRateLimitsProps<TChildProps, TDataName>>(ProjectRateLimitsDocument, {
      alias: 'projectRateLimits',
      ...operationOptions
    });
};

/**
 * __useProjectRateLimitsQuery__
 *
 * To run a query within a React component, call `useProjectRateLimitsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectRateLimitsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectRateLimitsQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useProjectRateLimitsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectRateLimitsQuery, ProjectRateLimitsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectRateLimitsQuery, ProjectRateLimitsQueryVariables>(ProjectRateLimitsDocument, baseOptions);
      }
export function useProjectRateLimitsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectRateLimitsQuery, ProjectRateLimitsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectRateLimitsQuery, ProjectRateLimitsQueryVariables>(ProjectRateLimitsDocument, baseOptions);
        }
export type ProjectRateLimitsQueryHookResult = ReturnType<typeof useProjectRateLimitsQuery>;
export type ProjectRateLimitsLazyQueryHookResult = ReturnType<typeof useProjectRateLimitsLazyQuery>;
export type ProjectRateLimitsQueryResult = ApolloReactCommon.QueryResult<ProjectRateLimitsQuery, ProjectRateLimitsQueryVariables>;
export const TriggerParquetExportDocument = gql`
    mutation triggerParquetExport($runId: ID!) {
  triggerParquetExport(input: {runId: $runId}) {
    task {
      id
    }
  }
}
    `;
export type TriggerParquetExportMutationFn = ApolloReactCommon.MutationFunction<TriggerParquetExportMutation, TriggerParquetExportMutationVariables>;
export type TriggerParquetExportComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TriggerParquetExportMutation, TriggerParquetExportMutationVariables>, 'mutation'>;

    export const TriggerParquetExportComponent = (props: TriggerParquetExportComponentProps) => (
      <ApolloReactComponents.Mutation<TriggerParquetExportMutation, TriggerParquetExportMutationVariables> mutation={TriggerParquetExportDocument} {...props} />
    );
    
export type TriggerParquetExportProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<TriggerParquetExportMutation, TriggerParquetExportMutationVariables>
    } & TChildProps;
export function withTriggerParquetExport<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TriggerParquetExportMutation,
  TriggerParquetExportMutationVariables,
  TriggerParquetExportProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, TriggerParquetExportMutation, TriggerParquetExportMutationVariables, TriggerParquetExportProps<TChildProps, TDataName>>(TriggerParquetExportDocument, {
      alias: 'triggerParquetExport',
      ...operationOptions
    });
};

/**
 * __useTriggerParquetExportMutation__
 *
 * To run a mutation, you first call `useTriggerParquetExportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTriggerParquetExportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [triggerParquetExportMutation, { data, loading, error }] = useTriggerParquetExportMutation({
 *   variables: {
 *      runId: // value for 'runId'
 *   },
 * });
 */
export function useTriggerParquetExportMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TriggerParquetExportMutation, TriggerParquetExportMutationVariables>) {
        return ApolloReactHooks.useMutation<TriggerParquetExportMutation, TriggerParquetExportMutationVariables>(TriggerParquetExportDocument, baseOptions);
      }
export type TriggerParquetExportMutationHookResult = ReturnType<typeof useTriggerParquetExportMutation>;
export type TriggerParquetExportMutationResult = ApolloReactCommon.MutationResult<TriggerParquetExportMutation>;
export type TriggerParquetExportMutationOptions = ApolloReactCommon.BaseMutationOptions<TriggerParquetExportMutation, TriggerParquetExportMutationVariables>;
export const CreateFinishedRunAlertDocument = gql`
    mutation CreateFinishedRunAlert($entityName: String!) {
  createFinishedRunAlert(input: {entityName: $entityName}) {
    alert {
      id
      condition {
        __typename
        ... on FinishedRunCondition {
          success
        }
      }
      subscriptions {
        id
      }
    }
  }
}
    `;
export type CreateFinishedRunAlertMutationFn = ApolloReactCommon.MutationFunction<CreateFinishedRunAlertMutation, CreateFinishedRunAlertMutationVariables>;
export type CreateFinishedRunAlertComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateFinishedRunAlertMutation, CreateFinishedRunAlertMutationVariables>, 'mutation'>;

    export const CreateFinishedRunAlertComponent = (props: CreateFinishedRunAlertComponentProps) => (
      <ApolloReactComponents.Mutation<CreateFinishedRunAlertMutation, CreateFinishedRunAlertMutationVariables> mutation={CreateFinishedRunAlertDocument} {...props} />
    );
    
export type CreateFinishedRunAlertProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateFinishedRunAlertMutation, CreateFinishedRunAlertMutationVariables>
    } & TChildProps;
export function withCreateFinishedRunAlert<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateFinishedRunAlertMutation,
  CreateFinishedRunAlertMutationVariables,
  CreateFinishedRunAlertProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateFinishedRunAlertMutation, CreateFinishedRunAlertMutationVariables, CreateFinishedRunAlertProps<TChildProps, TDataName>>(CreateFinishedRunAlertDocument, {
      alias: 'createFinishedRunAlert',
      ...operationOptions
    });
};

/**
 * __useCreateFinishedRunAlertMutation__
 *
 * To run a mutation, you first call `useCreateFinishedRunAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFinishedRunAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFinishedRunAlertMutation, { data, loading, error }] = useCreateFinishedRunAlertMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useCreateFinishedRunAlertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFinishedRunAlertMutation, CreateFinishedRunAlertMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFinishedRunAlertMutation, CreateFinishedRunAlertMutationVariables>(CreateFinishedRunAlertDocument, baseOptions);
      }
export type CreateFinishedRunAlertMutationHookResult = ReturnType<typeof useCreateFinishedRunAlertMutation>;
export type CreateFinishedRunAlertMutationResult = ApolloReactCommon.MutationResult<CreateFinishedRunAlertMutation>;
export type CreateFinishedRunAlertMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFinishedRunAlertMutation, CreateFinishedRunAlertMutationVariables>;
export const CreateStoppedRunAlertDocument = gql`
    mutation CreateStoppedRunAlert($entityName: String!, $minimumRunDuration: Duration!) {
  createStoppedRunAlert(
    input: {entityName: $entityName, minimumRunDuration: $minimumRunDuration}
  ) {
    alert {
      id
      condition {
        __typename
        ... on StoppedRunCondition {
          minimumRunDuration
        }
      }
      subscriptions {
        id
      }
    }
  }
}
    `;
export type CreateStoppedRunAlertMutationFn = ApolloReactCommon.MutationFunction<CreateStoppedRunAlertMutation, CreateStoppedRunAlertMutationVariables>;
export type CreateStoppedRunAlertComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateStoppedRunAlertMutation, CreateStoppedRunAlertMutationVariables>, 'mutation'>;

    export const CreateStoppedRunAlertComponent = (props: CreateStoppedRunAlertComponentProps) => (
      <ApolloReactComponents.Mutation<CreateStoppedRunAlertMutation, CreateStoppedRunAlertMutationVariables> mutation={CreateStoppedRunAlertDocument} {...props} />
    );
    
export type CreateStoppedRunAlertProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateStoppedRunAlertMutation, CreateStoppedRunAlertMutationVariables>
    } & TChildProps;
export function withCreateStoppedRunAlert<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateStoppedRunAlertMutation,
  CreateStoppedRunAlertMutationVariables,
  CreateStoppedRunAlertProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateStoppedRunAlertMutation, CreateStoppedRunAlertMutationVariables, CreateStoppedRunAlertProps<TChildProps, TDataName>>(CreateStoppedRunAlertDocument, {
      alias: 'createStoppedRunAlert',
      ...operationOptions
    });
};

/**
 * __useCreateStoppedRunAlertMutation__
 *
 * To run a mutation, you first call `useCreateStoppedRunAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStoppedRunAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStoppedRunAlertMutation, { data, loading, error }] = useCreateStoppedRunAlertMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      minimumRunDuration: // value for 'minimumRunDuration'
 *   },
 * });
 */
export function useCreateStoppedRunAlertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStoppedRunAlertMutation, CreateStoppedRunAlertMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStoppedRunAlertMutation, CreateStoppedRunAlertMutationVariables>(CreateStoppedRunAlertDocument, baseOptions);
      }
export type CreateStoppedRunAlertMutationHookResult = ReturnType<typeof useCreateStoppedRunAlertMutation>;
export type CreateStoppedRunAlertMutationResult = ApolloReactCommon.MutationResult<CreateStoppedRunAlertMutation>;
export type CreateStoppedRunAlertMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStoppedRunAlertMutation, CreateStoppedRunAlertMutationVariables>;
export const UpdateStoppedRunAlertDocument = gql`
    mutation UpdateStoppedRunAlert($id: ID!, $minimumRunDuration: Duration) {
  updateStoppedRunAlert(input: {id: $id, minimumRunDuration: $minimumRunDuration}) {
    alert {
      id
      condition {
        __typename
        ... on StoppedRunCondition {
          minimumRunDuration
        }
      }
      subscriptions {
        id
      }
    }
  }
}
    `;
export type UpdateStoppedRunAlertMutationFn = ApolloReactCommon.MutationFunction<UpdateStoppedRunAlertMutation, UpdateStoppedRunAlertMutationVariables>;
export type UpdateStoppedRunAlertComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateStoppedRunAlertMutation, UpdateStoppedRunAlertMutationVariables>, 'mutation'>;

    export const UpdateStoppedRunAlertComponent = (props: UpdateStoppedRunAlertComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateStoppedRunAlertMutation, UpdateStoppedRunAlertMutationVariables> mutation={UpdateStoppedRunAlertDocument} {...props} />
    );
    
export type UpdateStoppedRunAlertProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateStoppedRunAlertMutation, UpdateStoppedRunAlertMutationVariables>
    } & TChildProps;
export function withUpdateStoppedRunAlert<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateStoppedRunAlertMutation,
  UpdateStoppedRunAlertMutationVariables,
  UpdateStoppedRunAlertProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateStoppedRunAlertMutation, UpdateStoppedRunAlertMutationVariables, UpdateStoppedRunAlertProps<TChildProps, TDataName>>(UpdateStoppedRunAlertDocument, {
      alias: 'updateStoppedRunAlert',
      ...operationOptions
    });
};

/**
 * __useUpdateStoppedRunAlertMutation__
 *
 * To run a mutation, you first call `useUpdateStoppedRunAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoppedRunAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoppedRunAlertMutation, { data, loading, error }] = useUpdateStoppedRunAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *      minimumRunDuration: // value for 'minimumRunDuration'
 *   },
 * });
 */
export function useUpdateStoppedRunAlertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStoppedRunAlertMutation, UpdateStoppedRunAlertMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateStoppedRunAlertMutation, UpdateStoppedRunAlertMutationVariables>(UpdateStoppedRunAlertDocument, baseOptions);
      }
export type UpdateStoppedRunAlertMutationHookResult = ReturnType<typeof useUpdateStoppedRunAlertMutation>;
export type UpdateStoppedRunAlertMutationResult = ApolloReactCommon.MutationResult<UpdateStoppedRunAlertMutation>;
export type UpdateStoppedRunAlertMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStoppedRunAlertMutation, UpdateStoppedRunAlertMutationVariables>;
export const CreateScriptableRunAlertDocument = gql`
    mutation CreateScriptableRunAlert($entityName: String!) {
  createScriptableRunAlert(input: {entityName: $entityName}) {
    alert {
      id
      condition {
        __typename
        ... on ScriptableRunCondition {
          success
        }
      }
      subscriptions {
        id
      }
    }
  }
}
    `;
export type CreateScriptableRunAlertMutationFn = ApolloReactCommon.MutationFunction<CreateScriptableRunAlertMutation, CreateScriptableRunAlertMutationVariables>;
export type CreateScriptableRunAlertComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateScriptableRunAlertMutation, CreateScriptableRunAlertMutationVariables>, 'mutation'>;

    export const CreateScriptableRunAlertComponent = (props: CreateScriptableRunAlertComponentProps) => (
      <ApolloReactComponents.Mutation<CreateScriptableRunAlertMutation, CreateScriptableRunAlertMutationVariables> mutation={CreateScriptableRunAlertDocument} {...props} />
    );
    
export type CreateScriptableRunAlertProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateScriptableRunAlertMutation, CreateScriptableRunAlertMutationVariables>
    } & TChildProps;
export function withCreateScriptableRunAlert<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateScriptableRunAlertMutation,
  CreateScriptableRunAlertMutationVariables,
  CreateScriptableRunAlertProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateScriptableRunAlertMutation, CreateScriptableRunAlertMutationVariables, CreateScriptableRunAlertProps<TChildProps, TDataName>>(CreateScriptableRunAlertDocument, {
      alias: 'createScriptableRunAlert',
      ...operationOptions
    });
};

/**
 * __useCreateScriptableRunAlertMutation__
 *
 * To run a mutation, you first call `useCreateScriptableRunAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateScriptableRunAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createScriptableRunAlertMutation, { data, loading, error }] = useCreateScriptableRunAlertMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useCreateScriptableRunAlertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateScriptableRunAlertMutation, CreateScriptableRunAlertMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateScriptableRunAlertMutation, CreateScriptableRunAlertMutationVariables>(CreateScriptableRunAlertDocument, baseOptions);
      }
export type CreateScriptableRunAlertMutationHookResult = ReturnType<typeof useCreateScriptableRunAlertMutation>;
export type CreateScriptableRunAlertMutationResult = ApolloReactCommon.MutationResult<CreateScriptableRunAlertMutation>;
export type CreateScriptableRunAlertMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateScriptableRunAlertMutation, CreateScriptableRunAlertMutationVariables>;
export const DeleteAlertDocument = gql`
    mutation DeleteAlert($id: ID!) {
  deleteAlert(input: {id: $id}) {
    success
  }
}
    `;
export type DeleteAlertMutationFn = ApolloReactCommon.MutationFunction<DeleteAlertMutation, DeleteAlertMutationVariables>;
export type DeleteAlertComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteAlertMutation, DeleteAlertMutationVariables>, 'mutation'>;

    export const DeleteAlertComponent = (props: DeleteAlertComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteAlertMutation, DeleteAlertMutationVariables> mutation={DeleteAlertDocument} {...props} />
    );
    
export type DeleteAlertProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteAlertMutation, DeleteAlertMutationVariables>
    } & TChildProps;
export function withDeleteAlert<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteAlertMutation,
  DeleteAlertMutationVariables,
  DeleteAlertProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteAlertMutation, DeleteAlertMutationVariables, DeleteAlertProps<TChildProps, TDataName>>(DeleteAlertDocument, {
      alias: 'deleteAlert',
      ...operationOptions
    });
};

/**
 * __useDeleteAlertMutation__
 *
 * To run a mutation, you first call `useDeleteAlertMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAlertMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAlertMutation, { data, loading, error }] = useDeleteAlertMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAlertMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAlertMutation, DeleteAlertMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAlertMutation, DeleteAlertMutationVariables>(DeleteAlertDocument, baseOptions);
      }
export type DeleteAlertMutationHookResult = ReturnType<typeof useDeleteAlertMutation>;
export type DeleteAlertMutationResult = ApolloReactCommon.MutationResult<DeleteAlertMutation>;
export type DeleteAlertMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAlertMutation, DeleteAlertMutationVariables>;
export const CreateSlackChannelSubscriptionDocument = gql`
    mutation CreateSlackChannelSubscription($alertID: ID!, $integrationID: ID!) {
  createSlackChannelSubscription(
    input: {alertID: $alertID, integrationID: $integrationID}
  ) {
    subscription {
      id
    }
  }
}
    `;
export type CreateSlackChannelSubscriptionMutationFn = ApolloReactCommon.MutationFunction<CreateSlackChannelSubscriptionMutation, CreateSlackChannelSubscriptionMutationVariables>;
export type CreateSlackChannelSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateSlackChannelSubscriptionMutation, CreateSlackChannelSubscriptionMutationVariables>, 'mutation'>;

    export const CreateSlackChannelSubscriptionComponent = (props: CreateSlackChannelSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<CreateSlackChannelSubscriptionMutation, CreateSlackChannelSubscriptionMutationVariables> mutation={CreateSlackChannelSubscriptionDocument} {...props} />
    );
    
export type CreateSlackChannelSubscriptionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateSlackChannelSubscriptionMutation, CreateSlackChannelSubscriptionMutationVariables>
    } & TChildProps;
export function withCreateSlackChannelSubscription<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateSlackChannelSubscriptionMutation,
  CreateSlackChannelSubscriptionMutationVariables,
  CreateSlackChannelSubscriptionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateSlackChannelSubscriptionMutation, CreateSlackChannelSubscriptionMutationVariables, CreateSlackChannelSubscriptionProps<TChildProps, TDataName>>(CreateSlackChannelSubscriptionDocument, {
      alias: 'createSlackChannelSubscription',
      ...operationOptions
    });
};

/**
 * __useCreateSlackChannelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSlackChannelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSlackChannelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSlackChannelSubscriptionMutation, { data, loading, error }] = useCreateSlackChannelSubscriptionMutation({
 *   variables: {
 *      alertID: // value for 'alertID'
 *      integrationID: // value for 'integrationID'
 *   },
 * });
 */
export function useCreateSlackChannelSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSlackChannelSubscriptionMutation, CreateSlackChannelSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSlackChannelSubscriptionMutation, CreateSlackChannelSubscriptionMutationVariables>(CreateSlackChannelSubscriptionDocument, baseOptions);
      }
export type CreateSlackChannelSubscriptionMutationHookResult = ReturnType<typeof useCreateSlackChannelSubscriptionMutation>;
export type CreateSlackChannelSubscriptionMutationResult = ApolloReactCommon.MutationResult<CreateSlackChannelSubscriptionMutation>;
export type CreateSlackChannelSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSlackChannelSubscriptionMutation, CreateSlackChannelSubscriptionMutationVariables>;
export const CreateEmailSubscriptionDocument = gql`
    mutation CreateEmailSubscription($alertID: ID!) {
  createEmailSubscription(input: {alertID: $alertID}) {
    subscription {
      id
    }
  }
}
    `;
export type CreateEmailSubscriptionMutationFn = ApolloReactCommon.MutationFunction<CreateEmailSubscriptionMutation, CreateEmailSubscriptionMutationVariables>;
export type CreateEmailSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateEmailSubscriptionMutation, CreateEmailSubscriptionMutationVariables>, 'mutation'>;

    export const CreateEmailSubscriptionComponent = (props: CreateEmailSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<CreateEmailSubscriptionMutation, CreateEmailSubscriptionMutationVariables> mutation={CreateEmailSubscriptionDocument} {...props} />
    );
    
export type CreateEmailSubscriptionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateEmailSubscriptionMutation, CreateEmailSubscriptionMutationVariables>
    } & TChildProps;
export function withCreateEmailSubscription<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateEmailSubscriptionMutation,
  CreateEmailSubscriptionMutationVariables,
  CreateEmailSubscriptionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateEmailSubscriptionMutation, CreateEmailSubscriptionMutationVariables, CreateEmailSubscriptionProps<TChildProps, TDataName>>(CreateEmailSubscriptionDocument, {
      alias: 'createEmailSubscription',
      ...operationOptions
    });
};

/**
 * __useCreateEmailSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateEmailSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEmailSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEmailSubscriptionMutation, { data, loading, error }] = useCreateEmailSubscriptionMutation({
 *   variables: {
 *      alertID: // value for 'alertID'
 *   },
 * });
 */
export function useCreateEmailSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEmailSubscriptionMutation, CreateEmailSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEmailSubscriptionMutation, CreateEmailSubscriptionMutationVariables>(CreateEmailSubscriptionDocument, baseOptions);
      }
export type CreateEmailSubscriptionMutationHookResult = ReturnType<typeof useCreateEmailSubscriptionMutation>;
export type CreateEmailSubscriptionMutationResult = ApolloReactCommon.MutationResult<CreateEmailSubscriptionMutation>;
export type CreateEmailSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEmailSubscriptionMutation, CreateEmailSubscriptionMutationVariables>;
export const CreateViewCommentsAlertSubscriptionDocument = gql`
    mutation CreateViewCommentsAlertSubscription($viewID: ID!) {
  createViewCommentsAlertSubscription(input: {viewID: $viewID}) {
    subscription {
      id
    }
  }
}
    `;
export type CreateViewCommentsAlertSubscriptionMutationFn = ApolloReactCommon.MutationFunction<CreateViewCommentsAlertSubscriptionMutation, CreateViewCommentsAlertSubscriptionMutationVariables>;
export type CreateViewCommentsAlertSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateViewCommentsAlertSubscriptionMutation, CreateViewCommentsAlertSubscriptionMutationVariables>, 'mutation'>;

    export const CreateViewCommentsAlertSubscriptionComponent = (props: CreateViewCommentsAlertSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<CreateViewCommentsAlertSubscriptionMutation, CreateViewCommentsAlertSubscriptionMutationVariables> mutation={CreateViewCommentsAlertSubscriptionDocument} {...props} />
    );
    
export type CreateViewCommentsAlertSubscriptionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateViewCommentsAlertSubscriptionMutation, CreateViewCommentsAlertSubscriptionMutationVariables>
    } & TChildProps;
export function withCreateViewCommentsAlertSubscription<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateViewCommentsAlertSubscriptionMutation,
  CreateViewCommentsAlertSubscriptionMutationVariables,
  CreateViewCommentsAlertSubscriptionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateViewCommentsAlertSubscriptionMutation, CreateViewCommentsAlertSubscriptionMutationVariables, CreateViewCommentsAlertSubscriptionProps<TChildProps, TDataName>>(CreateViewCommentsAlertSubscriptionDocument, {
      alias: 'createViewCommentsAlertSubscription',
      ...operationOptions
    });
};

/**
 * __useCreateViewCommentsAlertSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateViewCommentsAlertSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateViewCommentsAlertSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createViewCommentsAlertSubscriptionMutation, { data, loading, error }] = useCreateViewCommentsAlertSubscriptionMutation({
 *   variables: {
 *      viewID: // value for 'viewID'
 *   },
 * });
 */
export function useCreateViewCommentsAlertSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateViewCommentsAlertSubscriptionMutation, CreateViewCommentsAlertSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateViewCommentsAlertSubscriptionMutation, CreateViewCommentsAlertSubscriptionMutationVariables>(CreateViewCommentsAlertSubscriptionDocument, baseOptions);
      }
export type CreateViewCommentsAlertSubscriptionMutationHookResult = ReturnType<typeof useCreateViewCommentsAlertSubscriptionMutation>;
export type CreateViewCommentsAlertSubscriptionMutationResult = ApolloReactCommon.MutationResult<CreateViewCommentsAlertSubscriptionMutation>;
export type CreateViewCommentsAlertSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateViewCommentsAlertSubscriptionMutation, CreateViewCommentsAlertSubscriptionMutationVariables>;
export const DeleteAlertSubscriptionDocument = gql`
    mutation DeleteAlertSubscription($id: ID!) {
  deleteAlertSubscription(input: {id: $id}) {
    success
  }
}
    `;
export type DeleteAlertSubscriptionMutationFn = ApolloReactCommon.MutationFunction<DeleteAlertSubscriptionMutation, DeleteAlertSubscriptionMutationVariables>;
export type DeleteAlertSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteAlertSubscriptionMutation, DeleteAlertSubscriptionMutationVariables>, 'mutation'>;

    export const DeleteAlertSubscriptionComponent = (props: DeleteAlertSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteAlertSubscriptionMutation, DeleteAlertSubscriptionMutationVariables> mutation={DeleteAlertSubscriptionDocument} {...props} />
    );
    
export type DeleteAlertSubscriptionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteAlertSubscriptionMutation, DeleteAlertSubscriptionMutationVariables>
    } & TChildProps;
export function withDeleteAlertSubscription<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteAlertSubscriptionMutation,
  DeleteAlertSubscriptionMutationVariables,
  DeleteAlertSubscriptionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteAlertSubscriptionMutation, DeleteAlertSubscriptionMutationVariables, DeleteAlertSubscriptionProps<TChildProps, TDataName>>(DeleteAlertSubscriptionDocument, {
      alias: 'deleteAlertSubscription',
      ...operationOptions
    });
};

/**
 * __useDeleteAlertSubscriptionMutation__
 *
 * To run a mutation, you first call `useDeleteAlertSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAlertSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAlertSubscriptionMutation, { data, loading, error }] = useDeleteAlertSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAlertSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAlertSubscriptionMutation, DeleteAlertSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAlertSubscriptionMutation, DeleteAlertSubscriptionMutationVariables>(DeleteAlertSubscriptionDocument, baseOptions);
      }
export type DeleteAlertSubscriptionMutationHookResult = ReturnType<typeof useDeleteAlertSubscriptionMutation>;
export type DeleteAlertSubscriptionMutationResult = ApolloReactCommon.MutationResult<DeleteAlertSubscriptionMutation>;
export type DeleteAlertSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAlertSubscriptionMutation, DeleteAlertSubscriptionMutationVariables>;
export const BenchmarkProjectDocument = gql`
    query BenchmarkProject($name: String!, $entityName: String) {
  project(name: $name, entityName: $entityName) {
    id
    access
    name
    description
    readOnly
    entityName
    views
    gitHubSubmissionRepo
    acceptedBenchmarkRuns: benchmarkRuns(state: "ACCEPTED") {
      ...benchmarkRunsFragment
    }
    rejectedBenchmarkRuns: benchmarkRuns(state: "REJECTED") {
      ...benchmarkRunsFragment
    }
    submittedBenchmarkRuns: benchmarkRuns(state: "SUBMITTED") {
      ...benchmarkRunsFragment
    }
    entity {
      id
      name
      photoUrl
      defaultAccess
      readOnlyAdmin
      privateOnly
      isTeam
    }
  }
}
    ${BenchmarkRunsFragmentFragmentDoc}`;
export type BenchmarkProjectComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BenchmarkProjectQuery, BenchmarkProjectQueryVariables>, 'query'> & ({ variables: BenchmarkProjectQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BenchmarkProjectComponent = (props: BenchmarkProjectComponentProps) => (
      <ApolloReactComponents.Query<BenchmarkProjectQuery, BenchmarkProjectQueryVariables> query={BenchmarkProjectDocument} {...props} />
    );
    
export type BenchmarkProjectProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<BenchmarkProjectQuery, BenchmarkProjectQueryVariables>
    } & TChildProps;
export function withBenchmarkProject<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BenchmarkProjectQuery,
  BenchmarkProjectQueryVariables,
  BenchmarkProjectProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, BenchmarkProjectQuery, BenchmarkProjectQueryVariables, BenchmarkProjectProps<TChildProps, TDataName>>(BenchmarkProjectDocument, {
      alias: 'benchmarkProject',
      ...operationOptions
    });
};

/**
 * __useBenchmarkProjectQuery__
 *
 * To run a query within a React component, call `useBenchmarkProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenchmarkProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenchmarkProjectQuery({
 *   variables: {
 *      name: // value for 'name'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useBenchmarkProjectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BenchmarkProjectQuery, BenchmarkProjectQueryVariables>) {
        return ApolloReactHooks.useQuery<BenchmarkProjectQuery, BenchmarkProjectQueryVariables>(BenchmarkProjectDocument, baseOptions);
      }
export function useBenchmarkProjectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BenchmarkProjectQuery, BenchmarkProjectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BenchmarkProjectQuery, BenchmarkProjectQueryVariables>(BenchmarkProjectDocument, baseOptions);
        }
export type BenchmarkProjectQueryHookResult = ReturnType<typeof useBenchmarkProjectQuery>;
export type BenchmarkProjectLazyQueryHookResult = ReturnType<typeof useBenchmarkProjectLazyQuery>;
export type BenchmarkProjectQueryResult = ApolloReactCommon.QueryResult<BenchmarkProjectQuery, BenchmarkProjectQueryVariables>;
export const SubmitBenchmarkRunDocument = gql`
    mutation SubmitBenchmarkRun($id: ID!, $isCodeHidden: Boolean, $benchmarkEntityName: String, $benchmarkName: String) {
  submitBenchmarkRun(
    input: {id: $id, isCodeHidden: $isCodeHidden, benchmarkEntityName: $benchmarkEntityName, benchmarkName: $benchmarkName}
  ) {
    benchmarkRun {
      id
      results
    }
  }
}
    `;
export type SubmitBenchmarkRunMutationFn = ApolloReactCommon.MutationFunction<SubmitBenchmarkRunMutation, SubmitBenchmarkRunMutationVariables>;
export type SubmitBenchmarkRunComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SubmitBenchmarkRunMutation, SubmitBenchmarkRunMutationVariables>, 'mutation'>;

    export const SubmitBenchmarkRunComponent = (props: SubmitBenchmarkRunComponentProps) => (
      <ApolloReactComponents.Mutation<SubmitBenchmarkRunMutation, SubmitBenchmarkRunMutationVariables> mutation={SubmitBenchmarkRunDocument} {...props} />
    );
    
export type SubmitBenchmarkRunProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<SubmitBenchmarkRunMutation, SubmitBenchmarkRunMutationVariables>
    } & TChildProps;
export function withSubmitBenchmarkRun<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SubmitBenchmarkRunMutation,
  SubmitBenchmarkRunMutationVariables,
  SubmitBenchmarkRunProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, SubmitBenchmarkRunMutation, SubmitBenchmarkRunMutationVariables, SubmitBenchmarkRunProps<TChildProps, TDataName>>(SubmitBenchmarkRunDocument, {
      alias: 'submitBenchmarkRun',
      ...operationOptions
    });
};

/**
 * __useSubmitBenchmarkRunMutation__
 *
 * To run a mutation, you first call `useSubmitBenchmarkRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitBenchmarkRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitBenchmarkRunMutation, { data, loading, error }] = useSubmitBenchmarkRunMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isCodeHidden: // value for 'isCodeHidden'
 *      benchmarkEntityName: // value for 'benchmarkEntityName'
 *      benchmarkName: // value for 'benchmarkName'
 *   },
 * });
 */
export function useSubmitBenchmarkRunMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitBenchmarkRunMutation, SubmitBenchmarkRunMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitBenchmarkRunMutation, SubmitBenchmarkRunMutationVariables>(SubmitBenchmarkRunDocument, baseOptions);
      }
export type SubmitBenchmarkRunMutationHookResult = ReturnType<typeof useSubmitBenchmarkRunMutation>;
export type SubmitBenchmarkRunMutationResult = ApolloReactCommon.MutationResult<SubmitBenchmarkRunMutation>;
export type SubmitBenchmarkRunMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitBenchmarkRunMutation, SubmitBenchmarkRunMutationVariables>;
export const PublishBenchmarkRunDocument = gql`
    mutation PublishBenchmarkRun($id: ID!, $isCodeHidden: Boolean, $benchmarkEntityName: String, $benchmarkName: String) {
  publishBenchmarkRun(
    input: {id: $id, isCodeHidden: $isCodeHidden, benchmarkEntityName: $benchmarkEntityName, benchmarkName: $benchmarkName}
  ) {
    benchmarkRun {
      id
      gitHubSubmissionPR
    }
  }
}
    `;
export type PublishBenchmarkRunMutationFn = ApolloReactCommon.MutationFunction<PublishBenchmarkRunMutation, PublishBenchmarkRunMutationVariables>;
export type PublishBenchmarkRunComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PublishBenchmarkRunMutation, PublishBenchmarkRunMutationVariables>, 'mutation'>;

    export const PublishBenchmarkRunComponent = (props: PublishBenchmarkRunComponentProps) => (
      <ApolloReactComponents.Mutation<PublishBenchmarkRunMutation, PublishBenchmarkRunMutationVariables> mutation={PublishBenchmarkRunDocument} {...props} />
    );
    
export type PublishBenchmarkRunProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PublishBenchmarkRunMutation, PublishBenchmarkRunMutationVariables>
    } & TChildProps;
export function withPublishBenchmarkRun<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PublishBenchmarkRunMutation,
  PublishBenchmarkRunMutationVariables,
  PublishBenchmarkRunProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PublishBenchmarkRunMutation, PublishBenchmarkRunMutationVariables, PublishBenchmarkRunProps<TChildProps, TDataName>>(PublishBenchmarkRunDocument, {
      alias: 'publishBenchmarkRun',
      ...operationOptions
    });
};

/**
 * __usePublishBenchmarkRunMutation__
 *
 * To run a mutation, you first call `usePublishBenchmarkRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePublishBenchmarkRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [publishBenchmarkRunMutation, { data, loading, error }] = usePublishBenchmarkRunMutation({
 *   variables: {
 *      id: // value for 'id'
 *      isCodeHidden: // value for 'isCodeHidden'
 *      benchmarkEntityName: // value for 'benchmarkEntityName'
 *      benchmarkName: // value for 'benchmarkName'
 *   },
 * });
 */
export function usePublishBenchmarkRunMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PublishBenchmarkRunMutation, PublishBenchmarkRunMutationVariables>) {
        return ApolloReactHooks.useMutation<PublishBenchmarkRunMutation, PublishBenchmarkRunMutationVariables>(PublishBenchmarkRunDocument, baseOptions);
      }
export type PublishBenchmarkRunMutationHookResult = ReturnType<typeof usePublishBenchmarkRunMutation>;
export type PublishBenchmarkRunMutationResult = ApolloReactCommon.MutationResult<PublishBenchmarkRunMutation>;
export type PublishBenchmarkRunMutationOptions = ApolloReactCommon.BaseMutationOptions<PublishBenchmarkRunMutation, PublishBenchmarkRunMutationVariables>;
export const UpdateBenchmarkRunDocument = gql`
    mutation UpdateBenchmarkRun($id: ID!, $state: String, $details: JSONString) {
  updateBenchmarkRun(input: {id: $id, state: $state, details: $details}) {
    benchmarkRun {
      id
      state
      details
    }
  }
}
    `;
export type UpdateBenchmarkRunMutationFn = ApolloReactCommon.MutationFunction<UpdateBenchmarkRunMutation, UpdateBenchmarkRunMutationVariables>;
export type UpdateBenchmarkRunComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateBenchmarkRunMutation, UpdateBenchmarkRunMutationVariables>, 'mutation'>;

    export const UpdateBenchmarkRunComponent = (props: UpdateBenchmarkRunComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateBenchmarkRunMutation, UpdateBenchmarkRunMutationVariables> mutation={UpdateBenchmarkRunDocument} {...props} />
    );
    
export type UpdateBenchmarkRunProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateBenchmarkRunMutation, UpdateBenchmarkRunMutationVariables>
    } & TChildProps;
export function withUpdateBenchmarkRun<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateBenchmarkRunMutation,
  UpdateBenchmarkRunMutationVariables,
  UpdateBenchmarkRunProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateBenchmarkRunMutation, UpdateBenchmarkRunMutationVariables, UpdateBenchmarkRunProps<TChildProps, TDataName>>(UpdateBenchmarkRunDocument, {
      alias: 'updateBenchmarkRun',
      ...operationOptions
    });
};

/**
 * __useUpdateBenchmarkRunMutation__
 *
 * To run a mutation, you first call `useUpdateBenchmarkRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBenchmarkRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBenchmarkRunMutation, { data, loading, error }] = useUpdateBenchmarkRunMutation({
 *   variables: {
 *      id: // value for 'id'
 *      state: // value for 'state'
 *      details: // value for 'details'
 *   },
 * });
 */
export function useUpdateBenchmarkRunMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateBenchmarkRunMutation, UpdateBenchmarkRunMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateBenchmarkRunMutation, UpdateBenchmarkRunMutationVariables>(UpdateBenchmarkRunDocument, baseOptions);
      }
export type UpdateBenchmarkRunMutationHookResult = ReturnType<typeof useUpdateBenchmarkRunMutation>;
export type UpdateBenchmarkRunMutationResult = ApolloReactCommon.MutationResult<UpdateBenchmarkRunMutation>;
export type UpdateBenchmarkRunMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateBenchmarkRunMutation, UpdateBenchmarkRunMutationVariables>;
export const CreateReportsPromptDocument = gql`
    query CreateReportsPrompt($entityName: String, $last: Int) {
  viewer(entityName: $entityName) {
    id
    runs(last: $last, order: "-updated_at") {
      edges {
        node {
          id
          createdAt
          project {
            id
            name
            entityName
          }
          updatedAt
        }
      }
    }
  }
}
    `;
export type CreateReportsPromptComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CreateReportsPromptQuery, CreateReportsPromptQueryVariables>, 'query'>;

    export const CreateReportsPromptComponent = (props: CreateReportsPromptComponentProps) => (
      <ApolloReactComponents.Query<CreateReportsPromptQuery, CreateReportsPromptQueryVariables> query={CreateReportsPromptDocument} {...props} />
    );
    
export type CreateReportsPromptProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CreateReportsPromptQuery, CreateReportsPromptQueryVariables>
    } & TChildProps;
export function withCreateReportsPrompt<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateReportsPromptQuery,
  CreateReportsPromptQueryVariables,
  CreateReportsPromptProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CreateReportsPromptQuery, CreateReportsPromptQueryVariables, CreateReportsPromptProps<TChildProps, TDataName>>(CreateReportsPromptDocument, {
      alias: 'createReportsPrompt',
      ...operationOptions
    });
};

/**
 * __useCreateReportsPromptQuery__
 *
 * To run a query within a React component, call `useCreateReportsPromptQuery` and pass it any options that fit your needs.
 * When your component renders, `useCreateReportsPromptQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCreateReportsPromptQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useCreateReportsPromptQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CreateReportsPromptQuery, CreateReportsPromptQueryVariables>) {
        return ApolloReactHooks.useQuery<CreateReportsPromptQuery, CreateReportsPromptQueryVariables>(CreateReportsPromptDocument, baseOptions);
      }
export function useCreateReportsPromptLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CreateReportsPromptQuery, CreateReportsPromptQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CreateReportsPromptQuery, CreateReportsPromptQueryVariables>(CreateReportsPromptDocument, baseOptions);
        }
export type CreateReportsPromptQueryHookResult = ReturnType<typeof useCreateReportsPromptQuery>;
export type CreateReportsPromptLazyQueryHookResult = ReturnType<typeof useCreateReportsPromptLazyQuery>;
export type CreateReportsPromptQueryResult = ApolloReactCommon.QueryResult<CreateReportsPromptQuery, CreateReportsPromptQueryVariables>;
export const CreateCustomChartDocument = gql`
    mutation CreateCustomChart($entity: String!, $name: String!, $displayName: String!, $type: String!, $access: String!, $spec: JSONString!) {
  createCustomChart(
    input: {entity: $entity, name: $name, displayName: $displayName, type: $type, access: $access, spec: $spec}
  ) {
    chart {
      id
      ...CustomChartFragment
    }
  }
}
    ${CustomChartFragmentFragmentDoc}`;
export type CreateCustomChartMutationFn = ApolloReactCommon.MutationFunction<CreateCustomChartMutation, CreateCustomChartMutationVariables>;
export type CreateCustomChartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCustomChartMutation, CreateCustomChartMutationVariables>, 'mutation'>;

    export const CreateCustomChartComponent = (props: CreateCustomChartComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCustomChartMutation, CreateCustomChartMutationVariables> mutation={CreateCustomChartDocument} {...props} />
    );
    
export type CreateCustomChartProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateCustomChartMutation, CreateCustomChartMutationVariables>
    } & TChildProps;
export function withCreateCustomChart<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateCustomChartMutation,
  CreateCustomChartMutationVariables,
  CreateCustomChartProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateCustomChartMutation, CreateCustomChartMutationVariables, CreateCustomChartProps<TChildProps, TDataName>>(CreateCustomChartDocument, {
      alias: 'createCustomChart',
      ...operationOptions
    });
};

/**
 * __useCreateCustomChartMutation__
 *
 * To run a mutation, you first call `useCreateCustomChartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomChartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomChartMutation, { data, loading, error }] = useCreateCustomChartMutation({
 *   variables: {
 *      entity: // value for 'entity'
 *      name: // value for 'name'
 *      displayName: // value for 'displayName'
 *      type: // value for 'type'
 *      access: // value for 'access'
 *      spec: // value for 'spec'
 *   },
 * });
 */
export function useCreateCustomChartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCustomChartMutation, CreateCustomChartMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCustomChartMutation, CreateCustomChartMutationVariables>(CreateCustomChartDocument, baseOptions);
      }
export type CreateCustomChartMutationHookResult = ReturnType<typeof useCreateCustomChartMutation>;
export type CreateCustomChartMutationResult = ApolloReactCommon.MutationResult<CreateCustomChartMutation>;
export type CreateCustomChartMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCustomChartMutation, CreateCustomChartMutationVariables>;
export const UpdateCustomChartDocument = gql`
    mutation UpdateCustomChart($entity: String!, $name: String!, $displayName: String, $type: String, $access: String, $spec: JSONString) {
  updateCustomChart(
    input: {entity: $entity, name: $name, displayName: $displayName, type: $type, access: $access, spec: $spec}
  ) {
    chart {
      id
      ...CustomChartFragment
    }
  }
}
    ${CustomChartFragmentFragmentDoc}`;
export type UpdateCustomChartMutationFn = ApolloReactCommon.MutationFunction<UpdateCustomChartMutation, UpdateCustomChartMutationVariables>;
export type UpdateCustomChartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCustomChartMutation, UpdateCustomChartMutationVariables>, 'mutation'>;

    export const UpdateCustomChartComponent = (props: UpdateCustomChartComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCustomChartMutation, UpdateCustomChartMutationVariables> mutation={UpdateCustomChartDocument} {...props} />
    );
    
export type UpdateCustomChartProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateCustomChartMutation, UpdateCustomChartMutationVariables>
    } & TChildProps;
export function withUpdateCustomChart<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCustomChartMutation,
  UpdateCustomChartMutationVariables,
  UpdateCustomChartProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCustomChartMutation, UpdateCustomChartMutationVariables, UpdateCustomChartProps<TChildProps, TDataName>>(UpdateCustomChartDocument, {
      alias: 'updateCustomChart',
      ...operationOptions
    });
};

/**
 * __useUpdateCustomChartMutation__
 *
 * To run a mutation, you first call `useUpdateCustomChartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomChartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomChartMutation, { data, loading, error }] = useUpdateCustomChartMutation({
 *   variables: {
 *      entity: // value for 'entity'
 *      name: // value for 'name'
 *      displayName: // value for 'displayName'
 *      type: // value for 'type'
 *      access: // value for 'access'
 *      spec: // value for 'spec'
 *   },
 * });
 */
export function useUpdateCustomChartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomChartMutation, UpdateCustomChartMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCustomChartMutation, UpdateCustomChartMutationVariables>(UpdateCustomChartDocument, baseOptions);
      }
export type UpdateCustomChartMutationHookResult = ReturnType<typeof useUpdateCustomChartMutation>;
export type UpdateCustomChartMutationResult = ApolloReactCommon.MutationResult<UpdateCustomChartMutation>;
export type UpdateCustomChartMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCustomChartMutation, UpdateCustomChartMutationVariables>;
export const DeleteCustomChartDocument = gql`
    mutation DeleteCustomChart($entity: String!, $name: String!) {
  deleteCustomChart(input: {entity: $entity, name: $name}) {
    success
  }
}
    `;
export type DeleteCustomChartMutationFn = ApolloReactCommon.MutationFunction<DeleteCustomChartMutation, DeleteCustomChartMutationVariables>;
export type DeleteCustomChartComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteCustomChartMutation, DeleteCustomChartMutationVariables>, 'mutation'>;

    export const DeleteCustomChartComponent = (props: DeleteCustomChartComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteCustomChartMutation, DeleteCustomChartMutationVariables> mutation={DeleteCustomChartDocument} {...props} />
    );
    
export type DeleteCustomChartProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteCustomChartMutation, DeleteCustomChartMutationVariables>
    } & TChildProps;
export function withDeleteCustomChart<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteCustomChartMutation,
  DeleteCustomChartMutationVariables,
  DeleteCustomChartProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteCustomChartMutation, DeleteCustomChartMutationVariables, DeleteCustomChartProps<TChildProps, TDataName>>(DeleteCustomChartDocument, {
      alias: 'deleteCustomChart',
      ...operationOptions
    });
};

/**
 * __useDeleteCustomChartMutation__
 *
 * To run a mutation, you first call `useDeleteCustomChartMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCustomChartMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCustomChartMutation, { data, loading, error }] = useDeleteCustomChartMutation({
 *   variables: {
 *      entity: // value for 'entity'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useDeleteCustomChartMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteCustomChartMutation, DeleteCustomChartMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteCustomChartMutation, DeleteCustomChartMutationVariables>(DeleteCustomChartDocument, baseOptions);
      }
export type DeleteCustomChartMutationHookResult = ReturnType<typeof useDeleteCustomChartMutation>;
export type DeleteCustomChartMutationResult = ApolloReactCommon.MutationResult<DeleteCustomChartMutation>;
export type DeleteCustomChartMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteCustomChartMutation, DeleteCustomChartMutationVariables>;
export const CustomChartsDocument = gql`
    query CustomCharts($entity: String!) {
  customCharts(type: "vega2", entity: $entity) {
    edges {
      node {
        id
        ...CustomChartFragment
      }
    }
  }
}
    ${CustomChartFragmentFragmentDoc}`;
export type CustomChartsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CustomChartsQuery, CustomChartsQueryVariables>, 'query'> & ({ variables: CustomChartsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CustomChartsComponent = (props: CustomChartsComponentProps) => (
      <ApolloReactComponents.Query<CustomChartsQuery, CustomChartsQueryVariables> query={CustomChartsDocument} {...props} />
    );
    
export type CustomChartsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CustomChartsQuery, CustomChartsQueryVariables>
    } & TChildProps;
export function withCustomCharts<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CustomChartsQuery,
  CustomChartsQueryVariables,
  CustomChartsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CustomChartsQuery, CustomChartsQueryVariables, CustomChartsProps<TChildProps, TDataName>>(CustomChartsDocument, {
      alias: 'customCharts',
      ...operationOptions
    });
};

/**
 * __useCustomChartsQuery__
 *
 * To run a query within a React component, call `useCustomChartsQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomChartsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomChartsQuery({
 *   variables: {
 *      entity: // value for 'entity'
 *   },
 * });
 */
export function useCustomChartsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CustomChartsQuery, CustomChartsQueryVariables>) {
        return ApolloReactHooks.useQuery<CustomChartsQuery, CustomChartsQueryVariables>(CustomChartsDocument, baseOptions);
      }
export function useCustomChartsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomChartsQuery, CustomChartsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CustomChartsQuery, CustomChartsQueryVariables>(CustomChartsDocument, baseOptions);
        }
export type CustomChartsQueryHookResult = ReturnType<typeof useCustomChartsQuery>;
export type CustomChartsLazyQueryHookResult = ReturnType<typeof useCustomChartsLazyQuery>;
export type CustomChartsQueryResult = ApolloReactCommon.QueryResult<CustomChartsQuery, CustomChartsQueryVariables>;
export const CustomChartDocument = gql`
    query CustomChart($id: ID!) {
  customChart(id: $id) {
    id
    ...CustomChartFragment
  }
}
    ${CustomChartFragmentFragmentDoc}`;
export type CustomChartComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CustomChartQuery, CustomChartQueryVariables>, 'query'> & ({ variables: CustomChartQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CustomChartComponent = (props: CustomChartComponentProps) => (
      <ApolloReactComponents.Query<CustomChartQuery, CustomChartQueryVariables> query={CustomChartDocument} {...props} />
    );
    
export type CustomChartProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CustomChartQuery, CustomChartQueryVariables>
    } & TChildProps;
export function withCustomChart<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CustomChartQuery,
  CustomChartQueryVariables,
  CustomChartProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CustomChartQuery, CustomChartQueryVariables, CustomChartProps<TChildProps, TDataName>>(CustomChartDocument, {
      alias: 'customChart',
      ...operationOptions
    });
};

/**
 * __useCustomChartQuery__
 *
 * To run a query within a React component, call `useCustomChartQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomChartQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomChartQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCustomChartQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CustomChartQuery, CustomChartQueryVariables>) {
        return ApolloReactHooks.useQuery<CustomChartQuery, CustomChartQueryVariables>(CustomChartDocument, baseOptions);
      }
export function useCustomChartLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomChartQuery, CustomChartQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CustomChartQuery, CustomChartQueryVariables>(CustomChartDocument, baseOptions);
        }
export type CustomChartQueryHookResult = ReturnType<typeof useCustomChartQuery>;
export type CustomChartLazyQueryHookResult = ReturnType<typeof useCustomChartLazyQuery>;
export type CustomChartQueryResult = ApolloReactCommon.QueryResult<CustomChartQuery, CustomChartQueryVariables>;
export const CreateCustomerSubscriptionDocument = gql`
    mutation createCustomerSubscription($planId: ID!, $newOrganizationName: String!, $paymentMethod: String!, $quantity: Int!, $trial: Boolean!, $clientMutationId: String) {
  createCustomerSubscription(
    input: {planId: $planId, newOrganizationName: $newOrganizationName, paymentMethod: $paymentMethod, quantity: $quantity, trial: $trial, clientMutationId: $clientMutationId}
  ) {
    subscription
    organizationId
    clientMutationId
  }
}
    `;
export type CreateCustomerSubscriptionMutationFn = ApolloReactCommon.MutationFunction<CreateCustomerSubscriptionMutation, CreateCustomerSubscriptionMutationVariables>;
export type CreateCustomerSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCustomerSubscriptionMutation, CreateCustomerSubscriptionMutationVariables>, 'mutation'>;

    export const CreateCustomerSubscriptionComponent = (props: CreateCustomerSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCustomerSubscriptionMutation, CreateCustomerSubscriptionMutationVariables> mutation={CreateCustomerSubscriptionDocument} {...props} />
    );
    
export type CreateCustomerSubscriptionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateCustomerSubscriptionMutation, CreateCustomerSubscriptionMutationVariables>
    } & TChildProps;
export function withCreateCustomerSubscription<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateCustomerSubscriptionMutation,
  CreateCustomerSubscriptionMutationVariables,
  CreateCustomerSubscriptionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateCustomerSubscriptionMutation, CreateCustomerSubscriptionMutationVariables, CreateCustomerSubscriptionProps<TChildProps, TDataName>>(CreateCustomerSubscriptionDocument, {
      alias: 'createCustomerSubscription',
      ...operationOptions
    });
};

/**
 * __useCreateCustomerSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateCustomerSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerSubscriptionMutation, { data, loading, error }] = useCreateCustomerSubscriptionMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      newOrganizationName: // value for 'newOrganizationName'
 *      paymentMethod: // value for 'paymentMethod'
 *      quantity: // value for 'quantity'
 *      trial: // value for 'trial'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useCreateCustomerSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCustomerSubscriptionMutation, CreateCustomerSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCustomerSubscriptionMutation, CreateCustomerSubscriptionMutationVariables>(CreateCustomerSubscriptionDocument, baseOptions);
      }
export type CreateCustomerSubscriptionMutationHookResult = ReturnType<typeof useCreateCustomerSubscriptionMutation>;
export type CreateCustomerSubscriptionMutationResult = ApolloReactCommon.MutationResult<CreateCustomerSubscriptionMutation>;
export type CreateCustomerSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCustomerSubscriptionMutation, CreateCustomerSubscriptionMutationVariables>;
export const CreateAcademicOrReverseTrialDocument = gql`
    mutation createAcademicOrReverseTrial($newOrganizationName: String!, $newTeamName: String!, $isAcademic: Boolean!, $clientMutationId: String) {
  createAcademicOrReverseTrial(
    input: {newOrganizationName: $newOrganizationName, newTeamName: $newTeamName, isAcademic: $isAcademic, clientMutationId: $clientMutationId}
  ) {
    clientMutationId
    orgID
  }
}
    `;
export type CreateAcademicOrReverseTrialMutationFn = ApolloReactCommon.MutationFunction<CreateAcademicOrReverseTrialMutation, CreateAcademicOrReverseTrialMutationVariables>;
export type CreateAcademicOrReverseTrialComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateAcademicOrReverseTrialMutation, CreateAcademicOrReverseTrialMutationVariables>, 'mutation'>;

    export const CreateAcademicOrReverseTrialComponent = (props: CreateAcademicOrReverseTrialComponentProps) => (
      <ApolloReactComponents.Mutation<CreateAcademicOrReverseTrialMutation, CreateAcademicOrReverseTrialMutationVariables> mutation={CreateAcademicOrReverseTrialDocument} {...props} />
    );
    
export type CreateAcademicOrReverseTrialProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateAcademicOrReverseTrialMutation, CreateAcademicOrReverseTrialMutationVariables>
    } & TChildProps;
export function withCreateAcademicOrReverseTrial<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateAcademicOrReverseTrialMutation,
  CreateAcademicOrReverseTrialMutationVariables,
  CreateAcademicOrReverseTrialProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateAcademicOrReverseTrialMutation, CreateAcademicOrReverseTrialMutationVariables, CreateAcademicOrReverseTrialProps<TChildProps, TDataName>>(CreateAcademicOrReverseTrialDocument, {
      alias: 'createAcademicOrReverseTrial',
      ...operationOptions
    });
};

/**
 * __useCreateAcademicOrReverseTrialMutation__
 *
 * To run a mutation, you first call `useCreateAcademicOrReverseTrialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAcademicOrReverseTrialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAcademicOrReverseTrialMutation, { data, loading, error }] = useCreateAcademicOrReverseTrialMutation({
 *   variables: {
 *      newOrganizationName: // value for 'newOrganizationName'
 *      newTeamName: // value for 'newTeamName'
 *      isAcademic: // value for 'isAcademic'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useCreateAcademicOrReverseTrialMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAcademicOrReverseTrialMutation, CreateAcademicOrReverseTrialMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAcademicOrReverseTrialMutation, CreateAcademicOrReverseTrialMutationVariables>(CreateAcademicOrReverseTrialDocument, baseOptions);
      }
export type CreateAcademicOrReverseTrialMutationHookResult = ReturnType<typeof useCreateAcademicOrReverseTrialMutation>;
export type CreateAcademicOrReverseTrialMutationResult = ApolloReactCommon.MutationResult<CreateAcademicOrReverseTrialMutation>;
export type CreateAcademicOrReverseTrialMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAcademicOrReverseTrialMutation, CreateAcademicOrReverseTrialMutationVariables>;
export const CreateCustomerTrialDocument = gql`
    mutation createCustomerTrial($planId: ID!, $userName: String!, $newOrganizationName: String!, $quantity: Int!, $trialDays: Int!, $clientMutationId: String) {
  createCustomerTrial(
    input: {planId: $planId, userName: $userName, newOrganizationName: $newOrganizationName, quantity: $quantity, trialDays: $trialDays, clientMutationId: $clientMutationId}
  ) {
    subscription
    clientMutationId
  }
}
    `;
export type CreateCustomerTrialMutationFn = ApolloReactCommon.MutationFunction<CreateCustomerTrialMutation, CreateCustomerTrialMutationVariables>;
export type CreateCustomerTrialComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateCustomerTrialMutation, CreateCustomerTrialMutationVariables>, 'mutation'>;

    export const CreateCustomerTrialComponent = (props: CreateCustomerTrialComponentProps) => (
      <ApolloReactComponents.Mutation<CreateCustomerTrialMutation, CreateCustomerTrialMutationVariables> mutation={CreateCustomerTrialDocument} {...props} />
    );
    
export type CreateCustomerTrialProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateCustomerTrialMutation, CreateCustomerTrialMutationVariables>
    } & TChildProps;
export function withCreateCustomerTrial<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateCustomerTrialMutation,
  CreateCustomerTrialMutationVariables,
  CreateCustomerTrialProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateCustomerTrialMutation, CreateCustomerTrialMutationVariables, CreateCustomerTrialProps<TChildProps, TDataName>>(CreateCustomerTrialDocument, {
      alias: 'createCustomerTrial',
      ...operationOptions
    });
};

/**
 * __useCreateCustomerTrialMutation__
 *
 * To run a mutation, you first call `useCreateCustomerTrialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateCustomerTrialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createCustomerTrialMutation, { data, loading, error }] = useCreateCustomerTrialMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      userName: // value for 'userName'
 *      newOrganizationName: // value for 'newOrganizationName'
 *      quantity: // value for 'quantity'
 *      trialDays: // value for 'trialDays'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useCreateCustomerTrialMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateCustomerTrialMutation, CreateCustomerTrialMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateCustomerTrialMutation, CreateCustomerTrialMutationVariables>(CreateCustomerTrialDocument, baseOptions);
      }
export type CreateCustomerTrialMutationHookResult = ReturnType<typeof useCreateCustomerTrialMutation>;
export type CreateCustomerTrialMutationResult = ApolloReactCommon.MutationResult<CreateCustomerTrialMutation>;
export type CreateCustomerTrialMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateCustomerTrialMutation, CreateCustomerTrialMutationVariables>;
export const CreateUserLedTrialDocument = gql`
    mutation createUserLedTrial($newOrganizationName: String!, $newTeamName: String!, $orgSize: String!, $isAcademic: Boolean!, $justification: String, $allowJoinMatchingEmailDomain: Boolean, $clientMutationId: String) {
  createUserLedTrial(
    input: {newOrganizationName: $newOrganizationName, newTeamName: $newTeamName, orgSize: $orgSize, isAcademic: $isAcademic, justification: $justification, allowJoinMatchingEmailDomain: $allowJoinMatchingEmailDomain, clientMutationId: $clientMutationId}
  ) {
    clientMutationId
    orgID
  }
}
    `;
export type CreateUserLedTrialMutationFn = ApolloReactCommon.MutationFunction<CreateUserLedTrialMutation, CreateUserLedTrialMutationVariables>;
export type CreateUserLedTrialComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateUserLedTrialMutation, CreateUserLedTrialMutationVariables>, 'mutation'>;

    export const CreateUserLedTrialComponent = (props: CreateUserLedTrialComponentProps) => (
      <ApolloReactComponents.Mutation<CreateUserLedTrialMutation, CreateUserLedTrialMutationVariables> mutation={CreateUserLedTrialDocument} {...props} />
    );
    
export type CreateUserLedTrialProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateUserLedTrialMutation, CreateUserLedTrialMutationVariables>
    } & TChildProps;
export function withCreateUserLedTrial<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateUserLedTrialMutation,
  CreateUserLedTrialMutationVariables,
  CreateUserLedTrialProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateUserLedTrialMutation, CreateUserLedTrialMutationVariables, CreateUserLedTrialProps<TChildProps, TDataName>>(CreateUserLedTrialDocument, {
      alias: 'createUserLedTrial',
      ...operationOptions
    });
};

/**
 * __useCreateUserLedTrialMutation__
 *
 * To run a mutation, you first call `useCreateUserLedTrialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserLedTrialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserLedTrialMutation, { data, loading, error }] = useCreateUserLedTrialMutation({
 *   variables: {
 *      newOrganizationName: // value for 'newOrganizationName'
 *      newTeamName: // value for 'newTeamName'
 *      orgSize: // value for 'orgSize'
 *      isAcademic: // value for 'isAcademic'
 *      justification: // value for 'justification'
 *      allowJoinMatchingEmailDomain: // value for 'allowJoinMatchingEmailDomain'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useCreateUserLedTrialMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserLedTrialMutation, CreateUserLedTrialMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserLedTrialMutation, CreateUserLedTrialMutationVariables>(CreateUserLedTrialDocument, baseOptions);
      }
export type CreateUserLedTrialMutationHookResult = ReturnType<typeof useCreateUserLedTrialMutation>;
export type CreateUserLedTrialMutationResult = ApolloReactCommon.MutationResult<CreateUserLedTrialMutation>;
export type CreateUserLedTrialMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserLedTrialMutation, CreateUserLedTrialMutationVariables>;
export const CancelCustomerSubscriptionDocument = gql`
    mutation cancelCustomerSubscription($organizationId: ID!, $clientMutationId: String) {
  cancelCustomerSubscription(
    input: {organizationId: $organizationId, clientMutationId: $clientMutationId}
  ) {
    success
    clientMutationId
  }
}
    `;
export type CancelCustomerSubscriptionMutationFn = ApolloReactCommon.MutationFunction<CancelCustomerSubscriptionMutation, CancelCustomerSubscriptionMutationVariables>;
export type CancelCustomerSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CancelCustomerSubscriptionMutation, CancelCustomerSubscriptionMutationVariables>, 'mutation'>;

    export const CancelCustomerSubscriptionComponent = (props: CancelCustomerSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<CancelCustomerSubscriptionMutation, CancelCustomerSubscriptionMutationVariables> mutation={CancelCustomerSubscriptionDocument} {...props} />
    );
    
export type CancelCustomerSubscriptionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CancelCustomerSubscriptionMutation, CancelCustomerSubscriptionMutationVariables>
    } & TChildProps;
export function withCancelCustomerSubscription<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CancelCustomerSubscriptionMutation,
  CancelCustomerSubscriptionMutationVariables,
  CancelCustomerSubscriptionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CancelCustomerSubscriptionMutation, CancelCustomerSubscriptionMutationVariables, CancelCustomerSubscriptionProps<TChildProps, TDataName>>(CancelCustomerSubscriptionDocument, {
      alias: 'cancelCustomerSubscription',
      ...operationOptions
    });
};

/**
 * __useCancelCustomerSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelCustomerSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelCustomerSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelCustomerSubscriptionMutation, { data, loading, error }] = useCancelCustomerSubscriptionMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useCancelCustomerSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CancelCustomerSubscriptionMutation, CancelCustomerSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CancelCustomerSubscriptionMutation, CancelCustomerSubscriptionMutationVariables>(CancelCustomerSubscriptionDocument, baseOptions);
      }
export type CancelCustomerSubscriptionMutationHookResult = ReturnType<typeof useCancelCustomerSubscriptionMutation>;
export type CancelCustomerSubscriptionMutationResult = ApolloReactCommon.MutationResult<CancelCustomerSubscriptionMutation>;
export type CancelCustomerSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CancelCustomerSubscriptionMutation, CancelCustomerSubscriptionMutationVariables>;
export const UpdateCustomerPaymentMethodDocument = gql`
    mutation updateCustomerPaymentMethod($organizationId: ID!, $paymentMethod: String!, $clientMutationId: String) {
  updateCustomerPaymentMethod(
    input: {organizationId: $organizationId, paymentMethod: $paymentMethod, clientMutationId: $clientMutationId}
  ) {
    success
    clientMutationId
  }
}
    `;
export type UpdateCustomerPaymentMethodMutationFn = ApolloReactCommon.MutationFunction<UpdateCustomerPaymentMethodMutation, UpdateCustomerPaymentMethodMutationVariables>;
export type UpdateCustomerPaymentMethodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCustomerPaymentMethodMutation, UpdateCustomerPaymentMethodMutationVariables>, 'mutation'>;

    export const UpdateCustomerPaymentMethodComponent = (props: UpdateCustomerPaymentMethodComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCustomerPaymentMethodMutation, UpdateCustomerPaymentMethodMutationVariables> mutation={UpdateCustomerPaymentMethodDocument} {...props} />
    );
    
export type UpdateCustomerPaymentMethodProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateCustomerPaymentMethodMutation, UpdateCustomerPaymentMethodMutationVariables>
    } & TChildProps;
export function withUpdateCustomerPaymentMethod<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCustomerPaymentMethodMutation,
  UpdateCustomerPaymentMethodMutationVariables,
  UpdateCustomerPaymentMethodProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCustomerPaymentMethodMutation, UpdateCustomerPaymentMethodMutationVariables, UpdateCustomerPaymentMethodProps<TChildProps, TDataName>>(UpdateCustomerPaymentMethodDocument, {
      alias: 'updateCustomerPaymentMethod',
      ...operationOptions
    });
};

/**
 * __useUpdateCustomerPaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerPaymentMethodMutation, { data, loading, error }] = useUpdateCustomerPaymentMethodMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      paymentMethod: // value for 'paymentMethod'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useUpdateCustomerPaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomerPaymentMethodMutation, UpdateCustomerPaymentMethodMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCustomerPaymentMethodMutation, UpdateCustomerPaymentMethodMutationVariables>(UpdateCustomerPaymentMethodDocument, baseOptions);
      }
export type UpdateCustomerPaymentMethodMutationHookResult = ReturnType<typeof useUpdateCustomerPaymentMethodMutation>;
export type UpdateCustomerPaymentMethodMutationResult = ApolloReactCommon.MutationResult<UpdateCustomerPaymentMethodMutation>;
export type UpdateCustomerPaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCustomerPaymentMethodMutation, UpdateCustomerPaymentMethodMutationVariables>;
export const AttachCustomerPaymentMethodDocument = gql`
    mutation attachCustomerPaymentMethod($organizationId: ID!, $paymentMethod: String!, $clientMutationId: String) {
  attachCustomerPaymentMethod(
    input: {organizationId: $organizationId, paymentMethod: $paymentMethod, clientMutationId: $clientMutationId}
  ) {
    success
    clientMutationId
  }
}
    `;
export type AttachCustomerPaymentMethodMutationFn = ApolloReactCommon.MutationFunction<AttachCustomerPaymentMethodMutation, AttachCustomerPaymentMethodMutationVariables>;
export type AttachCustomerPaymentMethodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AttachCustomerPaymentMethodMutation, AttachCustomerPaymentMethodMutationVariables>, 'mutation'>;

    export const AttachCustomerPaymentMethodComponent = (props: AttachCustomerPaymentMethodComponentProps) => (
      <ApolloReactComponents.Mutation<AttachCustomerPaymentMethodMutation, AttachCustomerPaymentMethodMutationVariables> mutation={AttachCustomerPaymentMethodDocument} {...props} />
    );
    
export type AttachCustomerPaymentMethodProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AttachCustomerPaymentMethodMutation, AttachCustomerPaymentMethodMutationVariables>
    } & TChildProps;
export function withAttachCustomerPaymentMethod<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AttachCustomerPaymentMethodMutation,
  AttachCustomerPaymentMethodMutationVariables,
  AttachCustomerPaymentMethodProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AttachCustomerPaymentMethodMutation, AttachCustomerPaymentMethodMutationVariables, AttachCustomerPaymentMethodProps<TChildProps, TDataName>>(AttachCustomerPaymentMethodDocument, {
      alias: 'attachCustomerPaymentMethod',
      ...operationOptions
    });
};

/**
 * __useAttachCustomerPaymentMethodMutation__
 *
 * To run a mutation, you first call `useAttachCustomerPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAttachCustomerPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [attachCustomerPaymentMethodMutation, { data, loading, error }] = useAttachCustomerPaymentMethodMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      paymentMethod: // value for 'paymentMethod'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useAttachCustomerPaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AttachCustomerPaymentMethodMutation, AttachCustomerPaymentMethodMutationVariables>) {
        return ApolloReactHooks.useMutation<AttachCustomerPaymentMethodMutation, AttachCustomerPaymentMethodMutationVariables>(AttachCustomerPaymentMethodDocument, baseOptions);
      }
export type AttachCustomerPaymentMethodMutationHookResult = ReturnType<typeof useAttachCustomerPaymentMethodMutation>;
export type AttachCustomerPaymentMethodMutationResult = ApolloReactCommon.MutationResult<AttachCustomerPaymentMethodMutation>;
export type AttachCustomerPaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<AttachCustomerPaymentMethodMutation, AttachCustomerPaymentMethodMutationVariables>;
export const UpdateCustomerDefaultPaymentMethodDocument = gql`
    mutation updateCustomerDefaultPaymentMethod($organizationId: ID!, $paymentMethod: String!, $clientMutationId: String) {
  updateCustomerDefaultPaymentMethod(
    input: {organizationId: $organizationId, paymentMethod: $paymentMethod, clientMutationId: $clientMutationId}
  ) {
    success
    clientMutationId
  }
}
    `;
export type UpdateCustomerDefaultPaymentMethodMutationFn = ApolloReactCommon.MutationFunction<UpdateCustomerDefaultPaymentMethodMutation, UpdateCustomerDefaultPaymentMethodMutationVariables>;
export type UpdateCustomerDefaultPaymentMethodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateCustomerDefaultPaymentMethodMutation, UpdateCustomerDefaultPaymentMethodMutationVariables>, 'mutation'>;

    export const UpdateCustomerDefaultPaymentMethodComponent = (props: UpdateCustomerDefaultPaymentMethodComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateCustomerDefaultPaymentMethodMutation, UpdateCustomerDefaultPaymentMethodMutationVariables> mutation={UpdateCustomerDefaultPaymentMethodDocument} {...props} />
    );
    
export type UpdateCustomerDefaultPaymentMethodProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateCustomerDefaultPaymentMethodMutation, UpdateCustomerDefaultPaymentMethodMutationVariables>
    } & TChildProps;
export function withUpdateCustomerDefaultPaymentMethod<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateCustomerDefaultPaymentMethodMutation,
  UpdateCustomerDefaultPaymentMethodMutationVariables,
  UpdateCustomerDefaultPaymentMethodProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateCustomerDefaultPaymentMethodMutation, UpdateCustomerDefaultPaymentMethodMutationVariables, UpdateCustomerDefaultPaymentMethodProps<TChildProps, TDataName>>(UpdateCustomerDefaultPaymentMethodDocument, {
      alias: 'updateCustomerDefaultPaymentMethod',
      ...operationOptions
    });
};

/**
 * __useUpdateCustomerDefaultPaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdateCustomerDefaultPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCustomerDefaultPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCustomerDefaultPaymentMethodMutation, { data, loading, error }] = useUpdateCustomerDefaultPaymentMethodMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      paymentMethod: // value for 'paymentMethod'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useUpdateCustomerDefaultPaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateCustomerDefaultPaymentMethodMutation, UpdateCustomerDefaultPaymentMethodMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateCustomerDefaultPaymentMethodMutation, UpdateCustomerDefaultPaymentMethodMutationVariables>(UpdateCustomerDefaultPaymentMethodDocument, baseOptions);
      }
export type UpdateCustomerDefaultPaymentMethodMutationHookResult = ReturnType<typeof useUpdateCustomerDefaultPaymentMethodMutation>;
export type UpdateCustomerDefaultPaymentMethodMutationResult = ApolloReactCommon.MutationResult<UpdateCustomerDefaultPaymentMethodMutation>;
export type UpdateCustomerDefaultPaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateCustomerDefaultPaymentMethodMutation, UpdateCustomerDefaultPaymentMethodMutationVariables>;
export const DetachCustomerPaymentMethodDocument = gql`
    mutation detachCustomerPaymentMethod($organizationId: ID!, $paymentMethod: String!, $clientMutationId: String) {
  detachCustomerPaymentMethod(
    input: {organizationId: $organizationId, paymentMethod: $paymentMethod, clientMutationId: $clientMutationId}
  ) {
    success
    clientMutationId
  }
}
    `;
export type DetachCustomerPaymentMethodMutationFn = ApolloReactCommon.MutationFunction<DetachCustomerPaymentMethodMutation, DetachCustomerPaymentMethodMutationVariables>;
export type DetachCustomerPaymentMethodComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DetachCustomerPaymentMethodMutation, DetachCustomerPaymentMethodMutationVariables>, 'mutation'>;

    export const DetachCustomerPaymentMethodComponent = (props: DetachCustomerPaymentMethodComponentProps) => (
      <ApolloReactComponents.Mutation<DetachCustomerPaymentMethodMutation, DetachCustomerPaymentMethodMutationVariables> mutation={DetachCustomerPaymentMethodDocument} {...props} />
    );
    
export type DetachCustomerPaymentMethodProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DetachCustomerPaymentMethodMutation, DetachCustomerPaymentMethodMutationVariables>
    } & TChildProps;
export function withDetachCustomerPaymentMethod<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DetachCustomerPaymentMethodMutation,
  DetachCustomerPaymentMethodMutationVariables,
  DetachCustomerPaymentMethodProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DetachCustomerPaymentMethodMutation, DetachCustomerPaymentMethodMutationVariables, DetachCustomerPaymentMethodProps<TChildProps, TDataName>>(DetachCustomerPaymentMethodDocument, {
      alias: 'detachCustomerPaymentMethod',
      ...operationOptions
    });
};

/**
 * __useDetachCustomerPaymentMethodMutation__
 *
 * To run a mutation, you first call `useDetachCustomerPaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDetachCustomerPaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [detachCustomerPaymentMethodMutation, { data, loading, error }] = useDetachCustomerPaymentMethodMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      paymentMethod: // value for 'paymentMethod'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useDetachCustomerPaymentMethodMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DetachCustomerPaymentMethodMutation, DetachCustomerPaymentMethodMutationVariables>) {
        return ApolloReactHooks.useMutation<DetachCustomerPaymentMethodMutation, DetachCustomerPaymentMethodMutationVariables>(DetachCustomerPaymentMethodDocument, baseOptions);
      }
export type DetachCustomerPaymentMethodMutationHookResult = ReturnType<typeof useDetachCustomerPaymentMethodMutation>;
export type DetachCustomerPaymentMethodMutationResult = ApolloReactCommon.MutationResult<DetachCustomerPaymentMethodMutation>;
export type DetachCustomerPaymentMethodMutationOptions = ApolloReactCommon.BaseMutationOptions<DetachCustomerPaymentMethodMutation, DetachCustomerPaymentMethodMutationVariables>;
export const RetryStripeOpenInvoicesByOrganizationDocument = gql`
    mutation retryStripeOpenInvoicesByOrganization($organizationId: ID!) {
  retryStripeOpenInvoicesByOrganization(input: {organizationId: $organizationId}) {
    success
    clientMutationId
  }
}
    `;
export type RetryStripeOpenInvoicesByOrganizationMutationFn = ApolloReactCommon.MutationFunction<RetryStripeOpenInvoicesByOrganizationMutation, RetryStripeOpenInvoicesByOrganizationMutationVariables>;
export type RetryStripeOpenInvoicesByOrganizationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RetryStripeOpenInvoicesByOrganizationMutation, RetryStripeOpenInvoicesByOrganizationMutationVariables>, 'mutation'>;

    export const RetryStripeOpenInvoicesByOrganizationComponent = (props: RetryStripeOpenInvoicesByOrganizationComponentProps) => (
      <ApolloReactComponents.Mutation<RetryStripeOpenInvoicesByOrganizationMutation, RetryStripeOpenInvoicesByOrganizationMutationVariables> mutation={RetryStripeOpenInvoicesByOrganizationDocument} {...props} />
    );
    
export type RetryStripeOpenInvoicesByOrganizationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RetryStripeOpenInvoicesByOrganizationMutation, RetryStripeOpenInvoicesByOrganizationMutationVariables>
    } & TChildProps;
export function withRetryStripeOpenInvoicesByOrganization<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RetryStripeOpenInvoicesByOrganizationMutation,
  RetryStripeOpenInvoicesByOrganizationMutationVariables,
  RetryStripeOpenInvoicesByOrganizationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RetryStripeOpenInvoicesByOrganizationMutation, RetryStripeOpenInvoicesByOrganizationMutationVariables, RetryStripeOpenInvoicesByOrganizationProps<TChildProps, TDataName>>(RetryStripeOpenInvoicesByOrganizationDocument, {
      alias: 'retryStripeOpenInvoicesByOrganization',
      ...operationOptions
    });
};

/**
 * __useRetryStripeOpenInvoicesByOrganizationMutation__
 *
 * To run a mutation, you first call `useRetryStripeOpenInvoicesByOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRetryStripeOpenInvoicesByOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [retryStripeOpenInvoicesByOrganizationMutation, { data, loading, error }] = useRetryStripeOpenInvoicesByOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useRetryStripeOpenInvoicesByOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RetryStripeOpenInvoicesByOrganizationMutation, RetryStripeOpenInvoicesByOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<RetryStripeOpenInvoicesByOrganizationMutation, RetryStripeOpenInvoicesByOrganizationMutationVariables>(RetryStripeOpenInvoicesByOrganizationDocument, baseOptions);
      }
export type RetryStripeOpenInvoicesByOrganizationMutationHookResult = ReturnType<typeof useRetryStripeOpenInvoicesByOrganizationMutation>;
export type RetryStripeOpenInvoicesByOrganizationMutationResult = ApolloReactCommon.MutationResult<RetryStripeOpenInvoicesByOrganizationMutation>;
export type RetryStripeOpenInvoicesByOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<RetryStripeOpenInvoicesByOrganizationMutation, RetryStripeOpenInvoicesByOrganizationMutationVariables>;
export const CustomerSubscriptionInfoDocument = gql`
    query CustomerSubscriptionInfo {
  viewer {
    id
    organizations {
      id
      name
      orgType
      usedSeats
      stripeBillingInfo {
        status
        cancelAtPeriodEnd
        currentPeriodEnd
        invoiceLink
        paymentMethod {
          id
          type
          cardType
          endingIn
        }
      }
      billingUser {
        id
        username
      }
      subscriptions {
        id
        plan {
          id
          name
          displayName
          unitPrice
          billingInterval
          planType
        }
        privileges
        seats
        expiresAt
        subscriptionType
        status
        billingPeriodStart
        billingPeriodEnd
      }
      teams {
        id
        name
        storageBytes
        referenceBytes
        computeHours
      }
      members {
        orgID
        id
        username
        name
        photoUrl
        admin
        teams {
          edges {
            node {
              id
              name
            }
          }
        }
        role
      }
      pendingInvites {
        id
        email
      }
    }
  }
}
    `;
export type CustomerSubscriptionInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CustomerSubscriptionInfoQuery, CustomerSubscriptionInfoQueryVariables>, 'query'>;

    export const CustomerSubscriptionInfoComponent = (props: CustomerSubscriptionInfoComponentProps) => (
      <ApolloReactComponents.Query<CustomerSubscriptionInfoQuery, CustomerSubscriptionInfoQueryVariables> query={CustomerSubscriptionInfoDocument} {...props} />
    );
    
export type CustomerSubscriptionInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CustomerSubscriptionInfoQuery, CustomerSubscriptionInfoQueryVariables>
    } & TChildProps;
export function withCustomerSubscriptionInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CustomerSubscriptionInfoQuery,
  CustomerSubscriptionInfoQueryVariables,
  CustomerSubscriptionInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CustomerSubscriptionInfoQuery, CustomerSubscriptionInfoQueryVariables, CustomerSubscriptionInfoProps<TChildProps, TDataName>>(CustomerSubscriptionInfoDocument, {
      alias: 'customerSubscriptionInfo',
      ...operationOptions
    });
};

/**
 * __useCustomerSubscriptionInfoQuery__
 *
 * To run a query within a React component, call `useCustomerSubscriptionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCustomerSubscriptionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCustomerSubscriptionInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useCustomerSubscriptionInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CustomerSubscriptionInfoQuery, CustomerSubscriptionInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<CustomerSubscriptionInfoQuery, CustomerSubscriptionInfoQueryVariables>(CustomerSubscriptionInfoDocument, baseOptions);
      }
export function useCustomerSubscriptionInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CustomerSubscriptionInfoQuery, CustomerSubscriptionInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CustomerSubscriptionInfoQuery, CustomerSubscriptionInfoQueryVariables>(CustomerSubscriptionInfoDocument, baseOptions);
        }
export type CustomerSubscriptionInfoQueryHookResult = ReturnType<typeof useCustomerSubscriptionInfoQuery>;
export type CustomerSubscriptionInfoLazyQueryHookResult = ReturnType<typeof useCustomerSubscriptionInfoLazyQuery>;
export type CustomerSubscriptionInfoQueryResult = ApolloReactCommon.QueryResult<CustomerSubscriptionInfoQuery, CustomerSubscriptionInfoQueryVariables>;
export const OrganizationSubscriptionInfoDocument = gql`
    query OrganizationSubscriptionInfo($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    name
    orgType
    flags
    usedSeats
    usedViewOnlySeats
    createdAt
    seatAvailability {
      seats
      viewOnlySeats
    }
    stripeBillingInfo {
      status
      cancelAtPeriodEnd
      currentPeriodEnd
      invoiceLink
      paymentMethod {
        id
        type
        cardType
        endingIn
      }
      paymentMetadata {
        shouldUpgradeToTeams
      }
    }
    stripePaymentMethods {
      stripePaymentMethodID
      type
      isDefault
      isFailed
      card {
        last4
        brand
      }
    }
    stripeInvoices {
      number
      created
      total
      status
      currency
      hostedInvoiceURL
      invoicePDF
      subscription {
        status
        organizationSubscriptions {
          id
          plan {
            id
            displayName
          }
        }
      }
    }
    billingUser {
      id
      username
      name
      email
      photoUrl
      stripeCustomerId
    }
    teams {
      id
      name
      memberCount
      photoUrl
    }
    subscriptions {
      id
      plan {
        id
        name
        unitPrice
        billingInterval
        planType
        maxSeats
        displayName
        defaultPrivileges
        stripePrice {
          amount
        }
      }
      privileges
      seats
      expiresAt
      subscriptionType
      status
      nextPlans {
        id
        name
        displayName
        billingInterval
        defaultPrivileges
        stripePrice {
          amount
        }
      }
      availableSeatsToPurchase
      isAutomaticUpgrade
      thresholdCrossedAt
      upgradedAt
      billingPeriodStart
      billingPeriodEnd
    }
    members {
      orgID
      id
      username
      name
      photoUrl
      admin
      email
      user {
        id
        loggedInAt
      }
      teams {
        edges {
          node {
            id
            name
          }
        }
      }
      role
      modelsSeat
    }
    pendingInvites {
      id
      email
      entity {
        id
        name
      }
      role
      createdAt
    }
  }
}
    `;
export type OrganizationSubscriptionInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationSubscriptionInfoQuery, OrganizationSubscriptionInfoQueryVariables>, 'query'> & ({ variables: OrganizationSubscriptionInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationSubscriptionInfoComponent = (props: OrganizationSubscriptionInfoComponentProps) => (
      <ApolloReactComponents.Query<OrganizationSubscriptionInfoQuery, OrganizationSubscriptionInfoQueryVariables> query={OrganizationSubscriptionInfoDocument} {...props} />
    );
    
export type OrganizationSubscriptionInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationSubscriptionInfoQuery, OrganizationSubscriptionInfoQueryVariables>
    } & TChildProps;
export function withOrganizationSubscriptionInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationSubscriptionInfoQuery,
  OrganizationSubscriptionInfoQueryVariables,
  OrganizationSubscriptionInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationSubscriptionInfoQuery, OrganizationSubscriptionInfoQueryVariables, OrganizationSubscriptionInfoProps<TChildProps, TDataName>>(OrganizationSubscriptionInfoDocument, {
      alias: 'organizationSubscriptionInfo',
      ...operationOptions
    });
};

/**
 * __useOrganizationSubscriptionInfoQuery__
 *
 * To run a query within a React component, call `useOrganizationSubscriptionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSubscriptionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSubscriptionInfoQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationSubscriptionInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationSubscriptionInfoQuery, OrganizationSubscriptionInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationSubscriptionInfoQuery, OrganizationSubscriptionInfoQueryVariables>(OrganizationSubscriptionInfoDocument, baseOptions);
      }
export function useOrganizationSubscriptionInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationSubscriptionInfoQuery, OrganizationSubscriptionInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationSubscriptionInfoQuery, OrganizationSubscriptionInfoQueryVariables>(OrganizationSubscriptionInfoDocument, baseOptions);
        }
export type OrganizationSubscriptionInfoQueryHookResult = ReturnType<typeof useOrganizationSubscriptionInfoQuery>;
export type OrganizationSubscriptionInfoLazyQueryHookResult = ReturnType<typeof useOrganizationSubscriptionInfoLazyQuery>;
export type OrganizationSubscriptionInfoQueryResult = ApolloReactCommon.QueryResult<OrganizationSubscriptionInfoQuery, OrganizationSubscriptionInfoQueryVariables>;
export const UpgradeCustomerSubscriptionDocument = gql`
    mutation upgradeCustomerSubscription($planID: ID!, $organizationID: ID!, $paymentMethod: String!, $quantity: Int!, $clientMutationId: String) {
  upgradeCustomerSubscription(
    input: {planID: $planID, organizationID: $organizationID, paymentMethod: $paymentMethod, quantity: $quantity, clientMutationId: $clientMutationId}
  ) {
    subscription
    organizationID
    clientMutationId
  }
}
    `;
export type UpgradeCustomerSubscriptionMutationFn = ApolloReactCommon.MutationFunction<UpgradeCustomerSubscriptionMutation, UpgradeCustomerSubscriptionMutationVariables>;
export type UpgradeCustomerSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpgradeCustomerSubscriptionMutation, UpgradeCustomerSubscriptionMutationVariables>, 'mutation'>;

    export const UpgradeCustomerSubscriptionComponent = (props: UpgradeCustomerSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<UpgradeCustomerSubscriptionMutation, UpgradeCustomerSubscriptionMutationVariables> mutation={UpgradeCustomerSubscriptionDocument} {...props} />
    );
    
export type UpgradeCustomerSubscriptionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpgradeCustomerSubscriptionMutation, UpgradeCustomerSubscriptionMutationVariables>
    } & TChildProps;
export function withUpgradeCustomerSubscription<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpgradeCustomerSubscriptionMutation,
  UpgradeCustomerSubscriptionMutationVariables,
  UpgradeCustomerSubscriptionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpgradeCustomerSubscriptionMutation, UpgradeCustomerSubscriptionMutationVariables, UpgradeCustomerSubscriptionProps<TChildProps, TDataName>>(UpgradeCustomerSubscriptionDocument, {
      alias: 'upgradeCustomerSubscription',
      ...operationOptions
    });
};

/**
 * __useUpgradeCustomerSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpgradeCustomerSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpgradeCustomerSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upgradeCustomerSubscriptionMutation, { data, loading, error }] = useUpgradeCustomerSubscriptionMutation({
 *   variables: {
 *      planID: // value for 'planID'
 *      organizationID: // value for 'organizationID'
 *      paymentMethod: // value for 'paymentMethod'
 *      quantity: // value for 'quantity'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useUpgradeCustomerSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpgradeCustomerSubscriptionMutation, UpgradeCustomerSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpgradeCustomerSubscriptionMutation, UpgradeCustomerSubscriptionMutationVariables>(UpgradeCustomerSubscriptionDocument, baseOptions);
      }
export type UpgradeCustomerSubscriptionMutationHookResult = ReturnType<typeof useUpgradeCustomerSubscriptionMutation>;
export type UpgradeCustomerSubscriptionMutationResult = ApolloReactCommon.MutationResult<UpgradeCustomerSubscriptionMutation>;
export type UpgradeCustomerSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpgradeCustomerSubscriptionMutation, UpgradeCustomerSubscriptionMutationVariables>;
export const UpgradeCustomerToProPlanDocument = gql`
    mutation upgradeCustomerToProPlan($planID: ID!, $organizationID: ID!, $paymentMethod: String!, $modelsSeats: Int!, $weaveIngestionTier: WeaveIngestionLimitInMb!, $clientMutationId: String) {
  upgradeCustomerToProPlan(
    input: {planID: $planID, organizationID: $organizationID, paymentMethod: $paymentMethod, modelsSeats: $modelsSeats, weaveIngestionTier: $weaveIngestionTier, clientMutationId: $clientMutationId}
  ) {
    subscriptionUsage
    subscriptionCommit
    organizationID
    clientMutationId
  }
}
    `;
export type UpgradeCustomerToProPlanMutationFn = ApolloReactCommon.MutationFunction<UpgradeCustomerToProPlanMutation, UpgradeCustomerToProPlanMutationVariables>;
export type UpgradeCustomerToProPlanComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpgradeCustomerToProPlanMutation, UpgradeCustomerToProPlanMutationVariables>, 'mutation'>;

    export const UpgradeCustomerToProPlanComponent = (props: UpgradeCustomerToProPlanComponentProps) => (
      <ApolloReactComponents.Mutation<UpgradeCustomerToProPlanMutation, UpgradeCustomerToProPlanMutationVariables> mutation={UpgradeCustomerToProPlanDocument} {...props} />
    );
    
export type UpgradeCustomerToProPlanProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpgradeCustomerToProPlanMutation, UpgradeCustomerToProPlanMutationVariables>
    } & TChildProps;
export function withUpgradeCustomerToProPlan<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpgradeCustomerToProPlanMutation,
  UpgradeCustomerToProPlanMutationVariables,
  UpgradeCustomerToProPlanProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpgradeCustomerToProPlanMutation, UpgradeCustomerToProPlanMutationVariables, UpgradeCustomerToProPlanProps<TChildProps, TDataName>>(UpgradeCustomerToProPlanDocument, {
      alias: 'upgradeCustomerToProPlan',
      ...operationOptions
    });
};

/**
 * __useUpgradeCustomerToProPlanMutation__
 *
 * To run a mutation, you first call `useUpgradeCustomerToProPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpgradeCustomerToProPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upgradeCustomerToProPlanMutation, { data, loading, error }] = useUpgradeCustomerToProPlanMutation({
 *   variables: {
 *      planID: // value for 'planID'
 *      organizationID: // value for 'organizationID'
 *      paymentMethod: // value for 'paymentMethod'
 *      modelsSeats: // value for 'modelsSeats'
 *      weaveIngestionTier: // value for 'weaveIngestionTier'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useUpgradeCustomerToProPlanMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpgradeCustomerToProPlanMutation, UpgradeCustomerToProPlanMutationVariables>) {
        return ApolloReactHooks.useMutation<UpgradeCustomerToProPlanMutation, UpgradeCustomerToProPlanMutationVariables>(UpgradeCustomerToProPlanDocument, baseOptions);
      }
export type UpgradeCustomerToProPlanMutationHookResult = ReturnType<typeof useUpgradeCustomerToProPlanMutation>;
export type UpgradeCustomerToProPlanMutationResult = ApolloReactCommon.MutationResult<UpgradeCustomerToProPlanMutation>;
export type UpgradeCustomerToProPlanMutationOptions = ApolloReactCommon.BaseMutationOptions<UpgradeCustomerToProPlanMutation, UpgradeCustomerToProPlanMutationVariables>;
export const CreateHourOverageSubscriptionDocument = gql`
    mutation createHourOverageSubscription($organizationId: ID!, $clientMutationId: String) {
  createHourOverageSubscription(
    input: {organizationId: $organizationId, clientMutationId: $clientMutationId}
  ) {
    subscription
    clientMutationId
  }
}
    `;
export type CreateHourOverageSubscriptionMutationFn = ApolloReactCommon.MutationFunction<CreateHourOverageSubscriptionMutation, CreateHourOverageSubscriptionMutationVariables>;
export type CreateHourOverageSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateHourOverageSubscriptionMutation, CreateHourOverageSubscriptionMutationVariables>, 'mutation'>;

    export const CreateHourOverageSubscriptionComponent = (props: CreateHourOverageSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<CreateHourOverageSubscriptionMutation, CreateHourOverageSubscriptionMutationVariables> mutation={CreateHourOverageSubscriptionDocument} {...props} />
    );
    
export type CreateHourOverageSubscriptionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateHourOverageSubscriptionMutation, CreateHourOverageSubscriptionMutationVariables>
    } & TChildProps;
export function withCreateHourOverageSubscription<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateHourOverageSubscriptionMutation,
  CreateHourOverageSubscriptionMutationVariables,
  CreateHourOverageSubscriptionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateHourOverageSubscriptionMutation, CreateHourOverageSubscriptionMutationVariables, CreateHourOverageSubscriptionProps<TChildProps, TDataName>>(CreateHourOverageSubscriptionDocument, {
      alias: 'createHourOverageSubscription',
      ...operationOptions
    });
};

/**
 * __useCreateHourOverageSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateHourOverageSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateHourOverageSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createHourOverageSubscriptionMutation, { data, loading, error }] = useCreateHourOverageSubscriptionMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useCreateHourOverageSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateHourOverageSubscriptionMutation, CreateHourOverageSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateHourOverageSubscriptionMutation, CreateHourOverageSubscriptionMutationVariables>(CreateHourOverageSubscriptionDocument, baseOptions);
      }
export type CreateHourOverageSubscriptionMutationHookResult = ReturnType<typeof useCreateHourOverageSubscriptionMutation>;
export type CreateHourOverageSubscriptionMutationResult = ApolloReactCommon.MutationResult<CreateHourOverageSubscriptionMutation>;
export type CreateHourOverageSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateHourOverageSubscriptionMutation, CreateHourOverageSubscriptionMutationVariables>;
export const UpgradeTrialSubscriptionDocument = gql`
    mutation upgradeTrialSubscription($organizationID: ID!, $planID: ID!) {
  upgradeTrialSubscription(
    input: {organizationID: $organizationID, planID: $planID}
  ) {
    success
    clientMutationId
  }
}
    `;
export type UpgradeTrialSubscriptionMutationFn = ApolloReactCommon.MutationFunction<UpgradeTrialSubscriptionMutation, UpgradeTrialSubscriptionMutationVariables>;
export type UpgradeTrialSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpgradeTrialSubscriptionMutation, UpgradeTrialSubscriptionMutationVariables>, 'mutation'>;

    export const UpgradeTrialSubscriptionComponent = (props: UpgradeTrialSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<UpgradeTrialSubscriptionMutation, UpgradeTrialSubscriptionMutationVariables> mutation={UpgradeTrialSubscriptionDocument} {...props} />
    );
    
export type UpgradeTrialSubscriptionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpgradeTrialSubscriptionMutation, UpgradeTrialSubscriptionMutationVariables>
    } & TChildProps;
export function withUpgradeTrialSubscription<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpgradeTrialSubscriptionMutation,
  UpgradeTrialSubscriptionMutationVariables,
  UpgradeTrialSubscriptionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpgradeTrialSubscriptionMutation, UpgradeTrialSubscriptionMutationVariables, UpgradeTrialSubscriptionProps<TChildProps, TDataName>>(UpgradeTrialSubscriptionDocument, {
      alias: 'upgradeTrialSubscription',
      ...operationOptions
    });
};

/**
 * __useUpgradeTrialSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpgradeTrialSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpgradeTrialSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upgradeTrialSubscriptionMutation, { data, loading, error }] = useUpgradeTrialSubscriptionMutation({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *      planID: // value for 'planID'
 *   },
 * });
 */
export function useUpgradeTrialSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpgradeTrialSubscriptionMutation, UpgradeTrialSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpgradeTrialSubscriptionMutation, UpgradeTrialSubscriptionMutationVariables>(UpgradeTrialSubscriptionDocument, baseOptions);
      }
export type UpgradeTrialSubscriptionMutationHookResult = ReturnType<typeof useUpgradeTrialSubscriptionMutation>;
export type UpgradeTrialSubscriptionMutationResult = ApolloReactCommon.MutationResult<UpgradeTrialSubscriptionMutation>;
export type UpgradeTrialSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpgradeTrialSubscriptionMutation, UpgradeTrialSubscriptionMutationVariables>;
export const BenchmarkDiscussionThreadsDocument = gql`
    query BenchmarkDiscussionThreads($benchmarkEntityName: String!, $benchmarkProjectName: String!) {
  project(entityName: $benchmarkEntityName, name: $benchmarkProjectName) {
    id
    benchmarkDiscussionThreads {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          createdAt
          updatedAt
          title
          body
          upvotes
          poster {
            id
            photoUrl
            name
          }
          comments {
            totalCount
          }
        }
      }
    }
  }
}
    `;
export type BenchmarkDiscussionThreadsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BenchmarkDiscussionThreadsQuery, BenchmarkDiscussionThreadsQueryVariables>, 'query'> & ({ variables: BenchmarkDiscussionThreadsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BenchmarkDiscussionThreadsComponent = (props: BenchmarkDiscussionThreadsComponentProps) => (
      <ApolloReactComponents.Query<BenchmarkDiscussionThreadsQuery, BenchmarkDiscussionThreadsQueryVariables> query={BenchmarkDiscussionThreadsDocument} {...props} />
    );
    
export type BenchmarkDiscussionThreadsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<BenchmarkDiscussionThreadsQuery, BenchmarkDiscussionThreadsQueryVariables>
    } & TChildProps;
export function withBenchmarkDiscussionThreads<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BenchmarkDiscussionThreadsQuery,
  BenchmarkDiscussionThreadsQueryVariables,
  BenchmarkDiscussionThreadsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, BenchmarkDiscussionThreadsQuery, BenchmarkDiscussionThreadsQueryVariables, BenchmarkDiscussionThreadsProps<TChildProps, TDataName>>(BenchmarkDiscussionThreadsDocument, {
      alias: 'benchmarkDiscussionThreads',
      ...operationOptions
    });
};

/**
 * __useBenchmarkDiscussionThreadsQuery__
 *
 * To run a query within a React component, call `useBenchmarkDiscussionThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBenchmarkDiscussionThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBenchmarkDiscussionThreadsQuery({
 *   variables: {
 *      benchmarkEntityName: // value for 'benchmarkEntityName'
 *      benchmarkProjectName: // value for 'benchmarkProjectName'
 *   },
 * });
 */
export function useBenchmarkDiscussionThreadsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BenchmarkDiscussionThreadsQuery, BenchmarkDiscussionThreadsQueryVariables>) {
        return ApolloReactHooks.useQuery<BenchmarkDiscussionThreadsQuery, BenchmarkDiscussionThreadsQueryVariables>(BenchmarkDiscussionThreadsDocument, baseOptions);
      }
export function useBenchmarkDiscussionThreadsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BenchmarkDiscussionThreadsQuery, BenchmarkDiscussionThreadsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BenchmarkDiscussionThreadsQuery, BenchmarkDiscussionThreadsQueryVariables>(BenchmarkDiscussionThreadsDocument, baseOptions);
        }
export type BenchmarkDiscussionThreadsQueryHookResult = ReturnType<typeof useBenchmarkDiscussionThreadsQuery>;
export type BenchmarkDiscussionThreadsLazyQueryHookResult = ReturnType<typeof useBenchmarkDiscussionThreadsLazyQuery>;
export type BenchmarkDiscussionThreadsQueryResult = ApolloReactCommon.QueryResult<BenchmarkDiscussionThreadsQuery, BenchmarkDiscussionThreadsQueryVariables>;
export const CreateBenchmarkDiscussionThreadDocument = gql`
    mutation CreateBenchmarkDiscussionThread($benchmarkEntityName: String!, $benchmarkProjectName: String!, $title: String!, $body: String!) {
  createBenchmarkDiscussionThread(
    input: {benchmarkEntityName: $benchmarkEntityName, benchmarkProjectName: $benchmarkProjectName, title: $title, body: $body}
  ) {
    discussionThread {
      id
      createdAt
      updatedAt
      title
      body
      upvotes
      poster {
        id
        photoUrl
        name
      }
    }
  }
}
    `;
export type CreateBenchmarkDiscussionThreadMutationFn = ApolloReactCommon.MutationFunction<CreateBenchmarkDiscussionThreadMutation, CreateBenchmarkDiscussionThreadMutationVariables>;
export type CreateBenchmarkDiscussionThreadComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateBenchmarkDiscussionThreadMutation, CreateBenchmarkDiscussionThreadMutationVariables>, 'mutation'>;

    export const CreateBenchmarkDiscussionThreadComponent = (props: CreateBenchmarkDiscussionThreadComponentProps) => (
      <ApolloReactComponents.Mutation<CreateBenchmarkDiscussionThreadMutation, CreateBenchmarkDiscussionThreadMutationVariables> mutation={CreateBenchmarkDiscussionThreadDocument} {...props} />
    );
    
export type CreateBenchmarkDiscussionThreadProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateBenchmarkDiscussionThreadMutation, CreateBenchmarkDiscussionThreadMutationVariables>
    } & TChildProps;
export function withCreateBenchmarkDiscussionThread<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateBenchmarkDiscussionThreadMutation,
  CreateBenchmarkDiscussionThreadMutationVariables,
  CreateBenchmarkDiscussionThreadProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateBenchmarkDiscussionThreadMutation, CreateBenchmarkDiscussionThreadMutationVariables, CreateBenchmarkDiscussionThreadProps<TChildProps, TDataName>>(CreateBenchmarkDiscussionThreadDocument, {
      alias: 'createBenchmarkDiscussionThread',
      ...operationOptions
    });
};

/**
 * __useCreateBenchmarkDiscussionThreadMutation__
 *
 * To run a mutation, you first call `useCreateBenchmarkDiscussionThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBenchmarkDiscussionThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBenchmarkDiscussionThreadMutation, { data, loading, error }] = useCreateBenchmarkDiscussionThreadMutation({
 *   variables: {
 *      benchmarkEntityName: // value for 'benchmarkEntityName'
 *      benchmarkProjectName: // value for 'benchmarkProjectName'
 *      title: // value for 'title'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateBenchmarkDiscussionThreadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBenchmarkDiscussionThreadMutation, CreateBenchmarkDiscussionThreadMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBenchmarkDiscussionThreadMutation, CreateBenchmarkDiscussionThreadMutationVariables>(CreateBenchmarkDiscussionThreadDocument, baseOptions);
      }
export type CreateBenchmarkDiscussionThreadMutationHookResult = ReturnType<typeof useCreateBenchmarkDiscussionThreadMutation>;
export type CreateBenchmarkDiscussionThreadMutationResult = ApolloReactCommon.MutationResult<CreateBenchmarkDiscussionThreadMutation>;
export type CreateBenchmarkDiscussionThreadMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBenchmarkDiscussionThreadMutation, CreateBenchmarkDiscussionThreadMutationVariables>;
export const UpdateDiscussionThreadDocument = gql`
    mutation UpdateDiscussionThread($discussionThreadID: ID!, $body: String!) {
  updateDiscussionThread(
    input: {discussionThreadID: $discussionThreadID, body: $body}
  ) {
    discussionThread {
      id
      createdAt
      updatedAt
      title
      body
      upvotes
      poster {
        id
        photoUrl
        name
      }
    }
  }
}
    `;
export type UpdateDiscussionThreadMutationFn = ApolloReactCommon.MutationFunction<UpdateDiscussionThreadMutation, UpdateDiscussionThreadMutationVariables>;
export type UpdateDiscussionThreadComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateDiscussionThreadMutation, UpdateDiscussionThreadMutationVariables>, 'mutation'>;

    export const UpdateDiscussionThreadComponent = (props: UpdateDiscussionThreadComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateDiscussionThreadMutation, UpdateDiscussionThreadMutationVariables> mutation={UpdateDiscussionThreadDocument} {...props} />
    );
    
export type UpdateDiscussionThreadProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateDiscussionThreadMutation, UpdateDiscussionThreadMutationVariables>
    } & TChildProps;
export function withUpdateDiscussionThread<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateDiscussionThreadMutation,
  UpdateDiscussionThreadMutationVariables,
  UpdateDiscussionThreadProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateDiscussionThreadMutation, UpdateDiscussionThreadMutationVariables, UpdateDiscussionThreadProps<TChildProps, TDataName>>(UpdateDiscussionThreadDocument, {
      alias: 'updateDiscussionThread',
      ...operationOptions
    });
};

/**
 * __useUpdateDiscussionThreadMutation__
 *
 * To run a mutation, you first call `useUpdateDiscussionThreadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDiscussionThreadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDiscussionThreadMutation, { data, loading, error }] = useUpdateDiscussionThreadMutation({
 *   variables: {
 *      discussionThreadID: // value for 'discussionThreadID'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useUpdateDiscussionThreadMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDiscussionThreadMutation, UpdateDiscussionThreadMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDiscussionThreadMutation, UpdateDiscussionThreadMutationVariables>(UpdateDiscussionThreadDocument, baseOptions);
      }
export type UpdateDiscussionThreadMutationHookResult = ReturnType<typeof useUpdateDiscussionThreadMutation>;
export type UpdateDiscussionThreadMutationResult = ApolloReactCommon.MutationResult<UpdateDiscussionThreadMutation>;
export type UpdateDiscussionThreadMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDiscussionThreadMutation, UpdateDiscussionThreadMutationVariables>;
export const CreateBenchmarkDiscussionCommentDocument = gql`
    mutation CreateBenchmarkDiscussionComment($discussionThreadID: ID!, $parentCommentID: ID, $body: String!) {
  createBenchmarkDiscussionComment(
    input: {discussionThreadID: $discussionThreadID, parentCommentID: $parentCommentID, body: $body}
  ) {
    discussionComment {
      id
      createdAt
      updatedAt
      deletedAt
      body
      isParent
      poster {
        id
        photoUrl
        name
      }
    }
  }
}
    `;
export type CreateBenchmarkDiscussionCommentMutationFn = ApolloReactCommon.MutationFunction<CreateBenchmarkDiscussionCommentMutation, CreateBenchmarkDiscussionCommentMutationVariables>;
export type CreateBenchmarkDiscussionCommentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateBenchmarkDiscussionCommentMutation, CreateBenchmarkDiscussionCommentMutationVariables>, 'mutation'>;

    export const CreateBenchmarkDiscussionCommentComponent = (props: CreateBenchmarkDiscussionCommentComponentProps) => (
      <ApolloReactComponents.Mutation<CreateBenchmarkDiscussionCommentMutation, CreateBenchmarkDiscussionCommentMutationVariables> mutation={CreateBenchmarkDiscussionCommentDocument} {...props} />
    );
    
export type CreateBenchmarkDiscussionCommentProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateBenchmarkDiscussionCommentMutation, CreateBenchmarkDiscussionCommentMutationVariables>
    } & TChildProps;
export function withCreateBenchmarkDiscussionComment<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateBenchmarkDiscussionCommentMutation,
  CreateBenchmarkDiscussionCommentMutationVariables,
  CreateBenchmarkDiscussionCommentProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateBenchmarkDiscussionCommentMutation, CreateBenchmarkDiscussionCommentMutationVariables, CreateBenchmarkDiscussionCommentProps<TChildProps, TDataName>>(CreateBenchmarkDiscussionCommentDocument, {
      alias: 'createBenchmarkDiscussionComment',
      ...operationOptions
    });
};

/**
 * __useCreateBenchmarkDiscussionCommentMutation__
 *
 * To run a mutation, you first call `useCreateBenchmarkDiscussionCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBenchmarkDiscussionCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBenchmarkDiscussionCommentMutation, { data, loading, error }] = useCreateBenchmarkDiscussionCommentMutation({
 *   variables: {
 *      discussionThreadID: // value for 'discussionThreadID'
 *      parentCommentID: // value for 'parentCommentID'
 *      body: // value for 'body'
 *   },
 * });
 */
export function useCreateBenchmarkDiscussionCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBenchmarkDiscussionCommentMutation, CreateBenchmarkDiscussionCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateBenchmarkDiscussionCommentMutation, CreateBenchmarkDiscussionCommentMutationVariables>(CreateBenchmarkDiscussionCommentDocument, baseOptions);
      }
export type CreateBenchmarkDiscussionCommentMutationHookResult = ReturnType<typeof useCreateBenchmarkDiscussionCommentMutation>;
export type CreateBenchmarkDiscussionCommentMutationResult = ApolloReactCommon.MutationResult<CreateBenchmarkDiscussionCommentMutation>;
export type CreateBenchmarkDiscussionCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBenchmarkDiscussionCommentMutation, CreateBenchmarkDiscussionCommentMutationVariables>;
export const UpdateDiscussionCommentDocument = gql`
    mutation UpdateDiscussionComment($viewID: ID, $discussionCommentID: ID!, $body: String!, $inlineCommentDetails: JSONString) {
  updateDiscussionComment(
    input: {viewID: $viewID, discussionCommentID: $discussionCommentID, body: $body, inlineCommentDetails: $inlineCommentDetails}
  ) {
    discussionComment {
      id
      createdAt
      updatedAt
      deletedAt
      body
      isParent
      poster {
        id
        photoUrl
        name
        username
      }
    }
  }
}
    `;
export type UpdateDiscussionCommentMutationFn = ApolloReactCommon.MutationFunction<UpdateDiscussionCommentMutation, UpdateDiscussionCommentMutationVariables>;
export type UpdateDiscussionCommentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateDiscussionCommentMutation, UpdateDiscussionCommentMutationVariables>, 'mutation'>;

    export const UpdateDiscussionCommentComponent = (props: UpdateDiscussionCommentComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateDiscussionCommentMutation, UpdateDiscussionCommentMutationVariables> mutation={UpdateDiscussionCommentDocument} {...props} />
    );
    
export type UpdateDiscussionCommentProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateDiscussionCommentMutation, UpdateDiscussionCommentMutationVariables>
    } & TChildProps;
export function withUpdateDiscussionComment<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateDiscussionCommentMutation,
  UpdateDiscussionCommentMutationVariables,
  UpdateDiscussionCommentProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateDiscussionCommentMutation, UpdateDiscussionCommentMutationVariables, UpdateDiscussionCommentProps<TChildProps, TDataName>>(UpdateDiscussionCommentDocument, {
      alias: 'updateDiscussionComment',
      ...operationOptions
    });
};

/**
 * __useUpdateDiscussionCommentMutation__
 *
 * To run a mutation, you first call `useUpdateDiscussionCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDiscussionCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDiscussionCommentMutation, { data, loading, error }] = useUpdateDiscussionCommentMutation({
 *   variables: {
 *      viewID: // value for 'viewID'
 *      discussionCommentID: // value for 'discussionCommentID'
 *      body: // value for 'body'
 *      inlineCommentDetails: // value for 'inlineCommentDetails'
 *   },
 * });
 */
export function useUpdateDiscussionCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDiscussionCommentMutation, UpdateDiscussionCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDiscussionCommentMutation, UpdateDiscussionCommentMutationVariables>(UpdateDiscussionCommentDocument, baseOptions);
      }
export type UpdateDiscussionCommentMutationHookResult = ReturnType<typeof useUpdateDiscussionCommentMutation>;
export type UpdateDiscussionCommentMutationResult = ApolloReactCommon.MutationResult<UpdateDiscussionCommentMutation>;
export type UpdateDiscussionCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDiscussionCommentMutation, UpdateDiscussionCommentMutationVariables>;
export const DeleteDiscussionCommentDocument = gql`
    mutation DeleteDiscussionComment($id: ID!, $deleteThread: Boolean) {
  deleteDiscussionComment(input: {id: $id, deleteThread: $deleteThread}) {
    success
  }
}
    `;
export type DeleteDiscussionCommentMutationFn = ApolloReactCommon.MutationFunction<DeleteDiscussionCommentMutation, DeleteDiscussionCommentMutationVariables>;
export type DeleteDiscussionCommentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteDiscussionCommentMutation, DeleteDiscussionCommentMutationVariables>, 'mutation'>;

    export const DeleteDiscussionCommentComponent = (props: DeleteDiscussionCommentComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteDiscussionCommentMutation, DeleteDiscussionCommentMutationVariables> mutation={DeleteDiscussionCommentDocument} {...props} />
    );
    
export type DeleteDiscussionCommentProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteDiscussionCommentMutation, DeleteDiscussionCommentMutationVariables>
    } & TChildProps;
export function withDeleteDiscussionComment<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteDiscussionCommentMutation,
  DeleteDiscussionCommentMutationVariables,
  DeleteDiscussionCommentProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteDiscussionCommentMutation, DeleteDiscussionCommentMutationVariables, DeleteDiscussionCommentProps<TChildProps, TDataName>>(DeleteDiscussionCommentDocument, {
      alias: 'deleteDiscussionComment',
      ...operationOptions
    });
};

/**
 * __useDeleteDiscussionCommentMutation__
 *
 * To run a mutation, you first call `useDeleteDiscussionCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDiscussionCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDiscussionCommentMutation, { data, loading, error }] = useDeleteDiscussionCommentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deleteThread: // value for 'deleteThread'
 *   },
 * });
 */
export function useDeleteDiscussionCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDiscussionCommentMutation, DeleteDiscussionCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDiscussionCommentMutation, DeleteDiscussionCommentMutationVariables>(DeleteDiscussionCommentDocument, baseOptions);
      }
export type DeleteDiscussionCommentMutationHookResult = ReturnType<typeof useDeleteDiscussionCommentMutation>;
export type DeleteDiscussionCommentMutationResult = ApolloReactCommon.MutationResult<DeleteDiscussionCommentMutation>;
export type DeleteDiscussionCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDiscussionCommentMutation, DeleteDiscussionCommentMutationVariables>;
export const ViewDiscussionThreadsDocument = gql`
    query ViewDiscussionThreads($viewID: ID!) {
  view(id: $viewID) {
    id
    discussionThreads {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          createdAt
          updatedAt
          title
          body
          upvotes
          poster {
            id
            photoUrl
            name
            username
          }
          comments {
            totalCount
            pageInfo {
              hasNextPage
              hasPreviousPage
            }
            edges {
              node {
                id
                createdAt
                updatedAt
                body
                poster {
                  id
                  photoUrl
                  name
                  username
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type ViewDiscussionThreadsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewDiscussionThreadsQuery, ViewDiscussionThreadsQueryVariables>, 'query'> & ({ variables: ViewDiscussionThreadsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ViewDiscussionThreadsComponent = (props: ViewDiscussionThreadsComponentProps) => (
      <ApolloReactComponents.Query<ViewDiscussionThreadsQuery, ViewDiscussionThreadsQueryVariables> query={ViewDiscussionThreadsDocument} {...props} />
    );
    
export type ViewDiscussionThreadsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewDiscussionThreadsQuery, ViewDiscussionThreadsQueryVariables>
    } & TChildProps;
export function withViewDiscussionThreads<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewDiscussionThreadsQuery,
  ViewDiscussionThreadsQueryVariables,
  ViewDiscussionThreadsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewDiscussionThreadsQuery, ViewDiscussionThreadsQueryVariables, ViewDiscussionThreadsProps<TChildProps, TDataName>>(ViewDiscussionThreadsDocument, {
      alias: 'viewDiscussionThreads',
      ...operationOptions
    });
};

/**
 * __useViewDiscussionThreadsQuery__
 *
 * To run a query within a React component, call `useViewDiscussionThreadsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewDiscussionThreadsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewDiscussionThreadsQuery({
 *   variables: {
 *      viewID: // value for 'viewID'
 *   },
 * });
 */
export function useViewDiscussionThreadsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewDiscussionThreadsQuery, ViewDiscussionThreadsQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewDiscussionThreadsQuery, ViewDiscussionThreadsQueryVariables>(ViewDiscussionThreadsDocument, baseOptions);
      }
export function useViewDiscussionThreadsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewDiscussionThreadsQuery, ViewDiscussionThreadsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewDiscussionThreadsQuery, ViewDiscussionThreadsQueryVariables>(ViewDiscussionThreadsDocument, baseOptions);
        }
export type ViewDiscussionThreadsQueryHookResult = ReturnType<typeof useViewDiscussionThreadsQuery>;
export type ViewDiscussionThreadsLazyQueryHookResult = ReturnType<typeof useViewDiscussionThreadsLazyQuery>;
export type ViewDiscussionThreadsQueryResult = ApolloReactCommon.QueryResult<ViewDiscussionThreadsQuery, ViewDiscussionThreadsQueryVariables>;
export const CreateViewDiscussionCommentDocument = gql`
    mutation CreateViewDiscussionComment($viewID: ID!, $discussionThreadID: ID, $body: String!, $notifyAllSubscribers: Boolean, $inlineCommentDetails: JSONString) {
  createViewDiscussionComment(
    input: {viewID: $viewID, discussionThreadID: $discussionThreadID, body: $body, notifyAllSubscribers: $notifyAllSubscribers, inlineCommentDetails: $inlineCommentDetails}
  ) {
    discussionThread {
      id
      createdAt
      updatedAt
      title
      body
      upvotes
      poster {
        id
        photoUrl
        name
        username
      }
    }
    discussionComment {
      id
      createdAt
      updatedAt
      body
      poster {
        id
        photoUrl
        name
        username
      }
    }
    alertSubscription {
      id
    }
  }
}
    `;
export type CreateViewDiscussionCommentMutationFn = ApolloReactCommon.MutationFunction<CreateViewDiscussionCommentMutation, CreateViewDiscussionCommentMutationVariables>;
export type CreateViewDiscussionCommentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateViewDiscussionCommentMutation, CreateViewDiscussionCommentMutationVariables>, 'mutation'>;

    export const CreateViewDiscussionCommentComponent = (props: CreateViewDiscussionCommentComponentProps) => (
      <ApolloReactComponents.Mutation<CreateViewDiscussionCommentMutation, CreateViewDiscussionCommentMutationVariables> mutation={CreateViewDiscussionCommentDocument} {...props} />
    );
    
export type CreateViewDiscussionCommentProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateViewDiscussionCommentMutation, CreateViewDiscussionCommentMutationVariables>
    } & TChildProps;
export function withCreateViewDiscussionComment<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateViewDiscussionCommentMutation,
  CreateViewDiscussionCommentMutationVariables,
  CreateViewDiscussionCommentProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateViewDiscussionCommentMutation, CreateViewDiscussionCommentMutationVariables, CreateViewDiscussionCommentProps<TChildProps, TDataName>>(CreateViewDiscussionCommentDocument, {
      alias: 'createViewDiscussionComment',
      ...operationOptions
    });
};

/**
 * __useCreateViewDiscussionCommentMutation__
 *
 * To run a mutation, you first call `useCreateViewDiscussionCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateViewDiscussionCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createViewDiscussionCommentMutation, { data, loading, error }] = useCreateViewDiscussionCommentMutation({
 *   variables: {
 *      viewID: // value for 'viewID'
 *      discussionThreadID: // value for 'discussionThreadID'
 *      body: // value for 'body'
 *      notifyAllSubscribers: // value for 'notifyAllSubscribers'
 *      inlineCommentDetails: // value for 'inlineCommentDetails'
 *   },
 * });
 */
export function useCreateViewDiscussionCommentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateViewDiscussionCommentMutation, CreateViewDiscussionCommentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateViewDiscussionCommentMutation, CreateViewDiscussionCommentMutationVariables>(CreateViewDiscussionCommentDocument, baseOptions);
      }
export type CreateViewDiscussionCommentMutationHookResult = ReturnType<typeof useCreateViewDiscussionCommentMutation>;
export type CreateViewDiscussionCommentMutationResult = ApolloReactCommon.MutationResult<CreateViewDiscussionCommentMutation>;
export type CreateViewDiscussionCommentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateViewDiscussionCommentMutation, CreateViewDiscussionCommentMutationVariables>;
export const DismissedBannerDocument = gql`
    query DismissedBanner($id: ID!) {
  dismissedBanner(id: $id) {
    id
    dismissedAt
  }
}
    `;
export type DismissedBannerComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DismissedBannerQuery, DismissedBannerQueryVariables>, 'query'> & ({ variables: DismissedBannerQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DismissedBannerComponent = (props: DismissedBannerComponentProps) => (
      <ApolloReactComponents.Query<DismissedBannerQuery, DismissedBannerQueryVariables> query={DismissedBannerDocument} {...props} />
    );
    
export type DismissedBannerProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<DismissedBannerQuery, DismissedBannerQueryVariables>
    } & TChildProps;
export function withDismissedBanner<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DismissedBannerQuery,
  DismissedBannerQueryVariables,
  DismissedBannerProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, DismissedBannerQuery, DismissedBannerQueryVariables, DismissedBannerProps<TChildProps, TDataName>>(DismissedBannerDocument, {
      alias: 'dismissedBanner',
      ...operationOptions
    });
};

/**
 * __useDismissedBannerQuery__
 *
 * To run a query within a React component, call `useDismissedBannerQuery` and pass it any options that fit your needs.
 * When your component renders, `useDismissedBannerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDismissedBannerQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDismissedBannerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DismissedBannerQuery, DismissedBannerQueryVariables>) {
        return ApolloReactHooks.useQuery<DismissedBannerQuery, DismissedBannerQueryVariables>(DismissedBannerDocument, baseOptions);
      }
export function useDismissedBannerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DismissedBannerQuery, DismissedBannerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DismissedBannerQuery, DismissedBannerQueryVariables>(DismissedBannerDocument, baseOptions);
        }
export type DismissedBannerQueryHookResult = ReturnType<typeof useDismissedBannerQuery>;
export type DismissedBannerLazyQueryHookResult = ReturnType<typeof useDismissedBannerLazyQuery>;
export type DismissedBannerQueryResult = ApolloReactCommon.QueryResult<DismissedBannerQuery, DismissedBannerQueryVariables>;
export const SetDismissedBannerDocument = gql`
    mutation SetDismissedBanner($id: ID!) {
  setDismissedBanner(input: {bannerID: $id}) {
    success
  }
}
    `;
export type SetDismissedBannerMutationFn = ApolloReactCommon.MutationFunction<SetDismissedBannerMutation, SetDismissedBannerMutationVariables>;
export type SetDismissedBannerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetDismissedBannerMutation, SetDismissedBannerMutationVariables>, 'mutation'>;

    export const SetDismissedBannerComponent = (props: SetDismissedBannerComponentProps) => (
      <ApolloReactComponents.Mutation<SetDismissedBannerMutation, SetDismissedBannerMutationVariables> mutation={SetDismissedBannerDocument} {...props} />
    );
    
export type SetDismissedBannerProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<SetDismissedBannerMutation, SetDismissedBannerMutationVariables>
    } & TChildProps;
export function withSetDismissedBanner<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SetDismissedBannerMutation,
  SetDismissedBannerMutationVariables,
  SetDismissedBannerProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, SetDismissedBannerMutation, SetDismissedBannerMutationVariables, SetDismissedBannerProps<TChildProps, TDataName>>(SetDismissedBannerDocument, {
      alias: 'setDismissedBanner',
      ...operationOptions
    });
};

/**
 * __useSetDismissedBannerMutation__
 *
 * To run a mutation, you first call `useSetDismissedBannerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetDismissedBannerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setDismissedBannerMutation, { data, loading, error }] = useSetDismissedBannerMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSetDismissedBannerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetDismissedBannerMutation, SetDismissedBannerMutationVariables>) {
        return ApolloReactHooks.useMutation<SetDismissedBannerMutation, SetDismissedBannerMutationVariables>(SetDismissedBannerDocument, baseOptions);
      }
export type SetDismissedBannerMutationHookResult = ReturnType<typeof useSetDismissedBannerMutation>;
export type SetDismissedBannerMutationResult = ApolloReactCommon.MutationResult<SetDismissedBannerMutation>;
export type SetDismissedBannerMutationOptions = ApolloReactCommon.BaseMutationOptions<SetDismissedBannerMutation, SetDismissedBannerMutationVariables>;
export const ResendEmailVerificationDocument = gql`
    mutation resendEmailVerification($email: String!, $clientMutationId: String) {
  resendEmailVerification(
    input: {email: $email, clientMutationId: $clientMutationId}
  ) {
    success
    clientMutationId
  }
}
    `;
export type ResendEmailVerificationMutationFn = ApolloReactCommon.MutationFunction<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables>;
export type ResendEmailVerificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables>, 'mutation'>;

    export const ResendEmailVerificationComponent = (props: ResendEmailVerificationComponentProps) => (
      <ApolloReactComponents.Mutation<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables> mutation={ResendEmailVerificationDocument} {...props} />
    );
    
export type ResendEmailVerificationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables>
    } & TChildProps;
export function withResendEmailVerification<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ResendEmailVerificationMutation,
  ResendEmailVerificationMutationVariables,
  ResendEmailVerificationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables, ResendEmailVerificationProps<TChildProps, TDataName>>(ResendEmailVerificationDocument, {
      alias: 'resendEmailVerification',
      ...operationOptions
    });
};

/**
 * __useResendEmailVerificationMutation__
 *
 * To run a mutation, you first call `useResendEmailVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResendEmailVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resendEmailVerificationMutation, { data, loading, error }] = useResendEmailVerificationMutation({
 *   variables: {
 *      email: // value for 'email'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useResendEmailVerificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables>) {
        return ApolloReactHooks.useMutation<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables>(ResendEmailVerificationDocument, baseOptions);
      }
export type ResendEmailVerificationMutationHookResult = ReturnType<typeof useResendEmailVerificationMutation>;
export type ResendEmailVerificationMutationResult = ApolloReactCommon.MutationResult<ResendEmailVerificationMutation>;
export type ResendEmailVerificationMutationOptions = ApolloReactCommon.BaseMutationOptions<ResendEmailVerificationMutation, ResendEmailVerificationMutationVariables>;
export const EntityPageDocument = gql`
    query EntityPage($entityName: String!) {
  entity(name: $entityName) {
    id
    isTeam
    entityType
    available
    claimingEntity {
      id
      name
    }
    members {
      id
      name
      username
      email
      photoUrl
      pending
      accountType
      role
    }
    name
  }
}
    `;
export type EntityPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityPageQuery, EntityPageQueryVariables>, 'query'> & ({ variables: EntityPageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntityPageComponent = (props: EntityPageComponentProps) => (
      <ApolloReactComponents.Query<EntityPageQuery, EntityPageQueryVariables> query={EntityPageDocument} {...props} />
    );
    
export type EntityPageProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityPageQuery, EntityPageQueryVariables>
    } & TChildProps;
export function withEntityPage<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityPageQuery,
  EntityPageQueryVariables,
  EntityPageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityPageQuery, EntityPageQueryVariables, EntityPageProps<TChildProps, TDataName>>(EntityPageDocument, {
      alias: 'entityPage',
      ...operationOptions
    });
};

/**
 * __useEntityPageQuery__
 *
 * To run a query within a React component, call `useEntityPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityPageQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useEntityPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityPageQuery, EntityPageQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityPageQuery, EntityPageQueryVariables>(EntityPageDocument, baseOptions);
      }
export function useEntityPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityPageQuery, EntityPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityPageQuery, EntityPageQueryVariables>(EntityPageDocument, baseOptions);
        }
export type EntityPageQueryHookResult = ReturnType<typeof useEntityPageQuery>;
export type EntityPageLazyQueryHookResult = ReturnType<typeof useEntityPageLazyQuery>;
export type EntityPageQueryResult = ApolloReactCommon.QueryResult<EntityPageQuery, EntityPageQueryVariables>;
export const EntityPageUserStatsDocument = gql`
    query EntityPageUserStats($entityName: String!, $userStatsStartDate: DateTime!) {
  entity(name: $entityName) {
    id
    userStats(from: $userStatsStartDate, first: 10) {
      edges {
        node {
          id
          user {
            id
            name
            username
            photoUrl
            accountType
          }
          runCount
        }
      }
    }
  }
}
    `;
export type EntityPageUserStatsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityPageUserStatsQuery, EntityPageUserStatsQueryVariables>, 'query'> & ({ variables: EntityPageUserStatsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntityPageUserStatsComponent = (props: EntityPageUserStatsComponentProps) => (
      <ApolloReactComponents.Query<EntityPageUserStatsQuery, EntityPageUserStatsQueryVariables> query={EntityPageUserStatsDocument} {...props} />
    );
    
export type EntityPageUserStatsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityPageUserStatsQuery, EntityPageUserStatsQueryVariables>
    } & TChildProps;
export function withEntityPageUserStats<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityPageUserStatsQuery,
  EntityPageUserStatsQueryVariables,
  EntityPageUserStatsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityPageUserStatsQuery, EntityPageUserStatsQueryVariables, EntityPageUserStatsProps<TChildProps, TDataName>>(EntityPageUserStatsDocument, {
      alias: 'entityPageUserStats',
      ...operationOptions
    });
};

/**
 * __useEntityPageUserStatsQuery__
 *
 * To run a query within a React component, call `useEntityPageUserStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityPageUserStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityPageUserStatsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      userStatsStartDate: // value for 'userStatsStartDate'
 *   },
 * });
 */
export function useEntityPageUserStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityPageUserStatsQuery, EntityPageUserStatsQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityPageUserStatsQuery, EntityPageUserStatsQueryVariables>(EntityPageUserStatsDocument, baseOptions);
      }
export function useEntityPageUserStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityPageUserStatsQuery, EntityPageUserStatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityPageUserStatsQuery, EntityPageUserStatsQueryVariables>(EntityPageUserStatsDocument, baseOptions);
        }
export type EntityPageUserStatsQueryHookResult = ReturnType<typeof useEntityPageUserStatsQuery>;
export type EntityPageUserStatsLazyQueryHookResult = ReturnType<typeof useEntityPageUserStatsLazyQuery>;
export type EntityPageUserStatsQueryResult = ApolloReactCommon.QueryResult<EntityPageUserStatsQuery, EntityPageUserStatsQueryVariables>;
export const EntityRunsDocument = gql`
    query EntityRuns($entityName: String!) {
  entity(name: $entityName) {
    id
    latestRuns {
      edges {
        node {
          id
          ...RunsTableFragment
        }
      }
    }
  }
}
    ${RunsTableFragmentFragmentDoc}`;
export type EntityRunsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityRunsQuery, EntityRunsQueryVariables>, 'query'> & ({ variables: EntityRunsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntityRunsComponent = (props: EntityRunsComponentProps) => (
      <ApolloReactComponents.Query<EntityRunsQuery, EntityRunsQueryVariables> query={EntityRunsDocument} {...props} />
    );
    
export type EntityRunsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityRunsQuery, EntityRunsQueryVariables>
    } & TChildProps;
export function withEntityRuns<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityRunsQuery,
  EntityRunsQueryVariables,
  EntityRunsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityRunsQuery, EntityRunsQueryVariables, EntityRunsProps<TChildProps, TDataName>>(EntityRunsDocument, {
      alias: 'entityRuns',
      ...operationOptions
    });
};

/**
 * __useEntityRunsQuery__
 *
 * To run a query within a React component, call `useEntityRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityRunsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useEntityRunsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityRunsQuery, EntityRunsQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityRunsQuery, EntityRunsQueryVariables>(EntityRunsDocument, baseOptions);
      }
export function useEntityRunsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityRunsQuery, EntityRunsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityRunsQuery, EntityRunsQueryVariables>(EntityRunsDocument, baseOptions);
        }
export type EntityRunsQueryHookResult = ReturnType<typeof useEntityRunsQuery>;
export type EntityRunsLazyQueryHookResult = ReturnType<typeof useEntityRunsLazyQuery>;
export type EntityRunsQueryResult = ApolloReactCommon.QueryResult<EntityRunsQuery, EntityRunsQueryVariables>;
export const EntitySettingsDocument = gql`
    query EntitySettings($name: String!) {
  entity(name: $name) {
    id
    settings {
      defaultCloudRegion {
        id
        provider
        region
      }
      openToMatchingOrgEmailDomainUsers
      entityProfileEnabled
      hideActivityCalendar
      membersCanInvite
      disableMagicLinkSharing
      disableMembersEditArtifactTTL
      hidden
    }
  }
}
    `;
export type EntitySettingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntitySettingsQuery, EntitySettingsQueryVariables>, 'query'> & ({ variables: EntitySettingsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntitySettingsComponent = (props: EntitySettingsComponentProps) => (
      <ApolloReactComponents.Query<EntitySettingsQuery, EntitySettingsQueryVariables> query={EntitySettingsDocument} {...props} />
    );
    
export type EntitySettingsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntitySettingsQuery, EntitySettingsQueryVariables>
    } & TChildProps;
export function withEntitySettings<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntitySettingsQuery,
  EntitySettingsQueryVariables,
  EntitySettingsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntitySettingsQuery, EntitySettingsQueryVariables, EntitySettingsProps<TChildProps, TDataName>>(EntitySettingsDocument, {
      alias: 'entitySettings',
      ...operationOptions
    });
};

/**
 * __useEntitySettingsQuery__
 *
 * To run a query within a React component, call `useEntitySettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntitySettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntitySettingsQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEntitySettingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntitySettingsQuery, EntitySettingsQueryVariables>) {
        return ApolloReactHooks.useQuery<EntitySettingsQuery, EntitySettingsQueryVariables>(EntitySettingsDocument, baseOptions);
      }
export function useEntitySettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntitySettingsQuery, EntitySettingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntitySettingsQuery, EntitySettingsQueryVariables>(EntitySettingsDocument, baseOptions);
        }
export type EntitySettingsQueryHookResult = ReturnType<typeof useEntitySettingsQuery>;
export type EntitySettingsLazyQueryHookResult = ReturnType<typeof useEntitySettingsLazyQuery>;
export type EntitySettingsQueryResult = ApolloReactCommon.QueryResult<EntitySettingsQuery, EntitySettingsQueryVariables>;
export const EntityStorageDocument = gql`
    query EntityStorage($entityName: String, $enableReferenceTracking: Boolean) {
  entity(name: $entityName) {
    id
    storageBytes
    referenceBytes
    computeHours
    storage: storageTree(enableReferenceTracking: $enableReferenceTracking) {
      id
      size
    }
  }
}
    `;
export type EntityStorageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityStorageQuery, EntityStorageQueryVariables>, 'query'>;

    export const EntityStorageComponent = (props: EntityStorageComponentProps) => (
      <ApolloReactComponents.Query<EntityStorageQuery, EntityStorageQueryVariables> query={EntityStorageDocument} {...props} />
    );
    
export type EntityStorageProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityStorageQuery, EntityStorageQueryVariables>
    } & TChildProps;
export function withEntityStorage<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityStorageQuery,
  EntityStorageQueryVariables,
  EntityStorageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityStorageQuery, EntityStorageQueryVariables, EntityStorageProps<TChildProps, TDataName>>(EntityStorageDocument, {
      alias: 'entityStorage',
      ...operationOptions
    });
};

/**
 * __useEntityStorageQuery__
 *
 * To run a query within a React component, call `useEntityStorageQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityStorageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityStorageQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      enableReferenceTracking: // value for 'enableReferenceTracking'
 *   },
 * });
 */
export function useEntityStorageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityStorageQuery, EntityStorageQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityStorageQuery, EntityStorageQueryVariables>(EntityStorageDocument, baseOptions);
      }
export function useEntityStorageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityStorageQuery, EntityStorageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityStorageQuery, EntityStorageQueryVariables>(EntityStorageDocument, baseOptions);
        }
export type EntityStorageQueryHookResult = ReturnType<typeof useEntityStorageQuery>;
export type EntityStorageLazyQueryHookResult = ReturnType<typeof useEntityStorageLazyQuery>;
export type EntityStorageQueryResult = ApolloReactCommon.QueryResult<EntityStorageQuery, EntityStorageQueryVariables>;
export const CachedEntityStorageInfoDocument = gql`
    query CachedEntityStorageInfo($entityName: String) {
  entity(name: $entityName) {
    id
    storageBytes(cached: true)
  }
}
    `;
export type CachedEntityStorageInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CachedEntityStorageInfoQuery, CachedEntityStorageInfoQueryVariables>, 'query'>;

    export const CachedEntityStorageInfoComponent = (props: CachedEntityStorageInfoComponentProps) => (
      <ApolloReactComponents.Query<CachedEntityStorageInfoQuery, CachedEntityStorageInfoQueryVariables> query={CachedEntityStorageInfoDocument} {...props} />
    );
    
export type CachedEntityStorageInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CachedEntityStorageInfoQuery, CachedEntityStorageInfoQueryVariables>
    } & TChildProps;
export function withCachedEntityStorageInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CachedEntityStorageInfoQuery,
  CachedEntityStorageInfoQueryVariables,
  CachedEntityStorageInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CachedEntityStorageInfoQuery, CachedEntityStorageInfoQueryVariables, CachedEntityStorageInfoProps<TChildProps, TDataName>>(CachedEntityStorageInfoDocument, {
      alias: 'cachedEntityStorageInfo',
      ...operationOptions
    });
};

/**
 * __useCachedEntityStorageInfoQuery__
 *
 * To run a query within a React component, call `useCachedEntityStorageInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCachedEntityStorageInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCachedEntityStorageInfoQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useCachedEntityStorageInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CachedEntityStorageInfoQuery, CachedEntityStorageInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<CachedEntityStorageInfoQuery, CachedEntityStorageInfoQueryVariables>(CachedEntityStorageInfoDocument, baseOptions);
      }
export function useCachedEntityStorageInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CachedEntityStorageInfoQuery, CachedEntityStorageInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CachedEntityStorageInfoQuery, CachedEntityStorageInfoQueryVariables>(CachedEntityStorageInfoDocument, baseOptions);
        }
export type CachedEntityStorageInfoQueryHookResult = ReturnType<typeof useCachedEntityStorageInfoQuery>;
export type CachedEntityStorageInfoLazyQueryHookResult = ReturnType<typeof useCachedEntityStorageInfoLazyQuery>;
export type CachedEntityStorageInfoQueryResult = ApolloReactCommon.QueryResult<CachedEntityStorageInfoQuery, CachedEntityStorageInfoQueryVariables>;
export const ArtifactCollectionStorageTreeDocument = gql`
    query ArtifactCollectionStorageTree($entityName: String!, $enableReferenceTracking: Boolean!, $artifactCollectionID: ID!) {
  entity(name: $entityName) {
    id
    artifactCollectionStorageTree(
      artifactCollectionID: $artifactCollectionID
      enableReferenceTracking: $enableReferenceTracking
    ) {
      edges {
        node {
          id
          name
          size
          artifactID
        }
      }
    }
  }
}
    `;
export type ArtifactCollectionStorageTreeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactCollectionStorageTreeQuery, ArtifactCollectionStorageTreeQueryVariables>, 'query'> & ({ variables: ArtifactCollectionStorageTreeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactCollectionStorageTreeComponent = (props: ArtifactCollectionStorageTreeComponentProps) => (
      <ApolloReactComponents.Query<ArtifactCollectionStorageTreeQuery, ArtifactCollectionStorageTreeQueryVariables> query={ArtifactCollectionStorageTreeDocument} {...props} />
    );
    
export type ArtifactCollectionStorageTreeProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactCollectionStorageTreeQuery, ArtifactCollectionStorageTreeQueryVariables>
    } & TChildProps;
export function withArtifactCollectionStorageTree<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactCollectionStorageTreeQuery,
  ArtifactCollectionStorageTreeQueryVariables,
  ArtifactCollectionStorageTreeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactCollectionStorageTreeQuery, ArtifactCollectionStorageTreeQueryVariables, ArtifactCollectionStorageTreeProps<TChildProps, TDataName>>(ArtifactCollectionStorageTreeDocument, {
      alias: 'artifactCollectionStorageTree',
      ...operationOptions
    });
};

/**
 * __useArtifactCollectionStorageTreeQuery__
 *
 * To run a query within a React component, call `useArtifactCollectionStorageTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactCollectionStorageTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactCollectionStorageTreeQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      enableReferenceTracking: // value for 'enableReferenceTracking'
 *      artifactCollectionID: // value for 'artifactCollectionID'
 *   },
 * });
 */
export function useArtifactCollectionStorageTreeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactCollectionStorageTreeQuery, ArtifactCollectionStorageTreeQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactCollectionStorageTreeQuery, ArtifactCollectionStorageTreeQueryVariables>(ArtifactCollectionStorageTreeDocument, baseOptions);
      }
export function useArtifactCollectionStorageTreeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactCollectionStorageTreeQuery, ArtifactCollectionStorageTreeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactCollectionStorageTreeQuery, ArtifactCollectionStorageTreeQueryVariables>(ArtifactCollectionStorageTreeDocument, baseOptions);
        }
export type ArtifactCollectionStorageTreeQueryHookResult = ReturnType<typeof useArtifactCollectionStorageTreeQuery>;
export type ArtifactCollectionStorageTreeLazyQueryHookResult = ReturnType<typeof useArtifactCollectionStorageTreeLazyQuery>;
export type ArtifactCollectionStorageTreeQueryResult = ApolloReactCommon.QueryResult<ArtifactCollectionStorageTreeQuery, ArtifactCollectionStorageTreeQueryVariables>;
export const EntityStorageTreeDocument = gql`
    query EntityStorageTree($entityName: String!, $enableReferenceTracking: Boolean!, $query: String, $cached: Boolean) {
  entity(name: $entityName) {
    id
    storageTree(enableReferenceTracking: $enableReferenceTracking, cached: $cached) {
      id
      name
      size
      lastUpdatedAt
      projects(query: $query) {
        totalSize
        edges {
          node {
            id
            name
            size
            projectID
            runs @skip(if: $enableReferenceTracking) {
              totalSize
              edges {
                node {
                  id
                  ...RunNode
                }
              }
            }
            artifactSequences {
              totalSize
              edges {
                node {
                  id
                  ...ArtifactNode
                }
              }
            }
            artifactTypes {
              id
              name
              size
            }
          }
        }
      }
    }
  }
}
    ${RunNodeFragmentDoc}
${ArtifactNodeFragmentDoc}`;
export type EntityStorageTreeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityStorageTreeQuery, EntityStorageTreeQueryVariables>, 'query'> & ({ variables: EntityStorageTreeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntityStorageTreeComponent = (props: EntityStorageTreeComponentProps) => (
      <ApolloReactComponents.Query<EntityStorageTreeQuery, EntityStorageTreeQueryVariables> query={EntityStorageTreeDocument} {...props} />
    );
    
export type EntityStorageTreeProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityStorageTreeQuery, EntityStorageTreeQueryVariables>
    } & TChildProps;
export function withEntityStorageTree<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityStorageTreeQuery,
  EntityStorageTreeQueryVariables,
  EntityStorageTreeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityStorageTreeQuery, EntityStorageTreeQueryVariables, EntityStorageTreeProps<TChildProps, TDataName>>(EntityStorageTreeDocument, {
      alias: 'entityStorageTree',
      ...operationOptions
    });
};

/**
 * __useEntityStorageTreeQuery__
 *
 * To run a query within a React component, call `useEntityStorageTreeQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityStorageTreeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityStorageTreeQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      enableReferenceTracking: // value for 'enableReferenceTracking'
 *      query: // value for 'query'
 *      cached: // value for 'cached'
 *   },
 * });
 */
export function useEntityStorageTreeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityStorageTreeQuery, EntityStorageTreeQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityStorageTreeQuery, EntityStorageTreeQueryVariables>(EntityStorageTreeDocument, baseOptions);
      }
export function useEntityStorageTreeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityStorageTreeQuery, EntityStorageTreeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityStorageTreeQuery, EntityStorageTreeQueryVariables>(EntityStorageTreeDocument, baseOptions);
        }
export type EntityStorageTreeQueryHookResult = ReturnType<typeof useEntityStorageTreeQuery>;
export type EntityStorageTreeLazyQueryHookResult = ReturnType<typeof useEntityStorageTreeLazyQuery>;
export type EntityStorageTreeQueryResult = ApolloReactCommon.QueryResult<EntityStorageTreeQuery, EntityStorageTreeQueryVariables>;
export const AllExperimentsDocument = gql`
    query AllExperiments {
  experiments {
    id
    name
    variants {
      bucket
      allocation
      description
    }
    experimentType
    startAt
    endAt
    updatedAt
    metadata
    sampleSize
    observationalUnit
    enrollmentPeriod
  }
}
    `;
export type AllExperimentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllExperimentsQuery, AllExperimentsQueryVariables>, 'query'>;

    export const AllExperimentsComponent = (props: AllExperimentsComponentProps) => (
      <ApolloReactComponents.Query<AllExperimentsQuery, AllExperimentsQueryVariables> query={AllExperimentsDocument} {...props} />
    );
    
export type AllExperimentsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AllExperimentsQuery, AllExperimentsQueryVariables>
    } & TChildProps;
export function withAllExperiments<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AllExperimentsQuery,
  AllExperimentsQueryVariables,
  AllExperimentsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AllExperimentsQuery, AllExperimentsQueryVariables, AllExperimentsProps<TChildProps, TDataName>>(AllExperimentsDocument, {
      alias: 'allExperiments',
      ...operationOptions
    });
};

/**
 * __useAllExperimentsQuery__
 *
 * To run a query within a React component, call `useAllExperimentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllExperimentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllExperimentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllExperimentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllExperimentsQuery, AllExperimentsQueryVariables>) {
        return ApolloReactHooks.useQuery<AllExperimentsQuery, AllExperimentsQueryVariables>(AllExperimentsDocument, baseOptions);
      }
export function useAllExperimentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllExperimentsQuery, AllExperimentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllExperimentsQuery, AllExperimentsQueryVariables>(AllExperimentsDocument, baseOptions);
        }
export type AllExperimentsQueryHookResult = ReturnType<typeof useAllExperimentsQuery>;
export type AllExperimentsLazyQueryHookResult = ReturnType<typeof useAllExperimentsLazyQuery>;
export type AllExperimentsQueryResult = ApolloReactCommon.QueryResult<AllExperimentsQuery, AllExperimentsQueryVariables>;
export const ActiveExperimentsDocument = gql`
    query ActiveExperiments {
  activeExperiments {
    id
    name
    activeVariants {
      bucket
      allocation
    }
    experimentType
    startAt
    endAt
  }
}
    `;
export type ActiveExperimentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ActiveExperimentsQuery, ActiveExperimentsQueryVariables>, 'query'>;

    export const ActiveExperimentsComponent = (props: ActiveExperimentsComponentProps) => (
      <ApolloReactComponents.Query<ActiveExperimentsQuery, ActiveExperimentsQueryVariables> query={ActiveExperimentsDocument} {...props} />
    );
    
export type ActiveExperimentsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ActiveExperimentsQuery, ActiveExperimentsQueryVariables>
    } & TChildProps;
export function withActiveExperiments<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ActiveExperimentsQuery,
  ActiveExperimentsQueryVariables,
  ActiveExperimentsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ActiveExperimentsQuery, ActiveExperimentsQueryVariables, ActiveExperimentsProps<TChildProps, TDataName>>(ActiveExperimentsDocument, {
      alias: 'activeExperiments',
      ...operationOptions
    });
};

/**
 * __useActiveExperimentsQuery__
 *
 * To run a query within a React component, call `useActiveExperimentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveExperimentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveExperimentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveExperimentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ActiveExperimentsQuery, ActiveExperimentsQueryVariables>) {
        return ApolloReactHooks.useQuery<ActiveExperimentsQuery, ActiveExperimentsQueryVariables>(ActiveExperimentsDocument, baseOptions);
      }
export function useActiveExperimentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActiveExperimentsQuery, ActiveExperimentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ActiveExperimentsQuery, ActiveExperimentsQueryVariables>(ActiveExperimentsDocument, baseOptions);
        }
export type ActiveExperimentsQueryHookResult = ReturnType<typeof useActiveExperimentsQuery>;
export type ActiveExperimentsLazyQueryHookResult = ReturnType<typeof useActiveExperimentsLazyQuery>;
export type ActiveExperimentsQueryResult = ApolloReactCommon.QueryResult<ActiveExperimentsQuery, ActiveExperimentsQueryVariables>;
export const CreateExperimentDocument = gql`
    mutation CreateExperiment($name: String!, $variants: [VariantInput!]!, $experimentType: ExperimentType!, $metadata: JSONString, $sampleSize: Int!, $observationalUnit: ObservationalUnit!, $enrollmentPeriod: Int!, $clientMutationId: String) {
  createExperiment(
    input: {name: $name, variants: $variants, experimentType: $experimentType, metadata: $metadata, sampleSize: $sampleSize, observationalUnit: $observationalUnit, enrollmentPeriod: $enrollmentPeriod, clientMutationId: $clientMutationId}
  ) {
    id
    success
    clientMutationId
  }
}
    `;
export type CreateExperimentMutationFn = ApolloReactCommon.MutationFunction<CreateExperimentMutation, CreateExperimentMutationVariables>;
export type CreateExperimentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateExperimentMutation, CreateExperimentMutationVariables>, 'mutation'>;

    export const CreateExperimentComponent = (props: CreateExperimentComponentProps) => (
      <ApolloReactComponents.Mutation<CreateExperimentMutation, CreateExperimentMutationVariables> mutation={CreateExperimentDocument} {...props} />
    );
    
export type CreateExperimentProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateExperimentMutation, CreateExperimentMutationVariables>
    } & TChildProps;
export function withCreateExperiment<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateExperimentMutation,
  CreateExperimentMutationVariables,
  CreateExperimentProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateExperimentMutation, CreateExperimentMutationVariables, CreateExperimentProps<TChildProps, TDataName>>(CreateExperimentDocument, {
      alias: 'createExperiment',
      ...operationOptions
    });
};

/**
 * __useCreateExperimentMutation__
 *
 * To run a mutation, you first call `useCreateExperimentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExperimentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExperimentMutation, { data, loading, error }] = useCreateExperimentMutation({
 *   variables: {
 *      name: // value for 'name'
 *      variants: // value for 'variants'
 *      experimentType: // value for 'experimentType'
 *      metadata: // value for 'metadata'
 *      sampleSize: // value for 'sampleSize'
 *      observationalUnit: // value for 'observationalUnit'
 *      enrollmentPeriod: // value for 'enrollmentPeriod'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useCreateExperimentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateExperimentMutation, CreateExperimentMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateExperimentMutation, CreateExperimentMutationVariables>(CreateExperimentDocument, baseOptions);
      }
export type CreateExperimentMutationHookResult = ReturnType<typeof useCreateExperimentMutation>;
export type CreateExperimentMutationResult = ApolloReactCommon.MutationResult<CreateExperimentMutation>;
export type CreateExperimentMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateExperimentMutation, CreateExperimentMutationVariables>;
export const UpdateExperimentDocument = gql`
    mutation UpdateExperiment($id: ID!, $name: String!, $variants: [VariantInput!]!, $experimentType: ExperimentType!, $metadata: JSONString, $sampleSize: Int!, $observationalUnit: ObservationalUnit!, $enrollmentPeriod: Int!, $clientMutationId: String) {
  updateExperiment(
    input: {id: $id, name: $name, variants: $variants, experimentType: $experimentType, metadata: $metadata, sampleSize: $sampleSize, observationalUnit: $observationalUnit, enrollmentPeriod: $enrollmentPeriod, clientMutationId: $clientMutationId}
  ) {
    id
    success
    clientMutationId
  }
}
    `;
export type UpdateExperimentMutationFn = ApolloReactCommon.MutationFunction<UpdateExperimentMutation, UpdateExperimentMutationVariables>;
export type UpdateExperimentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateExperimentMutation, UpdateExperimentMutationVariables>, 'mutation'>;

    export const UpdateExperimentComponent = (props: UpdateExperimentComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateExperimentMutation, UpdateExperimentMutationVariables> mutation={UpdateExperimentDocument} {...props} />
    );
    
export type UpdateExperimentProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateExperimentMutation, UpdateExperimentMutationVariables>
    } & TChildProps;
export function withUpdateExperiment<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateExperimentMutation,
  UpdateExperimentMutationVariables,
  UpdateExperimentProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateExperimentMutation, UpdateExperimentMutationVariables, UpdateExperimentProps<TChildProps, TDataName>>(UpdateExperimentDocument, {
      alias: 'updateExperiment',
      ...operationOptions
    });
};

/**
 * __useUpdateExperimentMutation__
 *
 * To run a mutation, you first call `useUpdateExperimentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateExperimentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateExperimentMutation, { data, loading, error }] = useUpdateExperimentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      variants: // value for 'variants'
 *      experimentType: // value for 'experimentType'
 *      metadata: // value for 'metadata'
 *      sampleSize: // value for 'sampleSize'
 *      observationalUnit: // value for 'observationalUnit'
 *      enrollmentPeriod: // value for 'enrollmentPeriod'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useUpdateExperimentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateExperimentMutation, UpdateExperimentMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateExperimentMutation, UpdateExperimentMutationVariables>(UpdateExperimentDocument, baseOptions);
      }
export type UpdateExperimentMutationHookResult = ReturnType<typeof useUpdateExperimentMutation>;
export type UpdateExperimentMutationResult = ApolloReactCommon.MutationResult<UpdateExperimentMutation>;
export type UpdateExperimentMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateExperimentMutation, UpdateExperimentMutationVariables>;
export const StartExperimentDocument = gql`
    mutation StartExperiment($id: ID!, $clientMutationId: String) {
  startExperiment(input: {id: $id, clientMutationId: $clientMutationId}) {
    id
    success
    clientMutationId
  }
}
    `;
export type StartExperimentMutationFn = ApolloReactCommon.MutationFunction<StartExperimentMutation, StartExperimentMutationVariables>;
export type StartExperimentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<StartExperimentMutation, StartExperimentMutationVariables>, 'mutation'>;

    export const StartExperimentComponent = (props: StartExperimentComponentProps) => (
      <ApolloReactComponents.Mutation<StartExperimentMutation, StartExperimentMutationVariables> mutation={StartExperimentDocument} {...props} />
    );
    
export type StartExperimentProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<StartExperimentMutation, StartExperimentMutationVariables>
    } & TChildProps;
export function withStartExperiment<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  StartExperimentMutation,
  StartExperimentMutationVariables,
  StartExperimentProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, StartExperimentMutation, StartExperimentMutationVariables, StartExperimentProps<TChildProps, TDataName>>(StartExperimentDocument, {
      alias: 'startExperiment',
      ...operationOptions
    });
};

/**
 * __useStartExperimentMutation__
 *
 * To run a mutation, you first call `useStartExperimentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartExperimentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startExperimentMutation, { data, loading, error }] = useStartExperimentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useStartExperimentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StartExperimentMutation, StartExperimentMutationVariables>) {
        return ApolloReactHooks.useMutation<StartExperimentMutation, StartExperimentMutationVariables>(StartExperimentDocument, baseOptions);
      }
export type StartExperimentMutationHookResult = ReturnType<typeof useStartExperimentMutation>;
export type StartExperimentMutationResult = ApolloReactCommon.MutationResult<StartExperimentMutation>;
export type StartExperimentMutationOptions = ApolloReactCommon.BaseMutationOptions<StartExperimentMutation, StartExperimentMutationVariables>;
export const StopExperimentDocument = gql`
    mutation StopExperiment($id: ID!, $clientMutationId: String) {
  stopExperiment(input: {id: $id, clientMutationId: $clientMutationId}) {
    id
    success
    clientMutationId
  }
}
    `;
export type StopExperimentMutationFn = ApolloReactCommon.MutationFunction<StopExperimentMutation, StopExperimentMutationVariables>;
export type StopExperimentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<StopExperimentMutation, StopExperimentMutationVariables>, 'mutation'>;

    export const StopExperimentComponent = (props: StopExperimentComponentProps) => (
      <ApolloReactComponents.Mutation<StopExperimentMutation, StopExperimentMutationVariables> mutation={StopExperimentDocument} {...props} />
    );
    
export type StopExperimentProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<StopExperimentMutation, StopExperimentMutationVariables>
    } & TChildProps;
export function withStopExperiment<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  StopExperimentMutation,
  StopExperimentMutationVariables,
  StopExperimentProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, StopExperimentMutation, StopExperimentMutationVariables, StopExperimentProps<TChildProps, TDataName>>(StopExperimentDocument, {
      alias: 'stopExperiment',
      ...operationOptions
    });
};

/**
 * __useStopExperimentMutation__
 *
 * To run a mutation, you first call `useStopExperimentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopExperimentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopExperimentMutation, { data, loading, error }] = useStopExperimentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useStopExperimentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StopExperimentMutation, StopExperimentMutationVariables>) {
        return ApolloReactHooks.useMutation<StopExperimentMutation, StopExperimentMutationVariables>(StopExperimentDocument, baseOptions);
      }
export type StopExperimentMutationHookResult = ReturnType<typeof useStopExperimentMutation>;
export type StopExperimentMutationResult = ApolloReactCommon.MutationResult<StopExperimentMutation>;
export type StopExperimentMutationOptions = ApolloReactCommon.BaseMutationOptions<StopExperimentMutation, StopExperimentMutationVariables>;
export const DeleteExperimentDocument = gql`
    mutation DeleteExperiment($id: ID!, $clientMutationId: String) {
  deleteExperiment(input: {id: $id, clientMutationId: $clientMutationId}) {
    id
    success
    clientMutationId
  }
}
    `;
export type DeleteExperimentMutationFn = ApolloReactCommon.MutationFunction<DeleteExperimentMutation, DeleteExperimentMutationVariables>;
export type DeleteExperimentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteExperimentMutation, DeleteExperimentMutationVariables>, 'mutation'>;

    export const DeleteExperimentComponent = (props: DeleteExperimentComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteExperimentMutation, DeleteExperimentMutationVariables> mutation={DeleteExperimentDocument} {...props} />
    );
    
export type DeleteExperimentProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteExperimentMutation, DeleteExperimentMutationVariables>
    } & TChildProps;
export function withDeleteExperiment<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteExperimentMutation,
  DeleteExperimentMutationVariables,
  DeleteExperimentProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteExperimentMutation, DeleteExperimentMutationVariables, DeleteExperimentProps<TChildProps, TDataName>>(DeleteExperimentDocument, {
      alias: 'deleteExperiment',
      ...operationOptions
    });
};

/**
 * __useDeleteExperimentMutation__
 *
 * To run a mutation, you first call `useDeleteExperimentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteExperimentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteExperimentMutation, { data, loading, error }] = useDeleteExperimentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useDeleteExperimentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteExperimentMutation, DeleteExperimentMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteExperimentMutation, DeleteExperimentMutationVariables>(DeleteExperimentDocument, baseOptions);
      }
export type DeleteExperimentMutationHookResult = ReturnType<typeof useDeleteExperimentMutation>;
export type DeleteExperimentMutationResult = ApolloReactCommon.MutationResult<DeleteExperimentMutation>;
export type DeleteExperimentMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteExperimentMutation, DeleteExperimentMutationVariables>;
export const UserEmailsDocument = gql`
    query UserEmails {
  viewer {
    id
    emails {
      id
      emailAddress
      type
      createdAt
      verified
      identities {
        id
        emailIdentityVerified
        provider
      }
    }
  }
}
    `;
export type UserEmailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserEmailsQuery, UserEmailsQueryVariables>, 'query'>;

    export const UserEmailsComponent = (props: UserEmailsComponentProps) => (
      <ApolloReactComponents.Query<UserEmailsQuery, UserEmailsQueryVariables> query={UserEmailsDocument} {...props} />
    );
    
export type UserEmailsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UserEmailsQuery, UserEmailsQueryVariables>
    } & TChildProps;
export function withUserEmails<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserEmailsQuery,
  UserEmailsQueryVariables,
  UserEmailsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, UserEmailsQuery, UserEmailsQueryVariables, UserEmailsProps<TChildProps, TDataName>>(UserEmailsDocument, {
      alias: 'userEmails',
      ...operationOptions
    });
};

/**
 * __useUserEmailsQuery__
 *
 * To run a query within a React component, call `useUserEmailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEmailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEmailsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserEmailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserEmailsQuery, UserEmailsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserEmailsQuery, UserEmailsQueryVariables>(UserEmailsDocument, baseOptions);
      }
export function useUserEmailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserEmailsQuery, UserEmailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserEmailsQuery, UserEmailsQueryVariables>(UserEmailsDocument, baseOptions);
        }
export type UserEmailsQueryHookResult = ReturnType<typeof useUserEmailsQuery>;
export type UserEmailsLazyQueryHookResult = ReturnType<typeof useUserEmailsLazyQuery>;
export type UserEmailsQueryResult = ApolloReactCommon.QueryResult<UserEmailsQuery, UserEmailsQueryVariables>;
export const GlueTasksDocument = gql`
    query GlueTasks {
  glueManager {
    taskTypes
    registeredTasks {
      edges {
        node {
          id
          name
          taskType
          arguments
          checkpoint
          lastRunAt
          status
          strategyType
          strategyMetadata
        }
      }
    }
  }
}
    `;
export type GlueTasksComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GlueTasksQuery, GlueTasksQueryVariables>, 'query'>;

    export const GlueTasksComponent = (props: GlueTasksComponentProps) => (
      <ApolloReactComponents.Query<GlueTasksQuery, GlueTasksQueryVariables> query={GlueTasksDocument} {...props} />
    );
    
export type GlueTasksProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GlueTasksQuery, GlueTasksQueryVariables>
    } & TChildProps;
export function withGlueTasks<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GlueTasksQuery,
  GlueTasksQueryVariables,
  GlueTasksProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GlueTasksQuery, GlueTasksQueryVariables, GlueTasksProps<TChildProps, TDataName>>(GlueTasksDocument, {
      alias: 'glueTasks',
      ...operationOptions
    });
};

/**
 * __useGlueTasksQuery__
 *
 * To run a query within a React component, call `useGlueTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlueTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlueTasksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGlueTasksQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GlueTasksQuery, GlueTasksQueryVariables>) {
        return ApolloReactHooks.useQuery<GlueTasksQuery, GlueTasksQueryVariables>(GlueTasksDocument, baseOptions);
      }
export function useGlueTasksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GlueTasksQuery, GlueTasksQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GlueTasksQuery, GlueTasksQueryVariables>(GlueTasksDocument, baseOptions);
        }
export type GlueTasksQueryHookResult = ReturnType<typeof useGlueTasksQuery>;
export type GlueTasksLazyQueryHookResult = ReturnType<typeof useGlueTasksLazyQuery>;
export type GlueTasksQueryResult = ApolloReactCommon.QueryResult<GlueTasksQuery, GlueTasksQueryVariables>;
export const GlueTaskConfigDocument = gql`
    query GlueTaskConfig($taskType: String!) {
  glueManager {
    taskConfig(taskType: $taskType) {
      defaultArguments
      argumentsSchema
      checkpointSchema
    }
  }
}
    `;
export type GlueTaskConfigComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GlueTaskConfigQuery, GlueTaskConfigQueryVariables>, 'query'> & ({ variables: GlueTaskConfigQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GlueTaskConfigComponent = (props: GlueTaskConfigComponentProps) => (
      <ApolloReactComponents.Query<GlueTaskConfigQuery, GlueTaskConfigQueryVariables> query={GlueTaskConfigDocument} {...props} />
    );
    
export type GlueTaskConfigProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GlueTaskConfigQuery, GlueTaskConfigQueryVariables>
    } & TChildProps;
export function withGlueTaskConfig<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GlueTaskConfigQuery,
  GlueTaskConfigQueryVariables,
  GlueTaskConfigProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GlueTaskConfigQuery, GlueTaskConfigQueryVariables, GlueTaskConfigProps<TChildProps, TDataName>>(GlueTaskConfigDocument, {
      alias: 'glueTaskConfig',
      ...operationOptions
    });
};

/**
 * __useGlueTaskConfigQuery__
 *
 * To run a query within a React component, call `useGlueTaskConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlueTaskConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlueTaskConfigQuery({
 *   variables: {
 *      taskType: // value for 'taskType'
 *   },
 * });
 */
export function useGlueTaskConfigQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GlueTaskConfigQuery, GlueTaskConfigQueryVariables>) {
        return ApolloReactHooks.useQuery<GlueTaskConfigQuery, GlueTaskConfigQueryVariables>(GlueTaskConfigDocument, baseOptions);
      }
export function useGlueTaskConfigLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GlueTaskConfigQuery, GlueTaskConfigQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GlueTaskConfigQuery, GlueTaskConfigQueryVariables>(GlueTaskConfigDocument, baseOptions);
        }
export type GlueTaskConfigQueryHookResult = ReturnType<typeof useGlueTaskConfigQuery>;
export type GlueTaskConfigLazyQueryHookResult = ReturnType<typeof useGlueTaskConfigLazyQuery>;
export type GlueTaskConfigQueryResult = ApolloReactCommon.QueryResult<GlueTaskConfigQuery, GlueTaskConfigQueryVariables>;
export const GlueRunsDocument = gql`
    query GlueRuns($taskID: ID!) {
  glueRuns(taskID: $taskID) {
    edges {
      node {
        id
        runAt
        runtimeMS
        arguments
        errors
        retries
        succeeded
      }
    }
  }
}
    `;
export type GlueRunsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GlueRunsQuery, GlueRunsQueryVariables>, 'query'> & ({ variables: GlueRunsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GlueRunsComponent = (props: GlueRunsComponentProps) => (
      <ApolloReactComponents.Query<GlueRunsQuery, GlueRunsQueryVariables> query={GlueRunsDocument} {...props} />
    );
    
export type GlueRunsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GlueRunsQuery, GlueRunsQueryVariables>
    } & TChildProps;
export function withGlueRuns<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GlueRunsQuery,
  GlueRunsQueryVariables,
  GlueRunsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GlueRunsQuery, GlueRunsQueryVariables, GlueRunsProps<TChildProps, TDataName>>(GlueRunsDocument, {
      alias: 'glueRuns',
      ...operationOptions
    });
};

/**
 * __useGlueRunsQuery__
 *
 * To run a query within a React component, call `useGlueRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlueRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlueRunsQuery({
 *   variables: {
 *      taskID: // value for 'taskID'
 *   },
 * });
 */
export function useGlueRunsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GlueRunsQuery, GlueRunsQueryVariables>) {
        return ApolloReactHooks.useQuery<GlueRunsQuery, GlueRunsQueryVariables>(GlueRunsDocument, baseOptions);
      }
export function useGlueRunsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GlueRunsQuery, GlueRunsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GlueRunsQuery, GlueRunsQueryVariables>(GlueRunsDocument, baseOptions);
        }
export type GlueRunsQueryHookResult = ReturnType<typeof useGlueRunsQuery>;
export type GlueRunsLazyQueryHookResult = ReturnType<typeof useGlueRunsLazyQuery>;
export type GlueRunsQueryResult = ApolloReactCommon.QueryResult<GlueRunsQuery, GlueRunsQueryVariables>;
export const CreateGlueTaskDocument = gql`
    mutation CreateGlueTask($name: String!, $taskType: String!, $arguments: JSONString!, $status: String!, $strategyType: String!, $strategyMetadata: String!, $clientMutationId: String) {
  createGlueTask(
    input: {name: $name, taskType: $taskType, arguments: $arguments, status: $status, strategyType: $strategyType, strategyMetadata: $strategyMetadata, clientMutationId: $clientMutationId}
  ) {
    success
    clientMutationId
  }
}
    `;
export type CreateGlueTaskMutationFn = ApolloReactCommon.MutationFunction<CreateGlueTaskMutation, CreateGlueTaskMutationVariables>;
export type CreateGlueTaskComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateGlueTaskMutation, CreateGlueTaskMutationVariables>, 'mutation'>;

    export const CreateGlueTaskComponent = (props: CreateGlueTaskComponentProps) => (
      <ApolloReactComponents.Mutation<CreateGlueTaskMutation, CreateGlueTaskMutationVariables> mutation={CreateGlueTaskDocument} {...props} />
    );
    
export type CreateGlueTaskProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateGlueTaskMutation, CreateGlueTaskMutationVariables>
    } & TChildProps;
export function withCreateGlueTask<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateGlueTaskMutation,
  CreateGlueTaskMutationVariables,
  CreateGlueTaskProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateGlueTaskMutation, CreateGlueTaskMutationVariables, CreateGlueTaskProps<TChildProps, TDataName>>(CreateGlueTaskDocument, {
      alias: 'createGlueTask',
      ...operationOptions
    });
};

/**
 * __useCreateGlueTaskMutation__
 *
 * To run a mutation, you first call `useCreateGlueTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGlueTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGlueTaskMutation, { data, loading, error }] = useCreateGlueTaskMutation({
 *   variables: {
 *      name: // value for 'name'
 *      taskType: // value for 'taskType'
 *      arguments: // value for 'arguments'
 *      status: // value for 'status'
 *      strategyType: // value for 'strategyType'
 *      strategyMetadata: // value for 'strategyMetadata'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useCreateGlueTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGlueTaskMutation, CreateGlueTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGlueTaskMutation, CreateGlueTaskMutationVariables>(CreateGlueTaskDocument, baseOptions);
      }
export type CreateGlueTaskMutationHookResult = ReturnType<typeof useCreateGlueTaskMutation>;
export type CreateGlueTaskMutationResult = ApolloReactCommon.MutationResult<CreateGlueTaskMutation>;
export type CreateGlueTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGlueTaskMutation, CreateGlueTaskMutationVariables>;
export const UpdateGlueTaskDocument = gql`
    mutation UpdateGlueTask($id: ID!, $name: String, $arguments: JSONString, $checkpoint: JSONString, $strategyType: String, $strategyMetadata: String, $status: String) {
  updateGlueTask(
    input: {id: $id, name: $name, arguments: $arguments, checkpoint: $checkpoint, strategyType: $strategyType, strategyMetadata: $strategyMetadata, status: $status}
  ) {
    success
    clientMutationId
  }
}
    `;
export type UpdateGlueTaskMutationFn = ApolloReactCommon.MutationFunction<UpdateGlueTaskMutation, UpdateGlueTaskMutationVariables>;
export type UpdateGlueTaskComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateGlueTaskMutation, UpdateGlueTaskMutationVariables>, 'mutation'>;

    export const UpdateGlueTaskComponent = (props: UpdateGlueTaskComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateGlueTaskMutation, UpdateGlueTaskMutationVariables> mutation={UpdateGlueTaskDocument} {...props} />
    );
    
export type UpdateGlueTaskProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateGlueTaskMutation, UpdateGlueTaskMutationVariables>
    } & TChildProps;
export function withUpdateGlueTask<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateGlueTaskMutation,
  UpdateGlueTaskMutationVariables,
  UpdateGlueTaskProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateGlueTaskMutation, UpdateGlueTaskMutationVariables, UpdateGlueTaskProps<TChildProps, TDataName>>(UpdateGlueTaskDocument, {
      alias: 'updateGlueTask',
      ...operationOptions
    });
};

/**
 * __useUpdateGlueTaskMutation__
 *
 * To run a mutation, you first call `useUpdateGlueTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGlueTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGlueTaskMutation, { data, loading, error }] = useUpdateGlueTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      arguments: // value for 'arguments'
 *      checkpoint: // value for 'checkpoint'
 *      strategyType: // value for 'strategyType'
 *      strategyMetadata: // value for 'strategyMetadata'
 *      status: // value for 'status'
 *   },
 * });
 */
export function useUpdateGlueTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateGlueTaskMutation, UpdateGlueTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateGlueTaskMutation, UpdateGlueTaskMutationVariables>(UpdateGlueTaskDocument, baseOptions);
      }
export type UpdateGlueTaskMutationHookResult = ReturnType<typeof useUpdateGlueTaskMutation>;
export type UpdateGlueTaskMutationResult = ApolloReactCommon.MutationResult<UpdateGlueTaskMutation>;
export type UpdateGlueTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateGlueTaskMutation, UpdateGlueTaskMutationVariables>;
export const EnableGlueTaskDocument = gql`
    mutation EnableGlueTask($id: ID!) {
  enableGlueTask(input: {id: $id}) {
    success
    clientMutationId
  }
}
    `;
export type EnableGlueTaskMutationFn = ApolloReactCommon.MutationFunction<EnableGlueTaskMutation, EnableGlueTaskMutationVariables>;
export type EnableGlueTaskComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EnableGlueTaskMutation, EnableGlueTaskMutationVariables>, 'mutation'>;

    export const EnableGlueTaskComponent = (props: EnableGlueTaskComponentProps) => (
      <ApolloReactComponents.Mutation<EnableGlueTaskMutation, EnableGlueTaskMutationVariables> mutation={EnableGlueTaskDocument} {...props} />
    );
    
export type EnableGlueTaskProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<EnableGlueTaskMutation, EnableGlueTaskMutationVariables>
    } & TChildProps;
export function withEnableGlueTask<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EnableGlueTaskMutation,
  EnableGlueTaskMutationVariables,
  EnableGlueTaskProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, EnableGlueTaskMutation, EnableGlueTaskMutationVariables, EnableGlueTaskProps<TChildProps, TDataName>>(EnableGlueTaskDocument, {
      alias: 'enableGlueTask',
      ...operationOptions
    });
};

/**
 * __useEnableGlueTaskMutation__
 *
 * To run a mutation, you first call `useEnableGlueTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableGlueTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableGlueTaskMutation, { data, loading, error }] = useEnableGlueTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useEnableGlueTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EnableGlueTaskMutation, EnableGlueTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<EnableGlueTaskMutation, EnableGlueTaskMutationVariables>(EnableGlueTaskDocument, baseOptions);
      }
export type EnableGlueTaskMutationHookResult = ReturnType<typeof useEnableGlueTaskMutation>;
export type EnableGlueTaskMutationResult = ApolloReactCommon.MutationResult<EnableGlueTaskMutation>;
export type EnableGlueTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<EnableGlueTaskMutation, EnableGlueTaskMutationVariables>;
export const DisableGlueTaskDocument = gql`
    mutation DisableGlueTask($id: ID!) {
  disableGlueTask(input: {id: $id}) {
    success
    clientMutationId
  }
}
    `;
export type DisableGlueTaskMutationFn = ApolloReactCommon.MutationFunction<DisableGlueTaskMutation, DisableGlueTaskMutationVariables>;
export type DisableGlueTaskComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DisableGlueTaskMutation, DisableGlueTaskMutationVariables>, 'mutation'>;

    export const DisableGlueTaskComponent = (props: DisableGlueTaskComponentProps) => (
      <ApolloReactComponents.Mutation<DisableGlueTaskMutation, DisableGlueTaskMutationVariables> mutation={DisableGlueTaskDocument} {...props} />
    );
    
export type DisableGlueTaskProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DisableGlueTaskMutation, DisableGlueTaskMutationVariables>
    } & TChildProps;
export function withDisableGlueTask<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DisableGlueTaskMutation,
  DisableGlueTaskMutationVariables,
  DisableGlueTaskProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DisableGlueTaskMutation, DisableGlueTaskMutationVariables, DisableGlueTaskProps<TChildProps, TDataName>>(DisableGlueTaskDocument, {
      alias: 'disableGlueTask',
      ...operationOptions
    });
};

/**
 * __useDisableGlueTaskMutation__
 *
 * To run a mutation, you first call `useDisableGlueTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDisableGlueTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [disableGlueTaskMutation, { data, loading, error }] = useDisableGlueTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDisableGlueTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DisableGlueTaskMutation, DisableGlueTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<DisableGlueTaskMutation, DisableGlueTaskMutationVariables>(DisableGlueTaskDocument, baseOptions);
      }
export type DisableGlueTaskMutationHookResult = ReturnType<typeof useDisableGlueTaskMutation>;
export type DisableGlueTaskMutationResult = ApolloReactCommon.MutationResult<DisableGlueTaskMutation>;
export type DisableGlueTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<DisableGlueTaskMutation, DisableGlueTaskMutationVariables>;
export const DeleteGlueTaskDocument = gql`
    mutation DeleteGlueTask($id: ID!) {
  deleteGlueTask(input: {id: $id}) {
    success
    clientMutationId
  }
}
    `;
export type DeleteGlueTaskMutationFn = ApolloReactCommon.MutationFunction<DeleteGlueTaskMutation, DeleteGlueTaskMutationVariables>;
export type DeleteGlueTaskComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteGlueTaskMutation, DeleteGlueTaskMutationVariables>, 'mutation'>;

    export const DeleteGlueTaskComponent = (props: DeleteGlueTaskComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteGlueTaskMutation, DeleteGlueTaskMutationVariables> mutation={DeleteGlueTaskDocument} {...props} />
    );
    
export type DeleteGlueTaskProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteGlueTaskMutation, DeleteGlueTaskMutationVariables>
    } & TChildProps;
export function withDeleteGlueTask<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteGlueTaskMutation,
  DeleteGlueTaskMutationVariables,
  DeleteGlueTaskProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteGlueTaskMutation, DeleteGlueTaskMutationVariables, DeleteGlueTaskProps<TChildProps, TDataName>>(DeleteGlueTaskDocument, {
      alias: 'deleteGlueTask',
      ...operationOptions
    });
};

/**
 * __useDeleteGlueTaskMutation__
 *
 * To run a mutation, you first call `useDeleteGlueTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGlueTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGlueTaskMutation, { data, loading, error }] = useDeleteGlueTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGlueTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteGlueTaskMutation, DeleteGlueTaskMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteGlueTaskMutation, DeleteGlueTaskMutationVariables>(DeleteGlueTaskDocument, baseOptions);
      }
export type DeleteGlueTaskMutationHookResult = ReturnType<typeof useDeleteGlueTaskMutation>;
export type DeleteGlueTaskMutationResult = ApolloReactCommon.MutationResult<DeleteGlueTaskMutation>;
export type DeleteGlueTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteGlueTaskMutation, DeleteGlueTaskMutationVariables>;
export const HomePageViewerDetailsDocument = gql`
    query HomePageViewerDetails($entityName: String) {
  viewer(entityName: $entityName) {
    id
    username
    name
    photoUrl
    organizations {
      id
      name
    }
    defaultEntity {
      id
      name
    }
    teams(first: 100) {
      edges {
        node {
          id
          name
          photoUrl
        }
      }
    }
  }
}
    `;
export type HomePageViewerDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<HomePageViewerDetailsQuery, HomePageViewerDetailsQueryVariables>, 'query'>;

    export const HomePageViewerDetailsComponent = (props: HomePageViewerDetailsComponentProps) => (
      <ApolloReactComponents.Query<HomePageViewerDetailsQuery, HomePageViewerDetailsQueryVariables> query={HomePageViewerDetailsDocument} {...props} />
    );
    
export type HomePageViewerDetailsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<HomePageViewerDetailsQuery, HomePageViewerDetailsQueryVariables>
    } & TChildProps;
export function withHomePageViewerDetails<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  HomePageViewerDetailsQuery,
  HomePageViewerDetailsQueryVariables,
  HomePageViewerDetailsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, HomePageViewerDetailsQuery, HomePageViewerDetailsQueryVariables, HomePageViewerDetailsProps<TChildProps, TDataName>>(HomePageViewerDetailsDocument, {
      alias: 'homePageViewerDetails',
      ...operationOptions
    });
};

/**
 * __useHomePageViewerDetailsQuery__
 *
 * To run a query within a React component, call `useHomePageViewerDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomePageViewerDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePageViewerDetailsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useHomePageViewerDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HomePageViewerDetailsQuery, HomePageViewerDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<HomePageViewerDetailsQuery, HomePageViewerDetailsQueryVariables>(HomePageViewerDetailsDocument, baseOptions);
      }
export function useHomePageViewerDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HomePageViewerDetailsQuery, HomePageViewerDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HomePageViewerDetailsQuery, HomePageViewerDetailsQueryVariables>(HomePageViewerDetailsDocument, baseOptions);
        }
export type HomePageViewerDetailsQueryHookResult = ReturnType<typeof useHomePageViewerDetailsQuery>;
export type HomePageViewerDetailsLazyQueryHookResult = ReturnType<typeof useHomePageViewerDetailsLazyQuery>;
export type HomePageViewerDetailsQueryResult = ApolloReactCommon.QueryResult<HomePageViewerDetailsQuery, HomePageViewerDetailsQueryVariables>;
export const HomePageViewerProjectsDocument = gql`
    query HomePageViewerProjects($entityName: String!, $first: Int = 20, $pattern: String) {
  projects(
    entityName: $entityName
    first: $first
    pattern: $pattern
    order: "-created_at"
  ) {
    edges {
      node {
        id
        name
        entityName
        access
        createdAt
      }
    }
  }
}
    `;
export type HomePageViewerProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<HomePageViewerProjectsQuery, HomePageViewerProjectsQueryVariables>, 'query'> & ({ variables: HomePageViewerProjectsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const HomePageViewerProjectsComponent = (props: HomePageViewerProjectsComponentProps) => (
      <ApolloReactComponents.Query<HomePageViewerProjectsQuery, HomePageViewerProjectsQueryVariables> query={HomePageViewerProjectsDocument} {...props} />
    );
    
export type HomePageViewerProjectsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<HomePageViewerProjectsQuery, HomePageViewerProjectsQueryVariables>
    } & TChildProps;
export function withHomePageViewerProjects<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  HomePageViewerProjectsQuery,
  HomePageViewerProjectsQueryVariables,
  HomePageViewerProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, HomePageViewerProjectsQuery, HomePageViewerProjectsQueryVariables, HomePageViewerProjectsProps<TChildProps, TDataName>>(HomePageViewerProjectsDocument, {
      alias: 'homePageViewerProjects',
      ...operationOptions
    });
};

/**
 * __useHomePageViewerProjectsQuery__
 *
 * To run a query within a React component, call `useHomePageViewerProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useHomePageViewerProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHomePageViewerProjectsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      first: // value for 'first'
 *      pattern: // value for 'pattern'
 *   },
 * });
 */
export function useHomePageViewerProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HomePageViewerProjectsQuery, HomePageViewerProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<HomePageViewerProjectsQuery, HomePageViewerProjectsQueryVariables>(HomePageViewerProjectsDocument, baseOptions);
      }
export function useHomePageViewerProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HomePageViewerProjectsQuery, HomePageViewerProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HomePageViewerProjectsQuery, HomePageViewerProjectsQueryVariables>(HomePageViewerProjectsDocument, baseOptions);
        }
export type HomePageViewerProjectsQueryHookResult = ReturnType<typeof useHomePageViewerProjectsQuery>;
export type HomePageViewerProjectsLazyQueryHookResult = ReturnType<typeof useHomePageViewerProjectsLazyQuery>;
export type HomePageViewerProjectsQueryResult = ApolloReactCommon.QueryResult<HomePageViewerProjectsQuery, HomePageViewerProjectsQueryVariables>;
export const ViewerReportsDocument = gql`
    query ViewerReports($entityName: String) {
  viewer(entityName: $entityName) {
    id
    views(viewType: "runs", first: 100) {
      edges {
        node {
          id
          ...ReportsTableFragment
        }
      }
    }
  }
}
    ${ReportsTableFragmentFragmentDoc}`;
export type ViewerReportsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewerReportsQuery, ViewerReportsQueryVariables>, 'query'>;

    export const ViewerReportsComponent = (props: ViewerReportsComponentProps) => (
      <ApolloReactComponents.Query<ViewerReportsQuery, ViewerReportsQueryVariables> query={ViewerReportsDocument} {...props} />
    );
    
export type ViewerReportsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewerReportsQuery, ViewerReportsQueryVariables>
    } & TChildProps;
export function withViewerReports<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewerReportsQuery,
  ViewerReportsQueryVariables,
  ViewerReportsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewerReportsQuery, ViewerReportsQueryVariables, ViewerReportsProps<TChildProps, TDataName>>(ViewerReportsDocument, {
      alias: 'viewerReports',
      ...operationOptions
    });
};

/**
 * __useViewerReportsQuery__
 *
 * To run a query within a React component, call `useViewerReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerReportsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useViewerReportsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewerReportsQuery, ViewerReportsQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewerReportsQuery, ViewerReportsQueryVariables>(ViewerReportsDocument, baseOptions);
      }
export function useViewerReportsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewerReportsQuery, ViewerReportsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewerReportsQuery, ViewerReportsQueryVariables>(ViewerReportsDocument, baseOptions);
        }
export type ViewerReportsQueryHookResult = ReturnType<typeof useViewerReportsQuery>;
export type ViewerReportsLazyQueryHookResult = ReturnType<typeof useViewerReportsLazyQuery>;
export type ViewerReportsQueryResult = ApolloReactCommon.QueryResult<ViewerReportsQuery, ViewerReportsQueryVariables>;
export const ViewerRunsDocument = gql`
    query ViewerRuns($entityName: String, $first: Int = 100) {
  viewer(entityName: $entityName) {
    id
    runs(first: $first) {
      edges {
        node {
          id
          ...RunsTableFragment
        }
      }
    }
  }
}
    ${RunsTableFragmentFragmentDoc}`;
export type ViewerRunsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewerRunsQuery, ViewerRunsQueryVariables>, 'query'>;

    export const ViewerRunsComponent = (props: ViewerRunsComponentProps) => (
      <ApolloReactComponents.Query<ViewerRunsQuery, ViewerRunsQueryVariables> query={ViewerRunsDocument} {...props} />
    );
    
export type ViewerRunsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewerRunsQuery, ViewerRunsQueryVariables>
    } & TChildProps;
export function withViewerRuns<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewerRunsQuery,
  ViewerRunsQueryVariables,
  ViewerRunsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewerRunsQuery, ViewerRunsQueryVariables, ViewerRunsProps<TChildProps, TDataName>>(ViewerRunsDocument, {
      alias: 'viewerRuns',
      ...operationOptions
    });
};

/**
 * __useViewerRunsQuery__
 *
 * To run a query within a React component, call `useViewerRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerRunsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useViewerRunsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewerRunsQuery, ViewerRunsQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewerRunsQuery, ViewerRunsQueryVariables>(ViewerRunsDocument, baseOptions);
      }
export function useViewerRunsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewerRunsQuery, ViewerRunsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewerRunsQuery, ViewerRunsQueryVariables>(ViewerRunsDocument, baseOptions);
        }
export type ViewerRunsQueryHookResult = ReturnType<typeof useViewerRunsQuery>;
export type ViewerRunsLazyQueryHookResult = ReturnType<typeof useViewerRunsLazyQuery>;
export type ViewerRunsQueryResult = ApolloReactCommon.QueryResult<ViewerRunsQuery, ViewerRunsQueryVariables>;
export const EntityProjectsDocument = gql`
    query EntityProjects($entityName: String!, $first: Int = 20) {
  entity(name: $entityName) {
    id
    projects(first: $first) {
      edges {
        node {
          id
          ...AccountProjectFragment
        }
      }
    }
  }
}
    ${AccountProjectFragmentFragmentDoc}`;
export type EntityProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityProjectsQuery, EntityProjectsQueryVariables>, 'query'> & ({ variables: EntityProjectsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntityProjectsComponent = (props: EntityProjectsComponentProps) => (
      <ApolloReactComponents.Query<EntityProjectsQuery, EntityProjectsQueryVariables> query={EntityProjectsDocument} {...props} />
    );
    
export type EntityProjectsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityProjectsQuery, EntityProjectsQueryVariables>
    } & TChildProps;
export function withEntityProjects<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityProjectsQuery,
  EntityProjectsQueryVariables,
  EntityProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityProjectsQuery, EntityProjectsQueryVariables, EntityProjectsProps<TChildProps, TDataName>>(EntityProjectsDocument, {
      alias: 'entityProjects',
      ...operationOptions
    });
};

/**
 * __useEntityProjectsQuery__
 *
 * To run a query within a React component, call `useEntityProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityProjectsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useEntityProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityProjectsQuery, EntityProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityProjectsQuery, EntityProjectsQueryVariables>(EntityProjectsDocument, baseOptions);
      }
export function useEntityProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityProjectsQuery, EntityProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityProjectsQuery, EntityProjectsQueryVariables>(EntityProjectsDocument, baseOptions);
        }
export type EntityProjectsQueryHookResult = ReturnType<typeof useEntityProjectsQuery>;
export type EntityProjectsLazyQueryHookResult = ReturnType<typeof useEntityProjectsLazyQuery>;
export type EntityProjectsQueryResult = ApolloReactCommon.QueryResult<EntityProjectsQuery, EntityProjectsQueryVariables>;
export const EntityReportsDocument = gql`
    query EntityReports($entityName: String!) {
  entity(name: $entityName) {
    id
    views(first: 100) {
      edges {
        node {
          id
          ...ReportsTableFragment
        }
      }
    }
  }
}
    ${ReportsTableFragmentFragmentDoc}`;
export type EntityReportsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityReportsQuery, EntityReportsQueryVariables>, 'query'> & ({ variables: EntityReportsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntityReportsComponent = (props: EntityReportsComponentProps) => (
      <ApolloReactComponents.Query<EntityReportsQuery, EntityReportsQueryVariables> query={EntityReportsDocument} {...props} />
    );
    
export type EntityReportsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityReportsQuery, EntityReportsQueryVariables>
    } & TChildProps;
export function withEntityReports<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityReportsQuery,
  EntityReportsQueryVariables,
  EntityReportsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityReportsQuery, EntityReportsQueryVariables, EntityReportsProps<TChildProps, TDataName>>(EntityReportsDocument, {
      alias: 'entityReports',
      ...operationOptions
    });
};

/**
 * __useEntityReportsQuery__
 *
 * To run a query within a React component, call `useEntityReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityReportsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useEntityReportsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityReportsQuery, EntityReportsQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityReportsQuery, EntityReportsQueryVariables>(EntityReportsDocument, baseOptions);
      }
export function useEntityReportsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityReportsQuery, EntityReportsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityReportsQuery, EntityReportsQueryVariables>(EntityReportsDocument, baseOptions);
        }
export type EntityReportsQueryHookResult = ReturnType<typeof useEntityReportsQuery>;
export type EntityReportsLazyQueryHookResult = ReturnType<typeof useEntityReportsLazyQuery>;
export type EntityReportsQueryResult = ApolloReactCommon.QueryResult<EntityReportsQuery, EntityReportsQueryVariables>;
export const OrganizationReportsDocument = gql`
    query OrganizationReports($orgID: ID!, $userID: ID) {
  organization(id: $orgID) {
    id
    views(first: 100, userID: $userID) {
      edges {
        node {
          id
          ...ReportsTableFragment
        }
      }
    }
  }
}
    ${ReportsTableFragmentFragmentDoc}`;
export type OrganizationReportsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationReportsQuery, OrganizationReportsQueryVariables>, 'query'> & ({ variables: OrganizationReportsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationReportsComponent = (props: OrganizationReportsComponentProps) => (
      <ApolloReactComponents.Query<OrganizationReportsQuery, OrganizationReportsQueryVariables> query={OrganizationReportsDocument} {...props} />
    );
    
export type OrganizationReportsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationReportsQuery, OrganizationReportsQueryVariables>
    } & TChildProps;
export function withOrganizationReports<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationReportsQuery,
  OrganizationReportsQueryVariables,
  OrganizationReportsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationReportsQuery, OrganizationReportsQueryVariables, OrganizationReportsProps<TChildProps, TDataName>>(OrganizationReportsDocument, {
      alias: 'organizationReports',
      ...operationOptions
    });
};

/**
 * __useOrganizationReportsQuery__
 *
 * To run a query within a React component, call `useOrganizationReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationReportsQuery({
 *   variables: {
 *      orgID: // value for 'orgID'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useOrganizationReportsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationReportsQuery, OrganizationReportsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationReportsQuery, OrganizationReportsQueryVariables>(OrganizationReportsDocument, baseOptions);
      }
export function useOrganizationReportsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationReportsQuery, OrganizationReportsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationReportsQuery, OrganizationReportsQueryVariables>(OrganizationReportsDocument, baseOptions);
        }
export type OrganizationReportsQueryHookResult = ReturnType<typeof useOrganizationReportsQuery>;
export type OrganizationReportsLazyQueryHookResult = ReturnType<typeof useOrganizationReportsLazyQuery>;
export type OrganizationReportsQueryResult = ApolloReactCommon.QueryResult<OrganizationReportsQuery, OrganizationReportsQueryVariables>;
export const AccountTeamsDocument = gql`
    query AccountTeams {
  viewer {
    id
    name
    username
    teams {
      edges {
        node {
          id
          isTeam
          organizationId
          name
          defaultAccess
          privateOnly
          members {
            id
            name
            username
            memberRole {
              name
            }
            email
            pending
            photoUrl
            accountType
            role
          }
        }
      }
    }
  }
}
    `;
export type AccountTeamsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AccountTeamsQuery, AccountTeamsQueryVariables>, 'query'>;

    export const AccountTeamsComponent = (props: AccountTeamsComponentProps) => (
      <ApolloReactComponents.Query<AccountTeamsQuery, AccountTeamsQueryVariables> query={AccountTeamsDocument} {...props} />
    );
    
export type AccountTeamsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AccountTeamsQuery, AccountTeamsQueryVariables>
    } & TChildProps;
export function withAccountTeams<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AccountTeamsQuery,
  AccountTeamsQueryVariables,
  AccountTeamsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AccountTeamsQuery, AccountTeamsQueryVariables, AccountTeamsProps<TChildProps, TDataName>>(AccountTeamsDocument, {
      alias: 'accountTeams',
      ...operationOptions
    });
};

/**
 * __useAccountTeamsQuery__
 *
 * To run a query within a React component, call `useAccountTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountTeamsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAccountTeamsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountTeamsQuery, AccountTeamsQueryVariables>) {
        return ApolloReactHooks.useQuery<AccountTeamsQuery, AccountTeamsQueryVariables>(AccountTeamsDocument, baseOptions);
      }
export function useAccountTeamsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountTeamsQuery, AccountTeamsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AccountTeamsQuery, AccountTeamsQueryVariables>(AccountTeamsDocument, baseOptions);
        }
export type AccountTeamsQueryHookResult = ReturnType<typeof useAccountTeamsQuery>;
export type AccountTeamsLazyQueryHookResult = ReturnType<typeof useAccountTeamsLazyQuery>;
export type AccountTeamsQueryResult = ApolloReactCommon.QueryResult<AccountTeamsQuery, AccountTeamsQueryVariables>;
export const CreateSlackIntegrationDocument = gql`
    mutation CreateSlackIntegration($code: String!, $entityName: String!, $redirectURI: String!) {
  createSlackIntegration(
    input: {code: $code, entityName: $entityName, redirectURI: $redirectURI}
  ) {
    integration {
      id
    }
  }
}
    `;
export type CreateSlackIntegrationMutationFn = ApolloReactCommon.MutationFunction<CreateSlackIntegrationMutation, CreateSlackIntegrationMutationVariables>;
export type CreateSlackIntegrationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateSlackIntegrationMutation, CreateSlackIntegrationMutationVariables>, 'mutation'>;

    export const CreateSlackIntegrationComponent = (props: CreateSlackIntegrationComponentProps) => (
      <ApolloReactComponents.Mutation<CreateSlackIntegrationMutation, CreateSlackIntegrationMutationVariables> mutation={CreateSlackIntegrationDocument} {...props} />
    );
    
export type CreateSlackIntegrationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateSlackIntegrationMutation, CreateSlackIntegrationMutationVariables>
    } & TChildProps;
export function withCreateSlackIntegration<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateSlackIntegrationMutation,
  CreateSlackIntegrationMutationVariables,
  CreateSlackIntegrationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateSlackIntegrationMutation, CreateSlackIntegrationMutationVariables, CreateSlackIntegrationProps<TChildProps, TDataName>>(CreateSlackIntegrationDocument, {
      alias: 'createSlackIntegration',
      ...operationOptions
    });
};

/**
 * __useCreateSlackIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateSlackIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSlackIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSlackIntegrationMutation, { data, loading, error }] = useCreateSlackIntegrationMutation({
 *   variables: {
 *      code: // value for 'code'
 *      entityName: // value for 'entityName'
 *      redirectURI: // value for 'redirectURI'
 *   },
 * });
 */
export function useCreateSlackIntegrationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSlackIntegrationMutation, CreateSlackIntegrationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSlackIntegrationMutation, CreateSlackIntegrationMutationVariables>(CreateSlackIntegrationDocument, baseOptions);
      }
export type CreateSlackIntegrationMutationHookResult = ReturnType<typeof useCreateSlackIntegrationMutation>;
export type CreateSlackIntegrationMutationResult = ApolloReactCommon.MutationResult<CreateSlackIntegrationMutation>;
export type CreateSlackIntegrationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSlackIntegrationMutation, CreateSlackIntegrationMutationVariables>;
export const CreateGitHubIntegrationDocument = gql`
    mutation CreateGitHubIntegration($code: String!, $entityName: String!, $redirectURI: String!, $state: String!) {
  createGitHubOAuthIntegration(
    input: {code: $code, entityName: $entityName, state: $state, redirectURI: $redirectURI}
  ) {
    integration {
      id
    }
  }
}
    `;
export type CreateGitHubIntegrationMutationFn = ApolloReactCommon.MutationFunction<CreateGitHubIntegrationMutation, CreateGitHubIntegrationMutationVariables>;
export type CreateGitHubIntegrationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateGitHubIntegrationMutation, CreateGitHubIntegrationMutationVariables>, 'mutation'>;

    export const CreateGitHubIntegrationComponent = (props: CreateGitHubIntegrationComponentProps) => (
      <ApolloReactComponents.Mutation<CreateGitHubIntegrationMutation, CreateGitHubIntegrationMutationVariables> mutation={CreateGitHubIntegrationDocument} {...props} />
    );
    
export type CreateGitHubIntegrationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateGitHubIntegrationMutation, CreateGitHubIntegrationMutationVariables>
    } & TChildProps;
export function withCreateGitHubIntegration<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateGitHubIntegrationMutation,
  CreateGitHubIntegrationMutationVariables,
  CreateGitHubIntegrationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateGitHubIntegrationMutation, CreateGitHubIntegrationMutationVariables, CreateGitHubIntegrationProps<TChildProps, TDataName>>(CreateGitHubIntegrationDocument, {
      alias: 'createGitHubIntegration',
      ...operationOptions
    });
};

/**
 * __useCreateGitHubIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateGitHubIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGitHubIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGitHubIntegrationMutation, { data, loading, error }] = useCreateGitHubIntegrationMutation({
 *   variables: {
 *      code: // value for 'code'
 *      entityName: // value for 'entityName'
 *      redirectURI: // value for 'redirectURI'
 *      state: // value for 'state'
 *   },
 * });
 */
export function useCreateGitHubIntegrationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGitHubIntegrationMutation, CreateGitHubIntegrationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGitHubIntegrationMutation, CreateGitHubIntegrationMutationVariables>(CreateGitHubIntegrationDocument, baseOptions);
      }
export type CreateGitHubIntegrationMutationHookResult = ReturnType<typeof useCreateGitHubIntegrationMutation>;
export type CreateGitHubIntegrationMutationResult = ApolloReactCommon.MutationResult<CreateGitHubIntegrationMutation>;
export type CreateGitHubIntegrationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGitHubIntegrationMutation, CreateGitHubIntegrationMutationVariables>;
export const CreateGenericWebhookIntegrationDocument = gql`
    mutation CreateGenericWebhookIntegration($entityName: String!, $webhookName: String!, $urlEndpoint: String!, $accessTokenRef: String, $secretRef: String) {
  createGenericWebhookIntegration(
    input: {entityName: $entityName, name: $webhookName, urlEndpoint: $urlEndpoint, accessTokenRef: $accessTokenRef, secretRef: $secretRef}
  ) {
    integration {
      __typename
      ... on GenericWebhookIntegration {
        id
        urlEndpoint
        name
      }
    }
  }
}
    `;
export type CreateGenericWebhookIntegrationMutationFn = ApolloReactCommon.MutationFunction<CreateGenericWebhookIntegrationMutation, CreateGenericWebhookIntegrationMutationVariables>;
export type CreateGenericWebhookIntegrationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateGenericWebhookIntegrationMutation, CreateGenericWebhookIntegrationMutationVariables>, 'mutation'>;

    export const CreateGenericWebhookIntegrationComponent = (props: CreateGenericWebhookIntegrationComponentProps) => (
      <ApolloReactComponents.Mutation<CreateGenericWebhookIntegrationMutation, CreateGenericWebhookIntegrationMutationVariables> mutation={CreateGenericWebhookIntegrationDocument} {...props} />
    );
    
export type CreateGenericWebhookIntegrationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateGenericWebhookIntegrationMutation, CreateGenericWebhookIntegrationMutationVariables>
    } & TChildProps;
export function withCreateGenericWebhookIntegration<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateGenericWebhookIntegrationMutation,
  CreateGenericWebhookIntegrationMutationVariables,
  CreateGenericWebhookIntegrationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateGenericWebhookIntegrationMutation, CreateGenericWebhookIntegrationMutationVariables, CreateGenericWebhookIntegrationProps<TChildProps, TDataName>>(CreateGenericWebhookIntegrationDocument, {
      alias: 'createGenericWebhookIntegration',
      ...operationOptions
    });
};

/**
 * __useCreateGenericWebhookIntegrationMutation__
 *
 * To run a mutation, you first call `useCreateGenericWebhookIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateGenericWebhookIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createGenericWebhookIntegrationMutation, { data, loading, error }] = useCreateGenericWebhookIntegrationMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      webhookName: // value for 'webhookName'
 *      urlEndpoint: // value for 'urlEndpoint'
 *      accessTokenRef: // value for 'accessTokenRef'
 *      secretRef: // value for 'secretRef'
 *   },
 * });
 */
export function useCreateGenericWebhookIntegrationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateGenericWebhookIntegrationMutation, CreateGenericWebhookIntegrationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateGenericWebhookIntegrationMutation, CreateGenericWebhookIntegrationMutationVariables>(CreateGenericWebhookIntegrationDocument, baseOptions);
      }
export type CreateGenericWebhookIntegrationMutationHookResult = ReturnType<typeof useCreateGenericWebhookIntegrationMutation>;
export type CreateGenericWebhookIntegrationMutationResult = ApolloReactCommon.MutationResult<CreateGenericWebhookIntegrationMutation>;
export type CreateGenericWebhookIntegrationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateGenericWebhookIntegrationMutation, CreateGenericWebhookIntegrationMutationVariables>;
export const UpdateGenericWebhookIntegrationDocument = gql`
    mutation UpdateGenericWebhookIntegration($integrationID: ID!, $webhookName: String, $urlEndpoint: String, $accessTokenRef: String, $secretRef: String) {
  updateGenericWebhookIntegration(
    input: {id: $integrationID, name: $webhookName, urlEndpoint: $urlEndpoint, accessTokenRef: $accessTokenRef, secretRef: $secretRef}
  ) {
    integration {
      __typename
      ... on GenericWebhookIntegration {
        id
        urlEndpoint
        name
      }
    }
  }
}
    `;
export type UpdateGenericWebhookIntegrationMutationFn = ApolloReactCommon.MutationFunction<UpdateGenericWebhookIntegrationMutation, UpdateGenericWebhookIntegrationMutationVariables>;
export type UpdateGenericWebhookIntegrationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateGenericWebhookIntegrationMutation, UpdateGenericWebhookIntegrationMutationVariables>, 'mutation'>;

    export const UpdateGenericWebhookIntegrationComponent = (props: UpdateGenericWebhookIntegrationComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateGenericWebhookIntegrationMutation, UpdateGenericWebhookIntegrationMutationVariables> mutation={UpdateGenericWebhookIntegrationDocument} {...props} />
    );
    
export type UpdateGenericWebhookIntegrationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateGenericWebhookIntegrationMutation, UpdateGenericWebhookIntegrationMutationVariables>
    } & TChildProps;
export function withUpdateGenericWebhookIntegration<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateGenericWebhookIntegrationMutation,
  UpdateGenericWebhookIntegrationMutationVariables,
  UpdateGenericWebhookIntegrationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateGenericWebhookIntegrationMutation, UpdateGenericWebhookIntegrationMutationVariables, UpdateGenericWebhookIntegrationProps<TChildProps, TDataName>>(UpdateGenericWebhookIntegrationDocument, {
      alias: 'updateGenericWebhookIntegration',
      ...operationOptions
    });
};

/**
 * __useUpdateGenericWebhookIntegrationMutation__
 *
 * To run a mutation, you first call `useUpdateGenericWebhookIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGenericWebhookIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGenericWebhookIntegrationMutation, { data, loading, error }] = useUpdateGenericWebhookIntegrationMutation({
 *   variables: {
 *      integrationID: // value for 'integrationID'
 *      webhookName: // value for 'webhookName'
 *      urlEndpoint: // value for 'urlEndpoint'
 *      accessTokenRef: // value for 'accessTokenRef'
 *      secretRef: // value for 'secretRef'
 *   },
 * });
 */
export function useUpdateGenericWebhookIntegrationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateGenericWebhookIntegrationMutation, UpdateGenericWebhookIntegrationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateGenericWebhookIntegrationMutation, UpdateGenericWebhookIntegrationMutationVariables>(UpdateGenericWebhookIntegrationDocument, baseOptions);
      }
export type UpdateGenericWebhookIntegrationMutationHookResult = ReturnType<typeof useUpdateGenericWebhookIntegrationMutation>;
export type UpdateGenericWebhookIntegrationMutationResult = ApolloReactCommon.MutationResult<UpdateGenericWebhookIntegrationMutation>;
export type UpdateGenericWebhookIntegrationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateGenericWebhookIntegrationMutation, UpdateGenericWebhookIntegrationMutationVariables>;
export const TestGenericWebhookIntegrationDocument = gql`
    mutation TestGenericWebhookIntegration($entityName: String!, $urlEndpoint: String!, $accessTokenRef: String, $secretRef: String, $requestPayload: JSONString) {
  testGenericWebhookIntegration(
    input: {entityName: $entityName, urlEndpoint: $urlEndpoint, accessTokenRef: $accessTokenRef, secretRef: $secretRef, requestPayload: $requestPayload}
  ) {
    ok
    response
  }
}
    `;
export type TestGenericWebhookIntegrationMutationFn = ApolloReactCommon.MutationFunction<TestGenericWebhookIntegrationMutation, TestGenericWebhookIntegrationMutationVariables>;
export type TestGenericWebhookIntegrationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TestGenericWebhookIntegrationMutation, TestGenericWebhookIntegrationMutationVariables>, 'mutation'>;

    export const TestGenericWebhookIntegrationComponent = (props: TestGenericWebhookIntegrationComponentProps) => (
      <ApolloReactComponents.Mutation<TestGenericWebhookIntegrationMutation, TestGenericWebhookIntegrationMutationVariables> mutation={TestGenericWebhookIntegrationDocument} {...props} />
    );
    
export type TestGenericWebhookIntegrationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<TestGenericWebhookIntegrationMutation, TestGenericWebhookIntegrationMutationVariables>
    } & TChildProps;
export function withTestGenericWebhookIntegration<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TestGenericWebhookIntegrationMutation,
  TestGenericWebhookIntegrationMutationVariables,
  TestGenericWebhookIntegrationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, TestGenericWebhookIntegrationMutation, TestGenericWebhookIntegrationMutationVariables, TestGenericWebhookIntegrationProps<TChildProps, TDataName>>(TestGenericWebhookIntegrationDocument, {
      alias: 'testGenericWebhookIntegration',
      ...operationOptions
    });
};

/**
 * __useTestGenericWebhookIntegrationMutation__
 *
 * To run a mutation, you first call `useTestGenericWebhookIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestGenericWebhookIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testGenericWebhookIntegrationMutation, { data, loading, error }] = useTestGenericWebhookIntegrationMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      urlEndpoint: // value for 'urlEndpoint'
 *      accessTokenRef: // value for 'accessTokenRef'
 *      secretRef: // value for 'secretRef'
 *      requestPayload: // value for 'requestPayload'
 *   },
 * });
 */
export function useTestGenericWebhookIntegrationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TestGenericWebhookIntegrationMutation, TestGenericWebhookIntegrationMutationVariables>) {
        return ApolloReactHooks.useMutation<TestGenericWebhookIntegrationMutation, TestGenericWebhookIntegrationMutationVariables>(TestGenericWebhookIntegrationDocument, baseOptions);
      }
export type TestGenericWebhookIntegrationMutationHookResult = ReturnType<typeof useTestGenericWebhookIntegrationMutation>;
export type TestGenericWebhookIntegrationMutationResult = ApolloReactCommon.MutationResult<TestGenericWebhookIntegrationMutation>;
export type TestGenericWebhookIntegrationMutationOptions = ApolloReactCommon.BaseMutationOptions<TestGenericWebhookIntegrationMutation, TestGenericWebhookIntegrationMutationVariables>;
export const DeleteIntegrationDocument = gql`
    mutation DeleteIntegration($id: ID!) {
  deleteIntegration(input: {id: $id}) {
    success
  }
}
    `;
export type DeleteIntegrationMutationFn = ApolloReactCommon.MutationFunction<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>;
export type DeleteIntegrationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>, 'mutation'>;

    export const DeleteIntegrationComponent = (props: DeleteIntegrationComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteIntegrationMutation, DeleteIntegrationMutationVariables> mutation={DeleteIntegrationDocument} {...props} />
    );
    
export type DeleteIntegrationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>
    } & TChildProps;
export function withDeleteIntegration<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteIntegrationMutation,
  DeleteIntegrationMutationVariables,
  DeleteIntegrationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteIntegrationMutation, DeleteIntegrationMutationVariables, DeleteIntegrationProps<TChildProps, TDataName>>(DeleteIntegrationDocument, {
      alias: 'deleteIntegration',
      ...operationOptions
    });
};

/**
 * __useDeleteIntegrationMutation__
 *
 * To run a mutation, you first call `useDeleteIntegrationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteIntegrationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteIntegrationMutation, { data, loading, error }] = useDeleteIntegrationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteIntegrationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>(DeleteIntegrationDocument, baseOptions);
      }
export type DeleteIntegrationMutationHookResult = ReturnType<typeof useDeleteIntegrationMutation>;
export type DeleteIntegrationMutationResult = ApolloReactCommon.MutationResult<DeleteIntegrationMutation>;
export type DeleteIntegrationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteIntegrationMutation, DeleteIntegrationMutationVariables>;
export const FetchLaunchAgentsFromProjectDocument = gql`
    query fetchLaunchAgentsFromProject($entityName: String!, $projectName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    launchAgents {
      id
      name
      createdAt
      agentStatus
      stopPolling
      runQueues
      hostname
      agentConfig
      version
    }
  }
}
    `;
export type FetchLaunchAgentsFromProjectComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FetchLaunchAgentsFromProjectQuery, FetchLaunchAgentsFromProjectQueryVariables>, 'query'> & ({ variables: FetchLaunchAgentsFromProjectQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FetchLaunchAgentsFromProjectComponent = (props: FetchLaunchAgentsFromProjectComponentProps) => (
      <ApolloReactComponents.Query<FetchLaunchAgentsFromProjectQuery, FetchLaunchAgentsFromProjectQueryVariables> query={FetchLaunchAgentsFromProjectDocument} {...props} />
    );
    
export type FetchLaunchAgentsFromProjectProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FetchLaunchAgentsFromProjectQuery, FetchLaunchAgentsFromProjectQueryVariables>
    } & TChildProps;
export function withFetchLaunchAgentsFromProject<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FetchLaunchAgentsFromProjectQuery,
  FetchLaunchAgentsFromProjectQueryVariables,
  FetchLaunchAgentsFromProjectProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FetchLaunchAgentsFromProjectQuery, FetchLaunchAgentsFromProjectQueryVariables, FetchLaunchAgentsFromProjectProps<TChildProps, TDataName>>(FetchLaunchAgentsFromProjectDocument, {
      alias: 'fetchLaunchAgentsFromProject',
      ...operationOptions
    });
};

/**
 * __useFetchLaunchAgentsFromProjectQuery__
 *
 * To run a query within a React component, call `useFetchLaunchAgentsFromProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchLaunchAgentsFromProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchLaunchAgentsFromProjectQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *   },
 * });
 */
export function useFetchLaunchAgentsFromProjectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchLaunchAgentsFromProjectQuery, FetchLaunchAgentsFromProjectQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchLaunchAgentsFromProjectQuery, FetchLaunchAgentsFromProjectQueryVariables>(FetchLaunchAgentsFromProjectDocument, baseOptions);
      }
export function useFetchLaunchAgentsFromProjectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchLaunchAgentsFromProjectQuery, FetchLaunchAgentsFromProjectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchLaunchAgentsFromProjectQuery, FetchLaunchAgentsFromProjectQueryVariables>(FetchLaunchAgentsFromProjectDocument, baseOptions);
        }
export type FetchLaunchAgentsFromProjectQueryHookResult = ReturnType<typeof useFetchLaunchAgentsFromProjectQuery>;
export type FetchLaunchAgentsFromProjectLazyQueryHookResult = ReturnType<typeof useFetchLaunchAgentsFromProjectLazyQuery>;
export type FetchLaunchAgentsFromProjectQueryResult = ApolloReactCommon.QueryResult<FetchLaunchAgentsFromProjectQuery, FetchLaunchAgentsFromProjectQueryVariables>;
export const StopLaunchAgentDocument = gql`
    mutation stopLaunchAgent($agentId: ID!, $stopPolling: Boolean) {
  updateLaunchAgent(input: {launchAgentId: $agentId, stopPolling: $stopPolling}) {
    success
  }
}
    `;
export type StopLaunchAgentMutationFn = ApolloReactCommon.MutationFunction<StopLaunchAgentMutation, StopLaunchAgentMutationVariables>;
export type StopLaunchAgentComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<StopLaunchAgentMutation, StopLaunchAgentMutationVariables>, 'mutation'>;

    export const StopLaunchAgentComponent = (props: StopLaunchAgentComponentProps) => (
      <ApolloReactComponents.Mutation<StopLaunchAgentMutation, StopLaunchAgentMutationVariables> mutation={StopLaunchAgentDocument} {...props} />
    );
    
export type StopLaunchAgentProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<StopLaunchAgentMutation, StopLaunchAgentMutationVariables>
    } & TChildProps;
export function withStopLaunchAgent<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  StopLaunchAgentMutation,
  StopLaunchAgentMutationVariables,
  StopLaunchAgentProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, StopLaunchAgentMutation, StopLaunchAgentMutationVariables, StopLaunchAgentProps<TChildProps, TDataName>>(StopLaunchAgentDocument, {
      alias: 'stopLaunchAgent',
      ...operationOptions
    });
};

/**
 * __useStopLaunchAgentMutation__
 *
 * To run a mutation, you first call `useStopLaunchAgentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopLaunchAgentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopLaunchAgentMutation, { data, loading, error }] = useStopLaunchAgentMutation({
 *   variables: {
 *      agentId: // value for 'agentId'
 *      stopPolling: // value for 'stopPolling'
 *   },
 * });
 */
export function useStopLaunchAgentMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StopLaunchAgentMutation, StopLaunchAgentMutationVariables>) {
        return ApolloReactHooks.useMutation<StopLaunchAgentMutation, StopLaunchAgentMutationVariables>(StopLaunchAgentDocument, baseOptions);
      }
export type StopLaunchAgentMutationHookResult = ReturnType<typeof useStopLaunchAgentMutation>;
export type StopLaunchAgentMutationResult = ApolloReactCommon.MutationResult<StopLaunchAgentMutation>;
export type StopLaunchAgentMutationOptions = ApolloReactCommon.BaseMutationOptions<StopLaunchAgentMutation, StopLaunchAgentMutationVariables>;
export const DeleteLaunchAgentsDocument = gql`
    mutation deleteLaunchAgents($agentIds: [ID!]!) {
  deleteLaunchAgents(input: {launchAgentIds: $agentIds}) {
    success
  }
}
    `;
export type DeleteLaunchAgentsMutationFn = ApolloReactCommon.MutationFunction<DeleteLaunchAgentsMutation, DeleteLaunchAgentsMutationVariables>;
export type DeleteLaunchAgentsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteLaunchAgentsMutation, DeleteLaunchAgentsMutationVariables>, 'mutation'>;

    export const DeleteLaunchAgentsComponent = (props: DeleteLaunchAgentsComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteLaunchAgentsMutation, DeleteLaunchAgentsMutationVariables> mutation={DeleteLaunchAgentsDocument} {...props} />
    );
    
export type DeleteLaunchAgentsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteLaunchAgentsMutation, DeleteLaunchAgentsMutationVariables>
    } & TChildProps;
export function withDeleteLaunchAgents<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteLaunchAgentsMutation,
  DeleteLaunchAgentsMutationVariables,
  DeleteLaunchAgentsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteLaunchAgentsMutation, DeleteLaunchAgentsMutationVariables, DeleteLaunchAgentsProps<TChildProps, TDataName>>(DeleteLaunchAgentsDocument, {
      alias: 'deleteLaunchAgents',
      ...operationOptions
    });
};

/**
 * __useDeleteLaunchAgentsMutation__
 *
 * To run a mutation, you first call `useDeleteLaunchAgentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteLaunchAgentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteLaunchAgentsMutation, { data, loading, error }] = useDeleteLaunchAgentsMutation({
 *   variables: {
 *      agentIds: // value for 'agentIds'
 *   },
 * });
 */
export function useDeleteLaunchAgentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteLaunchAgentsMutation, DeleteLaunchAgentsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteLaunchAgentsMutation, DeleteLaunchAgentsMutationVariables>(DeleteLaunchAgentsDocument, baseOptions);
      }
export type DeleteLaunchAgentsMutationHookResult = ReturnType<typeof useDeleteLaunchAgentsMutation>;
export type DeleteLaunchAgentsMutationResult = ApolloReactCommon.MutationResult<DeleteLaunchAgentsMutation>;
export type DeleteLaunchAgentsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteLaunchAgentsMutation, DeleteLaunchAgentsMutationVariables>;
export const HasLocalLicenseDocument = gql`
    query HasLocalLicense {
  serverInfo {
    hasLocalLicense
  }
}
    `;
export type HasLocalLicenseComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<HasLocalLicenseQuery, HasLocalLicenseQueryVariables>, 'query'>;

    export const HasLocalLicenseComponent = (props: HasLocalLicenseComponentProps) => (
      <ApolloReactComponents.Query<HasLocalLicenseQuery, HasLocalLicenseQueryVariables> query={HasLocalLicenseDocument} {...props} />
    );
    
export type HasLocalLicenseProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<HasLocalLicenseQuery, HasLocalLicenseQueryVariables>
    } & TChildProps;
export function withHasLocalLicense<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  HasLocalLicenseQuery,
  HasLocalLicenseQueryVariables,
  HasLocalLicenseProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, HasLocalLicenseQuery, HasLocalLicenseQueryVariables, HasLocalLicenseProps<TChildProps, TDataName>>(HasLocalLicenseDocument, {
      alias: 'hasLocalLicense',
      ...operationOptions
    });
};

/**
 * __useHasLocalLicenseQuery__
 *
 * To run a query within a React component, call `useHasLocalLicenseQuery` and pass it any options that fit your needs.
 * When your component renders, `useHasLocalLicenseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHasLocalLicenseQuery({
 *   variables: {
 *   },
 * });
 */
export function useHasLocalLicenseQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HasLocalLicenseQuery, HasLocalLicenseQueryVariables>) {
        return ApolloReactHooks.useQuery<HasLocalLicenseQuery, HasLocalLicenseQueryVariables>(HasLocalLicenseDocument, baseOptions);
      }
export function useHasLocalLicenseLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HasLocalLicenseQuery, HasLocalLicenseQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HasLocalLicenseQuery, HasLocalLicenseQueryVariables>(HasLocalLicenseDocument, baseOptions);
        }
export type HasLocalLicenseQueryHookResult = ReturnType<typeof useHasLocalLicenseQuery>;
export type HasLocalLicenseLazyQueryHookResult = ReturnType<typeof useHasLocalLicenseLazyQuery>;
export type HasLocalLicenseQueryResult = ApolloReactCommon.QueryResult<HasLocalLicenseQuery, HasLocalLicenseQueryVariables>;
export const LatestLocalVersionInfoDocument = gql`
    query latestLocalVersionInfo {
  serverInfo {
    latestLocalVersionInfo {
      outOfDate
      latestVersionString
      versionOnThisInstanceString
    }
  }
}
    `;
export type LatestLocalVersionInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LatestLocalVersionInfoQuery, LatestLocalVersionInfoQueryVariables>, 'query'>;

    export const LatestLocalVersionInfoComponent = (props: LatestLocalVersionInfoComponentProps) => (
      <ApolloReactComponents.Query<LatestLocalVersionInfoQuery, LatestLocalVersionInfoQueryVariables> query={LatestLocalVersionInfoDocument} {...props} />
    );
    
export type LatestLocalVersionInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<LatestLocalVersionInfoQuery, LatestLocalVersionInfoQueryVariables>
    } & TChildProps;
export function withLatestLocalVersionInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LatestLocalVersionInfoQuery,
  LatestLocalVersionInfoQueryVariables,
  LatestLocalVersionInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, LatestLocalVersionInfoQuery, LatestLocalVersionInfoQueryVariables, LatestLocalVersionInfoProps<TChildProps, TDataName>>(LatestLocalVersionInfoDocument, {
      alias: 'latestLocalVersionInfo',
      ...operationOptions
    });
};

/**
 * __useLatestLocalVersionInfoQuery__
 *
 * To run a query within a React component, call `useLatestLocalVersionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useLatestLocalVersionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLatestLocalVersionInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useLatestLocalVersionInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LatestLocalVersionInfoQuery, LatestLocalVersionInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<LatestLocalVersionInfoQuery, LatestLocalVersionInfoQueryVariables>(LatestLocalVersionInfoDocument, baseOptions);
      }
export function useLatestLocalVersionInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LatestLocalVersionInfoQuery, LatestLocalVersionInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LatestLocalVersionInfoQuery, LatestLocalVersionInfoQueryVariables>(LatestLocalVersionInfoDocument, baseOptions);
        }
export type LatestLocalVersionInfoQueryHookResult = ReturnType<typeof useLatestLocalVersionInfoQuery>;
export type LatestLocalVersionInfoLazyQueryHookResult = ReturnType<typeof useLatestLocalVersionInfoLazyQuery>;
export type LatestLocalVersionInfoQueryResult = ApolloReactCommon.QueryResult<LatestLocalVersionInfoQuery, LatestLocalVersionInfoQueryVariables>;
export const AvailableFilesQueryDocument = gql`
    query AvailableFilesQuery($projectName: String!, $entityName: String!, $runName: String!, $filenames: [String!]!, $includeDirectUrl: Boolean!) {
  project(entityName: $entityName, name: $projectName) {
    id
    run(name: $runName) {
      id
      files(names: $filenames) {
        edges {
          node {
            id
            name
            directUrl @include(if: $includeDirectUrl)
            url(upload: false)
            md5
            sizeBytes
          }
        }
      }
    }
  }
}
    `;
export type AvailableFilesQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AvailableFilesQueryQuery, AvailableFilesQueryQueryVariables>, 'query'> & ({ variables: AvailableFilesQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AvailableFilesQueryComponent = (props: AvailableFilesQueryComponentProps) => (
      <ApolloReactComponents.Query<AvailableFilesQueryQuery, AvailableFilesQueryQueryVariables> query={AvailableFilesQueryDocument} {...props} />
    );
    
export type AvailableFilesQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AvailableFilesQueryQuery, AvailableFilesQueryQueryVariables>
    } & TChildProps;
export function withAvailableFilesQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AvailableFilesQueryQuery,
  AvailableFilesQueryQueryVariables,
  AvailableFilesQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AvailableFilesQueryQuery, AvailableFilesQueryQueryVariables, AvailableFilesQueryProps<TChildProps, TDataName>>(AvailableFilesQueryDocument, {
      alias: 'availableFilesQuery',
      ...operationOptions
    });
};

/**
 * __useAvailableFilesQueryQuery__
 *
 * To run a query within a React component, call `useAvailableFilesQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableFilesQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableFilesQueryQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runName: // value for 'runName'
 *      filenames: // value for 'filenames'
 *      includeDirectUrl: // value for 'includeDirectUrl'
 *   },
 * });
 */
export function useAvailableFilesQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AvailableFilesQueryQuery, AvailableFilesQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<AvailableFilesQueryQuery, AvailableFilesQueryQueryVariables>(AvailableFilesQueryDocument, baseOptions);
      }
export function useAvailableFilesQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AvailableFilesQueryQuery, AvailableFilesQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AvailableFilesQueryQuery, AvailableFilesQueryQueryVariables>(AvailableFilesQueryDocument, baseOptions);
        }
export type AvailableFilesQueryQueryHookResult = ReturnType<typeof useAvailableFilesQueryQuery>;
export type AvailableFilesQueryLazyQueryHookResult = ReturnType<typeof useAvailableFilesQueryLazyQuery>;
export type AvailableFilesQueryQueryResult = ApolloReactCommon.QueryResult<AvailableFilesQueryQuery, AvailableFilesQueryQueryVariables>;
export const DirectUrlQueryDocument = gql`
    query DirectUrlQuery($projectName: String!, $entityName: String!, $runName: String!, $filenames: [String!]!) {
  project(entityName: $entityName, name: $projectName) {
    id
    run(name: $runName) {
      id
      files(names: $filenames) {
        edges {
          node {
            id
            name
            directUrl
            url(upload: false)
          }
        }
      }
    }
  }
}
    `;
export type DirectUrlQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DirectUrlQueryQuery, DirectUrlQueryQueryVariables>, 'query'> & ({ variables: DirectUrlQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DirectUrlQueryComponent = (props: DirectUrlQueryComponentProps) => (
      <ApolloReactComponents.Query<DirectUrlQueryQuery, DirectUrlQueryQueryVariables> query={DirectUrlQueryDocument} {...props} />
    );
    
export type DirectUrlQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<DirectUrlQueryQuery, DirectUrlQueryQueryVariables>
    } & TChildProps;
export function withDirectUrlQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DirectUrlQueryQuery,
  DirectUrlQueryQueryVariables,
  DirectUrlQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, DirectUrlQueryQuery, DirectUrlQueryQueryVariables, DirectUrlQueryProps<TChildProps, TDataName>>(DirectUrlQueryDocument, {
      alias: 'directUrlQuery',
      ...operationOptions
    });
};

/**
 * __useDirectUrlQueryQuery__
 *
 * To run a query within a React component, call `useDirectUrlQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useDirectUrlQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDirectUrlQueryQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runName: // value for 'runName'
 *      filenames: // value for 'filenames'
 *   },
 * });
 */
export function useDirectUrlQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DirectUrlQueryQuery, DirectUrlQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<DirectUrlQueryQuery, DirectUrlQueryQueryVariables>(DirectUrlQueryDocument, baseOptions);
      }
export function useDirectUrlQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DirectUrlQueryQuery, DirectUrlQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DirectUrlQueryQuery, DirectUrlQueryQueryVariables>(DirectUrlQueryDocument, baseOptions);
        }
export type DirectUrlQueryQueryHookResult = ReturnType<typeof useDirectUrlQueryQuery>;
export type DirectUrlQueryLazyQueryHookResult = ReturnType<typeof useDirectUrlQueryLazyQuery>;
export type DirectUrlQueryQueryResult = ApolloReactCommon.QueryResult<DirectUrlQueryQuery, DirectUrlQueryQueryVariables>;
export const ArtifactCodeFileQueryDocument = gql`
    query ArtifactCodeFileQuery($projectName: String!, $entityName: String!, $runName: String!, $filenames: [String!]!) {
  project(entityName: $entityName, name: $projectName) {
    id
    run(name: $runName) {
      id
      outputArtifacts {
        edges {
          node {
            id
            files(names: $filenames) {
              edges {
                node {
                  id
                  name
                  url(upload: false)
                  directUrl
                  sizeBytes
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type ArtifactCodeFileQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactCodeFileQueryQuery, ArtifactCodeFileQueryQueryVariables>, 'query'> & ({ variables: ArtifactCodeFileQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactCodeFileQueryComponent = (props: ArtifactCodeFileQueryComponentProps) => (
      <ApolloReactComponents.Query<ArtifactCodeFileQueryQuery, ArtifactCodeFileQueryQueryVariables> query={ArtifactCodeFileQueryDocument} {...props} />
    );
    
export type ArtifactCodeFileQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactCodeFileQueryQuery, ArtifactCodeFileQueryQueryVariables>
    } & TChildProps;
export function withArtifactCodeFileQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactCodeFileQueryQuery,
  ArtifactCodeFileQueryQueryVariables,
  ArtifactCodeFileQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactCodeFileQueryQuery, ArtifactCodeFileQueryQueryVariables, ArtifactCodeFileQueryProps<TChildProps, TDataName>>(ArtifactCodeFileQueryDocument, {
      alias: 'artifactCodeFileQuery',
      ...operationOptions
    });
};

/**
 * __useArtifactCodeFileQueryQuery__
 *
 * To run a query within a React component, call `useArtifactCodeFileQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactCodeFileQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactCodeFileQueryQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runName: // value for 'runName'
 *      filenames: // value for 'filenames'
 *   },
 * });
 */
export function useArtifactCodeFileQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactCodeFileQueryQuery, ArtifactCodeFileQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactCodeFileQueryQuery, ArtifactCodeFileQueryQueryVariables>(ArtifactCodeFileQueryDocument, baseOptions);
      }
export function useArtifactCodeFileQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactCodeFileQueryQuery, ArtifactCodeFileQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactCodeFileQueryQuery, ArtifactCodeFileQueryQueryVariables>(ArtifactCodeFileQueryDocument, baseOptions);
        }
export type ArtifactCodeFileQueryQueryHookResult = ReturnType<typeof useArtifactCodeFileQueryQuery>;
export type ArtifactCodeFileQueryLazyQueryHookResult = ReturnType<typeof useArtifactCodeFileQueryLazyQuery>;
export type ArtifactCodeFileQueryQueryResult = ApolloReactCommon.QueryResult<ArtifactCodeFileQueryQuery, ArtifactCodeFileQueryQueryVariables>;
export const AddWorkflowsAdminDocument = gql`
    mutation AddWorkflowsAdmin($entityName: String!, $username: String, $userID: ID, $adminType: WorkflowsAdminType!) {
  addWorkflowsAdmin(
    input: {entityName: $entityName, username: $username, userID: $userID, adminType: $adminType}
  ) {
    success
  }
}
    `;
export type AddWorkflowsAdminMutationFn = ApolloReactCommon.MutationFunction<AddWorkflowsAdminMutation, AddWorkflowsAdminMutationVariables>;
export type AddWorkflowsAdminComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddWorkflowsAdminMutation, AddWorkflowsAdminMutationVariables>, 'mutation'>;

    export const AddWorkflowsAdminComponent = (props: AddWorkflowsAdminComponentProps) => (
      <ApolloReactComponents.Mutation<AddWorkflowsAdminMutation, AddWorkflowsAdminMutationVariables> mutation={AddWorkflowsAdminDocument} {...props} />
    );
    
export type AddWorkflowsAdminProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AddWorkflowsAdminMutation, AddWorkflowsAdminMutationVariables>
    } & TChildProps;
export function withAddWorkflowsAdmin<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddWorkflowsAdminMutation,
  AddWorkflowsAdminMutationVariables,
  AddWorkflowsAdminProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddWorkflowsAdminMutation, AddWorkflowsAdminMutationVariables, AddWorkflowsAdminProps<TChildProps, TDataName>>(AddWorkflowsAdminDocument, {
      alias: 'addWorkflowsAdmin',
      ...operationOptions
    });
};

/**
 * __useAddWorkflowsAdminMutation__
 *
 * To run a mutation, you first call `useAddWorkflowsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddWorkflowsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addWorkflowsAdminMutation, { data, loading, error }] = useAddWorkflowsAdminMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      username: // value for 'username'
 *      userID: // value for 'userID'
 *      adminType: // value for 'adminType'
 *   },
 * });
 */
export function useAddWorkflowsAdminMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddWorkflowsAdminMutation, AddWorkflowsAdminMutationVariables>) {
        return ApolloReactHooks.useMutation<AddWorkflowsAdminMutation, AddWorkflowsAdminMutationVariables>(AddWorkflowsAdminDocument, baseOptions);
      }
export type AddWorkflowsAdminMutationHookResult = ReturnType<typeof useAddWorkflowsAdminMutation>;
export type AddWorkflowsAdminMutationResult = ApolloReactCommon.MutationResult<AddWorkflowsAdminMutation>;
export type AddWorkflowsAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<AddWorkflowsAdminMutation, AddWorkflowsAdminMutationVariables>;
export const DeleteWorkflowsAdminDocument = gql`
    mutation DeleteWorkflowsAdmin($entityName: String!, $username: String, $userID: ID, $adminType: WorkflowsAdminType!) {
  deleteWorkflowsAdmin(
    input: {entityName: $entityName, username: $username, userID: $userID, adminType: $adminType}
  ) {
    success
  }
}
    `;
export type DeleteWorkflowsAdminMutationFn = ApolloReactCommon.MutationFunction<DeleteWorkflowsAdminMutation, DeleteWorkflowsAdminMutationVariables>;
export type DeleteWorkflowsAdminComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteWorkflowsAdminMutation, DeleteWorkflowsAdminMutationVariables>, 'mutation'>;

    export const DeleteWorkflowsAdminComponent = (props: DeleteWorkflowsAdminComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteWorkflowsAdminMutation, DeleteWorkflowsAdminMutationVariables> mutation={DeleteWorkflowsAdminDocument} {...props} />
    );
    
export type DeleteWorkflowsAdminProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteWorkflowsAdminMutation, DeleteWorkflowsAdminMutationVariables>
    } & TChildProps;
export function withDeleteWorkflowsAdmin<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteWorkflowsAdminMutation,
  DeleteWorkflowsAdminMutationVariables,
  DeleteWorkflowsAdminProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteWorkflowsAdminMutation, DeleteWorkflowsAdminMutationVariables, DeleteWorkflowsAdminProps<TChildProps, TDataName>>(DeleteWorkflowsAdminDocument, {
      alias: 'deleteWorkflowsAdmin',
      ...operationOptions
    });
};

/**
 * __useDeleteWorkflowsAdminMutation__
 *
 * To run a mutation, you first call `useDeleteWorkflowsAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteWorkflowsAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteWorkflowsAdminMutation, { data, loading, error }] = useDeleteWorkflowsAdminMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      username: // value for 'username'
 *      userID: // value for 'userID'
 *      adminType: // value for 'adminType'
 *   },
 * });
 */
export function useDeleteWorkflowsAdminMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteWorkflowsAdminMutation, DeleteWorkflowsAdminMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteWorkflowsAdminMutation, DeleteWorkflowsAdminMutationVariables>(DeleteWorkflowsAdminDocument, baseOptions);
      }
export type DeleteWorkflowsAdminMutationHookResult = ReturnType<typeof useDeleteWorkflowsAdminMutation>;
export type DeleteWorkflowsAdminMutationResult = ApolloReactCommon.MutationResult<DeleteWorkflowsAdminMutation>;
export type DeleteWorkflowsAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteWorkflowsAdminMutation, DeleteWorkflowsAdminMutationVariables>;
export const AddProtectedAliasesDocument = gql`
    mutation AddProtectedAliases($entityName: String!, $aliases: [String!]!, $adminType: WorkflowsAdminType!) {
  addProtectedAliases(
    input: {entityName: $entityName, aliases: $aliases, adminType: $adminType}
  ) {
    aliases
  }
}
    `;
export type AddProtectedAliasesMutationFn = ApolloReactCommon.MutationFunction<AddProtectedAliasesMutation, AddProtectedAliasesMutationVariables>;
export type AddProtectedAliasesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddProtectedAliasesMutation, AddProtectedAliasesMutationVariables>, 'mutation'>;

    export const AddProtectedAliasesComponent = (props: AddProtectedAliasesComponentProps) => (
      <ApolloReactComponents.Mutation<AddProtectedAliasesMutation, AddProtectedAliasesMutationVariables> mutation={AddProtectedAliasesDocument} {...props} />
    );
    
export type AddProtectedAliasesProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AddProtectedAliasesMutation, AddProtectedAliasesMutationVariables>
    } & TChildProps;
export function withAddProtectedAliases<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddProtectedAliasesMutation,
  AddProtectedAliasesMutationVariables,
  AddProtectedAliasesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddProtectedAliasesMutation, AddProtectedAliasesMutationVariables, AddProtectedAliasesProps<TChildProps, TDataName>>(AddProtectedAliasesDocument, {
      alias: 'addProtectedAliases',
      ...operationOptions
    });
};

/**
 * __useAddProtectedAliasesMutation__
 *
 * To run a mutation, you first call `useAddProtectedAliasesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddProtectedAliasesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addProtectedAliasesMutation, { data, loading, error }] = useAddProtectedAliasesMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      aliases: // value for 'aliases'
 *      adminType: // value for 'adminType'
 *   },
 * });
 */
export function useAddProtectedAliasesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddProtectedAliasesMutation, AddProtectedAliasesMutationVariables>) {
        return ApolloReactHooks.useMutation<AddProtectedAliasesMutation, AddProtectedAliasesMutationVariables>(AddProtectedAliasesDocument, baseOptions);
      }
export type AddProtectedAliasesMutationHookResult = ReturnType<typeof useAddProtectedAliasesMutation>;
export type AddProtectedAliasesMutationResult = ApolloReactCommon.MutationResult<AddProtectedAliasesMutation>;
export type AddProtectedAliasesMutationOptions = ApolloReactCommon.BaseMutationOptions<AddProtectedAliasesMutation, AddProtectedAliasesMutationVariables>;
export const RemoveProtectedAliasesDocument = gql`
    mutation RemoveProtectedAliases($entityName: String!, $aliases: [String!]!, $adminType: WorkflowsAdminType!) {
  removeProtectedAliases(
    input: {entityName: $entityName, aliases: $aliases, adminType: $adminType}
  ) {
    aliases
  }
}
    `;
export type RemoveProtectedAliasesMutationFn = ApolloReactCommon.MutationFunction<RemoveProtectedAliasesMutation, RemoveProtectedAliasesMutationVariables>;
export type RemoveProtectedAliasesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveProtectedAliasesMutation, RemoveProtectedAliasesMutationVariables>, 'mutation'>;

    export const RemoveProtectedAliasesComponent = (props: RemoveProtectedAliasesComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveProtectedAliasesMutation, RemoveProtectedAliasesMutationVariables> mutation={RemoveProtectedAliasesDocument} {...props} />
    );
    
export type RemoveProtectedAliasesProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveProtectedAliasesMutation, RemoveProtectedAliasesMutationVariables>
    } & TChildProps;
export function withRemoveProtectedAliases<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveProtectedAliasesMutation,
  RemoveProtectedAliasesMutationVariables,
  RemoveProtectedAliasesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveProtectedAliasesMutation, RemoveProtectedAliasesMutationVariables, RemoveProtectedAliasesProps<TChildProps, TDataName>>(RemoveProtectedAliasesDocument, {
      alias: 'removeProtectedAliases',
      ...operationOptions
    });
};

/**
 * __useRemoveProtectedAliasesMutation__
 *
 * To run a mutation, you first call `useRemoveProtectedAliasesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProtectedAliasesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProtectedAliasesMutation, { data, loading, error }] = useRemoveProtectedAliasesMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      aliases: // value for 'aliases'
 *      adminType: // value for 'adminType'
 *   },
 * });
 */
export function useRemoveProtectedAliasesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveProtectedAliasesMutation, RemoveProtectedAliasesMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveProtectedAliasesMutation, RemoveProtectedAliasesMutationVariables>(RemoveProtectedAliasesDocument, baseOptions);
      }
export type RemoveProtectedAliasesMutationHookResult = ReturnType<typeof useRemoveProtectedAliasesMutation>;
export type RemoveProtectedAliasesMutationResult = ApolloReactCommon.MutationResult<RemoveProtectedAliasesMutation>;
export type RemoveProtectedAliasesMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveProtectedAliasesMutation, RemoveProtectedAliasesMutationVariables>;
export const EntityProtectedAliasesDocument = gql`
    query EntityProtectedAliases($entityName: String!, $workflowsAdminType: WorkflowsAdminType!) {
  entity(name: $entityName) {
    id
    workflowsAdmins(adminType: $workflowsAdminType) {
      id
      username
      name
      email
    }
    protectedAliases(adminType: $workflowsAdminType)
  }
}
    `;
export type EntityProtectedAliasesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityProtectedAliasesQuery, EntityProtectedAliasesQueryVariables>, 'query'> & ({ variables: EntityProtectedAliasesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntityProtectedAliasesComponent = (props: EntityProtectedAliasesComponentProps) => (
      <ApolloReactComponents.Query<EntityProtectedAliasesQuery, EntityProtectedAliasesQueryVariables> query={EntityProtectedAliasesDocument} {...props} />
    );
    
export type EntityProtectedAliasesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityProtectedAliasesQuery, EntityProtectedAliasesQueryVariables>
    } & TChildProps;
export function withEntityProtectedAliases<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityProtectedAliasesQuery,
  EntityProtectedAliasesQueryVariables,
  EntityProtectedAliasesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityProtectedAliasesQuery, EntityProtectedAliasesQueryVariables, EntityProtectedAliasesProps<TChildProps, TDataName>>(EntityProtectedAliasesDocument, {
      alias: 'entityProtectedAliases',
      ...operationOptions
    });
};

/**
 * __useEntityProtectedAliasesQuery__
 *
 * To run a query within a React component, call `useEntityProtectedAliasesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityProtectedAliasesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityProtectedAliasesQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      workflowsAdminType: // value for 'workflowsAdminType'
 *   },
 * });
 */
export function useEntityProtectedAliasesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityProtectedAliasesQuery, EntityProtectedAliasesQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityProtectedAliasesQuery, EntityProtectedAliasesQueryVariables>(EntityProtectedAliasesDocument, baseOptions);
      }
export function useEntityProtectedAliasesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityProtectedAliasesQuery, EntityProtectedAliasesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityProtectedAliasesQuery, EntityProtectedAliasesQueryVariables>(EntityProtectedAliasesDocument, baseOptions);
        }
export type EntityProtectedAliasesQueryHookResult = ReturnType<typeof useEntityProtectedAliasesQuery>;
export type EntityProtectedAliasesLazyQueryHookResult = ReturnType<typeof useEntityProtectedAliasesLazyQuery>;
export type EntityProtectedAliasesQueryResult = ApolloReactCommon.QueryResult<EntityProtectedAliasesQuery, EntityProtectedAliasesQueryVariables>;
export const ModelDocument = gql`
    query Model($name: String!, $logCursor: String, $fileCursor: String, $fileLimit: Int = 10, $fileNames: [String] = [], $entityName: String, $bucketName: String!, $upload: Boolean, $detailed: Boolean!, $includeFiles: Boolean = false) {
  project(name: $name, entityName: $entityName) {
    id
    readOnly
    name
    entityName
    description
    createdAt
    bucketCount
    access
    summaryMetrics
    run(name: $bucketName) {
      id
      name
      __typename
      ...SelectRunFragment
      ...BasicRunFragment
      ...DetailedRunFragment @include(if: $detailed)
      ...RunFilesFragment @include(if: $includeFiles)
    }
    views
  }
  viewer {
    id
    email
    photoUrl
    admin
    defaultFramework
  }
}
    ${SelectRunFragmentFragmentDoc}
${BasicRunFragmentFragmentDoc}
${DetailedRunFragmentFragmentDoc}
${RunFilesFragmentFragmentDoc}`;
export type ModelComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ModelQuery, ModelQueryVariables>, 'query'> & ({ variables: ModelQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ModelComponent = (props: ModelComponentProps) => (
      <ApolloReactComponents.Query<ModelQuery, ModelQueryVariables> query={ModelDocument} {...props} />
    );
    
export type ModelProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ModelQuery, ModelQueryVariables>
    } & TChildProps;
export function withModel<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ModelQuery,
  ModelQueryVariables,
  ModelProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ModelQuery, ModelQueryVariables, ModelProps<TChildProps, TDataName>>(ModelDocument, {
      alias: 'model',
      ...operationOptions
    });
};

/**
 * __useModelQuery__
 *
 * To run a query within a React component, call `useModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModelQuery({
 *   variables: {
 *      name: // value for 'name'
 *      logCursor: // value for 'logCursor'
 *      fileCursor: // value for 'fileCursor'
 *      fileLimit: // value for 'fileLimit'
 *      fileNames: // value for 'fileNames'
 *      entityName: // value for 'entityName'
 *      bucketName: // value for 'bucketName'
 *      upload: // value for 'upload'
 *      detailed: // value for 'detailed'
 *      includeFiles: // value for 'includeFiles'
 *   },
 * });
 */
export function useModelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ModelQuery, ModelQueryVariables>) {
        return ApolloReactHooks.useQuery<ModelQuery, ModelQueryVariables>(ModelDocument, baseOptions);
      }
export function useModelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ModelQuery, ModelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ModelQuery, ModelQueryVariables>(ModelDocument, baseOptions);
        }
export type ModelQueryHookResult = ReturnType<typeof useModelQuery>;
export type ModelLazyQueryHookResult = ReturnType<typeof useModelLazyQuery>;
export type ModelQueryResult = ApolloReactCommon.QueryResult<ModelQuery, ModelQueryVariables>;
export const UpsertModelDocument = gql`
    mutation upsertModel($description: String, $entityName: String, $id: String, $name: String, $framework: String, $access: String, $views: JSONString, $owner: ID) {
  upsertModel(
    input: {description: $description, entityName: $entityName, id: $id, name: $name, framework: $framework, access: $access, views: $views, owner: $owner}
  ) {
    project {
      id
      name
      entityName
      description
      access
      views
      user {
        id
      }
    }
    model {
      id
      name
      entityName
      description
      access
      views
      user {
        id
      }
    }
    inserted
  }
}
    `;
export type UpsertModelMutationFn = ApolloReactCommon.MutationFunction<UpsertModelMutation, UpsertModelMutationVariables>;
export type UpsertModelComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertModelMutation, UpsertModelMutationVariables>, 'mutation'>;

    export const UpsertModelComponent = (props: UpsertModelComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertModelMutation, UpsertModelMutationVariables> mutation={UpsertModelDocument} {...props} />
    );
    
export type UpsertModelProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpsertModelMutation, UpsertModelMutationVariables>
    } & TChildProps;
export function withUpsertModel<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpsertModelMutation,
  UpsertModelMutationVariables,
  UpsertModelProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpsertModelMutation, UpsertModelMutationVariables, UpsertModelProps<TChildProps, TDataName>>(UpsertModelDocument, {
      alias: 'upsertModel',
      ...operationOptions
    });
};

/**
 * __useUpsertModelMutation__
 *
 * To run a mutation, you first call `useUpsertModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertModelMutation, { data, loading, error }] = useUpsertModelMutation({
 *   variables: {
 *      description: // value for 'description'
 *      entityName: // value for 'entityName'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      framework: // value for 'framework'
 *      access: // value for 'access'
 *      views: // value for 'views'
 *      owner: // value for 'owner'
 *   },
 * });
 */
export function useUpsertModelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertModelMutation, UpsertModelMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertModelMutation, UpsertModelMutationVariables>(UpsertModelDocument, baseOptions);
      }
export type UpsertModelMutationHookResult = ReturnType<typeof useUpsertModelMutation>;
export type UpsertModelMutationResult = ApolloReactCommon.MutationResult<UpsertModelMutation>;
export type UpsertModelMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertModelMutation, UpsertModelMutationVariables>;
export const RenameProjectDocument = gql`
    mutation renameProject($entityName: String!, $oldProjectName: String!, $newProjectName: String!) {
  renameProject(
    input: {entityName: $entityName, oldProjectName: $oldProjectName, newProjectName: $newProjectName}
  ) {
    project {
      id
      name
      entityName
      description
      access
      views
    }
    model {
      id
      name
      entityName
      description
      access
      views
    }
    inserted
  }
}
    `;
export type RenameProjectMutationFn = ApolloReactCommon.MutationFunction<RenameProjectMutation, RenameProjectMutationVariables>;
export type RenameProjectComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RenameProjectMutation, RenameProjectMutationVariables>, 'mutation'>;

    export const RenameProjectComponent = (props: RenameProjectComponentProps) => (
      <ApolloReactComponents.Mutation<RenameProjectMutation, RenameProjectMutationVariables> mutation={RenameProjectDocument} {...props} />
    );
    
export type RenameProjectProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RenameProjectMutation, RenameProjectMutationVariables>
    } & TChildProps;
export function withRenameProject<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RenameProjectMutation,
  RenameProjectMutationVariables,
  RenameProjectProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RenameProjectMutation, RenameProjectMutationVariables, RenameProjectProps<TChildProps, TDataName>>(RenameProjectDocument, {
      alias: 'renameProject',
      ...operationOptions
    });
};

/**
 * __useRenameProjectMutation__
 *
 * To run a mutation, you first call `useRenameProjectMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRenameProjectMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [renameProjectMutation, { data, loading, error }] = useRenameProjectMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      oldProjectName: // value for 'oldProjectName'
 *      newProjectName: // value for 'newProjectName'
 *   },
 * });
 */
export function useRenameProjectMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RenameProjectMutation, RenameProjectMutationVariables>) {
        return ApolloReactHooks.useMutation<RenameProjectMutation, RenameProjectMutationVariables>(RenameProjectDocument, baseOptions);
      }
export type RenameProjectMutationHookResult = ReturnType<typeof useRenameProjectMutation>;
export type RenameProjectMutationResult = ApolloReactCommon.MutationResult<RenameProjectMutation>;
export type RenameProjectMutationOptions = ApolloReactCommon.BaseMutationOptions<RenameProjectMutation, RenameProjectMutationVariables>;
export const DeleteModelDocument = gql`
    mutation deleteModel($id: String!) {
  deleteModel(input: {id: $id}) {
    success
  }
}
    `;
export type DeleteModelMutationFn = ApolloReactCommon.MutationFunction<DeleteModelMutation, DeleteModelMutationVariables>;
export type DeleteModelComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteModelMutation, DeleteModelMutationVariables>, 'mutation'>;

    export const DeleteModelComponent = (props: DeleteModelComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteModelMutation, DeleteModelMutationVariables> mutation={DeleteModelDocument} {...props} />
    );
    
export type DeleteModelProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteModelMutation, DeleteModelMutationVariables>
    } & TChildProps;
export function withDeleteModel<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteModelMutation,
  DeleteModelMutationVariables,
  DeleteModelProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteModelMutation, DeleteModelMutationVariables, DeleteModelProps<TChildProps, TDataName>>(DeleteModelDocument, {
      alias: 'deleteModel',
      ...operationOptions
    });
};

/**
 * __useDeleteModelMutation__
 *
 * To run a mutation, you first call `useDeleteModelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteModelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteModelMutation, { data, loading, error }] = useDeleteModelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteModelMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteModelMutation, DeleteModelMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteModelMutation, DeleteModelMutationVariables>(DeleteModelDocument, baseOptions);
      }
export type DeleteModelMutationHookResult = ReturnType<typeof useDeleteModelMutation>;
export type DeleteModelMutationResult = ApolloReactCommon.MutationResult<DeleteModelMutation>;
export type DeleteModelMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteModelMutation, DeleteModelMutationVariables>;
export const ModelsDocument = gql`
    query Models($cursor: String, $entityName: String, $order: String, $first: Int = 500, $anonymous: Boolean = false) {
  projects(
    first: $first
    after: $cursor
    entityName: $entityName
    order: $order
    anonymous: $anonymous
  ) {
    edges {
      node {
        id
        name
        entityName
        description
        totalRuns
        totalUsers
        lastActive
      }
    }
    pageInfo {
      endCursor
      hasNextPage
    }
  }
  viewer {
    id
    email
    photoUrl
  }
}
    `;
export type ModelsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ModelsQuery, ModelsQueryVariables>, 'query'>;

    export const ModelsComponent = (props: ModelsComponentProps) => (
      <ApolloReactComponents.Query<ModelsQuery, ModelsQueryVariables> query={ModelsDocument} {...props} />
    );
    
export type ModelsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ModelsQuery, ModelsQueryVariables>
    } & TChildProps;
export function withModels<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ModelsQuery,
  ModelsQueryVariables,
  ModelsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ModelsQuery, ModelsQueryVariables, ModelsProps<TChildProps, TDataName>>(ModelsDocument, {
      alias: 'models',
      ...operationOptions
    });
};

/**
 * __useModelsQuery__
 *
 * To run a query within a React component, call `useModelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModelsQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      entityName: // value for 'entityName'
 *      order: // value for 'order'
 *      first: // value for 'first'
 *      anonymous: // value for 'anonymous'
 *   },
 * });
 */
export function useModelsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ModelsQuery, ModelsQueryVariables>) {
        return ApolloReactHooks.useQuery<ModelsQuery, ModelsQueryVariables>(ModelsDocument, baseOptions);
      }
export function useModelsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ModelsQuery, ModelsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ModelsQuery, ModelsQueryVariables>(ModelsDocument, baseOptions);
        }
export type ModelsQueryHookResult = ReturnType<typeof useModelsQuery>;
export type ModelsLazyQueryHookResult = ReturnType<typeof useModelsLazyQuery>;
export type ModelsQueryResult = ApolloReactCommon.QueryResult<ModelsQuery, ModelsQueryVariables>;
export const NumPendingInvitesDocument = gql`
    query NumPendingInvites {
  numPendingInvites
}
    `;
export type NumPendingInvitesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NumPendingInvitesQuery, NumPendingInvitesQueryVariables>, 'query'>;

    export const NumPendingInvitesComponent = (props: NumPendingInvitesComponentProps) => (
      <ApolloReactComponents.Query<NumPendingInvitesQuery, NumPendingInvitesQueryVariables> query={NumPendingInvitesDocument} {...props} />
    );
    
export type NumPendingInvitesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<NumPendingInvitesQuery, NumPendingInvitesQueryVariables>
    } & TChildProps;
export function withNumPendingInvites<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  NumPendingInvitesQuery,
  NumPendingInvitesQueryVariables,
  NumPendingInvitesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, NumPendingInvitesQuery, NumPendingInvitesQueryVariables, NumPendingInvitesProps<TChildProps, TDataName>>(NumPendingInvitesDocument, {
      alias: 'numPendingInvites',
      ...operationOptions
    });
};

/**
 * __useNumPendingInvitesQuery__
 *
 * To run a query within a React component, call `useNumPendingInvitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useNumPendingInvitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNumPendingInvitesQuery({
 *   variables: {
 *   },
 * });
 */
export function useNumPendingInvitesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NumPendingInvitesQuery, NumPendingInvitesQueryVariables>) {
        return ApolloReactHooks.useQuery<NumPendingInvitesQuery, NumPendingInvitesQueryVariables>(NumPendingInvitesDocument, baseOptions);
      }
export function useNumPendingInvitesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NumPendingInvitesQuery, NumPendingInvitesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NumPendingInvitesQuery, NumPendingInvitesQueryVariables>(NumPendingInvitesDocument, baseOptions);
        }
export type NumPendingInvitesQueryHookResult = ReturnType<typeof useNumPendingInvitesQuery>;
export type NumPendingInvitesLazyQueryHookResult = ReturnType<typeof useNumPendingInvitesLazyQuery>;
export type NumPendingInvitesQueryResult = ApolloReactCommon.QueryResult<NumPendingInvitesQuery, NumPendingInvitesQueryVariables>;
export const CreateOAuthProviderDocument = gql`
    mutation createOAuthProvider($organizationId: ID!, $issuerUrl: String!) {
  createOAuthProvider(
    input: {issuerUrl: $issuerUrl, organizationId: $organizationId}
  ) {
    success
  }
}
    `;
export type CreateOAuthProviderMutationFn = ApolloReactCommon.MutationFunction<CreateOAuthProviderMutation, CreateOAuthProviderMutationVariables>;
export type CreateOAuthProviderComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateOAuthProviderMutation, CreateOAuthProviderMutationVariables>, 'mutation'>;

    export const CreateOAuthProviderComponent = (props: CreateOAuthProviderComponentProps) => (
      <ApolloReactComponents.Mutation<CreateOAuthProviderMutation, CreateOAuthProviderMutationVariables> mutation={CreateOAuthProviderDocument} {...props} />
    );
    
export type CreateOAuthProviderProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateOAuthProviderMutation, CreateOAuthProviderMutationVariables>
    } & TChildProps;
export function withCreateOAuthProvider<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateOAuthProviderMutation,
  CreateOAuthProviderMutationVariables,
  CreateOAuthProviderProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateOAuthProviderMutation, CreateOAuthProviderMutationVariables, CreateOAuthProviderProps<TChildProps, TDataName>>(CreateOAuthProviderDocument, {
      alias: 'createOAuthProvider',
      ...operationOptions
    });
};

/**
 * __useCreateOAuthProviderMutation__
 *
 * To run a mutation, you first call `useCreateOAuthProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOAuthProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOAuthProviderMutation, { data, loading, error }] = useCreateOAuthProviderMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      issuerUrl: // value for 'issuerUrl'
 *   },
 * });
 */
export function useCreateOAuthProviderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOAuthProviderMutation, CreateOAuthProviderMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOAuthProviderMutation, CreateOAuthProviderMutationVariables>(CreateOAuthProviderDocument, baseOptions);
      }
export type CreateOAuthProviderMutationHookResult = ReturnType<typeof useCreateOAuthProviderMutation>;
export type CreateOAuthProviderMutationResult = ApolloReactCommon.MutationResult<CreateOAuthProviderMutation>;
export type CreateOAuthProviderMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOAuthProviderMutation, CreateOAuthProviderMutationVariables>;
export const OauthProvidersDocument = gql`
    query oauthProviders($organizationName: String!) {
  organization(name: $organizationName) {
    id
    OAuthProviders {
      id
      issuerUrl
    }
  }
}
    `;
export type OauthProvidersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OauthProvidersQuery, OauthProvidersQueryVariables>, 'query'> & ({ variables: OauthProvidersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OauthProvidersComponent = (props: OauthProvidersComponentProps) => (
      <ApolloReactComponents.Query<OauthProvidersQuery, OauthProvidersQueryVariables> query={OauthProvidersDocument} {...props} />
    );
    
export type OauthProvidersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OauthProvidersQuery, OauthProvidersQueryVariables>
    } & TChildProps;
export function withOauthProviders<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OauthProvidersQuery,
  OauthProvidersQueryVariables,
  OauthProvidersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OauthProvidersQuery, OauthProvidersQueryVariables, OauthProvidersProps<TChildProps, TDataName>>(OauthProvidersDocument, {
      alias: 'oauthProviders',
      ...operationOptions
    });
};

/**
 * __useOauthProvidersQuery__
 *
 * To run a query within a React component, call `useOauthProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOauthProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOauthProvidersQuery({
 *   variables: {
 *      organizationName: // value for 'organizationName'
 *   },
 * });
 */
export function useOauthProvidersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OauthProvidersQuery, OauthProvidersQueryVariables>) {
        return ApolloReactHooks.useQuery<OauthProvidersQuery, OauthProvidersQueryVariables>(OauthProvidersDocument, baseOptions);
      }
export function useOauthProvidersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OauthProvidersQuery, OauthProvidersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OauthProvidersQuery, OauthProvidersQueryVariables>(OauthProvidersDocument, baseOptions);
        }
export type OauthProvidersQueryHookResult = ReturnType<typeof useOauthProvidersQuery>;
export type OauthProvidersLazyQueryHookResult = ReturnType<typeof useOauthProvidersLazyQuery>;
export type OauthProvidersQueryResult = ApolloReactCommon.QueryResult<OauthProvidersQuery, OauthProvidersQueryVariables>;
export const DeleteOAuthProviderDocument = gql`
    mutation deleteOAuthProvider($providerId: ID!) {
  deleteOAuthProvider(input: {providerId: $providerId}) {
    success
  }
}
    `;
export type DeleteOAuthProviderMutationFn = ApolloReactCommon.MutationFunction<DeleteOAuthProviderMutation, DeleteOAuthProviderMutationVariables>;
export type DeleteOAuthProviderComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteOAuthProviderMutation, DeleteOAuthProviderMutationVariables>, 'mutation'>;

    export const DeleteOAuthProviderComponent = (props: DeleteOAuthProviderComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteOAuthProviderMutation, DeleteOAuthProviderMutationVariables> mutation={DeleteOAuthProviderDocument} {...props} />
    );
    
export type DeleteOAuthProviderProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteOAuthProviderMutation, DeleteOAuthProviderMutationVariables>
    } & TChildProps;
export function withDeleteOAuthProvider<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteOAuthProviderMutation,
  DeleteOAuthProviderMutationVariables,
  DeleteOAuthProviderProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteOAuthProviderMutation, DeleteOAuthProviderMutationVariables, DeleteOAuthProviderProps<TChildProps, TDataName>>(DeleteOAuthProviderDocument, {
      alias: 'deleteOAuthProvider',
      ...operationOptions
    });
};

/**
 * __useDeleteOAuthProviderMutation__
 *
 * To run a mutation, you first call `useDeleteOAuthProviderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOAuthProviderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOAuthProviderMutation, { data, loading, error }] = useDeleteOAuthProviderMutation({
 *   variables: {
 *      providerId: // value for 'providerId'
 *   },
 * });
 */
export function useDeleteOAuthProviderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOAuthProviderMutation, DeleteOAuthProviderMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOAuthProviderMutation, DeleteOAuthProviderMutationVariables>(DeleteOAuthProviderDocument, baseOptions);
      }
export type DeleteOAuthProviderMutationHookResult = ReturnType<typeof useDeleteOAuthProviderMutation>;
export type DeleteOAuthProviderMutationResult = ApolloReactCommon.MutationResult<DeleteOAuthProviderMutation>;
export type DeleteOAuthProviderMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOAuthProviderMutation, DeleteOAuthProviderMutationVariables>;
export const OauthClientsDocument = gql`
    query oauthClients($entityName: String!) {
  entity(name: $entityName) {
    id
    members {
      id
      role
      OAuthClients {
        id
        subject
      }
    }
  }
}
    `;
export type OauthClientsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OauthClientsQuery, OauthClientsQueryVariables>, 'query'> & ({ variables: OauthClientsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OauthClientsComponent = (props: OauthClientsComponentProps) => (
      <ApolloReactComponents.Query<OauthClientsQuery, OauthClientsQueryVariables> query={OauthClientsDocument} {...props} />
    );
    
export type OauthClientsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OauthClientsQuery, OauthClientsQueryVariables>
    } & TChildProps;
export function withOauthClients<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OauthClientsQuery,
  OauthClientsQueryVariables,
  OauthClientsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OauthClientsQuery, OauthClientsQueryVariables, OauthClientsProps<TChildProps, TDataName>>(OauthClientsDocument, {
      alias: 'oauthClients',
      ...operationOptions
    });
};

/**
 * __useOauthClientsQuery__
 *
 * To run a query within a React component, call `useOauthClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOauthClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOauthClientsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useOauthClientsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OauthClientsQuery, OauthClientsQueryVariables>) {
        return ApolloReactHooks.useQuery<OauthClientsQuery, OauthClientsQueryVariables>(OauthClientsDocument, baseOptions);
      }
export function useOauthClientsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OauthClientsQuery, OauthClientsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OauthClientsQuery, OauthClientsQueryVariables>(OauthClientsDocument, baseOptions);
        }
export type OauthClientsQueryHookResult = ReturnType<typeof useOauthClientsQuery>;
export type OauthClientsLazyQueryHookResult = ReturnType<typeof useOauthClientsLazyQuery>;
export type OauthClientsQueryResult = ApolloReactCommon.QueryResult<OauthClientsQuery, OauthClientsQueryVariables>;
export const CreateOAuthClientDocument = gql`
    mutation createOAuthClient($providerId: ID!, $name: String!, $subject: String!, $entityName: String!) {
  createClient(
    input: {providerId: $providerId, entityName: $entityName, name: $name, subject: $subject}
  ) {
    success
  }
}
    `;
export type CreateOAuthClientMutationFn = ApolloReactCommon.MutationFunction<CreateOAuthClientMutation, CreateOAuthClientMutationVariables>;
export type CreateOAuthClientComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateOAuthClientMutation, CreateOAuthClientMutationVariables>, 'mutation'>;

    export const CreateOAuthClientComponent = (props: CreateOAuthClientComponentProps) => (
      <ApolloReactComponents.Mutation<CreateOAuthClientMutation, CreateOAuthClientMutationVariables> mutation={CreateOAuthClientDocument} {...props} />
    );
    
export type CreateOAuthClientProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateOAuthClientMutation, CreateOAuthClientMutationVariables>
    } & TChildProps;
export function withCreateOAuthClient<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateOAuthClientMutation,
  CreateOAuthClientMutationVariables,
  CreateOAuthClientProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateOAuthClientMutation, CreateOAuthClientMutationVariables, CreateOAuthClientProps<TChildProps, TDataName>>(CreateOAuthClientDocument, {
      alias: 'createOAuthClient',
      ...operationOptions
    });
};

/**
 * __useCreateOAuthClientMutation__
 *
 * To run a mutation, you first call `useCreateOAuthClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOAuthClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOAuthClientMutation, { data, loading, error }] = useCreateOAuthClientMutation({
 *   variables: {
 *      providerId: // value for 'providerId'
 *      name: // value for 'name'
 *      subject: // value for 'subject'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useCreateOAuthClientMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOAuthClientMutation, CreateOAuthClientMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOAuthClientMutation, CreateOAuthClientMutationVariables>(CreateOAuthClientDocument, baseOptions);
      }
export type CreateOAuthClientMutationHookResult = ReturnType<typeof useCreateOAuthClientMutation>;
export type CreateOAuthClientMutationResult = ApolloReactCommon.MutationResult<CreateOAuthClientMutation>;
export type CreateOAuthClientMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOAuthClientMutation, CreateOAuthClientMutationVariables>;
export const DeleteOAuthClientDocument = gql`
    mutation deleteOAuthClient($clientId: String!, $entityName: String!) {
  revokeClient(input: {clientId: $clientId, entityName: $entityName}) {
    success
  }
}
    `;
export type DeleteOAuthClientMutationFn = ApolloReactCommon.MutationFunction<DeleteOAuthClientMutation, DeleteOAuthClientMutationVariables>;
export type DeleteOAuthClientComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteOAuthClientMutation, DeleteOAuthClientMutationVariables>, 'mutation'>;

    export const DeleteOAuthClientComponent = (props: DeleteOAuthClientComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteOAuthClientMutation, DeleteOAuthClientMutationVariables> mutation={DeleteOAuthClientDocument} {...props} />
    );
    
export type DeleteOAuthClientProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteOAuthClientMutation, DeleteOAuthClientMutationVariables>
    } & TChildProps;
export function withDeleteOAuthClient<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteOAuthClientMutation,
  DeleteOAuthClientMutationVariables,
  DeleteOAuthClientProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteOAuthClientMutation, DeleteOAuthClientMutationVariables, DeleteOAuthClientProps<TChildProps, TDataName>>(DeleteOAuthClientDocument, {
      alias: 'deleteOAuthClient',
      ...operationOptions
    });
};

/**
 * __useDeleteOAuthClientMutation__
 *
 * To run a mutation, you first call `useDeleteOAuthClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOAuthClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOAuthClientMutation, { data, loading, error }] = useDeleteOAuthClientMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useDeleteOAuthClientMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOAuthClientMutation, DeleteOAuthClientMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOAuthClientMutation, DeleteOAuthClientMutationVariables>(DeleteOAuthClientDocument, baseOptions);
      }
export type DeleteOAuthClientMutationHookResult = ReturnType<typeof useDeleteOAuthClientMutation>;
export type DeleteOAuthClientMutationResult = ApolloReactCommon.MutationResult<DeleteOAuthClientMutation>;
export type DeleteOAuthClientMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOAuthClientMutation, DeleteOAuthClientMutationVariables>;
export const ActivityTimelineDocument = gql`
    query ActivityTimeline($startDate: DateTime, $endDate: DateTime) {
  organization {
    id
    activity: activity(start: $startDate, end: $endDate) {
      numActiveUsers
      startAt
      endAt
    }
  }
}
    `;
export type ActivityTimelineComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ActivityTimelineQuery, ActivityTimelineQueryVariables>, 'query'>;

    export const ActivityTimelineComponent = (props: ActivityTimelineComponentProps) => (
      <ApolloReactComponents.Query<ActivityTimelineQuery, ActivityTimelineQueryVariables> query={ActivityTimelineDocument} {...props} />
    );
    
export type ActivityTimelineProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ActivityTimelineQuery, ActivityTimelineQueryVariables>
    } & TChildProps;
export function withActivityTimeline<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ActivityTimelineQuery,
  ActivityTimelineQueryVariables,
  ActivityTimelineProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ActivityTimelineQuery, ActivityTimelineQueryVariables, ActivityTimelineProps<TChildProps, TDataName>>(ActivityTimelineDocument, {
      alias: 'activityTimeline',
      ...operationOptions
    });
};

/**
 * __useActivityTimelineQuery__
 *
 * To run a query within a React component, call `useActivityTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useActivityTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActivityTimelineQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useActivityTimelineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ActivityTimelineQuery, ActivityTimelineQueryVariables>) {
        return ApolloReactHooks.useQuery<ActivityTimelineQuery, ActivityTimelineQueryVariables>(ActivityTimelineDocument, baseOptions);
      }
export function useActivityTimelineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActivityTimelineQuery, ActivityTimelineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ActivityTimelineQuery, ActivityTimelineQueryVariables>(ActivityTimelineDocument, baseOptions);
        }
export type ActivityTimelineQueryHookResult = ReturnType<typeof useActivityTimelineQuery>;
export type ActivityTimelineLazyQueryHookResult = ReturnType<typeof useActivityTimelineLazyQuery>;
export type ActivityTimelineQueryResult = ApolloReactCommon.QueryResult<ActivityTimelineQuery, ActivityTimelineQueryVariables>;
export const OrgFlagsDocument = gql`
    query OrgFlags($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    flags
  }
}
    `;
export type OrgFlagsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrgFlagsQuery, OrgFlagsQueryVariables>, 'query'> & ({ variables: OrgFlagsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrgFlagsComponent = (props: OrgFlagsComponentProps) => (
      <ApolloReactComponents.Query<OrgFlagsQuery, OrgFlagsQueryVariables> query={OrgFlagsDocument} {...props} />
    );
    
export type OrgFlagsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrgFlagsQuery, OrgFlagsQueryVariables>
    } & TChildProps;
export function withOrgFlags<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrgFlagsQuery,
  OrgFlagsQueryVariables,
  OrgFlagsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrgFlagsQuery, OrgFlagsQueryVariables, OrgFlagsProps<TChildProps, TDataName>>(OrgFlagsDocument, {
      alias: 'orgFlags',
      ...operationOptions
    });
};

/**
 * __useOrgFlagsQuery__
 *
 * To run a query within a React component, call `useOrgFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgFlagsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrgFlagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgFlagsQuery, OrgFlagsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgFlagsQuery, OrgFlagsQueryVariables>(OrgFlagsDocument, baseOptions);
      }
export function useOrgFlagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgFlagsQuery, OrgFlagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgFlagsQuery, OrgFlagsQueryVariables>(OrgFlagsDocument, baseOptions);
        }
export type OrgFlagsQueryHookResult = ReturnType<typeof useOrgFlagsQuery>;
export type OrgFlagsLazyQueryHookResult = ReturnType<typeof useOrgFlagsLazyQuery>;
export type OrgFlagsQueryResult = ApolloReactCommon.QueryResult<OrgFlagsQuery, OrgFlagsQueryVariables>;
export const OrgDashboardEnablementDocument = gql`
    query OrgDashboardEnablement {
  organization {
    id
    name
    activity {
      numActiveUsers
    }
  }
}
    `;
export type OrgDashboardEnablementComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrgDashboardEnablementQuery, OrgDashboardEnablementQueryVariables>, 'query'>;

    export const OrgDashboardEnablementComponent = (props: OrgDashboardEnablementComponentProps) => (
      <ApolloReactComponents.Query<OrgDashboardEnablementQuery, OrgDashboardEnablementQueryVariables> query={OrgDashboardEnablementDocument} {...props} />
    );
    
export type OrgDashboardEnablementProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrgDashboardEnablementQuery, OrgDashboardEnablementQueryVariables>
    } & TChildProps;
export function withOrgDashboardEnablement<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrgDashboardEnablementQuery,
  OrgDashboardEnablementQueryVariables,
  OrgDashboardEnablementProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrgDashboardEnablementQuery, OrgDashboardEnablementQueryVariables, OrgDashboardEnablementProps<TChildProps, TDataName>>(OrgDashboardEnablementDocument, {
      alias: 'orgDashboardEnablement',
      ...operationOptions
    });
};

/**
 * __useOrgDashboardEnablementQuery__
 *
 * To run a query within a React component, call `useOrgDashboardEnablementQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgDashboardEnablementQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgDashboardEnablementQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgDashboardEnablementQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgDashboardEnablementQuery, OrgDashboardEnablementQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgDashboardEnablementQuery, OrgDashboardEnablementQueryVariables>(OrgDashboardEnablementDocument, baseOptions);
      }
export function useOrgDashboardEnablementLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgDashboardEnablementQuery, OrgDashboardEnablementQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgDashboardEnablementQuery, OrgDashboardEnablementQueryVariables>(OrgDashboardEnablementDocument, baseOptions);
        }
export type OrgDashboardEnablementQueryHookResult = ReturnType<typeof useOrgDashboardEnablementQuery>;
export type OrgDashboardEnablementLazyQueryHookResult = ReturnType<typeof useOrgDashboardEnablementLazyQuery>;
export type OrgDashboardEnablementQueryResult = ApolloReactCommon.QueryResult<OrgDashboardEnablementQuery, OrgDashboardEnablementQueryVariables>;
export const OrgDashboardContextDocument = gql`
    query OrgDashboardContext($orgName: String) {
  organization(name: $orgName) {
    id
    name
    members {
      orgID
      id
      modelsSeat
      user {
        id
        ...UserRoleFragment
        teams {
          edges {
            node {
              id
              name
            }
          }
        }
        activity {
          lastActiveAt
          startAt
          endAt
          daysActive
          numReports
          numRuns
        }
      }
    }
  }
}
    ${UserRoleFragmentFragmentDoc}`;
export type OrgDashboardContextComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrgDashboardContextQuery, OrgDashboardContextQueryVariables>, 'query'>;

    export const OrgDashboardContextComponent = (props: OrgDashboardContextComponentProps) => (
      <ApolloReactComponents.Query<OrgDashboardContextQuery, OrgDashboardContextQueryVariables> query={OrgDashboardContextDocument} {...props} />
    );
    
export type OrgDashboardContextProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrgDashboardContextQuery, OrgDashboardContextQueryVariables>
    } & TChildProps;
export function withOrgDashboardContext<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrgDashboardContextQuery,
  OrgDashboardContextQueryVariables,
  OrgDashboardContextProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrgDashboardContextQuery, OrgDashboardContextQueryVariables, OrgDashboardContextProps<TChildProps, TDataName>>(OrgDashboardContextDocument, {
      alias: 'orgDashboardContext',
      ...operationOptions
    });
};

/**
 * __useOrgDashboardContextQuery__
 *
 * To run a query within a React component, call `useOrgDashboardContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgDashboardContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgDashboardContextQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useOrgDashboardContextQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgDashboardContextQuery, OrgDashboardContextQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgDashboardContextQuery, OrgDashboardContextQueryVariables>(OrgDashboardContextDocument, baseOptions);
      }
export function useOrgDashboardContextLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgDashboardContextQuery, OrgDashboardContextQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgDashboardContextQuery, OrgDashboardContextQueryVariables>(OrgDashboardContextDocument, baseOptions);
        }
export type OrgDashboardContextQueryHookResult = ReturnType<typeof useOrgDashboardContextQuery>;
export type OrgDashboardContextLazyQueryHookResult = ReturnType<typeof useOrgDashboardContextLazyQuery>;
export type OrgDashboardContextQueryResult = ApolloReactCommon.QueryResult<OrgDashboardContextQuery, OrgDashboardContextQueryVariables>;
export const OrgActivityTimelineDocument = gql`
    query OrgActivityTimeline($startDate: DateTime, $endDate: DateTime) {
  organization {
    id
    activity: activity(start: $startDate, end: $endDate) {
      numActiveUsers
      startAt
      endAt
    }
  }
}
    `;
export type OrgActivityTimelineComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrgActivityTimelineQuery, OrgActivityTimelineQueryVariables>, 'query'>;

    export const OrgActivityTimelineComponent = (props: OrgActivityTimelineComponentProps) => (
      <ApolloReactComponents.Query<OrgActivityTimelineQuery, OrgActivityTimelineQueryVariables> query={OrgActivityTimelineDocument} {...props} />
    );
    
export type OrgActivityTimelineProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrgActivityTimelineQuery, OrgActivityTimelineQueryVariables>
    } & TChildProps;
export function withOrgActivityTimeline<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrgActivityTimelineQuery,
  OrgActivityTimelineQueryVariables,
  OrgActivityTimelineProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrgActivityTimelineQuery, OrgActivityTimelineQueryVariables, OrgActivityTimelineProps<TChildProps, TDataName>>(OrgActivityTimelineDocument, {
      alias: 'orgActivityTimeline',
      ...operationOptions
    });
};

/**
 * __useOrgActivityTimelineQuery__
 *
 * To run a query within a React component, call `useOrgActivityTimelineQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgActivityTimelineQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgActivityTimelineQuery({
 *   variables: {
 *      startDate: // value for 'startDate'
 *      endDate: // value for 'endDate'
 *   },
 * });
 */
export function useOrgActivityTimelineQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgActivityTimelineQuery, OrgActivityTimelineQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgActivityTimelineQuery, OrgActivityTimelineQueryVariables>(OrgActivityTimelineDocument, baseOptions);
      }
export function useOrgActivityTimelineLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgActivityTimelineQuery, OrgActivityTimelineQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgActivityTimelineQuery, OrgActivityTimelineQueryVariables>(OrgActivityTimelineDocument, baseOptions);
        }
export type OrgActivityTimelineQueryHookResult = ReturnType<typeof useOrgActivityTimelineQuery>;
export type OrgActivityTimelineLazyQueryHookResult = ReturnType<typeof useOrgActivityTimelineLazyQuery>;
export type OrgActivityTimelineQueryResult = ApolloReactCommon.QueryResult<OrgActivityTimelineQuery, OrgActivityTimelineQueryVariables>;
export const OrgSeatsDocument = gql`
    query OrgSeats {
  organization {
    id
    seatAvailability {
      seats
      viewOnlySeats
      expiresAt
    }
  }
}
    `;
export type OrgSeatsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrgSeatsQuery, OrgSeatsQueryVariables>, 'query'>;

    export const OrgSeatsComponent = (props: OrgSeatsComponentProps) => (
      <ApolloReactComponents.Query<OrgSeatsQuery, OrgSeatsQueryVariables> query={OrgSeatsDocument} {...props} />
    );
    
export type OrgSeatsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrgSeatsQuery, OrgSeatsQueryVariables>
    } & TChildProps;
export function withOrgSeats<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrgSeatsQuery,
  OrgSeatsQueryVariables,
  OrgSeatsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrgSeatsQuery, OrgSeatsQueryVariables, OrgSeatsProps<TChildProps, TDataName>>(OrgSeatsDocument, {
      alias: 'orgSeats',
      ...operationOptions
    });
};

/**
 * __useOrgSeatsQuery__
 *
 * To run a query within a React component, call `useOrgSeatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgSeatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgSeatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrgSeatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgSeatsQuery, OrgSeatsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgSeatsQuery, OrgSeatsQueryVariables>(OrgSeatsDocument, baseOptions);
      }
export function useOrgSeatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgSeatsQuery, OrgSeatsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgSeatsQuery, OrgSeatsQueryVariables>(OrgSeatsDocument, baseOptions);
        }
export type OrgSeatsQueryHookResult = ReturnType<typeof useOrgSeatsQuery>;
export type OrgSeatsLazyQueryHookResult = ReturnType<typeof useOrgSeatsLazyQuery>;
export type OrgSeatsQueryResult = ApolloReactCommon.QueryResult<OrgSeatsQuery, OrgSeatsQueryVariables>;
export const UserActivityTableDocument = gql`
    query UserActivityTable {
  organization {
    id
    name
    members {
      orgID
      id
      role
      modelsSeat
      user {
        id
        name
        photoUrl
        createdAt
        accountType
        ...UserRoleFragment
        activity {
          lastActiveAt
          daysActive
          numReports
          numRuns
        }
        teams {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
}
    ${UserRoleFragmentFragmentDoc}`;
export type UserActivityTableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserActivityTableQuery, UserActivityTableQueryVariables>, 'query'>;

    export const UserActivityTableComponent = (props: UserActivityTableComponentProps) => (
      <ApolloReactComponents.Query<UserActivityTableQuery, UserActivityTableQueryVariables> query={UserActivityTableDocument} {...props} />
    );
    
export type UserActivityTableProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UserActivityTableQuery, UserActivityTableQueryVariables>
    } & TChildProps;
export function withUserActivityTable<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserActivityTableQuery,
  UserActivityTableQueryVariables,
  UserActivityTableProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, UserActivityTableQuery, UserActivityTableQueryVariables, UserActivityTableProps<TChildProps, TDataName>>(UserActivityTableDocument, {
      alias: 'userActivityTable',
      ...operationOptions
    });
};

/**
 * __useUserActivityTableQuery__
 *
 * To run a query within a React component, call `useUserActivityTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserActivityTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserActivityTableQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserActivityTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserActivityTableQuery, UserActivityTableQueryVariables>) {
        return ApolloReactHooks.useQuery<UserActivityTableQuery, UserActivityTableQueryVariables>(UserActivityTableDocument, baseOptions);
      }
export function useUserActivityTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserActivityTableQuery, UserActivityTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserActivityTableQuery, UserActivityTableQueryVariables>(UserActivityTableDocument, baseOptions);
        }
export type UserActivityTableQueryHookResult = ReturnType<typeof useUserActivityTableQuery>;
export type UserActivityTableLazyQueryHookResult = ReturnType<typeof useUserActivityTableLazyQuery>;
export type UserActivityTableQueryResult = ApolloReactCommon.QueryResult<UserActivityTableQuery, UserActivityTableQueryVariables>;
export const AddUserToOrganizationDocument = gql`
    mutation addUserToOrganization($userName: String!, $teams: [String!], $organizationId: ID!, $clientMutationId: String, $userOrgRole: String!) {
  addUserToOrganization(
    input: {userName: $userName, teams: $teams, organizationId: $organizationId, clientMutationId: $clientMutationId, userOrgRole: $userOrgRole}
  ) {
    success
    clientMutationId
  }
}
    `;
export type AddUserToOrganizationMutationFn = ApolloReactCommon.MutationFunction<AddUserToOrganizationMutation, AddUserToOrganizationMutationVariables>;
export type AddUserToOrganizationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddUserToOrganizationMutation, AddUserToOrganizationMutationVariables>, 'mutation'>;

    export const AddUserToOrganizationComponent = (props: AddUserToOrganizationComponentProps) => (
      <ApolloReactComponents.Mutation<AddUserToOrganizationMutation, AddUserToOrganizationMutationVariables> mutation={AddUserToOrganizationDocument} {...props} />
    );
    
export type AddUserToOrganizationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AddUserToOrganizationMutation, AddUserToOrganizationMutationVariables>
    } & TChildProps;
export function withAddUserToOrganization<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddUserToOrganizationMutation,
  AddUserToOrganizationMutationVariables,
  AddUserToOrganizationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddUserToOrganizationMutation, AddUserToOrganizationMutationVariables, AddUserToOrganizationProps<TChildProps, TDataName>>(AddUserToOrganizationDocument, {
      alias: 'addUserToOrganization',
      ...operationOptions
    });
};

/**
 * __useAddUserToOrganizationMutation__
 *
 * To run a mutation, you first call `useAddUserToOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddUserToOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addUserToOrganizationMutation, { data, loading, error }] = useAddUserToOrganizationMutation({
 *   variables: {
 *      userName: // value for 'userName'
 *      teams: // value for 'teams'
 *      organizationId: // value for 'organizationId'
 *      clientMutationId: // value for 'clientMutationId'
 *      userOrgRole: // value for 'userOrgRole'
 *   },
 * });
 */
export function useAddUserToOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddUserToOrganizationMutation, AddUserToOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<AddUserToOrganizationMutation, AddUserToOrganizationMutationVariables>(AddUserToOrganizationDocument, baseOptions);
      }
export type AddUserToOrganizationMutationHookResult = ReturnType<typeof useAddUserToOrganizationMutation>;
export type AddUserToOrganizationMutationResult = ApolloReactCommon.MutationResult<AddUserToOrganizationMutation>;
export type AddUserToOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<AddUserToOrganizationMutation, AddUserToOrganizationMutationVariables>;
export const RemoveUserFromOrganizationDocument = gql`
    mutation removeUserFromOrganization($userName: String!, $organizationId: ID!, $clientMutationId: String) {
  removeUserFromOrganization(
    input: {userName: $userName, organizationId: $organizationId, clientMutationId: $clientMutationId}
  ) {
    success
    clientMutationId
  }
}
    `;
export type RemoveUserFromOrganizationMutationFn = ApolloReactCommon.MutationFunction<RemoveUserFromOrganizationMutation, RemoveUserFromOrganizationMutationVariables>;
export type RemoveUserFromOrganizationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveUserFromOrganizationMutation, RemoveUserFromOrganizationMutationVariables>, 'mutation'>;

    export const RemoveUserFromOrganizationComponent = (props: RemoveUserFromOrganizationComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveUserFromOrganizationMutation, RemoveUserFromOrganizationMutationVariables> mutation={RemoveUserFromOrganizationDocument} {...props} />
    );
    
export type RemoveUserFromOrganizationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveUserFromOrganizationMutation, RemoveUserFromOrganizationMutationVariables>
    } & TChildProps;
export function withRemoveUserFromOrganization<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveUserFromOrganizationMutation,
  RemoveUserFromOrganizationMutationVariables,
  RemoveUserFromOrganizationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveUserFromOrganizationMutation, RemoveUserFromOrganizationMutationVariables, RemoveUserFromOrganizationProps<TChildProps, TDataName>>(RemoveUserFromOrganizationDocument, {
      alias: 'removeUserFromOrganization',
      ...operationOptions
    });
};

/**
 * __useRemoveUserFromOrganizationMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromOrganizationMutation, { data, loading, error }] = useRemoveUserFromOrganizationMutation({
 *   variables: {
 *      userName: // value for 'userName'
 *      organizationId: // value for 'organizationId'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useRemoveUserFromOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveUserFromOrganizationMutation, RemoveUserFromOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveUserFromOrganizationMutation, RemoveUserFromOrganizationMutationVariables>(RemoveUserFromOrganizationDocument, baseOptions);
      }
export type RemoveUserFromOrganizationMutationHookResult = ReturnType<typeof useRemoveUserFromOrganizationMutation>;
export type RemoveUserFromOrganizationMutationResult = ApolloReactCommon.MutationResult<RemoveUserFromOrganizationMutation>;
export type RemoveUserFromOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveUserFromOrganizationMutation, RemoveUserFromOrganizationMutationVariables>;
export const RemoveUserFromOrganizationByIdDocument = gql`
    mutation removeUserFromOrganizationById($userId: ID!, $organizationId: ID!, $clientMutationId: String) {
  removeUserFromOrganizationById(
    input: {userId: $userId, organizationId: $organizationId, clientMutationId: $clientMutationId}
  ) {
    success
    clientMutationId
  }
}
    `;
export type RemoveUserFromOrganizationByIdMutationFn = ApolloReactCommon.MutationFunction<RemoveUserFromOrganizationByIdMutation, RemoveUserFromOrganizationByIdMutationVariables>;
export type RemoveUserFromOrganizationByIdComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RemoveUserFromOrganizationByIdMutation, RemoveUserFromOrganizationByIdMutationVariables>, 'mutation'>;

    export const RemoveUserFromOrganizationByIdComponent = (props: RemoveUserFromOrganizationByIdComponentProps) => (
      <ApolloReactComponents.Mutation<RemoveUserFromOrganizationByIdMutation, RemoveUserFromOrganizationByIdMutationVariables> mutation={RemoveUserFromOrganizationByIdDocument} {...props} />
    );
    
export type RemoveUserFromOrganizationByIdProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RemoveUserFromOrganizationByIdMutation, RemoveUserFromOrganizationByIdMutationVariables>
    } & TChildProps;
export function withRemoveUserFromOrganizationById<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RemoveUserFromOrganizationByIdMutation,
  RemoveUserFromOrganizationByIdMutationVariables,
  RemoveUserFromOrganizationByIdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RemoveUserFromOrganizationByIdMutation, RemoveUserFromOrganizationByIdMutationVariables, RemoveUserFromOrganizationByIdProps<TChildProps, TDataName>>(RemoveUserFromOrganizationByIdDocument, {
      alias: 'removeUserFromOrganizationById',
      ...operationOptions
    });
};

/**
 * __useRemoveUserFromOrganizationByIdMutation__
 *
 * To run a mutation, you first call `useRemoveUserFromOrganizationByIdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveUserFromOrganizationByIdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeUserFromOrganizationByIdMutation, { data, loading, error }] = useRemoveUserFromOrganizationByIdMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      organizationId: // value for 'organizationId'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useRemoveUserFromOrganizationByIdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveUserFromOrganizationByIdMutation, RemoveUserFromOrganizationByIdMutationVariables>) {
        return ApolloReactHooks.useMutation<RemoveUserFromOrganizationByIdMutation, RemoveUserFromOrganizationByIdMutationVariables>(RemoveUserFromOrganizationByIdDocument, baseOptions);
      }
export type RemoveUserFromOrganizationByIdMutationHookResult = ReturnType<typeof useRemoveUserFromOrganizationByIdMutation>;
export type RemoveUserFromOrganizationByIdMutationResult = ApolloReactCommon.MutationResult<RemoveUserFromOrganizationByIdMutation>;
export type RemoveUserFromOrganizationByIdMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveUserFromOrganizationByIdMutation, RemoveUserFromOrganizationByIdMutationVariables>;
export const UpdateOrganizationDocument = gql`
    mutation updateOrganization($organizationID: ID!, $flags: JSONString, $name: String) {
  updateOrganization(
    input: {organizationID: $organizationID, flags: $flags, name: $name}
  ) {
    success
    clientMutationId
  }
}
    `;
export type UpdateOrganizationMutationFn = ApolloReactCommon.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export type UpdateOrganizationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>, 'mutation'>;

    export const UpdateOrganizationComponent = (props: UpdateOrganizationComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables> mutation={UpdateOrganizationDocument} {...props} />
    );
    
export type UpdateOrganizationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>
    } & TChildProps;
export function withUpdateOrganization<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateOrganizationMutation,
  UpdateOrganizationMutationVariables,
  UpdateOrganizationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateOrganizationMutation, UpdateOrganizationMutationVariables, UpdateOrganizationProps<TChildProps, TDataName>>(UpdateOrganizationDocument, {
      alias: 'updateOrganization',
      ...operationOptions
    });
};

/**
 * __useUpdateOrganizationMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationMutation, { data, loading, error }] = useUpdateOrganizationMutation({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *      flags: // value for 'flags'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useUpdateOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>(UpdateOrganizationDocument, baseOptions);
      }
export type UpdateOrganizationMutationHookResult = ReturnType<typeof useUpdateOrganizationMutation>;
export type UpdateOrganizationMutationResult = ApolloReactCommon.MutationResult<UpdateOrganizationMutation>;
export type UpdateOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrganizationMutation, UpdateOrganizationMutationVariables>;
export const DeleteOrganizationDocument = gql`
    mutation deleteOrganization($organizationId: ID!, $clientMutationId: String) {
  deleteOrganization(
    input: {organizationId: $organizationId, clientMutationId: $clientMutationId}
  ) {
    success
    clientMutationId
  }
}
    `;
export type DeleteOrganizationMutationFn = ApolloReactCommon.MutationFunction<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;
export type DeleteOrganizationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>, 'mutation'>;

    export const DeleteOrganizationComponent = (props: DeleteOrganizationComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteOrganizationMutation, DeleteOrganizationMutationVariables> mutation={DeleteOrganizationDocument} {...props} />
    );
    
export type DeleteOrganizationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>
    } & TChildProps;
export function withDeleteOrganization<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteOrganizationMutation,
  DeleteOrganizationMutationVariables,
  DeleteOrganizationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteOrganizationMutation, DeleteOrganizationMutationVariables, DeleteOrganizationProps<TChildProps, TDataName>>(DeleteOrganizationDocument, {
      alias: 'deleteOrganization',
      ...operationOptions
    });
};

/**
 * __useDeleteOrganizationMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationMutation, { data, loading, error }] = useDeleteOrganizationMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useDeleteOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>(DeleteOrganizationDocument, baseOptions);
      }
export type DeleteOrganizationMutationHookResult = ReturnType<typeof useDeleteOrganizationMutation>;
export type DeleteOrganizationMutationResult = ApolloReactCommon.MutationResult<DeleteOrganizationMutation>;
export type DeleteOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOrganizationMutation, DeleteOrganizationMutationVariables>;
export const UpdateOrganizationUserDocument = gql`
    mutation updateOrganizationUser($userName: String!, $organizationId: ID!, $userOrgRole: String!, $clientMutationId: String) {
  updateOrganizationUser(
    input: {userName: $userName, organizationId: $organizationId, userOrgRole: $userOrgRole, clientMutationId: $clientMutationId}
  ) {
    success
    clientMutationId
  }
}
    `;
export type UpdateOrganizationUserMutationFn = ApolloReactCommon.MutationFunction<UpdateOrganizationUserMutation, UpdateOrganizationUserMutationVariables>;
export type UpdateOrganizationUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateOrganizationUserMutation, UpdateOrganizationUserMutationVariables>, 'mutation'>;

    export const UpdateOrganizationUserComponent = (props: UpdateOrganizationUserComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateOrganizationUserMutation, UpdateOrganizationUserMutationVariables> mutation={UpdateOrganizationUserDocument} {...props} />
    );
    
export type UpdateOrganizationUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateOrganizationUserMutation, UpdateOrganizationUserMutationVariables>
    } & TChildProps;
export function withUpdateOrganizationUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateOrganizationUserMutation,
  UpdateOrganizationUserMutationVariables,
  UpdateOrganizationUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateOrganizationUserMutation, UpdateOrganizationUserMutationVariables, UpdateOrganizationUserProps<TChildProps, TDataName>>(UpdateOrganizationUserDocument, {
      alias: 'updateOrganizationUser',
      ...operationOptions
    });
};

/**
 * __useUpdateOrganizationUserMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationUserMutation, { data, loading, error }] = useUpdateOrganizationUserMutation({
 *   variables: {
 *      userName: // value for 'userName'
 *      organizationId: // value for 'organizationId'
 *      userOrgRole: // value for 'userOrgRole'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useUpdateOrganizationUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganizationUserMutation, UpdateOrganizationUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrganizationUserMutation, UpdateOrganizationUserMutationVariables>(UpdateOrganizationUserDocument, baseOptions);
      }
export type UpdateOrganizationUserMutationHookResult = ReturnType<typeof useUpdateOrganizationUserMutation>;
export type UpdateOrganizationUserMutationResult = ApolloReactCommon.MutationResult<UpdateOrganizationUserMutation>;
export type UpdateOrganizationUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrganizationUserMutation, UpdateOrganizationUserMutationVariables>;
export const UpdateOrganizationUserV2Document = gql`
    mutation UpdateOrganizationUserV2($userName: String!, $organizationId: ID!, $userOrgRole: String!, $clientMutationId: String) {
  updateOrganizationUserV2(
    input: {userName: $userName, organizationId: $organizationId, userOrgRole: $userOrgRole, clientMutationId: $clientMutationId}
  ) {
    success
    clientMutationId
  }
}
    `;
export type UpdateOrganizationUserV2MutationFn = ApolloReactCommon.MutationFunction<UpdateOrganizationUserV2Mutation, UpdateOrganizationUserV2MutationVariables>;
export type UpdateOrganizationUserV2ComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateOrganizationUserV2Mutation, UpdateOrganizationUserV2MutationVariables>, 'mutation'>;

    export const UpdateOrganizationUserV2Component = (props: UpdateOrganizationUserV2ComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateOrganizationUserV2Mutation, UpdateOrganizationUserV2MutationVariables> mutation={UpdateOrganizationUserV2Document} {...props} />
    );
    
export type UpdateOrganizationUserV2Props<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateOrganizationUserV2Mutation, UpdateOrganizationUserV2MutationVariables>
    } & TChildProps;
export function withUpdateOrganizationUserV2<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateOrganizationUserV2Mutation,
  UpdateOrganizationUserV2MutationVariables,
  UpdateOrganizationUserV2Props<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateOrganizationUserV2Mutation, UpdateOrganizationUserV2MutationVariables, UpdateOrganizationUserV2Props<TChildProps, TDataName>>(UpdateOrganizationUserV2Document, {
      alias: 'updateOrganizationUserV2',
      ...operationOptions
    });
};

/**
 * __useUpdateOrganizationUserV2Mutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationUserV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationUserV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationUserV2Mutation, { data, loading, error }] = useUpdateOrganizationUserV2Mutation({
 *   variables: {
 *      userName: // value for 'userName'
 *      organizationId: // value for 'organizationId'
 *      userOrgRole: // value for 'userOrgRole'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useUpdateOrganizationUserV2Mutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganizationUserV2Mutation, UpdateOrganizationUserV2MutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrganizationUserV2Mutation, UpdateOrganizationUserV2MutationVariables>(UpdateOrganizationUserV2Document, baseOptions);
      }
export type UpdateOrganizationUserV2MutationHookResult = ReturnType<typeof useUpdateOrganizationUserV2Mutation>;
export type UpdateOrganizationUserV2MutationResult = ApolloReactCommon.MutationResult<UpdateOrganizationUserV2Mutation>;
export type UpdateOrganizationUserV2MutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrganizationUserV2Mutation, UpdateOrganizationUserV2MutationVariables>;
export const UpdateModelsSeatDocument = gql`
    mutation updateModelsSeat($userID: ID!, $organizationId: ID!, $modelsSeat: String!, $clientMutationId: String) {
  updateModelsSeat(
    input: {organizationId: $organizationId, userId: $userID, modelsSeat: $modelsSeat, clientMutationId: $clientMutationId}
  ) {
    success
    clientMutationId
  }
}
    `;
export type UpdateModelsSeatMutationFn = ApolloReactCommon.MutationFunction<UpdateModelsSeatMutation, UpdateModelsSeatMutationVariables>;
export type UpdateModelsSeatComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateModelsSeatMutation, UpdateModelsSeatMutationVariables>, 'mutation'>;

    export const UpdateModelsSeatComponent = (props: UpdateModelsSeatComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateModelsSeatMutation, UpdateModelsSeatMutationVariables> mutation={UpdateModelsSeatDocument} {...props} />
    );
    
export type UpdateModelsSeatProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateModelsSeatMutation, UpdateModelsSeatMutationVariables>
    } & TChildProps;
export function withUpdateModelsSeat<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateModelsSeatMutation,
  UpdateModelsSeatMutationVariables,
  UpdateModelsSeatProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateModelsSeatMutation, UpdateModelsSeatMutationVariables, UpdateModelsSeatProps<TChildProps, TDataName>>(UpdateModelsSeatDocument, {
      alias: 'updateModelsSeat',
      ...operationOptions
    });
};

/**
 * __useUpdateModelsSeatMutation__
 *
 * To run a mutation, you first call `useUpdateModelsSeatMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateModelsSeatMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateModelsSeatMutation, { data, loading, error }] = useUpdateModelsSeatMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      organizationId: // value for 'organizationId'
 *      modelsSeat: // value for 'modelsSeat'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useUpdateModelsSeatMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateModelsSeatMutation, UpdateModelsSeatMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateModelsSeatMutation, UpdateModelsSeatMutationVariables>(UpdateModelsSeatDocument, baseOptions);
      }
export type UpdateModelsSeatMutationHookResult = ReturnType<typeof useUpdateModelsSeatMutation>;
export type UpdateModelsSeatMutationResult = ApolloReactCommon.MutationResult<UpdateModelsSeatMutation>;
export type UpdateModelsSeatMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateModelsSeatMutation, UpdateModelsSeatMutationVariables>;
export const UpdateOrganizationBillingUserDocument = gql`
    mutation updateOrganizationBillingUser($organizationId: ID!, $newBillingUser: ID!, $clientMutationId: String) {
  updateOrganizationBillingUser(
    input: {organizationId: $organizationId, newBillingUser: $newBillingUser, clientMutationId: $clientMutationId}
  ) {
    success
    clientMutationId
  }
}
    `;
export type UpdateOrganizationBillingUserMutationFn = ApolloReactCommon.MutationFunction<UpdateOrganizationBillingUserMutation, UpdateOrganizationBillingUserMutationVariables>;
export type UpdateOrganizationBillingUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateOrganizationBillingUserMutation, UpdateOrganizationBillingUserMutationVariables>, 'mutation'>;

    export const UpdateOrganizationBillingUserComponent = (props: UpdateOrganizationBillingUserComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateOrganizationBillingUserMutation, UpdateOrganizationBillingUserMutationVariables> mutation={UpdateOrganizationBillingUserDocument} {...props} />
    );
    
export type UpdateOrganizationBillingUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateOrganizationBillingUserMutation, UpdateOrganizationBillingUserMutationVariables>
    } & TChildProps;
export function withUpdateOrganizationBillingUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateOrganizationBillingUserMutation,
  UpdateOrganizationBillingUserMutationVariables,
  UpdateOrganizationBillingUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateOrganizationBillingUserMutation, UpdateOrganizationBillingUserMutationVariables, UpdateOrganizationBillingUserProps<TChildProps, TDataName>>(UpdateOrganizationBillingUserDocument, {
      alias: 'updateOrganizationBillingUser',
      ...operationOptions
    });
};

/**
 * __useUpdateOrganizationBillingUserMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationBillingUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationBillingUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationBillingUserMutation, { data, loading, error }] = useUpdateOrganizationBillingUserMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      newBillingUser: // value for 'newBillingUser'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useUpdateOrganizationBillingUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganizationBillingUserMutation, UpdateOrganizationBillingUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrganizationBillingUserMutation, UpdateOrganizationBillingUserMutationVariables>(UpdateOrganizationBillingUserDocument, baseOptions);
      }
export type UpdateOrganizationBillingUserMutationHookResult = ReturnType<typeof useUpdateOrganizationBillingUserMutation>;
export type UpdateOrganizationBillingUserMutationResult = ApolloReactCommon.MutationResult<UpdateOrganizationBillingUserMutation>;
export type UpdateOrganizationBillingUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrganizationBillingUserMutation, UpdateOrganizationBillingUserMutationVariables>;
export const UpdateOrganizationSubscriptionDocument = gql`
    mutation updateOrganizationSubscription($organizationSubscriptionID: ID!, $createStripeSubscription: Boolean!, $planID: ID, $seats: Int, $privileges: JSONString, $type: OrganizationSubscriptionType, $status: OrganizationSubscriptionStatus, $stripeSubscriptionId: String, $expiresAt: DateTime) {
  updateOrganizationSubscription(
    input: {organizationSubscriptionID: $organizationSubscriptionID, planID: $planID, seats: $seats, privileges: $privileges, type: $type, status: $status, stripeSubscriptionId: $stripeSubscriptionId, expiresAt: $expiresAt, createStripeSubscription: $createStripeSubscription}
  ) {
    success
    clientMutationId
  }
}
    `;
export type UpdateOrganizationSubscriptionMutationFn = ApolloReactCommon.MutationFunction<UpdateOrganizationSubscriptionMutation, UpdateOrganizationSubscriptionMutationVariables>;
export type UpdateOrganizationSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateOrganizationSubscriptionMutation, UpdateOrganizationSubscriptionMutationVariables>, 'mutation'>;

    export const UpdateOrganizationSubscriptionComponent = (props: UpdateOrganizationSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateOrganizationSubscriptionMutation, UpdateOrganizationSubscriptionMutationVariables> mutation={UpdateOrganizationSubscriptionDocument} {...props} />
    );
    
export type UpdateOrganizationSubscriptionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateOrganizationSubscriptionMutation, UpdateOrganizationSubscriptionMutationVariables>
    } & TChildProps;
export function withUpdateOrganizationSubscription<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateOrganizationSubscriptionMutation,
  UpdateOrganizationSubscriptionMutationVariables,
  UpdateOrganizationSubscriptionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateOrganizationSubscriptionMutation, UpdateOrganizationSubscriptionMutationVariables, UpdateOrganizationSubscriptionProps<TChildProps, TDataName>>(UpdateOrganizationSubscriptionDocument, {
      alias: 'updateOrganizationSubscription',
      ...operationOptions
    });
};

/**
 * __useUpdateOrganizationSubscriptionMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationSubscriptionMutation, { data, loading, error }] = useUpdateOrganizationSubscriptionMutation({
 *   variables: {
 *      organizationSubscriptionID: // value for 'organizationSubscriptionID'
 *      createStripeSubscription: // value for 'createStripeSubscription'
 *      planID: // value for 'planID'
 *      seats: // value for 'seats'
 *      privileges: // value for 'privileges'
 *      type: // value for 'type'
 *      status: // value for 'status'
 *      stripeSubscriptionId: // value for 'stripeSubscriptionId'
 *      expiresAt: // value for 'expiresAt'
 *   },
 * });
 */
export function useUpdateOrganizationSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganizationSubscriptionMutation, UpdateOrganizationSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrganizationSubscriptionMutation, UpdateOrganizationSubscriptionMutationVariables>(UpdateOrganizationSubscriptionDocument, baseOptions);
      }
export type UpdateOrganizationSubscriptionMutationHookResult = ReturnType<typeof useUpdateOrganizationSubscriptionMutation>;
export type UpdateOrganizationSubscriptionMutationResult = ApolloReactCommon.MutationResult<UpdateOrganizationSubscriptionMutation>;
export type UpdateOrganizationSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrganizationSubscriptionMutation, UpdateOrganizationSubscriptionMutationVariables>;
export const DeleteOrganizationSubscriptionDocument = gql`
    mutation deleteOrganizationSubscription($subscriptionID: ID!) {
  deleteOrganizationSubscription(input: {subscriptionID: $subscriptionID}) {
    success
    clientMutationId
  }
}
    `;
export type DeleteOrganizationSubscriptionMutationFn = ApolloReactCommon.MutationFunction<DeleteOrganizationSubscriptionMutation, DeleteOrganizationSubscriptionMutationVariables>;
export type DeleteOrganizationSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteOrganizationSubscriptionMutation, DeleteOrganizationSubscriptionMutationVariables>, 'mutation'>;

    export const DeleteOrganizationSubscriptionComponent = (props: DeleteOrganizationSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteOrganizationSubscriptionMutation, DeleteOrganizationSubscriptionMutationVariables> mutation={DeleteOrganizationSubscriptionDocument} {...props} />
    );
    
export type DeleteOrganizationSubscriptionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteOrganizationSubscriptionMutation, DeleteOrganizationSubscriptionMutationVariables>
    } & TChildProps;
export function withDeleteOrganizationSubscription<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteOrganizationSubscriptionMutation,
  DeleteOrganizationSubscriptionMutationVariables,
  DeleteOrganizationSubscriptionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteOrganizationSubscriptionMutation, DeleteOrganizationSubscriptionMutationVariables, DeleteOrganizationSubscriptionProps<TChildProps, TDataName>>(DeleteOrganizationSubscriptionDocument, {
      alias: 'deleteOrganizationSubscription',
      ...operationOptions
    });
};

/**
 * __useDeleteOrganizationSubscriptionMutation__
 *
 * To run a mutation, you first call `useDeleteOrganizationSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteOrganizationSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteOrganizationSubscriptionMutation, { data, loading, error }] = useDeleteOrganizationSubscriptionMutation({
 *   variables: {
 *      subscriptionID: // value for 'subscriptionID'
 *   },
 * });
 */
export function useDeleteOrganizationSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteOrganizationSubscriptionMutation, DeleteOrganizationSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteOrganizationSubscriptionMutation, DeleteOrganizationSubscriptionMutationVariables>(DeleteOrganizationSubscriptionDocument, baseOptions);
      }
export type DeleteOrganizationSubscriptionMutationHookResult = ReturnType<typeof useDeleteOrganizationSubscriptionMutation>;
export type DeleteOrganizationSubscriptionMutationResult = ApolloReactCommon.MutationResult<DeleteOrganizationSubscriptionMutation>;
export type DeleteOrganizationSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteOrganizationSubscriptionMutation, DeleteOrganizationSubscriptionMutationVariables>;
export const LinkTeamToOrganizationDocument = gql`
    mutation linkTeamToOrganization($entityName: String!, $organizationID: ID!, $clientMutationId: String) {
  linkTeamToOrganization(
    input: {entityName: $entityName, organizationID: $organizationID, clientMutationId: $clientMutationId}
  ) {
    success
    clientMutationId
  }
}
    `;
export type LinkTeamToOrganizationMutationFn = ApolloReactCommon.MutationFunction<LinkTeamToOrganizationMutation, LinkTeamToOrganizationMutationVariables>;
export type LinkTeamToOrganizationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LinkTeamToOrganizationMutation, LinkTeamToOrganizationMutationVariables>, 'mutation'>;

    export const LinkTeamToOrganizationComponent = (props: LinkTeamToOrganizationComponentProps) => (
      <ApolloReactComponents.Mutation<LinkTeamToOrganizationMutation, LinkTeamToOrganizationMutationVariables> mutation={LinkTeamToOrganizationDocument} {...props} />
    );
    
export type LinkTeamToOrganizationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<LinkTeamToOrganizationMutation, LinkTeamToOrganizationMutationVariables>
    } & TChildProps;
export function withLinkTeamToOrganization<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LinkTeamToOrganizationMutation,
  LinkTeamToOrganizationMutationVariables,
  LinkTeamToOrganizationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, LinkTeamToOrganizationMutation, LinkTeamToOrganizationMutationVariables, LinkTeamToOrganizationProps<TChildProps, TDataName>>(LinkTeamToOrganizationDocument, {
      alias: 'linkTeamToOrganization',
      ...operationOptions
    });
};

/**
 * __useLinkTeamToOrganizationMutation__
 *
 * To run a mutation, you first call `useLinkTeamToOrganizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkTeamToOrganizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkTeamToOrganizationMutation, { data, loading, error }] = useLinkTeamToOrganizationMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      organizationID: // value for 'organizationID'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useLinkTeamToOrganizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LinkTeamToOrganizationMutation, LinkTeamToOrganizationMutationVariables>) {
        return ApolloReactHooks.useMutation<LinkTeamToOrganizationMutation, LinkTeamToOrganizationMutationVariables>(LinkTeamToOrganizationDocument, baseOptions);
      }
export type LinkTeamToOrganizationMutationHookResult = ReturnType<typeof useLinkTeamToOrganizationMutation>;
export type LinkTeamToOrganizationMutationResult = ApolloReactCommon.MutationResult<LinkTeamToOrganizationMutation>;
export type LinkTeamToOrganizationMutationOptions = ApolloReactCommon.BaseMutationOptions<LinkTeamToOrganizationMutation, LinkTeamToOrganizationMutationVariables>;
export const AllOrganizationsDocument = gql`
    query allOrganizations($first: Int, $cursor: String, $queryOrg: String, $querySubId: String, $queryUser: String, $queryTeam: String, $emailDomain: String) {
  organizations(
    first: $first
    after: $cursor
    queryOrg: $queryOrg
    querySubId: $querySubId
    queryUser: $queryUser
    queryTeam: $queryTeam
    emailDomain: $emailDomain
  ) {
    pageInfo {
      hasNextPage
      endCursor
    }
    edges {
      node {
        id
        name
        orgType
        flags
        billingUser {
          id
          username
          email
        }
        teams {
          id
          name
        }
        usedSeats
        usedViewOnlySeats
        stripeBillingInfo {
          stripeSubscriptionId
          status
          currentPeriodEnd
          cancelAtPeriodEnd
          paymentMethod {
            id
            cardType
            endingIn
          }
        }
        members {
          orgID
          id
          admin
          name
          username
          photoUrl
          teams {
            edges {
              node {
                id
                name
              }
            }
          }
          role
          modelsSeat
        }
        subscriptions {
          plan {
            id
            name
            planType
            displayName
          }
          seats
          id
          subscriptionType
          stripeSubscriptionId
          status
          expiresAt
          privileges
        }
      }
    }
  }
}
    `;
export type AllOrganizationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllOrganizationsQuery, AllOrganizationsQueryVariables>, 'query'>;

    export const AllOrganizationsComponent = (props: AllOrganizationsComponentProps) => (
      <ApolloReactComponents.Query<AllOrganizationsQuery, AllOrganizationsQueryVariables> query={AllOrganizationsDocument} {...props} />
    );
    
export type AllOrganizationsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AllOrganizationsQuery, AllOrganizationsQueryVariables>
    } & TChildProps;
export function withAllOrganizations<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AllOrganizationsQuery,
  AllOrganizationsQueryVariables,
  AllOrganizationsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AllOrganizationsQuery, AllOrganizationsQueryVariables, AllOrganizationsProps<TChildProps, TDataName>>(AllOrganizationsDocument, {
      alias: 'allOrganizations',
      ...operationOptions
    });
};

/**
 * __useAllOrganizationsQuery__
 *
 * To run a query within a React component, call `useAllOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllOrganizationsQuery({
 *   variables: {
 *      first: // value for 'first'
 *      cursor: // value for 'cursor'
 *      queryOrg: // value for 'queryOrg'
 *      querySubId: // value for 'querySubId'
 *      queryUser: // value for 'queryUser'
 *      queryTeam: // value for 'queryTeam'
 *      emailDomain: // value for 'emailDomain'
 *   },
 * });
 */
export function useAllOrganizationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllOrganizationsQuery, AllOrganizationsQueryVariables>) {
        return ApolloReactHooks.useQuery<AllOrganizationsQuery, AllOrganizationsQueryVariables>(AllOrganizationsDocument, baseOptions);
      }
export function useAllOrganizationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllOrganizationsQuery, AllOrganizationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllOrganizationsQuery, AllOrganizationsQueryVariables>(AllOrganizationsDocument, baseOptions);
        }
export type AllOrganizationsQueryHookResult = ReturnType<typeof useAllOrganizationsQuery>;
export type AllOrganizationsLazyQueryHookResult = ReturnType<typeof useAllOrganizationsLazyQuery>;
export type AllOrganizationsQueryResult = ApolloReactCommon.QueryResult<AllOrganizationsQuery, AllOrganizationsQueryVariables>;
export const OrganizationSubscriptionsDocument = gql`
    query OrganizationSubscriptions {
  viewer {
    id
    organizations {
      id
      createdAt
      name
      billingUser {
        id
        username
        email
      }
      flags
      usedSeats
      subscriptions {
        id
        subscriptionType
        status
        expiresAt
        privileges
        plan {
          id
          name
          planType
          billingInterval
        }
        seats
        isAutomaticUpgrade
        createdAt
        thresholdCrossedAt
        upgradedAt
        billingPeriodStart
        billingPeriodEnd
        expansionPacks {
          edges {
            node {
              id
              secondsLimit
              secondsRemaining
              expiresAt
            }
          }
        }
      }
      teams {
        id
        name
        computeHours
        oldComputeHours: computeHours(minDaysOld: 14)
      }
      members {
        orgID
        id
        username
        role
      }
      pendingInvites {
        id
      }
    }
  }
}
    `;
export type OrganizationSubscriptionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationSubscriptionsQuery, OrganizationSubscriptionsQueryVariables>, 'query'>;

    export const OrganizationSubscriptionsComponent = (props: OrganizationSubscriptionsComponentProps) => (
      <ApolloReactComponents.Query<OrganizationSubscriptionsQuery, OrganizationSubscriptionsQueryVariables> query={OrganizationSubscriptionsDocument} {...props} />
    );
    
export type OrganizationSubscriptionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationSubscriptionsQuery, OrganizationSubscriptionsQueryVariables>
    } & TChildProps;
export function withOrganizationSubscriptions<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationSubscriptionsQuery,
  OrganizationSubscriptionsQueryVariables,
  OrganizationSubscriptionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationSubscriptionsQuery, OrganizationSubscriptionsQueryVariables, OrganizationSubscriptionsProps<TChildProps, TDataName>>(OrganizationSubscriptionsDocument, {
      alias: 'organizationSubscriptions',
      ...operationOptions
    });
};

/**
 * __useOrganizationSubscriptionsQuery__
 *
 * To run a query within a React component, call `useOrganizationSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationSubscriptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationSubscriptionsQuery, OrganizationSubscriptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationSubscriptionsQuery, OrganizationSubscriptionsQueryVariables>(OrganizationSubscriptionsDocument, baseOptions);
      }
export function useOrganizationSubscriptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationSubscriptionsQuery, OrganizationSubscriptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationSubscriptionsQuery, OrganizationSubscriptionsQueryVariables>(OrganizationSubscriptionsDocument, baseOptions);
        }
export type OrganizationSubscriptionsQueryHookResult = ReturnType<typeof useOrganizationSubscriptionsQuery>;
export type OrganizationSubscriptionsLazyQueryHookResult = ReturnType<typeof useOrganizationSubscriptionsLazyQuery>;
export type OrganizationSubscriptionsQueryResult = ApolloReactCommon.QueryResult<OrganizationSubscriptionsQuery, OrganizationSubscriptionsQueryVariables>;
export const OrganizationStorageInfoDocument = gql`
    query OrganizationStorageInfo($organizationID: ID!) {
  organization(id: $organizationID) {
    id
    teams {
      id
      storageBytes(cached: true)
      referenceBytes(cached: true)
    }
  }
}
    `;
export type OrganizationStorageInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationStorageInfoQuery, OrganizationStorageInfoQueryVariables>, 'query'> & ({ variables: OrganizationStorageInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationStorageInfoComponent = (props: OrganizationStorageInfoComponentProps) => (
      <ApolloReactComponents.Query<OrganizationStorageInfoQuery, OrganizationStorageInfoQueryVariables> query={OrganizationStorageInfoDocument} {...props} />
    );
    
export type OrganizationStorageInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationStorageInfoQuery, OrganizationStorageInfoQueryVariables>
    } & TChildProps;
export function withOrganizationStorageInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationStorageInfoQuery,
  OrganizationStorageInfoQueryVariables,
  OrganizationStorageInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationStorageInfoQuery, OrganizationStorageInfoQueryVariables, OrganizationStorageInfoProps<TChildProps, TDataName>>(OrganizationStorageInfoDocument, {
      alias: 'organizationStorageInfo',
      ...operationOptions
    });
};

/**
 * __useOrganizationStorageInfoQuery__
 *
 * To run a query within a React component, call `useOrganizationStorageInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationStorageInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationStorageInfoQuery({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *   },
 * });
 */
export function useOrganizationStorageInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationStorageInfoQuery, OrganizationStorageInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationStorageInfoQuery, OrganizationStorageInfoQueryVariables>(OrganizationStorageInfoDocument, baseOptions);
      }
export function useOrganizationStorageInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationStorageInfoQuery, OrganizationStorageInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationStorageInfoQuery, OrganizationStorageInfoQueryVariables>(OrganizationStorageInfoDocument, baseOptions);
        }
export type OrganizationStorageInfoQueryHookResult = ReturnType<typeof useOrganizationStorageInfoQuery>;
export type OrganizationStorageInfoLazyQueryHookResult = ReturnType<typeof useOrganizationStorageInfoLazyQuery>;
export type OrganizationStorageInfoQueryResult = ApolloReactCommon.QueryResult<OrganizationStorageInfoQuery, OrganizationStorageInfoQueryVariables>;
export const OrganizationSubscriptionsBasicInfoDocument = gql`
    query OrganizationSubscriptionsBasicInfo {
  viewer {
    id
    username
    organizations {
      id
      ...OrganizationSubscriptionBasicInfo
    }
  }
}
    ${OrganizationSubscriptionBasicInfoFragmentDoc}`;
export type OrganizationSubscriptionsBasicInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationSubscriptionsBasicInfoQuery, OrganizationSubscriptionsBasicInfoQueryVariables>, 'query'>;

    export const OrganizationSubscriptionsBasicInfoComponent = (props: OrganizationSubscriptionsBasicInfoComponentProps) => (
      <ApolloReactComponents.Query<OrganizationSubscriptionsBasicInfoQuery, OrganizationSubscriptionsBasicInfoQueryVariables> query={OrganizationSubscriptionsBasicInfoDocument} {...props} />
    );
    
export type OrganizationSubscriptionsBasicInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationSubscriptionsBasicInfoQuery, OrganizationSubscriptionsBasicInfoQueryVariables>
    } & TChildProps;
export function withOrganizationSubscriptionsBasicInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationSubscriptionsBasicInfoQuery,
  OrganizationSubscriptionsBasicInfoQueryVariables,
  OrganizationSubscriptionsBasicInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationSubscriptionsBasicInfoQuery, OrganizationSubscriptionsBasicInfoQueryVariables, OrganizationSubscriptionsBasicInfoProps<TChildProps, TDataName>>(OrganizationSubscriptionsBasicInfoDocument, {
      alias: 'organizationSubscriptionsBasicInfo',
      ...operationOptions
    });
};

/**
 * __useOrganizationSubscriptionsBasicInfoQuery__
 *
 * To run a query within a React component, call `useOrganizationSubscriptionsBasicInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSubscriptionsBasicInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSubscriptionsBasicInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useOrganizationSubscriptionsBasicInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationSubscriptionsBasicInfoQuery, OrganizationSubscriptionsBasicInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationSubscriptionsBasicInfoQuery, OrganizationSubscriptionsBasicInfoQueryVariables>(OrganizationSubscriptionsBasicInfoDocument, baseOptions);
      }
export function useOrganizationSubscriptionsBasicInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationSubscriptionsBasicInfoQuery, OrganizationSubscriptionsBasicInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationSubscriptionsBasicInfoQuery, OrganizationSubscriptionsBasicInfoQueryVariables>(OrganizationSubscriptionsBasicInfoDocument, baseOptions);
        }
export type OrganizationSubscriptionsBasicInfoQueryHookResult = ReturnType<typeof useOrganizationSubscriptionsBasicInfoQuery>;
export type OrganizationSubscriptionsBasicInfoLazyQueryHookResult = ReturnType<typeof useOrganizationSubscriptionsBasicInfoLazyQuery>;
export type OrganizationSubscriptionsBasicInfoQueryResult = ApolloReactCommon.QueryResult<OrganizationSubscriptionsBasicInfoQuery, OrganizationSubscriptionsBasicInfoQueryVariables>;
export const OrganizationSubscriptionBasicInfoByNameDocument = gql`
    query OrganizationSubscriptionBasicInfoByName($name: String!) {
  organization(name: $name) {
    id
    ...OrganizationSubscriptionBasicInfo
  }
}
    ${OrganizationSubscriptionBasicInfoFragmentDoc}`;
export type OrganizationSubscriptionBasicInfoByNameComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationSubscriptionBasicInfoByNameQuery, OrganizationSubscriptionBasicInfoByNameQueryVariables>, 'query'> & ({ variables: OrganizationSubscriptionBasicInfoByNameQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationSubscriptionBasicInfoByNameComponent = (props: OrganizationSubscriptionBasicInfoByNameComponentProps) => (
      <ApolloReactComponents.Query<OrganizationSubscriptionBasicInfoByNameQuery, OrganizationSubscriptionBasicInfoByNameQueryVariables> query={OrganizationSubscriptionBasicInfoByNameDocument} {...props} />
    );
    
export type OrganizationSubscriptionBasicInfoByNameProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationSubscriptionBasicInfoByNameQuery, OrganizationSubscriptionBasicInfoByNameQueryVariables>
    } & TChildProps;
export function withOrganizationSubscriptionBasicInfoByName<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationSubscriptionBasicInfoByNameQuery,
  OrganizationSubscriptionBasicInfoByNameQueryVariables,
  OrganizationSubscriptionBasicInfoByNameProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationSubscriptionBasicInfoByNameQuery, OrganizationSubscriptionBasicInfoByNameQueryVariables, OrganizationSubscriptionBasicInfoByNameProps<TChildProps, TDataName>>(OrganizationSubscriptionBasicInfoByNameDocument, {
      alias: 'organizationSubscriptionBasicInfoByName',
      ...operationOptions
    });
};

/**
 * __useOrganizationSubscriptionBasicInfoByNameQuery__
 *
 * To run a query within a React component, call `useOrganizationSubscriptionBasicInfoByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSubscriptionBasicInfoByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSubscriptionBasicInfoByNameQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useOrganizationSubscriptionBasicInfoByNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationSubscriptionBasicInfoByNameQuery, OrganizationSubscriptionBasicInfoByNameQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationSubscriptionBasicInfoByNameQuery, OrganizationSubscriptionBasicInfoByNameQueryVariables>(OrganizationSubscriptionBasicInfoByNameDocument, baseOptions);
      }
export function useOrganizationSubscriptionBasicInfoByNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationSubscriptionBasicInfoByNameQuery, OrganizationSubscriptionBasicInfoByNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationSubscriptionBasicInfoByNameQuery, OrganizationSubscriptionBasicInfoByNameQueryVariables>(OrganizationSubscriptionBasicInfoByNameDocument, baseOptions);
        }
export type OrganizationSubscriptionBasicInfoByNameQueryHookResult = ReturnType<typeof useOrganizationSubscriptionBasicInfoByNameQuery>;
export type OrganizationSubscriptionBasicInfoByNameLazyQueryHookResult = ReturnType<typeof useOrganizationSubscriptionBasicInfoByNameLazyQuery>;
export type OrganizationSubscriptionBasicInfoByNameQueryResult = ApolloReactCommon.QueryResult<OrganizationSubscriptionBasicInfoByNameQuery, OrganizationSubscriptionBasicInfoByNameQueryVariables>;
export const OrganizationSubscriptionsBasicDetailsDocument = gql`
    query OrganizationSubscriptionsBasicDetails($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    subscriptions {
      id
      subscriptionType
      billingPeriodStart
      billingPeriodEnd
      plan {
        id
        name
        planType
      }
    }
  }
}
    `;
export type OrganizationSubscriptionsBasicDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationSubscriptionsBasicDetailsQuery, OrganizationSubscriptionsBasicDetailsQueryVariables>, 'query'> & ({ variables: OrganizationSubscriptionsBasicDetailsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationSubscriptionsBasicDetailsComponent = (props: OrganizationSubscriptionsBasicDetailsComponentProps) => (
      <ApolloReactComponents.Query<OrganizationSubscriptionsBasicDetailsQuery, OrganizationSubscriptionsBasicDetailsQueryVariables> query={OrganizationSubscriptionsBasicDetailsDocument} {...props} />
    );
    
export type OrganizationSubscriptionsBasicDetailsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationSubscriptionsBasicDetailsQuery, OrganizationSubscriptionsBasicDetailsQueryVariables>
    } & TChildProps;
export function withOrganizationSubscriptionsBasicDetails<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationSubscriptionsBasicDetailsQuery,
  OrganizationSubscriptionsBasicDetailsQueryVariables,
  OrganizationSubscriptionsBasicDetailsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationSubscriptionsBasicDetailsQuery, OrganizationSubscriptionsBasicDetailsQueryVariables, OrganizationSubscriptionsBasicDetailsProps<TChildProps, TDataName>>(OrganizationSubscriptionsBasicDetailsDocument, {
      alias: 'organizationSubscriptionsBasicDetails',
      ...operationOptions
    });
};

/**
 * __useOrganizationSubscriptionsBasicDetailsQuery__
 *
 * To run a query within a React component, call `useOrganizationSubscriptionsBasicDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSubscriptionsBasicDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSubscriptionsBasicDetailsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationSubscriptionsBasicDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationSubscriptionsBasicDetailsQuery, OrganizationSubscriptionsBasicDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationSubscriptionsBasicDetailsQuery, OrganizationSubscriptionsBasicDetailsQueryVariables>(OrganizationSubscriptionsBasicDetailsDocument, baseOptions);
      }
export function useOrganizationSubscriptionsBasicDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationSubscriptionsBasicDetailsQuery, OrganizationSubscriptionsBasicDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationSubscriptionsBasicDetailsQuery, OrganizationSubscriptionsBasicDetailsQueryVariables>(OrganizationSubscriptionsBasicDetailsDocument, baseOptions);
        }
export type OrganizationSubscriptionsBasicDetailsQueryHookResult = ReturnType<typeof useOrganizationSubscriptionsBasicDetailsQuery>;
export type OrganizationSubscriptionsBasicDetailsLazyQueryHookResult = ReturnType<typeof useOrganizationSubscriptionsBasicDetailsLazyQuery>;
export type OrganizationSubscriptionsBasicDetailsQueryResult = ApolloReactCommon.QueryResult<OrganizationSubscriptionsBasicDetailsQuery, OrganizationSubscriptionsBasicDetailsQueryVariables>;
export const OrganizationComputeSecondUsageInfoDocument = gql`
    query OrganizationComputeSecondUsageInfo($organizationId: ID!, $timeWindow: TimeWindow!) {
  organization(id: $organizationId) {
    id
    teams {
      id
      computeHours(cached: true, timeWindow: $timeWindow)
    }
  }
}
    `;
export type OrganizationComputeSecondUsageInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationComputeSecondUsageInfoQuery, OrganizationComputeSecondUsageInfoQueryVariables>, 'query'> & ({ variables: OrganizationComputeSecondUsageInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationComputeSecondUsageInfoComponent = (props: OrganizationComputeSecondUsageInfoComponentProps) => (
      <ApolloReactComponents.Query<OrganizationComputeSecondUsageInfoQuery, OrganizationComputeSecondUsageInfoQueryVariables> query={OrganizationComputeSecondUsageInfoDocument} {...props} />
    );
    
export type OrganizationComputeSecondUsageInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationComputeSecondUsageInfoQuery, OrganizationComputeSecondUsageInfoQueryVariables>
    } & TChildProps;
export function withOrganizationComputeSecondUsageInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationComputeSecondUsageInfoQuery,
  OrganizationComputeSecondUsageInfoQueryVariables,
  OrganizationComputeSecondUsageInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationComputeSecondUsageInfoQuery, OrganizationComputeSecondUsageInfoQueryVariables, OrganizationComputeSecondUsageInfoProps<TChildProps, TDataName>>(OrganizationComputeSecondUsageInfoDocument, {
      alias: 'organizationComputeSecondUsageInfo',
      ...operationOptions
    });
};

/**
 * __useOrganizationComputeSecondUsageInfoQuery__
 *
 * To run a query within a React component, call `useOrganizationComputeSecondUsageInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationComputeSecondUsageInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationComputeSecondUsageInfoQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      timeWindow: // value for 'timeWindow'
 *   },
 * });
 */
export function useOrganizationComputeSecondUsageInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationComputeSecondUsageInfoQuery, OrganizationComputeSecondUsageInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationComputeSecondUsageInfoQuery, OrganizationComputeSecondUsageInfoQueryVariables>(OrganizationComputeSecondUsageInfoDocument, baseOptions);
      }
export function useOrganizationComputeSecondUsageInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationComputeSecondUsageInfoQuery, OrganizationComputeSecondUsageInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationComputeSecondUsageInfoQuery, OrganizationComputeSecondUsageInfoQueryVariables>(OrganizationComputeSecondUsageInfoDocument, baseOptions);
        }
export type OrganizationComputeSecondUsageInfoQueryHookResult = ReturnType<typeof useOrganizationComputeSecondUsageInfoQuery>;
export type OrganizationComputeSecondUsageInfoLazyQueryHookResult = ReturnType<typeof useOrganizationComputeSecondUsageInfoLazyQuery>;
export type OrganizationComputeSecondUsageInfoQueryResult = ApolloReactCommon.QueryResult<OrganizationComputeSecondUsageInfoQuery, OrganizationComputeSecondUsageInfoQueryVariables>;
export const UpdateOrganizationSubscriptionSeatsDocument = gql`
    mutation updateOrganizationSubscriptionSeats($organizationID: ID!, $seats: Int!, $clientMutationId: String) {
  updateOrganizationSubscriptionSeats(
    input: {organizationID: $organizationID, seats: $seats, clientMutationId: $clientMutationId}
  ) {
    success
    clientMutationId
  }
}
    `;
export type UpdateOrganizationSubscriptionSeatsMutationFn = ApolloReactCommon.MutationFunction<UpdateOrganizationSubscriptionSeatsMutation, UpdateOrganizationSubscriptionSeatsMutationVariables>;
export type UpdateOrganizationSubscriptionSeatsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateOrganizationSubscriptionSeatsMutation, UpdateOrganizationSubscriptionSeatsMutationVariables>, 'mutation'>;

    export const UpdateOrganizationSubscriptionSeatsComponent = (props: UpdateOrganizationSubscriptionSeatsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateOrganizationSubscriptionSeatsMutation, UpdateOrganizationSubscriptionSeatsMutationVariables> mutation={UpdateOrganizationSubscriptionSeatsDocument} {...props} />
    );
    
export type UpdateOrganizationSubscriptionSeatsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateOrganizationSubscriptionSeatsMutation, UpdateOrganizationSubscriptionSeatsMutationVariables>
    } & TChildProps;
export function withUpdateOrganizationSubscriptionSeats<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateOrganizationSubscriptionSeatsMutation,
  UpdateOrganizationSubscriptionSeatsMutationVariables,
  UpdateOrganizationSubscriptionSeatsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateOrganizationSubscriptionSeatsMutation, UpdateOrganizationSubscriptionSeatsMutationVariables, UpdateOrganizationSubscriptionSeatsProps<TChildProps, TDataName>>(UpdateOrganizationSubscriptionSeatsDocument, {
      alias: 'updateOrganizationSubscriptionSeats',
      ...operationOptions
    });
};

/**
 * __useUpdateOrganizationSubscriptionSeatsMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationSubscriptionSeatsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationSubscriptionSeatsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationSubscriptionSeatsMutation, { data, loading, error }] = useUpdateOrganizationSubscriptionSeatsMutation({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *      seats: // value for 'seats'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useUpdateOrganizationSubscriptionSeatsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganizationSubscriptionSeatsMutation, UpdateOrganizationSubscriptionSeatsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrganizationSubscriptionSeatsMutation, UpdateOrganizationSubscriptionSeatsMutationVariables>(UpdateOrganizationSubscriptionSeatsDocument, baseOptions);
      }
export type UpdateOrganizationSubscriptionSeatsMutationHookResult = ReturnType<typeof useUpdateOrganizationSubscriptionSeatsMutation>;
export type UpdateOrganizationSubscriptionSeatsMutationResult = ApolloReactCommon.MutationResult<UpdateOrganizationSubscriptionSeatsMutation>;
export type UpdateOrganizationSubscriptionSeatsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrganizationSubscriptionSeatsMutation, UpdateOrganizationSubscriptionSeatsMutationVariables>;
export const SendRequestOrgEmailDomainVerificationDocument = gql`
    mutation sendRequestOrgEmailDomainVerification($organizationID: ID!, $clientMutationId: String) {
  sendRequestOrgEmailDomainVerification(
    input: {organizationID: $organizationID, clientMutationId: $clientMutationId}
  ) {
    success
    clientMutationId
  }
}
    `;
export type SendRequestOrgEmailDomainVerificationMutationFn = ApolloReactCommon.MutationFunction<SendRequestOrgEmailDomainVerificationMutation, SendRequestOrgEmailDomainVerificationMutationVariables>;
export type SendRequestOrgEmailDomainVerificationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SendRequestOrgEmailDomainVerificationMutation, SendRequestOrgEmailDomainVerificationMutationVariables>, 'mutation'>;

    export const SendRequestOrgEmailDomainVerificationComponent = (props: SendRequestOrgEmailDomainVerificationComponentProps) => (
      <ApolloReactComponents.Mutation<SendRequestOrgEmailDomainVerificationMutation, SendRequestOrgEmailDomainVerificationMutationVariables> mutation={SendRequestOrgEmailDomainVerificationDocument} {...props} />
    );
    
export type SendRequestOrgEmailDomainVerificationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<SendRequestOrgEmailDomainVerificationMutation, SendRequestOrgEmailDomainVerificationMutationVariables>
    } & TChildProps;
export function withSendRequestOrgEmailDomainVerification<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SendRequestOrgEmailDomainVerificationMutation,
  SendRequestOrgEmailDomainVerificationMutationVariables,
  SendRequestOrgEmailDomainVerificationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, SendRequestOrgEmailDomainVerificationMutation, SendRequestOrgEmailDomainVerificationMutationVariables, SendRequestOrgEmailDomainVerificationProps<TChildProps, TDataName>>(SendRequestOrgEmailDomainVerificationDocument, {
      alias: 'sendRequestOrgEmailDomainVerification',
      ...operationOptions
    });
};

/**
 * __useSendRequestOrgEmailDomainVerificationMutation__
 *
 * To run a mutation, you first call `useSendRequestOrgEmailDomainVerificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendRequestOrgEmailDomainVerificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendRequestOrgEmailDomainVerificationMutation, { data, loading, error }] = useSendRequestOrgEmailDomainVerificationMutation({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useSendRequestOrgEmailDomainVerificationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SendRequestOrgEmailDomainVerificationMutation, SendRequestOrgEmailDomainVerificationMutationVariables>) {
        return ApolloReactHooks.useMutation<SendRequestOrgEmailDomainVerificationMutation, SendRequestOrgEmailDomainVerificationMutationVariables>(SendRequestOrgEmailDomainVerificationDocument, baseOptions);
      }
export type SendRequestOrgEmailDomainVerificationMutationHookResult = ReturnType<typeof useSendRequestOrgEmailDomainVerificationMutation>;
export type SendRequestOrgEmailDomainVerificationMutationResult = ApolloReactCommon.MutationResult<SendRequestOrgEmailDomainVerificationMutation>;
export type SendRequestOrgEmailDomainVerificationMutationOptions = ApolloReactCommon.BaseMutationOptions<SendRequestOrgEmailDomainVerificationMutation, SendRequestOrgEmailDomainVerificationMutationVariables>;
export const OrganizationRunsDocument = gql`
    query OrganizationRuns($orgID: ID!, $userID: ID!) {
  organization(id: $orgID) {
    id
    latestRuns(userID: $userID) {
      edges {
        node {
          id
          ...RunsTableFragment
        }
      }
    }
  }
}
    ${RunsTableFragmentFragmentDoc}`;
export type OrganizationRunsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationRunsQuery, OrganizationRunsQueryVariables>, 'query'> & ({ variables: OrganizationRunsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationRunsComponent = (props: OrganizationRunsComponentProps) => (
      <ApolloReactComponents.Query<OrganizationRunsQuery, OrganizationRunsQueryVariables> query={OrganizationRunsDocument} {...props} />
    );
    
export type OrganizationRunsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationRunsQuery, OrganizationRunsQueryVariables>
    } & TChildProps;
export function withOrganizationRuns<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationRunsQuery,
  OrganizationRunsQueryVariables,
  OrganizationRunsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationRunsQuery, OrganizationRunsQueryVariables, OrganizationRunsProps<TChildProps, TDataName>>(OrganizationRunsDocument, {
      alias: 'organizationRuns',
      ...operationOptions
    });
};

/**
 * __useOrganizationRunsQuery__
 *
 * To run a query within a React component, call `useOrganizationRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationRunsQuery({
 *   variables: {
 *      orgID: // value for 'orgID'
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useOrganizationRunsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationRunsQuery, OrganizationRunsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationRunsQuery, OrganizationRunsQueryVariables>(OrganizationRunsDocument, baseOptions);
      }
export function useOrganizationRunsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationRunsQuery, OrganizationRunsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationRunsQuery, OrganizationRunsQueryVariables>(OrganizationRunsDocument, baseOptions);
        }
export type OrganizationRunsQueryHookResult = ReturnType<typeof useOrganizationRunsQuery>;
export type OrganizationRunsLazyQueryHookResult = ReturnType<typeof useOrganizationRunsLazyQuery>;
export type OrganizationRunsQueryResult = ApolloReactCommon.QueryResult<OrganizationRunsQuery, OrganizationRunsQueryVariables>;
export const OrganizationServiceAccountsDocument = gql`
    query OrganizationServiceAccounts($orgName: String!) {
  organization(name: $orgName) {
    id
    serviceAccounts {
      id
      name
      createdAt
      apiKey
      accountType
      defaultEntity {
        name
        id
      }
      teams {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
}
    `;
export type OrganizationServiceAccountsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationServiceAccountsQuery, OrganizationServiceAccountsQueryVariables>, 'query'> & ({ variables: OrganizationServiceAccountsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationServiceAccountsComponent = (props: OrganizationServiceAccountsComponentProps) => (
      <ApolloReactComponents.Query<OrganizationServiceAccountsQuery, OrganizationServiceAccountsQueryVariables> query={OrganizationServiceAccountsDocument} {...props} />
    );
    
export type OrganizationServiceAccountsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationServiceAccountsQuery, OrganizationServiceAccountsQueryVariables>
    } & TChildProps;
export function withOrganizationServiceAccounts<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationServiceAccountsQuery,
  OrganizationServiceAccountsQueryVariables,
  OrganizationServiceAccountsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationServiceAccountsQuery, OrganizationServiceAccountsQueryVariables, OrganizationServiceAccountsProps<TChildProps, TDataName>>(OrganizationServiceAccountsDocument, {
      alias: 'organizationServiceAccounts',
      ...operationOptions
    });
};

/**
 * __useOrganizationServiceAccountsQuery__
 *
 * To run a query within a React component, call `useOrganizationServiceAccountsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationServiceAccountsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationServiceAccountsQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useOrganizationServiceAccountsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationServiceAccountsQuery, OrganizationServiceAccountsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationServiceAccountsQuery, OrganizationServiceAccountsQueryVariables>(OrganizationServiceAccountsDocument, baseOptions);
      }
export function useOrganizationServiceAccountsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationServiceAccountsQuery, OrganizationServiceAccountsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationServiceAccountsQuery, OrganizationServiceAccountsQueryVariables>(OrganizationServiceAccountsDocument, baseOptions);
        }
export type OrganizationServiceAccountsQueryHookResult = ReturnType<typeof useOrganizationServiceAccountsQuery>;
export type OrganizationServiceAccountsLazyQueryHookResult = ReturnType<typeof useOrganizationServiceAccountsLazyQuery>;
export type OrganizationServiceAccountsQueryResult = ApolloReactCommon.QueryResult<OrganizationServiceAccountsQuery, OrganizationServiceAccountsQueryVariables>;
export const OrganizationProjectsDocument = gql`
    query OrganizationProjects($orgID: ID!, $userID: ID, $first: Int = 20) {
  organization(id: $orgID) {
    id
    projects(userID: $userID, first: $first, order: "-created_at") {
      edges {
        node {
          id
          ...AccountProjectFragment
        }
      }
    }
  }
}
    ${AccountProjectFragmentFragmentDoc}`;
export type OrganizationProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationProjectsQuery, OrganizationProjectsQueryVariables>, 'query'> & ({ variables: OrganizationProjectsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationProjectsComponent = (props: OrganizationProjectsComponentProps) => (
      <ApolloReactComponents.Query<OrganizationProjectsQuery, OrganizationProjectsQueryVariables> query={OrganizationProjectsDocument} {...props} />
    );
    
export type OrganizationProjectsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationProjectsQuery, OrganizationProjectsQueryVariables>
    } & TChildProps;
export function withOrganizationProjects<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationProjectsQuery,
  OrganizationProjectsQueryVariables,
  OrganizationProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationProjectsQuery, OrganizationProjectsQueryVariables, OrganizationProjectsProps<TChildProps, TDataName>>(OrganizationProjectsDocument, {
      alias: 'organizationProjects',
      ...operationOptions
    });
};

/**
 * __useOrganizationProjectsQuery__
 *
 * To run a query within a React component, call `useOrganizationProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationProjectsQuery({
 *   variables: {
 *      orgID: // value for 'orgID'
 *      userID: // value for 'userID'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useOrganizationProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationProjectsQuery, OrganizationProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationProjectsQuery, OrganizationProjectsQueryVariables>(OrganizationProjectsDocument, baseOptions);
      }
export function useOrganizationProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationProjectsQuery, OrganizationProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationProjectsQuery, OrganizationProjectsQueryVariables>(OrganizationProjectsDocument, baseOptions);
        }
export type OrganizationProjectsQueryHookResult = ReturnType<typeof useOrganizationProjectsQuery>;
export type OrganizationProjectsLazyQueryHookResult = ReturnType<typeof useOrganizationProjectsLazyQuery>;
export type OrganizationProjectsQueryResult = ApolloReactCommon.QueryResult<OrganizationProjectsQuery, OrganizationProjectsQueryVariables>;
export const ViewerOrganizationFeatureFlagsDocument = gql`
    query viewerOrganizationFeatureFlags($rampIDType: RampIDType!) {
  viewer {
    id
    organizations {
      id
      name
      featureFlags(rampIDType: $rampIDType) {
        rampKey
        isEnabled
      }
    }
  }
}
    `;
export type ViewerOrganizationFeatureFlagsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewerOrganizationFeatureFlagsQuery, ViewerOrganizationFeatureFlagsQueryVariables>, 'query'> & ({ variables: ViewerOrganizationFeatureFlagsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ViewerOrganizationFeatureFlagsComponent = (props: ViewerOrganizationFeatureFlagsComponentProps) => (
      <ApolloReactComponents.Query<ViewerOrganizationFeatureFlagsQuery, ViewerOrganizationFeatureFlagsQueryVariables> query={ViewerOrganizationFeatureFlagsDocument} {...props} />
    );
    
export type ViewerOrganizationFeatureFlagsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewerOrganizationFeatureFlagsQuery, ViewerOrganizationFeatureFlagsQueryVariables>
    } & TChildProps;
export function withViewerOrganizationFeatureFlags<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewerOrganizationFeatureFlagsQuery,
  ViewerOrganizationFeatureFlagsQueryVariables,
  ViewerOrganizationFeatureFlagsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewerOrganizationFeatureFlagsQuery, ViewerOrganizationFeatureFlagsQueryVariables, ViewerOrganizationFeatureFlagsProps<TChildProps, TDataName>>(ViewerOrganizationFeatureFlagsDocument, {
      alias: 'viewerOrganizationFeatureFlags',
      ...operationOptions
    });
};

/**
 * __useViewerOrganizationFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useViewerOrganizationFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerOrganizationFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerOrganizationFeatureFlagsQuery({
 *   variables: {
 *      rampIDType: // value for 'rampIDType'
 *   },
 * });
 */
export function useViewerOrganizationFeatureFlagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewerOrganizationFeatureFlagsQuery, ViewerOrganizationFeatureFlagsQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewerOrganizationFeatureFlagsQuery, ViewerOrganizationFeatureFlagsQueryVariables>(ViewerOrganizationFeatureFlagsDocument, baseOptions);
      }
export function useViewerOrganizationFeatureFlagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewerOrganizationFeatureFlagsQuery, ViewerOrganizationFeatureFlagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewerOrganizationFeatureFlagsQuery, ViewerOrganizationFeatureFlagsQueryVariables>(ViewerOrganizationFeatureFlagsDocument, baseOptions);
        }
export type ViewerOrganizationFeatureFlagsQueryHookResult = ReturnType<typeof useViewerOrganizationFeatureFlagsQuery>;
export type ViewerOrganizationFeatureFlagsLazyQueryHookResult = ReturnType<typeof useViewerOrganizationFeatureFlagsLazyQuery>;
export type ViewerOrganizationFeatureFlagsQueryResult = ApolloReactCommon.QueryResult<ViewerOrganizationFeatureFlagsQuery, ViewerOrganizationFeatureFlagsQueryVariables>;
export const OrganizationFeatureFlagsDocument = gql`
    query organizationFeatureFlags($orgName: String, $rampIDType: RampIDType!) {
  organization(name: $orgName) {
    id
    orgType
    featureFlags(rampIDType: $rampIDType) {
      rampKey
      isEnabled
    }
  }
}
    `;
export type OrganizationFeatureFlagsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationFeatureFlagsQuery, OrganizationFeatureFlagsQueryVariables>, 'query'> & ({ variables: OrganizationFeatureFlagsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationFeatureFlagsComponent = (props: OrganizationFeatureFlagsComponentProps) => (
      <ApolloReactComponents.Query<OrganizationFeatureFlagsQuery, OrganizationFeatureFlagsQueryVariables> query={OrganizationFeatureFlagsDocument} {...props} />
    );
    
export type OrganizationFeatureFlagsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationFeatureFlagsQuery, OrganizationFeatureFlagsQueryVariables>
    } & TChildProps;
export function withOrganizationFeatureFlags<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationFeatureFlagsQuery,
  OrganizationFeatureFlagsQueryVariables,
  OrganizationFeatureFlagsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationFeatureFlagsQuery, OrganizationFeatureFlagsQueryVariables, OrganizationFeatureFlagsProps<TChildProps, TDataName>>(OrganizationFeatureFlagsDocument, {
      alias: 'organizationFeatureFlags',
      ...operationOptions
    });
};

/**
 * __useOrganizationFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useOrganizationFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationFeatureFlagsQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *      rampIDType: // value for 'rampIDType'
 *   },
 * });
 */
export function useOrganizationFeatureFlagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationFeatureFlagsQuery, OrganizationFeatureFlagsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationFeatureFlagsQuery, OrganizationFeatureFlagsQueryVariables>(OrganizationFeatureFlagsDocument, baseOptions);
      }
export function useOrganizationFeatureFlagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationFeatureFlagsQuery, OrganizationFeatureFlagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationFeatureFlagsQuery, OrganizationFeatureFlagsQueryVariables>(OrganizationFeatureFlagsDocument, baseOptions);
        }
export type OrganizationFeatureFlagsQueryHookResult = ReturnType<typeof useOrganizationFeatureFlagsQuery>;
export type OrganizationFeatureFlagsLazyQueryHookResult = ReturnType<typeof useOrganizationFeatureFlagsLazyQuery>;
export type OrganizationFeatureFlagsQueryResult = ApolloReactCommon.QueryResult<OrganizationFeatureFlagsQuery, OrganizationFeatureFlagsQueryVariables>;
export const UpdateOrganizationPrivacySettingsDocument = gql`
    mutation updateOrganizationPrivacySettings($organizationId: ID!, $hidden: Boolean, $privateOnly: Boolean, $onlyAdminsCanInvite: Boolean, $disableMagicLinkSharing: Boolean, $restrictMatchingOrgEmailDomainUsers: Boolean, $codeSavingDisabled: Boolean, $clientMutationId: String) {
  updateOrganizationPrivacySettings(
    input: {organizationId: $organizationId, hidden: $hidden, privateOnly: $privateOnly, onlyAdminsCanInvite: $onlyAdminsCanInvite, disableMagicLinkSharing: $disableMagicLinkSharing, restrictMatchingOrgEmailDomainUsers: $restrictMatchingOrgEmailDomainUsers, codeSavingDisabled: $codeSavingDisabled, clientMutationId: $clientMutationId}
  ) {
    success
    clientMutationId
  }
}
    `;
export type UpdateOrganizationPrivacySettingsMutationFn = ApolloReactCommon.MutationFunction<UpdateOrganizationPrivacySettingsMutation, UpdateOrganizationPrivacySettingsMutationVariables>;
export type UpdateOrganizationPrivacySettingsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateOrganizationPrivacySettingsMutation, UpdateOrganizationPrivacySettingsMutationVariables>, 'mutation'>;

    export const UpdateOrganizationPrivacySettingsComponent = (props: UpdateOrganizationPrivacySettingsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateOrganizationPrivacySettingsMutation, UpdateOrganizationPrivacySettingsMutationVariables> mutation={UpdateOrganizationPrivacySettingsDocument} {...props} />
    );
    
export type UpdateOrganizationPrivacySettingsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateOrganizationPrivacySettingsMutation, UpdateOrganizationPrivacySettingsMutationVariables>
    } & TChildProps;
export function withUpdateOrganizationPrivacySettings<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateOrganizationPrivacySettingsMutation,
  UpdateOrganizationPrivacySettingsMutationVariables,
  UpdateOrganizationPrivacySettingsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateOrganizationPrivacySettingsMutation, UpdateOrganizationPrivacySettingsMutationVariables, UpdateOrganizationPrivacySettingsProps<TChildProps, TDataName>>(UpdateOrganizationPrivacySettingsDocument, {
      alias: 'updateOrganizationPrivacySettings',
      ...operationOptions
    });
};

/**
 * __useUpdateOrganizationPrivacySettingsMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationPrivacySettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationPrivacySettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationPrivacySettingsMutation, { data, loading, error }] = useUpdateOrganizationPrivacySettingsMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      hidden: // value for 'hidden'
 *      privateOnly: // value for 'privateOnly'
 *      onlyAdminsCanInvite: // value for 'onlyAdminsCanInvite'
 *      disableMagicLinkSharing: // value for 'disableMagicLinkSharing'
 *      restrictMatchingOrgEmailDomainUsers: // value for 'restrictMatchingOrgEmailDomainUsers'
 *      codeSavingDisabled: // value for 'codeSavingDisabled'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useUpdateOrganizationPrivacySettingsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateOrganizationPrivacySettingsMutation, UpdateOrganizationPrivacySettingsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateOrganizationPrivacySettingsMutation, UpdateOrganizationPrivacySettingsMutationVariables>(UpdateOrganizationPrivacySettingsDocument, baseOptions);
      }
export type UpdateOrganizationPrivacySettingsMutationHookResult = ReturnType<typeof useUpdateOrganizationPrivacySettingsMutation>;
export type UpdateOrganizationPrivacySettingsMutationResult = ApolloReactCommon.MutationResult<UpdateOrganizationPrivacySettingsMutation>;
export type UpdateOrganizationPrivacySettingsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateOrganizationPrivacySettingsMutation, UpdateOrganizationPrivacySettingsMutationVariables>;
export const OrganizationMemberModelsSeatDocument = gql`
    query OrganizationMemberModelsSeat($orgName: String, $username: String) {
  organization(name: $orgName) {
    id
    member(username: $username) {
      id
      role
      modelsSeat
    }
  }
}
    `;
export type OrganizationMemberModelsSeatComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationMemberModelsSeatQuery, OrganizationMemberModelsSeatQueryVariables>, 'query'>;

    export const OrganizationMemberModelsSeatComponent = (props: OrganizationMemberModelsSeatComponentProps) => (
      <ApolloReactComponents.Query<OrganizationMemberModelsSeatQuery, OrganizationMemberModelsSeatQueryVariables> query={OrganizationMemberModelsSeatDocument} {...props} />
    );
    
export type OrganizationMemberModelsSeatProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationMemberModelsSeatQuery, OrganizationMemberModelsSeatQueryVariables>
    } & TChildProps;
export function withOrganizationMemberModelsSeat<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationMemberModelsSeatQuery,
  OrganizationMemberModelsSeatQueryVariables,
  OrganizationMemberModelsSeatProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationMemberModelsSeatQuery, OrganizationMemberModelsSeatQueryVariables, OrganizationMemberModelsSeatProps<TChildProps, TDataName>>(OrganizationMemberModelsSeatDocument, {
      alias: 'organizationMemberModelsSeat',
      ...operationOptions
    });
};

/**
 * __useOrganizationMemberModelsSeatQuery__
 *
 * To run a query within a React component, call `useOrganizationMemberModelsSeatQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationMemberModelsSeatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationMemberModelsSeatQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *      username: // value for 'username'
 *   },
 * });
 */
export function useOrganizationMemberModelsSeatQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationMemberModelsSeatQuery, OrganizationMemberModelsSeatQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationMemberModelsSeatQuery, OrganizationMemberModelsSeatQueryVariables>(OrganizationMemberModelsSeatDocument, baseOptions);
      }
export function useOrganizationMemberModelsSeatLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationMemberModelsSeatQuery, OrganizationMemberModelsSeatQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationMemberModelsSeatQuery, OrganizationMemberModelsSeatQueryVariables>(OrganizationMemberModelsSeatDocument, baseOptions);
        }
export type OrganizationMemberModelsSeatQueryHookResult = ReturnType<typeof useOrganizationMemberModelsSeatQuery>;
export type OrganizationMemberModelsSeatLazyQueryHookResult = ReturnType<typeof useOrganizationMemberModelsSeatLazyQuery>;
export type OrganizationMemberModelsSeatQueryResult = ApolloReactCommon.QueryResult<OrganizationMemberModelsSeatQuery, OrganizationMemberModelsSeatQueryVariables>;
export const FetchPreviewableLinkDocument = gql`
    query FetchPreviewableLink($resourceID: ID!, $resourceType: PreviewableLinkResourceType!) {
  previewableLink(resourceID: $resourceID, resourceType: $resourceType) {
    id
    URL
    createdAt
    updatedAt
    resourceID
    resourceType
    __typename
  }
}
    `;
export type FetchPreviewableLinkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FetchPreviewableLinkQuery, FetchPreviewableLinkQueryVariables>, 'query'> & ({ variables: FetchPreviewableLinkQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FetchPreviewableLinkComponent = (props: FetchPreviewableLinkComponentProps) => (
      <ApolloReactComponents.Query<FetchPreviewableLinkQuery, FetchPreviewableLinkQueryVariables> query={FetchPreviewableLinkDocument} {...props} />
    );
    
export type FetchPreviewableLinkProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FetchPreviewableLinkQuery, FetchPreviewableLinkQueryVariables>
    } & TChildProps;
export function withFetchPreviewableLink<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FetchPreviewableLinkQuery,
  FetchPreviewableLinkQueryVariables,
  FetchPreviewableLinkProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FetchPreviewableLinkQuery, FetchPreviewableLinkQueryVariables, FetchPreviewableLinkProps<TChildProps, TDataName>>(FetchPreviewableLinkDocument, {
      alias: 'fetchPreviewableLink',
      ...operationOptions
    });
};

/**
 * __useFetchPreviewableLinkQuery__
 *
 * To run a query within a React component, call `useFetchPreviewableLinkQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchPreviewableLinkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchPreviewableLinkQuery({
 *   variables: {
 *      resourceID: // value for 'resourceID'
 *      resourceType: // value for 'resourceType'
 *   },
 * });
 */
export function useFetchPreviewableLinkQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchPreviewableLinkQuery, FetchPreviewableLinkQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchPreviewableLinkQuery, FetchPreviewableLinkQueryVariables>(FetchPreviewableLinkDocument, baseOptions);
      }
export function useFetchPreviewableLinkLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchPreviewableLinkQuery, FetchPreviewableLinkQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchPreviewableLinkQuery, FetchPreviewableLinkQueryVariables>(FetchPreviewableLinkDocument, baseOptions);
        }
export type FetchPreviewableLinkQueryHookResult = ReturnType<typeof useFetchPreviewableLinkQuery>;
export type FetchPreviewableLinkLazyQueryHookResult = ReturnType<typeof useFetchPreviewableLinkLazyQuery>;
export type FetchPreviewableLinkQueryResult = ApolloReactCommon.QueryResult<FetchPreviewableLinkQuery, FetchPreviewableLinkQueryVariables>;
export const ProfilePageDocument = gql`
    query ProfilePage($username: String!) {
  user(userName: $username) {
    id
    name
    username
    email
    photoUrl
    userInfo
    hideTeamsFromPublic
    accountType
    defaultEntity {
      id
      name
    }
    views(viewType: "runs", first: 10000) {
      edges {
        node {
          id
          ...ReportsTableFragment
        }
      }
    }
    starredViews(first: 500) {
      edges {
        node {
          id
          ...ReportsTableFragment
        }
      }
    }
    teams(first: 100) {
      edges {
        node {
          id
          name
          photoUrl
          isTeam
        }
      }
    }
  }
}
    ${ReportsTableFragmentFragmentDoc}`;
export type ProfilePageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProfilePageQuery, ProfilePageQueryVariables>, 'query'> & ({ variables: ProfilePageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProfilePageComponent = (props: ProfilePageComponentProps) => (
      <ApolloReactComponents.Query<ProfilePageQuery, ProfilePageQueryVariables> query={ProfilePageDocument} {...props} />
    );
    
export type ProfilePageProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProfilePageQuery, ProfilePageQueryVariables>
    } & TChildProps;
export function withProfilePage<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProfilePageQuery,
  ProfilePageQueryVariables,
  ProfilePageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProfilePageQuery, ProfilePageQueryVariables, ProfilePageProps<TChildProps, TDataName>>(ProfilePageDocument, {
      alias: 'profilePage',
      ...operationOptions
    });
};

/**
 * __useProfilePageQuery__
 *
 * To run a query within a React component, call `useProfilePageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilePageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilePageQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useProfilePageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProfilePageQuery, ProfilePageQueryVariables>) {
        return ApolloReactHooks.useQuery<ProfilePageQuery, ProfilePageQueryVariables>(ProfilePageDocument, baseOptions);
      }
export function useProfilePageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProfilePageQuery, ProfilePageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProfilePageQuery, ProfilePageQueryVariables>(ProfilePageDocument, baseOptions);
        }
export type ProfilePageQueryHookResult = ReturnType<typeof useProfilePageQuery>;
export type ProfilePageLazyQueryHookResult = ReturnType<typeof useProfilePageLazyQuery>;
export type ProfilePageQueryResult = ApolloReactCommon.QueryResult<ProfilePageQuery, ProfilePageQueryVariables>;
export const ProfilePageProjectsDocument = gql`
    query ProfilePageProjects($username: String!) {
  user(userName: $username) {
    id
    projects(first: 100) {
      edges {
        node {
          id
          name
          entityName
          access
          description
          totalRuns
          lastActive
          runCount
          user {
            id
            username
          }
        }
      }
    }
  }
}
    `;
export type ProfilePageProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProfilePageProjectsQuery, ProfilePageProjectsQueryVariables>, 'query'> & ({ variables: ProfilePageProjectsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProfilePageProjectsComponent = (props: ProfilePageProjectsComponentProps) => (
      <ApolloReactComponents.Query<ProfilePageProjectsQuery, ProfilePageProjectsQueryVariables> query={ProfilePageProjectsDocument} {...props} />
    );
    
export type ProfilePageProjectsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProfilePageProjectsQuery, ProfilePageProjectsQueryVariables>
    } & TChildProps;
export function withProfilePageProjects<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProfilePageProjectsQuery,
  ProfilePageProjectsQueryVariables,
  ProfilePageProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProfilePageProjectsQuery, ProfilePageProjectsQueryVariables, ProfilePageProjectsProps<TChildProps, TDataName>>(ProfilePageProjectsDocument, {
      alias: 'profilePageProjects',
      ...operationOptions
    });
};

/**
 * __useProfilePageProjectsQuery__
 *
 * To run a query within a React component, call `useProfilePageProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilePageProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilePageProjectsQuery({
 *   variables: {
 *      username: // value for 'username'
 *   },
 * });
 */
export function useProfilePageProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProfilePageProjectsQuery, ProfilePageProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProfilePageProjectsQuery, ProfilePageProjectsQueryVariables>(ProfilePageProjectsDocument, baseOptions);
      }
export function useProfilePageProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProfilePageProjectsQuery, ProfilePageProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProfilePageProjectsQuery, ProfilePageProjectsQueryVariables>(ProfilePageProjectsDocument, baseOptions);
        }
export type ProfilePageProjectsQueryHookResult = ReturnType<typeof useProfilePageProjectsQuery>;
export type ProfilePageProjectsLazyQueryHookResult = ReturnType<typeof useProfilePageProjectsLazyQuery>;
export type ProfilePageProjectsQueryResult = ApolloReactCommon.QueryResult<ProfilePageProjectsQuery, ProfilePageProjectsQueryVariables>;
export const UserRunsActivityDocument = gql`
    query UserRunsActivity($username: String!, $dailyRunCountLimit: Int!) {
  user(userName: $username) {
    id
    dailyRunCount(limit: $dailyRunCountLimit)
  }
}
    `;
export type UserRunsActivityComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserRunsActivityQuery, UserRunsActivityQueryVariables>, 'query'> & ({ variables: UserRunsActivityQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserRunsActivityComponent = (props: UserRunsActivityComponentProps) => (
      <ApolloReactComponents.Query<UserRunsActivityQuery, UserRunsActivityQueryVariables> query={UserRunsActivityDocument} {...props} />
    );
    
export type UserRunsActivityProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UserRunsActivityQuery, UserRunsActivityQueryVariables>
    } & TChildProps;
export function withUserRunsActivity<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserRunsActivityQuery,
  UserRunsActivityQueryVariables,
  UserRunsActivityProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, UserRunsActivityQuery, UserRunsActivityQueryVariables, UserRunsActivityProps<TChildProps, TDataName>>(UserRunsActivityDocument, {
      alias: 'userRunsActivity',
      ...operationOptions
    });
};

/**
 * __useUserRunsActivityQuery__
 *
 * To run a query within a React component, call `useUserRunsActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRunsActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRunsActivityQuery({
 *   variables: {
 *      username: // value for 'username'
 *      dailyRunCountLimit: // value for 'dailyRunCountLimit'
 *   },
 * });
 */
export function useUserRunsActivityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserRunsActivityQuery, UserRunsActivityQueryVariables>) {
        return ApolloReactHooks.useQuery<UserRunsActivityQuery, UserRunsActivityQueryVariables>(UserRunsActivityDocument, baseOptions);
      }
export function useUserRunsActivityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserRunsActivityQuery, UserRunsActivityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserRunsActivityQuery, UserRunsActivityQueryVariables>(UserRunsActivityDocument, baseOptions);
        }
export type UserRunsActivityQueryHookResult = ReturnType<typeof useUserRunsActivityQuery>;
export type UserRunsActivityLazyQueryHookResult = ReturnType<typeof useUserRunsActivityLazyQuery>;
export type UserRunsActivityQueryResult = ApolloReactCommon.QueryResult<UserRunsActivityQuery, UserRunsActivityQueryVariables>;
export const UserRunsDocument = gql`
    query UserRuns($username: String!, $pattern: String, $first: Int = 100) {
  user(userName: $username) {
    id
    runs(first: $first, pattern: $pattern) {
      edges {
        node {
          id
          ...RunsTableFragment
        }
      }
    }
  }
}
    ${RunsTableFragmentFragmentDoc}`;
export type UserRunsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserRunsQuery, UserRunsQueryVariables>, 'query'> & ({ variables: UserRunsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserRunsComponent = (props: UserRunsComponentProps) => (
      <ApolloReactComponents.Query<UserRunsQuery, UserRunsQueryVariables> query={UserRunsDocument} {...props} />
    );
    
export type UserRunsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UserRunsQuery, UserRunsQueryVariables>
    } & TChildProps;
export function withUserRuns<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserRunsQuery,
  UserRunsQueryVariables,
  UserRunsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, UserRunsQuery, UserRunsQueryVariables, UserRunsProps<TChildProps, TDataName>>(UserRunsDocument, {
      alias: 'userRuns',
      ...operationOptions
    });
};

/**
 * __useUserRunsQuery__
 *
 * To run a query within a React component, call `useUserRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserRunsQuery({
 *   variables: {
 *      username: // value for 'username'
 *      pattern: // value for 'pattern'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useUserRunsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserRunsQuery, UserRunsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserRunsQuery, UserRunsQueryVariables>(UserRunsDocument, baseOptions);
      }
export function useUserRunsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserRunsQuery, UserRunsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserRunsQuery, UserRunsQueryVariables>(UserRunsDocument, baseOptions);
        }
export type UserRunsQueryHookResult = ReturnType<typeof useUserRunsQuery>;
export type UserRunsLazyQueryHookResult = ReturnType<typeof useUserRunsLazyQuery>;
export type UserRunsQueryResult = ApolloReactCommon.QueryResult<UserRunsQuery, UserRunsQueryVariables>;
export const ProjectNameFromInternalIdDocument = gql`
    query ProjectNameFromInternalId($internalId: ID!) {
  project(internalId: $internalId) {
    id
    name
  }
}
    `;
export type ProjectNameFromInternalIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectNameFromInternalIdQuery, ProjectNameFromInternalIdQueryVariables>, 'query'> & ({ variables: ProjectNameFromInternalIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectNameFromInternalIdComponent = (props: ProjectNameFromInternalIdComponentProps) => (
      <ApolloReactComponents.Query<ProjectNameFromInternalIdQuery, ProjectNameFromInternalIdQueryVariables> query={ProjectNameFromInternalIdDocument} {...props} />
    );
    
export type ProjectNameFromInternalIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProjectNameFromInternalIdQuery, ProjectNameFromInternalIdQueryVariables>
    } & TChildProps;
export function withProjectNameFromInternalId<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProjectNameFromInternalIdQuery,
  ProjectNameFromInternalIdQueryVariables,
  ProjectNameFromInternalIdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProjectNameFromInternalIdQuery, ProjectNameFromInternalIdQueryVariables, ProjectNameFromInternalIdProps<TChildProps, TDataName>>(ProjectNameFromInternalIdDocument, {
      alias: 'projectNameFromInternalId',
      ...operationOptions
    });
};

/**
 * __useProjectNameFromInternalIdQuery__
 *
 * To run a query within a React component, call `useProjectNameFromInternalIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectNameFromInternalIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectNameFromInternalIdQuery({
 *   variables: {
 *      internalId: // value for 'internalId'
 *   },
 * });
 */
export function useProjectNameFromInternalIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectNameFromInternalIdQuery, ProjectNameFromInternalIdQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectNameFromInternalIdQuery, ProjectNameFromInternalIdQueryVariables>(ProjectNameFromInternalIdDocument, baseOptions);
      }
export function useProjectNameFromInternalIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectNameFromInternalIdQuery, ProjectNameFromInternalIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectNameFromInternalIdQuery, ProjectNameFromInternalIdQueryVariables>(ProjectNameFromInternalIdDocument, baseOptions);
        }
export type ProjectNameFromInternalIdQueryHookResult = ReturnType<typeof useProjectNameFromInternalIdQuery>;
export type ProjectNameFromInternalIdLazyQueryHookResult = ReturnType<typeof useProjectNameFromInternalIdLazyQuery>;
export type ProjectNameFromInternalIdQueryResult = ApolloReactCommon.QueryResult<ProjectNameFromInternalIdQuery, ProjectNameFromInternalIdQueryVariables>;
export const ProjectInfosFromInternalIdsDocument = gql`
    query ProjectInfosFromInternalIds($internalIds: [ID!]!) {
  projects(internalIDs: $internalIds) {
    edges {
      node {
        id
        internalId
        name
        entityName
      }
    }
  }
}
    `;
export type ProjectInfosFromInternalIdsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectInfosFromInternalIdsQuery, ProjectInfosFromInternalIdsQueryVariables>, 'query'> & ({ variables: ProjectInfosFromInternalIdsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectInfosFromInternalIdsComponent = (props: ProjectInfosFromInternalIdsComponentProps) => (
      <ApolloReactComponents.Query<ProjectInfosFromInternalIdsQuery, ProjectInfosFromInternalIdsQueryVariables> query={ProjectInfosFromInternalIdsDocument} {...props} />
    );
    
export type ProjectInfosFromInternalIdsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProjectInfosFromInternalIdsQuery, ProjectInfosFromInternalIdsQueryVariables>
    } & TChildProps;
export function withProjectInfosFromInternalIds<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProjectInfosFromInternalIdsQuery,
  ProjectInfosFromInternalIdsQueryVariables,
  ProjectInfosFromInternalIdsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProjectInfosFromInternalIdsQuery, ProjectInfosFromInternalIdsQueryVariables, ProjectInfosFromInternalIdsProps<TChildProps, TDataName>>(ProjectInfosFromInternalIdsDocument, {
      alias: 'projectInfosFromInternalIds',
      ...operationOptions
    });
};

/**
 * __useProjectInfosFromInternalIdsQuery__
 *
 * To run a query within a React component, call `useProjectInfosFromInternalIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectInfosFromInternalIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectInfosFromInternalIdsQuery({
 *   variables: {
 *      internalIds: // value for 'internalIds'
 *   },
 * });
 */
export function useProjectInfosFromInternalIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectInfosFromInternalIdsQuery, ProjectInfosFromInternalIdsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectInfosFromInternalIdsQuery, ProjectInfosFromInternalIdsQueryVariables>(ProjectInfosFromInternalIdsDocument, baseOptions);
      }
export function useProjectInfosFromInternalIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectInfosFromInternalIdsQuery, ProjectInfosFromInternalIdsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectInfosFromInternalIdsQuery, ProjectInfosFromInternalIdsQueryVariables>(ProjectInfosFromInternalIdsDocument, baseOptions);
        }
export type ProjectInfosFromInternalIdsQueryHookResult = ReturnType<typeof useProjectInfosFromInternalIdsQuery>;
export type ProjectInfosFromInternalIdsLazyQueryHookResult = ReturnType<typeof useProjectInfosFromInternalIdsLazyQuery>;
export type ProjectInfosFromInternalIdsQueryResult = ApolloReactCommon.QueryResult<ProjectInfosFromInternalIdsQuery, ProjectInfosFromInternalIdsQueryVariables>;
export const AllProjectNamesDocument = gql`
    query AllProjectNames($entityName: String!, $first: Int = 500, $order: String = "name") {
  projects(first: $first, entityName: $entityName, order: $order) {
    edges {
      node {
        id
        name
      }
    }
  }
}
    `;
export type AllProjectNamesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllProjectNamesQuery, AllProjectNamesQueryVariables>, 'query'> & ({ variables: AllProjectNamesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AllProjectNamesComponent = (props: AllProjectNamesComponentProps) => (
      <ApolloReactComponents.Query<AllProjectNamesQuery, AllProjectNamesQueryVariables> query={AllProjectNamesDocument} {...props} />
    );
    
export type AllProjectNamesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AllProjectNamesQuery, AllProjectNamesQueryVariables>
    } & TChildProps;
export function withAllProjectNames<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AllProjectNamesQuery,
  AllProjectNamesQueryVariables,
  AllProjectNamesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AllProjectNamesQuery, AllProjectNamesQueryVariables, AllProjectNamesProps<TChildProps, TDataName>>(AllProjectNamesDocument, {
      alias: 'allProjectNames',
      ...operationOptions
    });
};

/**
 * __useAllProjectNamesQuery__
 *
 * To run a query within a React component, call `useAllProjectNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllProjectNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllProjectNamesQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      first: // value for 'first'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useAllProjectNamesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllProjectNamesQuery, AllProjectNamesQueryVariables>) {
        return ApolloReactHooks.useQuery<AllProjectNamesQuery, AllProjectNamesQueryVariables>(AllProjectNamesDocument, baseOptions);
      }
export function useAllProjectNamesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllProjectNamesQuery, AllProjectNamesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllProjectNamesQuery, AllProjectNamesQueryVariables>(AllProjectNamesDocument, baseOptions);
        }
export type AllProjectNamesQueryHookResult = ReturnType<typeof useAllProjectNamesQuery>;
export type AllProjectNamesLazyQueryHookResult = ReturnType<typeof useAllProjectNamesLazyQuery>;
export type AllProjectNamesQueryResult = ApolloReactCommon.QueryResult<AllProjectNamesQuery, AllProjectNamesQueryVariables>;
export const MoveProjectsDocument = gql`
    mutation moveProjects($projectNames: [String!], $sourceEntityName: String!, $destinationEntityName: String!, $ownerUsername: String!, $notes: String, $clientMutationId: String) {
  moveProjects(
    input: {projectNames: $projectNames, sourceEntityName: $sourceEntityName, destinationEntityName: $destinationEntityName, ownerUsername: $ownerUsername, notes: $notes, clientMutationId: $clientMutationId}
  ) {
    tasks {
      id
      type
      state
      progress
      name
    }
  }
}
    `;
export type MoveProjectsMutationFn = ApolloReactCommon.MutationFunction<MoveProjectsMutation, MoveProjectsMutationVariables>;
export type MoveProjectsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MoveProjectsMutation, MoveProjectsMutationVariables>, 'mutation'>;

    export const MoveProjectsComponent = (props: MoveProjectsComponentProps) => (
      <ApolloReactComponents.Mutation<MoveProjectsMutation, MoveProjectsMutationVariables> mutation={MoveProjectsDocument} {...props} />
    );
    
export type MoveProjectsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<MoveProjectsMutation, MoveProjectsMutationVariables>
    } & TChildProps;
export function withMoveProjects<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MoveProjectsMutation,
  MoveProjectsMutationVariables,
  MoveProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, MoveProjectsMutation, MoveProjectsMutationVariables, MoveProjectsProps<TChildProps, TDataName>>(MoveProjectsDocument, {
      alias: 'moveProjects',
      ...operationOptions
    });
};

/**
 * __useMoveProjectsMutation__
 *
 * To run a mutation, you first call `useMoveProjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveProjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveProjectsMutation, { data, loading, error }] = useMoveProjectsMutation({
 *   variables: {
 *      projectNames: // value for 'projectNames'
 *      sourceEntityName: // value for 'sourceEntityName'
 *      destinationEntityName: // value for 'destinationEntityName'
 *      ownerUsername: // value for 'ownerUsername'
 *      notes: // value for 'notes'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useMoveProjectsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MoveProjectsMutation, MoveProjectsMutationVariables>) {
        return ApolloReactHooks.useMutation<MoveProjectsMutation, MoveProjectsMutationVariables>(MoveProjectsDocument, baseOptions);
      }
export type MoveProjectsMutationHookResult = ReturnType<typeof useMoveProjectsMutation>;
export type MoveProjectsMutationResult = ApolloReactCommon.MutationResult<MoveProjectsMutation>;
export type MoveProjectsMutationOptions = ApolloReactCommon.BaseMutationOptions<MoveProjectsMutation, MoveProjectsMutationVariables>;
export const CloneProjectsDocument = gql`
    mutation cloneProjects($projectNames: [String!], $sourceEntityName: String!, $destinationEntityName: String!, $ownerUsername: String!, $notes: String, $clientMutationId: String) {
  cloneProjects(
    input: {projectNames: $projectNames, sourceEntityName: $sourceEntityName, destinationEntityName: $destinationEntityName, ownerUsername: $ownerUsername, notes: $notes, clientMutationId: $clientMutationId}
  ) {
    tasks {
      id
      type
      state
      progress
      name
    }
  }
}
    `;
export type CloneProjectsMutationFn = ApolloReactCommon.MutationFunction<CloneProjectsMutation, CloneProjectsMutationVariables>;
export type CloneProjectsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CloneProjectsMutation, CloneProjectsMutationVariables>, 'mutation'>;

    export const CloneProjectsComponent = (props: CloneProjectsComponentProps) => (
      <ApolloReactComponents.Mutation<CloneProjectsMutation, CloneProjectsMutationVariables> mutation={CloneProjectsDocument} {...props} />
    );
    
export type CloneProjectsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CloneProjectsMutation, CloneProjectsMutationVariables>
    } & TChildProps;
export function withCloneProjects<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CloneProjectsMutation,
  CloneProjectsMutationVariables,
  CloneProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CloneProjectsMutation, CloneProjectsMutationVariables, CloneProjectsProps<TChildProps, TDataName>>(CloneProjectsDocument, {
      alias: 'cloneProjects',
      ...operationOptions
    });
};

/**
 * __useCloneProjectsMutation__
 *
 * To run a mutation, you first call `useCloneProjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCloneProjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cloneProjectsMutation, { data, loading, error }] = useCloneProjectsMutation({
 *   variables: {
 *      projectNames: // value for 'projectNames'
 *      sourceEntityName: // value for 'sourceEntityName'
 *      destinationEntityName: // value for 'destinationEntityName'
 *      ownerUsername: // value for 'ownerUsername'
 *      notes: // value for 'notes'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useCloneProjectsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CloneProjectsMutation, CloneProjectsMutationVariables>) {
        return ApolloReactHooks.useMutation<CloneProjectsMutation, CloneProjectsMutationVariables>(CloneProjectsDocument, baseOptions);
      }
export type CloneProjectsMutationHookResult = ReturnType<typeof useCloneProjectsMutation>;
export type CloneProjectsMutationResult = ApolloReactCommon.MutationResult<CloneProjectsMutation>;
export type CloneProjectsMutationOptions = ApolloReactCommon.BaseMutationOptions<CloneProjectsMutation, CloneProjectsMutationVariables>;
export const ProjectMembersDocument = gql`
    query ProjectMembers($projectName: String!, $entityName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    members {
      ...ProjectMemberFields
    }
  }
}
    ${ProjectMemberFieldsFragmentDoc}`;
export type ProjectMembersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectMembersQuery, ProjectMembersQueryVariables>, 'query'> & ({ variables: ProjectMembersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectMembersComponent = (props: ProjectMembersComponentProps) => (
      <ApolloReactComponents.Query<ProjectMembersQuery, ProjectMembersQueryVariables> query={ProjectMembersDocument} {...props} />
    );
    
export type ProjectMembersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProjectMembersQuery, ProjectMembersQueryVariables>
    } & TChildProps;
export function withProjectMembers<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProjectMembersQuery,
  ProjectMembersQueryVariables,
  ProjectMembersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProjectMembersQuery, ProjectMembersQueryVariables, ProjectMembersProps<TChildProps, TDataName>>(ProjectMembersDocument, {
      alias: 'projectMembers',
      ...operationOptions
    });
};

/**
 * __useProjectMembersQuery__
 *
 * To run a query within a React component, call `useProjectMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectMembersQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useProjectMembersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectMembersQuery, ProjectMembersQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectMembersQuery, ProjectMembersQueryVariables>(ProjectMembersDocument, baseOptions);
      }
export function useProjectMembersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectMembersQuery, ProjectMembersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectMembersQuery, ProjectMembersQueryVariables>(ProjectMembersDocument, baseOptions);
        }
export type ProjectMembersQueryHookResult = ReturnType<typeof useProjectMembersQuery>;
export type ProjectMembersLazyQueryHookResult = ReturnType<typeof useProjectMembersLazyQuery>;
export type ProjectMembersQueryResult = ApolloReactCommon.QueryResult<ProjectMembersQuery, ProjectMembersQueryVariables>;
export const ProjectMemberDocument = gql`
    query ProjectMember($projectName: String!, $entityName: String!, $memberId: ID!) {
  project(name: $projectName, entityName: $entityName) {
    id
    member(id: $memberId) {
      ...ProjectMemberFields
    }
  }
}
    ${ProjectMemberFieldsFragmentDoc}`;
export type ProjectMemberComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectMemberQuery, ProjectMemberQueryVariables>, 'query'> & ({ variables: ProjectMemberQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectMemberComponent = (props: ProjectMemberComponentProps) => (
      <ApolloReactComponents.Query<ProjectMemberQuery, ProjectMemberQueryVariables> query={ProjectMemberDocument} {...props} />
    );
    
export type ProjectMemberProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProjectMemberQuery, ProjectMemberQueryVariables>
    } & TChildProps;
export function withProjectMember<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProjectMemberQuery,
  ProjectMemberQueryVariables,
  ProjectMemberProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProjectMemberQuery, ProjectMemberQueryVariables, ProjectMemberProps<TChildProps, TDataName>>(ProjectMemberDocument, {
      alias: 'projectMember',
      ...operationOptions
    });
};

/**
 * __useProjectMemberQuery__
 *
 * To run a query within a React component, call `useProjectMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectMemberQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useProjectMemberQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectMemberQuery, ProjectMemberQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectMemberQuery, ProjectMemberQueryVariables>(ProjectMemberDocument, baseOptions);
      }
export function useProjectMemberLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectMemberQuery, ProjectMemberQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectMemberQuery, ProjectMemberQueryVariables>(ProjectMemberDocument, baseOptions);
        }
export type ProjectMemberQueryHookResult = ReturnType<typeof useProjectMemberQuery>;
export type ProjectMemberLazyQueryHookResult = ReturnType<typeof useProjectMemberLazyQuery>;
export type ProjectMemberQueryResult = ApolloReactCommon.QueryResult<ProjectMemberQuery, ProjectMemberQueryVariables>;
export const CreateProjectMembersDocument = gql`
    mutation createProjectMembers($userIds: [ID!]!, $projectId: ID!) {
  createProjectMembers(input: {userIds: $userIds, projectId: $projectId}) {
    success
  }
}
    `;
export type CreateProjectMembersMutationFn = ApolloReactCommon.MutationFunction<CreateProjectMembersMutation, CreateProjectMembersMutationVariables>;
export type CreateProjectMembersComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateProjectMembersMutation, CreateProjectMembersMutationVariables>, 'mutation'>;

    export const CreateProjectMembersComponent = (props: CreateProjectMembersComponentProps) => (
      <ApolloReactComponents.Mutation<CreateProjectMembersMutation, CreateProjectMembersMutationVariables> mutation={CreateProjectMembersDocument} {...props} />
    );
    
export type CreateProjectMembersProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateProjectMembersMutation, CreateProjectMembersMutationVariables>
    } & TChildProps;
export function withCreateProjectMembers<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateProjectMembersMutation,
  CreateProjectMembersMutationVariables,
  CreateProjectMembersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateProjectMembersMutation, CreateProjectMembersMutationVariables, CreateProjectMembersProps<TChildProps, TDataName>>(CreateProjectMembersDocument, {
      alias: 'createProjectMembers',
      ...operationOptions
    });
};

/**
 * __useCreateProjectMembersMutation__
 *
 * To run a mutation, you first call `useCreateProjectMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProjectMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProjectMembersMutation, { data, loading, error }] = useCreateProjectMembersMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useCreateProjectMembersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateProjectMembersMutation, CreateProjectMembersMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateProjectMembersMutation, CreateProjectMembersMutationVariables>(CreateProjectMembersDocument, baseOptions);
      }
export type CreateProjectMembersMutationHookResult = ReturnType<typeof useCreateProjectMembersMutation>;
export type CreateProjectMembersMutationResult = ApolloReactCommon.MutationResult<CreateProjectMembersMutation>;
export type CreateProjectMembersMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateProjectMembersMutation, CreateProjectMembersMutationVariables>;
export const DeleteProjectMembersDocument = gql`
    mutation deleteProjectMembers($userIds: [ID!]!, $projectId: ID!) {
  deleteProjectMembers(input: {userIds: $userIds, projectId: $projectId}) {
    success
  }
}
    `;
export type DeleteProjectMembersMutationFn = ApolloReactCommon.MutationFunction<DeleteProjectMembersMutation, DeleteProjectMembersMutationVariables>;
export type DeleteProjectMembersComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteProjectMembersMutation, DeleteProjectMembersMutationVariables>, 'mutation'>;

    export const DeleteProjectMembersComponent = (props: DeleteProjectMembersComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteProjectMembersMutation, DeleteProjectMembersMutationVariables> mutation={DeleteProjectMembersDocument} {...props} />
    );
    
export type DeleteProjectMembersProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteProjectMembersMutation, DeleteProjectMembersMutationVariables>
    } & TChildProps;
export function withDeleteProjectMembers<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteProjectMembersMutation,
  DeleteProjectMembersMutationVariables,
  DeleteProjectMembersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteProjectMembersMutation, DeleteProjectMembersMutationVariables, DeleteProjectMembersProps<TChildProps, TDataName>>(DeleteProjectMembersDocument, {
      alias: 'deleteProjectMembers',
      ...operationOptions
    });
};

/**
 * __useDeleteProjectMembersMutation__
 *
 * To run a mutation, you first call `useDeleteProjectMembersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProjectMembersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProjectMembersMutation, { data, loading, error }] = useDeleteProjectMembersMutation({
 *   variables: {
 *      userIds: // value for 'userIds'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDeleteProjectMembersMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteProjectMembersMutation, DeleteProjectMembersMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteProjectMembersMutation, DeleteProjectMembersMutationVariables>(DeleteProjectMembersDocument, baseOptions);
      }
export type DeleteProjectMembersMutationHookResult = ReturnType<typeof useDeleteProjectMembersMutation>;
export type DeleteProjectMembersMutationResult = ApolloReactCommon.MutationResult<DeleteProjectMembersMutation>;
export type DeleteProjectMembersMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteProjectMembersMutation, DeleteProjectMembersMutationVariables>;
export const ProjectMembersEntityMembersDocument = gql`
    query ProjectMembersEntityMembers($projectName: String!, $entityName: String) {
  project(name: $projectName, entityName: $entityName) {
    id
    members {
      id
      role {
        ID
        name
      }
    }
    entity {
      id
      members {
        id
        admin
        pending
        email
        username
        name
        photoUrl
        accountType
        memberRole {
          ID
          name
          permissions {
            name
            groupName
            displayName
          }
        }
      }
    }
  }
}
    `;
export type ProjectMembersEntityMembersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectMembersEntityMembersQuery, ProjectMembersEntityMembersQueryVariables>, 'query'> & ({ variables: ProjectMembersEntityMembersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectMembersEntityMembersComponent = (props: ProjectMembersEntityMembersComponentProps) => (
      <ApolloReactComponents.Query<ProjectMembersEntityMembersQuery, ProjectMembersEntityMembersQueryVariables> query={ProjectMembersEntityMembersDocument} {...props} />
    );
    
export type ProjectMembersEntityMembersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProjectMembersEntityMembersQuery, ProjectMembersEntityMembersQueryVariables>
    } & TChildProps;
export function withProjectMembersEntityMembers<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProjectMembersEntityMembersQuery,
  ProjectMembersEntityMembersQueryVariables,
  ProjectMembersEntityMembersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProjectMembersEntityMembersQuery, ProjectMembersEntityMembersQueryVariables, ProjectMembersEntityMembersProps<TChildProps, TDataName>>(ProjectMembersEntityMembersDocument, {
      alias: 'projectMembersEntityMembers',
      ...operationOptions
    });
};

/**
 * __useProjectMembersEntityMembersQuery__
 *
 * To run a query within a React component, call `useProjectMembersEntityMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectMembersEntityMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectMembersEntityMembersQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useProjectMembersEntityMembersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectMembersEntityMembersQuery, ProjectMembersEntityMembersQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectMembersEntityMembersQuery, ProjectMembersEntityMembersQueryVariables>(ProjectMembersEntityMembersDocument, baseOptions);
      }
export function useProjectMembersEntityMembersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectMembersEntityMembersQuery, ProjectMembersEntityMembersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectMembersEntityMembersQuery, ProjectMembersEntityMembersQueryVariables>(ProjectMembersEntityMembersDocument, baseOptions);
        }
export type ProjectMembersEntityMembersQueryHookResult = ReturnType<typeof useProjectMembersEntityMembersQuery>;
export type ProjectMembersEntityMembersLazyQueryHookResult = ReturnType<typeof useProjectMembersEntityMembersLazyQuery>;
export type ProjectMembersEntityMembersQueryResult = ApolloReactCommon.QueryResult<ProjectMembersEntityMembersQuery, ProjectMembersEntityMembersQueryVariables>;
export const RampSettingsDocument = gql`
    query RampSettings {
  rampManager {
    settings {
      edges {
        name
        group
        description
        rampType
        rampIDType
        stores {
          name
          thresholds {
            checkId
            displayCheckID
            threshold
          }
        }
      }
    }
  }
}
    `;
export type RampSettingsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RampSettingsQuery, RampSettingsQueryVariables>, 'query'>;

    export const RampSettingsComponent = (props: RampSettingsComponentProps) => (
      <ApolloReactComponents.Query<RampSettingsQuery, RampSettingsQueryVariables> query={RampSettingsDocument} {...props} />
    );
    
export type RampSettingsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RampSettingsQuery, RampSettingsQueryVariables>
    } & TChildProps;
export function withRampSettings<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RampSettingsQuery,
  RampSettingsQueryVariables,
  RampSettingsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RampSettingsQuery, RampSettingsQueryVariables, RampSettingsProps<TChildProps, TDataName>>(RampSettingsDocument, {
      alias: 'rampSettings',
      ...operationOptions
    });
};

/**
 * __useRampSettingsQuery__
 *
 * To run a query within a React component, call `useRampSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRampSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRampSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRampSettingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RampSettingsQuery, RampSettingsQueryVariables>) {
        return ApolloReactHooks.useQuery<RampSettingsQuery, RampSettingsQueryVariables>(RampSettingsDocument, baseOptions);
      }
export function useRampSettingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RampSettingsQuery, RampSettingsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RampSettingsQuery, RampSettingsQueryVariables>(RampSettingsDocument, baseOptions);
        }
export type RampSettingsQueryHookResult = ReturnType<typeof useRampSettingsQuery>;
export type RampSettingsLazyQueryHookResult = ReturnType<typeof useRampSettingsLazyQuery>;
export type RampSettingsQueryResult = ApolloReactCommon.QueryResult<RampSettingsQuery, RampSettingsQueryVariables>;
export const IdIsRampedForSettingDocument = gql`
    query IDIsRampedForSetting($setting: String!, $id: String!) {
  rampManager {
    setting(name: $setting) {
      idIsRamped(id: $id)
    }
  }
}
    `;
export type IdIsRampedForSettingComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<IdIsRampedForSettingQuery, IdIsRampedForSettingQueryVariables>, 'query'> & ({ variables: IdIsRampedForSettingQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const IdIsRampedForSettingComponent = (props: IdIsRampedForSettingComponentProps) => (
      <ApolloReactComponents.Query<IdIsRampedForSettingQuery, IdIsRampedForSettingQueryVariables> query={IdIsRampedForSettingDocument} {...props} />
    );
    
export type IdIsRampedForSettingProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<IdIsRampedForSettingQuery, IdIsRampedForSettingQueryVariables>
    } & TChildProps;
export function withIdIsRampedForSetting<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  IdIsRampedForSettingQuery,
  IdIsRampedForSettingQueryVariables,
  IdIsRampedForSettingProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, IdIsRampedForSettingQuery, IdIsRampedForSettingQueryVariables, IdIsRampedForSettingProps<TChildProps, TDataName>>(IdIsRampedForSettingDocument, {
      alias: 'idIsRampedForSetting',
      ...operationOptions
    });
};

/**
 * __useIdIsRampedForSettingQuery__
 *
 * To run a query within a React component, call `useIdIsRampedForSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useIdIsRampedForSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIdIsRampedForSettingQuery({
 *   variables: {
 *      setting: // value for 'setting'
 *      id: // value for 'id'
 *   },
 * });
 */
export function useIdIsRampedForSettingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IdIsRampedForSettingQuery, IdIsRampedForSettingQueryVariables>) {
        return ApolloReactHooks.useQuery<IdIsRampedForSettingQuery, IdIsRampedForSettingQueryVariables>(IdIsRampedForSettingDocument, baseOptions);
      }
export function useIdIsRampedForSettingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IdIsRampedForSettingQuery, IdIsRampedForSettingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IdIsRampedForSettingQuery, IdIsRampedForSettingQueryVariables>(IdIsRampedForSettingDocument, baseOptions);
        }
export type IdIsRampedForSettingQueryHookResult = ReturnType<typeof useIdIsRampedForSettingQuery>;
export type IdIsRampedForSettingLazyQueryHookResult = ReturnType<typeof useIdIsRampedForSettingLazyQuery>;
export type IdIsRampedForSettingQueryResult = ApolloReactCommon.QueryResult<IdIsRampedForSettingQuery, IdIsRampedForSettingQueryVariables>;
export const UpdateRampThresholdDocument = gql`
    mutation updateRampThreshold($store: String!, $setting: String!, $checkId: String!, $threshold: Float!, $clientMutationId: String) {
  updateRampThreshold(
    input: {store: $store, setting: $setting, checkId: $checkId, threshold: $threshold, clientMutationId: $clientMutationId}
  ) {
    success
    clientMutationId
  }
}
    `;
export type UpdateRampThresholdMutationFn = ApolloReactCommon.MutationFunction<UpdateRampThresholdMutation, UpdateRampThresholdMutationVariables>;
export type UpdateRampThresholdComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateRampThresholdMutation, UpdateRampThresholdMutationVariables>, 'mutation'>;

    export const UpdateRampThresholdComponent = (props: UpdateRampThresholdComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateRampThresholdMutation, UpdateRampThresholdMutationVariables> mutation={UpdateRampThresholdDocument} {...props} />
    );
    
export type UpdateRampThresholdProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateRampThresholdMutation, UpdateRampThresholdMutationVariables>
    } & TChildProps;
export function withUpdateRampThreshold<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateRampThresholdMutation,
  UpdateRampThresholdMutationVariables,
  UpdateRampThresholdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateRampThresholdMutation, UpdateRampThresholdMutationVariables, UpdateRampThresholdProps<TChildProps, TDataName>>(UpdateRampThresholdDocument, {
      alias: 'updateRampThreshold',
      ...operationOptions
    });
};

/**
 * __useUpdateRampThresholdMutation__
 *
 * To run a mutation, you first call `useUpdateRampThresholdMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRampThresholdMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRampThresholdMutation, { data, loading, error }] = useUpdateRampThresholdMutation({
 *   variables: {
 *      store: // value for 'store'
 *      setting: // value for 'setting'
 *      checkId: // value for 'checkId'
 *      threshold: // value for 'threshold'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useUpdateRampThresholdMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRampThresholdMutation, UpdateRampThresholdMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRampThresholdMutation, UpdateRampThresholdMutationVariables>(UpdateRampThresholdDocument, baseOptions);
      }
export type UpdateRampThresholdMutationHookResult = ReturnType<typeof useUpdateRampThresholdMutation>;
export type UpdateRampThresholdMutationResult = ApolloReactCommon.MutationResult<UpdateRampThresholdMutation>;
export type UpdateRampThresholdMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRampThresholdMutation, UpdateRampThresholdMutationVariables>;
export const FetchRepoDisplayNameByIdDocument = gql`
    query FetchRepoDisplayNameByID($id: ID!) {
  repo(id: $id) {
    id
    displayName
  }
}
    `;
export type FetchRepoDisplayNameByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FetchRepoDisplayNameByIdQuery, FetchRepoDisplayNameByIdQueryVariables>, 'query'> & ({ variables: FetchRepoDisplayNameByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FetchRepoDisplayNameByIdComponent = (props: FetchRepoDisplayNameByIdComponentProps) => (
      <ApolloReactComponents.Query<FetchRepoDisplayNameByIdQuery, FetchRepoDisplayNameByIdQueryVariables> query={FetchRepoDisplayNameByIdDocument} {...props} />
    );
    
export type FetchRepoDisplayNameByIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FetchRepoDisplayNameByIdQuery, FetchRepoDisplayNameByIdQueryVariables>
    } & TChildProps;
export function withFetchRepoDisplayNameById<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FetchRepoDisplayNameByIdQuery,
  FetchRepoDisplayNameByIdQueryVariables,
  FetchRepoDisplayNameByIdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FetchRepoDisplayNameByIdQuery, FetchRepoDisplayNameByIdQueryVariables, FetchRepoDisplayNameByIdProps<TChildProps, TDataName>>(FetchRepoDisplayNameByIdDocument, {
      alias: 'fetchRepoDisplayNameById',
      ...operationOptions
    });
};

/**
 * __useFetchRepoDisplayNameByIdQuery__
 *
 * To run a query within a React component, call `useFetchRepoDisplayNameByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRepoDisplayNameByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRepoDisplayNameByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchRepoDisplayNameByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchRepoDisplayNameByIdQuery, FetchRepoDisplayNameByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchRepoDisplayNameByIdQuery, FetchRepoDisplayNameByIdQueryVariables>(FetchRepoDisplayNameByIdDocument, baseOptions);
      }
export function useFetchRepoDisplayNameByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchRepoDisplayNameByIdQuery, FetchRepoDisplayNameByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchRepoDisplayNameByIdQuery, FetchRepoDisplayNameByIdQueryVariables>(FetchRepoDisplayNameByIdDocument, baseOptions);
        }
export type FetchRepoDisplayNameByIdQueryHookResult = ReturnType<typeof useFetchRepoDisplayNameByIdQuery>;
export type FetchRepoDisplayNameByIdLazyQueryHookResult = ReturnType<typeof useFetchRepoDisplayNameByIdLazyQuery>;
export type FetchRepoDisplayNameByIdQueryResult = ApolloReactCommon.QueryResult<FetchRepoDisplayNameByIdQuery, FetchRepoDisplayNameByIdQueryVariables>;
export const ReportSearchDocument = gql`
    query ReportSearch($query: String!) {
  reportSearch(query: $query) {
    edges {
      node {
        entityName
        displayName
        id
        projectName
      }
    }
  }
}
    `;
export type ReportSearchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ReportSearchQuery, ReportSearchQueryVariables>, 'query'> & ({ variables: ReportSearchQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ReportSearchComponent = (props: ReportSearchComponentProps) => (
      <ApolloReactComponents.Query<ReportSearchQuery, ReportSearchQueryVariables> query={ReportSearchDocument} {...props} />
    );
    
export type ReportSearchProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ReportSearchQuery, ReportSearchQueryVariables>
    } & TChildProps;
export function withReportSearch<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReportSearchQuery,
  ReportSearchQueryVariables,
  ReportSearchProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ReportSearchQuery, ReportSearchQueryVariables, ReportSearchProps<TChildProps, TDataName>>(ReportSearchDocument, {
      alias: 'reportSearch',
      ...operationOptions
    });
};

/**
 * __useReportSearchQuery__
 *
 * To run a query within a React component, call `useReportSearchQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportSearchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportSearchQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useReportSearchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReportSearchQuery, ReportSearchQueryVariables>) {
        return ApolloReactHooks.useQuery<ReportSearchQuery, ReportSearchQueryVariables>(ReportSearchDocument, baseOptions);
      }
export function useReportSearchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReportSearchQuery, ReportSearchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReportSearchQuery, ReportSearchQueryVariables>(ReportSearchDocument, baseOptions);
        }
export type ReportSearchQueryHookResult = ReturnType<typeof useReportSearchQuery>;
export type ReportSearchLazyQueryHookResult = ReturnType<typeof useReportSearchLazyQuery>;
export type ReportSearchQueryResult = ApolloReactCommon.QueryResult<ReportSearchQuery, ReportSearchQueryVariables>;
export const OrganizationRolesDocument = gql`
    query OrganizationRoles($orgName: String!) {
  organization(name: $orgName) {
    id
    roles {
      ID
      name
      description
      inheritedFrom {
        name
      }
    }
  }
}
    `;
export type OrganizationRolesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationRolesQuery, OrganizationRolesQueryVariables>, 'query'> & ({ variables: OrganizationRolesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationRolesComponent = (props: OrganizationRolesComponentProps) => (
      <ApolloReactComponents.Query<OrganizationRolesQuery, OrganizationRolesQueryVariables> query={OrganizationRolesDocument} {...props} />
    );
    
export type OrganizationRolesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationRolesQuery, OrganizationRolesQueryVariables>
    } & TChildProps;
export function withOrganizationRoles<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationRolesQuery,
  OrganizationRolesQueryVariables,
  OrganizationRolesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationRolesQuery, OrganizationRolesQueryVariables, OrganizationRolesProps<TChildProps, TDataName>>(OrganizationRolesDocument, {
      alias: 'organizationRoles',
      ...operationOptions
    });
};

/**
 * __useOrganizationRolesQuery__
 *
 * To run a query within a React component, call `useOrganizationRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationRolesQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useOrganizationRolesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationRolesQuery, OrganizationRolesQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationRolesQuery, OrganizationRolesQueryVariables>(OrganizationRolesDocument, baseOptions);
      }
export function useOrganizationRolesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationRolesQuery, OrganizationRolesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationRolesQuery, OrganizationRolesQueryVariables>(OrganizationRolesDocument, baseOptions);
        }
export type OrganizationRolesQueryHookResult = ReturnType<typeof useOrganizationRolesQuery>;
export type OrganizationRolesLazyQueryHookResult = ReturnType<typeof useOrganizationRolesLazyQuery>;
export type OrganizationRolesQueryResult = ApolloReactCommon.QueryResult<OrganizationRolesQuery, OrganizationRolesQueryVariables>;
export const OrganizationRolePermissionsDocument = gql`
    query OrganizationRolePermissions($orgName: String!) {
  organization(name: $orgName) {
    id
    roles {
      ID
      name
      description
      inheritedFrom {
        ID
        name
      }
      permissions {
        name
        displayName
        groupName
      }
    }
  }
}
    `;
export type OrganizationRolePermissionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationRolePermissionsQuery, OrganizationRolePermissionsQueryVariables>, 'query'> & ({ variables: OrganizationRolePermissionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationRolePermissionsComponent = (props: OrganizationRolePermissionsComponentProps) => (
      <ApolloReactComponents.Query<OrganizationRolePermissionsQuery, OrganizationRolePermissionsQueryVariables> query={OrganizationRolePermissionsDocument} {...props} />
    );
    
export type OrganizationRolePermissionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationRolePermissionsQuery, OrganizationRolePermissionsQueryVariables>
    } & TChildProps;
export function withOrganizationRolePermissions<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationRolePermissionsQuery,
  OrganizationRolePermissionsQueryVariables,
  OrganizationRolePermissionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationRolePermissionsQuery, OrganizationRolePermissionsQueryVariables, OrganizationRolePermissionsProps<TChildProps, TDataName>>(OrganizationRolePermissionsDocument, {
      alias: 'organizationRolePermissions',
      ...operationOptions
    });
};

/**
 * __useOrganizationRolePermissionsQuery__
 *
 * To run a query within a React component, call `useOrganizationRolePermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationRolePermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationRolePermissionsQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useOrganizationRolePermissionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationRolePermissionsQuery, OrganizationRolePermissionsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationRolePermissionsQuery, OrganizationRolePermissionsQueryVariables>(OrganizationRolePermissionsDocument, baseOptions);
      }
export function useOrganizationRolePermissionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationRolePermissionsQuery, OrganizationRolePermissionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationRolePermissionsQuery, OrganizationRolePermissionsQueryVariables>(OrganizationRolePermissionsDocument, baseOptions);
        }
export type OrganizationRolePermissionsQueryHookResult = ReturnType<typeof useOrganizationRolePermissionsQuery>;
export type OrganizationRolePermissionsLazyQueryHookResult = ReturnType<typeof useOrganizationRolePermissionsLazyQuery>;
export type OrganizationRolePermissionsQueryResult = ApolloReactCommon.QueryResult<OrganizationRolePermissionsQuery, OrganizationRolePermissionsQueryVariables>;
export const CreateRoleDocument = gql`
    mutation createRole($name: String!, $description: String, $inheritedFrom: String!, $permissions: [String!]!, $organizationID: ID!) {
  createRole(
    input: {name: $name, description: $description, inheritedFrom: $inheritedFrom, permissions: $permissions, organizationID: $organizationID}
  ) {
    success
  }
}
    `;
export type CreateRoleMutationFn = ApolloReactCommon.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>;
export type CreateRoleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateRoleMutation, CreateRoleMutationVariables>, 'mutation'>;

    export const CreateRoleComponent = (props: CreateRoleComponentProps) => (
      <ApolloReactComponents.Mutation<CreateRoleMutation, CreateRoleMutationVariables> mutation={CreateRoleDocument} {...props} />
    );
    
export type CreateRoleProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateRoleMutation, CreateRoleMutationVariables>
    } & TChildProps;
export function withCreateRole<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateRoleMutation,
  CreateRoleMutationVariables,
  CreateRoleProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateRoleMutation, CreateRoleMutationVariables, CreateRoleProps<TChildProps, TDataName>>(CreateRoleDocument, {
      alias: 'createRole',
      ...operationOptions
    });
};

/**
 * __useCreateRoleMutation__
 *
 * To run a mutation, you first call `useCreateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRoleMutation, { data, loading, error }] = useCreateRoleMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      inheritedFrom: // value for 'inheritedFrom'
 *      permissions: // value for 'permissions'
 *      organizationID: // value for 'organizationID'
 *   },
 * });
 */
export function useCreateRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRoleMutation, CreateRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRoleMutation, CreateRoleMutationVariables>(CreateRoleDocument, baseOptions);
      }
export type CreateRoleMutationHookResult = ReturnType<typeof useCreateRoleMutation>;
export type CreateRoleMutationResult = ApolloReactCommon.MutationResult<CreateRoleMutation>;
export type CreateRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRoleMutation, CreateRoleMutationVariables>;
export const UpdateRoleDocument = gql`
    mutation updateRole($roleID: ID!, $name: String, $description: String, $inheritedFrom: String) {
  updateRole(
    input: {roleID: $roleID, name: $name, description: $description, inheritedFrom: $inheritedFrom}
  ) {
    success
  }
}
    `;
export type UpdateRoleMutationFn = ApolloReactCommon.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>;
export type UpdateRoleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateRoleMutation, UpdateRoleMutationVariables>, 'mutation'>;

    export const UpdateRoleComponent = (props: UpdateRoleComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateRoleMutation, UpdateRoleMutationVariables> mutation={UpdateRoleDocument} {...props} />
    );
    
export type UpdateRoleProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateRoleMutation, UpdateRoleMutationVariables>
    } & TChildProps;
export function withUpdateRole<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateRoleMutation,
  UpdateRoleMutationVariables,
  UpdateRoleProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateRoleMutation, UpdateRoleMutationVariables, UpdateRoleProps<TChildProps, TDataName>>(UpdateRoleDocument, {
      alias: 'updateRole',
      ...operationOptions
    });
};

/**
 * __useUpdateRoleMutation__
 *
 * To run a mutation, you first call `useUpdateRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRoleMutation, { data, loading, error }] = useUpdateRoleMutation({
 *   variables: {
 *      roleID: // value for 'roleID'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      inheritedFrom: // value for 'inheritedFrom'
 *   },
 * });
 */
export function useUpdateRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRoleMutation, UpdateRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRoleMutation, UpdateRoleMutationVariables>(UpdateRoleDocument, baseOptions);
      }
export type UpdateRoleMutationHookResult = ReturnType<typeof useUpdateRoleMutation>;
export type UpdateRoleMutationResult = ApolloReactCommon.MutationResult<UpdateRoleMutation>;
export type UpdateRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRoleMutation, UpdateRoleMutationVariables>;
export const DeleteRoleDocument = gql`
    mutation deleteRole($roleID: ID!) {
  deleteRole(input: {roleID: $roleID}) {
    success
  }
}
    `;
export type DeleteRoleMutationFn = ApolloReactCommon.MutationFunction<DeleteRoleMutation, DeleteRoleMutationVariables>;
export type DeleteRoleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteRoleMutation, DeleteRoleMutationVariables>, 'mutation'>;

    export const DeleteRoleComponent = (props: DeleteRoleComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteRoleMutation, DeleteRoleMutationVariables> mutation={DeleteRoleDocument} {...props} />
    );
    
export type DeleteRoleProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteRoleMutation, DeleteRoleMutationVariables>
    } & TChildProps;
export function withDeleteRole<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteRoleMutation,
  DeleteRoleMutationVariables,
  DeleteRoleProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteRoleMutation, DeleteRoleMutationVariables, DeleteRoleProps<TChildProps, TDataName>>(DeleteRoleDocument, {
      alias: 'deleteRole',
      ...operationOptions
    });
};

/**
 * __useDeleteRoleMutation__
 *
 * To run a mutation, you first call `useDeleteRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRoleMutation, { data, loading, error }] = useDeleteRoleMutation({
 *   variables: {
 *      roleID: // value for 'roleID'
 *   },
 * });
 */
export function useDeleteRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRoleMutation, DeleteRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRoleMutation, DeleteRoleMutationVariables>(DeleteRoleDocument, baseOptions);
      }
export type DeleteRoleMutationHookResult = ReturnType<typeof useDeleteRoleMutation>;
export type DeleteRoleMutationResult = ApolloReactCommon.MutationResult<DeleteRoleMutation>;
export type DeleteRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRoleMutation, DeleteRoleMutationVariables>;
export const AddRolePermissionsDocument = gql`
    mutation addRolePermissions($roleID: ID!, $permissions: [String!]!) {
  addRolePermissions(input: {roleID: $roleID, permissions: $permissions}) {
    success
  }
}
    `;
export type AddRolePermissionsMutationFn = ApolloReactCommon.MutationFunction<AddRolePermissionsMutation, AddRolePermissionsMutationVariables>;
export type AddRolePermissionsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddRolePermissionsMutation, AddRolePermissionsMutationVariables>, 'mutation'>;

    export const AddRolePermissionsComponent = (props: AddRolePermissionsComponentProps) => (
      <ApolloReactComponents.Mutation<AddRolePermissionsMutation, AddRolePermissionsMutationVariables> mutation={AddRolePermissionsDocument} {...props} />
    );
    
export type AddRolePermissionsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AddRolePermissionsMutation, AddRolePermissionsMutationVariables>
    } & TChildProps;
export function withAddRolePermissions<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddRolePermissionsMutation,
  AddRolePermissionsMutationVariables,
  AddRolePermissionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddRolePermissionsMutation, AddRolePermissionsMutationVariables, AddRolePermissionsProps<TChildProps, TDataName>>(AddRolePermissionsDocument, {
      alias: 'addRolePermissions',
      ...operationOptions
    });
};

/**
 * __useAddRolePermissionsMutation__
 *
 * To run a mutation, you first call `useAddRolePermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRolePermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRolePermissionsMutation, { data, loading, error }] = useAddRolePermissionsMutation({
 *   variables: {
 *      roleID: // value for 'roleID'
 *      permissions: // value for 'permissions'
 *   },
 * });
 */
export function useAddRolePermissionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddRolePermissionsMutation, AddRolePermissionsMutationVariables>) {
        return ApolloReactHooks.useMutation<AddRolePermissionsMutation, AddRolePermissionsMutationVariables>(AddRolePermissionsDocument, baseOptions);
      }
export type AddRolePermissionsMutationHookResult = ReturnType<typeof useAddRolePermissionsMutation>;
export type AddRolePermissionsMutationResult = ApolloReactCommon.MutationResult<AddRolePermissionsMutation>;
export type AddRolePermissionsMutationOptions = ApolloReactCommon.BaseMutationOptions<AddRolePermissionsMutation, AddRolePermissionsMutationVariables>;
export const DeleteRolePermissionsDocument = gql`
    mutation deleteRolePermissions($roleID: ID!, $permissions: [String!]!) {
  deleteRolePermissions(input: {roleID: $roleID, permissions: $permissions}) {
    success
  }
}
    `;
export type DeleteRolePermissionsMutationFn = ApolloReactCommon.MutationFunction<DeleteRolePermissionsMutation, DeleteRolePermissionsMutationVariables>;
export type DeleteRolePermissionsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteRolePermissionsMutation, DeleteRolePermissionsMutationVariables>, 'mutation'>;

    export const DeleteRolePermissionsComponent = (props: DeleteRolePermissionsComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteRolePermissionsMutation, DeleteRolePermissionsMutationVariables> mutation={DeleteRolePermissionsDocument} {...props} />
    );
    
export type DeleteRolePermissionsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteRolePermissionsMutation, DeleteRolePermissionsMutationVariables>
    } & TChildProps;
export function withDeleteRolePermissions<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteRolePermissionsMutation,
  DeleteRolePermissionsMutationVariables,
  DeleteRolePermissionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteRolePermissionsMutation, DeleteRolePermissionsMutationVariables, DeleteRolePermissionsProps<TChildProps, TDataName>>(DeleteRolePermissionsDocument, {
      alias: 'deleteRolePermissions',
      ...operationOptions
    });
};

/**
 * __useDeleteRolePermissionsMutation__
 *
 * To run a mutation, you first call `useDeleteRolePermissionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRolePermissionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRolePermissionsMutation, { data, loading, error }] = useDeleteRolePermissionsMutation({
 *   variables: {
 *      roleID: // value for 'roleID'
 *      permissions: // value for 'permissions'
 *   },
 * });
 */
export function useDeleteRolePermissionsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRolePermissionsMutation, DeleteRolePermissionsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRolePermissionsMutation, DeleteRolePermissionsMutationVariables>(DeleteRolePermissionsDocument, baseOptions);
      }
export type DeleteRolePermissionsMutationHookResult = ReturnType<typeof useDeleteRolePermissionsMutation>;
export type DeleteRolePermissionsMutationResult = ApolloReactCommon.MutationResult<DeleteRolePermissionsMutation>;
export type DeleteRolePermissionsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRolePermissionsMutation, DeleteRolePermissionsMutationVariables>;
export const EntityMemberRoleDocument = gql`
    query EntityMemberRole($entityName: String!, $memberId: ID!) {
  entity(name: $entityName) {
    id
    member(id: $memberId) {
      id
      role
      memberRole {
        ID
        name
        inheritedFrom {
          permissions {
            name
            groupName
            displayName
          }
        }
        permissions {
          name
          groupName
          displayName
        }
      }
    }
  }
}
    `;
export type EntityMemberRoleComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityMemberRoleQuery, EntityMemberRoleQueryVariables>, 'query'> & ({ variables: EntityMemberRoleQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntityMemberRoleComponent = (props: EntityMemberRoleComponentProps) => (
      <ApolloReactComponents.Query<EntityMemberRoleQuery, EntityMemberRoleQueryVariables> query={EntityMemberRoleDocument} {...props} />
    );
    
export type EntityMemberRoleProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityMemberRoleQuery, EntityMemberRoleQueryVariables>
    } & TChildProps;
export function withEntityMemberRole<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityMemberRoleQuery,
  EntityMemberRoleQueryVariables,
  EntityMemberRoleProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityMemberRoleQuery, EntityMemberRoleQueryVariables, EntityMemberRoleProps<TChildProps, TDataName>>(EntityMemberRoleDocument, {
      alias: 'entityMemberRole',
      ...operationOptions
    });
};

/**
 * __useEntityMemberRoleQuery__
 *
 * To run a query within a React component, call `useEntityMemberRoleQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityMemberRoleQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityMemberRoleQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useEntityMemberRoleQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityMemberRoleQuery, EntityMemberRoleQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityMemberRoleQuery, EntityMemberRoleQueryVariables>(EntityMemberRoleDocument, baseOptions);
      }
export function useEntityMemberRoleLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityMemberRoleQuery, EntityMemberRoleQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityMemberRoleQuery, EntityMemberRoleQueryVariables>(EntityMemberRoleDocument, baseOptions);
        }
export type EntityMemberRoleQueryHookResult = ReturnType<typeof useEntityMemberRoleQuery>;
export type EntityMemberRoleLazyQueryHookResult = ReturnType<typeof useEntityMemberRoleLazyQuery>;
export type EntityMemberRoleQueryResult = ApolloReactCommon.QueryResult<EntityMemberRoleQuery, EntityMemberRoleQueryVariables>;
export const RunLogLinesDocument = gql`
    query RunLogLines($projectName: String!, $entityName: String, $runName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    run(name: $runName) {
      id
      logLines(last: 10000) {
        edges {
          node {
            id
            line
            level
          }
        }
      }
    }
  }
}
    `;
export type RunLogLinesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunLogLinesQuery, RunLogLinesQueryVariables>, 'query'> & ({ variables: RunLogLinesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunLogLinesComponent = (props: RunLogLinesComponentProps) => (
      <ApolloReactComponents.Query<RunLogLinesQuery, RunLogLinesQueryVariables> query={RunLogLinesDocument} {...props} />
    );
    
export type RunLogLinesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunLogLinesQuery, RunLogLinesQueryVariables>
    } & TChildProps;
export function withRunLogLines<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunLogLinesQuery,
  RunLogLinesQueryVariables,
  RunLogLinesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunLogLinesQuery, RunLogLinesQueryVariables, RunLogLinesProps<TChildProps, TDataName>>(RunLogLinesDocument, {
      alias: 'runLogLines',
      ...operationOptions
    });
};

/**
 * __useRunLogLinesQuery__
 *
 * To run a query within a React component, call `useRunLogLinesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunLogLinesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunLogLinesQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runName: // value for 'runName'
 *   },
 * });
 */
export function useRunLogLinesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunLogLinesQuery, RunLogLinesQueryVariables>) {
        return ApolloReactHooks.useQuery<RunLogLinesQuery, RunLogLinesQueryVariables>(RunLogLinesDocument, baseOptions);
      }
export function useRunLogLinesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunLogLinesQuery, RunLogLinesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunLogLinesQuery, RunLogLinesQueryVariables>(RunLogLinesDocument, baseOptions);
        }
export type RunLogLinesQueryHookResult = ReturnType<typeof useRunLogLinesQuery>;
export type RunLogLinesLazyQueryHookResult = ReturnType<typeof useRunLogLinesLazyQuery>;
export type RunLogLinesQueryResult = ApolloReactCommon.QueryResult<RunLogLinesQuery, RunLogLinesQueryVariables>;
export const RunDocument = gql`
    query Run($projectName: String!, $entityName: String, $runName: String!, $enableArtifactCounts: Boolean = false) {
  project(name: $projectName, entityName: $entityName) {
    id
    name
    readOnly
    createdAt
    access
    views
    entityName
    tags {
      id
      name
      colorIndex
    }
    isBenchmark
    linkedBenchmark {
      id
      entityName
      name
      gitHubSubmissionRepo
      views
    }
    run(name: $runName) {
      id
      name
      displayName
      notes
      config
      summaryMetrics
      historyKeys(format: PLAINTEXT)
      github
      fileCount
      createdAt
      heartbeatAt
      computeSeconds
      commit
      jobType
      group
      tags: tagColors {
        id
        name
        colorIndex
      }
      host
      state
      stopped
      sweep {
        id
        name
        displayName
      }
      runInfo {
        program
        args
        os
        python
        colab
        executable
        codeSaved
        cpuCount
        cpuCountLogical
        gpuCount
        gpu
        git {
          remote
          commit
        }
      }
      diffPatchFile: files(first: 1, names: ["diff.patch"]) {
        edges {
          node {
            id
            name
            md5
            sizeBytes
          }
        }
      }
      metadataFile: files(first: 1, names: ["wandb-metadata.json"]) {
        edges {
          node {
            id
            name
            md5
            sizeBytes
            directUrl
          }
        }
      }
      ...ArtifactCountsFragment @include(if: $enableArtifactCounts)
      logLineCount
      outputLogFile: files(first: 1, names: ["output.log"]) {
        edges {
          node {
            id
            name
            md5
            sizeBytes
            directUrl
            url(upload: false)
          }
        }
      }
      benchmarkRun {
        id
        benchmark {
          id
          name
          entityName
          readOnly
          views
        }
        details
        results
        gitHubSubmissionPR
        run {
          id
          name
          github
          user {
            id
            username
          }
          summaryMetrics
        }
        originalProject {
          id
          name
          entityName
        }
        originalRun {
          id
          name
        }
        isCodeHidden
        state
      }
      user {
        id
        username
        name
        photoUrl
        accountType
      }
      servicesAvailable {
        tensorboard
      }
      branchPoint {
        run {
          id
          name
          project {
            id
            name
            entity {
              id
              name
            }
          }
        }
        step
      }
      internalID
    }
    entity {
      id
      name
      defaultAccess
      readOnlyAdmin
      privateOnly
      isTeam
      user {
        id
        username
        accountType
      }
      claimingEntity {
        id
        name
      }
      codeSavingEnabled
    }
  }
}
    ${ArtifactCountsFragmentFragmentDoc}`;
export type RunComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunQuery, RunQueryVariables>, 'query'> & ({ variables: RunQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunComponent = (props: RunComponentProps) => (
      <ApolloReactComponents.Query<RunQuery, RunQueryVariables> query={RunDocument} {...props} />
    );
    
export type RunProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunQuery, RunQueryVariables>
    } & TChildProps;
export function withRun<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunQuery,
  RunQueryVariables,
  RunProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunQuery, RunQueryVariables, RunProps<TChildProps, TDataName>>(RunDocument, {
      alias: 'run',
      ...operationOptions
    });
};

/**
 * __useRunQuery__
 *
 * To run a query within a React component, call `useRunQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runName: // value for 'runName'
 *      enableArtifactCounts: // value for 'enableArtifactCounts'
 *   },
 * });
 */
export function useRunQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunQuery, RunQueryVariables>) {
        return ApolloReactHooks.useQuery<RunQuery, RunQueryVariables>(RunDocument, baseOptions);
      }
export function useRunLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunQuery, RunQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunQuery, RunQueryVariables>(RunDocument, baseOptions);
        }
export type RunQueryHookResult = ReturnType<typeof useRunQuery>;
export type RunLazyQueryHookResult = ReturnType<typeof useRunLazyQuery>;
export type RunQueryResult = ApolloReactCommon.QueryResult<RunQuery, RunQueryVariables>;
export const CreateRunQueueDocument = gql`
    mutation createRunQueue($entityName: String!, $projectName: String!, $queueName: String!, $access: RunQueueAccessType!, $defaultResourceConfigID: ID, $prioritizationMode: RunQueuePrioritizationMode!) {
  createRunQueue(
    input: {entityName: $entityName, projectName: $projectName, queueName: $queueName, access: $access, defaultResourceConfigID: $defaultResourceConfigID, prioritizationMode: $prioritizationMode}
  ) {
    success
    queueID
  }
}
    `;
export type CreateRunQueueMutationFn = ApolloReactCommon.MutationFunction<CreateRunQueueMutation, CreateRunQueueMutationVariables>;
export type CreateRunQueueComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateRunQueueMutation, CreateRunQueueMutationVariables>, 'mutation'>;

    export const CreateRunQueueComponent = (props: CreateRunQueueComponentProps) => (
      <ApolloReactComponents.Mutation<CreateRunQueueMutation, CreateRunQueueMutationVariables> mutation={CreateRunQueueDocument} {...props} />
    );
    
export type CreateRunQueueProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateRunQueueMutation, CreateRunQueueMutationVariables>
    } & TChildProps;
export function withCreateRunQueue<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateRunQueueMutation,
  CreateRunQueueMutationVariables,
  CreateRunQueueProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateRunQueueMutation, CreateRunQueueMutationVariables, CreateRunQueueProps<TChildProps, TDataName>>(CreateRunQueueDocument, {
      alias: 'createRunQueue',
      ...operationOptions
    });
};

/**
 * __useCreateRunQueueMutation__
 *
 * To run a mutation, you first call `useCreateRunQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRunQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRunQueueMutation, { data, loading, error }] = useCreateRunQueueMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      queueName: // value for 'queueName'
 *      access: // value for 'access'
 *      defaultResourceConfigID: // value for 'defaultResourceConfigID'
 *      prioritizationMode: // value for 'prioritizationMode'
 *   },
 * });
 */
export function useCreateRunQueueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateRunQueueMutation, CreateRunQueueMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateRunQueueMutation, CreateRunQueueMutationVariables>(CreateRunQueueDocument, baseOptions);
      }
export type CreateRunQueueMutationHookResult = ReturnType<typeof useCreateRunQueueMutation>;
export type CreateRunQueueMutationResult = ApolloReactCommon.MutationResult<CreateRunQueueMutation>;
export type CreateRunQueueMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateRunQueueMutation, CreateRunQueueMutationVariables>;
export const UpdateRunQueueDocument = gql`
    mutation updateRunQueue($queueID: ID!, $defaultResourceConfigID: ID, $externalLinks: JSONString) {
  updateRunQueue(
    input: {queueID: $queueID, defaultResourceConfigID: $defaultResourceConfigID, externalLinks: $externalLinks}
  ) {
    success
  }
}
    `;
export type UpdateRunQueueMutationFn = ApolloReactCommon.MutationFunction<UpdateRunQueueMutation, UpdateRunQueueMutationVariables>;
export type UpdateRunQueueComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateRunQueueMutation, UpdateRunQueueMutationVariables>, 'mutation'>;

    export const UpdateRunQueueComponent = (props: UpdateRunQueueComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateRunQueueMutation, UpdateRunQueueMutationVariables> mutation={UpdateRunQueueDocument} {...props} />
    );
    
export type UpdateRunQueueProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateRunQueueMutation, UpdateRunQueueMutationVariables>
    } & TChildProps;
export function withUpdateRunQueue<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateRunQueueMutation,
  UpdateRunQueueMutationVariables,
  UpdateRunQueueProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateRunQueueMutation, UpdateRunQueueMutationVariables, UpdateRunQueueProps<TChildProps, TDataName>>(UpdateRunQueueDocument, {
      alias: 'updateRunQueue',
      ...operationOptions
    });
};

/**
 * __useUpdateRunQueueMutation__
 *
 * To run a mutation, you first call `useUpdateRunQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRunQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRunQueueMutation, { data, loading, error }] = useUpdateRunQueueMutation({
 *   variables: {
 *      queueID: // value for 'queueID'
 *      defaultResourceConfigID: // value for 'defaultResourceConfigID'
 *      externalLinks: // value for 'externalLinks'
 *   },
 * });
 */
export function useUpdateRunQueueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateRunQueueMutation, UpdateRunQueueMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateRunQueueMutation, UpdateRunQueueMutationVariables>(UpdateRunQueueDocument, baseOptions);
      }
export type UpdateRunQueueMutationHookResult = ReturnType<typeof useUpdateRunQueueMutation>;
export type UpdateRunQueueMutationResult = ApolloReactCommon.MutationResult<UpdateRunQueueMutation>;
export type UpdateRunQueueMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateRunQueueMutation, UpdateRunQueueMutationVariables>;
export const EnableRunQueuePrioritizationDocument = gql`
    mutation enableRunQueuePrioritization($queueID: ID!) {
  updateRunQueue(input: {queueID: $queueID, prioritizationMode: V0}) {
    success
  }
}
    `;
export type EnableRunQueuePrioritizationMutationFn = ApolloReactCommon.MutationFunction<EnableRunQueuePrioritizationMutation, EnableRunQueuePrioritizationMutationVariables>;
export type EnableRunQueuePrioritizationComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<EnableRunQueuePrioritizationMutation, EnableRunQueuePrioritizationMutationVariables>, 'mutation'>;

    export const EnableRunQueuePrioritizationComponent = (props: EnableRunQueuePrioritizationComponentProps) => (
      <ApolloReactComponents.Mutation<EnableRunQueuePrioritizationMutation, EnableRunQueuePrioritizationMutationVariables> mutation={EnableRunQueuePrioritizationDocument} {...props} />
    );
    
export type EnableRunQueuePrioritizationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<EnableRunQueuePrioritizationMutation, EnableRunQueuePrioritizationMutationVariables>
    } & TChildProps;
export function withEnableRunQueuePrioritization<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EnableRunQueuePrioritizationMutation,
  EnableRunQueuePrioritizationMutationVariables,
  EnableRunQueuePrioritizationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, EnableRunQueuePrioritizationMutation, EnableRunQueuePrioritizationMutationVariables, EnableRunQueuePrioritizationProps<TChildProps, TDataName>>(EnableRunQueuePrioritizationDocument, {
      alias: 'enableRunQueuePrioritization',
      ...operationOptions
    });
};

/**
 * __useEnableRunQueuePrioritizationMutation__
 *
 * To run a mutation, you first call `useEnableRunQueuePrioritizationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEnableRunQueuePrioritizationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [enableRunQueuePrioritizationMutation, { data, loading, error }] = useEnableRunQueuePrioritizationMutation({
 *   variables: {
 *      queueID: // value for 'queueID'
 *   },
 * });
 */
export function useEnableRunQueuePrioritizationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EnableRunQueuePrioritizationMutation, EnableRunQueuePrioritizationMutationVariables>) {
        return ApolloReactHooks.useMutation<EnableRunQueuePrioritizationMutation, EnableRunQueuePrioritizationMutationVariables>(EnableRunQueuePrioritizationDocument, baseOptions);
      }
export type EnableRunQueuePrioritizationMutationHookResult = ReturnType<typeof useEnableRunQueuePrioritizationMutation>;
export type EnableRunQueuePrioritizationMutationResult = ApolloReactCommon.MutationResult<EnableRunQueuePrioritizationMutation>;
export type EnableRunQueuePrioritizationMutationOptions = ApolloReactCommon.BaseMutationOptions<EnableRunQueuePrioritizationMutation, EnableRunQueuePrioritizationMutationVariables>;
export const DeleteRunQueuesDocument = gql`
    mutation deleteRunQueues($queueIDs: [ID!]!) {
  deleteRunQueues(input: {queueIDs: $queueIDs}) {
    success
  }
}
    `;
export type DeleteRunQueuesMutationFn = ApolloReactCommon.MutationFunction<DeleteRunQueuesMutation, DeleteRunQueuesMutationVariables>;
export type DeleteRunQueuesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteRunQueuesMutation, DeleteRunQueuesMutationVariables>, 'mutation'>;

    export const DeleteRunQueuesComponent = (props: DeleteRunQueuesComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteRunQueuesMutation, DeleteRunQueuesMutationVariables> mutation={DeleteRunQueuesDocument} {...props} />
    );
    
export type DeleteRunQueuesProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteRunQueuesMutation, DeleteRunQueuesMutationVariables>
    } & TChildProps;
export function withDeleteRunQueues<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteRunQueuesMutation,
  DeleteRunQueuesMutationVariables,
  DeleteRunQueuesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteRunQueuesMutation, DeleteRunQueuesMutationVariables, DeleteRunQueuesProps<TChildProps, TDataName>>(DeleteRunQueuesDocument, {
      alias: 'deleteRunQueues',
      ...operationOptions
    });
};

/**
 * __useDeleteRunQueuesMutation__
 *
 * To run a mutation, you first call `useDeleteRunQueuesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRunQueuesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRunQueuesMutation, { data, loading, error }] = useDeleteRunQueuesMutation({
 *   variables: {
 *      queueIDs: // value for 'queueIDs'
 *   },
 * });
 */
export function useDeleteRunQueuesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRunQueuesMutation, DeleteRunQueuesMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRunQueuesMutation, DeleteRunQueuesMutationVariables>(DeleteRunQueuesDocument, baseOptions);
      }
export type DeleteRunQueuesMutationHookResult = ReturnType<typeof useDeleteRunQueuesMutation>;
export type DeleteRunQueuesMutationResult = ApolloReactCommon.MutationResult<DeleteRunQueuesMutation>;
export type DeleteRunQueuesMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRunQueuesMutation, DeleteRunQueuesMutationVariables>;
export const PushToRunQueueDocument = gql`
    mutation pushToRunQueue($queueID: ID!, $runSpec: JSONString!, $priority: Int, $templateVariableValues: JSONString) {
  pushToRunQueue(
    input: {queueID: $queueID, runSpec: $runSpec, priority: $priority, templateVariableValues: $templateVariableValues}
  ) {
    runQueueItemId
  }
}
    `;
export type PushToRunQueueMutationFn = ApolloReactCommon.MutationFunction<PushToRunQueueMutation, PushToRunQueueMutationVariables>;
export type PushToRunQueueComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PushToRunQueueMutation, PushToRunQueueMutationVariables>, 'mutation'>;

    export const PushToRunQueueComponent = (props: PushToRunQueueComponentProps) => (
      <ApolloReactComponents.Mutation<PushToRunQueueMutation, PushToRunQueueMutationVariables> mutation={PushToRunQueueDocument} {...props} />
    );
    
export type PushToRunQueueProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PushToRunQueueMutation, PushToRunQueueMutationVariables>
    } & TChildProps;
export function withPushToRunQueue<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PushToRunQueueMutation,
  PushToRunQueueMutationVariables,
  PushToRunQueueProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PushToRunQueueMutation, PushToRunQueueMutationVariables, PushToRunQueueProps<TChildProps, TDataName>>(PushToRunQueueDocument, {
      alias: 'pushToRunQueue',
      ...operationOptions
    });
};

/**
 * __usePushToRunQueueMutation__
 *
 * To run a mutation, you first call `usePushToRunQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePushToRunQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pushToRunQueueMutation, { data, loading, error }] = usePushToRunQueueMutation({
 *   variables: {
 *      queueID: // value for 'queueID'
 *      runSpec: // value for 'runSpec'
 *      priority: // value for 'priority'
 *      templateVariableValues: // value for 'templateVariableValues'
 *   },
 * });
 */
export function usePushToRunQueueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PushToRunQueueMutation, PushToRunQueueMutationVariables>) {
        return ApolloReactHooks.useMutation<PushToRunQueueMutation, PushToRunQueueMutationVariables>(PushToRunQueueDocument, baseOptions);
      }
export type PushToRunQueueMutationHookResult = ReturnType<typeof usePushToRunQueueMutation>;
export type PushToRunQueueMutationResult = ApolloReactCommon.MutationResult<PushToRunQueueMutation>;
export type PushToRunQueueMutationOptions = ApolloReactCommon.BaseMutationOptions<PushToRunQueueMutation, PushToRunQueueMutationVariables>;
export const DeleteFromRunQueueDocument = gql`
    mutation deleteFromRunQueue($queueID: ID!, $runQueueItemId: ID!) {
  deleteFromRunQueue(input: {queueID: $queueID, runQueueItemId: $runQueueItemId}) {
    success
  }
}
    `;
export type DeleteFromRunQueueMutationFn = ApolloReactCommon.MutationFunction<DeleteFromRunQueueMutation, DeleteFromRunQueueMutationVariables>;
export type DeleteFromRunQueueComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteFromRunQueueMutation, DeleteFromRunQueueMutationVariables>, 'mutation'>;

    export const DeleteFromRunQueueComponent = (props: DeleteFromRunQueueComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteFromRunQueueMutation, DeleteFromRunQueueMutationVariables> mutation={DeleteFromRunQueueDocument} {...props} />
    );
    
export type DeleteFromRunQueueProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteFromRunQueueMutation, DeleteFromRunQueueMutationVariables>
    } & TChildProps;
export function withDeleteFromRunQueue<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteFromRunQueueMutation,
  DeleteFromRunQueueMutationVariables,
  DeleteFromRunQueueProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteFromRunQueueMutation, DeleteFromRunQueueMutationVariables, DeleteFromRunQueueProps<TChildProps, TDataName>>(DeleteFromRunQueueDocument, {
      alias: 'deleteFromRunQueue',
      ...operationOptions
    });
};

/**
 * __useDeleteFromRunQueueMutation__
 *
 * To run a mutation, you first call `useDeleteFromRunQueueMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFromRunQueueMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFromRunQueueMutation, { data, loading, error }] = useDeleteFromRunQueueMutation({
 *   variables: {
 *      queueID: // value for 'queueID'
 *      runQueueItemId: // value for 'runQueueItemId'
 *   },
 * });
 */
export function useDeleteFromRunQueueMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFromRunQueueMutation, DeleteFromRunQueueMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteFromRunQueueMutation, DeleteFromRunQueueMutationVariables>(DeleteFromRunQueueDocument, baseOptions);
      }
export type DeleteFromRunQueueMutationHookResult = ReturnType<typeof useDeleteFromRunQueueMutation>;
export type DeleteFromRunQueueMutationResult = ApolloReactCommon.MutationResult<DeleteFromRunQueueMutation>;
export type DeleteFromRunQueueMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFromRunQueueMutation, DeleteFromRunQueueMutationVariables>;
export const FetchRunQueuesFromProjectDocument = gql`
    query fetchRunQueuesFromProject($entityName: String!, $projectName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    runQueues {
      id
      createdAt
      createdBy
      access
      updatedAt
      name
      defaultResourceConfigID
      executorInfo
      runQueueItems {
        edges {
          node {
            id
            ...RunQueueItemFragment
          }
        }
      }
    }
  }
}
    ${RunQueueItemFragmentFragmentDoc}`;
export type FetchRunQueuesFromProjectComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FetchRunQueuesFromProjectQuery, FetchRunQueuesFromProjectQueryVariables>, 'query'> & ({ variables: FetchRunQueuesFromProjectQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FetchRunQueuesFromProjectComponent = (props: FetchRunQueuesFromProjectComponentProps) => (
      <ApolloReactComponents.Query<FetchRunQueuesFromProjectQuery, FetchRunQueuesFromProjectQueryVariables> query={FetchRunQueuesFromProjectDocument} {...props} />
    );
    
export type FetchRunQueuesFromProjectProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FetchRunQueuesFromProjectQuery, FetchRunQueuesFromProjectQueryVariables>
    } & TChildProps;
export function withFetchRunQueuesFromProject<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FetchRunQueuesFromProjectQuery,
  FetchRunQueuesFromProjectQueryVariables,
  FetchRunQueuesFromProjectProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FetchRunQueuesFromProjectQuery, FetchRunQueuesFromProjectQueryVariables, FetchRunQueuesFromProjectProps<TChildProps, TDataName>>(FetchRunQueuesFromProjectDocument, {
      alias: 'fetchRunQueuesFromProject',
      ...operationOptions
    });
};

/**
 * __useFetchRunQueuesFromProjectQuery__
 *
 * To run a query within a React component, call `useFetchRunQueuesFromProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRunQueuesFromProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRunQueuesFromProjectQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *   },
 * });
 */
export function useFetchRunQueuesFromProjectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchRunQueuesFromProjectQuery, FetchRunQueuesFromProjectQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchRunQueuesFromProjectQuery, FetchRunQueuesFromProjectQueryVariables>(FetchRunQueuesFromProjectDocument, baseOptions);
      }
export function useFetchRunQueuesFromProjectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchRunQueuesFromProjectQuery, FetchRunQueuesFromProjectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchRunQueuesFromProjectQuery, FetchRunQueuesFromProjectQueryVariables>(FetchRunQueuesFromProjectDocument, baseOptions);
        }
export type FetchRunQueuesFromProjectQueryHookResult = ReturnType<typeof useFetchRunQueuesFromProjectQuery>;
export type FetchRunQueuesFromProjectLazyQueryHookResult = ReturnType<typeof useFetchRunQueuesFromProjectLazyQuery>;
export type FetchRunQueuesFromProjectQueryResult = ApolloReactCommon.QueryResult<FetchRunQueuesFromProjectQuery, FetchRunQueuesFromProjectQueryVariables>;
export const FetchRunQueueByNameDocument = gql`
    query FetchRunQueueByName($entityName: String!, $projectName: String!, $runQueueName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    runQueue(name: $runQueueName) {
      id
      createdAt
      createdBy
      access
      updatedAt
      name
      defaultResourceConfigID
      executorInfo
      runQueueItems {
        edges {
          node {
            id
            ...RunQueueItemFragment
          }
        }
      }
    }
  }
}
    ${RunQueueItemFragmentFragmentDoc}`;
export type FetchRunQueueByNameComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FetchRunQueueByNameQuery, FetchRunQueueByNameQueryVariables>, 'query'> & ({ variables: FetchRunQueueByNameQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FetchRunQueueByNameComponent = (props: FetchRunQueueByNameComponentProps) => (
      <ApolloReactComponents.Query<FetchRunQueueByNameQuery, FetchRunQueueByNameQueryVariables> query={FetchRunQueueByNameDocument} {...props} />
    );
    
export type FetchRunQueueByNameProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FetchRunQueueByNameQuery, FetchRunQueueByNameQueryVariables>
    } & TChildProps;
export function withFetchRunQueueByName<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FetchRunQueueByNameQuery,
  FetchRunQueueByNameQueryVariables,
  FetchRunQueueByNameProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FetchRunQueueByNameQuery, FetchRunQueueByNameQueryVariables, FetchRunQueueByNameProps<TChildProps, TDataName>>(FetchRunQueueByNameDocument, {
      alias: 'fetchRunQueueByName',
      ...operationOptions
    });
};

/**
 * __useFetchRunQueueByNameQuery__
 *
 * To run a query within a React component, call `useFetchRunQueueByNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRunQueueByNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRunQueueByNameQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      runQueueName: // value for 'runQueueName'
 *   },
 * });
 */
export function useFetchRunQueueByNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchRunQueueByNameQuery, FetchRunQueueByNameQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchRunQueueByNameQuery, FetchRunQueueByNameQueryVariables>(FetchRunQueueByNameDocument, baseOptions);
      }
export function useFetchRunQueueByNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchRunQueueByNameQuery, FetchRunQueueByNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchRunQueueByNameQuery, FetchRunQueueByNameQueryVariables>(FetchRunQueueByNameDocument, baseOptions);
        }
export type FetchRunQueueByNameQueryHookResult = ReturnType<typeof useFetchRunQueueByNameQuery>;
export type FetchRunQueueByNameLazyQueryHookResult = ReturnType<typeof useFetchRunQueueByNameLazyQuery>;
export type FetchRunQueueByNameQueryResult = ApolloReactCommon.QueryResult<FetchRunQueueByNameQuery, FetchRunQueueByNameQueryVariables>;
export const FetchRunQueueItemFromProjectDocument = gql`
    query FetchRunQueueItemFromProject($entityName: String!, $projectName: String!, $runQueueName: String!, $runQueueItemID: ID!) {
  project(name: $projectName, entityName: $entityName) {
    id
    runQueue(name: $runQueueName) {
      id
      runQueueItem(id: $runQueueItemID) {
        id
        ...RunQueueItemFragment
      }
    }
  }
}
    ${RunQueueItemFragmentFragmentDoc}`;
export type FetchRunQueueItemFromProjectComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FetchRunQueueItemFromProjectQuery, FetchRunQueueItemFromProjectQueryVariables>, 'query'> & ({ variables: FetchRunQueueItemFromProjectQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FetchRunQueueItemFromProjectComponent = (props: FetchRunQueueItemFromProjectComponentProps) => (
      <ApolloReactComponents.Query<FetchRunQueueItemFromProjectQuery, FetchRunQueueItemFromProjectQueryVariables> query={FetchRunQueueItemFromProjectDocument} {...props} />
    );
    
export type FetchRunQueueItemFromProjectProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FetchRunQueueItemFromProjectQuery, FetchRunQueueItemFromProjectQueryVariables>
    } & TChildProps;
export function withFetchRunQueueItemFromProject<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FetchRunQueueItemFromProjectQuery,
  FetchRunQueueItemFromProjectQueryVariables,
  FetchRunQueueItemFromProjectProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FetchRunQueueItemFromProjectQuery, FetchRunQueueItemFromProjectQueryVariables, FetchRunQueueItemFromProjectProps<TChildProps, TDataName>>(FetchRunQueueItemFromProjectDocument, {
      alias: 'fetchRunQueueItemFromProject',
      ...operationOptions
    });
};

/**
 * __useFetchRunQueueItemFromProjectQuery__
 *
 * To run a query within a React component, call `useFetchRunQueueItemFromProjectQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRunQueueItemFromProjectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRunQueueItemFromProjectQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      runQueueName: // value for 'runQueueName'
 *      runQueueItemID: // value for 'runQueueItemID'
 *   },
 * });
 */
export function useFetchRunQueueItemFromProjectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchRunQueueItemFromProjectQuery, FetchRunQueueItemFromProjectQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchRunQueueItemFromProjectQuery, FetchRunQueueItemFromProjectQueryVariables>(FetchRunQueueItemFromProjectDocument, baseOptions);
      }
export function useFetchRunQueueItemFromProjectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchRunQueueItemFromProjectQuery, FetchRunQueueItemFromProjectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchRunQueueItemFromProjectQuery, FetchRunQueueItemFromProjectQueryVariables>(FetchRunQueueItemFromProjectDocument, baseOptions);
        }
export type FetchRunQueueItemFromProjectQueryHookResult = ReturnType<typeof useFetchRunQueueItemFromProjectQuery>;
export type FetchRunQueueItemFromProjectLazyQueryHookResult = ReturnType<typeof useFetchRunQueueItemFromProjectLazyQuery>;
export type FetchRunQueueItemFromProjectQueryResult = ApolloReactCommon.QueryResult<FetchRunQueueItemFromProjectQuery, FetchRunQueueItemFromProjectQueryVariables>;
export const TeamInfoDocument = gql`
    query TeamInfo($entityName: String!) {
  entity(name: $entityName) {
    id
    members {
      id
      username
      name
      admin
    }
  }
}
    `;
export type TeamInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamInfoQuery, TeamInfoQueryVariables>, 'query'> & ({ variables: TeamInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamInfoComponent = (props: TeamInfoComponentProps) => (
      <ApolloReactComponents.Query<TeamInfoQuery, TeamInfoQueryVariables> query={TeamInfoDocument} {...props} />
    );
    
export type TeamInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TeamInfoQuery, TeamInfoQueryVariables>
    } & TChildProps;
export function withTeamInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TeamInfoQuery,
  TeamInfoQueryVariables,
  TeamInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TeamInfoQuery, TeamInfoQueryVariables, TeamInfoProps<TChildProps, TDataName>>(TeamInfoDocument, {
      alias: 'teamInfo',
      ...operationOptions
    });
};

/**
 * __useTeamInfoQuery__
 *
 * To run a query within a React component, call `useTeamInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamInfoQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useTeamInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamInfoQuery, TeamInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamInfoQuery, TeamInfoQueryVariables>(TeamInfoDocument, baseOptions);
      }
export function useTeamInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamInfoQuery, TeamInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamInfoQuery, TeamInfoQueryVariables>(TeamInfoDocument, baseOptions);
        }
export type TeamInfoQueryHookResult = ReturnType<typeof useTeamInfoQuery>;
export type TeamInfoLazyQueryHookResult = ReturnType<typeof useTeamInfoLazyQuery>;
export type TeamInfoQueryResult = ApolloReactCommon.QueryResult<TeamInfoQuery, TeamInfoQueryVariables>;
export const RunDisplayNameDocument = gql`
    query RunDisplayName($entityName: String!, $projectName: String!, $runName: String!) {
  project(entityName: $entityName, name: $projectName) {
    id
    run(name: $runName) {
      id
      displayName
    }
  }
}
    `;
export type RunDisplayNameComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunDisplayNameQuery, RunDisplayNameQueryVariables>, 'query'> & ({ variables: RunDisplayNameQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunDisplayNameComponent = (props: RunDisplayNameComponentProps) => (
      <ApolloReactComponents.Query<RunDisplayNameQuery, RunDisplayNameQueryVariables> query={RunDisplayNameDocument} {...props} />
    );
    
export type RunDisplayNameProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunDisplayNameQuery, RunDisplayNameQueryVariables>
    } & TChildProps;
export function withRunDisplayName<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunDisplayNameQuery,
  RunDisplayNameQueryVariables,
  RunDisplayNameProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunDisplayNameQuery, RunDisplayNameQueryVariables, RunDisplayNameProps<TChildProps, TDataName>>(RunDisplayNameDocument, {
      alias: 'runDisplayName',
      ...operationOptions
    });
};

/**
 * __useRunDisplayNameQuery__
 *
 * To run a query within a React component, call `useRunDisplayNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunDisplayNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunDisplayNameQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      runName: // value for 'runName'
 *   },
 * });
 */
export function useRunDisplayNameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunDisplayNameQuery, RunDisplayNameQueryVariables>) {
        return ApolloReactHooks.useQuery<RunDisplayNameQuery, RunDisplayNameQueryVariables>(RunDisplayNameDocument, baseOptions);
      }
export function useRunDisplayNameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunDisplayNameQuery, RunDisplayNameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunDisplayNameQuery, RunDisplayNameQueryVariables>(RunDisplayNameDocument, baseOptions);
        }
export type RunDisplayNameQueryHookResult = ReturnType<typeof useRunDisplayNameQuery>;
export type RunDisplayNameLazyQueryHookResult = ReturnType<typeof useRunDisplayNameLazyQuery>;
export type RunDisplayNameQueryResult = ApolloReactCommon.QueryResult<RunDisplayNameQuery, RunDisplayNameQueryVariables>;
export const RunsStateDocument = gql`
    query RunsState($projectName: String!, $entityName: String, $filters: JSONString) {
  project(name: $projectName, entityName: $entityName) {
    id
    runs(filters: $filters) {
      edges {
        node {
          id
          name
          displayName
          state
        }
      }
    }
  }
}
    `;
export type RunsStateComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunsStateQuery, RunsStateQueryVariables>, 'query'> & ({ variables: RunsStateQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunsStateComponent = (props: RunsStateComponentProps) => (
      <ApolloReactComponents.Query<RunsStateQuery, RunsStateQueryVariables> query={RunsStateDocument} {...props} />
    );
    
export type RunsStateProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunsStateQuery, RunsStateQueryVariables>
    } & TChildProps;
export function withRunsState<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunsStateQuery,
  RunsStateQueryVariables,
  RunsStateProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunsStateQuery, RunsStateQueryVariables, RunsStateProps<TChildProps, TDataName>>(RunsStateDocument, {
      alias: 'runsState',
      ...operationOptions
    });
};

/**
 * __useRunsStateQuery__
 *
 * To run a query within a React component, call `useRunsStateQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunsStateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunsStateQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useRunsStateQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunsStateQuery, RunsStateQueryVariables>) {
        return ApolloReactHooks.useQuery<RunsStateQuery, RunsStateQueryVariables>(RunsStateDocument, baseOptions);
      }
export function useRunsStateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunsStateQuery, RunsStateQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunsStateQuery, RunsStateQueryVariables>(RunsStateDocument, baseOptions);
        }
export type RunsStateQueryHookResult = ReturnType<typeof useRunsStateQuery>;
export type RunsStateLazyQueryHookResult = ReturnType<typeof useRunsStateLazyQuery>;
export type RunsStateQueryResult = ApolloReactCommon.QueryResult<RunsStateQuery, RunsStateQueryVariables>;
export const RunSystemMetricsDocument = gql`
    query RunSystemMetrics($projectName: String!, $entityName: String, $runName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    run(name: $runName) {
      id
      events(samples: 1000)
    }
  }
}
    `;
export type RunSystemMetricsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunSystemMetricsQuery, RunSystemMetricsQueryVariables>, 'query'> & ({ variables: RunSystemMetricsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunSystemMetricsComponent = (props: RunSystemMetricsComponentProps) => (
      <ApolloReactComponents.Query<RunSystemMetricsQuery, RunSystemMetricsQueryVariables> query={RunSystemMetricsDocument} {...props} />
    );
    
export type RunSystemMetricsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunSystemMetricsQuery, RunSystemMetricsQueryVariables>
    } & TChildProps;
export function withRunSystemMetrics<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunSystemMetricsQuery,
  RunSystemMetricsQueryVariables,
  RunSystemMetricsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunSystemMetricsQuery, RunSystemMetricsQueryVariables, RunSystemMetricsProps<TChildProps, TDataName>>(RunSystemMetricsDocument, {
      alias: 'runSystemMetrics',
      ...operationOptions
    });
};

/**
 * __useRunSystemMetricsQuery__
 *
 * To run a query within a React component, call `useRunSystemMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunSystemMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunSystemMetricsQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runName: // value for 'runName'
 *   },
 * });
 */
export function useRunSystemMetricsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunSystemMetricsQuery, RunSystemMetricsQueryVariables>) {
        return ApolloReactHooks.useQuery<RunSystemMetricsQuery, RunSystemMetricsQueryVariables>(RunSystemMetricsDocument, baseOptions);
      }
export function useRunSystemMetricsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunSystemMetricsQuery, RunSystemMetricsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunSystemMetricsQuery, RunSystemMetricsQueryVariables>(RunSystemMetricsDocument, baseOptions);
        }
export type RunSystemMetricsQueryHookResult = ReturnType<typeof useRunSystemMetricsQuery>;
export type RunSystemMetricsLazyQueryHookResult = ReturnType<typeof useRunSystemMetricsLazyQuery>;
export type RunSystemMetricsQueryResult = ApolloReactCommon.QueryResult<RunSystemMetricsQuery, RunSystemMetricsQueryVariables>;
export const RunTensorboardDocument = gql`
    query RunTensorboard($projectName: String!, $entityName: String!, $runName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    run(name: $runName) {
      id
      servicesAvailable {
        tensorboard
      }
    }
  }
}
    `;
export type RunTensorboardComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunTensorboardQuery, RunTensorboardQueryVariables>, 'query'> & ({ variables: RunTensorboardQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunTensorboardComponent = (props: RunTensorboardComponentProps) => (
      <ApolloReactComponents.Query<RunTensorboardQuery, RunTensorboardQueryVariables> query={RunTensorboardDocument} {...props} />
    );
    
export type RunTensorboardProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunTensorboardQuery, RunTensorboardQueryVariables>
    } & TChildProps;
export function withRunTensorboard<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunTensorboardQuery,
  RunTensorboardQueryVariables,
  RunTensorboardProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunTensorboardQuery, RunTensorboardQueryVariables, RunTensorboardProps<TChildProps, TDataName>>(RunTensorboardDocument, {
      alias: 'runTensorboard',
      ...operationOptions
    });
};

/**
 * __useRunTensorboardQuery__
 *
 * To run a query within a React component, call `useRunTensorboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunTensorboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunTensorboardQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runName: // value for 'runName'
 *   },
 * });
 */
export function useRunTensorboardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunTensorboardQuery, RunTensorboardQueryVariables>) {
        return ApolloReactHooks.useQuery<RunTensorboardQuery, RunTensorboardQueryVariables>(RunTensorboardDocument, baseOptions);
      }
export function useRunTensorboardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunTensorboardQuery, RunTensorboardQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunTensorboardQuery, RunTensorboardQueryVariables>(RunTensorboardDocument, baseOptions);
        }
export type RunTensorboardQueryHookResult = ReturnType<typeof useRunTensorboardQuery>;
export type RunTensorboardLazyQueryHookResult = ReturnType<typeof useRunTensorboardLazyQuery>;
export type RunTensorboardQueryResult = ApolloReactCommon.QueryResult<RunTensorboardQuery, RunTensorboardQueryVariables>;
export const RunsModelDocument = gql`
    query RunsModel($cursor: String, $name: String!, $entityName: String, $jobKey: String, $order: String, $filters: JSONString, $limit: Int = 1000, $bucketIds: [String], $requestSubscribe: Boolean!, $selectEnable: Boolean = true, $basicEnable: Boolean = true, $history: Boolean = false, $historyKeyInfo: Boolean = false, $historySamples: Int = 750, $sampledHistory: Boolean = false, $fields: [String], $historyFields: [String], $sampledHistorySpecs: [JSONString!]!, $groupKeys: [String], $groupLevel: Int) {
  project(name: $name, entityName: $entityName) {
    id
    name
    createdAt
    entityName
    description
    summaryMetrics
    views
    requestSubscribe @include(if: $requestSubscribe)
    runCount(filters: $filters)
    runs(
      first: $limit
      after: $cursor
      jobKey: $jobKey
      order: $order
      names: $bucketIds
      filters: $filters
      fields: $fields
      historyFields: $historyFields
      groupKeys: $groupKeys
      groupLevel: $groupLevel
    ) {
      totalCount
      edges {
        node {
          id
          name
          displayName
          __typename
          ...SelectRunFragment @include(if: $selectEnable)
          ...BasicRunFragment @include(if: $basicEnable)
          ...HistoryRunFragment @include(if: $history)
          ...SampledHistoryRunFragment @include(if: $sampledHistory)
          historyKeys(format: PLAINTEXT) @include(if: $historyKeyInfo)
        }
      }
      pageInfo {
        startCursor
        hasPreviousPage
        endCursor
        hasNextPage
      }
    }
  }
  viewer {
    id
    email
    photoUrl
    admin
  }
  serverInfo {
    pollingOK
  }
}
    ${SelectRunFragmentFragmentDoc}
${BasicRunFragmentFragmentDoc}
${HistoryRunFragmentFragmentDoc}
${SampledHistoryRunFragmentFragmentDoc}`;
export type RunsModelComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunsModelQuery, RunsModelQueryVariables>, 'query'> & ({ variables: RunsModelQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunsModelComponent = (props: RunsModelComponentProps) => (
      <ApolloReactComponents.Query<RunsModelQuery, RunsModelQueryVariables> query={RunsModelDocument} {...props} />
    );
    
export type RunsModelProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunsModelQuery, RunsModelQueryVariables>
    } & TChildProps;
export function withRunsModel<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunsModelQuery,
  RunsModelQueryVariables,
  RunsModelProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunsModelQuery, RunsModelQueryVariables, RunsModelProps<TChildProps, TDataName>>(RunsModelDocument, {
      alias: 'runsModel',
      ...operationOptions
    });
};

/**
 * __useRunsModelQuery__
 *
 * To run a query within a React component, call `useRunsModelQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunsModelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunsModelQuery({
 *   variables: {
 *      cursor: // value for 'cursor'
 *      name: // value for 'name'
 *      entityName: // value for 'entityName'
 *      jobKey: // value for 'jobKey'
 *      order: // value for 'order'
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      bucketIds: // value for 'bucketIds'
 *      requestSubscribe: // value for 'requestSubscribe'
 *      selectEnable: // value for 'selectEnable'
 *      basicEnable: // value for 'basicEnable'
 *      history: // value for 'history'
 *      historyKeyInfo: // value for 'historyKeyInfo'
 *      historySamples: // value for 'historySamples'
 *      sampledHistory: // value for 'sampledHistory'
 *      fields: // value for 'fields'
 *      historyFields: // value for 'historyFields'
 *      sampledHistorySpecs: // value for 'sampledHistorySpecs'
 *      groupKeys: // value for 'groupKeys'
 *      groupLevel: // value for 'groupLevel'
 *   },
 * });
 */
export function useRunsModelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunsModelQuery, RunsModelQueryVariables>) {
        return ApolloReactHooks.useQuery<RunsModelQuery, RunsModelQueryVariables>(RunsModelDocument, baseOptions);
      }
export function useRunsModelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunsModelQuery, RunsModelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunsModelQuery, RunsModelQueryVariables>(RunsModelDocument, baseOptions);
        }
export type RunsModelQueryHookResult = ReturnType<typeof useRunsModelQuery>;
export type RunsModelLazyQueryHookResult = ReturnType<typeof useRunsModelLazyQuery>;
export type RunsModelQueryResult = ApolloReactCommon.QueryResult<RunsModelQuery, RunsModelQueryVariables>;
export const UpsertRunDocument = gql`
    mutation upsertRun($id: String, $displayName: String, $notes: String, $tags: [String!]) {
  upsertBucket(
    input: {id: $id, displayName: $displayName, notes: $notes, tags: $tags}
  ) {
    bucket {
      id
      name
      displayName
      notes
      tags: tagColors {
        id
        name
        colorIndex
      }
      project {
        id
        tags {
          id
          name
          colorIndex
        }
      }
    }
    inserted
  }
}
    `;
export type UpsertRunMutationFn = ApolloReactCommon.MutationFunction<UpsertRunMutation, UpsertRunMutationVariables>;
export type UpsertRunComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertRunMutation, UpsertRunMutationVariables>, 'mutation'>;

    export const UpsertRunComponent = (props: UpsertRunComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertRunMutation, UpsertRunMutationVariables> mutation={UpsertRunDocument} {...props} />
    );
    
export type UpsertRunProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpsertRunMutation, UpsertRunMutationVariables>
    } & TChildProps;
export function withUpsertRun<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpsertRunMutation,
  UpsertRunMutationVariables,
  UpsertRunProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpsertRunMutation, UpsertRunMutationVariables, UpsertRunProps<TChildProps, TDataName>>(UpsertRunDocument, {
      alias: 'upsertRun',
      ...operationOptions
    });
};

/**
 * __useUpsertRunMutation__
 *
 * To run a mutation, you first call `useUpsertRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertRunMutation, { data, loading, error }] = useUpsertRunMutation({
 *   variables: {
 *      id: // value for 'id'
 *      displayName: // value for 'displayName'
 *      notes: // value for 'notes'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useUpsertRunMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertRunMutation, UpsertRunMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertRunMutation, UpsertRunMutationVariables>(UpsertRunDocument, baseOptions);
      }
export type UpsertRunMutationHookResult = ReturnType<typeof useUpsertRunMutation>;
export type UpsertRunMutationResult = ApolloReactCommon.MutationResult<UpsertRunMutation>;
export type UpsertRunMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertRunMutation, UpsertRunMutationVariables>;
export const UpsertRunGroupDocument = gql`
    mutation upsertRunGroup($entityName: String!, $projectName: String!, $name: String!, $newName: String, $notes: String, $tags: [String!]) {
  upsertRunGroup(
    input: {projectName: $projectName, entityName: $entityName, name: $name, notes: $notes, newName: $newName, tags: $tags}
  ) {
    group {
      id
      name
      notes
      tags {
        id
        name
        colorIndex
      }
      project {
        id
        tags {
          id
          name
          colorIndex
        }
      }
    }
  }
}
    `;
export type UpsertRunGroupMutationFn = ApolloReactCommon.MutationFunction<UpsertRunGroupMutation, UpsertRunGroupMutationVariables>;
export type UpsertRunGroupComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertRunGroupMutation, UpsertRunGroupMutationVariables>, 'mutation'>;

    export const UpsertRunGroupComponent = (props: UpsertRunGroupComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertRunGroupMutation, UpsertRunGroupMutationVariables> mutation={UpsertRunGroupDocument} {...props} />
    );
    
export type UpsertRunGroupProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpsertRunGroupMutation, UpsertRunGroupMutationVariables>
    } & TChildProps;
export function withUpsertRunGroup<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpsertRunGroupMutation,
  UpsertRunGroupMutationVariables,
  UpsertRunGroupProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpsertRunGroupMutation, UpsertRunGroupMutationVariables, UpsertRunGroupProps<TChildProps, TDataName>>(UpsertRunGroupDocument, {
      alias: 'upsertRunGroup',
      ...operationOptions
    });
};

/**
 * __useUpsertRunGroupMutation__
 *
 * To run a mutation, you first call `useUpsertRunGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertRunGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertRunGroupMutation, { data, loading, error }] = useUpsertRunGroupMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      name: // value for 'name'
 *      newName: // value for 'newName'
 *      notes: // value for 'notes'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useUpsertRunGroupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertRunGroupMutation, UpsertRunGroupMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertRunGroupMutation, UpsertRunGroupMutationVariables>(UpsertRunGroupDocument, baseOptions);
      }
export type UpsertRunGroupMutationHookResult = ReturnType<typeof useUpsertRunGroupMutation>;
export type UpsertRunGroupMutationResult = ApolloReactCommon.MutationResult<UpsertRunGroupMutation>;
export type UpsertRunGroupMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertRunGroupMutation, UpsertRunGroupMutationVariables>;
export const ModifyRunsDocument = gql`
    mutation modifyRuns($filters: JSONString, $entityName: String, $projectName: String, $addTags: [String], $removeTags: [String]) {
  modifyRuns(
    input: {filters: $filters, entityName: $entityName, projectName: $projectName, addTags: $addTags, removeTags: $removeTags}
  ) {
    runsSQL {
      id
      name
      __typename
      ...SelectRunFragment
      ...BasicRunFragment
      user {
        id
        username
        photoUrl
      }
    }
  }
}
    ${SelectRunFragmentFragmentDoc}
${BasicRunFragmentFragmentDoc}`;
export type ModifyRunsMutationFn = ApolloReactCommon.MutationFunction<ModifyRunsMutation, ModifyRunsMutationVariables>;
export type ModifyRunsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ModifyRunsMutation, ModifyRunsMutationVariables>, 'mutation'>;

    export const ModifyRunsComponent = (props: ModifyRunsComponentProps) => (
      <ApolloReactComponents.Mutation<ModifyRunsMutation, ModifyRunsMutationVariables> mutation={ModifyRunsDocument} {...props} />
    );
    
export type ModifyRunsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ModifyRunsMutation, ModifyRunsMutationVariables>
    } & TChildProps;
export function withModifyRuns<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ModifyRunsMutation,
  ModifyRunsMutationVariables,
  ModifyRunsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ModifyRunsMutation, ModifyRunsMutationVariables, ModifyRunsProps<TChildProps, TDataName>>(ModifyRunsDocument, {
      alias: 'modifyRuns',
      ...operationOptions
    });
};

/**
 * __useModifyRunsMutation__
 *
 * To run a mutation, you first call `useModifyRunsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useModifyRunsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [modifyRunsMutation, { data, loading, error }] = useModifyRunsMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      addTags: // value for 'addTags'
 *      removeTags: // value for 'removeTags'
 *   },
 * });
 */
export function useModifyRunsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ModifyRunsMutation, ModifyRunsMutationVariables>) {
        return ApolloReactHooks.useMutation<ModifyRunsMutation, ModifyRunsMutationVariables>(ModifyRunsDocument, baseOptions);
      }
export type ModifyRunsMutationHookResult = ReturnType<typeof useModifyRunsMutation>;
export type ModifyRunsMutationResult = ApolloReactCommon.MutationResult<ModifyRunsMutation>;
export type ModifyRunsMutationOptions = ApolloReactCommon.BaseMutationOptions<ModifyRunsMutation, ModifyRunsMutationVariables>;
export const MoveRunsDocument = gql`
    mutation moveRuns($filters: JSONString!, $sourceEntityName: String!, $sourceProjectName: String!, $destinationEntityName: String!, $destinationProjectName: String!) {
  moveRuns(
    input: {filters: $filters, sourceEntityName: $sourceEntityName, sourceProjectName: $sourceProjectName, destinationEntityName: $destinationEntityName, destinationProjectName: $destinationProjectName}
  ) {
    task {
      id
    }
  }
}
    `;
export type MoveRunsMutationFn = ApolloReactCommon.MutationFunction<MoveRunsMutation, MoveRunsMutationVariables>;
export type MoveRunsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MoveRunsMutation, MoveRunsMutationVariables>, 'mutation'>;

    export const MoveRunsComponent = (props: MoveRunsComponentProps) => (
      <ApolloReactComponents.Mutation<MoveRunsMutation, MoveRunsMutationVariables> mutation={MoveRunsDocument} {...props} />
    );
    
export type MoveRunsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<MoveRunsMutation, MoveRunsMutationVariables>
    } & TChildProps;
export function withMoveRuns<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MoveRunsMutation,
  MoveRunsMutationVariables,
  MoveRunsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, MoveRunsMutation, MoveRunsMutationVariables, MoveRunsProps<TChildProps, TDataName>>(MoveRunsDocument, {
      alias: 'moveRuns',
      ...operationOptions
    });
};

/**
 * __useMoveRunsMutation__
 *
 * To run a mutation, you first call `useMoveRunsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveRunsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveRunsMutation, { data, loading, error }] = useMoveRunsMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *      sourceEntityName: // value for 'sourceEntityName'
 *      sourceProjectName: // value for 'sourceProjectName'
 *      destinationEntityName: // value for 'destinationEntityName'
 *      destinationProjectName: // value for 'destinationProjectName'
 *   },
 * });
 */
export function useMoveRunsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MoveRunsMutation, MoveRunsMutationVariables>) {
        return ApolloReactHooks.useMutation<MoveRunsMutation, MoveRunsMutationVariables>(MoveRunsDocument, baseOptions);
      }
export type MoveRunsMutationHookResult = ReturnType<typeof useMoveRunsMutation>;
export type MoveRunsMutationResult = ApolloReactCommon.MutationResult<MoveRunsMutation>;
export type MoveRunsMutationOptions = ApolloReactCommon.BaseMutationOptions<MoveRunsMutation, MoveRunsMutationVariables>;
export const DeleteRunsDocument = gql`
    mutation deleteRuns($filters: JSONString!, $entityName: String!, $projectName: String!, $deleteArtifacts: Boolean!) {
  deleteRuns(
    input: {filters: $filters, entityName: $entityName, projectName: $projectName, deleteArtifacts: $deleteArtifacts}
  ) {
    clientMutationId
    errors
  }
}
    `;
export type DeleteRunsMutationFn = ApolloReactCommon.MutationFunction<DeleteRunsMutation, DeleteRunsMutationVariables>;
export type DeleteRunsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteRunsMutation, DeleteRunsMutationVariables>, 'mutation'>;

    export const DeleteRunsComponent = (props: DeleteRunsComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteRunsMutation, DeleteRunsMutationVariables> mutation={DeleteRunsDocument} {...props} />
    );
    
export type DeleteRunsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteRunsMutation, DeleteRunsMutationVariables>
    } & TChildProps;
export function withDeleteRuns<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteRunsMutation,
  DeleteRunsMutationVariables,
  DeleteRunsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteRunsMutation, DeleteRunsMutationVariables, DeleteRunsProps<TChildProps, TDataName>>(DeleteRunsDocument, {
      alias: 'deleteRuns',
      ...operationOptions
    });
};

/**
 * __useDeleteRunsMutation__
 *
 * To run a mutation, you first call `useDeleteRunsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRunsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRunsMutation, { data, loading, error }] = useDeleteRunsMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      deleteArtifacts: // value for 'deleteArtifacts'
 *   },
 * });
 */
export function useDeleteRunsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRunsMutation, DeleteRunsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRunsMutation, DeleteRunsMutationVariables>(DeleteRunsDocument, baseOptions);
      }
export type DeleteRunsMutationHookResult = ReturnType<typeof useDeleteRunsMutation>;
export type DeleteRunsMutationResult = ApolloReactCommon.MutationResult<DeleteRunsMutation>;
export type DeleteRunsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRunsMutation, DeleteRunsMutationVariables>;
export const UndeleteRunsDocument = gql`
    mutation undeleteRuns($filters: JSONString!, $entityName: String!, $projectName: String!) {
  undeleteRuns(
    input: {filters: $filters, entityName: $entityName, projectName: $projectName}
  ) {
    clientMutationId
  }
}
    `;
export type UndeleteRunsMutationFn = ApolloReactCommon.MutationFunction<UndeleteRunsMutation, UndeleteRunsMutationVariables>;
export type UndeleteRunsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UndeleteRunsMutation, UndeleteRunsMutationVariables>, 'mutation'>;

    export const UndeleteRunsComponent = (props: UndeleteRunsComponentProps) => (
      <ApolloReactComponents.Mutation<UndeleteRunsMutation, UndeleteRunsMutationVariables> mutation={UndeleteRunsDocument} {...props} />
    );
    
export type UndeleteRunsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UndeleteRunsMutation, UndeleteRunsMutationVariables>
    } & TChildProps;
export function withUndeleteRuns<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UndeleteRunsMutation,
  UndeleteRunsMutationVariables,
  UndeleteRunsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UndeleteRunsMutation, UndeleteRunsMutationVariables, UndeleteRunsProps<TChildProps, TDataName>>(UndeleteRunsDocument, {
      alias: 'undeleteRuns',
      ...operationOptions
    });
};

/**
 * __useUndeleteRunsMutation__
 *
 * To run a mutation, you first call `useUndeleteRunsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndeleteRunsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undeleteRunsMutation, { data, loading, error }] = useUndeleteRunsMutation({
 *   variables: {
 *      filters: // value for 'filters'
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *   },
 * });
 */
export function useUndeleteRunsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UndeleteRunsMutation, UndeleteRunsMutationVariables>) {
        return ApolloReactHooks.useMutation<UndeleteRunsMutation, UndeleteRunsMutationVariables>(UndeleteRunsDocument, baseOptions);
      }
export type UndeleteRunsMutationHookResult = ReturnType<typeof useUndeleteRunsMutation>;
export type UndeleteRunsMutationResult = ApolloReactCommon.MutationResult<UndeleteRunsMutation>;
export type UndeleteRunsMutationOptions = ApolloReactCommon.BaseMutationOptions<UndeleteRunsMutation, UndeleteRunsMutationVariables>;
export const DeleteRunDocument = gql`
    mutation deleteRun($id: ID!, $deleteArtifacts: Boolean = false) {
  deleteRun(input: {id: $id, deleteArtifacts: $deleteArtifacts}) {
    clientMutationId
  }
}
    `;
export type DeleteRunMutationFn = ApolloReactCommon.MutationFunction<DeleteRunMutation, DeleteRunMutationVariables>;
export type DeleteRunComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteRunMutation, DeleteRunMutationVariables>, 'mutation'>;

    export const DeleteRunComponent = (props: DeleteRunComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteRunMutation, DeleteRunMutationVariables> mutation={DeleteRunDocument} {...props} />
    );
    
export type DeleteRunProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteRunMutation, DeleteRunMutationVariables>
    } & TChildProps;
export function withDeleteRun<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteRunMutation,
  DeleteRunMutationVariables,
  DeleteRunProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteRunMutation, DeleteRunMutationVariables, DeleteRunProps<TChildProps, TDataName>>(DeleteRunDocument, {
      alias: 'deleteRun',
      ...operationOptions
    });
};

/**
 * __useDeleteRunMutation__
 *
 * To run a mutation, you first call `useDeleteRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRunMutation, { data, loading, error }] = useDeleteRunMutation({
 *   variables: {
 *      id: // value for 'id'
 *      deleteArtifacts: // value for 'deleteArtifacts'
 *   },
 * });
 */
export function useDeleteRunMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRunMutation, DeleteRunMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteRunMutation, DeleteRunMutationVariables>(DeleteRunDocument, baseOptions);
      }
export type DeleteRunMutationHookResult = ReturnType<typeof useDeleteRunMutation>;
export type DeleteRunMutationResult = ApolloReactCommon.MutationResult<DeleteRunMutation>;
export type DeleteRunMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRunMutation, DeleteRunMutationVariables>;
export const StopRunDocument = gql`
    mutation stopRun($id: ID!) {
  stopRun(input: {id: $id}) {
    clientMutationId
  }
}
    `;
export type StopRunMutationFn = ApolloReactCommon.MutationFunction<StopRunMutation, StopRunMutationVariables>;
export type StopRunComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<StopRunMutation, StopRunMutationVariables>, 'mutation'>;

    export const StopRunComponent = (props: StopRunComponentProps) => (
      <ApolloReactComponents.Mutation<StopRunMutation, StopRunMutationVariables> mutation={StopRunDocument} {...props} />
    );
    
export type StopRunProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<StopRunMutation, StopRunMutationVariables>
    } & TChildProps;
export function withStopRun<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  StopRunMutation,
  StopRunMutationVariables,
  StopRunProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, StopRunMutation, StopRunMutationVariables, StopRunProps<TChildProps, TDataName>>(StopRunDocument, {
      alias: 'stopRun',
      ...operationOptions
    });
};

/**
 * __useStopRunMutation__
 *
 * To run a mutation, you first call `useStopRunMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopRunMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopRunMutation, { data, loading, error }] = useStopRunMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStopRunMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StopRunMutation, StopRunMutationVariables>) {
        return ApolloReactHooks.useMutation<StopRunMutation, StopRunMutationVariables>(StopRunDocument, baseOptions);
      }
export type StopRunMutationHookResult = ReturnType<typeof useStopRunMutation>;
export type StopRunMutationResult = ApolloReactCommon.MutationResult<StopRunMutation>;
export type StopRunMutationOptions = ApolloReactCommon.BaseMutationOptions<StopRunMutation, StopRunMutationVariables>;
export const RunStopDocument = gql`
    mutation runStop($id: ID!) {
  stopRun(input: {id: $id}) {
    success
  }
}
    `;
export type RunStopMutationFn = ApolloReactCommon.MutationFunction<RunStopMutation, RunStopMutationVariables>;
export type RunStopComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RunStopMutation, RunStopMutationVariables>, 'mutation'>;

    export const RunStopComponent = (props: RunStopComponentProps) => (
      <ApolloReactComponents.Mutation<RunStopMutation, RunStopMutationVariables> mutation={RunStopDocument} {...props} />
    );
    
export type RunStopProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RunStopMutation, RunStopMutationVariables>
    } & TChildProps;
export function withRunStop<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunStopMutation,
  RunStopMutationVariables,
  RunStopProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RunStopMutation, RunStopMutationVariables, RunStopProps<TChildProps, TDataName>>(RunStopDocument, {
      alias: 'runStop',
      ...operationOptions
    });
};

/**
 * __useRunStopMutation__
 *
 * To run a mutation, you first call `useRunStopMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRunStopMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [runStopMutation, { data, loading, error }] = useRunStopMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRunStopMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RunStopMutation, RunStopMutationVariables>) {
        return ApolloReactHooks.useMutation<RunStopMutation, RunStopMutationVariables>(RunStopDocument, baseOptions);
      }
export type RunStopMutationHookResult = ReturnType<typeof useRunStopMutation>;
export type RunStopMutationResult = ApolloReactCommon.MutationResult<RunStopMutation>;
export type RunStopMutationOptions = ApolloReactCommon.BaseMutationOptions<RunStopMutation, RunStopMutationVariables>;
export const DeleteFilesDocument = gql`
    mutation deleteFiles($ids: [ID!]!, $projectId: Int!) {
  deleteFiles(input: {files: $ids, projectId: $projectId}) {
    clientMutationId
    success
  }
}
    `;
export type DeleteFilesMutationFn = ApolloReactCommon.MutationFunction<DeleteFilesMutation, DeleteFilesMutationVariables>;
export type DeleteFilesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteFilesMutation, DeleteFilesMutationVariables>, 'mutation'>;

    export const DeleteFilesComponent = (props: DeleteFilesComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteFilesMutation, DeleteFilesMutationVariables> mutation={DeleteFilesDocument} {...props} />
    );
    
export type DeleteFilesProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteFilesMutation, DeleteFilesMutationVariables>
    } & TChildProps;
export function withDeleteFiles<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteFilesMutation,
  DeleteFilesMutationVariables,
  DeleteFilesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteFilesMutation, DeleteFilesMutationVariables, DeleteFilesProps<TChildProps, TDataName>>(DeleteFilesDocument, {
      alias: 'deleteFiles',
      ...operationOptions
    });
};

/**
 * __useDeleteFilesMutation__
 *
 * To run a mutation, you first call `useDeleteFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFilesMutation, { data, loading, error }] = useDeleteFilesMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      projectId: // value for 'projectId'
 *   },
 * });
 */
export function useDeleteFilesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFilesMutation, DeleteFilesMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteFilesMutation, DeleteFilesMutationVariables>(DeleteFilesDocument, baseOptions);
      }
export type DeleteFilesMutationHookResult = ReturnType<typeof useDeleteFilesMutation>;
export type DeleteFilesMutationResult = ApolloReactCommon.MutationResult<DeleteFilesMutation>;
export type DeleteFilesMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFilesMutation, DeleteFilesMutationVariables>;
export const DeleteFilesByRunAndFolderDocument = gql`
    mutation deleteFilesByRunAndFolder($runName: String!, $projectId: Int!, $folders: [String!]!) {
  deleteFilesByRunAndFolder(
    input: {runName: $runName, projectId: $projectId, folders: $folders}
  ) {
    clientMutationId
    success
  }
}
    `;
export type DeleteFilesByRunAndFolderMutationFn = ApolloReactCommon.MutationFunction<DeleteFilesByRunAndFolderMutation, DeleteFilesByRunAndFolderMutationVariables>;
export type DeleteFilesByRunAndFolderComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteFilesByRunAndFolderMutation, DeleteFilesByRunAndFolderMutationVariables>, 'mutation'>;

    export const DeleteFilesByRunAndFolderComponent = (props: DeleteFilesByRunAndFolderComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteFilesByRunAndFolderMutation, DeleteFilesByRunAndFolderMutationVariables> mutation={DeleteFilesByRunAndFolderDocument} {...props} />
    );
    
export type DeleteFilesByRunAndFolderProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteFilesByRunAndFolderMutation, DeleteFilesByRunAndFolderMutationVariables>
    } & TChildProps;
export function withDeleteFilesByRunAndFolder<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteFilesByRunAndFolderMutation,
  DeleteFilesByRunAndFolderMutationVariables,
  DeleteFilesByRunAndFolderProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteFilesByRunAndFolderMutation, DeleteFilesByRunAndFolderMutationVariables, DeleteFilesByRunAndFolderProps<TChildProps, TDataName>>(DeleteFilesByRunAndFolderDocument, {
      alias: 'deleteFilesByRunAndFolder',
      ...operationOptions
    });
};

/**
 * __useDeleteFilesByRunAndFolderMutation__
 *
 * To run a mutation, you first call `useDeleteFilesByRunAndFolderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteFilesByRunAndFolderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteFilesByRunAndFolderMutation, { data, loading, error }] = useDeleteFilesByRunAndFolderMutation({
 *   variables: {
 *      runName: // value for 'runName'
 *      projectId: // value for 'projectId'
 *      folders: // value for 'folders'
 *   },
 * });
 */
export function useDeleteFilesByRunAndFolderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteFilesByRunAndFolderMutation, DeleteFilesByRunAndFolderMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteFilesByRunAndFolderMutation, DeleteFilesByRunAndFolderMutationVariables>(DeleteFilesByRunAndFolderDocument, baseOptions);
      }
export type DeleteFilesByRunAndFolderMutationHookResult = ReturnType<typeof useDeleteFilesByRunAndFolderMutation>;
export type DeleteFilesByRunAndFolderMutationResult = ApolloReactCommon.MutationResult<DeleteFilesByRunAndFolderMutation>;
export type DeleteFilesByRunAndFolderMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteFilesByRunAndFolderMutation, DeleteFilesByRunAndFolderMutationVariables>;
export const InsertSecretDocument = gql`
    mutation insertSecret($entityName: String!, $secretName: String!, $secretValue: String!) {
  insertSecret(
    input: {entityName: $entityName, secretName: $secretName, secretValue: $secretValue}
  ) {
    success
  }
}
    `;
export type InsertSecretMutationFn = ApolloReactCommon.MutationFunction<InsertSecretMutation, InsertSecretMutationVariables>;
export type InsertSecretComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertSecretMutation, InsertSecretMutationVariables>, 'mutation'>;

    export const InsertSecretComponent = (props: InsertSecretComponentProps) => (
      <ApolloReactComponents.Mutation<InsertSecretMutation, InsertSecretMutationVariables> mutation={InsertSecretDocument} {...props} />
    );
    
export type InsertSecretProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<InsertSecretMutation, InsertSecretMutationVariables>
    } & TChildProps;
export function withInsertSecret<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InsertSecretMutation,
  InsertSecretMutationVariables,
  InsertSecretProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, InsertSecretMutation, InsertSecretMutationVariables, InsertSecretProps<TChildProps, TDataName>>(InsertSecretDocument, {
      alias: 'insertSecret',
      ...operationOptions
    });
};

/**
 * __useInsertSecretMutation__
 *
 * To run a mutation, you first call `useInsertSecretMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertSecretMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertSecretMutation, { data, loading, error }] = useInsertSecretMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      secretName: // value for 'secretName'
 *      secretValue: // value for 'secretValue'
 *   },
 * });
 */
export function useInsertSecretMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertSecretMutation, InsertSecretMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertSecretMutation, InsertSecretMutationVariables>(InsertSecretDocument, baseOptions);
      }
export type InsertSecretMutationHookResult = ReturnType<typeof useInsertSecretMutation>;
export type InsertSecretMutationResult = ApolloReactCommon.MutationResult<InsertSecretMutation>;
export type InsertSecretMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertSecretMutation, InsertSecretMutationVariables>;
export const DeleteSecretDocument = gql`
    mutation deleteSecret($entityName: String!, $secretName: String!) {
  deleteSecret(input: {entityName: $entityName, secretName: $secretName}) {
    success
  }
}
    `;
export type DeleteSecretMutationFn = ApolloReactCommon.MutationFunction<DeleteSecretMutation, DeleteSecretMutationVariables>;
export type DeleteSecretComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteSecretMutation, DeleteSecretMutationVariables>, 'mutation'>;

    export const DeleteSecretComponent = (props: DeleteSecretComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteSecretMutation, DeleteSecretMutationVariables> mutation={DeleteSecretDocument} {...props} />
    );
    
export type DeleteSecretProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteSecretMutation, DeleteSecretMutationVariables>
    } & TChildProps;
export function withDeleteSecret<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteSecretMutation,
  DeleteSecretMutationVariables,
  DeleteSecretProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteSecretMutation, DeleteSecretMutationVariables, DeleteSecretProps<TChildProps, TDataName>>(DeleteSecretDocument, {
      alias: 'deleteSecret',
      ...operationOptions
    });
};

/**
 * __useDeleteSecretMutation__
 *
 * To run a mutation, you first call `useDeleteSecretMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSecretMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSecretMutation, { data, loading, error }] = useDeleteSecretMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      secretName: // value for 'secretName'
 *   },
 * });
 */
export function useDeleteSecretMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSecretMutation, DeleteSecretMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSecretMutation, DeleteSecretMutationVariables>(DeleteSecretDocument, baseOptions);
      }
export type DeleteSecretMutationHookResult = ReturnType<typeof useDeleteSecretMutation>;
export type DeleteSecretMutationResult = ApolloReactCommon.MutationResult<DeleteSecretMutation>;
export type DeleteSecretMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSecretMutation, DeleteSecretMutationVariables>;
export const SecretsDocument = gql`
    query secrets($entityName: String!) {
  entity(name: $entityName) {
    id
    secrets {
      entityId
      name
      createdAt
    }
  }
}
    `;
export type SecretsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SecretsQuery, SecretsQueryVariables>, 'query'> & ({ variables: SecretsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SecretsComponent = (props: SecretsComponentProps) => (
      <ApolloReactComponents.Query<SecretsQuery, SecretsQueryVariables> query={SecretsDocument} {...props} />
    );
    
export type SecretsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SecretsQuery, SecretsQueryVariables>
    } & TChildProps;
export function withSecrets<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SecretsQuery,
  SecretsQueryVariables,
  SecretsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SecretsQuery, SecretsQueryVariables, SecretsProps<TChildProps, TDataName>>(SecretsDocument, {
      alias: 'secrets',
      ...operationOptions
    });
};

/**
 * __useSecretsQuery__
 *
 * To run a query within a React component, call `useSecretsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecretsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecretsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useSecretsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SecretsQuery, SecretsQueryVariables>) {
        return ApolloReactHooks.useQuery<SecretsQuery, SecretsQueryVariables>(SecretsDocument, baseOptions);
      }
export function useSecretsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SecretsQuery, SecretsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SecretsQuery, SecretsQueryVariables>(SecretsDocument, baseOptions);
        }
export type SecretsQueryHookResult = ReturnType<typeof useSecretsQuery>;
export type SecretsLazyQueryHookResult = ReturnType<typeof useSecretsLazyQuery>;
export type SecretsQueryResult = ApolloReactCommon.QueryResult<SecretsQuery, SecretsQueryVariables>;
export const ServerInfoDocument = gql`
    query ServerInfo {
  serverInfo {
    availableCloudRegions {
      id
      provider
      region
    }
    isGoogleIap
    slackClientID
  }
}
    `;
export type ServerInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ServerInfoQuery, ServerInfoQueryVariables>, 'query'>;

    export const ServerInfoComponent = (props: ServerInfoComponentProps) => (
      <ApolloReactComponents.Query<ServerInfoQuery, ServerInfoQueryVariables> query={ServerInfoDocument} {...props} />
    );
    
export type ServerInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ServerInfoQuery, ServerInfoQueryVariables>
    } & TChildProps;
export function withServerInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ServerInfoQuery,
  ServerInfoQueryVariables,
  ServerInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ServerInfoQuery, ServerInfoQueryVariables, ServerInfoProps<TChildProps, TDataName>>(ServerInfoDocument, {
      alias: 'serverInfo',
      ...operationOptions
    });
};

/**
 * __useServerInfoQuery__
 *
 * To run a query within a React component, call `useServerInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useServerInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServerInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useServerInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ServerInfoQuery, ServerInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<ServerInfoQuery, ServerInfoQueryVariables>(ServerInfoDocument, baseOptions);
      }
export function useServerInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ServerInfoQuery, ServerInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ServerInfoQuery, ServerInfoQueryVariables>(ServerInfoDocument, baseOptions);
        }
export type ServerInfoQueryHookResult = ReturnType<typeof useServerInfoQuery>;
export type ServerInfoLazyQueryHookResult = ReturnType<typeof useServerInfoLazyQuery>;
export type ServerInfoQueryResult = ApolloReactCommon.QueryResult<ServerInfoQuery, ServerInfoQueryVariables>;
export const LicenseExpirationUiDocument = gql`
    query LicenseExpirationUI {
  serverInfo {
    licenseExpirationUI {
      bannerTotalDays
      bannerDaysRemaining
      renewUrl
    }
  }
}
    `;
export type LicenseExpirationUiComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<LicenseExpirationUiQuery, LicenseExpirationUiQueryVariables>, 'query'>;

    export const LicenseExpirationUiComponent = (props: LicenseExpirationUiComponentProps) => (
      <ApolloReactComponents.Query<LicenseExpirationUiQuery, LicenseExpirationUiQueryVariables> query={LicenseExpirationUiDocument} {...props} />
    );
    
export type LicenseExpirationUiProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<LicenseExpirationUiQuery, LicenseExpirationUiQueryVariables>
    } & TChildProps;
export function withLicenseExpirationUi<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LicenseExpirationUiQuery,
  LicenseExpirationUiQueryVariables,
  LicenseExpirationUiProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, LicenseExpirationUiQuery, LicenseExpirationUiQueryVariables, LicenseExpirationUiProps<TChildProps, TDataName>>(LicenseExpirationUiDocument, {
      alias: 'licenseExpirationUi',
      ...operationOptions
    });
};

/**
 * __useLicenseExpirationUiQuery__
 *
 * To run a query within a React component, call `useLicenseExpirationUiQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicenseExpirationUiQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicenseExpirationUiQuery({
 *   variables: {
 *   },
 * });
 */
export function useLicenseExpirationUiQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<LicenseExpirationUiQuery, LicenseExpirationUiQueryVariables>) {
        return ApolloReactHooks.useQuery<LicenseExpirationUiQuery, LicenseExpirationUiQueryVariables>(LicenseExpirationUiDocument, baseOptions);
      }
export function useLicenseExpirationUiLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<LicenseExpirationUiQuery, LicenseExpirationUiQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<LicenseExpirationUiQuery, LicenseExpirationUiQueryVariables>(LicenseExpirationUiDocument, baseOptions);
        }
export type LicenseExpirationUiQueryHookResult = ReturnType<typeof useLicenseExpirationUiQuery>;
export type LicenseExpirationUiLazyQueryHookResult = ReturnType<typeof useLicenseExpirationUiLazyQuery>;
export type LicenseExpirationUiQueryResult = ApolloReactCommon.QueryResult<LicenseExpirationUiQuery, LicenseExpirationUiQueryVariables>;
export const UserLimitReachedDocument = gql`
    query UserLimitReached {
  serverInfo {
    userLimitReached
    viewOnlyUserLimitReached
    licenseFlags
  }
}
    `;
export type UserLimitReachedComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserLimitReachedQuery, UserLimitReachedQueryVariables>, 'query'>;

    export const UserLimitReachedComponent = (props: UserLimitReachedComponentProps) => (
      <ApolloReactComponents.Query<UserLimitReachedQuery, UserLimitReachedQueryVariables> query={UserLimitReachedDocument} {...props} />
    );
    
export type UserLimitReachedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UserLimitReachedQuery, UserLimitReachedQueryVariables>
    } & TChildProps;
export function withUserLimitReached<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserLimitReachedQuery,
  UserLimitReachedQueryVariables,
  UserLimitReachedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, UserLimitReachedQuery, UserLimitReachedQueryVariables, UserLimitReachedProps<TChildProps, TDataName>>(UserLimitReachedDocument, {
      alias: 'userLimitReached',
      ...operationOptions
    });
};

/**
 * __useUserLimitReachedQuery__
 *
 * To run a query within a React component, call `useUserLimitReachedQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserLimitReachedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserLimitReachedQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserLimitReachedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserLimitReachedQuery, UserLimitReachedQueryVariables>) {
        return ApolloReactHooks.useQuery<UserLimitReachedQuery, UserLimitReachedQueryVariables>(UserLimitReachedDocument, baseOptions);
      }
export function useUserLimitReachedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserLimitReachedQuery, UserLimitReachedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserLimitReachedQuery, UserLimitReachedQueryVariables>(UserLimitReachedDocument, baseOptions);
        }
export type UserLimitReachedQueryHookResult = ReturnType<typeof useUserLimitReachedQuery>;
export type UserLimitReachedLazyQueryHookResult = ReturnType<typeof useUserLimitReachedLazyQuery>;
export type UserLimitReachedQueryResult = ApolloReactCommon.QueryResult<UserLimitReachedQuery, UserLimitReachedQueryVariables>;
export const ViewOnlyUserLimitReachedDocument = gql`
    query ViewOnlyUserLimitReached {
  serverInfo {
    viewOnlyUserLimitReached
  }
}
    `;
export type ViewOnlyUserLimitReachedComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewOnlyUserLimitReachedQuery, ViewOnlyUserLimitReachedQueryVariables>, 'query'>;

    export const ViewOnlyUserLimitReachedComponent = (props: ViewOnlyUserLimitReachedComponentProps) => (
      <ApolloReactComponents.Query<ViewOnlyUserLimitReachedQuery, ViewOnlyUserLimitReachedQueryVariables> query={ViewOnlyUserLimitReachedDocument} {...props} />
    );
    
export type ViewOnlyUserLimitReachedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewOnlyUserLimitReachedQuery, ViewOnlyUserLimitReachedQueryVariables>
    } & TChildProps;
export function withViewOnlyUserLimitReached<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewOnlyUserLimitReachedQuery,
  ViewOnlyUserLimitReachedQueryVariables,
  ViewOnlyUserLimitReachedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewOnlyUserLimitReachedQuery, ViewOnlyUserLimitReachedQueryVariables, ViewOnlyUserLimitReachedProps<TChildProps, TDataName>>(ViewOnlyUserLimitReachedDocument, {
      alias: 'viewOnlyUserLimitReached',
      ...operationOptions
    });
};

/**
 * __useViewOnlyUserLimitReachedQuery__
 *
 * To run a query within a React component, call `useViewOnlyUserLimitReachedQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewOnlyUserLimitReachedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewOnlyUserLimitReachedQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewOnlyUserLimitReachedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewOnlyUserLimitReachedQuery, ViewOnlyUserLimitReachedQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewOnlyUserLimitReachedQuery, ViewOnlyUserLimitReachedQueryVariables>(ViewOnlyUserLimitReachedDocument, baseOptions);
      }
export function useViewOnlyUserLimitReachedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewOnlyUserLimitReachedQuery, ViewOnlyUserLimitReachedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewOnlyUserLimitReachedQuery, ViewOnlyUserLimitReachedQueryVariables>(ViewOnlyUserLimitReachedDocument, baseOptions);
        }
export type ViewOnlyUserLimitReachedQueryHookResult = ReturnType<typeof useViewOnlyUserLimitReachedQuery>;
export type ViewOnlyUserLimitReachedLazyQueryHookResult = ReturnType<typeof useViewOnlyUserLimitReachedLazyQuery>;
export type ViewOnlyUserLimitReachedQueryResult = ApolloReactCommon.QueryResult<ViewOnlyUserLimitReachedQuery, ViewOnlyUserLimitReachedQueryVariables>;
export const RegisteredModelLimitReachedDocument = gql`
    query RegisteredModelLimitReached($entityName: String!) {
  serverInfo {
    registeredModelLimitReached(entityName: $entityName)
  }
}
    `;
export type RegisteredModelLimitReachedComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RegisteredModelLimitReachedQuery, RegisteredModelLimitReachedQueryVariables>, 'query'> & ({ variables: RegisteredModelLimitReachedQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RegisteredModelLimitReachedComponent = (props: RegisteredModelLimitReachedComponentProps) => (
      <ApolloReactComponents.Query<RegisteredModelLimitReachedQuery, RegisteredModelLimitReachedQueryVariables> query={RegisteredModelLimitReachedDocument} {...props} />
    );
    
export type RegisteredModelLimitReachedProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RegisteredModelLimitReachedQuery, RegisteredModelLimitReachedQueryVariables>
    } & TChildProps;
export function withRegisteredModelLimitReached<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RegisteredModelLimitReachedQuery,
  RegisteredModelLimitReachedQueryVariables,
  RegisteredModelLimitReachedProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RegisteredModelLimitReachedQuery, RegisteredModelLimitReachedQueryVariables, RegisteredModelLimitReachedProps<TChildProps, TDataName>>(RegisteredModelLimitReachedDocument, {
      alias: 'registeredModelLimitReached',
      ...operationOptions
    });
};

/**
 * __useRegisteredModelLimitReachedQuery__
 *
 * To run a query within a React component, call `useRegisteredModelLimitReachedQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisteredModelLimitReachedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisteredModelLimitReachedQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useRegisteredModelLimitReachedQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RegisteredModelLimitReachedQuery, RegisteredModelLimitReachedQueryVariables>) {
        return ApolloReactHooks.useQuery<RegisteredModelLimitReachedQuery, RegisteredModelLimitReachedQueryVariables>(RegisteredModelLimitReachedDocument, baseOptions);
      }
export function useRegisteredModelLimitReachedLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RegisteredModelLimitReachedQuery, RegisteredModelLimitReachedQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RegisteredModelLimitReachedQuery, RegisteredModelLimitReachedQueryVariables>(RegisteredModelLimitReachedDocument, baseOptions);
        }
export type RegisteredModelLimitReachedQueryHookResult = ReturnType<typeof useRegisteredModelLimitReachedQuery>;
export type RegisteredModelLimitReachedLazyQueryHookResult = ReturnType<typeof useRegisteredModelLimitReachedLazyQuery>;
export type RegisteredModelLimitReachedQueryResult = ApolloReactCommon.QueryResult<RegisteredModelLimitReachedQuery, RegisteredModelLimitReachedQueryVariables>;
export const ActionHistoryLimitDocument = gql`
    query ActionHistoryLimit($entityName: String, $artifactCollectionID: ID) {
  serverInfo {
    actionHistoryLimit(
      entityName: $entityName
      artifactCollectionID: $artifactCollectionID
    )
  }
}
    `;
export type ActionHistoryLimitComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ActionHistoryLimitQuery, ActionHistoryLimitQueryVariables>, 'query'>;

    export const ActionHistoryLimitComponent = (props: ActionHistoryLimitComponentProps) => (
      <ApolloReactComponents.Query<ActionHistoryLimitQuery, ActionHistoryLimitQueryVariables> query={ActionHistoryLimitDocument} {...props} />
    );
    
export type ActionHistoryLimitProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ActionHistoryLimitQuery, ActionHistoryLimitQueryVariables>
    } & TChildProps;
export function withActionHistoryLimit<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ActionHistoryLimitQuery,
  ActionHistoryLimitQueryVariables,
  ActionHistoryLimitProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ActionHistoryLimitQuery, ActionHistoryLimitQueryVariables, ActionHistoryLimitProps<TChildProps, TDataName>>(ActionHistoryLimitDocument, {
      alias: 'actionHistoryLimit',
      ...operationOptions
    });
};

/**
 * __useActionHistoryLimitQuery__
 *
 * To run a query within a React component, call `useActionHistoryLimitQuery` and pass it any options that fit your needs.
 * When your component renders, `useActionHistoryLimitQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActionHistoryLimitQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      artifactCollectionID: // value for 'artifactCollectionID'
 *   },
 * });
 */
export function useActionHistoryLimitQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ActionHistoryLimitQuery, ActionHistoryLimitQueryVariables>) {
        return ApolloReactHooks.useQuery<ActionHistoryLimitQuery, ActionHistoryLimitQueryVariables>(ActionHistoryLimitDocument, baseOptions);
      }
export function useActionHistoryLimitLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ActionHistoryLimitQuery, ActionHistoryLimitQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ActionHistoryLimitQuery, ActionHistoryLimitQueryVariables>(ActionHistoryLimitDocument, baseOptions);
        }
export type ActionHistoryLimitQueryHookResult = ReturnType<typeof useActionHistoryLimitQuery>;
export type ActionHistoryLimitLazyQueryHookResult = ReturnType<typeof useActionHistoryLimitLazyQuery>;
export type ActionHistoryLimitQueryResult = ApolloReactCommon.QueryResult<ActionHistoryLimitQuery, ActionHistoryLimitQueryVariables>;
export const FrontendHostDocument = gql`
    query FrontendHost {
  serverInfo {
    frontendHost
  }
}
    `;
export type FrontendHostComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FrontendHostQuery, FrontendHostQueryVariables>, 'query'>;

    export const FrontendHostComponent = (props: FrontendHostComponentProps) => (
      <ApolloReactComponents.Query<FrontendHostQuery, FrontendHostQueryVariables> query={FrontendHostDocument} {...props} />
    );
    
export type FrontendHostProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FrontendHostQuery, FrontendHostQueryVariables>
    } & TChildProps;
export function withFrontendHost<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FrontendHostQuery,
  FrontendHostQueryVariables,
  FrontendHostProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FrontendHostQuery, FrontendHostQueryVariables, FrontendHostProps<TChildProps, TDataName>>(FrontendHostDocument, {
      alias: 'frontendHost',
      ...operationOptions
    });
};

/**
 * __useFrontendHostQuery__
 *
 * To run a query within a React component, call `useFrontendHostQuery` and pass it any options that fit your needs.
 * When your component renders, `useFrontendHostQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFrontendHostQuery({
 *   variables: {
 *   },
 * });
 */
export function useFrontendHostQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FrontendHostQuery, FrontendHostQueryVariables>) {
        return ApolloReactHooks.useQuery<FrontendHostQuery, FrontendHostQueryVariables>(FrontendHostDocument, baseOptions);
      }
export function useFrontendHostLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FrontendHostQuery, FrontendHostQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FrontendHostQuery, FrontendHostQueryVariables>(FrontendHostDocument, baseOptions);
        }
export type FrontendHostQueryHookResult = ReturnType<typeof useFrontendHostQuery>;
export type FrontendHostLazyQueryHookResult = ReturnType<typeof useFrontendHostLazyQuery>;
export type FrontendHostQueryResult = ApolloReactCommon.QueryResult<FrontendHostQuery, FrontendHostQueryVariables>;
export const DeploymentIdDocument = gql`
    query DeploymentID {
  serverInfo {
    deploymentId
  }
}
    `;
export type DeploymentIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DeploymentIdQuery, DeploymentIdQueryVariables>, 'query'>;

    export const DeploymentIdComponent = (props: DeploymentIdComponentProps) => (
      <ApolloReactComponents.Query<DeploymentIdQuery, DeploymentIdQueryVariables> query={DeploymentIdDocument} {...props} />
    );
    
export type DeploymentIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<DeploymentIdQuery, DeploymentIdQueryVariables>
    } & TChildProps;
export function withDeploymentId<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeploymentIdQuery,
  DeploymentIdQueryVariables,
  DeploymentIdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, DeploymentIdQuery, DeploymentIdQueryVariables, DeploymentIdProps<TChildProps, TDataName>>(DeploymentIdDocument, {
      alias: 'deploymentId',
      ...operationOptions
    });
};

/**
 * __useDeploymentIdQuery__
 *
 * To run a query within a React component, call `useDeploymentIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useDeploymentIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDeploymentIdQuery({
 *   variables: {
 *   },
 * });
 */
export function useDeploymentIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DeploymentIdQuery, DeploymentIdQueryVariables>) {
        return ApolloReactHooks.useQuery<DeploymentIdQuery, DeploymentIdQueryVariables>(DeploymentIdDocument, baseOptions);
      }
export function useDeploymentIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DeploymentIdQuery, DeploymentIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DeploymentIdQuery, DeploymentIdQueryVariables>(DeploymentIdDocument, baseOptions);
        }
export type DeploymentIdQueryHookResult = ReturnType<typeof useDeploymentIdQuery>;
export type DeploymentIdLazyQueryHookResult = ReturnType<typeof useDeploymentIdLazyQuery>;
export type DeploymentIdQueryResult = ApolloReactCommon.QueryResult<DeploymentIdQuery, DeploymentIdQueryVariables>;
export const SecureStorageConnectorEnabledDocument = gql`
    query SecureStorageConnectorEnabled {
  serverInfo {
    secureStorageConnectorEnabled
  }
}
    `;
export type SecureStorageConnectorEnabledComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SecureStorageConnectorEnabledQuery, SecureStorageConnectorEnabledQueryVariables>, 'query'>;

    export const SecureStorageConnectorEnabledComponent = (props: SecureStorageConnectorEnabledComponentProps) => (
      <ApolloReactComponents.Query<SecureStorageConnectorEnabledQuery, SecureStorageConnectorEnabledQueryVariables> query={SecureStorageConnectorEnabledDocument} {...props} />
    );
    
export type SecureStorageConnectorEnabledProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SecureStorageConnectorEnabledQuery, SecureStorageConnectorEnabledQueryVariables>
    } & TChildProps;
export function withSecureStorageConnectorEnabled<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SecureStorageConnectorEnabledQuery,
  SecureStorageConnectorEnabledQueryVariables,
  SecureStorageConnectorEnabledProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SecureStorageConnectorEnabledQuery, SecureStorageConnectorEnabledQueryVariables, SecureStorageConnectorEnabledProps<TChildProps, TDataName>>(SecureStorageConnectorEnabledDocument, {
      alias: 'secureStorageConnectorEnabled',
      ...operationOptions
    });
};

/**
 * __useSecureStorageConnectorEnabledQuery__
 *
 * To run a query within a React component, call `useSecureStorageConnectorEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useSecureStorageConnectorEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSecureStorageConnectorEnabledQuery({
 *   variables: {
 *   },
 * });
 */
export function useSecureStorageConnectorEnabledQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SecureStorageConnectorEnabledQuery, SecureStorageConnectorEnabledQueryVariables>) {
        return ApolloReactHooks.useQuery<SecureStorageConnectorEnabledQuery, SecureStorageConnectorEnabledQueryVariables>(SecureStorageConnectorEnabledDocument, baseOptions);
      }
export function useSecureStorageConnectorEnabledLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SecureStorageConnectorEnabledQuery, SecureStorageConnectorEnabledQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SecureStorageConnectorEnabledQuery, SecureStorageConnectorEnabledQueryVariables>(SecureStorageConnectorEnabledDocument, baseOptions);
        }
export type SecureStorageConnectorEnabledQueryHookResult = ReturnType<typeof useSecureStorageConnectorEnabledQuery>;
export type SecureStorageConnectorEnabledLazyQueryHookResult = ReturnType<typeof useSecureStorageConnectorEnabledLazyQuery>;
export type SecureStorageConnectorEnabledQueryResult = ApolloReactCommon.QueryResult<SecureStorageConnectorEnabledQuery, SecureStorageConnectorEnabledQueryVariables>;
export const AvailableBucketProvidersDocument = gql`
    query AvailableBucketProviders {
  serverInfo {
    availableBucketProviders
  }
}
    `;
export type AvailableBucketProvidersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AvailableBucketProvidersQuery, AvailableBucketProvidersQueryVariables>, 'query'>;

    export const AvailableBucketProvidersComponent = (props: AvailableBucketProvidersComponentProps) => (
      <ApolloReactComponents.Query<AvailableBucketProvidersQuery, AvailableBucketProvidersQueryVariables> query={AvailableBucketProvidersDocument} {...props} />
    );
    
export type AvailableBucketProvidersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AvailableBucketProvidersQuery, AvailableBucketProvidersQueryVariables>
    } & TChildProps;
export function withAvailableBucketProviders<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AvailableBucketProvidersQuery,
  AvailableBucketProvidersQueryVariables,
  AvailableBucketProvidersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AvailableBucketProvidersQuery, AvailableBucketProvidersQueryVariables, AvailableBucketProvidersProps<TChildProps, TDataName>>(AvailableBucketProvidersDocument, {
      alias: 'availableBucketProviders',
      ...operationOptions
    });
};

/**
 * __useAvailableBucketProvidersQuery__
 *
 * To run a query within a React component, call `useAvailableBucketProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableBucketProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableBucketProvidersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAvailableBucketProvidersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AvailableBucketProvidersQuery, AvailableBucketProvidersQueryVariables>) {
        return ApolloReactHooks.useQuery<AvailableBucketProvidersQuery, AvailableBucketProvidersQueryVariables>(AvailableBucketProvidersDocument, baseOptions);
      }
export function useAvailableBucketProvidersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AvailableBucketProvidersQuery, AvailableBucketProvidersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AvailableBucketProvidersQuery, AvailableBucketProvidersQueryVariables>(AvailableBucketProvidersDocument, baseOptions);
        }
export type AvailableBucketProvidersQueryHookResult = ReturnType<typeof useAvailableBucketProvidersQuery>;
export type AvailableBucketProvidersLazyQueryHookResult = ReturnType<typeof useAvailableBucketProvidersLazyQuery>;
export type AvailableBucketProvidersQueryResult = ApolloReactCommon.QueryResult<AvailableBucketProvidersQuery, AvailableBucketProvidersQueryVariables>;
export const DataRetentionDurationDocument = gql`
    query DataRetentionDuration {
  serverInfo {
    dataRetentionDuration
  }
}
    `;
export type DataRetentionDurationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DataRetentionDurationQuery, DataRetentionDurationQueryVariables>, 'query'>;

    export const DataRetentionDurationComponent = (props: DataRetentionDurationComponentProps) => (
      <ApolloReactComponents.Query<DataRetentionDurationQuery, DataRetentionDurationQueryVariables> query={DataRetentionDurationDocument} {...props} />
    );
    
export type DataRetentionDurationProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<DataRetentionDurationQuery, DataRetentionDurationQueryVariables>
    } & TChildProps;
export function withDataRetentionDuration<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DataRetentionDurationQuery,
  DataRetentionDurationQueryVariables,
  DataRetentionDurationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, DataRetentionDurationQuery, DataRetentionDurationQueryVariables, DataRetentionDurationProps<TChildProps, TDataName>>(DataRetentionDurationDocument, {
      alias: 'dataRetentionDuration',
      ...operationOptions
    });
};

/**
 * __useDataRetentionDurationQuery__
 *
 * To run a query within a React component, call `useDataRetentionDurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useDataRetentionDurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDataRetentionDurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useDataRetentionDurationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DataRetentionDurationQuery, DataRetentionDurationQueryVariables>) {
        return ApolloReactHooks.useQuery<DataRetentionDurationQuery, DataRetentionDurationQueryVariables>(DataRetentionDurationDocument, baseOptions);
      }
export function useDataRetentionDurationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DataRetentionDurationQuery, DataRetentionDurationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DataRetentionDurationQuery, DataRetentionDurationQueryVariables>(DataRetentionDurationDocument, baseOptions);
        }
export type DataRetentionDurationQueryHookResult = ReturnType<typeof useDataRetentionDurationQuery>;
export type DataRetentionDurationLazyQueryHookResult = ReturnType<typeof useDataRetentionDurationLazyQuery>;
export type DataRetentionDurationQueryResult = ApolloReactCommon.QueryResult<DataRetentionDurationQuery, DataRetentionDurationQueryVariables>;
export const FailedPaymentBannersDocument = gql`
    query FailedPaymentBanners($id: ID!) {
  failedPaymentBanners(id: $id) {
    userID
    orgsWithFailedPayments {
      id
      orgId
      orgName
      isBillingUser
      billingEmail
      daysUntilCancellation
      isCollectionMethodACH
    }
  }
}
    `;
export type FailedPaymentBannersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FailedPaymentBannersQuery, FailedPaymentBannersQueryVariables>, 'query'> & ({ variables: FailedPaymentBannersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FailedPaymentBannersComponent = (props: FailedPaymentBannersComponentProps) => (
      <ApolloReactComponents.Query<FailedPaymentBannersQuery, FailedPaymentBannersQueryVariables> query={FailedPaymentBannersDocument} {...props} />
    );
    
export type FailedPaymentBannersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FailedPaymentBannersQuery, FailedPaymentBannersQueryVariables>
    } & TChildProps;
export function withFailedPaymentBanners<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FailedPaymentBannersQuery,
  FailedPaymentBannersQueryVariables,
  FailedPaymentBannersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FailedPaymentBannersQuery, FailedPaymentBannersQueryVariables, FailedPaymentBannersProps<TChildProps, TDataName>>(FailedPaymentBannersDocument, {
      alias: 'failedPaymentBanners',
      ...operationOptions
    });
};

/**
 * __useFailedPaymentBannersQuery__
 *
 * To run a query within a React component, call `useFailedPaymentBannersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFailedPaymentBannersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFailedPaymentBannersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFailedPaymentBannersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FailedPaymentBannersQuery, FailedPaymentBannersQueryVariables>) {
        return ApolloReactHooks.useQuery<FailedPaymentBannersQuery, FailedPaymentBannersQueryVariables>(FailedPaymentBannersDocument, baseOptions);
      }
export function useFailedPaymentBannersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FailedPaymentBannersQuery, FailedPaymentBannersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FailedPaymentBannersQuery, FailedPaymentBannersQueryVariables>(FailedPaymentBannersDocument, baseOptions);
        }
export type FailedPaymentBannersQueryHookResult = ReturnType<typeof useFailedPaymentBannersQuery>;
export type FailedPaymentBannersLazyQueryHookResult = ReturnType<typeof useFailedPaymentBannersLazyQuery>;
export type FailedPaymentBannersQueryResult = ApolloReactCommon.QueryResult<FailedPaymentBannersQuery, FailedPaymentBannersQueryVariables>;
export const StarredProjectsDocument = gql`
    query StarredProjects($organizationId: ID) {
  viewer {
    id
    starredProjects(first: 30, organizationID: $organizationId) {
      edges {
        node {
          id
          ...AccountProjectFragment
        }
      }
    }
  }
}
    ${AccountProjectFragmentFragmentDoc}`;
export type StarredProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StarredProjectsQuery, StarredProjectsQueryVariables>, 'query'>;

    export const StarredProjectsComponent = (props: StarredProjectsComponentProps) => (
      <ApolloReactComponents.Query<StarredProjectsQuery, StarredProjectsQueryVariables> query={StarredProjectsDocument} {...props} />
    );
    
export type StarredProjectsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<StarredProjectsQuery, StarredProjectsQueryVariables>
    } & TChildProps;
export function withStarredProjects<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  StarredProjectsQuery,
  StarredProjectsQueryVariables,
  StarredProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, StarredProjectsQuery, StarredProjectsQueryVariables, StarredProjectsProps<TChildProps, TDataName>>(StarredProjectsDocument, {
      alias: 'starredProjects',
      ...operationOptions
    });
};

/**
 * __useStarredProjectsQuery__
 *
 * To run a query within a React component, call `useStarredProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useStarredProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStarredProjectsQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useStarredProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StarredProjectsQuery, StarredProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<StarredProjectsQuery, StarredProjectsQueryVariables>(StarredProjectsDocument, baseOptions);
      }
export function useStarredProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StarredProjectsQuery, StarredProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StarredProjectsQuery, StarredProjectsQueryVariables>(StarredProjectsDocument, baseOptions);
        }
export type StarredProjectsQueryHookResult = ReturnType<typeof useStarredProjectsQuery>;
export type StarredProjectsLazyQueryHookResult = ReturnType<typeof useStarredProjectsLazyQuery>;
export type StarredProjectsQueryResult = ApolloReactCommon.QueryResult<StarredProjectsQuery, StarredProjectsQueryVariables>;
export const CreateStorageSubscriptionDocument = gql`
    mutation createStorageSubscription($organizationId: ID, $paymentMethod: String, $clientMutationId: String) {
  createStorageSubscription(
    input: {organizationId: $organizationId, paymentMethod: $paymentMethod, clientMutationId: $clientMutationId}
  ) {
    subscription
    clientMutationId
  }
}
    `;
export type CreateStorageSubscriptionMutationFn = ApolloReactCommon.MutationFunction<CreateStorageSubscriptionMutation, CreateStorageSubscriptionMutationVariables>;
export type CreateStorageSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateStorageSubscriptionMutation, CreateStorageSubscriptionMutationVariables>, 'mutation'>;

    export const CreateStorageSubscriptionComponent = (props: CreateStorageSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<CreateStorageSubscriptionMutation, CreateStorageSubscriptionMutationVariables> mutation={CreateStorageSubscriptionDocument} {...props} />
    );
    
export type CreateStorageSubscriptionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateStorageSubscriptionMutation, CreateStorageSubscriptionMutationVariables>
    } & TChildProps;
export function withCreateStorageSubscription<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateStorageSubscriptionMutation,
  CreateStorageSubscriptionMutationVariables,
  CreateStorageSubscriptionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateStorageSubscriptionMutation, CreateStorageSubscriptionMutationVariables, CreateStorageSubscriptionProps<TChildProps, TDataName>>(CreateStorageSubscriptionDocument, {
      alias: 'createStorageSubscription',
      ...operationOptions
    });
};

/**
 * __useCreateStorageSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateStorageSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStorageSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStorageSubscriptionMutation, { data, loading, error }] = useCreateStorageSubscriptionMutation({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *      paymentMethod: // value for 'paymentMethod'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useCreateStorageSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStorageSubscriptionMutation, CreateStorageSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateStorageSubscriptionMutation, CreateStorageSubscriptionMutationVariables>(CreateStorageSubscriptionDocument, baseOptions);
      }
export type CreateStorageSubscriptionMutationHookResult = ReturnType<typeof useCreateStorageSubscriptionMutation>;
export type CreateStorageSubscriptionMutationResult = ApolloReactCommon.MutationResult<CreateStorageSubscriptionMutation>;
export type CreateStorageSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStorageSubscriptionMutation, CreateStorageSubscriptionMutationVariables>;
export const SubscriptionPlansDocument = gql`
    query SubscriptionPlans {
  plans {
    id
    name
    displayName
    stripePlanId
    maxSeats
    defaultPrivileges
    planType
    planVisibility
    unitPrice
    billingInterval
  }
}
    `;
export type SubscriptionPlansComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>, 'query'>;

    export const SubscriptionPlansComponent = (props: SubscriptionPlansComponentProps) => (
      <ApolloReactComponents.Query<SubscriptionPlansQuery, SubscriptionPlansQueryVariables> query={SubscriptionPlansDocument} {...props} />
    );
    
export type SubscriptionPlansProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>
    } & TChildProps;
export function withSubscriptionPlans<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SubscriptionPlansQuery,
  SubscriptionPlansQueryVariables,
  SubscriptionPlansProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SubscriptionPlansQuery, SubscriptionPlansQueryVariables, SubscriptionPlansProps<TChildProps, TDataName>>(SubscriptionPlansDocument, {
      alias: 'subscriptionPlans',
      ...operationOptions
    });
};

/**
 * __useSubscriptionPlansQuery__
 *
 * To run a query within a React component, call `useSubscriptionPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionPlansQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>) {
        return ApolloReactHooks.useQuery<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>(SubscriptionPlansDocument, baseOptions);
      }
export function useSubscriptionPlansLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>(SubscriptionPlansDocument, baseOptions);
        }
export type SubscriptionPlansQueryHookResult = ReturnType<typeof useSubscriptionPlansQuery>;
export type SubscriptionPlansLazyQueryHookResult = ReturnType<typeof useSubscriptionPlansLazyQuery>;
export type SubscriptionPlansQueryResult = ApolloReactCommon.QueryResult<SubscriptionPlansQuery, SubscriptionPlansQueryVariables>;
export const UpsertSweepDocument = gql`
    mutation upsertSweep($id: ID, $description: String, $state: String, $displayName: String, $config: String, $entityName: String, $projectName: String, $priorRunsFilters: JSONString, $launchScheduler: JSONString) {
  upsertSweep(
    input: {id: $id, description: $description, state: $state, displayName: $displayName, config: $config, entityName: $entityName, projectName: $projectName, priorRunsFilters: $priorRunsFilters, launchScheduler: $launchScheduler}
  ) {
    sweep {
      id
      name
      displayName
      description
      state
    }
    inserted
    insertedPriorRuns
  }
}
    `;
export type UpsertSweepMutationFn = ApolloReactCommon.MutationFunction<UpsertSweepMutation, UpsertSweepMutationVariables>;
export type UpsertSweepComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertSweepMutation, UpsertSweepMutationVariables>, 'mutation'>;

    export const UpsertSweepComponent = (props: UpsertSweepComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertSweepMutation, UpsertSweepMutationVariables> mutation={UpsertSweepDocument} {...props} />
    );
    
export type UpsertSweepProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpsertSweepMutation, UpsertSweepMutationVariables>
    } & TChildProps;
export function withUpsertSweep<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpsertSweepMutation,
  UpsertSweepMutationVariables,
  UpsertSweepProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpsertSweepMutation, UpsertSweepMutationVariables, UpsertSweepProps<TChildProps, TDataName>>(UpsertSweepDocument, {
      alias: 'upsertSweep',
      ...operationOptions
    });
};

/**
 * __useUpsertSweepMutation__
 *
 * To run a mutation, you first call `useUpsertSweepMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertSweepMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertSweepMutation, { data, loading, error }] = useUpsertSweepMutation({
 *   variables: {
 *      id: // value for 'id'
 *      description: // value for 'description'
 *      state: // value for 'state'
 *      displayName: // value for 'displayName'
 *      config: // value for 'config'
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      priorRunsFilters: // value for 'priorRunsFilters'
 *      launchScheduler: // value for 'launchScheduler'
 *   },
 * });
 */
export function useUpsertSweepMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertSweepMutation, UpsertSweepMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertSweepMutation, UpsertSweepMutationVariables>(UpsertSweepDocument, baseOptions);
      }
export type UpsertSweepMutationHookResult = ReturnType<typeof useUpsertSweepMutation>;
export type UpsertSweepMutationResult = ApolloReactCommon.MutationResult<UpsertSweepMutation>;
export type UpsertSweepMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertSweepMutation, UpsertSweepMutationVariables>;
export const DeleteSweepsDocument = gql`
    mutation deleteSweeps($ids: [ID!]!, $deleteRuns: Boolean!) {
  deleteSweeps(input: {ids: $ids, deleteRuns: $deleteRuns}) {
    affectedAgents
    affectedRuns
  }
}
    `;
export type DeleteSweepsMutationFn = ApolloReactCommon.MutationFunction<DeleteSweepsMutation, DeleteSweepsMutationVariables>;
export type DeleteSweepsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteSweepsMutation, DeleteSweepsMutationVariables>, 'mutation'>;

    export const DeleteSweepsComponent = (props: DeleteSweepsComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteSweepsMutation, DeleteSweepsMutationVariables> mutation={DeleteSweepsDocument} {...props} />
    );
    
export type DeleteSweepsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteSweepsMutation, DeleteSweepsMutationVariables>
    } & TChildProps;
export function withDeleteSweeps<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteSweepsMutation,
  DeleteSweepsMutationVariables,
  DeleteSweepsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteSweepsMutation, DeleteSweepsMutationVariables, DeleteSweepsProps<TChildProps, TDataName>>(DeleteSweepsDocument, {
      alias: 'deleteSweeps',
      ...operationOptions
    });
};

/**
 * __useDeleteSweepsMutation__
 *
 * To run a mutation, you first call `useDeleteSweepsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSweepsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSweepsMutation, { data, loading, error }] = useDeleteSweepsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      deleteRuns: // value for 'deleteRuns'
 *   },
 * });
 */
export function useDeleteSweepsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSweepsMutation, DeleteSweepsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteSweepsMutation, DeleteSweepsMutationVariables>(DeleteSweepsDocument, baseOptions);
      }
export type DeleteSweepsMutationHookResult = ReturnType<typeof useDeleteSweepsMutation>;
export type DeleteSweepsMutationResult = ApolloReactCommon.MutationResult<DeleteSweepsMutation>;
export type DeleteSweepsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSweepsMutation, DeleteSweepsMutationVariables>;
export const GetTaskDocument = gql`
    query GetTask($id: ID!) {
  task(id: $id) {
    id
    name
    __typename
    state
    progress
    createdAt
    updatedAt
    type
  }
}
    `;
export type GetTaskComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GetTaskQuery, GetTaskQueryVariables>, 'query'> & ({ variables: GetTaskQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GetTaskComponent = (props: GetTaskComponentProps) => (
      <ApolloReactComponents.Query<GetTaskQuery, GetTaskQueryVariables> query={GetTaskDocument} {...props} />
    );
    
export type GetTaskProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GetTaskQuery, GetTaskQueryVariables>
    } & TChildProps;
export function withGetTask<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GetTaskQuery,
  GetTaskQueryVariables,
  GetTaskProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GetTaskQuery, GetTaskQueryVariables, GetTaskProps<TChildProps, TDataName>>(GetTaskDocument, {
      alias: 'getTask',
      ...operationOptions
    });
};

/**
 * __useGetTaskQuery__
 *
 * To run a query within a React component, call `useGetTaskQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetTaskQuery, GetTaskQueryVariables>) {
        return ApolloReactHooks.useQuery<GetTaskQuery, GetTaskQueryVariables>(GetTaskDocument, baseOptions);
      }
export function useGetTaskLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaskQuery, GetTaskQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetTaskQuery, GetTaskQueryVariables>(GetTaskDocument, baseOptions);
        }
export type GetTaskQueryHookResult = ReturnType<typeof useGetTaskQuery>;
export type GetTaskLazyQueryHookResult = ReturnType<typeof useGetTaskLazyQuery>;
export type GetTaskQueryResult = ApolloReactCommon.QueryResult<GetTaskQuery, GetTaskQueryVariables>;
export const EntityIsTeamDocument = gql`
    query EntityIsTeam($entityName: String) {
  entity(name: $entityName) {
    id
    isTeam
  }
}
    `;
export type EntityIsTeamComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityIsTeamQuery, EntityIsTeamQueryVariables>, 'query'>;

    export const EntityIsTeamComponent = (props: EntityIsTeamComponentProps) => (
      <ApolloReactComponents.Query<EntityIsTeamQuery, EntityIsTeamQueryVariables> query={EntityIsTeamDocument} {...props} />
    );
    
export type EntityIsTeamProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityIsTeamQuery, EntityIsTeamQueryVariables>
    } & TChildProps;
export function withEntityIsTeam<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityIsTeamQuery,
  EntityIsTeamQueryVariables,
  EntityIsTeamProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityIsTeamQuery, EntityIsTeamQueryVariables, EntityIsTeamProps<TChildProps, TDataName>>(EntityIsTeamDocument, {
      alias: 'entityIsTeam',
      ...operationOptions
    });
};

/**
 * __useEntityIsTeamQuery__
 *
 * To run a query within a React component, call `useEntityIsTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityIsTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityIsTeamQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useEntityIsTeamQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityIsTeamQuery, EntityIsTeamQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityIsTeamQuery, EntityIsTeamQueryVariables>(EntityIsTeamDocument, baseOptions);
      }
export function useEntityIsTeamLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityIsTeamQuery, EntityIsTeamQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityIsTeamQuery, EntityIsTeamQueryVariables>(EntityIsTeamDocument, baseOptions);
        }
export type EntityIsTeamQueryHookResult = ReturnType<typeof useEntityIsTeamQuery>;
export type EntityIsTeamLazyQueryHookResult = ReturnType<typeof useEntityIsTeamLazyQuery>;
export type EntityIsTeamQueryResult = ApolloReactCommon.QueryResult<EntityIsTeamQuery, EntityIsTeamQueryVariables>;
export const BasicTeamOrganizationDocument = gql`
    query BasicTeamOrganization($entityName: String) {
  entity(name: $entityName) {
    id
    isTeam
    available
    organization {
      id
      name
    }
  }
}
    `;
export type BasicTeamOrganizationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BasicTeamOrganizationQuery, BasicTeamOrganizationQueryVariables>, 'query'>;

    export const BasicTeamOrganizationComponent = (props: BasicTeamOrganizationComponentProps) => (
      <ApolloReactComponents.Query<BasicTeamOrganizationQuery, BasicTeamOrganizationQueryVariables> query={BasicTeamOrganizationDocument} {...props} />
    );
    
export type BasicTeamOrganizationProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<BasicTeamOrganizationQuery, BasicTeamOrganizationQueryVariables>
    } & TChildProps;
export function withBasicTeamOrganization<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BasicTeamOrganizationQuery,
  BasicTeamOrganizationQueryVariables,
  BasicTeamOrganizationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, BasicTeamOrganizationQuery, BasicTeamOrganizationQueryVariables, BasicTeamOrganizationProps<TChildProps, TDataName>>(BasicTeamOrganizationDocument, {
      alias: 'basicTeamOrganization',
      ...operationOptions
    });
};

/**
 * __useBasicTeamOrganizationQuery__
 *
 * To run a query within a React component, call `useBasicTeamOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useBasicTeamOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBasicTeamOrganizationQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useBasicTeamOrganizationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BasicTeamOrganizationQuery, BasicTeamOrganizationQueryVariables>) {
        return ApolloReactHooks.useQuery<BasicTeamOrganizationQuery, BasicTeamOrganizationQueryVariables>(BasicTeamOrganizationDocument, baseOptions);
      }
export function useBasicTeamOrganizationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BasicTeamOrganizationQuery, BasicTeamOrganizationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BasicTeamOrganizationQuery, BasicTeamOrganizationQueryVariables>(BasicTeamOrganizationDocument, baseOptions);
        }
export type BasicTeamOrganizationQueryHookResult = ReturnType<typeof useBasicTeamOrganizationQuery>;
export type BasicTeamOrganizationLazyQueryHookResult = ReturnType<typeof useBasicTeamOrganizationLazyQuery>;
export type BasicTeamOrganizationQueryResult = ApolloReactCommon.QueryResult<BasicTeamOrganizationQuery, BasicTeamOrganizationQueryVariables>;
export const TeamOrganizationDocument = gql`
    query TeamOrganization($entityName: String) {
  entity(name: $entityName) {
    id
    isTeam
    computeHours
    settings {
      openToMatchingOrgEmailDomainUsers
      membersCanInvite
      disableMagicLinkSharing
    }
    organization {
      id
      name
      flags
      usedSeats
      orgType
      roles {
        ID
        name
        description
        inheritedFrom {
          name
        }
      }
      members {
        orgID
        id
        username
        role
        modelsSeat
      }
      seatAvailability {
        seats
        viewOnlySeats
      }
      subscriptions {
        id
        plan {
          id
          name
          planType
          unitPrice
          billingInterval
          defaultPrivileges
          maxSeats
        }
        privileges
        subscriptionType
        billingPeriodStart
        billingPeriodEnd
        availableSeatsToPurchase
        seats
      }
    }
  }
}
    `;
export type TeamOrganizationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamOrganizationQuery, TeamOrganizationQueryVariables>, 'query'>;

    export const TeamOrganizationComponent = (props: TeamOrganizationComponentProps) => (
      <ApolloReactComponents.Query<TeamOrganizationQuery, TeamOrganizationQueryVariables> query={TeamOrganizationDocument} {...props} />
    );
    
export type TeamOrganizationProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TeamOrganizationQuery, TeamOrganizationQueryVariables>
    } & TChildProps;
export function withTeamOrganization<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TeamOrganizationQuery,
  TeamOrganizationQueryVariables,
  TeamOrganizationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TeamOrganizationQuery, TeamOrganizationQueryVariables, TeamOrganizationProps<TChildProps, TDataName>>(TeamOrganizationDocument, {
      alias: 'teamOrganization',
      ...operationOptions
    });
};

/**
 * __useTeamOrganizationQuery__
 *
 * To run a query within a React component, call `useTeamOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamOrganizationQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useTeamOrganizationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamOrganizationQuery, TeamOrganizationQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamOrganizationQuery, TeamOrganizationQueryVariables>(TeamOrganizationDocument, baseOptions);
      }
export function useTeamOrganizationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamOrganizationQuery, TeamOrganizationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamOrganizationQuery, TeamOrganizationQueryVariables>(TeamOrganizationDocument, baseOptions);
        }
export type TeamOrganizationQueryHookResult = ReturnType<typeof useTeamOrganizationQuery>;
export type TeamOrganizationLazyQueryHookResult = ReturnType<typeof useTeamOrganizationLazyQuery>;
export type TeamOrganizationQueryResult = ApolloReactCommon.QueryResult<TeamOrganizationQuery, TeamOrganizationQueryVariables>;
export const TeamOrganizationRolesDocument = gql`
    query TeamOrganizationRoles($entityName: String) {
  entity(name: $entityName) {
    id
    settings {
      membersCanInvite
    }
    members {
      id
      username
      role
    }
    organization {
      id
      seatAvailability {
        viewOnlySeats
      }
      roles {
        ID
        name
        description
        inheritedFrom {
          name
        }
      }
      members {
        id
        username
        role
      }
    }
  }
}
    `;
export type TeamOrganizationRolesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamOrganizationRolesQuery, TeamOrganizationRolesQueryVariables>, 'query'>;

    export const TeamOrganizationRolesComponent = (props: TeamOrganizationRolesComponentProps) => (
      <ApolloReactComponents.Query<TeamOrganizationRolesQuery, TeamOrganizationRolesQueryVariables> query={TeamOrganizationRolesDocument} {...props} />
    );
    
export type TeamOrganizationRolesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TeamOrganizationRolesQuery, TeamOrganizationRolesQueryVariables>
    } & TChildProps;
export function withTeamOrganizationRoles<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TeamOrganizationRolesQuery,
  TeamOrganizationRolesQueryVariables,
  TeamOrganizationRolesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TeamOrganizationRolesQuery, TeamOrganizationRolesQueryVariables, TeamOrganizationRolesProps<TChildProps, TDataName>>(TeamOrganizationRolesDocument, {
      alias: 'teamOrganizationRoles',
      ...operationOptions
    });
};

/**
 * __useTeamOrganizationRolesQuery__
 *
 * To run a query within a React component, call `useTeamOrganizationRolesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamOrganizationRolesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamOrganizationRolesQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useTeamOrganizationRolesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamOrganizationRolesQuery, TeamOrganizationRolesQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamOrganizationRolesQuery, TeamOrganizationRolesQueryVariables>(TeamOrganizationRolesDocument, baseOptions);
      }
export function useTeamOrganizationRolesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamOrganizationRolesQuery, TeamOrganizationRolesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamOrganizationRolesQuery, TeamOrganizationRolesQueryVariables>(TeamOrganizationRolesDocument, baseOptions);
        }
export type TeamOrganizationRolesQueryHookResult = ReturnType<typeof useTeamOrganizationRolesQuery>;
export type TeamOrganizationRolesLazyQueryHookResult = ReturnType<typeof useTeamOrganizationRolesLazyQuery>;
export type TeamOrganizationRolesQueryResult = ApolloReactCommon.QueryResult<TeamOrganizationRolesQuery, TeamOrganizationRolesQueryVariables>;
export const TeamPageProjectsTwoDocument = gql`
    query TeamPageProjectsTwo($entityName: String!, $first: Int = 10) {
  entity(name: $entityName) {
    id
    organizationId
    projects(order: "-created_at", first: $first) {
      edges {
        node {
          id
          name
          entityName
          description
          totalRuns
          lastActive
        }
      }
    }
  }
}
    `;
export type TeamPageProjectsTwoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamPageProjectsTwoQuery, TeamPageProjectsTwoQueryVariables>, 'query'> & ({ variables: TeamPageProjectsTwoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamPageProjectsTwoComponent = (props: TeamPageProjectsTwoComponentProps) => (
      <ApolloReactComponents.Query<TeamPageProjectsTwoQuery, TeamPageProjectsTwoQueryVariables> query={TeamPageProjectsTwoDocument} {...props} />
    );
    
export type TeamPageProjectsTwoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TeamPageProjectsTwoQuery, TeamPageProjectsTwoQueryVariables>
    } & TChildProps;
export function withTeamPageProjectsTwo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TeamPageProjectsTwoQuery,
  TeamPageProjectsTwoQueryVariables,
  TeamPageProjectsTwoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TeamPageProjectsTwoQuery, TeamPageProjectsTwoQueryVariables, TeamPageProjectsTwoProps<TChildProps, TDataName>>(TeamPageProjectsTwoDocument, {
      alias: 'teamPageProjectsTwo',
      ...operationOptions
    });
};

/**
 * __useTeamPageProjectsTwoQuery__
 *
 * To run a query within a React component, call `useTeamPageProjectsTwoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamPageProjectsTwoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamPageProjectsTwoQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useTeamPageProjectsTwoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamPageProjectsTwoQuery, TeamPageProjectsTwoQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamPageProjectsTwoQuery, TeamPageProjectsTwoQueryVariables>(TeamPageProjectsTwoDocument, baseOptions);
      }
export function useTeamPageProjectsTwoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamPageProjectsTwoQuery, TeamPageProjectsTwoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamPageProjectsTwoQuery, TeamPageProjectsTwoQueryVariables>(TeamPageProjectsTwoDocument, baseOptions);
        }
export type TeamPageProjectsTwoQueryHookResult = ReturnType<typeof useTeamPageProjectsTwoQuery>;
export type TeamPageProjectsTwoLazyQueryHookResult = ReturnType<typeof useTeamPageProjectsTwoLazyQuery>;
export type TeamPageProjectsTwoQueryResult = ApolloReactCommon.QueryResult<TeamPageProjectsTwoQuery, TeamPageProjectsTwoQueryVariables>;
export const TeamPageProjectsDocument = gql`
    query TeamPageProjects($entityName: String!) {
  entity(name: $entityName) {
    id
    organizationId
    projects(order: "-created_at", first: 100) {
      edges {
        node {
          id
          name
          entityName
          access
          allViews(viewType: "runs", first: 10000) {
            edges {
              node {
                id
                ...ReportsTableFragment
              }
            }
          }
        }
      }
    }
  }
}
    ${ReportsTableFragmentFragmentDoc}`;
export type TeamPageProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamPageProjectsQuery, TeamPageProjectsQueryVariables>, 'query'> & ({ variables: TeamPageProjectsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamPageProjectsComponent = (props: TeamPageProjectsComponentProps) => (
      <ApolloReactComponents.Query<TeamPageProjectsQuery, TeamPageProjectsQueryVariables> query={TeamPageProjectsDocument} {...props} />
    );
    
export type TeamPageProjectsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TeamPageProjectsQuery, TeamPageProjectsQueryVariables>
    } & TChildProps;
export function withTeamPageProjects<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TeamPageProjectsQuery,
  TeamPageProjectsQueryVariables,
  TeamPageProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TeamPageProjectsQuery, TeamPageProjectsQueryVariables, TeamPageProjectsProps<TChildProps, TDataName>>(TeamPageProjectsDocument, {
      alias: 'teamPageProjects',
      ...operationOptions
    });
};

/**
 * __useTeamPageProjectsQuery__
 *
 * To run a query within a React component, call `useTeamPageProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamPageProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamPageProjectsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useTeamPageProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamPageProjectsQuery, TeamPageProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamPageProjectsQuery, TeamPageProjectsQueryVariables>(TeamPageProjectsDocument, baseOptions);
      }
export function useTeamPageProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamPageProjectsQuery, TeamPageProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamPageProjectsQuery, TeamPageProjectsQueryVariables>(TeamPageProjectsDocument, baseOptions);
        }
export type TeamPageProjectsQueryHookResult = ReturnType<typeof useTeamPageProjectsQuery>;
export type TeamPageProjectsLazyQueryHookResult = ReturnType<typeof useTeamPageProjectsLazyQuery>;
export type TeamPageProjectsQueryResult = ApolloReactCommon.QueryResult<TeamPageProjectsQuery, TeamPageProjectsQueryVariables>;
export const FetchTeamProfilePageProjectsByIDsDocument = gql`
    query FetchTeamProfilePageProjectsByIDs($entityName: String!, $projectIDs: [ID!]!) {
  projects(entityName: $entityName, projectIDs: $projectIDs) {
    edges {
      node {
        id
        name
        entityName
        access
        lastActive
        runCount
        user {
          id
          username
        }
      }
    }
  }
}
    `;
export type FetchTeamProfilePageProjectsByIDsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FetchTeamProfilePageProjectsByIDsQuery, FetchTeamProfilePageProjectsByIDsQueryVariables>, 'query'> & ({ variables: FetchTeamProfilePageProjectsByIDsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FetchTeamProfilePageProjectsByIDsComponent = (props: FetchTeamProfilePageProjectsByIDsComponentProps) => (
      <ApolloReactComponents.Query<FetchTeamProfilePageProjectsByIDsQuery, FetchTeamProfilePageProjectsByIDsQueryVariables> query={FetchTeamProfilePageProjectsByIDsDocument} {...props} />
    );
    
export type FetchTeamProfilePageProjectsByIDsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FetchTeamProfilePageProjectsByIDsQuery, FetchTeamProfilePageProjectsByIDsQueryVariables>
    } & TChildProps;
export function withFetchTeamProfilePageProjectsByIDs<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FetchTeamProfilePageProjectsByIDsQuery,
  FetchTeamProfilePageProjectsByIDsQueryVariables,
  FetchTeamProfilePageProjectsByIDsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FetchTeamProfilePageProjectsByIDsQuery, FetchTeamProfilePageProjectsByIDsQueryVariables, FetchTeamProfilePageProjectsByIDsProps<TChildProps, TDataName>>(FetchTeamProfilePageProjectsByIDsDocument, {
      alias: 'fetchTeamProfilePageProjectsByIDs',
      ...operationOptions
    });
};

/**
 * __useFetchTeamProfilePageProjectsByIDsQuery__
 *
 * To run a query within a React component, call `useFetchTeamProfilePageProjectsByIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchTeamProfilePageProjectsByIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchTeamProfilePageProjectsByIDsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectIDs: // value for 'projectIDs'
 *   },
 * });
 */
export function useFetchTeamProfilePageProjectsByIDsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchTeamProfilePageProjectsByIDsQuery, FetchTeamProfilePageProjectsByIDsQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchTeamProfilePageProjectsByIDsQuery, FetchTeamProfilePageProjectsByIDsQueryVariables>(FetchTeamProfilePageProjectsByIDsDocument, baseOptions);
      }
export function useFetchTeamProfilePageProjectsByIDsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchTeamProfilePageProjectsByIDsQuery, FetchTeamProfilePageProjectsByIDsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchTeamProfilePageProjectsByIDsQuery, FetchTeamProfilePageProjectsByIDsQueryVariables>(FetchTeamProfilePageProjectsByIDsDocument, baseOptions);
        }
export type FetchTeamProfilePageProjectsByIDsQueryHookResult = ReturnType<typeof useFetchTeamProfilePageProjectsByIDsQuery>;
export type FetchTeamProfilePageProjectsByIDsLazyQueryHookResult = ReturnType<typeof useFetchTeamProfilePageProjectsByIDsLazyQuery>;
export type FetchTeamProfilePageProjectsByIDsQueryResult = ApolloReactCommon.QueryResult<FetchTeamProfilePageProjectsByIDsQuery, FetchTeamProfilePageProjectsByIDsQueryVariables>;
export const TeamPageDocument = gql`
    query TeamPage($entityName: String!, $workflowsAdminType: WorkflowsAdminType!) {
  entity(name: $entityName) {
    available
    claimingEntity {
      id
      name
    }
    id
    isTeam
    members {
      id
      admin
      pending
      email
      username
      name
      photoUrl
      accountType
      apiKey
      role
      memberRole {
        ID
        name
      }
    }
    memberCount
    name
    organizationId
    invites {
      edges {
        node {
          id
          email
          fromUser {
            id
            username
          }
        }
      }
    }
    photoUrl
    projects(first: 1) {
      edges {
        node {
          id
        }
      }
    }
    projectCount
    readOnly
    workflowsAdmins(adminType: $workflowsAdminType) {
      id
      username
      name
      email
    }
    protectedAliases(adminType: $workflowsAdminType)
  }
}
    `;
export type TeamPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamPageQuery, TeamPageQueryVariables>, 'query'> & ({ variables: TeamPageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamPageComponent = (props: TeamPageComponentProps) => (
      <ApolloReactComponents.Query<TeamPageQuery, TeamPageQueryVariables> query={TeamPageDocument} {...props} />
    );
    
export type TeamPageProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TeamPageQuery, TeamPageQueryVariables>
    } & TChildProps;
export function withTeamPage<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TeamPageQuery,
  TeamPageQueryVariables,
  TeamPageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TeamPageQuery, TeamPageQueryVariables, TeamPageProps<TChildProps, TDataName>>(TeamPageDocument, {
      alias: 'teamPage',
      ...operationOptions
    });
};

/**
 * __useTeamPageQuery__
 *
 * To run a query within a React component, call `useTeamPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamPageQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      workflowsAdminType: // value for 'workflowsAdminType'
 *   },
 * });
 */
export function useTeamPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamPageQuery, TeamPageQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamPageQuery, TeamPageQueryVariables>(TeamPageDocument, baseOptions);
      }
export function useTeamPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamPageQuery, TeamPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamPageQuery, TeamPageQueryVariables>(TeamPageDocument, baseOptions);
        }
export type TeamPageQueryHookResult = ReturnType<typeof useTeamPageQuery>;
export type TeamPageLazyQueryHookResult = ReturnType<typeof useTeamPageLazyQuery>;
export type TeamPageQueryResult = ApolloReactCommon.QueryResult<TeamPageQuery, TeamPageQueryVariables>;
export const AvailableTeamDocument = gql`
    query availableTeam($teamName: String!) {
  entity(name: $teamName) {
    id
    available
  }
}
    `;
export type AvailableTeamComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AvailableTeamQuery, AvailableTeamQueryVariables>, 'query'> & ({ variables: AvailableTeamQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AvailableTeamComponent = (props: AvailableTeamComponentProps) => (
      <ApolloReactComponents.Query<AvailableTeamQuery, AvailableTeamQueryVariables> query={AvailableTeamDocument} {...props} />
    );
    
export type AvailableTeamProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AvailableTeamQuery, AvailableTeamQueryVariables>
    } & TChildProps;
export function withAvailableTeam<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AvailableTeamQuery,
  AvailableTeamQueryVariables,
  AvailableTeamProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AvailableTeamQuery, AvailableTeamQueryVariables, AvailableTeamProps<TChildProps, TDataName>>(AvailableTeamDocument, {
      alias: 'availableTeam',
      ...operationOptions
    });
};

/**
 * __useAvailableTeamQuery__
 *
 * To run a query within a React component, call `useAvailableTeamQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableTeamQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableTeamQuery({
 *   variables: {
 *      teamName: // value for 'teamName'
 *   },
 * });
 */
export function useAvailableTeamQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AvailableTeamQuery, AvailableTeamQueryVariables>) {
        return ApolloReactHooks.useQuery<AvailableTeamQuery, AvailableTeamQueryVariables>(AvailableTeamDocument, baseOptions);
      }
export function useAvailableTeamLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AvailableTeamQuery, AvailableTeamQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AvailableTeamQuery, AvailableTeamQueryVariables>(AvailableTeamDocument, baseOptions);
        }
export type AvailableTeamQueryHookResult = ReturnType<typeof useAvailableTeamQuery>;
export type AvailableTeamLazyQueryHookResult = ReturnType<typeof useAvailableTeamLazyQuery>;
export type AvailableTeamQueryResult = ApolloReactCommon.QueryResult<AvailableTeamQuery, AvailableTeamQueryVariables>;
export const AvailableOrgDocument = gql`
    query availableOrg($name: String!) {
  organization(name: $name) {
    id
    available
  }
}
    `;
export type AvailableOrgComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AvailableOrgQuery, AvailableOrgQueryVariables>, 'query'> & ({ variables: AvailableOrgQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AvailableOrgComponent = (props: AvailableOrgComponentProps) => (
      <ApolloReactComponents.Query<AvailableOrgQuery, AvailableOrgQueryVariables> query={AvailableOrgDocument} {...props} />
    );
    
export type AvailableOrgProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AvailableOrgQuery, AvailableOrgQueryVariables>
    } & TChildProps;
export function withAvailableOrg<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AvailableOrgQuery,
  AvailableOrgQueryVariables,
  AvailableOrgProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AvailableOrgQuery, AvailableOrgQueryVariables, AvailableOrgProps<TChildProps, TDataName>>(AvailableOrgDocument, {
      alias: 'availableOrg',
      ...operationOptions
    });
};

/**
 * __useAvailableOrgQuery__
 *
 * To run a query within a React component, call `useAvailableOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useAvailableOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAvailableOrgQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useAvailableOrgQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AvailableOrgQuery, AvailableOrgQueryVariables>) {
        return ApolloReactHooks.useQuery<AvailableOrgQuery, AvailableOrgQueryVariables>(AvailableOrgDocument, baseOptions);
      }
export function useAvailableOrgLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AvailableOrgQuery, AvailableOrgQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AvailableOrgQuery, AvailableOrgQueryVariables>(AvailableOrgDocument, baseOptions);
        }
export type AvailableOrgQueryHookResult = ReturnType<typeof useAvailableOrgQuery>;
export type AvailableOrgLazyQueryHookResult = ReturnType<typeof useAvailableOrgLazyQuery>;
export type AvailableOrgQueryResult = ApolloReactCommon.QueryResult<AvailableOrgQuery, AvailableOrgQueryVariables>;
export const OrganizationStorageBucketInfosDocument = gql`
    query organizationStorageBucketInfos($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    teams {
      id
      settings {
        storageBucketInfo {
          ID
          provider
          name
          kmsKeyID
          awsExternalID
          path
        }
      }
    }
  }
}
    `;
export type OrganizationStorageBucketInfosComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationStorageBucketInfosQuery, OrganizationStorageBucketInfosQueryVariables>, 'query'> & ({ variables: OrganizationStorageBucketInfosQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationStorageBucketInfosComponent = (props: OrganizationStorageBucketInfosComponentProps) => (
      <ApolloReactComponents.Query<OrganizationStorageBucketInfosQuery, OrganizationStorageBucketInfosQueryVariables> query={OrganizationStorageBucketInfosDocument} {...props} />
    );
    
export type OrganizationStorageBucketInfosProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationStorageBucketInfosQuery, OrganizationStorageBucketInfosQueryVariables>
    } & TChildProps;
export function withOrganizationStorageBucketInfos<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationStorageBucketInfosQuery,
  OrganizationStorageBucketInfosQueryVariables,
  OrganizationStorageBucketInfosProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationStorageBucketInfosQuery, OrganizationStorageBucketInfosQueryVariables, OrganizationStorageBucketInfosProps<TChildProps, TDataName>>(OrganizationStorageBucketInfosDocument, {
      alias: 'organizationStorageBucketInfos',
      ...operationOptions
    });
};

/**
 * __useOrganizationStorageBucketInfosQuery__
 *
 * To run a query within a React component, call `useOrganizationStorageBucketInfosQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationStorageBucketInfosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationStorageBucketInfosQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationStorageBucketInfosQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationStorageBucketInfosQuery, OrganizationStorageBucketInfosQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationStorageBucketInfosQuery, OrganizationStorageBucketInfosQueryVariables>(OrganizationStorageBucketInfosDocument, baseOptions);
      }
export function useOrganizationStorageBucketInfosLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationStorageBucketInfosQuery, OrganizationStorageBucketInfosQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationStorageBucketInfosQuery, OrganizationStorageBucketInfosQueryVariables>(OrganizationStorageBucketInfosDocument, baseOptions);
        }
export type OrganizationStorageBucketInfosQueryHookResult = ReturnType<typeof useOrganizationStorageBucketInfosQuery>;
export type OrganizationStorageBucketInfosLazyQueryHookResult = ReturnType<typeof useOrganizationStorageBucketInfosLazyQuery>;
export type OrganizationStorageBucketInfosQueryResult = ApolloReactCommon.QueryResult<OrganizationStorageBucketInfosQuery, OrganizationStorageBucketInfosQueryVariables>;
export const StorageBucketInfosDocument = gql`
    query storageBucketInfos {
  entities {
    edges {
      node {
        id
        settings {
          storageBucketInfo {
            ID
            provider
            name
            kmsKeyID
            awsExternalID
            path
          }
        }
      }
    }
  }
}
    `;
export type StorageBucketInfosComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StorageBucketInfosQuery, StorageBucketInfosQueryVariables>, 'query'>;

    export const StorageBucketInfosComponent = (props: StorageBucketInfosComponentProps) => (
      <ApolloReactComponents.Query<StorageBucketInfosQuery, StorageBucketInfosQueryVariables> query={StorageBucketInfosDocument} {...props} />
    );
    
export type StorageBucketInfosProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<StorageBucketInfosQuery, StorageBucketInfosQueryVariables>
    } & TChildProps;
export function withStorageBucketInfos<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  StorageBucketInfosQuery,
  StorageBucketInfosQueryVariables,
  StorageBucketInfosProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, StorageBucketInfosQuery, StorageBucketInfosQueryVariables, StorageBucketInfosProps<TChildProps, TDataName>>(StorageBucketInfosDocument, {
      alias: 'storageBucketInfos',
      ...operationOptions
    });
};

/**
 * __useStorageBucketInfosQuery__
 *
 * To run a query within a React component, call `useStorageBucketInfosQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorageBucketInfosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorageBucketInfosQuery({
 *   variables: {
 *   },
 * });
 */
export function useStorageBucketInfosQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StorageBucketInfosQuery, StorageBucketInfosQueryVariables>) {
        return ApolloReactHooks.useQuery<StorageBucketInfosQuery, StorageBucketInfosQueryVariables>(StorageBucketInfosDocument, baseOptions);
      }
export function useStorageBucketInfosLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StorageBucketInfosQuery, StorageBucketInfosQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StorageBucketInfosQuery, StorageBucketInfosQueryVariables>(StorageBucketInfosDocument, baseOptions);
        }
export type StorageBucketInfosQueryHookResult = ReturnType<typeof useStorageBucketInfosQuery>;
export type StorageBucketInfosLazyQueryHookResult = ReturnType<typeof useStorageBucketInfosLazyQuery>;
export type StorageBucketInfosQueryResult = ApolloReactCommon.QueryResult<StorageBucketInfosQuery, StorageBucketInfosQueryVariables>;
export const CreateTeamDocument = gql`
    mutation createTeam($teamName: String!, $organizationId: String, $teamAdminUserName: String, $storageBucketInfo: StorageBucketInfoInput) {
  createTeam(
    input: {teamName: $teamName, organizationId: $organizationId, teamAdminUserName: $teamAdminUserName, storageBucketInfo: $storageBucketInfo}
  ) {
    entity {
      id
      name
      available
      photoUrl
      limits
    }
  }
}
    `;
export type CreateTeamMutationFn = ApolloReactCommon.MutationFunction<CreateTeamMutation, CreateTeamMutationVariables>;
export type CreateTeamComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateTeamMutation, CreateTeamMutationVariables>, 'mutation'>;

    export const CreateTeamComponent = (props: CreateTeamComponentProps) => (
      <ApolloReactComponents.Mutation<CreateTeamMutation, CreateTeamMutationVariables> mutation={CreateTeamDocument} {...props} />
    );
    
export type CreateTeamProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateTeamMutation, CreateTeamMutationVariables>
    } & TChildProps;
export function withCreateTeam<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateTeamMutation,
  CreateTeamMutationVariables,
  CreateTeamProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateTeamMutation, CreateTeamMutationVariables, CreateTeamProps<TChildProps, TDataName>>(CreateTeamDocument, {
      alias: 'createTeam',
      ...operationOptions
    });
};

/**
 * __useCreateTeamMutation__
 *
 * To run a mutation, you first call `useCreateTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTeamMutation, { data, loading, error }] = useCreateTeamMutation({
 *   variables: {
 *      teamName: // value for 'teamName'
 *      organizationId: // value for 'organizationId'
 *      teamAdminUserName: // value for 'teamAdminUserName'
 *      storageBucketInfo: // value for 'storageBucketInfo'
 *   },
 * });
 */
export function useCreateTeamMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateTeamMutation, CreateTeamMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateTeamMutation, CreateTeamMutationVariables>(CreateTeamDocument, baseOptions);
      }
export type CreateTeamMutationHookResult = ReturnType<typeof useCreateTeamMutation>;
export type CreateTeamMutationResult = ApolloReactCommon.MutationResult<CreateTeamMutation>;
export type CreateTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateTeamMutation, CreateTeamMutationVariables>;
export const TestBucketStoreConnectionDocument = gql`
    mutation testBucketStoreConnection($input: StorageBucketInfoInput!) {
  testBucketStoreConnection(input: $input) {
    message
    severity
  }
}
    `;
export type TestBucketStoreConnectionMutationFn = ApolloReactCommon.MutationFunction<TestBucketStoreConnectionMutation, TestBucketStoreConnectionMutationVariables>;
export type TestBucketStoreConnectionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<TestBucketStoreConnectionMutation, TestBucketStoreConnectionMutationVariables>, 'mutation'>;

    export const TestBucketStoreConnectionComponent = (props: TestBucketStoreConnectionComponentProps) => (
      <ApolloReactComponents.Mutation<TestBucketStoreConnectionMutation, TestBucketStoreConnectionMutationVariables> mutation={TestBucketStoreConnectionDocument} {...props} />
    );
    
export type TestBucketStoreConnectionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<TestBucketStoreConnectionMutation, TestBucketStoreConnectionMutationVariables>
    } & TChildProps;
export function withTestBucketStoreConnection<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TestBucketStoreConnectionMutation,
  TestBucketStoreConnectionMutationVariables,
  TestBucketStoreConnectionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, TestBucketStoreConnectionMutation, TestBucketStoreConnectionMutationVariables, TestBucketStoreConnectionProps<TChildProps, TDataName>>(TestBucketStoreConnectionDocument, {
      alias: 'testBucketStoreConnection',
      ...operationOptions
    });
};

/**
 * __useTestBucketStoreConnectionMutation__
 *
 * To run a mutation, you first call `useTestBucketStoreConnectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useTestBucketStoreConnectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [testBucketStoreConnectionMutation, { data, loading, error }] = useTestBucketStoreConnectionMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useTestBucketStoreConnectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<TestBucketStoreConnectionMutation, TestBucketStoreConnectionMutationVariables>) {
        return ApolloReactHooks.useMutation<TestBucketStoreConnectionMutation, TestBucketStoreConnectionMutationVariables>(TestBucketStoreConnectionDocument, baseOptions);
      }
export type TestBucketStoreConnectionMutationHookResult = ReturnType<typeof useTestBucketStoreConnectionMutation>;
export type TestBucketStoreConnectionMutationResult = ApolloReactCommon.MutationResult<TestBucketStoreConnectionMutation>;
export type TestBucketStoreConnectionMutationOptions = ApolloReactCommon.BaseMutationOptions<TestBucketStoreConnectionMutation, TestBucketStoreConnectionMutationVariables>;
export const DeleteTeamDocument = gql`
    mutation deleteTeam($teamName: String!) {
  deleteTeam(input: {teamName: $teamName}) {
    success
  }
}
    `;
export type DeleteTeamMutationFn = ApolloReactCommon.MutationFunction<DeleteTeamMutation, DeleteTeamMutationVariables>;
export type DeleteTeamComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteTeamMutation, DeleteTeamMutationVariables>, 'mutation'>;

    export const DeleteTeamComponent = (props: DeleteTeamComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteTeamMutation, DeleteTeamMutationVariables> mutation={DeleteTeamDocument} {...props} />
    );
    
export type DeleteTeamProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteTeamMutation, DeleteTeamMutationVariables>
    } & TChildProps;
export function withDeleteTeam<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteTeamMutation,
  DeleteTeamMutationVariables,
  DeleteTeamProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteTeamMutation, DeleteTeamMutationVariables, DeleteTeamProps<TChildProps, TDataName>>(DeleteTeamDocument, {
      alias: 'deleteTeam',
      ...operationOptions
    });
};

/**
 * __useDeleteTeamMutation__
 *
 * To run a mutation, you first call `useDeleteTeamMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTeamMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTeamMutation, { data, loading, error }] = useDeleteTeamMutation({
 *   variables: {
 *      teamName: // value for 'teamName'
 *   },
 * });
 */
export function useDeleteTeamMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTeamMutation, DeleteTeamMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTeamMutation, DeleteTeamMutationVariables>(DeleteTeamDocument, baseOptions);
      }
export type DeleteTeamMutationHookResult = ReturnType<typeof useDeleteTeamMutation>;
export type DeleteTeamMutationResult = ApolloReactCommon.MutationResult<DeleteTeamMutation>;
export type DeleteTeamMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTeamMutation, DeleteTeamMutationVariables>;
export const EntityFeatureFlagsDocument = gql`
    query entityFeatureFlags($teamName: String, $rampIDType: RampIDType!) {
  entity(name: $teamName) {
    id
    featureFlags(rampIDType: $rampIDType) {
      rampKey
      isEnabled
    }
  }
}
    `;
export type EntityFeatureFlagsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityFeatureFlagsQuery, EntityFeatureFlagsQueryVariables>, 'query'> & ({ variables: EntityFeatureFlagsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntityFeatureFlagsComponent = (props: EntityFeatureFlagsComponentProps) => (
      <ApolloReactComponents.Query<EntityFeatureFlagsQuery, EntityFeatureFlagsQueryVariables> query={EntityFeatureFlagsDocument} {...props} />
    );
    
export type EntityFeatureFlagsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityFeatureFlagsQuery, EntityFeatureFlagsQueryVariables>
    } & TChildProps;
export function withEntityFeatureFlags<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityFeatureFlagsQuery,
  EntityFeatureFlagsQueryVariables,
  EntityFeatureFlagsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityFeatureFlagsQuery, EntityFeatureFlagsQueryVariables, EntityFeatureFlagsProps<TChildProps, TDataName>>(EntityFeatureFlagsDocument, {
      alias: 'entityFeatureFlags',
      ...operationOptions
    });
};

/**
 * __useEntityFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useEntityFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityFeatureFlagsQuery({
 *   variables: {
 *      teamName: // value for 'teamName'
 *      rampIDType: // value for 'rampIDType'
 *   },
 * });
 */
export function useEntityFeatureFlagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityFeatureFlagsQuery, EntityFeatureFlagsQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityFeatureFlagsQuery, EntityFeatureFlagsQueryVariables>(EntityFeatureFlagsDocument, baseOptions);
      }
export function useEntityFeatureFlagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityFeatureFlagsQuery, EntityFeatureFlagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityFeatureFlagsQuery, EntityFeatureFlagsQueryVariables>(EntityFeatureFlagsDocument, baseOptions);
        }
export type EntityFeatureFlagsQueryHookResult = ReturnType<typeof useEntityFeatureFlagsQuery>;
export type EntityFeatureFlagsLazyQueryHookResult = ReturnType<typeof useEntityFeatureFlagsLazyQuery>;
export type EntityFeatureFlagsQueryResult = ApolloReactCommon.QueryResult<EntityFeatureFlagsQuery, EntityFeatureFlagsQueryVariables>;
export const OrganizationTeamsDocument = gql`
    query organizationTeams($orgName: String!) {
  organization(name: $orgName) {
    id
    teams {
      id
      name
      memberCount
      computeHours
    }
    subscriptions {
      id
      privileges
    }
    members {
      orgID
      id
      username
      role
      user {
        id
        name
        accountType
        ...UserRoleFragment
        teams {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  }
}
    ${UserRoleFragmentFragmentDoc}`;
export type OrganizationTeamsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationTeamsQuery, OrganizationTeamsQueryVariables>, 'query'> & ({ variables: OrganizationTeamsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationTeamsComponent = (props: OrganizationTeamsComponentProps) => (
      <ApolloReactComponents.Query<OrganizationTeamsQuery, OrganizationTeamsQueryVariables> query={OrganizationTeamsDocument} {...props} />
    );
    
export type OrganizationTeamsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationTeamsQuery, OrganizationTeamsQueryVariables>
    } & TChildProps;
export function withOrganizationTeams<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationTeamsQuery,
  OrganizationTeamsQueryVariables,
  OrganizationTeamsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationTeamsQuery, OrganizationTeamsQueryVariables, OrganizationTeamsProps<TChildProps, TDataName>>(OrganizationTeamsDocument, {
      alias: 'organizationTeams',
      ...operationOptions
    });
};

/**
 * __useOrganizationTeamsQuery__
 *
 * To run a query within a React component, call `useOrganizationTeamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationTeamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationTeamsQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useOrganizationTeamsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationTeamsQuery, OrganizationTeamsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationTeamsQuery, OrganizationTeamsQueryVariables>(OrganizationTeamsDocument, baseOptions);
      }
export function useOrganizationTeamsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationTeamsQuery, OrganizationTeamsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationTeamsQuery, OrganizationTeamsQueryVariables>(OrganizationTeamsDocument, baseOptions);
        }
export type OrganizationTeamsQueryHookResult = ReturnType<typeof useOrganizationTeamsQuery>;
export type OrganizationTeamsLazyQueryHookResult = ReturnType<typeof useOrganizationTeamsLazyQuery>;
export type OrganizationTeamsQueryResult = ApolloReactCommon.QueryResult<OrganizationTeamsQuery, OrganizationTeamsQueryVariables>;
export const EntityOrganizationDocument = gql`
    query entityOrganization($teamName: String!) {
  entity(name: $teamName) {
    id
    organization {
      id
      name
    }
  }
}
    `;
export type EntityOrganizationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityOrganizationQuery, EntityOrganizationQueryVariables>, 'query'> & ({ variables: EntityOrganizationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntityOrganizationComponent = (props: EntityOrganizationComponentProps) => (
      <ApolloReactComponents.Query<EntityOrganizationQuery, EntityOrganizationQueryVariables> query={EntityOrganizationDocument} {...props} />
    );
    
export type EntityOrganizationProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityOrganizationQuery, EntityOrganizationQueryVariables>
    } & TChildProps;
export function withEntityOrganization<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityOrganizationQuery,
  EntityOrganizationQueryVariables,
  EntityOrganizationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityOrganizationQuery, EntityOrganizationQueryVariables, EntityOrganizationProps<TChildProps, TDataName>>(EntityOrganizationDocument, {
      alias: 'entityOrganization',
      ...operationOptions
    });
};

/**
 * __useEntityOrganizationQuery__
 *
 * To run a query within a React component, call `useEntityOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityOrganizationQuery({
 *   variables: {
 *      teamName: // value for 'teamName'
 *   },
 * });
 */
export function useEntityOrganizationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityOrganizationQuery, EntityOrganizationQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityOrganizationQuery, EntityOrganizationQueryVariables>(EntityOrganizationDocument, baseOptions);
      }
export function useEntityOrganizationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityOrganizationQuery, EntityOrganizationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityOrganizationQuery, EntityOrganizationQueryVariables>(EntityOrganizationDocument, baseOptions);
        }
export type EntityOrganizationQueryHookResult = ReturnType<typeof useEntityOrganizationQuery>;
export type EntityOrganizationLazyQueryHookResult = ReturnType<typeof useEntityOrganizationLazyQuery>;
export type EntityOrganizationQueryResult = ApolloReactCommon.QueryResult<EntityOrganizationQuery, EntityOrganizationQueryVariables>;
export const UserContextDocument = gql`
    query UserContext {
  viewer {
    id
    views(viewType: "userContext") {
      edges {
        node {
          id
          spec
        }
      }
    }
  }
}
    `;
export type UserContextComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserContextQuery, UserContextQueryVariables>, 'query'>;

    export const UserContextComponent = (props: UserContextComponentProps) => (
      <ApolloReactComponents.Query<UserContextQuery, UserContextQueryVariables> query={UserContextDocument} {...props} />
    );
    
export type UserContextProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UserContextQuery, UserContextQueryVariables>
    } & TChildProps;
export function withUserContext<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserContextQuery,
  UserContextQueryVariables,
  UserContextProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, UserContextQuery, UserContextQueryVariables, UserContextProps<TChildProps, TDataName>>(UserContextDocument, {
      alias: 'userContext',
      ...operationOptions
    });
};

/**
 * __useUserContextQuery__
 *
 * To run a query within a React component, call `useUserContextQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserContextQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserContextQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserContextQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserContextQuery, UserContextQueryVariables>) {
        return ApolloReactHooks.useQuery<UserContextQuery, UserContextQueryVariables>(UserContextDocument, baseOptions);
      }
export function useUserContextLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserContextQuery, UserContextQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserContextQuery, UserContextQueryVariables>(UserContextDocument, baseOptions);
        }
export type UserContextQueryHookResult = ReturnType<typeof useUserContextQuery>;
export type UserContextLazyQueryHookResult = ReturnType<typeof useUserContextLazyQuery>;
export type UserContextQueryResult = ApolloReactCommon.QueryResult<UserContextQuery, UserContextQueryVariables>;
export const ViewerUserInfoDocument = gql`
    query ViewerUserInfo {
  viewer {
    id
    userInfo
  }
}
    `;
export type ViewerUserInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewerUserInfoQuery, ViewerUserInfoQueryVariables>, 'query'>;

    export const ViewerUserInfoComponent = (props: ViewerUserInfoComponentProps) => (
      <ApolloReactComponents.Query<ViewerUserInfoQuery, ViewerUserInfoQueryVariables> query={ViewerUserInfoDocument} {...props} />
    );
    
export type ViewerUserInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewerUserInfoQuery, ViewerUserInfoQueryVariables>
    } & TChildProps;
export function withViewerUserInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewerUserInfoQuery,
  ViewerUserInfoQueryVariables,
  ViewerUserInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewerUserInfoQuery, ViewerUserInfoQueryVariables, ViewerUserInfoProps<TChildProps, TDataName>>(ViewerUserInfoDocument, {
      alias: 'viewerUserInfo',
      ...operationOptions
    });
};

/**
 * __useViewerUserInfoQuery__
 *
 * To run a query within a React component, call `useViewerUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerUserInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewerUserInfoQuery, ViewerUserInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewerUserInfoQuery, ViewerUserInfoQueryVariables>(ViewerUserInfoDocument, baseOptions);
      }
export function useViewerUserInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewerUserInfoQuery, ViewerUserInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewerUserInfoQuery, ViewerUserInfoQueryVariables>(ViewerUserInfoDocument, baseOptions);
        }
export type ViewerUserInfoQueryHookResult = ReturnType<typeof useViewerUserInfoQuery>;
export type ViewerUserInfoLazyQueryHookResult = ReturnType<typeof useViewerUserInfoLazyQuery>;
export type ViewerUserInfoQueryResult = ApolloReactCommon.QueryResult<ViewerUserInfoQuery, ViewerUserInfoQueryVariables>;
export const UserOrganizationsDocument = gql`
    query UserOrganizations($emailDomain: String) {
  viewer {
    id
    organizations: organizations {
      id
      name
      orgType
      members {
        id
        admin
      }
      teams {
        id
        name
      }
      subscriptions {
        id
        subscriptionType
        privileges
        plan {
          id
          name
          planType
          displayName
        }
      }
    }
    openMatchingOrganizations: organizations(emailDomain: $emailDomain) {
      id
      name
      orgType
      flags
      members {
        orgID
        id
        admin
      }
      subscriptions {
        id
        status
      }
      teams {
        id
        name
        memberCount
        settings {
          openToMatchingOrgEmailDomainUsers
        }
      }
    }
  }
}
    `;
export type UserOrganizationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserOrganizationsQuery, UserOrganizationsQueryVariables>, 'query'>;

    export const UserOrganizationsComponent = (props: UserOrganizationsComponentProps) => (
      <ApolloReactComponents.Query<UserOrganizationsQuery, UserOrganizationsQueryVariables> query={UserOrganizationsDocument} {...props} />
    );
    
export type UserOrganizationsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UserOrganizationsQuery, UserOrganizationsQueryVariables>
    } & TChildProps;
export function withUserOrganizations<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserOrganizationsQuery,
  UserOrganizationsQueryVariables,
  UserOrganizationsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, UserOrganizationsQuery, UserOrganizationsQueryVariables, UserOrganizationsProps<TChildProps, TDataName>>(UserOrganizationsDocument, {
      alias: 'userOrganizations',
      ...operationOptions
    });
};

/**
 * __useUserOrganizationsQuery__
 *
 * To run a query within a React component, call `useUserOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserOrganizationsQuery({
 *   variables: {
 *      emailDomain: // value for 'emailDomain'
 *   },
 * });
 */
export function useUserOrganizationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserOrganizationsQuery, UserOrganizationsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserOrganizationsQuery, UserOrganizationsQueryVariables>(UserOrganizationsDocument, baseOptions);
      }
export function useUserOrganizationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserOrganizationsQuery, UserOrganizationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserOrganizationsQuery, UserOrganizationsQueryVariables>(UserOrganizationsDocument, baseOptions);
        }
export type UserOrganizationsQueryHookResult = ReturnType<typeof useUserOrganizationsQuery>;
export type UserOrganizationsLazyQueryHookResult = ReturnType<typeof useUserOrganizationsLazyQuery>;
export type UserOrganizationsQueryResult = ApolloReactCommon.QueryResult<UserOrganizationsQuery, UserOrganizationsQueryVariables>;
export const EntityStorageBytesDocument = gql`
    query EntityStorageBytes($name: String!) {
  entity(name: $name) {
    id
    storageBytes
  }
}
    `;
export type EntityStorageBytesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityStorageBytesQuery, EntityStorageBytesQueryVariables>, 'query'> & ({ variables: EntityStorageBytesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntityStorageBytesComponent = (props: EntityStorageBytesComponentProps) => (
      <ApolloReactComponents.Query<EntityStorageBytesQuery, EntityStorageBytesQueryVariables> query={EntityStorageBytesDocument} {...props} />
    );
    
export type EntityStorageBytesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityStorageBytesQuery, EntityStorageBytesQueryVariables>
    } & TChildProps;
export function withEntityStorageBytes<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityStorageBytesQuery,
  EntityStorageBytesQueryVariables,
  EntityStorageBytesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityStorageBytesQuery, EntityStorageBytesQueryVariables, EntityStorageBytesProps<TChildProps, TDataName>>(EntityStorageBytesDocument, {
      alias: 'entityStorageBytes',
      ...operationOptions
    });
};

/**
 * __useEntityStorageBytesQuery__
 *
 * To run a query within a React component, call `useEntityStorageBytesQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityStorageBytesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityStorageBytesQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEntityStorageBytesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityStorageBytesQuery, EntityStorageBytesQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityStorageBytesQuery, EntityStorageBytesQueryVariables>(EntityStorageBytesDocument, baseOptions);
      }
export function useEntityStorageBytesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityStorageBytesQuery, EntityStorageBytesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityStorageBytesQuery, EntityStorageBytesQueryVariables>(EntityStorageBytesDocument, baseOptions);
        }
export type EntityStorageBytesQueryHookResult = ReturnType<typeof useEntityStorageBytesQuery>;
export type EntityStorageBytesLazyQueryHookResult = ReturnType<typeof useEntityStorageBytesLazyQuery>;
export type EntityStorageBytesQueryResult = ApolloReactCommon.QueryResult<EntityStorageBytesQuery, EntityStorageBytesQueryVariables>;
export const EntityDocument = gql`
    query Entity($name: String!) {
  entity(name: $name) {
    id
    name
    available
    photoUrl
    readOnly
    readOnlyAdmin
    isTeam
    privateOnly
    codeSavingEnabled
    artifactTTLDurationSeconds
    settings {
      hidden
      openToMatchingOrgEmailDomainUsers
      membersCanInvite
      disableMagicLinkSharing
      defaultCloudRegion {
        id
        provider
        region
      }
      storageBucketInfo {
        provider
        name
        kmsKeyID
        awsExternalID
        path
      }
      disableMembersEditArtifactTTL
    }
    defaultAccess
    isPaid
    rateLimits {
      graphql
      sdkGraphql
      filestreamCount
      filestreamSize
      sdkGraphqlQuerySeconds
    }
    members {
      id
      admin
      role
      pending
      email
      username
      name
      photoUrl
      accountType
      apiKey
    }
    defaultAlerts {
      id
      condition {
        __typename
        ... on FinishedRunCondition {
          success
        }
        ... on StoppedRunCondition {
          minimumRunDuration
        }
      }
      subscriptions {
        __typename
        ... on EmailSubscription {
          id
        }
        ... on SlackChannelSubscription {
          id
        }
      }
    }
    integrations {
      edges {
        node {
          id
          __typename
          ... on SlackIntegration {
            teamName
            channelName
          }
          ... on GenericWebhookIntegration {
            id
            urlEndpoint
            name
            accessTokenRef
            secretRef
            createdAt
          }
        }
      }
    }
    computeHours
    organization {
      id
      name
      orgType
      billingUser {
        id
        username
      }
      subscriptions {
        id
        plan {
          id
          name
          planType
        }
        privileges
        seats
        expiresAt
        subscriptionType
        status
      }
      members {
        orgID
        id
        username
        name
        photoUrl
        admin
        role
      }
      pendingInvites {
        id
        email
      }
    }
    invites {
      edges {
        node {
          id
          createdAt
          name
          toUser {
            id
            email
            username
          }
        }
      }
    }
  }
  user(userName: $name) {
    id
    username
    accountType
  }
}
    `;
export type EntityComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityQuery, EntityQueryVariables>, 'query'> & ({ variables: EntityQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntityComponent = (props: EntityComponentProps) => (
      <ApolloReactComponents.Query<EntityQuery, EntityQueryVariables> query={EntityDocument} {...props} />
    );
    
export type EntityProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityQuery, EntityQueryVariables>
    } & TChildProps;
export function withEntity<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityQuery,
  EntityQueryVariables,
  EntityProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityQuery, EntityQueryVariables, EntityProps<TChildProps, TDataName>>(EntityDocument, {
      alias: 'entity',
      ...operationOptions
    });
};

/**
 * __useEntityQuery__
 *
 * To run a query within a React component, call `useEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEntityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityQuery, EntityQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityQuery, EntityQueryVariables>(EntityDocument, baseOptions);
      }
export function useEntityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityQuery, EntityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityQuery, EntityQueryVariables>(EntityDocument, baseOptions);
        }
export type EntityQueryHookResult = ReturnType<typeof useEntityQuery>;
export type EntityLazyQueryHookResult = ReturnType<typeof useEntityLazyQuery>;
export type EntityQueryResult = ApolloReactCommon.QueryResult<EntityQuery, EntityQueryVariables>;
export const EntityIntegrationsDocument = gql`
    query EntityIntegrations($name: String!) {
  entity(name: $name) {
    id
    name
    integrations {
      edges {
        node {
          id
          __typename
          ... on SlackIntegration {
            teamName
            channelName
          }
          ... on GenericWebhookIntegration {
            id
            urlEndpoint
            name
            accessTokenRef
            secretRef
            createdAt
          }
        }
      }
    }
  }
}
    `;
export type EntityIntegrationsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityIntegrationsQuery, EntityIntegrationsQueryVariables>, 'query'> & ({ variables: EntityIntegrationsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntityIntegrationsComponent = (props: EntityIntegrationsComponentProps) => (
      <ApolloReactComponents.Query<EntityIntegrationsQuery, EntityIntegrationsQueryVariables> query={EntityIntegrationsDocument} {...props} />
    );
    
export type EntityIntegrationsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityIntegrationsQuery, EntityIntegrationsQueryVariables>
    } & TChildProps;
export function withEntityIntegrations<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityIntegrationsQuery,
  EntityIntegrationsQueryVariables,
  EntityIntegrationsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityIntegrationsQuery, EntityIntegrationsQueryVariables, EntityIntegrationsProps<TChildProps, TDataName>>(EntityIntegrationsDocument, {
      alias: 'entityIntegrations',
      ...operationOptions
    });
};

/**
 * __useEntityIntegrationsQuery__
 *
 * To run a query within a React component, call `useEntityIntegrationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityIntegrationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityIntegrationsQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useEntityIntegrationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityIntegrationsQuery, EntityIntegrationsQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityIntegrationsQuery, EntityIntegrationsQueryVariables>(EntityIntegrationsDocument, baseOptions);
      }
export function useEntityIntegrationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityIntegrationsQuery, EntityIntegrationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityIntegrationsQuery, EntityIntegrationsQueryVariables>(EntityIntegrationsDocument, baseOptions);
        }
export type EntityIntegrationsQueryHookResult = ReturnType<typeof useEntityIntegrationsQuery>;
export type EntityIntegrationsLazyQueryHookResult = ReturnType<typeof useEntityIntegrationsLazyQuery>;
export type EntityIntegrationsQueryResult = ApolloReactCommon.QueryResult<EntityIntegrationsQuery, EntityIntegrationsQueryVariables>;
export const SearchUsersDocument = gql`
    query SearchUsers($query: String) {
  users(query: $query) {
    edges {
      node {
        id
        name
        loggedInAt
        userInfo
        ...UserRoleFragment
      }
    }
  }
}
    ${UserRoleFragmentFragmentDoc}`;
export type SearchUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SearchUsersQuery, SearchUsersQueryVariables>, 'query'>;

    export const SearchUsersComponent = (props: SearchUsersComponentProps) => (
      <ApolloReactComponents.Query<SearchUsersQuery, SearchUsersQueryVariables> query={SearchUsersDocument} {...props} />
    );
    
export type SearchUsersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SearchUsersQuery, SearchUsersQueryVariables>
    } & TChildProps;
export function withSearchUsers<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SearchUsersQuery,
  SearchUsersQueryVariables,
  SearchUsersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SearchUsersQuery, SearchUsersQueryVariables, SearchUsersProps<TChildProps, TDataName>>(SearchUsersDocument, {
      alias: 'searchUsers',
      ...operationOptions
    });
};

/**
 * __useSearchUsersQuery__
 *
 * To run a query within a React component, call `useSearchUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchUsersQuery({
 *   variables: {
 *      query: // value for 'query'
 *   },
 * });
 */
export function useSearchUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, baseOptions);
      }
export function useSearchUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchUsersQuery, SearchUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchUsersQuery, SearchUsersQueryVariables>(SearchUsersDocument, baseOptions);
        }
export type SearchUsersQueryHookResult = ReturnType<typeof useSearchUsersQuery>;
export type SearchUsersLazyQueryHookResult = ReturnType<typeof useSearchUsersLazyQuery>;
export type SearchUsersQueryResult = ApolloReactCommon.QueryResult<SearchUsersQuery, SearchUsersQueryVariables>;
export const FindUsersDocument = gql`
    query FindUsers($query: String!, $limit: Int!) {
  users(query: $query, first: $limit) {
    edges {
      node {
        id
        ...UserFragment
      }
    }
  }
}
    ${UserFragmentFragmentDoc}`;
export type FindUsersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FindUsersQuery, FindUsersQueryVariables>, 'query'> & ({ variables: FindUsersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FindUsersComponent = (props: FindUsersComponentProps) => (
      <ApolloReactComponents.Query<FindUsersQuery, FindUsersQueryVariables> query={FindUsersDocument} {...props} />
    );
    
export type FindUsersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FindUsersQuery, FindUsersQueryVariables>
    } & TChildProps;
export function withFindUsers<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FindUsersQuery,
  FindUsersQueryVariables,
  FindUsersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FindUsersQuery, FindUsersQueryVariables, FindUsersProps<TChildProps, TDataName>>(FindUsersDocument, {
      alias: 'findUsers',
      ...operationOptions
    });
};

/**
 * __useFindUsersQuery__
 *
 * To run a query within a React component, call `useFindUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUsersQuery({
 *   variables: {
 *      query: // value for 'query'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useFindUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FindUsersQuery, FindUsersQueryVariables>) {
        return ApolloReactHooks.useQuery<FindUsersQuery, FindUsersQueryVariables>(FindUsersDocument, baseOptions);
      }
export function useFindUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindUsersQuery, FindUsersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FindUsersQuery, FindUsersQueryVariables>(FindUsersDocument, baseOptions);
        }
export type FindUsersQueryHookResult = ReturnType<typeof useFindUsersQuery>;
export type FindUsersLazyQueryHookResult = ReturnType<typeof useFindUsersLazyQuery>;
export type FindUsersQueryResult = ApolloReactCommon.QueryResult<FindUsersQuery, FindUsersQueryVariables>;
export const FindUsersByUsernameDocument = gql`
    query FindUsersByUsername($usernames: [String!]!, $limit: Int!) {
  users(usernames: $usernames, first: $limit) {
    edges {
      node {
        id
        ...UserFragment
      }
    }
  }
}
    ${UserFragmentFragmentDoc}`;
export type FindUsersByUsernameComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FindUsersByUsernameQuery, FindUsersByUsernameQueryVariables>, 'query'> & ({ variables: FindUsersByUsernameQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FindUsersByUsernameComponent = (props: FindUsersByUsernameComponentProps) => (
      <ApolloReactComponents.Query<FindUsersByUsernameQuery, FindUsersByUsernameQueryVariables> query={FindUsersByUsernameDocument} {...props} />
    );
    
export type FindUsersByUsernameProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FindUsersByUsernameQuery, FindUsersByUsernameQueryVariables>
    } & TChildProps;
export function withFindUsersByUsername<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FindUsersByUsernameQuery,
  FindUsersByUsernameQueryVariables,
  FindUsersByUsernameProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FindUsersByUsernameQuery, FindUsersByUsernameQueryVariables, FindUsersByUsernameProps<TChildProps, TDataName>>(FindUsersByUsernameDocument, {
      alias: 'findUsersByUsername',
      ...operationOptions
    });
};

/**
 * __useFindUsersByUsernameQuery__
 *
 * To run a query within a React component, call `useFindUsersByUsernameQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindUsersByUsernameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindUsersByUsernameQuery({
 *   variables: {
 *      usernames: // value for 'usernames'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useFindUsersByUsernameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FindUsersByUsernameQuery, FindUsersByUsernameQueryVariables>) {
        return ApolloReactHooks.useQuery<FindUsersByUsernameQuery, FindUsersByUsernameQueryVariables>(FindUsersByUsernameDocument, baseOptions);
      }
export function useFindUsersByUsernameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FindUsersByUsernameQuery, FindUsersByUsernameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FindUsersByUsernameQuery, FindUsersByUsernameQueryVariables>(FindUsersByUsernameDocument, baseOptions);
        }
export type FindUsersByUsernameQueryHookResult = ReturnType<typeof useFindUsersByUsernameQuery>;
export type FindUsersByUsernameLazyQueryHookResult = ReturnType<typeof useFindUsersByUsernameLazyQuery>;
export type FindUsersByUsernameQueryResult = ApolloReactCommon.QueryResult<FindUsersByUsernameQuery, FindUsersByUsernameQueryVariables>;
export const UpdateUserRoleDocument = gql`
    mutation UpdateUserRole($id: ID!, $role: String!) {
  updateUserRole(input: {id: $id, accountType: $role}) {
    user {
      id
      name
      username
      email
      admin
      deletedAt
      accountType
    }
  }
}
    `;
export type UpdateUserRoleMutationFn = ApolloReactCommon.MutationFunction<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;
export type UpdateUserRoleComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>, 'mutation'>;

    export const UpdateUserRoleComponent = (props: UpdateUserRoleComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserRoleMutation, UpdateUserRoleMutationVariables> mutation={UpdateUserRoleDocument} {...props} />
    );
    
export type UpdateUserRoleProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>
    } & TChildProps;
export function withUpdateUserRole<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateUserRoleMutation,
  UpdateUserRoleMutationVariables,
  UpdateUserRoleProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateUserRoleMutation, UpdateUserRoleMutationVariables, UpdateUserRoleProps<TChildProps, TDataName>>(UpdateUserRoleDocument, {
      alias: 'updateUserRole',
      ...operationOptions
    });
};

/**
 * __useUpdateUserRoleMutation__
 *
 * To run a mutation, you first call `useUpdateUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRoleMutation, { data, loading, error }] = useUpdateUserRoleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateUserRoleMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>(UpdateUserRoleDocument, baseOptions);
      }
export type UpdateUserRoleMutationHookResult = ReturnType<typeof useUpdateUserRoleMutation>;
export type UpdateUserRoleMutationResult = ApolloReactCommon.MutationResult<UpdateUserRoleMutation>;
export type UpdateUserRoleMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserRoleMutation, UpdateUserRoleMutationVariables>;
export const UpdateUserRoleV2Document = gql`
    mutation UpdateUserRoleV2($id: ID!, $role: String!) {
  updateUserRoleV2(input: {id: $id, accountType: $role}) {
    user {
      id
      name
      username
      email
      admin
      deletedAt
      accountType
    }
  }
}
    `;
export type UpdateUserRoleV2MutationFn = ApolloReactCommon.MutationFunction<UpdateUserRoleV2Mutation, UpdateUserRoleV2MutationVariables>;
export type UpdateUserRoleV2ComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserRoleV2Mutation, UpdateUserRoleV2MutationVariables>, 'mutation'>;

    export const UpdateUserRoleV2Component = (props: UpdateUserRoleV2ComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserRoleV2Mutation, UpdateUserRoleV2MutationVariables> mutation={UpdateUserRoleV2Document} {...props} />
    );
    
export type UpdateUserRoleV2Props<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateUserRoleV2Mutation, UpdateUserRoleV2MutationVariables>
    } & TChildProps;
export function withUpdateUserRoleV2<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateUserRoleV2Mutation,
  UpdateUserRoleV2MutationVariables,
  UpdateUserRoleV2Props<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateUserRoleV2Mutation, UpdateUserRoleV2MutationVariables, UpdateUserRoleV2Props<TChildProps, TDataName>>(UpdateUserRoleV2Document, {
      alias: 'updateUserRoleV2',
      ...operationOptions
    });
};

/**
 * __useUpdateUserRoleV2Mutation__
 *
 * To run a mutation, you first call `useUpdateUserRoleV2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserRoleV2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserRoleV2Mutation, { data, loading, error }] = useUpdateUserRoleV2Mutation({
 *   variables: {
 *      id: // value for 'id'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateUserRoleV2Mutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserRoleV2Mutation, UpdateUserRoleV2MutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserRoleV2Mutation, UpdateUserRoleV2MutationVariables>(UpdateUserRoleV2Document, baseOptions);
      }
export type UpdateUserRoleV2MutationHookResult = ReturnType<typeof useUpdateUserRoleV2Mutation>;
export type UpdateUserRoleV2MutationResult = ApolloReactCommon.MutationResult<UpdateUserRoleV2Mutation>;
export type UpdateUserRoleV2MutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserRoleV2Mutation, UpdateUserRoleV2MutationVariables>;
export const SetUserAdminDocument = gql`
    mutation SetUserAdmin($id: ID!, $admin: Boolean!) {
  updateUser(input: {id: $id, admin: $admin}) {
    user {
      id
      name
      username
      email
      admin
      deletedAt
    }
  }
}
    `;
export type SetUserAdminMutationFn = ApolloReactCommon.MutationFunction<SetUserAdminMutation, SetUserAdminMutationVariables>;
export type SetUserAdminComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SetUserAdminMutation, SetUserAdminMutationVariables>, 'mutation'>;

    export const SetUserAdminComponent = (props: SetUserAdminComponentProps) => (
      <ApolloReactComponents.Mutation<SetUserAdminMutation, SetUserAdminMutationVariables> mutation={SetUserAdminDocument} {...props} />
    );
    
export type SetUserAdminProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<SetUserAdminMutation, SetUserAdminMutationVariables>
    } & TChildProps;
export function withSetUserAdmin<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SetUserAdminMutation,
  SetUserAdminMutationVariables,
  SetUserAdminProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, SetUserAdminMutation, SetUserAdminMutationVariables, SetUserAdminProps<TChildProps, TDataName>>(SetUserAdminDocument, {
      alias: 'setUserAdmin',
      ...operationOptions
    });
};

/**
 * __useSetUserAdminMutation__
 *
 * To run a mutation, you first call `useSetUserAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserAdminMutation, { data, loading, error }] = useSetUserAdminMutation({
 *   variables: {
 *      id: // value for 'id'
 *      admin: // value for 'admin'
 *   },
 * });
 */
export function useSetUserAdminMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SetUserAdminMutation, SetUserAdminMutationVariables>) {
        return ApolloReactHooks.useMutation<SetUserAdminMutation, SetUserAdminMutationVariables>(SetUserAdminDocument, baseOptions);
      }
export type SetUserAdminMutationHookResult = ReturnType<typeof useSetUserAdminMutation>;
export type SetUserAdminMutationResult = ApolloReactCommon.MutationResult<SetUserAdminMutation>;
export type SetUserAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<SetUserAdminMutation, SetUserAdminMutationVariables>;
export const PurgeUserDocument = gql`
    mutation PurgeUser($username: String!, $email: String!) {
  purgeUser(input: {username: $username, email: $email}) {
    user {
      id
      deletedAt
    }
  }
}
    `;
export type PurgeUserMutationFn = ApolloReactCommon.MutationFunction<PurgeUserMutation, PurgeUserMutationVariables>;
export type PurgeUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<PurgeUserMutation, PurgeUserMutationVariables>, 'mutation'>;

    export const PurgeUserComponent = (props: PurgeUserComponentProps) => (
      <ApolloReactComponents.Mutation<PurgeUserMutation, PurgeUserMutationVariables> mutation={PurgeUserDocument} {...props} />
    );
    
export type PurgeUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<PurgeUserMutation, PurgeUserMutationVariables>
    } & TChildProps;
export function withPurgeUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PurgeUserMutation,
  PurgeUserMutationVariables,
  PurgeUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, PurgeUserMutation, PurgeUserMutationVariables, PurgeUserProps<TChildProps, TDataName>>(PurgeUserDocument, {
      alias: 'purgeUser',
      ...operationOptions
    });
};

/**
 * __usePurgeUserMutation__
 *
 * To run a mutation, you first call `usePurgeUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePurgeUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [purgeUserMutation, { data, loading, error }] = usePurgeUserMutation({
 *   variables: {
 *      username: // value for 'username'
 *      email: // value for 'email'
 *   },
 * });
 */
export function usePurgeUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<PurgeUserMutation, PurgeUserMutationVariables>) {
        return ApolloReactHooks.useMutation<PurgeUserMutation, PurgeUserMutationVariables>(PurgeUserDocument, baseOptions);
      }
export type PurgeUserMutationHookResult = ReturnType<typeof usePurgeUserMutation>;
export type PurgeUserMutationResult = ApolloReactCommon.MutationResult<PurgeUserMutation>;
export type PurgeUserMutationOptions = ApolloReactCommon.BaseMutationOptions<PurgeUserMutation, PurgeUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($id: ID!) {
  deleteUser(input: {id: $id}) {
    user {
      id
      name
      username
      email
      admin
      deletedAt
    }
  }
}
    `;
export type DeleteUserMutationFn = ApolloReactCommon.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;
export type DeleteUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteUserMutation, DeleteUserMutationVariables>, 'mutation'>;

    export const DeleteUserComponent = (props: DeleteUserComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteUserMutation, DeleteUserMutationVariables> mutation={DeleteUserDocument} {...props} />
    );
    
export type DeleteUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>
    } & TChildProps;
export function withDeleteUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteUserMutation,
  DeleteUserMutationVariables,
  DeleteUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteUserMutation, DeleteUserMutationVariables, DeleteUserProps<TChildProps, TDataName>>(DeleteUserDocument, {
      alias: 'deleteUser',
      ...operationOptions
    });
};

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, baseOptions);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = ApolloReactCommon.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const UndeleteUserDocument = gql`
    mutation UndeleteUser($id: ID!) {
  undeleteUser(input: {id: $id}) {
    user {
      id
      name
      username
      email
      admin
      deletedAt
    }
  }
}
    `;
export type UndeleteUserMutationFn = ApolloReactCommon.MutationFunction<UndeleteUserMutation, UndeleteUserMutationVariables>;
export type UndeleteUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UndeleteUserMutation, UndeleteUserMutationVariables>, 'mutation'>;

    export const UndeleteUserComponent = (props: UndeleteUserComponentProps) => (
      <ApolloReactComponents.Mutation<UndeleteUserMutation, UndeleteUserMutationVariables> mutation={UndeleteUserDocument} {...props} />
    );
    
export type UndeleteUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UndeleteUserMutation, UndeleteUserMutationVariables>
    } & TChildProps;
export function withUndeleteUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UndeleteUserMutation,
  UndeleteUserMutationVariables,
  UndeleteUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UndeleteUserMutation, UndeleteUserMutationVariables, UndeleteUserProps<TChildProps, TDataName>>(UndeleteUserDocument, {
      alias: 'undeleteUser',
      ...operationOptions
    });
};

/**
 * __useUndeleteUserMutation__
 *
 * To run a mutation, you first call `useUndeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUndeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [undeleteUserMutation, { data, loading, error }] = useUndeleteUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUndeleteUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UndeleteUserMutation, UndeleteUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UndeleteUserMutation, UndeleteUserMutationVariables>(UndeleteUserDocument, baseOptions);
      }
export type UndeleteUserMutationHookResult = ReturnType<typeof useUndeleteUserMutation>;
export type UndeleteUserMutationResult = ApolloReactCommon.MutationResult<UndeleteUserMutation>;
export type UndeleteUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UndeleteUserMutation, UndeleteUserMutationVariables>;
export const CreateInviteDocument = gql`
    mutation CreateInvite($entityName: String!, $email: String, $username: String, $admin: Boolean, $role: String, $addSeat: Boolean, $modelSeat: String) {
  createInvite(
    input: {entityName: $entityName, email: $email, username: $username, admin: $admin, role: $role, addSeat: $addSeat, modelSeat: $modelSeat}
  ) {
    invite {
      id
      name
      email
      createdAt
      toUser {
        id
        name
      }
    }
    success
    remainingSeats
    remainingViewOnlySeats
  }
}
    `;
export type CreateInviteMutationFn = ApolloReactCommon.MutationFunction<CreateInviteMutation, CreateInviteMutationVariables>;
export type CreateInviteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateInviteMutation, CreateInviteMutationVariables>, 'mutation'>;

    export const CreateInviteComponent = (props: CreateInviteComponentProps) => (
      <ApolloReactComponents.Mutation<CreateInviteMutation, CreateInviteMutationVariables> mutation={CreateInviteDocument} {...props} />
    );
    
export type CreateInviteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateInviteMutation, CreateInviteMutationVariables>
    } & TChildProps;
export function withCreateInvite<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateInviteMutation,
  CreateInviteMutationVariables,
  CreateInviteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateInviteMutation, CreateInviteMutationVariables, CreateInviteProps<TChildProps, TDataName>>(CreateInviteDocument, {
      alias: 'createInvite',
      ...operationOptions
    });
};

/**
 * __useCreateInviteMutation__
 *
 * To run a mutation, you first call `useCreateInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInviteMutation, { data, loading, error }] = useCreateInviteMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      email: // value for 'email'
 *      username: // value for 'username'
 *      admin: // value for 'admin'
 *      role: // value for 'role'
 *      addSeat: // value for 'addSeat'
 *      modelSeat: // value for 'modelSeat'
 *   },
 * });
 */
export function useCreateInviteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateInviteMutation, CreateInviteMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateInviteMutation, CreateInviteMutationVariables>(CreateInviteDocument, baseOptions);
      }
export type CreateInviteMutationHookResult = ReturnType<typeof useCreateInviteMutation>;
export type CreateInviteMutationResult = ApolloReactCommon.MutationResult<CreateInviteMutation>;
export type CreateInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateInviteMutation, CreateInviteMutationVariables>;
export const DeleteInviteDocument = gql`
    mutation DeleteInvite($id: String, $entityName: String) {
  deleteInvite(input: {id: $id, entityName: $entityName}) {
    success
  }
}
    `;
export type DeleteInviteMutationFn = ApolloReactCommon.MutationFunction<DeleteInviteMutation, DeleteInviteMutationVariables>;
export type DeleteInviteComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteInviteMutation, DeleteInviteMutationVariables>, 'mutation'>;

    export const DeleteInviteComponent = (props: DeleteInviteComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteInviteMutation, DeleteInviteMutationVariables> mutation={DeleteInviteDocument} {...props} />
    );
    
export type DeleteInviteProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteInviteMutation, DeleteInviteMutationVariables>
    } & TChildProps;
export function withDeleteInvite<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteInviteMutation,
  DeleteInviteMutationVariables,
  DeleteInviteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteInviteMutation, DeleteInviteMutationVariables, DeleteInviteProps<TChildProps, TDataName>>(DeleteInviteDocument, {
      alias: 'deleteInvite',
      ...operationOptions
    });
};

/**
 * __useDeleteInviteMutation__
 *
 * To run a mutation, you first call `useDeleteInviteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInviteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInviteMutation, { data, loading, error }] = useDeleteInviteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useDeleteInviteMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteInviteMutation, DeleteInviteMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteInviteMutation, DeleteInviteMutationVariables>(DeleteInviteDocument, baseOptions);
      }
export type DeleteInviteMutationHookResult = ReturnType<typeof useDeleteInviteMutation>;
export type DeleteInviteMutationResult = ApolloReactCommon.MutationResult<DeleteInviteMutation>;
export type DeleteInviteMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteInviteMutation, DeleteInviteMutationVariables>;
export const UpdateMemberDocument = gql`
    mutation UpdateMember($entityName: String!, $user: ID!, $role: String!) {
  updateMember(input: {entityName: $entityName, user: $user, role: $role}) {
    member {
      id
      role
    }
  }
}
    `;
export type UpdateMemberMutationFn = ApolloReactCommon.MutationFunction<UpdateMemberMutation, UpdateMemberMutationVariables>;
export type UpdateMemberComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateMemberMutation, UpdateMemberMutationVariables>, 'mutation'>;

    export const UpdateMemberComponent = (props: UpdateMemberComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateMemberMutation, UpdateMemberMutationVariables> mutation={UpdateMemberDocument} {...props} />
    );
    
export type UpdateMemberProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateMemberMutation, UpdateMemberMutationVariables>
    } & TChildProps;
export function withUpdateMember<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateMemberMutation,
  UpdateMemberMutationVariables,
  UpdateMemberProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateMemberMutation, UpdateMemberMutationVariables, UpdateMemberProps<TChildProps, TDataName>>(UpdateMemberDocument, {
      alias: 'updateMember',
      ...operationOptions
    });
};

/**
 * __useUpdateMemberMutation__
 *
 * To run a mutation, you first call `useUpdateMemberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMemberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMemberMutation, { data, loading, error }] = useUpdateMemberMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      user: // value for 'user'
 *      role: // value for 'role'
 *   },
 * });
 */
export function useUpdateMemberMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateMemberMutation, UpdateMemberMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateMemberMutation, UpdateMemberMutationVariables>(UpdateMemberDocument, baseOptions);
      }
export type UpdateMemberMutationHookResult = ReturnType<typeof useUpdateMemberMutation>;
export type UpdateMemberMutationResult = ApolloReactCommon.MutationResult<UpdateMemberMutation>;
export type UpdateMemberMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateMemberMutation, UpdateMemberMutationVariables>;
export const CreateServiceAccountDocument = gql`
    mutation CreateServiceAccount($entityName: String!, $description: String!, $organizationId: ID) {
  createServiceAccount(
    input: {description: $description, entityName: $entityName, organizationId: $organizationId}
  ) {
    user {
      id
      name
      apiKeys {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
}
    `;
export type CreateServiceAccountMutationFn = ApolloReactCommon.MutationFunction<CreateServiceAccountMutation, CreateServiceAccountMutationVariables>;
export type CreateServiceAccountComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateServiceAccountMutation, CreateServiceAccountMutationVariables>, 'mutation'>;

    export const CreateServiceAccountComponent = (props: CreateServiceAccountComponentProps) => (
      <ApolloReactComponents.Mutation<CreateServiceAccountMutation, CreateServiceAccountMutationVariables> mutation={CreateServiceAccountDocument} {...props} />
    );
    
export type CreateServiceAccountProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateServiceAccountMutation, CreateServiceAccountMutationVariables>
    } & TChildProps;
export function withCreateServiceAccount<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateServiceAccountMutation,
  CreateServiceAccountMutationVariables,
  CreateServiceAccountProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateServiceAccountMutation, CreateServiceAccountMutationVariables, CreateServiceAccountProps<TChildProps, TDataName>>(CreateServiceAccountDocument, {
      alias: 'createServiceAccount',
      ...operationOptions
    });
};

/**
 * __useCreateServiceAccountMutation__
 *
 * To run a mutation, you first call `useCreateServiceAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateServiceAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createServiceAccountMutation, { data, loading, error }] = useCreateServiceAccountMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      description: // value for 'description'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCreateServiceAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateServiceAccountMutation, CreateServiceAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateServiceAccountMutation, CreateServiceAccountMutationVariables>(CreateServiceAccountDocument, baseOptions);
      }
export type CreateServiceAccountMutationHookResult = ReturnType<typeof useCreateServiceAccountMutation>;
export type CreateServiceAccountMutationResult = ApolloReactCommon.MutationResult<CreateServiceAccountMutation>;
export type CreateServiceAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateServiceAccountMutation, CreateServiceAccountMutationVariables>;
export const CreateOrgServiceAccountDocument = gql`
    mutation CreateOrgServiceAccount($description: String!, $defaultEntityId: ID!, $organizationId: ID!) {
  createOrgServiceAccount(
    input: {description: $description, defaultEntityId: $defaultEntityId, organizationId: $organizationId}
  ) {
    user {
      id
      name
      apiKeys {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
}
    `;
export type CreateOrgServiceAccountMutationFn = ApolloReactCommon.MutationFunction<CreateOrgServiceAccountMutation, CreateOrgServiceAccountMutationVariables>;
export type CreateOrgServiceAccountComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateOrgServiceAccountMutation, CreateOrgServiceAccountMutationVariables>, 'mutation'>;

    export const CreateOrgServiceAccountComponent = (props: CreateOrgServiceAccountComponentProps) => (
      <ApolloReactComponents.Mutation<CreateOrgServiceAccountMutation, CreateOrgServiceAccountMutationVariables> mutation={CreateOrgServiceAccountDocument} {...props} />
    );
    
export type CreateOrgServiceAccountProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateOrgServiceAccountMutation, CreateOrgServiceAccountMutationVariables>
    } & TChildProps;
export function withCreateOrgServiceAccount<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateOrgServiceAccountMutation,
  CreateOrgServiceAccountMutationVariables,
  CreateOrgServiceAccountProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateOrgServiceAccountMutation, CreateOrgServiceAccountMutationVariables, CreateOrgServiceAccountProps<TChildProps, TDataName>>(CreateOrgServiceAccountDocument, {
      alias: 'createOrgServiceAccount',
      ...operationOptions
    });
};

/**
 * __useCreateOrgServiceAccountMutation__
 *
 * To run a mutation, you first call `useCreateOrgServiceAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrgServiceAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrgServiceAccountMutation, { data, loading, error }] = useCreateOrgServiceAccountMutation({
 *   variables: {
 *      description: // value for 'description'
 *      defaultEntityId: // value for 'defaultEntityId'
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useCreateOrgServiceAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateOrgServiceAccountMutation, CreateOrgServiceAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateOrgServiceAccountMutation, CreateOrgServiceAccountMutationVariables>(CreateOrgServiceAccountDocument, baseOptions);
      }
export type CreateOrgServiceAccountMutationHookResult = ReturnType<typeof useCreateOrgServiceAccountMutation>;
export type CreateOrgServiceAccountMutationResult = ApolloReactCommon.MutationResult<CreateOrgServiceAccountMutation>;
export type CreateOrgServiceAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateOrgServiceAccountMutation, CreateOrgServiceAccountMutationVariables>;
export const DeleteServiceAccountDocument = gql`
    mutation DeleteServiceAccount($id: ID!) {
  deleteServiceAccount(input: {id: $id}) {
    user {
      id
    }
  }
}
    `;
export type DeleteServiceAccountMutationFn = ApolloReactCommon.MutationFunction<DeleteServiceAccountMutation, DeleteServiceAccountMutationVariables>;
export type DeleteServiceAccountComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteServiceAccountMutation, DeleteServiceAccountMutationVariables>, 'mutation'>;

    export const DeleteServiceAccountComponent = (props: DeleteServiceAccountComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteServiceAccountMutation, DeleteServiceAccountMutationVariables> mutation={DeleteServiceAccountDocument} {...props} />
    );
    
export type DeleteServiceAccountProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteServiceAccountMutation, DeleteServiceAccountMutationVariables>
    } & TChildProps;
export function withDeleteServiceAccount<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteServiceAccountMutation,
  DeleteServiceAccountMutationVariables,
  DeleteServiceAccountProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteServiceAccountMutation, DeleteServiceAccountMutationVariables, DeleteServiceAccountProps<TChildProps, TDataName>>(DeleteServiceAccountDocument, {
      alias: 'deleteServiceAccount',
      ...operationOptions
    });
};

/**
 * __useDeleteServiceAccountMutation__
 *
 * To run a mutation, you first call `useDeleteServiceAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteServiceAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteServiceAccountMutation, { data, loading, error }] = useDeleteServiceAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteServiceAccountMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteServiceAccountMutation, DeleteServiceAccountMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteServiceAccountMutation, DeleteServiceAccountMutationVariables>(DeleteServiceAccountDocument, baseOptions);
      }
export type DeleteServiceAccountMutationHookResult = ReturnType<typeof useDeleteServiceAccountMutation>;
export type DeleteServiceAccountMutationResult = ApolloReactCommon.MutationResult<DeleteServiceAccountMutation>;
export type DeleteServiceAccountMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteServiceAccountMutation, DeleteServiceAccountMutationVariables>;
export const CreateEntityDocument = gql`
    mutation CreateEntity($name: String!, $invited: String, $framework: String) {
  createEntity(
    input: {name: $name, defaultFramework: $framework, invited: $invited}
  ) {
    entity {
      id
      name
      photoUrl
      invitedTeam
      projects(first: 100) {
        edges {
          node {
            id
            name
            entityName
          }
        }
      }
    }
    apiKey {
      id
      name
    }
  }
}
    `;
export type CreateEntityMutationFn = ApolloReactCommon.MutationFunction<CreateEntityMutation, CreateEntityMutationVariables>;
export type CreateEntityComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateEntityMutation, CreateEntityMutationVariables>, 'mutation'>;

    export const CreateEntityComponent = (props: CreateEntityComponentProps) => (
      <ApolloReactComponents.Mutation<CreateEntityMutation, CreateEntityMutationVariables> mutation={CreateEntityDocument} {...props} />
    );
    
export type CreateEntityProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateEntityMutation, CreateEntityMutationVariables>
    } & TChildProps;
export function withCreateEntity<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateEntityMutation,
  CreateEntityMutationVariables,
  CreateEntityProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateEntityMutation, CreateEntityMutationVariables, CreateEntityProps<TChildProps, TDataName>>(CreateEntityDocument, {
      alias: 'createEntity',
      ...operationOptions
    });
};

/**
 * __useCreateEntityMutation__
 *
 * To run a mutation, you first call `useCreateEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEntityMutation, { data, loading, error }] = useCreateEntityMutation({
 *   variables: {
 *      name: // value for 'name'
 *      invited: // value for 'invited'
 *      framework: // value for 'framework'
 *   },
 * });
 */
export function useCreateEntityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEntityMutation, CreateEntityMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEntityMutation, CreateEntityMutationVariables>(CreateEntityDocument, baseOptions);
      }
export type CreateEntityMutationHookResult = ReturnType<typeof useCreateEntityMutation>;
export type CreateEntityMutationResult = ApolloReactCommon.MutationResult<CreateEntityMutation>;
export type CreateEntityMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEntityMutation, CreateEntityMutationVariables>;
export const CreateUserFromAdminDocument = gql`
    mutation CreateUserFromAdmin($email: String!, $admin: Boolean, $accountType: String) {
  createUser(input: {email: $email, admin: $admin, accountType: $accountType}) {
    user {
      id
      name
      username
      email
      admin
    }
  }
}
    `;
export type CreateUserFromAdminMutationFn = ApolloReactCommon.MutationFunction<CreateUserFromAdminMutation, CreateUserFromAdminMutationVariables>;
export type CreateUserFromAdminComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateUserFromAdminMutation, CreateUserFromAdminMutationVariables>, 'mutation'>;

    export const CreateUserFromAdminComponent = (props: CreateUserFromAdminComponentProps) => (
      <ApolloReactComponents.Mutation<CreateUserFromAdminMutation, CreateUserFromAdminMutationVariables> mutation={CreateUserFromAdminDocument} {...props} />
    );
    
export type CreateUserFromAdminProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateUserFromAdminMutation, CreateUserFromAdminMutationVariables>
    } & TChildProps;
export function withCreateUserFromAdmin<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateUserFromAdminMutation,
  CreateUserFromAdminMutationVariables,
  CreateUserFromAdminProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateUserFromAdminMutation, CreateUserFromAdminMutationVariables, CreateUserFromAdminProps<TChildProps, TDataName>>(CreateUserFromAdminDocument, {
      alias: 'createUserFromAdmin',
      ...operationOptions
    });
};

/**
 * __useCreateUserFromAdminMutation__
 *
 * To run a mutation, you first call `useCreateUserFromAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserFromAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserFromAdminMutation, { data, loading, error }] = useCreateUserFromAdminMutation({
 *   variables: {
 *      email: // value for 'email'
 *      admin: // value for 'admin'
 *      accountType: // value for 'accountType'
 *   },
 * });
 */
export function useCreateUserFromAdminMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserFromAdminMutation, CreateUserFromAdminMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserFromAdminMutation, CreateUserFromAdminMutationVariables>(CreateUserFromAdminDocument, baseOptions);
      }
export type CreateUserFromAdminMutationHookResult = ReturnType<typeof useCreateUserFromAdminMutation>;
export type CreateUserFromAdminMutationResult = ApolloReactCommon.MutationResult<CreateUserFromAdminMutation>;
export type CreateUserFromAdminMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserFromAdminMutation, CreateUserFromAdminMutationVariables>;
export const CreateEntityOnboardingFlowDocument = gql`
    mutation CreateEntityOnboardingFlow($name: String!) {
  createEntity(input: {name: $name}) {
    entity {
      id
    }
  }
}
    `;
export type CreateEntityOnboardingFlowMutationFn = ApolloReactCommon.MutationFunction<CreateEntityOnboardingFlowMutation, CreateEntityOnboardingFlowMutationVariables>;
export type CreateEntityOnboardingFlowComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateEntityOnboardingFlowMutation, CreateEntityOnboardingFlowMutationVariables>, 'mutation'>;

    export const CreateEntityOnboardingFlowComponent = (props: CreateEntityOnboardingFlowComponentProps) => (
      <ApolloReactComponents.Mutation<CreateEntityOnboardingFlowMutation, CreateEntityOnboardingFlowMutationVariables> mutation={CreateEntityOnboardingFlowDocument} {...props} />
    );
    
export type CreateEntityOnboardingFlowProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateEntityOnboardingFlowMutation, CreateEntityOnboardingFlowMutationVariables>
    } & TChildProps;
export function withCreateEntityOnboardingFlow<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateEntityOnboardingFlowMutation,
  CreateEntityOnboardingFlowMutationVariables,
  CreateEntityOnboardingFlowProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateEntityOnboardingFlowMutation, CreateEntityOnboardingFlowMutationVariables, CreateEntityOnboardingFlowProps<TChildProps, TDataName>>(CreateEntityOnboardingFlowDocument, {
      alias: 'createEntityOnboardingFlow',
      ...operationOptions
    });
};

/**
 * __useCreateEntityOnboardingFlowMutation__
 *
 * To run a mutation, you first call `useCreateEntityOnboardingFlowMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEntityOnboardingFlowMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEntityOnboardingFlowMutation, { data, loading, error }] = useCreateEntityOnboardingFlowMutation({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateEntityOnboardingFlowMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateEntityOnboardingFlowMutation, CreateEntityOnboardingFlowMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateEntityOnboardingFlowMutation, CreateEntityOnboardingFlowMutationVariables>(CreateEntityOnboardingFlowDocument, baseOptions);
      }
export type CreateEntityOnboardingFlowMutationHookResult = ReturnType<typeof useCreateEntityOnboardingFlowMutation>;
export type CreateEntityOnboardingFlowMutationResult = ApolloReactCommon.MutationResult<CreateEntityOnboardingFlowMutation>;
export type CreateEntityOnboardingFlowMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateEntityOnboardingFlowMutation, CreateEntityOnboardingFlowMutationVariables>;
export const CheckAvailabilityDocument = gql`
    query CheckAvailability($name: String) {
  entity(name: $name) {
    id
    available
  }
}
    `;
export type CheckAvailabilityComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CheckAvailabilityQuery, CheckAvailabilityQueryVariables>, 'query'>;

    export const CheckAvailabilityComponent = (props: CheckAvailabilityComponentProps) => (
      <ApolloReactComponents.Query<CheckAvailabilityQuery, CheckAvailabilityQueryVariables> query={CheckAvailabilityDocument} {...props} />
    );
    
export type CheckAvailabilityProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CheckAvailabilityQuery, CheckAvailabilityQueryVariables>
    } & TChildProps;
export function withCheckAvailability<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CheckAvailabilityQuery,
  CheckAvailabilityQueryVariables,
  CheckAvailabilityProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CheckAvailabilityQuery, CheckAvailabilityQueryVariables, CheckAvailabilityProps<TChildProps, TDataName>>(CheckAvailabilityDocument, {
      alias: 'checkAvailability',
      ...operationOptions
    });
};

/**
 * __useCheckAvailabilityQuery__
 *
 * To run a query within a React component, call `useCheckAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckAvailabilityQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCheckAvailabilityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CheckAvailabilityQuery, CheckAvailabilityQueryVariables>) {
        return ApolloReactHooks.useQuery<CheckAvailabilityQuery, CheckAvailabilityQueryVariables>(CheckAvailabilityDocument, baseOptions);
      }
export function useCheckAvailabilityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CheckAvailabilityQuery, CheckAvailabilityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CheckAvailabilityQuery, CheckAvailabilityQueryVariables>(CheckAvailabilityDocument, baseOptions);
        }
export type CheckAvailabilityQueryHookResult = ReturnType<typeof useCheckAvailabilityQuery>;
export type CheckAvailabilityLazyQueryHookResult = ReturnType<typeof useCheckAvailabilityLazyQuery>;
export type CheckAvailabilityQueryResult = ApolloReactCommon.QueryResult<CheckAvailabilityQuery, CheckAvailabilityQueryVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($defaultEntity: String, $defaultFramework: String, $photoUrl: String, $code: String, $settingsVisited: Boolean, $galleryVisited: Boolean, $onboardingHidden: Boolean, $hideTeamsFromPublic: Boolean) {
  updateUser(
    input: {defaultEntity: $defaultEntity, defaultFramework: $defaultFramework, photoUrl: $photoUrl, code: $code, settingsVisited: $settingsVisited, galleryVisited: $galleryVisited, onboardingHidden: $onboardingHidden, hideTeamsFromPublic: $hideTeamsFromPublic}
  ) {
    user {
      id
      flags
      entity
      hideTeamsFromPublic
      teams {
        edges {
          node {
            id
            name
            isTeam
          }
        }
      }
    }
  }
}
    `;
export type UpdateUserMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;
export type UpdateUserComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserMutation, UpdateUserMutationVariables>, 'mutation'>;

    export const UpdateUserComponent = (props: UpdateUserComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserMutation, UpdateUserMutationVariables> mutation={UpdateUserDocument} {...props} />
    );
    
export type UpdateUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>
    } & TChildProps;
export function withUpdateUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UpdateUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateUserMutation, UpdateUserMutationVariables, UpdateUserProps<TChildProps, TDataName>>(UpdateUserDocument, {
      alias: 'updateUser',
      ...operationOptions
    });
};

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      defaultEntity: // value for 'defaultEntity'
 *      defaultFramework: // value for 'defaultFramework'
 *      photoUrl: // value for 'photoUrl'
 *      code: // value for 'code'
 *      settingsVisited: // value for 'settingsVisited'
 *      galleryVisited: // value for 'galleryVisited'
 *      onboardingHidden: // value for 'onboardingHidden'
 *      hideTeamsFromPublic: // value for 'hideTeamsFromPublic'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, baseOptions);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const UpdateUserInfoDocument = gql`
    mutation UpdateUserInfo($id: ID, $name: String, $photoUrl: String, $userInfo: JSONString, $defaultEntity: String, $primaryEmail: String, $password: String) {
  updateUser(
    input: {id: $id, name: $name, userInfo: $userInfo, defaultEntity: $defaultEntity, photoUrl: $photoUrl, primaryEmail: $primaryEmail, password: $password}
  ) {
    user {
      id
      name
      userInfo
      photoUrl
      entity
      teams {
        edges {
          node {
            id
            name
            isTeam
          }
        }
      }
    }
  }
}
    `;
export type UpdateUserInfoMutationFn = ApolloReactCommon.MutationFunction<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>;
export type UpdateUserInfoComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>, 'mutation'>;

    export const UpdateUserInfoComponent = (props: UpdateUserInfoComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserInfoMutation, UpdateUserInfoMutationVariables> mutation={UpdateUserInfoDocument} {...props} />
    );
    
export type UpdateUserInfoProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>
    } & TChildProps;
export function withUpdateUserInfo<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateUserInfoMutation,
  UpdateUserInfoMutationVariables,
  UpdateUserInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateUserInfoMutation, UpdateUserInfoMutationVariables, UpdateUserInfoProps<TChildProps, TDataName>>(UpdateUserInfoDocument, {
      alias: 'updateUserInfo',
      ...operationOptions
    });
};

/**
 * __useUpdateUserInfoMutation__
 *
 * To run a mutation, you first call `useUpdateUserInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserInfoMutation, { data, loading, error }] = useUpdateUserInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      photoUrl: // value for 'photoUrl'
 *      userInfo: // value for 'userInfo'
 *      defaultEntity: // value for 'defaultEntity'
 *      primaryEmail: // value for 'primaryEmail'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useUpdateUserInfoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>(UpdateUserInfoDocument, baseOptions);
      }
export type UpdateUserInfoMutationHookResult = ReturnType<typeof useUpdateUserInfoMutation>;
export type UpdateUserInfoMutationResult = ApolloReactCommon.MutationResult<UpdateUserInfoMutation>;
export type UpdateUserInfoMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserInfoMutation, UpdateUserInfoMutationVariables>;
export const UpdateUserEmailDocument = gql`
    mutation UpdateUserEmail($id: ID!, $type: EmailType) {
  updateUserEmail(input: {id: $id, type: $type}) {
    success
    email {
      id
      emailAddress
    }
  }
}
    `;
export type UpdateUserEmailMutationFn = ApolloReactCommon.MutationFunction<UpdateUserEmailMutation, UpdateUserEmailMutationVariables>;
export type UpdateUserEmailComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateUserEmailMutation, UpdateUserEmailMutationVariables>, 'mutation'>;

    export const UpdateUserEmailComponent = (props: UpdateUserEmailComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateUserEmailMutation, UpdateUserEmailMutationVariables> mutation={UpdateUserEmailDocument} {...props} />
    );
    
export type UpdateUserEmailProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateUserEmailMutation, UpdateUserEmailMutationVariables>
    } & TChildProps;
export function withUpdateUserEmail<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateUserEmailMutation,
  UpdateUserEmailMutationVariables,
  UpdateUserEmailProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateUserEmailMutation, UpdateUserEmailMutationVariables, UpdateUserEmailProps<TChildProps, TDataName>>(UpdateUserEmailDocument, {
      alias: 'updateUserEmail',
      ...operationOptions
    });
};

/**
 * __useUpdateUserEmailMutation__
 *
 * To run a mutation, you first call `useUpdateUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserEmailMutation, { data, loading, error }] = useUpdateUserEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useUpdateUserEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserEmailMutation, UpdateUserEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserEmailMutation, UpdateUserEmailMutationVariables>(UpdateUserEmailDocument, baseOptions);
      }
export type UpdateUserEmailMutationHookResult = ReturnType<typeof useUpdateUserEmailMutation>;
export type UpdateUserEmailMutationResult = ApolloReactCommon.MutationResult<UpdateUserEmailMutation>;
export type UpdateUserEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserEmailMutation, UpdateUserEmailMutationVariables>;
export const DeleteUserEmailDocument = gql`
    mutation DeleteUserEmail($id: ID!) {
  deleteUserEmail(input: {id: $id}) {
    success
  }
}
    `;
export type DeleteUserEmailMutationFn = ApolloReactCommon.MutationFunction<DeleteUserEmailMutation, DeleteUserEmailMutationVariables>;
export type DeleteUserEmailComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteUserEmailMutation, DeleteUserEmailMutationVariables>, 'mutation'>;

    export const DeleteUserEmailComponent = (props: DeleteUserEmailComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteUserEmailMutation, DeleteUserEmailMutationVariables> mutation={DeleteUserEmailDocument} {...props} />
    );
    
export type DeleteUserEmailProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteUserEmailMutation, DeleteUserEmailMutationVariables>
    } & TChildProps;
export function withDeleteUserEmail<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteUserEmailMutation,
  DeleteUserEmailMutationVariables,
  DeleteUserEmailProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteUserEmailMutation, DeleteUserEmailMutationVariables, DeleteUserEmailProps<TChildProps, TDataName>>(DeleteUserEmailDocument, {
      alias: 'deleteUserEmail',
      ...operationOptions
    });
};

/**
 * __useDeleteUserEmailMutation__
 *
 * To run a mutation, you first call `useDeleteUserEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserEmailMutation, { data, loading, error }] = useDeleteUserEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteUserEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteUserEmailMutation, DeleteUserEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteUserEmailMutation, DeleteUserEmailMutationVariables>(DeleteUserEmailDocument, baseOptions);
      }
export type DeleteUserEmailMutationHookResult = ReturnType<typeof useDeleteUserEmailMutation>;
export type DeleteUserEmailMutationResult = ApolloReactCommon.MutationResult<DeleteUserEmailMutation>;
export type DeleteUserEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteUserEmailMutation, DeleteUserEmailMutationVariables>;
export const GenerateApiKeyDocument = gql`
    mutation GenerateApiKey($description: String) {
  generateApiKey(input: {description: $description}) {
    apiKey {
      id
      name
    }
  }
}
    `;
export type GenerateApiKeyMutationFn = ApolloReactCommon.MutationFunction<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>;
export type GenerateApiKeyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>, 'mutation'>;

    export const GenerateApiKeyComponent = (props: GenerateApiKeyComponentProps) => (
      <ApolloReactComponents.Mutation<GenerateApiKeyMutation, GenerateApiKeyMutationVariables> mutation={GenerateApiKeyDocument} {...props} />
    );
    
export type GenerateApiKeyProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>
    } & TChildProps;
export function withGenerateApiKey<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GenerateApiKeyMutation,
  GenerateApiKeyMutationVariables,
  GenerateApiKeyProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, GenerateApiKeyMutation, GenerateApiKeyMutationVariables, GenerateApiKeyProps<TChildProps, TDataName>>(GenerateApiKeyDocument, {
      alias: 'generateApiKey',
      ...operationOptions
    });
};

/**
 * __useGenerateApiKeyMutation__
 *
 * To run a mutation, you first call `useGenerateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateApiKeyMutation, { data, loading, error }] = useGenerateApiKeyMutation({
 *   variables: {
 *      description: // value for 'description'
 *   },
 * });
 */
export function useGenerateApiKeyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>) {
        return ApolloReactHooks.useMutation<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>(GenerateApiKeyDocument, baseOptions);
      }
export type GenerateApiKeyMutationHookResult = ReturnType<typeof useGenerateApiKeyMutation>;
export type GenerateApiKeyMutationResult = ApolloReactCommon.MutationResult<GenerateApiKeyMutation>;
export type GenerateApiKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>;
export const DeleteApiKeyDocument = gql`
    mutation DeleteApiKey($id: String!) {
  deleteApiKey(input: {id: $id}) {
    success
  }
}
    `;
export type DeleteApiKeyMutationFn = ApolloReactCommon.MutationFunction<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>;
export type DeleteApiKeyComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>, 'mutation'>;

    export const DeleteApiKeyComponent = (props: DeleteApiKeyComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteApiKeyMutation, DeleteApiKeyMutationVariables> mutation={DeleteApiKeyDocument} {...props} />
    );
    
export type DeleteApiKeyProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>
    } & TChildProps;
export function withDeleteApiKey<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteApiKeyMutation,
  DeleteApiKeyMutationVariables,
  DeleteApiKeyProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteApiKeyMutation, DeleteApiKeyMutationVariables, DeleteApiKeyProps<TChildProps, TDataName>>(DeleteApiKeyDocument, {
      alias: 'deleteApiKey',
      ...operationOptions
    });
};

/**
 * __useDeleteApiKeyMutation__
 *
 * To run a mutation, you first call `useDeleteApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteApiKeyMutation, { data, loading, error }] = useDeleteApiKeyMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteApiKeyMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>(DeleteApiKeyDocument, baseOptions);
      }
export type DeleteApiKeyMutationHookResult = ReturnType<typeof useDeleteApiKeyMutation>;
export type DeleteApiKeyMutationResult = ApolloReactCommon.MutationResult<DeleteApiKeyMutation>;
export type DeleteApiKeyMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteApiKeyMutation, DeleteApiKeyMutationVariables>;
export const UpdateEntityDocument = gql`
    mutation UpdateEntity($entityName: String!, $defaultAccess: String, $privateOnly: Boolean, $codeSavingEnabled: Boolean, $settings: EntitySettingsInput) {
  updateEntity(
    input: {entity: $entityName, defaultAccess: $defaultAccess, privateOnly: $privateOnly, codeSavingEnabled: $codeSavingEnabled, settings: $settings}
  ) {
    entity {
      id
      defaultAccess
      privateOnly
      limits
      settings {
        hidden
        defaultCloudRegion {
          id
          provider
          region
        }
      }
    }
  }
}
    `;
export type UpdateEntityMutationFn = ApolloReactCommon.MutationFunction<UpdateEntityMutation, UpdateEntityMutationVariables>;
export type UpdateEntityComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateEntityMutation, UpdateEntityMutationVariables>, 'mutation'>;

    export const UpdateEntityComponent = (props: UpdateEntityComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateEntityMutation, UpdateEntityMutationVariables> mutation={UpdateEntityDocument} {...props} />
    );
    
export type UpdateEntityProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateEntityMutation, UpdateEntityMutationVariables>
    } & TChildProps;
export function withUpdateEntity<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateEntityMutation,
  UpdateEntityMutationVariables,
  UpdateEntityProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateEntityMutation, UpdateEntityMutationVariables, UpdateEntityProps<TChildProps, TDataName>>(UpdateEntityDocument, {
      alias: 'updateEntity',
      ...operationOptions
    });
};

/**
 * __useUpdateEntityMutation__
 *
 * To run a mutation, you first call `useUpdateEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntityMutation, { data, loading, error }] = useUpdateEntityMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      defaultAccess: // value for 'defaultAccess'
 *      privateOnly: // value for 'privateOnly'
 *      codeSavingEnabled: // value for 'codeSavingEnabled'
 *      settings: // value for 'settings'
 *   },
 * });
 */
export function useUpdateEntityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateEntityMutation, UpdateEntityMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateEntityMutation, UpdateEntityMutationVariables>(UpdateEntityDocument, baseOptions);
      }
export type UpdateEntityMutationHookResult = ReturnType<typeof useUpdateEntityMutation>;
export type UpdateEntityMutationResult = ApolloReactCommon.MutationResult<UpdateEntityMutation>;
export type UpdateEntityMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateEntityMutation, UpdateEntityMutationVariables>;
export const ClaimAnonymousEntityDocument = gql`
    mutation ClaimAnonymousEntity($anonymousApiKey: String!) {
  claimAnonymousEntity(input: {anonymousApiKey: $anonymousApiKey}) {
    task {
      id
      type
      state
      progress
      name
    }
  }
}
    `;
export type ClaimAnonymousEntityMutationFn = ApolloReactCommon.MutationFunction<ClaimAnonymousEntityMutation, ClaimAnonymousEntityMutationVariables>;
export type ClaimAnonymousEntityComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ClaimAnonymousEntityMutation, ClaimAnonymousEntityMutationVariables>, 'mutation'>;

    export const ClaimAnonymousEntityComponent = (props: ClaimAnonymousEntityComponentProps) => (
      <ApolloReactComponents.Mutation<ClaimAnonymousEntityMutation, ClaimAnonymousEntityMutationVariables> mutation={ClaimAnonymousEntityDocument} {...props} />
    );
    
export type ClaimAnonymousEntityProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ClaimAnonymousEntityMutation, ClaimAnonymousEntityMutationVariables>
    } & TChildProps;
export function withClaimAnonymousEntity<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ClaimAnonymousEntityMutation,
  ClaimAnonymousEntityMutationVariables,
  ClaimAnonymousEntityProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ClaimAnonymousEntityMutation, ClaimAnonymousEntityMutationVariables, ClaimAnonymousEntityProps<TChildProps, TDataName>>(ClaimAnonymousEntityDocument, {
      alias: 'claimAnonymousEntity',
      ...operationOptions
    });
};

/**
 * __useClaimAnonymousEntityMutation__
 *
 * To run a mutation, you first call `useClaimAnonymousEntityMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClaimAnonymousEntityMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [claimAnonymousEntityMutation, { data, loading, error }] = useClaimAnonymousEntityMutation({
 *   variables: {
 *      anonymousApiKey: // value for 'anonymousApiKey'
 *   },
 * });
 */
export function useClaimAnonymousEntityMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ClaimAnonymousEntityMutation, ClaimAnonymousEntityMutationVariables>) {
        return ApolloReactHooks.useMutation<ClaimAnonymousEntityMutation, ClaimAnonymousEntityMutationVariables>(ClaimAnonymousEntityDocument, baseOptions);
      }
export type ClaimAnonymousEntityMutationHookResult = ReturnType<typeof useClaimAnonymousEntityMutation>;
export type ClaimAnonymousEntityMutationResult = ApolloReactCommon.MutationResult<ClaimAnonymousEntityMutation>;
export type ClaimAnonymousEntityMutationOptions = ApolloReactCommon.BaseMutationOptions<ClaimAnonymousEntityMutation, ClaimAnonymousEntityMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation ResetPassword($email: String!) {
  resetPassword(input: {email: $email}) {
    success
  }
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;
export type ResetPasswordComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ResetPasswordMutation, ResetPasswordMutationVariables>, 'mutation'>;

    export const ResetPasswordComponent = (props: ResetPasswordComponentProps) => (
      <ApolloReactComponents.Mutation<ResetPasswordMutation, ResetPasswordMutationVariables> mutation={ResetPasswordDocument} {...props} />
    );
    
export type ResetPasswordProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>
    } & TChildProps;
export function withResetPassword<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ResetPasswordMutation,
  ResetPasswordMutationVariables,
  ResetPasswordProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ResetPasswordMutation, ResetPasswordMutationVariables, ResetPasswordProps<TChildProps, TDataName>>(ResetPasswordDocument, {
      alias: 'resetPassword',
      ...operationOptions
    });
};

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const ViewerDocument = gql`
    query Viewer($includeStorage: Boolean = false) {
  viewer {
    id
    analyticsId
    admin
    email
    entity
    defaultFramework
    photoUrl
    flags
    code
    username
    createdAt
    name
    accountType
    userInfo
    hideTeamsFromPublic
    limits
    signupRequired
    personalEntityDisabled
    instanceAdminTeamAccessDisabled
    teams(first: 100) {
      edges {
        node {
          id
          name
          photoUrl
          defaultAccess
          readOnlyAdmin
          privateOnly
          isTeam
          organizationId
        }
      }
    }
    organizations {
      id
      name
      orgType
      subscriptions {
        id
        subscriptionType
      }
    }
    defaultEntity {
      id
      name
    }
    userEntity {
      id
      name
      defaultAccess
      codeSavingEnabled
      storageBytes @include(if: $includeStorage)
      settings {
        defaultCloudRegion {
          id
          provider
          region
        }
      }
      isTeam
      defaultAlerts {
        id
        condition {
          __typename
          ... on FinishedRunCondition {
            success
          }
          ... on StoppedRunCondition {
            minimumRunDuration
          }
        }
        subscriptions {
          __typename
          ... on EmailSubscription {
            id
          }
          ... on SlackChannelSubscription {
            id
          }
        }
      }
      integrations {
        edges {
          node {
            id
            __typename
            ... on SlackIntegration {
              teamName
              channelName
            }
          }
        }
      }
      claimedEntities {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
}
    `;
export type ViewerComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewerQuery, ViewerQueryVariables>, 'query'>;

    export const ViewerComponent = (props: ViewerComponentProps) => (
      <ApolloReactComponents.Query<ViewerQuery, ViewerQueryVariables> query={ViewerDocument} {...props} />
    );
    
export type ViewerProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewerQuery, ViewerQueryVariables>
    } & TChildProps;
export function withViewer<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewerQuery,
  ViewerQueryVariables,
  ViewerProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewerQuery, ViewerQueryVariables, ViewerProps<TChildProps, TDataName>>(ViewerDocument, {
      alias: 'viewer',
      ...operationOptions
    });
};

/**
 * __useViewerQuery__
 *
 * To run a query within a React component, call `useViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerQuery({
 *   variables: {
 *      includeStorage: // value for 'includeStorage'
 *   },
 * });
 */
export function useViewerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewerQuery, ViewerQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewerQuery, ViewerQueryVariables>(ViewerDocument, baseOptions);
      }
export function useViewerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewerQuery, ViewerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewerQuery, ViewerQueryVariables>(ViewerDocument, baseOptions);
        }
export type ViewerQueryHookResult = ReturnType<typeof useViewerQuery>;
export type ViewerLazyQueryHookResult = ReturnType<typeof useViewerLazyQuery>;
export type ViewerQueryResult = ApolloReactCommon.QueryResult<ViewerQuery, ViewerQueryVariables>;
export const ViewerPhotoUrlDocument = gql`
    query ViewerPhotoURL {
  viewer {
    id
    photoUrl
  }
}
    `;
export type ViewerPhotoUrlComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewerPhotoUrlQuery, ViewerPhotoUrlQueryVariables>, 'query'>;

    export const ViewerPhotoUrlComponent = (props: ViewerPhotoUrlComponentProps) => (
      <ApolloReactComponents.Query<ViewerPhotoUrlQuery, ViewerPhotoUrlQueryVariables> query={ViewerPhotoUrlDocument} {...props} />
    );
    
export type ViewerPhotoUrlProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewerPhotoUrlQuery, ViewerPhotoUrlQueryVariables>
    } & TChildProps;
export function withViewerPhotoUrl<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewerPhotoUrlQuery,
  ViewerPhotoUrlQueryVariables,
  ViewerPhotoUrlProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewerPhotoUrlQuery, ViewerPhotoUrlQueryVariables, ViewerPhotoUrlProps<TChildProps, TDataName>>(ViewerPhotoUrlDocument, {
      alias: 'viewerPhotoUrl',
      ...operationOptions
    });
};

/**
 * __useViewerPhotoUrlQuery__
 *
 * To run a query within a React component, call `useViewerPhotoUrlQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerPhotoUrlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerPhotoUrlQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerPhotoUrlQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewerPhotoUrlQuery, ViewerPhotoUrlQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewerPhotoUrlQuery, ViewerPhotoUrlQueryVariables>(ViewerPhotoUrlDocument, baseOptions);
      }
export function useViewerPhotoUrlLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewerPhotoUrlQuery, ViewerPhotoUrlQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewerPhotoUrlQuery, ViewerPhotoUrlQueryVariables>(ViewerPhotoUrlDocument, baseOptions);
        }
export type ViewerPhotoUrlQueryHookResult = ReturnType<typeof useViewerPhotoUrlQuery>;
export type ViewerPhotoUrlLazyQueryHookResult = ReturnType<typeof useViewerPhotoUrlLazyQuery>;
export type ViewerPhotoUrlQueryResult = ApolloReactCommon.QueryResult<ViewerPhotoUrlQuery, ViewerPhotoUrlQueryVariables>;
export const ViewerProjectsDocument = gql`
    query ViewerProjects($entityName: String, $name: String) {
  viewer {
    id
    entity
    photoUrl
    username
    createdAt
    linkedProjects: projects(
      first: 100
      linkedBenchmark: {EntityName: $entityName, Name: $name}
    ) {
      edges {
        node {
          id
          name
          entityName
          runs(order: "createdAt", first: 50) {
            edges {
              node {
                id
                name
                notes
                summaryMetrics
                project {
                  id
                  entityName
                  name
                }
                displayName
                createdAt
                benchmarkRun {
                  id
                  results
                  originalRun {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
    projects(first: 100) {
      edges {
        node {
          id
          name
          entityName
        }
      }
    }
  }
}
    `;
export type ViewerProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewerProjectsQuery, ViewerProjectsQueryVariables>, 'query'>;

    export const ViewerProjectsComponent = (props: ViewerProjectsComponentProps) => (
      <ApolloReactComponents.Query<ViewerProjectsQuery, ViewerProjectsQueryVariables> query={ViewerProjectsDocument} {...props} />
    );
    
export type ViewerProjectsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewerProjectsQuery, ViewerProjectsQueryVariables>
    } & TChildProps;
export function withViewerProjects<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewerProjectsQuery,
  ViewerProjectsQueryVariables,
  ViewerProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewerProjectsQuery, ViewerProjectsQueryVariables, ViewerProjectsProps<TChildProps, TDataName>>(ViewerProjectsDocument, {
      alias: 'viewerProjects',
      ...operationOptions
    });
};

/**
 * __useViewerProjectsQuery__
 *
 * To run a query within a React component, call `useViewerProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerProjectsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useViewerProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewerProjectsQuery, ViewerProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewerProjectsQuery, ViewerProjectsQueryVariables>(ViewerProjectsDocument, baseOptions);
      }
export function useViewerProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewerProjectsQuery, ViewerProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewerProjectsQuery, ViewerProjectsQueryVariables>(ViewerProjectsDocument, baseOptions);
        }
export type ViewerProjectsQueryHookResult = ReturnType<typeof useViewerProjectsQuery>;
export type ViewerProjectsLazyQueryHookResult = ReturnType<typeof useViewerProjectsLazyQuery>;
export type ViewerProjectsQueryResult = ApolloReactCommon.QueryResult<ViewerProjectsQuery, ViewerProjectsQueryVariables>;
export const ViewerFeatureFlagsDocument = gql`
    query viewerFeatureFlags($rampIDType: RampIDType!) {
  viewer {
    id
    featureFlags(rampIDType: $rampIDType) {
      rampKey
      isEnabled
    }
  }
}
    `;
export type ViewerFeatureFlagsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewerFeatureFlagsQuery, ViewerFeatureFlagsQueryVariables>, 'query'> & ({ variables: ViewerFeatureFlagsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ViewerFeatureFlagsComponent = (props: ViewerFeatureFlagsComponentProps) => (
      <ApolloReactComponents.Query<ViewerFeatureFlagsQuery, ViewerFeatureFlagsQueryVariables> query={ViewerFeatureFlagsDocument} {...props} />
    );
    
export type ViewerFeatureFlagsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewerFeatureFlagsQuery, ViewerFeatureFlagsQueryVariables>
    } & TChildProps;
export function withViewerFeatureFlags<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewerFeatureFlagsQuery,
  ViewerFeatureFlagsQueryVariables,
  ViewerFeatureFlagsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewerFeatureFlagsQuery, ViewerFeatureFlagsQueryVariables, ViewerFeatureFlagsProps<TChildProps, TDataName>>(ViewerFeatureFlagsDocument, {
      alias: 'viewerFeatureFlags',
      ...operationOptions
    });
};

/**
 * __useViewerFeatureFlagsQuery__
 *
 * To run a query within a React component, call `useViewerFeatureFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerFeatureFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerFeatureFlagsQuery({
 *   variables: {
 *      rampIDType: // value for 'rampIDType'
 *   },
 * });
 */
export function useViewerFeatureFlagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewerFeatureFlagsQuery, ViewerFeatureFlagsQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewerFeatureFlagsQuery, ViewerFeatureFlagsQueryVariables>(ViewerFeatureFlagsDocument, baseOptions);
      }
export function useViewerFeatureFlagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewerFeatureFlagsQuery, ViewerFeatureFlagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewerFeatureFlagsQuery, ViewerFeatureFlagsQueryVariables>(ViewerFeatureFlagsDocument, baseOptions);
        }
export type ViewerFeatureFlagsQueryHookResult = ReturnType<typeof useViewerFeatureFlagsQuery>;
export type ViewerFeatureFlagsLazyQueryHookResult = ReturnType<typeof useViewerFeatureFlagsLazyQuery>;
export type ViewerFeatureFlagsQueryResult = ApolloReactCommon.QueryResult<ViewerFeatureFlagsQuery, ViewerFeatureFlagsQueryVariables>;
export const UpsertViewDocument = gql`
    mutation upsertView($id: ID, $entityName: String, $projectName: String, $type: String, $name: String, $description: String, $spec: String!, $specIsGzipped: Boolean) {
  upsertView(
    input: {id: $id, entityName: $entityName, projectName: $projectName, name: $name, description: $description, type: $type, spec: $spec, specIsGzipped: $specIsGzipped}
  ) {
    view {
      id
      ...ViewFragment
    }
    inserted
  }
}
    ${ViewFragmentFragmentDoc}`;
export type UpsertViewMutationFn = ApolloReactCommon.MutationFunction<UpsertViewMutation, UpsertViewMutationVariables>;
export type UpsertViewComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertViewMutation, UpsertViewMutationVariables>, 'mutation'>;

    export const UpsertViewComponent = (props: UpsertViewComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertViewMutation, UpsertViewMutationVariables> mutation={UpsertViewDocument} {...props} />
    );
    
export type UpsertViewProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpsertViewMutation, UpsertViewMutationVariables>
    } & TChildProps;
export function withUpsertView<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpsertViewMutation,
  UpsertViewMutationVariables,
  UpsertViewProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpsertViewMutation, UpsertViewMutationVariables, UpsertViewProps<TChildProps, TDataName>>(UpsertViewDocument, {
      alias: 'upsertView',
      ...operationOptions
    });
};

/**
 * __useUpsertViewMutation__
 *
 * To run a mutation, you first call `useUpsertViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertViewMutation, { data, loading, error }] = useUpsertViewMutation({
 *   variables: {
 *      id: // value for 'id'
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      type: // value for 'type'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      spec: // value for 'spec'
 *      specIsGzipped: // value for 'specIsGzipped'
 *   },
 * });
 */
export function useUpsertViewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertViewMutation, UpsertViewMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertViewMutation, UpsertViewMutationVariables>(UpsertViewDocument, baseOptions);
      }
export type UpsertViewMutationHookResult = ReturnType<typeof useUpsertViewMutation>;
export type UpsertViewMutationResult = ApolloReactCommon.MutationResult<UpsertViewMutation>;
export type UpsertViewMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertViewMutation, UpsertViewMutationVariables>;
export const DeleteViewDocument = gql`
    mutation deleteView($id: ID) {
  deleteView(input: {id: $id}) {
    success
  }
}
    `;
export type DeleteViewMutationFn = ApolloReactCommon.MutationFunction<DeleteViewMutation, DeleteViewMutationVariables>;
export type DeleteViewComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteViewMutation, DeleteViewMutationVariables>, 'mutation'>;

    export const DeleteViewComponent = (props: DeleteViewComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteViewMutation, DeleteViewMutationVariables> mutation={DeleteViewDocument} {...props} />
    );
    
export type DeleteViewProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteViewMutation, DeleteViewMutationVariables>
    } & TChildProps;
export function withDeleteView<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteViewMutation,
  DeleteViewMutationVariables,
  DeleteViewProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteViewMutation, DeleteViewMutationVariables, DeleteViewProps<TChildProps, TDataName>>(DeleteViewDocument, {
      alias: 'deleteView',
      ...operationOptions
    });
};

/**
 * __useDeleteViewMutation__
 *
 * To run a mutation, you first call `useDeleteViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteViewMutation, { data, loading, error }] = useDeleteViewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteViewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteViewMutation, DeleteViewMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteViewMutation, DeleteViewMutationVariables>(DeleteViewDocument, baseOptions);
      }
export type DeleteViewMutationHookResult = ReturnType<typeof useDeleteViewMutation>;
export type DeleteViewMutationResult = ApolloReactCommon.MutationResult<DeleteViewMutation>;
export type DeleteViewMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteViewMutation, DeleteViewMutationVariables>;
export const CreateSharedViewDocument = gql`
    mutation createSharedView($entityName: String!, $name: String!, $type: String!, $description: String, $spec: String!) {
  upsertSharedView(
    input: {entityName: $entityName, name: $name, type: $type, description: $description, spec: $spec}
  ) {
    view {
      id
      ...ViewFragment
    }
  }
}
    ${ViewFragmentFragmentDoc}`;
export type CreateSharedViewMutationFn = ApolloReactCommon.MutationFunction<CreateSharedViewMutation, CreateSharedViewMutationVariables>;
export type CreateSharedViewComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateSharedViewMutation, CreateSharedViewMutationVariables>, 'mutation'>;

    export const CreateSharedViewComponent = (props: CreateSharedViewComponentProps) => (
      <ApolloReactComponents.Mutation<CreateSharedViewMutation, CreateSharedViewMutationVariables> mutation={CreateSharedViewDocument} {...props} />
    );
    
export type CreateSharedViewProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateSharedViewMutation, CreateSharedViewMutationVariables>
    } & TChildProps;
export function withCreateSharedView<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateSharedViewMutation,
  CreateSharedViewMutationVariables,
  CreateSharedViewProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateSharedViewMutation, CreateSharedViewMutationVariables, CreateSharedViewProps<TChildProps, TDataName>>(CreateSharedViewDocument, {
      alias: 'createSharedView',
      ...operationOptions
    });
};

/**
 * __useCreateSharedViewMutation__
 *
 * To run a mutation, you first call `useCreateSharedViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSharedViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSharedViewMutation, { data, loading, error }] = useCreateSharedViewMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      description: // value for 'description'
 *      spec: // value for 'spec'
 *   },
 * });
 */
export function useCreateSharedViewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateSharedViewMutation, CreateSharedViewMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateSharedViewMutation, CreateSharedViewMutationVariables>(CreateSharedViewDocument, baseOptions);
      }
export type CreateSharedViewMutationHookResult = ReturnType<typeof useCreateSharedViewMutation>;
export type CreateSharedViewMutationResult = ApolloReactCommon.MutationResult<CreateSharedViewMutation>;
export type CreateSharedViewMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateSharedViewMutation, CreateSharedViewMutationVariables>;
export const ViewParentDocument = gql`
    query viewParent($id: ID!) {
  viewParent(id: $id) {
    id
    displayName
  }
}
    `;
export type ViewParentComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewParentQuery, ViewParentQueryVariables>, 'query'> & ({ variables: ViewParentQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ViewParentComponent = (props: ViewParentComponentProps) => (
      <ApolloReactComponents.Query<ViewParentQuery, ViewParentQueryVariables> query={ViewParentDocument} {...props} />
    );
    
export type ViewParentProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewParentQuery, ViewParentQueryVariables>
    } & TChildProps;
export function withViewParent<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewParentQuery,
  ViewParentQueryVariables,
  ViewParentProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewParentQuery, ViewParentQueryVariables, ViewParentProps<TChildProps, TDataName>>(ViewParentDocument, {
      alias: 'viewParent',
      ...operationOptions
    });
};

/**
 * __useViewParentQuery__
 *
 * To run a query within a React component, call `useViewParentQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewParentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewParentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useViewParentQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewParentQuery, ViewParentQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewParentQuery, ViewParentQueryVariables>(ViewParentDocument, baseOptions);
      }
export function useViewParentLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewParentQuery, ViewParentQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewParentQuery, ViewParentQueryVariables>(ViewParentDocument, baseOptions);
        }
export type ViewParentQueryHookResult = ReturnType<typeof useViewParentQuery>;
export type ViewParentLazyQueryHookResult = ReturnType<typeof useViewParentLazyQuery>;
export type ViewParentQueryResult = ApolloReactCommon.QueryResult<ViewParentQuery, ViewParentQueryVariables>;
export const ViewChildrenDocument = gql`
    query viewChildren($id: ID!) {
  view(id: $id) {
    id
    children {
      edges {
        node {
          id
          displayName
          user {
            id
          }
        }
      }
    }
  }
}
    `;
export type ViewChildrenComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewChildrenQuery, ViewChildrenQueryVariables>, 'query'> & ({ variables: ViewChildrenQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ViewChildrenComponent = (props: ViewChildrenComponentProps) => (
      <ApolloReactComponents.Query<ViewChildrenQuery, ViewChildrenQueryVariables> query={ViewChildrenDocument} {...props} />
    );
    
export type ViewChildrenProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewChildrenQuery, ViewChildrenQueryVariables>
    } & TChildProps;
export function withViewChildren<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewChildrenQuery,
  ViewChildrenQueryVariables,
  ViewChildrenProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewChildrenQuery, ViewChildrenQueryVariables, ViewChildrenProps<TChildProps, TDataName>>(ViewChildrenDocument, {
      alias: 'viewChildren',
      ...operationOptions
    });
};

/**
 * __useViewChildrenQuery__
 *
 * To run a query within a React component, call `useViewChildrenQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewChildrenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewChildrenQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useViewChildrenQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewChildrenQuery, ViewChildrenQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewChildrenQuery, ViewChildrenQueryVariables>(ViewChildrenDocument, baseOptions);
      }
export function useViewChildrenLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewChildrenQuery, ViewChildrenQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewChildrenQuery, ViewChildrenQueryVariables>(ViewChildrenDocument, baseOptions);
        }
export type ViewChildrenQueryHookResult = ReturnType<typeof useViewChildrenQuery>;
export type ViewChildrenLazyQueryHookResult = ReturnType<typeof useViewChildrenLazyQuery>;
export type ViewChildrenQueryResult = ApolloReactCommon.QueryResult<ViewChildrenQuery, ViewChildrenQueryVariables>;
export const AcademicApplicationOrgInfoDocument = gql`
    query AcademicApplicationOrgInfo($organizationID: ID!) {
  organization(id: $organizationID) {
    id
    name
    subscriptions {
      id
      plan {
        id
        name
        planType
      }
    }
  }
}
    `;
export type AcademicApplicationOrgInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AcademicApplicationOrgInfoQuery, AcademicApplicationOrgInfoQueryVariables>, 'query'> & ({ variables: AcademicApplicationOrgInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AcademicApplicationOrgInfoComponent = (props: AcademicApplicationOrgInfoComponentProps) => (
      <ApolloReactComponents.Query<AcademicApplicationOrgInfoQuery, AcademicApplicationOrgInfoQueryVariables> query={AcademicApplicationOrgInfoDocument} {...props} />
    );
    
export type AcademicApplicationOrgInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AcademicApplicationOrgInfoQuery, AcademicApplicationOrgInfoQueryVariables>
    } & TChildProps;
export function withAcademicApplicationOrgInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AcademicApplicationOrgInfoQuery,
  AcademicApplicationOrgInfoQueryVariables,
  AcademicApplicationOrgInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AcademicApplicationOrgInfoQuery, AcademicApplicationOrgInfoQueryVariables, AcademicApplicationOrgInfoProps<TChildProps, TDataName>>(AcademicApplicationOrgInfoDocument, {
      alias: 'academicApplicationOrgInfo',
      ...operationOptions
    });
};

/**
 * __useAcademicApplicationOrgInfoQuery__
 *
 * To run a query within a React component, call `useAcademicApplicationOrgInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useAcademicApplicationOrgInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAcademicApplicationOrgInfoQuery({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *   },
 * });
 */
export function useAcademicApplicationOrgInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AcademicApplicationOrgInfoQuery, AcademicApplicationOrgInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<AcademicApplicationOrgInfoQuery, AcademicApplicationOrgInfoQueryVariables>(AcademicApplicationOrgInfoDocument, baseOptions);
      }
export function useAcademicApplicationOrgInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AcademicApplicationOrgInfoQuery, AcademicApplicationOrgInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AcademicApplicationOrgInfoQuery, AcademicApplicationOrgInfoQueryVariables>(AcademicApplicationOrgInfoDocument, baseOptions);
        }
export type AcademicApplicationOrgInfoQueryHookResult = ReturnType<typeof useAcademicApplicationOrgInfoQuery>;
export type AcademicApplicationOrgInfoLazyQueryHookResult = ReturnType<typeof useAcademicApplicationOrgInfoLazyQuery>;
export type AcademicApplicationOrgInfoQueryResult = ApolloReactCommon.QueryResult<AcademicApplicationOrgInfoQuery, AcademicApplicationOrgInfoQueryVariables>;
export const CurrentBillingPeriodUsageSectionOrgInfoDocument = gql`
    query CurrentBillingPeriodUsageSectionOrgInfo($orgName: String!) {
  organization(name: $orgName) {
    id
    createdAt
    teams {
      id
      storageBytes
    }
    subscriptions {
      id
      billingPeriodStart
      billingPeriodEnd
      subscriptionType
      plan {
        id
        name
        planType
      }
    }
  }
}
    `;
export type CurrentBillingPeriodUsageSectionOrgInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CurrentBillingPeriodUsageSectionOrgInfoQuery, CurrentBillingPeriodUsageSectionOrgInfoQueryVariables>, 'query'> & ({ variables: CurrentBillingPeriodUsageSectionOrgInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CurrentBillingPeriodUsageSectionOrgInfoComponent = (props: CurrentBillingPeriodUsageSectionOrgInfoComponentProps) => (
      <ApolloReactComponents.Query<CurrentBillingPeriodUsageSectionOrgInfoQuery, CurrentBillingPeriodUsageSectionOrgInfoQueryVariables> query={CurrentBillingPeriodUsageSectionOrgInfoDocument} {...props} />
    );
    
export type CurrentBillingPeriodUsageSectionOrgInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CurrentBillingPeriodUsageSectionOrgInfoQuery, CurrentBillingPeriodUsageSectionOrgInfoQueryVariables>
    } & TChildProps;
export function withCurrentBillingPeriodUsageSectionOrgInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CurrentBillingPeriodUsageSectionOrgInfoQuery,
  CurrentBillingPeriodUsageSectionOrgInfoQueryVariables,
  CurrentBillingPeriodUsageSectionOrgInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CurrentBillingPeriodUsageSectionOrgInfoQuery, CurrentBillingPeriodUsageSectionOrgInfoQueryVariables, CurrentBillingPeriodUsageSectionOrgInfoProps<TChildProps, TDataName>>(CurrentBillingPeriodUsageSectionOrgInfoDocument, {
      alias: 'currentBillingPeriodUsageSectionOrgInfo',
      ...operationOptions
    });
};

/**
 * __useCurrentBillingPeriodUsageSectionOrgInfoQuery__
 *
 * To run a query within a React component, call `useCurrentBillingPeriodUsageSectionOrgInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentBillingPeriodUsageSectionOrgInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentBillingPeriodUsageSectionOrgInfoQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useCurrentBillingPeriodUsageSectionOrgInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentBillingPeriodUsageSectionOrgInfoQuery, CurrentBillingPeriodUsageSectionOrgInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrentBillingPeriodUsageSectionOrgInfoQuery, CurrentBillingPeriodUsageSectionOrgInfoQueryVariables>(CurrentBillingPeriodUsageSectionOrgInfoDocument, baseOptions);
      }
export function useCurrentBillingPeriodUsageSectionOrgInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentBillingPeriodUsageSectionOrgInfoQuery, CurrentBillingPeriodUsageSectionOrgInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrentBillingPeriodUsageSectionOrgInfoQuery, CurrentBillingPeriodUsageSectionOrgInfoQueryVariables>(CurrentBillingPeriodUsageSectionOrgInfoDocument, baseOptions);
        }
export type CurrentBillingPeriodUsageSectionOrgInfoQueryHookResult = ReturnType<typeof useCurrentBillingPeriodUsageSectionOrgInfoQuery>;
export type CurrentBillingPeriodUsageSectionOrgInfoLazyQueryHookResult = ReturnType<typeof useCurrentBillingPeriodUsageSectionOrgInfoLazyQuery>;
export type CurrentBillingPeriodUsageSectionOrgInfoQueryResult = ApolloReactCommon.QueryResult<CurrentBillingPeriodUsageSectionOrgInfoQuery, CurrentBillingPeriodUsageSectionOrgInfoQueryVariables>;
export const EnterpriseBillingPeriodOrgInfoDocument = gql`
    query EnterpriseBillingPeriodOrgInfo($orgName: String!) {
  organization(name: $orgName) {
    id
    weaveEnterpriseSubscription {
      billingPeriodStart
      billingPeriodEnd
    }
  }
}
    `;
export type EnterpriseBillingPeriodOrgInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EnterpriseBillingPeriodOrgInfoQuery, EnterpriseBillingPeriodOrgInfoQueryVariables>, 'query'> & ({ variables: EnterpriseBillingPeriodOrgInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EnterpriseBillingPeriodOrgInfoComponent = (props: EnterpriseBillingPeriodOrgInfoComponentProps) => (
      <ApolloReactComponents.Query<EnterpriseBillingPeriodOrgInfoQuery, EnterpriseBillingPeriodOrgInfoQueryVariables> query={EnterpriseBillingPeriodOrgInfoDocument} {...props} />
    );
    
export type EnterpriseBillingPeriodOrgInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EnterpriseBillingPeriodOrgInfoQuery, EnterpriseBillingPeriodOrgInfoQueryVariables>
    } & TChildProps;
export function withEnterpriseBillingPeriodOrgInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EnterpriseBillingPeriodOrgInfoQuery,
  EnterpriseBillingPeriodOrgInfoQueryVariables,
  EnterpriseBillingPeriodOrgInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EnterpriseBillingPeriodOrgInfoQuery, EnterpriseBillingPeriodOrgInfoQueryVariables, EnterpriseBillingPeriodOrgInfoProps<TChildProps, TDataName>>(EnterpriseBillingPeriodOrgInfoDocument, {
      alias: 'enterpriseBillingPeriodOrgInfo',
      ...operationOptions
    });
};

/**
 * __useEnterpriseBillingPeriodOrgInfoQuery__
 *
 * To run a query within a React component, call `useEnterpriseBillingPeriodOrgInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useEnterpriseBillingPeriodOrgInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEnterpriseBillingPeriodOrgInfoQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useEnterpriseBillingPeriodOrgInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EnterpriseBillingPeriodOrgInfoQuery, EnterpriseBillingPeriodOrgInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<EnterpriseBillingPeriodOrgInfoQuery, EnterpriseBillingPeriodOrgInfoQueryVariables>(EnterpriseBillingPeriodOrgInfoDocument, baseOptions);
      }
export function useEnterpriseBillingPeriodOrgInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EnterpriseBillingPeriodOrgInfoQuery, EnterpriseBillingPeriodOrgInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EnterpriseBillingPeriodOrgInfoQuery, EnterpriseBillingPeriodOrgInfoQueryVariables>(EnterpriseBillingPeriodOrgInfoDocument, baseOptions);
        }
export type EnterpriseBillingPeriodOrgInfoQueryHookResult = ReturnType<typeof useEnterpriseBillingPeriodOrgInfoQuery>;
export type EnterpriseBillingPeriodOrgInfoLazyQueryHookResult = ReturnType<typeof useEnterpriseBillingPeriodOrgInfoLazyQuery>;
export type EnterpriseBillingPeriodOrgInfoQueryResult = ApolloReactCommon.QueryResult<EnterpriseBillingPeriodOrgInfoQuery, EnterpriseBillingPeriodOrgInfoQueryVariables>;
export const CurrentPlanPageOrgInfoDocument = gql`
    query CurrentPlanPageOrgInfo($organizationID: ID!) {
  organization(id: $organizationID) {
    id
    name
    subscriptions {
      id
      plan {
        id
        planType
        name
      }
    }
    stripeBillingInfo {
      currentPeriodEnd
    }
  }
}
    `;
export type CurrentPlanPageOrgInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<CurrentPlanPageOrgInfoQuery, CurrentPlanPageOrgInfoQueryVariables>, 'query'> & ({ variables: CurrentPlanPageOrgInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const CurrentPlanPageOrgInfoComponent = (props: CurrentPlanPageOrgInfoComponentProps) => (
      <ApolloReactComponents.Query<CurrentPlanPageOrgInfoQuery, CurrentPlanPageOrgInfoQueryVariables> query={CurrentPlanPageOrgInfoDocument} {...props} />
    );
    
export type CurrentPlanPageOrgInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<CurrentPlanPageOrgInfoQuery, CurrentPlanPageOrgInfoQueryVariables>
    } & TChildProps;
export function withCurrentPlanPageOrgInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CurrentPlanPageOrgInfoQuery,
  CurrentPlanPageOrgInfoQueryVariables,
  CurrentPlanPageOrgInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, CurrentPlanPageOrgInfoQuery, CurrentPlanPageOrgInfoQueryVariables, CurrentPlanPageOrgInfoProps<TChildProps, TDataName>>(CurrentPlanPageOrgInfoDocument, {
      alias: 'currentPlanPageOrgInfo',
      ...operationOptions
    });
};

/**
 * __useCurrentPlanPageOrgInfoQuery__
 *
 * To run a query within a React component, call `useCurrentPlanPageOrgInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useCurrentPlanPageOrgInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCurrentPlanPageOrgInfoQuery({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *   },
 * });
 */
export function useCurrentPlanPageOrgInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<CurrentPlanPageOrgInfoQuery, CurrentPlanPageOrgInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<CurrentPlanPageOrgInfoQuery, CurrentPlanPageOrgInfoQueryVariables>(CurrentPlanPageOrgInfoDocument, baseOptions);
      }
export function useCurrentPlanPageOrgInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<CurrentPlanPageOrgInfoQuery, CurrentPlanPageOrgInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<CurrentPlanPageOrgInfoQuery, CurrentPlanPageOrgInfoQueryVariables>(CurrentPlanPageOrgInfoDocument, baseOptions);
        }
export type CurrentPlanPageOrgInfoQueryHookResult = ReturnType<typeof useCurrentPlanPageOrgInfoQuery>;
export type CurrentPlanPageOrgInfoLazyQueryHookResult = ReturnType<typeof useCurrentPlanPageOrgInfoLazyQuery>;
export type CurrentPlanPageOrgInfoQueryResult = ApolloReactCommon.QueryResult<CurrentPlanPageOrgInfoQuery, CurrentPlanPageOrgInfoQueryVariables>;
export const PrimaryPlanCardV3OrgInfoDocument = gql`
    query PrimaryPlanCardV3OrgInfo($organizationID: ID!) {
  organization(id: $organizationID) {
    id
    name
    usedSeats
    createdAt
    teams {
      id
      computeHours
      storageBytes
    }
    subscriptions {
      id
      expiresAt
      seats
      billingPeriodStart
      billingPeriodEnd
      subscriptionType
      discount {
        start
        end
        coupon {
          id
          amountOff
          percentOff
          duration
          currency
          name
        }
      }
      plan {
        id
        displayName
        planType
        name
        stripePrice {
          amount
        }
        billingInterval
        unitPrice
      }
    }
  }
}
    `;
export type PrimaryPlanCardV3OrgInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PrimaryPlanCardV3OrgInfoQuery, PrimaryPlanCardV3OrgInfoQueryVariables>, 'query'> & ({ variables: PrimaryPlanCardV3OrgInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PrimaryPlanCardV3OrgInfoComponent = (props: PrimaryPlanCardV3OrgInfoComponentProps) => (
      <ApolloReactComponents.Query<PrimaryPlanCardV3OrgInfoQuery, PrimaryPlanCardV3OrgInfoQueryVariables> query={PrimaryPlanCardV3OrgInfoDocument} {...props} />
    );
    
export type PrimaryPlanCardV3OrgInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<PrimaryPlanCardV3OrgInfoQuery, PrimaryPlanCardV3OrgInfoQueryVariables>
    } & TChildProps;
export function withPrimaryPlanCardV3OrgInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PrimaryPlanCardV3OrgInfoQuery,
  PrimaryPlanCardV3OrgInfoQueryVariables,
  PrimaryPlanCardV3OrgInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, PrimaryPlanCardV3OrgInfoQuery, PrimaryPlanCardV3OrgInfoQueryVariables, PrimaryPlanCardV3OrgInfoProps<TChildProps, TDataName>>(PrimaryPlanCardV3OrgInfoDocument, {
      alias: 'primaryPlanCardV3OrgInfo',
      ...operationOptions
    });
};

/**
 * __usePrimaryPlanCardV3OrgInfoQuery__
 *
 * To run a query within a React component, call `usePrimaryPlanCardV3OrgInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePrimaryPlanCardV3OrgInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePrimaryPlanCardV3OrgInfoQuery({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *   },
 * });
 */
export function usePrimaryPlanCardV3OrgInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PrimaryPlanCardV3OrgInfoQuery, PrimaryPlanCardV3OrgInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<PrimaryPlanCardV3OrgInfoQuery, PrimaryPlanCardV3OrgInfoQueryVariables>(PrimaryPlanCardV3OrgInfoDocument, baseOptions);
      }
export function usePrimaryPlanCardV3OrgInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PrimaryPlanCardV3OrgInfoQuery, PrimaryPlanCardV3OrgInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PrimaryPlanCardV3OrgInfoQuery, PrimaryPlanCardV3OrgInfoQueryVariables>(PrimaryPlanCardV3OrgInfoDocument, baseOptions);
        }
export type PrimaryPlanCardV3OrgInfoQueryHookResult = ReturnType<typeof usePrimaryPlanCardV3OrgInfoQuery>;
export type PrimaryPlanCardV3OrgInfoLazyQueryHookResult = ReturnType<typeof usePrimaryPlanCardV3OrgInfoLazyQuery>;
export type PrimaryPlanCardV3OrgInfoQueryResult = ApolloReactCommon.QueryResult<PrimaryPlanCardV3OrgInfoQuery, PrimaryPlanCardV3OrgInfoQueryVariables>;
export const StoragePlanCardV2OrgInfoDocument = gql`
    query StoragePlanCardV2OrgInfo($organizationID: ID!) {
  organization(id: $organizationID) {
    id
    name
    createdAt
    teams {
      id
      storageBytes
      referenceBytes
    }
    subscriptions {
      id
      plan {
        id
        name
        planType
      }
      billingPeriodStart
      billingPeriodEnd
      subscriptionType
    }
  }
}
    `;
export type StoragePlanCardV2OrgInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StoragePlanCardV2OrgInfoQuery, StoragePlanCardV2OrgInfoQueryVariables>, 'query'> & ({ variables: StoragePlanCardV2OrgInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const StoragePlanCardV2OrgInfoComponent = (props: StoragePlanCardV2OrgInfoComponentProps) => (
      <ApolloReactComponents.Query<StoragePlanCardV2OrgInfoQuery, StoragePlanCardV2OrgInfoQueryVariables> query={StoragePlanCardV2OrgInfoDocument} {...props} />
    );
    
export type StoragePlanCardV2OrgInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<StoragePlanCardV2OrgInfoQuery, StoragePlanCardV2OrgInfoQueryVariables>
    } & TChildProps;
export function withStoragePlanCardV2OrgInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  StoragePlanCardV2OrgInfoQuery,
  StoragePlanCardV2OrgInfoQueryVariables,
  StoragePlanCardV2OrgInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, StoragePlanCardV2OrgInfoQuery, StoragePlanCardV2OrgInfoQueryVariables, StoragePlanCardV2OrgInfoProps<TChildProps, TDataName>>(StoragePlanCardV2OrgInfoDocument, {
      alias: 'storagePlanCardV2OrgInfo',
      ...operationOptions
    });
};

/**
 * __useStoragePlanCardV2OrgInfoQuery__
 *
 * To run a query within a React component, call `useStoragePlanCardV2OrgInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useStoragePlanCardV2OrgInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStoragePlanCardV2OrgInfoQuery({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *   },
 * });
 */
export function useStoragePlanCardV2OrgInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StoragePlanCardV2OrgInfoQuery, StoragePlanCardV2OrgInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<StoragePlanCardV2OrgInfoQuery, StoragePlanCardV2OrgInfoQueryVariables>(StoragePlanCardV2OrgInfoDocument, baseOptions);
      }
export function useStoragePlanCardV2OrgInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StoragePlanCardV2OrgInfoQuery, StoragePlanCardV2OrgInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StoragePlanCardV2OrgInfoQuery, StoragePlanCardV2OrgInfoQueryVariables>(StoragePlanCardV2OrgInfoDocument, baseOptions);
        }
export type StoragePlanCardV2OrgInfoQueryHookResult = ReturnType<typeof useStoragePlanCardV2OrgInfoQuery>;
export type StoragePlanCardV2OrgInfoLazyQueryHookResult = ReturnType<typeof useStoragePlanCardV2OrgInfoLazyQuery>;
export type StoragePlanCardV2OrgInfoQueryResult = ApolloReactCommon.QueryResult<StoragePlanCardV2OrgInfoQuery, StoragePlanCardV2OrgInfoQueryVariables>;
export const ServerCurrentBillingPeriodUsageSectionDocument = gql`
    query ServerCurrentBillingPeriodUsageSection {
  serverInfo {
    weaveLimits {
      weaveLimitBytes
      weaveOverageCostCents
      weaveOverageUnit
    }
    contractStartDate
  }
}
    `;
export type ServerCurrentBillingPeriodUsageSectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ServerCurrentBillingPeriodUsageSectionQuery, ServerCurrentBillingPeriodUsageSectionQueryVariables>, 'query'>;

    export const ServerCurrentBillingPeriodUsageSectionComponent = (props: ServerCurrentBillingPeriodUsageSectionComponentProps) => (
      <ApolloReactComponents.Query<ServerCurrentBillingPeriodUsageSectionQuery, ServerCurrentBillingPeriodUsageSectionQueryVariables> query={ServerCurrentBillingPeriodUsageSectionDocument} {...props} />
    );
    
export type ServerCurrentBillingPeriodUsageSectionProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ServerCurrentBillingPeriodUsageSectionQuery, ServerCurrentBillingPeriodUsageSectionQueryVariables>
    } & TChildProps;
export function withServerCurrentBillingPeriodUsageSection<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ServerCurrentBillingPeriodUsageSectionQuery,
  ServerCurrentBillingPeriodUsageSectionQueryVariables,
  ServerCurrentBillingPeriodUsageSectionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ServerCurrentBillingPeriodUsageSectionQuery, ServerCurrentBillingPeriodUsageSectionQueryVariables, ServerCurrentBillingPeriodUsageSectionProps<TChildProps, TDataName>>(ServerCurrentBillingPeriodUsageSectionDocument, {
      alias: 'serverCurrentBillingPeriodUsageSection',
      ...operationOptions
    });
};

/**
 * __useServerCurrentBillingPeriodUsageSectionQuery__
 *
 * To run a query within a React component, call `useServerCurrentBillingPeriodUsageSectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useServerCurrentBillingPeriodUsageSectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServerCurrentBillingPeriodUsageSectionQuery({
 *   variables: {
 *   },
 * });
 */
export function useServerCurrentBillingPeriodUsageSectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ServerCurrentBillingPeriodUsageSectionQuery, ServerCurrentBillingPeriodUsageSectionQueryVariables>) {
        return ApolloReactHooks.useQuery<ServerCurrentBillingPeriodUsageSectionQuery, ServerCurrentBillingPeriodUsageSectionQueryVariables>(ServerCurrentBillingPeriodUsageSectionDocument, baseOptions);
      }
export function useServerCurrentBillingPeriodUsageSectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ServerCurrentBillingPeriodUsageSectionQuery, ServerCurrentBillingPeriodUsageSectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ServerCurrentBillingPeriodUsageSectionQuery, ServerCurrentBillingPeriodUsageSectionQueryVariables>(ServerCurrentBillingPeriodUsageSectionDocument, baseOptions);
        }
export type ServerCurrentBillingPeriodUsageSectionQueryHookResult = ReturnType<typeof useServerCurrentBillingPeriodUsageSectionQuery>;
export type ServerCurrentBillingPeriodUsageSectionLazyQueryHookResult = ReturnType<typeof useServerCurrentBillingPeriodUsageSectionLazyQuery>;
export type ServerCurrentBillingPeriodUsageSectionQueryResult = ApolloReactCommon.QueryResult<ServerCurrentBillingPeriodUsageSectionQuery, ServerCurrentBillingPeriodUsageSectionQueryVariables>;
export const TotalUsageSectionOrgInfoDocument = gql`
    query TotalUsageSectionOrgInfo($organizationID: ID!) {
  organization(id: $organizationID) {
    id
    usedSeats
    subscriptions {
      id
      privileges
      seats
      subscriptionType
      billingPeriodEnd
      plan {
        id
        name
        planType
      }
    }
    teams {
      id
      computeHours
      storageBytes
      referenceBytes
    }
  }
}
    `;
export type TotalUsageSectionOrgInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TotalUsageSectionOrgInfoQuery, TotalUsageSectionOrgInfoQueryVariables>, 'query'> & ({ variables: TotalUsageSectionOrgInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TotalUsageSectionOrgInfoComponent = (props: TotalUsageSectionOrgInfoComponentProps) => (
      <ApolloReactComponents.Query<TotalUsageSectionOrgInfoQuery, TotalUsageSectionOrgInfoQueryVariables> query={TotalUsageSectionOrgInfoDocument} {...props} />
    );
    
export type TotalUsageSectionOrgInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TotalUsageSectionOrgInfoQuery, TotalUsageSectionOrgInfoQueryVariables>
    } & TChildProps;
export function withTotalUsageSectionOrgInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TotalUsageSectionOrgInfoQuery,
  TotalUsageSectionOrgInfoQueryVariables,
  TotalUsageSectionOrgInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TotalUsageSectionOrgInfoQuery, TotalUsageSectionOrgInfoQueryVariables, TotalUsageSectionOrgInfoProps<TChildProps, TDataName>>(TotalUsageSectionOrgInfoDocument, {
      alias: 'totalUsageSectionOrgInfo',
      ...operationOptions
    });
};

/**
 * __useTotalUsageSectionOrgInfoQuery__
 *
 * To run a query within a React component, call `useTotalUsageSectionOrgInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTotalUsageSectionOrgInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTotalUsageSectionOrgInfoQuery({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *   },
 * });
 */
export function useTotalUsageSectionOrgInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TotalUsageSectionOrgInfoQuery, TotalUsageSectionOrgInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<TotalUsageSectionOrgInfoQuery, TotalUsageSectionOrgInfoQueryVariables>(TotalUsageSectionOrgInfoDocument, baseOptions);
      }
export function useTotalUsageSectionOrgInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TotalUsageSectionOrgInfoQuery, TotalUsageSectionOrgInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TotalUsageSectionOrgInfoQuery, TotalUsageSectionOrgInfoQueryVariables>(TotalUsageSectionOrgInfoDocument, baseOptions);
        }
export type TotalUsageSectionOrgInfoQueryHookResult = ReturnType<typeof useTotalUsageSectionOrgInfoQuery>;
export type TotalUsageSectionOrgInfoLazyQueryHookResult = ReturnType<typeof useTotalUsageSectionOrgInfoLazyQuery>;
export type TotalUsageSectionOrgInfoQueryResult = ApolloReactCommon.QueryResult<TotalUsageSectionOrgInfoQuery, TotalUsageSectionOrgInfoQueryVariables>;
export const StorageTableDocument = gql`
    query StorageTable($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    name
    teams {
      id
      name
      storageBytes
      storageTree {
        id
      }
      projects {
        edges {
          node {
            id
            storageBytes
          }
        }
      }
    }
  }
}
    `;
export type StorageTableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StorageTableQuery, StorageTableQueryVariables>, 'query'> & ({ variables: StorageTableQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const StorageTableComponent = (props: StorageTableComponentProps) => (
      <ApolloReactComponents.Query<StorageTableQuery, StorageTableQueryVariables> query={StorageTableDocument} {...props} />
    );
    
export type StorageTableProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<StorageTableQuery, StorageTableQueryVariables>
    } & TChildProps;
export function withStorageTable<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  StorageTableQuery,
  StorageTableQueryVariables,
  StorageTableProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, StorageTableQuery, StorageTableQueryVariables, StorageTableProps<TChildProps, TDataName>>(StorageTableDocument, {
      alias: 'storageTable',
      ...operationOptions
    });
};

/**
 * __useStorageTableQuery__
 *
 * To run a query within a React component, call `useStorageTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useStorageTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStorageTableQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useStorageTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StorageTableQuery, StorageTableQueryVariables>) {
        return ApolloReactHooks.useQuery<StorageTableQuery, StorageTableQueryVariables>(StorageTableDocument, baseOptions);
      }
export function useStorageTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StorageTableQuery, StorageTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StorageTableQuery, StorageTableQueryVariables>(StorageTableDocument, baseOptions);
        }
export type StorageTableQueryHookResult = ReturnType<typeof useStorageTableQuery>;
export type StorageTableLazyQueryHookResult = ReturnType<typeof useStorageTableLazyQuery>;
export type StorageTableQueryResult = ApolloReactCommon.QueryResult<StorageTableQuery, StorageTableQueryVariables>;
export const DefaultEntityForDomainCaptureDropdownDocument = gql`
    query DefaultEntityForDomainCaptureDropdown($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    flags
    teams {
      id
      name
      settings {
        openToMatchingOrgEmailDomainUsers
      }
    }
  }
}
    `;
export type DefaultEntityForDomainCaptureDropdownComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DefaultEntityForDomainCaptureDropdownQuery, DefaultEntityForDomainCaptureDropdownQueryVariables>, 'query'> & ({ variables: DefaultEntityForDomainCaptureDropdownQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DefaultEntityForDomainCaptureDropdownComponent = (props: DefaultEntityForDomainCaptureDropdownComponentProps) => (
      <ApolloReactComponents.Query<DefaultEntityForDomainCaptureDropdownQuery, DefaultEntityForDomainCaptureDropdownQueryVariables> query={DefaultEntityForDomainCaptureDropdownDocument} {...props} />
    );
    
export type DefaultEntityForDomainCaptureDropdownProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<DefaultEntityForDomainCaptureDropdownQuery, DefaultEntityForDomainCaptureDropdownQueryVariables>
    } & TChildProps;
export function withDefaultEntityForDomainCaptureDropdown<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DefaultEntityForDomainCaptureDropdownQuery,
  DefaultEntityForDomainCaptureDropdownQueryVariables,
  DefaultEntityForDomainCaptureDropdownProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, DefaultEntityForDomainCaptureDropdownQuery, DefaultEntityForDomainCaptureDropdownQueryVariables, DefaultEntityForDomainCaptureDropdownProps<TChildProps, TDataName>>(DefaultEntityForDomainCaptureDropdownDocument, {
      alias: 'defaultEntityForDomainCaptureDropdown',
      ...operationOptions
    });
};

/**
 * __useDefaultEntityForDomainCaptureDropdownQuery__
 *
 * To run a query within a React component, call `useDefaultEntityForDomainCaptureDropdownQuery` and pass it any options that fit your needs.
 * When your component renders, `useDefaultEntityForDomainCaptureDropdownQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDefaultEntityForDomainCaptureDropdownQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useDefaultEntityForDomainCaptureDropdownQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DefaultEntityForDomainCaptureDropdownQuery, DefaultEntityForDomainCaptureDropdownQueryVariables>) {
        return ApolloReactHooks.useQuery<DefaultEntityForDomainCaptureDropdownQuery, DefaultEntityForDomainCaptureDropdownQueryVariables>(DefaultEntityForDomainCaptureDropdownDocument, baseOptions);
      }
export function useDefaultEntityForDomainCaptureDropdownLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DefaultEntityForDomainCaptureDropdownQuery, DefaultEntityForDomainCaptureDropdownQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DefaultEntityForDomainCaptureDropdownQuery, DefaultEntityForDomainCaptureDropdownQueryVariables>(DefaultEntityForDomainCaptureDropdownDocument, baseOptions);
        }
export type DefaultEntityForDomainCaptureDropdownQueryHookResult = ReturnType<typeof useDefaultEntityForDomainCaptureDropdownQuery>;
export type DefaultEntityForDomainCaptureDropdownLazyQueryHookResult = ReturnType<typeof useDefaultEntityForDomainCaptureDropdownLazyQuery>;
export type DefaultEntityForDomainCaptureDropdownQueryResult = ApolloReactCommon.QueryResult<DefaultEntityForDomainCaptureDropdownQuery, DefaultEntityForDomainCaptureDropdownQueryVariables>;
export const OrganizationDefaultModelsSeatDocument = gql`
    query OrganizationDefaultModelsSeat($orgName: String!) {
  organization(name: $orgName) {
    id
    flags
    subscriptions {
      id
      privileges
      plan {
        id
        planType
      }
    }
  }
}
    `;
export type OrganizationDefaultModelsSeatComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationDefaultModelsSeatQuery, OrganizationDefaultModelsSeatQueryVariables>, 'query'> & ({ variables: OrganizationDefaultModelsSeatQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationDefaultModelsSeatComponent = (props: OrganizationDefaultModelsSeatComponentProps) => (
      <ApolloReactComponents.Query<OrganizationDefaultModelsSeatQuery, OrganizationDefaultModelsSeatQueryVariables> query={OrganizationDefaultModelsSeatDocument} {...props} />
    );
    
export type OrganizationDefaultModelsSeatProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationDefaultModelsSeatQuery, OrganizationDefaultModelsSeatQueryVariables>
    } & TChildProps;
export function withOrganizationDefaultModelsSeat<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationDefaultModelsSeatQuery,
  OrganizationDefaultModelsSeatQueryVariables,
  OrganizationDefaultModelsSeatProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationDefaultModelsSeatQuery, OrganizationDefaultModelsSeatQueryVariables, OrganizationDefaultModelsSeatProps<TChildProps, TDataName>>(OrganizationDefaultModelsSeatDocument, {
      alias: 'organizationDefaultModelsSeat',
      ...operationOptions
    });
};

/**
 * __useOrganizationDefaultModelsSeatQuery__
 *
 * To run a query within a React component, call `useOrganizationDefaultModelsSeatQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationDefaultModelsSeatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationDefaultModelsSeatQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useOrganizationDefaultModelsSeatQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationDefaultModelsSeatQuery, OrganizationDefaultModelsSeatQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationDefaultModelsSeatQuery, OrganizationDefaultModelsSeatQueryVariables>(OrganizationDefaultModelsSeatDocument, baseOptions);
      }
export function useOrganizationDefaultModelsSeatLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationDefaultModelsSeatQuery, OrganizationDefaultModelsSeatQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationDefaultModelsSeatQuery, OrganizationDefaultModelsSeatQueryVariables>(OrganizationDefaultModelsSeatDocument, baseOptions);
        }
export type OrganizationDefaultModelsSeatQueryHookResult = ReturnType<typeof useOrganizationDefaultModelsSeatQuery>;
export type OrganizationDefaultModelsSeatLazyQueryHookResult = ReturnType<typeof useOrganizationDefaultModelsSeatLazyQuery>;
export type OrganizationDefaultModelsSeatQueryResult = ApolloReactCommon.QueryResult<OrganizationDefaultModelsSeatQuery, OrganizationDefaultModelsSeatQueryVariables>;
export const OrganizationDomainCaptureSettingDocument = gql`
    query OrganizationDomainCaptureSetting($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    flags
    teams {
      id
      settings {
        openToMatchingOrgEmailDomainUsers
      }
    }
  }
}
    `;
export type OrganizationDomainCaptureSettingComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationDomainCaptureSettingQuery, OrganizationDomainCaptureSettingQueryVariables>, 'query'> & ({ variables: OrganizationDomainCaptureSettingQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationDomainCaptureSettingComponent = (props: OrganizationDomainCaptureSettingComponentProps) => (
      <ApolloReactComponents.Query<OrganizationDomainCaptureSettingQuery, OrganizationDomainCaptureSettingQueryVariables> query={OrganizationDomainCaptureSettingDocument} {...props} />
    );
    
export type OrganizationDomainCaptureSettingProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationDomainCaptureSettingQuery, OrganizationDomainCaptureSettingQueryVariables>
    } & TChildProps;
export function withOrganizationDomainCaptureSetting<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationDomainCaptureSettingQuery,
  OrganizationDomainCaptureSettingQueryVariables,
  OrganizationDomainCaptureSettingProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationDomainCaptureSettingQuery, OrganizationDomainCaptureSettingQueryVariables, OrganizationDomainCaptureSettingProps<TChildProps, TDataName>>(OrganizationDomainCaptureSettingDocument, {
      alias: 'organizationDomainCaptureSetting',
      ...operationOptions
    });
};

/**
 * __useOrganizationDomainCaptureSettingQuery__
 *
 * To run a query within a React component, call `useOrganizationDomainCaptureSettingQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationDomainCaptureSettingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationDomainCaptureSettingQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationDomainCaptureSettingQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationDomainCaptureSettingQuery, OrganizationDomainCaptureSettingQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationDomainCaptureSettingQuery, OrganizationDomainCaptureSettingQueryVariables>(OrganizationDomainCaptureSettingDocument, baseOptions);
      }
export function useOrganizationDomainCaptureSettingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationDomainCaptureSettingQuery, OrganizationDomainCaptureSettingQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationDomainCaptureSettingQuery, OrganizationDomainCaptureSettingQueryVariables>(OrganizationDomainCaptureSettingDocument, baseOptions);
        }
export type OrganizationDomainCaptureSettingQueryHookResult = ReturnType<typeof useOrganizationDomainCaptureSettingQuery>;
export type OrganizationDomainCaptureSettingLazyQueryHookResult = ReturnType<typeof useOrganizationDomainCaptureSettingLazyQuery>;
export type OrganizationDomainCaptureSettingQueryResult = ApolloReactCommon.QueryResult<OrganizationDomainCaptureSettingQuery, OrganizationDomainCaptureSettingQueryVariables>;
export const ViewerDomainClaimBlockingReasonDocument = gql`
    query ViewerDomainClaimBlockingReason($orgID: ID!) {
  viewer {
    id
    domainClaimBlockingReason(orgId: $orgID)
  }
}
    `;
export type ViewerDomainClaimBlockingReasonComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewerDomainClaimBlockingReasonQuery, ViewerDomainClaimBlockingReasonQueryVariables>, 'query'> & ({ variables: ViewerDomainClaimBlockingReasonQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ViewerDomainClaimBlockingReasonComponent = (props: ViewerDomainClaimBlockingReasonComponentProps) => (
      <ApolloReactComponents.Query<ViewerDomainClaimBlockingReasonQuery, ViewerDomainClaimBlockingReasonQueryVariables> query={ViewerDomainClaimBlockingReasonDocument} {...props} />
    );
    
export type ViewerDomainClaimBlockingReasonProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewerDomainClaimBlockingReasonQuery, ViewerDomainClaimBlockingReasonQueryVariables>
    } & TChildProps;
export function withViewerDomainClaimBlockingReason<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewerDomainClaimBlockingReasonQuery,
  ViewerDomainClaimBlockingReasonQueryVariables,
  ViewerDomainClaimBlockingReasonProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewerDomainClaimBlockingReasonQuery, ViewerDomainClaimBlockingReasonQueryVariables, ViewerDomainClaimBlockingReasonProps<TChildProps, TDataName>>(ViewerDomainClaimBlockingReasonDocument, {
      alias: 'viewerDomainClaimBlockingReason',
      ...operationOptions
    });
};

/**
 * __useViewerDomainClaimBlockingReasonQuery__
 *
 * To run a query within a React component, call `useViewerDomainClaimBlockingReasonQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerDomainClaimBlockingReasonQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerDomainClaimBlockingReasonQuery({
 *   variables: {
 *      orgID: // value for 'orgID'
 *   },
 * });
 */
export function useViewerDomainClaimBlockingReasonQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewerDomainClaimBlockingReasonQuery, ViewerDomainClaimBlockingReasonQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewerDomainClaimBlockingReasonQuery, ViewerDomainClaimBlockingReasonQueryVariables>(ViewerDomainClaimBlockingReasonDocument, baseOptions);
      }
export function useViewerDomainClaimBlockingReasonLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewerDomainClaimBlockingReasonQuery, ViewerDomainClaimBlockingReasonQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewerDomainClaimBlockingReasonQuery, ViewerDomainClaimBlockingReasonQueryVariables>(ViewerDomainClaimBlockingReasonDocument, baseOptions);
        }
export type ViewerDomainClaimBlockingReasonQueryHookResult = ReturnType<typeof useViewerDomainClaimBlockingReasonQuery>;
export type ViewerDomainClaimBlockingReasonLazyQueryHookResult = ReturnType<typeof useViewerDomainClaimBlockingReasonLazyQuery>;
export type ViewerDomainClaimBlockingReasonQueryResult = ApolloReactCommon.QueryResult<ViewerDomainClaimBlockingReasonQuery, ViewerDomainClaimBlockingReasonQueryVariables>;
export const OrganizationTeamsWithDomainCaptureEnabledDocument = gql`
    query OrganizationTeamsWithDomainCaptureEnabled($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    flags
    teams {
      id
      name
      settings {
        openToMatchingOrgEmailDomainUsers
      }
      memberCount
    }
  }
}
    `;
export type OrganizationTeamsWithDomainCaptureEnabledComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationTeamsWithDomainCaptureEnabledQuery, OrganizationTeamsWithDomainCaptureEnabledQueryVariables>, 'query'> & ({ variables: OrganizationTeamsWithDomainCaptureEnabledQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationTeamsWithDomainCaptureEnabledComponent = (props: OrganizationTeamsWithDomainCaptureEnabledComponentProps) => (
      <ApolloReactComponents.Query<OrganizationTeamsWithDomainCaptureEnabledQuery, OrganizationTeamsWithDomainCaptureEnabledQueryVariables> query={OrganizationTeamsWithDomainCaptureEnabledDocument} {...props} />
    );
    
export type OrganizationTeamsWithDomainCaptureEnabledProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationTeamsWithDomainCaptureEnabledQuery, OrganizationTeamsWithDomainCaptureEnabledQueryVariables>
    } & TChildProps;
export function withOrganizationTeamsWithDomainCaptureEnabled<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationTeamsWithDomainCaptureEnabledQuery,
  OrganizationTeamsWithDomainCaptureEnabledQueryVariables,
  OrganizationTeamsWithDomainCaptureEnabledProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationTeamsWithDomainCaptureEnabledQuery, OrganizationTeamsWithDomainCaptureEnabledQueryVariables, OrganizationTeamsWithDomainCaptureEnabledProps<TChildProps, TDataName>>(OrganizationTeamsWithDomainCaptureEnabledDocument, {
      alias: 'organizationTeamsWithDomainCaptureEnabled',
      ...operationOptions
    });
};

/**
 * __useOrganizationTeamsWithDomainCaptureEnabledQuery__
 *
 * To run a query within a React component, call `useOrganizationTeamsWithDomainCaptureEnabledQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationTeamsWithDomainCaptureEnabledQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationTeamsWithDomainCaptureEnabledQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useOrganizationTeamsWithDomainCaptureEnabledQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationTeamsWithDomainCaptureEnabledQuery, OrganizationTeamsWithDomainCaptureEnabledQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationTeamsWithDomainCaptureEnabledQuery, OrganizationTeamsWithDomainCaptureEnabledQueryVariables>(OrganizationTeamsWithDomainCaptureEnabledDocument, baseOptions);
      }
export function useOrganizationTeamsWithDomainCaptureEnabledLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationTeamsWithDomainCaptureEnabledQuery, OrganizationTeamsWithDomainCaptureEnabledQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationTeamsWithDomainCaptureEnabledQuery, OrganizationTeamsWithDomainCaptureEnabledQueryVariables>(OrganizationTeamsWithDomainCaptureEnabledDocument, baseOptions);
        }
export type OrganizationTeamsWithDomainCaptureEnabledQueryHookResult = ReturnType<typeof useOrganizationTeamsWithDomainCaptureEnabledQuery>;
export type OrganizationTeamsWithDomainCaptureEnabledLazyQueryHookResult = ReturnType<typeof useOrganizationTeamsWithDomainCaptureEnabledLazyQuery>;
export type OrganizationTeamsWithDomainCaptureEnabledQueryResult = ApolloReactCommon.QueryResult<OrganizationTeamsWithDomainCaptureEnabledQuery, OrganizationTeamsWithDomainCaptureEnabledQueryVariables>;
export const EditOrgMembersModalDocument = gql`
    query EditOrgMembersModal($organizationID: ID!) {
  organization(id: $organizationID) {
    id
    name
    flags
    teams {
      id
      name
      settings {
        openToMatchingOrgEmailDomainUsers
      }
    }
    seatAvailability {
      seats
    }
    pendingInvites {
      id
      email
    }
    billingUser {
      id
      username
    }
    subscriptions {
      id
      seats
      plan {
        id
        unitPrice
        planType
        billingInterval
      }
    }
    members {
      id
      username
      role
      modelsSeat
      name
      user {
        id
        loggedInAt
      }
      teams {
        edges {
          node {
            id
            name
          }
        }
      }
    }
  }
}
    `;
export type EditOrgMembersModalComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EditOrgMembersModalQuery, EditOrgMembersModalQueryVariables>, 'query'> & ({ variables: EditOrgMembersModalQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EditOrgMembersModalComponent = (props: EditOrgMembersModalComponentProps) => (
      <ApolloReactComponents.Query<EditOrgMembersModalQuery, EditOrgMembersModalQueryVariables> query={EditOrgMembersModalDocument} {...props} />
    );
    
export type EditOrgMembersModalProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EditOrgMembersModalQuery, EditOrgMembersModalQueryVariables>
    } & TChildProps;
export function withEditOrgMembersModal<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EditOrgMembersModalQuery,
  EditOrgMembersModalQueryVariables,
  EditOrgMembersModalProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EditOrgMembersModalQuery, EditOrgMembersModalQueryVariables, EditOrgMembersModalProps<TChildProps, TDataName>>(EditOrgMembersModalDocument, {
      alias: 'editOrgMembersModal',
      ...operationOptions
    });
};

/**
 * __useEditOrgMembersModalQuery__
 *
 * To run a query within a React component, call `useEditOrgMembersModalQuery` and pass it any options that fit your needs.
 * When your component renders, `useEditOrgMembersModalQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEditOrgMembersModalQuery({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *   },
 * });
 */
export function useEditOrgMembersModalQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EditOrgMembersModalQuery, EditOrgMembersModalQueryVariables>) {
        return ApolloReactHooks.useQuery<EditOrgMembersModalQuery, EditOrgMembersModalQueryVariables>(EditOrgMembersModalDocument, baseOptions);
      }
export function useEditOrgMembersModalLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EditOrgMembersModalQuery, EditOrgMembersModalQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EditOrgMembersModalQuery, EditOrgMembersModalQueryVariables>(EditOrgMembersModalDocument, baseOptions);
        }
export type EditOrgMembersModalQueryHookResult = ReturnType<typeof useEditOrgMembersModalQuery>;
export type EditOrgMembersModalLazyQueryHookResult = ReturnType<typeof useEditOrgMembersModalLazyQuery>;
export type EditOrgMembersModalQueryResult = ApolloReactCommon.QueryResult<EditOrgMembersModalQuery, EditOrgMembersModalQueryVariables>;
export const IsEmailDomainClaimedAndShouldRedirectToSsoDocument = gql`
    query IsEmailDomainClaimedAndShouldRedirectToSSO {
  viewer {
    id
    isEmailDomainClaimedAndShouldRedirectToSSO
  }
}
    `;
export type IsEmailDomainClaimedAndShouldRedirectToSsoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<IsEmailDomainClaimedAndShouldRedirectToSsoQuery, IsEmailDomainClaimedAndShouldRedirectToSsoQueryVariables>, 'query'>;

    export const IsEmailDomainClaimedAndShouldRedirectToSsoComponent = (props: IsEmailDomainClaimedAndShouldRedirectToSsoComponentProps) => (
      <ApolloReactComponents.Query<IsEmailDomainClaimedAndShouldRedirectToSsoQuery, IsEmailDomainClaimedAndShouldRedirectToSsoQueryVariables> query={IsEmailDomainClaimedAndShouldRedirectToSsoDocument} {...props} />
    );
    
export type IsEmailDomainClaimedAndShouldRedirectToSsoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<IsEmailDomainClaimedAndShouldRedirectToSsoQuery, IsEmailDomainClaimedAndShouldRedirectToSsoQueryVariables>
    } & TChildProps;
export function withIsEmailDomainClaimedAndShouldRedirectToSso<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  IsEmailDomainClaimedAndShouldRedirectToSsoQuery,
  IsEmailDomainClaimedAndShouldRedirectToSsoQueryVariables,
  IsEmailDomainClaimedAndShouldRedirectToSsoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, IsEmailDomainClaimedAndShouldRedirectToSsoQuery, IsEmailDomainClaimedAndShouldRedirectToSsoQueryVariables, IsEmailDomainClaimedAndShouldRedirectToSsoProps<TChildProps, TDataName>>(IsEmailDomainClaimedAndShouldRedirectToSsoDocument, {
      alias: 'isEmailDomainClaimedAndShouldRedirectToSso',
      ...operationOptions
    });
};

/**
 * __useIsEmailDomainClaimedAndShouldRedirectToSsoQuery__
 *
 * To run a query within a React component, call `useIsEmailDomainClaimedAndShouldRedirectToSsoQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsEmailDomainClaimedAndShouldRedirectToSsoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsEmailDomainClaimedAndShouldRedirectToSsoQuery({
 *   variables: {
 *   },
 * });
 */
export function useIsEmailDomainClaimedAndShouldRedirectToSsoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<IsEmailDomainClaimedAndShouldRedirectToSsoQuery, IsEmailDomainClaimedAndShouldRedirectToSsoQueryVariables>) {
        return ApolloReactHooks.useQuery<IsEmailDomainClaimedAndShouldRedirectToSsoQuery, IsEmailDomainClaimedAndShouldRedirectToSsoQueryVariables>(IsEmailDomainClaimedAndShouldRedirectToSsoDocument, baseOptions);
      }
export function useIsEmailDomainClaimedAndShouldRedirectToSsoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<IsEmailDomainClaimedAndShouldRedirectToSsoQuery, IsEmailDomainClaimedAndShouldRedirectToSsoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<IsEmailDomainClaimedAndShouldRedirectToSsoQuery, IsEmailDomainClaimedAndShouldRedirectToSsoQueryVariables>(IsEmailDomainClaimedAndShouldRedirectToSsoDocument, baseOptions);
        }
export type IsEmailDomainClaimedAndShouldRedirectToSsoQueryHookResult = ReturnType<typeof useIsEmailDomainClaimedAndShouldRedirectToSsoQuery>;
export type IsEmailDomainClaimedAndShouldRedirectToSsoLazyQueryHookResult = ReturnType<typeof useIsEmailDomainClaimedAndShouldRedirectToSsoLazyQuery>;
export type IsEmailDomainClaimedAndShouldRedirectToSsoQueryResult = ApolloReactCommon.QueryResult<IsEmailDomainClaimedAndShouldRedirectToSsoQuery, IsEmailDomainClaimedAndShouldRedirectToSsoQueryVariables>;
export const TeamPageShowcasedProjectsDocument = gql`
    query TeamPageShowcasedProjects($entityName: String!, $pattern: String, $first: Int = 20, $order: String = "-created_at") {
  projects(
    first: $first
    entityName: $entityName
    pattern: $pattern
    order: $order
  ) {
    edges {
      node {
        id
        name
        entityName
        description
        lastActive
        runCount
        access
        user {
          id
          username
        }
      }
    }
    pageInfo {
      startCursor
      endCursor
      hasNextPage
      hasPreviousPage
    }
  }
}
    `;
export type TeamPageShowcasedProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamPageShowcasedProjectsQuery, TeamPageShowcasedProjectsQueryVariables>, 'query'> & ({ variables: TeamPageShowcasedProjectsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamPageShowcasedProjectsComponent = (props: TeamPageShowcasedProjectsComponentProps) => (
      <ApolloReactComponents.Query<TeamPageShowcasedProjectsQuery, TeamPageShowcasedProjectsQueryVariables> query={TeamPageShowcasedProjectsDocument} {...props} />
    );
    
export type TeamPageShowcasedProjectsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TeamPageShowcasedProjectsQuery, TeamPageShowcasedProjectsQueryVariables>
    } & TChildProps;
export function withTeamPageShowcasedProjects<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TeamPageShowcasedProjectsQuery,
  TeamPageShowcasedProjectsQueryVariables,
  TeamPageShowcasedProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TeamPageShowcasedProjectsQuery, TeamPageShowcasedProjectsQueryVariables, TeamPageShowcasedProjectsProps<TChildProps, TDataName>>(TeamPageShowcasedProjectsDocument, {
      alias: 'teamPageShowcasedProjects',
      ...operationOptions
    });
};

/**
 * __useTeamPageShowcasedProjectsQuery__
 *
 * To run a query within a React component, call `useTeamPageShowcasedProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamPageShowcasedProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamPageShowcasedProjectsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      pattern: // value for 'pattern'
 *      first: // value for 'first'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useTeamPageShowcasedProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamPageShowcasedProjectsQuery, TeamPageShowcasedProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamPageShowcasedProjectsQuery, TeamPageShowcasedProjectsQueryVariables>(TeamPageShowcasedProjectsDocument, baseOptions);
      }
export function useTeamPageShowcasedProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamPageShowcasedProjectsQuery, TeamPageShowcasedProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamPageShowcasedProjectsQuery, TeamPageShowcasedProjectsQueryVariables>(TeamPageShowcasedProjectsDocument, baseOptions);
        }
export type TeamPageShowcasedProjectsQueryHookResult = ReturnType<typeof useTeamPageShowcasedProjectsQuery>;
export type TeamPageShowcasedProjectsLazyQueryHookResult = ReturnType<typeof useTeamPageShowcasedProjectsLazyQuery>;
export type TeamPageShowcasedProjectsQueryResult = ApolloReactCommon.QueryResult<TeamPageShowcasedProjectsQuery, TeamPageShowcasedProjectsQueryVariables>;
export const ProfilePageShowcasedProjectsDocument = gql`
    query ProfilePageShowcasedProjects($username: String!, $pattern: String, $first: Int = 20, $order: String = "-created_at") {
  user(userName: $username) {
    id
    projects(first: $first, pattern: $pattern, order: $order) {
      edges {
        node {
          id
          name
          entityName
          access
          description
          totalRuns
          lastActive
          runCount
          user {
            id
            username
          }
        }
      }
    }
  }
}
    `;
export type ProfilePageShowcasedProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProfilePageShowcasedProjectsQuery, ProfilePageShowcasedProjectsQueryVariables>, 'query'> & ({ variables: ProfilePageShowcasedProjectsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProfilePageShowcasedProjectsComponent = (props: ProfilePageShowcasedProjectsComponentProps) => (
      <ApolloReactComponents.Query<ProfilePageShowcasedProjectsQuery, ProfilePageShowcasedProjectsQueryVariables> query={ProfilePageShowcasedProjectsDocument} {...props} />
    );
    
export type ProfilePageShowcasedProjectsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProfilePageShowcasedProjectsQuery, ProfilePageShowcasedProjectsQueryVariables>
    } & TChildProps;
export function withProfilePageShowcasedProjects<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProfilePageShowcasedProjectsQuery,
  ProfilePageShowcasedProjectsQueryVariables,
  ProfilePageShowcasedProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProfilePageShowcasedProjectsQuery, ProfilePageShowcasedProjectsQueryVariables, ProfilePageShowcasedProjectsProps<TChildProps, TDataName>>(ProfilePageShowcasedProjectsDocument, {
      alias: 'profilePageShowcasedProjects',
      ...operationOptions
    });
};

/**
 * __useProfilePageShowcasedProjectsQuery__
 *
 * To run a query within a React component, call `useProfilePageShowcasedProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProfilePageShowcasedProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProfilePageShowcasedProjectsQuery({
 *   variables: {
 *      username: // value for 'username'
 *      pattern: // value for 'pattern'
 *      first: // value for 'first'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useProfilePageShowcasedProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProfilePageShowcasedProjectsQuery, ProfilePageShowcasedProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProfilePageShowcasedProjectsQuery, ProfilePageShowcasedProjectsQueryVariables>(ProfilePageShowcasedProjectsDocument, baseOptions);
      }
export function useProfilePageShowcasedProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProfilePageShowcasedProjectsQuery, ProfilePageShowcasedProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProfilePageShowcasedProjectsQuery, ProfilePageShowcasedProjectsQueryVariables>(ProfilePageShowcasedProjectsDocument, baseOptions);
        }
export type ProfilePageShowcasedProjectsQueryHookResult = ReturnType<typeof useProfilePageShowcasedProjectsQuery>;
export type ProfilePageShowcasedProjectsLazyQueryHookResult = ReturnType<typeof useProfilePageShowcasedProjectsLazyQuery>;
export type ProfilePageShowcasedProjectsQueryResult = ApolloReactCommon.QueryResult<ProfilePageShowcasedProjectsQuery, ProfilePageShowcasedProjectsQueryVariables>;
export const HistoricUsageOrganizationInfoDocument = gql`
    query HistoricUsageOrganizationInfo($orgName: String!) {
  organization(name: $orgName) {
    id
    members {
      id
      username
      role
    }
  }
}
    `;
export type HistoricUsageOrganizationInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<HistoricUsageOrganizationInfoQuery, HistoricUsageOrganizationInfoQueryVariables>, 'query'> & ({ variables: HistoricUsageOrganizationInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const HistoricUsageOrganizationInfoComponent = (props: HistoricUsageOrganizationInfoComponentProps) => (
      <ApolloReactComponents.Query<HistoricUsageOrganizationInfoQuery, HistoricUsageOrganizationInfoQueryVariables> query={HistoricUsageOrganizationInfoDocument} {...props} />
    );
    
export type HistoricUsageOrganizationInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<HistoricUsageOrganizationInfoQuery, HistoricUsageOrganizationInfoQueryVariables>
    } & TChildProps;
export function withHistoricUsageOrganizationInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  HistoricUsageOrganizationInfoQuery,
  HistoricUsageOrganizationInfoQueryVariables,
  HistoricUsageOrganizationInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, HistoricUsageOrganizationInfoQuery, HistoricUsageOrganizationInfoQueryVariables, HistoricUsageOrganizationInfoProps<TChildProps, TDataName>>(HistoricUsageOrganizationInfoDocument, {
      alias: 'historicUsageOrganizationInfo',
      ...operationOptions
    });
};

/**
 * __useHistoricUsageOrganizationInfoQuery__
 *
 * To run a query within a React component, call `useHistoricUsageOrganizationInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoricUsageOrganizationInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoricUsageOrganizationInfoQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useHistoricUsageOrganizationInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HistoricUsageOrganizationInfoQuery, HistoricUsageOrganizationInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<HistoricUsageOrganizationInfoQuery, HistoricUsageOrganizationInfoQueryVariables>(HistoricUsageOrganizationInfoDocument, baseOptions);
      }
export function useHistoricUsageOrganizationInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HistoricUsageOrganizationInfoQuery, HistoricUsageOrganizationInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HistoricUsageOrganizationInfoQuery, HistoricUsageOrganizationInfoQueryVariables>(HistoricUsageOrganizationInfoDocument, baseOptions);
        }
export type HistoricUsageOrganizationInfoQueryHookResult = ReturnType<typeof useHistoricUsageOrganizationInfoQuery>;
export type HistoricUsageOrganizationInfoLazyQueryHookResult = ReturnType<typeof useHistoricUsageOrganizationInfoLazyQuery>;
export type HistoricUsageOrganizationInfoQueryResult = ApolloReactCommon.QueryResult<HistoricUsageOrganizationInfoQuery, HistoricUsageOrganizationInfoQueryVariables>;
export const UsageTabInfoDocument = gql`
    query UsageTabInfo($orgName: String!, $usageType: UsageType!, $intervalMarkers: [DateTime!]!) {
  organization(name: $orgName) {
    id
    createdAt
    usage(usageType: $usageType, intervalMarkers: $intervalMarkers) {
      start
      end
      value
      projectName
      entityName
    }
  }
}
    `;
export type UsageTabInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UsageTabInfoQuery, UsageTabInfoQueryVariables>, 'query'> & ({ variables: UsageTabInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UsageTabInfoComponent = (props: UsageTabInfoComponentProps) => (
      <ApolloReactComponents.Query<UsageTabInfoQuery, UsageTabInfoQueryVariables> query={UsageTabInfoDocument} {...props} />
    );
    
export type UsageTabInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UsageTabInfoQuery, UsageTabInfoQueryVariables>
    } & TChildProps;
export function withUsageTabInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UsageTabInfoQuery,
  UsageTabInfoQueryVariables,
  UsageTabInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, UsageTabInfoQuery, UsageTabInfoQueryVariables, UsageTabInfoProps<TChildProps, TDataName>>(UsageTabInfoDocument, {
      alias: 'usageTabInfo',
      ...operationOptions
    });
};

/**
 * __useUsageTabInfoQuery__
 *
 * To run a query within a React component, call `useUsageTabInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsageTabInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsageTabInfoQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *      usageType: // value for 'usageType'
 *      intervalMarkers: // value for 'intervalMarkers'
 *   },
 * });
 */
export function useUsageTabInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UsageTabInfoQuery, UsageTabInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<UsageTabInfoQuery, UsageTabInfoQueryVariables>(UsageTabInfoDocument, baseOptions);
      }
export function useUsageTabInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UsageTabInfoQuery, UsageTabInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UsageTabInfoQuery, UsageTabInfoQueryVariables>(UsageTabInfoDocument, baseOptions);
        }
export type UsageTabInfoQueryHookResult = ReturnType<typeof useUsageTabInfoQuery>;
export type UsageTabInfoLazyQueryHookResult = ReturnType<typeof useUsageTabInfoLazyQuery>;
export type UsageTabInfoQueryResult = ApolloReactCommon.QueryResult<UsageTabInfoQuery, UsageTabInfoQueryVariables>;
export const GlobalUsageTabInfoDocument = gql`
    query GlobalUsageTabInfo($usageType: UsageType!, $intervalMarkers: [DateTime!]!) {
  usage(usageType: $usageType, intervalMarkers: $intervalMarkers) {
    start
    end
    value
    projectName
    entityName
  }
}
    `;
export type GlobalUsageTabInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GlobalUsageTabInfoQuery, GlobalUsageTabInfoQueryVariables>, 'query'> & ({ variables: GlobalUsageTabInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GlobalUsageTabInfoComponent = (props: GlobalUsageTabInfoComponentProps) => (
      <ApolloReactComponents.Query<GlobalUsageTabInfoQuery, GlobalUsageTabInfoQueryVariables> query={GlobalUsageTabInfoDocument} {...props} />
    );
    
export type GlobalUsageTabInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GlobalUsageTabInfoQuery, GlobalUsageTabInfoQueryVariables>
    } & TChildProps;
export function withGlobalUsageTabInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GlobalUsageTabInfoQuery,
  GlobalUsageTabInfoQueryVariables,
  GlobalUsageTabInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GlobalUsageTabInfoQuery, GlobalUsageTabInfoQueryVariables, GlobalUsageTabInfoProps<TChildProps, TDataName>>(GlobalUsageTabInfoDocument, {
      alias: 'globalUsageTabInfo',
      ...operationOptions
    });
};

/**
 * __useGlobalUsageTabInfoQuery__
 *
 * To run a query within a React component, call `useGlobalUsageTabInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGlobalUsageTabInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGlobalUsageTabInfoQuery({
 *   variables: {
 *      usageType: // value for 'usageType'
 *      intervalMarkers: // value for 'intervalMarkers'
 *   },
 * });
 */
export function useGlobalUsageTabInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GlobalUsageTabInfoQuery, GlobalUsageTabInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<GlobalUsageTabInfoQuery, GlobalUsageTabInfoQueryVariables>(GlobalUsageTabInfoDocument, baseOptions);
      }
export function useGlobalUsageTabInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GlobalUsageTabInfoQuery, GlobalUsageTabInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GlobalUsageTabInfoQuery, GlobalUsageTabInfoQueryVariables>(GlobalUsageTabInfoDocument, baseOptions);
        }
export type GlobalUsageTabInfoQueryHookResult = ReturnType<typeof useGlobalUsageTabInfoQuery>;
export type GlobalUsageTabInfoLazyQueryHookResult = ReturnType<typeof useGlobalUsageTabInfoLazyQuery>;
export type GlobalUsageTabInfoQueryResult = ApolloReactCommon.QueryResult<GlobalUsageTabInfoQuery, GlobalUsageTabInfoQueryVariables>;
export const TrialCardAccountExpirationDocument = gql`
    query TrialCardAccountExpiration($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    subscriptions {
      id
      expiresAt
      status
      plan {
        id
        name
        planType
      }
    }
  }
}
    `;
export type TrialCardAccountExpirationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TrialCardAccountExpirationQuery, TrialCardAccountExpirationQueryVariables>, 'query'> & ({ variables: TrialCardAccountExpirationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TrialCardAccountExpirationComponent = (props: TrialCardAccountExpirationComponentProps) => (
      <ApolloReactComponents.Query<TrialCardAccountExpirationQuery, TrialCardAccountExpirationQueryVariables> query={TrialCardAccountExpirationDocument} {...props} />
    );
    
export type TrialCardAccountExpirationProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TrialCardAccountExpirationQuery, TrialCardAccountExpirationQueryVariables>
    } & TChildProps;
export function withTrialCardAccountExpiration<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TrialCardAccountExpirationQuery,
  TrialCardAccountExpirationQueryVariables,
  TrialCardAccountExpirationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TrialCardAccountExpirationQuery, TrialCardAccountExpirationQueryVariables, TrialCardAccountExpirationProps<TChildProps, TDataName>>(TrialCardAccountExpirationDocument, {
      alias: 'trialCardAccountExpiration',
      ...operationOptions
    });
};

/**
 * __useTrialCardAccountExpirationQuery__
 *
 * To run a query within a React component, call `useTrialCardAccountExpirationQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrialCardAccountExpirationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrialCardAccountExpirationQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useTrialCardAccountExpirationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrialCardAccountExpirationQuery, TrialCardAccountExpirationQueryVariables>) {
        return ApolloReactHooks.useQuery<TrialCardAccountExpirationQuery, TrialCardAccountExpirationQueryVariables>(TrialCardAccountExpirationDocument, baseOptions);
      }
export function useTrialCardAccountExpirationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrialCardAccountExpirationQuery, TrialCardAccountExpirationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrialCardAccountExpirationQuery, TrialCardAccountExpirationQueryVariables>(TrialCardAccountExpirationDocument, baseOptions);
        }
export type TrialCardAccountExpirationQueryHookResult = ReturnType<typeof useTrialCardAccountExpirationQuery>;
export type TrialCardAccountExpirationLazyQueryHookResult = ReturnType<typeof useTrialCardAccountExpirationLazyQuery>;
export type TrialCardAccountExpirationQueryResult = ApolloReactCommon.QueryResult<TrialCardAccountExpirationQuery, TrialCardAccountExpirationQueryVariables>;
export const PredefinedRoleOrgInfoDocument = gql`
    query PredefinedRoleOrgInfo($organizationID: ID!) {
  organization(id: $organizationID) {
    id
    subscriptions {
      id
      subscriptionType
      plan {
        id
        name
        planType
      }
    }
  }
}
    `;
export type PredefinedRoleOrgInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PredefinedRoleOrgInfoQuery, PredefinedRoleOrgInfoQueryVariables>, 'query'> & ({ variables: PredefinedRoleOrgInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PredefinedRoleOrgInfoComponent = (props: PredefinedRoleOrgInfoComponentProps) => (
      <ApolloReactComponents.Query<PredefinedRoleOrgInfoQuery, PredefinedRoleOrgInfoQueryVariables> query={PredefinedRoleOrgInfoDocument} {...props} />
    );
    
export type PredefinedRoleOrgInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<PredefinedRoleOrgInfoQuery, PredefinedRoleOrgInfoQueryVariables>
    } & TChildProps;
export function withPredefinedRoleOrgInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PredefinedRoleOrgInfoQuery,
  PredefinedRoleOrgInfoQueryVariables,
  PredefinedRoleOrgInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, PredefinedRoleOrgInfoQuery, PredefinedRoleOrgInfoQueryVariables, PredefinedRoleOrgInfoProps<TChildProps, TDataName>>(PredefinedRoleOrgInfoDocument, {
      alias: 'predefinedRoleOrgInfo',
      ...operationOptions
    });
};

/**
 * __usePredefinedRoleOrgInfoQuery__
 *
 * To run a query within a React component, call `usePredefinedRoleOrgInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `usePredefinedRoleOrgInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePredefinedRoleOrgInfoQuery({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *   },
 * });
 */
export function usePredefinedRoleOrgInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PredefinedRoleOrgInfoQuery, PredefinedRoleOrgInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<PredefinedRoleOrgInfoQuery, PredefinedRoleOrgInfoQueryVariables>(PredefinedRoleOrgInfoDocument, baseOptions);
      }
export function usePredefinedRoleOrgInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PredefinedRoleOrgInfoQuery, PredefinedRoleOrgInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PredefinedRoleOrgInfoQuery, PredefinedRoleOrgInfoQueryVariables>(PredefinedRoleOrgInfoDocument, baseOptions);
        }
export type PredefinedRoleOrgInfoQueryHookResult = ReturnType<typeof usePredefinedRoleOrgInfoQuery>;
export type PredefinedRoleOrgInfoLazyQueryHookResult = ReturnType<typeof usePredefinedRoleOrgInfoLazyQuery>;
export type PredefinedRoleOrgInfoQueryResult = ApolloReactCommon.QueryResult<PredefinedRoleOrgInfoQuery, PredefinedRoleOrgInfoQueryVariables>;
export const OrganizationFlagsDocument = gql`
    query OrganizationFlags($orgName: String!) {
  organization(name: $orgName) {
    id
    flags
  }
}
    `;
export type OrganizationFlagsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationFlagsQuery, OrganizationFlagsQueryVariables>, 'query'> & ({ variables: OrganizationFlagsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationFlagsComponent = (props: OrganizationFlagsComponentProps) => (
      <ApolloReactComponents.Query<OrganizationFlagsQuery, OrganizationFlagsQueryVariables> query={OrganizationFlagsDocument} {...props} />
    );
    
export type OrganizationFlagsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationFlagsQuery, OrganizationFlagsQueryVariables>
    } & TChildProps;
export function withOrganizationFlags<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationFlagsQuery,
  OrganizationFlagsQueryVariables,
  OrganizationFlagsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationFlagsQuery, OrganizationFlagsQueryVariables, OrganizationFlagsProps<TChildProps, TDataName>>(OrganizationFlagsDocument, {
      alias: 'organizationFlags',
      ...operationOptions
    });
};

/**
 * __useOrganizationFlagsQuery__
 *
 * To run a query within a React component, call `useOrganizationFlagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationFlagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationFlagsQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useOrganizationFlagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationFlagsQuery, OrganizationFlagsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationFlagsQuery, OrganizationFlagsQueryVariables>(OrganizationFlagsDocument, baseOptions);
      }
export function useOrganizationFlagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationFlagsQuery, OrganizationFlagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationFlagsQuery, OrganizationFlagsQueryVariables>(OrganizationFlagsDocument, baseOptions);
        }
export type OrganizationFlagsQueryHookResult = ReturnType<typeof useOrganizationFlagsQuery>;
export type OrganizationFlagsLazyQueryHookResult = ReturnType<typeof useOrganizationFlagsLazyQuery>;
export type OrganizationFlagsQueryResult = ApolloReactCommon.QueryResult<OrganizationFlagsQuery, OrganizationFlagsQueryVariables>;
export const FetchProfilePageProjectsByIDsDocument = gql`
    query FetchProfilePageProjectsByIDs($entityName: String!, $projectIDs: [ID!]!) {
  projects(entityName: $entityName, projectIDs: $projectIDs) {
    edges {
      node {
        id
        name
        entityName
        access
        lastActive
        runCount
        user {
          id
          username
        }
      }
    }
  }
}
    `;
export type FetchProfilePageProjectsByIDsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FetchProfilePageProjectsByIDsQuery, FetchProfilePageProjectsByIDsQueryVariables>, 'query'> & ({ variables: FetchProfilePageProjectsByIDsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FetchProfilePageProjectsByIDsComponent = (props: FetchProfilePageProjectsByIDsComponentProps) => (
      <ApolloReactComponents.Query<FetchProfilePageProjectsByIDsQuery, FetchProfilePageProjectsByIDsQueryVariables> query={FetchProfilePageProjectsByIDsDocument} {...props} />
    );
    
export type FetchProfilePageProjectsByIDsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FetchProfilePageProjectsByIDsQuery, FetchProfilePageProjectsByIDsQueryVariables>
    } & TChildProps;
export function withFetchProfilePageProjectsByIDs<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FetchProfilePageProjectsByIDsQuery,
  FetchProfilePageProjectsByIDsQueryVariables,
  FetchProfilePageProjectsByIDsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FetchProfilePageProjectsByIDsQuery, FetchProfilePageProjectsByIDsQueryVariables, FetchProfilePageProjectsByIDsProps<TChildProps, TDataName>>(FetchProfilePageProjectsByIDsDocument, {
      alias: 'fetchProfilePageProjectsByIDs',
      ...operationOptions
    });
};

/**
 * __useFetchProfilePageProjectsByIDsQuery__
 *
 * To run a query within a React component, call `useFetchProfilePageProjectsByIDsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProfilePageProjectsByIDsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProfilePageProjectsByIDsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectIDs: // value for 'projectIDs'
 *   },
 * });
 */
export function useFetchProfilePageProjectsByIDsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchProfilePageProjectsByIDsQuery, FetchProfilePageProjectsByIDsQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchProfilePageProjectsByIDsQuery, FetchProfilePageProjectsByIDsQueryVariables>(FetchProfilePageProjectsByIDsDocument, baseOptions);
      }
export function useFetchProfilePageProjectsByIDsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchProfilePageProjectsByIDsQuery, FetchProfilePageProjectsByIDsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchProfilePageProjectsByIDsQuery, FetchProfilePageProjectsByIDsQueryVariables>(FetchProfilePageProjectsByIDsDocument, baseOptions);
        }
export type FetchProfilePageProjectsByIDsQueryHookResult = ReturnType<typeof useFetchProfilePageProjectsByIDsQuery>;
export type FetchProfilePageProjectsByIDsLazyQueryHookResult = ReturnType<typeof useFetchProfilePageProjectsByIDsLazyQuery>;
export type FetchProfilePageProjectsByIDsQueryResult = ApolloReactCommon.QueryResult<FetchProfilePageProjectsByIDsQuery, FetchProfilePageProjectsByIDsQueryVariables>;
export const StartupProgramDocument = gql`
    query StartupProgram($organizationID: ID!) {
  organization(id: $organizationID) {
    id
    flags
    subscriptions {
      id
      subscriptionType
      plan {
        id
        name
        planType
      }
    }
  }
}
    `;
export type StartupProgramComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StartupProgramQuery, StartupProgramQueryVariables>, 'query'> & ({ variables: StartupProgramQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const StartupProgramComponent = (props: StartupProgramComponentProps) => (
      <ApolloReactComponents.Query<StartupProgramQuery, StartupProgramQueryVariables> query={StartupProgramDocument} {...props} />
    );
    
export type StartupProgramProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<StartupProgramQuery, StartupProgramQueryVariables>
    } & TChildProps;
export function withStartupProgram<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  StartupProgramQuery,
  StartupProgramQueryVariables,
  StartupProgramProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, StartupProgramQuery, StartupProgramQueryVariables, StartupProgramProps<TChildProps, TDataName>>(StartupProgramDocument, {
      alias: 'startupProgram',
      ...operationOptions
    });
};

/**
 * __useStartupProgramQuery__
 *
 * To run a query within a React component, call `useStartupProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useStartupProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStartupProgramQuery({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *   },
 * });
 */
export function useStartupProgramQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StartupProgramQuery, StartupProgramQueryVariables>) {
        return ApolloReactHooks.useQuery<StartupProgramQuery, StartupProgramQueryVariables>(StartupProgramDocument, baseOptions);
      }
export function useStartupProgramLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StartupProgramQuery, StartupProgramQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StartupProgramQuery, StartupProgramQueryVariables>(StartupProgramDocument, baseOptions);
        }
export type StartupProgramQueryHookResult = ReturnType<typeof useStartupProgramQuery>;
export type StartupProgramLazyQueryHookResult = ReturnType<typeof useStartupProgramLazyQuery>;
export type StartupProgramQueryResult = ApolloReactCommon.QueryResult<StartupProgramQuery, StartupProgramQueryVariables>;
export const StartupProgramCouponInfoDocument = gql`
    query StartupProgramCouponInfo($couponID: String!) {
  coupon(id: $couponID) {
    id
  }
}
    `;
export type StartupProgramCouponInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StartupProgramCouponInfoQuery, StartupProgramCouponInfoQueryVariables>, 'query'> & ({ variables: StartupProgramCouponInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const StartupProgramCouponInfoComponent = (props: StartupProgramCouponInfoComponentProps) => (
      <ApolloReactComponents.Query<StartupProgramCouponInfoQuery, StartupProgramCouponInfoQueryVariables> query={StartupProgramCouponInfoDocument} {...props} />
    );
    
export type StartupProgramCouponInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<StartupProgramCouponInfoQuery, StartupProgramCouponInfoQueryVariables>
    } & TChildProps;
export function withStartupProgramCouponInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  StartupProgramCouponInfoQuery,
  StartupProgramCouponInfoQueryVariables,
  StartupProgramCouponInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, StartupProgramCouponInfoQuery, StartupProgramCouponInfoQueryVariables, StartupProgramCouponInfoProps<TChildProps, TDataName>>(StartupProgramCouponInfoDocument, {
      alias: 'startupProgramCouponInfo',
      ...operationOptions
    });
};

/**
 * __useStartupProgramCouponInfoQuery__
 *
 * To run a query within a React component, call `useStartupProgramCouponInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useStartupProgramCouponInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStartupProgramCouponInfoQuery({
 *   variables: {
 *      couponID: // value for 'couponID'
 *   },
 * });
 */
export function useStartupProgramCouponInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StartupProgramCouponInfoQuery, StartupProgramCouponInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<StartupProgramCouponInfoQuery, StartupProgramCouponInfoQueryVariables>(StartupProgramCouponInfoDocument, baseOptions);
      }
export function useStartupProgramCouponInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StartupProgramCouponInfoQuery, StartupProgramCouponInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StartupProgramCouponInfoQuery, StartupProgramCouponInfoQueryVariables>(StartupProgramCouponInfoDocument, baseOptions);
        }
export type StartupProgramCouponInfoQueryHookResult = ReturnType<typeof useStartupProgramCouponInfoQuery>;
export type StartupProgramCouponInfoLazyQueryHookResult = ReturnType<typeof useStartupProgramCouponInfoLazyQuery>;
export type StartupProgramCouponInfoQueryResult = ApolloReactCommon.QueryResult<StartupProgramCouponInfoQuery, StartupProgramCouponInfoQueryVariables>;
export const TrialEndOrgInfoDocument = gql`
    query TrialEndOrgInfo($organizationID: ID!) {
  organization(id: $organizationID) {
    id
    name
    usedSeats
    flags
    teams {
      id
      name
    }
    subscriptions {
      id
      expiresAt
      plan {
        id
        name
        planType
      }
    }
  }
}
    `;
export type TrialEndOrgInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TrialEndOrgInfoQuery, TrialEndOrgInfoQueryVariables>, 'query'> & ({ variables: TrialEndOrgInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TrialEndOrgInfoComponent = (props: TrialEndOrgInfoComponentProps) => (
      <ApolloReactComponents.Query<TrialEndOrgInfoQuery, TrialEndOrgInfoQueryVariables> query={TrialEndOrgInfoDocument} {...props} />
    );
    
export type TrialEndOrgInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TrialEndOrgInfoQuery, TrialEndOrgInfoQueryVariables>
    } & TChildProps;
export function withTrialEndOrgInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TrialEndOrgInfoQuery,
  TrialEndOrgInfoQueryVariables,
  TrialEndOrgInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TrialEndOrgInfoQuery, TrialEndOrgInfoQueryVariables, TrialEndOrgInfoProps<TChildProps, TDataName>>(TrialEndOrgInfoDocument, {
      alias: 'trialEndOrgInfo',
      ...operationOptions
    });
};

/**
 * __useTrialEndOrgInfoQuery__
 *
 * To run a query within a React component, call `useTrialEndOrgInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrialEndOrgInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrialEndOrgInfoQuery({
 *   variables: {
 *      organizationID: // value for 'organizationID'
 *   },
 * });
 */
export function useTrialEndOrgInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrialEndOrgInfoQuery, TrialEndOrgInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<TrialEndOrgInfoQuery, TrialEndOrgInfoQueryVariables>(TrialEndOrgInfoDocument, baseOptions);
      }
export function useTrialEndOrgInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrialEndOrgInfoQuery, TrialEndOrgInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrialEndOrgInfoQuery, TrialEndOrgInfoQueryVariables>(TrialEndOrgInfoDocument, baseOptions);
        }
export type TrialEndOrgInfoQueryHookResult = ReturnType<typeof useTrialEndOrgInfoQuery>;
export type TrialEndOrgInfoLazyQueryHookResult = ReturnType<typeof useTrialEndOrgInfoLazyQuery>;
export type TrialEndOrgInfoQueryResult = ApolloReactCommon.QueryResult<TrialEndOrgInfoQuery, TrialEndOrgInfoQueryVariables>;
export const TrialEndCouponInfoDocument = gql`
    query TrialEndCouponInfo($couponID: String!) {
  coupon(id: $couponID) {
    id
    amountOff
    percentOff
    duration
    currency
    name
    durationInMonths
  }
}
    `;
export type TrialEndCouponInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TrialEndCouponInfoQuery, TrialEndCouponInfoQueryVariables>, 'query'> & ({ variables: TrialEndCouponInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TrialEndCouponInfoComponent = (props: TrialEndCouponInfoComponentProps) => (
      <ApolloReactComponents.Query<TrialEndCouponInfoQuery, TrialEndCouponInfoQueryVariables> query={TrialEndCouponInfoDocument} {...props} />
    );
    
export type TrialEndCouponInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TrialEndCouponInfoQuery, TrialEndCouponInfoQueryVariables>
    } & TChildProps;
export function withTrialEndCouponInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TrialEndCouponInfoQuery,
  TrialEndCouponInfoQueryVariables,
  TrialEndCouponInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TrialEndCouponInfoQuery, TrialEndCouponInfoQueryVariables, TrialEndCouponInfoProps<TChildProps, TDataName>>(TrialEndCouponInfoDocument, {
      alias: 'trialEndCouponInfo',
      ...operationOptions
    });
};

/**
 * __useTrialEndCouponInfoQuery__
 *
 * To run a query within a React component, call `useTrialEndCouponInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrialEndCouponInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrialEndCouponInfoQuery({
 *   variables: {
 *      couponID: // value for 'couponID'
 *   },
 * });
 */
export function useTrialEndCouponInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrialEndCouponInfoQuery, TrialEndCouponInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<TrialEndCouponInfoQuery, TrialEndCouponInfoQueryVariables>(TrialEndCouponInfoDocument, baseOptions);
      }
export function useTrialEndCouponInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrialEndCouponInfoQuery, TrialEndCouponInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrialEndCouponInfoQuery, TrialEndCouponInfoQueryVariables>(TrialEndCouponInfoDocument, baseOptions);
        }
export type TrialEndCouponInfoQueryHookResult = ReturnType<typeof useTrialEndCouponInfoQuery>;
export type TrialEndCouponInfoLazyQueryHookResult = ReturnType<typeof useTrialEndCouponInfoLazyQuery>;
export type TrialEndCouponInfoQueryResult = ApolloReactCommon.QueryResult<TrialEndCouponInfoQuery, TrialEndCouponInfoQueryVariables>;
export const CreateAccessTokenDocument = gql`
    mutation createAccessToken($projects: [ProjectSpecifier!]!, $viewId: ID!, $email: String, $username: String, $clientMutationId: String, $previewURL: String) {
  createAccessToken(
    input: {projects: $projects, viewId: $viewId, email: $email, username: $username, clientMutationId: $clientMutationId, previewURL: $previewURL}
  ) {
    accessToken {
      id
      token
      view {
        id
      }
      type
      emails
      createdBy {
        id
        username
        email
        name
      }
      createdAt
      lastAccessedAt
      revokedAt
      projects {
        id
        name
        entityName
      }
    }
    toNewUser
    recipientAlreadyOnTeam
    clientMutationId
  }
}
    `;
export type CreateAccessTokenMutationFn = ApolloReactCommon.MutationFunction<CreateAccessTokenMutation, CreateAccessTokenMutationVariables>;
export type CreateAccessTokenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateAccessTokenMutation, CreateAccessTokenMutationVariables>, 'mutation'>;

    export const CreateAccessTokenComponent = (props: CreateAccessTokenComponentProps) => (
      <ApolloReactComponents.Mutation<CreateAccessTokenMutation, CreateAccessTokenMutationVariables> mutation={CreateAccessTokenDocument} {...props} />
    );
    
export type CreateAccessTokenProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateAccessTokenMutation, CreateAccessTokenMutationVariables>
    } & TChildProps;
export function withCreateAccessToken<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateAccessTokenMutation,
  CreateAccessTokenMutationVariables,
  CreateAccessTokenProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateAccessTokenMutation, CreateAccessTokenMutationVariables, CreateAccessTokenProps<TChildProps, TDataName>>(CreateAccessTokenDocument, {
      alias: 'createAccessToken',
      ...operationOptions
    });
};

/**
 * __useCreateAccessTokenMutation__
 *
 * To run a mutation, you first call `useCreateAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createAccessTokenMutation, { data, loading, error }] = useCreateAccessTokenMutation({
 *   variables: {
 *      projects: // value for 'projects'
 *      viewId: // value for 'viewId'
 *      email: // value for 'email'
 *      username: // value for 'username'
 *      clientMutationId: // value for 'clientMutationId'
 *      previewURL: // value for 'previewURL'
 *   },
 * });
 */
export function useCreateAccessTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateAccessTokenMutation, CreateAccessTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateAccessTokenMutation, CreateAccessTokenMutationVariables>(CreateAccessTokenDocument, baseOptions);
      }
export type CreateAccessTokenMutationHookResult = ReturnType<typeof useCreateAccessTokenMutation>;
export type CreateAccessTokenMutationResult = ApolloReactCommon.MutationResult<CreateAccessTokenMutation>;
export type CreateAccessTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateAccessTokenMutation, CreateAccessTokenMutationVariables>;
export const RevokeAccessTokenDocument = gql`
    mutation revokeAccessToken($token: String!, $clientMutationId: String) {
  revokeAccessToken(input: {token: $token, clientMutationId: $clientMutationId}) {
    success
    clientMutationId
  }
}
    `;
export type RevokeAccessTokenMutationFn = ApolloReactCommon.MutationFunction<RevokeAccessTokenMutation, RevokeAccessTokenMutationVariables>;
export type RevokeAccessTokenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RevokeAccessTokenMutation, RevokeAccessTokenMutationVariables>, 'mutation'>;

    export const RevokeAccessTokenComponent = (props: RevokeAccessTokenComponentProps) => (
      <ApolloReactComponents.Mutation<RevokeAccessTokenMutation, RevokeAccessTokenMutationVariables> mutation={RevokeAccessTokenDocument} {...props} />
    );
    
export type RevokeAccessTokenProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RevokeAccessTokenMutation, RevokeAccessTokenMutationVariables>
    } & TChildProps;
export function withRevokeAccessToken<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RevokeAccessTokenMutation,
  RevokeAccessTokenMutationVariables,
  RevokeAccessTokenProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RevokeAccessTokenMutation, RevokeAccessTokenMutationVariables, RevokeAccessTokenProps<TChildProps, TDataName>>(RevokeAccessTokenDocument, {
      alias: 'revokeAccessToken',
      ...operationOptions
    });
};

/**
 * __useRevokeAccessTokenMutation__
 *
 * To run a mutation, you first call `useRevokeAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeAccessTokenMutation, { data, loading, error }] = useRevokeAccessTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useRevokeAccessTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevokeAccessTokenMutation, RevokeAccessTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<RevokeAccessTokenMutation, RevokeAccessTokenMutationVariables>(RevokeAccessTokenDocument, baseOptions);
      }
export type RevokeAccessTokenMutationHookResult = ReturnType<typeof useRevokeAccessTokenMutation>;
export type RevokeAccessTokenMutationResult = ApolloReactCommon.MutationResult<RevokeAccessTokenMutation>;
export type RevokeAccessTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<RevokeAccessTokenMutation, RevokeAccessTokenMutationVariables>;
export const UpdateAccessTokenProjectsDocument = gql`
    mutation updateAccessTokenProjects($token: String!, $projects: [ProjectSpecifier!]!, $clientMutationId: String) {
  updateAccessTokenProjects(
    input: {token: $token, projects: $projects, clientMutationId: $clientMutationId}
  ) {
    success
    clientMutationId
  }
}
    `;
export type UpdateAccessTokenProjectsMutationFn = ApolloReactCommon.MutationFunction<UpdateAccessTokenProjectsMutation, UpdateAccessTokenProjectsMutationVariables>;
export type UpdateAccessTokenProjectsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateAccessTokenProjectsMutation, UpdateAccessTokenProjectsMutationVariables>, 'mutation'>;

    export const UpdateAccessTokenProjectsComponent = (props: UpdateAccessTokenProjectsComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateAccessTokenProjectsMutation, UpdateAccessTokenProjectsMutationVariables> mutation={UpdateAccessTokenProjectsDocument} {...props} />
    );
    
export type UpdateAccessTokenProjectsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateAccessTokenProjectsMutation, UpdateAccessTokenProjectsMutationVariables>
    } & TChildProps;
export function withUpdateAccessTokenProjects<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateAccessTokenProjectsMutation,
  UpdateAccessTokenProjectsMutationVariables,
  UpdateAccessTokenProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateAccessTokenProjectsMutation, UpdateAccessTokenProjectsMutationVariables, UpdateAccessTokenProjectsProps<TChildProps, TDataName>>(UpdateAccessTokenProjectsDocument, {
      alias: 'updateAccessTokenProjects',
      ...operationOptions
    });
};

/**
 * __useUpdateAccessTokenProjectsMutation__
 *
 * To run a mutation, you first call `useUpdateAccessTokenProjectsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccessTokenProjectsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccessTokenProjectsMutation, { data, loading, error }] = useUpdateAccessTokenProjectsMutation({
 *   variables: {
 *      token: // value for 'token'
 *      projects: // value for 'projects'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useUpdateAccessTokenProjectsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAccessTokenProjectsMutation, UpdateAccessTokenProjectsMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAccessTokenProjectsMutation, UpdateAccessTokenProjectsMutationVariables>(UpdateAccessTokenProjectsDocument, baseOptions);
      }
export type UpdateAccessTokenProjectsMutationHookResult = ReturnType<typeof useUpdateAccessTokenProjectsMutation>;
export type UpdateAccessTokenProjectsMutationResult = ApolloReactCommon.MutationResult<UpdateAccessTokenProjectsMutation>;
export type UpdateAccessTokenProjectsMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAccessTokenProjectsMutation, UpdateAccessTokenProjectsMutationVariables>;
export const UpdateAccessTokenDocument = gql`
    mutation updateAccessToken($token: String!, $email: String, $username: String, $clientMutationId: String, $previewURL: String) {
  updateAccessToken(
    input: {token: $token, email: $email, username: $username, clientMutationId: $clientMutationId, previewURL: $previewURL}
  ) {
    accessToken {
      id
      token
      view {
        id
      }
      type
      emails
      createdBy {
        id
        username
        email
        name
      }
      createdAt
      lastAccessedAt
      revokedAt
      projects {
        id
        name
        entityName
      }
    }
    toNewUser
    recipientAlreadyOnTeam
    clientMutationId
  }
}
    `;
export type UpdateAccessTokenMutationFn = ApolloReactCommon.MutationFunction<UpdateAccessTokenMutation, UpdateAccessTokenMutationVariables>;
export type UpdateAccessTokenComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateAccessTokenMutation, UpdateAccessTokenMutationVariables>, 'mutation'>;

    export const UpdateAccessTokenComponent = (props: UpdateAccessTokenComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateAccessTokenMutation, UpdateAccessTokenMutationVariables> mutation={UpdateAccessTokenDocument} {...props} />
    );
    
export type UpdateAccessTokenProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateAccessTokenMutation, UpdateAccessTokenMutationVariables>
    } & TChildProps;
export function withUpdateAccessToken<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateAccessTokenMutation,
  UpdateAccessTokenMutationVariables,
  UpdateAccessTokenProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateAccessTokenMutation, UpdateAccessTokenMutationVariables, UpdateAccessTokenProps<TChildProps, TDataName>>(UpdateAccessTokenDocument, {
      alias: 'updateAccessToken',
      ...operationOptions
    });
};

/**
 * __useUpdateAccessTokenMutation__
 *
 * To run a mutation, you first call `useUpdateAccessTokenMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAccessTokenMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAccessTokenMutation, { data, loading, error }] = useUpdateAccessTokenMutation({
 *   variables: {
 *      token: // value for 'token'
 *      email: // value for 'email'
 *      username: // value for 'username'
 *      clientMutationId: // value for 'clientMutationId'
 *      previewURL: // value for 'previewURL'
 *   },
 * });
 */
export function useUpdateAccessTokenMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateAccessTokenMutation, UpdateAccessTokenMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateAccessTokenMutation, UpdateAccessTokenMutationVariables>(UpdateAccessTokenDocument, baseOptions);
      }
export type UpdateAccessTokenMutationHookResult = ReturnType<typeof useUpdateAccessTokenMutation>;
export type UpdateAccessTokenMutationResult = ApolloReactCommon.MutationResult<UpdateAccessTokenMutation>;
export type UpdateAccessTokenMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateAccessTokenMutation, UpdateAccessTokenMutationVariables>;
export const RevokeAccessTokenEmailDocument = gql`
    mutation revokeAccessTokenEmail($token: String!, $email: String!, $clientMutationId: String) {
  revokeAccessTokenEmail(
    input: {token: $token, email: $email, clientMutationId: $clientMutationId}
  ) {
    accessToken {
      id
      token
      view {
        id
      }
      type
      emails
    }
    clientMutationId
  }
}
    `;
export type RevokeAccessTokenEmailMutationFn = ApolloReactCommon.MutationFunction<RevokeAccessTokenEmailMutation, RevokeAccessTokenEmailMutationVariables>;
export type RevokeAccessTokenEmailComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RevokeAccessTokenEmailMutation, RevokeAccessTokenEmailMutationVariables>, 'mutation'>;

    export const RevokeAccessTokenEmailComponent = (props: RevokeAccessTokenEmailComponentProps) => (
      <ApolloReactComponents.Mutation<RevokeAccessTokenEmailMutation, RevokeAccessTokenEmailMutationVariables> mutation={RevokeAccessTokenEmailDocument} {...props} />
    );
    
export type RevokeAccessTokenEmailProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<RevokeAccessTokenEmailMutation, RevokeAccessTokenEmailMutationVariables>
    } & TChildProps;
export function withRevokeAccessTokenEmail<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RevokeAccessTokenEmailMutation,
  RevokeAccessTokenEmailMutationVariables,
  RevokeAccessTokenEmailProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RevokeAccessTokenEmailMutation, RevokeAccessTokenEmailMutationVariables, RevokeAccessTokenEmailProps<TChildProps, TDataName>>(RevokeAccessTokenEmailDocument, {
      alias: 'revokeAccessTokenEmail',
      ...operationOptions
    });
};

/**
 * __useRevokeAccessTokenEmailMutation__
 *
 * To run a mutation, you first call `useRevokeAccessTokenEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeAccessTokenEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeAccessTokenEmailMutation, { data, loading, error }] = useRevokeAccessTokenEmailMutation({
 *   variables: {
 *      token: // value for 'token'
 *      email: // value for 'email'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useRevokeAccessTokenEmailMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RevokeAccessTokenEmailMutation, RevokeAccessTokenEmailMutationVariables>) {
        return ApolloReactHooks.useMutation<RevokeAccessTokenEmailMutation, RevokeAccessTokenEmailMutationVariables>(RevokeAccessTokenEmailDocument, baseOptions);
      }
export type RevokeAccessTokenEmailMutationHookResult = ReturnType<typeof useRevokeAccessTokenEmailMutation>;
export type RevokeAccessTokenEmailMutationResult = ApolloReactCommon.MutationResult<RevokeAccessTokenEmailMutation>;
export type RevokeAccessTokenEmailMutationOptions = ApolloReactCommon.BaseMutationOptions<RevokeAccessTokenEmailMutation, RevokeAccessTokenEmailMutationVariables>;
export const ArtifactCollectionAliasActionHistoryDocument = gql`
    query ArtifactCollectionAliasActionHistory($projectName: String, $entityName: String, $artifactTypeName: String!, $artifactCollectionName: String!, $actionCursor: String, $actionFirst: Int, $alias: String) {
  project(name: $projectName, entityName: $entityName) {
    id
    artifactType(name: $artifactTypeName) {
      id
      artifactCollection(name: $artifactCollectionName) {
        id
        aliases {
          edges {
            node {
              id
              alias
            }
          }
        }
        aliasActionHistory(after: $actionCursor, first: $actionFirst, alias: $alias) {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              createdAt
              alias
              actionType
              targetArtifact {
                id
                versionIndex
                artifactSequence {
                  id
                  name
                  project {
                    id
                    name
                  }
                }
              }
              sourceArtifact {
                id
                versionIndex
                artifactSequence {
                  id
                  name
                  project {
                    id
                    name
                  }
                }
              }
              user {
                id
                username
                photoUrl
                name
                accountType
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type ArtifactCollectionAliasActionHistoryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactCollectionAliasActionHistoryQuery, ArtifactCollectionAliasActionHistoryQueryVariables>, 'query'> & ({ variables: ArtifactCollectionAliasActionHistoryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactCollectionAliasActionHistoryComponent = (props: ArtifactCollectionAliasActionHistoryComponentProps) => (
      <ApolloReactComponents.Query<ArtifactCollectionAliasActionHistoryQuery, ArtifactCollectionAliasActionHistoryQueryVariables> query={ArtifactCollectionAliasActionHistoryDocument} {...props} />
    );
    
export type ArtifactCollectionAliasActionHistoryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactCollectionAliasActionHistoryQuery, ArtifactCollectionAliasActionHistoryQueryVariables>
    } & TChildProps;
export function withArtifactCollectionAliasActionHistory<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactCollectionAliasActionHistoryQuery,
  ArtifactCollectionAliasActionHistoryQueryVariables,
  ArtifactCollectionAliasActionHistoryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactCollectionAliasActionHistoryQuery, ArtifactCollectionAliasActionHistoryQueryVariables, ArtifactCollectionAliasActionHistoryProps<TChildProps, TDataName>>(ArtifactCollectionAliasActionHistoryDocument, {
      alias: 'artifactCollectionAliasActionHistory',
      ...operationOptions
    });
};

/**
 * __useArtifactCollectionAliasActionHistoryQuery__
 *
 * To run a query within a React component, call `useArtifactCollectionAliasActionHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactCollectionAliasActionHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactCollectionAliasActionHistoryQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      artifactTypeName: // value for 'artifactTypeName'
 *      artifactCollectionName: // value for 'artifactCollectionName'
 *      actionCursor: // value for 'actionCursor'
 *      actionFirst: // value for 'actionFirst'
 *      alias: // value for 'alias'
 *   },
 * });
 */
export function useArtifactCollectionAliasActionHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactCollectionAliasActionHistoryQuery, ArtifactCollectionAliasActionHistoryQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactCollectionAliasActionHistoryQuery, ArtifactCollectionAliasActionHistoryQueryVariables>(ArtifactCollectionAliasActionHistoryDocument, baseOptions);
      }
export function useArtifactCollectionAliasActionHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactCollectionAliasActionHistoryQuery, ArtifactCollectionAliasActionHistoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactCollectionAliasActionHistoryQuery, ArtifactCollectionAliasActionHistoryQueryVariables>(ArtifactCollectionAliasActionHistoryDocument, baseOptions);
        }
export type ArtifactCollectionAliasActionHistoryQueryHookResult = ReturnType<typeof useArtifactCollectionAliasActionHistoryQuery>;
export type ArtifactCollectionAliasActionHistoryLazyQueryHookResult = ReturnType<typeof useArtifactCollectionAliasActionHistoryLazyQuery>;
export type ArtifactCollectionAliasActionHistoryQueryResult = ApolloReactCommon.QueryResult<ArtifactCollectionAliasActionHistoryQuery, ArtifactCollectionAliasActionHistoryQueryVariables>;
export const ArtifactCollectionActionHistoryDocument = gql`
    query ArtifactCollectionActionHistory($projectName: String, $entityName: String, $artifactTypeName: String!, $artifactCollectionName: String!, $actionCursor: String, $actionFirst: Int) {
  project(name: $projectName, entityName: $entityName) {
    id
    artifactType(name: $artifactTypeName) {
      id
      artifactCollection(name: $artifactCollectionName) {
        id
        aliases {
          edges {
            node {
              id
              alias
            }
          }
        }
        artifactCollectionActions(after: $actionCursor, first: $actionFirst) {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              createdAt
              actionType
              user {
                id
                username
                name
                accountType
              }
              artifact {
                id
                versionIndex
                artifactSequence {
                  id
                  name
                  project {
                    id
                    name
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type ArtifactCollectionActionHistoryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactCollectionActionHistoryQuery, ArtifactCollectionActionHistoryQueryVariables>, 'query'> & ({ variables: ArtifactCollectionActionHistoryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactCollectionActionHistoryComponent = (props: ArtifactCollectionActionHistoryComponentProps) => (
      <ApolloReactComponents.Query<ArtifactCollectionActionHistoryQuery, ArtifactCollectionActionHistoryQueryVariables> query={ArtifactCollectionActionHistoryDocument} {...props} />
    );
    
export type ArtifactCollectionActionHistoryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactCollectionActionHistoryQuery, ArtifactCollectionActionHistoryQueryVariables>
    } & TChildProps;
export function withArtifactCollectionActionHistory<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactCollectionActionHistoryQuery,
  ArtifactCollectionActionHistoryQueryVariables,
  ArtifactCollectionActionHistoryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactCollectionActionHistoryQuery, ArtifactCollectionActionHistoryQueryVariables, ArtifactCollectionActionHistoryProps<TChildProps, TDataName>>(ArtifactCollectionActionHistoryDocument, {
      alias: 'artifactCollectionActionHistory',
      ...operationOptions
    });
};

/**
 * __useArtifactCollectionActionHistoryQuery__
 *
 * To run a query within a React component, call `useArtifactCollectionActionHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactCollectionActionHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactCollectionActionHistoryQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      artifactTypeName: // value for 'artifactTypeName'
 *      artifactCollectionName: // value for 'artifactCollectionName'
 *      actionCursor: // value for 'actionCursor'
 *      actionFirst: // value for 'actionFirst'
 *   },
 * });
 */
export function useArtifactCollectionActionHistoryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactCollectionActionHistoryQuery, ArtifactCollectionActionHistoryQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactCollectionActionHistoryQuery, ArtifactCollectionActionHistoryQueryVariables>(ArtifactCollectionActionHistoryDocument, baseOptions);
      }
export function useArtifactCollectionActionHistoryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactCollectionActionHistoryQuery, ArtifactCollectionActionHistoryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactCollectionActionHistoryQuery, ArtifactCollectionActionHistoryQueryVariables>(ArtifactCollectionActionHistoryDocument, baseOptions);
        }
export type ArtifactCollectionActionHistoryQueryHookResult = ReturnType<typeof useArtifactCollectionActionHistoryQuery>;
export type ArtifactCollectionActionHistoryLazyQueryHookResult = ReturnType<typeof useArtifactCollectionActionHistoryLazyQuery>;
export type ArtifactCollectionActionHistoryQueryResult = ApolloReactCommon.QueryResult<ArtifactCollectionActionHistoryQuery, ArtifactCollectionActionHistoryQueryVariables>;
export const ArtifactCollectionDocument = gql`
    query ArtifactCollection($projectName: String, $entityName: String, $artifactTypeName: String!, $artifactCollectionName: String!, $artifactCursor: String, $artifactFirst: Int) {
  project(name: $projectName, entityName: $entityName) {
    id
    artifactType(name: $artifactTypeName) {
      id
      name
      description
      artifactCollection(name: $artifactCollectionName) {
        __typename
        id
        name
        description
        artifacts {
          totalCount
        }
        artifactMemberships(after: $artifactCursor, first: $artifactFirst) {
          pageInfo {
            hasNextPage
            hasPreviousPage
            startCursor
            endCursor
          }
          edges {
            node {
              id
              createdAt
              commitHash
              versionIndex
              artifact {
                id
                digest
                state
                createdAt
              }
              aliases {
                id
                artifactCollection {
                  id
                  name
                }
                alias
              }
            }
          }
        }
        aliases {
          edges {
            node {
              id
              alias
              artifact {
                id
                versionIndex
              }
            }
          }
        }
        tags {
          edges {
            node {
              id
              name
              tagCategoryName
              attributes
            }
          }
        }
      }
    }
  }
}
    `;
export type ArtifactCollectionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactCollectionQuery, ArtifactCollectionQueryVariables>, 'query'> & ({ variables: ArtifactCollectionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactCollectionComponent = (props: ArtifactCollectionComponentProps) => (
      <ApolloReactComponents.Query<ArtifactCollectionQuery, ArtifactCollectionQueryVariables> query={ArtifactCollectionDocument} {...props} />
    );
    
export type ArtifactCollectionProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactCollectionQuery, ArtifactCollectionQueryVariables>
    } & TChildProps;
export function withArtifactCollection<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactCollectionQuery,
  ArtifactCollectionQueryVariables,
  ArtifactCollectionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactCollectionQuery, ArtifactCollectionQueryVariables, ArtifactCollectionProps<TChildProps, TDataName>>(ArtifactCollectionDocument, {
      alias: 'artifactCollection',
      ...operationOptions
    });
};

/**
 * __useArtifactCollectionQuery__
 *
 * To run a query within a React component, call `useArtifactCollectionQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactCollectionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactCollectionQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      artifactTypeName: // value for 'artifactTypeName'
 *      artifactCollectionName: // value for 'artifactCollectionName'
 *      artifactCursor: // value for 'artifactCursor'
 *      artifactFirst: // value for 'artifactFirst'
 *   },
 * });
 */
export function useArtifactCollectionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactCollectionQuery, ArtifactCollectionQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactCollectionQuery, ArtifactCollectionQueryVariables>(ArtifactCollectionDocument, baseOptions);
      }
export function useArtifactCollectionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactCollectionQuery, ArtifactCollectionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactCollectionQuery, ArtifactCollectionQueryVariables>(ArtifactCollectionDocument, baseOptions);
        }
export type ArtifactCollectionQueryHookResult = ReturnType<typeof useArtifactCollectionQuery>;
export type ArtifactCollectionLazyQueryHookResult = ReturnType<typeof useArtifactCollectionLazyQuery>;
export type ArtifactCollectionQueryResult = ApolloReactCommon.QueryResult<ArtifactCollectionQuery, ArtifactCollectionQueryVariables>;
export const EntityPortfoliosDocument = gql`
    query EntityPortfolios($entityName: String!, $artifactTypeName: String!) {
  entity(name: $entityName) {
    id
    name
    projects {
      edges {
        node {
          id
          name
          artifactType(name: $artifactTypeName) {
            id
            name
            artifactCollections(collectionTypes: [PORTFOLIO]) {
              edges {
                node {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type EntityPortfoliosComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityPortfoliosQuery, EntityPortfoliosQueryVariables>, 'query'> & ({ variables: EntityPortfoliosQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntityPortfoliosComponent = (props: EntityPortfoliosComponentProps) => (
      <ApolloReactComponents.Query<EntityPortfoliosQuery, EntityPortfoliosQueryVariables> query={EntityPortfoliosDocument} {...props} />
    );
    
export type EntityPortfoliosProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityPortfoliosQuery, EntityPortfoliosQueryVariables>
    } & TChildProps;
export function withEntityPortfolios<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityPortfoliosQuery,
  EntityPortfoliosQueryVariables,
  EntityPortfoliosProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityPortfoliosQuery, EntityPortfoliosQueryVariables, EntityPortfoliosProps<TChildProps, TDataName>>(EntityPortfoliosDocument, {
      alias: 'entityPortfolios',
      ...operationOptions
    });
};

/**
 * __useEntityPortfoliosQuery__
 *
 * To run a query within a React component, call `useEntityPortfoliosQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityPortfoliosQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityPortfoliosQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      artifactTypeName: // value for 'artifactTypeName'
 *   },
 * });
 */
export function useEntityPortfoliosQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityPortfoliosQuery, EntityPortfoliosQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityPortfoliosQuery, EntityPortfoliosQueryVariables>(EntityPortfoliosDocument, baseOptions);
      }
export function useEntityPortfoliosLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityPortfoliosQuery, EntityPortfoliosQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityPortfoliosQuery, EntityPortfoliosQueryVariables>(EntityPortfoliosDocument, baseOptions);
        }
export type EntityPortfoliosQueryHookResult = ReturnType<typeof useEntityPortfoliosQuery>;
export type EntityPortfoliosLazyQueryHookResult = ReturnType<typeof useEntityPortfoliosLazyQuery>;
export type EntityPortfoliosQueryResult = ApolloReactCommon.QueryResult<EntityPortfoliosQuery, EntityPortfoliosQueryVariables>;
export const ArtifactCollectionNameFromIdDocument = gql`
    query ArtifactCollectionNameFromID($artifactCollectionID: ID!) {
  artifactCollection(id: $artifactCollectionID) {
    __typename
    ... on ArtifactSequence {
      id
      name
      project {
        id
        name
      }
    }
    ... on ArtifactPortfolio {
      id
      name
      project {
        id
        name
      }
    }
  }
}
    `;
export type ArtifactCollectionNameFromIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactCollectionNameFromIdQuery, ArtifactCollectionNameFromIdQueryVariables>, 'query'> & ({ variables: ArtifactCollectionNameFromIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactCollectionNameFromIdComponent = (props: ArtifactCollectionNameFromIdComponentProps) => (
      <ApolloReactComponents.Query<ArtifactCollectionNameFromIdQuery, ArtifactCollectionNameFromIdQueryVariables> query={ArtifactCollectionNameFromIdDocument} {...props} />
    );
    
export type ArtifactCollectionNameFromIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactCollectionNameFromIdQuery, ArtifactCollectionNameFromIdQueryVariables>
    } & TChildProps;
export function withArtifactCollectionNameFromId<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactCollectionNameFromIdQuery,
  ArtifactCollectionNameFromIdQueryVariables,
  ArtifactCollectionNameFromIdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactCollectionNameFromIdQuery, ArtifactCollectionNameFromIdQueryVariables, ArtifactCollectionNameFromIdProps<TChildProps, TDataName>>(ArtifactCollectionNameFromIdDocument, {
      alias: 'artifactCollectionNameFromId',
      ...operationOptions
    });
};

/**
 * __useArtifactCollectionNameFromIdQuery__
 *
 * To run a query within a React component, call `useArtifactCollectionNameFromIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactCollectionNameFromIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactCollectionNameFromIdQuery({
 *   variables: {
 *      artifactCollectionID: // value for 'artifactCollectionID'
 *   },
 * });
 */
export function useArtifactCollectionNameFromIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactCollectionNameFromIdQuery, ArtifactCollectionNameFromIdQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactCollectionNameFromIdQuery, ArtifactCollectionNameFromIdQueryVariables>(ArtifactCollectionNameFromIdDocument, baseOptions);
      }
export function useArtifactCollectionNameFromIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactCollectionNameFromIdQuery, ArtifactCollectionNameFromIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactCollectionNameFromIdQuery, ArtifactCollectionNameFromIdQueryVariables>(ArtifactCollectionNameFromIdDocument, baseOptions);
        }
export type ArtifactCollectionNameFromIdQueryHookResult = ReturnType<typeof useArtifactCollectionNameFromIdQuery>;
export type ArtifactCollectionNameFromIdLazyQueryHookResult = ReturnType<typeof useArtifactCollectionNameFromIdLazyQuery>;
export type ArtifactCollectionNameFromIdQueryResult = ApolloReactCommon.QueryResult<ArtifactCollectionNameFromIdQuery, ArtifactCollectionNameFromIdQueryVariables>;
export const ArtifactFilesDocument = gql`
    query ArtifactFiles($artifactID: ID!, $artifactFileNames: [String!]!) {
  artifact(id: $artifactID) {
    id
    createdAt
    files(names: $artifactFileNames) {
      edges {
        node {
          id
          name
          displayName
          digest
          url
          directUrl
          isReference
          sizeBytes
          updatedAt
        }
      }
    }
  }
}
    `;
export type ArtifactFilesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactFilesQuery, ArtifactFilesQueryVariables>, 'query'> & ({ variables: ArtifactFilesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactFilesComponent = (props: ArtifactFilesComponentProps) => (
      <ApolloReactComponents.Query<ArtifactFilesQuery, ArtifactFilesQueryVariables> query={ArtifactFilesDocument} {...props} />
    );
    
export type ArtifactFilesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactFilesQuery, ArtifactFilesQueryVariables>
    } & TChildProps;
export function withArtifactFiles<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactFilesQuery,
  ArtifactFilesQueryVariables,
  ArtifactFilesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactFilesQuery, ArtifactFilesQueryVariables, ArtifactFilesProps<TChildProps, TDataName>>(ArtifactFilesDocument, {
      alias: 'artifactFiles',
      ...operationOptions
    });
};

/**
 * __useArtifactFilesQuery__
 *
 * To run a query within a React component, call `useArtifactFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactFilesQuery({
 *   variables: {
 *      artifactID: // value for 'artifactID'
 *      artifactFileNames: // value for 'artifactFileNames'
 *   },
 * });
 */
export function useArtifactFilesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactFilesQuery, ArtifactFilesQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactFilesQuery, ArtifactFilesQueryVariables>(ArtifactFilesDocument, baseOptions);
      }
export function useArtifactFilesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactFilesQuery, ArtifactFilesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactFilesQuery, ArtifactFilesQueryVariables>(ArtifactFilesDocument, baseOptions);
        }
export type ArtifactFilesQueryHookResult = ReturnType<typeof useArtifactFilesQuery>;
export type ArtifactFilesLazyQueryHookResult = ReturnType<typeof useArtifactFilesLazyQuery>;
export type ArtifactFilesQueryResult = ApolloReactCommon.QueryResult<ArtifactFilesQuery, ArtifactFilesQueryVariables>;
export const ArtifactIdDocument = gql`
    query ArtifactID($projectName: String!, $entityName: String!, $artifactTypeName: String!, $artifactID: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    artifactType(name: $artifactTypeName) {
      id
      artifact(name: $artifactID) {
        id
      }
    }
  }
}
    `;
export type ArtifactIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactIdQuery, ArtifactIdQueryVariables>, 'query'> & ({ variables: ArtifactIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactIdComponent = (props: ArtifactIdComponentProps) => (
      <ApolloReactComponents.Query<ArtifactIdQuery, ArtifactIdQueryVariables> query={ArtifactIdDocument} {...props} />
    );
    
export type ArtifactIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactIdQuery, ArtifactIdQueryVariables>
    } & TChildProps;
export function withArtifactId<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactIdQuery,
  ArtifactIdQueryVariables,
  ArtifactIdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactIdQuery, ArtifactIdQueryVariables, ArtifactIdProps<TChildProps, TDataName>>(ArtifactIdDocument, {
      alias: 'artifactId',
      ...operationOptions
    });
};

/**
 * __useArtifactIdQuery__
 *
 * To run a query within a React component, call `useArtifactIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactIdQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      artifactTypeName: // value for 'artifactTypeName'
 *      artifactID: // value for 'artifactID'
 *   },
 * });
 */
export function useArtifactIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactIdQuery, ArtifactIdQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactIdQuery, ArtifactIdQueryVariables>(ArtifactIdDocument, baseOptions);
      }
export function useArtifactIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactIdQuery, ArtifactIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactIdQuery, ArtifactIdQueryVariables>(ArtifactIdDocument, baseOptions);
        }
export type ArtifactIdQueryHookResult = ReturnType<typeof useArtifactIdQuery>;
export type ArtifactIdLazyQueryHookResult = ReturnType<typeof useArtifactIdLazyQuery>;
export type ArtifactIdQueryResult = ApolloReactCommon.QueryResult<ArtifactIdQuery, ArtifactIdQueryVariables>;
export const ArtifactPathInfoDocument = gql`
    query ArtifactPathInfo($artifactId: ID!) {
  artifact(id: $artifactId) {
    id
    createdAt
    commitHash
    versionIndex
    artifactSequence {
      id
      name
    }
    artifactType {
      id
      name
      project {
        id
        name
        entityName
      }
    }
  }
}
    `;
export type ArtifactPathInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactPathInfoQuery, ArtifactPathInfoQueryVariables>, 'query'> & ({ variables: ArtifactPathInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactPathInfoComponent = (props: ArtifactPathInfoComponentProps) => (
      <ApolloReactComponents.Query<ArtifactPathInfoQuery, ArtifactPathInfoQueryVariables> query={ArtifactPathInfoDocument} {...props} />
    );
    
export type ArtifactPathInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactPathInfoQuery, ArtifactPathInfoQueryVariables>
    } & TChildProps;
export function withArtifactPathInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactPathInfoQuery,
  ArtifactPathInfoQueryVariables,
  ArtifactPathInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactPathInfoQuery, ArtifactPathInfoQueryVariables, ArtifactPathInfoProps<TChildProps, TDataName>>(ArtifactPathInfoDocument, {
      alias: 'artifactPathInfo',
      ...operationOptions
    });
};

/**
 * __useArtifactPathInfoQuery__
 *
 * To run a query within a React component, call `useArtifactPathInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactPathInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactPathInfoQuery({
 *   variables: {
 *      artifactId: // value for 'artifactId'
 *   },
 * });
 */
export function useArtifactPathInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactPathInfoQuery, ArtifactPathInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactPathInfoQuery, ArtifactPathInfoQueryVariables>(ArtifactPathInfoDocument, baseOptions);
      }
export function useArtifactPathInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactPathInfoQuery, ArtifactPathInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactPathInfoQuery, ArtifactPathInfoQueryVariables>(ArtifactPathInfoDocument, baseOptions);
        }
export type ArtifactPathInfoQueryHookResult = ReturnType<typeof useArtifactPathInfoQuery>;
export type ArtifactPathInfoLazyQueryHookResult = ReturnType<typeof useArtifactPathInfoLazyQuery>;
export type ArtifactPathInfoQueryResult = ApolloReactCommon.QueryResult<ArtifactPathInfoQuery, ArtifactPathInfoQueryVariables>;
export const ArtifactManifestDocument = gql`
    query ArtifactManifest($projectName: String, $entityName: String, $artifactTypeName: String!, $artifactID: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    artifactType(name: $artifactTypeName) {
      id
      artifact(name: $artifactID) {
        id
        createdAt
        currentManifest {
          id
          file {
            id
            directUrl
          }
        }
      }
    }
  }
  serverInfo {
    defaultCloudRegion {
      id
    }
  }
}
    `;
export type ArtifactManifestComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactManifestQuery, ArtifactManifestQueryVariables>, 'query'> & ({ variables: ArtifactManifestQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactManifestComponent = (props: ArtifactManifestComponentProps) => (
      <ApolloReactComponents.Query<ArtifactManifestQuery, ArtifactManifestQueryVariables> query={ArtifactManifestDocument} {...props} />
    );
    
export type ArtifactManifestProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactManifestQuery, ArtifactManifestQueryVariables>
    } & TChildProps;
export function withArtifactManifest<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactManifestQuery,
  ArtifactManifestQueryVariables,
  ArtifactManifestProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactManifestQuery, ArtifactManifestQueryVariables, ArtifactManifestProps<TChildProps, TDataName>>(ArtifactManifestDocument, {
      alias: 'artifactManifest',
      ...operationOptions
    });
};

/**
 * __useArtifactManifestQuery__
 *
 * To run a query within a React component, call `useArtifactManifestQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactManifestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactManifestQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      artifactTypeName: // value for 'artifactTypeName'
 *      artifactID: // value for 'artifactID'
 *   },
 * });
 */
export function useArtifactManifestQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactManifestQuery, ArtifactManifestQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactManifestQuery, ArtifactManifestQueryVariables>(ArtifactManifestDocument, baseOptions);
      }
export function useArtifactManifestLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactManifestQuery, ArtifactManifestQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactManifestQuery, ArtifactManifestQueryVariables>(ArtifactManifestDocument, baseOptions);
        }
export type ArtifactManifestQueryHookResult = ReturnType<typeof useArtifactManifestQuery>;
export type ArtifactManifestLazyQueryHookResult = ReturnType<typeof useArtifactManifestLazyQuery>;
export type ArtifactManifestQueryResult = ApolloReactCommon.QueryResult<ArtifactManifestQuery, ArtifactManifestQueryVariables>;
export const ArtifactManifestFromIdDocument = gql`
    query ArtifactManifestFromID($artifactId: ID!) {
  artifact(id: $artifactId) {
    id
    createdAt
    artifactSequence {
      id
      name
      project {
        id
        name
        entityName
      }
    }
    artifactType {
      id
      name
    }
    currentManifest {
      id
      file {
        id
        directUrl
      }
    }
  }
  serverInfo {
    defaultCloudRegion {
      id
    }
  }
}
    `;
export type ArtifactManifestFromIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactManifestFromIdQuery, ArtifactManifestFromIdQueryVariables>, 'query'> & ({ variables: ArtifactManifestFromIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactManifestFromIdComponent = (props: ArtifactManifestFromIdComponentProps) => (
      <ApolloReactComponents.Query<ArtifactManifestFromIdQuery, ArtifactManifestFromIdQueryVariables> query={ArtifactManifestFromIdDocument} {...props} />
    );
    
export type ArtifactManifestFromIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactManifestFromIdQuery, ArtifactManifestFromIdQueryVariables>
    } & TChildProps;
export function withArtifactManifestFromId<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactManifestFromIdQuery,
  ArtifactManifestFromIdQueryVariables,
  ArtifactManifestFromIdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactManifestFromIdQuery, ArtifactManifestFromIdQueryVariables, ArtifactManifestFromIdProps<TChildProps, TDataName>>(ArtifactManifestFromIdDocument, {
      alias: 'artifactManifestFromId',
      ...operationOptions
    });
};

/**
 * __useArtifactManifestFromIdQuery__
 *
 * To run a query within a React component, call `useArtifactManifestFromIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactManifestFromIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactManifestFromIdQuery({
 *   variables: {
 *      artifactId: // value for 'artifactId'
 *   },
 * });
 */
export function useArtifactManifestFromIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactManifestFromIdQuery, ArtifactManifestFromIdQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactManifestFromIdQuery, ArtifactManifestFromIdQueryVariables>(ArtifactManifestFromIdDocument, baseOptions);
      }
export function useArtifactManifestFromIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactManifestFromIdQuery, ArtifactManifestFromIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactManifestFromIdQuery, ArtifactManifestFromIdQueryVariables>(ArtifactManifestFromIdDocument, baseOptions);
        }
export type ArtifactManifestFromIdQueryHookResult = ReturnType<typeof useArtifactManifestFromIdQuery>;
export type ArtifactManifestFromIdLazyQueryHookResult = ReturnType<typeof useArtifactManifestFromIdLazyQuery>;
export type ArtifactManifestFromIdQueryResult = ApolloReactCommon.QueryResult<ArtifactManifestFromIdQuery, ArtifactManifestFromIdQueryVariables>;
export const ResolveLatestSequenceAliasToArtifactIdDocument = gql`
    query ResolveLatestSequenceAliasToArtifactId($sequenceId: ID!) {
  artifactSequence(id: $sequenceId) {
    id
    latestArtifact {
      id
    }
  }
}
    `;
export type ResolveLatestSequenceAliasToArtifactIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ResolveLatestSequenceAliasToArtifactIdQuery, ResolveLatestSequenceAliasToArtifactIdQueryVariables>, 'query'> & ({ variables: ResolveLatestSequenceAliasToArtifactIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ResolveLatestSequenceAliasToArtifactIdComponent = (props: ResolveLatestSequenceAliasToArtifactIdComponentProps) => (
      <ApolloReactComponents.Query<ResolveLatestSequenceAliasToArtifactIdQuery, ResolveLatestSequenceAliasToArtifactIdQueryVariables> query={ResolveLatestSequenceAliasToArtifactIdDocument} {...props} />
    );
    
export type ResolveLatestSequenceAliasToArtifactIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ResolveLatestSequenceAliasToArtifactIdQuery, ResolveLatestSequenceAliasToArtifactIdQueryVariables>
    } & TChildProps;
export function withResolveLatestSequenceAliasToArtifactId<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ResolveLatestSequenceAliasToArtifactIdQuery,
  ResolveLatestSequenceAliasToArtifactIdQueryVariables,
  ResolveLatestSequenceAliasToArtifactIdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ResolveLatestSequenceAliasToArtifactIdQuery, ResolveLatestSequenceAliasToArtifactIdQueryVariables, ResolveLatestSequenceAliasToArtifactIdProps<TChildProps, TDataName>>(ResolveLatestSequenceAliasToArtifactIdDocument, {
      alias: 'resolveLatestSequenceAliasToArtifactId',
      ...operationOptions
    });
};

/**
 * __useResolveLatestSequenceAliasToArtifactIdQuery__
 *
 * To run a query within a React component, call `useResolveLatestSequenceAliasToArtifactIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useResolveLatestSequenceAliasToArtifactIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResolveLatestSequenceAliasToArtifactIdQuery({
 *   variables: {
 *      sequenceId: // value for 'sequenceId'
 *   },
 * });
 */
export function useResolveLatestSequenceAliasToArtifactIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ResolveLatestSequenceAliasToArtifactIdQuery, ResolveLatestSequenceAliasToArtifactIdQueryVariables>) {
        return ApolloReactHooks.useQuery<ResolveLatestSequenceAliasToArtifactIdQuery, ResolveLatestSequenceAliasToArtifactIdQueryVariables>(ResolveLatestSequenceAliasToArtifactIdDocument, baseOptions);
      }
export function useResolveLatestSequenceAliasToArtifactIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ResolveLatestSequenceAliasToArtifactIdQuery, ResolveLatestSequenceAliasToArtifactIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ResolveLatestSequenceAliasToArtifactIdQuery, ResolveLatestSequenceAliasToArtifactIdQueryVariables>(ResolveLatestSequenceAliasToArtifactIdDocument, baseOptions);
        }
export type ResolveLatestSequenceAliasToArtifactIdQueryHookResult = ReturnType<typeof useResolveLatestSequenceAliasToArtifactIdQuery>;
export type ResolveLatestSequenceAliasToArtifactIdLazyQueryHookResult = ReturnType<typeof useResolveLatestSequenceAliasToArtifactIdLazyQuery>;
export type ResolveLatestSequenceAliasToArtifactIdQueryResult = ApolloReactCommon.QueryResult<ResolveLatestSequenceAliasToArtifactIdQuery, ResolveLatestSequenceAliasToArtifactIdQueryVariables>;
export const ArtifactCommitHashDocument = gql`
    query ArtifactCommitHash($id: ID!, $artifactCollectionName: String!) {
  artifact(id: $id) {
    id
    commitHash(artifactCollectionName: $artifactCollectionName)
  }
}
    `;
export type ArtifactCommitHashComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactCommitHashQuery, ArtifactCommitHashQueryVariables>, 'query'> & ({ variables: ArtifactCommitHashQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactCommitHashComponent = (props: ArtifactCommitHashComponentProps) => (
      <ApolloReactComponents.Query<ArtifactCommitHashQuery, ArtifactCommitHashQueryVariables> query={ArtifactCommitHashDocument} {...props} />
    );
    
export type ArtifactCommitHashProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactCommitHashQuery, ArtifactCommitHashQueryVariables>
    } & TChildProps;
export function withArtifactCommitHash<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactCommitHashQuery,
  ArtifactCommitHashQueryVariables,
  ArtifactCommitHashProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactCommitHashQuery, ArtifactCommitHashQueryVariables, ArtifactCommitHashProps<TChildProps, TDataName>>(ArtifactCommitHashDocument, {
      alias: 'artifactCommitHash',
      ...operationOptions
    });
};

/**
 * __useArtifactCommitHashQuery__
 *
 * To run a query within a React component, call `useArtifactCommitHashQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactCommitHashQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactCommitHashQuery({
 *   variables: {
 *      id: // value for 'id'
 *      artifactCollectionName: // value for 'artifactCollectionName'
 *   },
 * });
 */
export function useArtifactCommitHashQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactCommitHashQuery, ArtifactCommitHashQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactCommitHashQuery, ArtifactCommitHashQueryVariables>(ArtifactCommitHashDocument, baseOptions);
      }
export function useArtifactCommitHashLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactCommitHashQuery, ArtifactCommitHashQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactCommitHashQuery, ArtifactCommitHashQueryVariables>(ArtifactCommitHashDocument, baseOptions);
        }
export type ArtifactCommitHashQueryHookResult = ReturnType<typeof useArtifactCommitHashQuery>;
export type ArtifactCommitHashLazyQueryHookResult = ReturnType<typeof useArtifactCommitHashLazyQuery>;
export type ArtifactCommitHashQueryResult = ApolloReactCommon.QueryResult<ArtifactCommitHashQuery, ArtifactCommitHashQueryVariables>;
export const CreateArtifactTypesDocument = gql`
    mutation CreateArtifactTypes($entityName: String!, $projectName: String!, $artifactTypes: [ArtifactTypeInput!]!) {
  createArtifactTypes(
    input: {entityName: $entityName, projectName: $projectName, artifactTypes: $artifactTypes}
  ) {
    artifactTypes {
      id
      name
    }
  }
}
    `;
export type CreateArtifactTypesMutationFn = ApolloReactCommon.MutationFunction<CreateArtifactTypesMutation, CreateArtifactTypesMutationVariables>;
export type CreateArtifactTypesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateArtifactTypesMutation, CreateArtifactTypesMutationVariables>, 'mutation'>;

    export const CreateArtifactTypesComponent = (props: CreateArtifactTypesComponentProps) => (
      <ApolloReactComponents.Mutation<CreateArtifactTypesMutation, CreateArtifactTypesMutationVariables> mutation={CreateArtifactTypesDocument} {...props} />
    );
    
export type CreateArtifactTypesProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateArtifactTypesMutation, CreateArtifactTypesMutationVariables>
    } & TChildProps;
export function withCreateArtifactTypes<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateArtifactTypesMutation,
  CreateArtifactTypesMutationVariables,
  CreateArtifactTypesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateArtifactTypesMutation, CreateArtifactTypesMutationVariables, CreateArtifactTypesProps<TChildProps, TDataName>>(CreateArtifactTypesDocument, {
      alias: 'createArtifactTypes',
      ...operationOptions
    });
};

/**
 * __useCreateArtifactTypesMutation__
 *
 * To run a mutation, you first call `useCreateArtifactTypesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArtifactTypesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArtifactTypesMutation, { data, loading, error }] = useCreateArtifactTypesMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      artifactTypes: // value for 'artifactTypes'
 *   },
 * });
 */
export function useCreateArtifactTypesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArtifactTypesMutation, CreateArtifactTypesMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateArtifactTypesMutation, CreateArtifactTypesMutationVariables>(CreateArtifactTypesDocument, baseOptions);
      }
export type CreateArtifactTypesMutationHookResult = ReturnType<typeof useCreateArtifactTypesMutation>;
export type CreateArtifactTypesMutationResult = ApolloReactCommon.MutationResult<CreateArtifactTypesMutation>;
export type CreateArtifactTypesMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateArtifactTypesMutation, CreateArtifactTypesMutationVariables>;
export const UpdateArtifactDocument = gql`
    mutation UpdateArtifact($artifactID: ID!, $description: String, $aliases: [ArtifactAliasInput!], $labels: JSONString, $metadata: JSONString, $ttlDurationSeconds: Int64) {
  updateArtifact(
    input: {artifactID: $artifactID, description: $description, aliases: $aliases, labels: $labels, metadata: $metadata, ttlDurationSeconds: $ttlDurationSeconds}
  ) {
    artifact {
      id
      digest
      description
      aliases {
        id
        artifactCollectionName
        alias
      }
      labels
      ttlDurationSeconds
    }
  }
}
    `;
export type UpdateArtifactMutationFn = ApolloReactCommon.MutationFunction<UpdateArtifactMutation, UpdateArtifactMutationVariables>;
export type UpdateArtifactComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateArtifactMutation, UpdateArtifactMutationVariables>, 'mutation'>;

    export const UpdateArtifactComponent = (props: UpdateArtifactComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateArtifactMutation, UpdateArtifactMutationVariables> mutation={UpdateArtifactDocument} {...props} />
    );
    
export type UpdateArtifactProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateArtifactMutation, UpdateArtifactMutationVariables>
    } & TChildProps;
export function withUpdateArtifact<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateArtifactMutation,
  UpdateArtifactMutationVariables,
  UpdateArtifactProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateArtifactMutation, UpdateArtifactMutationVariables, UpdateArtifactProps<TChildProps, TDataName>>(UpdateArtifactDocument, {
      alias: 'updateArtifact',
      ...operationOptions
    });
};

/**
 * __useUpdateArtifactMutation__
 *
 * To run a mutation, you first call `useUpdateArtifactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArtifactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtifactMutation, { data, loading, error }] = useUpdateArtifactMutation({
 *   variables: {
 *      artifactID: // value for 'artifactID'
 *      description: // value for 'description'
 *      aliases: // value for 'aliases'
 *      labels: // value for 'labels'
 *      metadata: // value for 'metadata'
 *      ttlDurationSeconds: // value for 'ttlDurationSeconds'
 *   },
 * });
 */
export function useUpdateArtifactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArtifactMutation, UpdateArtifactMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateArtifactMutation, UpdateArtifactMutationVariables>(UpdateArtifactDocument, baseOptions);
      }
export type UpdateArtifactMutationHookResult = ReturnType<typeof useUpdateArtifactMutation>;
export type UpdateArtifactMutationResult = ApolloReactCommon.MutationResult<UpdateArtifactMutation>;
export type UpdateArtifactMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateArtifactMutation, UpdateArtifactMutationVariables>;
export const DeleteArtifactDocument = gql`
    mutation DeleteArtifact($artifactID: ID!, $deleteAliases: Boolean = false) {
  deleteArtifact(input: {artifactID: $artifactID, deleteAliases: $deleteAliases}) {
    artifact {
      id
      state
    }
  }
}
    `;
export type DeleteArtifactMutationFn = ApolloReactCommon.MutationFunction<DeleteArtifactMutation, DeleteArtifactMutationVariables>;
export type DeleteArtifactComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteArtifactMutation, DeleteArtifactMutationVariables>, 'mutation'>;

    export const DeleteArtifactComponent = (props: DeleteArtifactComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteArtifactMutation, DeleteArtifactMutationVariables> mutation={DeleteArtifactDocument} {...props} />
    );
    
export type DeleteArtifactProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteArtifactMutation, DeleteArtifactMutationVariables>
    } & TChildProps;
export function withDeleteArtifact<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteArtifactMutation,
  DeleteArtifactMutationVariables,
  DeleteArtifactProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteArtifactMutation, DeleteArtifactMutationVariables, DeleteArtifactProps<TChildProps, TDataName>>(DeleteArtifactDocument, {
      alias: 'deleteArtifact',
      ...operationOptions
    });
};

/**
 * __useDeleteArtifactMutation__
 *
 * To run a mutation, you first call `useDeleteArtifactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArtifactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArtifactMutation, { data, loading, error }] = useDeleteArtifactMutation({
 *   variables: {
 *      artifactID: // value for 'artifactID'
 *      deleteAliases: // value for 'deleteAliases'
 *   },
 * });
 */
export function useDeleteArtifactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArtifactMutation, DeleteArtifactMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteArtifactMutation, DeleteArtifactMutationVariables>(DeleteArtifactDocument, baseOptions);
      }
export type DeleteArtifactMutationHookResult = ReturnType<typeof useDeleteArtifactMutation>;
export type DeleteArtifactMutationResult = ApolloReactCommon.MutationResult<DeleteArtifactMutation>;
export type DeleteArtifactMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteArtifactMutation, DeleteArtifactMutationVariables>;
export const DeleteArtifactSequenceDocument = gql`
    mutation DeleteArtifactSequence($artifactSequenceID: ID!) {
  deleteArtifactSequence(input: {artifactSequenceID: $artifactSequenceID}) {
    artifactCollection {
      id
      state
    }
  }
}
    `;
export type DeleteArtifactSequenceMutationFn = ApolloReactCommon.MutationFunction<DeleteArtifactSequenceMutation, DeleteArtifactSequenceMutationVariables>;
export type DeleteArtifactSequenceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteArtifactSequenceMutation, DeleteArtifactSequenceMutationVariables>, 'mutation'>;

    export const DeleteArtifactSequenceComponent = (props: DeleteArtifactSequenceComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteArtifactSequenceMutation, DeleteArtifactSequenceMutationVariables> mutation={DeleteArtifactSequenceDocument} {...props} />
    );
    
export type DeleteArtifactSequenceProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteArtifactSequenceMutation, DeleteArtifactSequenceMutationVariables>
    } & TChildProps;
export function withDeleteArtifactSequence<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteArtifactSequenceMutation,
  DeleteArtifactSequenceMutationVariables,
  DeleteArtifactSequenceProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteArtifactSequenceMutation, DeleteArtifactSequenceMutationVariables, DeleteArtifactSequenceProps<TChildProps, TDataName>>(DeleteArtifactSequenceDocument, {
      alias: 'deleteArtifactSequence',
      ...operationOptions
    });
};

/**
 * __useDeleteArtifactSequenceMutation__
 *
 * To run a mutation, you first call `useDeleteArtifactSequenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArtifactSequenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArtifactSequenceMutation, { data, loading, error }] = useDeleteArtifactSequenceMutation({
 *   variables: {
 *      artifactSequenceID: // value for 'artifactSequenceID'
 *   },
 * });
 */
export function useDeleteArtifactSequenceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArtifactSequenceMutation, DeleteArtifactSequenceMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteArtifactSequenceMutation, DeleteArtifactSequenceMutationVariables>(DeleteArtifactSequenceDocument, baseOptions);
      }
export type DeleteArtifactSequenceMutationHookResult = ReturnType<typeof useDeleteArtifactSequenceMutation>;
export type DeleteArtifactSequenceMutationResult = ApolloReactCommon.MutationResult<DeleteArtifactSequenceMutation>;
export type DeleteArtifactSequenceMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteArtifactSequenceMutation, DeleteArtifactSequenceMutationVariables>;
export const UpdateArtifactTypeDocument = gql`
    mutation UpdateArtifactType($artifactTypeID: ID!, $description: String) {
  updateArtifactType(
    input: {artifactTypeID: $artifactTypeID, description: $description}
  ) {
    artifactType {
      id
      description
    }
  }
}
    `;
export type UpdateArtifactTypeMutationFn = ApolloReactCommon.MutationFunction<UpdateArtifactTypeMutation, UpdateArtifactTypeMutationVariables>;
export type UpdateArtifactTypeComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateArtifactTypeMutation, UpdateArtifactTypeMutationVariables>, 'mutation'>;

    export const UpdateArtifactTypeComponent = (props: UpdateArtifactTypeComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateArtifactTypeMutation, UpdateArtifactTypeMutationVariables> mutation={UpdateArtifactTypeDocument} {...props} />
    );
    
export type UpdateArtifactTypeProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateArtifactTypeMutation, UpdateArtifactTypeMutationVariables>
    } & TChildProps;
export function withUpdateArtifactType<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateArtifactTypeMutation,
  UpdateArtifactTypeMutationVariables,
  UpdateArtifactTypeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateArtifactTypeMutation, UpdateArtifactTypeMutationVariables, UpdateArtifactTypeProps<TChildProps, TDataName>>(UpdateArtifactTypeDocument, {
      alias: 'updateArtifactType',
      ...operationOptions
    });
};

/**
 * __useUpdateArtifactTypeMutation__
 *
 * To run a mutation, you first call `useUpdateArtifactTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArtifactTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtifactTypeMutation, { data, loading, error }] = useUpdateArtifactTypeMutation({
 *   variables: {
 *      artifactTypeID: // value for 'artifactTypeID'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateArtifactTypeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArtifactTypeMutation, UpdateArtifactTypeMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateArtifactTypeMutation, UpdateArtifactTypeMutationVariables>(UpdateArtifactTypeDocument, baseOptions);
      }
export type UpdateArtifactTypeMutationHookResult = ReturnType<typeof useUpdateArtifactTypeMutation>;
export type UpdateArtifactTypeMutationResult = ApolloReactCommon.MutationResult<UpdateArtifactTypeMutation>;
export type UpdateArtifactTypeMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateArtifactTypeMutation, UpdateArtifactTypeMutationVariables>;
export const UpdateArtifactPortfolioDocument = gql`
    mutation UpdateArtifactPortfolio($artifactPortfolioID: ID!, $name: String, $description: String) {
  updateArtifactPortfolio(
    input: {artifactPortfolioID: $artifactPortfolioID, name: $name, description: $description}
  ) {
    artifactCollection {
      id
      name
      description
    }
  }
}
    `;
export type UpdateArtifactPortfolioMutationFn = ApolloReactCommon.MutationFunction<UpdateArtifactPortfolioMutation, UpdateArtifactPortfolioMutationVariables>;
export type UpdateArtifactPortfolioComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateArtifactPortfolioMutation, UpdateArtifactPortfolioMutationVariables>, 'mutation'>;

    export const UpdateArtifactPortfolioComponent = (props: UpdateArtifactPortfolioComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateArtifactPortfolioMutation, UpdateArtifactPortfolioMutationVariables> mutation={UpdateArtifactPortfolioDocument} {...props} />
    );
    
export type UpdateArtifactPortfolioProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateArtifactPortfolioMutation, UpdateArtifactPortfolioMutationVariables>
    } & TChildProps;
export function withUpdateArtifactPortfolio<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateArtifactPortfolioMutation,
  UpdateArtifactPortfolioMutationVariables,
  UpdateArtifactPortfolioProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateArtifactPortfolioMutation, UpdateArtifactPortfolioMutationVariables, UpdateArtifactPortfolioProps<TChildProps, TDataName>>(UpdateArtifactPortfolioDocument, {
      alias: 'updateArtifactPortfolio',
      ...operationOptions
    });
};

/**
 * __useUpdateArtifactPortfolioMutation__
 *
 * To run a mutation, you first call `useUpdateArtifactPortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArtifactPortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtifactPortfolioMutation, { data, loading, error }] = useUpdateArtifactPortfolioMutation({
 *   variables: {
 *      artifactPortfolioID: // value for 'artifactPortfolioID'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateArtifactPortfolioMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArtifactPortfolioMutation, UpdateArtifactPortfolioMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateArtifactPortfolioMutation, UpdateArtifactPortfolioMutationVariables>(UpdateArtifactPortfolioDocument, baseOptions);
      }
export type UpdateArtifactPortfolioMutationHookResult = ReturnType<typeof useUpdateArtifactPortfolioMutation>;
export type UpdateArtifactPortfolioMutationResult = ApolloReactCommon.MutationResult<UpdateArtifactPortfolioMutation>;
export type UpdateArtifactPortfolioMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateArtifactPortfolioMutation, UpdateArtifactPortfolioMutationVariables>;
export const UpdateArtifactCollectionDocument = gql`
    mutation UpdateArtifactCollection($artifactSequenceID: ID!, $name: String, $description: String) {
  updateArtifactSequence(
    input: {artifactSequenceID: $artifactSequenceID, name: $name, description: $description}
  ) {
    artifactCollection {
      id
      name
      description
    }
  }
}
    `;
export type UpdateArtifactCollectionMutationFn = ApolloReactCommon.MutationFunction<UpdateArtifactCollectionMutation, UpdateArtifactCollectionMutationVariables>;
export type UpdateArtifactCollectionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateArtifactCollectionMutation, UpdateArtifactCollectionMutationVariables>, 'mutation'>;

    export const UpdateArtifactCollectionComponent = (props: UpdateArtifactCollectionComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateArtifactCollectionMutation, UpdateArtifactCollectionMutationVariables> mutation={UpdateArtifactCollectionDocument} {...props} />
    );
    
export type UpdateArtifactCollectionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateArtifactCollectionMutation, UpdateArtifactCollectionMutationVariables>
    } & TChildProps;
export function withUpdateArtifactCollection<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateArtifactCollectionMutation,
  UpdateArtifactCollectionMutationVariables,
  UpdateArtifactCollectionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateArtifactCollectionMutation, UpdateArtifactCollectionMutationVariables, UpdateArtifactCollectionProps<TChildProps, TDataName>>(UpdateArtifactCollectionDocument, {
      alias: 'updateArtifactCollection',
      ...operationOptions
    });
};

/**
 * __useUpdateArtifactCollectionMutation__
 *
 * To run a mutation, you first call `useUpdateArtifactCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArtifactCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtifactCollectionMutation, { data, loading, error }] = useUpdateArtifactCollectionMutation({
 *   variables: {
 *      artifactSequenceID: // value for 'artifactSequenceID'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useUpdateArtifactCollectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArtifactCollectionMutation, UpdateArtifactCollectionMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateArtifactCollectionMutation, UpdateArtifactCollectionMutationVariables>(UpdateArtifactCollectionDocument, baseOptions);
      }
export type UpdateArtifactCollectionMutationHookResult = ReturnType<typeof useUpdateArtifactCollectionMutation>;
export type UpdateArtifactCollectionMutationResult = ApolloReactCommon.MutationResult<UpdateArtifactCollectionMutation>;
export type UpdateArtifactCollectionMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateArtifactCollectionMutation, UpdateArtifactCollectionMutationVariables>;
export const MoveArtifactCollectionDocument = gql`
    mutation MoveArtifactCollection($artifactSequenceID: ID!, $destinationArtifactTypeName: String!) {
  moveArtifactSequence(
    input: {artifactSequenceID: $artifactSequenceID, destinationArtifactTypeName: $destinationArtifactTypeName}
  ) {
    artifactCollection {
      id
      name
      description
    }
  }
}
    `;
export type MoveArtifactCollectionMutationFn = ApolloReactCommon.MutationFunction<MoveArtifactCollectionMutation, MoveArtifactCollectionMutationVariables>;
export type MoveArtifactCollectionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MoveArtifactCollectionMutation, MoveArtifactCollectionMutationVariables>, 'mutation'>;

    export const MoveArtifactCollectionComponent = (props: MoveArtifactCollectionComponentProps) => (
      <ApolloReactComponents.Mutation<MoveArtifactCollectionMutation, MoveArtifactCollectionMutationVariables> mutation={MoveArtifactCollectionDocument} {...props} />
    );
    
export type MoveArtifactCollectionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<MoveArtifactCollectionMutation, MoveArtifactCollectionMutationVariables>
    } & TChildProps;
export function withMoveArtifactCollection<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MoveArtifactCollectionMutation,
  MoveArtifactCollectionMutationVariables,
  MoveArtifactCollectionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, MoveArtifactCollectionMutation, MoveArtifactCollectionMutationVariables, MoveArtifactCollectionProps<TChildProps, TDataName>>(MoveArtifactCollectionDocument, {
      alias: 'moveArtifactCollection',
      ...operationOptions
    });
};

/**
 * __useMoveArtifactCollectionMutation__
 *
 * To run a mutation, you first call `useMoveArtifactCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveArtifactCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveArtifactCollectionMutation, { data, loading, error }] = useMoveArtifactCollectionMutation({
 *   variables: {
 *      artifactSequenceID: // value for 'artifactSequenceID'
 *      destinationArtifactTypeName: // value for 'destinationArtifactTypeName'
 *   },
 * });
 */
export function useMoveArtifactCollectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MoveArtifactCollectionMutation, MoveArtifactCollectionMutationVariables>) {
        return ApolloReactHooks.useMutation<MoveArtifactCollectionMutation, MoveArtifactCollectionMutationVariables>(MoveArtifactCollectionDocument, baseOptions);
      }
export type MoveArtifactCollectionMutationHookResult = ReturnType<typeof useMoveArtifactCollectionMutation>;
export type MoveArtifactCollectionMutationResult = ApolloReactCommon.MutationResult<MoveArtifactCollectionMutation>;
export type MoveArtifactCollectionMutationOptions = ApolloReactCommon.BaseMutationOptions<MoveArtifactCollectionMutation, MoveArtifactCollectionMutationVariables>;
export const DeleteArtifactCollectionDocument = gql`
    mutation DeleteArtifactCollection($artifactSequenceID: ID!) {
  deleteArtifactSequence(input: {artifactSequenceID: $artifactSequenceID}) {
    artifactCollection {
      id
    }
  }
}
    `;
export type DeleteArtifactCollectionMutationFn = ApolloReactCommon.MutationFunction<DeleteArtifactCollectionMutation, DeleteArtifactCollectionMutationVariables>;
export type DeleteArtifactCollectionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteArtifactCollectionMutation, DeleteArtifactCollectionMutationVariables>, 'mutation'>;

    export const DeleteArtifactCollectionComponent = (props: DeleteArtifactCollectionComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteArtifactCollectionMutation, DeleteArtifactCollectionMutationVariables> mutation={DeleteArtifactCollectionDocument} {...props} />
    );
    
export type DeleteArtifactCollectionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteArtifactCollectionMutation, DeleteArtifactCollectionMutationVariables>
    } & TChildProps;
export function withDeleteArtifactCollection<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteArtifactCollectionMutation,
  DeleteArtifactCollectionMutationVariables,
  DeleteArtifactCollectionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteArtifactCollectionMutation, DeleteArtifactCollectionMutationVariables, DeleteArtifactCollectionProps<TChildProps, TDataName>>(DeleteArtifactCollectionDocument, {
      alias: 'deleteArtifactCollection',
      ...operationOptions
    });
};

/**
 * __useDeleteArtifactCollectionMutation__
 *
 * To run a mutation, you first call `useDeleteArtifactCollectionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArtifactCollectionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArtifactCollectionMutation, { data, loading, error }] = useDeleteArtifactCollectionMutation({
 *   variables: {
 *      artifactSequenceID: // value for 'artifactSequenceID'
 *   },
 * });
 */
export function useDeleteArtifactCollectionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArtifactCollectionMutation, DeleteArtifactCollectionMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteArtifactCollectionMutation, DeleteArtifactCollectionMutationVariables>(DeleteArtifactCollectionDocument, baseOptions);
      }
export type DeleteArtifactCollectionMutationHookResult = ReturnType<typeof useDeleteArtifactCollectionMutation>;
export type DeleteArtifactCollectionMutationResult = ApolloReactCommon.MutationResult<DeleteArtifactCollectionMutation>;
export type DeleteArtifactCollectionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteArtifactCollectionMutation, DeleteArtifactCollectionMutationVariables>;
export const DeleteArtifactPortfolioDocument = gql`
    mutation DeleteArtifactPortfolio($artifactPortfolioID: ID!) {
  deleteArtifactPortfolio(input: {artifactPortfolioID: $artifactPortfolioID}) {
    artifactCollection {
      id
    }
  }
}
    `;
export type DeleteArtifactPortfolioMutationFn = ApolloReactCommon.MutationFunction<DeleteArtifactPortfolioMutation, DeleteArtifactPortfolioMutationVariables>;
export type DeleteArtifactPortfolioComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteArtifactPortfolioMutation, DeleteArtifactPortfolioMutationVariables>, 'mutation'>;

    export const DeleteArtifactPortfolioComponent = (props: DeleteArtifactPortfolioComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteArtifactPortfolioMutation, DeleteArtifactPortfolioMutationVariables> mutation={DeleteArtifactPortfolioDocument} {...props} />
    );
    
export type DeleteArtifactPortfolioProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteArtifactPortfolioMutation, DeleteArtifactPortfolioMutationVariables>
    } & TChildProps;
export function withDeleteArtifactPortfolio<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteArtifactPortfolioMutation,
  DeleteArtifactPortfolioMutationVariables,
  DeleteArtifactPortfolioProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteArtifactPortfolioMutation, DeleteArtifactPortfolioMutationVariables, DeleteArtifactPortfolioProps<TChildProps, TDataName>>(DeleteArtifactPortfolioDocument, {
      alias: 'deleteArtifactPortfolio',
      ...operationOptions
    });
};

/**
 * __useDeleteArtifactPortfolioMutation__
 *
 * To run a mutation, you first call `useDeleteArtifactPortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArtifactPortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArtifactPortfolioMutation, { data, loading, error }] = useDeleteArtifactPortfolioMutation({
 *   variables: {
 *      artifactPortfolioID: // value for 'artifactPortfolioID'
 *   },
 * });
 */
export function useDeleteArtifactPortfolioMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArtifactPortfolioMutation, DeleteArtifactPortfolioMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteArtifactPortfolioMutation, DeleteArtifactPortfolioMutationVariables>(DeleteArtifactPortfolioDocument, baseOptions);
      }
export type DeleteArtifactPortfolioMutationHookResult = ReturnType<typeof useDeleteArtifactPortfolioMutation>;
export type DeleteArtifactPortfolioMutationResult = ApolloReactCommon.MutationResult<DeleteArtifactPortfolioMutation>;
export type DeleteArtifactPortfolioMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteArtifactPortfolioMutation, DeleteArtifactPortfolioMutationVariables>;
export const CreateArtifactFilesDocument = gql`
    mutation CreateArtifactFiles($artifactFiles: [CreateArtifactFileSpecInput!]!, $layout: ArtifactStorageLayout = V1) {
  createArtifactFiles(
    input: {artifactFiles: $artifactFiles, storageLayout: $layout}
  ) {
    files {
      edges {
        node {
          id
          name
          displayName
          uploadUrl: url(upload: true)
          directUrl
          isReference
          sizeBytes
          updatedAt
        }
      }
    }
  }
}
    `;
export type CreateArtifactFilesMutationFn = ApolloReactCommon.MutationFunction<CreateArtifactFilesMutation, CreateArtifactFilesMutationVariables>;
export type CreateArtifactFilesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateArtifactFilesMutation, CreateArtifactFilesMutationVariables>, 'mutation'>;

    export const CreateArtifactFilesComponent = (props: CreateArtifactFilesComponentProps) => (
      <ApolloReactComponents.Mutation<CreateArtifactFilesMutation, CreateArtifactFilesMutationVariables> mutation={CreateArtifactFilesDocument} {...props} />
    );
    
export type CreateArtifactFilesProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateArtifactFilesMutation, CreateArtifactFilesMutationVariables>
    } & TChildProps;
export function withCreateArtifactFiles<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateArtifactFilesMutation,
  CreateArtifactFilesMutationVariables,
  CreateArtifactFilesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateArtifactFilesMutation, CreateArtifactFilesMutationVariables, CreateArtifactFilesProps<TChildProps, TDataName>>(CreateArtifactFilesDocument, {
      alias: 'createArtifactFiles',
      ...operationOptions
    });
};

/**
 * __useCreateArtifactFilesMutation__
 *
 * To run a mutation, you first call `useCreateArtifactFilesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArtifactFilesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArtifactFilesMutation, { data, loading, error }] = useCreateArtifactFilesMutation({
 *   variables: {
 *      artifactFiles: // value for 'artifactFiles'
 *      layout: // value for 'layout'
 *   },
 * });
 */
export function useCreateArtifactFilesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArtifactFilesMutation, CreateArtifactFilesMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateArtifactFilesMutation, CreateArtifactFilesMutationVariables>(CreateArtifactFilesDocument, baseOptions);
      }
export type CreateArtifactFilesMutationHookResult = ReturnType<typeof useCreateArtifactFilesMutation>;
export type CreateArtifactFilesMutationResult = ApolloReactCommon.MutationResult<CreateArtifactFilesMutation>;
export type CreateArtifactFilesMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateArtifactFilesMutation, CreateArtifactFilesMutationVariables>;
export const CreateArtifactPortfolioDocument = gql`
    mutation CreateArtifactPortfolio($entityName: String!, $projectName: String!, $artifactTypeID: ID!, $name: String!, $description: String, $clientMutationId: String) {
  createArtifactPortfolio(
    input: {entityName: $entityName, projectName: $projectName, artifactTypeID: $artifactTypeID, name: $name, description: $description, clientMutationId: $clientMutationId}
  ) {
    artifactCollection {
      id
      name
    }
  }
}
    `;
export type CreateArtifactPortfolioMutationFn = ApolloReactCommon.MutationFunction<CreateArtifactPortfolioMutation, CreateArtifactPortfolioMutationVariables>;
export type CreateArtifactPortfolioComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateArtifactPortfolioMutation, CreateArtifactPortfolioMutationVariables>, 'mutation'>;

    export const CreateArtifactPortfolioComponent = (props: CreateArtifactPortfolioComponentProps) => (
      <ApolloReactComponents.Mutation<CreateArtifactPortfolioMutation, CreateArtifactPortfolioMutationVariables> mutation={CreateArtifactPortfolioDocument} {...props} />
    );
    
export type CreateArtifactPortfolioProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateArtifactPortfolioMutation, CreateArtifactPortfolioMutationVariables>
    } & TChildProps;
export function withCreateArtifactPortfolio<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateArtifactPortfolioMutation,
  CreateArtifactPortfolioMutationVariables,
  CreateArtifactPortfolioProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateArtifactPortfolioMutation, CreateArtifactPortfolioMutationVariables, CreateArtifactPortfolioProps<TChildProps, TDataName>>(CreateArtifactPortfolioDocument, {
      alias: 'createArtifactPortfolio',
      ...operationOptions
    });
};

/**
 * __useCreateArtifactPortfolioMutation__
 *
 * To run a mutation, you first call `useCreateArtifactPortfolioMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArtifactPortfolioMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArtifactPortfolioMutation, { data, loading, error }] = useCreateArtifactPortfolioMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      artifactTypeID: // value for 'artifactTypeID'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useCreateArtifactPortfolioMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArtifactPortfolioMutation, CreateArtifactPortfolioMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateArtifactPortfolioMutation, CreateArtifactPortfolioMutationVariables>(CreateArtifactPortfolioDocument, baseOptions);
      }
export type CreateArtifactPortfolioMutationHookResult = ReturnType<typeof useCreateArtifactPortfolioMutation>;
export type CreateArtifactPortfolioMutationResult = ApolloReactCommon.MutationResult<CreateArtifactPortfolioMutation>;
export type CreateArtifactPortfolioMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateArtifactPortfolioMutation, CreateArtifactPortfolioMutationVariables>;
export const CreateArtifactSequenceDocument = gql`
    mutation CreateArtifactSequence($entityName: String!, $projectName: String!, $artifactTypeID: ID!, $name: String!, $description: String, $clientMutationId: String) {
  createArtifactSequence(
    input: {entityName: $entityName, projectName: $projectName, artifactTypeID: $artifactTypeID, name: $name, description: $description, clientMutationId: $clientMutationId}
  ) {
    artifactCollection {
      id
      name
    }
  }
}
    `;
export type CreateArtifactSequenceMutationFn = ApolloReactCommon.MutationFunction<CreateArtifactSequenceMutation, CreateArtifactSequenceMutationVariables>;
export type CreateArtifactSequenceComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateArtifactSequenceMutation, CreateArtifactSequenceMutationVariables>, 'mutation'>;

    export const CreateArtifactSequenceComponent = (props: CreateArtifactSequenceComponentProps) => (
      <ApolloReactComponents.Mutation<CreateArtifactSequenceMutation, CreateArtifactSequenceMutationVariables> mutation={CreateArtifactSequenceDocument} {...props} />
    );
    
export type CreateArtifactSequenceProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateArtifactSequenceMutation, CreateArtifactSequenceMutationVariables>
    } & TChildProps;
export function withCreateArtifactSequence<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateArtifactSequenceMutation,
  CreateArtifactSequenceMutationVariables,
  CreateArtifactSequenceProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateArtifactSequenceMutation, CreateArtifactSequenceMutationVariables, CreateArtifactSequenceProps<TChildProps, TDataName>>(CreateArtifactSequenceDocument, {
      alias: 'createArtifactSequence',
      ...operationOptions
    });
};

/**
 * __useCreateArtifactSequenceMutation__
 *
 * To run a mutation, you first call `useCreateArtifactSequenceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArtifactSequenceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArtifactSequenceMutation, { data, loading, error }] = useCreateArtifactSequenceMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      artifactTypeID: // value for 'artifactTypeID'
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useCreateArtifactSequenceMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArtifactSequenceMutation, CreateArtifactSequenceMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateArtifactSequenceMutation, CreateArtifactSequenceMutationVariables>(CreateArtifactSequenceDocument, baseOptions);
      }
export type CreateArtifactSequenceMutationHookResult = ReturnType<typeof useCreateArtifactSequenceMutation>;
export type CreateArtifactSequenceMutationResult = ApolloReactCommon.MutationResult<CreateArtifactSequenceMutation>;
export type CreateArtifactSequenceMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateArtifactSequenceMutation, CreateArtifactSequenceMutationVariables>;
export const LinkArtifactDocument = gql`
    mutation LinkArtifact($artifactID: ID, $artifactPortfolioID: ID, $aliases: [ArtifactAliasInput!]) {
  linkArtifact(
    input: {artifactID: $artifactID, artifactPortfolioID: $artifactPortfolioID, aliases: $aliases}
  ) {
    artifactCollectionID
    artifactID
    createdAt
    versionIndex
    clientMutationId
  }
}
    `;
export type LinkArtifactMutationFn = ApolloReactCommon.MutationFunction<LinkArtifactMutation, LinkArtifactMutationVariables>;
export type LinkArtifactComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<LinkArtifactMutation, LinkArtifactMutationVariables>, 'mutation'>;

    export const LinkArtifactComponent = (props: LinkArtifactComponentProps) => (
      <ApolloReactComponents.Mutation<LinkArtifactMutation, LinkArtifactMutationVariables> mutation={LinkArtifactDocument} {...props} />
    );
    
export type LinkArtifactProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<LinkArtifactMutation, LinkArtifactMutationVariables>
    } & TChildProps;
export function withLinkArtifact<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  LinkArtifactMutation,
  LinkArtifactMutationVariables,
  LinkArtifactProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, LinkArtifactMutation, LinkArtifactMutationVariables, LinkArtifactProps<TChildProps, TDataName>>(LinkArtifactDocument, {
      alias: 'linkArtifact',
      ...operationOptions
    });
};

/**
 * __useLinkArtifactMutation__
 *
 * To run a mutation, you first call `useLinkArtifactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLinkArtifactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [linkArtifactMutation, { data, loading, error }] = useLinkArtifactMutation({
 *   variables: {
 *      artifactID: // value for 'artifactID'
 *      artifactPortfolioID: // value for 'artifactPortfolioID'
 *      aliases: // value for 'aliases'
 *   },
 * });
 */
export function useLinkArtifactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LinkArtifactMutation, LinkArtifactMutationVariables>) {
        return ApolloReactHooks.useMutation<LinkArtifactMutation, LinkArtifactMutationVariables>(LinkArtifactDocument, baseOptions);
      }
export type LinkArtifactMutationHookResult = ReturnType<typeof useLinkArtifactMutation>;
export type LinkArtifactMutationResult = ApolloReactCommon.MutationResult<LinkArtifactMutation>;
export type LinkArtifactMutationOptions = ApolloReactCommon.BaseMutationOptions<LinkArtifactMutation, LinkArtifactMutationVariables>;
export const UnlinkArtifactDocument = gql`
    mutation UnlinkArtifact($artifactID: ID!, $artifactPortfolioID: ID!) {
  unlinkArtifact(
    input: {artifactID: $artifactID, artifactPortfolioID: $artifactPortfolioID}
  ) {
    artifactID
    success
    clientMutationId
  }
}
    `;
export type UnlinkArtifactMutationFn = ApolloReactCommon.MutationFunction<UnlinkArtifactMutation, UnlinkArtifactMutationVariables>;
export type UnlinkArtifactComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnlinkArtifactMutation, UnlinkArtifactMutationVariables>, 'mutation'>;

    export const UnlinkArtifactComponent = (props: UnlinkArtifactComponentProps) => (
      <ApolloReactComponents.Mutation<UnlinkArtifactMutation, UnlinkArtifactMutationVariables> mutation={UnlinkArtifactDocument} {...props} />
    );
    
export type UnlinkArtifactProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UnlinkArtifactMutation, UnlinkArtifactMutationVariables>
    } & TChildProps;
export function withUnlinkArtifact<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UnlinkArtifactMutation,
  UnlinkArtifactMutationVariables,
  UnlinkArtifactProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UnlinkArtifactMutation, UnlinkArtifactMutationVariables, UnlinkArtifactProps<TChildProps, TDataName>>(UnlinkArtifactDocument, {
      alias: 'unlinkArtifact',
      ...operationOptions
    });
};

/**
 * __useUnlinkArtifactMutation__
 *
 * To run a mutation, you first call `useUnlinkArtifactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnlinkArtifactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unlinkArtifactMutation, { data, loading, error }] = useUnlinkArtifactMutation({
 *   variables: {
 *      artifactID: // value for 'artifactID'
 *      artifactPortfolioID: // value for 'artifactPortfolioID'
 *   },
 * });
 */
export function useUnlinkArtifactMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnlinkArtifactMutation, UnlinkArtifactMutationVariables>) {
        return ApolloReactHooks.useMutation<UnlinkArtifactMutation, UnlinkArtifactMutationVariables>(UnlinkArtifactDocument, baseOptions);
      }
export type UnlinkArtifactMutationHookResult = ReturnType<typeof useUnlinkArtifactMutation>;
export type UnlinkArtifactMutationResult = ApolloReactCommon.MutationResult<UnlinkArtifactMutation>;
export type UnlinkArtifactMutationOptions = ApolloReactCommon.BaseMutationOptions<UnlinkArtifactMutation, UnlinkArtifactMutationVariables>;
export const AddAliasesDocument = gql`
    mutation AddAliases($artifactID: ID!, $aliases: [ArtifactCollectionAliasInput!]!, $clientMutationId: String) {
  addAliases(
    input: {artifactID: $artifactID, aliases: $aliases, clientMutationId: $clientMutationId}
  ) {
    success
  }
}
    `;
export type AddAliasesMutationFn = ApolloReactCommon.MutationFunction<AddAliasesMutation, AddAliasesMutationVariables>;
export type AddAliasesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<AddAliasesMutation, AddAliasesMutationVariables>, 'mutation'>;

    export const AddAliasesComponent = (props: AddAliasesComponentProps) => (
      <ApolloReactComponents.Mutation<AddAliasesMutation, AddAliasesMutationVariables> mutation={AddAliasesDocument} {...props} />
    );
    
export type AddAliasesProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<AddAliasesMutation, AddAliasesMutationVariables>
    } & TChildProps;
export function withAddAliases<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AddAliasesMutation,
  AddAliasesMutationVariables,
  AddAliasesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AddAliasesMutation, AddAliasesMutationVariables, AddAliasesProps<TChildProps, TDataName>>(AddAliasesDocument, {
      alias: 'addAliases',
      ...operationOptions
    });
};

/**
 * __useAddAliasesMutation__
 *
 * To run a mutation, you first call `useAddAliasesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAliasesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAliasesMutation, { data, loading, error }] = useAddAliasesMutation({
 *   variables: {
 *      artifactID: // value for 'artifactID'
 *      aliases: // value for 'aliases'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useAddAliasesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddAliasesMutation, AddAliasesMutationVariables>) {
        return ApolloReactHooks.useMutation<AddAliasesMutation, AddAliasesMutationVariables>(AddAliasesDocument, baseOptions);
      }
export type AddAliasesMutationHookResult = ReturnType<typeof useAddAliasesMutation>;
export type AddAliasesMutationResult = ApolloReactCommon.MutationResult<AddAliasesMutation>;
export type AddAliasesMutationOptions = ApolloReactCommon.BaseMutationOptions<AddAliasesMutation, AddAliasesMutationVariables>;
export const DeleteAliasesDocument = gql`
    mutation DeleteAliases($artifactID: ID!, $aliases: [ArtifactCollectionAliasInput!]!, $clientMutationId: String) {
  deleteAliases(
    input: {artifactID: $artifactID, aliases: $aliases, clientMutationId: $clientMutationId}
  ) {
    success
  }
}
    `;
export type DeleteAliasesMutationFn = ApolloReactCommon.MutationFunction<DeleteAliasesMutation, DeleteAliasesMutationVariables>;
export type DeleteAliasesComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteAliasesMutation, DeleteAliasesMutationVariables>, 'mutation'>;

    export const DeleteAliasesComponent = (props: DeleteAliasesComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteAliasesMutation, DeleteAliasesMutationVariables> mutation={DeleteAliasesDocument} {...props} />
    );
    
export type DeleteAliasesProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteAliasesMutation, DeleteAliasesMutationVariables>
    } & TChildProps;
export function withDeleteAliases<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteAliasesMutation,
  DeleteAliasesMutationVariables,
  DeleteAliasesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteAliasesMutation, DeleteAliasesMutationVariables, DeleteAliasesProps<TChildProps, TDataName>>(DeleteAliasesDocument, {
      alias: 'deleteAliases',
      ...operationOptions
    });
};

/**
 * __useDeleteAliasesMutation__
 *
 * To run a mutation, you first call `useDeleteAliasesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAliasesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAliasesMutation, { data, loading, error }] = useDeleteAliasesMutation({
 *   variables: {
 *      artifactID: // value for 'artifactID'
 *      aliases: // value for 'aliases'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useDeleteAliasesMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteAliasesMutation, DeleteAliasesMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteAliasesMutation, DeleteAliasesMutationVariables>(DeleteAliasesDocument, baseOptions);
      }
export type DeleteAliasesMutationHookResult = ReturnType<typeof useDeleteAliasesMutation>;
export type DeleteAliasesMutationResult = ApolloReactCommon.MutationResult<DeleteAliasesMutation>;
export type DeleteAliasesMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteAliasesMutation, DeleteAliasesMutationVariables>;
export const UpdateArtifactEntityTtlDocument = gql`
    mutation UpdateArtifactEntityTTL($entityName: String!, $ttlDurationSeconds: Int64!) {
  updateArtifactEntityTTL(
    input: {entityName: $entityName, ttlDurationSeconds: $ttlDurationSeconds}
  ) {
    clientMutationId
  }
}
    `;
export type UpdateArtifactEntityTtlMutationFn = ApolloReactCommon.MutationFunction<UpdateArtifactEntityTtlMutation, UpdateArtifactEntityTtlMutationVariables>;
export type UpdateArtifactEntityTtlComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateArtifactEntityTtlMutation, UpdateArtifactEntityTtlMutationVariables>, 'mutation'>;

    export const UpdateArtifactEntityTtlComponent = (props: UpdateArtifactEntityTtlComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateArtifactEntityTtlMutation, UpdateArtifactEntityTtlMutationVariables> mutation={UpdateArtifactEntityTtlDocument} {...props} />
    );
    
export type UpdateArtifactEntityTtlProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateArtifactEntityTtlMutation, UpdateArtifactEntityTtlMutationVariables>
    } & TChildProps;
export function withUpdateArtifactEntityTtl<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateArtifactEntityTtlMutation,
  UpdateArtifactEntityTtlMutationVariables,
  UpdateArtifactEntityTtlProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateArtifactEntityTtlMutation, UpdateArtifactEntityTtlMutationVariables, UpdateArtifactEntityTtlProps<TChildProps, TDataName>>(UpdateArtifactEntityTtlDocument, {
      alias: 'updateArtifactEntityTtl',
      ...operationOptions
    });
};

/**
 * __useUpdateArtifactEntityTtlMutation__
 *
 * To run a mutation, you first call `useUpdateArtifactEntityTtlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateArtifactEntityTtlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateArtifactEntityTtlMutation, { data, loading, error }] = useUpdateArtifactEntityTtlMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      ttlDurationSeconds: // value for 'ttlDurationSeconds'
 *   },
 * });
 */
export function useUpdateArtifactEntityTtlMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateArtifactEntityTtlMutation, UpdateArtifactEntityTtlMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateArtifactEntityTtlMutation, UpdateArtifactEntityTtlMutationVariables>(UpdateArtifactEntityTtlDocument, baseOptions);
      }
export type UpdateArtifactEntityTtlMutationHookResult = ReturnType<typeof useUpdateArtifactEntityTtlMutation>;
export type UpdateArtifactEntityTtlMutationResult = ApolloReactCommon.MutationResult<UpdateArtifactEntityTtlMutation>;
export type UpdateArtifactEntityTtlMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateArtifactEntityTtlMutation, UpdateArtifactEntityTtlMutationVariables>;
export const DeleteArtifactEntityTtlDocument = gql`
    mutation DeleteArtifactEntityTTL($entityName: String!) {
  deleteArtifactEntityTTL(input: {entityName: $entityName}) {
    clientMutationId
  }
}
    `;
export type DeleteArtifactEntityTtlMutationFn = ApolloReactCommon.MutationFunction<DeleteArtifactEntityTtlMutation, DeleteArtifactEntityTtlMutationVariables>;
export type DeleteArtifactEntityTtlComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteArtifactEntityTtlMutation, DeleteArtifactEntityTtlMutationVariables>, 'mutation'>;

    export const DeleteArtifactEntityTtlComponent = (props: DeleteArtifactEntityTtlComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteArtifactEntityTtlMutation, DeleteArtifactEntityTtlMutationVariables> mutation={DeleteArtifactEntityTtlDocument} {...props} />
    );
    
export type DeleteArtifactEntityTtlProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteArtifactEntityTtlMutation, DeleteArtifactEntityTtlMutationVariables>
    } & TChildProps;
export function withDeleteArtifactEntityTtl<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteArtifactEntityTtlMutation,
  DeleteArtifactEntityTtlMutationVariables,
  DeleteArtifactEntityTtlProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteArtifactEntityTtlMutation, DeleteArtifactEntityTtlMutationVariables, DeleteArtifactEntityTtlProps<TChildProps, TDataName>>(DeleteArtifactEntityTtlDocument, {
      alias: 'deleteArtifactEntityTtl',
      ...operationOptions
    });
};

/**
 * __useDeleteArtifactEntityTtlMutation__
 *
 * To run a mutation, you first call `useDeleteArtifactEntityTtlMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArtifactEntityTtlMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArtifactEntityTtlMutation, { data, loading, error }] = useDeleteArtifactEntityTtlMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useDeleteArtifactEntityTtlMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArtifactEntityTtlMutation, DeleteArtifactEntityTtlMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteArtifactEntityTtlMutation, DeleteArtifactEntityTtlMutationVariables>(DeleteArtifactEntityTtlDocument, baseOptions);
      }
export type DeleteArtifactEntityTtlMutationHookResult = ReturnType<typeof useDeleteArtifactEntityTtlMutation>;
export type DeleteArtifactEntityTtlMutationResult = ApolloReactCommon.MutationResult<DeleteArtifactEntityTtlMutation>;
export type DeleteArtifactEntityTtlMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteArtifactEntityTtlMutation, DeleteArtifactEntityTtlMutationVariables>;
export const ArtifactRedirectDocument = gql`
    query ArtifactRedirect($projectName: String, $entityName: String, $artifactTypeName: String!, $artifactCollectionName: String!, $membershipIdentifier: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    artifactTypes {
      edges {
        node {
          id
          name
          artifactCollections(first: 1) {
            edges {
              node {
                id
                name
                artifactMemberships(first: 1) {
                  edges {
                    node {
                      id
                      versionIndex
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    artifactType(name: $artifactTypeName) {
      id
      name
      artifactCollections(first: 1) {
        edges {
          node {
            id
            name
            artifactMemberships(first: 1) {
              edges {
                node {
                  id
                  versionIndex
                }
              }
            }
          }
        }
      }
      artifactCollection(name: $artifactCollectionName) {
        id
        name
        artifactMemberships(first: 1) {
          edges {
            node {
              id
              versionIndex
            }
          }
        }
        artifactMembership(aliasName: $membershipIdentifier) {
          id
          versionIndex
        }
      }
    }
  }
}
    `;
export type ArtifactRedirectComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactRedirectQuery, ArtifactRedirectQueryVariables>, 'query'> & ({ variables: ArtifactRedirectQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactRedirectComponent = (props: ArtifactRedirectComponentProps) => (
      <ApolloReactComponents.Query<ArtifactRedirectQuery, ArtifactRedirectQueryVariables> query={ArtifactRedirectDocument} {...props} />
    );
    
export type ArtifactRedirectProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactRedirectQuery, ArtifactRedirectQueryVariables>
    } & TChildProps;
export function withArtifactRedirect<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactRedirectQuery,
  ArtifactRedirectQueryVariables,
  ArtifactRedirectProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactRedirectQuery, ArtifactRedirectQueryVariables, ArtifactRedirectProps<TChildProps, TDataName>>(ArtifactRedirectDocument, {
      alias: 'artifactRedirect',
      ...operationOptions
    });
};

/**
 * __useArtifactRedirectQuery__
 *
 * To run a query within a React component, call `useArtifactRedirectQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactRedirectQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactRedirectQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      artifactTypeName: // value for 'artifactTypeName'
 *      artifactCollectionName: // value for 'artifactCollectionName'
 *      membershipIdentifier: // value for 'membershipIdentifier'
 *   },
 * });
 */
export function useArtifactRedirectQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactRedirectQuery, ArtifactRedirectQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactRedirectQuery, ArtifactRedirectQueryVariables>(ArtifactRedirectDocument, baseOptions);
      }
export function useArtifactRedirectLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactRedirectQuery, ArtifactRedirectQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactRedirectQuery, ArtifactRedirectQueryVariables>(ArtifactRedirectDocument, baseOptions);
        }
export type ArtifactRedirectQueryHookResult = ReturnType<typeof useArtifactRedirectQuery>;
export type ArtifactRedirectLazyQueryHookResult = ReturnType<typeof useArtifactRedirectLazyQuery>;
export type ArtifactRedirectQueryResult = ApolloReactCommon.QueryResult<ArtifactRedirectQuery, ArtifactRedirectQueryVariables>;
export const ArtifactTypeDocument = gql`
    query ArtifactType($projectName: String!, $entityName: String!, $artifactTypeName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    artifactType(name: $artifactTypeName) {
      id
      name
      description
      artifactSequences(order: "-createdAt") {
        edges {
          node {
            id
            name
            description
            createdAt
            artifacts {
              totalCount
            }
          }
        }
      }
    }
  }
}
    `;
export type ArtifactTypeComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactTypeQuery, ArtifactTypeQueryVariables>, 'query'> & ({ variables: ArtifactTypeQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ArtifactTypeComponent = (props: ArtifactTypeComponentProps) => (
      <ApolloReactComponents.Query<ArtifactTypeQuery, ArtifactTypeQueryVariables> query={ArtifactTypeDocument} {...props} />
    );
    
export type ArtifactTypeProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactTypeQuery, ArtifactTypeQueryVariables>
    } & TChildProps;
export function withArtifactType<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactTypeQuery,
  ArtifactTypeQueryVariables,
  ArtifactTypeProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactTypeQuery, ArtifactTypeQueryVariables, ArtifactTypeProps<TChildProps, TDataName>>(ArtifactTypeDocument, {
      alias: 'artifactType',
      ...operationOptions
    });
};

/**
 * __useArtifactTypeQuery__
 *
 * To run a query within a React component, call `useArtifactTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactTypeQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      artifactTypeName: // value for 'artifactTypeName'
 *   },
 * });
 */
export function useArtifactTypeQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactTypeQuery, ArtifactTypeQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactTypeQuery, ArtifactTypeQueryVariables>(ArtifactTypeDocument, baseOptions);
      }
export function useArtifactTypeLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactTypeQuery, ArtifactTypeQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactTypeQuery, ArtifactTypeQueryVariables>(ArtifactTypeDocument, baseOptions);
        }
export type ArtifactTypeQueryHookResult = ReturnType<typeof useArtifactTypeQuery>;
export type ArtifactTypeLazyQueryHookResult = ReturnType<typeof useArtifactTypeLazyQuery>;
export type ArtifactTypeQueryResult = ApolloReactCommon.QueryResult<ArtifactTypeQuery, ArtifactTypeQueryVariables>;
export const ArtifactTypesDocument = gql`
    query ArtifactTypes($entityName: String, $projectName: String) {
  project(name: $projectName, entityName: $entityName) {
    id
    artifactTypes {
      edges {
        node {
          id
          name
        }
      }
    }
  }
}
    `;
export type ArtifactTypesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ArtifactTypesQuery, ArtifactTypesQueryVariables>, 'query'>;

    export const ArtifactTypesComponent = (props: ArtifactTypesComponentProps) => (
      <ApolloReactComponents.Query<ArtifactTypesQuery, ArtifactTypesQueryVariables> query={ArtifactTypesDocument} {...props} />
    );
    
export type ArtifactTypesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ArtifactTypesQuery, ArtifactTypesQueryVariables>
    } & TChildProps;
export function withArtifactTypes<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ArtifactTypesQuery,
  ArtifactTypesQueryVariables,
  ArtifactTypesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ArtifactTypesQuery, ArtifactTypesQueryVariables, ArtifactTypesProps<TChildProps, TDataName>>(ArtifactTypesDocument, {
      alias: 'artifactTypes',
      ...operationOptions
    });
};

/**
 * __useArtifactTypesQuery__
 *
 * To run a query within a React component, call `useArtifactTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useArtifactTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useArtifactTypesQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *   },
 * });
 */
export function useArtifactTypesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ArtifactTypesQuery, ArtifactTypesQueryVariables>) {
        return ApolloReactHooks.useQuery<ArtifactTypesQuery, ArtifactTypesQueryVariables>(ArtifactTypesDocument, baseOptions);
      }
export function useArtifactTypesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ArtifactTypesQuery, ArtifactTypesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ArtifactTypesQuery, ArtifactTypesQueryVariables>(ArtifactTypesDocument, baseOptions);
        }
export type ArtifactTypesQueryHookResult = ReturnType<typeof useArtifactTypesQuery>;
export type ArtifactTypesLazyQueryHookResult = ReturnType<typeof useArtifactTypesLazyQuery>;
export type ArtifactTypesQueryResult = ApolloReactCommon.QueryResult<ArtifactTypesQuery, ArtifactTypesQueryVariables>;
export const ViewerDefaultResourceConfigsDocument = gql`
    query ViewerDefaultResourceConfigs {
  viewer {
    id
    teams {
      edges {
        node {
          id
          defaultResourceConfigs {
            edges {
              node {
                id
                createdAt
                updatedAt
                scope {
                  __typename
                  Type
                  ID
                }
                resource
                config
                templateVariables {
                  name
                  description
                  schema
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type ViewerDefaultResourceConfigsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewerDefaultResourceConfigsQuery, ViewerDefaultResourceConfigsQueryVariables>, 'query'>;

    export const ViewerDefaultResourceConfigsComponent = (props: ViewerDefaultResourceConfigsComponentProps) => (
      <ApolloReactComponents.Query<ViewerDefaultResourceConfigsQuery, ViewerDefaultResourceConfigsQueryVariables> query={ViewerDefaultResourceConfigsDocument} {...props} />
    );
    
export type ViewerDefaultResourceConfigsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewerDefaultResourceConfigsQuery, ViewerDefaultResourceConfigsQueryVariables>
    } & TChildProps;
export function withViewerDefaultResourceConfigs<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewerDefaultResourceConfigsQuery,
  ViewerDefaultResourceConfigsQueryVariables,
  ViewerDefaultResourceConfigsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewerDefaultResourceConfigsQuery, ViewerDefaultResourceConfigsQueryVariables, ViewerDefaultResourceConfigsProps<TChildProps, TDataName>>(ViewerDefaultResourceConfigsDocument, {
      alias: 'viewerDefaultResourceConfigs',
      ...operationOptions
    });
};

/**
 * __useViewerDefaultResourceConfigsQuery__
 *
 * To run a query within a React component, call `useViewerDefaultResourceConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerDefaultResourceConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerDefaultResourceConfigsQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerDefaultResourceConfigsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewerDefaultResourceConfigsQuery, ViewerDefaultResourceConfigsQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewerDefaultResourceConfigsQuery, ViewerDefaultResourceConfigsQueryVariables>(ViewerDefaultResourceConfigsDocument, baseOptions);
      }
export function useViewerDefaultResourceConfigsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewerDefaultResourceConfigsQuery, ViewerDefaultResourceConfigsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewerDefaultResourceConfigsQuery, ViewerDefaultResourceConfigsQueryVariables>(ViewerDefaultResourceConfigsDocument, baseOptions);
        }
export type ViewerDefaultResourceConfigsQueryHookResult = ReturnType<typeof useViewerDefaultResourceConfigsQuery>;
export type ViewerDefaultResourceConfigsLazyQueryHookResult = ReturnType<typeof useViewerDefaultResourceConfigsLazyQuery>;
export type ViewerDefaultResourceConfigsQueryResult = ApolloReactCommon.QueryResult<ViewerDefaultResourceConfigsQuery, ViewerDefaultResourceConfigsQueryVariables>;
export const EntityDefaultResourceConfigsDocument = gql`
    query EntityDefaultResourceConfigs($entityName: String!) {
  entity(name: $entityName) {
    id
    defaultResourceConfigs {
      edges {
        node {
          id
          createdAt
          updatedAt
          scope {
            __typename
            Type
            ID
          }
          resource
          config
          templateVariables {
            name
            description
            schema
          }
        }
      }
    }
  }
}
    `;
export type EntityDefaultResourceConfigsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityDefaultResourceConfigsQuery, EntityDefaultResourceConfigsQueryVariables>, 'query'> & ({ variables: EntityDefaultResourceConfigsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntityDefaultResourceConfigsComponent = (props: EntityDefaultResourceConfigsComponentProps) => (
      <ApolloReactComponents.Query<EntityDefaultResourceConfigsQuery, EntityDefaultResourceConfigsQueryVariables> query={EntityDefaultResourceConfigsDocument} {...props} />
    );
    
export type EntityDefaultResourceConfigsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityDefaultResourceConfigsQuery, EntityDefaultResourceConfigsQueryVariables>
    } & TChildProps;
export function withEntityDefaultResourceConfigs<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityDefaultResourceConfigsQuery,
  EntityDefaultResourceConfigsQueryVariables,
  EntityDefaultResourceConfigsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityDefaultResourceConfigsQuery, EntityDefaultResourceConfigsQueryVariables, EntityDefaultResourceConfigsProps<TChildProps, TDataName>>(EntityDefaultResourceConfigsDocument, {
      alias: 'entityDefaultResourceConfigs',
      ...operationOptions
    });
};

/**
 * __useEntityDefaultResourceConfigsQuery__
 *
 * To run a query within a React component, call `useEntityDefaultResourceConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityDefaultResourceConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityDefaultResourceConfigsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useEntityDefaultResourceConfigsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityDefaultResourceConfigsQuery, EntityDefaultResourceConfigsQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityDefaultResourceConfigsQuery, EntityDefaultResourceConfigsQueryVariables>(EntityDefaultResourceConfigsDocument, baseOptions);
      }
export function useEntityDefaultResourceConfigsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityDefaultResourceConfigsQuery, EntityDefaultResourceConfigsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityDefaultResourceConfigsQuery, EntityDefaultResourceConfigsQueryVariables>(EntityDefaultResourceConfigsDocument, baseOptions);
        }
export type EntityDefaultResourceConfigsQueryHookResult = ReturnType<typeof useEntityDefaultResourceConfigsQuery>;
export type EntityDefaultResourceConfigsLazyQueryHookResult = ReturnType<typeof useEntityDefaultResourceConfigsLazyQuery>;
export type EntityDefaultResourceConfigsQueryResult = ApolloReactCommon.QueryResult<EntityDefaultResourceConfigsQuery, EntityDefaultResourceConfigsQueryVariables>;
export const ProjectDefaultResourceConfigsDocument = gql`
    query ProjectDefaultResourceConfigs($projectName: String!) {
  project(name: $projectName) {
    id
    defaultResourceConfigs {
      edges {
        node {
          id
          createdAt
          updatedAt
          scope {
            Type
            ID
          }
          resource
          config
          templateVariables {
            name
            description
            schema
          }
        }
      }
    }
  }
}
    `;
export type ProjectDefaultResourceConfigsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectDefaultResourceConfigsQuery, ProjectDefaultResourceConfigsQueryVariables>, 'query'> & ({ variables: ProjectDefaultResourceConfigsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectDefaultResourceConfigsComponent = (props: ProjectDefaultResourceConfigsComponentProps) => (
      <ApolloReactComponents.Query<ProjectDefaultResourceConfigsQuery, ProjectDefaultResourceConfigsQueryVariables> query={ProjectDefaultResourceConfigsDocument} {...props} />
    );
    
export type ProjectDefaultResourceConfigsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProjectDefaultResourceConfigsQuery, ProjectDefaultResourceConfigsQueryVariables>
    } & TChildProps;
export function withProjectDefaultResourceConfigs<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProjectDefaultResourceConfigsQuery,
  ProjectDefaultResourceConfigsQueryVariables,
  ProjectDefaultResourceConfigsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProjectDefaultResourceConfigsQuery, ProjectDefaultResourceConfigsQueryVariables, ProjectDefaultResourceConfigsProps<TChildProps, TDataName>>(ProjectDefaultResourceConfigsDocument, {
      alias: 'projectDefaultResourceConfigs',
      ...operationOptions
    });
};

/**
 * __useProjectDefaultResourceConfigsQuery__
 *
 * To run a query within a React component, call `useProjectDefaultResourceConfigsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectDefaultResourceConfigsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectDefaultResourceConfigsQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *   },
 * });
 */
export function useProjectDefaultResourceConfigsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectDefaultResourceConfigsQuery, ProjectDefaultResourceConfigsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectDefaultResourceConfigsQuery, ProjectDefaultResourceConfigsQueryVariables>(ProjectDefaultResourceConfigsDocument, baseOptions);
      }
export function useProjectDefaultResourceConfigsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectDefaultResourceConfigsQuery, ProjectDefaultResourceConfigsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectDefaultResourceConfigsQuery, ProjectDefaultResourceConfigsQueryVariables>(ProjectDefaultResourceConfigsDocument, baseOptions);
        }
export type ProjectDefaultResourceConfigsQueryHookResult = ReturnType<typeof useProjectDefaultResourceConfigsQuery>;
export type ProjectDefaultResourceConfigsLazyQueryHookResult = ReturnType<typeof useProjectDefaultResourceConfigsLazyQuery>;
export type ProjectDefaultResourceConfigsQueryResult = ApolloReactCommon.QueryResult<ProjectDefaultResourceConfigsQuery, ProjectDefaultResourceConfigsQueryVariables>;
export const CreateDefaultResourceConfigDocument = gql`
    mutation CreateDefaultResourceConfig($entityName: String!, $projectName: String, $resource: String!, $config: JSONString!, $templateVariables: JSONString!) {
  createDefaultResourceConfig(
    input: {entityName: $entityName, projectName: $projectName, resource: $resource, config: $config, templateVariables: $templateVariables}
  ) {
    defaultResourceConfigID
    schemaValidationErrors
    success
  }
}
    `;
export type CreateDefaultResourceConfigMutationFn = ApolloReactCommon.MutationFunction<CreateDefaultResourceConfigMutation, CreateDefaultResourceConfigMutationVariables>;
export type CreateDefaultResourceConfigComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateDefaultResourceConfigMutation, CreateDefaultResourceConfigMutationVariables>, 'mutation'>;

    export const CreateDefaultResourceConfigComponent = (props: CreateDefaultResourceConfigComponentProps) => (
      <ApolloReactComponents.Mutation<CreateDefaultResourceConfigMutation, CreateDefaultResourceConfigMutationVariables> mutation={CreateDefaultResourceConfigDocument} {...props} />
    );
    
export type CreateDefaultResourceConfigProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateDefaultResourceConfigMutation, CreateDefaultResourceConfigMutationVariables>
    } & TChildProps;
export function withCreateDefaultResourceConfig<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateDefaultResourceConfigMutation,
  CreateDefaultResourceConfigMutationVariables,
  CreateDefaultResourceConfigProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateDefaultResourceConfigMutation, CreateDefaultResourceConfigMutationVariables, CreateDefaultResourceConfigProps<TChildProps, TDataName>>(CreateDefaultResourceConfigDocument, {
      alias: 'createDefaultResourceConfig',
      ...operationOptions
    });
};

/**
 * __useCreateDefaultResourceConfigMutation__
 *
 * To run a mutation, you first call `useCreateDefaultResourceConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateDefaultResourceConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createDefaultResourceConfigMutation, { data, loading, error }] = useCreateDefaultResourceConfigMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      resource: // value for 'resource'
 *      config: // value for 'config'
 *      templateVariables: // value for 'templateVariables'
 *   },
 * });
 */
export function useCreateDefaultResourceConfigMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateDefaultResourceConfigMutation, CreateDefaultResourceConfigMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateDefaultResourceConfigMutation, CreateDefaultResourceConfigMutationVariables>(CreateDefaultResourceConfigDocument, baseOptions);
      }
export type CreateDefaultResourceConfigMutationHookResult = ReturnType<typeof useCreateDefaultResourceConfigMutation>;
export type CreateDefaultResourceConfigMutationResult = ApolloReactCommon.MutationResult<CreateDefaultResourceConfigMutation>;
export type CreateDefaultResourceConfigMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateDefaultResourceConfigMutation, CreateDefaultResourceConfigMutationVariables>;
export const DeleteDefaultResourceConfigDocument = gql`
    mutation DeleteDefaultResourceConfig($defaultResourceConfigIDs: [ID!]!) {
  deleteDefaultResourceConfigs(
    input: {defaultResourceConfigIDs: $defaultResourceConfigIDs}
  ) {
    numAffected
    success
  }
}
    `;
export type DeleteDefaultResourceConfigMutationFn = ApolloReactCommon.MutationFunction<DeleteDefaultResourceConfigMutation, DeleteDefaultResourceConfigMutationVariables>;
export type DeleteDefaultResourceConfigComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteDefaultResourceConfigMutation, DeleteDefaultResourceConfigMutationVariables>, 'mutation'>;

    export const DeleteDefaultResourceConfigComponent = (props: DeleteDefaultResourceConfigComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteDefaultResourceConfigMutation, DeleteDefaultResourceConfigMutationVariables> mutation={DeleteDefaultResourceConfigDocument} {...props} />
    );
    
export type DeleteDefaultResourceConfigProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteDefaultResourceConfigMutation, DeleteDefaultResourceConfigMutationVariables>
    } & TChildProps;
export function withDeleteDefaultResourceConfig<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteDefaultResourceConfigMutation,
  DeleteDefaultResourceConfigMutationVariables,
  DeleteDefaultResourceConfigProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteDefaultResourceConfigMutation, DeleteDefaultResourceConfigMutationVariables, DeleteDefaultResourceConfigProps<TChildProps, TDataName>>(DeleteDefaultResourceConfigDocument, {
      alias: 'deleteDefaultResourceConfig',
      ...operationOptions
    });
};

/**
 * __useDeleteDefaultResourceConfigMutation__
 *
 * To run a mutation, you first call `useDeleteDefaultResourceConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteDefaultResourceConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteDefaultResourceConfigMutation, { data, loading, error }] = useDeleteDefaultResourceConfigMutation({
 *   variables: {
 *      defaultResourceConfigIDs: // value for 'defaultResourceConfigIDs'
 *   },
 * });
 */
export function useDeleteDefaultResourceConfigMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteDefaultResourceConfigMutation, DeleteDefaultResourceConfigMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteDefaultResourceConfigMutation, DeleteDefaultResourceConfigMutationVariables>(DeleteDefaultResourceConfigDocument, baseOptions);
      }
export type DeleteDefaultResourceConfigMutationHookResult = ReturnType<typeof useDeleteDefaultResourceConfigMutation>;
export type DeleteDefaultResourceConfigMutationResult = ApolloReactCommon.MutationResult<DeleteDefaultResourceConfigMutation>;
export type DeleteDefaultResourceConfigMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteDefaultResourceConfigMutation, DeleteDefaultResourceConfigMutationVariables>;
export const UpdateDefaultResourceConfigDocument = gql`
    mutation UpdateDefaultResourceConfig($defaultResourceConfigID: ID!, $resource: String, $config: JSONString!, $entityName: String, $projectName: String, $templateVariables: JSONString!) {
  updateDefaultResourceConfig(
    input: {defaultResourceConfigID: $defaultResourceConfigID, resource: $resource, config: $config, entityName: $entityName, projectName: $projectName, templateVariables: $templateVariables}
  ) {
    schemaValidationErrors
    success
  }
}
    `;
export type UpdateDefaultResourceConfigMutationFn = ApolloReactCommon.MutationFunction<UpdateDefaultResourceConfigMutation, UpdateDefaultResourceConfigMutationVariables>;
export type UpdateDefaultResourceConfigComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateDefaultResourceConfigMutation, UpdateDefaultResourceConfigMutationVariables>, 'mutation'>;

    export const UpdateDefaultResourceConfigComponent = (props: UpdateDefaultResourceConfigComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateDefaultResourceConfigMutation, UpdateDefaultResourceConfigMutationVariables> mutation={UpdateDefaultResourceConfigDocument} {...props} />
    );
    
export type UpdateDefaultResourceConfigProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateDefaultResourceConfigMutation, UpdateDefaultResourceConfigMutationVariables>
    } & TChildProps;
export function withUpdateDefaultResourceConfig<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateDefaultResourceConfigMutation,
  UpdateDefaultResourceConfigMutationVariables,
  UpdateDefaultResourceConfigProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateDefaultResourceConfigMutation, UpdateDefaultResourceConfigMutationVariables, UpdateDefaultResourceConfigProps<TChildProps, TDataName>>(UpdateDefaultResourceConfigDocument, {
      alias: 'updateDefaultResourceConfig',
      ...operationOptions
    });
};

/**
 * __useUpdateDefaultResourceConfigMutation__
 *
 * To run a mutation, you first call `useUpdateDefaultResourceConfigMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateDefaultResourceConfigMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateDefaultResourceConfigMutation, { data, loading, error }] = useUpdateDefaultResourceConfigMutation({
 *   variables: {
 *      defaultResourceConfigID: // value for 'defaultResourceConfigID'
 *      resource: // value for 'resource'
 *      config: // value for 'config'
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      templateVariables: // value for 'templateVariables'
 *   },
 * });
 */
export function useUpdateDefaultResourceConfigMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateDefaultResourceConfigMutation, UpdateDefaultResourceConfigMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateDefaultResourceConfigMutation, UpdateDefaultResourceConfigMutationVariables>(UpdateDefaultResourceConfigDocument, baseOptions);
      }
export type UpdateDefaultResourceConfigMutationHookResult = ReturnType<typeof useUpdateDefaultResourceConfigMutation>;
export type UpdateDefaultResourceConfigMutationResult = ApolloReactCommon.MutationResult<UpdateDefaultResourceConfigMutation>;
export type UpdateDefaultResourceConfigMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateDefaultResourceConfigMutation, UpdateDefaultResourceConfigMutationVariables>;
export const EntityArtifactsDocument = gql`
    query EntityArtifacts($entityName: String!) {
  entity(name: $entityName) {
    id
    projects {
      edges {
        node {
          id
          name
          artifactTypes {
            edges {
              node {
                id
                name
                artifactSequences(order: "-createdAt") {
                  edges {
                    node {
                      id
                      name
                      createdAt
                      artifacts(first: 100) {
                        edges {
                          node {
                            id
                            versionIndex
                            aliases {
                              id
                              alias
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type EntityArtifactsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityArtifactsQuery, EntityArtifactsQueryVariables>, 'query'> & ({ variables: EntityArtifactsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntityArtifactsComponent = (props: EntityArtifactsComponentProps) => (
      <ApolloReactComponents.Query<EntityArtifactsQuery, EntityArtifactsQueryVariables> query={EntityArtifactsDocument} {...props} />
    );
    
export type EntityArtifactsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityArtifactsQuery, EntityArtifactsQueryVariables>
    } & TChildProps;
export function withEntityArtifacts<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityArtifactsQuery,
  EntityArtifactsQueryVariables,
  EntityArtifactsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityArtifactsQuery, EntityArtifactsQueryVariables, EntityArtifactsProps<TChildProps, TDataName>>(EntityArtifactsDocument, {
      alias: 'entityArtifacts',
      ...operationOptions
    });
};

/**
 * __useEntityArtifactsQuery__
 *
 * To run a query within a React component, call `useEntityArtifactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityArtifactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityArtifactsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useEntityArtifactsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityArtifactsQuery, EntityArtifactsQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityArtifactsQuery, EntityArtifactsQueryVariables>(EntityArtifactsDocument, baseOptions);
      }
export function useEntityArtifactsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityArtifactsQuery, EntityArtifactsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityArtifactsQuery, EntityArtifactsQueryVariables>(EntityArtifactsDocument, baseOptions);
        }
export type EntityArtifactsQueryHookResult = ReturnType<typeof useEntityArtifactsQuery>;
export type EntityArtifactsLazyQueryHookResult = ReturnType<typeof useEntityArtifactsLazyQuery>;
export type EntityArtifactsQueryResult = ApolloReactCommon.QueryResult<EntityArtifactsQuery, EntityArtifactsQueryVariables>;
export const PaidEntityDocument = gql`
    query PaidEntity($entityName: String!) {
  entity(name: $entityName) {
    id
    name
    isPaid
  }
}
    `;
export type PaidEntityComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PaidEntityQuery, PaidEntityQueryVariables>, 'query'> & ({ variables: PaidEntityQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PaidEntityComponent = (props: PaidEntityComponentProps) => (
      <ApolloReactComponents.Query<PaidEntityQuery, PaidEntityQueryVariables> query={PaidEntityDocument} {...props} />
    );
    
export type PaidEntityProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<PaidEntityQuery, PaidEntityQueryVariables>
    } & TChildProps;
export function withPaidEntity<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PaidEntityQuery,
  PaidEntityQueryVariables,
  PaidEntityProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, PaidEntityQuery, PaidEntityQueryVariables, PaidEntityProps<TChildProps, TDataName>>(PaidEntityDocument, {
      alias: 'paidEntity',
      ...operationOptions
    });
};

/**
 * __usePaidEntityQuery__
 *
 * To run a query within a React component, call `usePaidEntityQuery` and pass it any options that fit your needs.
 * When your component renders, `usePaidEntityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePaidEntityQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function usePaidEntityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PaidEntityQuery, PaidEntityQueryVariables>) {
        return ApolloReactHooks.useQuery<PaidEntityQuery, PaidEntityQueryVariables>(PaidEntityDocument, baseOptions);
      }
export function usePaidEntityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PaidEntityQuery, PaidEntityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PaidEntityQuery, PaidEntityQueryVariables>(PaidEntityDocument, baseOptions);
        }
export type PaidEntityQueryHookResult = ReturnType<typeof usePaidEntityQuery>;
export type PaidEntityLazyQueryHookResult = ReturnType<typeof usePaidEntityLazyQuery>;
export type PaidEntityQueryResult = ApolloReactCommon.QueryResult<PaidEntityQuery, PaidEntityQueryVariables>;
export const EntityProfileDocument = gql`
    query EntityProfile($entityName: String!) {
  entity(name: $entityName) {
    id
    profileView {
      id
      spec
      updatedAt
      updatedBy {
        id
        username
      }
    }
  }
}
    `;
export type EntityProfileComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityProfileQuery, EntityProfileQueryVariables>, 'query'> & ({ variables: EntityProfileQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntityProfileComponent = (props: EntityProfileComponentProps) => (
      <ApolloReactComponents.Query<EntityProfileQuery, EntityProfileQueryVariables> query={EntityProfileDocument} {...props} />
    );
    
export type EntityProfileProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityProfileQuery, EntityProfileQueryVariables>
    } & TChildProps;
export function withEntityProfile<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityProfileQuery,
  EntityProfileQueryVariables,
  EntityProfileProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityProfileQuery, EntityProfileQueryVariables, EntityProfileProps<TChildProps, TDataName>>(EntityProfileDocument, {
      alias: 'entityProfile',
      ...operationOptions
    });
};

/**
 * __useEntityProfileQuery__
 *
 * To run a query within a React component, call `useEntityProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityProfileQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useEntityProfileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityProfileQuery, EntityProfileQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityProfileQuery, EntityProfileQueryVariables>(EntityProfileDocument, baseOptions);
      }
export function useEntityProfileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityProfileQuery, EntityProfileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityProfileQuery, EntityProfileQueryVariables>(EntityProfileDocument, baseOptions);
        }
export type EntityProfileQueryHookResult = ReturnType<typeof useEntityProfileQuery>;
export type EntityProfileLazyQueryHookResult = ReturnType<typeof useEntityProfileLazyQuery>;
export type EntityProfileQueryResult = ApolloReactCommon.QueryResult<EntityProfileQuery, EntityProfileQueryVariables>;
export const EntityProjectsReportsDocument = gql`
    query EntityProjectsReports($entityName: String!, $pattern: String, $first: Int = 100) {
  entity(name: $entityName) {
    id
    projects(order: "-created_at", first: $first, pattern: $pattern) {
      edges {
        node {
          id
          name
          entityName
          reports: allViews(viewType: "runs") {
            edges {
              node {
                id
                ...ViewFragmentMetadata
              }
            }
          }
        }
      }
    }
  }
}
    ${ViewFragmentMetadataFragmentDoc}`;
export type EntityProjectsReportsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityProjectsReportsQuery, EntityProjectsReportsQueryVariables>, 'query'> & ({ variables: EntityProjectsReportsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntityProjectsReportsComponent = (props: EntityProjectsReportsComponentProps) => (
      <ApolloReactComponents.Query<EntityProjectsReportsQuery, EntityProjectsReportsQueryVariables> query={EntityProjectsReportsDocument} {...props} />
    );
    
export type EntityProjectsReportsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityProjectsReportsQuery, EntityProjectsReportsQueryVariables>
    } & TChildProps;
export function withEntityProjectsReports<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityProjectsReportsQuery,
  EntityProjectsReportsQueryVariables,
  EntityProjectsReportsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityProjectsReportsQuery, EntityProjectsReportsQueryVariables, EntityProjectsReportsProps<TChildProps, TDataName>>(EntityProjectsReportsDocument, {
      alias: 'entityProjectsReports',
      ...operationOptions
    });
};

/**
 * __useEntityProjectsReportsQuery__
 *
 * To run a query within a React component, call `useEntityProjectsReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityProjectsReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityProjectsReportsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      pattern: // value for 'pattern'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useEntityProjectsReportsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityProjectsReportsQuery, EntityProjectsReportsQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityProjectsReportsQuery, EntityProjectsReportsQueryVariables>(EntityProjectsReportsDocument, baseOptions);
      }
export function useEntityProjectsReportsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityProjectsReportsQuery, EntityProjectsReportsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityProjectsReportsQuery, EntityProjectsReportsQueryVariables>(EntityProjectsReportsDocument, baseOptions);
        }
export type EntityProjectsReportsQueryHookResult = ReturnType<typeof useEntityProjectsReportsQuery>;
export type EntityProjectsReportsLazyQueryHookResult = ReturnType<typeof useEntityProjectsReportsLazyQuery>;
export type EntityProjectsReportsQueryResult = ApolloReactCommon.QueryResult<EntityProjectsReportsQuery, EntityProjectsReportsQueryVariables>;
export const EntityTagsDocument = gql`
    query EntityTags($entityName: String!) {
  entity(name: $entityName) {
    id
    tagCategories {
      id
      name
      tags {
        edges {
          node {
            id
            name
            tagCategoryName
            attributes
          }
        }
      }
    }
  }
}
    `;
export type EntityTagsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityTagsQuery, EntityTagsQueryVariables>, 'query'> & ({ variables: EntityTagsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntityTagsComponent = (props: EntityTagsComponentProps) => (
      <ApolloReactComponents.Query<EntityTagsQuery, EntityTagsQueryVariables> query={EntityTagsDocument} {...props} />
    );
    
export type EntityTagsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityTagsQuery, EntityTagsQueryVariables>
    } & TChildProps;
export function withEntityTags<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityTagsQuery,
  EntityTagsQueryVariables,
  EntityTagsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityTagsQuery, EntityTagsQueryVariables, EntityTagsProps<TChildProps, TDataName>>(EntityTagsDocument, {
      alias: 'entityTags',
      ...operationOptions
    });
};

/**
 * __useEntityTagsQuery__
 *
 * To run a query within a React component, call `useEntityTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityTagsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useEntityTagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityTagsQuery, EntityTagsQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityTagsQuery, EntityTagsQueryVariables>(EntityTagsDocument, baseOptions);
      }
export function useEntityTagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityTagsQuery, EntityTagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityTagsQuery, EntityTagsQueryVariables>(EntityTagsDocument, baseOptions);
        }
export type EntityTagsQueryHookResult = ReturnType<typeof useEntityTagsQuery>;
export type EntityTagsLazyQueryHookResult = ReturnType<typeof useEntityTagsLazyQuery>;
export type EntityTagsQueryResult = ApolloReactCommon.QueryResult<EntityTagsQuery, EntityTagsQueryVariables>;
export const GalleryDocument = gql`
    query Gallery {
  singletonView(type: "fullyConnected") {
    id
    spec
  }
}
    `;
export type GalleryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GalleryQuery, GalleryQueryVariables>, 'query'>;

    export const GalleryComponent = (props: GalleryComponentProps) => (
      <ApolloReactComponents.Query<GalleryQuery, GalleryQueryVariables> query={GalleryDocument} {...props} />
    );
    
export type GalleryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GalleryQuery, GalleryQueryVariables>
    } & TChildProps;
export function withGallery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GalleryQuery,
  GalleryQueryVariables,
  GalleryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GalleryQuery, GalleryQueryVariables, GalleryProps<TChildProps, TDataName>>(GalleryDocument, {
      alias: 'gallery',
      ...operationOptions
    });
};

/**
 * __useGalleryQuery__
 *
 * To run a query within a React component, call `useGalleryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGalleryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGalleryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGalleryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GalleryQuery, GalleryQueryVariables>) {
        return ApolloReactHooks.useQuery<GalleryQuery, GalleryQueryVariables>(GalleryDocument, baseOptions);
      }
export function useGalleryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GalleryQuery, GalleryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GalleryQuery, GalleryQueryVariables>(GalleryDocument, baseOptions);
        }
export type GalleryQueryHookResult = ReturnType<typeof useGalleryQuery>;
export type GalleryLazyQueryHookResult = ReturnType<typeof useGalleryLazyQuery>;
export type GalleryQueryResult = ApolloReactCommon.QueryResult<GalleryQuery, GalleryQueryVariables>;
export const UpdateGalleryDocument = gql`
    mutation UpdateGallery($id: ID, $spec: String!) {
  upsertView(input: {id: $id, spec: $spec, name: "", type: "fullyConnected"}) {
    view {
      id
      updatedAt
      createdAt
      spec
    }
    inserted
  }
}
    `;
export type UpdateGalleryMutationFn = ApolloReactCommon.MutationFunction<UpdateGalleryMutation, UpdateGalleryMutationVariables>;
export type UpdateGalleryComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateGalleryMutation, UpdateGalleryMutationVariables>, 'mutation'>;

    export const UpdateGalleryComponent = (props: UpdateGalleryComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateGalleryMutation, UpdateGalleryMutationVariables> mutation={UpdateGalleryDocument} {...props} />
    );
    
export type UpdateGalleryProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpdateGalleryMutation, UpdateGalleryMutationVariables>
    } & TChildProps;
export function withUpdateGallery<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateGalleryMutation,
  UpdateGalleryMutationVariables,
  UpdateGalleryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateGalleryMutation, UpdateGalleryMutationVariables, UpdateGalleryProps<TChildProps, TDataName>>(UpdateGalleryDocument, {
      alias: 'updateGallery',
      ...operationOptions
    });
};

/**
 * __useUpdateGalleryMutation__
 *
 * To run a mutation, you first call `useUpdateGalleryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGalleryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGalleryMutation, { data, loading, error }] = useUpdateGalleryMutation({
 *   variables: {
 *      id: // value for 'id'
 *      spec: // value for 'spec'
 *   },
 * });
 */
export function useUpdateGalleryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateGalleryMutation, UpdateGalleryMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateGalleryMutation, UpdateGalleryMutationVariables>(UpdateGalleryDocument, baseOptions);
      }
export type UpdateGalleryMutationHookResult = ReturnType<typeof useUpdateGalleryMutation>;
export type UpdateGalleryMutationResult = ApolloReactCommon.MutationResult<UpdateGalleryMutation>;
export type UpdateGalleryMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateGalleryMutation, UpdateGalleryMutationVariables>;
export const FeaturedReportsMetadataDocument = gql`
    query FeaturedReportsMetadata($ids: [ID!]!, $recentStarCountFrom: DateTime) {
  views(ids: $ids) {
    edges {
      node {
        id
        createdAt
        updatedAt
        entityName
        project {
          id
          entityName
          name
          access
        }
        displayName
        description
        previewUrl
        viewCount
        starCount
        recentStarCount: starCount(from: $recentStarCountFrom)
        starred
        discussionThreads {
          edges {
            node {
              id
              comments {
                totalCount
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type FeaturedReportsMetadataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FeaturedReportsMetadataQuery, FeaturedReportsMetadataQueryVariables>, 'query'> & ({ variables: FeaturedReportsMetadataQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FeaturedReportsMetadataComponent = (props: FeaturedReportsMetadataComponentProps) => (
      <ApolloReactComponents.Query<FeaturedReportsMetadataQuery, FeaturedReportsMetadataQueryVariables> query={FeaturedReportsMetadataDocument} {...props} />
    );
    
export type FeaturedReportsMetadataProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FeaturedReportsMetadataQuery, FeaturedReportsMetadataQueryVariables>
    } & TChildProps;
export function withFeaturedReportsMetadata<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FeaturedReportsMetadataQuery,
  FeaturedReportsMetadataQueryVariables,
  FeaturedReportsMetadataProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FeaturedReportsMetadataQuery, FeaturedReportsMetadataQueryVariables, FeaturedReportsMetadataProps<TChildProps, TDataName>>(FeaturedReportsMetadataDocument, {
      alias: 'featuredReportsMetadata',
      ...operationOptions
    });
};

/**
 * __useFeaturedReportsMetadataQuery__
 *
 * To run a query within a React component, call `useFeaturedReportsMetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedReportsMetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedReportsMetadataQuery({
 *   variables: {
 *      ids: // value for 'ids'
 *      recentStarCountFrom: // value for 'recentStarCountFrom'
 *   },
 * });
 */
export function useFeaturedReportsMetadataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FeaturedReportsMetadataQuery, FeaturedReportsMetadataQueryVariables>) {
        return ApolloReactHooks.useQuery<FeaturedReportsMetadataQuery, FeaturedReportsMetadataQueryVariables>(FeaturedReportsMetadataDocument, baseOptions);
      }
export function useFeaturedReportsMetadataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FeaturedReportsMetadataQuery, FeaturedReportsMetadataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FeaturedReportsMetadataQuery, FeaturedReportsMetadataQueryVariables>(FeaturedReportsMetadataDocument, baseOptions);
        }
export type FeaturedReportsMetadataQueryHookResult = ReturnType<typeof useFeaturedReportsMetadataQuery>;
export type FeaturedReportsMetadataLazyQueryHookResult = ReturnType<typeof useFeaturedReportsMetadataLazyQuery>;
export type FeaturedReportsMetadataQueryResult = ApolloReactCommon.QueryResult<FeaturedReportsMetadataQuery, FeaturedReportsMetadataQueryVariables>;
export const FeaturedReportsAuthorsDocument = gql`
    query FeaturedReportsAuthors($usernames: [String!]!) {
  users(usernames: $usernames) {
    edges {
      node {
        id
        name
        username
        photoUrl
        userInfo
      }
    }
  }
}
    `;
export type FeaturedReportsAuthorsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FeaturedReportsAuthorsQuery, FeaturedReportsAuthorsQueryVariables>, 'query'> & ({ variables: FeaturedReportsAuthorsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FeaturedReportsAuthorsComponent = (props: FeaturedReportsAuthorsComponentProps) => (
      <ApolloReactComponents.Query<FeaturedReportsAuthorsQuery, FeaturedReportsAuthorsQueryVariables> query={FeaturedReportsAuthorsDocument} {...props} />
    );
    
export type FeaturedReportsAuthorsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FeaturedReportsAuthorsQuery, FeaturedReportsAuthorsQueryVariables>
    } & TChildProps;
export function withFeaturedReportsAuthors<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FeaturedReportsAuthorsQuery,
  FeaturedReportsAuthorsQueryVariables,
  FeaturedReportsAuthorsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FeaturedReportsAuthorsQuery, FeaturedReportsAuthorsQueryVariables, FeaturedReportsAuthorsProps<TChildProps, TDataName>>(FeaturedReportsAuthorsDocument, {
      alias: 'featuredReportsAuthors',
      ...operationOptions
    });
};

/**
 * __useFeaturedReportsAuthorsQuery__
 *
 * To run a query within a React component, call `useFeaturedReportsAuthorsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFeaturedReportsAuthorsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFeaturedReportsAuthorsQuery({
 *   variables: {
 *      usernames: // value for 'usernames'
 *   },
 * });
 */
export function useFeaturedReportsAuthorsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FeaturedReportsAuthorsQuery, FeaturedReportsAuthorsQueryVariables>) {
        return ApolloReactHooks.useQuery<FeaturedReportsAuthorsQuery, FeaturedReportsAuthorsQueryVariables>(FeaturedReportsAuthorsDocument, baseOptions);
      }
export function useFeaturedReportsAuthorsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FeaturedReportsAuthorsQuery, FeaturedReportsAuthorsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FeaturedReportsAuthorsQuery, FeaturedReportsAuthorsQueryVariables>(FeaturedReportsAuthorsDocument, baseOptions);
        }
export type FeaturedReportsAuthorsQueryHookResult = ReturnType<typeof useFeaturedReportsAuthorsQuery>;
export type FeaturedReportsAuthorsLazyQueryHookResult = ReturnType<typeof useFeaturedReportsAuthorsLazyQuery>;
export type FeaturedReportsAuthorsQueryResult = ApolloReactCommon.QueryResult<FeaturedReportsAuthorsQuery, FeaturedReportsAuthorsQueryVariables>;
export const InsertGalleryDiscussionDocument = gql`
    mutation InsertGalleryDiscussion($spec: String!, $reportID: ID!, $sendEmail: Boolean) {
  insertGalleryDiscussion(
    input: {spec: $spec, reportID: $reportID, sendEmail: $sendEmail}
  ) {
    success
  }
}
    `;
export type InsertGalleryDiscussionMutationFn = ApolloReactCommon.MutationFunction<InsertGalleryDiscussionMutation, InsertGalleryDiscussionMutationVariables>;
export type InsertGalleryDiscussionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<InsertGalleryDiscussionMutation, InsertGalleryDiscussionMutationVariables>, 'mutation'>;

    export const InsertGalleryDiscussionComponent = (props: InsertGalleryDiscussionComponentProps) => (
      <ApolloReactComponents.Mutation<InsertGalleryDiscussionMutation, InsertGalleryDiscussionMutationVariables> mutation={InsertGalleryDiscussionDocument} {...props} />
    );
    
export type InsertGalleryDiscussionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<InsertGalleryDiscussionMutation, InsertGalleryDiscussionMutationVariables>
    } & TChildProps;
export function withInsertGalleryDiscussion<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InsertGalleryDiscussionMutation,
  InsertGalleryDiscussionMutationVariables,
  InsertGalleryDiscussionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, InsertGalleryDiscussionMutation, InsertGalleryDiscussionMutationVariables, InsertGalleryDiscussionProps<TChildProps, TDataName>>(InsertGalleryDiscussionDocument, {
      alias: 'insertGalleryDiscussion',
      ...operationOptions
    });
};

/**
 * __useInsertGalleryDiscussionMutation__
 *
 * To run a mutation, you first call `useInsertGalleryDiscussionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertGalleryDiscussionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertGalleryDiscussionMutation, { data, loading, error }] = useInsertGalleryDiscussionMutation({
 *   variables: {
 *      spec: // value for 'spec'
 *      reportID: // value for 'reportID'
 *      sendEmail: // value for 'sendEmail'
 *   },
 * });
 */
export function useInsertGalleryDiscussionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InsertGalleryDiscussionMutation, InsertGalleryDiscussionMutationVariables>) {
        return ApolloReactHooks.useMutation<InsertGalleryDiscussionMutation, InsertGalleryDiscussionMutationVariables>(InsertGalleryDiscussionDocument, baseOptions);
      }
export type InsertGalleryDiscussionMutationHookResult = ReturnType<typeof useInsertGalleryDiscussionMutation>;
export type InsertGalleryDiscussionMutationResult = ApolloReactCommon.MutationResult<InsertGalleryDiscussionMutation>;
export type InsertGalleryDiscussionMutationOptions = ApolloReactCommon.BaseMutationOptions<InsertGalleryDiscussionMutation, InsertGalleryDiscussionMutationVariables>;
export const SubmitFcNewsDocument = gql`
    mutation SubmitFCNews($name: String!, $email: String!, $url: String!, $details: String) {
  submitFCNews(input: {name: $name, email: $email, url: $url, details: $details}) {
    success
  }
}
    `;
export type SubmitFcNewsMutationFn = ApolloReactCommon.MutationFunction<SubmitFcNewsMutation, SubmitFcNewsMutationVariables>;
export type SubmitFcNewsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SubmitFcNewsMutation, SubmitFcNewsMutationVariables>, 'mutation'>;

    export const SubmitFcNewsComponent = (props: SubmitFcNewsComponentProps) => (
      <ApolloReactComponents.Mutation<SubmitFcNewsMutation, SubmitFcNewsMutationVariables> mutation={SubmitFcNewsDocument} {...props} />
    );
    
export type SubmitFcNewsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<SubmitFcNewsMutation, SubmitFcNewsMutationVariables>
    } & TChildProps;
export function withSubmitFcNews<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SubmitFcNewsMutation,
  SubmitFcNewsMutationVariables,
  SubmitFcNewsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, SubmitFcNewsMutation, SubmitFcNewsMutationVariables, SubmitFcNewsProps<TChildProps, TDataName>>(SubmitFcNewsDocument, {
      alias: 'submitFcNews',
      ...operationOptions
    });
};

/**
 * __useSubmitFcNewsMutation__
 *
 * To run a mutation, you first call `useSubmitFcNewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitFcNewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitFcNewsMutation, { data, loading, error }] = useSubmitFcNewsMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      url: // value for 'url'
 *      details: // value for 'details'
 *   },
 * });
 */
export function useSubmitFcNewsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitFcNewsMutation, SubmitFcNewsMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitFcNewsMutation, SubmitFcNewsMutationVariables>(SubmitFcNewsDocument, baseOptions);
      }
export type SubmitFcNewsMutationHookResult = ReturnType<typeof useSubmitFcNewsMutation>;
export type SubmitFcNewsMutationResult = ApolloReactCommon.MutationResult<SubmitFcNewsMutation>;
export type SubmitFcNewsMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitFcNewsMutation, SubmitFcNewsMutationVariables>;
export const GalleryTagSectionsDocument = gql`
    query GalleryTagSections {
  galleryTagSections
}
    `;
export type GalleryTagSectionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GalleryTagSectionsQuery, GalleryTagSectionsQueryVariables>, 'query'>;

    export const GalleryTagSectionsComponent = (props: GalleryTagSectionsComponentProps) => (
      <ApolloReactComponents.Query<GalleryTagSectionsQuery, GalleryTagSectionsQueryVariables> query={GalleryTagSectionsDocument} {...props} />
    );
    
export type GalleryTagSectionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GalleryTagSectionsQuery, GalleryTagSectionsQueryVariables>
    } & TChildProps;
export function withGalleryTagSections<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GalleryTagSectionsQuery,
  GalleryTagSectionsQueryVariables,
  GalleryTagSectionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GalleryTagSectionsQuery, GalleryTagSectionsQueryVariables, GalleryTagSectionsProps<TChildProps, TDataName>>(GalleryTagSectionsDocument, {
      alias: 'galleryTagSections',
      ...operationOptions
    });
};

/**
 * __useGalleryTagSectionsQuery__
 *
 * To run a query within a React component, call `useGalleryTagSectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGalleryTagSectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGalleryTagSectionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGalleryTagSectionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GalleryTagSectionsQuery, GalleryTagSectionsQueryVariables>) {
        return ApolloReactHooks.useQuery<GalleryTagSectionsQuery, GalleryTagSectionsQueryVariables>(GalleryTagSectionsDocument, baseOptions);
      }
export function useGalleryTagSectionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GalleryTagSectionsQuery, GalleryTagSectionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GalleryTagSectionsQuery, GalleryTagSectionsQueryVariables>(GalleryTagSectionsDocument, baseOptions);
        }
export type GalleryTagSectionsQueryHookResult = ReturnType<typeof useGalleryTagSectionsQuery>;
export type GalleryTagSectionsLazyQueryHookResult = ReturnType<typeof useGalleryTagSectionsLazyQuery>;
export type GalleryTagSectionsQueryResult = ApolloReactCommon.QueryResult<GalleryTagSectionsQuery, GalleryTagSectionsQueryVariables>;
export const ProjectAccessDocument = gql`
    query ProjectAccess($name: String, $entityName: String) {
  project(name: $name, entityName: $entityName) {
    id
    access
  }
}
    `;
export type ProjectAccessComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectAccessQuery, ProjectAccessQueryVariables>, 'query'>;

    export const ProjectAccessComponent = (props: ProjectAccessComponentProps) => (
      <ApolloReactComponents.Query<ProjectAccessQuery, ProjectAccessQueryVariables> query={ProjectAccessDocument} {...props} />
    );
    
export type ProjectAccessProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProjectAccessQuery, ProjectAccessQueryVariables>
    } & TChildProps;
export function withProjectAccess<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProjectAccessQuery,
  ProjectAccessQueryVariables,
  ProjectAccessProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProjectAccessQuery, ProjectAccessQueryVariables, ProjectAccessProps<TChildProps, TDataName>>(ProjectAccessDocument, {
      alias: 'projectAccess',
      ...operationOptions
    });
};

/**
 * __useProjectAccessQuery__
 *
 * To run a query within a React component, call `useProjectAccessQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectAccessQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectAccessQuery({
 *   variables: {
 *      name: // value for 'name'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useProjectAccessQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectAccessQuery, ProjectAccessQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectAccessQuery, ProjectAccessQueryVariables>(ProjectAccessDocument, baseOptions);
      }
export function useProjectAccessLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectAccessQuery, ProjectAccessQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectAccessQuery, ProjectAccessQueryVariables>(ProjectAccessDocument, baseOptions);
        }
export type ProjectAccessQueryHookResult = ReturnType<typeof useProjectAccessQuery>;
export type ProjectAccessLazyQueryHookResult = ReturnType<typeof useProjectAccessLazyQuery>;
export type ProjectAccessQueryResult = ApolloReactCommon.QueryResult<ProjectAccessQuery, ProjectAccessQueryVariables>;
export const NewsletterSubscriptionDocument = gql`
    query NewsletterSubscription($userName: String!) {
  user(userName: $userName) {
    id
    newsletterSubscription {
      id
    }
  }
}
    `;
export type NewsletterSubscriptionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NewsletterSubscriptionQuery, NewsletterSubscriptionQueryVariables>, 'query'> & ({ variables: NewsletterSubscriptionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const NewsletterSubscriptionComponent = (props: NewsletterSubscriptionComponentProps) => (
      <ApolloReactComponents.Query<NewsletterSubscriptionQuery, NewsletterSubscriptionQueryVariables> query={NewsletterSubscriptionDocument} {...props} />
    );
    
export type NewsletterSubscriptionProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<NewsletterSubscriptionQuery, NewsletterSubscriptionQueryVariables>
    } & TChildProps;
export function withNewsletterSubscription<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  NewsletterSubscriptionQuery,
  NewsletterSubscriptionQueryVariables,
  NewsletterSubscriptionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, NewsletterSubscriptionQuery, NewsletterSubscriptionQueryVariables, NewsletterSubscriptionProps<TChildProps, TDataName>>(NewsletterSubscriptionDocument, {
      alias: 'newsletterSubscription',
      ...operationOptions
    });
};

/**
 * __useNewsletterSubscriptionQuery__
 *
 * To run a query within a React component, call `useNewsletterSubscriptionQuery` and pass it any options that fit your needs.
 * When your component renders, `useNewsletterSubscriptionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNewsletterSubscriptionQuery({
 *   variables: {
 *      userName: // value for 'userName'
 *   },
 * });
 */
export function useNewsletterSubscriptionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NewsletterSubscriptionQuery, NewsletterSubscriptionQueryVariables>) {
        return ApolloReactHooks.useQuery<NewsletterSubscriptionQuery, NewsletterSubscriptionQueryVariables>(NewsletterSubscriptionDocument, baseOptions);
      }
export function useNewsletterSubscriptionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NewsletterSubscriptionQuery, NewsletterSubscriptionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NewsletterSubscriptionQuery, NewsletterSubscriptionQueryVariables>(NewsletterSubscriptionDocument, baseOptions);
        }
export type NewsletterSubscriptionQueryHookResult = ReturnType<typeof useNewsletterSubscriptionQuery>;
export type NewsletterSubscriptionLazyQueryHookResult = ReturnType<typeof useNewsletterSubscriptionLazyQuery>;
export type NewsletterSubscriptionQueryResult = ApolloReactCommon.QueryResult<NewsletterSubscriptionQuery, NewsletterSubscriptionQueryVariables>;
export const CreateNewsletterSubscriptionDocument = gql`
    mutation CreateNewsletterSubscription($email: String) {
  createNewsletterSubscription(input: {email: $email}) {
    newsletterSubscription {
      id
    }
  }
}
    `;
export type CreateNewsletterSubscriptionMutationFn = ApolloReactCommon.MutationFunction<CreateNewsletterSubscriptionMutation, CreateNewsletterSubscriptionMutationVariables>;
export type CreateNewsletterSubscriptionComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateNewsletterSubscriptionMutation, CreateNewsletterSubscriptionMutationVariables>, 'mutation'>;

    export const CreateNewsletterSubscriptionComponent = (props: CreateNewsletterSubscriptionComponentProps) => (
      <ApolloReactComponents.Mutation<CreateNewsletterSubscriptionMutation, CreateNewsletterSubscriptionMutationVariables> mutation={CreateNewsletterSubscriptionDocument} {...props} />
    );
    
export type CreateNewsletterSubscriptionProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateNewsletterSubscriptionMutation, CreateNewsletterSubscriptionMutationVariables>
    } & TChildProps;
export function withCreateNewsletterSubscription<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateNewsletterSubscriptionMutation,
  CreateNewsletterSubscriptionMutationVariables,
  CreateNewsletterSubscriptionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateNewsletterSubscriptionMutation, CreateNewsletterSubscriptionMutationVariables, CreateNewsletterSubscriptionProps<TChildProps, TDataName>>(CreateNewsletterSubscriptionDocument, {
      alias: 'createNewsletterSubscription',
      ...operationOptions
    });
};

/**
 * __useCreateNewsletterSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateNewsletterSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNewsletterSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNewsletterSubscriptionMutation, { data, loading, error }] = useCreateNewsletterSubscriptionMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useCreateNewsletterSubscriptionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateNewsletterSubscriptionMutation, CreateNewsletterSubscriptionMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateNewsletterSubscriptionMutation, CreateNewsletterSubscriptionMutationVariables>(CreateNewsletterSubscriptionDocument, baseOptions);
      }
export type CreateNewsletterSubscriptionMutationHookResult = ReturnType<typeof useCreateNewsletterSubscriptionMutation>;
export type CreateNewsletterSubscriptionMutationResult = ApolloReactCommon.MutationResult<CreateNewsletterSubscriptionMutation>;
export type CreateNewsletterSubscriptionMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateNewsletterSubscriptionMutation, CreateNewsletterSubscriptionMutationVariables>;
export const AllNewsletterSubscriptionsDocument = gql`
    query AllNewsletterSubscriptions($first: Int = 10000) {
  newsletterSubscriptions(first: $first) {
    edges {
      node {
        id
        user {
          id
          username
          email
        }
        email
        createdAt
      }
    }
  }
}
    `;
export type AllNewsletterSubscriptionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllNewsletterSubscriptionsQuery, AllNewsletterSubscriptionsQueryVariables>, 'query'>;

    export const AllNewsletterSubscriptionsComponent = (props: AllNewsletterSubscriptionsComponentProps) => (
      <ApolloReactComponents.Query<AllNewsletterSubscriptionsQuery, AllNewsletterSubscriptionsQueryVariables> query={AllNewsletterSubscriptionsDocument} {...props} />
    );
    
export type AllNewsletterSubscriptionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AllNewsletterSubscriptionsQuery, AllNewsletterSubscriptionsQueryVariables>
    } & TChildProps;
export function withAllNewsletterSubscriptions<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AllNewsletterSubscriptionsQuery,
  AllNewsletterSubscriptionsQueryVariables,
  AllNewsletterSubscriptionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AllNewsletterSubscriptionsQuery, AllNewsletterSubscriptionsQueryVariables, AllNewsletterSubscriptionsProps<TChildProps, TDataName>>(AllNewsletterSubscriptionsDocument, {
      alias: 'allNewsletterSubscriptions',
      ...operationOptions
    });
};

/**
 * __useAllNewsletterSubscriptionsQuery__
 *
 * To run a query within a React component, call `useAllNewsletterSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllNewsletterSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllNewsletterSubscriptionsQuery({
 *   variables: {
 *      first: // value for 'first'
 *   },
 * });
 */
export function useAllNewsletterSubscriptionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllNewsletterSubscriptionsQuery, AllNewsletterSubscriptionsQueryVariables>) {
        return ApolloReactHooks.useQuery<AllNewsletterSubscriptionsQuery, AllNewsletterSubscriptionsQueryVariables>(AllNewsletterSubscriptionsDocument, baseOptions);
      }
export function useAllNewsletterSubscriptionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllNewsletterSubscriptionsQuery, AllNewsletterSubscriptionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllNewsletterSubscriptionsQuery, AllNewsletterSubscriptionsQueryVariables>(AllNewsletterSubscriptionsDocument, baseOptions);
        }
export type AllNewsletterSubscriptionsQueryHookResult = ReturnType<typeof useAllNewsletterSubscriptionsQuery>;
export type AllNewsletterSubscriptionsLazyQueryHookResult = ReturnType<typeof useAllNewsletterSubscriptionsLazyQuery>;
export type AllNewsletterSubscriptionsQueryResult = ApolloReactCommon.QueryResult<AllNewsletterSubscriptionsQuery, AllNewsletterSubscriptionsQueryVariables>;
export const GroupPageDocument = gql`
    query GroupPage($projectName: String!, $entityName: String, $groupName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    runGroup(name: $groupName) {
      id
      name
      notes
      tags {
        id
        name
        colorIndex
      }
    }
  }
}
    `;
export type GroupPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GroupPageQuery, GroupPageQueryVariables>, 'query'> & ({ variables: GroupPageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GroupPageComponent = (props: GroupPageComponentProps) => (
      <ApolloReactComponents.Query<GroupPageQuery, GroupPageQueryVariables> query={GroupPageDocument} {...props} />
    );
    
export type GroupPageProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<GroupPageQuery, GroupPageQueryVariables>
    } & TChildProps;
export function withGroupPage<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GroupPageQuery,
  GroupPageQueryVariables,
  GroupPageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, GroupPageQuery, GroupPageQueryVariables, GroupPageProps<TChildProps, TDataName>>(GroupPageDocument, {
      alias: 'groupPage',
      ...operationOptions
    });
};

/**
 * __useGroupPageQuery__
 *
 * To run a query within a React component, call `useGroupPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useGroupPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGroupPageQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      groupName: // value for 'groupName'
 *   },
 * });
 */
export function useGroupPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GroupPageQuery, GroupPageQueryVariables>) {
        return ApolloReactHooks.useQuery<GroupPageQuery, GroupPageQueryVariables>(GroupPageDocument, baseOptions);
      }
export function useGroupPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GroupPageQuery, GroupPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GroupPageQuery, GroupPageQueryVariables>(GroupPageDocument, baseOptions);
        }
export type GroupPageQueryHookResult = ReturnType<typeof useGroupPageQuery>;
export type GroupPageLazyQueryHookResult = ReturnType<typeof useGroupPageLazyQuery>;
export type GroupPageQueryResult = ApolloReactCommon.QueryResult<GroupPageQuery, GroupPageQueryVariables>;
export const HistoryKeysDocument = gql`
    query HistoryKeys($projectName: String!, $entityName: String!, $filters: JSONString, $limit: Int = 100, $order: String) {
  project(name: $projectName, entityName: $entityName) {
    id
    runs(filters: $filters, first: $limit, order: $order) {
      historyKeys(format: PLAINTEXT)
      edges {
        node {
          id
          wandbConfig(keys: ["viz", "visualize"])
        }
      }
    }
  }
}
    `;
export type HistoryKeysComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<HistoryKeysQuery, HistoryKeysQueryVariables>, 'query'> & ({ variables: HistoryKeysQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const HistoryKeysComponent = (props: HistoryKeysComponentProps) => (
      <ApolloReactComponents.Query<HistoryKeysQuery, HistoryKeysQueryVariables> query={HistoryKeysDocument} {...props} />
    );
    
export type HistoryKeysProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<HistoryKeysQuery, HistoryKeysQueryVariables>
    } & TChildProps;
export function withHistoryKeys<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  HistoryKeysQuery,
  HistoryKeysQueryVariables,
  HistoryKeysProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, HistoryKeysQuery, HistoryKeysQueryVariables, HistoryKeysProps<TChildProps, TDataName>>(HistoryKeysDocument, {
      alias: 'historyKeys',
      ...operationOptions
    });
};

/**
 * __useHistoryKeysQuery__
 *
 * To run a query within a React component, call `useHistoryKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoryKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoryKeysQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useHistoryKeysQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HistoryKeysQuery, HistoryKeysQueryVariables>) {
        return ApolloReactHooks.useQuery<HistoryKeysQuery, HistoryKeysQueryVariables>(HistoryKeysDocument, baseOptions);
      }
export function useHistoryKeysLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HistoryKeysQuery, HistoryKeysQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HistoryKeysQuery, HistoryKeysQueryVariables>(HistoryKeysDocument, baseOptions);
        }
export type HistoryKeysQueryHookResult = ReturnType<typeof useHistoryKeysQuery>;
export type HistoryKeysLazyQueryHookResult = ReturnType<typeof useHistoryKeysLazyQuery>;
export type HistoryKeysQueryResult = ApolloReactCommon.QueryResult<HistoryKeysQuery, HistoryKeysQueryVariables>;
export const HistoryKeysByProjectIdDocument = gql`
    query HistoryKeysByProjectId($internalId: ID!, $filters: JSONString, $limit: Int = 100, $order: String) {
  project(internalId: $internalId) {
    id
    runs(filters: $filters, first: $limit, order: $order) {
      historyKeys(format: BITMAP)
      edges {
        node {
          id
          wandbConfig(keys: ["viz", "visualize"])
        }
      }
    }
  }
}
    `;
export type HistoryKeysByProjectIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<HistoryKeysByProjectIdQuery, HistoryKeysByProjectIdQueryVariables>, 'query'> & ({ variables: HistoryKeysByProjectIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const HistoryKeysByProjectIdComponent = (props: HistoryKeysByProjectIdComponentProps) => (
      <ApolloReactComponents.Query<HistoryKeysByProjectIdQuery, HistoryKeysByProjectIdQueryVariables> query={HistoryKeysByProjectIdDocument} {...props} />
    );
    
export type HistoryKeysByProjectIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<HistoryKeysByProjectIdQuery, HistoryKeysByProjectIdQueryVariables>
    } & TChildProps;
export function withHistoryKeysByProjectId<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  HistoryKeysByProjectIdQuery,
  HistoryKeysByProjectIdQueryVariables,
  HistoryKeysByProjectIdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, HistoryKeysByProjectIdQuery, HistoryKeysByProjectIdQueryVariables, HistoryKeysByProjectIdProps<TChildProps, TDataName>>(HistoryKeysByProjectIdDocument, {
      alias: 'historyKeysByProjectId',
      ...operationOptions
    });
};

/**
 * __useHistoryKeysByProjectIdQuery__
 *
 * To run a query within a React component, call `useHistoryKeysByProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useHistoryKeysByProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useHistoryKeysByProjectIdQuery({
 *   variables: {
 *      internalId: // value for 'internalId'
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useHistoryKeysByProjectIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<HistoryKeysByProjectIdQuery, HistoryKeysByProjectIdQueryVariables>) {
        return ApolloReactHooks.useQuery<HistoryKeysByProjectIdQuery, HistoryKeysByProjectIdQueryVariables>(HistoryKeysByProjectIdDocument, baseOptions);
      }
export function useHistoryKeysByProjectIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<HistoryKeysByProjectIdQuery, HistoryKeysByProjectIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<HistoryKeysByProjectIdQuery, HistoryKeysByProjectIdQueryVariables>(HistoryKeysByProjectIdDocument, baseOptions);
        }
export type HistoryKeysByProjectIdQueryHookResult = ReturnType<typeof useHistoryKeysByProjectIdQuery>;
export type HistoryKeysByProjectIdLazyQueryHookResult = ReturnType<typeof useHistoryKeysByProjectIdLazyQuery>;
export type HistoryKeysByProjectIdQueryResult = ApolloReactCommon.QueryResult<HistoryKeysByProjectIdQuery, HistoryKeysByProjectIdQueryVariables>;
export const InviteDocument = gql`
    query Invite($key: String!) {
  invite(key: $key) {
    id
    toUser {
      id
      username
    }
  }
}
    `;
export type InviteComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<InviteQuery, InviteQueryVariables>, 'query'> & ({ variables: InviteQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const InviteComponent = (props: InviteComponentProps) => (
      <ApolloReactComponents.Query<InviteQuery, InviteQueryVariables> query={InviteDocument} {...props} />
    );
    
export type InviteProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<InviteQuery, InviteQueryVariables>
    } & TChildProps;
export function withInvite<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InviteQuery,
  InviteQueryVariables,
  InviteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, InviteQuery, InviteQueryVariables, InviteProps<TChildProps, TDataName>>(InviteDocument, {
      alias: 'invite',
      ...operationOptions
    });
};

/**
 * __useInviteQuery__
 *
 * To run a query within a React component, call `useInviteQuery` and pass it any options that fit your needs.
 * When your component renders, `useInviteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInviteQuery({
 *   variables: {
 *      key: // value for 'key'
 *   },
 * });
 */
export function useInviteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<InviteQuery, InviteQueryVariables>) {
        return ApolloReactHooks.useQuery<InviteQuery, InviteQueryVariables>(InviteDocument, baseOptions);
      }
export function useInviteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<InviteQuery, InviteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<InviteQuery, InviteQueryVariables>(InviteDocument, baseOptions);
        }
export type InviteQueryHookResult = ReturnType<typeof useInviteQuery>;
export type InviteLazyQueryHookResult = ReturnType<typeof useInviteLazyQuery>;
export type InviteQueryResult = ApolloReactCommon.QueryResult<InviteQuery, InviteQueryVariables>;
export const FetchEntityJobsDocument = gql`
    query FetchEntityJobs($entityName: String!) {
  entity(name: $entityName) {
    id
    name
    projects(first: 100) {
      edges {
        node {
          id
          name
          artifactType(name: "job") {
            id
            artifactCollections {
              edges {
                node {
                  id
                  name
                  description
                  artifacts {
                    edges {
                      node {
                        id
                        description
                        labels
                        versionIndex
                        aliases {
                          id
                          alias
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type FetchEntityJobsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FetchEntityJobsQuery, FetchEntityJobsQueryVariables>, 'query'> & ({ variables: FetchEntityJobsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FetchEntityJobsComponent = (props: FetchEntityJobsComponentProps) => (
      <ApolloReactComponents.Query<FetchEntityJobsQuery, FetchEntityJobsQueryVariables> query={FetchEntityJobsDocument} {...props} />
    );
    
export type FetchEntityJobsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FetchEntityJobsQuery, FetchEntityJobsQueryVariables>
    } & TChildProps;
export function withFetchEntityJobs<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FetchEntityJobsQuery,
  FetchEntityJobsQueryVariables,
  FetchEntityJobsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FetchEntityJobsQuery, FetchEntityJobsQueryVariables, FetchEntityJobsProps<TChildProps, TDataName>>(FetchEntityJobsDocument, {
      alias: 'fetchEntityJobs',
      ...operationOptions
    });
};

/**
 * __useFetchEntityJobsQuery__
 *
 * To run a query within a React component, call `useFetchEntityJobsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchEntityJobsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchEntityJobsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useFetchEntityJobsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchEntityJobsQuery, FetchEntityJobsQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchEntityJobsQuery, FetchEntityJobsQueryVariables>(FetchEntityJobsDocument, baseOptions);
      }
export function useFetchEntityJobsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchEntityJobsQuery, FetchEntityJobsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchEntityJobsQuery, FetchEntityJobsQueryVariables>(FetchEntityJobsDocument, baseOptions);
        }
export type FetchEntityJobsQueryHookResult = ReturnType<typeof useFetchEntityJobsQuery>;
export type FetchEntityJobsLazyQueryHookResult = ReturnType<typeof useFetchEntityJobsLazyQuery>;
export type FetchEntityJobsQueryResult = ApolloReactCommon.QueryResult<FetchEntityJobsQuery, FetchEntityJobsQueryVariables>;
export const FetchProjectJobsPartialDocument = gql`
    query FetchProjectJobsPartial($entityName: String!, $projectName: String!, $limit: Int!, $after: String) {
  project(entityName: $entityName, name: $projectName) {
    id
    name
    artifactType(name: "job") {
      id
      artifactCollections(first: $limit, after: $after) {
        pageInfo {
          hasNextPage
          endCursor
        }
        edges {
          node {
            id
            name
            description
            createdAt
            artifacts {
              edges {
                node {
                  id
                  description
                  labels
                  versionIndex
                  aliases {
                    id
                    alias
                  }
                  usedBy(first: 1, order: "-createdAt") {
                    totalCount
                    edges {
                      node {
                        id
                        name
                        displayName
                        state
                        createdAt
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type FetchProjectJobsPartialComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FetchProjectJobsPartialQuery, FetchProjectJobsPartialQueryVariables>, 'query'> & ({ variables: FetchProjectJobsPartialQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FetchProjectJobsPartialComponent = (props: FetchProjectJobsPartialComponentProps) => (
      <ApolloReactComponents.Query<FetchProjectJobsPartialQuery, FetchProjectJobsPartialQueryVariables> query={FetchProjectJobsPartialDocument} {...props} />
    );
    
export type FetchProjectJobsPartialProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FetchProjectJobsPartialQuery, FetchProjectJobsPartialQueryVariables>
    } & TChildProps;
export function withFetchProjectJobsPartial<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FetchProjectJobsPartialQuery,
  FetchProjectJobsPartialQueryVariables,
  FetchProjectJobsPartialProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FetchProjectJobsPartialQuery, FetchProjectJobsPartialQueryVariables, FetchProjectJobsPartialProps<TChildProps, TDataName>>(FetchProjectJobsPartialDocument, {
      alias: 'fetchProjectJobsPartial',
      ...operationOptions
    });
};

/**
 * __useFetchProjectJobsPartialQuery__
 *
 * To run a query within a React component, call `useFetchProjectJobsPartialQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProjectJobsPartialQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProjectJobsPartialQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      limit: // value for 'limit'
 *      after: // value for 'after'
 *   },
 * });
 */
export function useFetchProjectJobsPartialQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchProjectJobsPartialQuery, FetchProjectJobsPartialQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchProjectJobsPartialQuery, FetchProjectJobsPartialQueryVariables>(FetchProjectJobsPartialDocument, baseOptions);
      }
export function useFetchProjectJobsPartialLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchProjectJobsPartialQuery, FetchProjectJobsPartialQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchProjectJobsPartialQuery, FetchProjectJobsPartialQueryVariables>(FetchProjectJobsPartialDocument, baseOptions);
        }
export type FetchProjectJobsPartialQueryHookResult = ReturnType<typeof useFetchProjectJobsPartialQuery>;
export type FetchProjectJobsPartialLazyQueryHookResult = ReturnType<typeof useFetchProjectJobsPartialLazyQuery>;
export type FetchProjectJobsPartialQueryResult = ApolloReactCommon.QueryResult<FetchProjectJobsPartialQuery, FetchProjectJobsPartialQueryVariables>;
export const FetchJobVersionsDocument = gql`
    query FetchJobVersions($entityName: String!, $projectName: String!, $jobName: String!) {
  project(entityName: $entityName, name: $projectName) {
    id
    artifactCollection(name: $jobName) {
      id
      description
      artifacts {
        edges {
          node {
            id
            createdAt
            createdBy {
              __typename
              ... on User {
                id
                name
                username
                photoUrl
              }
              ... on Run {
                id
                name
                displayName
                defaultColorIndex
              }
            }
            aliases {
              id
              alias
            }
          }
        }
      }
    }
  }
}
    `;
export type FetchJobVersionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FetchJobVersionsQuery, FetchJobVersionsQueryVariables>, 'query'> & ({ variables: FetchJobVersionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FetchJobVersionsComponent = (props: FetchJobVersionsComponentProps) => (
      <ApolloReactComponents.Query<FetchJobVersionsQuery, FetchJobVersionsQueryVariables> query={FetchJobVersionsDocument} {...props} />
    );
    
export type FetchJobVersionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FetchJobVersionsQuery, FetchJobVersionsQueryVariables>
    } & TChildProps;
export function withFetchJobVersions<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FetchJobVersionsQuery,
  FetchJobVersionsQueryVariables,
  FetchJobVersionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FetchJobVersionsQuery, FetchJobVersionsQueryVariables, FetchJobVersionsProps<TChildProps, TDataName>>(FetchJobVersionsDocument, {
      alias: 'fetchJobVersions',
      ...operationOptions
    });
};

/**
 * __useFetchJobVersionsQuery__
 *
 * To run a query within a React component, call `useFetchJobVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchJobVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchJobVersionsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      jobName: // value for 'jobName'
 *   },
 * });
 */
export function useFetchJobVersionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchJobVersionsQuery, FetchJobVersionsQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchJobVersionsQuery, FetchJobVersionsQueryVariables>(FetchJobVersionsDocument, baseOptions);
      }
export function useFetchJobVersionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchJobVersionsQuery, FetchJobVersionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchJobVersionsQuery, FetchJobVersionsQueryVariables>(FetchJobVersionsDocument, baseOptions);
        }
export type FetchJobVersionsQueryHookResult = ReturnType<typeof useFetchJobVersionsQuery>;
export type FetchJobVersionsLazyQueryHookResult = ReturnType<typeof useFetchJobVersionsLazyQuery>;
export type FetchJobVersionsQueryResult = ApolloReactCommon.QueryResult<FetchJobVersionsQuery, FetchJobVersionsQueryVariables>;
export const FetchJobDocument = gql`
    query FetchJob($jobId: ID!) {
  artifactCollection(id: $jobId) {
    id
    name
  }
}
    `;
export type FetchJobComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FetchJobQuery, FetchJobQueryVariables>, 'query'> & ({ variables: FetchJobQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FetchJobComponent = (props: FetchJobComponentProps) => (
      <ApolloReactComponents.Query<FetchJobQuery, FetchJobQueryVariables> query={FetchJobDocument} {...props} />
    );
    
export type FetchJobProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FetchJobQuery, FetchJobQueryVariables>
    } & TChildProps;
export function withFetchJob<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FetchJobQuery,
  FetchJobQueryVariables,
  FetchJobProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FetchJobQuery, FetchJobQueryVariables, FetchJobProps<TChildProps, TDataName>>(FetchJobDocument, {
      alias: 'fetchJob',
      ...operationOptions
    });
};

/**
 * __useFetchJobQuery__
 *
 * To run a query within a React component, call `useFetchJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchJobQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useFetchJobQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchJobQuery, FetchJobQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchJobQuery, FetchJobQueryVariables>(FetchJobDocument, baseOptions);
      }
export function useFetchJobLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchJobQuery, FetchJobQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchJobQuery, FetchJobQueryVariables>(FetchJobDocument, baseOptions);
        }
export type FetchJobQueryHookResult = ReturnType<typeof useFetchJobQuery>;
export type FetchJobLazyQueryHookResult = ReturnType<typeof useFetchJobLazyQuery>;
export type FetchJobQueryResult = ApolloReactCommon.QueryResult<FetchJobQuery, FetchJobQueryVariables>;
export const FetchJobDetailsDocument = gql`
    query FetchJobDetails($jobId: ID!) {
  artifactCollection(id: $jobId) {
    id
    name
    description
    createdAt
    project {
      id
      access
      name
      entity {
        id
        name
      }
    }
    artifacts {
      edges {
        node {
          id
          description
          labels
          versionIndex
          aliases {
            id
            alias
          }
          createdAt
          createdBy {
            __typename
            ... on User {
              id
              name
              username
              photoUrl
            }
            ... on Run {
              id
              name
              displayName
              defaultColorIndex
            }
          }
          usedBy {
            totalCount
            edges {
              node {
                id
                name
                displayName
                state
                stopped
                createdAt
                user {
                  id
                  name
                  username
                  photoUrl
                }
                config
                project {
                  id
                  name
                  entity {
                    id
                    name
                  }
                }
                defaultColorIndex
                runInfo {
                  args
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type FetchJobDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FetchJobDetailsQuery, FetchJobDetailsQueryVariables>, 'query'> & ({ variables: FetchJobDetailsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FetchJobDetailsComponent = (props: FetchJobDetailsComponentProps) => (
      <ApolloReactComponents.Query<FetchJobDetailsQuery, FetchJobDetailsQueryVariables> query={FetchJobDetailsDocument} {...props} />
    );
    
export type FetchJobDetailsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FetchJobDetailsQuery, FetchJobDetailsQueryVariables>
    } & TChildProps;
export function withFetchJobDetails<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FetchJobDetailsQuery,
  FetchJobDetailsQueryVariables,
  FetchJobDetailsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FetchJobDetailsQuery, FetchJobDetailsQueryVariables, FetchJobDetailsProps<TChildProps, TDataName>>(FetchJobDetailsDocument, {
      alias: 'fetchJobDetails',
      ...operationOptions
    });
};

/**
 * __useFetchJobDetailsQuery__
 *
 * To run a query within a React component, call `useFetchJobDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchJobDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchJobDetailsQuery({
 *   variables: {
 *      jobId: // value for 'jobId'
 *   },
 * });
 */
export function useFetchJobDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchJobDetailsQuery, FetchJobDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchJobDetailsQuery, FetchJobDetailsQueryVariables>(FetchJobDetailsDocument, baseOptions);
      }
export function useFetchJobDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchJobDetailsQuery, FetchJobDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchJobDetailsQuery, FetchJobDetailsQueryVariables>(FetchJobDetailsDocument, baseOptions);
        }
export type FetchJobDetailsQueryHookResult = ReturnType<typeof useFetchJobDetailsQuery>;
export type FetchJobDetailsLazyQueryHookResult = ReturnType<typeof useFetchJobDetailsLazyQuery>;
export type FetchJobDetailsQueryResult = ApolloReactCommon.QueryResult<FetchJobDetailsQuery, FetchJobDetailsQueryVariables>;
export const FetchProjectRunsDocument = gql`
    query FetchProjectRuns($entityName: String!, $projectName: String!) {
  project(entityName: $entityName, name: $projectName) {
    id
    runs {
      edges {
        node {
          id
          name
          displayName
          runInfo {
            git {
              remote
              commit
            }
          }
        }
      }
    }
  }
}
    `;
export type FetchProjectRunsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FetchProjectRunsQuery, FetchProjectRunsQueryVariables>, 'query'> & ({ variables: FetchProjectRunsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FetchProjectRunsComponent = (props: FetchProjectRunsComponentProps) => (
      <ApolloReactComponents.Query<FetchProjectRunsQuery, FetchProjectRunsQueryVariables> query={FetchProjectRunsDocument} {...props} />
    );
    
export type FetchProjectRunsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FetchProjectRunsQuery, FetchProjectRunsQueryVariables>
    } & TChildProps;
export function withFetchProjectRuns<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FetchProjectRunsQuery,
  FetchProjectRunsQueryVariables,
  FetchProjectRunsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FetchProjectRunsQuery, FetchProjectRunsQueryVariables, FetchProjectRunsProps<TChildProps, TDataName>>(FetchProjectRunsDocument, {
      alias: 'fetchProjectRuns',
      ...operationOptions
    });
};

/**
 * __useFetchProjectRunsQuery__
 *
 * To run a query within a React component, call `useFetchProjectRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProjectRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProjectRunsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *   },
 * });
 */
export function useFetchProjectRunsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchProjectRunsQuery, FetchProjectRunsQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchProjectRunsQuery, FetchProjectRunsQueryVariables>(FetchProjectRunsDocument, baseOptions);
      }
export function useFetchProjectRunsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchProjectRunsQuery, FetchProjectRunsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchProjectRunsQuery, FetchProjectRunsQueryVariables>(FetchProjectRunsDocument, baseOptions);
        }
export type FetchProjectRunsQueryHookResult = ReturnType<typeof useFetchProjectRunsQuery>;
export type FetchProjectRunsLazyQueryHookResult = ReturnType<typeof useFetchProjectRunsLazyQuery>;
export type FetchProjectRunsQueryResult = ApolloReactCommon.QueryResult<FetchProjectRunsQuery, FetchProjectRunsQueryVariables>;
export const FetchProjectLaunchQueuesDocument = gql`
    query FetchProjectLaunchQueues($entityName: String!, $projectName: String!) {
  project(entityName: $entityName, name: $projectName) {
    id
    name
    entity {
      id
      isTeam
      name
    }
    runQueues {
      id
      name
      access
      defaultResourceConfigID
      prioritizationMode
    }
  }
}
    `;
export type FetchProjectLaunchQueuesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FetchProjectLaunchQueuesQuery, FetchProjectLaunchQueuesQueryVariables>, 'query'> & ({ variables: FetchProjectLaunchQueuesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FetchProjectLaunchQueuesComponent = (props: FetchProjectLaunchQueuesComponentProps) => (
      <ApolloReactComponents.Query<FetchProjectLaunchQueuesQuery, FetchProjectLaunchQueuesQueryVariables> query={FetchProjectLaunchQueuesDocument} {...props} />
    );
    
export type FetchProjectLaunchQueuesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FetchProjectLaunchQueuesQuery, FetchProjectLaunchQueuesQueryVariables>
    } & TChildProps;
export function withFetchProjectLaunchQueues<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FetchProjectLaunchQueuesQuery,
  FetchProjectLaunchQueuesQueryVariables,
  FetchProjectLaunchQueuesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FetchProjectLaunchQueuesQuery, FetchProjectLaunchQueuesQueryVariables, FetchProjectLaunchQueuesProps<TChildProps, TDataName>>(FetchProjectLaunchQueuesDocument, {
      alias: 'fetchProjectLaunchQueues',
      ...operationOptions
    });
};

/**
 * __useFetchProjectLaunchQueuesQuery__
 *
 * To run a query within a React component, call `useFetchProjectLaunchQueuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProjectLaunchQueuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProjectLaunchQueuesQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *   },
 * });
 */
export function useFetchProjectLaunchQueuesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchProjectLaunchQueuesQuery, FetchProjectLaunchQueuesQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchProjectLaunchQueuesQuery, FetchProjectLaunchQueuesQueryVariables>(FetchProjectLaunchQueuesDocument, baseOptions);
      }
export function useFetchProjectLaunchQueuesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchProjectLaunchQueuesQuery, FetchProjectLaunchQueuesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchProjectLaunchQueuesQuery, FetchProjectLaunchQueuesQueryVariables>(FetchProjectLaunchQueuesDocument, baseOptions);
        }
export type FetchProjectLaunchQueuesQueryHookResult = ReturnType<typeof useFetchProjectLaunchQueuesQuery>;
export type FetchProjectLaunchQueuesLazyQueryHookResult = ReturnType<typeof useFetchProjectLaunchQueuesLazyQuery>;
export type FetchProjectLaunchQueuesQueryResult = ApolloReactCommon.QueryResult<FetchProjectLaunchQueuesQuery, FetchProjectLaunchQueuesQueryVariables>;
export const FetchEntityProjectsDocument = gql`
    query FetchEntityProjects($entityName: String!) {
  entity(name: $entityName) {
    id
    projects(first: 100, order: "-createdAt") {
      edges {
        node {
          id
          name
        }
      }
    }
  }
}
    `;
export type FetchEntityProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FetchEntityProjectsQuery, FetchEntityProjectsQueryVariables>, 'query'> & ({ variables: FetchEntityProjectsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FetchEntityProjectsComponent = (props: FetchEntityProjectsComponentProps) => (
      <ApolloReactComponents.Query<FetchEntityProjectsQuery, FetchEntityProjectsQueryVariables> query={FetchEntityProjectsDocument} {...props} />
    );
    
export type FetchEntityProjectsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FetchEntityProjectsQuery, FetchEntityProjectsQueryVariables>
    } & TChildProps;
export function withFetchEntityProjects<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FetchEntityProjectsQuery,
  FetchEntityProjectsQueryVariables,
  FetchEntityProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FetchEntityProjectsQuery, FetchEntityProjectsQueryVariables, FetchEntityProjectsProps<TChildProps, TDataName>>(FetchEntityProjectsDocument, {
      alias: 'fetchEntityProjects',
      ...operationOptions
    });
};

/**
 * __useFetchEntityProjectsQuery__
 *
 * To run a query within a React component, call `useFetchEntityProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchEntityProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchEntityProjectsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useFetchEntityProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchEntityProjectsQuery, FetchEntityProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchEntityProjectsQuery, FetchEntityProjectsQueryVariables>(FetchEntityProjectsDocument, baseOptions);
      }
export function useFetchEntityProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchEntityProjectsQuery, FetchEntityProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchEntityProjectsQuery, FetchEntityProjectsQueryVariables>(FetchEntityProjectsDocument, baseOptions);
        }
export type FetchEntityProjectsQueryHookResult = ReturnType<typeof useFetchEntityProjectsQuery>;
export type FetchEntityProjectsLazyQueryHookResult = ReturnType<typeof useFetchEntityProjectsLazyQuery>;
export type FetchEntityProjectsQueryResult = ApolloReactCommon.QueryResult<FetchEntityProjectsQuery, FetchEntityProjectsQueryVariables>;
export const EntityPhotoDocument = gql`
    query EntityPhoto($entityName: String!) {
  entity(name: $entityName) {
    id
    photoUrl
  }
}
    `;
export type EntityPhotoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityPhotoQuery, EntityPhotoQueryVariables>, 'query'> & ({ variables: EntityPhotoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntityPhotoComponent = (props: EntityPhotoComponentProps) => (
      <ApolloReactComponents.Query<EntityPhotoQuery, EntityPhotoQueryVariables> query={EntityPhotoDocument} {...props} />
    );
    
export type EntityPhotoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityPhotoQuery, EntityPhotoQueryVariables>
    } & TChildProps;
export function withEntityPhoto<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityPhotoQuery,
  EntityPhotoQueryVariables,
  EntityPhotoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityPhotoQuery, EntityPhotoQueryVariables, EntityPhotoProps<TChildProps, TDataName>>(EntityPhotoDocument, {
      alias: 'entityPhoto',
      ...operationOptions
    });
};

/**
 * __useEntityPhotoQuery__
 *
 * To run a query within a React component, call `useEntityPhotoQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityPhotoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityPhotoQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useEntityPhotoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityPhotoQuery, EntityPhotoQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityPhotoQuery, EntityPhotoQueryVariables>(EntityPhotoDocument, baseOptions);
      }
export function useEntityPhotoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityPhotoQuery, EntityPhotoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityPhotoQuery, EntityPhotoQueryVariables>(EntityPhotoDocument, baseOptions);
        }
export type EntityPhotoQueryHookResult = ReturnType<typeof useEntityPhotoQuery>;
export type EntityPhotoLazyQueryHookResult = ReturnType<typeof useEntityPhotoLazyQuery>;
export type EntityPhotoQueryResult = ApolloReactCommon.QueryResult<EntityPhotoQuery, EntityPhotoQueryVariables>;
export const UserEntitiesDocument = gql`
    query UserEntities {
  viewer {
    id
    teams {
      edges {
        node {
          id
          name
          photoUrl
          members {
            id
            username
            role
          }
        }
      }
    }
  }
}
    `;
export type UserEntitiesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserEntitiesQuery, UserEntitiesQueryVariables>, 'query'>;

    export const UserEntitiesComponent = (props: UserEntitiesComponentProps) => (
      <ApolloReactComponents.Query<UserEntitiesQuery, UserEntitiesQueryVariables> query={UserEntitiesDocument} {...props} />
    );
    
export type UserEntitiesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UserEntitiesQuery, UserEntitiesQueryVariables>
    } & TChildProps;
export function withUserEntities<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserEntitiesQuery,
  UserEntitiesQueryVariables,
  UserEntitiesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, UserEntitiesQuery, UserEntitiesQueryVariables, UserEntitiesProps<TChildProps, TDataName>>(UserEntitiesDocument, {
      alias: 'userEntities',
      ...operationOptions
    });
};

/**
 * __useUserEntitiesQuery__
 *
 * To run a query within a React component, call `useUserEntitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEntitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEntitiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserEntitiesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserEntitiesQuery, UserEntitiesQueryVariables>) {
        return ApolloReactHooks.useQuery<UserEntitiesQuery, UserEntitiesQueryVariables>(UserEntitiesDocument, baseOptions);
      }
export function useUserEntitiesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserEntitiesQuery, UserEntitiesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserEntitiesQuery, UserEntitiesQueryVariables>(UserEntitiesDocument, baseOptions);
        }
export type UserEntitiesQueryHookResult = ReturnType<typeof useUserEntitiesQuery>;
export type UserEntitiesLazyQueryHookResult = ReturnType<typeof useUserEntitiesLazyQuery>;
export type UserEntitiesQueryResult = ApolloReactCommon.QueryResult<UserEntitiesQuery, UserEntitiesQueryVariables>;
export const ViewerDestinationProjectsDocument = gql`
    query ViewerDestinationProjects {
  viewer {
    id
    teams {
      edges {
        node {
          id
          name
          projects {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type ViewerDestinationProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewerDestinationProjectsQuery, ViewerDestinationProjectsQueryVariables>, 'query'>;

    export const ViewerDestinationProjectsComponent = (props: ViewerDestinationProjectsComponentProps) => (
      <ApolloReactComponents.Query<ViewerDestinationProjectsQuery, ViewerDestinationProjectsQueryVariables> query={ViewerDestinationProjectsDocument} {...props} />
    );
    
export type ViewerDestinationProjectsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewerDestinationProjectsQuery, ViewerDestinationProjectsQueryVariables>
    } & TChildProps;
export function withViewerDestinationProjects<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewerDestinationProjectsQuery,
  ViewerDestinationProjectsQueryVariables,
  ViewerDestinationProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewerDestinationProjectsQuery, ViewerDestinationProjectsQueryVariables, ViewerDestinationProjectsProps<TChildProps, TDataName>>(ViewerDestinationProjectsDocument, {
      alias: 'viewerDestinationProjects',
      ...operationOptions
    });
};

/**
 * __useViewerDestinationProjectsQuery__
 *
 * To run a query within a React component, call `useViewerDestinationProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerDestinationProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerDestinationProjectsQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerDestinationProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewerDestinationProjectsQuery, ViewerDestinationProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewerDestinationProjectsQuery, ViewerDestinationProjectsQueryVariables>(ViewerDestinationProjectsDocument, baseOptions);
      }
export function useViewerDestinationProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewerDestinationProjectsQuery, ViewerDestinationProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewerDestinationProjectsQuery, ViewerDestinationProjectsQueryVariables>(ViewerDestinationProjectsDocument, baseOptions);
        }
export type ViewerDestinationProjectsQueryHookResult = ReturnType<typeof useViewerDestinationProjectsQuery>;
export type ViewerDestinationProjectsLazyQueryHookResult = ReturnType<typeof useViewerDestinationProjectsLazyQuery>;
export type ViewerDestinationProjectsQueryResult = ApolloReactCommon.QueryResult<ViewerDestinationProjectsQuery, ViewerDestinationProjectsQueryVariables>;
export const QueueNamesDocument = gql`
    query QueueNames($entityName: String) {
  project(name: "model-registry", entityName: $entityName) {
    id
    runQueues {
      id
      name
    }
  }
}
    `;
export type QueueNamesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<QueueNamesQuery, QueueNamesQueryVariables>, 'query'>;

    export const QueueNamesComponent = (props: QueueNamesComponentProps) => (
      <ApolloReactComponents.Query<QueueNamesQuery, QueueNamesQueryVariables> query={QueueNamesDocument} {...props} />
    );
    
export type QueueNamesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<QueueNamesQuery, QueueNamesQueryVariables>
    } & TChildProps;
export function withQueueNames<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  QueueNamesQuery,
  QueueNamesQueryVariables,
  QueueNamesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, QueueNamesQuery, QueueNamesQueryVariables, QueueNamesProps<TChildProps, TDataName>>(QueueNamesDocument, {
      alias: 'queueNames',
      ...operationOptions
    });
};

/**
 * __useQueueNamesQuery__
 *
 * To run a query within a React component, call `useQueueNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueueNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueueNamesQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useQueueNamesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QueueNamesQuery, QueueNamesQueryVariables>) {
        return ApolloReactHooks.useQuery<QueueNamesQuery, QueueNamesQueryVariables>(QueueNamesDocument, baseOptions);
      }
export function useQueueNamesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QueueNamesQuery, QueueNamesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QueueNamesQuery, QueueNamesQueryVariables>(QueueNamesDocument, baseOptions);
        }
export type QueueNamesQueryHookResult = ReturnType<typeof useQueueNamesQuery>;
export type QueueNamesLazyQueryHookResult = ReturnType<typeof useQueueNamesLazyQuery>;
export type QueueNamesQueryResult = ApolloReactCommon.QueryResult<QueueNamesQuery, QueueNamesQueryVariables>;
export const AllQueuesDocument = gql`
    query AllQueues {
  viewer {
    id
    entity
    teams {
      edges {
        node {
          id
          name
          organizationId
          launchProject {
            id
            name
            launchAgents {
              id
              name
              createdAt
              heartbeatAt
              agentStatus
              stopPolling
              runQueues
              hostname
            }
            runQueues {
              id
              createdAt
              createdBy
              updatedAt
              name
              access
              prioritizationMode
              executorInfo
              externalLinks
              defaultResourceConfig {
                id
                ...DefaultResourceConfigFragment
              }
              runQueueItems(first: 1000) {
                edges {
                  node {
                    id
                    ...RunQueueItemFragment
                    error {
                      message
                      stage
                      filePaths
                    }
                    warnings {
                      message
                      stage
                      filePaths
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${DefaultResourceConfigFragmentFragmentDoc}
${RunQueueItemFragmentFragmentDoc}`;
export type AllQueuesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllQueuesQuery, AllQueuesQueryVariables>, 'query'>;

    export const AllQueuesComponent = (props: AllQueuesComponentProps) => (
      <ApolloReactComponents.Query<AllQueuesQuery, AllQueuesQueryVariables> query={AllQueuesDocument} {...props} />
    );
    
export type AllQueuesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AllQueuesQuery, AllQueuesQueryVariables>
    } & TChildProps;
export function withAllQueues<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AllQueuesQuery,
  AllQueuesQueryVariables,
  AllQueuesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AllQueuesQuery, AllQueuesQueryVariables, AllQueuesProps<TChildProps, TDataName>>(AllQueuesDocument, {
      alias: 'allQueues',
      ...operationOptions
    });
};

/**
 * __useAllQueuesQuery__
 *
 * To run a query within a React component, call `useAllQueuesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllQueuesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllQueuesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllQueuesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllQueuesQuery, AllQueuesQueryVariables>) {
        return ApolloReactHooks.useQuery<AllQueuesQuery, AllQueuesQueryVariables>(AllQueuesDocument, baseOptions);
      }
export function useAllQueuesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllQueuesQuery, AllQueuesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllQueuesQuery, AllQueuesQueryVariables>(AllQueuesDocument, baseOptions);
        }
export type AllQueuesQueryHookResult = ReturnType<typeof useAllQueuesQuery>;
export type AllQueuesLazyQueryHookResult = ReturnType<typeof useAllQueuesLazyQuery>;
export type AllQueuesQueryResult = ApolloReactCommon.QueryResult<AllQueuesQuery, AllQueuesQueryVariables>;
export const EntityQueuesLiteDocument = gql`
    query EntityQueuesLite($entityName: String) {
  entity(name: $entityName) {
    id
    name
    launchProject {
      id
      name
      launchAgents {
        id
        name
        createdAt
        heartbeatAt
        agentStatus
        stopPolling
        runQueues
        hostname
      }
      runQueues {
        id
        createdAt
        createdBy
        updatedAt
        name
        access
        defaultResourceConfig {
          id
          createdAt
          updatedAt
          scope {
            __typename
            Type
            ID
          }
          resource
          config
          templateVariables {
            name
            description
            schema
          }
        }
        prioritizationMode
        executorInfo
        externalLinks
      }
    }
  }
}
    `;
export type EntityQueuesLiteComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityQueuesLiteQuery, EntityQueuesLiteQueryVariables>, 'query'>;

    export const EntityQueuesLiteComponent = (props: EntityQueuesLiteComponentProps) => (
      <ApolloReactComponents.Query<EntityQueuesLiteQuery, EntityQueuesLiteQueryVariables> query={EntityQueuesLiteDocument} {...props} />
    );
    
export type EntityQueuesLiteProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityQueuesLiteQuery, EntityQueuesLiteQueryVariables>
    } & TChildProps;
export function withEntityQueuesLite<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityQueuesLiteQuery,
  EntityQueuesLiteQueryVariables,
  EntityQueuesLiteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityQueuesLiteQuery, EntityQueuesLiteQueryVariables, EntityQueuesLiteProps<TChildProps, TDataName>>(EntityQueuesLiteDocument, {
      alias: 'entityQueuesLite',
      ...operationOptions
    });
};

/**
 * __useEntityQueuesLiteQuery__
 *
 * To run a query within a React component, call `useEntityQueuesLiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityQueuesLiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityQueuesLiteQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useEntityQueuesLiteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityQueuesLiteQuery, EntityQueuesLiteQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityQueuesLiteQuery, EntityQueuesLiteQueryVariables>(EntityQueuesLiteDocument, baseOptions);
      }
export function useEntityQueuesLiteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityQueuesLiteQuery, EntityQueuesLiteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityQueuesLiteQuery, EntityQueuesLiteQueryVariables>(EntityQueuesLiteDocument, baseOptions);
        }
export type EntityQueuesLiteQueryHookResult = ReturnType<typeof useEntityQueuesLiteQuery>;
export type EntityQueuesLiteLazyQueryHookResult = ReturnType<typeof useEntityQueuesLiteLazyQuery>;
export type EntityQueuesLiteQueryResult = ApolloReactCommon.QueryResult<EntityQueuesLiteQuery, EntityQueuesLiteQueryVariables>;
export const AllQueuesLiteDocument = gql`
    query AllQueuesLite {
  viewer {
    id
    entity
    teams {
      edges {
        node {
          id
          name
          organizationId
          launchProject {
            id
            name
            launchAgents {
              id
              name
              createdAt
              heartbeatAt
              agentStatus
              stopPolling
              runQueues
              hostname
            }
            runQueues {
              id
              createdAt
              createdBy
              updatedAt
              name
              access
              prioritizationMode
              executorInfo
              externalLinks
              defaultResourceConfig {
                id
                createdAt
                updatedAt
                scope {
                  __typename
                  Type
                  ID
                }
                resource
                config
                templateVariables {
                  name
                  description
                  schema
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type AllQueuesLiteComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllQueuesLiteQuery, AllQueuesLiteQueryVariables>, 'query'>;

    export const AllQueuesLiteComponent = (props: AllQueuesLiteComponentProps) => (
      <ApolloReactComponents.Query<AllQueuesLiteQuery, AllQueuesLiteQueryVariables> query={AllQueuesLiteDocument} {...props} />
    );
    
export type AllQueuesLiteProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AllQueuesLiteQuery, AllQueuesLiteQueryVariables>
    } & TChildProps;
export function withAllQueuesLite<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AllQueuesLiteQuery,
  AllQueuesLiteQueryVariables,
  AllQueuesLiteProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AllQueuesLiteQuery, AllQueuesLiteQueryVariables, AllQueuesLiteProps<TChildProps, TDataName>>(AllQueuesLiteDocument, {
      alias: 'allQueuesLite',
      ...operationOptions
    });
};

/**
 * __useAllQueuesLiteQuery__
 *
 * To run a query within a React component, call `useAllQueuesLiteQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllQueuesLiteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllQueuesLiteQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllQueuesLiteQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllQueuesLiteQuery, AllQueuesLiteQueryVariables>) {
        return ApolloReactHooks.useQuery<AllQueuesLiteQuery, AllQueuesLiteQueryVariables>(AllQueuesLiteDocument, baseOptions);
      }
export function useAllQueuesLiteLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllQueuesLiteQuery, AllQueuesLiteQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllQueuesLiteQuery, AllQueuesLiteQueryVariables>(AllQueuesLiteDocument, baseOptions);
        }
export type AllQueuesLiteQueryHookResult = ReturnType<typeof useAllQueuesLiteQuery>;
export type AllQueuesLiteLazyQueryHookResult = ReturnType<typeof useAllQueuesLiteLazyQuery>;
export type AllQueuesLiteQueryResult = ApolloReactCommon.QueryResult<AllQueuesLiteQuery, AllQueuesLiteQueryVariables>;
export const RunsInformationDocument = gql`
    query RunsInformation($projectName: String!, $entityName: String, $filters: JSONString) {
  project(name: $projectName, entityName: $entityName) {
    id
    runs(filters: $filters) {
      edges {
        node {
          id
          name
          displayName
          state
          createdAt
          heartbeatAt
        }
      }
    }
  }
}
    `;
export type RunsInformationComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunsInformationQuery, RunsInformationQueryVariables>, 'query'> & ({ variables: RunsInformationQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunsInformationComponent = (props: RunsInformationComponentProps) => (
      <ApolloReactComponents.Query<RunsInformationQuery, RunsInformationQueryVariables> query={RunsInformationDocument} {...props} />
    );
    
export type RunsInformationProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunsInformationQuery, RunsInformationQueryVariables>
    } & TChildProps;
export function withRunsInformation<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunsInformationQuery,
  RunsInformationQueryVariables,
  RunsInformationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunsInformationQuery, RunsInformationQueryVariables, RunsInformationProps<TChildProps, TDataName>>(RunsInformationDocument, {
      alias: 'runsInformation',
      ...operationOptions
    });
};

/**
 * __useRunsInformationQuery__
 *
 * To run a query within a React component, call `useRunsInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunsInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunsInformationQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useRunsInformationQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunsInformationQuery, RunsInformationQueryVariables>) {
        return ApolloReactHooks.useQuery<RunsInformationQuery, RunsInformationQueryVariables>(RunsInformationDocument, baseOptions);
      }
export function useRunsInformationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunsInformationQuery, RunsInformationQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunsInformationQuery, RunsInformationQueryVariables>(RunsInformationDocument, baseOptions);
        }
export type RunsInformationQueryHookResult = ReturnType<typeof useRunsInformationQuery>;
export type RunsInformationLazyQueryHookResult = ReturnType<typeof useRunsInformationLazyQuery>;
export type RunsInformationQueryResult = ApolloReactCommon.QueryResult<RunsInformationQuery, RunsInformationQueryVariables>;
export const FetchCodeArtifactFilesDocument = gql`
    query FetchCodeArtifactFiles($artifactID: ID!) {
  artifact(id: $artifactID) {
    id
    files {
      edges {
        node {
          id
          name
          displayName
          mimetype
          sizeBytes
          url
        }
      }
    }
  }
}
    `;
export type FetchCodeArtifactFilesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FetchCodeArtifactFilesQuery, FetchCodeArtifactFilesQueryVariables>, 'query'> & ({ variables: FetchCodeArtifactFilesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FetchCodeArtifactFilesComponent = (props: FetchCodeArtifactFilesComponentProps) => (
      <ApolloReactComponents.Query<FetchCodeArtifactFilesQuery, FetchCodeArtifactFilesQueryVariables> query={FetchCodeArtifactFilesDocument} {...props} />
    );
    
export type FetchCodeArtifactFilesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FetchCodeArtifactFilesQuery, FetchCodeArtifactFilesQueryVariables>
    } & TChildProps;
export function withFetchCodeArtifactFiles<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FetchCodeArtifactFilesQuery,
  FetchCodeArtifactFilesQueryVariables,
  FetchCodeArtifactFilesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FetchCodeArtifactFilesQuery, FetchCodeArtifactFilesQueryVariables, FetchCodeArtifactFilesProps<TChildProps, TDataName>>(FetchCodeArtifactFilesDocument, {
      alias: 'fetchCodeArtifactFiles',
      ...operationOptions
    });
};

/**
 * __useFetchCodeArtifactFilesQuery__
 *
 * To run a query within a React component, call `useFetchCodeArtifactFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchCodeArtifactFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchCodeArtifactFilesQuery({
 *   variables: {
 *      artifactID: // value for 'artifactID'
 *   },
 * });
 */
export function useFetchCodeArtifactFilesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchCodeArtifactFilesQuery, FetchCodeArtifactFilesQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchCodeArtifactFilesQuery, FetchCodeArtifactFilesQueryVariables>(FetchCodeArtifactFilesDocument, baseOptions);
      }
export function useFetchCodeArtifactFilesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchCodeArtifactFilesQuery, FetchCodeArtifactFilesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchCodeArtifactFilesQuery, FetchCodeArtifactFilesQueryVariables>(FetchCodeArtifactFilesDocument, baseOptions);
        }
export type FetchCodeArtifactFilesQueryHookResult = ReturnType<typeof useFetchCodeArtifactFilesQuery>;
export type FetchCodeArtifactFilesLazyQueryHookResult = ReturnType<typeof useFetchCodeArtifactFilesLazyQuery>;
export type FetchCodeArtifactFilesQueryResult = ApolloReactCommon.QueryResult<FetchCodeArtifactFilesQuery, FetchCodeArtifactFilesQueryVariables>;
export const FetchAgentDetailsDocument = gql`
    query FetchAgentDetails($agentId: ID!) {
  launchAgent(id: $agentId) {
    id
    name
    createdAt
    updatedAt
    heartbeatAt
    runQueues
    hostname
    agentStatus
    stopPolling
    agentConfig
    version
  }
}
    `;
export type FetchAgentDetailsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FetchAgentDetailsQuery, FetchAgentDetailsQueryVariables>, 'query'> & ({ variables: FetchAgentDetailsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FetchAgentDetailsComponent = (props: FetchAgentDetailsComponentProps) => (
      <ApolloReactComponents.Query<FetchAgentDetailsQuery, FetchAgentDetailsQueryVariables> query={FetchAgentDetailsDocument} {...props} />
    );
    
export type FetchAgentDetailsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FetchAgentDetailsQuery, FetchAgentDetailsQueryVariables>
    } & TChildProps;
export function withFetchAgentDetails<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FetchAgentDetailsQuery,
  FetchAgentDetailsQueryVariables,
  FetchAgentDetailsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FetchAgentDetailsQuery, FetchAgentDetailsQueryVariables, FetchAgentDetailsProps<TChildProps, TDataName>>(FetchAgentDetailsDocument, {
      alias: 'fetchAgentDetails',
      ...operationOptions
    });
};

/**
 * __useFetchAgentDetailsQuery__
 *
 * To run a query within a React component, call `useFetchAgentDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAgentDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAgentDetailsQuery({
 *   variables: {
 *      agentId: // value for 'agentId'
 *   },
 * });
 */
export function useFetchAgentDetailsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchAgentDetailsQuery, FetchAgentDetailsQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchAgentDetailsQuery, FetchAgentDetailsQueryVariables>(FetchAgentDetailsDocument, baseOptions);
      }
export function useFetchAgentDetailsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchAgentDetailsQuery, FetchAgentDetailsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchAgentDetailsQuery, FetchAgentDetailsQueryVariables>(FetchAgentDetailsDocument, baseOptions);
        }
export type FetchAgentDetailsQueryHookResult = ReturnType<typeof useFetchAgentDetailsQuery>;
export type FetchAgentDetailsLazyQueryHookResult = ReturnType<typeof useFetchAgentDetailsLazyQuery>;
export type FetchAgentDetailsQueryResult = ApolloReactCommon.QueryResult<FetchAgentDetailsQuery, FetchAgentDetailsQueryVariables>;
export const EntityAgentsDocument = gql`
    query EntityAgents($entityName: String!) {
  entity(name: $entityName) {
    id
    name
    launchProject {
      id
      name
      launchAgents {
        id
        name
        createdAt
        heartbeatAt
        agentStatus
        stopPolling
        runQueues
        hostname
      }
    }
  }
}
    `;
export type EntityAgentsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<EntityAgentsQuery, EntityAgentsQueryVariables>, 'query'> & ({ variables: EntityAgentsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const EntityAgentsComponent = (props: EntityAgentsComponentProps) => (
      <ApolloReactComponents.Query<EntityAgentsQuery, EntityAgentsQueryVariables> query={EntityAgentsDocument} {...props} />
    );
    
export type EntityAgentsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<EntityAgentsQuery, EntityAgentsQueryVariables>
    } & TChildProps;
export function withEntityAgents<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  EntityAgentsQuery,
  EntityAgentsQueryVariables,
  EntityAgentsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, EntityAgentsQuery, EntityAgentsQueryVariables, EntityAgentsProps<TChildProps, TDataName>>(EntityAgentsDocument, {
      alias: 'entityAgents',
      ...operationOptions
    });
};

/**
 * __useEntityAgentsQuery__
 *
 * To run a query within a React component, call `useEntityAgentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useEntityAgentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEntityAgentsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useEntityAgentsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<EntityAgentsQuery, EntityAgentsQueryVariables>) {
        return ApolloReactHooks.useQuery<EntityAgentsQuery, EntityAgentsQueryVariables>(EntityAgentsDocument, baseOptions);
      }
export function useEntityAgentsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<EntityAgentsQuery, EntityAgentsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<EntityAgentsQuery, EntityAgentsQueryVariables>(EntityAgentsDocument, baseOptions);
        }
export type EntityAgentsQueryHookResult = ReturnType<typeof useEntityAgentsQuery>;
export type EntityAgentsLazyQueryHookResult = ReturnType<typeof useEntityAgentsLazyQuery>;
export type EntityAgentsQueryResult = ApolloReactCommon.QueryResult<EntityAgentsQuery, EntityAgentsQueryVariables>;
export const FetchRunOutputLogDocument = gql`
    query FetchRunOutputLog($entityName: String!, $projectName: String!, $runName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    run(name: $runName) {
      id
      logLineCount
      outputLogFile: files(first: 1, names: ["output.log"]) {
        edges {
          node {
            id
            name
            md5
            sizeBytes
            directUrl
            url(upload: false)
          }
        }
      }
    }
  }
}
    `;
export type FetchRunOutputLogComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FetchRunOutputLogQuery, FetchRunOutputLogQueryVariables>, 'query'> & ({ variables: FetchRunOutputLogQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FetchRunOutputLogComponent = (props: FetchRunOutputLogComponentProps) => (
      <ApolloReactComponents.Query<FetchRunOutputLogQuery, FetchRunOutputLogQueryVariables> query={FetchRunOutputLogDocument} {...props} />
    );
    
export type FetchRunOutputLogProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FetchRunOutputLogQuery, FetchRunOutputLogQueryVariables>
    } & TChildProps;
export function withFetchRunOutputLog<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FetchRunOutputLogQuery,
  FetchRunOutputLogQueryVariables,
  FetchRunOutputLogProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FetchRunOutputLogQuery, FetchRunOutputLogQueryVariables, FetchRunOutputLogProps<TChildProps, TDataName>>(FetchRunOutputLogDocument, {
      alias: 'fetchRunOutputLog',
      ...operationOptions
    });
};

/**
 * __useFetchRunOutputLogQuery__
 *
 * To run a query within a React component, call `useFetchRunOutputLogQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchRunOutputLogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchRunOutputLogQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      runName: // value for 'runName'
 *   },
 * });
 */
export function useFetchRunOutputLogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchRunOutputLogQuery, FetchRunOutputLogQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchRunOutputLogQuery, FetchRunOutputLogQueryVariables>(FetchRunOutputLogDocument, baseOptions);
      }
export function useFetchRunOutputLogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchRunOutputLogQuery, FetchRunOutputLogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchRunOutputLogQuery, FetchRunOutputLogQueryVariables>(FetchRunOutputLogDocument, baseOptions);
        }
export type FetchRunOutputLogQueryHookResult = ReturnType<typeof useFetchRunOutputLogQuery>;
export type FetchRunOutputLogLazyQueryHookResult = ReturnType<typeof useFetchRunOutputLogLazyQuery>;
export type FetchRunOutputLogQueryResult = ApolloReactCommon.QueryResult<FetchRunOutputLogQuery, FetchRunOutputLogQueryVariables>;
export const QueueByIdDocument = gql`
    query QueueByID($entityName: String!, $queueId: ID!) {
  project(name: "model-registry", entityName: $entityName) {
    id
    name
    launchAgents {
      id
      name
      createdAt
      heartbeatAt
      agentStatus
      stopPolling
      runQueues
      hostname
    }
    runQueue(id: $queueId) {
      id
      createdAt
      name
      defaultResourceConfig {
        id
        createdAt
        resource
        config
        templateVariables {
          name
          description
          schema
        }
      }
      prioritizationMode
      executorInfo
      externalLinks
      runQueueItems(first: 1000) {
        edges {
          node {
            id
            ...RunQueueItemFragment
            error {
              message
              stage
              filePaths
            }
            warnings {
              message
              stage
              filePaths
            }
          }
        }
      }
    }
  }
}
    ${RunQueueItemFragmentFragmentDoc}`;
export type QueueByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<QueueByIdQuery, QueueByIdQueryVariables>, 'query'> & ({ variables: QueueByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const QueueByIdComponent = (props: QueueByIdComponentProps) => (
      <ApolloReactComponents.Query<QueueByIdQuery, QueueByIdQueryVariables> query={QueueByIdDocument} {...props} />
    );
    
export type QueueByIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<QueueByIdQuery, QueueByIdQueryVariables>
    } & TChildProps;
export function withQueueById<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  QueueByIdQuery,
  QueueByIdQueryVariables,
  QueueByIdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, QueueByIdQuery, QueueByIdQueryVariables, QueueByIdProps<TChildProps, TDataName>>(QueueByIdDocument, {
      alias: 'queueById',
      ...operationOptions
    });
};

/**
 * __useQueueByIdQuery__
 *
 * To run a query within a React component, call `useQueueByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useQueueByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQueueByIdQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      queueId: // value for 'queueId'
 *   },
 * });
 */
export function useQueueByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<QueueByIdQuery, QueueByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<QueueByIdQuery, QueueByIdQueryVariables>(QueueByIdDocument, baseOptions);
      }
export function useQueueByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<QueueByIdQuery, QueueByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<QueueByIdQuery, QueueByIdQueryVariables>(QueueByIdDocument, baseOptions);
        }
export type QueueByIdQueryHookResult = ReturnType<typeof useQueueByIdQuery>;
export type QueueByIdLazyQueryHookResult = ReturnType<typeof useQueueByIdLazyQuery>;
export type QueueByIdQueryResult = ApolloReactCommon.QueryResult<QueueByIdQuery, QueueByIdQueryVariables>;
export const RunJobDocument = gql`
    query RunJob($entityName: String!, $projectName: String!, $runName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    run(name: $runName) {
      id
      job {
        id
        artifactSequence {
          id
          name
        }
        versionIndex
      }
    }
  }
}
    `;
export type RunJobComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunJobQuery, RunJobQueryVariables>, 'query'> & ({ variables: RunJobQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunJobComponent = (props: RunJobComponentProps) => (
      <ApolloReactComponents.Query<RunJobQuery, RunJobQueryVariables> query={RunJobDocument} {...props} />
    );
    
export type RunJobProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunJobQuery, RunJobQueryVariables>
    } & TChildProps;
export function withRunJob<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunJobQuery,
  RunJobQueryVariables,
  RunJobProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunJobQuery, RunJobQueryVariables, RunJobProps<TChildProps, TDataName>>(RunJobDocument, {
      alias: 'runJob',
      ...operationOptions
    });
};

/**
 * __useRunJobQuery__
 *
 * To run a query within a React component, call `useRunJobQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunJobQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunJobQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      runName: // value for 'runName'
 *   },
 * });
 */
export function useRunJobQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunJobQuery, RunJobQueryVariables>) {
        return ApolloReactHooks.useQuery<RunJobQuery, RunJobQueryVariables>(RunJobDocument, baseOptions);
      }
export function useRunJobLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunJobQuery, RunJobQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunJobQuery, RunJobQueryVariables>(RunJobDocument, baseOptions);
        }
export type RunJobQueryHookResult = ReturnType<typeof useRunJobQuery>;
export type RunJobLazyQueryHookResult = ReturnType<typeof useRunJobLazyQuery>;
export type RunJobQueryResult = ApolloReactCommon.QueryResult<RunJobQuery, RunJobQueryVariables>;
export const RunFilesAndFolderWithDirectUrLsDocument = gql`
    query RunFilesAndFolderWithDirectURLs($projectName: String!, $entityName: String, $runName: String!, $maxNumFiles: Int!, $pattern: String) {
  project(name: $projectName, entityName: $entityName) {
    id
    name
    run(name: $runName) {
      id
      name
      projectId
      files(first: $maxNumFiles, pattern: $pattern) {
        edges {
          node {
            id
            name
            directUrl
            sizeBytes
            updatedAt
            artifact {
              id
              digest
            }
          }
        }
      }
    }
  }
}
    `;
export type RunFilesAndFolderWithDirectUrLsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunFilesAndFolderWithDirectUrLsQuery, RunFilesAndFolderWithDirectUrLsQueryVariables>, 'query'> & ({ variables: RunFilesAndFolderWithDirectUrLsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunFilesAndFolderWithDirectUrLsComponent = (props: RunFilesAndFolderWithDirectUrLsComponentProps) => (
      <ApolloReactComponents.Query<RunFilesAndFolderWithDirectUrLsQuery, RunFilesAndFolderWithDirectUrLsQueryVariables> query={RunFilesAndFolderWithDirectUrLsDocument} {...props} />
    );
    
export type RunFilesAndFolderWithDirectUrLsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunFilesAndFolderWithDirectUrLsQuery, RunFilesAndFolderWithDirectUrLsQueryVariables>
    } & TChildProps;
export function withRunFilesAndFolderWithDirectUrLs<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunFilesAndFolderWithDirectUrLsQuery,
  RunFilesAndFolderWithDirectUrLsQueryVariables,
  RunFilesAndFolderWithDirectUrLsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunFilesAndFolderWithDirectUrLsQuery, RunFilesAndFolderWithDirectUrLsQueryVariables, RunFilesAndFolderWithDirectUrLsProps<TChildProps, TDataName>>(RunFilesAndFolderWithDirectUrLsDocument, {
      alias: 'runFilesAndFolderWithDirectUrLs',
      ...operationOptions
    });
};

/**
 * __useRunFilesAndFolderWithDirectUrLsQuery__
 *
 * To run a query within a React component, call `useRunFilesAndFolderWithDirectUrLsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunFilesAndFolderWithDirectUrLsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunFilesAndFolderWithDirectUrLsQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runName: // value for 'runName'
 *      maxNumFiles: // value for 'maxNumFiles'
 *      pattern: // value for 'pattern'
 *   },
 * });
 */
export function useRunFilesAndFolderWithDirectUrLsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunFilesAndFolderWithDirectUrLsQuery, RunFilesAndFolderWithDirectUrLsQueryVariables>) {
        return ApolloReactHooks.useQuery<RunFilesAndFolderWithDirectUrLsQuery, RunFilesAndFolderWithDirectUrLsQueryVariables>(RunFilesAndFolderWithDirectUrLsDocument, baseOptions);
      }
export function useRunFilesAndFolderWithDirectUrLsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunFilesAndFolderWithDirectUrLsQuery, RunFilesAndFolderWithDirectUrLsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunFilesAndFolderWithDirectUrLsQuery, RunFilesAndFolderWithDirectUrLsQueryVariables>(RunFilesAndFolderWithDirectUrLsDocument, baseOptions);
        }
export type RunFilesAndFolderWithDirectUrLsQueryHookResult = ReturnType<typeof useRunFilesAndFolderWithDirectUrLsQuery>;
export type RunFilesAndFolderWithDirectUrLsLazyQueryHookResult = ReturnType<typeof useRunFilesAndFolderWithDirectUrLsLazyQuery>;
export type RunFilesAndFolderWithDirectUrLsQueryResult = ApolloReactCommon.QueryResult<RunFilesAndFolderWithDirectUrLsQuery, RunFilesAndFolderWithDirectUrLsQueryVariables>;
export const AccountUsingLaunchDocument = gql`
    query AccountUsingLaunch($orgID: ID!) {
  organization(id: $orgID) {
    id
    isLaunchActive
  }
}
    `;
export type AccountUsingLaunchComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AccountUsingLaunchQuery, AccountUsingLaunchQueryVariables>, 'query'> & ({ variables: AccountUsingLaunchQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AccountUsingLaunchComponent = (props: AccountUsingLaunchComponentProps) => (
      <ApolloReactComponents.Query<AccountUsingLaunchQuery, AccountUsingLaunchQueryVariables> query={AccountUsingLaunchDocument} {...props} />
    );
    
export type AccountUsingLaunchProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AccountUsingLaunchQuery, AccountUsingLaunchQueryVariables>
    } & TChildProps;
export function withAccountUsingLaunch<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AccountUsingLaunchQuery,
  AccountUsingLaunchQueryVariables,
  AccountUsingLaunchProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AccountUsingLaunchQuery, AccountUsingLaunchQueryVariables, AccountUsingLaunchProps<TChildProps, TDataName>>(AccountUsingLaunchDocument, {
      alias: 'accountUsingLaunch',
      ...operationOptions
    });
};

/**
 * __useAccountUsingLaunchQuery__
 *
 * To run a query within a React component, call `useAccountUsingLaunchQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountUsingLaunchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountUsingLaunchQuery({
 *   variables: {
 *      orgID: // value for 'orgID'
 *   },
 * });
 */
export function useAccountUsingLaunchQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountUsingLaunchQuery, AccountUsingLaunchQueryVariables>) {
        return ApolloReactHooks.useQuery<AccountUsingLaunchQuery, AccountUsingLaunchQueryVariables>(AccountUsingLaunchDocument, baseOptions);
      }
export function useAccountUsingLaunchLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountUsingLaunchQuery, AccountUsingLaunchQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AccountUsingLaunchQuery, AccountUsingLaunchQueryVariables>(AccountUsingLaunchDocument, baseOptions);
        }
export type AccountUsingLaunchQueryHookResult = ReturnType<typeof useAccountUsingLaunchQuery>;
export type AccountUsingLaunchLazyQueryHookResult = ReturnType<typeof useAccountUsingLaunchLazyQuery>;
export type AccountUsingLaunchQueryResult = ApolloReactCommon.QueryResult<AccountUsingLaunchQuery, AccountUsingLaunchQueryVariables>;
export const MessageOfTheDayDocument = gql`
    query MessageOfTheDay {
  serverInfo {
    messageOfTheDay
  }
}
    `;
export type MessageOfTheDayComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MessageOfTheDayQuery, MessageOfTheDayQueryVariables>, 'query'>;

    export const MessageOfTheDayComponent = (props: MessageOfTheDayComponentProps) => (
      <ApolloReactComponents.Query<MessageOfTheDayQuery, MessageOfTheDayQueryVariables> query={MessageOfTheDayDocument} {...props} />
    );
    
export type MessageOfTheDayProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<MessageOfTheDayQuery, MessageOfTheDayQueryVariables>
    } & TChildProps;
export function withMessageOfTheDay<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MessageOfTheDayQuery,
  MessageOfTheDayQueryVariables,
  MessageOfTheDayProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, MessageOfTheDayQuery, MessageOfTheDayQueryVariables, MessageOfTheDayProps<TChildProps, TDataName>>(MessageOfTheDayDocument, {
      alias: 'messageOfTheDay',
      ...operationOptions
    });
};

/**
 * __useMessageOfTheDayQuery__
 *
 * To run a query within a React component, call `useMessageOfTheDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useMessageOfTheDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMessageOfTheDayQuery({
 *   variables: {
 *   },
 * });
 */
export function useMessageOfTheDayQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MessageOfTheDayQuery, MessageOfTheDayQueryVariables>) {
        return ApolloReactHooks.useQuery<MessageOfTheDayQuery, MessageOfTheDayQueryVariables>(MessageOfTheDayDocument, baseOptions);
      }
export function useMessageOfTheDayLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MessageOfTheDayQuery, MessageOfTheDayQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MessageOfTheDayQuery, MessageOfTheDayQueryVariables>(MessageOfTheDayDocument, baseOptions);
        }
export type MessageOfTheDayQueryHookResult = ReturnType<typeof useMessageOfTheDayQuery>;
export type MessageOfTheDayLazyQueryHookResult = ReturnType<typeof useMessageOfTheDayLazyQuery>;
export type MessageOfTheDayQueryResult = ApolloReactCommon.QueryResult<MessageOfTheDayQuery, MessageOfTheDayQueryVariables>;
export const MoveViewDocument = gql`
    mutation moveView($id: ID!, $entityName: String!, $projectName: String!, $clientMutationId: String) {
  moveView(
    input: {id: $id, entityName: $entityName, projectName: $projectName, clientMutationId: $clientMutationId}
  ) {
    success
    clientMutationId
  }
}
    `;
export type MoveViewMutationFn = ApolloReactCommon.MutationFunction<MoveViewMutation, MoveViewMutationVariables>;
export type MoveViewComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<MoveViewMutation, MoveViewMutationVariables>, 'mutation'>;

    export const MoveViewComponent = (props: MoveViewComponentProps) => (
      <ApolloReactComponents.Mutation<MoveViewMutation, MoveViewMutationVariables> mutation={MoveViewDocument} {...props} />
    );
    
export type MoveViewProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<MoveViewMutation, MoveViewMutationVariables>
    } & TChildProps;
export function withMoveView<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MoveViewMutation,
  MoveViewMutationVariables,
  MoveViewProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, MoveViewMutation, MoveViewMutationVariables, MoveViewProps<TChildProps, TDataName>>(MoveViewDocument, {
      alias: 'moveView',
      ...operationOptions
    });
};

/**
 * __useMoveViewMutation__
 *
 * To run a mutation, you first call `useMoveViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useMoveViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [moveViewMutation, { data, loading, error }] = useMoveViewMutation({
 *   variables: {
 *      id: // value for 'id'
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useMoveViewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<MoveViewMutation, MoveViewMutationVariables>) {
        return ApolloReactHooks.useMutation<MoveViewMutation, MoveViewMutationVariables>(MoveViewDocument, baseOptions);
      }
export type MoveViewMutationHookResult = ReturnType<typeof useMoveViewMutation>;
export type MoveViewMutationResult = ApolloReactCommon.MutationResult<MoveViewMutation>;
export type MoveViewMutationOptions = ApolloReactCommon.BaseMutationOptions<MoveViewMutation, MoveViewMutationVariables>;
export const MultiRunWorkspaceDocument = gql`
    query MultiRunWorkspace($projectName: String!, $entityName: String) {
  project(name: $projectName, entityName: $entityName) {
    id
    readOnly
    isBenchmark
    linkedBenchmark {
      id
      entityName
      name
      gitHubSubmissionRepo
      views
    }
    runQueues {
      id
      name
      createdBy
      access
    }
  }
}
    `;
export type MultiRunWorkspaceComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<MultiRunWorkspaceQuery, MultiRunWorkspaceQueryVariables>, 'query'> & ({ variables: MultiRunWorkspaceQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const MultiRunWorkspaceComponent = (props: MultiRunWorkspaceComponentProps) => (
      <ApolloReactComponents.Query<MultiRunWorkspaceQuery, MultiRunWorkspaceQueryVariables> query={MultiRunWorkspaceDocument} {...props} />
    );
    
export type MultiRunWorkspaceProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<MultiRunWorkspaceQuery, MultiRunWorkspaceQueryVariables>
    } & TChildProps;
export function withMultiRunWorkspace<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MultiRunWorkspaceQuery,
  MultiRunWorkspaceQueryVariables,
  MultiRunWorkspaceProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, MultiRunWorkspaceQuery, MultiRunWorkspaceQueryVariables, MultiRunWorkspaceProps<TChildProps, TDataName>>(MultiRunWorkspaceDocument, {
      alias: 'multiRunWorkspace',
      ...operationOptions
    });
};

/**
 * __useMultiRunWorkspaceQuery__
 *
 * To run a query within a React component, call `useMultiRunWorkspaceQuery` and pass it any options that fit your needs.
 * When your component renders, `useMultiRunWorkspaceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMultiRunWorkspaceQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useMultiRunWorkspaceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<MultiRunWorkspaceQuery, MultiRunWorkspaceQueryVariables>) {
        return ApolloReactHooks.useQuery<MultiRunWorkspaceQuery, MultiRunWorkspaceQueryVariables>(MultiRunWorkspaceDocument, baseOptions);
      }
export function useMultiRunWorkspaceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<MultiRunWorkspaceQuery, MultiRunWorkspaceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<MultiRunWorkspaceQuery, MultiRunWorkspaceQueryVariables>(MultiRunWorkspaceDocument, baseOptions);
        }
export type MultiRunWorkspaceQueryHookResult = ReturnType<typeof useMultiRunWorkspaceQuery>;
export type MultiRunWorkspaceLazyQueryHookResult = ReturnType<typeof useMultiRunWorkspaceLazyQuery>;
export type MultiRunWorkspaceQueryResult = ApolloReactCommon.QueryResult<MultiRunWorkspaceQuery, MultiRunWorkspaceQueryVariables>;
export const NwEntityPermissionDocument = gql`
    query NwEntityPermission($entityName: String) {
  entity(name: $entityName) {
    id
    isTeam
    settings {
      hidden
    }
  }
}
    `;
export type NwEntityPermissionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NwEntityPermissionQuery, NwEntityPermissionQueryVariables>, 'query'>;

    export const NwEntityPermissionComponent = (props: NwEntityPermissionComponentProps) => (
      <ApolloReactComponents.Query<NwEntityPermissionQuery, NwEntityPermissionQueryVariables> query={NwEntityPermissionDocument} {...props} />
    );
    
export type NwEntityPermissionProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<NwEntityPermissionQuery, NwEntityPermissionQueryVariables>
    } & TChildProps;
export function withNwEntityPermission<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  NwEntityPermissionQuery,
  NwEntityPermissionQueryVariables,
  NwEntityPermissionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, NwEntityPermissionQuery, NwEntityPermissionQueryVariables, NwEntityPermissionProps<TChildProps, TDataName>>(NwEntityPermissionDocument, {
      alias: 'nwEntityPermission',
      ...operationOptions
    });
};

/**
 * __useNwEntityPermissionQuery__
 *
 * To run a query within a React component, call `useNwEntityPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useNwEntityPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNwEntityPermissionQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useNwEntityPermissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NwEntityPermissionQuery, NwEntityPermissionQueryVariables>) {
        return ApolloReactHooks.useQuery<NwEntityPermissionQuery, NwEntityPermissionQueryVariables>(NwEntityPermissionDocument, baseOptions);
      }
export function useNwEntityPermissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NwEntityPermissionQuery, NwEntityPermissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NwEntityPermissionQuery, NwEntityPermissionQueryVariables>(NwEntityPermissionDocument, baseOptions);
        }
export type NwEntityPermissionQueryHookResult = ReturnType<typeof useNwEntityPermissionQuery>;
export type NwEntityPermissionLazyQueryHookResult = ReturnType<typeof useNwEntityPermissionLazyQuery>;
export type NwEntityPermissionQueryResult = ApolloReactCommon.QueryResult<NwEntityPermissionQuery, NwEntityPermissionQueryVariables>;
export const NwProjectPermissionDocument = gql`
    query NwProjectPermission($projectName: String!, $entityName: String) {
  project(name: $projectName, entityName: $entityName) {
    id
    access
    user {
      id
      username
    }
    members {
      id
      username
    }
  }
}
    `;
export type NwProjectPermissionComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NwProjectPermissionQuery, NwProjectPermissionQueryVariables>, 'query'> & ({ variables: NwProjectPermissionQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const NwProjectPermissionComponent = (props: NwProjectPermissionComponentProps) => (
      <ApolloReactComponents.Query<NwProjectPermissionQuery, NwProjectPermissionQueryVariables> query={NwProjectPermissionDocument} {...props} />
    );
    
export type NwProjectPermissionProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<NwProjectPermissionQuery, NwProjectPermissionQueryVariables>
    } & TChildProps;
export function withNwProjectPermission<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  NwProjectPermissionQuery,
  NwProjectPermissionQueryVariables,
  NwProjectPermissionProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, NwProjectPermissionQuery, NwProjectPermissionQueryVariables, NwProjectPermissionProps<TChildProps, TDataName>>(NwProjectPermissionDocument, {
      alias: 'nwProjectPermission',
      ...operationOptions
    });
};

/**
 * __useNwProjectPermissionQuery__
 *
 * To run a query within a React component, call `useNwProjectPermissionQuery` and pass it any options that fit your needs.
 * When your component renders, `useNwProjectPermissionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNwProjectPermissionQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useNwProjectPermissionQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NwProjectPermissionQuery, NwProjectPermissionQueryVariables>) {
        return ApolloReactHooks.useQuery<NwProjectPermissionQuery, NwProjectPermissionQueryVariables>(NwProjectPermissionDocument, baseOptions);
      }
export function useNwProjectPermissionLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NwProjectPermissionQuery, NwProjectPermissionQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NwProjectPermissionQuery, NwProjectPermissionQueryVariables>(NwProjectPermissionDocument, baseOptions);
        }
export type NwProjectPermissionQueryHookResult = ReturnType<typeof useNwProjectPermissionQuery>;
export type NwProjectPermissionLazyQueryHookResult = ReturnType<typeof useNwProjectPermissionLazyQuery>;
export type NwProjectPermissionQueryResult = ApolloReactCommon.QueryResult<NwProjectPermissionQuery, NwProjectPermissionQueryVariables>;
export const OrgAdminsDocument = gql`
    query OrgAdmins($orgName: String) {
  organization(name: $orgName) {
    id
    billingUser {
      id
      username
    }
    members {
      id
      user {
        id
        username
      }
      admin
    }
  }
}
    `;
export type OrgAdminsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrgAdminsQuery, OrgAdminsQueryVariables>, 'query'>;

    export const OrgAdminsComponent = (props: OrgAdminsComponentProps) => (
      <ApolloReactComponents.Query<OrgAdminsQuery, OrgAdminsQueryVariables> query={OrgAdminsDocument} {...props} />
    );
    
export type OrgAdminsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrgAdminsQuery, OrgAdminsQueryVariables>
    } & TChildProps;
export function withOrgAdmins<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrgAdminsQuery,
  OrgAdminsQueryVariables,
  OrgAdminsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrgAdminsQuery, OrgAdminsQueryVariables, OrgAdminsProps<TChildProps, TDataName>>(OrgAdminsDocument, {
      alias: 'orgAdmins',
      ...operationOptions
    });
};

/**
 * __useOrgAdminsQuery__
 *
 * To run a query within a React component, call `useOrgAdminsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrgAdminsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrgAdminsQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useOrgAdminsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrgAdminsQuery, OrgAdminsQueryVariables>) {
        return ApolloReactHooks.useQuery<OrgAdminsQuery, OrgAdminsQueryVariables>(OrgAdminsDocument, baseOptions);
      }
export function useOrgAdminsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrgAdminsQuery, OrgAdminsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrgAdminsQuery, OrgAdminsQueryVariables>(OrgAdminsDocument, baseOptions);
        }
export type OrgAdminsQueryHookResult = ReturnType<typeof useOrgAdminsQuery>;
export type OrgAdminsLazyQueryHookResult = ReturnType<typeof useOrgAdminsLazyQuery>;
export type OrgAdminsQueryResult = ApolloReactCommon.QueryResult<OrgAdminsQuery, OrgAdminsQueryVariables>;
export const OrganizationPageDocument = gql`
    query OrganizationPage($name: String!) {
  organization(name: $name) {
    id
    name
    members {
      orgID
      id
      name
      username
    }
    subscriptions {
      id
      privileges
    }
  }
}
    `;
export type OrganizationPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationPageQuery, OrganizationPageQueryVariables>, 'query'> & ({ variables: OrganizationPageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationPageComponent = (props: OrganizationPageComponentProps) => (
      <ApolloReactComponents.Query<OrganizationPageQuery, OrganizationPageQueryVariables> query={OrganizationPageDocument} {...props} />
    );
    
export type OrganizationPageProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationPageQuery, OrganizationPageQueryVariables>
    } & TChildProps;
export function withOrganizationPage<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationPageQuery,
  OrganizationPageQueryVariables,
  OrganizationPageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationPageQuery, OrganizationPageQueryVariables, OrganizationPageProps<TChildProps, TDataName>>(OrganizationPageDocument, {
      alias: 'organizationPage',
      ...operationOptions
    });
};

/**
 * __useOrganizationPageQuery__
 *
 * To run a query within a React component, call `useOrganizationPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationPageQuery({
 *   variables: {
 *      name: // value for 'name'
 *   },
 * });
 */
export function useOrganizationPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationPageQuery, OrganizationPageQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationPageQuery, OrganizationPageQueryVariables>(OrganizationPageDocument, baseOptions);
      }
export function useOrganizationPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationPageQuery, OrganizationPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationPageQuery, OrganizationPageQueryVariables>(OrganizationPageDocument, baseOptions);
        }
export type OrganizationPageQueryHookResult = ReturnType<typeof useOrganizationPageQuery>;
export type OrganizationPageLazyQueryHookResult = ReturnType<typeof useOrganizationPageLazyQuery>;
export type OrganizationPageQueryResult = ApolloReactCommon.QueryResult<OrganizationPageQuery, OrganizationPageQueryVariables>;
export const ParameterImportanceDocument = gql`
    query ParameterImportance($projectName: String!, $entityName: String, $limit: Int!, $targetName: String!, $filters: JSONString, $parameters: [String!], $runParameters: [String!]) {
  project(name: $projectName, entityName: $entityName) {
    id
    parameterImportance(
      limit: $limit
      filters: $filters
      target: $targetName
      parameters: $parameters
      runParameters: $runParameters
    )
  }
}
    `;
export type ParameterImportanceComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ParameterImportanceQuery, ParameterImportanceQueryVariables>, 'query'> & ({ variables: ParameterImportanceQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ParameterImportanceComponent = (props: ParameterImportanceComponentProps) => (
      <ApolloReactComponents.Query<ParameterImportanceQuery, ParameterImportanceQueryVariables> query={ParameterImportanceDocument} {...props} />
    );
    
export type ParameterImportanceProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ParameterImportanceQuery, ParameterImportanceQueryVariables>
    } & TChildProps;
export function withParameterImportance<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ParameterImportanceQuery,
  ParameterImportanceQueryVariables,
  ParameterImportanceProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ParameterImportanceQuery, ParameterImportanceQueryVariables, ParameterImportanceProps<TChildProps, TDataName>>(ParameterImportanceDocument, {
      alias: 'parameterImportance',
      ...operationOptions
    });
};

/**
 * __useParameterImportanceQuery__
 *
 * To run a query within a React component, call `useParameterImportanceQuery` and pass it any options that fit your needs.
 * When your component renders, `useParameterImportanceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParameterImportanceQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      limit: // value for 'limit'
 *      targetName: // value for 'targetName'
 *      filters: // value for 'filters'
 *      parameters: // value for 'parameters'
 *      runParameters: // value for 'runParameters'
 *   },
 * });
 */
export function useParameterImportanceQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ParameterImportanceQuery, ParameterImportanceQueryVariables>) {
        return ApolloReactHooks.useQuery<ParameterImportanceQuery, ParameterImportanceQueryVariables>(ParameterImportanceDocument, baseOptions);
      }
export function useParameterImportanceLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ParameterImportanceQuery, ParameterImportanceQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ParameterImportanceQuery, ParameterImportanceQueryVariables>(ParameterImportanceDocument, baseOptions);
        }
export type ParameterImportanceQueryHookResult = ReturnType<typeof useParameterImportanceQuery>;
export type ParameterImportanceLazyQueryHookResult = ReturnType<typeof useParameterImportanceLazyQuery>;
export type ParameterImportanceQueryResult = ApolloReactCommon.QueryResult<ParameterImportanceQuery, ParameterImportanceQueryVariables>;
export const ProjectArtifactCollectionsDocument = gql`
    query ProjectArtifactCollections($projectName: String!, $entityName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    artifactTypes {
      edges {
        node {
          id
          artifactCollections(order: "-createdAt") {
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type ProjectArtifactCollectionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectArtifactCollectionsQuery, ProjectArtifactCollectionsQueryVariables>, 'query'> & ({ variables: ProjectArtifactCollectionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectArtifactCollectionsComponent = (props: ProjectArtifactCollectionsComponentProps) => (
      <ApolloReactComponents.Query<ProjectArtifactCollectionsQuery, ProjectArtifactCollectionsQueryVariables> query={ProjectArtifactCollectionsDocument} {...props} />
    );
    
export type ProjectArtifactCollectionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProjectArtifactCollectionsQuery, ProjectArtifactCollectionsQueryVariables>
    } & TChildProps;
export function withProjectArtifactCollections<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProjectArtifactCollectionsQuery,
  ProjectArtifactCollectionsQueryVariables,
  ProjectArtifactCollectionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProjectArtifactCollectionsQuery, ProjectArtifactCollectionsQueryVariables, ProjectArtifactCollectionsProps<TChildProps, TDataName>>(ProjectArtifactCollectionsDocument, {
      alias: 'projectArtifactCollections',
      ...operationOptions
    });
};

/**
 * __useProjectArtifactCollectionsQuery__
 *
 * To run a query within a React component, call `useProjectArtifactCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectArtifactCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectArtifactCollectionsQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useProjectArtifactCollectionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectArtifactCollectionsQuery, ProjectArtifactCollectionsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectArtifactCollectionsQuery, ProjectArtifactCollectionsQueryVariables>(ProjectArtifactCollectionsDocument, baseOptions);
      }
export function useProjectArtifactCollectionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectArtifactCollectionsQuery, ProjectArtifactCollectionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectArtifactCollectionsQuery, ProjectArtifactCollectionsQueryVariables>(ProjectArtifactCollectionsDocument, baseOptions);
        }
export type ProjectArtifactCollectionsQueryHookResult = ReturnType<typeof useProjectArtifactCollectionsQuery>;
export type ProjectArtifactCollectionsLazyQueryHookResult = ReturnType<typeof useProjectArtifactCollectionsLazyQuery>;
export type ProjectArtifactCollectionsQueryResult = ApolloReactCommon.QueryResult<ProjectArtifactCollectionsQuery, ProjectArtifactCollectionsQueryVariables>;
export const ProjectArtifactsDocument = gql`
    query ProjectArtifacts($projectName: String!, $entityName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    artifactTypes {
      edges {
        node {
          id
          name
          artifactCollections(order: "-createdAt") {
            edges {
              node {
                id
                name
              }
            }
            totalCount
          }
        }
      }
    }
  }
}
    `;
export type ProjectArtifactsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectArtifactsQuery, ProjectArtifactsQueryVariables>, 'query'> & ({ variables: ProjectArtifactsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectArtifactsComponent = (props: ProjectArtifactsComponentProps) => (
      <ApolloReactComponents.Query<ProjectArtifactsQuery, ProjectArtifactsQueryVariables> query={ProjectArtifactsDocument} {...props} />
    );
    
export type ProjectArtifactsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProjectArtifactsQuery, ProjectArtifactsQueryVariables>
    } & TChildProps;
export function withProjectArtifacts<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProjectArtifactsQuery,
  ProjectArtifactsQueryVariables,
  ProjectArtifactsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProjectArtifactsQuery, ProjectArtifactsQueryVariables, ProjectArtifactsProps<TChildProps, TDataName>>(ProjectArtifactsDocument, {
      alias: 'projectArtifacts',
      ...operationOptions
    });
};

/**
 * __useProjectArtifactsQuery__
 *
 * To run a query within a React component, call `useProjectArtifactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectArtifactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectArtifactsQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useProjectArtifactsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectArtifactsQuery, ProjectArtifactsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectArtifactsQuery, ProjectArtifactsQueryVariables>(ProjectArtifactsDocument, baseOptions);
      }
export function useProjectArtifactsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectArtifactsQuery, ProjectArtifactsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectArtifactsQuery, ProjectArtifactsQueryVariables>(ProjectArtifactsDocument, baseOptions);
        }
export type ProjectArtifactsQueryHookResult = ReturnType<typeof useProjectArtifactsQuery>;
export type ProjectArtifactsLazyQueryHookResult = ReturnType<typeof useProjectArtifactsLazyQuery>;
export type ProjectArtifactsQueryResult = ApolloReactCommon.QueryResult<ProjectArtifactsQuery, ProjectArtifactsQueryVariables>;
export const ProjectFieldsDocument = gql`
    query ProjectFields($projectName: String!, $entityName: String!, $types: [String!]!, $columns: [String!]!, $pattern: String, $count: Int!, $cursor: String) {
  project(name: $projectName, entityName: $entityName) {
    id
    fields(
      types: $types
      columns: $columns
      pattern: $pattern
      first: $count
      after: $cursor
    ) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          path
          type
        }
      }
    }
  }
}
    `;
export type ProjectFieldsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectFieldsQuery, ProjectFieldsQueryVariables>, 'query'> & ({ variables: ProjectFieldsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectFieldsComponent = (props: ProjectFieldsComponentProps) => (
      <ApolloReactComponents.Query<ProjectFieldsQuery, ProjectFieldsQueryVariables> query={ProjectFieldsDocument} {...props} />
    );
    
export type ProjectFieldsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProjectFieldsQuery, ProjectFieldsQueryVariables>
    } & TChildProps;
export function withProjectFields<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProjectFieldsQuery,
  ProjectFieldsQueryVariables,
  ProjectFieldsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProjectFieldsQuery, ProjectFieldsQueryVariables, ProjectFieldsProps<TChildProps, TDataName>>(ProjectFieldsDocument, {
      alias: 'projectFields',
      ...operationOptions
    });
};

/**
 * __useProjectFieldsQuery__
 *
 * To run a query within a React component, call `useProjectFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectFieldsQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      types: // value for 'types'
 *      columns: // value for 'columns'
 *      pattern: // value for 'pattern'
 *      count: // value for 'count'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useProjectFieldsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectFieldsQuery, ProjectFieldsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectFieldsQuery, ProjectFieldsQueryVariables>(ProjectFieldsDocument, baseOptions);
      }
export function useProjectFieldsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectFieldsQuery, ProjectFieldsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectFieldsQuery, ProjectFieldsQueryVariables>(ProjectFieldsDocument, baseOptions);
        }
export type ProjectFieldsQueryHookResult = ReturnType<typeof useProjectFieldsQuery>;
export type ProjectFieldsLazyQueryHookResult = ReturnType<typeof useProjectFieldsLazyQuery>;
export type ProjectFieldsQueryResult = ApolloReactCommon.QueryResult<ProjectFieldsQuery, ProjectFieldsQueryVariables>;
export const ProjectPageDocument = gql`
    query ProjectPage($projectName: String!, $entityName: String) {
  project(name: $projectName, entityName: $entityName) {
    id
    name
    entityName
    lastActive
    access
    description
    readOnly
    totalRuns: runCount
    fromV2
    computeHours
    totalSweeps
    totalUsers
    isBenchmark
    views
    reports: allViews(viewType: "runs") {
      totalCount
    }
    user {
      id
      name
      username
      photoUrl
      accountType
    }
    linkedBenchmark {
      id
      entityName
      name
      gitHubSubmissionRepo
      views
    }
    entity {
      id
      name
      defaultAccess
      readOnlyAdmin
      privateOnly
      isTeam
      claimingEntity {
        id
        name
      }
    }
    ...WeaveflowCheckFragment
  }
}
    ${WeaveflowCheckFragmentFragmentDoc}`;
export type ProjectPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectPageQuery, ProjectPageQueryVariables>, 'query'> & ({ variables: ProjectPageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectPageComponent = (props: ProjectPageComponentProps) => (
      <ApolloReactComponents.Query<ProjectPageQuery, ProjectPageQueryVariables> query={ProjectPageDocument} {...props} />
    );
    
export type ProjectPageProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProjectPageQuery, ProjectPageQueryVariables>
    } & TChildProps;
export function withProjectPage<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProjectPageQuery,
  ProjectPageQueryVariables,
  ProjectPageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProjectPageQuery, ProjectPageQueryVariables, ProjectPageProps<TChildProps, TDataName>>(ProjectPageDocument, {
      alias: 'projectPage',
      ...operationOptions
    });
};

/**
 * __useProjectPageQuery__
 *
 * To run a query within a React component, call `useProjectPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectPageQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useProjectPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectPageQuery, ProjectPageQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectPageQuery, ProjectPageQueryVariables>(ProjectPageDocument, baseOptions);
      }
export function useProjectPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectPageQuery, ProjectPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectPageQuery, ProjectPageQueryVariables>(ProjectPageDocument, baseOptions);
        }
export type ProjectPageQueryHookResult = ReturnType<typeof useProjectPageQuery>;
export type ProjectPageLazyQueryHookResult = ReturnType<typeof useProjectPageLazyQuery>;
export type ProjectPageQueryResult = ApolloReactCommon.QueryResult<ProjectPageQuery, ProjectPageQueryVariables>;
export const ProjectTags2Document = gql`
    query ProjectTags2($projectName: String!, $entityName: String!, $pattern: String, $before: String, $after: String, $first: Int = 100, $last: Int) {
  project(name: $projectName, entityName: $entityName) {
    id
    runTags(
      pattern: $pattern
      before: $before
      after: $after
      first: $first
      last: $last
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
        startCursor
        endCursor
      }
      edges {
        node {
          name
          id
        }
      }
    }
  }
}
    `;
export type ProjectTags2ComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectTags2Query, ProjectTags2QueryVariables>, 'query'> & ({ variables: ProjectTags2QueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectTags2Component = (props: ProjectTags2ComponentProps) => (
      <ApolloReactComponents.Query<ProjectTags2Query, ProjectTags2QueryVariables> query={ProjectTags2Document} {...props} />
    );
    
export type ProjectTags2Props<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProjectTags2Query, ProjectTags2QueryVariables>
    } & TChildProps;
export function withProjectTags2<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProjectTags2Query,
  ProjectTags2QueryVariables,
  ProjectTags2Props<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProjectTags2Query, ProjectTags2QueryVariables, ProjectTags2Props<TChildProps, TDataName>>(ProjectTags2Document, {
      alias: 'projectTags2',
      ...operationOptions
    });
};

/**
 * __useProjectTags2Query__
 *
 * To run a query within a React component, call `useProjectTags2Query` and pass it any options that fit your needs.
 * When your component renders, `useProjectTags2Query` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTags2Query({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      pattern: // value for 'pattern'
 *      before: // value for 'before'
 *      after: // value for 'after'
 *      first: // value for 'first'
 *      last: // value for 'last'
 *   },
 * });
 */
export function useProjectTags2Query(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectTags2Query, ProjectTags2QueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectTags2Query, ProjectTags2QueryVariables>(ProjectTags2Document, baseOptions);
      }
export function useProjectTags2LazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectTags2Query, ProjectTags2QueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectTags2Query, ProjectTags2QueryVariables>(ProjectTags2Document, baseOptions);
        }
export type ProjectTags2QueryHookResult = ReturnType<typeof useProjectTags2Query>;
export type ProjectTags2LazyQueryHookResult = ReturnType<typeof useProjectTags2LazyQuery>;
export type ProjectTags2QueryResult = ApolloReactCommon.QueryResult<ProjectTags2Query, ProjectTags2QueryVariables>;
export const ProjectTagsDocument = gql`
    query ProjectTags($projectName: String!, $entityName: String, $filters: JSONString) {
  project(name: $projectName, entityName: $entityName) {
    id
    tagCounts(filters: $filters) {
      name
      count
    }
  }
}
    `;
export type ProjectTagsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ProjectTagsQuery, ProjectTagsQueryVariables>, 'query'> & ({ variables: ProjectTagsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ProjectTagsComponent = (props: ProjectTagsComponentProps) => (
      <ApolloReactComponents.Query<ProjectTagsQuery, ProjectTagsQueryVariables> query={ProjectTagsDocument} {...props} />
    );
    
export type ProjectTagsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ProjectTagsQuery, ProjectTagsQueryVariables>
    } & TChildProps;
export function withProjectTags<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ProjectTagsQuery,
  ProjectTagsQueryVariables,
  ProjectTagsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ProjectTagsQuery, ProjectTagsQueryVariables, ProjectTagsProps<TChildProps, TDataName>>(ProjectTagsDocument, {
      alias: 'projectTags',
      ...operationOptions
    });
};

/**
 * __useProjectTagsQuery__
 *
 * To run a query within a React component, call `useProjectTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useProjectTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useProjectTagsQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useProjectTagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ProjectTagsQuery, ProjectTagsQueryVariables>) {
        return ApolloReactHooks.useQuery<ProjectTagsQuery, ProjectTagsQueryVariables>(ProjectTagsDocument, baseOptions);
      }
export function useProjectTagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ProjectTagsQuery, ProjectTagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ProjectTagsQuery, ProjectTagsQueryVariables>(ProjectTagsDocument, baseOptions);
        }
export type ProjectTagsQueryHookResult = ReturnType<typeof useProjectTagsQuery>;
export type ProjectTagsLazyQueryHookResult = ReturnType<typeof useProjectTagsLazyQuery>;
export type ProjectTagsQueryResult = ApolloReactCommon.QueryResult<ProjectTagsQuery, ProjectTagsQueryVariables>;
export const ReportPageDocument = gql`
    query ReportPage($projectName: String!, $entityName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    access
    readOnly
    entity {
      id
      name
      defaultAccess
      readOnlyAdmin
      privateOnly
      isTeam
    }
  }
}
    `;
export type ReportPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ReportPageQuery, ReportPageQueryVariables>, 'query'> & ({ variables: ReportPageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ReportPageComponent = (props: ReportPageComponentProps) => (
      <ApolloReactComponents.Query<ReportPageQuery, ReportPageQueryVariables> query={ReportPageDocument} {...props} />
    );
    
export type ReportPageProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ReportPageQuery, ReportPageQueryVariables>
    } & TChildProps;
export function withReportPage<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReportPageQuery,
  ReportPageQueryVariables,
  ReportPageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ReportPageQuery, ReportPageQueryVariables, ReportPageProps<TChildProps, TDataName>>(ReportPageDocument, {
      alias: 'reportPage',
      ...operationOptions
    });
};

/**
 * __useReportPageQuery__
 *
 * To run a query within a React component, call `useReportPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportPageQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useReportPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReportPageQuery, ReportPageQueryVariables>) {
        return ApolloReactHooks.useQuery<ReportPageQuery, ReportPageQueryVariables>(ReportPageDocument, baseOptions);
      }
export function useReportPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReportPageQuery, ReportPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReportPageQuery, ReportPageQueryVariables>(ReportPageDocument, baseOptions);
        }
export type ReportPageQueryHookResult = ReturnType<typeof useReportPageQuery>;
export type ReportPageLazyQueryHookResult = ReturnType<typeof useReportPageLazyQuery>;
export type ReportPageQueryResult = ApolloReactCommon.QueryResult<ReportPageQuery, ReportPageQueryVariables>;
export const ReportTableDocument = gql`
    query ReportTable($projectName: String!, $entityName: String, $draftsOffset: Int = 0, $draftsPageSize: Int = 100, $publishedOffset: Int = 0, $publishedPageSize: Int = 100) {
  project(name: $projectName, entityName: $entityName) {
    id
    access
    readOnly
    reportDrafts: allViews(
      viewType: "runs/draft"
      first: $draftsPageSize
      offset: $draftsOffset
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
      edges {
        node {
          id
          ...ViewFragmentMetadata
        }
      }
    }
    reports: allViews(
      viewType: "runs"
      first: $publishedPageSize
      offset: $publishedOffset
    ) {
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      totalCount
      edges {
        node {
          id
          ...ViewFragmentMetadata
        }
      }
    }
  }
}
    ${ViewFragmentMetadataFragmentDoc}`;
export type ReportTableComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ReportTableQuery, ReportTableQueryVariables>, 'query'> & ({ variables: ReportTableQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ReportTableComponent = (props: ReportTableComponentProps) => (
      <ApolloReactComponents.Query<ReportTableQuery, ReportTableQueryVariables> query={ReportTableDocument} {...props} />
    );
    
export type ReportTableProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ReportTableQuery, ReportTableQueryVariables>
    } & TChildProps;
export function withReportTable<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ReportTableQuery,
  ReportTableQueryVariables,
  ReportTableProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ReportTableQuery, ReportTableQueryVariables, ReportTableProps<TChildProps, TDataName>>(ReportTableDocument, {
      alias: 'reportTable',
      ...operationOptions
    });
};

/**
 * __useReportTableQuery__
 *
 * To run a query within a React component, call `useReportTableQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportTableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportTableQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      draftsOffset: // value for 'draftsOffset'
 *      draftsPageSize: // value for 'draftsPageSize'
 *      publishedOffset: // value for 'publishedOffset'
 *      publishedPageSize: // value for 'publishedPageSize'
 *   },
 * });
 */
export function useReportTableQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ReportTableQuery, ReportTableQueryVariables>) {
        return ApolloReactHooks.useQuery<ReportTableQuery, ReportTableQueryVariables>(ReportTableDocument, baseOptions);
      }
export function useReportTableLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ReportTableQuery, ReportTableQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ReportTableQuery, ReportTableQueryVariables>(ReportTableDocument, baseOptions);
        }
export type ReportTableQueryHookResult = ReturnType<typeof useReportTableQuery>;
export type ReportTableLazyQueryHookResult = ReturnType<typeof useReportTableLazyQuery>;
export type ReportTableQueryResult = ApolloReactCommon.QueryResult<ReportTableQuery, ReportTableQueryVariables>;
export const RunArtifactVersionsDocument = gql`
    query RunArtifactVersions($projectName: String!, $entityName: String!, $runName: String!, $pageSize: Int = 1000, $includeOutput: Boolean = false, $includeInput: Boolean = false) {
  project(name: $projectName, entityName: $entityName) {
    id
    run(name: $runName) {
      id
      outputArtifacts(first: $pageSize) @include(if: $includeOutput) {
        totalCount
        edges {
          node {
            artifactType {
              id
              name
              project {
                id
                name
                entity {
                  id
                  name
                }
              }
            }
            id
            digest
            commitHash
            versionIndex
            artifactSequence {
              id
              name
            }
            aliases {
              id
              artifactCollectionName
              alias
            }
            usedBy {
              totalCount
            }
            isLinkedToGlobalRegistry
          }
        }
      }
      inputArtifacts(first: $pageSize) @include(if: $includeInput) {
        totalCount
        edges {
          node {
            artifactType {
              id
              name
              project {
                id
                name
                entity {
                  id
                  name
                }
              }
            }
            id
            digest
            commitHash
            versionIndex
            artifactSequence {
              id
              name
              project {
                id
                name
                entity {
                  id
                  name
                }
              }
            }
            aliases {
              id
              artifactCollectionName
              alias
            }
            usedBy(first: 0) {
              totalCount
            }
          }
          usedAs
        }
      }
    }
  }
}
    `;
export type RunArtifactVersionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunArtifactVersionsQuery, RunArtifactVersionsQueryVariables>, 'query'> & ({ variables: RunArtifactVersionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunArtifactVersionsComponent = (props: RunArtifactVersionsComponentProps) => (
      <ApolloReactComponents.Query<RunArtifactVersionsQuery, RunArtifactVersionsQueryVariables> query={RunArtifactVersionsDocument} {...props} />
    );
    
export type RunArtifactVersionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunArtifactVersionsQuery, RunArtifactVersionsQueryVariables>
    } & TChildProps;
export function withRunArtifactVersions<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunArtifactVersionsQuery,
  RunArtifactVersionsQueryVariables,
  RunArtifactVersionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunArtifactVersionsQuery, RunArtifactVersionsQueryVariables, RunArtifactVersionsProps<TChildProps, TDataName>>(RunArtifactVersionsDocument, {
      alias: 'runArtifactVersions',
      ...operationOptions
    });
};

/**
 * __useRunArtifactVersionsQuery__
 *
 * To run a query within a React component, call `useRunArtifactVersionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunArtifactVersionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunArtifactVersionsQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runName: // value for 'runName'
 *      pageSize: // value for 'pageSize'
 *      includeOutput: // value for 'includeOutput'
 *      includeInput: // value for 'includeInput'
 *   },
 * });
 */
export function useRunArtifactVersionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunArtifactVersionsQuery, RunArtifactVersionsQueryVariables>) {
        return ApolloReactHooks.useQuery<RunArtifactVersionsQuery, RunArtifactVersionsQueryVariables>(RunArtifactVersionsDocument, baseOptions);
      }
export function useRunArtifactVersionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunArtifactVersionsQuery, RunArtifactVersionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunArtifactVersionsQuery, RunArtifactVersionsQueryVariables>(RunArtifactVersionsDocument, baseOptions);
        }
export type RunArtifactVersionsQueryHookResult = ReturnType<typeof useRunArtifactVersionsQuery>;
export type RunArtifactVersionsLazyQueryHookResult = ReturnType<typeof useRunArtifactVersionsLazyQuery>;
export type RunArtifactVersionsQueryResult = ApolloReactCommon.QueryResult<RunArtifactVersionsQuery, RunArtifactVersionsQueryVariables>;
export const RunsOutputArtifactsDocument = gql`
    query RunsOutputArtifacts($projectName: String!, $entityName: String!, $runNames: [String!]!, $filters: JSONString, $filenames: [String!]!) {
  project(name: $projectName, entityName: $entityName) {
    id
    runs(names: $runNames) {
      edges {
        node {
          id
          name
          outputArtifacts(filters: $filters) {
            edges {
              node {
                id
                artifactType {
                  id
                  name
                }
                files(names: $filenames) {
                  edges {
                    node {
                      id
                      name
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type RunsOutputArtifactsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunsOutputArtifactsQuery, RunsOutputArtifactsQueryVariables>, 'query'> & ({ variables: RunsOutputArtifactsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunsOutputArtifactsComponent = (props: RunsOutputArtifactsComponentProps) => (
      <ApolloReactComponents.Query<RunsOutputArtifactsQuery, RunsOutputArtifactsQueryVariables> query={RunsOutputArtifactsDocument} {...props} />
    );
    
export type RunsOutputArtifactsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunsOutputArtifactsQuery, RunsOutputArtifactsQueryVariables>
    } & TChildProps;
export function withRunsOutputArtifacts<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunsOutputArtifactsQuery,
  RunsOutputArtifactsQueryVariables,
  RunsOutputArtifactsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunsOutputArtifactsQuery, RunsOutputArtifactsQueryVariables, RunsOutputArtifactsProps<TChildProps, TDataName>>(RunsOutputArtifactsDocument, {
      alias: 'runsOutputArtifacts',
      ...operationOptions
    });
};

/**
 * __useRunsOutputArtifactsQuery__
 *
 * To run a query within a React component, call `useRunsOutputArtifactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunsOutputArtifactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunsOutputArtifactsQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runNames: // value for 'runNames'
 *      filters: // value for 'filters'
 *      filenames: // value for 'filenames'
 *   },
 * });
 */
export function useRunsOutputArtifactsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunsOutputArtifactsQuery, RunsOutputArtifactsQueryVariables>) {
        return ApolloReactHooks.useQuery<RunsOutputArtifactsQuery, RunsOutputArtifactsQueryVariables>(RunsOutputArtifactsDocument, baseOptions);
      }
export function useRunsOutputArtifactsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunsOutputArtifactsQuery, RunsOutputArtifactsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunsOutputArtifactsQuery, RunsOutputArtifactsQueryVariables>(RunsOutputArtifactsDocument, baseOptions);
        }
export type RunsOutputArtifactsQueryHookResult = ReturnType<typeof useRunsOutputArtifactsQuery>;
export type RunsOutputArtifactsLazyQueryHookResult = ReturnType<typeof useRunsOutputArtifactsLazyQuery>;
export type RunsOutputArtifactsQueryResult = ApolloReactCommon.QueryResult<RunsOutputArtifactsQuery, RunsOutputArtifactsQueryVariables>;
export const RunFilesDocument = gql`
    query RunFiles($projectName: String!, $entityName: String, $runName: String!, $maxNumFiles: Int!) {
  project(name: $projectName, entityName: $entityName) {
    id
    name
    run(name: $runName) {
      id
      name
      files(first: $maxNumFiles) {
        edges {
          node {
            id
            name
            url(upload: false)
            sizeBytes
            updatedAt
            artifact {
              id
              digest
            }
          }
        }
      }
    }
  }
}
    `;
export type RunFilesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunFilesQuery, RunFilesQueryVariables>, 'query'> & ({ variables: RunFilesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunFilesComponent = (props: RunFilesComponentProps) => (
      <ApolloReactComponents.Query<RunFilesQuery, RunFilesQueryVariables> query={RunFilesDocument} {...props} />
    );
    
export type RunFilesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunFilesQuery, RunFilesQueryVariables>
    } & TChildProps;
export function withRunFiles<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunFilesQuery,
  RunFilesQueryVariables,
  RunFilesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunFilesQuery, RunFilesQueryVariables, RunFilesProps<TChildProps, TDataName>>(RunFilesDocument, {
      alias: 'runFiles',
      ...operationOptions
    });
};

/**
 * __useRunFilesQuery__
 *
 * To run a query within a React component, call `useRunFilesQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunFilesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunFilesQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runName: // value for 'runName'
 *      maxNumFiles: // value for 'maxNumFiles'
 *   },
 * });
 */
export function useRunFilesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunFilesQuery, RunFilesQueryVariables>) {
        return ApolloReactHooks.useQuery<RunFilesQuery, RunFilesQueryVariables>(RunFilesDocument, baseOptions);
      }
export function useRunFilesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunFilesQuery, RunFilesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunFilesQuery, RunFilesQueryVariables>(RunFilesDocument, baseOptions);
        }
export type RunFilesQueryHookResult = ReturnType<typeof useRunFilesQuery>;
export type RunFilesLazyQueryHookResult = ReturnType<typeof useRunFilesLazyQuery>;
export type RunFilesQueryResult = ApolloReactCommon.QueryResult<RunFilesQuery, RunFilesQueryVariables>;
export const RunFilesAndFoldersDocument = gql`
    query RunFilesAndFolders($projectName: String!, $entityName: String, $runName: String!, $maxNumFiles: Int!, $folderPath: String) {
  project(name: $projectName, entityName: $entityName) {
    id
    name
    run(name: $runName) {
      id
      name
      projectId
      files(first: $maxNumFiles, folderPath: $folderPath) {
        edges {
          node {
            id
            name
            url(upload: false)
            sizeBytes
            updatedAt
            artifact {
              id
              digest
            }
          }
        }
      }
      folders(folderPath: $folderPath) {
        folderList {
          name
          size
          numberOfFiles
        }
      }
    }
  }
}
    `;
export type RunFilesAndFoldersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunFilesAndFoldersQuery, RunFilesAndFoldersQueryVariables>, 'query'> & ({ variables: RunFilesAndFoldersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunFilesAndFoldersComponent = (props: RunFilesAndFoldersComponentProps) => (
      <ApolloReactComponents.Query<RunFilesAndFoldersQuery, RunFilesAndFoldersQueryVariables> query={RunFilesAndFoldersDocument} {...props} />
    );
    
export type RunFilesAndFoldersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunFilesAndFoldersQuery, RunFilesAndFoldersQueryVariables>
    } & TChildProps;
export function withRunFilesAndFolders<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunFilesAndFoldersQuery,
  RunFilesAndFoldersQueryVariables,
  RunFilesAndFoldersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunFilesAndFoldersQuery, RunFilesAndFoldersQueryVariables, RunFilesAndFoldersProps<TChildProps, TDataName>>(RunFilesAndFoldersDocument, {
      alias: 'runFilesAndFolders',
      ...operationOptions
    });
};

/**
 * __useRunFilesAndFoldersQuery__
 *
 * To run a query within a React component, call `useRunFilesAndFoldersQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunFilesAndFoldersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunFilesAndFoldersQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runName: // value for 'runName'
 *      maxNumFiles: // value for 'maxNumFiles'
 *      folderPath: // value for 'folderPath'
 *   },
 * });
 */
export function useRunFilesAndFoldersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunFilesAndFoldersQuery, RunFilesAndFoldersQueryVariables>) {
        return ApolloReactHooks.useQuery<RunFilesAndFoldersQuery, RunFilesAndFoldersQueryVariables>(RunFilesAndFoldersDocument, baseOptions);
      }
export function useRunFilesAndFoldersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunFilesAndFoldersQuery, RunFilesAndFoldersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunFilesAndFoldersQuery, RunFilesAndFoldersQueryVariables>(RunFilesAndFoldersDocument, baseOptions);
        }
export type RunFilesAndFoldersQueryHookResult = ReturnType<typeof useRunFilesAndFoldersQuery>;
export type RunFilesAndFoldersLazyQueryHookResult = ReturnType<typeof useRunFilesAndFoldersLazyQuery>;
export type RunFilesAndFoldersQueryResult = ApolloReactCommon.QueryResult<RunFilesAndFoldersQuery, RunFilesAndFoldersQueryVariables>;
export const RunHistoryQueryDocument = gql`
    query RunHistoryQuery($entityName: String!, $projectName: String!, $runName: String!, $sampledHistorySpecs: [JSONString!]!) {
  project(entityName: $entityName, name: $projectName) {
    id
    run(name: $runName) {
      id
      sampledHistory(specs: $sampledHistorySpecs, packVersion: 1)
    }
  }
}
    `;
export type RunHistoryQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunHistoryQueryQuery, RunHistoryQueryQueryVariables>, 'query'> & ({ variables: RunHistoryQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunHistoryQueryComponent = (props: RunHistoryQueryComponentProps) => (
      <ApolloReactComponents.Query<RunHistoryQueryQuery, RunHistoryQueryQueryVariables> query={RunHistoryQueryDocument} {...props} />
    );
    
export type RunHistoryQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunHistoryQueryQuery, RunHistoryQueryQueryVariables>
    } & TChildProps;
export function withRunHistoryQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunHistoryQueryQuery,
  RunHistoryQueryQueryVariables,
  RunHistoryQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunHistoryQueryQuery, RunHistoryQueryQueryVariables, RunHistoryQueryProps<TChildProps, TDataName>>(RunHistoryQueryDocument, {
      alias: 'runHistoryQuery',
      ...operationOptions
    });
};

/**
 * __useRunHistoryQueryQuery__
 *
 * To run a query within a React component, call `useRunHistoryQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunHistoryQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunHistoryQueryQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      runName: // value for 'runName'
 *      sampledHistorySpecs: // value for 'sampledHistorySpecs'
 *   },
 * });
 */
export function useRunHistoryQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunHistoryQueryQuery, RunHistoryQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<RunHistoryQueryQuery, RunHistoryQueryQueryVariables>(RunHistoryQueryDocument, baseOptions);
      }
export function useRunHistoryQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunHistoryQueryQuery, RunHistoryQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunHistoryQueryQuery, RunHistoryQueryQueryVariables>(RunHistoryQueryDocument, baseOptions);
        }
export type RunHistoryQueryQueryHookResult = ReturnType<typeof useRunHistoryQueryQuery>;
export type RunHistoryQueryLazyQueryHookResult = ReturnType<typeof useRunHistoryQueryLazyQuery>;
export type RunHistoryQueryQueryResult = ApolloReactCommon.QueryResult<RunHistoryQueryQuery, RunHistoryQueryQueryVariables>;
export const RunInfoAndConfigDocument = gql`
    query RunInfoAndConfig($projectName: String!, $entityName: String, $runName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    run(name: $runName) {
      id
      runInfo {
        program
        args
        os
        python
        colab
        executable
        codeSaved
        cpuCount
        cpuCountLogical
        gpuCount
        gpu
        git {
          remote
          commit
        }
      }
      config
    }
  }
}
    `;
export type RunInfoAndConfigComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunInfoAndConfigQuery, RunInfoAndConfigQueryVariables>, 'query'> & ({ variables: RunInfoAndConfigQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunInfoAndConfigComponent = (props: RunInfoAndConfigComponentProps) => (
      <ApolloReactComponents.Query<RunInfoAndConfigQuery, RunInfoAndConfigQueryVariables> query={RunInfoAndConfigDocument} {...props} />
    );
    
export type RunInfoAndConfigProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunInfoAndConfigQuery, RunInfoAndConfigQueryVariables>
    } & TChildProps;
export function withRunInfoAndConfig<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunInfoAndConfigQuery,
  RunInfoAndConfigQueryVariables,
  RunInfoAndConfigProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunInfoAndConfigQuery, RunInfoAndConfigQueryVariables, RunInfoAndConfigProps<TChildProps, TDataName>>(RunInfoAndConfigDocument, {
      alias: 'runInfoAndConfig',
      ...operationOptions
    });
};

/**
 * __useRunInfoAndConfigQuery__
 *
 * To run a query within a React component, call `useRunInfoAndConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunInfoAndConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunInfoAndConfigQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runName: // value for 'runName'
 *   },
 * });
 */
export function useRunInfoAndConfigQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunInfoAndConfigQuery, RunInfoAndConfigQueryVariables>) {
        return ApolloReactHooks.useQuery<RunInfoAndConfigQuery, RunInfoAndConfigQueryVariables>(RunInfoAndConfigDocument, baseOptions);
      }
export function useRunInfoAndConfigLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunInfoAndConfigQuery, RunInfoAndConfigQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunInfoAndConfigQuery, RunInfoAndConfigQueryVariables>(RunInfoAndConfigDocument, baseOptions);
        }
export type RunInfoAndConfigQueryHookResult = ReturnType<typeof useRunInfoAndConfigQuery>;
export type RunInfoAndConfigLazyQueryHookResult = ReturnType<typeof useRunInfoAndConfigLazyQuery>;
export type RunInfoAndConfigQueryResult = ApolloReactCommon.QueryResult<RunInfoAndConfigQuery, RunInfoAndConfigQueryVariables>;
export const RunInfoDocument = gql`
    query RunInfo($projectName: String!, $entityName: String, $runName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    run(name: $runName) {
      id
      runInfo {
        program
        args
        os
        python
        colab
        executable
        codeSaved
        cpuCount
        cpuCountLogical
        gpuCount
        gpu
        git {
          remote
          commit
        }
      }
    }
  }
}
    `;
export type RunInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunInfoQuery, RunInfoQueryVariables>, 'query'> & ({ variables: RunInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunInfoComponent = (props: RunInfoComponentProps) => (
      <ApolloReactComponents.Query<RunInfoQuery, RunInfoQueryVariables> query={RunInfoDocument} {...props} />
    );
    
export type RunInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunInfoQuery, RunInfoQueryVariables>
    } & TChildProps;
export function withRunInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunInfoQuery,
  RunInfoQueryVariables,
  RunInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunInfoQuery, RunInfoQueryVariables, RunInfoProps<TChildProps, TDataName>>(RunInfoDocument, {
      alias: 'runInfo',
      ...operationOptions
    });
};

/**
 * __useRunInfoQuery__
 *
 * To run a query within a React component, call `useRunInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunInfoQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runName: // value for 'runName'
 *   },
 * });
 */
export function useRunInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunInfoQuery, RunInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<RunInfoQuery, RunInfoQueryVariables>(RunInfoDocument, baseOptions);
      }
export function useRunInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunInfoQuery, RunInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunInfoQuery, RunInfoQueryVariables>(RunInfoDocument, baseOptions);
        }
export type RunInfoQueryHookResult = ReturnType<typeof useRunInfoQuery>;
export type RunInfoLazyQueryHookResult = ReturnType<typeof useRunInfoLazyQuery>;
export type RunInfoQueryResult = ApolloReactCommon.QueryResult<RunInfoQuery, RunInfoQueryVariables>;
export const RunKeysDocument = gql`
    query RunKeys($entityName: String!, $projectName: String!, $types: [String!] = ["number", "string", "boolean", "other"], $limit: Int = 1000) {
  project(name: $projectName, entityName: $entityName) {
    id
    name
    fields(
      types: $types
      columns: ["config", "summary_metrics", "system_metrics"]
      first: $limit
    ) {
      edges {
        node {
          path
        }
      }
    }
  }
}
    `;
export type RunKeysComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunKeysQuery, RunKeysQueryVariables>, 'query'> & ({ variables: RunKeysQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunKeysComponent = (props: RunKeysComponentProps) => (
      <ApolloReactComponents.Query<RunKeysQuery, RunKeysQueryVariables> query={RunKeysDocument} {...props} />
    );
    
export type RunKeysProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunKeysQuery, RunKeysQueryVariables>
    } & TChildProps;
export function withRunKeys<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunKeysQuery,
  RunKeysQueryVariables,
  RunKeysProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunKeysQuery, RunKeysQueryVariables, RunKeysProps<TChildProps, TDataName>>(RunKeysDocument, {
      alias: 'runKeys',
      ...operationOptions
    });
};

/**
 * __useRunKeysQuery__
 *
 * To run a query within a React component, call `useRunKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunKeysQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      types: // value for 'types'
 *      limit: // value for 'limit'
 *   },
 * });
 */
export function useRunKeysQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunKeysQuery, RunKeysQueryVariables>) {
        return ApolloReactHooks.useQuery<RunKeysQuery, RunKeysQueryVariables>(RunKeysDocument, baseOptions);
      }
export function useRunKeysLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunKeysQuery, RunKeysQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunKeysQuery, RunKeysQueryVariables>(RunKeysDocument, baseOptions);
        }
export type RunKeysQueryHookResult = ReturnType<typeof useRunKeysQuery>;
export type RunKeysLazyQueryHookResult = ReturnType<typeof useRunKeysLazyQuery>;
export type RunKeysQueryResult = ApolloReactCommon.QueryResult<RunKeysQuery, RunKeysQueryVariables>;
export const RunSelectorWithIdDocument = gql`
    query RunSelectorWithID($internalId: ID!) {
  project(internalId: $internalId) {
    id
    runCount
    fields(
      types: ["number", "string", "boolean", "other"]
      columns: ["config", "summary_metrics", "system_metrics", "aggregations_min", "aggregations_max"]
      first: 2000
    ) {
      edges {
        node {
          path
        }
      }
    }
    entity {
      id
      settings {
        storageBucketInfo {
          ID
        }
      }
    }
  }
}
    `;
export type RunSelectorWithIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunSelectorWithIdQuery, RunSelectorWithIdQueryVariables>, 'query'> & ({ variables: RunSelectorWithIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunSelectorWithIdComponent = (props: RunSelectorWithIdComponentProps) => (
      <ApolloReactComponents.Query<RunSelectorWithIdQuery, RunSelectorWithIdQueryVariables> query={RunSelectorWithIdDocument} {...props} />
    );
    
export type RunSelectorWithIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunSelectorWithIdQuery, RunSelectorWithIdQueryVariables>
    } & TChildProps;
export function withRunSelectorWithId<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunSelectorWithIdQuery,
  RunSelectorWithIdQueryVariables,
  RunSelectorWithIdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunSelectorWithIdQuery, RunSelectorWithIdQueryVariables, RunSelectorWithIdProps<TChildProps, TDataName>>(RunSelectorWithIdDocument, {
      alias: 'runSelectorWithId',
      ...operationOptions
    });
};

/**
 * __useRunSelectorWithIdQuery__
 *
 * To run a query within a React component, call `useRunSelectorWithIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunSelectorWithIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunSelectorWithIdQuery({
 *   variables: {
 *      internalId: // value for 'internalId'
 *   },
 * });
 */
export function useRunSelectorWithIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunSelectorWithIdQuery, RunSelectorWithIdQueryVariables>) {
        return ApolloReactHooks.useQuery<RunSelectorWithIdQuery, RunSelectorWithIdQueryVariables>(RunSelectorWithIdDocument, baseOptions);
      }
export function useRunSelectorWithIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunSelectorWithIdQuery, RunSelectorWithIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunSelectorWithIdQuery, RunSelectorWithIdQueryVariables>(RunSelectorWithIdDocument, baseOptions);
        }
export type RunSelectorWithIdQueryHookResult = ReturnType<typeof useRunSelectorWithIdQuery>;
export type RunSelectorWithIdLazyQueryHookResult = ReturnType<typeof useRunSelectorWithIdLazyQuery>;
export type RunSelectorWithIdQueryResult = ApolloReactCommon.QueryResult<RunSelectorWithIdQuery, RunSelectorWithIdQueryVariables>;
export const RunSelectorDocument = gql`
    query RunSelector($name: String!, $entityName: String) {
  project(name: $name, entityName: $entityName) {
    id
    runCount
    fields(
      types: ["number", "string", "boolean", "other"]
      columns: ["config", "summary_metrics", "system_metrics", "aggregations_min", "aggregations_max"]
      first: 2000
    ) {
      edges {
        node {
          path
        }
      }
    }
    entity {
      id
      settings {
        storageBucketInfo {
          ID
        }
      }
    }
  }
}
    `;
export type RunSelectorComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunSelectorQuery, RunSelectorQueryVariables>, 'query'> & ({ variables: RunSelectorQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunSelectorComponent = (props: RunSelectorComponentProps) => (
      <ApolloReactComponents.Query<RunSelectorQuery, RunSelectorQueryVariables> query={RunSelectorDocument} {...props} />
    );
    
export type RunSelectorProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunSelectorQuery, RunSelectorQueryVariables>
    } & TChildProps;
export function withRunSelector<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunSelectorQuery,
  RunSelectorQueryVariables,
  RunSelectorProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunSelectorQuery, RunSelectorQueryVariables, RunSelectorProps<TChildProps, TDataName>>(RunSelectorDocument, {
      alias: 'runSelector',
      ...operationOptions
    });
};

/**
 * __useRunSelectorQuery__
 *
 * To run a query within a React component, call `useRunSelectorQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunSelectorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunSelectorQuery({
 *   variables: {
 *      name: // value for 'name'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useRunSelectorQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunSelectorQuery, RunSelectorQueryVariables>) {
        return ApolloReactHooks.useQuery<RunSelectorQuery, RunSelectorQueryVariables>(RunSelectorDocument, baseOptions);
      }
export function useRunSelectorLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunSelectorQuery, RunSelectorQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunSelectorQuery, RunSelectorQueryVariables>(RunSelectorDocument, baseOptions);
        }
export type RunSelectorQueryHookResult = ReturnType<typeof useRunSelectorQuery>;
export type RunSelectorLazyQueryHookResult = ReturnType<typeof useRunSelectorLazyQuery>;
export type RunSelectorQueryResult = ApolloReactCommon.QueryResult<RunSelectorQuery, RunSelectorQueryVariables>;
export const RunSelectorRunsWithIdDocument = gql`
    query RunSelectorRunsWithID($aggregationKeys: [String!], $configKeys: [String!], $currentRuns: [String!]!, $enableAggregations: Boolean = false, $enableArtifactCounts: Boolean = false, $enableConfig: Boolean = false, $enableSummary: Boolean = false, $enableTags: Boolean = false, $filters: JSONString!, $groupKeys: [String!]!, $groupLevel: Int!, $lastUpdated: DateTime!, $limit: Int!, $order: String!, $summaryKeys: [String!], $wandbKeys: [String!], $internalId: ID!) {
  project(internalId: $internalId) {
    ...RunsSelectorRunsProjectFragment
  }
}
    ${RunsSelectorRunsProjectFragmentFragmentDoc}`;
export type RunSelectorRunsWithIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunSelectorRunsWithIdQuery, RunSelectorRunsWithIdQueryVariables>, 'query'> & ({ variables: RunSelectorRunsWithIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunSelectorRunsWithIdComponent = (props: RunSelectorRunsWithIdComponentProps) => (
      <ApolloReactComponents.Query<RunSelectorRunsWithIdQuery, RunSelectorRunsWithIdQueryVariables> query={RunSelectorRunsWithIdDocument} {...props} />
    );
    
export type RunSelectorRunsWithIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunSelectorRunsWithIdQuery, RunSelectorRunsWithIdQueryVariables>
    } & TChildProps;
export function withRunSelectorRunsWithId<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunSelectorRunsWithIdQuery,
  RunSelectorRunsWithIdQueryVariables,
  RunSelectorRunsWithIdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunSelectorRunsWithIdQuery, RunSelectorRunsWithIdQueryVariables, RunSelectorRunsWithIdProps<TChildProps, TDataName>>(RunSelectorRunsWithIdDocument, {
      alias: 'runSelectorRunsWithId',
      ...operationOptions
    });
};

/**
 * __useRunSelectorRunsWithIdQuery__
 *
 * To run a query within a React component, call `useRunSelectorRunsWithIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunSelectorRunsWithIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunSelectorRunsWithIdQuery({
 *   variables: {
 *      aggregationKeys: // value for 'aggregationKeys'
 *      configKeys: // value for 'configKeys'
 *      currentRuns: // value for 'currentRuns'
 *      enableAggregations: // value for 'enableAggregations'
 *      enableArtifactCounts: // value for 'enableArtifactCounts'
 *      enableConfig: // value for 'enableConfig'
 *      enableSummary: // value for 'enableSummary'
 *      enableTags: // value for 'enableTags'
 *      filters: // value for 'filters'
 *      groupKeys: // value for 'groupKeys'
 *      groupLevel: // value for 'groupLevel'
 *      lastUpdated: // value for 'lastUpdated'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      summaryKeys: // value for 'summaryKeys'
 *      wandbKeys: // value for 'wandbKeys'
 *      internalId: // value for 'internalId'
 *   },
 * });
 */
export function useRunSelectorRunsWithIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunSelectorRunsWithIdQuery, RunSelectorRunsWithIdQueryVariables>) {
        return ApolloReactHooks.useQuery<RunSelectorRunsWithIdQuery, RunSelectorRunsWithIdQueryVariables>(RunSelectorRunsWithIdDocument, baseOptions);
      }
export function useRunSelectorRunsWithIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunSelectorRunsWithIdQuery, RunSelectorRunsWithIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunSelectorRunsWithIdQuery, RunSelectorRunsWithIdQueryVariables>(RunSelectorRunsWithIdDocument, baseOptions);
        }
export type RunSelectorRunsWithIdQueryHookResult = ReturnType<typeof useRunSelectorRunsWithIdQuery>;
export type RunSelectorRunsWithIdLazyQueryHookResult = ReturnType<typeof useRunSelectorRunsWithIdLazyQuery>;
export type RunSelectorRunsWithIdQueryResult = ApolloReactCommon.QueryResult<RunSelectorRunsWithIdQuery, RunSelectorRunsWithIdQueryVariables>;
export const RunSelectorRunsDocument = gql`
    query RunSelectorRuns($aggregationKeys: [String!], $configKeys: [String!], $currentRuns: [String!]!, $enableAggregations: Boolean = false, $enableArtifactCounts: Boolean = false, $enableConfig: Boolean = false, $enableSummary: Boolean = false, $enableTags: Boolean = false, $filters: JSONString!, $groupKeys: [String!]!, $groupLevel: Int!, $lastUpdated: DateTime!, $limit: Int!, $order: String!, $summaryKeys: [String!], $wandbKeys: [String!], $entityName: String!, $projectName: String!) {
  project(name: $projectName, entityName: $entityName) {
    ...RunsSelectorRunsProjectFragment
  }
}
    ${RunsSelectorRunsProjectFragmentFragmentDoc}`;
export type RunSelectorRunsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunSelectorRunsQuery, RunSelectorRunsQueryVariables>, 'query'> & ({ variables: RunSelectorRunsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunSelectorRunsComponent = (props: RunSelectorRunsComponentProps) => (
      <ApolloReactComponents.Query<RunSelectorRunsQuery, RunSelectorRunsQueryVariables> query={RunSelectorRunsDocument} {...props} />
    );
    
export type RunSelectorRunsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunSelectorRunsQuery, RunSelectorRunsQueryVariables>
    } & TChildProps;
export function withRunSelectorRuns<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunSelectorRunsQuery,
  RunSelectorRunsQueryVariables,
  RunSelectorRunsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunSelectorRunsQuery, RunSelectorRunsQueryVariables, RunSelectorRunsProps<TChildProps, TDataName>>(RunSelectorRunsDocument, {
      alias: 'runSelectorRuns',
      ...operationOptions
    });
};

/**
 * __useRunSelectorRunsQuery__
 *
 * To run a query within a React component, call `useRunSelectorRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunSelectorRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunSelectorRunsQuery({
 *   variables: {
 *      aggregationKeys: // value for 'aggregationKeys'
 *      configKeys: // value for 'configKeys'
 *      currentRuns: // value for 'currentRuns'
 *      enableAggregations: // value for 'enableAggregations'
 *      enableArtifactCounts: // value for 'enableArtifactCounts'
 *      enableConfig: // value for 'enableConfig'
 *      enableSummary: // value for 'enableSummary'
 *      enableTags: // value for 'enableTags'
 *      filters: // value for 'filters'
 *      groupKeys: // value for 'groupKeys'
 *      groupLevel: // value for 'groupLevel'
 *      lastUpdated: // value for 'lastUpdated'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      summaryKeys: // value for 'summaryKeys'
 *      wandbKeys: // value for 'wandbKeys'
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *   },
 * });
 */
export function useRunSelectorRunsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunSelectorRunsQuery, RunSelectorRunsQueryVariables>) {
        return ApolloReactHooks.useQuery<RunSelectorRunsQuery, RunSelectorRunsQueryVariables>(RunSelectorRunsDocument, baseOptions);
      }
export function useRunSelectorRunsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunSelectorRunsQuery, RunSelectorRunsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunSelectorRunsQuery, RunSelectorRunsQueryVariables>(RunSelectorRunsDocument, baseOptions);
        }
export type RunSelectorRunsQueryHookResult = ReturnType<typeof useRunSelectorRunsQuery>;
export type RunSelectorRunsLazyQueryHookResult = ReturnType<typeof useRunSelectorRunsLazyQuery>;
export type RunSelectorRunsQueryResult = ApolloReactCommon.QueryResult<RunSelectorRunsQuery, RunSelectorRunsQueryVariables>;
export const RunSelectorCsvExportRunsDocument = gql`
    query RunSelectorCsvExportRuns($aggregationKeys: [String!], $configKeys: [String!], $enableAggregations: Boolean = false, $enableArtifactCounts: Boolean = false, $enableConfig: Boolean = false, $enableSummary: Boolean = false, $enableTags: Boolean = false, $entityName: String!, $filters: JSONString!, $groupKeys: [String!]!, $groupLevel: Int!, $limit: Int!, $order: String!, $projectName: String!, $summaryKeys: [String!], $wandbKeys: [String!]) {
  project(name: $projectName, entityName: $entityName) {
    id
    runs(
      first: $limit
      order: $order
      filters: $filters
      groupKeys: $groupKeys
      groupLevel: $groupLevel
    ) {
      edges {
        node {
          id
          aggregations(keys: $aggregationKeys) @include(if: $enableAggregations)
          commit
          config(keys: $configKeys) @include(if: $enableConfig)
          createdAt
          displayName
          framework
          github
          group
          groupCounts
          heartbeatAt
          host
          jobType
          logLineCount
          name
          notes
          pendingUpdates
          projectId
          readOnly
          runInfo {
            gpu
            gpuCount
          }
          shouldStop
          state
          stopped
          summaryMetrics(keys: $summaryKeys) @include(if: $enableSummary)
          sweep {
            id
            name
            displayName
          }
          tags @include(if: $enableTags)
          updatedAt
          user {
            id
            username
          }
          wandbConfig(keys: $wandbKeys)
          ...ArtifactCountsFragment @include(if: $enableArtifactCounts)
        }
      }
    }
  }
}
    ${ArtifactCountsFragmentFragmentDoc}`;
export type RunSelectorCsvExportRunsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunSelectorCsvExportRunsQuery, RunSelectorCsvExportRunsQueryVariables>, 'query'> & ({ variables: RunSelectorCsvExportRunsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunSelectorCsvExportRunsComponent = (props: RunSelectorCsvExportRunsComponentProps) => (
      <ApolloReactComponents.Query<RunSelectorCsvExportRunsQuery, RunSelectorCsvExportRunsQueryVariables> query={RunSelectorCsvExportRunsDocument} {...props} />
    );
    
export type RunSelectorCsvExportRunsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunSelectorCsvExportRunsQuery, RunSelectorCsvExportRunsQueryVariables>
    } & TChildProps;
export function withRunSelectorCsvExportRuns<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunSelectorCsvExportRunsQuery,
  RunSelectorCsvExportRunsQueryVariables,
  RunSelectorCsvExportRunsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunSelectorCsvExportRunsQuery, RunSelectorCsvExportRunsQueryVariables, RunSelectorCsvExportRunsProps<TChildProps, TDataName>>(RunSelectorCsvExportRunsDocument, {
      alias: 'runSelectorCsvExportRuns',
      ...operationOptions
    });
};

/**
 * __useRunSelectorCsvExportRunsQuery__
 *
 * To run a query within a React component, call `useRunSelectorCsvExportRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunSelectorCsvExportRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunSelectorCsvExportRunsQuery({
 *   variables: {
 *      aggregationKeys: // value for 'aggregationKeys'
 *      configKeys: // value for 'configKeys'
 *      enableAggregations: // value for 'enableAggregations'
 *      enableArtifactCounts: // value for 'enableArtifactCounts'
 *      enableConfig: // value for 'enableConfig'
 *      enableSummary: // value for 'enableSummary'
 *      enableTags: // value for 'enableTags'
 *      entityName: // value for 'entityName'
 *      filters: // value for 'filters'
 *      groupKeys: // value for 'groupKeys'
 *      groupLevel: // value for 'groupLevel'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      projectName: // value for 'projectName'
 *      summaryKeys: // value for 'summaryKeys'
 *      wandbKeys: // value for 'wandbKeys'
 *   },
 * });
 */
export function useRunSelectorCsvExportRunsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunSelectorCsvExportRunsQuery, RunSelectorCsvExportRunsQueryVariables>) {
        return ApolloReactHooks.useQuery<RunSelectorCsvExportRunsQuery, RunSelectorCsvExportRunsQueryVariables>(RunSelectorCsvExportRunsDocument, baseOptions);
      }
export function useRunSelectorCsvExportRunsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunSelectorCsvExportRunsQuery, RunSelectorCsvExportRunsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunSelectorCsvExportRunsQuery, RunSelectorCsvExportRunsQueryVariables>(RunSelectorCsvExportRunsDocument, baseOptions);
        }
export type RunSelectorCsvExportRunsQueryHookResult = ReturnType<typeof useRunSelectorCsvExportRunsQuery>;
export type RunSelectorCsvExportRunsLazyQueryHookResult = ReturnType<typeof useRunSelectorCsvExportRunsLazyQuery>;
export type RunSelectorCsvExportRunsQueryResult = ApolloReactCommon.QueryResult<RunSelectorCsvExportRunsQuery, RunSelectorCsvExportRunsQueryVariables>;
export const RunSelectorAutoColsRunsDocument = gql`
    query RunSelectorAutoColsRuns($enableArtifactCounts: Boolean = false, $entityName: String!, $filters: JSONString!, $groupKeys: [String!]!, $groupLevel: Int!, $limit: Int!, $order: String!, $projectName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    runs(
      first: $limit
      order: $order
      filters: $filters
      groupKeys: $groupKeys
      groupLevel: $groupLevel
    ) {
      edges {
        node {
          id
          aggregations
          commit
          config
          createdAt
          displayName
          framework
          github
          group
          groupCounts
          heartbeatAt
          host
          jobType
          logLineCount
          name
          notes
          pendingUpdates
          projectId
          readOnly
          runInfo {
            gpu
            gpuCount
          }
          shouldStop
          state
          stopped
          summaryMetrics
          sweep {
            id
            name
            displayName
          }
          updatedAt
          user {
            id
            username
          }
          ...ArtifactCountsFragment @include(if: $enableArtifactCounts)
        }
      }
    }
  }
}
    ${ArtifactCountsFragmentFragmentDoc}`;
export type RunSelectorAutoColsRunsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunSelectorAutoColsRunsQuery, RunSelectorAutoColsRunsQueryVariables>, 'query'> & ({ variables: RunSelectorAutoColsRunsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunSelectorAutoColsRunsComponent = (props: RunSelectorAutoColsRunsComponentProps) => (
      <ApolloReactComponents.Query<RunSelectorAutoColsRunsQuery, RunSelectorAutoColsRunsQueryVariables> query={RunSelectorAutoColsRunsDocument} {...props} />
    );
    
export type RunSelectorAutoColsRunsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunSelectorAutoColsRunsQuery, RunSelectorAutoColsRunsQueryVariables>
    } & TChildProps;
export function withRunSelectorAutoColsRuns<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunSelectorAutoColsRunsQuery,
  RunSelectorAutoColsRunsQueryVariables,
  RunSelectorAutoColsRunsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunSelectorAutoColsRunsQuery, RunSelectorAutoColsRunsQueryVariables, RunSelectorAutoColsRunsProps<TChildProps, TDataName>>(RunSelectorAutoColsRunsDocument, {
      alias: 'runSelectorAutoColsRuns',
      ...operationOptions
    });
};

/**
 * __useRunSelectorAutoColsRunsQuery__
 *
 * To run a query within a React component, call `useRunSelectorAutoColsRunsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunSelectorAutoColsRunsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunSelectorAutoColsRunsQuery({
 *   variables: {
 *      enableArtifactCounts: // value for 'enableArtifactCounts'
 *      entityName: // value for 'entityName'
 *      filters: // value for 'filters'
 *      groupKeys: // value for 'groupKeys'
 *      groupLevel: // value for 'groupLevel'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      projectName: // value for 'projectName'
 *   },
 * });
 */
export function useRunSelectorAutoColsRunsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunSelectorAutoColsRunsQuery, RunSelectorAutoColsRunsQueryVariables>) {
        return ApolloReactHooks.useQuery<RunSelectorAutoColsRunsQuery, RunSelectorAutoColsRunsQueryVariables>(RunSelectorAutoColsRunsDocument, baseOptions);
      }
export function useRunSelectorAutoColsRunsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunSelectorAutoColsRunsQuery, RunSelectorAutoColsRunsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunSelectorAutoColsRunsQuery, RunSelectorAutoColsRunsQueryVariables>(RunSelectorAutoColsRunsDocument, baseOptions);
        }
export type RunSelectorAutoColsRunsQueryHookResult = ReturnType<typeof useRunSelectorAutoColsRunsQuery>;
export type RunSelectorAutoColsRunsLazyQueryHookResult = ReturnType<typeof useRunSelectorAutoColsRunsLazyQuery>;
export type RunSelectorAutoColsRunsQueryResult = ApolloReactCommon.QueryResult<RunSelectorAutoColsRunsQuery, RunSelectorAutoColsRunsQueryVariables>;
export const BulkEditorProjectTagsDocument = gql`
    query BulkEditorProjectTags($name: String!, $entityName: String, $tempFilters: JSONString) {
  project(name: $name, entityName: $entityName) {
    id
    tags {
      id
      name
      colorIndex
    }
    tagCounts(filters: $tempFilters) {
      name
      count
    }
  }
}
    `;
export type BulkEditorProjectTagsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<BulkEditorProjectTagsQuery, BulkEditorProjectTagsQueryVariables>, 'query'> & ({ variables: BulkEditorProjectTagsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const BulkEditorProjectTagsComponent = (props: BulkEditorProjectTagsComponentProps) => (
      <ApolloReactComponents.Query<BulkEditorProjectTagsQuery, BulkEditorProjectTagsQueryVariables> query={BulkEditorProjectTagsDocument} {...props} />
    );
    
export type BulkEditorProjectTagsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<BulkEditorProjectTagsQuery, BulkEditorProjectTagsQueryVariables>
    } & TChildProps;
export function withBulkEditorProjectTags<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  BulkEditorProjectTagsQuery,
  BulkEditorProjectTagsQueryVariables,
  BulkEditorProjectTagsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, BulkEditorProjectTagsQuery, BulkEditorProjectTagsQueryVariables, BulkEditorProjectTagsProps<TChildProps, TDataName>>(BulkEditorProjectTagsDocument, {
      alias: 'bulkEditorProjectTags',
      ...operationOptions
    });
};

/**
 * __useBulkEditorProjectTagsQuery__
 *
 * To run a query within a React component, call `useBulkEditorProjectTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBulkEditorProjectTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBulkEditorProjectTagsQuery({
 *   variables: {
 *      name: // value for 'name'
 *      entityName: // value for 'entityName'
 *      tempFilters: // value for 'tempFilters'
 *   },
 * });
 */
export function useBulkEditorProjectTagsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BulkEditorProjectTagsQuery, BulkEditorProjectTagsQueryVariables>) {
        return ApolloReactHooks.useQuery<BulkEditorProjectTagsQuery, BulkEditorProjectTagsQueryVariables>(BulkEditorProjectTagsDocument, baseOptions);
      }
export function useBulkEditorProjectTagsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BulkEditorProjectTagsQuery, BulkEditorProjectTagsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<BulkEditorProjectTagsQuery, BulkEditorProjectTagsQueryVariables>(BulkEditorProjectTagsDocument, baseOptions);
        }
export type BulkEditorProjectTagsQueryHookResult = ReturnType<typeof useBulkEditorProjectTagsQuery>;
export type BulkEditorProjectTagsLazyQueryHookResult = ReturnType<typeof useBulkEditorProjectTagsLazyQuery>;
export type BulkEditorProjectTagsQueryResult = ApolloReactCommon.QueryResult<BulkEditorProjectTagsQuery, BulkEditorProjectTagsQueryVariables>;
export const RunSetSummaryDocument = gql`
    query RunSetSummary($projectName: String!, $entityName: String!, $selections: JSONString!) {
  project(name: $projectName, entityName: $entityName) {
    id
    selectedCount: runCount(filters: $selections)
  }
}
    `;
export type RunSetSummaryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunSetSummaryQuery, RunSetSummaryQueryVariables>, 'query'> & ({ variables: RunSetSummaryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunSetSummaryComponent = (props: RunSetSummaryComponentProps) => (
      <ApolloReactComponents.Query<RunSetSummaryQuery, RunSetSummaryQueryVariables> query={RunSetSummaryDocument} {...props} />
    );
    
export type RunSetSummaryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunSetSummaryQuery, RunSetSummaryQueryVariables>
    } & TChildProps;
export function withRunSetSummary<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunSetSummaryQuery,
  RunSetSummaryQueryVariables,
  RunSetSummaryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunSetSummaryQuery, RunSetSummaryQueryVariables, RunSetSummaryProps<TChildProps, TDataName>>(RunSetSummaryDocument, {
      alias: 'runSetSummary',
      ...operationOptions
    });
};

/**
 * __useRunSetSummaryQuery__
 *
 * To run a query within a React component, call `useRunSetSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunSetSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunSetSummaryQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      selections: // value for 'selections'
 *   },
 * });
 */
export function useRunSetSummaryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunSetSummaryQuery, RunSetSummaryQueryVariables>) {
        return ApolloReactHooks.useQuery<RunSetSummaryQuery, RunSetSummaryQueryVariables>(RunSetSummaryDocument, baseOptions);
      }
export function useRunSetSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunSetSummaryQuery, RunSetSummaryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunSetSummaryQuery, RunSetSummaryQueryVariables>(RunSetSummaryDocument, baseOptions);
        }
export type RunSetSummaryQueryHookResult = ReturnType<typeof useRunSetSummaryQuery>;
export type RunSetSummaryLazyQueryHookResult = ReturnType<typeof useRunSetSummaryLazyQuery>;
export type RunSetSummaryQueryResult = ApolloReactCommon.QueryResult<RunSetSummaryQuery, RunSetSummaryQueryVariables>;
export const RunSetSummaryByIdDocument = gql`
    query RunSetSummaryByID($internalId: ID!, $selections: JSONString!) {
  project(internalId: $internalId) {
    id
    selectedCount: runCount(filters: $selections)
  }
}
    `;
export type RunSetSummaryByIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunSetSummaryByIdQuery, RunSetSummaryByIdQueryVariables>, 'query'> & ({ variables: RunSetSummaryByIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunSetSummaryByIdComponent = (props: RunSetSummaryByIdComponentProps) => (
      <ApolloReactComponents.Query<RunSetSummaryByIdQuery, RunSetSummaryByIdQueryVariables> query={RunSetSummaryByIdDocument} {...props} />
    );
    
export type RunSetSummaryByIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunSetSummaryByIdQuery, RunSetSummaryByIdQueryVariables>
    } & TChildProps;
export function withRunSetSummaryById<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunSetSummaryByIdQuery,
  RunSetSummaryByIdQueryVariables,
  RunSetSummaryByIdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunSetSummaryByIdQuery, RunSetSummaryByIdQueryVariables, RunSetSummaryByIdProps<TChildProps, TDataName>>(RunSetSummaryByIdDocument, {
      alias: 'runSetSummaryById',
      ...operationOptions
    });
};

/**
 * __useRunSetSummaryByIdQuery__
 *
 * To run a query within a React component, call `useRunSetSummaryByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunSetSummaryByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunSetSummaryByIdQuery({
 *   variables: {
 *      internalId: // value for 'internalId'
 *      selections: // value for 'selections'
 *   },
 * });
 */
export function useRunSetSummaryByIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunSetSummaryByIdQuery, RunSetSummaryByIdQueryVariables>) {
        return ApolloReactHooks.useQuery<RunSetSummaryByIdQuery, RunSetSummaryByIdQueryVariables>(RunSetSummaryByIdDocument, baseOptions);
      }
export function useRunSetSummaryByIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunSetSummaryByIdQuery, RunSetSummaryByIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunSetSummaryByIdQuery, RunSetSummaryByIdQueryVariables>(RunSetSummaryByIdDocument, baseOptions);
        }
export type RunSetSummaryByIdQueryHookResult = ReturnType<typeof useRunSetSummaryByIdQuery>;
export type RunSetSummaryByIdLazyQueryHookResult = ReturnType<typeof useRunSetSummaryByIdLazyQuery>;
export type RunSetSummaryByIdQueryResult = ApolloReactCommon.QueryResult<RunSetSummaryByIdQuery, RunSetSummaryByIdQueryVariables>;
export const RunValueSuggestionsDocument = gql`
    query RunValueSuggestions($projectName: String!, $entityName: String, $keyPath: String!, $filters: JSONString) {
  project(name: $projectName, entityName: $entityName) {
    id
    name
    runKeySuggestions(keyPath: $keyPath, filters: $filters) {
      value
      displayValue
      count
    }
  }
}
    `;
export type RunValueSuggestionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunValueSuggestionsQuery, RunValueSuggestionsQueryVariables>, 'query'> & ({ variables: RunValueSuggestionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunValueSuggestionsComponent = (props: RunValueSuggestionsComponentProps) => (
      <ApolloReactComponents.Query<RunValueSuggestionsQuery, RunValueSuggestionsQueryVariables> query={RunValueSuggestionsDocument} {...props} />
    );
    
export type RunValueSuggestionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunValueSuggestionsQuery, RunValueSuggestionsQueryVariables>
    } & TChildProps;
export function withRunValueSuggestions<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunValueSuggestionsQuery,
  RunValueSuggestionsQueryVariables,
  RunValueSuggestionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunValueSuggestionsQuery, RunValueSuggestionsQueryVariables, RunValueSuggestionsProps<TChildProps, TDataName>>(RunValueSuggestionsDocument, {
      alias: 'runValueSuggestions',
      ...operationOptions
    });
};

/**
 * __useRunValueSuggestionsQuery__
 *
 * To run a query within a React component, call `useRunValueSuggestionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunValueSuggestionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunValueSuggestionsQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      keyPath: // value for 'keyPath'
 *      filters: // value for 'filters'
 *   },
 * });
 */
export function useRunValueSuggestionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunValueSuggestionsQuery, RunValueSuggestionsQueryVariables>) {
        return ApolloReactHooks.useQuery<RunValueSuggestionsQuery, RunValueSuggestionsQueryVariables>(RunValueSuggestionsDocument, baseOptions);
      }
export function useRunValueSuggestionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunValueSuggestionsQuery, RunValueSuggestionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunValueSuggestionsQuery, RunValueSuggestionsQueryVariables>(RunValueSuggestionsDocument, baseOptions);
        }
export type RunValueSuggestionsQueryHookResult = ReturnType<typeof useRunValueSuggestionsQuery>;
export type RunValueSuggestionsLazyQueryHookResult = ReturnType<typeof useRunValueSuggestionsLazyQuery>;
export type RunValueSuggestionsQueryResult = ApolloReactCommon.QueryResult<RunValueSuggestionsQuery, RunValueSuggestionsQueryVariables>;
export const ViewSpecDocument = gql`
    query ViewSpec($id: ID!) {
  view(id: $id) {
    id
    type
    spec
  }
}
    `;
export type ViewSpecComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewSpecQuery, ViewSpecQueryVariables>, 'query'> & ({ variables: ViewSpecQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ViewSpecComponent = (props: ViewSpecComponentProps) => (
      <ApolloReactComponents.Query<ViewSpecQuery, ViewSpecQueryVariables> query={ViewSpecDocument} {...props} />
    );
    
export type ViewSpecProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewSpecQuery, ViewSpecQueryVariables>
    } & TChildProps;
export function withViewSpec<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewSpecQuery,
  ViewSpecQueryVariables,
  ViewSpecProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewSpecQuery, ViewSpecQueryVariables, ViewSpecProps<TChildProps, TDataName>>(ViewSpecDocument, {
      alias: 'viewSpec',
      ...operationOptions
    });
};

/**
 * __useViewSpecQuery__
 *
 * To run a query within a React component, call `useViewSpecQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewSpecQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewSpecQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useViewSpecQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewSpecQuery, ViewSpecQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewSpecQuery, ViewSpecQueryVariables>(ViewSpecDocument, baseOptions);
      }
export function useViewSpecLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewSpecQuery, ViewSpecQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewSpecQuery, ViewSpecQueryVariables>(ViewSpecDocument, baseOptions);
        }
export type ViewSpecQueryHookResult = ReturnType<typeof useViewSpecQuery>;
export type ViewSpecLazyQueryHookResult = ReturnType<typeof useViewSpecLazyQuery>;
export type ViewSpecQueryResult = ApolloReactCommon.QueryResult<ViewSpecQuery, ViewSpecQueryVariables>;
export const SearchEntityProjectsDocument = gql`
    query SearchEntityProjects($entityName: String, $entityProjectsLimit: Int = 10000) {
  projects(entityName: $entityName, first: $entityProjectsLimit) {
    edges {
      node {
        id
        name
        entityName
        allViews(viewType: "runs", first: 1000) {
          edges {
            node {
              id
              displayName
              project {
                id
                name
                entityName
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type SearchEntityProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SearchEntityProjectsQuery, SearchEntityProjectsQueryVariables>, 'query'>;

    export const SearchEntityProjectsComponent = (props: SearchEntityProjectsComponentProps) => (
      <ApolloReactComponents.Query<SearchEntityProjectsQuery, SearchEntityProjectsQueryVariables> query={SearchEntityProjectsDocument} {...props} />
    );
    
export type SearchEntityProjectsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SearchEntityProjectsQuery, SearchEntityProjectsQueryVariables>
    } & TChildProps;
export function withSearchEntityProjects<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SearchEntityProjectsQuery,
  SearchEntityProjectsQueryVariables,
  SearchEntityProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SearchEntityProjectsQuery, SearchEntityProjectsQueryVariables, SearchEntityProjectsProps<TChildProps, TDataName>>(SearchEntityProjectsDocument, {
      alias: 'searchEntityProjects',
      ...operationOptions
    });
};

/**
 * __useSearchEntityProjectsQuery__
 *
 * To run a query within a React component, call `useSearchEntityProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchEntityProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchEntityProjectsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      entityProjectsLimit: // value for 'entityProjectsLimit'
 *   },
 * });
 */
export function useSearchEntityProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchEntityProjectsQuery, SearchEntityProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchEntityProjectsQuery, SearchEntityProjectsQueryVariables>(SearchEntityProjectsDocument, baseOptions);
      }
export function useSearchEntityProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchEntityProjectsQuery, SearchEntityProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchEntityProjectsQuery, SearchEntityProjectsQueryVariables>(SearchEntityProjectsDocument, baseOptions);
        }
export type SearchEntityProjectsQueryHookResult = ReturnType<typeof useSearchEntityProjectsQuery>;
export type SearchEntityProjectsLazyQueryHookResult = ReturnType<typeof useSearchEntityProjectsLazyQuery>;
export type SearchEntityProjectsQueryResult = ApolloReactCommon.QueryResult<SearchEntityProjectsQuery, SearchEntityProjectsQueryVariables>;
export const SearchViewerDocument = gql`
    query SearchViewer {
  viewer {
    id
    admin
    photoUrl
    username
    organizations {
      id
      name
      orgType
    }
    projects(first: 500) {
      edges {
        node {
          id
          name
          entityName
        }
      }
    }
    views(viewType: "runs", first: 10000) {
      edges {
        node {
          id
          displayName
          project {
            id
            name
            entityName
          }
        }
      }
    }
    teams(first: 500) {
      edges {
        node {
          id
          name
          photoUrl
          projects(first: 500) {
            edges {
              node {
                id
                name
                entityName
              }
            }
          }
        }
      }
    }
  }
}
    `;
export type SearchViewerComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SearchViewerQuery, SearchViewerQueryVariables>, 'query'>;

    export const SearchViewerComponent = (props: SearchViewerComponentProps) => (
      <ApolloReactComponents.Query<SearchViewerQuery, SearchViewerQueryVariables> query={SearchViewerDocument} {...props} />
    );
    
export type SearchViewerProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SearchViewerQuery, SearchViewerQueryVariables>
    } & TChildProps;
export function withSearchViewer<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SearchViewerQuery,
  SearchViewerQueryVariables,
  SearchViewerProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SearchViewerQuery, SearchViewerQueryVariables, SearchViewerProps<TChildProps, TDataName>>(SearchViewerDocument, {
      alias: 'searchViewer',
      ...operationOptions
    });
};

/**
 * __useSearchViewerQuery__
 *
 * To run a query within a React component, call `useSearchViewerQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchViewerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchViewerQuery({
 *   variables: {
 *   },
 * });
 */
export function useSearchViewerQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchViewerQuery, SearchViewerQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchViewerQuery, SearchViewerQueryVariables>(SearchViewerDocument, baseOptions);
      }
export function useSearchViewerLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchViewerQuery, SearchViewerQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchViewerQuery, SearchViewerQueryVariables>(SearchViewerDocument, baseOptions);
        }
export type SearchViewerQueryHookResult = ReturnType<typeof useSearchViewerQuery>;
export type SearchViewerLazyQueryHookResult = ReturnType<typeof useSearchViewerLazyQuery>;
export type SearchViewerQueryResult = ApolloReactCommon.QueryResult<SearchViewerQuery, SearchViewerQueryVariables>;
export const SearchNavQueryDocument = gql`
    query SearchNavQuery($entityName: String, $projectName: String, $runName: String!, $sweepName: String!) {
  project(entityName: $entityName, name: $projectName) {
    id
    name
    access
    readOnly
    isBenchmark
    entity {
      id
      name
      readOnlyAdmin
      defaultAccess
      privateOnly
      isTeam
    }
    user {
      id
    }
    run(name: $runName) {
      id
      displayName
      state
    }
    sweep(sweepName: $sweepName) {
      id
      name
      displayName
      config
    }
  }
}
    `;
export type SearchNavQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SearchNavQueryQuery, SearchNavQueryQueryVariables>, 'query'> & ({ variables: SearchNavQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SearchNavQueryComponent = (props: SearchNavQueryComponentProps) => (
      <ApolloReactComponents.Query<SearchNavQueryQuery, SearchNavQueryQueryVariables> query={SearchNavQueryDocument} {...props} />
    );
    
export type SearchNavQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SearchNavQueryQuery, SearchNavQueryQueryVariables>
    } & TChildProps;
export function withSearchNavQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SearchNavQueryQuery,
  SearchNavQueryQueryVariables,
  SearchNavQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SearchNavQueryQuery, SearchNavQueryQueryVariables, SearchNavQueryProps<TChildProps, TDataName>>(SearchNavQueryDocument, {
      alias: 'searchNavQuery',
      ...operationOptions
    });
};

/**
 * __useSearchNavQueryQuery__
 *
 * To run a query within a React component, call `useSearchNavQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchNavQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchNavQueryQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      runName: // value for 'runName'
 *      sweepName: // value for 'sweepName'
 *   },
 * });
 */
export function useSearchNavQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SearchNavQueryQuery, SearchNavQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<SearchNavQueryQuery, SearchNavQueryQueryVariables>(SearchNavQueryDocument, baseOptions);
      }
export function useSearchNavQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SearchNavQueryQuery, SearchNavQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SearchNavQueryQuery, SearchNavQueryQueryVariables>(SearchNavQueryDocument, baseOptions);
        }
export type SearchNavQueryQueryHookResult = ReturnType<typeof useSearchNavQueryQuery>;
export type SearchNavQueryLazyQueryHookResult = ReturnType<typeof useSearchNavQueryLazyQuery>;
export type SearchNavQueryQueryResult = ApolloReactCommon.QueryResult<SearchNavQueryQuery, SearchNavQueryQueryVariables>;
export const SelectedCountDocument = gql`
    query SelectedCount($name: String!, $entityName: String, $filters: JSONString, $tempFilters: JSONString) {
  project(name: $name, entityName: $entityName) {
    id
    selectedCount: runCount(filters: $filters)
    tempSelectedCount: runCount(filters: $tempFilters)
    tempSelectedActiveCount: runActiveCount(filters: $tempFilters)
    tempOutputArtifactsCount: runOutputArtifactsCount(filters: $tempFilters)
  }
}
    `;
export type SelectedCountComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SelectedCountQuery, SelectedCountQueryVariables>, 'query'> & ({ variables: SelectedCountQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SelectedCountComponent = (props: SelectedCountComponentProps) => (
      <ApolloReactComponents.Query<SelectedCountQuery, SelectedCountQueryVariables> query={SelectedCountDocument} {...props} />
    );
    
export type SelectedCountProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SelectedCountQuery, SelectedCountQueryVariables>
    } & TChildProps;
export function withSelectedCount<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SelectedCountQuery,
  SelectedCountQueryVariables,
  SelectedCountProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SelectedCountQuery, SelectedCountQueryVariables, SelectedCountProps<TChildProps, TDataName>>(SelectedCountDocument, {
      alias: 'selectedCount',
      ...operationOptions
    });
};

/**
 * __useSelectedCountQuery__
 *
 * To run a query within a React component, call `useSelectedCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectedCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectedCountQuery({
 *   variables: {
 *      name: // value for 'name'
 *      entityName: // value for 'entityName'
 *      filters: // value for 'filters'
 *      tempFilters: // value for 'tempFilters'
 *   },
 * });
 */
export function useSelectedCountQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SelectedCountQuery, SelectedCountQueryVariables>) {
        return ApolloReactHooks.useQuery<SelectedCountQuery, SelectedCountQueryVariables>(SelectedCountDocument, baseOptions);
      }
export function useSelectedCountLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SelectedCountQuery, SelectedCountQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SelectedCountQuery, SelectedCountQueryVariables>(SelectedCountDocument, baseOptions);
        }
export type SelectedCountQueryHookResult = ReturnType<typeof useSelectedCountQuery>;
export type SelectedCountLazyQueryHookResult = ReturnType<typeof useSelectedCountLazyQuery>;
export type SelectedCountQueryResult = ApolloReactCommon.QueryResult<SelectedCountQuery, SelectedCountQueryVariables>;
export const SelectedCountWithIdDocument = gql`
    query SelectedCountWithID($internalId: ID!, $filters: JSONString, $tempFilters: JSONString) {
  project(internalId: $internalId) {
    id
    selectedCount: runCount(filters: $filters)
    tempSelectedCount: runCount(filters: $tempFilters)
    tempSelectedActiveCount: runActiveCount(filters: $tempFilters)
    tempOutputArtifactsCount: runOutputArtifactsCount(filters: $tempFilters)
  }
}
    `;
export type SelectedCountWithIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SelectedCountWithIdQuery, SelectedCountWithIdQueryVariables>, 'query'> & ({ variables: SelectedCountWithIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SelectedCountWithIdComponent = (props: SelectedCountWithIdComponentProps) => (
      <ApolloReactComponents.Query<SelectedCountWithIdQuery, SelectedCountWithIdQueryVariables> query={SelectedCountWithIdDocument} {...props} />
    );
    
export type SelectedCountWithIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SelectedCountWithIdQuery, SelectedCountWithIdQueryVariables>
    } & TChildProps;
export function withSelectedCountWithId<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SelectedCountWithIdQuery,
  SelectedCountWithIdQueryVariables,
  SelectedCountWithIdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SelectedCountWithIdQuery, SelectedCountWithIdQueryVariables, SelectedCountWithIdProps<TChildProps, TDataName>>(SelectedCountWithIdDocument, {
      alias: 'selectedCountWithId',
      ...operationOptions
    });
};

/**
 * __useSelectedCountWithIdQuery__
 *
 * To run a query within a React component, call `useSelectedCountWithIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useSelectedCountWithIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSelectedCountWithIdQuery({
 *   variables: {
 *      internalId: // value for 'internalId'
 *      filters: // value for 'filters'
 *      tempFilters: // value for 'tempFilters'
 *   },
 * });
 */
export function useSelectedCountWithIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SelectedCountWithIdQuery, SelectedCountWithIdQueryVariables>) {
        return ApolloReactHooks.useQuery<SelectedCountWithIdQuery, SelectedCountWithIdQueryVariables>(SelectedCountWithIdDocument, baseOptions);
      }
export function useSelectedCountWithIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SelectedCountWithIdQuery, SelectedCountWithIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SelectedCountWithIdQuery, SelectedCountWithIdQueryVariables>(SelectedCountWithIdDocument, baseOptions);
        }
export type SelectedCountWithIdQueryHookResult = ReturnType<typeof useSelectedCountWithIdQuery>;
export type SelectedCountWithIdLazyQueryHookResult = ReturnType<typeof useSelectedCountWithIdLazyQuery>;
export type SelectedCountWithIdQueryResult = ApolloReactCommon.QueryResult<SelectedCountWithIdQuery, SelectedCountWithIdQueryVariables>;
export const SingleFileDocument = gql`
    query SingleFile($projectName: String!, $entityName: String, $runName: String!, $fileName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    run(name: $runName) {
      id
      files(first: 1, names: [$fileName]) {
        edges {
          node {
            id
            name
            directUrl
            sizeBytes
            updatedAt
          }
        }
      }
    }
  }
}
    `;
export type SingleFileComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SingleFileQuery, SingleFileQueryVariables>, 'query'> & ({ variables: SingleFileQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SingleFileComponent = (props: SingleFileComponentProps) => (
      <ApolloReactComponents.Query<SingleFileQuery, SingleFileQueryVariables> query={SingleFileDocument} {...props} />
    );
    
export type SingleFileProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SingleFileQuery, SingleFileQueryVariables>
    } & TChildProps;
export function withSingleFile<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SingleFileQuery,
  SingleFileQueryVariables,
  SingleFileProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SingleFileQuery, SingleFileQueryVariables, SingleFileProps<TChildProps, TDataName>>(SingleFileDocument, {
      alias: 'singleFile',
      ...operationOptions
    });
};

/**
 * __useSingleFileQuery__
 *
 * To run a query within a React component, call `useSingleFileQuery` and pass it any options that fit your needs.
 * When your component renders, `useSingleFileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSingleFileQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runName: // value for 'runName'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useSingleFileQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SingleFileQuery, SingleFileQueryVariables>) {
        return ApolloReactHooks.useQuery<SingleFileQuery, SingleFileQueryVariables>(SingleFileDocument, baseOptions);
      }
export function useSingleFileLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SingleFileQuery, SingleFileQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SingleFileQuery, SingleFileQueryVariables>(SingleFileDocument, baseOptions);
        }
export type SingleFileQueryHookResult = ReturnType<typeof useSingleFileQuery>;
export type SingleFileLazyQueryHookResult = ReturnType<typeof useSingleFileLazyQuery>;
export type SingleFileQueryResult = ApolloReactCommon.QueryResult<SingleFileQuery, SingleFileQueryVariables>;
export const SweepPageDocument = gql`
    query SweepPage($projectName: String!, $entityName: String!, $sweepName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    access
    readOnly
    sweep(sweepName: $sweepName) {
      id
      name
      displayName
      runCount
      runCountExpected
      runTime
      method
      state
      description
      user {
        id
        username
        photoUrl
      }
      config
      createdAt
      heartbeatAt
      updatedAt
      controllerRunName
      priorRuns {
        edges {
          node {
            id
            name
          }
        }
      }
      agents {
        edges {
          node {
            id
            name
            state
            host
            persistent
            user {
              id
            }
            metrics
            createdAt
            heartbeatAt
            totalRuns
          }
        }
      }
      scheduler
    }
    entity {
      id
      name
      defaultAccess
      readOnlyAdmin
      privateOnly
      isTeam
    }
  }
}
    `;
export type SweepPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SweepPageQuery, SweepPageQueryVariables>, 'query'> & ({ variables: SweepPageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SweepPageComponent = (props: SweepPageComponentProps) => (
      <ApolloReactComponents.Query<SweepPageQuery, SweepPageQueryVariables> query={SweepPageDocument} {...props} />
    );
    
export type SweepPageProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SweepPageQuery, SweepPageQueryVariables>
    } & TChildProps;
export function withSweepPage<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SweepPageQuery,
  SweepPageQueryVariables,
  SweepPageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SweepPageQuery, SweepPageQueryVariables, SweepPageProps<TChildProps, TDataName>>(SweepPageDocument, {
      alias: 'sweepPage',
      ...operationOptions
    });
};

/**
 * __useSweepPageQuery__
 *
 * To run a query within a React component, call `useSweepPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useSweepPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSweepPageQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      sweepName: // value for 'sweepName'
 *   },
 * });
 */
export function useSweepPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SweepPageQuery, SweepPageQueryVariables>) {
        return ApolloReactHooks.useQuery<SweepPageQuery, SweepPageQueryVariables>(SweepPageDocument, baseOptions);
      }
export function useSweepPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SweepPageQuery, SweepPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SweepPageQuery, SweepPageQueryVariables>(SweepPageDocument, baseOptions);
        }
export type SweepPageQueryHookResult = ReturnType<typeof useSweepPageQuery>;
export type SweepPageLazyQueryHookResult = ReturnType<typeof useSweepPageLazyQuery>;
export type SweepPageQueryResult = ApolloReactCommon.QueryResult<SweepPageQuery, SweepPageQueryVariables>;
export const SweepsDocument = gql`
    query Sweeps($projectName: String!, $entityName: String) {
  project(name: $projectName, entityName: $entityName) {
    id
    readOnly
    sweeps {
      edges {
        node {
          id
          name
          displayName
          state
          runCount
          runCountExpected
          runTime
          createdAt
          user {
            id
            username
            photoUrl
          }
          config
          scheduler
        }
      }
    }
  }
}
    `;
export type SweepsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SweepsQuery, SweepsQueryVariables>, 'query'> & ({ variables: SweepsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SweepsComponent = (props: SweepsComponentProps) => (
      <ApolloReactComponents.Query<SweepsQuery, SweepsQueryVariables> query={SweepsDocument} {...props} />
    );
    
export type SweepsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SweepsQuery, SweepsQueryVariables>
    } & TChildProps;
export function withSweeps<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SweepsQuery,
  SweepsQueryVariables,
  SweepsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SweepsQuery, SweepsQueryVariables, SweepsProps<TChildProps, TDataName>>(SweepsDocument, {
      alias: 'sweeps',
      ...operationOptions
    });
};

/**
 * __useSweepsQuery__
 *
 * To run a query within a React component, call `useSweepsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSweepsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSweepsQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useSweepsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SweepsQuery, SweepsQueryVariables>) {
        return ApolloReactHooks.useQuery<SweepsQuery, SweepsQueryVariables>(SweepsDocument, baseOptions);
      }
export function useSweepsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SweepsQuery, SweepsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SweepsQuery, SweepsQueryVariables>(SweepsDocument, baseOptions);
        }
export type SweepsQueryHookResult = ReturnType<typeof useSweepsQuery>;
export type SweepsLazyQueryHookResult = ReturnType<typeof useSweepsLazyQuery>;
export type SweepsQueryResult = ApolloReactCommon.QueryResult<SweepsQuery, SweepsQueryVariables>;
export const CreateArtifactCollectionTagAssignmentsDocument = gql`
    mutation CreateArtifactCollectionTagAssignments($entityName: String!, $projectName: String!, $artifactCollectionName: String!, $tags: [TagInput!]!) {
  createArtifactCollectionTagAssignments(
    input: {entityName: $entityName, projectName: $projectName, artifactCollectionName: $artifactCollectionName, tags: $tags}
  ) {
    tags {
      id
      name
      tagCategoryName
    }
  }
}
    `;
export type CreateArtifactCollectionTagAssignmentsMutationFn = ApolloReactCommon.MutationFunction<CreateArtifactCollectionTagAssignmentsMutation, CreateArtifactCollectionTagAssignmentsMutationVariables>;
export type CreateArtifactCollectionTagAssignmentsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateArtifactCollectionTagAssignmentsMutation, CreateArtifactCollectionTagAssignmentsMutationVariables>, 'mutation'>;

    export const CreateArtifactCollectionTagAssignmentsComponent = (props: CreateArtifactCollectionTagAssignmentsComponentProps) => (
      <ApolloReactComponents.Mutation<CreateArtifactCollectionTagAssignmentsMutation, CreateArtifactCollectionTagAssignmentsMutationVariables> mutation={CreateArtifactCollectionTagAssignmentsDocument} {...props} />
    );
    
export type CreateArtifactCollectionTagAssignmentsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateArtifactCollectionTagAssignmentsMutation, CreateArtifactCollectionTagAssignmentsMutationVariables>
    } & TChildProps;
export function withCreateArtifactCollectionTagAssignments<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateArtifactCollectionTagAssignmentsMutation,
  CreateArtifactCollectionTagAssignmentsMutationVariables,
  CreateArtifactCollectionTagAssignmentsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateArtifactCollectionTagAssignmentsMutation, CreateArtifactCollectionTagAssignmentsMutationVariables, CreateArtifactCollectionTagAssignmentsProps<TChildProps, TDataName>>(CreateArtifactCollectionTagAssignmentsDocument, {
      alias: 'createArtifactCollectionTagAssignments',
      ...operationOptions
    });
};

/**
 * __useCreateArtifactCollectionTagAssignmentsMutation__
 *
 * To run a mutation, you first call `useCreateArtifactCollectionTagAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArtifactCollectionTagAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArtifactCollectionTagAssignmentsMutation, { data, loading, error }] = useCreateArtifactCollectionTagAssignmentsMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      artifactCollectionName: // value for 'artifactCollectionName'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useCreateArtifactCollectionTagAssignmentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArtifactCollectionTagAssignmentsMutation, CreateArtifactCollectionTagAssignmentsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateArtifactCollectionTagAssignmentsMutation, CreateArtifactCollectionTagAssignmentsMutationVariables>(CreateArtifactCollectionTagAssignmentsDocument, baseOptions);
      }
export type CreateArtifactCollectionTagAssignmentsMutationHookResult = ReturnType<typeof useCreateArtifactCollectionTagAssignmentsMutation>;
export type CreateArtifactCollectionTagAssignmentsMutationResult = ApolloReactCommon.MutationResult<CreateArtifactCollectionTagAssignmentsMutation>;
export type CreateArtifactCollectionTagAssignmentsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateArtifactCollectionTagAssignmentsMutation, CreateArtifactCollectionTagAssignmentsMutationVariables>;
export const DeleteArtifactCollectionTagAssignmentsDocument = gql`
    mutation DeleteArtifactCollectionTagAssignments($entityName: String!, $projectName: String!, $artifactCollectionName: String!, $tags: [TagInput!]!) {
  deleteArtifactCollectionTagAssignments(
    input: {entityName: $entityName, projectName: $projectName, artifactCollectionName: $artifactCollectionName, tags: $tags}
  ) {
    success
  }
}
    `;
export type DeleteArtifactCollectionTagAssignmentsMutationFn = ApolloReactCommon.MutationFunction<DeleteArtifactCollectionTagAssignmentsMutation, DeleteArtifactCollectionTagAssignmentsMutationVariables>;
export type DeleteArtifactCollectionTagAssignmentsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteArtifactCollectionTagAssignmentsMutation, DeleteArtifactCollectionTagAssignmentsMutationVariables>, 'mutation'>;

    export const DeleteArtifactCollectionTagAssignmentsComponent = (props: DeleteArtifactCollectionTagAssignmentsComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteArtifactCollectionTagAssignmentsMutation, DeleteArtifactCollectionTagAssignmentsMutationVariables> mutation={DeleteArtifactCollectionTagAssignmentsDocument} {...props} />
    );
    
export type DeleteArtifactCollectionTagAssignmentsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteArtifactCollectionTagAssignmentsMutation, DeleteArtifactCollectionTagAssignmentsMutationVariables>
    } & TChildProps;
export function withDeleteArtifactCollectionTagAssignments<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteArtifactCollectionTagAssignmentsMutation,
  DeleteArtifactCollectionTagAssignmentsMutationVariables,
  DeleteArtifactCollectionTagAssignmentsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteArtifactCollectionTagAssignmentsMutation, DeleteArtifactCollectionTagAssignmentsMutationVariables, DeleteArtifactCollectionTagAssignmentsProps<TChildProps, TDataName>>(DeleteArtifactCollectionTagAssignmentsDocument, {
      alias: 'deleteArtifactCollectionTagAssignments',
      ...operationOptions
    });
};

/**
 * __useDeleteArtifactCollectionTagAssignmentsMutation__
 *
 * To run a mutation, you first call `useDeleteArtifactCollectionTagAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArtifactCollectionTagAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArtifactCollectionTagAssignmentsMutation, { data, loading, error }] = useDeleteArtifactCollectionTagAssignmentsMutation({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      artifactCollectionName: // value for 'artifactCollectionName'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useDeleteArtifactCollectionTagAssignmentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArtifactCollectionTagAssignmentsMutation, DeleteArtifactCollectionTagAssignmentsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteArtifactCollectionTagAssignmentsMutation, DeleteArtifactCollectionTagAssignmentsMutationVariables>(DeleteArtifactCollectionTagAssignmentsDocument, baseOptions);
      }
export type DeleteArtifactCollectionTagAssignmentsMutationHookResult = ReturnType<typeof useDeleteArtifactCollectionTagAssignmentsMutation>;
export type DeleteArtifactCollectionTagAssignmentsMutationResult = ApolloReactCommon.MutationResult<DeleteArtifactCollectionTagAssignmentsMutation>;
export type DeleteArtifactCollectionTagAssignmentsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteArtifactCollectionTagAssignmentsMutation, DeleteArtifactCollectionTagAssignmentsMutationVariables>;
export const CreateArtifactTagAssignmentsDocument = gql`
    mutation CreateArtifactTagAssignments($artifactID: ID!, $tags: [TagInput!]!) {
  createArtifactTagAssignments(input: {artifactID: $artifactID, tags: $tags}) {
    tags {
      id
      name
      tagCategoryName
    }
  }
}
    `;
export type CreateArtifactTagAssignmentsMutationFn = ApolloReactCommon.MutationFunction<CreateArtifactTagAssignmentsMutation, CreateArtifactTagAssignmentsMutationVariables>;
export type CreateArtifactTagAssignmentsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateArtifactTagAssignmentsMutation, CreateArtifactTagAssignmentsMutationVariables>, 'mutation'>;

    export const CreateArtifactTagAssignmentsComponent = (props: CreateArtifactTagAssignmentsComponentProps) => (
      <ApolloReactComponents.Mutation<CreateArtifactTagAssignmentsMutation, CreateArtifactTagAssignmentsMutationVariables> mutation={CreateArtifactTagAssignmentsDocument} {...props} />
    );
    
export type CreateArtifactTagAssignmentsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateArtifactTagAssignmentsMutation, CreateArtifactTagAssignmentsMutationVariables>
    } & TChildProps;
export function withCreateArtifactTagAssignments<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateArtifactTagAssignmentsMutation,
  CreateArtifactTagAssignmentsMutationVariables,
  CreateArtifactTagAssignmentsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateArtifactTagAssignmentsMutation, CreateArtifactTagAssignmentsMutationVariables, CreateArtifactTagAssignmentsProps<TChildProps, TDataName>>(CreateArtifactTagAssignmentsDocument, {
      alias: 'createArtifactTagAssignments',
      ...operationOptions
    });
};

/**
 * __useCreateArtifactTagAssignmentsMutation__
 *
 * To run a mutation, you first call `useCreateArtifactTagAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateArtifactTagAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createArtifactTagAssignmentsMutation, { data, loading, error }] = useCreateArtifactTagAssignmentsMutation({
 *   variables: {
 *      artifactID: // value for 'artifactID'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useCreateArtifactTagAssignmentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateArtifactTagAssignmentsMutation, CreateArtifactTagAssignmentsMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateArtifactTagAssignmentsMutation, CreateArtifactTagAssignmentsMutationVariables>(CreateArtifactTagAssignmentsDocument, baseOptions);
      }
export type CreateArtifactTagAssignmentsMutationHookResult = ReturnType<typeof useCreateArtifactTagAssignmentsMutation>;
export type CreateArtifactTagAssignmentsMutationResult = ApolloReactCommon.MutationResult<CreateArtifactTagAssignmentsMutation>;
export type CreateArtifactTagAssignmentsMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateArtifactTagAssignmentsMutation, CreateArtifactTagAssignmentsMutationVariables>;
export const DeleteArtifactTagAssignmentsDocument = gql`
    mutation DeleteArtifactTagAssignments($artifactID: ID!, $tags: [TagInput!]!) {
  deleteArtifactTagAssignments(input: {artifactID: $artifactID, tags: $tags}) {
    success
  }
}
    `;
export type DeleteArtifactTagAssignmentsMutationFn = ApolloReactCommon.MutationFunction<DeleteArtifactTagAssignmentsMutation, DeleteArtifactTagAssignmentsMutationVariables>;
export type DeleteArtifactTagAssignmentsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteArtifactTagAssignmentsMutation, DeleteArtifactTagAssignmentsMutationVariables>, 'mutation'>;

    export const DeleteArtifactTagAssignmentsComponent = (props: DeleteArtifactTagAssignmentsComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteArtifactTagAssignmentsMutation, DeleteArtifactTagAssignmentsMutationVariables> mutation={DeleteArtifactTagAssignmentsDocument} {...props} />
    );
    
export type DeleteArtifactTagAssignmentsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteArtifactTagAssignmentsMutation, DeleteArtifactTagAssignmentsMutationVariables>
    } & TChildProps;
export function withDeleteArtifactTagAssignments<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteArtifactTagAssignmentsMutation,
  DeleteArtifactTagAssignmentsMutationVariables,
  DeleteArtifactTagAssignmentsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteArtifactTagAssignmentsMutation, DeleteArtifactTagAssignmentsMutationVariables, DeleteArtifactTagAssignmentsProps<TChildProps, TDataName>>(DeleteArtifactTagAssignmentsDocument, {
      alias: 'deleteArtifactTagAssignments',
      ...operationOptions
    });
};

/**
 * __useDeleteArtifactTagAssignmentsMutation__
 *
 * To run a mutation, you first call `useDeleteArtifactTagAssignmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteArtifactTagAssignmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteArtifactTagAssignmentsMutation, { data, loading, error }] = useDeleteArtifactTagAssignmentsMutation({
 *   variables: {
 *      artifactID: // value for 'artifactID'
 *      tags: // value for 'tags'
 *   },
 * });
 */
export function useDeleteArtifactTagAssignmentsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteArtifactTagAssignmentsMutation, DeleteArtifactTagAssignmentsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteArtifactTagAssignmentsMutation, DeleteArtifactTagAssignmentsMutationVariables>(DeleteArtifactTagAssignmentsDocument, baseOptions);
      }
export type DeleteArtifactTagAssignmentsMutationHookResult = ReturnType<typeof useDeleteArtifactTagAssignmentsMutation>;
export type DeleteArtifactTagAssignmentsMutationResult = ApolloReactCommon.MutationResult<DeleteArtifactTagAssignmentsMutation>;
export type DeleteArtifactTagAssignmentsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteArtifactTagAssignmentsMutation, DeleteArtifactTagAssignmentsMutationVariables>;
export const TeamMembersDocument = gql`
    query TeamMembers($entityName: String!) {
  entity(name: $entityName) {
    id
    members {
      ...TeamMemberFields
    }
  }
}
    ${TeamMemberFieldsFragmentDoc}`;
export type TeamMembersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamMembersQuery, TeamMembersQueryVariables>, 'query'> & ({ variables: TeamMembersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamMembersComponent = (props: TeamMembersComponentProps) => (
      <ApolloReactComponents.Query<TeamMembersQuery, TeamMembersQueryVariables> query={TeamMembersDocument} {...props} />
    );
    
export type TeamMembersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TeamMembersQuery, TeamMembersQueryVariables>
    } & TChildProps;
export function withTeamMembers<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TeamMembersQuery,
  TeamMembersQueryVariables,
  TeamMembersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TeamMembersQuery, TeamMembersQueryVariables, TeamMembersProps<TChildProps, TDataName>>(TeamMembersDocument, {
      alias: 'teamMembers',
      ...operationOptions
    });
};

/**
 * __useTeamMembersQuery__
 *
 * To run a query within a React component, call `useTeamMembersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMembersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMembersQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useTeamMembersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamMembersQuery, TeamMembersQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamMembersQuery, TeamMembersQueryVariables>(TeamMembersDocument, baseOptions);
      }
export function useTeamMembersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamMembersQuery, TeamMembersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamMembersQuery, TeamMembersQueryVariables>(TeamMembersDocument, baseOptions);
        }
export type TeamMembersQueryHookResult = ReturnType<typeof useTeamMembersQuery>;
export type TeamMembersLazyQueryHookResult = ReturnType<typeof useTeamMembersLazyQuery>;
export type TeamMembersQueryResult = ApolloReactCommon.QueryResult<TeamMembersQuery, TeamMembersQueryVariables>;
export const TeamMemberDocument = gql`
    query TeamMember($entityName: String!, $memberId: ID!) {
  entity(name: $entityName) {
    id
    member(id: $memberId) {
      ...TeamMemberFields
    }
  }
}
    ${TeamMemberFieldsFragmentDoc}`;
export type TeamMemberComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamMemberQuery, TeamMemberQueryVariables>, 'query'> & ({ variables: TeamMemberQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamMemberComponent = (props: TeamMemberComponentProps) => (
      <ApolloReactComponents.Query<TeamMemberQuery, TeamMemberQueryVariables> query={TeamMemberDocument} {...props} />
    );
    
export type TeamMemberProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TeamMemberQuery, TeamMemberQueryVariables>
    } & TChildProps;
export function withTeamMember<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TeamMemberQuery,
  TeamMemberQueryVariables,
  TeamMemberProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TeamMemberQuery, TeamMemberQueryVariables, TeamMemberProps<TChildProps, TDataName>>(TeamMemberDocument, {
      alias: 'teamMember',
      ...operationOptions
    });
};

/**
 * __useTeamMemberQuery__
 *
 * To run a query within a React component, call `useTeamMemberQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamMemberQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamMemberQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      memberId: // value for 'memberId'
 *   },
 * });
 */
export function useTeamMemberQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamMemberQuery, TeamMemberQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamMemberQuery, TeamMemberQueryVariables>(TeamMemberDocument, baseOptions);
      }
export function useTeamMemberLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamMemberQuery, TeamMemberQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamMemberQuery, TeamMemberQueryVariables>(TeamMemberDocument, baseOptions);
        }
export type TeamMemberQueryHookResult = ReturnType<typeof useTeamMemberQuery>;
export type TeamMemberLazyQueryHookResult = ReturnType<typeof useTeamMemberLazyQuery>;
export type TeamMemberQueryResult = ApolloReactCommon.QueryResult<TeamMemberQuery, TeamMemberQueryVariables>;
export const CreateFilterTriggerDocument = gql`
    mutation createFilterTrigger($name: String!, $description: String, $triggeringEventType: EventTriggeringConditionType!, $scopeType: TriggerScopeType!, $scopeID: ID!, $eventFilter: JSONString!, $triggeredActionType: TriggeredActionType!, $triggeredActionConfig: TriggeredActionConfig!, $enabled: Boolean!) {
  createFilterTrigger(
    input: {name: $name, description: $description, triggeringEventType: $triggeringEventType, scopeType: $scopeType, scopeID: $scopeID, eventFilter: $eventFilter, triggeredActionType: $triggeredActionType, triggeredActionConfig: $triggeredActionConfig, enabled: $enabled}
  ) {
    trigger {
      id
    }
  }
}
    `;
export type CreateFilterTriggerMutationFn = ApolloReactCommon.MutationFunction<CreateFilterTriggerMutation, CreateFilterTriggerMutationVariables>;
export type CreateFilterTriggerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<CreateFilterTriggerMutation, CreateFilterTriggerMutationVariables>, 'mutation'>;

    export const CreateFilterTriggerComponent = (props: CreateFilterTriggerComponentProps) => (
      <ApolloReactComponents.Mutation<CreateFilterTriggerMutation, CreateFilterTriggerMutationVariables> mutation={CreateFilterTriggerDocument} {...props} />
    );
    
export type CreateFilterTriggerProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<CreateFilterTriggerMutation, CreateFilterTriggerMutationVariables>
    } & TChildProps;
export function withCreateFilterTrigger<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateFilterTriggerMutation,
  CreateFilterTriggerMutationVariables,
  CreateFilterTriggerProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateFilterTriggerMutation, CreateFilterTriggerMutationVariables, CreateFilterTriggerProps<TChildProps, TDataName>>(CreateFilterTriggerDocument, {
      alias: 'createFilterTrigger',
      ...operationOptions
    });
};

/**
 * __useCreateFilterTriggerMutation__
 *
 * To run a mutation, you first call `useCreateFilterTriggerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFilterTriggerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFilterTriggerMutation, { data, loading, error }] = useCreateFilterTriggerMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *      triggeringEventType: // value for 'triggeringEventType'
 *      scopeType: // value for 'scopeType'
 *      scopeID: // value for 'scopeID'
 *      eventFilter: // value for 'eventFilter'
 *      triggeredActionType: // value for 'triggeredActionType'
 *      triggeredActionConfig: // value for 'triggeredActionConfig'
 *      enabled: // value for 'enabled'
 *   },
 * });
 */
export function useCreateFilterTriggerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateFilterTriggerMutation, CreateFilterTriggerMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateFilterTriggerMutation, CreateFilterTriggerMutationVariables>(CreateFilterTriggerDocument, baseOptions);
      }
export type CreateFilterTriggerMutationHookResult = ReturnType<typeof useCreateFilterTriggerMutation>;
export type CreateFilterTriggerMutationResult = ApolloReactCommon.MutationResult<CreateFilterTriggerMutation>;
export type CreateFilterTriggerMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateFilterTriggerMutation, CreateFilterTriggerMutationVariables>;
export const FetchProjectTriggersDocument = gql`
    query FetchProjectTriggers($entityName: String!, $projectName: String!, $projectOnly: Boolean) {
  project(name: $projectName, entityName: $entityName) {
    id
    triggers(projectOnly: $projectOnly) {
      id
      name
      createdAt
      enabled
      createdBy {
        id
        name
      }
      description
      scope {
        __typename
        ... on Project {
          id
          name
        }
        ... on ArtifactSequence {
          id
          name
          project {
            id
            name
          }
        }
        ... on ArtifactPortfolio {
          id
          name
          project {
            id
            name
          }
        }
      }
      triggeringCondition {
        __typename
        ... on FilterEventTriggeringCondition {
          eventType
          filter
        }
      }
      triggeredAction {
        __typename
        ... on QueueJobTriggeredAction {
          queue {
            id
            name
          }
          template
        }
        ... on NotificationTriggeredAction {
          integration {
            id
          }
          title
          message
          severity
        }
        ... on GenericWebhookTriggeredAction {
          integration {
            __typename
            ... on GenericWebhookIntegration {
              id
              name
              urlEndpoint
              accessTokenRef
              secretRef
              createdAt
            }
          }
          requestPayload
        }
      }
    }
  }
}
    `;
export type FetchProjectTriggersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FetchProjectTriggersQuery, FetchProjectTriggersQueryVariables>, 'query'> & ({ variables: FetchProjectTriggersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FetchProjectTriggersComponent = (props: FetchProjectTriggersComponentProps) => (
      <ApolloReactComponents.Query<FetchProjectTriggersQuery, FetchProjectTriggersQueryVariables> query={FetchProjectTriggersDocument} {...props} />
    );
    
export type FetchProjectTriggersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FetchProjectTriggersQuery, FetchProjectTriggersQueryVariables>
    } & TChildProps;
export function withFetchProjectTriggers<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FetchProjectTriggersQuery,
  FetchProjectTriggersQueryVariables,
  FetchProjectTriggersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FetchProjectTriggersQuery, FetchProjectTriggersQueryVariables, FetchProjectTriggersProps<TChildProps, TDataName>>(FetchProjectTriggersDocument, {
      alias: 'fetchProjectTriggers',
      ...operationOptions
    });
};

/**
 * __useFetchProjectTriggersQuery__
 *
 * To run a query within a React component, call `useFetchProjectTriggersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchProjectTriggersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchProjectTriggersQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      projectOnly: // value for 'projectOnly'
 *   },
 * });
 */
export function useFetchProjectTriggersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchProjectTriggersQuery, FetchProjectTriggersQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchProjectTriggersQuery, FetchProjectTriggersQueryVariables>(FetchProjectTriggersDocument, baseOptions);
      }
export function useFetchProjectTriggersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchProjectTriggersQuery, FetchProjectTriggersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchProjectTriggersQuery, FetchProjectTriggersQueryVariables>(FetchProjectTriggersDocument, baseOptions);
        }
export type FetchProjectTriggersQueryHookResult = ReturnType<typeof useFetchProjectTriggersQuery>;
export type FetchProjectTriggersLazyQueryHookResult = ReturnType<typeof useFetchProjectTriggersLazyQuery>;
export type FetchProjectTriggersQueryResult = ApolloReactCommon.QueryResult<FetchProjectTriggersQuery, FetchProjectTriggersQueryVariables>;
export const FetchArtifactCollectionTriggersDocument = gql`
    query FetchArtifactCollectionTriggers($artifactCollectionID: ID!) {
  artifactCollection(id: $artifactCollectionID) {
    id
    name
    triggers {
      edges {
        node {
          id
          name
          createdAt
          enabled
          createdBy {
            id
            name
          }
          description
          scope {
            __typename
            ... on Project {
              id
              name
            }
            ... on ArtifactSequence {
              id
              name
              project {
                id
                name
              }
            }
            ... on ArtifactPortfolio {
              id
              name
              project {
                id
                name
              }
            }
          }
          triggeringCondition {
            __typename
            ... on FilterEventTriggeringCondition {
              eventType
              filter
            }
          }
          triggeredAction {
            __typename
            ... on QueueJobTriggeredAction {
              queue {
                id
                name
              }
              template
            }
            ... on NotificationTriggeredAction {
              integration {
                id
              }
              title
              message
              severity
            }
            ... on GenericWebhookTriggeredAction {
              integration {
                __typename
                ... on GenericWebhookIntegration {
                  id
                  name
                  urlEndpoint
                  accessTokenRef
                  secretRef
                  createdAt
                }
              }
              requestPayload
            }
          }
        }
      }
    }
  }
}
    `;
export type FetchArtifactCollectionTriggersComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<FetchArtifactCollectionTriggersQuery, FetchArtifactCollectionTriggersQueryVariables>, 'query'> & ({ variables: FetchArtifactCollectionTriggersQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const FetchArtifactCollectionTriggersComponent = (props: FetchArtifactCollectionTriggersComponentProps) => (
      <ApolloReactComponents.Query<FetchArtifactCollectionTriggersQuery, FetchArtifactCollectionTriggersQueryVariables> query={FetchArtifactCollectionTriggersDocument} {...props} />
    );
    
export type FetchArtifactCollectionTriggersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<FetchArtifactCollectionTriggersQuery, FetchArtifactCollectionTriggersQueryVariables>
    } & TChildProps;
export function withFetchArtifactCollectionTriggers<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  FetchArtifactCollectionTriggersQuery,
  FetchArtifactCollectionTriggersQueryVariables,
  FetchArtifactCollectionTriggersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, FetchArtifactCollectionTriggersQuery, FetchArtifactCollectionTriggersQueryVariables, FetchArtifactCollectionTriggersProps<TChildProps, TDataName>>(FetchArtifactCollectionTriggersDocument, {
      alias: 'fetchArtifactCollectionTriggers',
      ...operationOptions
    });
};

/**
 * __useFetchArtifactCollectionTriggersQuery__
 *
 * To run a query within a React component, call `useFetchArtifactCollectionTriggersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchArtifactCollectionTriggersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchArtifactCollectionTriggersQuery({
 *   variables: {
 *      artifactCollectionID: // value for 'artifactCollectionID'
 *   },
 * });
 */
export function useFetchArtifactCollectionTriggersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<FetchArtifactCollectionTriggersQuery, FetchArtifactCollectionTriggersQueryVariables>) {
        return ApolloReactHooks.useQuery<FetchArtifactCollectionTriggersQuery, FetchArtifactCollectionTriggersQueryVariables>(FetchArtifactCollectionTriggersDocument, baseOptions);
      }
export function useFetchArtifactCollectionTriggersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<FetchArtifactCollectionTriggersQuery, FetchArtifactCollectionTriggersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<FetchArtifactCollectionTriggersQuery, FetchArtifactCollectionTriggersQueryVariables>(FetchArtifactCollectionTriggersDocument, baseOptions);
        }
export type FetchArtifactCollectionTriggersQueryHookResult = ReturnType<typeof useFetchArtifactCollectionTriggersQuery>;
export type FetchArtifactCollectionTriggersLazyQueryHookResult = ReturnType<typeof useFetchArtifactCollectionTriggersLazyQuery>;
export type FetchArtifactCollectionTriggersQueryResult = ApolloReactCommon.QueryResult<FetchArtifactCollectionTriggersQuery, FetchArtifactCollectionTriggersQueryVariables>;
export const DeleteTriggerDocument = gql`
    mutation DeleteTrigger($triggerID: ID!) {
  deleteTrigger(input: {triggerID: $triggerID}) {
    success
  }
}
    `;
export type DeleteTriggerMutationFn = ApolloReactCommon.MutationFunction<DeleteTriggerMutation, DeleteTriggerMutationVariables>;
export type DeleteTriggerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteTriggerMutation, DeleteTriggerMutationVariables>, 'mutation'>;

    export const DeleteTriggerComponent = (props: DeleteTriggerComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteTriggerMutation, DeleteTriggerMutationVariables> mutation={DeleteTriggerDocument} {...props} />
    );
    
export type DeleteTriggerProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteTriggerMutation, DeleteTriggerMutationVariables>
    } & TChildProps;
export function withDeleteTrigger<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteTriggerMutation,
  DeleteTriggerMutationVariables,
  DeleteTriggerProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteTriggerMutation, DeleteTriggerMutationVariables, DeleteTriggerProps<TChildProps, TDataName>>(DeleteTriggerDocument, {
      alias: 'deleteTrigger',
      ...operationOptions
    });
};

/**
 * __useDeleteTriggerMutation__
 *
 * To run a mutation, you first call `useDeleteTriggerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTriggerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTriggerMutation, { data, loading, error }] = useDeleteTriggerMutation({
 *   variables: {
 *      triggerID: // value for 'triggerID'
 *   },
 * });
 */
export function useDeleteTriggerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTriggerMutation, DeleteTriggerMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteTriggerMutation, DeleteTriggerMutationVariables>(DeleteTriggerDocument, baseOptions);
      }
export type DeleteTriggerMutationHookResult = ReturnType<typeof useDeleteTriggerMutation>;
export type DeleteTriggerMutationResult = ApolloReactCommon.MutationResult<DeleteTriggerMutation>;
export type DeleteTriggerMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTriggerMutation, DeleteTriggerMutationVariables>;
export const ContactUsForUserLedTrialDocument = gql`
    mutation contactUsForUserLedTrial($clientMutationId: String) {
  contactUsForUserLedTrial(input: {clientMutationId: $clientMutationId}) {
    clientMutationId
  }
}
    `;
export type ContactUsForUserLedTrialMutationFn = ApolloReactCommon.MutationFunction<ContactUsForUserLedTrialMutation, ContactUsForUserLedTrialMutationVariables>;
export type ContactUsForUserLedTrialComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ContactUsForUserLedTrialMutation, ContactUsForUserLedTrialMutationVariables>, 'mutation'>;

    export const ContactUsForUserLedTrialComponent = (props: ContactUsForUserLedTrialComponentProps) => (
      <ApolloReactComponents.Mutation<ContactUsForUserLedTrialMutation, ContactUsForUserLedTrialMutationVariables> mutation={ContactUsForUserLedTrialDocument} {...props} />
    );
    
export type ContactUsForUserLedTrialProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ContactUsForUserLedTrialMutation, ContactUsForUserLedTrialMutationVariables>
    } & TChildProps;
export function withContactUsForUserLedTrial<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ContactUsForUserLedTrialMutation,
  ContactUsForUserLedTrialMutationVariables,
  ContactUsForUserLedTrialProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ContactUsForUserLedTrialMutation, ContactUsForUserLedTrialMutationVariables, ContactUsForUserLedTrialProps<TChildProps, TDataName>>(ContactUsForUserLedTrialDocument, {
      alias: 'contactUsForUserLedTrial',
      ...operationOptions
    });
};

/**
 * __useContactUsForUserLedTrialMutation__
 *
 * To run a mutation, you first call `useContactUsForUserLedTrialMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactUsForUserLedTrialMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactUsForUserLedTrialMutation, { data, loading, error }] = useContactUsForUserLedTrialMutation({
 *   variables: {
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useContactUsForUserLedTrialMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ContactUsForUserLedTrialMutation, ContactUsForUserLedTrialMutationVariables>) {
        return ApolloReactHooks.useMutation<ContactUsForUserLedTrialMutation, ContactUsForUserLedTrialMutationVariables>(ContactUsForUserLedTrialDocument, baseOptions);
      }
export type ContactUsForUserLedTrialMutationHookResult = ReturnType<typeof useContactUsForUserLedTrialMutation>;
export type ContactUsForUserLedTrialMutationResult = ApolloReactCommon.MutationResult<ContactUsForUserLedTrialMutation>;
export type ContactUsForUserLedTrialMutationOptions = ApolloReactCommon.BaseMutationOptions<ContactUsForUserLedTrialMutation, ContactUsForUserLedTrialMutationVariables>;
export const ContactUsForComputeHoursDocument = gql`
    mutation contactUsForComputeHours($clientMutationId: String) {
  contactUsForComputeHours(input: {clientMutationId: $clientMutationId}) {
    clientMutationId
  }
}
    `;
export type ContactUsForComputeHoursMutationFn = ApolloReactCommon.MutationFunction<ContactUsForComputeHoursMutation, ContactUsForComputeHoursMutationVariables>;
export type ContactUsForComputeHoursComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ContactUsForComputeHoursMutation, ContactUsForComputeHoursMutationVariables>, 'mutation'>;

    export const ContactUsForComputeHoursComponent = (props: ContactUsForComputeHoursComponentProps) => (
      <ApolloReactComponents.Mutation<ContactUsForComputeHoursMutation, ContactUsForComputeHoursMutationVariables> mutation={ContactUsForComputeHoursDocument} {...props} />
    );
    
export type ContactUsForComputeHoursProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ContactUsForComputeHoursMutation, ContactUsForComputeHoursMutationVariables>
    } & TChildProps;
export function withContactUsForComputeHours<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ContactUsForComputeHoursMutation,
  ContactUsForComputeHoursMutationVariables,
  ContactUsForComputeHoursProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ContactUsForComputeHoursMutation, ContactUsForComputeHoursMutationVariables, ContactUsForComputeHoursProps<TChildProps, TDataName>>(ContactUsForComputeHoursDocument, {
      alias: 'contactUsForComputeHours',
      ...operationOptions
    });
};

/**
 * __useContactUsForComputeHoursMutation__
 *
 * To run a mutation, you first call `useContactUsForComputeHoursMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactUsForComputeHoursMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactUsForComputeHoursMutation, { data, loading, error }] = useContactUsForComputeHoursMutation({
 *   variables: {
 *      clientMutationId: // value for 'clientMutationId'
 *   },
 * });
 */
export function useContactUsForComputeHoursMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ContactUsForComputeHoursMutation, ContactUsForComputeHoursMutationVariables>) {
        return ApolloReactHooks.useMutation<ContactUsForComputeHoursMutation, ContactUsForComputeHoursMutationVariables>(ContactUsForComputeHoursDocument, baseOptions);
      }
export type ContactUsForComputeHoursMutationHookResult = ReturnType<typeof useContactUsForComputeHoursMutation>;
export type ContactUsForComputeHoursMutationResult = ApolloReactCommon.MutationResult<ContactUsForComputeHoursMutation>;
export type ContactUsForComputeHoursMutationOptions = ApolloReactCommon.BaseMutationOptions<ContactUsForComputeHoursMutation, ContactUsForComputeHoursMutationVariables>;
export const UserProjectsDocument = gql`
    query UserProjects($userName: String!, $includeReports: Boolean!, $first: Int = 10000, $pattern: String) {
  user(userName: $userName) {
    id
    teams {
      edges {
        node {
          id
          name
          projects(order: "-created_at", first: $first, pattern: $pattern) {
            edges {
              node {
                id
                name
                entityName
                reports: allViews(viewType: "runs") @include(if: $includeReports) {
                  edges {
                    node {
                      id
                      ...ViewFragmentMetadata
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
    ${ViewFragmentMetadataFragmentDoc}`;
export type UserProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserProjectsQuery, UserProjectsQueryVariables>, 'query'> & ({ variables: UserProjectsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const UserProjectsComponent = (props: UserProjectsComponentProps) => (
      <ApolloReactComponents.Query<UserProjectsQuery, UserProjectsQueryVariables> query={UserProjectsDocument} {...props} />
    );
    
export type UserProjectsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UserProjectsQuery, UserProjectsQueryVariables>
    } & TChildProps;
export function withUserProjects<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserProjectsQuery,
  UserProjectsQueryVariables,
  UserProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, UserProjectsQuery, UserProjectsQueryVariables, UserProjectsProps<TChildProps, TDataName>>(UserProjectsDocument, {
      alias: 'userProjects',
      ...operationOptions
    });
};

/**
 * __useUserProjectsQuery__
 *
 * To run a query within a React component, call `useUserProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserProjectsQuery({
 *   variables: {
 *      userName: // value for 'userName'
 *      includeReports: // value for 'includeReports'
 *      first: // value for 'first'
 *      pattern: // value for 'pattern'
 *   },
 * });
 */
export function useUserProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserProjectsQuery, UserProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserProjectsQuery, UserProjectsQueryVariables>(UserProjectsDocument, baseOptions);
      }
export function useUserProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserProjectsQuery, UserProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserProjectsQuery, UserProjectsQueryVariables>(UserProjectsDocument, baseOptions);
        }
export type UserProjectsQueryHookResult = ReturnType<typeof useUserProjectsQuery>;
export type UserProjectsLazyQueryHookResult = ReturnType<typeof useUserProjectsLazyQuery>;
export type UserProjectsQueryResult = ApolloReactCommon.QueryResult<UserProjectsQuery, UserProjectsQueryVariables>;
export const AllTeamProjectsDocument = gql`
    query AllTeamProjects($entityName: String!, $first: Int = 10000) {
  projects(first: $first, entityName: $entityName) {
    edges {
      node {
        id
        name
        entityName
        totalRuns
        lastActive
        computeHours
      }
    }
    pageInfo {
      hasNextPage
      endCursor
    }
  }
}
    `;
export type AllTeamProjectsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AllTeamProjectsQuery, AllTeamProjectsQueryVariables>, 'query'> & ({ variables: AllTeamProjectsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AllTeamProjectsComponent = (props: AllTeamProjectsComponentProps) => (
      <ApolloReactComponents.Query<AllTeamProjectsQuery, AllTeamProjectsQueryVariables> query={AllTeamProjectsDocument} {...props} />
    );
    
export type AllTeamProjectsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AllTeamProjectsQuery, AllTeamProjectsQueryVariables>
    } & TChildProps;
export function withAllTeamProjects<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AllTeamProjectsQuery,
  AllTeamProjectsQueryVariables,
  AllTeamProjectsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AllTeamProjectsQuery, AllTeamProjectsQueryVariables, AllTeamProjectsProps<TChildProps, TDataName>>(AllTeamProjectsDocument, {
      alias: 'allTeamProjects',
      ...operationOptions
    });
};

/**
 * __useAllTeamProjectsQuery__
 *
 * To run a query within a React component, call `useAllTeamProjectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTeamProjectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTeamProjectsQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useAllTeamProjectsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AllTeamProjectsQuery, AllTeamProjectsQueryVariables>) {
        return ApolloReactHooks.useQuery<AllTeamProjectsQuery, AllTeamProjectsQueryVariables>(AllTeamProjectsDocument, baseOptions);
      }
export function useAllTeamProjectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AllTeamProjectsQuery, AllTeamProjectsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AllTeamProjectsQuery, AllTeamProjectsQueryVariables>(AllTeamProjectsDocument, baseOptions);
        }
export type AllTeamProjectsQueryHookResult = ReturnType<typeof useAllTeamProjectsQuery>;
export type AllTeamProjectsLazyQueryHookResult = ReturnType<typeof useAllTeamProjectsLazyQuery>;
export type AllTeamProjectsQueryResult = ApolloReactCommon.QueryResult<AllTeamProjectsQuery, AllTeamProjectsQueryVariables>;
export const UserReportsDocument = gql`
    query UserReports {
  viewer {
    id
    username
    views(viewType: "runs") {
      edges {
        node {
          id
          createdAt
          updatedAt
          name
          displayName
          description
          previewUrl
          spec
          viewCount
          project {
            id
            name
            entityName
            access
          }
        }
      }
    }
  }
}
    `;
export type UserReportsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<UserReportsQuery, UserReportsQueryVariables>, 'query'>;

    export const UserReportsComponent = (props: UserReportsComponentProps) => (
      <ApolloReactComponents.Query<UserReportsQuery, UserReportsQueryVariables> query={UserReportsDocument} {...props} />
    );
    
export type UserReportsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UserReportsQuery, UserReportsQueryVariables>
    } & TChildProps;
export function withUserReports<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserReportsQuery,
  UserReportsQueryVariables,
  UserReportsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, UserReportsQuery, UserReportsQueryVariables, UserReportsProps<TChildProps, TDataName>>(UserReportsDocument, {
      alias: 'userReports',
      ...operationOptions
    });
};

/**
 * __useUserReportsQuery__
 *
 * To run a query within a React component, call `useUserReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserReportsQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserReportsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<UserReportsQuery, UserReportsQueryVariables>) {
        return ApolloReactHooks.useQuery<UserReportsQuery, UserReportsQueryVariables>(UserReportsDocument, baseOptions);
      }
export function useUserReportsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<UserReportsQuery, UserReportsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<UserReportsQuery, UserReportsQueryVariables>(UserReportsDocument, baseOptions);
        }
export type UserReportsQueryHookResult = ReturnType<typeof useUserReportsQuery>;
export type UserReportsLazyQueryHookResult = ReturnType<typeof useUserReportsLazyQuery>;
export type UserReportsQueryResult = ApolloReactCommon.QueryResult<UserReportsQuery, UserReportsQueryVariables>;
export const Vega2PanelDocument = gql`
    query Vega2Panel($projectName: String!, $entityName: String, $id: ID!, $fetchSingle: Boolean!) {
  project(name: $projectName, entityName: $entityName) {
    id
    allViews(viewType: "vega2-panel") {
      edges {
        node {
          id
          ...ViewFragment
        }
      }
    }
  }
  view(id: $id, type: "vega2-panel") @include(if: $fetchSingle) {
    id
    ...ViewFragment
  }
}
    ${ViewFragmentFragmentDoc}`;
export type Vega2PanelComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<Vega2PanelQuery, Vega2PanelQueryVariables>, 'query'> & ({ variables: Vega2PanelQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const Vega2PanelComponent = (props: Vega2PanelComponentProps) => (
      <ApolloReactComponents.Query<Vega2PanelQuery, Vega2PanelQueryVariables> query={Vega2PanelDocument} {...props} />
    );
    
export type Vega2PanelProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<Vega2PanelQuery, Vega2PanelQueryVariables>
    } & TChildProps;
export function withVega2Panel<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  Vega2PanelQuery,
  Vega2PanelQueryVariables,
  Vega2PanelProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, Vega2PanelQuery, Vega2PanelQueryVariables, Vega2PanelProps<TChildProps, TDataName>>(Vega2PanelDocument, {
      alias: 'vega2Panel',
      ...operationOptions
    });
};

/**
 * __useVega2PanelQuery__
 *
 * To run a query within a React component, call `useVega2PanelQuery` and pass it any options that fit your needs.
 * When your component renders, `useVega2PanelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVega2PanelQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      id: // value for 'id'
 *      fetchSingle: // value for 'fetchSingle'
 *   },
 * });
 */
export function useVega2PanelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Vega2PanelQuery, Vega2PanelQueryVariables>) {
        return ApolloReactHooks.useQuery<Vega2PanelQuery, Vega2PanelQueryVariables>(Vega2PanelDocument, baseOptions);
      }
export function useVega2PanelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Vega2PanelQuery, Vega2PanelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Vega2PanelQuery, Vega2PanelQueryVariables>(Vega2PanelDocument, baseOptions);
        }
export type Vega2PanelQueryHookResult = ReturnType<typeof useVega2PanelQuery>;
export type Vega2PanelLazyQueryHookResult = ReturnType<typeof useVega2PanelLazyQuery>;
export type Vega2PanelQueryResult = ApolloReactCommon.QueryResult<Vega2PanelQuery, Vega2PanelQueryVariables>;
export const VegaPanelDocument = gql`
    query VegaPanel($projectName: String!, $entityName: String, $id: ID!, $fetchSingle: Boolean!) {
  project(name: $projectName, entityName: $entityName) {
    id
    allViews(viewType: "vega-panel") {
      edges {
        node {
          id
          ...ViewFragment
        }
      }
    }
  }
  view(id: $id, type: "vega-panel") @include(if: $fetchSingle) {
    id
    ...ViewFragment
  }
}
    ${ViewFragmentFragmentDoc}`;
export type VegaPanelComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<VegaPanelQuery, VegaPanelQueryVariables>, 'query'> & ({ variables: VegaPanelQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const VegaPanelComponent = (props: VegaPanelComponentProps) => (
      <ApolloReactComponents.Query<VegaPanelQuery, VegaPanelQueryVariables> query={VegaPanelDocument} {...props} />
    );
    
export type VegaPanelProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<VegaPanelQuery, VegaPanelQueryVariables>
    } & TChildProps;
export function withVegaPanel<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  VegaPanelQuery,
  VegaPanelQueryVariables,
  VegaPanelProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, VegaPanelQuery, VegaPanelQueryVariables, VegaPanelProps<TChildProps, TDataName>>(VegaPanelDocument, {
      alias: 'vegaPanel',
      ...operationOptions
    });
};

/**
 * __useVegaPanelQuery__
 *
 * To run a query within a React component, call `useVegaPanelQuery` and pass it any options that fit your needs.
 * When your component renders, `useVegaPanelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVegaPanelQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      id: // value for 'id'
 *      fetchSingle: // value for 'fetchSingle'
 *   },
 * });
 */
export function useVegaPanelQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<VegaPanelQuery, VegaPanelQueryVariables>) {
        return ApolloReactHooks.useQuery<VegaPanelQuery, VegaPanelQueryVariables>(VegaPanelDocument, baseOptions);
      }
export function useVegaPanelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<VegaPanelQuery, VegaPanelQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<VegaPanelQuery, VegaPanelQueryVariables>(VegaPanelDocument, baseOptions);
        }
export type VegaPanelQueryHookResult = ReturnType<typeof useVegaPanelQuery>;
export type VegaPanelLazyQueryHookResult = ReturnType<typeof useVegaPanelLazyQuery>;
export type VegaPanelQueryResult = ApolloReactCommon.QueryResult<VegaPanelQuery, VegaPanelQueryVariables>;
export const ViewerApiKeyDocument = gql`
    query ViewerApiKey {
  viewer {
    id
    apiKey
  }
}
    `;
export type ViewerApiKeyComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewerApiKeyQuery, ViewerApiKeyQueryVariables>, 'query'>;

    export const ViewerApiKeyComponent = (props: ViewerApiKeyComponentProps) => (
      <ApolloReactComponents.Query<ViewerApiKeyQuery, ViewerApiKeyQueryVariables> query={ViewerApiKeyDocument} {...props} />
    );
    
export type ViewerApiKeyProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewerApiKeyQuery, ViewerApiKeyQueryVariables>
    } & TChildProps;
export function withViewerApiKey<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewerApiKeyQuery,
  ViewerApiKeyQueryVariables,
  ViewerApiKeyProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewerApiKeyQuery, ViewerApiKeyQueryVariables, ViewerApiKeyProps<TChildProps, TDataName>>(ViewerApiKeyDocument, {
      alias: 'viewerApiKey',
      ...operationOptions
    });
};

/**
 * __useViewerApiKeyQuery__
 *
 * To run a query within a React component, call `useViewerApiKeyQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerApiKeyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerApiKeyQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerApiKeyQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewerApiKeyQuery, ViewerApiKeyQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewerApiKeyQuery, ViewerApiKeyQueryVariables>(ViewerApiKeyDocument, baseOptions);
      }
export function useViewerApiKeyLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewerApiKeyQuery, ViewerApiKeyQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewerApiKeyQuery, ViewerApiKeyQueryVariables>(ViewerApiKeyDocument, baseOptions);
        }
export type ViewerApiKeyQueryHookResult = ReturnType<typeof useViewerApiKeyQuery>;
export type ViewerApiKeyLazyQueryHookResult = ReturnType<typeof useViewerApiKeyLazyQuery>;
export type ViewerApiKeyQueryResult = ApolloReactCommon.QueryResult<ViewerApiKeyQuery, ViewerApiKeyQueryVariables>;
export const ViewerApiKeysDocument = gql`
    query ViewerApiKeys {
  viewer {
    id
    apiKeys {
      edges {
        node {
          id
          name
        }
      }
    }
  }
}
    `;
export type ViewerApiKeysComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewerApiKeysQuery, ViewerApiKeysQueryVariables>, 'query'>;

    export const ViewerApiKeysComponent = (props: ViewerApiKeysComponentProps) => (
      <ApolloReactComponents.Query<ViewerApiKeysQuery, ViewerApiKeysQueryVariables> query={ViewerApiKeysDocument} {...props} />
    );
    
export type ViewerApiKeysProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewerApiKeysQuery, ViewerApiKeysQueryVariables>
    } & TChildProps;
export function withViewerApiKeys<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewerApiKeysQuery,
  ViewerApiKeysQueryVariables,
  ViewerApiKeysProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewerApiKeysQuery, ViewerApiKeysQueryVariables, ViewerApiKeysProps<TChildProps, TDataName>>(ViewerApiKeysDocument, {
      alias: 'viewerApiKeys',
      ...operationOptions
    });
};

/**
 * __useViewerApiKeysQuery__
 *
 * To run a query within a React component, call `useViewerApiKeysQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewerApiKeysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewerApiKeysQuery({
 *   variables: {
 *   },
 * });
 */
export function useViewerApiKeysQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewerApiKeysQuery, ViewerApiKeysQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewerApiKeysQuery, ViewerApiKeysQueryVariables>(ViewerApiKeysDocument, baseOptions);
      }
export function useViewerApiKeysLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewerApiKeysQuery, ViewerApiKeysQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewerApiKeysQuery, ViewerApiKeysQueryVariables>(ViewerApiKeysDocument, baseOptions);
        }
export type ViewerApiKeysQueryHookResult = ReturnType<typeof useViewerApiKeysQuery>;
export type ViewerApiKeysLazyQueryHookResult = ReturnType<typeof useViewerApiKeysLazyQuery>;
export type ViewerApiKeysQueryResult = ApolloReactCommon.QueryResult<ViewerApiKeysQuery, ViewerApiKeysQueryVariables>;
export const WandbConfigDocument = gql`
    query WandbConfig($projectName: String!, $entityName: String!, $filters: JSONString, $limit: Int = 100, $order: String) {
  project(name: $projectName, entityName: $entityName) {
    id
    runs(filters: $filters, first: $limit, order: $order) {
      edges {
        node {
          id
          wandbConfig
        }
      }
    }
  }
}
    `;
export type WandbConfigComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<WandbConfigQuery, WandbConfigQueryVariables>, 'query'> & ({ variables: WandbConfigQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const WandbConfigComponent = (props: WandbConfigComponentProps) => (
      <ApolloReactComponents.Query<WandbConfigQuery, WandbConfigQueryVariables> query={WandbConfigDocument} {...props} />
    );
    
export type WandbConfigProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<WandbConfigQuery, WandbConfigQueryVariables>
    } & TChildProps;
export function withWandbConfig<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  WandbConfigQuery,
  WandbConfigQueryVariables,
  WandbConfigProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, WandbConfigQuery, WandbConfigQueryVariables, WandbConfigProps<TChildProps, TDataName>>(WandbConfigDocument, {
      alias: 'wandbConfig',
      ...operationOptions
    });
};

/**
 * __useWandbConfigQuery__
 *
 * To run a query within a React component, call `useWandbConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useWandbConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWandbConfigQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      filters: // value for 'filters'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useWandbConfigQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<WandbConfigQuery, WandbConfigQueryVariables>) {
        return ApolloReactHooks.useQuery<WandbConfigQuery, WandbConfigQueryVariables>(WandbConfigDocument, baseOptions);
      }
export function useWandbConfigLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<WandbConfigQuery, WandbConfigQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<WandbConfigQuery, WandbConfigQueryVariables>(WandbConfigDocument, baseOptions);
        }
export type WandbConfigQueryHookResult = ReturnType<typeof useWandbConfigQuery>;
export type WandbConfigLazyQueryHookResult = ReturnType<typeof useWandbConfigLazyQuery>;
export type WandbConfigQueryResult = ApolloReactCommon.QueryResult<WandbConfigQuery, WandbConfigQueryVariables>;
export const ServerPollingOkDocument = gql`
    query ServerPollingOk {
  serverInfo {
    pollingOK
  }
}
    `;
export type ServerPollingOkComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ServerPollingOkQuery, ServerPollingOkQueryVariables>, 'query'>;

    export const ServerPollingOkComponent = (props: ServerPollingOkComponentProps) => (
      <ApolloReactComponents.Query<ServerPollingOkQuery, ServerPollingOkQueryVariables> query={ServerPollingOkDocument} {...props} />
    );
    
export type ServerPollingOkProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ServerPollingOkQuery, ServerPollingOkQueryVariables>
    } & TChildProps;
export function withServerPollingOk<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ServerPollingOkQuery,
  ServerPollingOkQueryVariables,
  ServerPollingOkProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ServerPollingOkQuery, ServerPollingOkQueryVariables, ServerPollingOkProps<TChildProps, TDataName>>(ServerPollingOkDocument, {
      alias: 'serverPollingOk',
      ...operationOptions
    });
};

/**
 * __useServerPollingOkQuery__
 *
 * To run a query within a React component, call `useServerPollingOkQuery` and pass it any options that fit your needs.
 * When your component renders, `useServerPollingOkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServerPollingOkQuery({
 *   variables: {
 *   },
 * });
 */
export function useServerPollingOkQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ServerPollingOkQuery, ServerPollingOkQueryVariables>) {
        return ApolloReactHooks.useQuery<ServerPollingOkQuery, ServerPollingOkQueryVariables>(ServerPollingOkDocument, baseOptions);
      }
export function useServerPollingOkLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ServerPollingOkQuery, ServerPollingOkQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ServerPollingOkQuery, ServerPollingOkQueryVariables>(ServerPollingOkDocument, baseOptions);
        }
export type ServerPollingOkQueryHookResult = ReturnType<typeof useServerPollingOkQuery>;
export type ServerPollingOkLazyQueryHookResult = ReturnType<typeof useServerPollingOkLazyQuery>;
export type ServerPollingOkQueryResult = ApolloReactCommon.QueryResult<ServerPollingOkQuery, ServerPollingOkQueryVariables>;
export const AccountPrivilegesDocument = gql`
    query AccountPrivileges($organizationId: ID!) {
  organization(id: $organizationId) {
    id
    subscriptions {
      id
      privileges
      plan {
        id
        planType
      }
    }
  }
}
    `;
export type AccountPrivilegesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<AccountPrivilegesQuery, AccountPrivilegesQueryVariables>, 'query'> & ({ variables: AccountPrivilegesQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const AccountPrivilegesComponent = (props: AccountPrivilegesComponentProps) => (
      <ApolloReactComponents.Query<AccountPrivilegesQuery, AccountPrivilegesQueryVariables> query={AccountPrivilegesDocument} {...props} />
    );
    
export type AccountPrivilegesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AccountPrivilegesQuery, AccountPrivilegesQueryVariables>
    } & TChildProps;
export function withAccountPrivileges<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AccountPrivilegesQuery,
  AccountPrivilegesQueryVariables,
  AccountPrivilegesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AccountPrivilegesQuery, AccountPrivilegesQueryVariables, AccountPrivilegesProps<TChildProps, TDataName>>(AccountPrivilegesDocument, {
      alias: 'accountPrivileges',
      ...operationOptions
    });
};

/**
 * __useAccountPrivilegesQuery__
 *
 * To run a query within a React component, call `useAccountPrivilegesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAccountPrivilegesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAccountPrivilegesQuery({
 *   variables: {
 *      organizationId: // value for 'organizationId'
 *   },
 * });
 */
export function useAccountPrivilegesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<AccountPrivilegesQuery, AccountPrivilegesQueryVariables>) {
        return ApolloReactHooks.useQuery<AccountPrivilegesQuery, AccountPrivilegesQueryVariables>(AccountPrivilegesDocument, baseOptions);
      }
export function useAccountPrivilegesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<AccountPrivilegesQuery, AccountPrivilegesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<AccountPrivilegesQuery, AccountPrivilegesQueryVariables>(AccountPrivilegesDocument, baseOptions);
        }
export type AccountPrivilegesQueryHookResult = ReturnType<typeof useAccountPrivilegesQuery>;
export type AccountPrivilegesLazyQueryHookResult = ReturnType<typeof useAccountPrivilegesLazyQuery>;
export type AccountPrivilegesQueryResult = ApolloReactCommon.QueryResult<AccountPrivilegesQuery, AccountPrivilegesQueryVariables>;
export const TeamOrgDocument = gql`
    query TeamOrg($entityName: String!) {
  entity(name: $entityName) {
    id
    name
    organization {
      id
      name
    }
  }
}
    `;
export type TeamOrgComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TeamOrgQuery, TeamOrgQueryVariables>, 'query'> & ({ variables: TeamOrgQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TeamOrgComponent = (props: TeamOrgComponentProps) => (
      <ApolloReactComponents.Query<TeamOrgQuery, TeamOrgQueryVariables> query={TeamOrgDocument} {...props} />
    );
    
export type TeamOrgProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TeamOrgQuery, TeamOrgQueryVariables>
    } & TChildProps;
export function withTeamOrg<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TeamOrgQuery,
  TeamOrgQueryVariables,
  TeamOrgProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TeamOrgQuery, TeamOrgQueryVariables, TeamOrgProps<TChildProps, TDataName>>(TeamOrgDocument, {
      alias: 'teamOrg',
      ...operationOptions
    });
};

/**
 * __useTeamOrgQuery__
 *
 * To run a query within a React component, call `useTeamOrgQuery` and pass it any options that fit your needs.
 * When your component renders, `useTeamOrgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTeamOrgQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useTeamOrgQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TeamOrgQuery, TeamOrgQueryVariables>) {
        return ApolloReactHooks.useQuery<TeamOrgQuery, TeamOrgQueryVariables>(TeamOrgDocument, baseOptions);
      }
export function useTeamOrgLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TeamOrgQuery, TeamOrgQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TeamOrgQuery, TeamOrgQueryVariables>(TeamOrgDocument, baseOptions);
        }
export type TeamOrgQueryHookResult = ReturnType<typeof useTeamOrgQuery>;
export type TeamOrgLazyQueryHookResult = ReturnType<typeof useTeamOrgLazyQuery>;
export type TeamOrgQueryResult = ApolloReactCommon.QueryResult<TeamOrgQuery, TeamOrgQueryVariables>;
export const OrganizationSubscriptionUpdateAdditionalInfoDocument = gql`
    query OrganizationSubscriptionUpdateAdditionalInfo($orgName: String!) {
  organization(name: $orgName) {
    id
    weaveEnterpriseSubscription {
      billingPeriodStart
      billingPeriodEnd
    }
  }
}
    `;
export type OrganizationSubscriptionUpdateAdditionalInfoComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<OrganizationSubscriptionUpdateAdditionalInfoQuery, OrganizationSubscriptionUpdateAdditionalInfoQueryVariables>, 'query'> & ({ variables: OrganizationSubscriptionUpdateAdditionalInfoQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const OrganizationSubscriptionUpdateAdditionalInfoComponent = (props: OrganizationSubscriptionUpdateAdditionalInfoComponentProps) => (
      <ApolloReactComponents.Query<OrganizationSubscriptionUpdateAdditionalInfoQuery, OrganizationSubscriptionUpdateAdditionalInfoQueryVariables> query={OrganizationSubscriptionUpdateAdditionalInfoDocument} {...props} />
    );
    
export type OrganizationSubscriptionUpdateAdditionalInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<OrganizationSubscriptionUpdateAdditionalInfoQuery, OrganizationSubscriptionUpdateAdditionalInfoQueryVariables>
    } & TChildProps;
export function withOrganizationSubscriptionUpdateAdditionalInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  OrganizationSubscriptionUpdateAdditionalInfoQuery,
  OrganizationSubscriptionUpdateAdditionalInfoQueryVariables,
  OrganizationSubscriptionUpdateAdditionalInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, OrganizationSubscriptionUpdateAdditionalInfoQuery, OrganizationSubscriptionUpdateAdditionalInfoQueryVariables, OrganizationSubscriptionUpdateAdditionalInfoProps<TChildProps, TDataName>>(OrganizationSubscriptionUpdateAdditionalInfoDocument, {
      alias: 'organizationSubscriptionUpdateAdditionalInfo',
      ...operationOptions
    });
};

/**
 * __useOrganizationSubscriptionUpdateAdditionalInfoQuery__
 *
 * To run a query within a React component, call `useOrganizationSubscriptionUpdateAdditionalInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useOrganizationSubscriptionUpdateAdditionalInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOrganizationSubscriptionUpdateAdditionalInfoQuery({
 *   variables: {
 *      orgName: // value for 'orgName'
 *   },
 * });
 */
export function useOrganizationSubscriptionUpdateAdditionalInfoQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<OrganizationSubscriptionUpdateAdditionalInfoQuery, OrganizationSubscriptionUpdateAdditionalInfoQueryVariables>) {
        return ApolloReactHooks.useQuery<OrganizationSubscriptionUpdateAdditionalInfoQuery, OrganizationSubscriptionUpdateAdditionalInfoQueryVariables>(OrganizationSubscriptionUpdateAdditionalInfoDocument, baseOptions);
      }
export function useOrganizationSubscriptionUpdateAdditionalInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<OrganizationSubscriptionUpdateAdditionalInfoQuery, OrganizationSubscriptionUpdateAdditionalInfoQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<OrganizationSubscriptionUpdateAdditionalInfoQuery, OrganizationSubscriptionUpdateAdditionalInfoQueryVariables>(OrganizationSubscriptionUpdateAdditionalInfoDocument, baseOptions);
        }
export type OrganizationSubscriptionUpdateAdditionalInfoQueryHookResult = ReturnType<typeof useOrganizationSubscriptionUpdateAdditionalInfoQuery>;
export type OrganizationSubscriptionUpdateAdditionalInfoLazyQueryHookResult = ReturnType<typeof useOrganizationSubscriptionUpdateAdditionalInfoLazyQuery>;
export type OrganizationSubscriptionUpdateAdditionalInfoQueryResult = ApolloReactCommon.QueryResult<OrganizationSubscriptionUpdateAdditionalInfoQuery, OrganizationSubscriptionUpdateAdditionalInfoQueryVariables>;
export const DiscussionThreadPageDocument = gql`
    query DiscussionThreadPage($threadID: ID!) {
  discussionThread(id: $threadID) {
    id
    createdAt
    updatedAt
    deletedAt
    title
    body
    upvotes
    poster {
      id
      photoUrl
      name
    }
    comments {
      totalCount
      pageInfo {
        hasNextPage
        hasPreviousPage
      }
      edges {
        node {
          id
          createdAt
          updatedAt
          deletedAt
          body
          isParent
          upvotes
          poster {
            id
            photoUrl
            name
          }
        }
      }
    }
  }
  viewer {
    id
  }
}
    `;
export type DiscussionThreadPageComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<DiscussionThreadPageQuery, DiscussionThreadPageQueryVariables>, 'query'> & ({ variables: DiscussionThreadPageQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const DiscussionThreadPageComponent = (props: DiscussionThreadPageComponentProps) => (
      <ApolloReactComponents.Query<DiscussionThreadPageQuery, DiscussionThreadPageQueryVariables> query={DiscussionThreadPageDocument} {...props} />
    );
    
export type DiscussionThreadPageProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<DiscussionThreadPageQuery, DiscussionThreadPageQueryVariables>
    } & TChildProps;
export function withDiscussionThreadPage<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DiscussionThreadPageQuery,
  DiscussionThreadPageQueryVariables,
  DiscussionThreadPageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, DiscussionThreadPageQuery, DiscussionThreadPageQueryVariables, DiscussionThreadPageProps<TChildProps, TDataName>>(DiscussionThreadPageDocument, {
      alias: 'discussionThreadPage',
      ...operationOptions
    });
};

/**
 * __useDiscussionThreadPageQuery__
 *
 * To run a query within a React component, call `useDiscussionThreadPageQuery` and pass it any options that fit your needs.
 * When your component renders, `useDiscussionThreadPageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDiscussionThreadPageQuery({
 *   variables: {
 *      threadID: // value for 'threadID'
 *   },
 * });
 */
export function useDiscussionThreadPageQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<DiscussionThreadPageQuery, DiscussionThreadPageQueryVariables>) {
        return ApolloReactHooks.useQuery<DiscussionThreadPageQuery, DiscussionThreadPageQueryVariables>(DiscussionThreadPageDocument, baseOptions);
      }
export function useDiscussionThreadPageLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<DiscussionThreadPageQuery, DiscussionThreadPageQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<DiscussionThreadPageQuery, DiscussionThreadPageQueryVariables>(DiscussionThreadPageDocument, baseOptions);
        }
export type DiscussionThreadPageQueryHookResult = ReturnType<typeof useDiscussionThreadPageQuery>;
export type DiscussionThreadPageLazyQueryHookResult = ReturnType<typeof useDiscussionThreadPageLazyQuery>;
export type DiscussionThreadPageQueryResult = ApolloReactCommon.QueryResult<DiscussionThreadPageQuery, DiscussionThreadPageQueryVariables>;
export const SubmitRunByIdQueryDocument = gql`
    query SubmitRunByIdQuery($entityName: String!, $projectName: String!, $runName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    name
    entityName
    run(name: $runName) {
      id
      name
      displayName
      summaryMetrics
      benchmarkRun {
        id
        results
      }
    }
  }
}
    `;
export type SubmitRunByIdQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SubmitRunByIdQueryQuery, SubmitRunByIdQueryQueryVariables>, 'query'> & ({ variables: SubmitRunByIdQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const SubmitRunByIdQueryComponent = (props: SubmitRunByIdQueryComponentProps) => (
      <ApolloReactComponents.Query<SubmitRunByIdQueryQuery, SubmitRunByIdQueryQueryVariables> query={SubmitRunByIdQueryDocument} {...props} />
    );
    
export type SubmitRunByIdQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SubmitRunByIdQueryQuery, SubmitRunByIdQueryQueryVariables>
    } & TChildProps;
export function withSubmitRunByIdQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SubmitRunByIdQueryQuery,
  SubmitRunByIdQueryQueryVariables,
  SubmitRunByIdQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SubmitRunByIdQueryQuery, SubmitRunByIdQueryQueryVariables, SubmitRunByIdQueryProps<TChildProps, TDataName>>(SubmitRunByIdQueryDocument, {
      alias: 'submitRunByIdQuery',
      ...operationOptions
    });
};

/**
 * __useSubmitRunByIdQueryQuery__
 *
 * To run a query within a React component, call `useSubmitRunByIdQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubmitRunByIdQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubmitRunByIdQueryQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      runName: // value for 'runName'
 *   },
 * });
 */
export function useSubmitRunByIdQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SubmitRunByIdQueryQuery, SubmitRunByIdQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<SubmitRunByIdQueryQuery, SubmitRunByIdQueryQueryVariables>(SubmitRunByIdQueryDocument, baseOptions);
      }
export function useSubmitRunByIdQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SubmitRunByIdQueryQuery, SubmitRunByIdQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SubmitRunByIdQueryQuery, SubmitRunByIdQueryQueryVariables>(SubmitRunByIdQueryDocument, baseOptions);
        }
export type SubmitRunByIdQueryQueryHookResult = ReturnType<typeof useSubmitRunByIdQueryQuery>;
export type SubmitRunByIdQueryLazyQueryHookResult = ReturnType<typeof useSubmitRunByIdQueryLazyQuery>;
export type SubmitRunByIdQueryQueryResult = ApolloReactCommon.QueryResult<SubmitRunByIdQueryQuery, SubmitRunByIdQueryQueryVariables>;
export const RunCsvDocument = gql`
    query RunCSV($projectName: String!, $entityName: String, $runName: String!, $fileName: String!) {
  project(name: $projectName, entityName: $entityName) {
    id
    name
    run(name: $runName) {
      id
      name
      benchmarkRun {
        id
        results
      }
      submissionFiles: files(first: 1, names: [$fileName]) {
        edges {
          node {
            id
            name
            md5
          }
        }
      }
    }
  }
}
    `;
export type RunCsvComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunCsvQuery, RunCsvQueryVariables>, 'query'> & ({ variables: RunCsvQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunCsvComponent = (props: RunCsvComponentProps) => (
      <ApolloReactComponents.Query<RunCsvQuery, RunCsvQueryVariables> query={RunCsvDocument} {...props} />
    );
    
export type RunCsvProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunCsvQuery, RunCsvQueryVariables>
    } & TChildProps;
export function withRunCsv<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunCsvQuery,
  RunCsvQueryVariables,
  RunCsvProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunCsvQuery, RunCsvQueryVariables, RunCsvProps<TChildProps, TDataName>>(RunCsvDocument, {
      alias: 'runCsv',
      ...operationOptions
    });
};

/**
 * __useRunCsvQuery__
 *
 * To run a query within a React component, call `useRunCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunCsvQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *      runName: // value for 'runName'
 *      fileName: // value for 'fileName'
 *   },
 * });
 */
export function useRunCsvQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunCsvQuery, RunCsvQueryVariables>) {
        return ApolloReactHooks.useQuery<RunCsvQuery, RunCsvQueryVariables>(RunCsvDocument, baseOptions);
      }
export function useRunCsvLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunCsvQuery, RunCsvQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunCsvQuery, RunCsvQueryVariables>(RunCsvDocument, baseOptions);
        }
export type RunCsvQueryHookResult = ReturnType<typeof useRunCsvQuery>;
export type RunCsvLazyQueryHookResult = ReturnType<typeof useRunCsvLazyQuery>;
export type RunCsvQueryResult = ApolloReactCommon.QueryResult<RunCsvQuery, RunCsvQueryVariables>;
export const UpsertLinkedBenchmarkDocument = gql`
    mutation upsertLinkedBenchmark($description: String, $entityName: String, $id: String, $name: String, $framework: String, $access: String, $views: JSONString, $linkedBenchmark: ID) {
  upsertModel(
    input: {description: $description, entityName: $entityName, id: $id, name: $name, framework: $framework, access: $access, views: $views, linkedBenchmark: $linkedBenchmark}
  ) {
    project {
      id
      name
      entityName
      description
      access
      views
      isBenchmark
      linkedBenchmark {
        id
        name
      }
    }
    inserted
  }
}
    `;
export type UpsertLinkedBenchmarkMutationFn = ApolloReactCommon.MutationFunction<UpsertLinkedBenchmarkMutation, UpsertLinkedBenchmarkMutationVariables>;
export type UpsertLinkedBenchmarkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertLinkedBenchmarkMutation, UpsertLinkedBenchmarkMutationVariables>, 'mutation'>;

    export const UpsertLinkedBenchmarkComponent = (props: UpsertLinkedBenchmarkComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertLinkedBenchmarkMutation, UpsertLinkedBenchmarkMutationVariables> mutation={UpsertLinkedBenchmarkDocument} {...props} />
    );
    
export type UpsertLinkedBenchmarkProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpsertLinkedBenchmarkMutation, UpsertLinkedBenchmarkMutationVariables>
    } & TChildProps;
export function withUpsertLinkedBenchmark<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpsertLinkedBenchmarkMutation,
  UpsertLinkedBenchmarkMutationVariables,
  UpsertLinkedBenchmarkProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpsertLinkedBenchmarkMutation, UpsertLinkedBenchmarkMutationVariables, UpsertLinkedBenchmarkProps<TChildProps, TDataName>>(UpsertLinkedBenchmarkDocument, {
      alias: 'upsertLinkedBenchmark',
      ...operationOptions
    });
};

/**
 * __useUpsertLinkedBenchmarkMutation__
 *
 * To run a mutation, you first call `useUpsertLinkedBenchmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertLinkedBenchmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertLinkedBenchmarkMutation, { data, loading, error }] = useUpsertLinkedBenchmarkMutation({
 *   variables: {
 *      description: // value for 'description'
 *      entityName: // value for 'entityName'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      framework: // value for 'framework'
 *      access: // value for 'access'
 *      views: // value for 'views'
 *      linkedBenchmark: // value for 'linkedBenchmark'
 *   },
 * });
 */
export function useUpsertLinkedBenchmarkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertLinkedBenchmarkMutation, UpsertLinkedBenchmarkMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertLinkedBenchmarkMutation, UpsertLinkedBenchmarkMutationVariables>(UpsertLinkedBenchmarkDocument, baseOptions);
      }
export type UpsertLinkedBenchmarkMutationHookResult = ReturnType<typeof useUpsertLinkedBenchmarkMutation>;
export type UpsertLinkedBenchmarkMutationResult = ApolloReactCommon.MutationResult<UpsertLinkedBenchmarkMutation>;
export type UpsertLinkedBenchmarkMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertLinkedBenchmarkMutation, UpsertLinkedBenchmarkMutationVariables>;
export const SimpleTestQueryDocument = gql`
    query SimpleTestQuery {
  serverInfo {
    pollingOK
  }
}
    `;
export type SimpleTestQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<SimpleTestQueryQuery, SimpleTestQueryQueryVariables>, 'query'>;

    export const SimpleTestQueryComponent = (props: SimpleTestQueryComponentProps) => (
      <ApolloReactComponents.Query<SimpleTestQueryQuery, SimpleTestQueryQueryVariables> query={SimpleTestQueryDocument} {...props} />
    );
    
export type SimpleTestQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SimpleTestQueryQuery, SimpleTestQueryQueryVariables>
    } & TChildProps;
export function withSimpleTestQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SimpleTestQueryQuery,
  SimpleTestQueryQueryVariables,
  SimpleTestQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SimpleTestQueryQuery, SimpleTestQueryQueryVariables, SimpleTestQueryProps<TChildProps, TDataName>>(SimpleTestQueryDocument, {
      alias: 'simpleTestQuery',
      ...operationOptions
    });
};

/**
 * __useSimpleTestQueryQuery__
 *
 * To run a query within a React component, call `useSimpleTestQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSimpleTestQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSimpleTestQueryQuery({
 *   variables: {
 *   },
 * });
 */
export function useSimpleTestQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<SimpleTestQueryQuery, SimpleTestQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<SimpleTestQueryQuery, SimpleTestQueryQueryVariables>(SimpleTestQueryDocument, baseOptions);
      }
export function useSimpleTestQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<SimpleTestQueryQuery, SimpleTestQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<SimpleTestQueryQuery, SimpleTestQueryQueryVariables>(SimpleTestQueryDocument, baseOptions);
        }
export type SimpleTestQueryQueryHookResult = ReturnType<typeof useSimpleTestQueryQuery>;
export type SimpleTestQueryLazyQueryHookResult = ReturnType<typeof useSimpleTestQueryLazyQuery>;
export type SimpleTestQueryQueryResult = ApolloReactCommon.QueryResult<SimpleTestQueryQuery, SimpleTestQueryQueryVariables>;
export const UpsertBenchmarkDocument = gql`
    mutation upsertBenchmark($description: String, $entityName: String!, $id: String, $name: String!, $access: String, $views: JSONString) {
  upsertModel(
    input: {description: $description, entityName: $entityName, id: $id, name: $name, access: $access, views: $views, isBenchmark: true}
  ) {
    project {
      id
      name
      entityName
      description
      access
      views
      isBenchmark
    }
    inserted
  }
}
    `;
export type UpsertBenchmarkMutationFn = ApolloReactCommon.MutationFunction<UpsertBenchmarkMutation, UpsertBenchmarkMutationVariables>;
export type UpsertBenchmarkComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertBenchmarkMutation, UpsertBenchmarkMutationVariables>, 'mutation'>;

    export const UpsertBenchmarkComponent = (props: UpsertBenchmarkComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertBenchmarkMutation, UpsertBenchmarkMutationVariables> mutation={UpsertBenchmarkDocument} {...props} />
    );
    
export type UpsertBenchmarkProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpsertBenchmarkMutation, UpsertBenchmarkMutationVariables>
    } & TChildProps;
export function withUpsertBenchmark<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpsertBenchmarkMutation,
  UpsertBenchmarkMutationVariables,
  UpsertBenchmarkProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpsertBenchmarkMutation, UpsertBenchmarkMutationVariables, UpsertBenchmarkProps<TChildProps, TDataName>>(UpsertBenchmarkDocument, {
      alias: 'upsertBenchmark',
      ...operationOptions
    });
};

/**
 * __useUpsertBenchmarkMutation__
 *
 * To run a mutation, you first call `useUpsertBenchmarkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertBenchmarkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertBenchmarkMutation, { data, loading, error }] = useUpsertBenchmarkMutation({
 *   variables: {
 *      description: // value for 'description'
 *      entityName: // value for 'entityName'
 *      id: // value for 'id'
 *      name: // value for 'name'
 *      access: // value for 'access'
 *      views: // value for 'views'
 *   },
 * });
 */
export function useUpsertBenchmarkMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertBenchmarkMutation, UpsertBenchmarkMutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertBenchmarkMutation, UpsertBenchmarkMutationVariables>(UpsertBenchmarkDocument, baseOptions);
      }
export type UpsertBenchmarkMutationHookResult = ReturnType<typeof useUpsertBenchmarkMutation>;
export type UpsertBenchmarkMutationResult = ApolloReactCommon.MutationResult<UpsertBenchmarkMutation>;
export type UpsertBenchmarkMutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertBenchmarkMutation, UpsertBenchmarkMutationVariables>;
export const TrendingReposDocument = gql`
    query TrendingRepos($from: DateTime!) {
  trendingRepos(from: $from) {
    totalCount
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    schema
    edges {
      node {
        row
      }
    }
  }
}
    `;
export type TrendingReposComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<TrendingReposQuery, TrendingReposQueryVariables>, 'query'> & ({ variables: TrendingReposQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const TrendingReposComponent = (props: TrendingReposComponentProps) => (
      <ApolloReactComponents.Query<TrendingReposQuery, TrendingReposQueryVariables> query={TrendingReposDocument} {...props} />
    );
    
export type TrendingReposProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TrendingReposQuery, TrendingReposQueryVariables>
    } & TChildProps;
export function withTrendingRepos<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TrendingReposQuery,
  TrendingReposQueryVariables,
  TrendingReposProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TrendingReposQuery, TrendingReposQueryVariables, TrendingReposProps<TChildProps, TDataName>>(TrendingReposDocument, {
      alias: 'trendingRepos',
      ...operationOptions
    });
};

/**
 * __useTrendingReposQuery__
 *
 * To run a query within a React component, call `useTrendingReposQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrendingReposQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrendingReposQuery({
 *   variables: {
 *      from: // value for 'from'
 *   },
 * });
 */
export function useTrendingReposQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<TrendingReposQuery, TrendingReposQueryVariables>) {
        return ApolloReactHooks.useQuery<TrendingReposQuery, TrendingReposQueryVariables>(TrendingReposDocument, baseOptions);
      }
export function useTrendingReposLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<TrendingReposQuery, TrendingReposQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<TrendingReposQuery, TrendingReposQueryVariables>(TrendingReposDocument, baseOptions);
        }
export type TrendingReposQueryHookResult = ReturnType<typeof useTrendingReposQuery>;
export type TrendingReposLazyQueryHookResult = ReturnType<typeof useTrendingReposLazyQuery>;
export type TrendingReposQueryResult = ApolloReactCommon.QueryResult<TrendingReposQuery, TrendingReposQueryVariables>;
export const RunsStateQueryDocument = gql`
    query RunsStateQuery($aggregationKeys: [String!], $configKeys: [String!], $enableAggregations: Boolean = false, $enableArtifactCounts: Boolean = false, $enableBasic: Boolean = true, $enableConfig: Boolean = false, $enableHistoryKeyInfo: Boolean = false, $enableSampledHistory: Boolean = false, $enableSummary: Boolean = false, $enableSystemMetrics: Boolean = true, $enableTags: Boolean = true, $enableWandb: Boolean = false, $entityName: String!, $filters: JSONString!, $groupKeys: [String!]!, $groupLevel: Int!, $limit: Int!, $order: String!, $projectName: String!, $sampledHistorySpecs: [JSONString!]!, $summaryKeys: [String!], $wandbKeys: [String!]) {
  project(name: $projectName, entityName: $entityName) {
    id
    runs(
      first: $limit
      order: $order
      filters: $filters
      groupKeys: $groupKeys
      groupLevel: $groupLevel
    ) {
      totalCount
      edges {
        node {
          id
          name
          projectId
          displayName
          updatedAt
          ...ArtifactCountsFragment @include(if: $enableArtifactCounts)
          ...RunStateBasicFragment @include(if: $enableBasic)
          aggregations(keys: $aggregationKeys) @include(if: $enableAggregations)
          config(keys: $configKeys) @include(if: $enableConfig)
          historyKeys(format: PLAINTEXT) @include(if: $enableHistoryKeyInfo)
          sampledHistory(specs: $sampledHistorySpecs, packVersion: 1) @include(if: $enableSampledHistory)
          summaryMetrics(keys: $summaryKeys) @include(if: $enableSummary)
          systemMetrics @include(if: $enableSystemMetrics)
          tags: tagColors @include(if: $enableTags) {
            id
            name
            colorIndex
          }
          wandbConfig(keys: $wandbKeys) @include(if: $enableWandb)
        }
      }
    }
  }
}
    ${ArtifactCountsFragmentFragmentDoc}
${RunStateBasicFragmentFragmentDoc}`;
export type RunsStateQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunsStateQueryQuery, RunsStateQueryQueryVariables>, 'query'> & ({ variables: RunsStateQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunsStateQueryComponent = (props: RunsStateQueryComponentProps) => (
      <ApolloReactComponents.Query<RunsStateQueryQuery, RunsStateQueryQueryVariables> query={RunsStateQueryDocument} {...props} />
    );
    
export type RunsStateQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunsStateQueryQuery, RunsStateQueryQueryVariables>
    } & TChildProps;
export function withRunsStateQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunsStateQueryQuery,
  RunsStateQueryQueryVariables,
  RunsStateQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunsStateQueryQuery, RunsStateQueryQueryVariables, RunsStateQueryProps<TChildProps, TDataName>>(RunsStateQueryDocument, {
      alias: 'runsStateQuery',
      ...operationOptions
    });
};

/**
 * __useRunsStateQueryQuery__
 *
 * To run a query within a React component, call `useRunsStateQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunsStateQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunsStateQueryQuery({
 *   variables: {
 *      aggregationKeys: // value for 'aggregationKeys'
 *      configKeys: // value for 'configKeys'
 *      enableAggregations: // value for 'enableAggregations'
 *      enableArtifactCounts: // value for 'enableArtifactCounts'
 *      enableBasic: // value for 'enableBasic'
 *      enableConfig: // value for 'enableConfig'
 *      enableHistoryKeyInfo: // value for 'enableHistoryKeyInfo'
 *      enableSampledHistory: // value for 'enableSampledHistory'
 *      enableSummary: // value for 'enableSummary'
 *      enableSystemMetrics: // value for 'enableSystemMetrics'
 *      enableTags: // value for 'enableTags'
 *      enableWandb: // value for 'enableWandb'
 *      entityName: // value for 'entityName'
 *      filters: // value for 'filters'
 *      groupKeys: // value for 'groupKeys'
 *      groupLevel: // value for 'groupLevel'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      projectName: // value for 'projectName'
 *      sampledHistorySpecs: // value for 'sampledHistorySpecs'
 *      summaryKeys: // value for 'summaryKeys'
 *      wandbKeys: // value for 'wandbKeys'
 *   },
 * });
 */
export function useRunsStateQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunsStateQueryQuery, RunsStateQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<RunsStateQueryQuery, RunsStateQueryQueryVariables>(RunsStateQueryDocument, baseOptions);
      }
export function useRunsStateQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunsStateQueryQuery, RunsStateQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunsStateQueryQuery, RunsStateQueryQueryVariables>(RunsStateQueryDocument, baseOptions);
        }
export type RunsStateQueryQueryHookResult = ReturnType<typeof useRunsStateQueryQuery>;
export type RunsStateQueryLazyQueryHookResult = ReturnType<typeof useRunsStateQueryLazyQuery>;
export type RunsStateQueryQueryResult = ApolloReactCommon.QueryResult<RunsStateQueryQuery, RunsStateQueryQueryVariables>;
export const RunsStateQueryWithProjectIdDocument = gql`
    query RunsStateQueryWithProjectId($aggregationKeys: [String!], $configKeys: [String!], $enableAggregations: Boolean = false, $enableArtifactCounts: Boolean = false, $enableBasic: Boolean = true, $enableConfig: Boolean = false, $enableHistoryKeyInfo: Boolean = false, $enableSampledHistory: Boolean = false, $enableSummary: Boolean = false, $enableSystemMetrics: Boolean = true, $enableTags: Boolean = true, $enableWandb: Boolean = false, $filters: JSONString!, $groupKeys: [String!]!, $groupLevel: Int!, $limit: Int!, $order: String!, $sampledHistorySpecs: [JSONString!]!, $summaryKeys: [String!], $wandbKeys: [String!], $internalId: ID!) {
  project(internalId: $internalId) {
    id
    runs(
      first: $limit
      order: $order
      filters: $filters
      groupKeys: $groupKeys
      groupLevel: $groupLevel
    ) {
      totalCount
      edges {
        node {
          id
          name
          projectId
          displayName
          updatedAt
          ...ArtifactCountsFragment @include(if: $enableArtifactCounts)
          ...RunStateBasicFragment @include(if: $enableBasic)
          aggregations(keys: $aggregationKeys) @include(if: $enableAggregations)
          config(keys: $configKeys) @include(if: $enableConfig)
          historyKeys(format: BITMAP) @include(if: $enableHistoryKeyInfo)
          sampledHistory(specs: $sampledHistorySpecs, packVersion: 1) @include(if: $enableSampledHistory)
          summaryMetrics(keys: $summaryKeys) @include(if: $enableSummary)
          systemMetrics @include(if: $enableSystemMetrics)
          tags: tagColors @include(if: $enableTags) {
            id
            name
            colorIndex
          }
          wandbConfig(keys: $wandbKeys) @include(if: $enableWandb)
        }
      }
    }
  }
}
    ${ArtifactCountsFragmentFragmentDoc}
${RunStateBasicFragmentFragmentDoc}`;
export type RunsStateQueryWithProjectIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunsStateQueryWithProjectIdQuery, RunsStateQueryWithProjectIdQueryVariables>, 'query'> & ({ variables: RunsStateQueryWithProjectIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunsStateQueryWithProjectIdComponent = (props: RunsStateQueryWithProjectIdComponentProps) => (
      <ApolloReactComponents.Query<RunsStateQueryWithProjectIdQuery, RunsStateQueryWithProjectIdQueryVariables> query={RunsStateQueryWithProjectIdDocument} {...props} />
    );
    
export type RunsStateQueryWithProjectIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunsStateQueryWithProjectIdQuery, RunsStateQueryWithProjectIdQueryVariables>
    } & TChildProps;
export function withRunsStateQueryWithProjectId<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunsStateQueryWithProjectIdQuery,
  RunsStateQueryWithProjectIdQueryVariables,
  RunsStateQueryWithProjectIdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunsStateQueryWithProjectIdQuery, RunsStateQueryWithProjectIdQueryVariables, RunsStateQueryWithProjectIdProps<TChildProps, TDataName>>(RunsStateQueryWithProjectIdDocument, {
      alias: 'runsStateQueryWithProjectId',
      ...operationOptions
    });
};

/**
 * __useRunsStateQueryWithProjectIdQuery__
 *
 * To run a query within a React component, call `useRunsStateQueryWithProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunsStateQueryWithProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunsStateQueryWithProjectIdQuery({
 *   variables: {
 *      aggregationKeys: // value for 'aggregationKeys'
 *      configKeys: // value for 'configKeys'
 *      enableAggregations: // value for 'enableAggregations'
 *      enableArtifactCounts: // value for 'enableArtifactCounts'
 *      enableBasic: // value for 'enableBasic'
 *      enableConfig: // value for 'enableConfig'
 *      enableHistoryKeyInfo: // value for 'enableHistoryKeyInfo'
 *      enableSampledHistory: // value for 'enableSampledHistory'
 *      enableSummary: // value for 'enableSummary'
 *      enableSystemMetrics: // value for 'enableSystemMetrics'
 *      enableTags: // value for 'enableTags'
 *      enableWandb: // value for 'enableWandb'
 *      filters: // value for 'filters'
 *      groupKeys: // value for 'groupKeys'
 *      groupLevel: // value for 'groupLevel'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      sampledHistorySpecs: // value for 'sampledHistorySpecs'
 *      summaryKeys: // value for 'summaryKeys'
 *      wandbKeys: // value for 'wandbKeys'
 *      internalId: // value for 'internalId'
 *   },
 * });
 */
export function useRunsStateQueryWithProjectIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunsStateQueryWithProjectIdQuery, RunsStateQueryWithProjectIdQueryVariables>) {
        return ApolloReactHooks.useQuery<RunsStateQueryWithProjectIdQuery, RunsStateQueryWithProjectIdQueryVariables>(RunsStateQueryWithProjectIdDocument, baseOptions);
      }
export function useRunsStateQueryWithProjectIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunsStateQueryWithProjectIdQuery, RunsStateQueryWithProjectIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunsStateQueryWithProjectIdQuery, RunsStateQueryWithProjectIdQueryVariables>(RunsStateQueryWithProjectIdDocument, baseOptions);
        }
export type RunsStateQueryWithProjectIdQueryHookResult = ReturnType<typeof useRunsStateQueryWithProjectIdQuery>;
export type RunsStateQueryWithProjectIdLazyQueryHookResult = ReturnType<typeof useRunsStateQueryWithProjectIdLazyQuery>;
export type RunsStateQueryWithProjectIdQueryResult = ApolloReactCommon.QueryResult<RunsStateQueryWithProjectIdQuery, RunsStateQueryWithProjectIdQueryVariables>;
export const RunsStateDeltaQueryDocument = gql`
    query RunsStateDeltaQuery($aggregationKeys: [String!], $configKeys: [String!], $currentRuns: [String!]!, $enableAggregations: Boolean = false, $enableArtifactCounts: Boolean = false, $enableBasic: Boolean = true, $enableConfig: Boolean = false, $enableHistoryKeyInfo: Boolean = false, $enableSampledHistory: Boolean = false, $enableSummary: Boolean = false, $enableSystemMetrics: Boolean = true, $enableTags: Boolean = true, $enableWandb: Boolean = false, $entityName: String!, $filters: JSONString!, $groupKeys: [String!]!, $groupLevel: Int!, $lastUpdated: DateTime!, $limit: Int!, $order: String!, $projectName: String!, $sampledHistorySpecs: [JSONString!]!, $summaryKeys: [String!], $wandbKeys: [String!]) {
  project(name: $projectName, entityName: $entityName) {
    id
    runs(
      first: $limit
      order: $order
      filters: $filters
      groupKeys: $groupKeys
      groupLevel: $groupLevel
    ) {
      totalCount
      delta(currentRuns: $currentRuns, lastUpdated: $lastUpdated) {
        index
        op
        run {
          id
          name
          projectId
          displayName
          updatedAt
          ...ArtifactCountsFragment @include(if: $enableArtifactCounts)
          ...RunStateBasicFragment @include(if: $enableBasic)
          aggregations(keys: $aggregationKeys) @include(if: $enableAggregations)
          config(keys: $configKeys) @include(if: $enableConfig)
          historyKeys(format: PLAINTEXT) @include(if: $enableHistoryKeyInfo)
          sampledHistory(specs: $sampledHistorySpecs, packVersion: 1) @include(if: $enableSampledHistory)
          summaryMetrics(keys: $summaryKeys) @include(if: $enableSummary)
          systemMetrics @include(if: $enableSystemMetrics)
          tags: tagColors @include(if: $enableTags) {
            id
            name
            colorIndex
          }
          wandbConfig(keys: $wandbKeys) @include(if: $enableWandb)
        }
      }
    }
  }
}
    ${ArtifactCountsFragmentFragmentDoc}
${RunStateBasicFragmentFragmentDoc}`;
export type RunsStateDeltaQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunsStateDeltaQueryQuery, RunsStateDeltaQueryQueryVariables>, 'query'> & ({ variables: RunsStateDeltaQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunsStateDeltaQueryComponent = (props: RunsStateDeltaQueryComponentProps) => (
      <ApolloReactComponents.Query<RunsStateDeltaQueryQuery, RunsStateDeltaQueryQueryVariables> query={RunsStateDeltaQueryDocument} {...props} />
    );
    
export type RunsStateDeltaQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunsStateDeltaQueryQuery, RunsStateDeltaQueryQueryVariables>
    } & TChildProps;
export function withRunsStateDeltaQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunsStateDeltaQueryQuery,
  RunsStateDeltaQueryQueryVariables,
  RunsStateDeltaQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunsStateDeltaQueryQuery, RunsStateDeltaQueryQueryVariables, RunsStateDeltaQueryProps<TChildProps, TDataName>>(RunsStateDeltaQueryDocument, {
      alias: 'runsStateDeltaQuery',
      ...operationOptions
    });
};

/**
 * __useRunsStateDeltaQueryQuery__
 *
 * To run a query within a React component, call `useRunsStateDeltaQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunsStateDeltaQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunsStateDeltaQueryQuery({
 *   variables: {
 *      aggregationKeys: // value for 'aggregationKeys'
 *      configKeys: // value for 'configKeys'
 *      currentRuns: // value for 'currentRuns'
 *      enableAggregations: // value for 'enableAggregations'
 *      enableArtifactCounts: // value for 'enableArtifactCounts'
 *      enableBasic: // value for 'enableBasic'
 *      enableConfig: // value for 'enableConfig'
 *      enableHistoryKeyInfo: // value for 'enableHistoryKeyInfo'
 *      enableSampledHistory: // value for 'enableSampledHistory'
 *      enableSummary: // value for 'enableSummary'
 *      enableSystemMetrics: // value for 'enableSystemMetrics'
 *      enableTags: // value for 'enableTags'
 *      enableWandb: // value for 'enableWandb'
 *      entityName: // value for 'entityName'
 *      filters: // value for 'filters'
 *      groupKeys: // value for 'groupKeys'
 *      groupLevel: // value for 'groupLevel'
 *      lastUpdated: // value for 'lastUpdated'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      projectName: // value for 'projectName'
 *      sampledHistorySpecs: // value for 'sampledHistorySpecs'
 *      summaryKeys: // value for 'summaryKeys'
 *      wandbKeys: // value for 'wandbKeys'
 *   },
 * });
 */
export function useRunsStateDeltaQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunsStateDeltaQueryQuery, RunsStateDeltaQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<RunsStateDeltaQueryQuery, RunsStateDeltaQueryQueryVariables>(RunsStateDeltaQueryDocument, baseOptions);
      }
export function useRunsStateDeltaQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunsStateDeltaQueryQuery, RunsStateDeltaQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunsStateDeltaQueryQuery, RunsStateDeltaQueryQueryVariables>(RunsStateDeltaQueryDocument, baseOptions);
        }
export type RunsStateDeltaQueryQueryHookResult = ReturnType<typeof useRunsStateDeltaQueryQuery>;
export type RunsStateDeltaQueryLazyQueryHookResult = ReturnType<typeof useRunsStateDeltaQueryLazyQuery>;
export type RunsStateDeltaQueryQueryResult = ApolloReactCommon.QueryResult<RunsStateDeltaQueryQuery, RunsStateDeltaQueryQueryVariables>;
export const RunsStateDeltaQueryWithProjectIdDocument = gql`
    query RunsStateDeltaQueryWithProjectId($aggregationKeys: [String!], $configKeys: [String!], $currentRuns: [String!]!, $enableAggregations: Boolean = false, $enableArtifactCounts: Boolean = false, $enableBasic: Boolean = true, $enableConfig: Boolean = false, $enableHistoryKeyInfo: Boolean = false, $enableSampledHistory: Boolean = false, $enableSummary: Boolean = false, $enableSystemMetrics: Boolean = true, $enableTags: Boolean = true, $enableWandb: Boolean = false, $filters: JSONString!, $groupKeys: [String!]!, $groupLevel: Int!, $lastUpdated: DateTime!, $limit: Int!, $order: String!, $sampledHistorySpecs: [JSONString!]!, $summaryKeys: [String!], $wandbKeys: [String!], $internalId: ID!) {
  project(internalId: $internalId) {
    id
    runs(
      first: $limit
      order: $order
      filters: $filters
      groupKeys: $groupKeys
      groupLevel: $groupLevel
    ) {
      totalCount
      delta(currentRuns: $currentRuns, lastUpdated: $lastUpdated) {
        index
        op
        run {
          id
          name
          projectId
          displayName
          updatedAt
          ...ArtifactCountsFragment @include(if: $enableArtifactCounts)
          ...RunStateBasicFragment @include(if: $enableBasic)
          aggregations(keys: $aggregationKeys) @include(if: $enableAggregations)
          config(keys: $configKeys) @include(if: $enableConfig)
          historyKeys(format: BITMAP) @include(if: $enableHistoryKeyInfo)
          sampledHistory(specs: $sampledHistorySpecs, packVersion: 1) @include(if: $enableSampledHistory)
          summaryMetrics(keys: $summaryKeys) @include(if: $enableSummary)
          systemMetrics @include(if: $enableSystemMetrics)
          tags: tagColors @include(if: $enableTags) {
            id
            name
            colorIndex
          }
          wandbConfig(keys: $wandbKeys) @include(if: $enableWandb)
        }
      }
    }
  }
}
    ${ArtifactCountsFragmentFragmentDoc}
${RunStateBasicFragmentFragmentDoc}`;
export type RunsStateDeltaQueryWithProjectIdComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<RunsStateDeltaQueryWithProjectIdQuery, RunsStateDeltaQueryWithProjectIdQueryVariables>, 'query'> & ({ variables: RunsStateDeltaQueryWithProjectIdQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const RunsStateDeltaQueryWithProjectIdComponent = (props: RunsStateDeltaQueryWithProjectIdComponentProps) => (
      <ApolloReactComponents.Query<RunsStateDeltaQueryWithProjectIdQuery, RunsStateDeltaQueryWithProjectIdQueryVariables> query={RunsStateDeltaQueryWithProjectIdDocument} {...props} />
    );
    
export type RunsStateDeltaQueryWithProjectIdProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<RunsStateDeltaQueryWithProjectIdQuery, RunsStateDeltaQueryWithProjectIdQueryVariables>
    } & TChildProps;
export function withRunsStateDeltaQueryWithProjectId<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RunsStateDeltaQueryWithProjectIdQuery,
  RunsStateDeltaQueryWithProjectIdQueryVariables,
  RunsStateDeltaQueryWithProjectIdProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, RunsStateDeltaQueryWithProjectIdQuery, RunsStateDeltaQueryWithProjectIdQueryVariables, RunsStateDeltaQueryWithProjectIdProps<TChildProps, TDataName>>(RunsStateDeltaQueryWithProjectIdDocument, {
      alias: 'runsStateDeltaQueryWithProjectId',
      ...operationOptions
    });
};

/**
 * __useRunsStateDeltaQueryWithProjectIdQuery__
 *
 * To run a query within a React component, call `useRunsStateDeltaQueryWithProjectIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRunsStateDeltaQueryWithProjectIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRunsStateDeltaQueryWithProjectIdQuery({
 *   variables: {
 *      aggregationKeys: // value for 'aggregationKeys'
 *      configKeys: // value for 'configKeys'
 *      currentRuns: // value for 'currentRuns'
 *      enableAggregations: // value for 'enableAggregations'
 *      enableArtifactCounts: // value for 'enableArtifactCounts'
 *      enableBasic: // value for 'enableBasic'
 *      enableConfig: // value for 'enableConfig'
 *      enableHistoryKeyInfo: // value for 'enableHistoryKeyInfo'
 *      enableSampledHistory: // value for 'enableSampledHistory'
 *      enableSummary: // value for 'enableSummary'
 *      enableSystemMetrics: // value for 'enableSystemMetrics'
 *      enableTags: // value for 'enableTags'
 *      enableWandb: // value for 'enableWandb'
 *      filters: // value for 'filters'
 *      groupKeys: // value for 'groupKeys'
 *      groupLevel: // value for 'groupLevel'
 *      lastUpdated: // value for 'lastUpdated'
 *      limit: // value for 'limit'
 *      order: // value for 'order'
 *      sampledHistorySpecs: // value for 'sampledHistorySpecs'
 *      summaryKeys: // value for 'summaryKeys'
 *      wandbKeys: // value for 'wandbKeys'
 *      internalId: // value for 'internalId'
 *   },
 * });
 */
export function useRunsStateDeltaQueryWithProjectIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<RunsStateDeltaQueryWithProjectIdQuery, RunsStateDeltaQueryWithProjectIdQueryVariables>) {
        return ApolloReactHooks.useQuery<RunsStateDeltaQueryWithProjectIdQuery, RunsStateDeltaQueryWithProjectIdQueryVariables>(RunsStateDeltaQueryWithProjectIdDocument, baseOptions);
      }
export function useRunsStateDeltaQueryWithProjectIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<RunsStateDeltaQueryWithProjectIdQuery, RunsStateDeltaQueryWithProjectIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<RunsStateDeltaQueryWithProjectIdQuery, RunsStateDeltaQueryWithProjectIdQueryVariables>(RunsStateDeltaQueryWithProjectIdDocument, baseOptions);
        }
export type RunsStateDeltaQueryWithProjectIdQueryHookResult = ReturnType<typeof useRunsStateDeltaQueryWithProjectIdQuery>;
export type RunsStateDeltaQueryWithProjectIdLazyQueryHookResult = ReturnType<typeof useRunsStateDeltaQueryWithProjectIdLazyQuery>;
export type RunsStateDeltaQueryWithProjectIdQueryResult = ApolloReactCommon.QueryResult<RunsStateDeltaQueryWithProjectIdQuery, RunsStateDeltaQueryWithProjectIdQueryVariables>;
export const Views2MetadataDocument = gql`
    query Views2Metadata($entityName: String, $name: String, $viewType: String = "runs", $userName: String, $viewName: String) {
  project(name: $name, entityName: $entityName) {
    id
    allViews(viewType: $viewType, viewName: $viewName, userName: $userName) {
      edges {
        node {
          id
          ...ViewFragmentMetadata2
        }
      }
    }
  }
}
    ${ViewFragmentMetadata2FragmentDoc}`;
export type Views2MetadataComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<Views2MetadataQuery, Views2MetadataQueryVariables>, 'query'>;

    export const Views2MetadataComponent = (props: Views2MetadataComponentProps) => (
      <ApolloReactComponents.Query<Views2MetadataQuery, Views2MetadataQueryVariables> query={Views2MetadataDocument} {...props} />
    );
    
export type Views2MetadataProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<Views2MetadataQuery, Views2MetadataQueryVariables>
    } & TChildProps;
export function withViews2Metadata<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  Views2MetadataQuery,
  Views2MetadataQueryVariables,
  Views2MetadataProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, Views2MetadataQuery, Views2MetadataQueryVariables, Views2MetadataProps<TChildProps, TDataName>>(Views2MetadataDocument, {
      alias: 'views2Metadata',
      ...operationOptions
    });
};

/**
 * __useViews2MetadataQuery__
 *
 * To run a query within a React component, call `useViews2MetadataQuery` and pass it any options that fit your needs.
 * When your component renders, `useViews2MetadataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViews2MetadataQuery({
 *   variables: {
 *      entityName: // value for 'entityName'
 *      name: // value for 'name'
 *      viewType: // value for 'viewType'
 *      userName: // value for 'userName'
 *      viewName: // value for 'viewName'
 *   },
 * });
 */
export function useViews2MetadataQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Views2MetadataQuery, Views2MetadataQueryVariables>) {
        return ApolloReactHooks.useQuery<Views2MetadataQuery, Views2MetadataQueryVariables>(Views2MetadataDocument, baseOptions);
      }
export function useViews2MetadataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Views2MetadataQuery, Views2MetadataQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Views2MetadataQuery, Views2MetadataQueryVariables>(Views2MetadataDocument, baseOptions);
        }
export type Views2MetadataQueryHookResult = ReturnType<typeof useViews2MetadataQuery>;
export type Views2MetadataLazyQueryHookResult = ReturnType<typeof useViews2MetadataLazyQuery>;
export type Views2MetadataQueryResult = ApolloReactCommon.QueryResult<Views2MetadataQuery, Views2MetadataQueryVariables>;
export const Views2ViewDocument = gql`
    query Views2View($id: ID!) {
  view(id: $id) {
    id
    ...ViewFragmentMetadata2
    spec
  }
}
    ${ViewFragmentMetadata2FragmentDoc}`;
export type Views2ViewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<Views2ViewQuery, Views2ViewQueryVariables>, 'query'> & ({ variables: Views2ViewQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const Views2ViewComponent = (props: Views2ViewComponentProps) => (
      <ApolloReactComponents.Query<Views2ViewQuery, Views2ViewQueryVariables> query={Views2ViewDocument} {...props} />
    );
    
export type Views2ViewProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<Views2ViewQuery, Views2ViewQueryVariables>
    } & TChildProps;
export function withViews2View<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  Views2ViewQuery,
  Views2ViewQueryVariables,
  Views2ViewProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, Views2ViewQuery, Views2ViewQueryVariables, Views2ViewProps<TChildProps, TDataName>>(Views2ViewDocument, {
      alias: 'views2View',
      ...operationOptions
    });
};

/**
 * __useViews2ViewQuery__
 *
 * To run a query within a React component, call `useViews2ViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useViews2ViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViews2ViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useViews2ViewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Views2ViewQuery, Views2ViewQueryVariables>) {
        return ApolloReactHooks.useQuery<Views2ViewQuery, Views2ViewQueryVariables>(Views2ViewDocument, baseOptions);
      }
export function useViews2ViewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Views2ViewQuery, Views2ViewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Views2ViewQuery, Views2ViewQueryVariables>(Views2ViewDocument, baseOptions);
        }
export type Views2ViewQueryHookResult = ReturnType<typeof useViews2ViewQuery>;
export type Views2ViewLazyQueryHookResult = ReturnType<typeof useViews2ViewLazyQuery>;
export type Views2ViewQueryResult = ApolloReactCommon.QueryResult<Views2ViewQuery, Views2ViewQueryVariables>;
export const Views2RawViewDocument = gql`
    query Views2RawView($id: ID!) {
  view(id: $id) {
    id
    ...ViewFragmentMetadata2
    specObject
  }
}
    ${ViewFragmentMetadata2FragmentDoc}`;
export type Views2RawViewComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<Views2RawViewQuery, Views2RawViewQueryVariables>, 'query'> & ({ variables: Views2RawViewQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const Views2RawViewComponent = (props: Views2RawViewComponentProps) => (
      <ApolloReactComponents.Query<Views2RawViewQuery, Views2RawViewQueryVariables> query={Views2RawViewDocument} {...props} />
    );
    
export type Views2RawViewProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<Views2RawViewQuery, Views2RawViewQueryVariables>
    } & TChildProps;
export function withViews2RawView<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  Views2RawViewQuery,
  Views2RawViewQueryVariables,
  Views2RawViewProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, Views2RawViewQuery, Views2RawViewQueryVariables, Views2RawViewProps<TChildProps, TDataName>>(Views2RawViewDocument, {
      alias: 'views2RawView',
      ...operationOptions
    });
};

/**
 * __useViews2RawViewQuery__
 *
 * To run a query within a React component, call `useViews2RawViewQuery` and pass it any options that fit your needs.
 * When your component renders, `useViews2RawViewQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViews2RawViewQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useViews2RawViewQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Views2RawViewQuery, Views2RawViewQueryVariables>) {
        return ApolloReactHooks.useQuery<Views2RawViewQuery, Views2RawViewQueryVariables>(Views2RawViewDocument, baseOptions);
      }
export function useViews2RawViewLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Views2RawViewQuery, Views2RawViewQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Views2RawViewQuery, Views2RawViewQueryVariables>(Views2RawViewDocument, baseOptions);
        }
export type Views2RawViewQueryHookResult = ReturnType<typeof useViews2RawViewQuery>;
export type Views2RawViewLazyQueryHookResult = ReturnType<typeof useViews2RawViewLazyQuery>;
export type Views2RawViewQueryResult = ApolloReactCommon.QueryResult<Views2RawViewQuery, Views2RawViewQueryVariables>;
export const ViewUpdatedAtDocument = gql`
    query ViewUpdatedAt($id: ID!) {
  view(id: $id) {
    id
    updatedAt
  }
}
    `;
export type ViewUpdatedAtComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewUpdatedAtQuery, ViewUpdatedAtQueryVariables>, 'query'> & ({ variables: ViewUpdatedAtQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ViewUpdatedAtComponent = (props: ViewUpdatedAtComponentProps) => (
      <ApolloReactComponents.Query<ViewUpdatedAtQuery, ViewUpdatedAtQueryVariables> query={ViewUpdatedAtDocument} {...props} />
    );
    
export type ViewUpdatedAtProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewUpdatedAtQuery, ViewUpdatedAtQueryVariables>
    } & TChildProps;
export function withViewUpdatedAt<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewUpdatedAtQuery,
  ViewUpdatedAtQueryVariables,
  ViewUpdatedAtProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewUpdatedAtQuery, ViewUpdatedAtQueryVariables, ViewUpdatedAtProps<TChildProps, TDataName>>(ViewUpdatedAtDocument, {
      alias: 'viewUpdatedAt',
      ...operationOptions
    });
};

/**
 * __useViewUpdatedAtQuery__
 *
 * To run a query within a React component, call `useViewUpdatedAtQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewUpdatedAtQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewUpdatedAtQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useViewUpdatedAtQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewUpdatedAtQuery, ViewUpdatedAtQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewUpdatedAtQuery, ViewUpdatedAtQueryVariables>(ViewUpdatedAtDocument, baseOptions);
      }
export function useViewUpdatedAtLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewUpdatedAtQuery, ViewUpdatedAtQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewUpdatedAtQuery, ViewUpdatedAtQueryVariables>(ViewUpdatedAtDocument, baseOptions);
        }
export type ViewUpdatedAtQueryHookResult = ReturnType<typeof useViewUpdatedAtQuery>;
export type ViewUpdatedAtLazyQueryHookResult = ReturnType<typeof useViewUpdatedAtLazyQuery>;
export type ViewUpdatedAtQueryResult = ApolloReactCommon.QueryResult<ViewUpdatedAtQuery, ViewUpdatedAtQueryVariables>;
export const NamedWorkspaceUpdateViewFieldsDocument = gql`
    query NamedWorkspaceUpdateViewFields($id: ID!) {
  view(id: $id) {
    id
    createdAt
    user {
      id
      name
      username
    }
    updatedAt
    updatedBy {
      id
      name
      username
    }
  }
}
    `;
export type NamedWorkspaceUpdateViewFieldsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<NamedWorkspaceUpdateViewFieldsQuery, NamedWorkspaceUpdateViewFieldsQueryVariables>, 'query'> & ({ variables: NamedWorkspaceUpdateViewFieldsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const NamedWorkspaceUpdateViewFieldsComponent = (props: NamedWorkspaceUpdateViewFieldsComponentProps) => (
      <ApolloReactComponents.Query<NamedWorkspaceUpdateViewFieldsQuery, NamedWorkspaceUpdateViewFieldsQueryVariables> query={NamedWorkspaceUpdateViewFieldsDocument} {...props} />
    );
    
export type NamedWorkspaceUpdateViewFieldsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<NamedWorkspaceUpdateViewFieldsQuery, NamedWorkspaceUpdateViewFieldsQueryVariables>
    } & TChildProps;
export function withNamedWorkspaceUpdateViewFields<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  NamedWorkspaceUpdateViewFieldsQuery,
  NamedWorkspaceUpdateViewFieldsQueryVariables,
  NamedWorkspaceUpdateViewFieldsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, NamedWorkspaceUpdateViewFieldsQuery, NamedWorkspaceUpdateViewFieldsQueryVariables, NamedWorkspaceUpdateViewFieldsProps<TChildProps, TDataName>>(NamedWorkspaceUpdateViewFieldsDocument, {
      alias: 'namedWorkspaceUpdateViewFields',
      ...operationOptions
    });
};

/**
 * __useNamedWorkspaceUpdateViewFieldsQuery__
 *
 * To run a query within a React component, call `useNamedWorkspaceUpdateViewFieldsQuery` and pass it any options that fit your needs.
 * When your component renders, `useNamedWorkspaceUpdateViewFieldsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNamedWorkspaceUpdateViewFieldsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useNamedWorkspaceUpdateViewFieldsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<NamedWorkspaceUpdateViewFieldsQuery, NamedWorkspaceUpdateViewFieldsQueryVariables>) {
        return ApolloReactHooks.useQuery<NamedWorkspaceUpdateViewFieldsQuery, NamedWorkspaceUpdateViewFieldsQueryVariables>(NamedWorkspaceUpdateViewFieldsDocument, baseOptions);
      }
export function useNamedWorkspaceUpdateViewFieldsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<NamedWorkspaceUpdateViewFieldsQuery, NamedWorkspaceUpdateViewFieldsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<NamedWorkspaceUpdateViewFieldsQuery, NamedWorkspaceUpdateViewFieldsQueryVariables>(NamedWorkspaceUpdateViewFieldsDocument, baseOptions);
        }
export type NamedWorkspaceUpdateViewFieldsQueryHookResult = ReturnType<typeof useNamedWorkspaceUpdateViewFieldsQuery>;
export type NamedWorkspaceUpdateViewFieldsLazyQueryHookResult = ReturnType<typeof useNamedWorkspaceUpdateViewFieldsLazyQuery>;
export type NamedWorkspaceUpdateViewFieldsQueryResult = ApolloReactCommon.QueryResult<NamedWorkspaceUpdateViewFieldsQuery, NamedWorkspaceUpdateViewFieldsQueryVariables>;
export const UpsertView2Document = gql`
    mutation UpsertView2($id: ID, $entityName: String, $projectName: String, $type: String, $name: String, $displayName: String, $description: String, $spec: String, $specIsGzipped: Boolean, $parentId: ID, $locked: Boolean, $previewUrl: String, $coverUrl: String, $showcasedAt: DateTime, $createdUsing: ViewSource) {
  upsertView(
    input: {id: $id, entityName: $entityName, projectName: $projectName, name: $name, displayName: $displayName, description: $description, type: $type, spec: $spec, specIsGzipped: $specIsGzipped, parentId: $parentId, locked: $locked, previewUrl: $previewUrl, coverUrl: $coverUrl, showcasedAt: $showcasedAt, createdUsing: $createdUsing}
  ) {
    view {
      id
      ...ViewFragmentMetadata2
    }
    inserted
  }
}
    ${ViewFragmentMetadata2FragmentDoc}`;
export type UpsertView2MutationFn = ApolloReactCommon.MutationFunction<UpsertView2Mutation, UpsertView2MutationVariables>;
export type UpsertView2ComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpsertView2Mutation, UpsertView2MutationVariables>, 'mutation'>;

    export const UpsertView2Component = (props: UpsertView2ComponentProps) => (
      <ApolloReactComponents.Mutation<UpsertView2Mutation, UpsertView2MutationVariables> mutation={UpsertView2Document} {...props} />
    );
    
export type UpsertView2Props<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UpsertView2Mutation, UpsertView2MutationVariables>
    } & TChildProps;
export function withUpsertView2<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpsertView2Mutation,
  UpsertView2MutationVariables,
  UpsertView2Props<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpsertView2Mutation, UpsertView2MutationVariables, UpsertView2Props<TChildProps, TDataName>>(UpsertView2Document, {
      alias: 'upsertView2',
      ...operationOptions
    });
};

/**
 * __useUpsertView2Mutation__
 *
 * To run a mutation, you first call `useUpsertView2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertView2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertView2Mutation, { data, loading, error }] = useUpsertView2Mutation({
 *   variables: {
 *      id: // value for 'id'
 *      entityName: // value for 'entityName'
 *      projectName: // value for 'projectName'
 *      type: // value for 'type'
 *      name: // value for 'name'
 *      displayName: // value for 'displayName'
 *      description: // value for 'description'
 *      spec: // value for 'spec'
 *      specIsGzipped: // value for 'specIsGzipped'
 *      parentId: // value for 'parentId'
 *      locked: // value for 'locked'
 *      previewUrl: // value for 'previewUrl'
 *      coverUrl: // value for 'coverUrl'
 *      showcasedAt: // value for 'showcasedAt'
 *      createdUsing: // value for 'createdUsing'
 *   },
 * });
 */
export function useUpsertView2Mutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpsertView2Mutation, UpsertView2MutationVariables>) {
        return ApolloReactHooks.useMutation<UpsertView2Mutation, UpsertView2MutationVariables>(UpsertView2Document, baseOptions);
      }
export type UpsertView2MutationHookResult = ReturnType<typeof useUpsertView2Mutation>;
export type UpsertView2MutationResult = ApolloReactCommon.MutationResult<UpsertView2Mutation>;
export type UpsertView2MutationOptions = ApolloReactCommon.BaseMutationOptions<UpsertView2Mutation, UpsertView2MutationVariables>;
export const DeleteView2Document = gql`
    mutation deleteView2($id: ID, $deleteDrafts: Boolean) {
  deleteView(input: {id: $id, deleteDrafts: $deleteDrafts}) {
    success
    pendingDrafts
  }
}
    `;
export type DeleteView2MutationFn = ApolloReactCommon.MutationFunction<DeleteView2Mutation, DeleteView2MutationVariables>;
export type DeleteView2ComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteView2Mutation, DeleteView2MutationVariables>, 'mutation'>;

    export const DeleteView2Component = (props: DeleteView2ComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteView2Mutation, DeleteView2MutationVariables> mutation={DeleteView2Document} {...props} />
    );
    
export type DeleteView2Props<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteView2Mutation, DeleteView2MutationVariables>
    } & TChildProps;
export function withDeleteView2<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteView2Mutation,
  DeleteView2MutationVariables,
  DeleteView2Props<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteView2Mutation, DeleteView2MutationVariables, DeleteView2Props<TChildProps, TDataName>>(DeleteView2Document, {
      alias: 'deleteView2',
      ...operationOptions
    });
};

/**
 * __useDeleteView2Mutation__
 *
 * To run a mutation, you first call `useDeleteView2Mutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteView2Mutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteView2Mutation, { data, loading, error }] = useDeleteView2Mutation({
 *   variables: {
 *      id: // value for 'id'
 *      deleteDrafts: // value for 'deleteDrafts'
 *   },
 * });
 */
export function useDeleteView2Mutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteView2Mutation, DeleteView2MutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteView2Mutation, DeleteView2MutationVariables>(DeleteView2Document, baseOptions);
      }
export type DeleteView2MutationHookResult = ReturnType<typeof useDeleteView2Mutation>;
export type DeleteView2MutationResult = ApolloReactCommon.MutationResult<DeleteView2Mutation>;
export type DeleteView2MutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteView2Mutation, DeleteView2MutationVariables>;
export const DeleteViewsDocument = gql`
    mutation deleteViews($ids: [ID!]!, $deleteDrafts: Boolean) {
  deleteViews(input: {ids: $ids, deleteDrafts: $deleteDrafts}) {
    success
    pendingDrafts
  }
}
    `;
export type DeleteViewsMutationFn = ApolloReactCommon.MutationFunction<DeleteViewsMutation, DeleteViewsMutationVariables>;
export type DeleteViewsComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<DeleteViewsMutation, DeleteViewsMutationVariables>, 'mutation'>;

    export const DeleteViewsComponent = (props: DeleteViewsComponentProps) => (
      <ApolloReactComponents.Mutation<DeleteViewsMutation, DeleteViewsMutationVariables> mutation={DeleteViewsDocument} {...props} />
    );
    
export type DeleteViewsProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<DeleteViewsMutation, DeleteViewsMutationVariables>
    } & TChildProps;
export function withDeleteViews<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteViewsMutation,
  DeleteViewsMutationVariables,
  DeleteViewsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteViewsMutation, DeleteViewsMutationVariables, DeleteViewsProps<TChildProps, TDataName>>(DeleteViewsDocument, {
      alias: 'deleteViews',
      ...operationOptions
    });
};

/**
 * __useDeleteViewsMutation__
 *
 * To run a mutation, you first call `useDeleteViewsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteViewsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteViewsMutation, { data, loading, error }] = useDeleteViewsMutation({
 *   variables: {
 *      ids: // value for 'ids'
 *      deleteDrafts: // value for 'deleteDrafts'
 *   },
 * });
 */
export function useDeleteViewsMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteViewsMutation, DeleteViewsMutationVariables>) {
        return ApolloReactHooks.useMutation<DeleteViewsMutation, DeleteViewsMutationVariables>(DeleteViewsDocument, baseOptions);
      }
export type DeleteViewsMutationHookResult = ReturnType<typeof useDeleteViewsMutation>;
export type DeleteViewsMutationResult = ApolloReactCommon.MutationResult<DeleteViewsMutation>;
export type DeleteViewsMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteViewsMutation, DeleteViewsMutationVariables>;
export const StarViewDocument = gql`
    mutation starView($id: ID!) {
  starView(input: {id: $id}) {
    view {
      id
      starCount
      starred
    }
  }
}
    `;
export type StarViewMutationFn = ApolloReactCommon.MutationFunction<StarViewMutation, StarViewMutationVariables>;
export type StarViewComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<StarViewMutation, StarViewMutationVariables>, 'mutation'>;

    export const StarViewComponent = (props: StarViewComponentProps) => (
      <ApolloReactComponents.Mutation<StarViewMutation, StarViewMutationVariables> mutation={StarViewDocument} {...props} />
    );
    
export type StarViewProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<StarViewMutation, StarViewMutationVariables>
    } & TChildProps;
export function withStarView<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  StarViewMutation,
  StarViewMutationVariables,
  StarViewProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, StarViewMutation, StarViewMutationVariables, StarViewProps<TChildProps, TDataName>>(StarViewDocument, {
      alias: 'starView',
      ...operationOptions
    });
};

/**
 * __useStarViewMutation__
 *
 * To run a mutation, you first call `useStarViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStarViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [starViewMutation, { data, loading, error }] = useStarViewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useStarViewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StarViewMutation, StarViewMutationVariables>) {
        return ApolloReactHooks.useMutation<StarViewMutation, StarViewMutationVariables>(StarViewDocument, baseOptions);
      }
export type StarViewMutationHookResult = ReturnType<typeof useStarViewMutation>;
export type StarViewMutationResult = ApolloReactCommon.MutationResult<StarViewMutation>;
export type StarViewMutationOptions = ApolloReactCommon.BaseMutationOptions<StarViewMutation, StarViewMutationVariables>;
export const UnstarViewDocument = gql`
    mutation unstarView($id: ID!) {
  unstarView(input: {id: $id}) {
    view {
      id
      starCount
      starred
    }
  }
}
    `;
export type UnstarViewMutationFn = ApolloReactCommon.MutationFunction<UnstarViewMutation, UnstarViewMutationVariables>;
export type UnstarViewComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UnstarViewMutation, UnstarViewMutationVariables>, 'mutation'>;

    export const UnstarViewComponent = (props: UnstarViewComponentProps) => (
      <ApolloReactComponents.Mutation<UnstarViewMutation, UnstarViewMutationVariables> mutation={UnstarViewDocument} {...props} />
    );
    
export type UnstarViewProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<UnstarViewMutation, UnstarViewMutationVariables>
    } & TChildProps;
export function withUnstarView<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UnstarViewMutation,
  UnstarViewMutationVariables,
  UnstarViewProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UnstarViewMutation, UnstarViewMutationVariables, UnstarViewProps<TChildProps, TDataName>>(UnstarViewDocument, {
      alias: 'unstarView',
      ...operationOptions
    });
};

/**
 * __useUnstarViewMutation__
 *
 * To run a mutation, you first call `useUnstarViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUnstarViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [unstarViewMutation, { data, loading, error }] = useUnstarViewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUnstarViewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UnstarViewMutation, UnstarViewMutationVariables>) {
        return ApolloReactHooks.useMutation<UnstarViewMutation, UnstarViewMutationVariables>(UnstarViewDocument, baseOptions);
      }
export type UnstarViewMutationHookResult = ReturnType<typeof useUnstarViewMutation>;
export type UnstarViewMutationResult = ApolloReactCommon.MutationResult<UnstarViewMutation>;
export type UnstarViewMutationOptions = ApolloReactCommon.BaseMutationOptions<UnstarViewMutation, UnstarViewMutationVariables>;
export const StargazersQueryDocument = gql`
    query StargazersQuery($viewID: ID!, $limit: Int, $cursor: String) {
  view(id: $viewID) {
    id
    stargazers(after: $cursor, first: $limit) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          name
          username
          userInfo
          photoUrl
        }
      }
    }
  }
}
    `;
export type StargazersQueryComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<StargazersQueryQuery, StargazersQueryQueryVariables>, 'query'> & ({ variables: StargazersQueryQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const StargazersQueryComponent = (props: StargazersQueryComponentProps) => (
      <ApolloReactComponents.Query<StargazersQueryQuery, StargazersQueryQueryVariables> query={StargazersQueryDocument} {...props} />
    );
    
export type StargazersQueryProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<StargazersQueryQuery, StargazersQueryQueryVariables>
    } & TChildProps;
export function withStargazersQuery<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  StargazersQueryQuery,
  StargazersQueryQueryVariables,
  StargazersQueryProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, StargazersQueryQuery, StargazersQueryQueryVariables, StargazersQueryProps<TChildProps, TDataName>>(StargazersQueryDocument, {
      alias: 'stargazersQuery',
      ...operationOptions
    });
};

/**
 * __useStargazersQueryQuery__
 *
 * To run a query within a React component, call `useStargazersQueryQuery` and pass it any options that fit your needs.
 * When your component renders, `useStargazersQueryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useStargazersQueryQuery({
 *   variables: {
 *      viewID: // value for 'viewID'
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function useStargazersQueryQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<StargazersQueryQuery, StargazersQueryQueryVariables>) {
        return ApolloReactHooks.useQuery<StargazersQueryQuery, StargazersQueryQueryVariables>(StargazersQueryDocument, baseOptions);
      }
export function useStargazersQueryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<StargazersQueryQuery, StargazersQueryQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<StargazersQueryQuery, StargazersQueryQueryVariables>(StargazersQueryDocument, baseOptions);
        }
export type StargazersQueryQueryHookResult = ReturnType<typeof useStargazersQueryQuery>;
export type StargazersQueryLazyQueryHookResult = ReturnType<typeof useStargazersQueryLazyQuery>;
export type StargazersQueryQueryResult = ApolloReactCommon.QueryResult<StargazersQueryQuery, StargazersQueryQueryVariables>;
export const PublicViewsDocument = gql`
    query PublicViews($limit: Int, $cursor: String) {
  publicViews(type: "runs", after: $cursor, first: $limit) {
    edges {
      node {
        id
        name
        description
        user {
          id
          username
          name
        }
      }
    }
  }
}
    `;
export type PublicViewsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PublicViewsQuery, PublicViewsQueryVariables>, 'query'>;

    export const PublicViewsComponent = (props: PublicViewsComponentProps) => (
      <ApolloReactComponents.Query<PublicViewsQuery, PublicViewsQueryVariables> query={PublicViewsDocument} {...props} />
    );
    
export type PublicViewsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<PublicViewsQuery, PublicViewsQueryVariables>
    } & TChildProps;
export function withPublicViews<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PublicViewsQuery,
  PublicViewsQueryVariables,
  PublicViewsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, PublicViewsQuery, PublicViewsQueryVariables, PublicViewsProps<TChildProps, TDataName>>(PublicViewsDocument, {
      alias: 'publicViews',
      ...operationOptions
    });
};

/**
 * __usePublicViewsQuery__
 *
 * To run a query within a React component, call `usePublicViewsQuery` and pass it any options that fit your needs.
 * When your component renders, `usePublicViewsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePublicViewsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      cursor: // value for 'cursor'
 *   },
 * });
 */
export function usePublicViewsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PublicViewsQuery, PublicViewsQueryVariables>) {
        return ApolloReactHooks.useQuery<PublicViewsQuery, PublicViewsQueryVariables>(PublicViewsDocument, baseOptions);
      }
export function usePublicViewsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PublicViewsQuery, PublicViewsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PublicViewsQuery, PublicViewsQueryVariables>(PublicViewsDocument, baseOptions);
        }
export type PublicViewsQueryHookResult = ReturnType<typeof usePublicViewsQuery>;
export type PublicViewsLazyQueryHookResult = ReturnType<typeof usePublicViewsLazyQuery>;
export type PublicViewsQueryResult = ApolloReactCommon.QueryResult<PublicViewsQuery, PublicViewsQueryVariables>;
export const ViewDraftsDocument = gql`
    query viewDrafts($viewID: ID!) {
  view(id: $viewID) {
    id
    children {
      edges {
        node {
          id
          displayName
          user {
            id
          }
          entityName
          project {
            id
            name
            entityName
          }
        }
      }
    }
  }
}
    `;
export type ViewDraftsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewDraftsQuery, ViewDraftsQueryVariables>, 'query'> & ({ variables: ViewDraftsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ViewDraftsComponent = (props: ViewDraftsComponentProps) => (
      <ApolloReactComponents.Query<ViewDraftsQuery, ViewDraftsQueryVariables> query={ViewDraftsDocument} {...props} />
    );
    
export type ViewDraftsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewDraftsQuery, ViewDraftsQueryVariables>
    } & TChildProps;
export function withViewDrafts<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewDraftsQuery,
  ViewDraftsQueryVariables,
  ViewDraftsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewDraftsQuery, ViewDraftsQueryVariables, ViewDraftsProps<TChildProps, TDataName>>(ViewDraftsDocument, {
      alias: 'viewDrafts',
      ...operationOptions
    });
};

/**
 * __useViewDraftsQuery__
 *
 * To run a query within a React component, call `useViewDraftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewDraftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewDraftsQuery({
 *   variables: {
 *      viewID: // value for 'viewID'
 *   },
 * });
 */
export function useViewDraftsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewDraftsQuery, ViewDraftsQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewDraftsQuery, ViewDraftsQueryVariables>(ViewDraftsDocument, baseOptions);
      }
export function useViewDraftsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewDraftsQuery, ViewDraftsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewDraftsQuery, ViewDraftsQueryVariables>(ViewDraftsDocument, baseOptions);
        }
export type ViewDraftsQueryHookResult = ReturnType<typeof useViewDraftsQuery>;
export type ViewDraftsLazyQueryHookResult = ReturnType<typeof useViewDraftsLazyQuery>;
export type ViewDraftsQueryResult = ApolloReactCommon.QueryResult<ViewDraftsQuery, ViewDraftsQueryVariables>;
export const ViewPermissionsDocument = gql`
    query viewPermissions($projectName: String!, $entityName: String!) {
  entity(name: $entityName) {
    id
    isTeam
    settings {
      disableMagicLinkSharing
      hidden
    }
  }
  project(name: $projectName, entityName: $entityName) {
    id
    access
  }
}
    `;
export type ViewPermissionsComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<ViewPermissionsQuery, ViewPermissionsQueryVariables>, 'query'> & ({ variables: ViewPermissionsQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const ViewPermissionsComponent = (props: ViewPermissionsComponentProps) => (
      <ApolloReactComponents.Query<ViewPermissionsQuery, ViewPermissionsQueryVariables> query={ViewPermissionsDocument} {...props} />
    );
    
export type ViewPermissionsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ViewPermissionsQuery, ViewPermissionsQueryVariables>
    } & TChildProps;
export function withViewPermissions<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewPermissionsQuery,
  ViewPermissionsQueryVariables,
  ViewPermissionsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ViewPermissionsQuery, ViewPermissionsQueryVariables, ViewPermissionsProps<TChildProps, TDataName>>(ViewPermissionsDocument, {
      alias: 'viewPermissions',
      ...operationOptions
    });
};

/**
 * __useViewPermissionsQuery__
 *
 * To run a query within a React component, call `useViewPermissionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useViewPermissionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useViewPermissionsQuery({
 *   variables: {
 *      projectName: // value for 'projectName'
 *      entityName: // value for 'entityName'
 *   },
 * });
 */
export function useViewPermissionsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<ViewPermissionsQuery, ViewPermissionsQueryVariables>) {
        return ApolloReactHooks.useQuery<ViewPermissionsQuery, ViewPermissionsQueryVariables>(ViewPermissionsDocument, baseOptions);
      }
export function useViewPermissionsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<ViewPermissionsQuery, ViewPermissionsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<ViewPermissionsQuery, ViewPermissionsQueryVariables>(ViewPermissionsDocument, baseOptions);
        }
export type ViewPermissionsQueryHookResult = ReturnType<typeof useViewPermissionsQuery>;
export type ViewPermissionsLazyQueryHookResult = ReturnType<typeof useViewPermissionsLazyQuery>;
export type ViewPermissionsQueryResult = ApolloReactCommon.QueryResult<ViewPermissionsQuery, ViewPermissionsQueryVariables>;
export const ViewViewDocument = gql`
    mutation viewView($id: ID!) {
  viewView(input: {id: $id}) {
    success
  }
}
    `;
export type ViewViewMutationFn = ApolloReactCommon.MutationFunction<ViewViewMutation, ViewViewMutationVariables>;
export type ViewViewComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<ViewViewMutation, ViewViewMutationVariables>, 'mutation'>;

    export const ViewViewComponent = (props: ViewViewComponentProps) => (
      <ApolloReactComponents.Mutation<ViewViewMutation, ViewViewMutationVariables> mutation={ViewViewDocument} {...props} />
    );
    
export type ViewViewProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: ApolloReactCommon.MutationFunction<ViewViewMutation, ViewViewMutationVariables>
    } & TChildProps;
export function withViewView<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ViewViewMutation,
  ViewViewMutationVariables,
  ViewViewProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, ViewViewMutation, ViewViewMutationVariables, ViewViewProps<TChildProps, TDataName>>(ViewViewDocument, {
      alias: 'viewView',
      ...operationOptions
    });
};

/**
 * __useViewViewMutation__
 *
 * To run a mutation, you first call `useViewViewMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useViewViewMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [viewViewMutation, { data, loading, error }] = useViewViewMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useViewViewMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ViewViewMutation, ViewViewMutationVariables>) {
        return ApolloReactHooks.useMutation<ViewViewMutation, ViewViewMutationVariables>(ViewViewDocument, baseOptions);
      }
export type ViewViewMutationHookResult = ReturnType<typeof useViewViewMutation>;
export type ViewViewMutationResult = ApolloReactCommon.MutationResult<ViewViewMutation>;
export type ViewViewMutationOptions = ApolloReactCommon.BaseMutationOptions<ViewViewMutation, ViewViewMutationVariables>;