import {getCascadingSetting} from '../../getCascadingSetting';

export const getCascadingShowLegend = ({
  suppressLegends,
  showLegend,
}: {
  suppressLegends?: boolean;
  showLegend?: boolean;
}): boolean | undefined => {
  // supressLegends is a deprecated workspace level setting, but showLegend is used in the panel config
  // so we need to flip the value.
  return getCascadingSetting([
    suppressLegends == null ? undefined : !suppressLegends,
    showLegend,
  ]);
};
