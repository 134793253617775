import {getRouteMatch} from '../../routes';
import {
  EMBEDDED_GROUP_PANEL_PATH,
  EMBEDDED_RUNS_PANEL_PATH,
  EMBEDDED_SWEEP_PANEL_PATH,
  EMBEDDED_WORKPACE_PANEL_PATH,
  FULL_SCREEN_GROUP_PANEL_PATH,
  FULL_SCREEN_GROUP_WORKSPACE_TAB_PANEL_PATH,
  FULL_SCREEN_RUNS_PANEL_PATH,
  FULL_SCREEN_RUNS_WORKSPACE_PANEL_PATH,
  FULL_SCREEN_SWEEP_PANEL_PATH,
  FULL_SCREEN_SWEEP_WORKSPACE_TAB_PANEL_PATH,
  FULL_SCREEN_WORKSPACE_PANEL_PATH,
  FULL_SCREEN_WORKSPACE_TAB_PANEL_PATH,
  GROUP_PATHS,
  PROJECT_BASE_PATH,
  PROJECT_WORKSPACE_PATH,
  RUN_BASE_PATH,
  RUNS_TAB_PATH,
  SWEEP_PATHS,
} from '../../routes/paths';

export function isEmbeddedPanelPath(path: string): boolean {
  const m = getRouteMatch(path);
  return (
    m?.path === EMBEDDED_WORKPACE_PANEL_PATH ||
    m?.path === EMBEDDED_RUNS_PANEL_PATH ||
    m?.path === EMBEDDED_GROUP_PANEL_PATH ||
    m?.path === EMBEDDED_SWEEP_PANEL_PATH
  );
}

export function isFullScreenWorkspacePanelByIdPath(path: string): boolean {
  const m = getRouteMatch(path);
  return (
    m?.path === FULL_SCREEN_WORKSPACE_PANEL_PATH ||
    m?.path === FULL_SCREEN_RUNS_PANEL_PATH ||
    m?.path === FULL_SCREEN_RUNS_WORKSPACE_PANEL_PATH ||
    m?.path === FULL_SCREEN_GROUP_PANEL_PATH ||
    m?.path === FULL_SCREEN_SWEEP_PANEL_PATH ||
    m?.path === FULL_SCREEN_GROUP_WORKSPACE_TAB_PANEL_PATH ||
    m?.path === FULL_SCREEN_SWEEP_WORKSPACE_TAB_PANEL_PATH ||
    m?.path === FULL_SCREEN_WORKSPACE_TAB_PANEL_PATH
  );
}

export function allowsFullScreenPanelsByQueryParams(path: string): boolean {
  const m = getRouteMatch(path);
  return (
    m?.path === PROJECT_BASE_PATH ||
    m?.path === PROJECT_WORKSPACE_PATH ||
    m?.path === RUNS_TAB_PATH ||
    m?.path === RUN_BASE_PATH ||
    GROUP_PATHS.includes(m?.path ?? '') ||
    SWEEP_PATHS.includes(m?.path ?? '')
  );
}
