import React, {memo, useMemo} from 'react';

import {
  overrideLineColors,
  overrideLineTitles,
  overrideLineWidths,
  overrideMarks,
} from '../../../util/plotHelpers/chart';
import {Line} from '../../../util/plotHelpers/types';
import {useDetectPanelVariant} from '../../Panel/hooks';
import {usePanelContext} from '../../Panel/PanelContextProvider';
import {useIsRenderingInPopup} from '../../Panel/SinglePanelInspectorContainer';
import {PanelPopupMode} from '../../Panel/types';
import {useDerivedLinesConfiguration} from '../config/useDerivedLinesConfiguration';
import {usePanelConfigContext} from '../PanelConfigContext';
import {usePanelZoom2} from '../zoom/PanelZoomContext2';
import {startPerfTimer} from './../../../util/profiler';
import {useBucketedData} from './../bucketedData/useBucketedData';
import {ConfigWrapper} from './../Config';
import {GraphWrapper} from './../Graph';
import {usePanelTimeContext} from './../PanelTimeContext';
import {RunsLinePlotPanelProps} from './../types';
import {removeBoundaryPoints} from './lineFilters/removeBoundaryPoints';
import {useLines} from './useLines';

type PanelRunsLinePlotProps = RunsLinePlotPanelProps;

const featureSupport = {
  grouping: false,
};

const PanelOutliersRunsLinePlotComp: React.FC<
  PanelRunsLinePlotProps
> = props => {
  const {timeFactor} = usePanelTimeContext();

  const {config: runsLinePlotConfig} = props;

  const {isSingleRun} = usePanelConfigContext();

  const {xDomainQuery: queryZoomRange} = usePanelZoom2();

  const {panelPopupMode} = usePanelContext();
  const isRenderingInPopup = useIsRenderingInPopup();
  const {isFullScreenPanelPage, isEmbeddedPanel} = useDetectPanelVariant();

  const isFullscreenMode =
    (isRenderingInPopup && panelPopupMode === PanelPopupMode.FULL_SCREEN) ||
    isFullScreenPanelPage ||
    isEmbeddedPanel;
  const nBuckets = isFullscreenMode ? 10000 : 1000;

  const {data, error, loading} = useBucketedData(
    runsLinePlotConfig,
    props.pageQuery,
    queryZoomRange,
    nBuckets
  );

  const {endPerfTimer} = startPerfTimer(`creating lines for outliers`);
  /**
   * When deriving the lines to show the run data on the plot we need to merge configuration data from multiple sources in order to draw the lines correctly.
   */
  const derivedLinesConfiguration = useDerivedLinesConfiguration({
    customRunColors: props.customRunColors,
    isGrouped: runsLinePlotConfig.isGrouped ?? false,
    runsLinePlotConfig,
    /**
     * I don't know what to do if there are multiple runsets - generally we can assume one
     */
    runSets: props.pageQuery.runSets ?? [],
    zoomTimestep: timeFactor,
  });

  const linesInitial = useLines(derivedLinesConfiguration, data.histories.data);
  const lines = useMemo(
    () => removeBoundaryPoints(linesInitial, queryZoomRange),
    [linesInitial, queryZoomRange]
  );

  const linesWithTitleOverride = useMemo(() => {
    if (runsLinePlotConfig.overrideSeriesTitles == null) {
      return lines;
    }
    return overrideLineTitles(
      lines as Line[],
      runsLinePlotConfig.overrideSeriesTitles,
      !isSingleRun
    );
  }, [lines, runsLinePlotConfig.overrideSeriesTitles, isSingleRun]);
  const linesWithColorOverride = useMemo(() => {
    if (runsLinePlotConfig.overrideColors == null) {
      return linesWithTitleOverride;
    }
    return overrideLineColors(
      linesWithTitleOverride as Line[],
      runsLinePlotConfig.overrideColors,
      !isSingleRun
    );
  }, [linesWithTitleOverride, runsLinePlotConfig.overrideColors, isSingleRun]);

  const linesWithMarkOverride = useMemo(() => {
    if (runsLinePlotConfig.overrideMarks == null) {
      return linesWithColorOverride;
    }
    return overrideMarks(
      linesWithColorOverride as Line[],
      runsLinePlotConfig.overrideMarks,
      !isSingleRun
    );
  }, [linesWithColorOverride, runsLinePlotConfig.overrideMarks, isSingleRun]);

  const linesWithWidthOverride = useMemo(() => {
    if (runsLinePlotConfig.overrideLineWidths == null) {
      return linesWithMarkOverride;
    }
    return overrideLineWidths(
      linesWithMarkOverride as Line[],
      runsLinePlotConfig.overrideLineWidths,
      !isSingleRun
    );
  }, [
    linesWithMarkOverride,
    runsLinePlotConfig.overrideLineWidths,
    isSingleRun,
  ]);

  endPerfTimer();

  if (props.configMode) {
    return (
      <ConfigWrapper
        {...props}
        // @ts-ignore
        data={data}
        hasError={!!error}
        featureSupport={featureSupport}
        // @ts-ignore
        lines={linesWithWidthOverride}
        lineCount={lines.length}
        loading={loading}
      />
    );
  }
  return (
    <GraphWrapper
      {...props}
      // @ts-ignore
      data={data}
      hasError={!!error}
      lineCount={lines.length}
      // @ts-ignore
      lines={linesWithWidthOverride}
      loading={loading}
      runNameTruncationType={
        props.pageQuery.runSets?.[0]?.runNameTruncationType
      }
    />
  );
};

export const PanelOutliersRunsLinePlot: React.FC<PanelRunsLinePlotProps> = memo(
  PanelOutliersRunsLinePlotComp
);
PanelOutliersRunsLinePlot.displayName = 'PanelOutliersRunsLinePlot';
