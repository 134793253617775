import {
  Boundary,
  ExternalBounded,
  ExternalMax,
  ExternalMin,
  FullyBounded,
  InternalBounded,
  PartialMaybeDomain,
} from './types';

export function makeSafeZoom(z: Record<string, number | null | undefined>) {
  return Object.entries(z).reduce((acc, [key, val]) => {
    if (typeof val === 'number' && Number.isFinite(val)) {
      acc[key] = val;
    } else {
      acc[key] = null;
    }
    return acc;
  }, {} as Record<string, number | null>);
}

export function isInRange(
  point: number | string,
  // two null domains have been filtered out above
  [min, max]: PartialMaybeDomain
) {
  if (typeof point !== 'number') {
    // NaN, Infinity, -Infinity come in as strings
    return false;
  }
  if (min == null && max !== null) {
    return point <= max;
  }
  if (max == null) {
    return point >= min;
  }
  return point >= min && point <= max;
}

export function hasLogicalMaxBoundary(b: ExternalMax) {
  return b.externalMax > b.internalMax;
}

export function hasLogicalMinBoundary(b: ExternalMin) {
  return b.externalMin < b.internalMin;
}

export function hasMinBoundary(b: InternalBounded): b is ExternalMin {
  return Number.isFinite(b.externalMin);
}

export function hasMaxBoundary(b: InternalBounded): b is ExternalMax {
  return Number.isFinite(b.externalMax);
}

export function hasLogicalBoundaries(b: FullyBounded) {
  return hasLogicalMinBoundary(b) && hasLogicalMaxBoundary(b);
}

export function hasInternalBounds(b: Boundary): b is InternalBounded {
  return Number.isFinite(b.internalMax) && Number.isFinite(b.internalMin);
}

export function isMissingExternalBounds(b: Boundary) {
  return b.externalMin === null && b.externalMax === null;
}

export function hasExternalBounds(b: InternalBounded): b is FullyBounded;
export function hasExternalBounds(b: Boundary): b is ExternalBounded;
export function hasExternalBounds(b: Boundary): boolean {
  return Number.isFinite(b.externalMax) && Number.isFinite(b.externalMin);
}
