import {debounce} from 'lodash';
import React, {useMemo} from 'react';
import {twMerge} from 'tailwind-merge';

import {
  SettingLevel,
  SettingType,
  trackSetting,
} from '../../../../services/analytics/workspaceSettingsEvents';
import {SettingOverrideKey} from '../../../../state/views/workspaceSettings/settingOverrideUtils';
import {maybePluralize} from '../../../../util/uihelpers';
import {useClearSettingRows} from '../hooks/useClearSettingRows';

type ClearSettingsRowProps = {
  clearSetting: () => void;
  count?: number;
  settingKey: SettingOverrideKey;
  settingLevel: SettingLevel;
};

/**
 * Displays a singular clear setting row.
 */
export const ClearSettingsRow = ({
  clearSetting,
  count,
  settingKey,
  settingLevel,
}: ClearSettingsRowProps) => {
  const debouncedClearSetting = useMemo(
    () => debounce(clearSetting, 500),
    [clearSetting]
  );

  return (
    <div>
      {count != null && count > 0 && (
        <span>
          {maybePluralize(count, settingLevel)}
          {count === 1 ? ' has ' : ' have '}
          overrides.{' '}
        </span>
      )}
      <span
        className="font-semibold text-teal-600 hover:text-teal-550"
        role="button"
        onClick={() => {
          debouncedClearSetting();
          trackSetting({
            action: 'clear',
            page: settingLevel === 'report' ? 'report' : 'workspace',
            settingLevel,
            setting: settingKey as SettingType,
          });
        }}>
        Clear
        {count != null && count > 1 && ' all'}
      </span>
    </div>
  );
};

/**
 * Displays a list of clear setting rows.
 */
export const ClearSettingsRows = ({
  className,
  rows,
}: {
  className?: string;
  rows: ReturnType<typeof useClearSettingRows>;
}) => {
  if (rows.length === 0) {
    return null;
  }

  return (
    <div className={twMerge('flex flex-col', className)}>
      {rows.map(row => (
        <ClearSettingsRow
          key={row.key}
          settingKey={row.settingKey}
          count={row.numOverrides}
          settingLevel={row.settingLevel}
          clearSetting={row.clearSetting}
        />
      ))}
    </div>
  );
};
