import * as React from 'react';
// eslint-disable-next-line wandb/no-deprecated-imports
import {Checkbox} from 'semantic-ui-react';

import DOC_URLS from '../../util/doc_urls';
import {LabeledOptionBlock as LabeledOption} from '../elements/LabeledOptionBlock';
import ProjectFieldSelector from '../ProjectFieldSelector';
import {DEFAULT_LINE_PLOT_SETTINGS} from '../WorkspaceDrawer/Settings/runLinePlots/linePlotDefaults';
import {AggType} from './AggType';
import {StyledWrapper} from './common';
import {PlotType} from './PlotType';
import {type BarChartConfig, type PanelGroupingOptionsProps} from './types';

export const OutliersPanelGroupingOptions = (
  props: PanelGroupingOptionsProps
) => {
  const {
    config,
    disabled,
    isGrouped,
    singleRun,
    updateConfig,
    usingExpressions,
  } = props;

  const showAggregationOptions =
    (props.type === 'bars' &&
      (config as BarChartConfig).plotStyle !== 'boxplot' &&
      (config as BarChartConfig).plotStyle !== 'violin') ||
    props.type === 'lines';
  const disableAggregationOptions = props.type === 'lines' && usingExpressions;

  return (
    <>
      {singleRun ? (
        <>
          {(config.metrics == null || config.metrics.length <= 1) && (
            <>
              <p>Select multiple metrics to enable grouping</p>
            </>
          )}
        </>
      ) : (
        <>
          <LabeledOption
            label="Runs"
            helpText="Aggregate over runs"
            docUrl={DOC_URLS.compareMetrics + '#grouping'}
            option={
              <StyledWrapper data-test="styled-wrapper">
                <Checkbox
                  toggle
                  disabled={disabled}
                  checked={config.aggregate}
                  name="aggregate"
                  onChange={(e, value) =>
                    updateConfig({
                      aggregate: value.checked,
                    })
                  }
                />
                {config.aggregate && (
                  <>
                    <span>Group by</span>
                    <ProjectFieldSelector
                      className={'groupby-picker'}
                      disabled={!props.config.aggregate}
                      query={props.pageQuery}
                      selection
                      columns={['config']}
                      defaultKeys={['config:None']}
                      value={`config:${config.groupBy ?? 'None'}`}
                      multi={false}
                      searchByKeyAndText
                      setValue={value =>
                        updateConfig({
                          groupBy: value.split(':')[1],
                        })
                      }
                    />
                  </>
                )}
              </StyledWrapper>
            }
          />
        </>
      )}
      {(config.aggregateMetrics === true ||
        ((props.type === 'bars' || props.type === 'lines') &&
          config.metrics != null &&
          config.metrics.length > 1)) && (
        <LabeledOption
          label="Metrics"
          helpText="Aggregate over all metrics"
          option={
            <Checkbox
              toggle
              disabled={disabled}
              checked={config.aggregateMetrics}
              name="aggregate"
              onChange={(e, value) =>
                updateConfig({
                  aggregateMetrics: value.checked,
                })
              }
            />
          }
        />
      )}
      {isGrouped && (
        <>
          <div className="group-options">
            <div className="group-option">
              {usingExpressions && (
                <div className="hint-text">
                  <p>
                    Grouping functions are disabled while using expressions. In
                    order to reenable, remove all expressions.
                  </p>
                </div>
              )}
              {props.type === 'bars' && (
                <PlotType
                  config={config as BarChartConfig}
                  usingExpressions={usingExpressions}
                  updateConfig={updateConfig}
                />
              )}

              {showAggregationOptions && (
                <AggType
                  config={config}
                  updateConfig={updateConfig}
                  options={{
                    allowMedian: false,
                    defaultGroupAgg: DEFAULT_LINE_PLOT_SETTINGS['groupAgg'],
                    disabled: disableAggregationOptions,
                    includeSum: props.type === 'scalar',
                  }}
                />
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};
