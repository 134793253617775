import classNames from 'classnames';
import * as d3 from 'd3';
import numeral from 'numeral';
import React from 'react';

import {UsageType} from '../../../generated/graphql';
import {addDaysUTC, secondsToHours, subDaysUTC} from '../../../util/date';
import {
  getFormattedBillingDate,
  METER_SECTION_STYLE,
} from '../../Billing/AccountSettings/BillingTab/PlanCard/PlanCardComponents';
import {MeterBar} from '../../Billing/AccountSettings/ManageUsagePage/MeterBar';

export const DEFAULT_NUMBER_OF_DAYS = 30;

export const TODAY = new Date();
// Ignore local time zone
TODAY.setUTCHours(0, 0, 0, 0);
export const TOMORROW = addDaysUTC(TODAY, 1);
export const DEFAULT_DAYS_AGO = subDaysUTC(TOMORROW, DEFAULT_NUMBER_OF_DAYS);
export const DEFAULT_INTERVAL_MARKERS = [DEFAULT_DAYS_AGO, TODAY];

export type Props = {
  value: number;
  limit?: number;
  title: string;
  billingPeriodStart?: Date;
  billingPeriodEnd?: Date;
  button?: React.ReactNode;
  usageType: UsageType;
  className?: string;
  hideDateInterval?: boolean;
  costText?: string | React.ReactNode;
  isUnlimited: boolean;
  secondaryButton?: React.ReactNode;
  customDateInterval?: string;
};

function getValueFormatter(usageType: UsageType): (value: number) => string {
  switch (usageType) {
    case UsageType.Storage:
    case UsageType.Weave:
      return value => `${numeral(value).format('0.0b')}`;
    case UsageType.TrackedHours:
      return value => `${d3.format(',')(secondsToHours(value))} hrs`;
    case UsageType.UserSeats:
      return value => String(value);
  }
}

export function getDateRangeText(date1: Date, date2: Date) {
  return `${getFormattedBillingDate(date1)} - ${getFormattedBillingDate(
    date2
  )}`;
}

function getUsageVerb(usageType: UsageType) {
  switch (usageType) {
    case UsageType.Weave:
      return 'Ingested';
    default:
      return 'Using';
  }
}

export const MeterSection = ({
  title,
  billingPeriodStart,
  billingPeriodEnd,
  value,
  usageType,
  limit,
  button,
  className,
  hideDateInterval = false,
  costText,
  isUnlimited,
  secondaryButton,
  customDateInterval,
}: Props) => {
  const format = getValueFormatter(usageType);
  const fraction = limit != null ? value / limit : 1; // if there's no limit, then just show the bar as full
  const usageText =
    limit != null ? (
      <div>
        {getUsageVerb(usageType)}{' '}
        <strong>
          {format(value)} ({Math.round(fraction * 100)}%)
        </strong>{' '}
        of {format(limit)}
      </div>
    ) : null;
  return (
    <div
      className={classNames(METER_SECTION_STYLE, className)}
      data-test="meter-section">
      <div className="flex justify-between">
        <div className="font-semibold">{title}</div>
        {!hideDateInterval && (
          <div className="text-sm text-moon-500">
            {customDateInterval != null
              ? customDateInterval
              : `Usage for 
            ${getDateRangeText(
              billingPeriodStart ?? DEFAULT_DAYS_AGO,
              billingPeriodEnd ?? TOMORROW
            )} UTC`}
          </div>
        )}
      </div>
      <div className="flex justify-between">
        <div className="mb-16 flex items-end">
          <div className="text-[32px] font-semibold leading-none">
            {format(value)}
          </div>
          {limit != null && (
            <div className="ml-6 flex font-semibold text-moon-500">
              <div className="mr-6">/</div>
              {format(limit)}
            </div>
          )}
        </div>
        <div className="text-sm">{costText}</div>
      </div>
      <MeterBar fraction={fraction} isUnlimited={isUnlimited} size="small" />
      <div className="mt-12 text-xs">
        <div className="flex justify-between">
          <div className="flex">
            {button}
            {secondaryButton != null && (
              <div className="ml-4">{secondaryButton}</div>
            )}
          </div>
          <div>{usageText}</div>
        </div>
      </div>
    </div>
  );
};
