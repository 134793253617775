import {useMemo} from 'react';

import {OrganizedSettings} from '../../../PanelBank/types';
import {getCascadingSetting} from '../getCascadingSetting';
import {getCascadingExcludeOutlierSetting} from '../runLinePlots/utils/getCascadingExcludeOutliers';
import {getCascadingSmoothingSettings} from '../runLinePlots/utils/getCascadingSmoothingSettings';
import {getCascadingXAxisSettings} from '../runLinePlots/utils/getCascadingXAxisSettings';
import {getCascadingYAxisSettings} from '../runLinePlots/utils/getCascadingYAxisSettings';
import {
  Complete,
  LinePlotDataSettings,
  LinePlotDisplaySettings,
  LinePlotSettings,
} from '../types';
import {getWorkspaceLayoutSettings} from '../workspaceLayout/getWorkspaceLayoutSettings';
import {WorkspaceSettingsContextType} from '../WorkspaceSettingsContextProvider';

/**
 * This hook determines which setting should be used in the panel.
 * This should only be used on a workspace page. Reports has its
 * own cascading logic because we don't have access to the context
 * providers and it's still on the old settings structure.
 *
 * Hierarchy: sectionSettings >> workspaceSettings
 *
 * This hook returns either the section or workspace cascading setting.
 */
export const useWorkspaceInheritedSettings = (
  workspaceSettingsContext: WorkspaceSettingsContextType,
  sectionLinePlotSettings?: LinePlotSettings
): OrganizedSettings => {
  const inheritedLinePlotSettings = useWorkspaceInheritedLinePlotSettings(
    workspaceSettingsContext.linePlot,
    sectionLinePlotSettings
  );

  return useMemo(
    () => ({
      ...getWorkspaceLayoutSettings(workspaceSettingsContext.workspaceLayout),
      linePlot: inheritedLinePlotSettings,
    }),
    [inheritedLinePlotSettings, workspaceSettingsContext.workspaceLayout]
  );
};

const useWorkspaceInheritedLinePlotSettings = (
  workspaceLinePlotSettings?: LinePlotSettings,
  sectionLinePlotSettings?: LinePlotSettings
): LinePlotSettings => {
  const inheritedLinePlotSettings = useMemo(
    () =>
      getWorkspaceInheritedLinePlotSettings(
        workspaceLinePlotSettings,
        sectionLinePlotSettings
      ),
    [sectionLinePlotSettings, workspaceLinePlotSettings]
  );

  return inheritedLinePlotSettings;
};

export const getWorkspaceInheritedLinePlotSettings = (
  workspaceLinePlotSettings?: LinePlotSettings,
  sectionLinePlotSettings?: LinePlotSettings
) => {
  return {
    ...getWorkspaceInheritedLinePlotDataSettings(
      workspaceLinePlotSettings,
      sectionLinePlotSettings
    ),
    ...getWorkspaceInheritedLinePlotDisplaySettings(
      workspaceLinePlotSettings,
      sectionLinePlotSettings
    ),
  };
};

export const getWorkspaceInheritedLinePlotDataSettings = (
  workspaceLinePlotSettings?: LinePlotSettings,
  sectionLinePlotSettings?: LinePlotSettings
): Complete<Omit<LinePlotDataSettings, 'maxRuns'>> => {
  const pointVisualizationMethod = getCascadingSetting([
    workspaceLinePlotSettings?.pointVisualizationMethod,
    sectionLinePlotSettings?.pointVisualizationMethod,
  ]);

  return {
    ...getCascadingSmoothingSettings(
      workspaceLinePlotSettings,
      sectionLinePlotSettings
    ),
    ...getCascadingXAxisSettings(
      workspaceLinePlotSettings,
      sectionLinePlotSettings
    ),
    ...getCascadingYAxisSettings(
      workspaceLinePlotSettings,
      sectionLinePlotSettings
    ),
    excludeOutliers: getCascadingExcludeOutlierSetting(
      pointVisualizationMethod,
      workspaceLinePlotSettings,
      sectionLinePlotSettings
    ),
    groupAgg: getCascadingSetting([
      workspaceLinePlotSettings?.groupAgg,
      sectionLinePlotSettings?.groupAgg,
    ]),
    pointVisualizationMethod,
    limit: getCascadingSetting([
      workspaceLinePlotSettings?.maxRuns,
      sectionLinePlotSettings?.maxRuns,
    ]),
    useRunsTableGroupingInPanels: getCascadingSetting([
      workspaceLinePlotSettings?.useRunsTableGroupingInPanels,
      sectionLinePlotSettings?.useRunsTableGroupingInPanels,
    ]),
  };
};

export const getWorkspaceInheritedLinePlotDisplaySettings = (
  workspaceLinePlotSettings?: LinePlotSettings,
  sectionLinePlotSettings?: LinePlotSettings
): Complete<
  Omit<LinePlotDisplaySettings, 'suppressLegends' | 'coordinateZooming'>
> => {
  return {
    colorRunNames: getCascadingSetting([
      workspaceLinePlotSettings?.colorRunNames,
      sectionLinePlotSettings?.colorRunNames,
    ]),
    displayFullRunName: getCascadingSetting([
      workspaceLinePlotSettings?.displayFullRunName,
      sectionLinePlotSettings?.displayFullRunName,
    ]),
    highlightedCompanionRunOnly: getCascadingSetting([
      workspaceLinePlotSettings?.highlightedCompanionRunOnly,
      sectionLinePlotSettings?.highlightedCompanionRunOnly,
    ]),
    showLegend: getCascadingSetting([
      workspaceLinePlotSettings?.showLegend,
      sectionLinePlotSettings?.showLegend,
    ]),
    tooltipNumberOfRuns: getCascadingSetting([
      workspaceLinePlotSettings?.tooltipNumberOfRuns,
      sectionLinePlotSettings?.tooltipNumberOfRuns,
    ]),
  };
};
