import classNames from 'classnames';
import React from 'react';

import {SkeletonWrapper} from '../../../components/Skeleton/Skeleton';
import {METER_SECTION_STYLE} from '../../Billing/AccountSettings/BillingTab/PlanCard/PlanCardComponents';

type Props = {
  title: string;
  button?: React.ReactNode;
  className?: string;
};

export const MeterSectionLoading = ({title, button, className}: Props) => {
  const usageGlimmer = (
    <SkeletonWrapper>
      <div className="h-12 w-[100px] rounded bg-moon-250" />
    </SkeletonWrapper>
  );
  return (
    <div className={classNames(METER_SECTION_STYLE, className)}>
      <div className="flex justify-between">
        <div className="font-semibold">{title}</div>
      </div>
      <SkeletonWrapper>
        <div className="mb-16 h-32 w-[150px] rounded bg-moon-250" />
      </SkeletonWrapper>
      <SkeletonWrapper>
        <div className="h-6 w-full rounded bg-moon-250" />
      </SkeletonWrapper>
      <div className="mt-12 text-xs">
        {button != null ? (
          <div className="flex justify-between">
            {button}
            {usageGlimmer}
          </div>
        ) : (
          usageGlimmer
        )}
      </div>
    </div>
  );
};
