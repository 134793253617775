import {createAction} from 'typesafe-actions';

import type {
  BaseSearchHistoryItem,
  PanelBankSectionConfig,
  PanelBankSettings,
} from '../../../components/PanelBank/types';
import {LinePlotSettings} from '../../../components/WorkspaceDrawer/Settings/types';
import * as PanelTypes from '../panel/types';
import * as PanelBankSectionConfigTypes from '../panelBankSectionConfig/types';
import * as WorkspaceSettingsTypes from '../workspaceSettings/types';
import * as Types from './types';

export const noOp = createAction(
  '@view/panelBankConfig/noOp',
  action => (ref: Types.Ref) => action({ref})
);

export const updateSettings = createAction(
  '@view/panelBankConfig/updateSettings',
  action => (ref: Types.Ref, panelBankSettings: Partial<PanelBankSettings>) =>
    action({ref, panelBankSettings})
);

export const pinSection = createAction(
  '@view/panelBankConfig/pinSection',
  action => (ref: Types.Ref, sectionRef: PanelBankSectionConfigTypes.Ref) =>
    action({ref, sectionRef})
);

export const updatePanelSearchHistory = createAction(
  '@view/panelBankConfig/updateSearchHistory',
  action =>
    (ref: Types.Ref, searchHistory: BaseSearchHistoryItem[] | undefined) =>
      action({ref, searchHistory})
);

export const setCurrentPanelSearch = createAction(
  '@view/panelBankConfig/setCurrentPanelSearch',
  action => (ref: Types.Ref, searchQuery: string) => action({ref, searchQuery})
);

// Add a new section
// By default, inserts new section before the given sectionRef.
// Pass addAfter=true if you want to insert after the given section.
export const addSection = createAction(
  '@view/panelBankConfig/addSection',
  action =>
    (
      ref: Types.Ref,
      sectionRef: PanelBankSectionConfigTypes.Ref,
      options?: {
        addAfter?: boolean;
        newSectionName?: string;
        panelRefs?: PanelTypes.Ref[];
      }
    ) =>
      action({ref, sectionRef, options})
);

export const deleteSection = createAction(
  '@view/panelBankConfig/deleteSection',
  action =>
    (
      ref: Types.Ref,
      sectionRef: PanelBankSectionConfigTypes.Ref,
      workspaceSettingsRef?: WorkspaceSettingsTypes.Ref
    ) =>
      action({ref, sectionRef, workspaceSettingsRef})
);

export const moveSectionBefore = createAction(
  '@view/panelBankConfig/moveSectionBefore',
  action =>
    (
      ref: Types.Ref,
      moveSectionRef: PanelBankSectionConfigTypes.Ref,
      beforeSectionRef?: PanelBankSectionConfigTypes.Ref // if null, we're moving to the last position in the array
    ) =>
      action({ref, moveSectionRef, beforeSectionRef})
);

export const movePanel = createAction(
  '@view/panelBankConfig/movePanel',
  action =>
    (
      ref: Types.Ref,
      panelRef: PanelTypes.Ref, // the panel we're deleting
      fromSectionRef: PanelBankSectionConfigTypes.Ref, // the panelbank section we're moving the panel from
      toSectionRef: PanelBankSectionConfigTypes.Ref, // the panelbank section we're moving the panel to
      toIndex?: number, // the index we're moving the panel to in toSectionRefs
      inactivePanelRefIDs?: Set<string>
    ) =>
      action({
        ref,
        panelRef,
        fromSectionRef,
        toSectionRef,
        toIndex,
        inactivePanelRefIDs,
      })
);

export const movePanelToNewSection = createAction(
  '@view/panelBankConfig/movePanelToNewSection',
  action =>
    (
      ref: Types.Ref,
      args: {
        panelRef: PanelTypes.Ref; // the panel we're deleting
        fromSectionRef: PanelBankSectionConfigTypes.Ref; // the panelbank section we're moving the panel from
        newSectionName: string;
      }
    ) =>
      action({ref, args}) // panelRef, fromSectionRef, toSectionRef, toIndex})
);

export const updateSettingsAndSortPanels = createAction(
  '@view/panelBankConfig/updateSettingsAndSortPanels',
  action =>
    (
      ref: Types.Ref,
      args: {
        workspaceSettingsRef: WorkspaceSettingsTypes.Ref;
        sortAlphabetically: boolean | undefined;
      }
    ) =>
      action({ref, args})
);

export const openOrCloseAllSections = createAction(
  '@view/panelBankConfig/openOrCloseAllSections',
  action => (ref: Types.Ref, isOpen: boolean) => action({ref, isOpen})
);

export const clearAllPanels = createAction(
  '@view/panelBankConfig/clearAllPanels',
  action =>
    (ref: Types.Ref, workspaceSettingsRef: WorkspaceSettingsTypes.Ref) =>
      action({ref, workspaceSettingsRef})
);

export const clearAllPanelsUndo = createAction(
  '@view/clearAllPanelsUndo',
  action =>
    (
      ref: Types.Ref,
      workspaceSettingsRef: WorkspaceSettingsTypes.Ref,
      sectionRefs: PanelBankSectionConfigTypes.Ref[]
    ) =>
      action({
        ref,
        workspaceSettingsRef,
        sectionRefs,
      })
);

export const updateAllLinePlotSectionSettings = createAction(
  '@view/panelBankConfig/updateAllLinePlotSectionSettings',
  action => (ref: Types.Ref, settings: Partial<LinePlotSettings>) =>
    action({ref, settings})
);

export const addPanelsBySpec = createAction(
  '@view/panelBankConfig/addPanelsBySpec',
  action => (ref: Types.Ref, specs: Types.KeySpecList) => action({ref, specs})
);

export const addPanelsBySpecUndo = createAction(
  '@view/panelBankConfig/addPanelsBySpecUndo',
  action =>
    (
      ref: Types.Ref,
      addedPanelRefs: PanelTypes.Ref[], // these panels will be deleted
      addedSectionRefs: PanelBankSectionConfigTypes.Ref[], // these sections will be deleted
      sectionPanelsAutoVals: PanelBankSectionConfig['isPanelsAuto'][], // section.isPanelsAuto flags will revert to these values
      specs: Types.KeySpecList // required for redo action
    ) =>
      action({
        ref,
        addedPanelRefs,
        addedSectionRefs,
        sectionPanelsAutoVals,
        specs,
      })
);
