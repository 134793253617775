import {Icon} from '@wandb/weave/components/Icon';
import {Tailwind} from '@wandb/weave/components/Tailwind';
import numeral from 'numeral';
import React, {FC, Fragment} from 'react';

import {
  AccountStatusBanner,
  AccountStatusBannerBoldText,
  AccountStatusBannerFlexWrapper,
  AccountStatusBannerTextWrapper,
} from '../../AccountStatusBanner';
import {useCurrentBannerStates} from '..';
import {WeaveApproachingLimitBannerProps} from './utils';

export const WeaveApproachingLimitBannerGlobalList: FC = () => {
  const currentBannerStates = useCurrentBannerStates();

  return (
    <Tailwind>
      <div className="space-y-8">
        {currentBannerStates.map(state => {
          const weaveApproachingLimitBannerProps =
            state.weaveEnforcementBannerFlags.weaveApproachingLimitBannerProps;

          return (
            <Fragment key={state.account.name + state.account.accountType}>
              {weaveApproachingLimitBannerProps && (
                <WeaveApproachingLimitBanner
                  {...weaveApproachingLimitBannerProps}
                />
              )}
            </Fragment>
          );
        })}
      </div>
    </Tailwind>
  );
};

export const WeaveApproachingLimitBanner: FC<
  WeaveApproachingLimitBannerProps
> = ({organizationId, weaveUsageBytes, weaveLimitBytes, cta}) => {
  const weaveFractionLeft = 1 - weaveUsageBytes / weaveLimitBytes;
  const weaveFractionLeftText = weaveFractionLeft.toLocaleString('us-en', {
    style: 'percent',
    maximumFractionDigits: 0,
  });
  const statusMsg =
    'Once you exceed your limit, access to Weave will be restricted. ';
  return (
    <Tailwind>
      <AccountStatusBanner
        eventData={{
          location: `exceeded 90% for Weave alert banner`,
          organizationId,
          resource: 'Weave',
        }}>
        <AccountStatusBannerFlexWrapper>
          <div>
            <Icon name="info" />
          </div>
          <AccountStatusBannerTextWrapper>
            <span>
              <AccountStatusBannerBoldText>
                You have {weaveFractionLeftText} of your{' '}
                {numeral(weaveLimitBytes).format('0 b')} free Weave ingestion
                remaining.
              </AccountStatusBannerBoldText>{' '}
              <span>{statusMsg}</span>
              {cta}
            </span>
          </AccountStatusBannerTextWrapper>
        </AccountStatusBannerFlexWrapper>
      </AccountStatusBanner>
    </Tailwind>
  );
};
