import gql from 'graphql-tag';

export const CURRENT_BILLING_PERIOD_USAGE_SECTION_ORG_INFO = gql`
  query CurrentBillingPeriodUsageSectionOrgInfo($orgName: String!) {
    organization(name: $orgName) {
      id
      createdAt
      teams {
        id
        storageBytes
      }
      subscriptions {
        id
        billingPeriodStart
        billingPeriodEnd
        subscriptionType
        plan {
          id
          name
          planType
        }
      }
    }
  }
`;

export const ENTERPRISE_BILLING_PERIOD_ORG_INFO = gql`
  query EnterpriseBillingPeriodOrgInfo($orgName: String!) {
    organization(name: $orgName) {
      id
      weaveEnterpriseSubscription {
        billingPeriodStart
        billingPeriodEnd
      }
    }
  }
`;
