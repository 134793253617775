import {LayoutType, MaskOptions} from '@wandb/weave/common/types/media';
import memoize from 'fast-memoize';

import {RunSignature} from '../../types/run';
import {runHistoryRowsWithOneOfKeys} from '../../util/media';
import {
  AllBoundingBoxControls,
  AllMaskControls,
  MediaPanelCardControl,
  MoleculeConfig,
  RunWithHistoryAndMediaWandb,
} from '../MediaCard';
import {panelViews} from '../PanelBank/types';

export const rowsWithKeysMemo = memoize(runHistoryRowsWithOneOfKeys);
export type Axis = 'step' | 'index' | 'run' | 'camera';
export type PanelModes = 'gallery' | 'grid';

export type MediaBrowserPanelConfig = {
  chartTitle?: string; //
  stepIndex?: number; // index of the step shown from each run, default: 0
  mediaIndex?: number; // index of the image shown from each run, default: 0
  mediaKeys?: string[]; // key for media, like 'examples'

  actualSize?: boolean; //
  fitToDimension?: boolean; //
  pixelated?: boolean; //

  // Settings for tiling
  columnCount?: number; //
  mode?: PanelModes; //
  gallerySettings?: {
    axis: Axis;
  };
  gridSettings?: {
    xAxis: Axis;
    yAxis: Axis;
  };
  selection?: {
    xAxis?: [number, number];
    yAxis?: [number, number];
  };

  page?: {
    start?: number;
  };

  // How to stack masks
  tileLayout?: {
    [mediaKey: string]: {
      type: LayoutType;
      maskOptions: MaskOptions[];
    };
  };

  // The number of steps to increase on the up and down buttons of the step slider
  stepStrideLength?: number;
  snapToExistingStep?: boolean;
  maxGalleryItems?: number;
  maxRuns?: number;
  maxYAxisCount?: number;
  moleculeConfig?: MoleculeConfig;
  segmentationMaskConfig?: AllMaskControls;
  boundingBoxConfig?: AllBoundingBoxControls;
};

export type NonEmptyTile = {
  mediaKey: string;
  mediaStep: number;
  mediaIndex: number;
  runSignature: RunSignature;
  run: RunWithHistoryAndMediaWandb;
  controls?: MediaPanelCardControl;

  // We don't set this for regular tiles, but it make the typing cleaner
  // to interact with empty tiles to have an optional here
  isEmpty?: false;
};

export const keysToTitle = (keys: string[]) => {
  return keys.length < 2 ? keys[0] : keys.join(', ');
};

export function getTitleFromConfig(config: MediaBrowserPanelConfig): string {
  return (
    config.chartTitle ||
    (config.mediaKeys != null
      ? keysToTitle(config.mediaKeys)
      : panelViews.mediaBrowser)
  );
}
