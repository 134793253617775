export const RUN_VIEW_TYPE = 'run-view';

export type SingleRunWorkspaceWorkspaceProps = {
  entityName: string;
  projectName: string;
  isProjectReadOnly: boolean;
};

export type SingleRunWorkspaceAllProps = SingleRunWorkspaceWorkspaceProps & {
  workspaceID: string;
};
