import {RunsLinePlotConfig} from '../../../../PanelRunsLinePlot/types';
import {
  DerivedPointVisualizationOption,
  ExcludeOutliersValues,
  isFullFidelityMode,
  LinePlotSettings,
} from '../../types';

type ExcludeOutliersSettings = {
  excludeOutliers?: ExcludeOutliersValues;
  ignoreOutliers?: boolean;
  showMinMaxOnHover?: 'always' | 'never' | 'onHover';
};

export const getCascadingExcludeOutlierSetting = (
  pointVisualizationMethod: DerivedPointVisualizationOption,
  workspaceSettings?: LinePlotSettings,
  sectionSettings?: LinePlotSettings,
  panelSettings?: RunsLinePlotConfig
) => {
  return getCascadingExcludeOutliers(
    isFullFidelityMode(pointVisualizationMethod),
    // Resolve workspace and section settings first
    getWorkspaceInheritedExcludeOutliers(workspaceSettings, sectionSettings),
    {
      excludeOutliers: panelSettings?.excludeOutliers,
      // @ts-expect-error - ignoreOutliers is deprecated
      ignoreOutliers: panelSettings?.ignoreOutliers,
      // @ts-expect-error - showMinMaxOnHover is deprecated
      showMinMaxOnHover: panelSettings?.showMinMaxOnHover,
    }
  );
};

/**
 * Section level settings take precedence over workspace level settings
 */
const getWorkspaceInheritedExcludeOutliers = (
  workspaceSettings?: LinePlotSettings,
  sectionSettings?: LinePlotSettings
) => {
  return {
    excludeOutliers:
      sectionSettings?.excludeOutliers ?? workspaceSettings?.excludeOutliers,
    ignoreOutliers:
      // @ts-expect-error - ignoreOutliers is deprecated
      sectionSettings?.ignoreOutliers ?? workspaceSettings?.ignoreOutliers,
    showMinMaxOnHover:
      // @ts-expect-error - showMinMaxOnHover is deprecated
      sectionSettings?.showMinMaxOnHover ??
      // @ts-expect-error - showMinMaxOnHover is deprecated
      workspaceSettings?.showMinMaxOnHover,
  };
};

/**
 * Priority
 * 1: if there's a new excludeOutliers setting on the panel, use that
 * 2: Depending on visualization mode, use the child's showMinMaxOnHover or ignoreOutliers setting
 * 3: Otherwise, use the parent's excludeOutliers setting
 */
const getCascadingExcludeOutliers = (
  isFullFidelityMode: boolean,
  inheritedSettings: ExcludeOutliersSettings,
  panelSettings: ExcludeOutliersSettings
): ExcludeOutliersValues | undefined => {
  if (panelSettings.excludeOutliers) {
    return panelSettings.excludeOutliers;
  }

  if (isFullFidelityMode) {
    if (panelSettings.showMinMaxOnHover) {
      if (panelSettings.showMinMaxOnHover === 'never') {
        return 'exclude-outliers';
      }
      return 'include-outliers';
    }
  }
  if (panelSettings.ignoreOutliers != null) {
    return panelSettings.ignoreOutliers
      ? 'exclude-outliers'
      : 'include-outliers';
  }

  if (inheritedSettings.excludeOutliers) {
    return inheritedSettings.excludeOutliers;
  }

  if (isFullFidelityMode) {
    if (inheritedSettings.showMinMaxOnHover) {
      if (inheritedSettings.showMinMaxOnHover === 'never') {
        return 'exclude-outliers';
      }
      return 'include-outliers';
    }
  } else {
    if (inheritedSettings.ignoreOutliers != null) {
      return inheritedSettings.ignoreOutliers
        ? 'exclude-outliers'
        : 'include-outliers';
    }
  }

  return undefined;
};
