import _ from 'lodash';

import {apolloClientNoCache} from '../../../apolloClient';
import {RunsDataQuery} from '../../../containers/RunsDataLoader';
import {
  BucketedRunsDeltaQueryDocument,
  BucketedRunsDeltaQueryInternalIdDocument,
  BucketedRunsDeltaQueryInternalIdQuery,
  BucketedRunsDeltaQueryInternalIdQueryVariables,
  BucketedRunsDeltaQueryQuery,
  BucketedRunsDeltaQueryQueryVariables,
} from '../../../generated/graphql';
import {propagateErrorsContext} from '../../../util/errors';
import * as Filter from '../../../util/filters';
import {sortToOrderString} from '../../../util/queryts';
import * as Run from '../../../util/runs';
import {BucketedLine} from '../types';
import {SingleQuery} from './../../../util/queryTypes';

function queryVars(
  runsDataQuery: Exclude<RunsDataQuery, 'queries'>,
  query: SingleQuery,
  nBuckets: number
) {
  const filters = JSON.stringify(Filter.toMongo(query.filters));
  const order = sortToOrderString(query.sort);
  return {
    bucketedHistorySpecs:
      runsDataQuery.bucketedHistorySpecs?.map(bhs =>
        JSON.stringify({...bhs, bins: nBuckets})
      ) ?? [],
    configKeys: runsDataQuery.configKeys,
    enableBasic: true,
    enableConfig: runsDataQuery.configKeys != null,
    enableSummary: runsDataQuery.summaryKeys != null,
    entityName: runsDataQuery.entityName,
    filters,
    groupKeys: [],
    groupLevel: 0,
    internalId: query.internalProjectId ?? '',
    limit: runsDataQuery.page?.size || 10,
    order,
    projectName: runsDataQuery.projectName,
    summaryKeys: runsDataQuery.summaryKeys,
  };
}

function doBucketedApiQuery(
  vars: ReturnType<typeof queryVars>,
  currentRuns: string[],
  lastUpdated: string
) {
  return apolloClientNoCache.query<
    BucketedRunsDeltaQueryQuery,
    BucketedRunsDeltaQueryQueryVariables
  >({
    context: propagateErrorsContext(),
    query: BucketedRunsDeltaQueryDocument,
    fetchPolicy: 'no-cache',
    variables: {
      ...vars,
      currentRuns,
      lastUpdated,
    },
  });
}

function doBucketedApiQueryByInternalId(
  vars: ReturnType<typeof queryVars>,
  currentRuns: string[],
  lastUpdated: string
) {
  return apolloClientNoCache.query<
    BucketedRunsDeltaQueryInternalIdQuery,
    BucketedRunsDeltaQueryInternalIdQueryVariables
  >({
    context: propagateErrorsContext(),
    query: BucketedRunsDeltaQueryInternalIdDocument,
    fetchPolicy: 'no-cache',
    variables: {
      ...vars,
      currentRuns,
      lastUpdated,
    },
  });
}

function applyDelta({
  currentRunsById,
  delta,
  order,
}: {
  currentRunsById: Record<string, BucketedLine>;
  order: string[];
  delta: NonNullable<
    NonNullable<
      NonNullable<BucketedRunsDeltaQueryQuery['project']>['runs']
    >['deltas']
  >['delta'];
}) {
  const deleteOps = Object.keys(currentRunsById).filter(
    rId => !order.includes(rId)
  );
  if (delta.length === 0 && deleteOps.length === 0) {
    return currentRunsById;
  } else {
    // new diffOps or deleteOps means we need to refresh the reference for `runsById`
    currentRunsById = Object.assign({}, currentRunsById);
    deleteOps.forEach(rId => {
      delete currentRunsById[rId];
    });
  }

  delta.forEach(runDelta => {
    if (!runDelta.run) {
      console.error('Bucketed Delta Op returned without valid run');
      return;
    }

    try {
      const parsedRun = Run.fromJson(runDelta.run);
      if (parsedRun == null) {
        console.error('Unable to parse run from server in outliers reducer');
        return;
      }

      // Update the entire record for any diffOp
      currentRunsById[runDelta.run.id] = {
        ...(parsedRun as unknown as BucketedLine),
        history: runDelta.run.bucketedHistory,
      };
    } catch (e) {
      console.error('Error parsing run data in outliers reducer', e);
    }
  });
  return currentRunsById;
}

export async function bucketedQuery(
  runsDataQuery: RunsDataQuery,
  singleQuery: SingleQuery,
  nBuckets: number,
  currentRunsById: Record<string, BucketedLine>
) {
  const vars = queryVars(runsDataQuery, singleQuery, nBuckets);
  const runIds = Object.keys(currentRunsById);
  const lastUpdated =
    _.max(Object.values(currentRunsById).map(l => l.updatedAt)) ??
    new Date(0).toISOString();

  /**
   * When querying across runsets, runsets outside the current entityName/projectName page need a different query mechanism
   */
  const query = vars.internalId
    ? doBucketedApiQueryByInternalId
    : doBucketedApiQuery;
  const result = await query(vars, runIds, lastUpdated);
  const {runs} = result?.data?.project ?? {};
  const {delta, order} = runs?.deltas ?? {};
  return {
    runsById: applyDelta({
      currentRunsById,
      delta: delta ?? [],
      order: order ?? [],
    }),
  };
}
