import {Icon} from '@wandb/weave/components/Icon';
import React from 'react';
import {twMerge} from 'tailwind-merge';

import {BannerBoldText, BannerFlexWrapper} from '../../../../components/Banner';
import {TargetBlank} from '../../../../components/Launch/Link';
import {Plan} from '../../../../generated/graphql';
import {
  isCommunityEditionPrimarySubscription,
  isTeamsPrimarySubscription,
  isTrialPlanPrimarySubscription,
} from '../../../../util/accounts/pricing';

type ExpiryBannerContentProps = {
  message: string;
  details: string;
  linkText: string;
  href: string;
  className?: string;
  onClick?: () => void;
};

export const ExpiryBannerContent = ({
  message,
  details,
  linkText,
  href,
  className,
  onClick,
}: ExpiryBannerContentProps) => (
  <BannerFlexWrapper className="flex w-full items-center justify-between">
    <div className="flex">
      <Icon name="info" className="mr-8" />
      <BannerBoldText className="mr-4">{message}</BannerBoldText>
      {details}
    </div>
    <TargetBlank
      className={twMerge(className, 'font-weight-800 mr-8 flex items-center')}
      href={href}
      onClick={onClick}>
      {linkText}
    </TargetBlank>
  </BannerFlexWrapper>
);

export const canSeeTrialEndPage = (subscription: {
  plan: Pick<Plan, 'name'>;
}) => {
  return (
    isTeamsPrimarySubscription(subscription) ||
    isCommunityEditionPrimarySubscription(subscription) ||
    isTrialPlanPrimarySubscription(subscription)
  );
};
