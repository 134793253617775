import {NetworkStatus} from 'apollo-client';
import React, {useState} from 'react';

import {
  useAccountSelectorContextProviderEntityQuery,
  useOrganizationSubscriptionBasicInfoByNameQuery,
  useViewerUserInfoQuery,
} from '../../../generated/graphql';
import {isProfilePath, useCurrentRouteMatch} from '../../../routes/utils';
import {useViewer} from '../../../state/viewer/hooks';
import {useAdminModeActive} from '../../../util/admin';
import {safeLocalStorage, safeSessionStorage} from '../../../util/localStorage';
import {
  useRampFlagAccountSelector,
  useRampFlagAccountSelectorNew,
} from '../../../util/rampFeatureFlags';
import {Account} from '../SearchNav/types';
import {
  AccountSelectorContext,
  AccountSelectorContextProvider,
  AccountSelectorStorageKeys,
} from './AccountSelectorContextProvider';
import {
  getAccountFromStorage,
  getCanSeeOrgInURL,
  getSelectedAccount,
  GetSelectedAccountParams,
} from './AccountSelectorUtils';
import {useAccountList} from './useAccountList';

type MatchParams = {
  params: {
    entityName?: string;
    orgName?: string;
  };
};

type Props = GetSelectedAccountParams & {
  updateSelectedAccount: (value: Account | null) => void;
  updateLastNonImpersonatedAccount: (value: Account | null) => void;
};

const AccountSelectorLoadedProvider: React.FC<Props> = ({
  children,
  updateSelectedAccount,
  updateLastNonImpersonatedAccount,
  ...props
}) => {
  // This is to prevent potential infinite flip-flopping between selected accounts.
  // Since the `getSelectedAccount` function relies on the session storage account,
  // we need to use this state so that the session storage account only gets updated once.
  // In theory, the selected account then can be initialized once (and the session storage updated to match),
  // and then only updated when the user explicitly selects a different account.
  const [hasUpdatedSessionStorageAccount, setHasUpdatedSessionStorageAccount] =
    useState(false);
  if (!hasUpdatedSessionStorageAccount) {
    const selectedAccount = getSelectedAccount(props);
    // Keep session storage in sync.
    // This is needed so that when navigating to a page e.g. without a orgname in the URL,
    // we can remember the last selected account from the previous URL.
    if (
      JSON.stringify(props.selectedAccount) !== JSON.stringify(selectedAccount)
    ) {
      updateSelectedAccount(selectedAccount);
      setHasUpdatedSessionStorageAccount(true);
    }
  }
  const lastNonImpersonatedAccount = getAccountFromStorage(
    safeLocalStorage,
    AccountSelectorStorageKeys.LAST_NON_IMPERSONATED_ACCOUNT,
    props.accountList
  );
  const canSeeOrgInURL = getCanSeeOrgInURL(props);

  return (
    <AccountSelectorContext.Provider
      value={{
        selectedAccount: getAccountFromStorage(
          safeSessionStorage,
          AccountSelectorStorageKeys.ACCOUNT_SELECTOR_VALUE,
          props.accountList
        ), // get the up to date value from session storage in case we updated it above
        setSelectedAccount: updateSelectedAccount,
        lastNonImpersonatedAccount,
        setLastNonImpersonatedAccount: updateLastNonImpersonatedAccount,
        canSeeOrgInURL,
      }}>
      {children}
    </AccountSelectorContext.Provider>
  );
};

const AccountSelectorContextProviderNew: React.FC = ({children}) => {
  const viewer = useViewer();
  const {accountList, isLoading: isAccountListLoading} = useAccountList();
  const {data: viewerData, loading: userInfoLoading} = useViewerUserInfoQuery();
  const {
    params: {orgName, entityName},
  }: MatchParams = useCurrentRouteMatch() ?? {params: {}};
  const {data: entityOrg, networkStatus: entityNetworkStatus} =
    useAccountSelectorContextProviderEntityQuery({
      variables: {teamName: entityName ?? ''},
      skip: !entityName,
    });

  // The organization associated with the orgName in the URL gets precedence over the
  // entity's org (in the event that they don't match).
  const orgNameFromCurrentUrl =
    orgName ?? entityOrg?.entity?.organization?.name;
  const skipOrgQuery = !orgNameFromCurrentUrl;
  const {data: org, networkStatus} =
    useOrganizationSubscriptionBasicInfoByNameQuery({
      variables: {name: orgNameFromCurrentUrl ?? ''},
      skip: skipOrgQuery,
    });
  const enableAccountSelector = useRampFlagAccountSelector();
  const isAdminMode = useAdminModeActive();

  const loading =
    isAccountListLoading ||
    viewer == null ||
    userInfoLoading ||
    (entityName && entityNetworkStatus !== NetworkStatus.ready) ||
    (!skipOrgQuery && networkStatus !== NetworkStatus.ready);

  const updateSelectedAccount = (value: Account | null) => {
    const stringValue = JSON.stringify(value);
    safeSessionStorage.setItem(
      AccountSelectorStorageKeys.ACCOUNT_SELECTOR_VALUE,
      stringValue
    );
    safeLocalStorage.setItem(
      AccountSelectorStorageKeys.LAST_ACCOUNT_SELECTOR_VALUE,
      stringValue
    );
  };

  const updateLastNonImpersonatedAccount = (value: Account | null) => {
    const stringValue = JSON.stringify(value);
    safeLocalStorage.setItem(
      AccountSelectorStorageKeys.LAST_NON_IMPERSONATED_ACCOUNT,
      stringValue
    );
  };

  if (loading || !enableAccountSelector) {
    // We don't immediately trust the account from session storage. It may be old, stale,
    // it may not match the URL, etc. We want to load everything we need to know to determine which account
    // to select before we show a value to the children components.
    return (
      <AccountSelectorContext.Provider
        value={{
          selectedAccount: null,
          setSelectedAccount: updateSelectedAccount,
          lastNonImpersonatedAccount: null,
          setLastNonImpersonatedAccount: updateLastNonImpersonatedAccount,
          canSeeOrgInURL: true, // prevent pre-mature redirects until we have all the permissions loaded
        }}>
        {children}
      </AccountSelectorContext.Provider>
    );
  }

  const sessionStorageAccount = getAccountFromStorage(
    safeSessionStorage,
    AccountSelectorStorageKeys.ACCOUNT_SELECTOR_VALUE,
    accountList
  );

  // Now that everything is loaded, we can render the AccountSelectorLoadedProvider
  // which will calculate the selected account and update it just once.
  return (
    <AccountSelectorLoadedProvider
      key={sessionStorageAccount?.id ?? 'no session storage account'} // force re-renders if the user manually switches accounts. React doesn't subscribe to session storage otherwise.
      selectedAccount={sessionStorageAccount}
      lastSelectedAccount={getAccountFromStorage(
        safeLocalStorage,
        AccountSelectorStorageKeys.LAST_ACCOUNT_SELECTOR_VALUE,
        accountList
      )}
      isProfilePath={isProfilePath(window.location.pathname)}
      isAdminMode={isAdminMode}
      viewer={viewer}
      accountList={accountList}
      userInfo={viewerData?.viewer?.userInfo}
      orgName={orgName}
      entityName={entityName}
      orgQuery={org}
      entityQuery={entityOrg}
      updateSelectedAccount={updateSelectedAccount}
      updateLastNonImpersonatedAccount={updateLastNonImpersonatedAccount}>
      {children}
    </AccountSelectorLoadedProvider>
  );
};

export const AccountSelectorContextProviderWrapper: React.FC = ({children}) => {
  const isNewAccountSelectorEnabled = useRampFlagAccountSelectorNew();
  if (isNewAccountSelectorEnabled) {
    return (
      <AccountSelectorContextProviderNew>
        {children}
      </AccountSelectorContextProviderNew>
    );
  }
  return (
    <AccountSelectorContextProvider>{children}</AccountSelectorContextProvider>
  );
};
