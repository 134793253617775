import {TeamMemberRole} from '../components/MembersTable/TeamMembersTable/utils';
import {useProjectMembersEntityMembersQuery} from '../generated/graphql';
import {useViewer} from '../state/viewer/hooks';
import {Viewer} from '../state/viewer/types';
import {useAdminModeActive} from './admin';
import {AccessOption} from './permissions';

export interface ViewerHasRestrictedProjectViewParams {
  projectName?: string;
  entityName: string;
  projectAccess: AccessOption;
}

// Return false if admin, else return useTeamAdminIsNotRestrictedProjectMember
export function useViewerHasRestrictedProjectView({
  projectName,
  entityName,
  projectAccess,
}: ViewerHasRestrictedProjectViewParams): boolean {
  const adminMode = useAdminModeActive();

  const isTeamAdminAndNotRestrictedProjectMember =
    useTeamAdminIsNotRestrictedProjectMember({
      projectAccess,
      projectName: projectName,
      entityName: entityName,
    });

  if (adminMode) {
    return false;
  }

  return isTeamAdminAndNotRestrictedProjectMember;
}

// Is the viewer a team admin for the restricted project, but not a project member?
export function useTeamAdminIsNotRestrictedProjectMember({
  projectName,
  entityName,
  projectAccess,
}: ViewerHasRestrictedProjectViewParams): boolean {
  const viewer: Viewer | undefined = useViewer();

  // get project members and members of the project's entity
  const {data, loading} = useProjectMembersEntityMembersQuery({
    variables: {
      entityName,
      projectName: projectName ?? '',
    },
    skip: projectAccess !== AccessOption.Restricted || projectName == null,
  });

  if (
    loading ||
    projectName == null ||
    projectAccess !== AccessOption.Restricted
  ) {
    return false;
  }

  const viewerIsProjectMember = data?.project?.members?.find(
    member => member.id === viewer?.id
  );

  // search through project's entity members to see if viewer is team admin.
  const viewerTeamMember = data?.project?.entity?.members?.find(
    member => member.id === viewer?.id
  );
  const viewerIsTeamAdmin =
    viewerTeamMember?.memberRole?.name === TeamMemberRole.ADMIN;

  return viewerIsTeamAdmin && !viewerIsProjectMember;
}
