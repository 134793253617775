// Functions for querying and mutating views.
//
// Uses apollo-client to send graphql requests. Internally these functions use
// the generated graphql types, which are noisy because our schema has a lot
// of nullable fields that are actually never null. So we hand write simpler
// types to make using the API cleaner.
import {ID} from '@wandb/weave/common/util/id';
import {notEmpty} from '@wandb/weave/common/util/obj';
import {unixTimestampMSFromUTCString} from '@wandb/weave/common/util/time';
import * as _ from 'lodash';

import * as Generated from '../../generated/graphql';
import {
  getCacheIfEnabled,
  getCacheStorageKey,
  LongTermCache,
} from '../../util/cache';
import {getDatadogRum} from '../../util/datadog';
import {logCustomActionToDatadog} from '../../util/profiler';
import {ApolloClient} from '../types';
import {getUrlStateByKey, URL_STATE_KEYS} from './../../services/url';
import {safeSessionStorage} from './../../util/localStorage';
import {DiscussionThread} from './discussionThread/types';
import * as Types from './types';

const CACHE_NAMESPACE = `viewSpec`;

type ThenArg<T> = T extends Promise<infer U> ? U : T;

/**
 * Retrieve a view row from the server
 *
 * Caching is optional mainly because reports can't use caching - there are too many other places reports are edited,
 * and also the updatedAt stamp for reports can't be trusted.
 */
export async function loadSingleViewById(
  client: ApolloClient,
  id: string,
  caching: 'cache' | 'no-cache'
) {
  async function loadFresh(
    cache?: LongTermCache | null
  ): Promise<ViewWithParsedSpec> {
    // Undefined and null have different meanings:
    // - Undefined means we don't know if the cache is enabled or not yet
    // - Null means we know for a fact the cache is disabled
    const viewWithParsedSpec = await fetchSpecFromGQL(client, id);

    if (cache === undefined) {
      cache = await getCacheIfEnabled();
    }

    if (fileName != null && cache != null) {
      cache.set(fileName, viewWithParsedSpec);
    }
    return viewWithParsedSpec;
  }

  // Ensures fileName is valid so it can be saved in cache, regardless of cache usage.
  const fileName = getCacheStorageKey(CACHE_NAMESPACE, id);
  if (fileName == null || caching === 'no-cache') {
    return await loadFresh();
  }

  const cache = await getCacheIfEnabled();
  if (cache === null) {
    return await loadFresh(cache);
  }

  const start = Date.now();
  const freshViewPromise = loadFresh(cache);
  const cachedViewPromise = cache.get<ViewWithParsedSpec>(fileName);

  const cacheTimestampPromise = cachedViewPromise.then(() => Date.now());
  const serverTimestampPromise = freshViewPromise.then(() => Date.now());
  const serverUpdatedAtTimestampPromise = fetchViewUpdatedAtMS(client, id);

  const cacheResultPromise = cachedViewPromise.then(cachedView => ({
    view: cachedView,
    source: 'cache' as const,
  }));

  const serverResultPromise = freshViewPromise.then(view => ({
    view,
    source: 'server' as const,
  }));

  const result = await Promise.race([cacheResultPromise, serverResultPromise]);

  async function logTimeSaved() {
    const [
      cacheTimestamp,
      serverTimestamp,
      serverUpdatedAtTimestamp,
      cacheResult,
    ] = await Promise.all([
      cacheTimestampPromise,
      serverTimestampPromise,
      serverUpdatedAtTimestampPromise,
      cacheResultPromise,
    ]);

    if (cacheResult.view == null) {
      // if we didn't read anything from the cache, we don't have anything to log
      return;
    }

    const serverDataIdenticalToCacheData =
      serverUpdatedAtTimestamp ===
      unixTimestampMSFromUTCString(cacheResult.view.data.updatedAt);

    const cacheLoadDuration = cacheTimestamp - start;
    const serverLoadDuration = serverTimestamp - start;
    const serverMinusCacheDuration = serverLoadDuration - cacheLoadDuration;

    logCustomActionToDatadog('viewload.timeSaved', {
      namespace: 'loadSingleViewById',
      cacheLoadDuration,
      serverLoadDuration,
      serverMinusCacheDuration,
      serverDataIdenticalToCacheData,
      bytesReadFromCache: cacheResult.view.bytesReadFromCache,
    });
  }

  // all codepaths from here on read data from both cache and server, so we can log the time saved
  logTimeSaved();

  // if server somehow returns first, dont wait for cache
  if (result.source === 'server') {
    return result.view;
  }

  if (result.view == null) {
    return await freshViewPromise;
  }

  const serverUpdatedAtTimestamp = await serverUpdatedAtTimestampPromise;

  if (
    serverUpdatedAtTimestamp >
    unixTimestampMSFromUTCString(result.view.data.updatedAt)
  ) {
    return await freshViewPromise;
  }

  getDatadogRum()?.setViewContextProperty(
    'viewBytesReadFromCache',
    result.view.bytesReadFromCache
  );

  return result.view.data;
}

async function fetchViewUpdatedAtMS(
  client: ApolloClient,
  id: string
): Promise<number> {
  const queryResult = await client.query<Generated.ViewUpdatedAtQuery>({
    query: Generated.ViewUpdatedAtDocument,
    fetchPolicy: 'no-cache',
    variables: {id},
  });

  const updatedAt = queryResult.data.view?.updatedAt;
  if (updatedAt == null) {
    throw new Error('not found');
  }

  return unixTimestampMSFromUTCString(updatedAt);
}

async function fetchSpecFromGQL(
  client: ApolloClient,
  id: string
): Promise<ViewWithParsedSpec> {
  const queryResult = await client.query<Generated.Views2RawViewQuery>({
    query: Generated.Views2RawViewDocument,
    fetchPolicy: 'no-cache',
    variables: {id},
  });
  const view = queryResult.data.view;
  if (view == null) {
    // eslint-disable-next-line no-throw-literal
    throw 'not found';
  }
  const parsedView = parseViewMetadata(view);
  if (parsedView == null) {
    // eslint-disable-next-line no-throw-literal
    throw 'parse error';
  }
  return parseSpec(parsedView, view.specObject);
}

type ViewWithParsedSpec = ReturnType<typeof parseSpec>;

function parseSpec(parsedView: Types.View, specObj: any) {
  try {
    const spec = specObj;

    // HAX: This is here to fix reports generated by the Reports API that have Media Panels.
    // There were 2 separate bugs:
    // 1. The Reports API was setting the `media_keys` field instead of `mediaKeys` on the Media Browser panel config.
    // 2. The Reports API was setting `mediaKeys` as a string instead of a list of strings.
    // This code fixes both of those issues by taking `mediaKeys ?? media_keys` and converting it to `string[]`.
    // This code is littered with conditional access operators (`?.`) because we should never make assumptions about the structure of the spec.
    const panelGrids =
      spec.blocks?.filter((b: any) => b.type === `panel-grid`) ?? [];
    for (const panelGrid of panelGrids) {
      const panels = panelGrid?.metadata?.panelBankSectionConfig?.panels ?? [];
      for (const panel of panels) {
        if (panel?.viewType !== `Media Browser` || panel?.config == null) {
          continue;
        }
        const mediaKeysInSpec =
          panel.config.mediaKeys ?? panel.config.media_keys;
        const mediaKeys =
          typeof mediaKeysInSpec === `string`
            ? [mediaKeysInSpec]
            : mediaKeysInSpec;
        panel.config.mediaKeys = mediaKeys;
        delete panel.config.media_keys;
      }
    }

    const calloutBlocks =
      spec.blocks?.filter((b: any) => b?.type === `callout-block`) ?? [];
    for (const block of calloutBlocks) {
      if (block?.children?.length === 0) {
        block.children.push({text: ``});
      }
    }

    const temporaryViewId = getUrlStateByKey(URL_STATE_KEYS.TEMPORARY_VIEW);
    const runNames = safeSessionStorage.getItem(temporaryViewId) ?? '';
    if (temporaryViewId && runNames.length) {
      /**
       * WARNING: HACK
       *
       * This is a hack to create a way to reuse a user's workspace view to show a temporary, read-only view of filters that are accessed via the URL. Ideally we'd mutate _reading_ the spec based on the URL parameters and not mess about with the source of truth, but because the spec is read in multiple places to create a workspace view there's no easy way to do that in a single spot. So instead of polluting the app with filter-read hacks I'm selectively mutating the spec on the way into redux to make it easier to reason about
       *
       * Why this is better:
       * 1. There's a single place where the code is mutated instead of multiple places
       * 2. Additional workspace modification respects this hack - because the underlying Redux store is changed adding additional filters will aggregate on top of the mutation. So you can load a spec with filters configured through a temporary view ID, and then disable that, and the refresh will respect your changes and the UI state will remain accurate.
       *
       * A better long-term approach would be to do this server-side (that is, if we wanted to keep doing this at all and didn't solve it more correctly: https://weightsandbiases.slack.com/archives/C034LF02J2E/p1673551059801789?thread_ts=1673283988.099639&cid=C034LF02J2E)
       *
       * Why this is safe: the same conditions that enable this hack (the presence of `URL_STATE_KEYS.TEMPORARY_VIEW` query params) also disable auto-saving of the workspace so the user can't accidentally overwrite their workspace spec with filter configurations read in through the URL (which happen by clicking a link)
       */
      // this is some additional safeguarding
      // I'm trying to narrow it down so that only workspace views can trigger this hack at the moment
      if (
        spec.section?.runSets.length === 1 &&
        spec.ref?.type === 'project-view'
      ) {
        /**
         * On temporary views we want to blow away ALL filtering in the spect outside what we've linked through session storage using the temporary view ID. A more scalable solution here would be to figure out a way to serialize the filtering spec, but in the interests of shipping a POC on this I'm using a single, known filter use case up front
         */
        spec.section.runSets[0].filters.filters = [
          {
            op: 'AND',
            filters: [
              {
                disabled: false,
                key: {section: 'run', name: 'displayName'},
                op: 'IN',
                value: runNames.split(','),
              },
            ],
          },
        ];
      }

      return {
        ...parsedView,
        spec,
      };
    } else {
      return {
        ...parsedView,
        spec,
      };
    }
  } catch {
    // eslint-disable-next-line no-throw-literal
    throw 'json parse error';
  }
}

export type LoadResultType = ThenArg<ReturnType<typeof loadSingleViewById>>;

// Load a list of views, not including spec
export async function loadMetadataList(
  client: ApolloClient,
  params: Types.LoadMetadataListParams
) {
  return client
    .query<Generated.Views2MetadataQuery>({
      query: Generated.Views2MetadataDocument,
      fetchPolicy: 'no-cache',
      variables: {
        ...params,
        name: params.projectName,
      },
    })
    .then(result => {
      const project = result.data.project;
      if (project == null) {
        throw new Error('View query failed with invalid project');
      }
      const views = project.allViews;
      if (views == null) {
        throw new Error('Unexpected result for ViewsQuery, missing allViews');
      }
      const nodes = views.edges.map(e => {
        const parsedView =
          e.node != null ? parseViewMetadata(e.node) : undefined;
        if (parsedView == null) {
          console.warn("Couldn't parse view from server: ", e.node);
        }
        return parsedView;
      });
      return Promise.resolve(nodes.filter(notEmpty));
    });
}

export type LoadMetadataListResultType = ThenArg<
  ReturnType<typeof loadMetadataList>
>;

export const save = (
  client: ApolloClient,
  view: Types.SaveableView,
  context?: any
) => {
  if (view.id == null) {
    if ((view.name == null && view.displayName == null) || view.type == null) {
      throw new Error(
        "If a view ID isn't provided, a name and type must be provided."
      );
    }
  }

  return client
    .mutate<Generated.UpsertView2Mutation>({
      mutation: Generated.UpsertView2Document,
      // Make sure to skip cache here. If we don't, apollo stores all the
      // variables, forever, which includes our spec, which can be huge.
      fetchPolicy: 'no-cache',
      context,
      variables: {
        id: view.id,
        entityName: view.project ? view.project.entityName : undefined,
        projectName: view.project ? view.project.name : undefined,
        name: view.name,
        displayName: view.displayName,
        type: view.type,
        description: view.description,
        spec: view.spec ? JSON.stringify(view.spec) : undefined,
        parentId: view.parentId,
        locked: view.locked,
        // previewUrl is a signed url to cloud storage, we normalize it here
        previewUrl: view.previewUrl ? `preview.png` : undefined,
        coverUrl: view.coverUrl ? `cover.png` : undefined,
        createdUsing: view.createdUsing,
      },
    })
    .then(result => {
      const data = result.data;
      if (data == null) {
        throw new Error('View save query failed');
      }
      const savedView = data.upsertView && data.upsertView.view;
      if (savedView == null) {
        throw new Error('Unexpected result for UpsertView, missing view');
      }
      const parsedView = parseViewMetadata(savedView);
      if (parsedView == null) {
        throw new Error(
          "Couldn't parse view from server: " + JSON.stringify(savedView)
        );
      }
      getCacheIfEnabled().then(cache => {
        if (view.spec != null && cache != null) {
          const fileName = getCacheStorageKey(CACHE_NAMESPACE, view.id);
          if (fileName != null) {
            cache.set(fileName, {...parsedView, spec: view.spec});
          }
        }
      });

      return parsedView;
    });
};

export type SaveResultType = ThenArg<ReturnType<typeof save>>;

export const deleteView = (
  client: ApolloClient,
  id: string,
  deleteDrafts: boolean = false
) =>
  client.mutate<Generated.DeleteView2Mutation>({
    mutation: Generated.DeleteView2Document,
    variables: {id, deleteDrafts},
  });

export type DeleteResultType = ThenArg<ReturnType<typeof deleteView>>;

export const deleteViews = (
  client: ApolloClient,
  ids: string[],
  deleteDrafts: boolean = false
) =>
  client.mutate<Generated.DeleteViewsMutation>({
    mutation: Generated.DeleteViewsDocument,
    variables: {ids, deleteDrafts},
  });

export type BatchDeleteResultType = ThenArg<ReturnType<typeof deleteViews>>;

function parseViewMetadata(
  view: Generated.ViewFragmentMetadata2Fragment
): Types.View | undefined {
  const name = view.name;
  if (name == null) {
    return;
  }
  const displayName = view.displayName;
  if (displayName == null) {
    return;
  }
  const type = view.type;
  if (type == null) {
    return;
  }

  if (!_.includes(Types.VIEW_TYPES, type)) {
    return;
  }

  const updatedAt = view.updatedAt;
  if (updatedAt == null) {
    return;
  }

  const updatedBy = parseUser(view.updatedBy);

  const createdAt = view.createdAt;
  if (createdAt == null) {
    return;
  }

  const user = parseUser(view.user);
  if (user == null) {
    return;
  }

  const project = parseProject(view.project);

  if (view.starred == null) {
    view.starred = false;
  }

  if (view.locked == null) {
    view.locked = true;
  }

  return {
    cid: ID(), // Note that this means the cid is randomly create on the client, not synced to the server
    id: view.id,
    type: type as Types.ViewType,
    name,
    displayName,
    description: view.description || '',
    updatedAt,
    updatedBy,
    createdAt,
    user,
    entityName: view.entityName,
    project,
    starCount: view.starCount,
    starred: view.starred,
    parentId: view.parentId || undefined,
    locked: view.locked,
    previewUrl: view.previewUrl || undefined,
    coverUrl: view.coverUrl || undefined,
    viewCount: view.viewCount,
    alertSubscription: view.alertSubscription || undefined,
    accessTokens: view.accessTokens ?? undefined,
  };
}

export function parseUser(
  user: Generated.ViewFragmentMetadata2Fragment['user']
): Types.View['user'] | undefined {
  if (user == null) {
    return;
  }
  const username = user.username ?? '';
  return {
    id: user.id,
    username,
    name: user.name,
    photoUrl: user.photoUrl || undefined,
    admin: user.admin || false,
  };
}

export const parseDiscussionThreads = (
  data?: Generated.ViewDiscussionThreadsQuery
): DiscussionThread[] => {
  if (data?.view == null) {
    return [];
  }
  return data.view.discussionThreads.edges.map(t => {
    const thread = t.node;
    return {
      id: thread.id,
      poster: parseUser(thread.poster),
      createdAt: thread.createdAt,
      comments: thread.comments.edges.map(c => {
        const comment = c.node;
        return {
          id: comment.id,
          body: comment.body,
          poster: parseUser(comment.poster),
          createdAt: comment.createdAt,
          updatedAt: comment.updatedAt ?? undefined,
        };
      }),
    };
  });
};

function parseProject(
  project: Generated.ViewFragmentMetadata2Fragment['project']
): Types.View['project'] | undefined {
  if (project == null) {
    return;
  }
  const name = project.name;
  if (name == null) {
    return;
  }
  const entityName = project.entityName;
  if (entityName == null) {
    return;
  }
  return {
    id: project.id,
    name,
    entityName,
    readOnly: project.readOnly || false,
  };
}

export const starView = (client: ApolloClient, id: string) =>
  client
    .mutate<Generated.StarViewMutation>({
      mutation: Generated.StarViewDocument,
      variables: {id},
    })
    .then(result => {
      const data = result.data;
      if (data == null) {
        throw new Error('Star view mutation failed');
      }
      const starCount =
        data.starView && data.starView.view && data.starView.view.starCount;
      if (starCount == null) {
        throw new Error('Unexpected result for StarView, missing star count');
      }
      return starCount;
    });

export const unstarView = (client: ApolloClient, id: string) =>
  client
    .mutate<Generated.UnstarViewMutation>({
      mutation: Generated.UnstarViewDocument,
      variables: {id},
    })
    .then(result => {
      const data = result.data;
      if (data == null) {
        throw new Error('Unstar view mutation failed');
      }
      const starCount =
        data.unstarView &&
        data.unstarView.view &&
        data.unstarView.view.starCount;
      if (starCount == null) {
        throw new Error('Unexpected result for UnstarView, missing star count');
      }
      return starCount;
    });
