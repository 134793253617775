const root = 'https://docs.wandb.ai';

// Use this directly if you know the name of the docUrl you want.
const DOC_URLS = {
  artifacts: root + '/guides/artifacts',
  artifactAutomations: root + '/guides/artifacts/project-scoped-automations',
  artifactsMetadata: root + '/artifacts/api#updating-artifacts',
  artifactTTL: root + '/guides/artifacts/ttl',
  audio: root + '/guides/track/log/media#other-media',
  automation: root + '/guides/model_registry/model-registry-automations',
  codeComparer: root + '/guides/app/features/panels/code#code-comparer',
  codeSaving: root + '/guides/app/features/panels/code',
  compareMetrics: root + '/guides/app/features/panels/line-plot',
  config: root + '/guides/track/config',
  createQueue: root + '/guides/launch/setup-launch',
  customCharts: root + '/guides/app/features/custom-charts',
  defineMetric: root + '/guides/track/log#customize-the-summary',
  domainCapture:
    root + '/guides/hosting/iam/manage-organization/#domain-capture',
  examples: 'https://github.com/wandb/examples',
  fileExport: root + '/library/public-api-guide#download-all-files-from-a-run',
  gettingStarted: root + '/quickstart',
  images: root + '/guides/track/log/media#images',
  integrations: root + '/guides/integrations',
  html: root + '/guides/track/log/media#other-media',
  launch: root + '/guides/launch',
  launchJobs: root + '/guides/launch/launch-jobs',
  launchJobsCreate: root + '/guides/launch/create-launch-job',
  launchKubernetes: root + '/guides/launch/kubernetes',
  launchSageMakerAgentConfiguration:
    root + '/guides/launch/sagemaker#agent-configuration',
  launchVertex: root + '/guides/launch/setup-vertex',
  loggingObjects: root + '/guides/track/log',
  logs: root + '/guides/track/log',
  modelManagement: root + '/guides/models/',
  molecule: root + '/guides/track/log/media#3d-visualizations',
  object3D: root + '/guides/track/log/media#3d-visualizations',
  parameterImportance:
    root + '/guides/app/features/panels/parameter-importance',
  plotly: root + '/guides/track/log/plots',
  'query-panel': root + '/guides/app/features/panels/query-panel',
  publicApi: root + '/ref/python/public-api',
  pythonApi: root + '/ref/python/public-api',
  registry: root + '/guides/registry/',
  reports: root + '/guides/reports',
  root,
  save: root + '/guides/track/save-restore',
  serviceAccounts: root + '/guides/hosting/iam/service-accounts',
  started: root + '/quickstart',
  summaryMetric: root + '/guides/track/log#summary-metrics',
  sweeps: root + '/guides/sweeps',
  sweepsConfigNested:
    root + '/sweeps/define-sweep-configuration#nested-parameters',
  sweepsOnLaunch: root + '/guides/launch/sweeps-on-launch',
  table: root + '/guides/track/log/log-tables',
  teams: root + '/guides/app/features/teams',
  teamTrials: root + '/guides/app/features/teams#team-trials',
  video: root + '/guides/track/log/media#other-media',
  weave: root + '/guides/app/features/panels/weave',
  'weave-plot': root + '/guides/app/features/panels/weave',
  'weave-projector':
    root + '/guides/app/features/panels/weave/embedding-projector',
  'weave-table': root + '/guides/tables',
  weaveProduct:
    'https://wandb.github.io/weave/?utm_source=app&utm_medium=app&utm_campaign=weave-nudge',
  workspaceModes: root + '/guides/app/features/panels/#workspace-modes',
  workspaces: root + '/guides/app/pages/workspaces',
};

// Use this if you need an index lookup
export function lookupDocUrl(name: string) {
  return (DOC_URLS as {[key: string]: string | undefined})[name];
}

export default DOC_URLS;
