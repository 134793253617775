import {unsetCookie} from '@wandb/weave/common/util/cookie';
import {Button} from '@wandb/weave/components/Button';
import React, {FC, memo, useContext} from 'react';

import {envIsLocal} from '../../../config';
import {Analytics} from '../../../services/analytics';
import {USE_VIEW_AS_COOKIE_KEY, viewingAs} from '../../../util/admin';
import {AccountSelectorContext} from './AccountSelectorContextProvider';

const ImpersonatingButtonComp: FC = () => {
  const {
    setSelectedAccount,
    lastNonImpersonatedAccount,
    setLastNonImpersonatedAccount,
  } = useContext(AccountSelectorContext);

  if (envIsLocal) {
    return null;
  }

  const stopImpersonating = () => {
    setSelectedAccount(lastNonImpersonatedAccount ?? null);
    setLastNonImpersonatedAccount(null);
    unsetCookie(USE_VIEW_AS_COOKIE_KEY);
    Analytics.track(`User is no longer impersonating`, {
      wandb_user: lastNonImpersonatedAccount?.username,
      location: 'impersonating button',
    });
    window.location.reload();
  };

  const impersonatingText =
    'You are currently impersonating ' + viewingAs() + '.';
  const stopText = 'Stop';

  return (
    <Button
      icon="warning-alt"
      className="hidden text-white hover:text-white sm:flex"
      variant="ghost"
      active={true}
      size="large"
      onClick={stopImpersonating}>
      <>
        {impersonatingText} <span className="font-bold">{stopText}</span>
      </>
    </Button>
  );
};

const ImpersonatingButton = memo(ImpersonatingButtonComp);
ImpersonatingButton.displayName = 'ImpersonatingButton';

export default ImpersonatingButton;
