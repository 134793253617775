import {useMemo} from 'react';

import {SettingLevel} from '../../../../services/analytics/workspaceSettingsEvents';
import {
  SettingOverrideCounts,
  SettingOverrideKey,
} from '../../../../state/views/workspaceSettings/settingOverrideUtils';
import {LinePlotSettings} from '../types';
import {ClearSettingFnType} from './useSettingOverrides';

export type ClearSettingRow = {
  key: string;
  numOverrides: number;
  settingKey: SettingOverrideKey;
  settingLevel: SettingLevel;
  clearSetting: () => void;
};

export const useClearSettingRows = ({
  numOverrides,
  settingKey,
  settingLevel,
  clearSetting,
  emptySettings = {},
}: {
  numOverrides?: SettingOverrideCounts;
  settingKey?: SettingOverrideKey;
  settingLevel?: SettingLevel;
  clearSetting?: ClearSettingFnType;
  emptySettings?: Partial<LinePlotSettings>;
}): ClearSettingRow[] => {
  const rows = useMemo(() => {
    if (clearSetting == null || numOverrides == null || settingKey == null) {
      return [];
    }

    const rows: ClearSettingRow[] = [];
    if (
      settingLevel === 'workspace' &&
      numOverrides?.sections != null &&
      numOverrides.sections?.[settingKey] > 0
    ) {
      rows.push({
        key: `clear-section-${settingKey}`,
        numOverrides: numOverrides.sections?.[settingKey],
        settingKey,
        settingLevel: 'section',
        clearSetting: () => clearSetting('section', settingKey, emptySettings),
      });
    }
    if (numOverrides?.panels != null && numOverrides.panels?.[settingKey] > 0) {
      rows.push({
        key: `clear-panel-${settingKey}`,
        numOverrides: numOverrides.panels?.[settingKey],
        settingKey,
        settingLevel: 'panel',
        clearSetting: () =>
          // Reset to all undefined so cascading settings is propoagated properly
          clearSetting('panel', settingKey, emptySettings),
      });
    }
    return rows;
  }, [clearSetting, numOverrides, settingLevel, settingKey, emptySettings]);

  return rows;
};
