import {RunsLinePlotConfig} from '../../../../PanelRunsLinePlot/types';
import {getCascadingSetting} from '../../getCascadingSetting';
import {LinePlotSettings} from '../../types';

export const getCascadingYAxisSettings = (
  parentSettings: LinePlotSettings | undefined,
  childSettings?: LinePlotSettings,
  panelSettings?: RunsLinePlotConfig
): {
  yAxisMin: number | undefined;
  yAxisMax: number | undefined;
  yLogScale: boolean | undefined;
} => {
  return {
    yAxisMin: getCascadingSetting([
      parentSettings?.yAxisMin,
      childSettings?.yAxisMin,
      panelSettings?.yAxisMin,
    ]),
    yAxisMax: getCascadingSetting([
      parentSettings?.yAxisMax,
      childSettings?.yAxisMax,
      panelSettings?.yAxisMax,
    ]),
    yLogScale: getCascadingSetting([
      parentSettings?.yLogScale,
      childSettings?.yLogScale,
      panelSettings?.yLogScale,
    ]),
  };
};
