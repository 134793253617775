import {localPoint} from '@visx/event';
import {ParentSize} from '@visx/responsive';
import {useTooltip} from '@visx/tooltip';
import React from 'react';

import {Bar} from '../../../util/plotHelpers/types';
import {HorizontalBarChart} from './HorizontalBarChart';
import {Tooltip} from './Tooltip';
import {BarChartProps} from './types';
import {VerticalBarChart} from './VerticalBarChart';

export const BarChart = (props: BarChartProps) => {
  const {
    mouseOver,
    mouseOut,
    maxBars,
    enableTooltip = true,
    tooltipClassName,
    withTooltipBounds,
    valueFormatter,
  } = props;

  const bars = maxBars != null ? props.bars.slice(0, maxBars) : props.bars;

  const {
    hideTooltip,
    showTooltip,
    tooltipData,
    tooltipLeft,
    tooltipOpen,
    tooltipTop,
  } = useTooltip();

  const yDomain = bars.map((d, i) => i + 1);

  const xDomain = [
    props.min ?? Math.min(...bars.map(d => (isNaN(d.value) ? 0 : d.value)), 0),
    props.max ?? Math.max(...bars.map(d => (isNaN(d.value) ? 0 : d.value)), 0),
  ];

  const handleMouseOver = (
    event: React.MouseEvent<SVGRectElement, MouseEvent>,
    bar: Bar,
    top?: number,
    left?: number
  ) => {
    const coords = localPoint(event);
    if (enableTooltip) {
      showTooltip({
        tooltipLeft: left ?? coords?.x ?? 0,
        tooltipTop: top ?? coords?.y ?? 0,
        tooltipData: bar,
      });
    }
    if (mouseOver != null) {
      mouseOver(event, bar);
    }
  };

  const handleMouseOut = () => {
    hideTooltip();
    if (mouseOut != null) {
      mouseOut();
    }
  };

  const vals = bars.flatMap(b =>
    b.bins != null
      ? b.bins.map(d => d.bin)
      : b.quartiles != null
      ? b.quartiles
      : [b.value]
  );

  const min =
    props.min ??
    (props.boxPlot || props.violinPlot
      ? Math.min(...vals.map(v => (isNaN(v) ? 0 : v)))
      : Math.min(...vals.map(v => (isNaN(v) ? 0 : v)), 0));
  const max =
    props.max ??
    (props.boxPlot || props.violinPlot
      ? Math.max(...vals.map(v => (isNaN(v) ? 0 : v)))
      : Math.max(...vals.map(v => (isNaN(v) ? 0 : v)), 0));

  const fillStyle = React.useMemo(() => {
    return props.height != null ? {height: props.height} : {};
  }, [props.height]);

  if (props.vertical) {
    return (
      <div className="bar-chart" style={fillStyle}>
        <ParentSize>
          {parent => (
            <VerticalBarChart
              {...{
                ...props,
                bars,
                width: parent.width,
                height: parent.height,
                mouseOver: handleMouseOver,
                mouseOut: handleMouseOut,
                min,
                max,
                xDomain,
                yDomain,
              }}
            />
          )}
        </ParentSize>
        {tooltipOpen && (
          <Tooltip
            left={tooltipLeft}
            top={tooltipTop}
            data={tooltipData}
            tooltipClassName={tooltipClassName}
            withTooltipBounds={withTooltipBounds}
            valueFormatter={valueFormatter}
          />
        )}
      </div>
    );
  } else {
    return (
      <div className="bar-chart" style={fillStyle}>
        <ParentSize>
          {parent => (
            <div style={{width: '100%', height: '100%', overflow: 'auto'}}>
              <HorizontalBarChart
                {...{
                  ...props,
                  bars,
                  parentHeight: parent.height,
                  parentWidth: parent.width,
                  mouseOver: handleMouseOver,
                  mouseOut: handleMouseOut,
                  min,
                  max,
                  xDomain,
                  yDomain,
                }}
              />
            </div>
          )}
        </ParentSize>
        {tooltipOpen && (
          <Tooltip left={tooltipLeft} top={tooltipTop} data={tooltipData} />
        )}
      </div>
    );
  }
};

export default BarChart;
