import {OrganizedSettings} from '../../../components/PanelBank/types';
import {LinePlotSettings} from '../../../components/WorkspaceDrawer/Settings/types';
import {
  areExcludeOutliersSettingsActive,
  areMaxRunsSettingsActive,
  arePointAggregationSettingsActive,
  areSmoothingSettingsActive,
  areXAxisSettingsActive,
  areYAxisSettingsActive,
  isColorRunNamesSettingActive,
  isDisplayFullRunNameSettingActive,
  isGroupAggSettingActive,
  isHighlightedCompanionRunOnlySettingActive,
  isRunsTableGroupingSettingActive,
  isShowLegendSettingActive,
  isTooltipNumberOfRunsSettingActive,
} from '../../../components/WorkspaceDrawer/Settings/utils';
import {PanelBankSectionConfigNormalized} from '../../../state/views/panelBankSectionConfig/types';
import {PanelWithConfig} from '../../../util/panelTypes';
import {Ref} from '../panel/types';
import {
  filteredOverridePanels,
  filteredOverrideSections,
} from './settingOverrideFilters';

const ACTIVE_SETTING_FUNCTIONS: Record<
  SettingOverrideKey,
  (settings: LinePlotSettings | undefined) => boolean
> = {
  colorRunNames: isColorRunNamesSettingActive,
  displayFullRunName: isDisplayFullRunNameSettingActive,
  excludeOutliers: areExcludeOutliersSettingsActive,
  groupAgg: isGroupAggSettingActive,
  highlightedCompanionRunOnly: isHighlightedCompanionRunOnlySettingActive,
  maxRuns: areMaxRunsSettingsActive,
  pointVisualizationMethod: arePointAggregationSettingsActive,
  showLegend: isShowLegendSettingActive,
  smoothing: areSmoothingSettingsActive,
  tooltipNumberOfRuns: isTooltipNumberOfRunsSettingActive,
  useRunsTableGroupingInPanels: isRunsTableGroupingSettingActive,
  xAxis: areXAxisSettingsActive,
  yAxis: areYAxisSettingsActive,
};

export const calculateIsSettingEnabled = (
  settingKey: SettingOverrideKey,
  settings: LinePlotSettings | undefined
) => {
  return ACTIVE_SETTING_FUNCTIONS[settingKey](settings);
};

const SETTING_OVERRIDE_KEYS = [
  'colorRunNames',
  'displayFullRunName',
  'excludeOutliers',
  'groupAgg',
  'highlightedCompanionRunOnly',
  'maxRuns',
  'pointVisualizationMethod',
  'smoothing',
  'showLegend',
  'tooltipNumberOfRuns',
  'useRunsTableGroupingInPanels',
  'xAxis',
  'yAxis',
];
export type SettingOverrideKey = (typeof SETTING_OVERRIDE_KEYS)[number];

export type SectionOverrides = Record<
  SettingOverrideKey,
  PanelBankSectionConfigNormalized[]
>;
export type PanelOverrides = Record<
  SettingOverrideKey,
  (PanelWithConfig<'Run History Line Plot'> & {ref: Ref})[]
>;

export type SettingOverrideCounts = {
  panels?: Record<SettingOverrideKey, number>;
  sections?: Record<SettingOverrideKey, number>;
};

/**
 * Returns the panels that have a setting defined at config level which overrides the parent settings.
 * This data is to inform users how many panels have a different setting than the parent and allow them to clear these panels.
 * @param panels - The panels to filter through
 * @param settings - Parent settings that we want to check against
 */
export const getPanelsThatOverrideWorkspaceSettings = (
  panels: (PanelWithConfig<'Run History Line Plot'> & {ref: Ref})[],
  settings: OrganizedSettings | undefined
) => {
  return SETTING_OVERRIDE_KEYS.reduce(
    (acc: PanelOverrides, key: SettingOverrideKey) => {
      acc[key] = filteredOverridePanels({
        panels,
        linePlotSettings: settings?.linePlot,
        areSettingsActive: ACTIVE_SETTING_FUNCTIONS[key],
      });
      return acc;
    },
    {}
  );
};

/**
 * Returns sections that have a setting defined that overrides the parent settings.
 * This data is to inform users how many sections have a different setting than the parent and allow them to clear these section settings.
 * @param sections - The sections to filter through
 * @param settings - Parent settings that we want to check against
 */
export const getSectionsThatOverrideWorkspaceSettings = (
  sections: PanelBankSectionConfigNormalized[],
  settings: OrganizedSettings | undefined
) => {
  return SETTING_OVERRIDE_KEYS.reduce(
    (
      acc: Record<SettingOverrideKey, PanelBankSectionConfigNormalized[]>,
      key: SettingOverrideKey
    ) => {
      acc[key] = filteredOverrideSections({
        sections,
        linePlotSettings: settings?.linePlot,
        areSettingsActive: ACTIVE_SETTING_FUNCTIONS[key],
      });
      return acc;
    },
    {}
  );
};

/**
 * Returns the number of overrides for each setting.
 * These are the counts common between sections and panels.
 */
const getSettingOverrideCounts = (
  overrides: SectionOverrides | PanelOverrides
): Record<SettingOverrideKey, number> => {
  const settingKeys = Object.keys(overrides);
  return settingKeys.reduce((acc: Record<SettingOverrideKey, number>, key) => {
    acc[key] = overrides[key].length;
    return acc;
  }, {});
};

export const getSectionSettingOverrideCounts = (
  overrides: SectionOverrides
) => {
  return getSettingOverrideCounts(overrides);
};

export const getPanelSettingOverrideCounts = (
  panelOverrides: PanelOverrides
) => {
  return getSettingOverrideCounts(panelOverrides);
};
