import {useDeepMemo} from '@wandb/weave/hookUtils';
import {useEffect, useMemo, useState} from 'react';

import {Query} from '../../../util/queryTypes';
import {useSharedPanelState} from '../../Panel/SharedPanelStateContext';
import {POINT_VISUALIZATION_OPTIONS} from '../../WorkspaceDrawer/Settings/types';
import {Range, runsLinePlotTransformQuery} from '../common';
import {usePanelConfigContext} from '../PanelConfigContext';
import {RunsLinePlotConfig} from '../types';
import {BucketedQueryState} from './bucketedQueryManager';
import {bracketHistoriesData} from './util';

const usePanelQuery = (
  pageQuery: Query,
  config: RunsLinePlotConfig,
  queryZoomRange: Range
) => {
  const {limit: numRuns, parsedExpressions} = usePanelConfigContext();
  const transformed = runsLinePlotTransformQuery({
    query: pageQuery,
    runsLinePlotConfig: config,
    xStepRange: queryZoomRange,
    parsedExpressions,
    defaultMaxRuns: numRuns,
    isFullFidelity: true,
  });

  return useDeepMemo(transformed);
};

export function useBucketedData(
  config: RunsLinePlotConfig,
  pageQuery: Query,
  queryZoomRange: Range,
  nBuckets: number
) {
  const panelQuery = usePanelQuery(pageQuery, config, queryZoomRange);
  const {bucketQueryManagerById} = useSharedPanelState();

  const [bucketedDataById, setBucketedDataById] = useState<
    Record<string, BucketedQueryState>
  >({});

  useEffect(() => {
    const managers = bucketQueryManagerById.current;
    const handlers: ((state: BucketedQueryState) => void)[] = [];

    panelQuery.queries.forEach(q => {
      const handlerDataChange = (state: BucketedQueryState) => {
        const bracketedState = bracketHistoriesData(state, config.xAxis);
        setBucketedDataById(cur => ({...cur, [q.id]: bracketedState}));
      };

      handlers.push(handlerDataChange);

      bucketQueryManagerById.current[q.id].registerRequest({
        handler: handlerDataChange,
        nBuckets,
        runsDataQuery: panelQuery,
        singleQuery: q,
      });
    });

    // Cleanup function that unregisters all handlers
    return () => {
      panelQuery.queries.forEach((q, index) => {
        if (managers[q.id]) {
          managers[q.id].unregisterRequest(handlers[index]);
        }
      });
    };
  }, [nBuckets, panelQuery, bucketQueryManagerById, config.xAxis]);

  const bucketedDataMemo = useMemo(() => {
    return {
      _dataType: POINT_VISUALIZATION_OPTIONS.BucketingGorilla,
      entityName: pageQuery.entityName,
      histories: {
        data: Object.values(bucketedDataById).flatMap(v => {
          try {
            const result = Object.values(v.data?.runsById ?? {}).filter(
              r => !!r
            );
            return result;
          } catch (e) {
            console.error(e);
            return [];
          }
        }),
      },
      initialLoading: false,
      loadMore: () => {},
      projectName: pageQuery.projectName,
    };
  }, [bucketedDataById, pageQuery.entityName, pageQuery.projectName]);

  const {error, loading} = useMemo(() => {
    return {
      error: Object.values(bucketedDataById).find(v => v.error),
      loading: Object.values(bucketedDataById).some(v => v.loading),
    };
  }, [bucketedDataById]);

  if (error) {
    console.error(error);
  }

  return {
    data: bucketedDataMemo,
    error,
    loading,
  };
}
