import gql from 'graphql-tag';
import {useMemo} from 'react';

import * as Generated from '../../generated/graphql';
import {useCachedData} from '../../util/cache';
import * as Filter from '../../util/filters';
import * as FilterTypes from '../../util/filterTypes';
import * as QueryTS from '../../util/queryts';
import * as QueryTypes from '../../util/queryTypes';
import * as RunTypes from '../../util/runTypes';
import {useDeepMemo} from '../hooks';
import {useQuery} from './query';

export interface WandbConfigQueryVars {
  entityName: string;
  projectName: string;
  filters: FilterTypes.Filter<RunTypes.Key>;
  sort: QueryTypes.Sort;
}

export const WANDB_CONFIG_QUERY = gql`
  query WandbConfig(
    $projectName: String!
    $entityName: String!
    $filters: JSONString
    $limit: Int = 100
    $order: String
  ) {
    project(name: $projectName, entityName: $entityName) {
      id
      runs(filters: $filters, first: $limit, order: $order) {
        edges {
          node {
            id
            wandbConfig
          }
        }
      }
    }
  }
`;

interface Data {
  project: {
    runs: {
      edges: Array<{
        node: {
          wandbConfig: any;
        };
      }>;
    };
  };
}

export type WandbConfig = Array<{[key: string]: any}>;

type UseWandbConfigQueryResult =
  | {loading: true; error: null}
  | {loading: false; error: true}
  | {
      loading: false;
      error: null;
      wandbConfig?: WandbConfig;
    };

const configLoading = {loading: true as const, error: null};
const configError = {loading: false as const, error: true as const};

// This query is polled, respecting the user polling settings and the page poll
// interval (stored in redux). If the query is expensive (determined by a simple
// threshold on the result size), we raise the poll interval to a much larger
// value, to reduce network overhead for users.
export function useWandbConfigQuery(
  queryVars: WandbConfigQueryVars,
  skip?: boolean
): UseWandbConfigQueryResult {
  const {entityName, projectName, filters, sort} = queryVars;
  const pollMultiplier = 1;

  const query = useQuery<Data, Generated.WandbConfigQueryVariables>(
    Generated.WandbConfigDocument,
    {
      variables: {
        entityName,
        projectName,
        filters: JSON.stringify(Filter.toMongo(filters)),
        order: QueryTS.sortToOrderString(sort),
      },
      enablePolling: true,
      pollMultiplier,
      skip,
    }
  );

  const initialLoading = query.initialLoading;
  const project = query.initialLoading ? null : query.data?.project;

  const parsedConfig = useMemo(() => {
    if (project == null) {
      return null;
    }
    return project.runs?.edges.map(e => {
      return JSON.parse(e.node.wandbConfig);
    });
  }, [project]);

  const rawConfigFromServerOrCache = useCachedData({
    namespace: 'WandbConfig',
    key: queryVars,
    dataFromServer: parsedConfig,
    waitingForDataFromServer: initialLoading,
  });

  const configFromServerOrCache = useDeepMemo(rawConfigFromServerOrCache);
  const queryResult = useMemo(
    () => ({
      loading: false as const,
      error: null,
      wandbConfig: configFromServerOrCache ?? [],
    }),
    [configFromServerOrCache]
  );

  // Switch/conditional outside useMemo
  if (configFromServerOrCache != null) {
    return queryResult;
  }
  if (initialLoading) {
    return configLoading;
  }
  if (project == null) {
    return configError;
  }
  return queryResult;
}
