/**
 * Wrapper for react-router-dom Link for consistent styling.
 */

import {TEAL_500, TEAL_600} from '@wandb/weave/common/css/globals.styles';
import {FCWithRef} from '@wandb/weave/common/util/types';
import React from 'react';
import {Link as LinkComp} from 'react-router-dom';
// eslint-disable-next-line wandb/no-deprecated-imports
import styled from 'styled-components';

import {urlPrefixed} from '../../config';

export const Link = styled(LinkComp)`
  font-weight: 600;
  color: ${TEAL_600};
  &:hover {
    color: ${TEAL_500};
  }
`;

const A = styled.a`
  font-weight: 600;
  color: ${TEAL_600};
  &:hover {
    color: ${TEAL_500};
  }
`;

// Code duplication with @wandb/weave/common/util/links
export const TargetBlank: FCWithRef<
  React.AnchorHTMLAttributes<HTMLAnchorElement>,
  HTMLAnchorElement
> = React.memo(
  React.forwardRef(({children, ...passthroughProps}, ref) => {
    // Enforce an absolute prefixed url for blank targets
    if (
      passthroughProps.href != null &&
      !passthroughProps.href.startsWith('http')
    ) {
      passthroughProps.href = urlPrefixed(passthroughProps.href);
    }
    return (
      <A
        target="_blank"
        rel="noopener noreferrer"
        {...passthroughProps}
        ref={ref}>
        {children}
      </A>
    );
  })
);
