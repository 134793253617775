import type {LogsGlobal} from '@datadog/browser-logs';
import type {RumGlobal} from '@datadog/browser-rum';
import {useCallback, useContext, useEffect} from 'react';

import {DatadogContext, DatadogContextValue} from './context';

export function useDatadog(): DatadogContextValue {
  return useContext(DatadogContext);
}

export function useDatadogRum(): RumGlobal {
  return useDatadog().rum;
}

export function useDatadogLogs(): LogsGlobal {
  return useDatadog().logs;
}

/**
 * Sets a global RUM context property. If the property changes or the component
 * calling this hook are unmounted, that context property is unset.
 *
 * @param key
 * @param value
 */
export function useRumGlobalContext(
  key: string,
  value: unknown,
  shouldLog: boolean = true
) {
  const datadogRum = useDatadogRum();

  useEffect(() => {
    if (shouldLog) {
      datadogRum.setGlobalContextProperty(key, value);
      return () => {
        datadogRum.removeGlobalContextProperty(key);
      };
    }
    return undefined;
  }, [datadogRum, key, value, shouldLog]);
}

/**
 * As with `useRumGlobalContext`, but does not unset when the component unmounts.
 * @param key
 * @param value
 * @param shouldLog
 */
export function usePersistentRumGlobalContext(
  key: string,
  value: unknown,
  shouldLog: boolean = true
) {
  const datadogRum = useDatadogRum();

  useEffect(() => {
    if (shouldLog) {
      datadogRum.setGlobalContextProperty(key, value);
    }
  }, [datadogRum, key, value, shouldLog]);
}

/**
 * Sets a property on the view context, which is retained until some other code
 * changes the current view.
 *
 * @param key
 * @param value
 * @param shouldLog
 */
export function usePersistentRumViewContext(
  key: string,
  value: unknown,
  shouldLog: boolean = true
) {
  const datadogRum = useDatadogRum();

  useEffect(() => {
    if (shouldLog) {
      datadogRum.setViewContextProperty(key, value);
    }
  }, [datadogRum, key, value, shouldLog]);
}

/**
 * Datadog doesn't like feature flags with `.` in the name. If you log a
 * feature with `.` in the name, it gets rolled up with all the other flags
 * under the same prefix, and sets them all to the string `object Object`. To
 * avoid that, we fixup the feature flag name so that DD doesn't interpret it
 * as an object literal path.
 *
 * @param name
 * @returns the repaired name
 */
function repairFeatureFlagName(name: string): string {
  return name.replace(/\./g, '__');
}

export function useDatadogFeatureFlag(key: string) {
  const datadogRum = useDatadogRum();
  const datadogLogs = useDatadogLogs();

  const flagName = repairFeatureFlagName(key);
  return useCallback(
    (value: unknown) => {
      datadogRum.addFeatureFlagEvaluation(flagName, value);
      datadogLogs.setUserProperty(`features.${flagName}`, value);
    },
    [datadogLogs, datadogRum, flagName]
  );
}
