import queryString from 'query-string';

export function keyExistsInQueryString(
  key: string,
  qs = window.location.search
): boolean {
  const params = Object.keys(queryString.parse(qs)).map(k => k.toLowerCase());
  return params.includes(key.toLowerCase());
}

export function getValueFromQueryString(
  key: string,
  qs = window.location.search
): string | string[] | null | undefined {
  return queryString.parse(qs)[key];
}

export function getStringValueFromQueryString(
  key: string,
  qs = window.location.search
): string | null {
  const val = getValueFromQueryString(key, qs);
  if (Array.isArray(val)) {
    return val[0];
  }
  return val ?? null;
}

/**
 * Retrieves a boolean value from the query string.
 * Considers "false", "0", and "f", "no", and "n" as false, and any
 * other non-null, non-undefined value as true.
 *
 * @param key - The key to look for in the query string.
 * @param qs - The query string to parse, defaults to window.location.search.
 * @returns {boolean} - The boolean value of the query string parameter.
 */
export function getBooleanValueFromQueryString(
  key: string,
  qs = window.location.search
): boolean {
  const value = queryString.parse(qs)[key];
  if (typeof value === 'string') {
    return !['false', '0', 'f', 'no', 'n'].includes(value.toLowerCase());
  }
  return value != null;
}
