import React from 'react';

import {DEFAULT_LINE_PLOT_NUM_RUNS_NEW} from '../../components/WorkspaceDrawer/Settings/runLinePlots/maxRunDefaults';
import {RunsDataQuery, toRunsDataQuery} from '../../containers/RunsDataLoader';
import {CHART_SAMPLES} from '../../util/constants';
import {
  WANDB_BBOX_CLASS_LABEL_KEY,
  WANDB_MASK_CLASS_LABEL_KEY,
} from '../../util/mediaConstants';
import type {PanelSpec} from '../../util/panelTypes';
import {Query} from '../../util/queryTypes';
import {isGrouped} from '../../util/runhelpers';
import {panelViews} from '../PanelBank/types';
import {getTitleFromConfig, MediaBrowserPanelConfig} from './common';
export type {MediaBrowserPanelConfig};
export type {Axis, NonEmptyTile} from './common';
export {rowsWithKeysMemo} from './common';

function transformQuery(
  query: Query,
  config: MediaBrowserPanelConfig
): RunsDataQuery {
  const mediaKeys = config.mediaKeys || undefined;
  const result = toRunsDataQuery(
    query,
    {
      selectionsAsFilters: true,
    },
    {historyKeyInfo: true}
  );
  result.disabled = false;
  result.history = true;
  // This is required to get _wandb for single runs
  result.wandbKeys = [WANDB_MASK_CLASS_LABEL_KEY, WANDB_BBOX_CLASS_LABEL_KEY];
  //  This is required to get _wandb for multi runs
  // result.configKeys = ['_wandb'];
  if (mediaKeys) {
    result.historySpecs = [
      {keys: ['_step', ...mediaKeys], samples: CHART_SAMPLES},
    ];
  }
  result.page = {
    size: config.maxRuns ?? DEFAULT_LINE_PLOT_NUM_RUNS_NEW,
  };

  if (isGrouped(query)) {
    // We need the metadata for grouping because we do it locally
    // TODO: move grouping to server
    // result.disableMeta = false;

    // Disable grouping for this query, we'll do it ourselves.
    result.queries = result.queries.map(q => ({...q, grouping: []}));
  }

  return result;
}

export const Spec: PanelSpec<
  typeof panelViews.mediaBrowser,
  MediaBrowserPanelConfig
> = {
  type: panelViews.mediaBrowser,
  Component: React.lazy(() => import('./MediaBrowserPanelWrapper')),
  transformQuery,
  getTitleFromConfig,

  configSpec: {
    chartTitle: {editor: 'string', displayName: 'Chart title'},
    columnCount: {
      editor: 'slider',
      displayName: 'Columns',
      min: 1,
      max: 20,
      step: 1,
      default: 4,
    },
    actualSize: {
      editor: 'checkbox',
      displayName: 'Original size',
      default: false,
    },
    fitToDimension: {
      editor: 'checkbox',
      displayName: 'Cover fit',
      default: false,
    },
    pixelated: {editor: 'checkbox', displayName: 'Pixelated', default: true},
    mode: {
      editor: 'dropdown',
      displayName: 'Display as',
      default: 'gallery',
      options: [
        {text: 'Gallery', value: 'gallery'},
        {text: 'Grid', value: 'grid'},
      ],
    },
  },

  icon: 'panel-images',
};
