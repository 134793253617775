import {useMemo} from 'react';

import {OrganizedSettings} from '../../../../PanelBank/types';
import {RunsLinePlotConfig} from '../../../../PanelRunsLinePlot/types';
import {Complete, LinePlotSettings} from '../../types';
import {getLinePlotSettingWithDefault} from '../linePlotDefaults';
import {getCascadingExcludeOutlierSetting} from './getCascadingExcludeOutliers';
import {getCascadingMaxRuns} from './getCascadingMaxRuns';
import {
  getDerivedPointVisualizationOption,
  useCascadingPointAggregationMethod,
} from './getCascadingPointAggregation';
import {getCascadingShowLegend} from './getCascadingShowLegend';

/**
 * We want to make sure the setting fields exist, but still allow them to be undefined.
 * When setting value is null, it indicates that a user hasn't modified the setting
 * yet. A few values are handled differently at workspace and section level, so those
 * individual hooks should handle those there.
 */
const getLinePlotSettings = (
  linePlotSettings: LinePlotSettings | undefined
): Complete<
  Omit<LinePlotSettings, 'pointVisualizationMethod' | 'excludeOutliers'>
> => {
  return {
    colorRunNames: linePlotSettings?.colorRunNames,
    coordinateZooming: linePlotSettings?.coordinateZooming,
    displayFullRunName: linePlotSettings?.displayFullRunName,
    groupAgg: linePlotSettings?.groupAgg,
    highlightedCompanionRunOnly: linePlotSettings?.highlightedCompanionRunOnly,
    limit: linePlotSettings?.limit,
    maxRuns: linePlotSettings?.maxRuns,
    showLegend: getCascadingShowLegend({
      // @ts-expect-error - suppressLegends is deprecated
      suppressLegends: linePlotSettings?.suppressLegends,
      showLegend: linePlotSettings?.showLegend,
    }),
    smoothingType: linePlotSettings?.smoothingType,
    smoothingWeight: linePlotSettings?.smoothingWeight,
    tooltipNumberOfRuns: linePlotSettings?.tooltipNumberOfRuns,
    useRunsTableGroupingInPanels:
      linePlotSettings?.useRunsTableGroupingInPanels,
    xAxis: linePlotSettings?.xAxis,
    xAxisMax: linePlotSettings?.xAxisMax,
    xAxisMin: linePlotSettings?.xAxisMin,
    xLogScale: linePlotSettings?.xLogScale,
    yAxisMax: linePlotSettings?.yAxisMax,
    yAxisMin: linePlotSettings?.yAxisMin,
    yLogScale: linePlotSettings?.yLogScale,
  };
};

// All fields in the type should exist, but can be undefined
export const useWorkspaceLinePlotSettings = (
  organizedSettings?: OrganizedSettings
): Complete<LinePlotSettings> => {
  const linePlotSettings = organizedSettings?.linePlot;

  // This feature is still under development
  // Note: once feature is fully released on prod, we can remove this hook
  const {pointVisualizationMethod} =
    useCascadingPointAggregationMethod(linePlotSettings);

  return useMemo(
    () => ({
      ...getLinePlotSettings(linePlotSettings),
      pointVisualizationMethod,
      excludeOutliers: getCascadingExcludeOutlierSetting(
        pointVisualizationMethod,
        linePlotSettings
      ),
    }),
    [linePlotSettings, pointVisualizationMethod]
  );
};

// All fields in the type should exist, but can be undefined
export const useSectionLinePlotSettings = (
  organizedSettings?: OrganizedSettings
): Complete<LinePlotSettings> => {
  const linePlotSettings = organizedSettings?.linePlot;

  return useMemo(
    () => ({
      ...getLinePlotSettings(linePlotSettings),
      pointVisualizationMethod: linePlotSettings?.pointVisualizationMethod,
      // We don't need to worry about the deprecated excludeOutliers fields because
      // section settings came after those changes
      excludeOutliers: linePlotSettings?.excludeOutliers,
    }),
    [linePlotSettings]
  );
};

/**
 * @param settingsType indicates what level the settings are
 *
 * This is used at the panel/most specific level. It will take the current value or the default value.
 * We want to resolve at the panel level, so that we can easily add or remove settings
 * at both the workspace and section levels.
 */
export const getLinePlotSettingsWithDefaults = (
  settingsObj: LinePlotSettings | RunsLinePlotConfig | undefined
) => {
  const pointVisualizationMethod = getDerivedPointVisualizationOption(
    settingsObj?.pointVisualizationMethod
  );

  const limit = getCascadingMaxRuns({
    pointVisualizationMethod,
    maxRuns:
      settingsObj != null && 'maxRuns' in settingsObj
        ? settingsObj?.maxRuns
        : undefined,
    limit:
      settingsObj != null && 'limit' in settingsObj
        ? settingsObj?.limit
        : undefined,
  });

  return {
    colorRunNames: getLinePlotSettingWithDefault(
      settingsObj?.colorRunNames,
      'colorRunNames'
    ),
    displayFullRunName: getLinePlotSettingWithDefault(
      settingsObj?.displayFullRunName,
      'displayFullRunName'
    ),
    excludeOutliers: settingsObj?.excludeOutliers,
    groupAgg: getLinePlotSettingWithDefault(settingsObj?.groupAgg, 'groupAgg'),
    highlightedCompanionRunOnly: getLinePlotSettingWithDefault(
      settingsObj?.highlightedCompanionRunOnly,
      'highlightedCompanionRunOnly'
    ),
    limit: getLinePlotSettingWithDefault(limit, 'limit'),
    pointVisualizationMethod,
    showLegend: getLinePlotSettingWithDefault(
      settingsObj?.showLegend,
      'showLegend'
    ),
    smoothingType: getLinePlotSettingWithDefault(
      settingsObj?.smoothingType,
      'smoothingType'
    ),
    smoothingWeight: getLinePlotSettingWithDefault(
      settingsObj?.smoothingWeight,
      'smoothingWeight'
    ),
    tooltipNumberOfRuns: getLinePlotSettingWithDefault(
      settingsObj?.tooltipNumberOfRuns,
      'tooltipNumberOfRuns'
    ),
    useRunsTableGroupingInPanels: getLinePlotSettingWithDefault(
      settingsObj?.useRunsTableGroupingInPanels,
      'useRunsTableGroupingInPanels'
    ),
    xAxis: getLinePlotSettingWithDefault(settingsObj?.xAxis, 'xAxis'),
    xAxisMax: settingsObj?.xAxisMax,
    xAxisMin: settingsObj?.xAxisMin,
    xLogScale: getLinePlotSettingWithDefault(
      settingsObj?.xLogScale,
      'xLogScale'
    ),
    yAxisMax: settingsObj?.yAxisMax,
    yAxisMin: settingsObj?.yAxisMin,
    yLogScale: getLinePlotSettingWithDefault(
      settingsObj?.yLogScale,
      'yLogScale'
    ),
  };
};
