import _ from 'lodash';

import {Line} from '../../../../util/plotHelpers';
import {Range} from '../../common';

/**
 * When zooming, there's a lag between when the new boundary points are updated (immediately upon zoom or config change) and when the line data is updated. In the interim, there's a gap where the line data includes points with x-axis values that shouldn't be shown due to the chart bounaries.
 *
 * For areas (lines with y/y0 values that show as a shaded area)React-vis will then render those points outside the given boundary of the chart so we need to remove them. Because the lines are sorted by x-axis value, we can use a binary search to find the first and last points that should be included in the chart.
 *
 * This fn has been performance profiled with 50 lines of 1500 points apiece and typically takes no more than 0.2ms
 */
export const removeBoundaryPoints = (lines: Line[], xDomainQuery: Range) => {
  const min = xDomainQuery.min ?? -Infinity;
  const max = xDomainQuery.max ?? Infinity;

  // no-op when no zoom range is in effect
  if (min === -Infinity && max === Infinity) {
    return lines;
  }

  lines.forEach(line => {
    if (!line.data || !line.data.length || line.meta.type === 'line') {
      return;
    }

    const startIndex =
      min === -Infinity ? 0 : _.sortedIndexBy(line.data, {x: min, y: 0}, 'x');
    const endIndex =
      max === Infinity
        ? undefined
        : _.sortedIndexBy(line.data, {x: max, y: 0}, 'x') + 1;
    line.data = line.data.slice(startIndex, endIndex);
  });

  return lines;
};
