import _ from 'lodash';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';

import {getDatadogRum} from '../datadog';
import {logCustomActionToDatadog, startPerfTimer} from '../profiler';
import {CachedDataWithSize} from './types';
import {useCacheIfEnabled} from './useCacheIfEnabled';
import {getCacheStorageKey} from './util';

export type UseCachedDataParams<T> = {
  namespace: string;
  key: any;
  dataFromServer: T;
  waitingForDataFromServer: boolean;
};

export type UseCachedDataOpts = {
  checkRampFlags: boolean;
};

/**
 * READ BEFORE USING THIS HOOK. SRSLY GUYS.
 * To use this hook properly, there are a couple of things to keep in mind:
 * 1. The caller must ensure that `namespace` is not shared between different queries.
 * 2. When `key == null`, this hook will no-op.
 * 3. When the query changes, the caller must ensure that `key` changes either at the same time or BEFORE `dataFromServer` changes.
 *    Otherwise, we will cache the new data under the old key, so the old key will have invalid data.
 */

export function useCachedData<T>(
  {
    namespace,
    key,
    dataFromServer,
    waitingForDataFromServer,
  }: UseCachedDataParams<T>,
  opts?: UseCachedDataOpts
): T {
  const [dataFromCache, setDataFromCache] =
    useState<CachedDataWithSize<T> | null>(null);
  const cache = useCacheIfEnabled(opts?.checkRampFlags);
  const fileName = useMemo(
    () => getCacheStorageKey(namespace, key),
    [namespace, key]
  );

  // Fetch data from cache while we're waiting for data from the server
  useEffect(() => {
    if (!waitingForDataFromServer || fileName == null || !cache) {
      return;
    }
    (async () => {
      // Handle the case where `fileName` changed and
      // `dataFromCache` was already set with data for the previous `fileName`.
      // We reset `dataFromCache` to `null` while we fetch data for the new `fileName` from cache.
      setDataFromCache(null);

      const fromCache = await cache.get<T>(fileName);
      if (fromCache == null) {
        return;
      }
      setDataFromCache(fromCache);
    })();
  }, [waitingForDataFromServer, fileName, cache]);

  // Store data in cache when we get fresh data from the server
  useEffect(() => {
    if (waitingForDataFromServer || fileName == null || !cache) {
      return;
    }
    cache.set(fileName, dataFromServer);

    // We want to trigger the cache store conservatively.

    // We need to re-run the effect when `dataFromServer` changes.
    // Otherwise, fresh data will not overwrite stale data.

    // We do NOT want to re-run the effect when `waitingForDataFromServer` changes.
    // `dataFromServer` changing is a better indicator that fresh data is available to store.

    // We do NOT want to re-run the effect when `fileName` changes.
    // The `fileName` value updates when the query changes, but we have to wait until
    // `dataFromServer` updates to the new query result before we want to store it under the new `fileName`.

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataFromServer, cache]);

  useMeasureLoadDurations({
    namespace,
    dataFromCache: dataFromCache?.data,
    dataFromServer: waitingForDataFromServer ? null : dataFromServer,
  });

  let result = dataFromServer;
  let bytesReadFromCache = 0;

  if (waitingForDataFromServer && dataFromCache != null && cache) {
    result = dataFromCache.data;
    bytesReadFromCache = dataFromCache.bytesReadFromCache;
  }

  useSetBytesReadFromCache(namespace, result, bytesReadFromCache);

  return result;
}

type LoadTimestamps = {
  start: number;
  cache: number | null;
  server: number | null;
};

type UseMeasureLoadDurationsParams<T> = {
  namespace: string;
  dataFromCache: T | null;
  dataFromServer: T | null;
};

// For now, to keep it simple, we only measure initial load durations.
// This means that even if `key` changes and new fetches from both cache and server are triggered,
// those load durations are not measured.
function useMeasureLoadDurations<T>({
  namespace,
  dataFromCache,
  dataFromServer,
}: UseMeasureLoadDurationsParams<T>): void {
  const timestamps = useRef<LoadTimestamps>({
    start: Date.now(),
    cache: null,
    server: null,
  });
  const logged = useRef(false);

  const logLoadDurationsOnceIfReady = useCallback(() => {
    if (logged.current) {
      return;
    }

    const {start, cache, server} = timestamps.current;
    if (cache == null || server == null) {
      return;
    }

    const loadDurationCache = cache - start;
    const loadDurationServer = server - start;
    const serverDurationMinusCacheDuration =
      loadDurationServer - loadDurationCache;

    // One would think that this is really expensive for large objects,
    // but so far tests have shown that it takes at most ~15ms for some of the
    // largest cached objects. Admittedly, it was on a very powerful machine, but still.
    // To be safe, we'll monitor the performance of the deep equal comparison.
    const {endPerfTimer} = startPerfTimer(`cachehook.deepEqual`, {
      logToRum: true,
      alwaysLog: true,
    });
    const serverDataIdenticalToCacheData = _.isEqual(
      dataFromCache,
      dataFromServer
    );
    endPerfTimer();

    logCustomActionToDatadog(`cachehook.timeSaved`, {
      namespace,
      loadDurationCache,
      loadDurationServer,
      serverDurationMinusCacheDuration,
      serverDataIdenticalToCacheData,
    });

    logged.current = true;
  }, [namespace, dataFromCache, dataFromServer]);

  useEffect(() => {
    if (dataFromCache != null && timestamps.current.cache == null) {
      timestamps.current.cache = Date.now();
    }
    if (dataFromServer != null && timestamps.current.server == null) {
      timestamps.current.server = Date.now();
    }
    logLoadDurationsOnceIfReady();
  }, [dataFromCache, dataFromServer, logLoadDurationsOnceIfReady]);
}

export function useSetBytesReadFromCache(
  namespace: string,
  result: any | null,
  bytesReadFromCache: number
) {
  const haveSeenData = useRef(false);
  useEffect(() => {
    if (result != null && !haveSeenData.current) {
      haveSeenData.current = true;
      getDatadogRum()?.setViewContextProperty(
        `${namespace}BytesReadFromCache`,
        bytesReadFromCache
      );
    }
  }, [result, bytesReadFromCache, namespace]);
}
