// Handles data loading for full fidelity plots. We also still have "sampled"
// plots that are loaded through the usePanelRunsData hook, but full fidelity
// is now the default and we want to depcrecate the sampled style.
//
// Currently we create one BucketedQueryManager per runset per panel, in SharedPanelStateContext.
// This manager is shared across the regular panel chart view, fullscreen mode, and
// edit mode. We share the manager so that fullscreen and edit modes can immediately
// show data when opened.
//
// This works by:
// - maintaining a cache of of requests, their responses, and active listeners
//   (which are just callback functions)
// - deduping exactly the same requests to existing data
// - if a new request cannot be exactly matched, we find the best existing data
//   to immediately send to the handler, while making the requested query in parallel
// - see findBestExistingData for the matching logic
//
// We should move all of the "heavy data processing" logic related to line plots here
// and out of hooks. That will make it easier to reason about performance, test
// performance, move things to webworkers, etc.
//
// The following are canadidates for moving here:
// - make this handle multiple runsets for Reports
// - move post processing logic (like useLines) here, out of hooks
// - move all zoom logic here, out of hooks

import isEqual from 'lodash/isEqual';

import {RunsDataQuery} from '../../../containers/RunsDataLoader';
import {BucketedHistorySpec, specIsStepAxis} from '../../../state/runs/types';
import {Range} from '../common';
import {SingleQuery} from './../../../util/queryTypes';
import {bucketedQuery} from './bucketedDeltaQuery';
import {IS_POLL_TESTING_ENV} from './imperativePollTrigger';
import {RunsDataById} from './types';

type RequestHandler = (state: BucketedQueryState) => void;
interface BucketedQueryRequest {
  handlers: Array<{
    handler: RequestHandler;
    active: boolean;
  }>;
  nBuckets: number;
  queryPromise: Promise<BucketedData> | null;
  runsDataQuery: Exclude<RunsDataQuery, 'queries'>;
  singleQuery: SingleQuery;
  state: BucketedQueryState;
}

interface BucketedData {
  runsById: RunsDataById;
}

export interface BucketedQueryState {
  data: BucketedData | null;
  error: Error | null;
  lastUpdated: number;
  loading: boolean;
  lowerFidelityData: BucketedData | null;
}

export type BucketedQueryFn = (
  query: Exclude<RunsDataQuery, 'queries'>,
  singleQuery: SingleQuery,
  nBuckets: number,
  prevRunsById: RunsDataById
) => Promise<BucketedData>;

export class BucketedQueryManager {
  private boundPollHandler?: (event: Event) => void;
  private requests = new Array<BucketedQueryRequest>();
  private currentGeneration = 0;

  constructor(
    private runsetId: string,
    private queryFn: BucketedQueryFn = bucketedQuery
  ) {
    /**
     * Attaches a listener to the class under specific conditions. This allows us to emit an event (in this case a signal to force a poll event on each BQM) and have all of the BucketQueryManagers trigger their poll method. This behavior is not intended for prod environments. It's only intended for test environments where we want to trigger polling behavior to verify that the class has unregistered stale requests and isn't thrashing the UI by calling multiple requests per polling loop.
     *
     * NOTE: there is no functionality removing the event listener because we don't have any bulletproof way to detect when these classes are being destroyed. This is why this should only be enabled in a short-lived test environment.
     */
    if (IS_POLL_TESTING_ENV) {
      this.boundPollHandler = this.poll.bind(this);
      window.addEventListener('WANDB_BQM_POLL', this.boundPollHandler);
    }
  }

  private cleanupInactiveRequests() {
    this.requests = this.requests.filter(request =>
      request.handlers.some(h => h.active)
    );
  }

  private startQuery(request: BucketedQueryRequest, generation: number) {
    request.state = {
      ...request.state,
      loading: true,
      error: null,
    };
    this.notifyHandlers(request);

    request.queryPromise = this.fetchData({
      generation,
      nBuckets: request.nBuckets,
      prevRunsById: request.state.data?.runsById ?? {},
      runsDataQuery: request.runsDataQuery,
      singleQuery: request.singleQuery,
    });

    request.queryPromise
      .then(data => {
        if (this.currentGeneration === generation) {
          request.state = {
            ...request.state,
            data,
            error: null,
            lastUpdated: Date.now(),
            loading: false,
          };
        }
        return data;
      })
      .catch(error => {
        if (this.currentGeneration === generation) {
          request.state = {
            ...request.state,
            error: error instanceof Error ? error : new Error(String(error)),
            lastUpdated: Date.now(),
            loading: false,
          };
        }
      })
      .finally(() => {
        request.queryPromise = null;
        this.notifyHandlers(request);
      });
  }

  registerRequest({
    handler,
    nBuckets,
    runsDataQuery,
    singleQuery,
  }: {
    handler: RequestHandler;
    nBuckets: number;
    runsDataQuery: Exclude<RunsDataQuery, 'queries'>;
    singleQuery: SingleQuery;
  }) {
    const generation = this.currentGeneration;
    let request = this.requests.find(
      r =>
        r.nBuckets === nBuckets &&
        isEqual(r.runsDataQuery, runsDataQuery) &&
        isEqual(r.singleQuery, singleQuery)
    );

    let initialState;

    if (!request) {
      const lowerFidelityState = this.findBestExistingData(
        nBuckets,
        runsDataQuery
      );
      if (lowerFidelityState) {
        initialState = {...lowerFidelityState, loading: true};
      }

      const newRequest: BucketedQueryRequest = {
        nBuckets,
        runsDataQuery,
        singleQuery,
        handlers: [],
        state: {
          data: null,
          error: null,
          lastUpdated: Date.now(),
          loading: true,
          // We put the lower fidelity data on the state so that downstream we can pop off boundary points if we need to improve chart rendering. See: `bracketHistoryData`
          lowerFidelityData: lowerFidelityState?.data ?? null,
        },
        queryPromise: null,
      };
      this.requests.push(newRequest);

      this.startQuery(newRequest, generation);
      request = newRequest;
    }
    if (!initialState) {
      initialState = request.state;
    }

    request.handlers.push({handler, active: true});
    handler(initialState);

    return handler;
  }

  unregisterRequest(handler: RequestHandler) {
    for (const request of this.requests) {
      const handlerObj = request.handlers.find(h => h.handler === handler);
      if (handlerObj) {
        handlerObj.active = false;
        return;
      }
    }
    throw new Error('Handler not found when trying to unregister request');
  }

  poll() {
    const now = Date.now();
    const POLL_THRESHOLD = 10000;
    const generation = this.currentGeneration;

    this.cleanupInactiveRequests();

    this.requests.forEach(request => {
      if (
        !request.queryPromise &&
        now - request.state.lastUpdated > POLL_THRESHOLD
      ) {
        this.startQuery(request, generation);
      }
    });
  }

  private async fetchData({
    generation,
    nBuckets,
    prevRunsById,
    runsDataQuery,
    singleQuery,
  }: {
    generation: number;
    nBuckets: number;
    prevRunsById: RunsDataById;
    runsDataQuery: Exclude<RunsDataQuery, 'queries'>;
    singleQuery: SingleQuery;
  }): Promise<BucketedData> {
    const result = await this.queryFn(
      runsDataQuery,
      singleQuery,
      nBuckets,
      prevRunsById
    );

    /**
     * We need a way to associate the runset ID with each run so that we can populate it correctly later.
     */
    for (const run of Object.values(result.runsById)) {
      run.runsetId = this.runsetId;
    }

    if (generation !== this.currentGeneration) {
      console.log('STALE REQUEST CANCELLED');
    }

    return result;
  }

  private notifyHandlers(request: BucketedQueryRequest) {
    request.handlers.forEach(h => {
      if (h.active) {
        h.handler(request.state);
      }
    });
  }

  private findBestExistingData(
    nBuckets: number,
    runsDataQuery: RunsDataQuery
  ): BucketedQueryState | null {
    // First try to find a request with same query but fewer buckets
    const sameScopeRequest = this.requests
      .filter(
        r =>
          r.nBuckets <= nBuckets &&
          !r.state.loading &&
          isEqual(r.runsDataQuery, runsDataQuery)
      )
      .sort((a, b) => b.nBuckets - a.nBuckets)[0];

    if (sameScopeRequest) {
      return sameScopeRequest.state;
    }

    // If no exact match found, look for requests with same buckets but more zoomed out
    const zoomedOutRequests = this.requests
      .filter(
        r =>
          r.nBuckets === nBuckets &&
          !r.state.loading &&
          isZoomContained(runsDataQuery, r.runsDataQuery)
      )
      // Sort by "tightness" - if a is tighter than b, it should come first
      .sort((a, b) =>
        isZoomContained(a.runsDataQuery, b.runsDataQuery) ? -1 : 1
      )[0];

    return zoomedOutRequests?.state ?? null;
  }
}

/**
 * Return the min or max value depending on what kind of bucketedHistorySpec it is
 * Custom x-axis specs use minX and maxX instead of minStep and maxStep
 */
function getMinMax(
  spec: BucketedHistorySpec
): [number | undefined, number | undefined] {
  if (specIsStepAxis(spec)) {
    return [spec.minStep, spec.maxStep];
  }
  return [spec.minX, spec.maxX];
}

function queryZoomRange(query: RunsDataQuery): Range | null {
  if (!query.bucketedHistorySpecs || query.bucketedHistorySpecs.length === 0) {
    return null;
  }

  // Get the first spec's range
  const spec = query.bucketedHistorySpecs[0];
  if (!spec) {
    return null;
  }

  const [firstMin, firstMax] = getMinMax(spec);

  // Check if all specs have the same range (including undefined)
  const allMatch = query.bucketedHistorySpecs.every(spec => {
    const [min, max] = getMinMax(spec);
    return min === firstMin && max === firstMax;
  });

  if (!allMatch) {
    return null;
  }

  return {min: firstMin ?? null, max: firstMax ?? null};
}

/**
 * Determines if one query's zoom range is "more specific" than another.
 * Returns true if innerQuery specifies a more constrained range than outerQuery
 * in at least one dimension without being less constrained in the other.
 *
 * A finite bound is always considered more specific than null/undefined.
 *
 * Examples:
 * inner {min: 100, max: null} vs outer {min: null, max: null} -> true
 * inner {min: 100, max: 200} vs outer {min: 50, max: 300} -> true
 * inner {min: 100, max: 300} vs outer {min: 50, max: 300} -> true
 * inner {min: 50, max: 200} vs outer {min: 50, max: 300} -> true
 * inner {min: 1, max: 20} vs outer {min: null, max: 10} -> false (max is less constrained)
 */
function isZoomContained(
  innerQuery: RunsDataQuery,
  outerQuery: RunsDataQuery
): boolean {
  const innerRange = queryZoomRange(innerQuery);
  const outerRange = queryZoomRange(outerQuery);

  // If either query doesn't have a consistent range across its specs, they're not comparable
  if (innerRange === null || outerRange === null) {
    return false;
  }

  // Check min constraint
  const minIsMoreConstrained =
    // Either outer is null and inner is finite (more constrained)
    (outerRange.min === null && innerRange.min !== null) ||
    // Or both are null (equally constrained)
    (outerRange.min === null && innerRange.min === null) ||
    // Or both are finite and inner is higher (more constrained)
    (outerRange.min !== null &&
      innerRange.min !== null &&
      innerRange.min > outerRange.min);

  const minIsEqual =
    innerRange.min !== null &&
    outerRange.min !== null &&
    innerRange.min === outerRange.min;

  // Check max constraint
  const maxIsMoreConstrained =
    // Either outer is null and inner is finite (more constrained)
    (outerRange.max === null && innerRange.max !== null) ||
    // Or both are null (equally constrained)
    (outerRange.max === null && innerRange.max === null) ||
    // Or both are finite and inner is lower (more constrained)
    (outerRange.max !== null &&
      innerRange.max !== null &&
      innerRange.max < outerRange.max);

  const maxIsEqual =
    innerRange.max !== null &&
    outerRange.max !== null &&
    innerRange.max === outerRange.max;

  // Return true if:
  // - both bounds are more constrained, OR
  // - one bound is more constrained and the other is equal
  return (
    (minIsMoreConstrained && maxIsMoreConstrained) ||
    (minIsMoreConstrained && maxIsEqual) ||
    (minIsEqual && maxIsMoreConstrained)
  );
}
