import {ErrorPanel} from '@wandb/weave/components/ErrorPanel';
import classNames from 'classnames';
import React from 'react';

import {METER_SECTION_STYLE} from '../../Billing/AccountSettings/BillingTab/PlanCard/PlanCardComponents';
import {LOADING_ERROR} from '../../Billing/AccountSettings/ManageUsagePage/StorageTableLoadingFailure';

type Props = {
  className?: string;
};

export const MeterSectionError = ({className}: Props) => {
  return (
    <div className={classNames(METER_SECTION_STYLE, className)}>
      <ErrorPanel subtitle={LOADING_ERROR} subtitle2="" />
    </div>
  );
};
